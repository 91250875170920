/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import firebase from "firebase/app";
import _isEmpty from "lodash/isEmpty";

import ScreenLoader from "../../components/ScreenLoader";

import { callFunctionsAPI } from "../../helpers/action";

import { GreyButton } from "../../styles/button";

import { PLATFORM_NAME_LONG, PLATFORM_URL } from "../../constants";

class RedirectNotificationPage extends React.Component {
    componentDidMount() {
        const { nid = "" } = this.getQueryString();

        if (!_isEmpty(nid)) {
            callFunctionsAPI({
                url: "notifications",
                action: "marked_as_read",
                formData: {
                    notification_id: nid,
                },
            })
                .then((res) => {
                    this.doRedirect();
                })
                .catch((error) => {
                    console.log(error);
                    this.doRedirect();
                });
        } else {
            this.doRedirect();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {}

    getQueryString() {
        return this.props.location && this.props.location.search ? queryString.parse(this.props.location.search) : {};
    }

    doRedirect() {
        const { schedule_id } = this.props.match.params;
        const { location } = this.getQueryString();

        // make sure token is available
        if (location && !_isEmpty(location)) {
            switch (location) {
                case "registration":
                    this.props.history.push("/class_registration", { schedule_id });
                    break;
                case "confirmation":
                    this.props.history.push("/class_confirmation", { schedule_id });
                    break;
                case "course_access":
                    this.props.history.push("/course_access", { schedule_id });
                    break;
                case "attendance":
                    this.props.history.push("/class_attendance_reporting", { schedule_id });
                    break;
                case "showcase":
                    this.props.history.push("/class_showcase", { schedule_id });
                    break;
                case "payment":
                    this.props.history.push("/class_payment_tracking", { schedule_id });
                    break;
                case "student_database":
                    this.props.history.push("/student_database", { schedule_id });
                    break;
                case "quarterly_report":
                    this.props.history.push("/class_quarterly_report", { schedule_id });
                    break;
                case "mentor":
                    this.props.history.push("/mentors/" + schedule_id);
                    break;
                default:
                    this.props.history.push("/schedules/" + schedule_id);
                    break;
            }
        } else {
            this.props.history.push("/dashboard");
        }
    }

    render() {
        return <ScreenLoader />;
    }
}

// const mapStateToProps = state => {
//     return {
//         authData: state.auth && state.auth.user ? state.auth.user : null
//     }
// }

export default compose(connect(), withRouter)(RedirectNotificationPage);
