/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import queryString from "query-string";
import axios from "axios";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import _random from "lodash/random";
import _isEmpty from "lodash/isEmpty";

import CertificateDetails from "../CertificatePage/details";

import ScreenLoader from "../../components/ScreenLoader";

import { Button, InverseButton, GreyButton, InverseLink, InfoButton, InfoLink, AInverseLink } from "../../styles/button";
import { SuccessBox } from "../../styles/message";

import { isArrayExists, isObjectExists } from "../../helpers/validation";
import { cloneCollections } from "../../helpers/data";
import { getAPIErrorMessage } from "../../helpers/action";
import { triggerSuccessAlert } from "../../helpers/alert";

import { fb_getCertDetails } from "../../actions/students/fb";

import { FIREBASE_CONFIG_DATA } from "../../firebase";
import { PLATFORM_CERT_URL } from "../../constants";

const MainWrapper = styled.div`
    display: block;
    background: #f1f1f1;
    width: 100%;
    min-height: 100vh;
`;

const HeaderWrapper = styled.div`
    background: #fff;
    border-bottom: 1px solid #ddd;
    padding: 15px 30px;
    text-align: center;
`;

const ContentWrapper = styled.div`
    display: block;
    padding: 60px 15px;

    & > .MuiPaper-root {
        background: #fff;
        padding: 45px 30px;
        max-width: 1080px;
        margin: 0 auto;
    }
`;

const CertificatesWrapper = styled.div``;

const CertificateWrapper = styled.div``;

// breakline for printing
const CertificateBreakline = styled.div`
    page-break-after: always;
`;

const MessageWrapper = styled.div`
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 99999999;
    max-width: 500px;
    width: 100%;

    @media print {
        display: none;
    }
`;

const getCertData = (cert_id) => {
    return new Promise((resolve, reject) => {
        let promises = [];
        promises.push(fb_getCertDetails(cert_id));
        promises.push(
            axios({
                method: "get",
                url: "https://www.googleapis.com/webfonts/v1/webfonts?key=" + FIREBASE_CONFIG_DATA.apiKey,
            })
        );

        Promise.all(promises)
            .then((results) => {
                let badge = results && results[0] && results[0].badge && isObjectExists(results[0].badge) ? results[0].badge : false,
                    certificate =
                        results && results[0] && results[0].certificate && isObjectExists(results[0].certificate) ? results[0].certificate : false,
                    fonts =
                        results && results[1] && results[1].data && results[1].data.items && isArrayExists(results[1].data.items)
                            ? results[1].data.items
                            : false;
                resolve({ badge, certificate, fonts });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

class CertificatesPage extends React.Component {
    state = {
        badge: false,
        certificate: false,
        fonts: false,
        hide: false,
        certificates: [],
        error: false,
        randNum: false,
    };

    componentDidMount() {
        this.getCertificatesData();
    }

    getCertificatesData = () => {
        const { ids = "" } = this.getQueryString();
        if (ids && !_isEmpty(ids)) {
            const cert_ids = ids.split("_|_");
            let promises = [];
            cert_ids.forEach((cert_id) => {
                promises.push(getCertData(cert_id));
            });

            Promise.all(promises)
                .then((results) => {
                    this.setState({ certificates: [...results], randNum: _random(1, 1000) });
                })
                .catch((error) => {
                    this.setState({ error, randNum: _random(1, 1000) });
                });
        } else {
            this.setState({ error: "No certificate found." });
        }
    };

    getQueryString() {
        return this.props.location && this.props.location.search ? queryString.parse(this.props.location.search) : {};
    }

    renderContents() {
        const { certificates, hide } = this.state;
        return (
            <CertificatesWrapper>
                {!hide && (
                    <MessageWrapper>
                        <SuccessBox>
                            <Typography variant="h5">Please use browser's print function to print the certificates.</Typography>
                            <div
                                style={{
                                    textAlign: "right",
                                    paddingTop: "10px",
                                }}
                            >
                                <InverseButton
                                    onClick={() =>
                                        this.setState({
                                            hide: true,
                                        })
                                    }
                                >
                                    Hide this message
                                </InverseButton>
                            </div>
                        </SuccessBox>
                    </MessageWrapper>
                )}
                {certificates.map((cert, index) => {
                    return (
                        <CertificateWrapper key={index}>
                            <CertificateDetails
                                cert_id={cert.id}
                                badge={cert.badge}
                                certificate={cert.certificate}
                                fonts={cert.fonts}
                                noInstruction={true}
                            />
                            {index < certificates.length - 1 && <CertificateBreakline />}
                        </CertificateWrapper>
                    );
                })}
            </CertificatesWrapper>
        );
    }

    renderErrorMessage = () => {
        const { error } = this.state;
        return (
            <>
                <HeaderWrapper>
                    <img src="/logo.gif" style={{ display: "inline-block", height: "auto", width: "100%", maxWidth: "175px" }} />
                </HeaderWrapper>
                <ContentWrapper>
                    <Paper elevation={2}>
                        <div>{getAPIErrorMessage(error)}</div>
                    </Paper>
                </ContentWrapper>
            </>
        );
    };

    render() {
        const { randNum, error } = this.state;
        return !randNum ? <ScreenLoader /> : <MainWrapper>{error ? this.renderErrorMessage() : this.renderContents()}</MainWrapper>;
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(CertificatesPage);
