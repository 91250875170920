/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import axios from "axios";
import Papa from 'papaparse';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';
import _toString from 'lodash/toString';

import TableComp from '../../components/Table';

import { FormBox } from '../../styles/form';
import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton, SuccessButton, ASuccessLink } from '../../styles/button';
import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { getAPIErrorMessage } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp, getAge } from '../../helpers/date';
import { isAdmin, hasAccessRights, getUserRegionFilter } from '../../helpers/auth';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { deleteStudentFromDatabase } from '../../actions/database_students';
import { fb_exportStudentData } from '../../actions/database_students/fb';
import { toggleLoader } from '../../actions/global';

import { userStudentSchema } from '../../schemas/user_student';

import { PLATFORM_COURSE_REGISTRATION_LINK, GENDER_OPTIONS, ENROLLMENT_STATUS_OPTIONS, PLATFORM_CERT_URL, PLATFORM_BADGE_URL } from '../../constants';

const ConditionWrapper = styled.ul`
    font-size: 12px;
    li {
        display: block;
        margin-bottom: 10px;
        span {
            margin-right: 5px;
        }
        i {
            &.fa-check {
                color: ${green[200]};
            }
            &.fa-times {
                color: ${red[200]};
            }
        }
    }
`;

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    },
    table: {
        marginBottom: '30px',
        border: '1px solid ' + theme.palette.background,
        '& tr:last-child > td': {
            borderColor: theme.palette.background
        }
    },
    hcell: {
        width: '15%',
        fontWeight: '700',
        fontSize: '16px',
        verticalAlign: 'top',
        color: theme.palette.background,
    },
    tcell: {
        width: '85%',
        fontWeight: '400',
        fontSize: '14px',
        verticalAlign: 'top',
        color: theme.palette.background,
    }
});

class SearchResults extends React.Component {
    
    state = {
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        
    }

    getPaymentData = () => {
        const { student, schedules } = this.props;
        let payment = [];
        if ( student && student._data && student._data.trainees && isArrayExists( student._data.trainees ) ) {
            let trainees = _sortBy( cloneCollections( student._data.trainees ), ['confirmation_email_date'] );
            _forEach( trainees, trainee => {
                let schedule = ( schedules && isArrayExists( schedules ) ? _find( schedules, { id: trainee.schedule_id } ) : false );
                if ( schedule ) {
                    payment.push( {
                        id: schedule.id,
                        class_name: schedule.class_name || '',
                        payment_tracking: trainee.payment_tracking && isArrayExists( trainee.payment_tracking ) ? [ ...trainee.payment_tracking ] : []
                    });
                }
            });
        } // end - student
        return _sortBy( payment, [ p => p.class_name.toLowerCase() ]);
    }

    getBadges = () => {
        const { student } = this.props;
        let badges = ( student && student._data && student._data.badges && isArrayExists( student._data.badges ) ? [ ...student._data.badges ] : [] );
        // add in pending badges
        if ( student && student._data && student._data.pending_badges && isArrayExists( student._data.pending_badges ) ) {
            badges = [ ...badges, ...student._data.pending_badges ];
        }
        return badges;
    }

    getCourseEnrollments = () => {
        const { student, lms_courses } = this.props;
        let enrollments = [],
            studentTrainees = ( student && student._data && student._data.trainees && isArrayExists( student._data.trainees ) ? _filter( student._data.trainees, (t) => t.enrollment_status && t.enrollment_status !== 'delete' ) : [] );
        if ( studentTrainees && isArrayExists( studentTrainees ) && lms_courses && isArrayExists( lms_courses ) ) {
            let trainees = _sortBy( cloneCollections( studentTrainees ), ['confirmation_email_date'] );
            trainees = _reverse( trainees );
            _forEach( trainees, trainee => {
                if ( trainee.course_access && isArrayExists( trainee.course_access ) ) {
                    _forEach( trainee.course_access, course_access => {
                        let lms_course = _find( lms_courses, c => _toString( c.id ) === _toString( course_access.id ) );
                        if ( lms_course && !_find( enrollments, e => _toString( e.id ) === _toString( lms_course.id ) ) ) {
                            enrollments.push({
                                id: lms_course.id,
                                name: ( lms_course.name || '' ),
                                status: ( course_access.status || '' ),
                                activated: ( course_access.activated || 0 ),
                                deactivated: ( course_access.deactivated || 0 ),
                                concluded: ( course_access.concluded || 0 ),
                                course_code: ( course_access.course_code || '' ),
                                set_deactivation: ( course_access.set_deactivation || '' ),
                                schedule_id: trainee.schedule_id || ''
                            });
                        } // end - lms_course
                    });
                } // end - trainee.course_access
            });
        } // end - student
        return _sortBy( enrollments, ['activated']);
    }

    renderBadges = () => {
        const { classes, student } = this.props;
        return (
        <>
            <TableComp 
                items={this.getBadges()}
                showCheckbox={false}
                emptyCell="No badge(s) Found."
                cells={[
                    { id: 'badge_title', label: 'Badge Title', render: (item) => <div>{( item.image && !_isEmpty( item.image ) ? <img src={item.image} style={{ display: 'inline-block', width: '100%', height: 'auto', maxWidth: '30px', verticalAlign: 'middle', marginRight: '12px' }} /> : null )}{( item.name || '-' )}</div> },
                    { id: 'badge_status', label: 'Status', render: (item) => {
                        if ( item.badge_status && !_isEmpty( item.badge_status ) ) {
                            return (
                            <ConditionWrapper>
                                { item && item.conditions && item.conditions.showcase_date && item.conditions.showcase_date === 'yes' ? <li><span>Open Day Date:</span><i className="fa fa-check"></i></li> : null }
                                <li><span>Feedback Form:</span>{ item && item.conditions && item.conditions.feedback_form && item.conditions.feedback_form === 'yes' ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i> }</li>
                                <li><span>Showcase Project:</span>{ item && item.conditions && item.conditions.project && item.conditions.project === 'yes' ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i> }</li>
                                <li><span>Evaluation:</span>{ item && item.conditions && item.conditions.score && !_isEmpty( item.conditions.score ) ? item.conditions.score === 'pass' ? (
                                <>
                                    <i className="fa fa-thumbs-up" style={{ marginLeft: '3px', color: green[200] }}></i>
                                    { item.conditions.badge_approved && item.conditions.badge_approved === 'yes' ? <i className="fa fa-check"></i> : null }
                                </>
                                ) : <i className="fa fa-times" style={{ color: green[200] }}></i> : '---' }</li>
                            </ConditionWrapper>
                            )
                        } else {
                            return 'Claimed';
                        }
                    }},
                    { id: 'type_label', label: 'Type', render: (item) => <WrapWord>{ item.badge_status && !_isEmpty( item.badge_status ) ? ( item.badge_status === 'approved' || item.badge_status === 'nominated' ? item.type_label : '-' ) : (  item.type_label || '-' )}</WrapWord> },
                    { id: 'created_on', label: 'Claimed On', render: (item) => <WrapWord>{( !( item.badge_status && !_isEmpty( item.badge_status ) ) && item.created_on && item.created_on._seconds ? getMomentTime( item.created_on._seconds*1000, 'DD-MMM-YYYY' ) : '-' )}</WrapWord> }
                ]}
                actionStyles={{ width: "40%" }}
                actions={(item) => !( item.badge_status && !_isEmpty( item.badge_status ) ) && (
                    <ButtonGroup>
                        <AInfoLink key="badge" target="_blank" size="small" href={PLATFORM_BADGE_URL+item.id}><i className="fa fa-shield"></i>View Badge</AInfoLink>
                        <ASuccessLink key="cert" target="_blank" size="small" href={PLATFORM_CERT_URL+item.id}><i className="fa fa-certificate"></i>View Certificate</ASuccessLink>
                    </ButtonGroup>
                )} />
        </>
        )
    }

    renderCourseEnrollments = () => {
        const { classes, schedules } = this.props;
        return (
        <>
            <TableComp 
                items={this.getCourseEnrollments()}
                showCheckbox={false}
                emptyCell="No course enrollment Found."
                cells={[
                    { id: 'class_name', label: 'Class Name', render: (item) => <WrapWord>{getSelectedValue( schedules, item.schedule_id, 'id', 'class_name', '-' )}</WrapWord>},
                    { id: 'badge_title', label: 'Course Name', render: (item) => <WrapWord>{( item.name || '-' )}</WrapWord>},
                    { id: 'status', label: 'Status', render: (item) => <WrapWord>{getSelectedValue( ENROLLMENT_STATUS_OPTIONS, item.status, 'value', 'label', ( item.status && !_isEmpty( item.status ) ? item.status : '-' ) )}</WrapWord>},
                    { id: 'activated', label: 'Activated On', render: (item) => <WrapWord>{( item.activated ? getMomentTime( item.activated, 'DD-MMM-YYYY' ) : '-' )}</WrapWord> },
                    { id: 'deactivated', label: 'De-Activated On', render: (item) => <WrapWord>{( item.deactivated ? getMomentTime( item.deactivated, 'DD-MMM-YYYY' ) : '-' )}</WrapWord> },
                    { id: 'concluded', label: 'Concluded On', render: (item) => <WrapWord>{( item.concluded ? getMomentTime( item.concluded, 'DD-MMM-YYYY' ) : '-' )}</WrapWord> }
                ]}
                actionStyles={{ width: "20%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <AInfoLink key="class" size="small" href={"/r/"+( item.schedule_id && !_isEmpty( item.schedule_id ) ? item.schedule_id : null )+"?location=course_access"} onClick={event => {
                            event.preventDefault();
                            this.props.history.push('/course_access',{ schedule_id: ( item.schedule_id && !_isEmpty( item.schedule_id ) ? item.schedule_id : null ) });
                        }}>View Course Access</AInfoLink>
                    </ButtonGroup>
                )} 
                />
        </>
        )
    }

    renderPaymentTable = () => {
        return (
        <>
            <TableComp 
                items={this.getPaymentData()}
                showCheckbox={false}
                emptyCell="No payment data Found."
                cells={[
                    { id: 'class_name', label: 'Class Name', render: (item) => <WrapWord><a href={"/r/"+item.id+"?location=payment"} onClick={event => {
                        event.preventDefault();
                        history.push('/class_payment_tracking' , { schedule_id: item.id });
                    }}>{ item.class_name || '' }</a></WrapWord>},
                    { id: 'months_paid', label: 'Months Paid', render: (item) => (
                    <>
                        {item.payment_tracking.map((payment, index) => (
                        <div
                            key={payment.id}
                            style={{ display: 'inline-block', margin: '0px 10px 5px 0' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={( payment.payment_made && payment.payment_made === 'yes' ? true : false )}
                                        checkedIcon={(
                                            <CheckBoxIcon
                                                style={{
                                                    color: green[700],
                                                }}
                                                />
                                        )} />
                                }
                                label={payment.id} />
                        </div>
                        ))}
                    </>
                    )},
                ]}
                noAction={true}
                />
        </>
        )
    }

    renderStudentDetails = () => {
        const { classes, student, regions, schedules, authData } = this.props;
        const studentRegion = regions && isArrayExists(regions) ? regions.find((region) => region.id === student.region) : null;
        const userCurrentRegion = regions && isArrayExists(regions) ? regions.find((region) => region.id === authData.region) : null;
        return (
        <Table className={classes.table}>
            <TableBody>
                <TableRow>
                    <TableCell className={classes.hcell}>Partner</TableCell>
                    <TableCell className={classes.tcell}>
                        <div>{ student && student.region ? getSelectedValue( regions, student.region, 'id', 'label', '' ) : '' }</div>
                        { student && student.region && userCurrentRegion && student.region !== userCurrentRegion.id ? (
                        <div>
                            <InfoButton
                                style={{ padding: '10px 25px', marginTop: '15px'}}
                                onClick={() => {
                                    axios({
                                        method: 'post',
                                        url: `https://discord.com/api/webhooks/1124274880624459877/EqEdavk6agVuuq7zSmaiq9H66Ee8Hy8Rl3OrF10vIA4sEnFI6Z7gJ3r0JCoS3kV33LtP`,
                                        data: {
                                            content: `Request to change partner for ${student.email || '' }\n\n
                                            Requested by ${userCurrentRegion && userCurrentRegion.label || ''}.\n
                                            Current partner is ${studentRegion.label || '' }.\n
                                            Student Name: ${student.name || '' }\n
                                            Student UID: ${student._uid || '' }`
                                        }
                                    })
                                    .then((response) => {
                                        triggerSuccessAlert("Request Sent!");
                                    })
                                    .catch((error) => {
                                        triggerErrorAlert(error);
                                    });
                                }}
                                >
                                Request to Change Partner
                            </InfoButton>
                        </div>
                        ) : null }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Name</TableCell>
                    <TableCell className={classes.tcell}>{ student && student.name ? student.name : '-' }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Email</TableCell>
                    <TableCell className={classes.tcell}>{ student && student.email ? student.email : '-' }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Date of Birth </TableCell>
                    <TableCell className={classes.tcell}>{ student && student.date_of_birth_string ? student.date_of_birth_string : '-' }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Gender</TableCell>
                    <TableCell className={classes.tcell} style={{ textTransform: 'capitalize' }}>{ student && student.gender ? student.gender : '-' }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Mobile No.</TableCell>
                    <TableCell className={classes.tcell}>{ student && student.phone ? student.phone : '-' }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>School</TableCell>
                    <TableCell className={classes.tcell}>{ student && student.org ? student.org : '-' }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>City</TableCell>
                    <TableCell className={classes.tcell}>{ student && student.City ? student.City : '-' }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Personal Portfolio</TableCell>
                    <TableCell className={classes.tcell}>{ student && student.eportfolio ? student.eportfolio : '-' }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Parent Name</TableCell>
                    <TableCell className={classes.tcell}>{ student && student.parent_name ? student.parent_name : '-' }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Parent Email</TableCell>
                    <TableCell className={classes.tcell}>
                        { student && student.parent_email ? student.parent_email : '-' }
                        { student && student.observer_email && !_isEmpty( student.observer_email ) && student.parent_email && student.parent_email === student.observer_email ? <SuccessTag showBorder="yes" style={{ marginLeft: '8px' }}>Parent Observer Enabled</SuccessTag> : null }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Parent Mobile No.</TableCell>
                    <TableCell className={classes.tcell}>{ student && student.parent_phone ? student.parent_phone : '-' }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Parent City</TableCell>
                    <TableCell className={classes.tcell}>{ student && student.parent_city ? student.parent_city : '-' }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Teacher Name</TableCell>
                    <TableCell className={classes.tcell}>{ student && student.teacher_name ? student.teacher_name : '-' }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Teacher Email</TableCell>
                    <TableCell className={classes.tcell}>{ student && student.teacher_email ? student.teacher_email : '-' }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Teacher Mobile No.</TableCell>
                    <TableCell className={classes.tcell}>{ student && student.teacher_phone ? student.teacher_phone : '-' }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Teacher City</TableCell>
                    <TableCell className={classes.tcell}>{ student && student.teacher_city ? student.teacher_city : '-' }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Classes</TableCell>
                    <TableCell className={classes.tcell}>{ student && student.classes && isArrayExists( student.classes ) ? (
                        <div>{student.classes.map(schedule => (
                            <div key={schedule.id}><WrapWord><a href={"/schedules/"+schedule.id} onClick={event => {
                                event.preventDefault();
                                this.props.history.push("/schedules/"+schedule.id);
                            }}>{getSelectedValue( schedules, schedule.id, 'id', 'class_name', schedule.id )}</a></WrapWord></div>
                        ))}</div>
                        ) : '---' }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Course Enrollments</TableCell>
                    <TableCell className={classes.tcell}>{this.renderCourseEnrollments()}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Payment</TableCell>
                    <TableCell className={classes.tcell}>{this.renderPaymentTable()}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Student Badges</TableCell>
                    <TableCell className={classes.tcell}>{this.renderBadges()}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
        )
    }

    render() {
        const { authData, student } = this.props;
        return (
        <div>
            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                {this.renderStudentDetails()}
                { student.uid && (
                <AInfoLink href={"/student_database/"+student.uid} style={{ padding: '10px 25px' }} onClick={event => {
                    event.preventDefault();
                    this.props.history.push("/student_database/"+student.uid);
                }}><i className="fa fa-edit"></i>Edit Student Details</AInfoLink>
                ) }
            </Paper>
        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(SearchResults);