/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';
import _toString from 'lodash/toString';

import ModalView from '../../components/ModalView';
import MediaLibrary from '../../components/MediaLibrary';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormEditor from '../../components/FormEditor';
import EmailShortcodes from '../../components/EmailShortcodes';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, convertArrayToObject, convertObjectToArray, replaceAll } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { uploadMediaToStorage } from '../../helpers/firebase';
import { getAPIErrorMessage } from '../../helpers/action';

import { sendPaymentEmail } from '../../actions/class_payment_tracking'
import { toggleLoader } from '../../actions/global';

// import { PLATFORM_COURSE_REGISTRATION_LINK } from '../../constants';

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
    border: 2px solid #212121;
`;

const useStyles = theme => ({
    tooltip: {
        fontSize: '14px',
    },
    emailModal: {
        '& .MuiFilledInput-input.Mui-disabled, & .MuiInputBase-root.Mui-disabled, & .MuiFormLabel-root.Mui-disabled': {
            background: '#f1f1f1',
            color: '#212121'
        }
    }
});

const filePickerCallBack = (authData,field,dispatch) => ( callback, value, meta ) => {
    const input = document.getElementById('editor-mce-file-upload-field-' + field.id );

    input.click();
    input.onchange = function () {
        const file = input.files[0];
        const uploadPath = 'email_templates/' + ( field && field.id && !_isEmpty( field.id ) ? field.id : 'misc' );
        Alert.success('<span class="app-alert text-center content-block"><i class="fa fa-circle-o-notch fa-spin"></i>Uploading image - Please do not click on anything, browser back button, refresh or close this page!</span>', { position: 'top', effect: 'flip', beep: false, timeout: 'none', offset: 70 });
        dispatch(toggleLoader(true));
        uploadMediaToStorage(file, uploadPath, authData)
        .then(function(url) {
            Alert.closeAll();
            dispatch(toggleLoader(false));
            triggerSuccessAlert('Upload Done!');
            callback(url);
        })
        .catch(function(error) {
            Alert.closeAll();
            dispatch(toggleLoader(false));
            triggerErrorAlert(getAPIErrorMessage(error));
        });

    };
}

class EmailSender extends React.Component {

    utcOffSet = 8;
    
    state = {
        modalData: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { trainees, column, columns, email_type, email_templates, open } = this.props;
        if ( open && !prevProps.open ) {
            this.setState({ modalData: this.getInitialModalData() });
        } // end - open
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose )
            onClose();
    }

    handleEmailSend = () => {
        const { schedule_id, onClose, enrollment_status, email_type } = this.props;
        const { modalData } = this.state;
        let error = false,
            formData = {
                schedule_id,
                month: ( modalData && modalData.month && !_isEmpty( modalData.month ) ? modalData.month : false ), 
                trainees: ( modalData && modalData.trainees && isArrayExists( modalData.trainees ) ? modalData.trainees : [] ),
                email_type,
                email_subject: ( modalData && modalData.email_subject && !_isEmpty( modalData.email_subject ) ? modalData.email_subject : '' ),
                email_content: ( modalData && modalData.email_content && !_isEmpty( modalData.email_content ) ? modalData.email_content : '' ),
                email_attachments: ( modalData && modalData.email_attachments && !_isEmpty( modalData.email_attachments ) ? modalData.email_attachments : {} ),
                cc_parent: ( modalData && modalData.cc_parent && modalData.cc_parent === 'yes' ? 'yes' : 'no' ),
                cc_principle: ( modalData && modalData.cc_principle && modalData.cc_principle === 'yes' ? 'yes' : 'no' ),
                cc_coordinator: ( modalData && modalData.cc_coordinator && modalData.cc_coordinator === 'yes' ? 'yes' : 'no' ),
            };

        // error
        if ( !( formData.schedule_id && !_isEmpty( formData.schedule_id ) ) )
            error = "Invalid Schedule ID. Please refresh and try again.";

        if ( !( formData.trainees && isArrayExists( formData.trainees ) ) )
            error = 'Please select at least one student.';

        if ( !( formData.month && !_isEmpty( formData.month ) ) )
            error = 'Please select a month.';

        if ( !( formData.email_subject && !_isEmpty( formData.email_subject ) ) )
            error = "Please insert an email subject.";

        if ( !( formData.email_content && !_isEmpty( formData.email_content ) ) )
            error = "Please insert some email content.";

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(sendPaymentEmail(formData,{ schedule_id, enrollment_status }));
        } // end - error
    }

    handleFormUpdate = (newValue,key,status) => {
        const { modalData } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} );
        switch (key) {
            case 'month':
                newData[key] = newValue;
                newData['email_subject'] = this.addDateToTemplate( newData['email_subject'], { id: newValue });
                newData['email_content'] = this.addDateToTemplate( newData['email_content'], { id: newValue });
                break;
            default:
                newData[key] = newValue;
                break;
        }
        this.setState({ modalData: newData });
    }

    getStudentsEmails = () => {
        const { trainees } = this.props;
        let emails = '';
        if ( trainees && isArrayExists( trainees ) ) {
            _forEach( trainees, student => {
                emails += ( !_isEmpty( emails ) ? '\n' : '' ) + ( student.email || '' );
            });
        } // end - trainees
        return emails;
    }

    addDateToTemplate = (content,column,replace) => {
        const { columns, email_templates } = this.props;
        let selected = ( columns && isArrayExists( columns ) && column && column.id && !_isEmpty( column.id ) ? _find( columns, { id: column.id } ) : false );
        return ( selected && selected.start_at ? replaceAll( content, ( replace || 'MMM-YY' ), getMomentTime( selected.start_at, 'MMM-YY' ) ) : content );
    }

    getInitialModalData = () => {
        const { trainees, column, columns, email_type, email_templates } = this.props;
        let data = {
                trainees,
                month: ( column && column.id && !_isEmpty( column.id ) ? column.id : false ),
                email_subject: '',
                email_content: '',
                email_attachments: {},
                cc_parent: 'yes',
                cc_principle: 'yes',
                cc_coordinator: 'yes',
            },
            email_template = ( email_templates && isArrayExists( email_templates ) ? _find( email_templates, { id: email_type }) : false );
        // add email template
        if ( email_template ) {
            data.email_subject = ( email_template && email_template.subject && !_isEmpty( email_template.subject ) ? this.addDateToTemplate( email_template.subject, column ) : '' );
            data.email_content = ( email_template && email_template.template && !_isEmpty( email_template.template ) ? this.addDateToTemplate( email_template.template, column ) : '' );
            data.email_attachments = ( email_template && email_template.attachments && isObjectExists( email_template.attachments ) ? email_template.attachments : {} );
        } // end - email_template
        return data;
    }

    renderCheckBox = ( label, key, disabled ) => {
        return <FormControlLabel
                control={
                    <Checkbox
                        checked={( this.state.modalData[key] && this.state.modalData[key] === 'yes' ? true : false )}
                        onChange={(event) => {
                            if ( !disabled ) {
                                this.handleFormUpdate(( event.target.checked ? 'yes' : 'no' ),key);
                            } 
                        }}
                        value={this.state.modalData[key]}
                        color="primary" />
                }
                label={label} />
    }

    renderContent = () => {
        const { classes, column, columns, authData, dispatch, email_type } = this.props;
        const { modalData } = this.state;
        return (
        <div className={classes.emailModal}>
            <Typography variant="h5" style={{ marginBottom: '20px' }}>Please confirm if everything is correct:</Typography>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <FormSelect
                        label="Select a Month"
                        name="month"
                        disabled={( column && !_isEmpty( column ) ? true : false )} 
                        value={( modalData && modalData.month ? modalData.month : '' )}
                        options={getSelectOptions({
                            list: ( columns && isArrayExists( columns ) ? _sortBy( columns, ['id'] ) : false ),
                            options: [{ value: "", label: "Select an Option" }],
                            keys: { value: 'id', label: 'label' }
                        })}
                        onChange={this.handleFormUpdate} />  
                    <FormInput
                        label="Students' Emails"
                        multiline={true}
                        row={9} 
                        disabled={true} 
                        value={this.getStudentsEmails()} />
                </Grid>
                <Grid item xs={9}>
                    <div style={{ border: '2px solid #212121', marginBottom: '2px', padding: '10px 20px' }}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} sm={1}>
                                CC:
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                {this.renderCheckBox("Parent",'cc_parent')}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                {this.renderCheckBox("Principal",'cc_principle')}
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                {this.renderCheckBox("Coordinator",'cc_coordinator')}
                            </Grid>
                        </Grid>
                    </div>
                    <MediaLibrary 
                        attachable={true}
                        attachments={( modalData.email_attachments && isObjectExists( modalData.email_attachments ) ? convertObjectToArray(modalData.email_attachments) : [] )}
                        onAttachmentUpdate={(medias) => this.handleFormUpdate(medias,'email_attachments')} />
                    <FormInput label="Subject Line" name="email_subject" value={( modalData && modalData.email_subject ? modalData.email_subject : '' )} onChange={this.handleFormUpdate} />
                    <FormEditor 
                        id={( email_type || false )+"_email"}
                        name="email_content"
                        value={( modalData && modalData.email_content && !_isEmpty( modalData.email_content ) ? modalData.email_content : '' )} 
                        // disabled={true}
                        height={600}
                        onChange={this.handleFormUpdate} 
                        // filePickerTypes= 'image' 
                        // filePickerCallback={filePickerCallBack(authData,{ id: email_type },dispatch)} 
                        />
                </Grid>
            </Grid>
        </div>
        )
    }

    render() {
        const { open, email_type } = this.props;
        return (
        <>
            <ModalView 
                open={open}
                title={"Send " + ( email_type && email_type === 'invoice' ? 'Invoice Email' : 'Payment Reminder Email' )}
                maxWidth="lg"
                onClose={this.handleClose}
                disableBackdrop={true}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                actionLabel="Send Now"
                doAction={this.handleEmailSend}
                contents={this.renderContent()} />
        </>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles),
    withRouter
)(EmailSender);