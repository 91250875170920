export const partnerSchema = [
    { 
        id: 'id', 
        label: 'ID',
        required: ['update','delete'], 
        default: '', 
        type: 'string', 
        validation: 'string_id', 
        skip: ['add','update']
    },
    { 
        id: 'region', 
        label: 'Partner ID',
        required: false, 
        default: '', 
        type: 'system_data', 
        skip: ['add','update']
    },
    {   
        id: 'label',
        label: 'Partner Name',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'text',
        disabled: ['admin'],
        skip: false,
    },
    {   
        id: 'name',
        label: 'Person-in-charge',
        required: ['update'],
        default: '',
        type: 'string',
        field: 'text',
        skip: false,
    },
    {   
        id: 'designation',
        label: 'Title',
        required: ['update'],
        default: '',
        type: 'string',
        field: 'select'
    },
    {   
        id: 'gender',
        label: 'Gender',
        required: false,
        default: '',
        type: 'string',
        field: 'select'
    },
    {   
        id: 'id_num',
        label: 'IC/Passport Number',
        required: false,
        default: '',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'email',
        label: 'Email',
        required: ['add','update'],
        default: '',
        type: 'email',
        field: 'email',
        skip: false,
    },
    {   
        id: 'mobile',
        label: 'Mobile',
        required: ['update'],
        default: '',
        type: 'string',
        field: 'phone'
    },
    {   
        id: 'work_phone',
        label: 'Business WhatsApp Number',
        required: false,
        default: '',
        type: 'string',
        field: 'phone'
    },
    {   
        id: 'email_company',
        label: 'Company Email',
        required: ['update'],
        default: '',
        type: 'email',
        field: 'email',
        skip: false,
    },
    {   
        id: 'email_forwarder',
        label: 'Company Forwarder Email',
        required: false,
        default: '',
        type: 'email',
        field: 'email',
        skip: false,
    },

    // company details
    {   
        id: 'company_name',
        label: 'Company Name',
        required: ['update'],
        default: '',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'company_reg_no',
        label: 'Company Reg. No',
        required: ['update'],
        default: '',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'address',
        label: 'Address 1',
        required: ['update'],
        default: '',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'address2',
        label: 'Address 2',
        required: false,
        default: '',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'postcode',
        label: 'Postcode',
        required: ['update'],
        default: '',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'city',
        label: 'City',
        required: ['update'],
        default: '',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'state',
        label: 'State',
        required: ['update'],
        default: '',
        type: 'string',
        field: 'select'
    },
    {   
        id: 'country',
        label: 'Country',
        required: ['update'],
        default: 'MY',
        type: 'string',
        field: 'select'
    },
    {   
        id: 'merchant_key',
        label: 'Merchant Key',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        disabled: ['admin']
    },
    {   
        id: 'merchant_code',
        label: 'Merchant Code',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        disabled: ['admin']
    },
    {   
        id: 'payment_webhook_url',
        label: 'Payment Webhook - API URL',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        disabled: ['admin']
    },
    {   
        id: 'payment_webhook_key',
        label: 'Payment Webhook - Secret Key',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        disabled: ['admin']
    },
    // finance details
    {   
        id: 'finance',
        label: 'Finance',
        required: ['update'],
        default: {
            currency: 'BkY4FcjfQ', // MYR
            payee_name: '',
            bank_name: '',
            bank_account_no: '',
            payment_method: '',
            bank_swift_code: '',
            bank_address: ''
        },
        type: 'obj',
        field: 'special',
    },
    {   
        id: 'finance_currency',
        label: 'Currency',
        required: ['update'],
        default: 'BkY4FcjfQ',
        type: 'string',
        field: 'select',
        skip: ['add','update']
    },
    {   
        id: 'finance_payee_name',
        label: 'Payee Name',
        required: ['update'],
        default: '',
        type: 'string',
        field: 'text',
        skip: ['add','update']
    },
    {   
        id: 'finance_bank_account_no',
        label: 'Bank Account No',
        required: ['update'],
        default: '',
        type: 'string',
        field: 'text',
        skip: ['add','update']
    },
    {   
        id: 'finance_bank_name',
        label: 'Bank Name',
        required: ['update'],
        default: '',
        type: 'string',
        field: 'text',
        skip: ['add','update']
    },
    {   
        id: 'finance_bank_swift_code',
        label: 'SWIFT Code',
        required: ['update'],
        default: '',
        type: 'string',
        field: 'text',
        skip: ['add','update']
    },
    {   
        id: 'finance_bank_address',
        label: 'Bank Address',
        required: ['update'],
        default: '',
        type: 'string',
        field: 'textarea',
        skip: ['add','update']
    },

    {   
        id: 'created_on',
        label: 'Created On',
        required: false,
        default: 0,
        type: 'system_date',
        skip: ['update']
    },
    {   
        id: 'modified_on',
        label: 'Modified On',
        required: false,
        default: 0,
        type: 'system_date'
    },
    {   
        id: 'last_modified_by',
        label: 'Modified By',
        required: false,
        default: '',
        type: 'system_modified_by'
    }
];