/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _snakeCase from 'lodash/snakeCase';
import _find from 'lodash/find';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists, validateEmail, isObjectExists } from '../../helpers/validation';
import { getSnapshotDataBySchema, getDocDataBySchema } from '../../helpers/schemas';

import { courseSchema } from '../../schemas/course';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getCourses = (props) => {
    return new Promise((resolve,reject) => {

        firebase.firestore().collection("courses").get()
        .then(snapshot => {
            let list = [];
            if ( snapshot ) {
                snapshot.forEach(doc => {
                    let item = getDocDataBySchema( courseSchema, doc );
                    list.push( item );
                });
            } // end - snapshot
            resolve(list);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getCoursesOptions = (callback,props = {}) => {
    return new Promise((resolve,reject) => {
        const { region } = props;
        let Ref = firebase.firestore().collection("courses");

        if ( region && !_isEmpty( region ) )
            Ref = Ref.where("course_visibility_ids","array-contains-any",[region]);

        Ref.onSnapshot(snapshot => {
            const list = [];
            if ( snapshot ) {
                snapshot.forEach( doc => {
                    let item = getDocDataBySchema( courseSchema, doc );
                    list.push( item );
                });
            } // end - snapshot
            callback(list);
        });

    })
}

export const fb_getProductsOptions = (callback,props = {}) => {
    return new Promise((resolve,reject) => {
        const { region } = props;
        let Ref = firebase.firestore().collection("courses").where("is_product",'==','yes');

        Ref.onSnapshot(snapshot => {
            const list = [];
            if ( snapshot ) {
                snapshot.forEach( doc => {
                    let item = getDocDataBySchema( courseSchema, doc );
                    list.push( item );
                });
            } // end - snapshot
            callback(list);
        });

    })
}


export const fb_getCourse = (id) => {
    return new Promise((resolve,reject) => {

        let promises = [];
        promises.push( firebase.firestore().collection("courses").doc(id).get() );
        
        Promise.all( promises )
        .then(results => {
            let item = {};
            if ( results && results[0] ) {
                item = getDocDataBySchema( courseSchema, results[0] );
            } // end - results[0]
            resolve(item);
        })
        .catch(error => {
            reject(error);
        });
    })
}

export const fb_addCourse = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'course', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateCourse = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'course', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteCourse = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'course', action: 'delete', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}