/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	DATABASE_MARKETING_LIST,
	DATABASE_MARKETING_ADD,
	DATABASE_MARKETING_UPDATE,
	DATABASE_MARKETING_DELETE,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getMarketingList, fb_addMarketingData, fb_updateMarketingData, fb_deleteMarketingData, fb_importMarketingData } from './fb';


export const getMarketingsList = (props) => {
	return dispatch => {
		fb_getMarketingList(list => {
			dispatch({ 
                type: DATABASE_MARKETING_LIST, 
                payload: { list } 
            });
		},props);
	}
}

export const addMarketingData = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addMarketingData(formData)
		.then( results => {
			// updated
			dispatch({ type: DATABASE_MARKETING_ADD });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Marketing Data added");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const updateMarketingData = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_updateMarketingData(formData)
		.then( results => {
			// updated
			dispatch({ type: DATABASE_MARKETING_UPDATE });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Marketing Data Updated");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const deleteMarketingData = (id) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteMarketingData(id)
		.then( results => {
			dispatch({ type: DATABASE_MARKETING_DELETE });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Marketing Data Deleted!");
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const importMarketingData = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleLoader(true));
		dispatch(toggleModalProcessing(true,apiNum));

		fb_importMarketingData(formData)
		.then( results => {
			// updated
			dispatch({ type: DATABASE_MARKETING_UPDATE });
			dispatch(toggleLoader(false));
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Data Imported!");
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}