import _random from 'lodash/random';

import { 
    GET_MEDIA_LIBRARY,
    UPLOAD_NEW_MEDIA,
    DELETE_MEDIA
} from '../actions/types';

const initialState = {
    list: null,
    rand: false
};

export const media_library = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_MEDIA_LIBRARY:
            return {
                ...state,
                list: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case UPLOAD_NEW_MEDIA:
        case DELETE_MEDIA:
            return {
                ...state,
                rand: _random(1,9999)
            };
        default:
            return state;
    }
}