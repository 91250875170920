/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _remove from "lodash/remove";
import _isEqual from "lodash/isEqual";
import _startsWith from "lodash/startsWith";
import _replace from "lodash/replace";

import AppWrapper from "../../components/AppWrapper";
import DotsLoader from "../../components/DotsLoader";
import SnackBarSave from "../../components/SnackBarSave";

import EditForm from "./form";
import ItemNotFound from "../../components/ItemNotFound";

import { InfoButton, GreyButton } from "../../styles/button";

import { hasAccessRights } from "../../helpers/auth";
import { isArrayExists, isObjectExists } from "../../helpers/validation";
import { cloneCollections } from "../../helpers/data";
import { triggerErrorAlert } from "../../helpers/alert";
import { doSchemaErrorCheck } from "../../helpers/schemas";

import { getPersonnel, editPersonnel, triggerGrantPersonnelAccess, triggerRevokePersonnelAccess } from "../../actions/personnels";
import { getMaintenance } from "../../actions/maintenance";
import { getRegions } from "../../actions/m_regions";
import { getRoles } from "../../actions/m_roles";
import { appChangesMade, resetRedux } from "../../actions/misc";

import { userPartnerSchema } from "../../schemas/user_partner";

class PersonnelPage extends React.Component {
    state = {
        currentTab: "details",
        personnel: false,
        randNum: false,
    };

    componentDidMount() {
        const { designationList, currencyList, regionsList, rolesList } = this.props;
        const { personnel_id, partner_id } = this.props.match.params;

        // retrieve personnel details
        this.props.dispatch(getPersonnel(personnel_id));

        // get designation
        if (!designationList) this.props.dispatch(getMaintenance("designation"));

        // get currency
        if (!currencyList) this.props.dispatch(getMaintenance("currency"));

        // get regions
        if (!regionsList) {
            this.props.dispatch(getRegions());
        }
        // get roles
        if (!rolesList) {
            this.props.dispatch(getRoles());
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentPersonnel, randNum, designationList, currencyList, regionsList, rolesList } = this.props;

        // for personnel
        if (currentPersonnel && designationList && currencyList && regionsList && rolesList && randNum && randNum !== this.state.randNum) {
            this.setState({ personnel: cloneCollections(currentPersonnel), randNum, mailingListMessages: [] });
        } // end - currentPersonnel
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux("personnel"));
    }

    handleRefresh = (type) => {
        // const { personnel_id } = this.props.match.params;
        // switch( type ) {
        //     case 'emails':
        //         this.setState({ emails: false });
        //         this.props.dispatch(getEmailsBy('personnels',personnel_id));
        //         break;
        // }
    };

    handleGrantPersonnelAccess = (formData) => {
        this.props.dispatch(triggerGrantPersonnelAccess(formData));
    };

    handleRevokePersonnelAccess = (formData) => {
        this.props.dispatch(triggerRevokePersonnelAccess(formData));
    };

    handleSaveChanges = (event) => {
        const { personnel } = this.state;
        event.preventDefault();
        var error = false,
            formData = {};

        // do error check
        userPartnerSchema.forEach((schema) => {
            formData[schema.id] = personnel && personnel[schema.id] ? cloneCollections(personnel[schema.id]) : schema.default;
            if (!doSchemaErrorCheck(formData[schema.id], schema, "update")) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if (error) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editPersonnel(formData));
        } // end - error
    };

    handleFormUpdate = (newValue) => {
        this.setState({ personnel: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    };

    handleTabChange = (newTab) => {
        this.setState({ currentTab: newTab });
    };

    // getLinks = () => {
    //     const { authData } = this.props;
    //     let links = [
    //         { id: 'details', label: 'Licensee Info', icon: 'fa fa-cog' },
    //         { id: 'personal', label: 'Personal Database', icon: 'fa fa-users' }
    //     ];

    //     return links;
    // }

    renderTabContents = () => {
        const { personnel_id, partner_id } = this.props.match.params;
        const { authData, currentPersonnel, designationList, currencyList, regionsList, rolesList, changesMade } = this.props;
        const { currentTab, personnel } = this.state;
        const tabProps = {
            authData: authData || false,
            personnel: personnel || false,
            changesMade: changesMade || false,
            onFormUpdate: this.handleFormUpdate,
        };
        switch (currentTab) {
            case "details":
                return (
                    <EditForm
                        partner_id={partner_id}
                        personnel_id={personnel_id}
                        currentPersonnel={currentPersonnel || false}
                        designations={designationList || false}
                        currency={currencyList || false}
                        regions={regionsList || false}
                        roles={rolesList || false}
                        updateActions={[getPersonnel(personnel_id)]}
                        onGrantPersonnelAccess={this.handleGrantPersonnelAccess}
                        onRevokePersonnelAccess={this.handleRevokePersonnelAccess}
                        {...tabProps}
                    />
                );
            // case 'emails':
            //     return ( emails ? <EmailsList
            //         type="personnels"
            //         typeID={( personnel_id || false )}
            //         changesMade={changesMade}
            //         onRefresh={this.handleRefresh}
            //         {...tabProps} /> : <DotsLoader /> );
        }
    };

    // renderTabs = () => {
    //     const { currentTab } = this.state;
    //     const btnStyle = {
    //         textAlign: 'center',
    //         minWidth: '225px',
    //         padding: "10px 5px",
    //         marginRight: "5px"
    //     };
    //     return (
    //     <Box display="flex" justifyContent="center" alignItems="center">
    //         {this.getLinks().map((button,index) => {
    //             if ( button.id === currentTab) {
    //                 return <InfoButton key={index} style={btnStyle} onClick={event => event.preventDefault()}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</InfoButton>
    //             } else {
    //                 return <GreyButton key={index} style={btnStyle} onClick={this.handleTabChange.bind(this,button.id)}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</GreyButton>
    //             }
    //         })}
    //     </Box>
    //     )
    // }

    renderContents() {
        return (
            <div>
                {/* {this.renderTabs()} */}

                <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                    {this.renderTabContents()}
                </Paper>

                <div style={{ textAlign: "center", marginTop: "45px" }}>
                    <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}>
                        <i className="fa fa-save"></i>Save Changes
                    </InfoButton>
                </div>

                <SnackBarSave onSave={this.handleSaveChanges} />
            </div>
        );
    }

    render() {
        const { partner_id } = this.props.match.params;
        const { randNum } = this.state;
        const { currentPersonnel } = this.props;
        return (
            <AppWrapper
                title="Edit SMS User"
                subtitle="SMS USers"
                back={"/partner/" + partner_id + "/personnel"}
                breadcrumbs={[{ url: "/partner/" + partner_id + "/personnel", label: "SMS USers" }, { label: "Edit" }]}
                maxWidth="1110px"
                onLoad={!randNum ? true : false}
                contents={
                    currentPersonnel && currentPersonnel.uid && !_isEmpty(currentPersonnel.uid) ? (
                        this.renderContents()
                    ) : (
                        <ItemNotFound item="Personnel" />
                    )
                }
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: (state.auth && state.auth.user) || null,
        currentPersonnel: (state.personnels && state.personnels.personnel) || null,
        randNum: (state.personnels && state.personnels.rand) || null,
        designationList: (state.maintenance && state.maintenance.designations) || null,
        currencyList: (state.maintenance && state.maintenance.currency) || null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        rolesList: state.maintenance && state.maintenance.roles ? state.maintenance.roles : false,
        changesMade: (state.misc && state.misc.changes_made) || null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(PersonnelPage);
