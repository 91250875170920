/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";
import _sortBy from "lodash/sortBy";
import _filter from "lodash/filter";
import _split from "lodash/split";
import _forEach from "lodash/forEach";
import _toString from "lodash/toString";
import _reverse from "lodash/reverse";

import ParentObserver from "./parent_observer";
import LinkedAccounts from "../DatabaseStudentPage/linked_accounts";
import StudentRegionTransfer from "./student_region_transfer";
import StudentAccountTransfer from "./student_account_transfer";
import StudentAccountTransferPartner from "./student_account_transfer_partner";
import ExternalAssetsAccess from "../../modules/ExternalAssetsAccess";
import StudentLMSPassword from "./student_lms_password";
import StudentBackpackTransfer from "./student_backpack_transfer";
import StudentBackpackTransferPartner from "./student_backpack_transfer_partner";

import StudentSyncData from "../../modules/StudentSyncData";
import SchoolSelector from "../../modules/SchoolSelector";

import Table from "../../components/Table";
import ModalView from "../../components/ModalView";
import ModelDelete from "../../components/ModalDelete";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormMultiSelect from "../../components/FormMultiSelect";
import FormDatePicker from "../../components/FormDatePicker";
import FormPhoneField from "../../components/FormPhoneField";
import FormDateOfBirth from "../../components/FormDateOfBirth";

import { FormBox } from "../../styles/form";
import { InfoButton, ButtonGroup, AInfoLink, ASuccessLink, ErrorButton, AInverseLink } from "../../styles/button";
import { WrapWord } from "../../styles/misc";
import { InfoBox, WarningBox } from "../../styles/message";

import { isAdmin, hasAccessRights } from "../../helpers/auth";
import { isArrayExists } from "../../helpers/validation";
import { cloneCollections, getSelectOptions, getSelectValues, getSelectedValue } from "../../helpers/data";
import { getMomentTime, getDateNumFromString, isParentFieldRequired } from "../../helpers/date";
import { isSchemaRequired } from "../../helpers/schemas";
import { stringToInteger } from "../../helpers/number";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";

import { deleteStudentBadge, addParentObverser, removeParentObverser } from "../../actions/database_students";

import { userStudentSchema } from "../../schemas/user_student";
import { COUNTRIES_LIST } from "../../data/const_countries";
import { STATE_OPTIONS } from "../../data/const_states";

import {
    YES_NO_OPTIONS,
    GENDER_OPTIONS,
    PLATFORM_CERT_URL,
    PLATFORM_BADGE_URL,
    ENROLLMENT_STATUS_OPTIONS,
    PLATFORM_BACKPACK_URL,
} from "../../constants";

const ConditionWrapper = styled.ul`
    font-size: 12px;
    li {
        display: block;
        margin-bottom: 10px;
        span {
            margin-right: 5px;
        }
        i {
            &.fa-check {
                color: ${green[200]};
            }
            &.fa-times {
                color: ${red[200]};
            }
        }
    }
`;

const HeaderWrapper = styled.div`
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
`;

const useStyles = (theme) => ({
    boxheading: {
        fontSize: "20px",
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd",
    },
    formheader: {
        fontSize: "20px",
        fontWeight: "700",
        color: theme.palette.background,
    },
});

class StudentEditForm extends React.Component {
    state = {
        openBackpackTransferModal: false,
        openTransferNotesModal: false,
        openDeleteModal: false,
        deleteModal: false,
        randNum: false,
    };

    handleBackpackTransfer = (formData) => {
        this.props.onBackpackTransfer(formData);
    };

    handleBadgeDelete = () => {
        const { student } = this.props;
        const { deleteModal } = this.state;
        this.props.dispatch(deleteStudentBadge(deleteModal.id, student.uid));
    };

    handleFormUpdate = (newValue, key, subkey, subVal) => {
        const { onFormUpdate, student, currentStudent } = this.props;
        let newData = student && !_isEmpty(student) ? cloneCollections(student) : {};
        switch (key) {
            case "date_of_birth_string":
                newData[key] = newValue;
                newData["date_of_birth"] = subVal;
                break;
            default:
                newData[key] = newValue;
                break;
        }

        // do update
        if (onFormUpdate) onFormUpdate(newData);
    };

    getBadges = () => {
        const { student } = this.props;
        let badges = student && student._data && student._data.badges && isArrayExists(student._data.badges) ? [...student._data.badges] : [];
        // add in pending badges
        if (student && student._data && student._data.pending_badges && isArrayExists(student._data.pending_badges)) {
            badges = [...badges, ...student._data.pending_badges];
        }
        return badges;
    };

    getPaymentData = () => {
        const { student, schedules } = this.props;
        let payment = [];
        if (student && student._data && student._data.trainees && isArrayExists(student._data.trainees)) {
            let trainees = _sortBy(cloneCollections(student._data.trainees), ["confirmation_email_date"]);
            _forEach(trainees, (trainee) => {
                let schedule = schedules && isArrayExists(schedules) ? _find(schedules, { id: trainee.schedule_id }) : false;
                if (schedule) {
                    payment.push({
                        id: schedule.id,
                        class_name: schedule.class_name || "",
                        payment_tracking: trainee.payment_tracking && isArrayExists(trainee.payment_tracking) ? [...trainee.payment_tracking] : [],
                    });
                }
            });
        } // end - student
        return _sortBy(payment, [(p) => p.class_name.toLowerCase()]);
    };

    getCourseEnrollments = () => {
        const { student, lms_courses, schedules } = this.props;
        let enrollments = [],
            studentTrainees =
                student && student._data && student._data.trainees && isArrayExists(student._data.trainees)
                    ? _filter(student._data.trainees, (t) => t.enrollment_status && t.enrollment_status !== "delete")
                    : [];
        if (studentTrainees && isArrayExists(studentTrainees) && lms_courses && isArrayExists(lms_courses)) {
            let trainees = _sortBy(cloneCollections(studentTrainees), ["confirmation_email_date"]);
            trainees = _reverse(trainees);
            _forEach(trainees, (trainee) => {
                let schedule = schedules && isArrayExists(schedules) ? _find(schedules, { id: trainee.schedule_id }) : false;
                if (trainee.course_access && isArrayExists(trainee.course_access)) {
                    _forEach(trainee.course_access, (course_access) => {
                        let lms_course = _find(lms_courses, (c) => _toString(c.id) === _toString(course_access.id));
                        if (lms_course && !_find(enrollments, (e) => _toString(e.id) === _toString(lms_course.id))) {
                            enrollments.push({
                                id: lms_course.id,
                                name: lms_course.name || "",
                                status: course_access.status || "",
                                activated: course_access.activated || 0,
                                deactivated: course_access.deactivated || 0,
                                concluded: course_access.concluded || 0,
                                course_code: course_access.course_code || "",
                                set_deactivation: course_access.set_deactivation || "",
                                schedule_id: trainee.schedule_id || "",
                                class_name: (schedule && schedule.class_name) || "",
                            });
                        } // end - lms_course
                    });
                } // end - trainee.course_access
            });
        } // end - student
        return _sortBy(enrollments, ["activated"]);
    };

    getClassEnrollments = () => {
        const { student, lms_courses, schedules } = this.props;
        let classes = [];
        if (student && student._data && student._data.trainees && isArrayExists(student._data.trainees)) {
            _forEach(student._data.trainees, (trainee) => {
                const schedule = schedules && isArrayExists(schedules) ? _find(schedules, { id: trainee.schedule_id }) : false;
                let item = {
                    id: trainee.schedule_id,
                    class_name: (schedule && schedule.class_name) || "",
                    status: trainee.enrollment_status || "",
                    active_courses: [],
                    inactive_courses: [],
                    concluded_courses: [],
                };

                // make sure it's not deleted
                if (trainee.course_access && isArrayExists(trainee.course_access) && trainee.enrollment_status !== "delete") {
                    _forEach(trainee.course_access, (course_access) => {
                        const lms_course = _find(lms_courses, (c) => _toString(c.id) === _toString(course_access.id));
                        if (course_access.id && course_access.id !== "3" && lms_course) {
                            if (course_access.status === "active") {
                                item.active_courses.push({
                                    id: lms_course.id,
                                    name: lms_course.name || "",
                                });
                            }
                            if (course_access.status === "inactivate") {
                                item.inactive_courses.push({
                                    id: lms_course.id,
                                    name: lms_course.name || "",
                                });
                            }
                            if (course_access.status === "conclude") {
                                item.concluded_courses.push({
                                    id: lms_course.id,
                                    name: lms_course.name || "",
                                });
                            }
                        }
                    });
                } // end - trainee.course_access

                // get status
                if (item.active_courses.length > 0) item.status = "Active";

                if (!(item.active_courses.length > 0) && item.inactive_courses.length > 0 && !(item.concluded_courses.length > 0))
                    item.status = "Inactive";

                if (!(item.active_courses.length > 0) && item.concluded_courses.length > 0) item.status = "Completed";

                classes.push(item);
            });
        } // end - student

        return classes;
    };

    getMentorEnrollments() {
        const { student, lms_courses, schedules } = this.props;
        let classes = [];
        if (student && student.mentor_enrollments && isArrayExists(student.mentor_enrollments)) {
            student.mentor_enrollments.forEach((enrollment) => {
                if (enrollment && enrollment.schedule_id && !_isEmpty(enrollment.schedule_id) && !_find(classes, { id: enrollment.schedule_id })) {
                    const schedule = schedules && isArrayExists(schedules) ? _find(schedules, { id: enrollment.schedule_id }) : false;
                    if (schedule && schedule.mentors && isArrayExists(schedule.mentors) && _find(schedule.mentors, { id: student.email })) {
                        classes.push({
                            ...schedule,
                        });
                    }
                }
            });
        }
        return classes;
    }

    getFieldOptions = (field) => {
        const { student, designations, currency, regions, roles } = this.props;

        switch (field.name) {
            case "region":
                return getSelectOptions({
                    list: regions || false,
                    options: [{ value: "", label: "Select an Option" }],
                    keys: { value: "id", label: "label" },
                });
            case "role":
                return [
                    { value: "", label: "Select an Option" },
                    { value: "student", label: "Student" },
                    { value: "mentor", label: "Mentor" },
                ];
            case "gender":
                return GENDER_OPTIONS;
            default:
                return [];
        }
    };

    getFieldValue = (field) => {
        const { student } = this.props;
        switch (field.name) {
            default:
                return student && student[field.name] ? student[field.name] : field.default || "";
        }
    };

    requiredParentDetails = (key) => {
        const { student } = this.props;
        let required = false;
        // switch (key) {
        //     case 'parent_name':
        //     case 'parent_email':
        //     case 'parent_phone':
        //     case 'parent_city':
        //         required = ( student && student.date_of_birth_string && !_isEmpty( student.date_of_birth_string ) && isParentFieldRequired( student.date_of_birth_string ) ? true : false );
        //         break;
        // }
        return required;
    };

    getTooltip = (schema) => {
        switch (schema.id) {
            case "parent_email":
                return (
                    <>
                        ALLOW PARENT TO ACCESS LMS
                        <br />
                        We allow parents to observe their child’s learning progress on LMS. Upon confirmation of Program, parent should receive an
                        email with password to access LMS with the Observer role.
                    </>
                );
            case "teacher_email":
                return (
                    <>
                        ALLOW TEACHER TO ACCESS LMS
                        <br />
                        We allow teacher to observe their student’s learning progress on LMS. Upon confirmation of Program, teacher should receive an
                        email with password to access LMS with the Observer role.
                    </>
                );
            case "about_me":
                return <>This will reflect in your Backpack which public can view.</>;
            case "eportfolio":
                return (
                    <>
                        A e-portfolio is a digital resume of past projects, showcasing student’s accomplishments, skills, and capabilities.
                        <br />
                        <br />
                        This will reflect in your Backpack which public can view.
                    </>
                );
            default:
                return;
        }
    };

    isFieldDisabled = (schema) => {
        const { authData, student, formType } = this.props;
        var disabled = false;

        if (schema && schema.disabled && isArrayExists(schema.disabled)) {
            schema.disabled.forEach((condition) => {
                switch (condition) {
                    case "admin":
                        disabled = true;
                        if (isAdmin(authData)) {
                            disabled = false;
                            // (for student database only) make sure this student doesn't have any active courses - before it can be change
                            if (
                                schema.id === "region"
                                // && formType && formType === 'student'
                            ) {
                                // use the button trigger instead
                                disabled = true;
                                // old way of implementation
                                // if ( student && student._data && student._data.trainees && isArrayExists( student._data.trainees ) ) {
                                //     _forEach( student._data.trainees, trainee => {
                                //         // check if any course is active
                                //         if ( trainee.course_access && isArrayExists( trainee.course_access ) ) {
                                //             _forEach( trainee.course_access, course => {
                                //                 if ( course && course.status && course.status === 'active' ) {
                                //                     disabled = true;
                                //                 } // end - course
                                //             });
                                //         } // end - trainee.course_access
                                //     });
                                // } // end - student
                            } // end - schema.id
                        }
                        break;
                    case "update":
                        disabled = true;
                        break;
                    case "self":
                        // special condition for status - disable it if it's the current user
                        if (authData && student && authData.uid === student.uid) {
                            disabled = true;
                        } // end - authData
                        break;
                }
            });
        } // end - schema

        // special condition for parent_email
        if ("parent_email" === schema.id) {
            if (
                student &&
                student.observer_email &&
                !_isEmpty(student.observer_email) &&
                student.parent_email &&
                student.parent_email === student.observer_email
            ) {
                disabled = true;
            }
        }

        return disabled;
    };

    getField = (id) => {
        const { massEditMode } = this.props;
        let schema = _find(userStudentSchema, { id });
        return schema
            ? {
                  name: schema.id || "",
                  label:
                      (schema.label || "") +
                      ((this.requiredParentDetails(schema.id) || isSchemaRequired(schema, "update")) && !massEditMode ? " (Required)" : ""),
                  field_type: schema.field || "",
                  default: massEditMode ? null : schema.default || null,
                  disabled: schema.disabled || "parent_email" === schema.id ? this.isFieldDisabled(schema) : false,
                  tooltip: this.getTooltip(schema),
              }
            : null;
    };

    renderField = (id) => {
        let field = this.getField(id);
        if (field && field.field_type && !_isEmpty(field.field_type)) {
            switch (field.field_type) {
                case "text":
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case "textarea":
                    return (
                        <FormInput
                            {...field}
                            rows={"notes" === id ? 6 : 3}
                            multiline={true}
                            value={this.getFieldValue(field)}
                            onChange={this.handleFormUpdate}
                        />
                    );
                case "text_number":
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case "email":
                    return <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case "select":
                    return (
                        <div style={{ paddingTop: "7px" }}>
                            <FormSelect
                                {...field}
                                value={this.getFieldValue(field)}
                                options={this.getFieldOptions(field)}
                                onChange={this.handleFormUpdate}
                            />
                        </div>
                    );
                case "multiselect":
                    return (
                        <div style={{ paddingTop: "7px" }}>
                            <FormMultiSelect
                                {...field}
                                value={this.getFieldValue(field)}
                                options={this.getFieldOptions(field)}
                                onChange={this.handleFormUpdate}
                            />
                        </div>
                    );
                case "datepicker":
                    return <FormDatePicker {...field} noDefaultVal={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case "phone":
                    return <FormPhoneField {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case "select_date":
                    return <FormDateOfBirth {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
            }
        } // end - field.field_type
    };

    renderCreatedModifiedDate = (item) => {
        let created_on = item.created_on && item.created_on._seconds ? item.created_on._seconds * 1000 : item.created_on || null,
            modified_on = item.modified_on && item.modified_on._seconds ? item.modified_on._seconds * 1000 : item.modified_on || null;
        return (
            <div style={{ paddingTop: "10px", textAlign: "right", color: "#999", fontSize: "1.25rem" }}>
                <div>{created_on ? "Created on " + getMomentTime(created_on, "YYYY-MM-DD hh:mm:ssa") : ""}</div>
                <div>{modified_on ? "Last Modified on " + getMomentTime(modified_on, "YYYY-MM-DD hh:mm:ssa") : ""}</div>
            </div>
        );
    };

    render = () => {
        const {
            classes,
            student,
            schedules,
            regions,
            usersOptions,
            disableSystemDate,
            massEditMode,
            authData,
            formType,
            changesMade,
            history,
            onRegionTransfer,
            onAccountTransfer,
            onLMSPasswordChange = () => {},
            onGrantMentorAccess = () => {},
            onRevokeMentorAccess = () => {},
        } = this.props;
        const { openDeleteModal, deleteModal } = this.state;
        return (
            <FormBox>
                <HeaderWrapper>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h4" className={classes.formheader}>
                                Student Details
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} align="right">
                            <StudentSyncData student={student} />
                        </Grid>
                    </Grid>
                </HeaderWrapper>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        {this.renderField("region")}
                        {isAdmin(authData) ? (
                            <StudentRegionTransfer
                                student={student}
                                schedules={schedules}
                                enrollments={this.getCourseEnrollments()}
                                onTrigger={onRegionTransfer}
                            />
                        ) : (
                            <div style={{ padding: "10px 0" }}>
                                <InfoButton
                                    size="small"
                                    style={{ padding: "4px 6px" }}
                                    onClick={() =>
                                        this.setState({
                                            openTransferNotesModal: true,
                                        })
                                    }
                                >
                                    <i className="fa fa-random"></i>Change Partner
                                </InfoButton>
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {this.renderField("role")}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderField("name")}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderField("email")}
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <StudentLMSPassword student={student} onTrigger={onLMSPasswordChange} />
                            </Grid>
                            {isAdmin(authData) ? (
                                <Grid item xs={12} sm={6}>
                                    <StudentAccountTransfer student={student} onTrigger={onAccountTransfer} />
                                </Grid>
                            ) : (
                                <Grid item xs={12} sm={6}>
                                    <StudentAccountTransferPartner authData={authData} regions={regions} student={student} />
                                </Grid>
                            )}
                        </Grid>
                        <div>
                            {student.email_change_history && isArrayExists(student.email_change_history) ? (
                                <WarningBox>
                                    <h4 style={{ fontWeight: "700" }}>Email Change History</h4>
                                    {_reverse(_sortBy(student.email_change_history, ["changed_on"])).map((item) => {
                                        return (
                                            <div>
                                                Changed on {getMomentTime(item.changed_on, "YYYY-MM-DD hh:mm:ssa")} from {item.original_email || ""}{" "}
                                                to {item.new_email || ""}
                                            </div>
                                        );
                                    })}
                                </WarningBox>
                            ) : null}
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderField("date_of_birth_string")}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {this.renderField("gender")}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {this.renderField("phone")}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SchoolSelector predefinedState={null} value={student.org || ""} field={"org"} onChange={this.handleFormUpdate} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {this.renderField("city")}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        {this.renderField("about_me")}
                    </Grid>

                    <Grid item xs={12}>
                        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography variant="h4" className={classes.boxheading}>
                            Parent / Guardian Details
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {this.renderField("parent_name")}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={8}>
                                        {this.renderField("parent_email")}
                                    </Grid>
                                    <Grid item xs={2} style={{ textAlign: "center" }}>
                                        <AInverseLink
                                            target="_blank"
                                            href={`mailto:${student.parent_email || ""}`}
                                            style={{ padding: "10px 0px 5px 10px", marginTop: "5px" }}
                                        >
                                            <i className="fa fa-envelope" style={{ fontSize: "24px" }}></i>
                                        </AInverseLink>
                                    </Grid>
                                    <Grid item xs={2} align="center">
                                        <ParentObserver
                                            type="parent"
                                            student={student}
                                            authData={authData}
                                            onAddObserver={(student) => {
                                                this.props.dispatch(addParentObverser({ uid: student.uid, type: "parent" }));
                                            }}
                                            onRemoveObserver={(student) => {
                                                this.props.dispatch(removeParentObverser({ uid: student.uid, type: "parent" }));
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={10}>
                                        {this.renderField("parent_phone")}
                                    </Grid>
                                    <Grid item xs={2} style={{ textAlign: "right" }}>
                                        <AInverseLink
                                            target="_blank"
                                            href={`https://api.whatsapp.com/send/?phone=${
                                                student.parent_phone && !_isEmpty(student.parent_phone) ? student.parent_phone.replace("+", "") : ""
                                            }`}
                                            style={{ padding: "10px 0px 10px 5px", marginTop: "15px" }}
                                        >
                                            <i className="fa fa-whatsapp" style={{ fontSize: "30px" }}></i>
                                        </AInverseLink>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderField("parent_city")}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography variant="h4" className={classes.boxheading}>
                            Teacher Details
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {this.renderField("teacher_name")}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={8}>
                                        {this.renderField("teacher_email")}
                                    </Grid>
                                    <Grid item xs={2} style={{ textAlign: "center" }}>
                                        <AInverseLink
                                            target="_blank"
                                            href={`mailto:${student.teacher_email || ""}`}
                                            style={{ padding: "10px 0px 10px 5px", marginTop: "5px" }}
                                        >
                                            <i className="fa fa-envelope" style={{ fontSize: "24px" }}></i>
                                        </AInverseLink>
                                    </Grid>
                                    <Grid item xs={2} align="center">
                                        <ParentObserver
                                            type="teacher"
                                            student={student}
                                            authData={authData}
                                            onAddObserver={(student) => {
                                                this.props.dispatch(addParentObverser({ uid: student.uid, type: "teacher" }));
                                            }}
                                            onRemoveObserver={(student) => {
                                                this.props.dispatch(removeParentObverser({ uid: student.uid, type: "teacher" }));
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={10}>
                                        {this.renderField("teacher_phone")}
                                    </Grid>
                                    <Grid item xs={2} style={{ textAlign: "right" }}>
                                        <AInverseLink
                                            target="_blank"
                                            href={`https://api.whatsapp.com/send/?phone=${
                                                student.teacher_phone && !_isEmpty(student.teacher_phone)
                                                    ? student.teacher_phone.replace("+", "")
                                                    : ""
                                            }`}
                                            style={{ padding: "10px 0px 10px 5px", marginTop: "15px" }}
                                        >
                                            <i className="fa fa-whatsapp" style={{ fontSize: "30px" }}></i>
                                        </AInverseLink>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderField("teacher_org")}
                            </Grid>
                        </Grid>
                    </Grid>

                    <ExternalAssetsAccess
                        authData={authData}
                        user={student}
                        onGrantMentorAccess={(formData) => onGrantMentorAccess(formData)}
                        onRevokeMentorAccess={(formData) => onRevokeMentorAccess(formData)}
                    />

                    <Grid item xs={12}>
                        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                    </Grid>

                    <LinkedAccounts
                        changesMade={changesMade}
                        student={student}
                        authData={authData}
                        unlinkBadgr={() => {
                            const { onFormUpdate, student, currentStudent } = this.props;
                            let newData = student && !_isEmpty(student) ? cloneCollections(student) : {};
                            newData["badgr_entity_type"] = "";
                            newData["badgr_entity_id"] = "";
                            newData["badgr_emails"] = [];

                            // do update
                            if (onFormUpdate) onFormUpdate(newData);
                        }}
                    />

                    <Grid item xs={12}>
                        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                    </Grid>

                    {student && student.role && student.role === "mentor" && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h4" className={classes.boxheading}>
                                    Mentor Enrollments
                                </Typography>
                                <Table
                                    items={this.getMentorEnrollments()}
                                    showCheckbox={false}
                                    emptyCell="No class enrollment Found."
                                    cells={[
                                        {
                                            id: "class_name",
                                            label: "Class Name",
                                            render: (item) => (
                                                <WrapWord>
                                                    <a
                                                        href={"/r/" + (item.id && !_isEmpty(item.id) ? item.id : null) + "?location=course_access"}
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            history.push("/course_access", {
                                                                schedule_id: item.id && !_isEmpty(item.id) ? item.id : null,
                                                            });
                                                        }}
                                                    >
                                                        {item.class_name || ""}
                                                    </a>
                                                </WrapWord>
                                            ),
                                        },
                                        {
                                            id: "principle",
                                            label: "Principal",
                                            render: (item) => {
                                                return getSelectedValue(usersOptions, item["principle"], "email", "name", "-");
                                            },
                                        },
                                        {
                                            id: "coordinator",
                                            label: "Coordinator",
                                            render: (item) => {
                                                return getSelectedValue(usersOptions, item["coordinator"], "email", "name", "-");
                                            },
                                        },
                                        {
                                            id: "current_active_class_size",
                                            label: <>{"Current Active Class Size"}</>,
                                            render: (item) => item.total_registered || 0,
                                        },
                                        {
                                            id: "whole_class_status",
                                            label: <>{"Whole Class Status"}</>,
                                            render: (item) => (item.total_registered ? "Active" : "Inactive"),
                                        },
                                    ]}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.boxheading}>
                            Class Enrollments
                        </Typography>
                        <Table
                            items={this.getClassEnrollments()}
                            showCheckbox={false}
                            emptyCell="No class enrollment Found."
                            cells={[
                                {
                                    id: "class_name",
                                    label: "Class Name",
                                    render: (item) => (
                                        <WrapWord>
                                            <a
                                                href={"/r/" + (item.id && !_isEmpty(item.id) ? item.id : null) + "?location=course_access"}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    history.push("/course_access", { schedule_id: item.id && !_isEmpty(item.id) ? item.id : null });
                                                }}
                                            >
                                                {item.class_name || ""}
                                            </a>
                                        </WrapWord>
                                    ),
                                },
                                {
                                    id: "status",
                                    label: "Class Status",
                                    render: (item) => (
                                        <WrapWord style={{ fontSize: "12px" }}>
                                            {getSelectedValue(
                                                ENROLLMENT_STATUS_OPTIONS,
                                                item.status,
                                                "value",
                                                "label",
                                                item.status && !_isEmpty(item.status) ? item.status : "-"
                                            )}
                                        </WrapWord>
                                    ),
                                },
                                {
                                    id: "active_courses_num",
                                    label: "# Active Courses",
                                    render: (item) => (
                                        <WrapWord>
                                            {item.active_courses && isArrayExists(item.active_courses) ? item.active_courses.length : "0"}
                                        </WrapWord>
                                    ),
                                },
                                {
                                    id: "active_courses",
                                    label: "Active Courses",
                                    render: (item) => {
                                        return item.active_courses && isArrayExists(item.active_courses)
                                            ? item.active_courses.map((course, index) => {
                                                  return (
                                                      <div key={course.id} style={{ fontSize: "12px" }}>
                                                          {course.name}
                                                      </div>
                                                  );
                                              })
                                            : "-";
                                    },
                                },
                                {
                                    id: "inactive_courses_num",
                                    label: "# Inactive Courses",
                                    render: (item) => (
                                        <WrapWord>
                                            {item.inactive_courses && isArrayExists(item.inactive_courses) ? item.inactive_courses.length : "0"}
                                        </WrapWord>
                                    ),
                                },
                                {
                                    id: "inactive_courses",
                                    label: "Inactive Courses",
                                    render: (item) => {
                                        return item.inactive_courses && isArrayExists(item.inactive_courses)
                                            ? item.inactive_courses.map((course, index) => {
                                                  return (
                                                      <div key={course.id} style={{ fontSize: "12px" }}>
                                                          {course.name}
                                                      </div>
                                                  );
                                              })
                                            : "-";
                                    },
                                },
                                {
                                    id: "concluded_courses_num",
                                    label: "# Concluded Courses",
                                    render: (item) => (
                                        <WrapWord>
                                            {item.concluded_courses && isArrayExists(item.concluded_courses) ? item.concluded_courses.length : "0"}
                                        </WrapWord>
                                    ),
                                },
                                {
                                    id: "concluded_courses",
                                    label: "Concluded Courses",
                                    render: (item) => {
                                        return item.concluded_courses && isArrayExists(item.concluded_courses)
                                            ? item.concluded_courses.map((course, index) => {
                                                  return (
                                                      <div key={course.id} style={{ fontSize: "12px" }}>
                                                          {course.name}
                                                      </div>
                                                  );
                                              })
                                            : "-";
                                    },
                                },
                            ]}
                            // actionStyles={{ width: "20%" }}
                            // actions={(item) => (
                            //     <ButtonGroup>
                            //         <AInfoLink
                            //             key="class"
                            //             href={"/r/"+( item.schedule_id && !_isEmpty( item.schedule_id ) ? item.schedule_id : null )+'?location=course_access'}
                            //             target="_blank"
                            //             size="small"
                            //             onClick={event => {
                            //                 event.preventDefault();
                            //                 this.props.history.push('/course_access',{ schedule_id: ( item.schedule_id && !_isEmpty( item.schedule_id ) ? item.schedule_id : null ) });
                            //             }}>View Class</AInfoLink>
                            //     </ButtonGroup>
                            // )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.boxheading}>
                            Course Enrollments
                        </Typography>
                        <Table
                            items={this.getCourseEnrollments()}
                            showCheckbox={false}
                            emptyCell="No course enrollment Found."
                            cells={[
                                { id: "name", label: "Course Name", render: (item) => <WrapWord>{item.name || "-"}</WrapWord> },
                                {
                                    id: "status",
                                    label: "Course Status",
                                    render: (item) => (
                                        <WrapWord style={{ fontSize: "12px" }}>
                                            {getSelectedValue(
                                                ENROLLMENT_STATUS_OPTIONS,
                                                item.status,
                                                "value",
                                                "label",
                                                item.status && !_isEmpty(item.status) ? item.status : "-"
                                            )}
                                        </WrapWord>
                                    ),
                                },
                                {
                                    id: "activated",
                                    label: "Activated On",
                                    render: (item) => (
                                        <WrapWord>{item.activated ? getMomentTime(stringToInteger(item.activated), "DD-MMM-YYYY") : "-"}</WrapWord>
                                    ),
                                },
                                {
                                    id: "deactivated",
                                    label: "De-Activated On",
                                    render: (item) => (
                                        <WrapWord>
                                            {item.deactivated ? getMomentTime(stringToInteger(item.deactivated), "DD-MMM-YYYY") : "-"}
                                        </WrapWord>
                                    ),
                                },
                                {
                                    id: "concluded",
                                    label: "Concluded On",
                                    render: (item) => {
                                        return (
                                            <WrapWord>
                                                {item.concluded ? getMomentTime(stringToInteger(item.concluded), "DD-MMM-YYYY") : "-"}
                                            </WrapWord>
                                        );
                                    },
                                },
                                {
                                    id: "class_name",
                                    label: "Class Name",
                                    render: (item) => (
                                        <WrapWord>
                                            <a
                                                href={"/schedules/" + item.schedule_id}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    history.push("/schedules/" + item.schedule_id);
                                                }}
                                            >
                                                {item.class_name || ""}
                                            </a>
                                        </WrapWord>
                                    ),
                                },
                                { id: "role", label: "Role", render: (item) => <span>Student</span> },
                            ]}
                            actionStyles={{ width: "20%" }}
                            actions={(item) => (
                                <ButtonGroup>
                                    <AInfoLink
                                        key="class"
                                        href={
                                            "/r/" +
                                            (item.schedule_id && !_isEmpty(item.schedule_id) ? item.schedule_id : null) +
                                            "?location=course_access"
                                        }
                                        target="_blank"
                                        size="small"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            this.props.history.push("/course_access", {
                                                schedule_id: item.schedule_id && !_isEmpty(item.schedule_id) ? item.schedule_id : null,
                                            });
                                        }}
                                    >
                                        View Course Access
                                    </AInfoLink>
                                </ButtonGroup>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.boxheading}>
                            Payment
                        </Typography>
                        <Table
                            items={this.getPaymentData()}
                            showCheckbox={false}
                            emptyCell="No payment data Found."
                            cells={[
                                {
                                    id: "class_name",
                                    label: "Class Name",
                                    render: (item) => (
                                        <WrapWord>
                                            <a
                                                href={"/r/" + item.id + "?location=payment"}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    history.push("/class_payment_tracking", { schedule_id: item.id });
                                                }}
                                            >
                                                {item.class_name || ""}
                                            </a>
                                        </WrapWord>
                                    ),
                                },
                                {
                                    id: "months_paid",
                                    label: "Months Paid",
                                    render: (item) => (
                                        <>
                                            {item.payment_tracking.map((payment, index) => (
                                                <div key={payment.id} style={{ display: "inline-block", margin: "0px 10px 5px 0" }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={payment.payment_made && payment.payment_made === "yes" ? true : false}
                                                                checkedIcon={
                                                                    <CheckBoxIcon
                                                                        style={{
                                                                            color: green[700],
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        }
                                                        label={payment.id}
                                                    />
                                                </div>
                                            ))}
                                        </>
                                    ),
                                },
                            ]}
                            noAction={true}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.boxheading}>
                            Student Badges
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderField("eportfolio")}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            <strong>BACKPACK:</strong>{" "}
                            <a href={PLATFORM_BACKPACK_URL + (student && student.uid && !_isEmpty(student.uid) ? student.uid : "")} target="_blank">
                                {PLATFORM_BACKPACK_URL + (student && student.uid && !_isEmpty(student.uid) ? student.uid : "")}
                            </a>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Table
                            items={this.getBadges()}
                            showCheckbox={false}
                            emptyCell="No badge(s) Found."
                            cells={[
                                {
                                    id: "badge_title",
                                    label: "Badge Title",
                                    render: (item) => (
                                        <div>
                                            {item.image && !_isEmpty(item.image) ? (
                                                <img
                                                    src={item.image}
                                                    style={{
                                                        display: "inline-block",
                                                        width: "100%",
                                                        height: "auto",
                                                        maxWidth: "30px",
                                                        verticalAlign: "middle",
                                                        marginRight: "12px",
                                                    }}
                                                />
                                            ) : null}
                                            {item.name || "-"}
                                        </div>
                                    ),
                                },
                                {
                                    id: "badge_status",
                                    label: "Status",
                                    render: (item) => {
                                        if (item.badge_status && !_isEmpty(item.badge_status)) {
                                            return (
                                                <ConditionWrapper>
                                                    {item &&
                                                    item.conditions &&
                                                    item.conditions.showcase_date &&
                                                    item.conditions.showcase_date === "yes" ? (
                                                        <li>
                                                            <span>Open Day Date:</span>
                                                            <i className="fa fa-check"></i>
                                                        </li>
                                                    ) : null}
                                                    <li>
                                                        <span>Feedback Form:</span>
                                                        {item &&
                                                        item.conditions &&
                                                        item.conditions.feedback_form &&
                                                        item.conditions.feedback_form === "yes" ? (
                                                            <i className="fa fa-check"></i>
                                                        ) : (
                                                            <i className="fa fa-times"></i>
                                                        )}
                                                    </li>
                                                    <li>
                                                        <span>Showcase Project:</span>
                                                        {item && item.conditions && item.conditions.project && item.conditions.project === "yes" ? (
                                                            <i className="fa fa-check"></i>
                                                        ) : (
                                                            <i className="fa fa-times"></i>
                                                        )}
                                                    </li>
                                                    <li>
                                                        <span>Evaluation:</span>
                                                        {item && item.conditions && item.conditions.score && !_isEmpty(item.conditions.score) ? (
                                                            item.conditions.score === "pass" ? (
                                                                <>
                                                                    <i
                                                                        className="fa fa-thumbs-up"
                                                                        style={{ marginLeft: "3px", color: green[200] }}
                                                                    ></i>
                                                                    {item.conditions.badge_approved && item.conditions.badge_approved === "yes" ? (
                                                                        <i className="fa fa-check"></i>
                                                                    ) : null}
                                                                </>
                                                            ) : (
                                                                <i className="fa fa-times" style={{ color: green[200] }}></i>
                                                            )
                                                        ) : (
                                                            "---"
                                                        )}
                                                    </li>
                                                </ConditionWrapper>
                                            );
                                        } else {
                                            return "Claimed";
                                        }
                                    },
                                },
                                {
                                    id: "type_label",
                                    label: "Type",
                                    render: (item) => (
                                        <WrapWord>
                                            {item.badge_status && !_isEmpty(item.badge_status)
                                                ? item.badge_status === "approved" || item.badge_status === "nominated"
                                                    ? item.type_label
                                                    : "-"
                                                : item.type_label || "-"}
                                        </WrapWord>
                                    ),
                                },
                                {
                                    id: "created_on",
                                    label: "Claimed On",
                                    render: (item) => (
                                        <WrapWord>
                                            {!(item.badge_status && !_isEmpty(item.badge_status)) && item.created_on && item.created_on._seconds
                                                ? getMomentTime(item.created_on._seconds * 1000, "DD-MMM-YYYY")
                                                : "-"}
                                        </WrapWord>
                                    ),
                                },
                            ]}
                            actionStyles={{ width: "40%" }}
                            actions={(item) =>
                                !(item.badge_status && !_isEmpty(item.badge_status)) && (
                                    <ButtonGroup>
                                        <AInfoLink key="badge" target="_blank" size="small" href={PLATFORM_BADGE_URL + item.id}>
                                            <i className="fa fa-shield"></i>View Badge
                                        </AInfoLink>
                                        <ASuccessLink key="cert" target="_blank" size="small" href={PLATFORM_CERT_URL + item.id}>
                                            <i className="fa fa-certificate"></i>View Certificate
                                        </ASuccessLink>
                                        {authData && hasAccessRights(authData, ["sdw"]) ? (
                                            <ErrorButton
                                                key="delete"
                                                size="small"
                                                onClick={() => {
                                                    if (changesMade) {
                                                        alert("Please save changes first!");
                                                    } else {
                                                        this.setState({ openDeleteModal: true, deleteModal: item });
                                                    }
                                                }}
                                            >
                                                <i className="fa fa-trash"></i>Remove Badge
                                            </ErrorButton>
                                        ) : null}
                                    </ButtonGroup>
                                )
                            }
                        />
                    </Grid>
                    {student && student._data && student._data.badges && isArrayExists(student._data.badges) ? (
                        <StudentBackpackTransfer student={student} schedules={schedules} onTrigger={this.handleBackpackTransfer} />
                    ) : (
                        <StudentBackpackTransferPartner authData={authData} regions={regions} student={student} />
                    )}
                </Grid>

                {disableSystemDate ? null : this.renderCreatedModifiedDate(student)}

                {authData && hasAccessRights(authData, ["sdw"]) ? (
                    <ModelDelete
                        open={openDeleteModal}
                        title={
                            deleteModal && deleteModal.name
                                ? `Are you sure you want to remove this badge from the student account ( ${deleteModal.name} )?`
                                : false
                        }
                        onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                        onDelete={this.handleBadgeDelete}
                    />
                ) : null}

                <ModalView
                    open={this.state.openTransferNotesModal}
                    title="Transfer student to a Different Partner"
                    onClose={() => this.setState({ openTransferNotesModal: false })}
                    noAction={true}
                    cancelLabel="Close"
                    contents={
                        <>
                            <InfoBox>
                                This function cannot be accessed by Principal & Coordinator. Please contact your Chumbaka PIC to administer this.
                            </InfoBox>
                        </>
                    }
                />
            </FormBox>
        );
    };
}

export default compose(connect(), withStyles(useStyles), withRouter)(StudentEditForm);
