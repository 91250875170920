/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _split from 'lodash/split';
import _map from 'lodash/map';
import _trim from 'lodash/trim';

import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';

import { FormBox } from '../../styles/form';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';

import { userStudentSchema } from '../../schemas/user_student';
import { COUNTRIES_LIST } from '../../data/const_countries';
import { STATE_OPTIONS } from '../../data/const_states';

import { YES_NO_OPTIONSE } from '../../constants';

const useStyles = theme => ({
    section_title: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingTop: "15px",
        paddingBottom: "15px",
        marginBottom: "5px",
        borderBottom: "1px solid #ddd"
    }
});

class ShowcaseDates extends React.Component {

    utcOffSet = 8;

    state = {
        randNum: false
    };

    handleFormUpdate = ( newValue, key, subkey, subVal ) => {
        const { onFormUpdate, showcase } = this.props;
        let newTrainee = ( showcase && showcase.trainee && isObjectExists( showcase.trainee ) ? cloneCollections( showcase.trainee ) : {} );

        switch( key ) {
            default:
                newTrainee[key] = newValue;
                break;
        }

        // do update
        if ( onFormUpdate )
            onFormUpdate( newTrainee );
    }

    getFieldValue = (field) => {
        const { showcase } = this.props;
        switch( field.name ) {
            default:
                return ( showcase && showcase.trainee && showcase.trainee[field.name] ? showcase.trainee[field.name] : '' );
        }
    }

    renderProjectDesc = () => {
        const { showcase } = this.props;
        let field = {
            name: 'project_desc',
            label: 'Project Link (Required)',
            default: '',
            // disabled: ( showcase && showcase.trainee && showcase.trainee.showcase && showcase.trainee.showcase.badge_id && !_isEmpty( showcase.trainee.showcase.badge_id ) && !( showcase.trainee.project_reenabled && showcase.trainee.project_reenabled === 'yes' ) ? true : false )
        };
        return <FormInput {...field} value={this.getFieldValue(field)} multiline={true} rows={4} onChange={this.handleFormUpdate} />
    }

    renderProjectTitle = () => {
        const { showcase } = this.props;
        let field = {
            name: 'project_title',
            label: 'Project Title (Required)',
            default: '',
            // disabled: ( showcase && showcase.trainee && showcase.trainee.showcase && showcase.trainee.showcase.badge_id && !_isEmpty( showcase.trainee.showcase.badge_id ) && !( showcase.trainee.project_reenabled && showcase.trainee.project_reenabled === 'yes' ) ? true : false )
        };
        return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />
    }

    render = () => {
        const { classes, showcase } = this.props;
        return (
        <FormBox>
            <Grid container spacing={3}>
                <Grid item xs={12}>{this.renderProjectTitle()}</Grid>
                <Grid item xs={12}>{this.renderProjectDesc()}</Grid>
            </Grid>
            <Grid item xs={12}><Typography variant="body1"  style={{ color: '#D32F2F', fontWeight: "700", marginTop: '20px' }}>* Compulsory to fill in</Typography></Grid>
        </FormBox>
        );
    }

}

export default compose(
    withStyles(useStyles),
    withRouter
)(ShowcaseDates);