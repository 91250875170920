import React from "react";

const info = {
    heading: (
        <>
            <i className="fa fa-exclamation-circle"></i>Strictly for R&D’s edit only
        </>
    ),
    contents: [
        {
            id: "1",
            title: "Add a product here to link with LMS ",
            content: (
                <>
                    (
                    <a href="https://lms.chumbaka.asia/login/canvas" target="_blank">
                        https://lms.chumbaka.asia/login/canvas
                    </a>
                    )
                    <div>
                        <ol style={{ listStyle: "numeric", paddingLeft: "10px", marginTop: "10px" }}>
                            <li>Products include courses, kit, etc.</li>
                            <li>LMS Course is controlled by SMS via Product Profile.</li>
                            <li>
                                Only when Product Profile for a course is created, it can be added into a Program Profile, and subsequently Class
                                Schedule.
                            </li>
                        </ol>
                    </div>
                </>
            ),
        },
    ],
};

const buttons = [];

export default {
    info,
    buttons,
};
