/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _toLower from 'lodash/toLower';
import _split from 'lodash/split';
import _toString from 'lodash/toString';

import PreviewReportModal from './report_preview';
import SendReportModal from './report_send';
import StudentNameToDatabase from '../../modules/StudentNameToDatabase';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormSelect2 from '../../components/FormSelect2';
import FormRadio from '../../components/FormRadio';
import FormCheckbox from '../../components/FormCheckbox';
import ButtonActions from '../../components/ButtonActions';
import StudentEnrollmentStatus from '../../components/StudentEnrollmentStatus';
import ToolTipInfo from '../../components/ToolTipInfo';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton, WarningButton, SuccessButton, IndigoButton } from '../../styles/button';
import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from '../../styles/tag';
import { ErrorBox } from '../../styles/message';
import { WrapWord } from '../../styles/misc';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp, getAge } from '../../helpers/date';
import { isAdmin, hasAccessRights, getUserRegionFilter } from '../../helpers/auth';
import { getStudentEnrollmentStatus } from '../../helpers/trainees';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { editTrainee, deleteTrainee } from '../../actions/trainees';
import { appChangesMade } from '../../actions/misc';

import { traineeSchema } from '../../schemas/trainee';

import { GENDER_OPTIONS, ENROLLMENT_STATUS_OPTIONS, YES_NO_OPTIONS } from '../../constants';

const SuccessIcon = styled.i`
    color: #64dd17;
`;

const GreyIcon = styled.i`
    color: #64dd17;
`;

const WarningIcon = styled.i`
    color: #ff6f00;
`;

const AttendanceColumn = styled.div`
    padding: 16px;
    font-size: 11px;
    height: 100%;
    width: 100%;
    max-width: 200px;
    ${props => {
        if ( props.overdue && props.overdue === 'yes' ) {
            return `
            background: #ffebee;
            `;
        }
        if ( props.disabled && props.disabled === 'yes' ) {
            return `
            .disabled-wrapper {
                opacity: 0.4;
            }
            `;
        }
    }};
`;

const ReportColumn = styled.div`
    padding: 16px;
    font-size: 11px;
    height: 100%;
    width: 100%;
    max-width: 200px;
    ${props => {
        if ( props.overdue && props.overdue === 'yes' ) {
            return `
            background: #ffebee;
            `;
        }
        if ( props.disabled && props.disabled === 'yes' ) {
            return `
            .disabled-wrapper {
                opacity: 0.4;
            }
            `;
        }
    }};
`;

const RadioWrapper = styled.div`
    cursor: default;
    pointer-events: none;
    .MuiFormControl-fullWidth {
        background: none;
    }
    .MuiFormLabel-root,
    .MuiTypography-body1 {
        font-size: 11px;
    }
`;

const TextareaWrapper = styled.div`
    textarea {
        width: 100%;
        max-width: 150px;
        font-size: 11px;
        padding: 2px;
        overflow: scroll-y;
    }
`;

class StudentsTable extends React.Component {

    utcOffSet = 8;
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        filterBy: 'active',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openPreviewReport: false,
        openSendReport: false,
        sendReportType: false,
        studentsSelected: [],
        classCalendarSelected: false,
        modalData: false,
        traineeData: false,
        modalType: false,
        openMentorCommentsModal: false,
        randNum: false,
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { openMassReport } = this.props;
        if ( !prevProps.openMassReport && openMassReport )
            this.handleMassQuarterlyReport();
    }

    handleMassQuarterlyReport = () => {
        const { onMassReportClose } = this.props;
        const { checked } = this.state;
        if ( checked && isArrayExists( checked ) ) {
            this.setState({
                openSendReport: true,
                sendReportType: 'mass',
                studentsSelected: ( checked && isArrayExists( checked ) ? checked : [] ),
                classCalendarSelected: false,
            });
        } else {
            triggerErrorAlert("Please select at least one student");
            if ( onMassReportClose )
                onMassReportClose();
        } // end - checked
    }

    handleSendQuarterlyReport = ({ trainee, calendar_event }) => event => {
        this.setState({
            openSendReport: true,
            sendReportType: 'single',
            studentsSelected: ( trainee ? [{ id: trainee.id }] : [] ),
            classCalendarSelected: ( calendar_event && calendar_event.id ? calendar_event.id : false ),
        });
    }

    handlePreviewQuarterlyReport = ({ trainee, calendar_event }) => event => {
        this.setState({
            openPreviewReport: true,
            studentsSelected: ( trainee ? [{ id: trainee.id }] : [] ),
            classCalendarSelected: ( calendar_event && calendar_event.id ? calendar_event.id : false ),
        });
    }

    handleMentorCommentsUpdate = () => {
        const { trainees, onUpdate, authData } = this.props;
        const { modalData } = this.state;
        let error = false,
            newTrainees = ( trainees && isArrayExists( trainees ) ? cloneCollections( trainees ) : [] ),
            index = ( newTrainees && isArrayExists( newTrainees ) && modalData && modalData.id ? _findIndex( newTrainees, { id: modalData.id }) : -1 );

        // make sure comments doesn't exceed 1000 chars
        if ( modalData && modalData.mentor_comments && !_isEmpty( modalData.mentor_comments ) && _size( modalData.mentor_comments ) > 1000 )
            error = "Comments cannot be exceed more than 1000 characters";

        if ( error ) {
            triggerErrorAlert( error );
        } else {
            if ( index >= 0 ) {
                newTrainees[index].mentor_comments = ( modalData && modalData.mentor_comments && !_isEmpty( modalData.mentor_comments ) ? modalData.mentor_comments : '' );
                newTrainees[index].mentor_comments_updated = moment().utcOffset(8).valueOf();
                newTrainees[index].mentor_comments_updated_by = ( authData && authData.email || '' );
            }

            if ( onUpdate ) {
                onUpdate(newTrainees);
                this.props.dispatch(appChangesMade());
                this.setState({ openMentorCommentsModal: false, modalData: false, traineeData: false });
            }
        } // end - error

    }

    getActions = () => {
        const { authData } = this.props;
        let actions = [];

        actions.push({ 
            id: 'mass_quarterly_report', label: 'Send Quarterly Report for Selected Student(s)', icon: 'fa-envelope-o', onClick: this.handleMassQuarterlyReport
        });

        // actions.push({ 
        //     id: 'invoice', label: 'Send Invoice', icon: 'fa-envelope-o', disabled: true, onClick: this.handleSendEmail('invoice')
        // });

        return actions;
    }

    getCells = () => {
        const { columns, currentSchedule, history } = this.props;
        let cells = [
                // { id: 'region', label: 'Licensee', render: this.renderCell('region') },
                // { id: 'class_name', label: 'Class Name', render: this.renderCell('class_name') },
                // { id: 'created_on', label: 'Registration Date', render: this.renderCell('created_on') },
                { id: 'confirmation_email_date', label: 'Confirmation Date', render: this.renderCell('confirmation_email_date') },
                // { id: 'booking_date', label: 'Booking Date', render: this.renderCell('booking_date') },
                // { id: 'email', label: 'Email', render: this.renderCell('email') },
                { id: 'name', label: 'Name', render: (item) => <StudentNameToDatabase {...item} /> },
                { id: 'enrollment_status', label: 'Class Status', render: this.renderEnrollmentStatus('enrollment_status') },
                { id: 'attendances', label: "Attendance", render: this.renderCell('attendances')  },
                { id: 'mentor_comments', label: (<><ToolTipInfo content={(
                <>
                    This comment is an overall comment about a student over a quarter. <br /><br />
                    This comment will be automatically attached together in the <a href={"/r/"+currentSchedule+"?location=quarterly_report"} onClick={event => {
                        const { currentSchedule, history } = this.props;
                        event.preventDefault();
                        history.push('/class_quarterly_report',{ schedule_id: currentSchedule });
                    }}>Quarterly Report</a> to parents.<br /><br />
                    You may use use the <a href="https://docs.google.com/document/d/15K16BMbXewAlus8RLGOa-gca-iVCDQN5lkn2MBWg7SY/edit?usp=sharing" target="_blank">Rubric on Student Impact</a> to help you 
                </>
                )} />{"Mentor's General Comment"}</>), render: this.renderCell('mentor_comments'), style: { padding: '0px' } },
                { id: 'backpack', label: "Backpack", render: (item) => (
                <AInfoLink href={"/bp/" + item.id } target="_blank" minWidth="0px" noIconMargin="yes" style={{ padding: '5px 15px' }}><i className='fa fa-external-link'></i></AInfoLink>
                )  },
            ];

        if ( columns && isArrayExists( columns ) ) {
            _forEach( _sortBy(columns,['start_date']), column => {
                cells.push({
                    id: column.id,
                    label: ( column.label || column.id ),
                    style: { padding: '0px' },
                    render: this.renderReportColumn(column)
                });
            });
        } // end - columns

        return cells;
    }

    getCurrentTrainee = (trainee) => {
        const { currentTrainees } = this.props;
        return ( currentTrainees && isArrayExists( currentTrainees ) && trainee && trainee.id ? _find( currentTrainees, { id: trainee.id } ) : false );
    }

    getSelectedReport = (trainee,event_id) => {
        return ( trainee && trainee.quarterly_reports && isArrayExists( trainee.quarterly_reports ) ? _find( trainee.quarterly_reports, { id: event_id }) : false );
    }

    getReportDateOptions = () => {
        const { schedule } = this.props;
        let options = [{ value: 'all', label: 'All Report Dates' }];
        if ( schedule && schedule.class_calendar && isArrayExists( schedule.class_calendar ) ) {
            _forEach( _sortBy( schedule.class_calendar, ['start_date'] ), event => {
                if ( event.quarterly_report && event.quarterly_report === 'yes' )
                    options.push({ value: event.id, label: ( event.start_date ? getMomentTime( event.start_date, 'DD MMM YYYY' ) : '' ) })
            });
        } // end - schedule.class_calendar
        return options;
    }

    reorganizeData() {
        const { searchterms, sortBy, perPage, page, filterBy, filterByReportDate } = this.state;
        const { trainees } = this.props;
        let items = ( trainees ? cloneCollections( trainees ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','email'] );
			total = _size( items );
        } // end - searchterms

        // do filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy !== 'all' ) {
            items = _filter( items, (i) => getStudentEnrollmentStatus(i) === filterBy );
            total = _size( items );
        } // end - filterBy

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['confirmation_email_date'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['confirmation_email_date'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
                // case 'email-desc':
                //     items = _sortBy( items, ['email'] );
                //     items = _reverse( items );
                //     break;
                // case 'email-asc':
                //     items = _sortBy( items, ['email'] );
                //     break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderMentorCommentsForm = () => {
        const { columns, currentSchedule, history } = this.props;
        const { modalData } = this.state;
        return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormInput
                        label={"Mentor's General Comments (Max 1000 characters)"}
                        tooltip={(
                            <>
                                This comment is an overall comment about a student over a quarter. <br /><br />
                                This comment will be automatically attached together in the <a href={"/r/"+currentSchedule+"?location=quarterly_report"} onClick={event => {
                                    const { currentSchedule, history } = this.props;
                                    event.preventDefault();
                                    history.push('/class_quarterly_report',{ schedule_id: currentSchedule });
                                }}>Quarterly Report</a> to parents.<br /><br />
                                You may use use the <a href="https://docs.google.com/document/d/15K16BMbXewAlus8RLGOa-gca-iVCDQN5lkn2MBWg7SY/edit?usp=sharing" target="_blank">Rubric on Student Impact</a> to help you 
                            </>
                            )} 
                    name="mentor_comments" value={( modalData && modalData.mentor_comments ? modalData.mentor_comments : '' )} rows={5} multiline={true} onChange={(newValue) => {
                        this.setState({
                            modalData: {
                                ...modalData,
                                mentor_comments: newValue
                            }
                        });
                    }} />
                    { modalData && modalData.mentor_comments && !_isEmpty( modalData.mentor_comments ) && _size( modalData.mentor_comments ) > 1000 ? <ErrorBox>Please reduce your notes to less than 1000 characters. Current size: {_size( modalData.mentor_comments )} characters.</ErrorBox> : null }
                </Grid>
            </Grid>
        </div>
        );
    }

    renderEnrollmentStatus = type => item => {
        return <StudentEnrollmentStatus trainee={item} />
    }

    renderReportColumn = (calendar_event) => (trainee) => {
        const report = this.getSelectedReport(trainee,calendar_event.id);
        return report && report.sent_on ? <ReportColumn>Report Sent on: {getMomentTime(report.sent_on,'DD-MMM-YY')}</ReportColumn> : (
        <ReportColumn>
            <ButtonGroup>
                <InverseButton size="small" onClick={this.handlePreviewQuarterlyReport({ trainee, calendar_event })}><i className="fa fa-search"></i>Preview Report</InverseButton>
                <IndigoButton size="small" onClick={this.handleSendQuarterlyReport({ trainee, calendar_event })}><i className="fa fa-envelope-o"></i>Send Report</IndigoButton>
            </ButtonGroup>
        </ReportColumn>
        );
    }

    renderCell = type => item => {
        const { regions, schedules, currentSchedule, schedule } = this.props;
        switch( type ) {
            case 'created_on':
            case 'confirmation_email_date':
                return <WrapWord>{( item[type] ? getMomentTime( item[type], 'DD MMM YYYY' ) : '---' )}</WrapWord>;
            case 'mentor_comments':
                return (
                <AttendanceColumn>
                    <div className="disabled-wrapper">
                        <TextareaWrapper>
                            <textarea rows={6} disabled="disabled" value={ item.mentor_comments && !_isEmpty( item.mentor_comments ) ? item.mentor_comments : '-' } />
                        </TextareaWrapper>
                    </div>
                    <InfoButton size="small" minWidth="0px" style={{ padding: '4px 8px', marginTop: '4px' }} onClick={() => this.setState({
                        openMentorCommentsModal: true,
                        traineeData: ( item || false ),
                        modalData: {
                            id: item.id,
                            mentor_comments: ( item.mentor_comments || '' )
                        }
                    })}>Edit</InfoButton>
                    <div>
                        { item.mentor_comments_updated ? <div style={{ fontSize: '10px' }}>Last Updated on: {getMomentTime( item.mentor_comments_updated, 'DD MMM YYYY' )}</div> : null }
                        { item.mentor_comments_updated_by && !_isEmpty( item.mentor_comments_updated_by ) && <div style={{ fontSize: '10px' }}>Last Updated By: <WrapWord>{item.mentor_comments_updated_by}</WrapWord></div> }
                    </div>
                </AttendanceColumn>
                );
            case 'attendances':
                return (
                <>{ item.attendances && isArrayExists( item.attendances ) ? (item.attendances.filter(a => a.attend && a.attend === 'yes')).length + " / "+ item.attendances.length : '-' }</>
                );
                // return (
                // <>
                //     {item.attendances.map((attendance, index) => {
                //         const session = _find( schedule.class_calendar, { id: attendance.id } );
                //         return attendance.attend && (
                //             attendance.attend === 'yes' || 
                //             attendance.attend === 'no'
                //         ) && (
                //         <div
                //             key={attendance.id}
                //             style={{ display: 'inline-block', margin: '0px 10px 5px 0' }}>
                //             <FormControlLabel
                //                 control={
                //                     <Checkbox
                //                         checked={( attendance.attend && attendance.attend === 'yes' ? true : false )}
                //                         checkedIcon={(
                //                             <CheckBoxIcon
                //                                 style={{
                //                                     color: green[700],
                //                                 }}
                //                                 />
                //                         )}
                //                         icon={(
                //                             <i
                //                                 className='fa fa-times'
                //                                 style={{
                //                                     color: red[700],
                //                                 }}
                //                                 ></i>
                //                         )} />
                //                 }
                //                 label={( session && session.start_date ? getMomentTime( session.start_date, 'DD MMM' ) : '' )} />
                //         </div>
                //         );
                //     })}
                // </>
                // );
            case 'email':
                return <WrapWord>{( item[type] || '-' )}</WrapWord>;
        }
    }

    renderTableActions = () => {
        const { authData } = this.props;
        const { sortBy, perPage, searchterms, checked, filterBy, filterByReportDate } = this.state;
        return <TableBar
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                filterBy={filterBy}
                filterByLabel="Filter By Class Status"
                sortByOptions={[
                    { value: 'date-desc', label: 'Confirmation Date (Recent First)' },
                    { value: 'date-asc', label: 'Confirmation Date (Oldest First)' },
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' },
                    // { value: 'email-asc', label: 'Email ( A - Z)' },
                    // { value: 'email-desc', label: 'Email ( Z - A )' },
                    // { value: 'org-asc', label: 'School ( A - Z)' },
                    // { value: 'org-desc', label: 'School ( Z - A )' },
                ]}
                leftButtons={ checked && isArrayExists( checked ) ? [
                    <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                        <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                    </div>
                    ] : null }
                rightButtons={[
                    // <ButtonActions 
                    //     key="actions" 
                    //     label="Actions"
                    //     menuContainerStyle={{ width: "350px" }}
                    //     style={{ marginRight: "5px" }}
                    //     actions={this.getActions()} />
                ]}
                filterByOptions={getSelectOptions({ list: _filter( ENROLLMENT_STATUS_OPTIONS, { course_access: true } ), options: [{ value: 'all', label: 'All Status' }], keys: { value: 'value', label: 'label' } })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    doneLoaded={true}
                    // entriesLabel="(Total Registration For This Class)"
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { authData, users, quarterly_report, currentTrainees, schedule, enrollment_status } = this.props;
        const { allCheck, checked, openPreviewReport, openSendReport, sendReportType, studentsSelected, classCalendarSelected, openMentorCommentsModal } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            {this.renderTableActions()}
            <Table 
                items={items}
                showCheckbox={true}
                emptyCell="No Student(s) Found."
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={(newValue) => this.setState({ checked: newValue })}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                cells={this.getCells()}
                 />
            {this.renderPagination(total)}

            <ModalView 
                open={openMentorCommentsModal}
                title="Edit Mentor's General Comments"
                maxWidth="sm"
                onClose={() => this.setState({ openMentorCommentsModal: false, modalData: false, traineeData: false })}
                disableBackdrop={true}
                actionLabel="Update"
                doAction={this.handleMentorCommentsUpdate}
                contents={this.renderMentorCommentsForm()} />

            <PreviewReportModal
                open={openPreviewReport}
                quarterly_report={quarterly_report}
                class_calendar_id={classCalendarSelected}
                trainees={currentTrainees}
                schedule={schedule}
                studentsSelected={studentsSelected}
                onClose={() => this.setState({ openPreviewReport: false, studentsSelected: [], classCalendarSelected: false })} />

            <SendReportModal
                open={openSendReport}
                enrollment_status={enrollment_status}
                quarterly_report={quarterly_report}
                report_type={sendReportType}
                class_calendar_id={classCalendarSelected}
                trainees={currentTrainees}
                schedule={schedule}
                studentsSelected={studentsSelected}
                onClose={() => {
                    const { onMassReportClose } = this.props;
                    this.setState({ openSendReport: false, sendReportType: false, studentsSelected: [], classCalendarSelected: false, checked: [], allCheck: false })
                    if ( onMassReportClose )
                        onMassReportClose();
                }} />

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(StudentsTable);