/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import shortid from 'shortid';
import Alert from "react-s-alert";
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
// import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
// import _slice from 'lodash/slice';
// import _split from 'lodash/split';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
// import _replace from 'lodash/replace';
// import _remove from 'lodash/remove';
// import _map from 'lodash/map';

import MediaLibrary from '../../components/MediaLibrary';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormRadio from '../../components/FormRadio';
import FormInput from '../../components/FormInput';
import FormEditor from '../../components/FormEditor';
import EmailShortcodes from '../../components/EmailShortcodes';

import { InfoButton, ErrorButton, ButtonGroup } from '../../styles/button';
// import { WrapWord } from '../../styles/misc';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, convertArrayToObject, convertObjectToArray, getSelectedValue } from '../../helpers/data';
// import { getMomentTime } from '../../helpers/date';
import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { uploadMediaToStorage } from '../../helpers/firebase';
import { getAPIErrorMessage } from '../../helpers/action';

import { updateMaintenance } from '../../actions/maintenance';
import { toggleLoader } from '../../actions/global';

import { EMAIL_TEMPLATES_TYPES } from '../../constants';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    },
});


const filePickerCallBack = (authData,field,dispatch) => ( callback, value, meta ) => {
    const input = document.getElementById('editor-mce-file-upload-field-' + field.id );

    input.click();
    input.onchange = function () {
        const file = input.files[0];
        const uploadPath = 'email_templates/' + ( field && field.id && !_isEmpty( field.id ) ? field.id : 'misc' );
        Alert.success('<span class="app-alert text-center content-block"><i class="fa fa-circle-o-notch fa-spin"></i>Uploading image - Please do not click on anything, browser back button, refresh or close this page!</span>', { position: 'top', effect: 'flip', beep: false, timeout: 'none', offset: 70 });
        dispatch(toggleLoader(true));
        uploadMediaToStorage(file, uploadPath, authData)
        .then(function(url) {
            Alert.closeAll();
            dispatch(toggleLoader(false));
            triggerSuccessAlert('Upload Done!');
            callback(url);
        })
        .catch(function(error) {
            Alert.closeAll();
            dispatch(toggleLoader(false));
            triggerErrorAlert(getAPIErrorMessage(error));
        });

    };
}

const ActiveTag = styled.div`
    display: inline-block;
    padding: 5px 8px;
    margin: 0px 5px 5px 0px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 6px;
    color: #fff;
    background: ${props => (props.active && props.active == 'yes' ? green['500'] : grey['500'])};
`;

class List extends React.Component {
    
    state = {
        sortBy: 'module-asc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalType: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false
    }

    handleFormUpdate = (newValue,key,status) => {
        const { modalData } = this.state;
        var newData = ( modalData ? cloneCollections( modalData ) : {} );
        newData[key] = newValue;
        this.setState({ modalData: newData });
    }

    handleEdit = () => {
        const { modalData } = this.state;
        var error = false;

        if ( !( modalData && modalData.subject && !_isEmpty( modalData.subject ) ) ) {
            error = 'Please insert a valid Subject Line';
        } // end - modalData.label

        if ( !( modalData && modalData.label && !_isEmpty( modalData.label ) ) ) {
            error = 'Please insert a valid label';
        } // end - modalData.label

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateMaintenance(modalData));
        } // end - error
    }

    reorganizeData() {
        const { sortBy, perPage, page } = this.state;
        const { list } = this.props;
        let items = [];

        _forEach( EMAIL_TEMPLATES_TYPES, template => {
            let item = ( list && isArrayExists( list ) ? _find( list, { id: template.id }) : false );
            if ( item && isObjectExists( item ) ) {
                items.push({
                    ...template,
                    ...item
                });
            } else {
                items.push({
                    ...template,
                    type: 'email_templates',
                    value: '',
                    status: 'active',
                    subject: '',
                    template: '',
                    attachments: {}
                });
            } // end - item
        });

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                // case 'position-desc':
                //     items = _sortBy( items, [(i) => ( i.position ? parseInt( i.position, 10 ) : 99 )] );
                //     items = _reverse( items );
                //     break;
                // case 'position-asc':
                //     items = _sortBy( items, [(i) => ( i.position ? parseInt( i.position, 10 ) : 99 )] );
                //     break;
                case 'name-desc':
                    items = _sortBy( items, ['label'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['label'] );
                    break;
                case 'module-desc':
                    items = _sortBy( items, [ i => getSelectedValue( EMAIL_TEMPLATES_TYPES, i.id, 'id', 'module_used', '' ) ] );
                    items = _reverse( items );
                    break;
                case 'module-asc':
                    items = _sortBy( items, [ i => getSelectedValue( EMAIL_TEMPLATES_TYPES, i.id, 'id', 'module_used', '' ) ] );
                    break;
            }
        } // end - sortBy

        return { items, total: _size( items ) };
    }

    renderViewForm = () => {
        const { authData, dispatch, classes } = this.props;
        const { modalData, modalType } = this.state;
        return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}><FormInput label="Label" name="label" value={( modalData.label || '' )} disabled={true} onChange={this.handleFormUpdate} /></Grid>
                <Grid item xs={12}>
                    <MediaLibrary 
                        attachable={true}
                        attachments={( modalData && modalData.attachments && isObjectExists( modalData.attachments ) ? convertObjectToArray(modalData.attachments) : [] )}
                        onAttachmentUpdate={(medias) => this.handleFormUpdate(convertArrayToObject(medias),'attachments')} />
                </Grid>
                <Grid item xs={9}>
                    <FormInput label="Subject Line" name="subject" value={( modalData.subject || '' )} onChange={this.handleFormUpdate} />
                    <FormEditor 
                        id={( modalData && modalData.id && !_isEmpty( modalData.id ) ? modalData.id : false )}
                        name="template"
                        value={( modalData && modalData.template && !_isEmpty( modalData.template ) ? modalData.template : '' )} 
                        height={600} 
                        onChange={this.handleFormUpdate} 
                        filePickerTypes= 'image' 
                        filePickerCallback={filePickerCallBack(authData,modalData,dispatch)} />
                </Grid>
                <Grid item xs={3}>
                    <EmailShortcodes email_type={( modalData && modalData.id && !_isEmpty( modalData.id ) ? modalData.id : false )} />
                </Grid>
            </Grid>
        </div>
        );
    }

    renderTableActions = () => {
        const { type } = this.props;
        const { sortBy, perPage, searchterms } = this.state;
        return <TableBar
                show={['sort']}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'name-asc', label: 'Label ( A - Z)' },
                    { value: 'name-desc', label: 'Label ( Z - A )' },
                    { value: 'module-asc', label: 'Module ( A - Z)' },
                    { value: 'module-desc', label: 'Module ( Z - A )' },
                    // { value: 'position-asc', label: 'Position (smallest number first)' },
                    // { value: 'position-desc', label: 'Position (biggest number first)' }
                ]}
                // rightButtons={[
                //     <InfoButton minWidth="128px" key="addnew" style={{ marginRight: "5px" }} onClick={() => this.setState({ openViewModal: true, modalData: { 
                //         type,
                //         label: '', 
                //         value: '',
                //         status: 'active' 
                //     }, modalType: 'new' })}><i className="fa fa-plus-circle"></i>Add New</InfoButton>
                // ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderBody = (items) => {
        const { classes, authData } = this.props;
        return (
        <TableBody>
            { items && isArrayExists( items ) ? items.map(item => {
                return (
                <TableRow key={item.id}>
                    <TableCell className={classes.bodycell}>{getSelectedValue( EMAIL_TEMPLATES_TYPES, item.id, 'id', 'module_used', '---' )}</TableCell>
                    <TableCell className={classes.bodycell}>{item.label || ''}</TableCell>
                    <TableCell className={classes.bodycell}>{item.subject || ''}</TableCell>
                    <TableCell className={classes.bodycell} style={{ width: '20%' }}>
                        <ButtonGroup>
                            <InfoButton size="small" onClick={() => this.setState({ openViewModal: true, modalData: item, modalType: 'edit' })}><i className="fa fa-edit"></i>Edit</InfoButton>
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
                )
            }) : (
                <TableRow>
                    <TableCell className={classes.bodycell}>No item(s) found.</TableCell>
                </TableRow>
            ) }
        </TableBody>
        )
    }

    renderHeader = () => {
        const { classes } = this.props;
        return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headcell}>Module Used</TableCell>
                <TableCell className={classes.headcell}>Label</TableCell>
                <TableCell className={classes.headcell}>Subject Line</TableCell>
                <TableCell className={classes.headcell}>Actions</TableCell>
            </TableRow>
        </TableHead>
        )
    }

    render() {
        const { typeLabel } = this.props;
        const { openViewModal, modalType } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={openViewModal}
                title={ modalType && modalType == 'edit' ? "Edit " + ( typeLabel || 'Item' ) : "Add New " + ( typeLabel || 'Item' ) }
                actionLabel={ modalType && modalType == 'edit' ? "Update" : "Add New" }
                maxWidth="lg"
                disableAutoFocus={true}
                disableEnforceFocus={true}
                disableBackdrop={true}
                onClose={() => this.setState({ openViewModal: false, modalType: false, modalData: false })}
                doAction={this.handleEdit}
                contents={this.renderViewForm()} />

            {this.renderTableActions()}
            <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                <Table>
                    {this.renderHeader()}
                    {this.renderBody(items)}
                </Table>
            </Paper>

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(List);