/* eslint-disable */
import React, { useState, useMemo, useEffect } from 'react';
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';

import TeamShowcaseForm from '../TeamShowcaseForm';

// import ErrorMessage from '../../components/ErrorMessage';
// import DotsLoader from '../../components/DotsLoader';
// import ModelDelete from '../ModalDelete';
import ModalView from '../../components/ModalView';
// import FormInput from '../FormInput';
// import FormSelect from '../FormSelect';
// import ButtonActions from '../ButtonActions';
// import ToolTipInfo from '../ToolTipInfo';

// import { InfoButton, SuccessButton, GreyButton, ButtonGroup, InfoLink, SuccessLink, InverseButton, ErrorButton } from '../../styles/button';
// import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from '../../styles/tag';
// import { WrapWord } from '../../styles/misc';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
// import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
// import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';

import theme from '../../theme';

const Wrapper = styled.div`

`;

const ShowcaseModal = ({
    authData,
    schedule = {},
    trainees = [],
    team = {},
    teams = [],
    onAction,
    onClose
}) => {
    const [ formData, setFormData ] = useState({});

    useEffect(() => {
        setFormData(team);
    },[ team ]);

    const handleAction = () => {
        let error = false;
        // do error check
        if ( !( formData && formData.score && !_isEmpty( formData.score ) ) )
            error = 'Please fill in the score';

        if ( formData && formData.score && formData.score === 'pass' && !( formData.badge && formData.badge.nominated_badge_type && !_isEmpty( formData.badge.nominated_badge_type ) ) )
            error = 'Please select a badge type';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            onAction(formData);
            onClose();
        }
                
    }

    return (
    <Wrapper>

        <ModalView 
            open={open}
            title={"Review Team Project Submission"}
            maxWidth="sm"
            actionLabel="Update"
            cancelLabel="Cancel"
            doAction={handleAction}
            onClose={onClose}
            contents={(
            <>
                <TeamShowcaseForm
                    formData={formData}
                    schedule={schedule}
                    trainees={trainees}
                    currentTeam={team}
                    teams={teams}
                    onUpdate={(newData) => setFormData(newData)}
                    />
            </>
            )} />

    </Wrapper>
    )
}

export default ShowcaseModal