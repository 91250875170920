import _random from 'lodash/random';

import { 
	GET_PARTNERS,
	GET_PARTNERS_OPTIONS,
    GET_PARTNER,
	ADD_PARTNER,
	EDIT_PARTNER,
	DELETE_PARTNER,
    RESET_PARTNER,
    RESET_PARTNERS
} from '../actions/types';

const initialState = {
    partners: null,
    partner: null,
    partners_options: null,
    updated: false,
    rand_options: false,
    rand: false
};

export const partners = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_PARTNERS:
            return {
                ...state,
                partners: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case GET_PARTNERS_OPTIONS:
            return {
                ...state,
                partners_options: ( payload.list || [] ),
                rand_options: _random(1,9999)
            };
        case GET_PARTNER:
            return {
                ...state,
                partner: ( payload.partner || [] ),
                rand: _random(1,9999)
            }; 
        case ADD_PARTNER:
        case EDIT_PARTNER:
        case DELETE_PARTNER:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_PARTNER:
            return {
                ...state,
                partner: null,
                updated: false,
                rand: false
            };
        case RESET_PARTNERS:
            return {
                ...state,
                partners: null,
                rand: false
            };
        default:
            return state;
    }
}