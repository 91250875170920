/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import firebase from "firebase/app";
import Typography from "@material-ui/core/Typography";
import _isEmpty from "lodash/isEmpty";

import ModalView from "../../components/ModalView";
import FormInput from "../../components/FormInput";

import { FullScreenFlex, FullScreenComponent } from "../../styles/division";
import { Button, GreyButton, GreyLink, AGreyLink, AIndigoLink, AInfoLink } from "../../styles/button";

import { validateEmail } from "../../helpers/validation";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";

import { signIn, logout } from "../../actions/auth";

import { PLATFORM_NAME_LONG, PLATFORM_URL, DEV_MODE, CANVAS_LMS_URL } from "../../constants";

class LoginPage extends React.Component {
    state = {
        showLogin: false,
        openModal: false,
        email: "",
        emailSent: false,
        showOptions: false,
        processing: false,
    };

    componentDidMount() {}

    componentDidUpdate(prevProps, prevState, snapshot) {}

    handleLogin = (event) => {
        event.preventDefault();
        this.props.dispatch(signIn());
    };

    handleLogout = (event) => {
        event.preventDefault();
        this.props.dispatch(logout());
    };

    handlePasswordlessSignIn = () => {
        const { email } = this.state;
        let error = false;

        if (!(email && !_isEmpty(email) && validateEmail(email))) {
            error = "Please insert a valid email address";
        }

        if (error) {
            triggerErrorAlert(error);
        } else {
            this.setState({ processing: true });
            firebase
                .auth()
                .sendSignInLinkToEmail(email, {
                    url: (DEV_MODE ? "http://localhost:3000/" : PLATFORM_URL) + "fbauth",
                    handleCodeInApp: true,
                })
                .then(() => {
                    // Save the email locally so you don't need to ask the user for it again
                    window.localStorage.setItem("emailForSignIn", email);
                    this.setState({ emailSent: true, processing: false });
                })
                .catch((error) => {
                    triggerErrorAlert(error.message);
                    this.setState({ openModal: false, email: "", emailSent: false, processing: false });
                });
        } // end - error
    };

    renderModal = () => {
        const { email, emailSent } = this.state;
        return (
            <div>
                {emailSent ? (
                    <Typography variant="subtitle1" style={{ fontSize: "18px" }} align="center">
                        We sent you an email with your sign-in link.
                        <br />
                        Please check your inbox ({email}) now.
                    </Typography>
                ) : (
                    <>
                        <FormInput
                            label="Email Address (Required)"
                            name="email"
                            type="email"
                            value={email || ""}
                            onChange={(newValue) => this.setState({ email: newValue })}
                        />
                        <Typography variant="body1" style={{ paddingTop: "10px", paddingBottom: "5px" }}>
                            <strong style={{ fontWeight: "700" }}>Note:</strong> Our SMS does not require user to login with their password. Don’t
                            worry this is totally secured, even without a password.
                        </Typography>
                    </>
                )}
            </div>
        );
    };

    render() {
        const { authData } = this.props;
        const { openModal, processing, emailSent, showOptions, showLogin } = this.state;
        return (
            <FullScreenComponent style={{ background: 'url("/loader_bg.jpg")', backgroundPosition: "center center", backgroundSize: "cover" }}>
                <FullScreenFlex justify="center" items="center" style={{ background: "rgba(#f1f1f1,0.75)" }}>
                    <div
                        style={{
                            textAlign: "center",
                            padding: "60px 90px",
                            background: "#f1f1f1",
                            borderRadius: "12px",
                            boxShadow: "0 0 15px 0 rgba(0,0,0,0.25)",
                        }}
                    >
                        <div style={{ margin: "0 auto 30px auto", maxWidth: "175px" }}>
                            <img src="/logo.gif" style={{ display: "block", height: "auto", width: "100%" }} />
                        </div>
                        <h1 style={{ fontWeight: "300", textTransform: "uppercase", marginBottom: "30px" }}>{PLATFORM_NAME_LONG}</h1>
                        {showLogin ? (
                            authData ? (
                                <GreyButton style={{ textTransform: "uppercase", padding: "10px 45px" }} onClick={this.handleLogout}>
                                    <i className="fa fa-power-off"></i>Logout
                                </GreyButton>
                            ) : (
                                <>
                                    <div style={{ marginTop: "10px" }}>
                                        <Button
                                            style={{ textTransform: "uppercase", padding: "10px 45px", width: "100%" }}
                                            onClick={() => this.setState({ openModal: true })}
                                        >
                                            <i className="fa fa-envelope"></i>Login with Email Address
                                        </Button>
                                    </div>
                                    {showOptions ? (
                                        <div style={{ marginTop: "10px" }}>
                                            <GreyButton
                                                style={{ textTransform: "uppercase", padding: "10px 45px", width: "100%" }}
                                                onClick={this.handleLogin}
                                            >
                                                <i className="fa fa-google"></i>Login with Google Account
                                            </GreyButton>
                                        </div>
                                    ) : null}
                                    <div style={{ marginTop: "20px" }}>
                                        <GreyLink
                                            style={{ textTransform: "uppercase", padding: "10px 45px", width: "100%" }}
                                            onClick={() => this.setState({ showOptions: !showOptions })}
                                        >
                                            {showOptions ? "Hide " : "Show "} Other Option(s)
                                        </GreyLink>
                                    </div>
                                    <div style={{ marginTop: "10px" }}>
                                        <GreyLink
                                            style={{ textTransform: "uppercase", padding: "10px 45px", width: "100%" }}
                                            onClick={() => this.setState({ showLogin: false })}
                                        >
                                            <i className="fa fa-arrow-left"></i>Go Back
                                        </GreyLink>
                                    </div>
                                </>
                            )
                        ) : (
                            <>
                                <div
                                    style={{
                                        marginTop: "10px",
                                    }}
                                >
                                    <AIndigoLink href={CANVAS_LMS_URL} style={{ textTransform: "uppercase", padding: "10px 45px", width: "100%" }}>
                                        I am a student
                                    </AIndigoLink>
                                </div>
                                <div
                                    style={{
                                        marginTop: "20px",
                                    }}
                                >
                                    <AInfoLink href={CANVAS_LMS_URL} style={{ textTransform: "uppercase", padding: "10px 45px", width: "100%" }}>
                                        I am a mentor/teacher
                                    </AInfoLink>
                                </div>
                                <div
                                    style={{
                                        marginTop: "20px",
                                    }}
                                >
                                    <GreyButton
                                        style={{ textTransform: "uppercase", padding: "10px 45px", width: "100%" }}
                                        onClick={() => this.setState({ showLogin: true })}
                                    >
                                        I am a center principal
                                    </GreyButton>
                                </div>
                            </>
                        )}
                    </div>

                    <ModalView
                        open={openModal}
                        title={emailSent ? "" : "Enter Your Email Address"}
                        actionLabel={"Send Sign-In Link"}
                        maxWidth="xs"
                        processing={processing}
                        noCancel={emailSent}
                        noAction={emailSent}
                        disableBackdrop={emailSent}
                        onClose={() => this.setState({ openModal: false, email: "", emailSent: false, processing: false })}
                        doAction={this.handlePasswordlessSignIn}
                        contents={this.renderModal()}
                    />
                </FullScreenFlex>
            </FullScreenComponent>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(LoginPage);
