/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Render } from '@9gustin/react-notion-render';
import styled from "styled-components";
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _size from 'lodash/size';
import _isEqual from 'lodash/isEqual';
import _findIndex from 'lodash/findIndex';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
// import { NotionRenderer } from "react-notion-x";

import AppWrapper from '../../components/AppWrapper';

import { isAdmin } from '../../helpers/auth';
import { cloneCollections } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';

import { toggleLoader } from '../../actions/global';

import { ButtonGroup, AInfoLink, InfoButton } from '../../styles/button';
import { SuccessTag } from '../../styles/tag';
import { WarningBox, InfoBox } from '../../styles/message';



const NotionPage = ({
    authData,
    dispatch
}) => {
    const [ loaded, setLoaded ] = useState(false);
    const [ blocks, setBlocks ] = useState({});

    useEffect(() => {
        setLoaded(false);
        callFunctionsAPI({
            method: 'GET', 
            url: 'data', 
            action: 'notion' 
        })
        .then( blocks => {
            console.log(blocks);
            setLoaded(true);
            setBlocks(blocks);
        })
        .catch(error => {
            triggerErrorAlert(getAPIErrorMessage(error));
        });
    },[]);

    return <AppWrapper 
            subtitle=""
            title="Notion Page"
            onLoad={( !( loaded ) ? true : false )}
            contents={ authData ? (
            <>
    
                <Render blocks={blocks} useStyles />
    
            </>
            ) : null } />;

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(NotionPage);