/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	STUDENT_SHOWCASE_GET_TEAM_PROJECT,
	STUDENT_SHOWCASE_UPDATE_TEAM_PROJECT,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getStudentTeamProject, fb_updateStudentTeamProject } from './fb';

export const getStudentTeamProject = (schedule_id,lms_course_id,project_id) => {
	return dispatch => {

		fb_getStudentTeamProject(schedule_id,lms_course_id,project_id)
		.then(project => {
			dispatch({
				type: STUDENT_SHOWCASE_GET_TEAM_PROJECT,
				payload: { project }
			});	
		})
		.catch(error => {
			dispatch(toggleLoader(false));
			// triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: STUDENT_SHOWCASE_GET_TEAM_PROJECT,
				payload: { project: {} }
			});
		});

	}
}


export const updateStudentTeamProject = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateStudentTeamProject(formData)
		.then( results => {
			fb_getStudentTeamProject(formData.schedule_id,formData.lms_course_id,formData.project_id)
			.then(project => {
				// push new data
				dispatch({ type: STUDENT_SHOWCASE_GET_TEAM_PROJECT, payload: { project } });	
				// updated
				dispatch({ type: STUDENT_SHOWCASE_UPDATE_TEAM_PROJECT });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Team Project Updated");
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}