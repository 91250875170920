import React from "react";

const info = {
    heading: <></>,
    contents: [
        {
            id: "1",
            title: "What is 3. Delivery-Payment stage? ",
            content: (
                <>
                    <div>
                        <p>Delivery is about fulfilling your obligation to students and their parents.</p>
                        <p>Here in Payment, you can track payments from students. </p>
                    </div>
                </>
            ),
        },
        {
            id: "2",
            title: "How is this page useful? ",
            content: (
                <>
                    <div>
                        <ul style={{ listStyle: "disc", paddingLeft: "10px", marginLeft: "10px" }}>
                            <li>Decide on the Payment Level for each student</li>
                            <li>Send invoice in the beginning of the month</li>
                            <li>Send payment reminder in the middle of the month</li>
                            <li>Use Payment History to track iPay88 payment</li>
                        </ul>
                    </div>
                </>
            ),
        },
        {
            id: "3",
            title: "Notes",
            content: (
                <>
                    <div>
                        <ul style={{ listStyle: "disc", paddingLeft: "10px", marginLeft: "10px" }}>
                            <li>Partner will use own accounting system to create student’s invoices & reconcile payment.</li>
                            <li>Not all Class will use Payment module – decide at your own discretion.</li>
                        </ul>
                    </div>
                </>
            ),
        },
    ],
};

const buttons = [
    {
        id: "2",
        tooltip: true,
        tooltipContent: (
            <div style={{ padding: "10px" }}>
                <p style={{ fontWeight: "700" }}>📂 Chumbaka Mentor Portal - 3. Delivery</p>
                <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                    <li>Class Attendance and Reporting Manual Tracker (Template)</li>
                    <li>Class Norms for Day 1</li>
                    <li>Reporting Bug</li>
                    <li>Walkthrough LMS</li>
                    <li>Course Access Report Examples</li>
                </ul>
            </div>
        ),
        type: "drive",
        link: "https://drive.google.com/drive/u/0/folders/152jmBqjA8L79qHhxw2bkSPZzBwY_RZy3",
        label: "Google Drive",
    },
];

export default {
    info,
    buttons,
};
