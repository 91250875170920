/* eslint-disable */
import React from 'react';
import styled from "styled-components";

export const boxShadow = `
    box-shadow: 0 2px 6px 0 #cad5db;
`;

export const WrapWord = styled.span`
    white-space: -moz-pre-wrap !important; 
    white-space: -webkit-pre-wrap;
    white-space: -pre-wrap; 
    white-space: -o-pre-wrap;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
`;