/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Papa from 'papaparse';
import styled from "styled-components";
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _toLower from 'lodash/toLower';
import _split from 'lodash/split';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';
import FormCheckbox from '../../components/FormCheckbox';
import ButtonActions from '../../components/ButtonActions';
import ToolTipInfo from '../../components/ToolTipInfo';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from '../../styles/tag';
import { ErrorBox } from '../../styles/message';
import { WrapWord } from '../../styles/misc';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp, getAge } from '../../helpers/date';

import { updateCourseAccessLog } from '../../actions/hq_summary';

import { ENROLLMENT_STATUS_OPTIONS } from '../../constants';

const SuccessIcon = styled.i`
    color: #64dd17;
`;

const GreyIcon = styled.i`
    color: #64dd17;
`;

const WarningIcon = styled.i`
    color: #ff6f00;
`;

const RemarkColumn = styled.div`
    padding: 0px;
    font-size: 11px;
    height: 100%;
    width: 100%;
    max-width: 200px;
`;

const RadioWrapper = styled.div`
    .MuiFormControl-fullWidth {
        background: none;
    }
    .MuiFormLabel-root,
    .MuiTypography-body1 {
        font-size: 11px;
    }

    ${props => {
        if ( props.disablePointer && props.disablePointer === 'yes' ) {
            return `
            .MuiFormControlLabel-root,
            .MuiIconButton-label > input {
                cursor: default;
            }
            `;
        }
    }};
`;

const TextareaWrapper = styled.div`
    textarea {
        width: 100%;
        max-width: 150px;
        font-size: 11px;
        padding: 2px;
        overflow: scroll-y;
    }
`;

class CourseAccessLogTable extends React.Component {

    utcOffSet = 8;
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        filterBy: 'all',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        randNum: false,
        openReviewModal: false,
        modalData: false
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        
    }

    handleRemarkUpdate = () => {
        const { openReviewModal, modalData } = this.state;
        let error = false;

        if ( !( modalData && modalData.id && !_isEmpty( modalData.id ) ) ) {
            error = 'Unknown error - please refresh your browser and try again.';
        } // end - modalData.id

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateCourseAccessLog({
                id: modalData.id,
                remark: ( modalData.remark || '' )
            }));
        } // end - error

    }

    doCSVDownload = (results) => {
        let csv = Papa.unparse( results, { delimiter: ',' } );
        let blob = new Blob( [csv] , { type: 'text/csv;charset=utf-8;' }); //new way
        let uri = window.URL.createObjectURL(blob);
        let link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", '_blank');
        link.setAttribute("download", "export_summary.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    handleCSVDownload = (event) => {
        const { list } = this.props;
        event.preventDefault();
        let items = ( list ? this.addDataToList( list ) : [] ),
            cells = this.getCells(true),
            csvData = {
                fields: [],
                data: []
            };

        // add header
        _forEach( cells, cell => {
            csvData.fields.push(cell.label);
        });

        if ( items && isArrayExists( items ) ) {
            items = this.sortItems(items);
            _forEach( items, item => {
                let row = [];
                _forEach( cells, cell => {
                    row.push( this.getCellValue(item,cell.id) );
                });
                csvData.data.push(row);
            });
        } // end - items
        
        this.doCSVDownload(csvData);
    }

    handleFormUpdate = (newValue,key,subkey,subVal) => {
        const { modalData } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} );
        switch( key ) {
            // case 'course_type':
            //     let selectedType = ( course_type && isArrayExists( course_type ) ? _find( course_type, { id: newValue } ) : false );
            //     newData[key] = newValue;
            //     newData[key + '_label'] = ( selectedType && selectedType.label ? selectedType.label : '' );
            //     break;
            default:
                if ( subkey && ( subkey === 'checked' || subkey === 'unchecked' ) && subVal && !_isEmpty( subVal ) ) {
                    // use subVal as the new value
                    newData[key] = subVal;
                } else {
                    // otherwise - just update normally
                    newData[key] = newValue
                }
                break;
        } // end - key
        this.setState({ modalData: newData });
    }

    addDataToList(list) {
        const { lms_courses } = this.props;
        let items = [];
        if ( list && isArrayExists( list ) ) {
            _forEach( list, item => {
                let newItem = { ...item };
                // add course_name
                newItem.course_name = getSelectedValue( ( lms_courses || null ), item['lms_course_id'], 'id', 'name', '-' );
                items.push(newItem);
            });
        }
        return items;
    }

    reorganizeData() {
        const { searchterms, sortBy, perPage, page, filterBy } = this.state;
        const { list, regions } = this.props;
        let items = ( list ? this.addDataToList( list ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['lms_course_id','email','course_code','course_name','name','email'] );
			total = _size( items );
        } // end - searchterms
        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            items = this.sortItems(items);
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    sortItems = (items) => {
        const { regions } = this.props;
        const { sortBy } = this.state;
        switch( sortBy ) {
            case 'date-desc':
                items = _sortBy( items, ['logged_on'] );
                items = _reverse( items );
                break;
            case 'date-asc':
                items = _sortBy( items, ['logged_on'] );
                break;
            case 'lms_course_id-desc':
                items = _sortBy( items, ['lms_course_id'] );
                items = _reverse( items );
                break;
            case 'lms_course_id-asc':
                items = _sortBy( items, ['lms_course_id'] );
                break;
            case 'email-desc':
                items = _sortBy( items, ['email'] );
                items = _reverse( items );
                break;
            case 'email-asc':
                items = _sortBy( items, ['email'] );
                break;
            case 'partner-desc':
                items = _sortBy( items, [i => _toLower( getSelectedValue( regions, i.region, 'id', 'label', '-' ) )] );
                items = _reverse( items );
                break;
            case 'partner-asc':
                items = _sortBy( items, [i => _toLower( getSelectedValue( regions, i.region, 'id', 'label', '-' ) )] );
                break;
        }
        return items;
    }

    getCells = (download) => {
        return [
            { id: 'logged_on', label: 'Logged On', render: this.renderCell('logged_on') },
            { id: 'status', label: 'Status', render: this.renderCell('status') },
            { id: 'region', label: 'Partner', render: this.renderCell('region') },
            { id: 'name', label: 'Student Name', render: this.renderCell('name') },
            { id: 'email', label: 'Student Email', render: this.renderCell('email') },
            { id: 'lms_course_id', label: 'LMS Course ID', render: this.renderCell('lms_course_id') },
            { id: 'course_name', label: 'Course Name', render: this.renderCell('course_name') },
            { id: 'class_name', label: 'Class Name', render: this.renderCell('class_name') },
            { id: 'activated', label: 'Activated Date', render: this.renderCell('activated') },
            { id: 'deactivated', label: 'Inactivated Date', render: this.renderCell('deactivated') },
            { id: 'concluded', label: 'Concluded Date', render: this.renderCell('concluded') },
            { id: 'remark', label: ( download ? 'Remarks' : (
            <>
                <ToolTipInfo 
                    content={(
                    <>
                        REMARKS<br />
                        If this is a mentor or for internal testing, please note it down to ease the processing later. 
                    </>
                    )} />
                Remarks
            </>
            )), render: this.renderCell('remark') },
        ]
    }

    getCellValue(item,type) {
        const { regions, lms_courses } = this.props;
        switch( type ) {
            case 'region':
                return getSelectedValue( regions, item[type], 'id', 'label', '-' );
            case 'logged_on':
                return ( item[type] ? getMomentTime( item[type], 'YYYY-MM-DD HH:mm:ss' ) : '---' )
            case 'activated':
            case 'deactivated':
            case 'concluded':
                return ( item[type] ? getMomentTime( item[type], 'YYYY-MM-DD' ) : '---' );
            case 'status':
                return getSelectedValue( ENROLLMENT_STATUS_OPTIONS, item[type], 'value', 'label', '-' );
            // case 'course_name':
            //     return getSelectedValue( ( lms_courses || null ), item['lms_course_id'], 'id', 'name', '-' ); 
            default:
                return ( item[type] || '-' );
        }
    } 

    renderRemarkForm = () => {
        const { modalData } = this.state;
        return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormInput label="Remarks (Max 500 characters)" name="remark" value={( modalData && modalData.remark ? modalData.remark : '' )} rows={5} multiline={true} onChange={this.handleFormUpdate} />
                    { modalData && modalData.remark && !_isEmpty( modalData.remark ) && _size( modalData.remark ) > 500 ? <ErrorBox>Please reduce your remarks to less than 500 characters. Current size: {_size( modalData.remark )} characters.</ErrorBox> : null }
                </Grid>
            </Grid>
        </div>
        );
    }

    renderCell = type => item => {
        switch( type ) {
            case 'activated':
            case 'email':
                return <WrapWord>{this.getCellValue(item,type)}</WrapWord>;
            case 'remark':
                return (
                <RemarkColumn>
                    <div className="disabled-wrapper">
                        <TextareaWrapper>
                            <textarea rows={6} disabled="disabled" value={ item.remark && !_isEmpty( item.remark ) ? item.remark : '-' } />
                        </TextareaWrapper>
                    </div>
                    <InfoButton size="small" minWidth="0px" style={{ padding: '4px 8px', marginTop: '4px' }} onClick={() => this.setState({
                        openRemarkModal: true,
                        modalData: item
                    })}>Edit</InfoButton>
                </RemarkColumn>
                );
            default:
                return this.getCellValue(item,type);
        }
    }

    renderTableActions = () => {
        const { authData } = this.props;
        const { sortBy, perPage, searchterms, checked, filterBy } = this.state;
        return <TableBar
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                // filterBy={filterBy}
                sortByOptions={[
                    { value: 'date-desc', label: 'Activation Date (Recent First)' },
                    { value: 'date-asc', label: 'Activation Date (Oldest First)' },
                    { value: 'lms_course_id-asc', label: 'Course ID ( 1 - 999 )' },
                    { value: 'lms_course_id-desc', label: 'Course ID ( 999 - 1 )' },
                    { value: 'email-asc', label: 'Email ( A - Z)' },
                    { value: 'email-desc', label: 'Email ( Z - A )' },
                    { value: 'partner-asc', label: 'Partner ( A - Z)' },
                    { value: 'partner-desc', label: 'Partner ( Z - A )' },
                ]}
                leftButtons={ checked && isArrayExists( checked ) ? [
                    <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                        <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                    </div>
                    ] : null }
                rightButtons={[
                    <InfoButton minWidth="168px" key="csv" style={{ marginRight: "5px" }} onClick={this.handleCSVDownload}><i className="fa fa-file-excel-o"></i>Download CSV</InfoButton>
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    doneLoaded={true}
                    // entriesLabel="(Total Registration For This Class)"
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { authData } = this.props;
        const { allCheck, checked, openRemarkModal } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={( openRemarkModal ? true : false )}
                title="Edit Remarks"
                maxWidth="sm"
                onClose={() => this.setState({ openRemarkModal: false, modalData: false })}
                disableBackdrop={true}
                actionLabel="Update"
                doAction={this.handleRemarkUpdate}
                contents={this.renderRemarkForm()} />

            {this.renderTableActions()}
            <Table 
                items={items}
                showCheckbox={false}
                emptyCell="No item(s) Found."
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={(newValue) => this.setState({ checked: newValue })}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                cells={this.getCells()}
                 />
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(CourseAccessLogTable);