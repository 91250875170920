/* eslint-disable */
import React, { useState, useMemo } from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';

import FormInput from '../../components/FormInput';
import FormRadio from '../../components/FormRadio';
import FormSelect from '../../components/FormSelect';

import { AInfoLink } from '../../styles/button';
import { ErrorBox } from '../../styles/message';
// import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from '../../styles/tag';
// import { WrapWord } from '../../styles/misc';

import { isArrayExists, isObjectExists, validURL } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getMomentTime, getMomentNow } from '../../helpers/date';
import { compareString } from '../../helpers/data';
// import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
// import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { getTeamProjectSubmission, getBadgesOptions, getCurrentTrainee, isTeamBadgeIssued } from '../../helpers/showcase';

import theme from '../../theme';

const ProjectWrapper = styled.div`
    padding: 10px 20px;
    border: 2px solid #ddd;
    background-color: #f9f9f9;
    margin-bottom: 20px;

    .MuiAppBar-root {
        box-shadow: none;
    }

    .MuiOutlinedInput-root {
        background-color: #fff;
    }

    .MuiBox-root {
        padding: 16px 8px 8px 8px;
    }
`;

const a11yProps = (index) => {
    return {
      id: `project-tab-${index}`,
      'aria-controls': `project-tabpanel-${index}`,
    };
}
  
const TabPanel = ({ children, value, index, ...other }) => {
    return (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`project-tabpanel-${index}`}
        aria-labelledby={`project-tab-${index}`}
        {...other}
        >
        {value === index && (
        <Box p={3}>
            <Typography>{children}</Typography>
        </Box>
        )}
    </div>
    );
}

export const Projects = ({
    projects,
    onUpdate = () => {}
}) => {
    const [ tab, setTab ] = useState(0);
    return (
    <ProjectWrapper>
        <AppBar position="static" color="transparent">
            <Tabs value={tab} onChange={(e,newTab) => setTab(newTab)}>
                {projects.map(project => (
                    <Tab key={project.id} label={"Project #"+project.id} {...a11yProps(parseInt( project.id, 10 )-1)} />
                ))}
            </Tabs>
        </AppBar>
        {projects.map(project => {
            return (
            <TabPanel key={project.id} value={tab} index={parseInt( project.id, 10 )-1}>
                <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                        <FormInput label="Project Title" value={( project.title || '' )} onChange={(newValue) => {
                            onUpdate( newValue, 'title', project.id );
                        }} />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={11}>
                                <FormInput label="Project Link" value={( project.desc || '' )} onChange={(newValue) => {
                                    onUpdate( newValue, 'desc', project.id );
                                }} />
                            </Grid>
                            { project.desc && !_isEmpty( project.desc ) && validURL(project.desc) ? (
                            <Grid item xs={1} align="center">
                                <AInfoLink size="small" minWidth="0px" href={project.desc} target="_blank" rel="nofollow" noIconMargin="yes"><i className="fa fa-globe"></i></AInfoLink>
                            </Grid>
                            ) : null }
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <FormInput 
                            label={"Project Feedback *"} 
                            tooltip={
                            <>
                                FEEDBACK ON SHOWCASE PROJECT<br /><br />
                                This will be emailed to students & parents.<br />
                                Provide feedback to student on his/her showcase project. Use rubric to help you. 
                            </>
                            } 
                            required={true}
                            // disabled={ modalType && modalType === 'view_only' ? true : false } 
                            rows={4} 
                            multiline={true} 
                            name="project_feedback" 
                            value={( project && project.project_feedback || '' )} 
                            onChange={handleUpdate(project)} />
                        { project && project.project_feedback && !_isEmpty( project.project_feedback ) && _size( project.project_feedback ) > 500 ? <ErrorBox>Please reduce your notes to less than 500 characters. Current size: {_size( project.project_feedback )} characters.</ErrorBox> : null }
                    </Grid> */}
                </Grid>
            </TabPanel>
            )
        })}
    </ProjectWrapper>
    )
}

const TeamShowcaseForm = ({
    authData,
    formData = {},
    schedule = {},
    trainees = [],
    currentTeam = {},
    teams = [],
    currentShowcase,
    onUpdate
}) => {

    const projects = useMemo(() => {
        let projects = [];
        if ( schedule && schedule.project_submissions && !_isEmpty( schedule.project_submissions ) ) {
            const total = parseInt( schedule.project_submissions );
            for (let i = 1; i <= total; i++) {
                const project = ( formData && formData.projects && isArrayExists( formData.projects ) ? _find( formData.projects, p => compareString( p.id, i ) ) : false );
                if ( project ) {
                    projects.push( project );
                } else {
                    projects.push({
                        id: i.toString(),
                        title: '',
                        desc: '',
                        project_feedback: ''
                    });
                }
            }
        } else {
            projects = ( formData && formData.projects && isArrayExists( formData.projects ) ? [ ...formData.projects ] : [] );
        }
        return projects;
    },[ formData, schedule ]);

    const totalProjects = useMemo(() => {
        return ( schedule && schedule.team_module && schedule.team_module === 'yes' && schedule.project_submissions && !_isEmpty( schedule.project_submissions ) ? parseInt( schedule.project_submissions, 10 ) : 0 );
    },[schedule]);

    const badgesOptions = useMemo(() => {
        let selectedTrainee = ( currentTeam && currentTeam.trainees && currentTeam.trainees[0] && currentTeam.trainees[0].id && !_isEmpty( currentTeam.trainees[0].id ) ? getCurrentTrainee({ currentTrainees: trainees, trainee: currentTeam.trainees[0] }) : false ),
            options = [{ value: '', label: 'Select an Option' }];
        if ( currentShowcase && currentShowcase.badges && isArrayExists( currentShowcase.badges ) ) {
            _forEach( _sortBy( currentShowcase.badges, ['type_label'] ), badge => {
                if ( !_find( options, { value: badge.type } ) ) {
                    options.push({
                        value: badge.type,
                        label: ( badge.type_label || '' )
                    });
                }
            });
        }
        return options;
    }, [ currentShowcase, currentTeam, teams ]);

    const handleUpdate = (newValue,name) => {
        let newData = { ...formData };
        switch( name ) {
            case 'nominated_badge_type':
                if ( !( newData.badge && !_isEmpty( newData.badge ) ) ) {
                    newData.badge = {};
                }
                newData.badge.nominated_badge_type = newValue;
                // newData.badge.nominated_by = ( authData.name || '' );
                // newData.badge.nominated_by_email = ( authData.email || '' );
                // newData.badge.nominated_on = getMomentNow();
                break;
            case 'score':
                newData[name] = newValue;
                if ( newValue === 'fail' ) {
                    if ( !( newData.badge && !_isEmpty( newData.badge ) ) ) {
                        newData.badge = {};
                    }
                    newData.badge.nominated_badge_type = '';
                }
                break;
            default:
                newData[name] = newValue;
                break;
        }
        onUpdate(newData);
    }

    return totalProjects > 0 && projects && isArrayExists( projects ) && (
    <>
        
        <Projects 
            projects={projects}
            onUpdate={(newValue,key,pid) => {
                let newData = { ...formData };
                if ( !( newData.projects && isArrayExists( newData.projects ) ) ) {
                    newData.projects = [];
                }
                let projectIndex = _findIndex( newData.projects, { id: pid } );
                if ( projectIndex > -1 ) {
                    newData.projects[projectIndex][key] = newValue;
                } else {
                    newData.projects.push({
                        id: pid,
                        [key]: newValue
                    });
                }
                onUpdate(newData);
            }} />

        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <div style={{ padding: '0px 10px 10px 10px' }}>
                    <FormRadio 
                        inline={true} 
                        label="Update Scoring" 
                        name="score" 
                        value={( formData && formData.score && ( formData.score === 'pass' || formData.score === 'fail' ) ? formData.score : '' )} 
                        options={[
                            { value: 'pass', label: 'PASS' },
                            { value: 'fail', label: 'FAIL' }
                        ]}
                        disabled={ isTeamBadgeIssued({ trainees, team: currentTeam }) ? true : false } 
                        radioIcon={<CheckBoxOutlineBlankIcon color="disabled" />}
                        radioCheckedIcon={( formData && formData.score && formData.score === 'pass' ? <ThumbUpIcon style={{ color: green[700] }} /> : ( formData && formData.score && formData.score === 'fail' ? <ThumbDownIcon color="error" /> : <CheckBoxOutlineBlankIcon color="disabled" /> ) )} 
                        onChange={handleUpdate} />
                </div>
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormSelect 
                    disabled={ isTeamBadgeIssued({ trainees, team: currentTeam }) ? true : ( formData && formData.score && formData.score === 'pass' ? false : true )}
                    label={"Nominate Badge"} 
                    name="nominated_badge_type" 
                    value={( formData && formData.badge && formData.badge.nominated_badge_type ? formData.badge.nominated_badge_type : '' )} 
                    options={badgesOptions} 
                    onChange={handleUpdate} />
                { formData && formData.badge && formData.badge.nominated_by && !_isEmpty( formData.badge.nominated_by ) ? (
                <div style={{ paddingTop: '5px' }}>
                    <Typography variant="body2">By: {formData.badge.nominated_by}</Typography>
                    <Typography variant="body2">On: { formData.badge.nominated_on ? getMomentTime( formData.badge.nominated_on, 'DD MMM YYYY' ) : '-' }</Typography>
                </div>
                ) : null }
            </Grid>

        </Grid>
    </>
    );
}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        currentShowcase: state.classes && state.classes.showcase ? state.classes.showcase : null,
    }
}

export default compose(
    connect(mapStateToProps)
)(TeamShowcaseForm);