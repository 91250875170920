/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
// import _merge from 'lodash/merge';
// import _size from 'lodash/size';
// import _isEqual from 'lodash/isEqual';

import AppWrapper from '../../components/AppWrapper';
import ScheduleSwitcher from '../../components/ScheduleSwitcher';
import Table from './table';
import ClassFilterMenu from './filter_menu';

import { isAdmin, getUserRegionFilter } from '../../helpers/auth';
// import { cloneCollections } from '../../helpers/data';

import { getStudentsList } from '../../actions/database_students';
// import { getCoursesOptions } from '../../actions/courses';
import { getSchedulesOptions } from '../../actions/schedules';
import { getRegions } from '../../actions/m_regions';
// import { getMaintenance } from '../../actions/maintenance';
import { resetRedux } from '../../actions/misc';

import { ButtonGroup, AInfoLink } from '../../styles/button';

const TableWrapper = styled.div`
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
`;

class DatabaseStudentsPage extends React.Component {

    state = {
        students: false,
        currentSchedule: false,
        currentRegion: false,
        dataLoaded: false
    };

    componentDidMount() {
        const { authData, regionsList, schedulesList, location } = this.props;

        // preload trainees
        if ( location && location.state && location.state.schedule_id && !_isEmpty( location.state.schedule_id ) )
            this.handleFilter({ 
                schedule_id: location.state.schedule_id, 
                start_date: moment().utcOffset(8).subtract(12,'months').startOf('date').valueOf(),
                end_date: moment().utcOffset(8).endOf('date').valueOf() 
            });

        // get regions
        if ( !regionsList )
            this.props.dispatch(getRegions());

        // get schedule options
        if ( !schedulesList )
            this.props.dispatch(getSchedulesOptions({ region: getUserRegionFilter(authData) }));

        if ( regionsList && schedulesList ) {
            this.setState({ dataLoaded: true });
        } // end - randNum
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { regionsList, schedulesList } = this.props;
        if ( regionsList && schedulesList && !this.state.dataLoaded ) {
            this.setState({ dataLoaded: true });
        } // end - randNum
    }

    componentWillUnmount() {
        this.handleReset();
    }

    handleReset = () => {
        this.props.dispatch(resetRedux('database_students'));
    }

    handleFilter = ({ region, schedule_id, start_date, end_date }) => {
        this.setState({ 
            currentSchedule: ( schedule_id && !_isEmpty( schedule_id ) ? schedule_id : false ), 
            currentRegion: ( region && !_isEmpty( region ) ? region : false ) 
        });
        this.props.dispatch(getStudentsList({ 
            region, schedule_id, start_date, end_date }));
    }

    renderTable() {
        const { authData, studentsList, regionsList, schedulesList } = this.props;
        const { currentSchedule, currentRegion } = this.state;
        return <Table
                currentRegion={( currentRegion || false )}
                currentSchedule={( currentSchedule || false )}
                students={studentsList}
                schedules={schedulesList}
                regions={regionsList}
                authData={authData} />;
    }

    renderContents() {
        const { studentsList } = this.props;
        const { currentSchedule } = this.state;
        return (
        <>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <ClassFilterMenu 
                        currentSchedule={( currentSchedule || false )}
                        onFilter={this.handleFilter} />
                </Grid>
            </Grid>

            { studentsList ? <TableWrapper>{this.renderTable()}</TableWrapper> : null }

        </>
        );
    }

    render() {
        const { dataLoaded, currentSchedule } = this.state;
        const { authData } = this.props;
        return <AppWrapper 
                title="Student Database"
                subtitle="3. Delivery"
                onLoad={( !( dataLoaded ) ? true : false )}
                customColumn={( currentSchedule && !_isEmpty( currentSchedule ) ? (
                    <ScheduleSwitcher
                        schedule_id={currentSchedule}
                        authData={authData} />
                ) : null )}
                contents={ authData ? this.renderContents() : null } />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        studentsList: state.database_students && state.database_students.list ? state.database_students.list : null,
        randNum: state.database_students && state.database_students.rand ? state.database_students.rand : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        schedulesList: state.schedules && state.schedules.schedules_options ? state.schedules.schedules_options : false,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(DatabaseStudentsPage);