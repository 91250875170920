/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _isEqual from 'lodash/isEqual';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';
import _random from 'lodash/random';

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader'
import SnackBarSave from '../../components/SnackBarSave';

import EditForm from '../SchedulePage/form';

import { InfoButton, GreyButton } from '../../styles/button';

import { isAdmin, hasAccessRights, getUserRegionFilter } from '../../helpers/auth'; 
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck, isSkip } from '../../helpers/schemas';

import { addSchedule, getSchedule } from '../../actions/schedules';
import { getProgramsOptions } from '../../actions/programs';
import { getUsersOptions } from '../../actions/users';
import { getTrainingLocationsOptions } from '../../actions/training_locations';
import { getRegions } from '../../actions/m_regions';
import { getLMSCourses } from '../../actions/canvas_lms';
import { getMaintenance } from '../../actions/maintenance';
import { appChangesMade, resetRedux } from '../../actions/misc';

import { scheduleSchema } from '../../schemas/schedule';

class ScheduleNewPage extends React.Component {

    state = {
        currentTab: 'details',
        schedule: false,
        randNum: false
    };

    componentDidMount() {
        const { location } = this.props;
        const { duplicate_id } = queryString.parse(location.search);
        const { authData, regionsList, usersOptionsList, contactHourList, productLevelList, productCreditList, courseTypeList, courseDurationList, pricePerPaxList, venueList, programsList, lmsCoursesList } = this.props;

        if ( duplicate_id && !_isEmpty( duplicate_id ) ) {
            this.props.dispatch(getSchedule(duplicate_id));
        } else {
            if ( regionsList && usersOptionsList && venueList && programsList && !this.state.randNum )
                this.setState({ schedule: this.getDefaultScheduleData(), randNum: true });
        }

        // get regions
        if ( !regionsList )
            this.props.dispatch(getRegions());

        // get users options
        if ( !usersOptionsList )
            this.props.dispatch(getUsersOptions({ region: getUserRegionFilter(authData) }));

        // get contact hour
        if ( !contactHourList )
            this.props.dispatch(getMaintenance('contact_hour'));

        // get product level
        if ( !productLevelList )
            this.props.dispatch(getMaintenance('product_level'));

        // get product credit
        if ( !productCreditList )
            this.props.dispatch(getMaintenance('product_credit'));

        // get course type
        if ( !courseTypeList )
            this.props.dispatch(getMaintenance('course_type'));

        // get course duration
        if ( !courseDurationList )
            this.props.dispatch(getMaintenance('course_duration'));

        // get price per pax list
        if ( !pricePerPaxList )
            this.props.dispatch(getMaintenance('price_per_pax'));

        // get venue options
        if ( !venueList )
            this.props.dispatch(getTrainingLocationsOptions({ region: getUserRegionFilter(authData) }));

        // get programs options
        if ( !programsList )
            this.props.dispatch(getProgramsOptions({ region: getUserRegionFilter(authData) }));

        // get lms courses
        if ( !lmsCoursesList )
            this.props.dispatch(getLMSCourses());

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { authData, regionsList, usersOptionsList, contactHourList, productLevelList, productCreditList, courseTypeList, courseDurationList, pricePerPaxList, venueList, programsList, lmsCoursesList, location, duplicateSchedule, scheduleRandNum } = this.props;
        const { duplicate_id } = queryString.parse(location.search);

            // for schedule
        if ( regionsList && usersOptionsList && contactHourList && productLevelList && productCreditList && courseTypeList && courseDurationList && pricePerPaxList && venueList && programsList && lmsCoursesList && !this.state.randNum ) {
            if ( duplicate_id && !_isEmpty( duplicate_id ) ) {
                if ( duplicateSchedule && !_isEmpty( duplicateSchedule ) && scheduleRandNum ) {
                    // make sure user has access to the class
                    this.setState({ schedule: this.getDefaultScheduleData(  isAdmin( authData ) || ( authData.region && duplicateSchedule.region && authData.region === duplicateSchedule.region ) ? duplicateSchedule : null ), randNum: true });              
                }
            } else {
                this.setState({ schedule: this.getDefaultScheduleData(), randNum: true });
            }
        } // end - currentSchedule

    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('schedule'));
    }

    handleRefresh = (type) => {
        // const { schedule_id } = this.props.match.params;
        // switch( type ) {
        //     case 'emails':
        //         this.setState({ emails: false });
        //         this.props.dispatch(getEmailsBy('courses',schedule_id));
        //         break;
        // }
    }

    handleSaveChanges = (event) => {
        const { history } = this.props;
        const { schedule } = this.state;
        event.preventDefault();
        var error = false,
            formData = {};

        // do error check
        scheduleSchema.forEach(schema => {
            formData[schema.id] = ( schedule && schedule[schema.id] ? cloneCollections( schedule[schema.id] ) : schema.default );
            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'add' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        // check for start date & end date
        if ( schedule.start_date > schedule.end_date ) {
            error = 'Start date cannot be after End date';
        }

        if ( schedule.end_date < schedule.start_date ) {
            error = 'End date cannot be before Start date';
        }

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addSchedule(formData,history));
        } // end - error

    }

    handleFormUpdate = (newValue) => {
        this.setState({ schedule: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    getDefaultScheduleData = ( duplicateData = null ) => {
        const { authData, dispatch } = this.props;
        let schedule = {};
        scheduleSchema.forEach(schema => {
            if ( !isSkip(schema,'add') ) {
                switch (schema.id) {
                    case 'region':
                        if ( duplicateData && duplicateData[schema.id] ) {
                            schedule[schema.id] = duplicateData[schema.id];
                            dispatch(appChangesMade());
                        } else {
                            schedule[schema.id] = ( authData && authData.region ? authData.region : '' );
                        }
                        break;
                    case 'start_date':
                        if ( duplicateData && duplicateData[schema.id] ) {
                            schedule[schema.id] = duplicateData[schema.id];
                        } else {
                            schedule[schema.id] = moment().utcOffset(8).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).valueOf();
                        }
                        break;
                    case 'end_date':
                        if ( duplicateData && duplicateData[schema.id] ) {
                            schedule[schema.id] = duplicateData[schema.id];
                        } else {
                            schedule[schema.id] = moment().utcOffset(8).set({ hour: 14, minute: 0, second: 0, millisecond: 0 }).valueOf();
                        }
                        break;
                    case 'registration_deadline':
                        if ( duplicateData && duplicateData[schema.id] ) {
                            schedule[schema.id] = duplicateData[schema.id];
                        } else {
                            schedule[schema.id] =  moment().utcOffset(8).add(2,'weeks').endOf('date').valueOf();
                        }
                        break;
                    default:
                        if ( duplicateData && duplicateData[schema.id] ) {
                            schedule[schema.id] = duplicateData[schema.id];
                        } else {
                            schedule[schema.id] = ( schema.default || null );
                        }
                        break;
                }
            } // end - schema
        });
        return schedule;
    }

    renderTabContents = () => {
        const { authData, usersOptionsList, regionsList, venueList, contactHourList, productLevelList, productCreditList, programsList, courseDurationList, courseTypeList, pricePerPaxList, lmsCoursesList, changesMade } = this.props;
        const { currentTab, schedule } = this.state;
        const tabProps = {
            authData: ( authData || false ),
            schedule: ( schedule || false ),
            changesMade: ( changesMade || false ),
            onFormUpdate: this.handleFormUpdate
        };
        switch( currentTab ) {
            case 'details':
                return <EditForm 
                    formType="add"
                    users={usersOptionsList}
                    contact_hour={contactHourList}
                    product_level={productLevelList}
                    product_credit={productCreditList}
                    course_type={courseTypeList}
                    course_duration={courseDurationList}
                    regions={regionsList}
                    locations={venueList}
                    programs={programsList}
                    price_per_pax={pricePerPaxList}
                    lms_courses={lmsCoursesList}
                    // updateActions={[getSchedule(schedule_id)]} 
                    {...tabProps} />;
        }
    }

    renderContents() {
        const { schedule } = this.state;
        return (
        <div>

            {this.renderTabContents()}

            { schedule && schedule.program_id && !_isEmpty( schedule.program_id ) ? (
            <>
                <div style={{ textAlign: "center", marginTop: "45px" }}>
                    <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Add New Schedule</InfoButton>
                </div>
                <SnackBarSave onSave={this.handleSaveChanges} saveNowLabel="Add New" />
            </>
            ) : null }

        </div>
        );
    }

    render() {
        const { randNum } = this.state;
        return <AppWrapper 
                title="Add New Class Schedule"
                subtitle="1. Recruitment"
                back="/schedules"
                breadcrumbs={[
                    { url: '/schedules', label: 'Class Schedule' },
                    { label: 'Add New' }
                ]}
                maxWidth="1110px"
                onLoad={( !( randNum ) ? true : false )}
                contents={this.renderContents() } />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        duplicateSchedule: state.schedules && state.schedules.schedule ? state.schedules.schedule : null,
        scheduleRandNum: state.schedules && state.schedules.rand ? state.schedules.rand : null,
        usersOptionsList: state.users && state.users.users_options ? state.users.users_options : false,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        venueList: state.training_locations && state.training_locations.locations_options ? state.training_locations.locations_options : false,
        programsList: state.programs && state.programs.programs_options ? state.programs.programs_options : false,
        courseTypeList: state.maintenance && state.maintenance.course_type ? state.maintenance.course_type : null,
        courseDurationList: state.maintenance && state.maintenance.course_duration ? state.maintenance.course_duration : null,
        pricePerPaxList: state.maintenance && state.maintenance.price_per_pax ? state.maintenance.price_per_pax : null,
        contactHourList: state.maintenance && state.maintenance.contact_hour ? state.maintenance.contact_hour : null,
        productLevelList: state.maintenance && state.maintenance.product_level ? state.maintenance.product_level : null,
        productCreditList: state.maintenance && state.maintenance.product_credit ? state.maintenance.product_credit : null,
        lmsCoursesList: state.canvas_lms && state.canvas_lms.courses ? state.canvas_lms.courses : null,
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ScheduleNewPage);