/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import grey from "@material-ui/core/colors/grey";
import _isEmpty from "lodash/isEmpty";

import { isArrayExists, isObjectExists } from "../../helpers/validation";
import { cloneCollections } from "../../helpers/data";

import theme from "../../theme";

import {
    shortcodesConfirmationEmail,
    shortcodesReminderEmail,
    shortcodesInvoiceEmail,
    shortcodesPaymentReminderEmail,
    shortcodesRegistrationReceiptEmail,
    shortcodesLoginReminderEmail,
    shortcodesWithDrawalNoticeEmail,
    shortcodesQuarterlyReportEmail,
    shortcodesShowcaseEmail,
    shortcodesShowcaseProjectEmail,
    shortcodesMentorLMSEmail,
    shortcodesStudentLMSEmail,
    shortcodesParentLMSEmail,
    shortcodesTrialEmail,
    shortcodesBadgeOneOffIssuedEmail,
    shortcodesShowcaseOutstandingTasksStudentEmail,
    shortcodesShowcaseOutstandingTasksMentorEmail,
    shortcodesShowcaseBadgeApprovedEmail,
} from "../../data/email_shortcodes";

const ShortCodeItem = styled.div`
    margin-bottom: 10px;
    div {
        font-size: 16px;
        color: ${grey["600"]};
    }
    h6 {
        font-size: 12px;
    }
`;

const useStyles = (theme) => ({
    boxheading: {
        fontSize: "20px",
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd",
    },
    shortcodebox: {
        margin: "20px 0",
        padding: "20px",
        background: grey["50"],
        border: "1px solid " + grey["200"],
        height: "100%",
    },
});

class EmailShortcodes extends React.Component {
    getShortcodes = () => {
        const { email_type } = this.props;
        let options = [];
        if (email_type && !_isEmpty(email_type)) {
            switch (email_type) {
                case "confirmation_email":
                    options = cloneCollections(shortcodesConfirmationEmail);
                    break;
                case "reminder":
                    options = cloneCollections(shortcodesReminderEmail);
                    break;
                case "invoice":
                    options = cloneCollections(shortcodesInvoiceEmail);
                    break;
                case "payment_reminder":
                    options = cloneCollections(shortcodesPaymentReminderEmail);
                    break;
                case "registration_receipt":
                    options = cloneCollections(shortcodesRegistrationReceiptEmail);
                    break;
                case "login_reminder":
                    options = cloneCollections(shortcodesLoginReminderEmail);
                    break;
                case "withdrawal_notice":
                    options = cloneCollections(shortcodesWithDrawalNoticeEmail);
                    break;
                case "send_report":
                    options = cloneCollections(shortcodesQuarterlyReportEmail);
                    break;
                case "mentor_activation_email":
                    options = cloneCollections(shortcodesMentorLMSEmail);
                    break;
                case "student_activation_email":
                    options = cloneCollections(shortcodesStudentLMSEmail);
                    break;
                case "trial_invitation_email":
                    options = cloneCollections(shortcodesTrialEmail);
                    break;
                case "parent_activation_email":
                    options = cloneCollections(shortcodesParentLMSEmail);
                    break;
                case "teacher_activation_email":
                    options = cloneCollections(shortcodesParentLMSEmail);
                    break;
                case "badge_issued_email":
                    options = cloneCollections(shortcodesShowcaseEmail);
                    break;
                case "showcase_project_email":
                    options = cloneCollections(shortcodesShowcaseProjectEmail);
                    break;
                case "badge_oneoff_issued_email":
                    options = cloneCollections(shortcodesBadgeOneOffIssuedEmail);
                    break;
                case "showcase_outstanding_tasks_student":
                    options = cloneCollections(shortcodesShowcaseOutstandingTasksStudentEmail);
                    break;
                case "showcase_outstanding_tasks_mentor":
                    options = cloneCollections(shortcodesShowcaseOutstandingTasksMentorEmail);
                    break;
                case "showcase_badge_approved":
                    options = cloneCollections(shortcodesShowcaseBadgeApprovedEmail);
                    break;
            }
        } // end - email_type
        return options;
    };

    renderShortcodes = () => {
        const shortcodes = this.getShortcodes();
        return shortcodes && isArrayExists(shortcodes)
            ? shortcodes.map((item) => {
                  return (
                      <ShortCodeItem key={item.id}>
                          <Typography variant="h6">{item.label}</Typography>
                          <div>{item.id}</div>
                      </ShortCodeItem>
                  );
              })
            : null;
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.shortcodebox}>
                <Typography variant="h4" className={classes.boxheading}>
                    Available Shortcodes
                </Typography>
                {this.renderShortcodes()}
            </div>
        );
    }
}

export default compose(withStyles(useStyles))(EmailShortcodes);
