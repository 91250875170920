/* eslint-disable */
import _random from 'lodash/random';

import { 
    GET_HQ_SUMMARY,
    UPDATE_COURSE_ACCESS_REPORT,
    RESET_HQ_SUMMARY 
} from '../actions/types';

const initialState = {
    list: null,
    updated: false,
    rand: false
};

export const hq_summary = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_HQ_SUMMARY:
            return {
                ...state,
                list: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case UPDATE_COURSE_ACCESS_REPORT:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_HQ_SUMMARY:
            return {
                ...state,
                list: null,
                updated: false,
                rand: false
            };
        default:
            return state;
    }
}