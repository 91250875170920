/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import blue from '@material-ui/core/colors/blue';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton, FacebookIcon, TwitterIcon, LinkedinIcon, EmailIcon } from 'react-share';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _split from 'lodash/split';
import _map from 'lodash/map';

// import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists, isObjectExists, validURL } from '../../helpers/validation';
import { cloneCollections,  } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';

import { ButtonGroup, AIndigoLink, ASuccessLink } from '../../styles/button';

import { badgeSchema } from '../../schemas/badge';


import { PLATFORM_LEGACY_BADGE_TYPES, PLATFORM_BADGE_URL, PLATFORM_CERT_URL, PLATFORM_BACKPACK_URL } from '../../constants';


const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        borderBottom: "2px solid #212121",
        paddingBottom: '20px',
        marginBottom: '20px'
    },
    itemheading: {
        fontWeight: "700",
        paddingBottom: '5px',
    }
});

const Wrapper = styled.div`
    display: block;
`;

const ItemWrapper = styled.div`
    display: block;
    padding: 0 20px;
    margin-bottom: 20px;

    p {
        margin-bottom: 15px;
        &:last-child { margin-bottom: 0px; }
    }

    ul {
        list-style: circle;
        margin-left: 15px;
        margin-bottom: 15px;
        li {
            margin-bottom: 8px;
            &:last-child { margin-bottom: 0px; }
        }
    }

    ol {
        list-style: number;
        margin-left: 15px;
        margin-bottom: 15px;
    }

    strong { font-weight: 700; }

    a {
        font-weight: 700;
        color: ${blue[500]};
        &:hover { color: ${blue[700]}; }
    }
`;

const ItemContent = styled.div`
    display: block;
    padding-left: 15px;
`;

const BadgeImage = styled.div`
    display: block;
    text-align: center;
    min-height: 200px;
    img {
        display: inline-block;
        width: 100%;
        height: auto;
        max-width: 300px;
    }
`;

const BadgeTag = styled.span`
    display: inline-block;
    padding: 5px 8px;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    background: ${blue[500]};
    border-radius: 4px;
    margin: 0 5px 5px 0;
`;

const SocialLinks = styled.div`
    display: flex;
    margin-top: 30px;
    justify-content: center;
    align-items: center;

    & > div {
        display: inline-block;
        margin: 0 15px 5px 0;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    }

`;

class BadgeDetails extends React.Component {

    isOneOffBadge = () => {
        const { badge } = this.props;
        return ( badge && badge.references && badge.references.course_id && badge.references.course_id === 'oneoff' ? true : false );
    }

    getBadgeData = () => {
        const { badge } = this.props;
        let data = ( badge && isObjectExists( badge ) ? cloneCollections( badge ) : {} );
        return data;
    }

    renderProjectFeedback = (content) => {
        let data = _split( content , /(?:\r\n|\r|\n)/g );
        return ( data && isArrayExists( data ) ? _map( data, (string,index) => {
            return <div key={index} style={{ marginBottom: '5px' }}>{string}</div>;
        }) : null );
    }

    renderBadgeType = () => {
        const badge = this.getBadgeData();
        let isLegacyType = ( badge && badge.type ? _find( PLATFORM_LEGACY_BADGE_TYPES, { value: badge.type } ) : false );
        return ( isLegacyType && isLegacyType.label ? isLegacyType.label : ( badge.type_label && !_isEmpty( badge.type_label ) ? badge.type_label : '' ) );
    }

    renderLatterPart = () => {
        const { classes } = this.props;
        const badge = this.getBadgeData();
        return (
        <div style={{ paddingTop: '20px' }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} style={{ padding: '20px' }}>
                    <Typography variant="h5" className={classes.itemheading}>Description</Typography>
                    <ItemContent><div dangerouslySetInnerHTML={{ __html: ( badge.description || '' ) }}/></ItemContent>
                </Grid>
                <Grid item xs={12} sm={6} style={{ padding: '20px' }}>
                    <Typography variant="h5" className={classes.itemheading}>Criteria</Typography>
                    <ItemContent><div dangerouslySetInnerHTML={{ __html: ( badge.criteria_text || '' ) }}/></ItemContent>
                </Grid>
                <Grid item xs={12} sm={6} style={{ padding: '20px' }}>
                    <Typography variant="h5" className={classes.itemheading}>Issuer</Typography>
                    <ItemContent><a href={( badge.issuer_url || '#' )} target="_blank">{( badge.issuer_name || '' )}</a></ItemContent>
                </Grid>
                <Grid item xs={12} sm={6} style={{ padding: '20px' }}>
                    <Typography variant="h5" className={classes.itemheading}>Tags</Typography>
                    <ItemContent>
                    { badge.tags && isArrayExists( badge.tags ) ? badge.tags.map(tag => <BadgeTag key={tag}>{tag}</BadgeTag>) : '---' }
                    </ItemContent>
                </Grid>
                <Grid item xs={12} sm={12} style={{ padding: '20px', textAlign: 'center' }}>
                    { badge && badge.cert_referer && !_isEmpty( badge.cert_referer ) ? <AIndigoLink size="small" href={PLATFORM_CERT_URL + badge.id} target="_blank"><i className="fa fa-certificate"></i>Print PDF Cert</AIndigoLink> : null }
                </Grid>
            </Grid>
        </div>
        )
    }

    renderCenterPart = () => {
        const { classes } = this.props;
        const badge = this.getBadgeData();
        return (
        <div style={{ borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd', marginTop: '20px' }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <div style={{ padding: '30px 0', textAlign: 'center' }}>
                        <div>
                            <Typography variant="h5" className={classes.itemheading}>Awarded to</Typography>
                            <a href={PLATFORM_BACKPACK_URL+badge.user_id}><Typography variant="h4" className={classes.itemheading}>{badge.user_name || '---'}</Typography></a>
                        </div>
                        { !this.isOneOffBadge() && (
                        <div style={{ paddingTop: '10px' }}>
                            { badge.program_name && !_isEmpty( badge.program_name ) && (
                            <>
                                for <strong style={{ fontWeight: '700' }}>{badge.program_name || ''}</strong><br />
                            </>
                            ) }
                            for <strong style={{ fontWeight: '700' }}>{badge.course_duration_label || ''}</strong>
                        </div>
                        )}
                    </div>
                </Grid>
                { !this.isOneOffBadge() && (
                <Grid item xs={12} sm={12}>
                    <div style={{ padding: '30px 0', borderTop: '1px solid #ddd' }}>
                        <ItemWrapper>
                            <Typography variant="h5" className={classes.itemheading}>Evidence</Typography>
                            <ItemContent>
                                { badge.team_module && badge.team_module === 'yes' ? ( badge.team_projects && isArrayExists( badge.team_projects ) ? badge.team_projects.map(project => {
                                    return <div key={project.id}>{project.id}. { project.desc && !_isEmpty( project.desc ) && validURL( project.desc ) ? <a href={project.desc} target="_blank" rel="nofollow">{( project.title || '---' )}</a> : ( project.title || '---' ) }</div>
                                }) : null ) : (
                                <div>{ badge.user_project_link && !_isEmpty( badge.user_project_link ) ? <a href={badge.user_project_link} target="_blank" rel="nofollow">{( badge.user_project_title || '---' )}</a> : ( badge.user_project_title || '---' ) }</div>
                                )}
                            </ItemContent>
                        </ItemWrapper>
                        { !( badge.team_module && badge.team_module === 'yes' ) && (
                        <ItemWrapper>
                            <Typography variant="h5" className={classes.itemheading}>Mentor's Feedback:</Typography>
                            <ItemContent>
                                { badge.user_project_feedback && !_isEmpty( badge.user_project_feedback ) ? <div style={{ paddingTop: '10px' }}>{this.renderProjectFeedback(badge.user_project_feedback)}</div> : '' }
                            </ItemContent>
                        </ItemWrapper>) }
                    </div>
                </Grid>
                ) }
            </Grid>
        </div>
        );
    }

    renderTopFoldContent = () => {
        const { preview, classes } = this.props;
        const badge = this.getBadgeData();
        let title = ( badge.issuer_name || '' ) + ' - ' + ( badge.name || '' ),
            twitter_title = 'Yay! I have earned a digital badge in '+( badge.name || '' )+( badge.type ? ' ( '+badge.type+' )' : ''  ),
            desc = badge.user_name + ' has earned a digital badge in '+( badge.name || '' )+( badge.type ? ' ( '+badge.type+' )' : ''  ),
            share_url = PLATFORM_BADGE_URL + badge.id;
        return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={4} style={{ textAlign: 'left' }}>
                <Typography variant="h4" className={classes.itemheading}>{( !this.isOneOffBadge() ? ( badge.course_code &&  !_isEmpty( badge.course_code ) ? badge.course_code : '' )  : '' )}</Typography>
                <Typography variant="h4" className={classes.itemheading}>{( badge.name || '' )}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} style={{ textAlign: 'center' }}>
                <BadgeImage>
                    <img src={( badge.image || '' )} />
                </BadgeImage>
            </Grid>
            <Grid item xs={12} sm={4} style={{ textAlign: 'right' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'space-around', height: '100%' }}>
                    <div>
                        <Typography variant="h4" className={classes.itemheading}>{ badge.created_on && badge.created_on._seconds ? getMomentTime( badge.created_on._seconds*1000, 'DD MMM YYYY' ) : '---'}</Typography>
                        <Typography variant="h4" className={classes.itemheading}>{this.renderBadgeType()}</Typography>
                    </div>
                    <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                        <SocialLinks>
                            <div>Share This:</div>
                            <div>
                                <Tooltip title={<span style={{ fontSize: '12px', padding: '2px' }}>{( preview ? "Sharing option disabled in preview mode" : "Share this to Facebook")}</span>} arrow placement="top">
                                    <div>{ preview ? <FacebookIcon size={32} round={true} /> : (
                                    <FacebookShareButton
                                        className="showcase-sharing-item" 
                                        quote={desc}
                                        url={share_url} >
                                        <FacebookIcon size={32} round={true} />
                                    </FacebookShareButton>) }</div>
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title={<span style={{ fontSize: '12px', padding: '2px' }}>{( preview ? "Sharing option disabled in preview mode" : "Share this to Linkedin")}</span>} arrow placement="top">
                                    <div>{ preview ? <LinkedinIcon size={32} round={true} /> : (
                                    <LinkedinShareButton 
                                        title={title}
                                        description={desc}
                                        className="showcase-sharing-item"
                                        url={share_url}>
                                        <LinkedinIcon size={32} round={true} />
                                    </LinkedinShareButton>
                                    ) }</div>
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title={<span style={{ fontSize: '12px', padding: '2px' }}>{( preview ? "Sharing option disabled in preview mode" : "Share this to Twitter")}</span>} arrow placement="top">
                                    <div>{ preview ? <TwitterIcon size={32} round={true} /> : (
                                    <TwitterShareButton 
                                        title={twitter_title}
                                        className="showcase-sharing-item"
                                        url={share_url}>
                                        <TwitterIcon size={32} round={true} />
                                    </TwitterShareButton>
                                    ) }</div>
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title={<span style={{ fontSize: '12px', padding: '2px' }}>{( preview ? "Sharing option disabled in preview mode" : "Export to Badgr by linking service")}</span>} arrow placement="top">
                                    <div>{ preview ? <img src="https://backpack.openbadges.org/images/logo.svg" style={{ height: '32px' }} /> : (
                                    <a 
                                        className="showcase-sharing-item"
                                        href={ badge.lms_user_id ? `https://lms.chumbaka.asia/users/${badge.lms_user_id}/external_tools/13` : "https://lms.chumbaka.asia"}
                                        target="_blank">
                                        <img src="https://backpack.openbadges.org/images/logo.svg" style={{ height: '32px' }} />
                                    </a>
                                    ) }</div>
                                </Tooltip>
                            </div>
                        </SocialLinks>
                    </div>
                </div>
            </Grid>
        </Grid>
        )
    }

    render = () => {
        return (
        <Wrapper>
            {this.renderTopFoldContent()}
            {this.renderCenterPart()}
            {this.renderLatterPart()}
        </Wrapper>
        );
    }

}

export default compose(
    withStyles(useStyles),
    withRouter
)(BadgeDetails);