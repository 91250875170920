/* eslint-disable */
import React, { useMemo, useState, useEffect } from 'react';
import styled from "styled-components";
import moment from 'moment';
import Papa from 'papaparse';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _toLower from 'lodash/toLower';
import _split from 'lodash/split';

import MassGeneration from './mass_generation';

import { ButtonGroup, InfoButton } from '../../styles/button';
import { GreyTag } from '../../styles/tag';

import { isArrayExists } from '../../helpers/validation';
import { getMomentTime } from '../../helpers/date';


const Wrapper = styled.div`
    width: 100%;
    max-width: 1080px;
    padding: 30px;
    border: 2px solid #ddd;
    margin-bottom: 20px;
    background: #f1f1f1;

    h4, h5 {
        font-weight: 700;
    }
`;

const LatestStats = ({
    list,
    year,
    region = '',
    regions = [],
    onGenerate = () => {},
    onMassGeneration = () => {}
}) => {
    const [ selectedMonth, setSelectedMonth ] = useState(false);

    useEffect(() => {
        let data = { id: '', label: '' };
        const currentYear = moment().utcOffset(8).year();
        if ( year === currentYear.toString() && year === moment().utcOffset(8).subtract(1,'months').format('YYYY') ) {
            data.id = year+moment().utcOffset(8).subtract(1,'months').format('MM');
            data.label = moment().utcOffset(8).subtract(1,'months').format('MMM-YY');
        } else {
            let done = false;
            for (let index = 12; index > 0; index--) {
                if ( !done ) {
                    const selected = _find( list, { month: year+( index > 9 ? index : '0'+index ) } );
                    if ( selected ) {
                        data.id = selected.id;
                        data.label = moment(selected.id).utcOffset(8).startOf('month').format('MMM-YY');
                        done = true;
                    }
                }
                if ( index === 1 && !done ) {
                    data.id = year+'0'+index;
                    data.label = moment(year).utcOffset(8).startOf('year').format('MMM-YY');
                    done = true;
                }
            }
        }
        setSelectedMonth( data );
    }, [ year ]);

    const availableMonths = useMemo(() => {
        let options = [];
        const currentYear = moment().utcOffset(8).year();
        if ( list && isArrayExists( list ) ) {
            _forEach( list, ( item ) => {
                if ( !_find( options, { id: item.month } ) ) {
                    options.push({
                        id: item.month,
                        label: moment(item.month).utcOffset(8).startOf('month').format('MMM-YY')
                    });
                }
            });
        }
        // make sure the current month is available
        if ( year === currentYear.toString() && year === moment().utcOffset(8).subtract(1,'months').format('YYYY') ) {
            let currentMonth = year+moment().utcOffset(8).subtract(1,'months').format('MM');
            if ( !_find( options, { id: currentMonth } ) ) {
                options.push({
                    id: currentMonth,
                    label: moment().utcOffset(8).subtract(1,'months').format('MMM-YY')
                });
            }
        } 

        // really current month - for testing only
        // options.push({
        //     id: year+moment().utcOffset(8).format('MM'),
        //     label: moment().utcOffset(8).format('MMM-YY')
        // });

        return _sortBy( options, [ 'id' ] );
    },[list,year]);

    const isAllRegions = useMemo(() => {
        return region && region === 'all' ? true : false;
    }, [ region ]);

    const isPassedMonth = useMemo(() => {
        let currentMonthTime = moment().utcOffset(8).subtract(1,'months').startOf('month').valueOf(),
            selectedMonthTime = moment(selectedMonth.id).utcOffset(8).startOf('month').valueOf();
        if ( currentMonthTime > selectedMonthTime ) {
            return true;
        }
        return false;
    }, [ selectedMonth ]);

    const latestMonthData = useMemo(() => {
        return _find( list, (i) => {
            return i.month === selectedMonth.id;
        } );
    }, [ list, selectedMonth, isAllRegions ]);

    const totalActiveClasses = useMemo(() => {
        let count = 0;
        if ( latestMonthData && latestMonthData.classes && isArrayExists( latestMonthData.classes ) ) {
            _forEach( latestMonthData.classes, schedule => {
                if ( schedule.current_active_class_size ) {
                    count ++;
                }
            });
        }
        return count;
    }, [ latestMonthData ]);

    const totalActiveStudents = useMemo(() => {
        let count = 0;
        if ( latestMonthData && latestMonthData.classes && isArrayExists( latestMonthData.classes ) ) {
            _forEach( latestMonthData.classes, schedule => {
                if ( schedule.current_active_class_size ) {
                    count += parseInt( schedule.current_active_class_size );
                }
            });
        }
        return count;
    }, [ latestMonthData ]);

    const totalActiveProducts = useMemo(() => {
        let count = 0;
        if ( latestMonthData && latestMonthData.classes && isArrayExists( latestMonthData.classes ) ) {
            _forEach( latestMonthData.classes, schedule => {
                if ( schedule.total_active_products ) {
                    count += parseInt( schedule.total_active_products );
                }
            });
        }
        return count;
    }, [ latestMonthData ]);

    const totalRegistrations = useMemo(() => {
        let count = 0;
        if ( latestMonthData && latestMonthData.classes && isArrayExists( latestMonthData.classes ) ) {
            _forEach( latestMonthData.classes, schedule => {
                if ( schedule.total_registrations ) {
                    count += parseInt( schedule.total_registrations );
                }
            });
        }
        return count;
    }, [ latestMonthData ]);

    const totalConfirmedStudents = useMemo(() => {
        let count = 0;
        if ( latestMonthData && latestMonthData.classes && isArrayExists( latestMonthData.classes ) ) {
            _forEach( latestMonthData.classes, schedule => {
                if ( schedule.total_confirmed_students ) {
                    count += parseInt( schedule.total_confirmed_students );
                }
            });
        }
        return count;
    }, [ latestMonthData ]);

    const totalBadgesAwarded = useMemo(() => {
        let count = 0;
        if ( latestMonthData && latestMonthData.classes && isArrayExists( latestMonthData.classes ) ) {
            _forEach( latestMonthData.classes, schedule => {
                if ( schedule.total_badges_awarded ) {
                    count += parseInt( schedule.total_badges_awarded );
                }
            });
        }
        return count;
    }, [ latestMonthData ]);

    const handleGenerateData = () => {
        onGenerate( selectedMonth.id );
    }

    const doCSVDownload = (results, name) => {
        let csv = Papa.unparse( results, { delimiter: ',' } );
        let blob = new Blob( [csv] , { type: 'text/csv;charset=utf-8;' }); //new way
        let uri = window.URL.createObjectURL(blob);
        let link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", '_blank');
        link.setAttribute("download", "export_business_dashboard_"+name+".csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleMentorsDownload = () => {
        let csvData = {
            fields: ['Partner','Name','Email','Classes Mentoring','Added On'],
            data: []
        };

        if ( latestMonthData && latestMonthData.mentors && isArrayExists( latestMonthData.mentors ) ) {
            _forEach( latestMonthData.mentors, mentor => {
                let row = [],
                    classes_mentoring = '';
                row.push( mentor.partner || '' );
                row.push( mentor.name || '' );
                row.push( mentor.email || '' );

                if ( mentor.classes_mentoring && isArrayExists( mentor.classes_mentoring ) ) {
                    _forEach( mentor.classes_mentoring, class_mentoring => {
                        classes_mentoring += ( !_isEmpty( classes_mentoring ) ? '\n' : '' ) + class_mentoring.class_name || '';
                    })
                }
                row.push( classes_mentoring || '' );

                row.push( getMomentTime(mentor.added_on,'DD-MMM-YY') );
                csvData.data.push( row );
            });
        }

        doCSVDownload( csvData, 'mentors_' + latestMonthData.id );
    }

    const handleClassesDownload = () => {
        let csvData = {
            fields: ['Year-Month','Partner','Class Name','Principal','Coordinator','Venue','Class Day & Time','Rolling Basis','Start Date','Current Active Class Size','# Registration','# Confirmed Students','# Active Products','# Badges Pending Nomination','# Badges Pending Confirmation','# Badges Awarded '],
            data: []
        };

        if ( latestMonthData && latestMonthData.classes && isArrayExists( latestMonthData.classes ) ) {
            _forEach( latestMonthData.classes, classData => {
                let row = [];
                row.push( latestMonthData.id );

                row.push( classData.partner || '' );
                row.push( classData.class_name || '' );
                row.push( classData.principal || '' );
                row.push( classData.coordinator || '' );
                row.push( classData.venue || '' );
                row.push( classData.class_day_time || '' );
                row.push( classData.rolling_basis || '' );
                row.push( getMomentTime(classData.start_date,'DD-MMM-YY') );
                row.push( classData.current_active_class_size || '0' );
                row.push( classData.total_registrations || '0' );
                row.push( classData.total_confirmed_students || '0' );
                row.push( classData.total_active_products || '0' );
                row.push( classData.total_badges_pending_nomination || '0' );
                row.push( classData.total_badges_pending_confirmation || '0' );
                row.push( classData.total_badges_awarded || '0' );

                csvData.data.push( row );
            });
        }

        doCSVDownload( csvData, 'classes_' + latestMonthData.id );
    }

    return (
    <Wrapper>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
                <Typography variant='h4' style={{ marginBottom: '20px' }}>
                    Latest Data: 
                    <select 
                        value={( selectedMonth && selectedMonth.id ) || '' } 
                        onChange={(e) => {
                            setSelectedMonth(_find( availableMonths, { id: e.target.value } ));
                        }}
                        style={{ marginLeft: '10px', fontSize: '18px' }}>
                        {availableMonths.map(month => (<option key={month.id} value={month.id}>{month.label}</option>))}
                    </select>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
            { isAllRegions && !isPassedMonth && (
                <>
                    <MassGeneration
                        list={list}
                        year={year}
                        month={( selectedMonth && selectedMonth.id ) || ''}
                        regions={regions}
                        onMassGeneration={onMassGeneration}
                        />
                </>
                )}
            </Grid>
        </Grid>
        { latestMonthData ? (
        <Grid container spacing={4}>
            <Grid item xs={4}>
                <Typography variant='h5' style={{ marginBottom: '10px' }}>Mentor</Typography>
                <Card variant="outlined" style={{ backgroundColor: '#fff' }}>
                    <CardContent>
                        <Typography variant='h1' align='center'>{ latestMonthData && latestMonthData.mentors && isArrayExists( latestMonthData.mentors ) ? _size( latestMonthData.mentors ) : '0' }</Typography>
                        <Typography variant='body1' align='center' style={{ marginTop: '10px' }}># Mentors</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={4}>
                <Typography variant='h5' style={{ marginBottom: '10px' }}>Curriculum</Typography>
                <Card variant="outlined" style={{ backgroundColor: '#fff', marginBottom: '10px' }}>
                    <CardContent>
                        <Typography variant='h1' align='center'>{ totalActiveClasses || '0' }</Typography>
                        <Typography variant='body1' align='center' style={{ marginTop: '10px' }}># Classes with Active Students</Typography>
                    </CardContent>
                </Card>
                <Card variant="outlined" style={{ backgroundColor: '#fff', marginBottom: '10px' }}>
                    <CardContent>
                        <Typography variant='h1' align='center'>{ totalActiveProducts || '0' }</Typography>
                        <Typography variant='body1' align='center' style={{ marginTop: '10px' }}># Active Products</Typography>
                    </CardContent>
                </Card>
                <Card variant="outlined" style={{ backgroundColor: '#fff', marginBottom: '10px' }}>
                    <CardContent>
                        <Typography variant='h1' align='center'>{ totalBadgesAwarded || '0' }</Typography>
                        <Typography variant='body1' align='center' style={{ marginTop: '10px' }}># Badges Awarded</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={4}>
                <Typography variant='h5' style={{ marginBottom: '10px' }}>Technology</Typography>
                <Card variant="outlined" style={{ backgroundColor: '#fff', marginBottom: '10px' }}>
                    <CardContent>
                        <Typography variant='h1' align='center'>{ totalRegistrations || '0' }</Typography>
                        <Typography variant='body1' align='center' style={{ marginTop: '10px' }}># Registration</Typography>
                    </CardContent>
                </Card>
                <Card variant="outlined" style={{ backgroundColor: '#fff', marginBottom: '10px' }}>
                    <CardContent>
                        <Typography variant='h1' align='center'>{ totalConfirmedStudents || '0' }</Typography>
                        <Typography variant='body1' align='center' style={{ marginTop: '10px' }}># Confirmed Students</Typography>
                    </CardContent>
                </Card>
                <Card variant="outlined" style={{ backgroundColor: '#fff', marginBottom: '10px' }}>
                    <CardContent>
                        <Typography variant='h1' align='center'>{ totalActiveStudents || '0' }</Typography>
                        <Typography variant='body1' align='center' style={{ marginTop: '10px' }}># Active Students</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <ButtonGroup>
                    <GreyTag>Download Data as CSV:</GreyTag>
                    <InfoButton minWidth="168px" style={{ marginRight: "5px", padding: '10px 25px' }} onClick={handleMentorsDownload}><i className="fa fa-file-excel-o"></i>Mentors Data</InfoButton>
                    <InfoButton minWidth="168px" style={{ marginRight: "5px", padding: '10px 25px' }} onClick={handleClassesDownload}><i className="fa fa-file-excel-o"></i>Classes Data</InfoButton>
                </ButtonGroup>
            </Grid>
        </Grid>
        ) : (
        <>
            <div style={{ margin: '0px 0 5px 0' }}>
                <Typography variant='body1'>No data available</Typography>
            </div>
            { !isAllRegions && <InfoButton style={{ padding: '5px 15px' }} onClick={handleGenerateData}><i className="fa fa-gear"></i>Generate Data Now</InfoButton> }
        </>
        ) }
    </Wrapper>
    )
}

export default LatestStats;