/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getStudentFeedbackForm = (schedule_id,lms_course_id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'studentShowcase', action: 'get_feedback_form', formData: { schedule_id, lms_course_id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateStudentFeedbackForm = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'studentShowcase', action: 'update_feedback_form', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}