/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _size from 'lodash/size';
import _isEqual from 'lodash/isEqual';
import _findIndex from 'lodash/findIndex';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';

import AppWrapper from '../../components/AppWrapper';
import Table from './table';
import FilterMenu from './filter_menu';
import Summary from './summary';

import { isAdmin } from '../../helpers/auth';
import { cloneCollections } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isArrayExists } from '../../helpers/validation';

import { getHQSummary } from '../../actions/hq_summary';
import { getRegions } from '../../actions/m_regions';
import { getLMSCourses } from '../../actions/canvas_lms';
import { resetRedux } from '../../actions/misc';

import { ButtonGroup, AInfoLink, InfoButton } from '../../styles/button';
import { SuccessTag } from '../../styles/tag';
import { WarningBox, InfoBox } from '../../styles/message';

// import { ENROLLMENT_STATUS_OPTIONS } from '../../constants';

const TableWrapper = styled.div`
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
`;

class HQSummary extends React.Component {

    utcOffSet = 8;

    state = {
        list: false,
        month: false,
        randNum: false,
        dataLoaded: false
    };

    componentDidMount() {
        const { regionsList, lmsCoursesList } = this.props;

        // get regions
        if ( !regionsList ) 
            this.props.dispatch(getRegions());

        // get lms courses
        if ( !lmsCoursesList )
            this.props.dispatch(getLMSCourses());
        
        if ( regionsList && lmsCoursesList ) {
            this.setState({ dataLoaded: true });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { courseAccessList, regionsList, lmsCoursesList, randNum } = this.props;

        if ( regionsList && lmsCoursesList && !this.state.dataLoaded ) {
            this.setState({ dataLoaded: true });
        } // end - randNum

        if ( courseAccessList && courseAccessList && randNum && randNum !== this.state.randNum ) {
            this.setState({ 
                randNum, 
                list: ( courseAccessList && isArrayExists( courseAccessList ) ? cloneCollections( courseAccessList ) : [] ),
            });
        } // end - randNum
    }

    componentWillUnmount() {
        this.handleReset();
    }

    handleReset = () => {
        this.props.dispatch(resetRedux('hq_summary'));
    }

    handleFilter = (props) => {
        const { authData } = this.props;
        this.setState({ list: [], randNum: false, month: props.month });
        this.props.dispatch(getHQSummary(props));
    }

    renderTable() {
        const { authData, courseAccessList, regionsList, lmsCoursesList } = this.props;
        const { list } = this.state;
        return <Table
                list={list}
                regions={regionsList}
                lms_courses={lmsCoursesList}
                authData={authData} />;
    }

    renderContents() {
        const { courseAccessList, regionsList } = this.props;
        return (
        <>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={5}>
                    <FilterMenu 
                        regions={regionsList}
                        onFilter={this.handleFilter} />
                </Grid>
                <Grid item xs={12} sm={7}>
                    { courseAccessList && this.state.month && (
                    <Summary
                        month={this.state.month}
                        list={courseAccessList} />
                    )}
                </Grid>
            </Grid>

            { courseAccessList ? (
            <TableWrapper>
                {this.renderTable()}
            </TableWrapper>
            ) : null }


        </>
        );
    }

    render() {
        const { dataLoaded } = this.state;
        const { authData } = this.props;
        return <AppWrapper 
                subtitle="Summary"
                title="Report of Course Access"
                onLoad={( !( dataLoaded ) ? true : false )}
                contents={ authData ? this.renderContents() : null } />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        courseAccessList: state.hq_summary && state.hq_summary.list ? state.hq_summary.list : null,
        randNum: state.hq_summary && state.hq_summary.rand ? state.hq_summary.rand : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        lmsCoursesList: state.canvas_lms && state.canvas_lms.courses ? state.canvas_lms.courses : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(HQSummary);