/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';
import _toString from 'lodash/toString';

import ModalView from '../../components/ModalView';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';
import FormDatePicker from '../../components/FormDatePicker';
import FormCheckbox from '../../components/FormCheckbox';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getAPIErrorMessage } from '../../helpers/action';

import { InverseButton, GreyButton } from '../../styles/button';
import { WarningBox } from '../../styles/message';

// import { ENROLLMENT_STATUS_OPTIONS } from '../../constants';

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
    border: 2px solid #212121;
`;

class MassEditMentors extends React.Component {

    utcOffSet = 8;
    
    state = {
        modalData: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { trainees, open } = this.props;
        if ( open && !prevProps.open ) {
            this.setState({ modalData: this.getInitialModalData() });
        } // end - open
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose )
            onClose();
    }

    handleUpdate = () => {
        const { schedule, authData, onUpdate } = this.props;
        const { modalData } = this.state;
        let error = false;

        if ( !( modalData && modalData.mentor && !_isEmpty( modalData.mentor ) ) )
            error = 'Please select a mentor';

        if ( error ) {
            triggerErrorAlert(error);
        } else if ( onUpdate ) {
            onUpdate(this.updateTrainees());
            this.handleClose();
        } 
    }
    
    handleFormUpdate = (newValue,key,subkey,subVal) => {
        const { modalData } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} );
        switch( key ) {
            case 'action':
                newData[key] = newValue
                break;
            default:
                if ( subkey && ( subkey === 'checked' || subkey === 'unchecked' ) && subVal && !_isEmpty( subVal ) ) {
                    // use subVal as the new value
                    newData[key] = subVal;
                } else {
                    // otherwise - just update normally
                    newData[key] = newValue
                }
                break;
        } // end - key
        
        this.setState({ modalData: newData })
    }

    updateTrainees = (students) => {
        const { selected, trainees, courses } = this.props;
        const { modalData } = this.state;
        let newTrainees = ( trainees && isArrayExists( trainees ) ? cloneCollections( trainees ) : [] );
        _forEach( selected, student => {
            let index = ( trainees && isArrayExists( trainees ) ? _findIndex( trainees, { id: student.id } ) : -1 );
            if ( index >= 0 ) {
                newTrainees[index].mentor = ( modalData && modalData.mentor ? modalData.mentor : '' );
            } // end - index
        });
        return newTrainees;
    }

    getInitialModalData = () => {
        return {
            mentor: '',
        };
    }

    getMentorOptions = () => {
        const { schedule, mentors } = this.props;
        let options = [{ value: '', label: 'Select an Option' }];
        if ( schedule && schedule.mentors && isArrayExists( schedule.mentors ) ) {
            _forEach( schedule.mentors, mentor => {
                let selected = _find( mentors, { email: mentor.id } );
                options.push({
                    value: mentor.id,
                    label: ( selected && selected.name && !_isEmpty( selected.name ) ? selected.name : mentor.id )
                })
            });
        } // end - schedule.mentors
        return options;
    }

    renderModalForm = () => {
        const { modalData } = this.state;
        return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormSelect
                        label="Mentor"
                        name="mentor"
                        value={( modalData && modalData.mentor || '' )}
                        options={this.getMentorOptions()}
                        onChange={this.handleFormUpdate} />
                </Grid>
            </Grid>
        </>
        );
    }

    render() {
        const { open, schedule } = this.props;
        return (
        <>
            <ModalView 
                open={open}
                title={"Mass Edit Mentor"}
                onClose={this.handleClose}
                disableBackdrop={true}
                actionLabel="Update"
                maxWidth="sm"
                doAction={this.handleUpdate}
                contents={this.renderModalForm()} />

        </>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(MassEditMentors);