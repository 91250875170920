/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AppWrapper from '../../components/AppWrapper';
import List from './list';

import { getCourses } from '../../actions/courses';
import { getMaintenance } from '../../actions/maintenance';

class MaintenanceFeedbackForms extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {
        // get courses
        this.props.dispatch(getCourses());
        // get list
        this.props.dispatch(getMaintenance('feedback_forms'));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { coursesList, feedbackFormsList, randNum } = this.props;

        if ( coursesList && feedbackFormsList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    renderContents() {
        const { feedbackFormsList, coursesList, authData } = this.props;
        return <List 
                type="feedback_forms"
                typeLabel="Feedback Forms"
                courses={coursesList}
                authData={authData} 
                list={feedbackFormsList} />;
    }

    render() {
        const { feedbackFormsList, randNum } = this.props;
        return <AppWrapper 
                title="Feedback Forms"
                subtitle="Maintenance"
                onLoad={( !( feedbackFormsList && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        feedbackFormsList: state.maintenance && state.maintenance.feedback_forms ? state.maintenance.feedback_forms : null,
        coursesList: state.courses && state.courses.courses ? state.courses.courses : null,
        randNum: state.maintenance && state.maintenance.rand ? state.maintenance.rand : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(MaintenanceFeedbackForms);