/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_TRAINEES,
	GET_UNRECONCILIATION_PAYMENTS,
	UPDATE_CLASS_PAYMENT_TRACKING,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getUnreconciliationPayments, fb_updateClassPaymentTracking, fb_sendPaymentEmail } from './fb';
import { fb_getTrainees } from '../trainees/fb';

export const getUnreconciliationPayments = (props) => {
	return dispatch => {
		
		fb_getUnreconciliationPayments(list => {
			// push new data
			dispatch({ type: GET_UNRECONCILIATION_PAYMENTS, payload: { list } });
		},props);

	}
}

export const updateClassPaymentTracking = (formData,props) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateClassPaymentTracking(formData)
		.then( results => {
			fb_getTrainees(props)
			.then(list => {
				// push new data
				dispatch({ type: GET_TRAINEES, payload: { list } });	
				// updated
				dispatch({ type: UPDATE_CLASS_PAYMENT_TRACKING });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Payment Tracking Updated");
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const sendPaymentEmail = (formData,props) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));
		dispatch(toggleLoader(true));

		fb_sendPaymentEmail(formData)
		.then( results => {
			fb_getTrainees(props)
			.then(list => {
				// push new data
				dispatch({ type: GET_TRAINEES, payload: { list } });	
				// updated
				dispatch({ type: UPDATE_CLASS_PAYMENT_TRACKING });
				dispatch(toggleLoader(false));
				dispatch(toggleModalProcessing(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Email Sent! It might takes up to 5 minutes to reach the recipients inbox.",4000);
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}