/* eslint-disable */
import React, { useState, useContext, createContext, useMemo, useEffect } from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import shortid from 'shortid';
import Papa from 'papaparse';
import Dropzone from 'react-dropzone';
import blue from '@material-ui/core/colors/blue';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';

import { doCSVDownload } from '../TeamsTable/export';
import SchoolSelector from '../SchoolSelector';

import { InfoButton, SuccessButton, ButtonGroup, InverseButton, ErrorButton, AInfoLink } from '../../styles/button';
// import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import ErrorMessage from '../../components/ErrorMessage';
import DotsLoader from '../../components/DotsLoader';
import ModalView from '../../components/ModalView';
import ModalDelete from '../../components/ModalDelete';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue, trimEmail, trimString } from '../../helpers/data';
import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';

import { toggleLoader } from '../../actions/global';

import theme from '../../theme';

const ComponentContext = createContext();

const getList = (schedule_id) => {
    return new Promise((resolve,reject) => {
        callFunctionsAPI({ url: 'schedule', action: 'get_tm_list', formData: { schedule_id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
    });
}

const triggerAction = (action,formData) => {
    return new Promise((resolve,reject) => {
        callFunctionsAPI({ url: 'schedule', action, formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
    });
}

const CSVUploadTeachersMentors = ({
    authData,
    changesNotSaved,
    dispatch,
    schedule
}) => {
    const [ loaded, setLoaded ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState(null);
    const [ open , setOpen ] = useState(false);
    const [ edit , setEdit ] = useState({});
    const [ csvData, setCSVData ] = useState([]);
    const [ deleteModal, setDeleteModal ] = useState(false);
    const [ list, setList ] = useState([]);
    const [ searchterms, setSearchterms ] = useState('');
    const [ sortBy, setSortBy ] = useState('name-asc');
    const [ filterBy, setFilterBy ] = useState('');
    const [ checked, setChecked ] = useState([]);
    const [ allCheck, setAllCheck ] = useState(false);
    const [ perPage, setPerPage ] = useState(20);
    const [ page, setPage ] = useState(1);

    useEffect(() => {
        let mounted = true;
        if ( schedule && schedule.id && !_isEmpty( schedule.id ) ) {
            getList(schedule.id)
            .then(data => {
                if (mounted) {
                    setLoaded(true);
                    setList(data);
                }
            })
            .catch(error => {
                if ( mounted ) {
                    setLoaded(true);
                    setErrorMsg(getAPIErrorMessage(error));
                }
            });
        }
        return () => { mounted = false; }
    },[ schedule ]);

    const listData = useMemo(() => {
        let items = ( list ? cloneCollections( list ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','email','school'] );
			total = _size( items );
        } // end - searchterms

        // do filter
        // if ( filterBy && !_isEmpty( filterBy ) && filterBy !== 'all' ) {
        //     if ( filterBy === 'duplicates' ) {
        //         items = _filter( items, i => checkForDuplication( trainees,i.email) );
        //         total = _size( items );
        //     } else {
        //         items = _filter( items, { enrollment_status: filterBy });
        //         total = _size( items );
        //     }
        // } // end - filterBy

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
                case 'email-desc':
                    items = _sortBy( items, ['email'] );
                    items = _reverse( items );
                    break;
                case 'email-asc':
                    items = _sortBy( items, ['email'] );
                    break;
                case 'school-desc':
                    items = _sortBy( items, i => ( i.school && i.school.toLowerCase() || '' ) );
                    items = _reverse( items );
                    break;
                case 'school-asc':
                    items = _sortBy( items, i => ( i.school && i.school.toLowerCase() || '' ) );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }, [ list, sortBy, filterBy, perPage, page, searchterms ]);

    const handleCSVExport = (e) => {
        if ( checked && isArrayExists( checked ) ) {
            let csvData = {
                fields: [
                    "Name",
                    "Email",
                    "Role",
                    "School"
                ],
                data: []
            };
            _forEach( checked, item => {
                const selected = _find( list, { id: item.id });
                if ( selected ) {
                    let row = [];
                    row.push( selected.name || '' );
                    row.push( selected.email || '' );
                    row.push( selected.role || '' );
                    row.push( selected.school || '' );
                    csvData.data.push( row );
                }
            });
            doCSVDownload(csvData,'teachers_mentors');
        } else {
            triggerErrorAlert( 'Please select at least one teacher / mentor to export.' );
        }
    }

    const handleOpen = () => {
        if ( changesNotSaved ) {
            triggerErrorAlert( 'You have unsaved changes. Please save or discard your changes.' );
        } else {
            setOpen(true);
        }
    }

    const handleDelete = (item) => () => {
        if ( changesNotSaved ) {
            triggerErrorAlert( 'You have unsaved changes. Please save or discard your changes.' );
        } else {
            setDeleteModal(item);
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleFileUpload = (files) => {
        if ( files && files[0] && !_isEmpty( files[0] ) ) {
            Papa.parse(files[0], {
                header: true,
                complete: (results) => {
                    const uploadList = [];
                    if ( results && results.data && isArrayExists( results.data ) ) {
                        results.data.forEach(item => {
                            if ( item && item.name && item.email && !_isEmpty( item.email ) && item.role && !_isEmpty( item.role ) && ( trimString( item.role, true ) === 'teacher' || trimString( item.role, true ) === 'mentor' ) && !_find( list, { email: trimEmail( item.email ) } ) && 
                            !_find( list, { email: trimEmail( item.email ) } ) 
                            ) {
                                uploadList.push({
                                    id: shortid.generate(),
                                    name: item.name || '',
                                    email: trimEmail( item.email ),
                                    role: item.role,
                                    school: item.school || '',
                                });
                            }
                        });
                    }
                    if ( isArrayExists( uploadList ) ) {
                        setCSVData(uploadList);
                    } else {
                        triggerErrorAlert("Please upload a valid CSV file.");
                    } // end - uploadList
                }
            });
        } else {
            triggerErrorAlert('Please upload a valid CSV file');
        }
    }    

    const doAction = (action,formData) => {
        dispatch( toggleLoader(true) );
        triggerAction(action,formData)
        .then(() => {
            return ( schedule.id ? getList(schedule.id) : false );
        })
        .then(data => {
            dispatch( toggleLoader(false) );
            triggerSuccessAlert("Data Updated");
            setList(data);
        })
        .catch(error => {
            dispatch( toggleLoader(false) );
            triggerErrorAlert(getAPIErrorMessage(error));
        });
    }

    const handleEditUpdate = (newValue,key) => {
        switch( key ) {
            case 'org':
                setEdit({
                    ...edit,
                    school: newValue
                });
                break;
            default:
                setEdit({
                    ...edit,
                    [key]: newValue
                });
                break;
        }
    }

    const handleEditAction = () => {
        let error = false;

        // do error check
        if ( !( edit.role && !_isEmpty( edit.role ) ) )
            error = 'Please select a valid role.';

        if ( !( edit.name && !_isEmpty( edit.name ) ) )
            error = 'Please enter a valid name.';

        if ( error ) {
            triggerErrorAlert( error );
        } else {
            doAction('update_one_tm',{
                schedule_id: schedule.id,
                id: edit.id || '',
                name: edit.name || '',
                role: edit.role || '',
                school: edit.school || '',
            });
            setEdit({});
        }

    }

    return loaded ? ( errorMsg ? <ErrorMessage errorMessage={errorMsg} /> : (
    <>

        <Typography variant="h5" style={{ paddingBottom: '15px' }}>Teachers & Mentors List:</Typography>

        <TableBar
            sortBy={sortBy}
            // show={['entries','sortby']}
            perPage={perPage}
            filterBy={filterBy}
            searchterms={searchterms}
            sortByOptions={[
                { value: 'name-asc', label: 'Name ( A - Z)' },
                { value: 'name-desc', label: 'Name ( Z - A )' },
                { value: 'email-asc', label: 'Email ( A - Z)' },
                { value: 'email-desc', label: 'Email ( Z - A )' },
                { value: 'school-asc', label: 'School ( A - Z)' },
                { value: 'school-desc', label: 'School ( Z - A )' },
            ]}
            // leftButtons={ checked && isArrayExists( checked ) ? [
            //     <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
            //         <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
            //     </div>
            //     ] : null }
            rightButtons={[
                <InfoButton key="download" minWidth="196px" style={{ marginRight: '5px' }} onClick={handleCSVExport}><i className="fa fa-file-excel-o"></i>Export Data To CSV</InfoButton>,
                <SuccessButton key="upload" minWidth="168px" style={{ marginRight: '5px' }} onClick={handleOpen}><i className="fa fa-file-excel-o"></i>Upload CSV</SuccessButton>
            ]}
            // filterByOptions={getSelectOptions({ list: _filter( ENROLLMENT_STATUS_OPTIONS, { confirmation: true } ), options: [{ value: 'all', label: 'All Status' },{ value: 'duplicates', label: 'Email Duplications' }], keys: { value: 'value', label: 'label' } })}
            onEntriesChange={(newPerPage) => {
                setPerPage(newPerPage);
                setPage(1);
            }}
            onSearchChange={(terms) => {
                setSearchterms(terms);
                setPage(1);
            }}
            onSortByChange={(newSortBy) => {
                setSortBy(newSortBy);
                setPage(1);
            }}
            onFilterByChange={(newFilterBy) => {
                setFilterBy(newFilterBy);
                setPage(1);
            }}
            style={{ marginBottom: "20px" }} />
        <Table 
            items={listData.items}
            showCheckbox={true}
            emptyCell={ "No teacher(s) or mentor(s) found." }
            checked={( checked || [] )}
            allCheck={allCheck}
            onChecked={(newValue) => setChecked(newValue)}
            onAllChecked={(newValue) => setAllCheck(newValue)}
            cells={[
                { id: 'name', label: 'Name', render: (item) => <WrapWord>{( item.name || '-' )}</WrapWord> },
                { id: 'email', label: 'Email', render: (item) => <WrapWord>{( item.email || '-' )}</WrapWord> },
                { id: 'role', label: 'Role', render: (item) => <WrapWord>{( item.role && item.role === 'teacher' ? 'Teacher' : 'Mentor' )}</WrapWord> },
                { id: 'school', label: 'School', render: (item) => <WrapWord>{( item.school || '-' )}</WrapWord> },
            ]}
            actionStyles={{ width: "20%" }}
            actions={(item) => (
            <ButtonGroup>
                <InfoButton size="small" onClick={() => setEdit( item )}>Edit</InfoButton>
                <ErrorButton size="small" onClick={handleDelete(item)}>Remove</ErrorButton>
            </ButtonGroup>
            )} />
        <Pagination 
            total={listData.total}
            perPage={perPage} 
            page={page}
            style={{ marginTop: "20px" }}
            doneLoaded={true}
            onPageChange={(newPage) => setPage(newPage) } />
        
        <ModalView 
            open={( edit && isObjectExists( edit ) ? true : false )}
            title={"Edit Teacher or Mentor"}
            maxWidth="md"
            actionLabel={"Edit"}
            cancelLabel="Cancel"
            doAction={handleEditAction}
            onClose={() => setEdit({})}
            contents={(
            <>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormInput
                            label="Name *"
                            name="name"
                            value={( edit.name || '' )}
                            onChange={handleEditUpdate}/>
                    </Grid>   
                    <Grid item xs={6}>
                        <FormInput
                            label="Email *"
                            name="email"
                            disabled={true}
                            value={( edit.email || '' )}
                            onChange={() => { /* do nothing */ }}/>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ paddingTop: '16px' }}>
                            <FormSelect
                                label="Role *"
                                name="role"
                                value={( edit.role || '' )}
                                options={[
                                    { value: 'mentor', label: 'Mentor' },
                                    { value: 'teacher', label: 'Teacher' },
                                ]}
                                onChange={handleEditUpdate}/>
                        </div>
                    </Grid>  
                    <Grid item xs={6}>
                        <SchoolSelector
                            value={( edit.school || '' )}
                            field={{
                                label: "School / University",
                                // required: 'yes'
                            }}
                            onChange={handleEditUpdate} />
                    </Grid>     
                </Grid>   
            </>
            )} />

        <ModalView 
            open={open}
            title={"Upload Teachers & Mentors List"}
            maxWidth="md"
            actionLabel={"Upload"}
            cancelLabel="Cancel"
            doAction={() => {
                doAction('update_tm_list',{
                    schedule_id: schedule.id,
                    list: csvData
                });
                setOpen(false);
                setCSVData(false);
            }}
            onClose={handleClose}
            contents={(
            <>
                { csvData && isArrayExists( csvData ) ? (
                <>
                    <div style={{ padding: '0 0 10px 0', textAlign: 'right' }}>
                        <InverseButton size="small" onClick={() => {
                            setCSVData([]);
                        }}><i className='fa fa-refresh'></i>Reset</InverseButton>
                    </div>
                    <Table
                        items={csvData}
                        showCheckbox={false}
                        emptyCell="No data fround."
                        cells={[
                            { id: 'email', label: 'Email', render: (item) => <WrapWord>{( item.email || '-' )}</WrapWord> },
                            { id: 'name', label: 'Name', render: (item) => <WrapWord>{( item.name || '-' )}</WrapWord> },
                            { id: 'role', label: 'Role', render: (item) => <WrapWord>{( item.role || '-' )}</WrapWord> },
                            { id: 'school', label: 'School', render: (item) => <WrapWord>{( item.school || '-' )}</WrapWord> },
                        ]} />
                </>
                ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12} align="left">
                        <AInfoLink href="https://firebasestorage.googleapis.com/v0/b/chumbaka-dev-tms.appspot.com/o/file_storage%2Fclass_teachers_mentors_list.csv?alt=media&token=48091e6b-c28e-4b70-b13e-a038d43541ed" target="_blank" style={{ padding: '10px 25px' }}>Download Sample CSV File</AInfoLink>
                    </Grid>
                    <Grid item xs={12}>
                        <Dropzone onDrop={handleFileUpload} accept={".csv"}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ style: {
                                display: "flex",
                                height: '200px', 
                                width: '100%', 
                                border: '2px dashed ' + blue['700'],
                                color: blue['700'],
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer'
                            } })}>
                                <input {...getInputProps()} />
                                <div>Drop a CSV file here, or click to select a CSV file to upload</div>
                            </div>
                        )}
                        </Dropzone>
                    </Grid>
                </Grid>
                ) }
            </>
            )} />

        <ModalDelete
            open={( deleteModal && isObjectExists( deleteModal ) ? true : false )}
            title={( deleteModal && deleteModal.name ? `Are you sure you want to remove ( ${deleteModal.name} )?` : false )}
            onClose={() => setDeleteModal(false)}
            onDelete={() => {
                doAction('delete_tm_list',{
                    id: deleteModal.id,
                    schedule_id: schedule.id
                });
                setDeleteModal(false);
            }} />

        

    </>
    ) ) : <DotsLoader />
}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        changesNotSaved: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps)
)(CSVUploadTeachersMentors);