/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_TRAINEES,
	GET_CLASS_QUARTERLY_REPORT,
	UPDATE_CLASS_QUARTERLY_REPORT,
	PREVIEW_CLASS_QUARTERLY_REPORT,
	TRIGGER_CLASS_QUARTERLY_REPORT,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getClassQuarterlyReport, fb_updateClassQuarterlyReport, fb_triggerClassQuarterlyReport, fb_triggerPreviewReport } from './fb';
import { fb_getTrainees } from '../trainees/fb';

export const getClassQuarterlyReport = (id) => {
		return dispatch => {

		fb_getClassQuarterlyReport(id)
		.then(quarterly_report => {
			dispatch({
				type: GET_CLASS_QUARTERLY_REPORT,
				payload: { quarterly_report }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_CLASS_QUARTERLY_REPORT,
				payload: { quarterly_report: {} }
			});
		})

	}
}


export const updateClassQuarterlyReport = (formData,props) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_updateClassQuarterlyReport(formData)
		.then( results => {
			fb_getClassQuarterlyReport(formData.id)
			.then(quarterly_report => {
				// push new data
				dispatch({ type: GET_CLASS_QUARTERLY_REPORT, payload: { quarterly_report } });	
				// updated
				dispatch({ type: UPDATE_CLASS_QUARTERLY_REPORT });
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("Quarterly Report Email Updated.");
			})
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const triggerClassQuarterlyReport = (formData,props) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_triggerClassQuarterlyReport(formData)
		.then( results => {
			fb_getTrainees(props)
			.then(list => {
				// push new data
				dispatch({ type: GET_TRAINEES, payload: { list } });	
				// updated
				dispatch({ type: TRIGGER_CLASS_QUARTERLY_REPORT });
				dispatch(toggleModalProcessing(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Quarterly Report Email Sent! It might takes up to 5 minutes to reach corresponding email addresses.");
			})
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const triggerPreviewEmail = (formData,props) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_triggerPreviewReport(formData)
		.then( results => {
			// updated
			dispatch({ type: PREVIEW_CLASS_QUARTERLY_REPORT });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Quarterly Report Email Sent To Your Inbox - It might takes up to 5 minutes to reach your inbox",4000);
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}