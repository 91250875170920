/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';

import theme from '../../theme';
import { fa } from '../../styles/icon';

import { isArrayExists } from '../../helpers/validation';

import { appChangesReset } from '../../actions/misc';

const List = styled.ul`
    display: block;
    margin: 0px;
    padding: 0px;
    list-style: none;
`;

const Item = styled.li`
    display: inline-block;
    margin: 0px;
    padding: 0px;

    a,
    span {
        display: inline-block;
        color: ${props => (props.color ? props.color : '#212121')};
        font-weight: 700;
    }

    a {
        &:hover {
            color: ${props => (props.hoverColor ? props.hoverColor : theme.palette.primary.main)}; 
        }
    }
    
    &:after {
        display: inline-block;
        content: "\f105";
        ${fa}
        margin: 0 10px;
    }

    &:last-child {
        &:after { display: none; }
    }
`;

class BreadCrumbs extends React.Component {

    handleClick(url,event) {
        const { history, changesNotSaved } = this.props;
        event.preventDefault();

        // if changes not saved - trigger warning        
        if ( changesNotSaved ) {
            var answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?");
            // reset status if answer is yes
            if ( answer ) {
                this.props.dispatch(appChangesReset());
                history.push(url);
            }
        } else {
            history.push(url);
        }

    }
    
    render() {
        const { links, color, hoverColor } = this.props;
        return ( links && isArrayExists( links ) ? <List>{_map( links, ( link, index ) =>  {
            return (
            <Item key={index} color={( color || null )} hoverColor={( hoverColor || null )}>
                { link.url && !_isEmpty( link.url) ? <a href={link.url} onClick={this.handleClick.bind(this,link.url)}>{link.label}</a> : <span>{link.label}</span> }
            </Item>
            )
        })}</List> : '' ); 
    }

}

const mapStateToProps = state => {
    return {
        changesNotSaved: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(BreadCrumbs);