/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import _isEmpty from 'lodash/isEmpty';
import firebase from 'firebase/app';

import AppWrapper from '../../components/AppWrapper';

import { isArrayExists } from '../../helpers/validation';
import { getAPIErrorMessage, callFunctionsAPI, doPromise } from '../../helpers/action';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';

class TraineeToStudentRedirect extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {
        const { trainee_id } = this.props.match.params;
        this.doRedirect(trainee_id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    doRedirect = (trainee_id,path) => {
        const { history } = this.props;
        const { location } = this.getQueryString();
        // firebase.firestore().collection("trainees").doc(trainee_id).get()
        // .then(doc => {
        //     let trainee = ( doc.exists ? doc.data() : false );
        //     return ( trainee && trainee.email && !_isEmpty( trainee.email ) ? callFunctionsAPI({ url: 'student', action: 'get_uid', formData: { email: trainee.email } }) : false );
        // })

        doPromise({ method: 'POST', url: 'dbp', formData: { trainee_id }, params: [{ key: 'action', value: 'get_uid_by_tid' }] })
        .then( res => {
            const student = ( res && res.data ? res.data : false );
            // make sure token is available
            if ( this.props.match && this.props.match.path && !_isEmpty( this.props.match.path ) ) {
                switch( this.props.match.path ) {
                    case '/bp/:trainee_id':
                        if ( student && student.uid ) {
                            history.push("/backpack/"+student.uid);
                        } else {
                            history.push("/backpack"); 
                        }
                        break;
                    default: 
                        if ( student && student.uid ) {
                            history.push("/student_database/"+student.uid);
                        } else {
                            history.push("/student_database"); 
                        }
                        break;
                } 
            } else {
                if ( student && student.uid ) {
                    history.push("/student_database/"+student.uid);
                } else {
                    history.push("/student_database"); 
                }
            }
        })
        .catch(error => {
            triggerErrorAlert(getAPIErrorMessage(error));
            history.push("/student_database");
        });
    }

    renderContents() {
        return (
        <div></div>
        );
    }

    getQueryString() {
        return ( this.props.location && this.props.location.search ? queryString.parse(this.props.location.search) : {} );
    }

    render() {
        const { randNum } = this.state;
        return <AppWrapper 
                title=""
                subtitle=""
                onLoad={true}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(TraineeToStudentRedirect);