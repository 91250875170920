/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _replace from 'lodash/replace';
import _remove from 'lodash/remove';

import ModalDelete from '../../components/ModalDelete';
import FormInput from '../../components/FormInput';
import FormCheckbox from '../../components/FormCheckbox';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { getSelectedValue, cloneCollections } from '../../helpers/data';

import { Button, InverseButton, InverseLink } from '../../styles/button';

import { traineeSchema } from '../../schemas/trainee';

import { DAY_OPTIONS, GENDER_OPTIONS } from '../../constants';

const FormWrapper = styled.div`
    padding: 0px;
`;

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

const CHECKBOX_OPTIONS = [
    // { value: 'fb', label: 'Facebook' },
    { value: 'another_parent', label: 'Another Parent' },
    { value: 'google', label: 'Google Search' },
    { value: 'social_media', label: 'Social Media' },
    // { value: 'instagram', label: 'Instagram' },
    { value: 'newsletter', label: 'Chumbaka Newsletter' },
]

class SourceForm extends React.Component {

    state = {
        fb: 'no',
        another_parent: 'no',
        social_media: 'no',
        google: 'no',
        instagram: 'no',
        newsletter: 'no',
        others: '',
    };

    handleFormUpdate = (newValue,key,subkey,subVal) => {
        const { onUpdate } = this.props;
        const { fb, google, another_parent, social_media, instagram, newsletter, others } = this.state;
        let newData = [],
            newState = { fb, google, another_parent, social_media, instagram, newsletter, others };

        if ( subkey && ( subkey === 'checked' || subkey === 'unchecked' ) && subVal && !_isEmpty( subVal ) ) {
            // use subVal as the new value
            newState[key] = subVal;
        } else {
            // otherwise - just update normally
            newState[key] = newValue;
        }

        _forEach( newState, ( sVal, sKey ) => {
            if ( sKey ) {
                switch ( sKey ) {
                    case 'another_parent':
                    case 'social_media':
                    case 'fb':
                    case 'google':
                    case 'instagram':
                    case 'newsletter':
                        if ( sVal && sVal === 'yes' ) {
                            newData.push( getSelectedValue( CHECKBOX_OPTIONS, sKey, 'value', 'label' ) );
                        }
                        break;
                    case 'others':
                        if ( sVal && !_isEmpty( sVal ) ) {
                            newData.push(sVal);
                        }
                        break
                }
            } // end  - skey
        });

        this.setState(newState);

        if ( onUpdate )
            onUpdate(newData);
    }

    renderCheckbox = (name,label) => {
        return <FormCheckbox label={label} name={name} checked="yes" unchecked="no" value={( this.state[name] && this.state[name] === 'yes' ? 'yes' : 'no' )} onChange={this.handleFormUpdate} />
    }

    render = () => {
        const { others } = this.state;
        return (
        <FormWrapper>
            <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>{this.renderCheckbox('another_parent','Another Parent')}</Grid>
                <Grid item sm={6} xs={12}>{this.renderCheckbox('google','Google Search')}</Grid>
                <Grid item sm={6} xs={12}>{this.renderCheckbox('social_media',"Social Media")}</Grid>
                <Grid item sm={6} xs={12}>{this.renderCheckbox('newsletter','Chumbaka Newsletter')}</Grid>
                <Grid item xs={12}>
                    <FormInput label="Others" name="others" value={( others || '' )} onChange={this.handleFormUpdate} />
                </Grid>
            </Grid>
        </FormWrapper>
        )
    }
}


export default compose(
    withStyles(useStyles),
    withRouter
)(SourceForm);