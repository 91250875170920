import { combineReducers } from "redux";

import { auth } from "./auth";
import { global } from "./global";

import { partners } from "./partners";
import { personnels } from "./personnels";
import { training_locations } from "./training_locations";
import { courses } from "./courses";
import { badges } from "./badges";
import { programs } from "./programs";
import { schedules } from "./schedules";
import { classes } from "./classes";
import { trainees } from "./trainees";

import { canvas_lms } from "./canvas_lms";

import { mentors } from "./mentors";
import { course_registration } from "./course_registration";
import { payment_form } from "./payment_form";
import { students } from "./students";
import { student_showcase } from "./student_showcase";

import { hq_summary } from "./hq_summary";
import { database_students } from "./database_students";
import { database_marketing } from "./database_marketing";
import { users } from "./users";
import { media_library } from "./media_library";
import { maintenance } from "./maintenance";
import { notifications } from "./notifications";
import { misc } from "./misc";

export default combineReducers({
    auth,
    global,
    partners,
    personnels,
    training_locations,
    courses,
    badges,
    programs,
    schedules,
    classes,
    trainees,

    canvas_lms,

    mentors,
    students,
    student_showcase,
    course_registration,
    payment_form,

    hq_summary,
    database_students,
    database_marketing,
    users,
    media_library,
    notifications,
    maintenance,
    misc,
});
