/* eslint-disable */
import React, { useState } from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import shortid from 'shortid';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';

import theme from '../../theme';

const TeamProjectSubmissionDeadline = ({
    schedule
}) => {


    return (
        <div></div>
    )
}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
    }
}

export default compose(
    connect(mapStateToProps)
)(TeamProjectSubmissionDeadline);