/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _isEqual from 'lodash/isEqual';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader'
import SnackBarSave from '../../components/SnackBarSave';

import EditForm from './form';
import ItemNotFound from '../../components/ItemNotFound';

import { InfoButton, GreyButton } from '../../styles/button';

import { hasAccessRights } from '../../helpers/auth'; 
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { getCertificate, editCeritificate } from '../../actions/m_certificates';
import { getGoogleFonts } from '../../actions/fonts';
import { appChangesMade, resetRedux } from '../../actions/misc';

class MaintenanceCertificate extends React.Component {

    state = {
        currentTab: 'details',
        certificate: false,
        randNum: false
    };

    componentDidMount() {
        const { authData, fontOptions } = this.props;
        const { certificate_id } = this.props.match.params;
        
        // retrieve form details
        this.props.dispatch(getCertificate(certificate_id));

        // get fonts options
        if ( !fontOptions )
            this.props.dispatch(getGoogleFonts());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentCertificate, fontOptions, randNum } = this.props;
        // for certificate
        if ( currentCertificate && fontOptions && randNum && randNum !== this.state.randNum ) {
            this.setState({ certificate: this.reorganizeCertificateData( currentCertificate ), randNum });
        } // end - currentCertificate
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('certificate'));
    }

    handleRefresh = (type) => {
        // const { certificate_id } = this.props.match.params;
        // switch( type ) {
        //     case 'emails':
        //         this.setState({ emails: false });
        //         this.props.dispatch(getEmailsBy('partners',certificate_id));
        //         break;
        // }
    }

    handleSaveChanges = (event) => {
        const { certificate } = this.state;
        event.preventDefault();
        var error = false,
            formData = ( certificate && isObjectExists( certificate ) ? cloneCollections( certificate ) : {} );

        // do error check
        if ( !( formData && formData.status && !_isEmpty( formData.status ) ) )
            error = 'Please select a valid status';

        if ( !( formData && formData.name && !_isEmpty( formData.name ) ) )
            error = 'Please insert a valid name';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            // reorganize formData before submission
			if ( certificate.template_layout && isArrayExists( certificate.template_layout ) && certificate.template && isArrayExists( certificate.template ) ) {
				_forEach( certificate.template_layout, item => {
					var index = _findIndex( certificate.template, { id: item.i } );
					if ( index >= 0 ) {
						formData.template[index].x = item.x || 0;
						formData.template[index].y = item.y || 0;
						formData.template[index].w = item.w || 1;
						formData.template[index].h = item.h || 1;
						formData.template[index].minW = item.minW || 0;
						formData.template[index].minH = item.minH || 0;
					}
				});
			} // end - certificate
            this.props.dispatch(editCeritificate(formData));
        } // end - error

    }

    handleFormUpdate = (newValue) => {
        this.setState({ certificate: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    reorganizeCertificateData = (certificate) => {
        let data = cloneCollections( certificate );

        // retrieve template items
		if ( certificate['template'] && isArrayExists( certificate['template'] ) ) {
			data.template = [];
			data.template_layout = [];

			_forEach( certificate['template'], item => {
				data.template.push({
					id: item.id,
					type: item.type || '',
					path: item.path || '',
					value: item.value || ''
				});

				if ( item.type && item.type != 'background_image' ) {
					data.template_layout.push({
						i: item.id,
						x: ( item.x ? parseInt( item.x ) : 0 ),
						y: ( item.y ? parseInt( item.y ) : 0 ),
						w: ( item.w ? parseInt( item.w ) : 0 ),
						h: ( item.h ? parseInt( item.h ) : 0 ),
						minW: ( item.minW ? parseInt( item.minW ) : 0 ),
						minH: ( item.minH ? parseInt( item.minH ) : 0 )
					});
				} // end - item.type

			});
		} // end - certificate

        return data;
    }

    renderTabContents = () => {
        const { certificate_id } = this.props.match.params;
        const { authData, currentCertificate, fontOptions, changesMade } = this.props;
        const { currentTab, certificate } = this.state;
        const tabProps = {
            authData: ( authData || false ),
            certificate: ( certificate || false ),
            changesMade: ( changesMade || false ),
            onFormUpdate: this.handleFormUpdate
        };
        switch( currentTab ) {
            case 'details':
                return <EditForm 
                    certificate_id={certificate_id}
                    currentCertificate={( currentCertificate || false )}
                    fonts={( fontOptions || false )}
                    {...tabProps} />;
        }
    }

    renderContents() {
        return (
        <div>

            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                {this.renderTabContents()}
            </Paper>

            <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
            </div>

            <SnackBarSave onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { certificate_id } = this.props.match.params;
        const { randNum } = this.state;
        const { currentCertificate } = this.props;
        return <AppWrapper 
                title="Edit Certificate"
                subtitle="Certificates"
                back="/maintenance/certificates"
                breadcrumbs={[
                    { url: '/maintenance/certificates', label: 'Certificates' },
                    { label: 'Edit' }
                ]}
                maxWidth="1110px"
                onLoad={( !( randNum ) ? true : false )}
                contents={ currentCertificate && currentCertificate.id && !_isEmpty( currentCertificate.id ) ? this.renderContents() : <ItemNotFound item="Certificate" />} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        currentCertificate: ( state.maintenance && state.maintenance.certificate ? state.maintenance.certificate : null ),
        randNum: ( state.maintenance && state.maintenance.rand_certificate ? state.maintenance.rand_certificate : null ),
        fontOptions: ( state.maintenance && state.maintenance.fonts ? state.maintenance.fonts : null ),
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(MaintenanceCertificate);