/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';
import _replace from 'lodash/replace';

import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';

import theme from '../../theme';


class FormMultiSelect extends React.Component {

    state = {
        open: false
    };

    handleChange = (event) => {
        const { name } = this.props;
        if ( this.props.onChange ) {
            this.props.onChange(event.target.value,name);
        }
    }

    handleMenuItemClick = ( selected, checked ) => (event) => {
        const { name, value } = this.props;
        var newValue = ( value && isArrayExists( value ) ? cloneCollections( value ) : [] );

        // if checked
        if ( checked ) {
            // only add if wasn't already in the options
            if ( !_find( newValue, (o) => o === selected ) )
                newValue.push(selected);
        } else {
            // only remove if it's in the options
            if ( _find( newValue, (o) => o === selected ) )
                var pulled = _remove( newValue, (o) => o === selected );
        } // end - checked

        if ( this.props.onChange ) {
            this.props.onChange(newValue,name);
        }
    }

    renderValue = selected => {
        const { options } = this.props;
        var string = '';
        if ( selected && isArrayExists( selected ) ) {
            selected.forEach(value => {
                var item = _find( options, { value } );
                string += ( !_isEmpty( string ) ? ', ' : '' ) + ( item && item.label || '' );
            });
        }
        return string;
    }

    renderMenuItem = (option) => {
        const { value, columns } = this.props;
        const checked = ( value && !_isEmpty( value ) && value.indexOf(option.value) > -1 ? true : false );
        return (
        <Grid item key={option.value} xs={( columns ? columns : 12 )}>
            <MenuItem value={option.value} disabled={( option.disabled && option.disabled == 'yes' ? true : false )} onClick={this.handleMenuItemClick(option.value,!checked)}>
                <Checkbox color="primary" checked={checked} />
                <ListItemText primary={option.label} />
            </MenuItem>
        </Grid>
        );
    }

    renderMenuHeadling = (option) => {
        return (
        <Grid item key={option.value} xs={12}>
            <ListSubheader style={{ fontWeight: '700', fontSize: '16px' }}>{option.label}</ListSubheader>
        </Grid>
        );
    }

    renderOption = (option) => {
        const { columns } = this.props;
        return option && option.heading ? this.renderMenuHeadling(option) : this.renderMenuItem(option) ;
    }

    renderLabel = () => {
        const { label } = this.props;
        var newLabel = false;
        if ( label && !_isEmpty( label ) && ( 
            ( _toLower( label ).indexOf("(required)") >= 0 ) || 
            ( _toLower( label ).indexOf("*") >= 0 )
         ) ) {
            newLabel = _replace( label, '(Required)', '' );
            newLabel = _replace( newLabel, '(required)', '' );
            newLabel = _replace( newLabel, '*', '' );
        } // end - label
        return ( newLabel ? <span>{ newLabel || '' }<span className="required">*</span></span> : ( label || '' ) );
    }

    render() {
        const { id, value, options, disabled, noShrink } = this.props;
        const { open } = this.state;
        var newID = 'multi-select-' + _random(1,9999);
        return (
        <div style={{ paddingLeft: "5px", paddingRight: "5px" }}>
            <FormControl fullWidth={true}>
                <InputLabel shrink={true} htmlFor={( id || newID )} style={{ fontSize: "16px", fontWeight: "700", textTransform: "uppercase" }}>{this.renderLabel()}</InputLabel>
                <Select
                    multiple
                    //open={( open || false )}
                    value={( value && isArrayExists( value ) ? value : [] )}
                    //onChange={this.handleChange}
                    input={ disabled ? <FilledInput id={( id || newID )} fullWidth={true} /> : <Input id={( id || newID )} fullWidth={true} />}
                    renderValue={this.renderValue}
                    disabled={( disabled || false )}
                    MenuProps={{
                        PaperProps: {
                            style: { maxHeight: '75vh', width: 250, background: "#fff" }
                        }
                    }}
                    //onOpen={() => this.setState({ open: true })}
                    >
                    {/* <div>
                        <ClickAwayListener onClickAway={() => {
                            this.setState({ open: false });
                            console.log('clickedaway');
                        }}> */}
                            <Grid container spacing={1}>{ options && isArrayExists( options ) ? options.map(this.renderOption) : null }</Grid>
                            {/* { options && isArrayExists( options ) ? options.map((option) => (
                                <MenuItem key={option.value} value={option.value} disabled={( option.disabled && option.disabled == 'yes' ? true : false )} onClick={this.handleMenuItemClick(option.value)}>
                                <Checkbox color="primary" checked={value && !_isEmpty( value ) && value.indexOf(option.value) > -1} />
                                <ListItemText primary={option.label} />
                            </MenuItem>
                            )) : null } */}
                        {/* </ClickAwayListener>
                    </div> */}
                </Select>
            </FormControl>
        </div>
        )
    }

}

export default FormMultiSelect