/* eslint-disable */
import firebase from "firebase/app";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";

import { callFunctionsAPI } from "../../helpers/action";
import { isArrayExists } from "../../helpers/validation";

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve, reject) => {});
};

/* helper end */

export const fb_getMentorClasses = (lms_course_id) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "mentor", action: "get_schedules", formData: { lms_course_id } })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_getMentorClassAttendances = (schedule_id, lms_course_id) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "mentor", action: "get_schedule_attendances", formData: { schedule_id, lms_course_id } })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_updateMentorClassAttendances = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "mentor", action: "update_schedule_attendances", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_nominateBadgeMentor = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "mentor", action: "nominate_badge", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_addNewClassCalendar = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "mentor", action: "add_class_calendar", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
