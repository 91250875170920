/* eslint-disable */
import _random from "lodash/random";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";

import {
    GET_TRAINEES,
    ADD_TRAINEE,
    EDIT_TRAINEE,
    DELETE_TRAINEE,
    // GLOBAL_ERROR_TRIGGER
} from "../types";

import { isArrayExists } from "../../helpers/validation";
import { cloneCollections } from "../../helpers/data";
import { getAPIErrorMessage } from "../../helpers/action";
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from "../global";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import { appChangesReset } from "../misc";

import { fb_getTrainees, fb_addTrainee, fb_updateTrainee, fb_deleteTrainee, fb_undeleteTrainee } from "./fb";

export const getTrainees = (props, loader) => {
    return (dispatch) => {
        if (loader) dispatch(toggleLoader(true));

        fb_getTrainees(props)
            .then((list) => {
                if (loader) dispatch(toggleLoader(false));

                dispatch({
                    type: GET_TRAINEES,
                    payload: { list },
                });
            })
            .catch((error) => {
                if (loader) dispatch(toggleLoader(false));

                triggerErrorAlert(getAPIErrorMessage(error));
                dispatch({
                    type: GET_TRAINEES,
                    payload: { list: [] },
                });
            });
    };
};

export const addTrainee = (formData, props) => {
    return (dispatch) => {
        var apiNum = _random(1, 9999);
        dispatch(toggleModalProcessing(true, apiNum));

        fb_addTrainee(formData)
            .then((results) => {
                fb_getTrainees(props).then((list) => {
                    dispatch({ type: GET_TRAINEES, payload: { list } });
                    dispatch({ type: ADD_TRAINEE });
                    dispatch(toggleModalProcessing(false));
                    triggerSuccessAlert("Student Added");
                });
            })
            .catch((error) => {
                dispatch(toggleModalProcessing(false, apiNum));
                triggerErrorAlert(getAPIErrorMessage(error));
            });
    };
};

export const editTrainee = (formData, props) => {
    return (dispatch) => {
        var apiNum = _random(1, 9999);
        dispatch(toggleModalProcessing(true, apiNum));

        fb_updateTrainee(formData)
            .then((results) => {
                fb_getTrainees(props).then((list) => {
                    dispatch({ type: GET_TRAINEES, payload: { list } });
                    dispatch({ type: EDIT_TRAINEE });
                    dispatch(toggleModalProcessing(false));
                    triggerSuccessAlert("Student Updated");
                });
            })
            .catch((error) => {
                dispatch(toggleModalProcessing(false, apiNum));
                triggerErrorAlert(getAPIErrorMessage(error));
            });
    };
};

export const deleteTrainee = (id, props) => {
    return (dispatch) => {
        var apiNum = _random(1, 9999);
        dispatch(toggleModalProcessing(true, apiNum));
        dispatch(toggleModalDeleting(true));

        fb_deleteTrainee(id)
            .then((results) => {
                fb_getTrainees(props).then((list) => {
                    dispatch({ type: GET_TRAINEES, payload: { list } });
                    dispatch({ type: DELETE_TRAINEE });
                    dispatch(toggleModalDeleting(false));
                    dispatch(toggleModalProcessing(false));
                    triggerSuccessAlert("Student Removed From Class");
                });
            })
            .catch((error) => {
                dispatch(toggleModalDeleting(false));
                dispatch(toggleModalProcessing(false, apiNum));
                triggerErrorAlert(getAPIErrorMessage(error));
            });
    };
};

export const undeleteTrainee = (id, props) => {
    return (dispatch) => {
        var apiNum = _random(1, 9999);
        dispatch(toggleLoader(true, apiNum));

        fb_undeleteTrainee(id)
            .then((results) => {
                fb_getTrainees(props).then((list) => {
                    dispatch({ type: GET_TRAINEES, payload: { list } });
                    dispatch({ type: DELETE_TRAINEE });
                    dispatch(toggleLoader(false));
                    triggerSuccessAlert("Student Restored");
                });
            })
            .catch((error) => {
                dispatch(toggleLoader(false, apiNum));
                triggerErrorAlert(getAPIErrorMessage(error));
            });
    };
};

const triggerMassTraineeUpdates = ({ trainees }) => {
    return new Promise((resolve, reject) => {
        let promises = [];
        _forEach(trainees, (formData, index) => {
            promises.push(fb_updateTrainee(formData));
        });

        Promise.all(promises)
            .then(() => {
                resolve(true);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const doAsyncTraineesUpdate = async ({ trainees }) => {
    const list = [];
    const limits = 10;
    let count = 1,
        batch = [];

    if (trainees && isArrayExists(trainees)) {
        _forEach(trainees, (trainee) => {
            batch.push(trainee);
            if (count === limits) {
                list.push(batch); // add to list
                // then reset
                count = 1;
                batch = [];
            } else {
                count++;
            } // end - count
        });

        // if batch is not empty - add to list
        if (batch && isArrayExists(batch)) list.push(batch);
    } // end - trainees

    if (list && isArrayExists(list)) {
        let cIndex = 0;
        while (cIndex < list.length) {
            await triggerMassTraineeUpdates({ trainees: cloneCollections(list[cIndex]) })
                .then(() => {
                    cIndex++;
                })
                .catch((error) => {
                    let errorMessage =
                        error && error.response && error.response.data && error.response.data.message
                            ? error.response.data.message
                            : error.message || "Unable to mass update student. Please try again.";
                    throw new Error(errorMessage);
                });
        }
    } // end - contacts

    return "done";
};

export const massEditTrainees = (trainees, props) => {
    return (dispatch) => {
        var apiNum = _random(1, 9999);
        dispatch(toggleModalProcessing(true, apiNum));
        dispatch(toggleLoader(true, "Updating process is on-going! Please do not close this window or click the Back button on your browser"));

        doAsyncTraineesUpdate({ trainees })
            .then((results) => {
                fb_getTrainees(props).then((list) => {
                    dispatch({ type: GET_TRAINEES, payload: { list } });
                    dispatch({ type: EDIT_TRAINEE });
                    dispatch(toggleModalProcessing(false));
                    dispatch(toggleLoader(false));
                    triggerSuccessAlert("Student(s) Updated");
                });
            })
            .catch((error) => {
                dispatch(toggleModalProcessing(false, apiNum));
                dispatch(toggleLoader(false));
                triggerErrorAlert(getAPIErrorMessage(error));
            });
    };
};
