import React from "react";

const info = {
    heading: <></>,
    contents: [
        {
            id: "1",
            title: "Add a user of SMS here.",
            content: (
                <>
                    <div>
                        <p style={{ marginBottom: "10px" }}>
                            Generally users of SMS are the principal & coordinators of a class. They are NOT the mentors of a class.
                        </p>
                        <p style={{ marginBottom: "10px" }}>
                            Email address added here must be different from the one added in{" "}
                            <a href="https://sms.chumbaka.asia/mentors" target="_blank">
                                LMS Users
                            </a>
                            . You may use the +sms trick e.g.{" "}
                            <a href="mailto:abc@gmail.com" target="_blank">
                                abc+sms@gmail.com
                            </a>
                        </p>
                        <p style={{ marginBottom: "10px" }}>Each principal / coordinator should have 3 logins:</p>
                        <ol style={{ listStyle: "numeric", paddingLeft: "10px", marginLeft: "10px" }}>
                            <li>
                                One on SMS as a principal role (e.g.{" "}
                                <a href="mailto:haslina+sms@gmail.com" target="_blank">
                                    haslina+sms@gmail.com
                                </a>
                                ){" "}
                            </li>
                            <li>
                                One on LMS as a mentor role (e.g.{" "}
                                <a href="mailto:haslina+mentor@gmail.com" target="_blank">
                                    haslina+mentor@gmail.com
                                </a>
                                ){" "}
                            </li>
                            <li>
                                One on LMS as a student role (e.g.{" "}
                                <a href="mailto:haslina+student@gmail.com" target="_blank">
                                    haslina+student@gmail.com
                                </a>
                                ){" "}
                            </li>
                        </ol>
                    </div>
                </>
            ),
        },
    ],
};

const buttons = [
    {
        id: "1",
        tooltip: true,
        tooltipContent: (
            <div style={{ padding: "10px" }}>
                <p style={{ fontWeight: "700" }}>📂 Chumbaka Partner Portal - 2. Partner Development Program </p>
                <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                    <li>Resources</li>
                </ul>
            </div>
        ),
        type: "notion",
        link: "https://www.notion.so/chumbaka/2-Partner-Development-Program-7cdc6d1b0e45496ab3e32c600a8f156b?pvs=4",
        label: "Notion",
    },
    {
        id: "2",
        tooltip: true,
        tooltipContent: (
            <div style={{ padding: "10px" }}>
                <p style={{ fontWeight: "700" }}>📂 Chumbaka Partner Portal - 2. Partner Development Program </p>
                <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                    <li>2T (Thursday Townhall)</li>
                    <li>Annual Summits (2018-2024)</li>
                </ul>
            </div>
        ),
        type: "drive",
        link: "https://drive.google.com/drive/folders/1986Savp017N9KuVicp2BfEuguePuyYkU?usp=sharing",
        label: "Google Drive",
    },
];

export default {
    info,
    buttons,
};
