/* eslint-disable */
import React from 'react';
import Button from '@material-ui/core/Button';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';

import FormInput from '../../components/FormInput';

import { ErrorBox } from '../../styles/message';


const RemarkBox = ({
    modalData = {},
    handleFormUpdate = () => {},
}) => {

    return (
    <>
    
        { modalData.status && ( modalData.status === 'active' ) ? (
            <>
                <FormInput
                    tooltip={(
                    <>
                        If this activation or deactivation is out of the ordinary, please remark it. It will be reflected in the <a href="/course_access_report" target={"_blank"}>Course Access Report</a> at the end of the month.
                    </> 
                    )}
                    label="Remarks (Max 500 characters)" name="active_remark" value={( modalData && modalData.active_remark ? modalData.active_remark : '' )} rows={5} multiline={true} onChange={handleFormUpdate} />
                { modalData && modalData.active_remark && !_isEmpty( modalData.active_remark ) && _size( modalData.active_remark ) > 500 ? <ErrorBox>Please reduce your remarks to less than 500 characters. Current size: {_size( modalData.active_remark )} characters.</ErrorBox> : null }
            </>
            ) : null }
            { modalData.status && ( modalData.status === 'inactivate' ) ? (
            <>
                <FormInput 
                    tooltip={(
                        <>
                            If this activation or deactivation is out of the ordinary, please remark it. It will be reflected in the <a href="/course_access_report" target={"_blank"}>Course Access Report</a> at the end of the month.
                        </> 
                    )}
                    label="Remarks (Max 500 characters)" name="inactivate_remark" value={( modalData && modalData.inactivate_remark ? modalData.inactivate_remark : '' )} rows={5} multiline={true} onChange={handleFormUpdate} />
                { modalData && modalData.inactivate_remark && !_isEmpty( modalData.inactivate_remark ) && _size( modalData.inactivate_remark ) > 500 ? <ErrorBox>Please reduce your remarks to less than 500 characters. Current size: {_size( modalData.inactivate_remark )} characters.</ErrorBox> : null }
                <div><strong style={{ fontWeight: '700' }}>Suggestions for Deactivation Remarks:</strong></div>
                {/* <ButtonGroup orientation='vertical' size="small" variant='outlined' color="info" fullWidth={false} disableElevation={false}> */}
                    <Button variant="outlined" color="primary" style={{ marginBottom: '5px', marginRight: '5px' }} onClick={() => handleFormUpdate( 'Deactivate due to miscommunication after confirmation' , 'inactivate_remark' )}><i className='fa fa-plus' style={{ marginRight: '3px' }}></i>Deactivate due to miscommunication after confirmation</Button>
                    <Button variant="outlined" color="primary" style={{ marginBottom: '5px', marginRight: '5px' }} onClick={() => handleFormUpdate( 'Deactivate due to non-commitment' , 'inactivate_remark' )}><i className='fa fa-plus' style={{ marginRight: '3px' }}></i>Deactivate due to non-commitment</Button>
                    <Button variant="outlined" color="primary" style={{ marginBottom: '5px', marginRight: '5px' }} onClick={() => handleFormUpdate( 'Deactivate due to disinterest' , 'inactivate_remark' )}><i className='fa fa-plus' style={{ marginRight: '3px' }}></i>Deactivate due to disinterest</Button>
                    <Button variant="outlined" color="primary" style={{ marginBottom: '5px', marginRight: '5px' }} onClick={() => handleFormUpdate( 'Deactivate due to change of class' , 'inactivate_remark' )}><i className='fa fa-plus' style={{ marginRight: '3px' }}></i>Deactivate due to change of class</Button>
                    <Button variant="outlined" color="primary" style={{ marginBottom: '5px', marginRight: '5px' }} onClick={() => handleFormUpdate( 'Deactivate due to non-payment' , 'inactivate_remark' )}><i className='fa fa-plus' style={{ marginRight: '3px' }}></i>Deactivate due to non-payment</Button>
                {/* </ButtonGroup> */}
            </>
            ) : null }
            { modalData.status && ( modalData.status === 'conclude' ) ? (
            <>
                <FormInput 
                    tooltip={(
                        <>
                            If this activation or deactivation is out of the ordinary, please remark it. It will be reflected in the <a href="/course_access_report" target={"_blank"}>Course Access Report</a> at the end of the month.
                        </> 
                    )}
                    label="Remarks (Max 500 characters)" name="conclude_remark" value={( modalData && modalData.conclude_remark ? modalData.conclude_remark : '' )} rows={5} multiline={true} onChange={handleFormUpdate} />
                { modalData && modalData.conclude_remark && !_isEmpty( modalData.conclude_remark ) && _size( modalData.conclude_remark ) > 500 ? <ErrorBox>Please reduce your remarks to less than 500 characters. Current size: {_size( modalData.conclude_remark )} characters.</ErrorBox> : null }
            </>
            ) : null }
    
    </>
    )
}

export default RemarkBox;