/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import Paper from '@material-ui/core/Paper';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import _size from 'lodash/size';
// import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
// import _slice from 'lodash/slice';
// import _split from 'lodash/split';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _trim from 'lodash/trim';
import _random from 'lodash/random';
// import _replace from 'lodash/replace';
// import _remove from 'lodash/remove';
// import _map from 'lodash/map';

import { doCSVDownload } from '../../modules/TeamsTable/export';

import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormRadio from '../../components/FormRadio';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import Table from '../../components/Table';

import { InfoButton, ErrorButton, ButtonGroup, InverseButton, GreyButton } from '../../styles/button';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, doPagination, getSelectOptions, compareString, doArraySearch } from '../../helpers/data';
// import { getMomentTime } from '../../helpers/date';
import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';

import { addNewMaintenance, updateMaintenance, deleteMaintenance } from '../../actions/maintenance';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../../actions/global';

import { STATE_OPTIONS } from '../../data/const_states';

const performMassDeletion = (schools) => {
    return new Promise((resolve,reject) => {

        let promises = [];
        if ( schools && isArrayExists( schools ) ) {
            schools.forEach(school => {
                if ( school && school.id && !_isEmpty( school.id ) ) {
                    promises.push( callFunctionsAPI({ 
                        url: 'maintenance', 
                        action: 'delete', 
                        formData: { 
                            id: school.id, 
                            type: 'schools' 
                        }
                    }) );
                }
            });
        }

        if ( isArrayExists( promises ) ) {
            Promise.all( promises )
            .then(() => {
                resolve('done');
            })
            .catch(error => {
                reject(error);
            });
        } else {
            resolve(false);
        }

    });
}

const doMassDeletion = async (schools) => {

    const list = [];
    const limits = 5;
    let count = 1,
        batch = [];

    if ( schools && isArrayExists( schools ) ) {
        _forEach( schools, school => {
            batch.push(school);
            if ( count === limits ) {
                list.push(batch); // add to list
                // then reset
                count = 1; 
                batch = [];
            } else {
                count++;
            } // end - count
        });

        // if batch is not empty - add to list
        if ( batch && isArrayExists( batch ) )
            list.push( batch );

    } // end - trainees

    if ( list && isArrayExists( list ) ) {
        let cIndex = 0;
        while ( cIndex < list.length ) {
            await performMassDeletion( list[cIndex] )
                .then(() => {
                    cIndex++;
                })
                .catch(error => {
                    let errorMessage = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to issue badges. Please try again.' ) );
                    throw new Error( errorMessage );
                });
        }
    } // end - contacts

    return 'done';
}

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});

const ActiveTag = styled.div`
    display: inline-block;
    padding: 5px 8px;
    margin: 0px 5px 5px 0px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 6px;
    color: #fff;
    background: ${props => (props.active && props.active == 'yes' ? green['500'] : grey['500'])};
`;

class List extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        sortBy: 'name-asc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalType: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false,
        deleteAllModal: false
    }

    handleCSVExport = (e) => {
        e.preventDefault();
        const { list } = this.props;
        let csvData = {
            fields: [
                "School",
                "State"
            ],
            data: []
        };
        if ( list && isArrayExists( list ) ) {
            _forEach( list, (item) => {
                csvData.data.push({
                    School: item.label || '',
                    State: this.getStateValue( item.value || '' )
                });
            });
        }

        doCSVDownload( csvData, 'schools_custom' );
    }

    handleFormUpdate = (newValue,key,status) => {
        const { modalData } = this.state;
        var newData = ( modalData ? cloneCollections( modalData ) : {} );
        newData[key] = newValue;
        this.setState({ modalData: newData });
    }

    handleAddNew = () => {
        const { modalData } = this.state;
        let submitData = { ...modalData },
            error = false;

        if ( !( modalData && modalData.value && !_isEmpty( modalData.value ) ) ) {
            error = 'Please insert a valid State';
        } // end - modalData.label

        if ( !( modalData && modalData.label && !_isEmpty( modalData.label ) ) ) {
            error = 'Please insert a valid label';
        } // end - modalData.label

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            submitData.label = _trim( submitData.label ).toUpperCase();
            this.props.dispatch(addNewMaintenance(submitData));
        } // end - error
    }

    handleEdit = () => {
        const { modalData } = this.state;
        let submitData = { ...modalData },
            error = false;

        if ( !( modalData && modalData.value && !_isEmpty( modalData.value ) ) ) {
            error = 'Please insert a valid State';
        } // end - modalData.label

        if ( !( modalData && modalData.label && !_isEmpty( modalData.label ) ) ) {
            error = 'Please insert a valid label';
        } // end - modalData.label

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            submitData.label = _trim( submitData.label ).toUpperCase();
            this.props.dispatch(updateMaintenance(submitData));
        } // end - error
    }

    handleAction = () => {
        const { modalType } = this.state;
        if ( modalType && modalType == 'new' ) {
            this.handleAddNew();
        } else if ( modalType && modalType == 'edit' ) {
            this.handleEdit();
        } // end - modalType
    }

    handleDelete = () => {
        const { type } = this.props;
        const { deleteModal } = this.state;
        //perform delete
        this.props.dispatch(deleteMaintenance(deleteModal.id,type));
    }

    handleMassDelete = () => {
        const { dispatch } = this.props;
        const { checked } = this.state;
        const apiNum = _random(1,9999);
        dispatch(toggleModalProcessing(true,apiNum));
        dispatch(toggleLoader(true,"Deleting process is on-going! Please do not close this window or click the Back button on your browser"));
        doMassDeletion(checked)
        .then( list => {
            setTimeout(() => {
                dispatch(toggleModalProcessing(false));
                dispatch(toggleLoader(false));
                triggerSuccessAlert("Deletion process completed");
                this.setState({ checked: [], allCheck: false, deleteAllModal: false });
            },4000);
        })
        .catch( error => {
            dispatch(toggleModalProcessing(false,apiNum));
            dispatch(toggleLoader(false));
            triggerErrorAlert(getAPIErrorMessage(error));
        });
    }

    getStateOptions() {
        let selected = _find( STATE_OPTIONS, { country: 'Malaysia' } ),
            options = [{ value: '', label: 'Select an Option' }];
        if ( selected && selected.states && isArrayExists( selected.states ) ) {
            selected.states.forEach(state => {
                options.push({
                    value: state.toUpperCase(),
                    label: state.toUpperCase()
                });
            });
        }
        return options;
    }

    getStateValue(value) {
        switch( value.toUpperCase() ) {
            // case 'WILAYAH PERSEKUTUAN KUALA LUMPUR':
            //     return 'KUALA LUMPUR';
            // case 'WILAYAH PERSEKUTUAN LABUAN':
            //     return 'LABUAN';
            // case 'MELAKA':
            //     return 'MALACCA'; 
            // case 'PULAU PINANG':
            //     return 'PENANG';
            // case 'WILAYAH PERSEKUTUAN PUTRAJAYA':
            //     return 'PUTRAJAYA';
            case 'KUALA LUMPUR':
            case 'KUALA_LUMPUR':
                return 'WILAYAH PERSEKUTUAN KUALA LUMPUR';
            case 'LABUAN':
                return 'WILAYAH PERSEKUTUAN LABUAN';     
            case 'MALACCA':
                return 'MELAKA';  
            case 'PENANG':
                return 'PULAU PINANG';
            case 'PUTRAJAYA':
                return 'WILAYAH PERSEKUTUAN PUTRAJAYA';
            default:
                return value.toUpperCase();
        }
    }

    reorganizeData() {
        const { sortBy, perPage, page, searchterms } = this.state;
        const { list } = this.props;
        var items = ( list ? cloneCollections( list ) : [] ),
            total = _size( items );

        // perform search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['label','value'] );
			total = _size( items );
        }

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                // case 'position-desc':
                //     items = _sortBy( items, [(i) => ( i.position ? parseInt( i.position, 10 ) : 99 )] );
                //     items = _reverse( items );
                //     break;
                // case 'position-asc':
                //     items = _sortBy( items, [(i) => ( i.position ? parseInt( i.position, 10 ) : 99 )] );
                //     break;
                case 'name-desc':
                    items = _sortBy( items, ['label'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['label'] );
                    break;
                case 'value-desc':
                    items = _sortBy( items, i => this.getStateValue( i.value ) );
                    items = _reverse( items );
                    break;
                case 'value-asc':
                    items = _sortBy( items, i => this.getStateValue( i.value ) );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderViewForm = () => {
        const { authData } = this.props;
        const { modalData, modalType } = this.state;
        return (
        <div>
            <FormInput label="Label *" name="label" value={( modalData.label || '' )} onChange={this.handleFormUpdate} />
            <div style={{ paddingTop: '15px' }}>
                <FormSelect
                    label="State *"
                    name="value"
                    value={this.getStateValue( modalData.value || '' )}
                    options={this.getStateOptions()}
                    onChange={this.handleFormUpdate} />
            </div>
            {/* <div style={{ padding: '15px 10px 5px 10px' }}>
                <FormRadio label="Status" name="status" inline={true} value={( modalData.status || '' )} options={[
                    { value: 'active', label: 'Active' },
                    { value: 'disabled', label: 'Disabled' }
                ]} onChange={this.handleFormUpdate} />
            </div> */}
        </div>
        );
    }

    renderTableActions = () => {
        const { type } = this.props;
        const { sortBy, perPage, searchterms, checked } = this.state;
        return <TableBar
                show={['sort','entries','search']}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'name-asc', label: 'Label ( A - Z)' },
                    { value: 'name-desc', label: 'Label ( Z - A )' },
                    { value: 'value-asc', label: 'State ( A - Z)' },
                    { value: 'value-desc', label: 'State ( Z - A )' },
                    // { value: 'position-asc', label: 'Position (smallest number first)' },
                    // { value: 'position-desc', label: 'Position (biggest number first)' }
                ]}
                leftButtons={ checked && isArrayExists( checked ) ? [
                    <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                        <ButtonGroup>
                            <ErrorButton 
                                style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} 
                                onClick={() => {
                                    this.setState({ deleteAllModal: true });
                                }}>
                                    <i className="fa fa-trash" style={{ marginRight: "10px" }}></i>Delete Selected
                                </ErrorButton>
                            <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                        </ButtonGroup>
                    </div>
                    ] : null }
                rightButtons={[
                    <InverseButton key="export" minWidth="192px" style={{ marginRight: '5px' }} onClick={this.handleCSVExport}><i className="fa fa-file-excel-o"></i>Export Data To CSV</InverseButton>,
                    <InfoButton minWidth="128px" key="addnew" style={{ marginRight: "5px" }} onClick={() => this.setState({ openViewModal: true, modalData: { 
                        type,
                        label: '', 
                        value: '',
                        status: 'active' 
                    }, modalType: 'new' })}><i className="fa fa-plus-circle"></i>Add New</InfoButton>
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderTable = (items) => {
        const { authData } = this.props;
        const { allCheck, checked } = this.state;
        return <Table 
            items={items}
            showCheckbox={true}
            emptyCell="No school(s) Found."
            checked={( checked || [] )}
            allCheck={allCheck}
            onChecked={(newValue) => this.setState({ checked: newValue })}
            onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
            cells={[
                { id: 'label', label: 'School Name', render: (item) => <WrapWord>{( item.label || '-' )}</WrapWord> },
                { id: 'value', label: 'State', render: (item) => <WrapWord>{this.getStateValue( item.value || '' )}</WrapWord> },
            ]}
            actionStyles={{ width: "30%" }}
            actions={(item) => (
                <ButtonGroup>
                    <InfoButton size="small" onClick={() => this.setState({ openViewModal: true, modalData: item, modalType: 'edit' })}><i className="fa fa-edit"></i>Edit</InfoButton>
                    { authData && hasAccessRights( authData, ['mtd'] ) ? <ErrorButton size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton> : null }
                </ButtonGroup>
            )} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    doneLoaded={true}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { typeLabel } = this.props;
        const { openDeleteModal, deleteModal, openViewModal, modalType, deleteAllModal } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={openViewModal}
                title={ modalType && modalType == 'edit' ? "Edit " + ( typeLabel || 'Item' ) : "Add New " + ( typeLabel || 'Item' ) }
                actionLabel={ modalType && modalType == 'edit' ? "Update" : "Add New" }
                onClose={() => this.setState({ openViewModal: false, modalType: false, modalData: false })}
                doAction={this.handleAction}
                contents={this.renderViewForm()} />

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.label ? `Are you sure you want to delete this ${( typeLabel || 'Item' )} ( ${deleteModal.label} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            <ModelDelete
                open={deleteAllModal}
                title={"Are you sure you want to delete all the selected schools?"}
                onClose={() => this.setState({ deleteAllModal: false })}
                onDelete={this.handleMassDelete} />

            {this.renderTableActions()}
            {this.renderTable(items)}
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(List);