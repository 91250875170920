/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Papa from 'papaparse';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

import ImportModal from './import';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormSelect2 from '../../components/FormSelect2';
import FormDatePicker from '../../components/FormDatePicker';
import FormDateOfBirth from '../../components/FormDateOfBirth';
import FormPhoneField from '../../components/FormPhoneField';
import ButtonActions from '../../components/ButtonActions';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InfoLink, InverseButton, ErrorButton, SuccessButton, IndigoButton } from '../../styles/button';
import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';
import { isArrayExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue,  } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp, getAge } from '../../helpers/date';
import { isAdmin, hasAccessRights, getUserRegionFilter } from '../../helpers/auth';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { addNewMentor, removeMentor, resendMentorEmail } from '../../actions/mentors';
import { toggleLoader } from '../../actions/global';

import { userStudentSchema } from '../../schemas/user_student';

import { PLATFORM_COURSE_REGISTRATION_LINK, GENDER_OPTIONS, PLATFORM_ADMIN_DEFAULT_REGION } from '../../constants';

class MentorsTable extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        sortBy: 'date-desc',
        filterBy: 'all',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false,
        openViewModal: false,
        modalData: false,
        csvData: false,
        openImportModal: false,
        randNum: false,
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        
    }

    doCSVDownload = (results,name = "mentors_database") => {
        let csv = Papa.unparse( results, { delimiter: ',' } );
        let blob = new Blob( [csv] , { type: 'text/csv;charset=utf-8;' }); //new way
        let uri = window.URL.createObjectURL(blob);
        let link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", '_blank');
        link.setAttribute("download", "export_"+name+".csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    handleNotionUsersDownload = () => {
        const { dispatch } = this.props;
        dispatch( toggleLoader( true ) );
        callFunctionsAPI({
            method: 'GET',
            url: 'data',
            action: 'get_notion_users'
        })
        .then( users => {
            dispatch( toggleLoader( false ) );
            let csvData = {
                    fields: [
                        "Name",
                        "Email"
                    ],
                    data: []
                };
            if ( users && isArrayExists( users ) ) {
                _forEach( users, user => {
                    if ( user && !_isEmpty( user ) && user.type && user.type === 'person' ) {
                        let row = [];
                        row.push( ( user && user.name ) || '' );
                        row.push( ( user && user.person && user.person.email ) || '' );
                        csvData.data.push(row);
                    } // end - trainee
                });
                this.doCSVDownload(csvData,'notion_users');
            } else {
                triggerErrorAlert( 'No data to export.' );
            }
        })
        .catch(error => {
            dispatch( toggleLoader( false ) );
            triggerErrorAlert( getAPIErrorMessage( error ) );
        });
    }

    handleExport = () => {
        const { filterBy } = this.state;
        const { mentors, regions, schedules } = this.props;
        const list = ( filterBy && !_isEmpty( filterBy ) && filterBy !== 'all' ? _filter( mentors, { region: filterBy }) : cloneCollections( mentors ) );
        if ( list && isArrayExists( list ) ) {
            let csvData = {
                    fields: [
                        "Partner",
                        "Name",
                        "Email",
                        "Classes Mentoring",
                        "Added On",
                    ],
                    data: []
                };
            _forEach( list, item => {
                if ( item && !_isEmpty( item ) ) {
                    let row = [];
                    row.push( getSelectedValue( regions, item.region, 'id', 'label', '-' ) );
                    row.push( ( item && item.name || '' ) );
                    row.push( ( item && item.email || '' ) );

                    let classes = '',
                        enrollment_schedules = [];
                    if ( item && item.enrollments && isArrayExists( item.enrollments ) ) {
                        _forEach( item.enrollments, enrollment => {
                            let schedule = ( enrollment && enrollment.schedule_id && !_isEmpty( enrollment.schedule_id ) ? _find( schedules, { id: enrollment.schedule_id } ) : false );
                            if ( schedule && schedule.id && !_find( enrollment_schedules, { id: schedule.id } ) ) {
                                classes += ( !_isEmpty( classes ) ? '\n' : '' ) + ( schedule.class_name || '' );
                                enrollment_schedules.push( schedule );
                            }
                        });
                    } // end - item.enrollments
                    row.push( classes );

                    row.push( ( item.created_on ? getMomentTime( item.created_on, 'DD MMM YYYY' ) : '' ) );
                    csvData.data.push(row);
                } // end - trainee
            });
            this.doCSVDownload(csvData);
        } else {
            triggerErrorAlert('No lms user(s) found');
        } // end - mentors
    }

    handleMentorDelete = () => {
        const { authData } = this.props;
        const { deleteModal } = this.state;
        // perform delete
        this.props.dispatch(removeMentor(deleteModal.uid));
    }

    handleAddNew = () => {
        const { modalData } = this.state;
        let error = false,
            formData = ( modalData ? cloneCollections( modalData ) : {} );

        // add mentor role
        formData.role = 'mentor';

        if ( !( formData && formData.region && !_isEmpty( formData.region ) ) ) {
            error = 'Please select a valid Partner';
        } // end - formData.email

        if ( !( formData && formData.name && !_isEmpty( formData.name ) ) ) {
            error = 'Please enter a valid name';
        } // end - formData.email

        if ( !( formData && formData.email && validateEmail(formData.email) ) )
            error = 'Please enter a valid email';

        if ( error ) {
            triggerErrorAlert( error );
        } else {
            this.props.dispatch(addNewMentor(formData));
        }
    }

    handleFormUpdate = (newValue,key,subkey,subVal) => {
        const { modalData } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} );
        switch( key ) {
            // case 'course_type':
            //     let selectedType = ( course_type && isArrayExists( course_type ) ? _find( course_type, { id: newValue } ) : false );
            //     newData[key] = newValue;
            //     newData[key + '_label'] = ( selectedType && selectedType.label ? selectedType.label : '' );
            //     break;
            default:
                if ( subkey && ( subkey === 'checked' || subkey === 'unchecked' ) && subVal && !_isEmpty( subVal ) ) {
                    // use subVal as the new value
                    newData[key] = subVal;
                } else {
                    // otherwise - just update normally
                    newData[key] = newValue
                }
                break;
        } // end - key
        this.setState({ modalData: newData });
    }

    getActions = () => {
        const { authData } = this.props;
        let actions = [];

        actions.push({ 
            id: 'addnew', label: 'Add New LMS User', icon: 'fa-plus', onClick: () => this.setState({
                openViewModal: true,
                modalData: { name: '', email: '', region: ( isAdmin( authData ) ? '' : ( authData && authData.region && !_isEmpty( authData.region ) ? authData.region :  PLATFORM_ADMIN_DEFAULT_REGION ) ) }
            })
        });

        actions.push({ 
            id: 'bulkupload', label: 'Bulk Upload', icon: 'fa-cloud-upload', onClick: () => this.setState({ openImportModal: true })
        });

        actions.push({ 
            id: 'downloadcsv', label: 'Export Database', icon: 'fa-cloud-download', disabled: false, onClick: this.handleExport
        });

        if ( authData && isAdmin( authData ) ) {
            actions.push({ 
                id: 'downloadcsvnotion', label: 'Download Notion Users', icon: 'fa-cloud-download', disabled: false, onClick: this.handleNotionUsersDownload
            });
        }

        return actions;
    }

    reorganizeData() {
        const { searchterms, sortBy, perPage, page , filterBy } = this.state;
        const { mentors } = this.props;
        let items = ( mentors ? cloneCollections( mentors ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','email'] );
			total = _size( items );
        } // end - searchterms

        // do filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy !== 'all' ) {
            items = _filter( items, { region: filterBy });
            total = _size( items );
        } // end - filterBy

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['created_on'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['created_on'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
                case 'email-desc':
                    items = _sortBy( items, ['email'] );
                    items = _reverse( items );
                    break;
                case 'email-asc':
                    items = _sortBy( items, ['email'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderModalForm = () => {
        const { authData, regions } = this.props;
        const { modalData } = this.state;
        return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormSelect label="Partner *" name="region" value={( modalData.region || '' )} disabled={( isAdmin( authData ) ? false : true )} options={getSelectOptions({ list: regions, options: [{ value: '', label: 'Select an Option' }], keys: { value: 'id', label: 'label' }, sortBy: 'label' })} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={12}>
                    <FormInput label="Name *" name="name" value={( modalData.name || '' )} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={12}>
                    <FormInput label="Email *" name="email" value={( modalData.email || '' )} onChange={this.handleFormUpdate} />
                </Grid>
            </Grid>
        </div>
        );
    }

    renderCell = type => item => {
        const { regions, schedules } = this.props;
        switch( type ) {
            case 'region':
                return getSelectedValue( regions, item[type], 'id', 'label', '-' );
            case 'enrollments':
                let enrollment_schedules = [];
                if ( item && item.enrollments && isArrayExists( item.enrollments ) ) {
                    _forEach( item.enrollments, enrollment => {
                        if ( enrollment && enrollment.status && enrollment.status === 'active' ) {
                            let schedule = ( enrollment && enrollment.schedule_id && !_isEmpty( enrollment.schedule_id ) ? _find( schedules, { id: enrollment.schedule_id } ) : false );
                            if ( schedule && schedule.id && !_find( enrollment_schedules, { id: schedule.id } ) ) {
                                enrollment_schedules.push( schedule );
                            }
                        }
                    });
                } // end - item.enrollments
                return ( enrollment_schedules && isArrayExists( enrollment_schedules ) ? enrollment_schedules.map(item => (
                    <div key={item.id}><a href={"/r/" + item.id + '?location=course_access'} onClick={event => {
                        event.preventDefault();
                        this.props.history.push('/course_access',{ schedule_id: ( item.id && !_isEmpty( item.id ) ? item.id : null ) });
                    }}>{( item.class_name || '' )}</a></div>
                )) : null );
            case 'age':
                return ( item.date_of_birth_string && !_isEmpty( item.date_of_birth_string ) ? getAge( item.date_of_birth_string ) : ( item[type] || '-' ) );
            case 'created_on':
                return <WrapWord>{( item[type] ? getMomentTime( item[type], 'DD MMM YYYY' ) : '---' )}</WrapWord>;
            case 'email':
                return <WrapWord>{( item[type] || '-' )}</WrapWord>;
        }
    }

    renderTableActions = () => {
        const { authData, regions } = this.props;
        const { sortBy, perPage, searchterms, filterBy, checked } = this.state;
        return <TableBar
                sortBy={sortBy}
                filterBy={filterBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Registration Date (Recent First)' },
                    { value: 'date-asc', label: 'Registration Date (Oldest First)' },
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' },
                    { value: 'email-asc', label: 'Email ( A - Z)' },
                    { value: 'email-desc', label: 'Email ( Z - A )' },
                ]}
                leftButtons={ checked && isArrayExists( checked ) ? [
                    <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                        <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                    </div>
                    ] : null }
                rightButtons={[
                    <ButtonActions 
                        key="actions" 
                        label="Actions"
                        menuContainerStyle={{ width: "250px" }}
                        style={{ marginRight: "5px" }}
                        actions={this.getActions()} />
                ]}
                filterByOptions={ isAdmin( authData ) ? getSelectOptions({ list: regions, options: [{ value: 'all', label: 'All' }], keys: { value: 'id', label: 'label' }, sortBy: 'label' }) : null }
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    doneLoaded={true}
                    // entriesLabel="(Total Registration For This Class)"
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { authData, users, regions } = this.props;
        const { openDeleteModal, deleteModal, openViewModal, modalData, allCheck, checked, openImportModal } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={openViewModal}
                title="Add New LMS User"
                maxWidth="sm"
                onClose={() => this.setState({ openViewModal: false, modalData: false })}
                disableBackdrop={true}
                actionLabel="Add New"
                doAction={this.handleAddNew}
                contents={this.renderModalForm()} />

            { authData && hasAccessRights(authData,['med']) ? <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to remove this lms user ( ${deleteModal.name} ) from the list?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleMentorDelete} /> : null }

            {this.renderTableActions()}
            <Table 
                uniqueKey="uid"
                items={items}
                showCheckbox={false}
                emptyCell="No LMS User(s) Found."
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={(newValue) => this.setState({ checked: newValue })}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                cells={[
                    { id: 'region', label: 'Partner', render: this.renderCell('region') },
                    { id: 'name', label: 'Name', render: (item) => <a
                        href={"/student_database/"+item.uid}
                        target='_blank'
                        onClick={() => {
                            this.props.history.push("/student_database/"+item.uid);
                        }}
                        ><WrapWord>{( item.name || '-' )}</WrapWord></a> },
                    { id: 'email', label: 'Email', render: this.renderCell('email') },
                    { id: 'enrollments', label: 'Classes Mentoring', render: this.renderCell('enrollments') },
                    { id: 'created_on', label: 'Added On', render: this.renderCell('created_on') },
                ]}
                actionStyles={{ width: "30%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <InfoButton key="edit" size="small" onClick={() => {
                            this.props.history.push("/mentors/"+item.uid);
                        }}><i className="fa fa-edit"></i>Edit</InfoButton>
                        <IndigoButton key="resend" size="small" onClick={() => {
                            this.props.dispatch( resendMentorEmail(item.uid) );
                        }}><i className="fa fa-envelope"></i>Resend LMS Activation email</IndigoButton>
                        { authData && hasAccessRights( authData, ['med'] ) ? <ErrorButton key="delete" size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Remove From Mentors List</ErrorButton> : null }
                    </ButtonGroup>
                )} />
            {this.renderPagination(total)}

            <ImportModal
                open={openImportModal}
                authData={authData}
                users={( users || false )}
                regions={regions}
                onClose={() => this.setState({ openImportModal: false })} />

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(MentorsTable);