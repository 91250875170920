/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Papa from 'papaparse';
import Typography from '@material-ui/core/Typography';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormCheckbox from '../../components/FormCheckbox';
import ButtonActions from '../../components/ButtonActions';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { SuccessTag, AmberTag } from '../../styles/tag';
import { WarningBox } from '../../styles/message';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { isAdmin, hasAccessRights } from '../../helpers/auth';

import { COUNTRIES_LIST } from '../../data/const_countries';
import { STATE_OPTIONS } from '../../data/const_states';

import { addPartner, deletePartner } from '../../actions/partners';

import { partnerSchema } from '../../schemas/partner';

class Partners extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        filterBy: 'all',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false
    }

    handlePageRefresh = (event) => {
        const { authData } = this.props;
        event.preventDefault();
        if ( this.props.onRefresh )
            this.props.onRefresh({ authData });
    }

    doCSVDownload = (results) => {
        let csv = Papa.unparse( results, { delimiter: ',' } );
        let blob = new Blob( [csv] , { type: 'text/csv;charset=utf-8;' }); //new way
        let uri = window.URL.createObjectURL(blob);
        let link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", '_blank');
        link.setAttribute("download", "export_partners_profile.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    handleExport = () => {
        const { partners, designation, currency } = this.props;
        const { checked } = this.state;
        if ( checked && isArrayExists( checked ) ) {
            let csvData = {
                    fields: [
                        "Partner Name",
                        "Company Email",
                        "Company Forwarder Email",
                        "Business WhatsApp Number",
                        "Title",
                        "Person-in-charge",
                        "Gender",
                        "Email",
                        "Mobile",
                        "IC/Passport Number",
                        "Company Name",
                        "Company Reg. No",
                        "Address 1",
                        "Address 2",
                        "Postcode",
                        "City",
                        "State",
                        "Country",
                        "Currency",
                        "Payee Name",
                        "Bank Name",
                        "Bank Account No",
                        "SWIFT Code",
                        "Bank Address",
                        "Merchant Key",
                        "Merchant Code",
                    ],
                    data: []
                };
            _forEach( checked, selected => {
                let item = _find( partners, { id: selected.id } );
                if ( item && !_isEmpty( item ) ) {
                    let row = [];
                    row.push( ( item && item.label || '' ) );
                    row.push( ( item && item.email_company || '' ) );
                    row.push( ( item && item.email_forwarder || '' ) );
                    row.push( ( item && item.work_phone || '' ) );
                    row.push( getSelectedValue( designation, item.designation, 'id', 'label', '-' ) );
                    row.push( ( item && item.name || '' ) );
                    row.push( ( item && item.gender || '' ) );
                    row.push( ( item && item.email || '' ) );
                    row.push( ( item && item.mobile || '' ) );
                    row.push( ( item && item.id_num || '' ) );
                    row.push( ( item && item.company_name || '' ) );
                    row.push( ( item && item.company_reg_no || '' ) );
                    row.push( ( item && item.address || '' ) );
                    row.push( ( item && item.address2 || '' ) );
                    row.push( ( item && item.postcode || '' ) );
                    row.push( ( item && item.city || '' ) );
                    row.push( ( item && item.state || '' ) );
                    row.push( getSelectedValue( COUNTRIES_LIST, ( item && item.country || '' ), 'value', 'label', '-' ) );
                    row.push( getSelectedValue( currency, ( item && item.finance && item.finance.currency || '' ), 'id', 'label', '-' ) );
                    row.push( ( item && item.finance && item.finance.payee_name || '' ) );
                    row.push( ( item && item.finance && item.finance.bank_name || '' ) );
                    row.push( ( item && item.finance && item.finance.bank_account_no || '' ) );
                    row.push( ( item && item.finance && item.finance.bank_swift_code || '' ) );
                    row.push( ( item && item.finance && item.finance.bank_address || '' ) );
                    row.push( ( item && item.merchant_key || '' ) );
                    row.push( ( item && item.merchant_code || '' ) );
                    csvData.data.push(row);
                } // end - trainee
            });
            this.doCSVDownload(csvData);
        } else {
            triggerErrorAlert('Please select at least one personnel');
        } // end - checked
    }

    handleFormUpdate = (newValue,key,subkey,subVal) => {
        const { modalData } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} );
        if ( subkey && ( subkey === 'checked' || subkey === 'unchecked' ) && subVal && !_isEmpty( subVal ) ) {
            // use subVal as the new value
            newData[key] = subVal;
        } else {
            // otherwise - just update normally
            newData[key] = newValue;
        }
        this.setState({ modalData: newData });
    }

    handleAddNew = () => {
        const { history } = this.props;
        const { modalData } = this.state;
        var error = false;


        if ( !( modalData && modalData.name && !_isEmpty( modalData.name ) ) ) {
            error = 'Please enter a valid name';
        } // end - modalData.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addPartner(modalData,history));
        } // end - error
    }

    handleEdit = (id,event) => {
        event.preventDefault();
        this.props.history.push("/partner/"+id);
    }

    handleDelete = () => {
        const { deleteModal } = this.state;
        // perform delete
        this.props.dispatch(deletePartner(deleteModal.id,deleteModal.region));
    }

    handleAction = type => {
        const { checked } = this.state;
        // make sure there is an item selected
        if ( isArrayExists( checked ) ) {

        } else {
            triggerErrorAlert("Please select at least one item");
        } // end - checked
    }

    getActions = () => {
        const { authData } = this.props;
        let actions = [];

        if ( isAdmin( authData ) ) {
            actions.push({ 
                id: 'addnew', label: 'Add New Partner', icon: 'fa-plus-circle', onClick: () => this.setState({ openViewModal: true, modalData: { 
                    label: '',
                    name: '',
                    email: '',
                    invitation: 'yes'
                } }) 
            });

            actions.push({ 
                id: 'downloadcsv', label: 'Export Database', icon: 'fa-cloud-download', disabled: false, onClick: this.handleExport
            });
        } // end - isAdmin

        return actions;
    }

    getQueryProps = () => {
        const { sortBy, perPage, page } = this.state;
        return false;
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { partners } = this.props;
        let items = ( partners ? cloneCollections( partners ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['label','name','company_name','state','city'] );
			total = _size( items );
        } // end - searchterms

        // do custom filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy !== 'all' ) {
            // items = _filter( items, { org: filterBy });
            // total = _size( items );
        }

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, (i) => ( i.modified_on && i.modified_on._seconds ? i.modified_on._seconds : i.modified_on ) );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, (i) => ( i.modified_on && i.modified_on._seconds ? i.modified_on._seconds : i.modified_on ) );
                    break;
                case 'label-desc':
                    items = _sortBy( items, ['label'] );
                    items = _reverse( items );
                    break;
                case 'label-asc':
                    items = _sortBy( items, ['label'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
                case 'city-desc':
                    items = _sortBy( items, ['city'] );
                    items = _reverse( items );
                    break;
                case 'city-asc':
                    items = _sortBy( items, ['city'] );
                    break;
                case 'state-desc':
                    items = _sortBy( items, ['state'] );
                    items = _reverse( items );
                    break;
                case 'state-asc':
                    items = _sortBy( items, ['state'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    // renderCell = type => item => {
    //     switch( type ) {
    //         case 'email':
    //             return <WrapWord>{( item[type] || '' )}</WrapWord>
    //     }
    // }

    renderAddNewForm = () => {
        const { modalData } = this.state;
        return (
        <div>
            <WarningBox style={{ marginBottom: '10px' }}>
                <i className="fa fa-lightbulb-o"></i><strong style={{ fontWeight: '700' }}>Note: </strong>Do this together with the Person-in-Charge so that follow-up actions can be taken immediately.
            </WarningBox>
            <FormInput label="Partner *" name="label" value={( modalData.label || '' )} onChange={this.handleFormUpdate} />
            <FormInput label="Person-in-charge" name="name" value={( modalData.name || '' )} onChange={this.handleFormUpdate} />
            <FormInput label="Email Address *" name="email" type="email" value={( modalData.email || '' )} onChange={this.handleFormUpdate} />
            <FormCheckbox label="Send Account Creation Email" name="invitation" value={( modalData.invitation || 'no' )} checked="yes" unchecked="no" onChange={this.handleFormUpdate} />
        </div>
        );
    }

    renderTableActions = () => {
        const { authData } = this.props;
        const { sortBy, perPage, searchterms, checked } = this.state;
        return <TableBar
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Modified first' },
                    { value: 'date-asc', label: 'Oldest Modified first' },
                    { value: 'label-asc', label: 'Partner ( A - Z)' },
                    { value: 'label-desc', label: 'Partner ( Z - A )' },
                    { value: 'name-asc', label: 'Person-in-charge ( A - Z)' },
                    { value: 'name-desc', label: 'Person-in-charge ( Z - A )' },
                    { value: 'state-asc', label: 'State ( A - Z)' },
                    { value: 'state-desc', label: 'State ( Z - A )' },
                    { value: 'city-asc', label: 'City ( A - Z)' },
                    { value: 'city-desc', label: 'City ( Z - A )' }
                ]}
                // leftButtons={ checked && isArrayExists( checked ) ? [
                //     <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                //         <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                //     </div>
                //     ] : null }
                rightButtons={[
                    <ButtonActions 
                        key="actions" 
                        label="Actions"
                        menuContainerStyle={{ width: "200px" }}
                        style={{ marginRight: "5px" }}
                        actions={this.getActions()} />
                    ,
                    <InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton>
                ]}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    doneLoaded={true}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { authData } = this.props;
        const { openDeleteModal, deleteModal, openViewModal, allCheck, checked } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            { authData && isAdmin(authData) ? <ModalView 
                open={openViewModal}
                title="Add New Partner"
                onClose={() => this.setState({ openViewModal: false, modalData: false })}
                doAction={this.handleAddNew}
                contents={this.renderAddNewForm()} /> : null }

            { authData && isAdmin(authData) ? <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.label ? `Are you sure you want to delete this partner ( ${deleteModal.label} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} /> : null }

            {this.renderTableActions()}
            <Table 
                items={items}
                showCheckbox={true}
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={(newValue) => this.setState({ checked: newValue })}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                cells={[
                    { id: 'label', label: 'Partner', render: (item) => ( item.label || '' ) },
                    { id: 'name', label: 'Person-in-charge', render: (item) => ( item.name || '' ) },
                    { id: 'company_name', label: 'Company Name', render: (item) => ( item.company_name || '' ) },
                    { id: 'state', label: 'State', render: (item) => ( item.state || '' ) },
                    { id: 'city', label: 'City', render: (item) => ( item.city || '' ) }
                    
                ]}
                actionStyles={{ width: "10%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <AInfoLink key="edit" href={"/partner/"+item.id} size="small" onClick={this.handleEdit.bind(this,item.id)}><i className="fa fa-edit"></i>Edit</AInfoLink>
                        { authData && isAdmin( authData ) ? <ErrorButton key="delete" size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton> : null }
                    </ButtonGroup>
                )} />
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(Partners);