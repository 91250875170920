/* eslint-disable */
import React from 'react';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import indigo from '@material-ui/core/colors/indigo';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';

import styled from "styled-components";

const MessageBox = styled.div`
    display: ${props => (props.inline ? "inline-block" : "block")};
    margin-top: 5px;
    padding: 5px 12px;
    font-size: 1.35rem;
    font-weight: 400;
    color: #212121;

    i {
        display: inline-block;
        margin-right: 8px;
    }

    a {
        font-weight: 900;
        text-decoration: underline;
    }
`;

const Info = `
    background: ${blue['50']};
    border: 2px solid ${blue['700']};

    i {
        color: ${blue['700']};
    }

    a {
        color: ${blue['700']};
        &:hover { color: ${blue['500']}; }
    }
`;

const Success = `
    background: ${green['50']};
    border: 2px solid ${green['700']};

    i {
        color: ${green['700']};
    }

    a {
        color: ${green['700']};
        &:hover { color: ${green['500']}; }
    }
`;

const Warning = `
    background: ${amber['50']};
    border: 2px solid ${amber['700']};

    i {
        color: ${amber['700']};
    }

    a {
        color: ${amber['700']};
        &:hover { color: ${amber['500']}; }
    }
`;

const ErrorColor = `
    background: ${red['50']};
    border: 2px solid ${red['700']};

    i {
        color: ${red['700']};
    }

    a {
        color: ${red['700']};
        &:hover { color: ${red['500']}; }
    }
`;

const Indigo = `
    background: ${indigo['50']};
    border: 2px solid ${indigo['700']};

    i {
        color: ${indigo['700']};
    }

    a {
        color: ${indigo['700']};
        &:hover { color: ${indigo['500']}; }
    }
`;

const Grey = `
    background: ${grey['50']};
    border: 2px solid ${grey['700']};

    i {
        color: ${grey['700']};
    }

    a {
        color: ${grey['700']};
        &:hover { color: ${grey['500']}; }
    }
`;

export const InfoBox = styled(MessageBox)`
    ${Info}
`;


export const SuccessBox = styled(MessageBox)`
    ${Success}
`;


export const WarningBox = styled(MessageBox)`
    ${Warning}
`;


export const ErrorBox = styled(MessageBox)`
    ${ErrorColor}
`;


export const IndigoBox = styled(MessageBox)`
    ${Indigo}
`;


export const GreyBox = styled(MessageBox)`
    ${Grey}
`;