/* eslint-disable */
import React, { useState } from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import _isEmpty from 'lodash/isEmpty';

import UnenrolStudents from '../../modules/UnenrolStudents'

import ModalView from '../../components/ModalView';

import { triggerErrorAlert } from '../../helpers/alert';

import { WrapWord } from '../../styles/misc';
import { AInfoLink, WarningButton } from '../../styles/button';

import theme from '../../theme';
import { PLATFORM_URL } from '../../constants';



const StudentNameToDatabase = ({
    id,
    name = '',
    enrollment_status = '',
    schedule_id = false,
    history,
    showBackpackLink = false,
    showUnenrolOption = false,
    onStudentUnenrol = () => {}
}) => {
    return name && !_isEmpty( name ) ? (
    <WrapWord>
        {( enrollment_status && enrollment_status === 'confirmed' ? (
        // <a href={"/t/"+id} onClick={(event) => {
        //     event.preventDefault();
        //     history.push("/t/"+id);
        // }}>{name}</a>
        <a href={"/t/"+id} target="_blank">{name}</a>
        ) : name )}
        { showBackpackLink && (
        <div style={{ paddingTop: '5px' }}>
            <Tooltip 
                title="View Backpack"
                arrow 
                placement="top"
                >
                <AInfoLink 
                    href={PLATFORM_URL+"bp/" + id } 
                    target="_blank" 
                    minWidth="0px" 
                    //noIconMargin="yes" 
                    style={{ padding: '5px 15px' }}><i className='fa fa-external-link'></i>
                </AInfoLink>
            </Tooltip>
        </div>
        ) }
        {
            showUnenrolOption && (
            <>
                <UnenrolStudents
                    id={id}
                    name={name}
                    schedule_id={schedule_id}
                    onStudentUnenrol={onStudentUnenrol}
                    />
                
            </>
            )
        }
    </WrapWord>
    ) : '-';
}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        changesNotSaved: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(StudentNameToDatabase);