/* eslint-disable */
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _isInteger from 'lodash/isInteger';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';
import _size from 'lodash/size';
import _split from 'lodash/split';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';

import { isArrayExists, isObjectExists } from './validation';
import { replaceAll, compareString } from './data';

// import { ENROLLMENT_STATUS_OPTIONS } from '../constants';

// get course badges (based on lms course id)
export const getCourseBadges = ({ lms_course_id, schedule, badges }) => {
    const course = ( schedule && schedule.course_access && isArrayExists( schedule.course_access ) && lms_course_id ? _find( schedule.course_access, (c) => compareString( c.lms_course_id, lms_course_id ) ) : false );
    return ( course && course.id && badges && isArrayExists( badges ) ? _filter( badges, b => b.references && compareString( b.references.course_id, course.id ) ? true : false ) : [] );
}

// get selected trainee data (based on lms course id)
export const getSelectedTraineeData = ({ trainee, lms_course_id, key = false }) => {
    return ( trainee && key && !_isEmpty( key ) && trainee[key] && isArrayExists( trainee[key] ) ? _find( trainee[key], b => compareString( b.id, lms_course_id ) ) : false );
}

// get trainee data from trainees
export const getCurrentTrainee = ({ currentTrainees, trainee }) => {
    const selected = ( currentTrainees && isArrayExists( currentTrainees ) && trainee && trainee.id ? _find( currentTrainees, { id: trainee.id }) : false );
    return ( selected ? selected : ( trainee || false ) );
}

// get trainee from showcase data
export const getTraineeFromShowcase = ({ showcase, trainee }) => {
    return ( trainee && trainee.id && !_isEmpty(trainee.id) && showcase && showcase.trainees && isArrayExists( showcase.trainees ) ? _find( showcase.trainees, { id: trainee.id }) : false );
}

// get trainee's course data from showcase
export const getTraineeCourseFromShowcase = ({ trainee, lms_course_id, showcase }) => {
    const traineeData = getTraineeFromShowcase({ trainee, showcase });
    return ( traineeData && traineeData.courses && isArrayExists( traineeData.courses ) ? _find( traineeData.courses, c => compareString( c.id, lms_course_id ) ) : false );
}

// get feedback form answer from trainee
export const getFeedbackformFromTrainee = ({ trainee, lms_course_id, showcase }) => {
    const course = getTraineeCourseFromShowcase({ trainee, lms_course_id, showcase });
    // make sure feedback_form_answers isn't empty
    if ( course && course.feedback_form_answers && isArrayExists( course.feedback_form_answers ) ) {
        let count = 0;
        course.feedback_form_answers.forEach(answer => {
            if ( !( answer.value && !_isEmpty(answer.value) ) ) {
                count++;
            }
        });
        if ( course.feedback_form_answers.length === count ) {
            return false;
        } else {
            return course.feedback_form_answers;
        }
    } else {
        return false;
    }
}

// get trainee showcase status
export const getShowcaseStatus = ({ trainee, lms_course_id, showcase, currentTrainees }) => {
    const currentTrainee = getCurrentTrainee({ currentTrainees, trainee });
    const showcaseData = getSelectedTraineeData({ key: 'showcase', trainee: currentTrainee, lms_course_id });
    const feedbackForm = getFeedbackformFromTrainee({ trainee: currentTrainee, lms_course_id, showcase });
    const courseShowcase = getTraineeCourseFromShowcase({ trainee: currentTrainee, lms_course_id, showcase });
    let status = 'ineligible'; // ignore feedbackForm
    if ( courseShowcase && courseShowcase.issued_badge_id && !_isEmpty( courseShowcase.issued_badge_id ) ) {
        status = 'issued';
    } else if ( 
        // feedbackForm || 
        ( showcaseData && showcaseData.badge_id && !_isEmpty( showcaseData.badge_id ) ) ||
        ( showcaseData && showcaseData.badge_type && !_isEmpty( showcaseData.badge_type ) ) || 
        ( courseShowcase && courseShowcase.project_desc && !_isEmpty( courseShowcase.project_desc ) )
    ) {
        status = 'action_needed';
    }
    // check if it is from transferred trainee
    if ( 
        isBadgeIssuedFromTransferedClass({ trainee: currentTrainee, lms_course_id })
    ) {
        status = 'issued';
    } // end - trainee
    return status;
}

// check if the badge is issued in previous tranfered class
export const isBadgeIssuedFromTransferedClass = ({ trainee, lms_course_id }) => {
    let valid = false;

    if ( 
        trainee && trainee.transfer_trainee && isObjectExists( trainee.transfer_trainee ) &&
        trainee.transfer_trainee_showcase_data && isArrayExists( trainee.transfer_trainee_showcase_data ) &&
        trainee.transfer_trainee_showcase_data.find( t => compareString( t.id, lms_course_id ) )
    ) {
        let selectedCourse = trainee.transfer_trainee_showcase_data.find( t => compareString( t.id, lms_course_id ) );
        if ( selectedCourse && selectedCourse.badge && 
            selectedCourse.badge.issued_badge_id && 
            !_isEmpty( selectedCourse.badge.issued_badge_id ) ) {
            valid = true;
        }
    } // end - trainee

    return valid;
}

// get badges options for trainee
export const getBadgesOptions = ({ trainee, lms_course_id, badges }) => {
    const courseAccess = getSelectedTraineeData({ key: 'course_access', trainee, lms_course_id });
    // const courseProfile = ( modalData && modalData.course_id ? _find( courses, c => _toString( c.lms_course_id ) === _toString( modalData.course_id ) ) : false ),
    const courseBadges = ( courseAccess && courseAccess.course_id ? _filter( badges, b => b.references && compareString( b.references.course_id , courseAccess.course_id ) ) : false );

    let options = [{ value: '', label: 'Select an Option' }];
    if ( courseBadges && isArrayExists( courseBadges ) ) {
        _forEach( _sortBy( courseBadges, ['type_label'] ), badge => {
            options.push({
                value: badge.type,
                label: ( badge.type_label || '' )
            });
        });
    } // end - courseBadges
    return options
}

// get transfer trainee showcase data
export const getTransferTraineeShowcaseData = ({ trainee, lms_course_id }) => {
    let showcaseData = false;
    if ( trainee && trainee.transfer_trainee && isObjectExists( trainee.transfer_trainee ) &&
        trainee.transfer_trainee_showcase_data && isArrayExists( trainee.transfer_trainee_showcase_data ) &&
        trainee.transfer_trainee_showcase_data.find( t => compareString( t.id, lms_course_id ) )
    ) {
        showcaseData = trainee.transfer_trainee_showcase_data.find( t => compareString( t.id, lms_course_id ) );
    } // end - trainee
    return showcaseData;
}

// convert showcase data to renderable data
export const convertTranferedTraineeShowcaseData = (showcaseData) => {
    let data = {
        id: showcaseData.id,
        booking_date: ( showcaseData && showcaseData.showcase && showcaseData.showcase.booking_date ? showcaseData.showcase.booking_date : 0 ), // booking date of showcase date
        showcase_date: ( showcaseData && showcaseData.showcase && showcaseData.showcase.showcase_date ? showcaseData.showcase.showcase_date : 0 ),  // showcase date booked
        showcase_date_calendar_id: ( showcaseData && showcaseData.showcase && showcaseData.showcase.calendar_id ? showcaseData.showcase.calendar_id : '' ),  // calendar_id for the date booked
        project_title_1: ( showcaseData && showcaseData.project && showcaseData.project.title ? showcaseData.project.title : '' ),
        project_url_1: ( showcaseData && showcaseData.project && showcaseData.project.desc ? showcaseData.project.desc : '' ),
        project_feedback: ( showcaseData && showcaseData.project && showcaseData.project.feedback ? showcaseData.project.feedback : '' ),
        project_reenabled: ( showcaseData && showcaseData.project && showcaseData.project.project_reenabled && ( showcaseData.project.project_reenabled === 'yes' || showcaseData.project.project_reenabled === 'no' ) ? showcaseData.project.project_reenabled : 'no' ),
        nominated_badge_type: ( showcaseData && showcaseData.badge && showcaseData.badge.nominated_badge_type ? showcaseData.badge.nominated_badge_type : '' ),
        nominated_by: ( showcaseData && showcaseData.badge && showcaseData.badge.nominated_by ? showcaseData.badge.nominated_by : '' ),
        nominated_by_email: ( showcaseData && showcaseData.badge && showcaseData.badge.nominated_by_email ? showcaseData.badge.nominated_by_email : '' ),
        nominated_on: ( showcaseData && showcaseData.badge && showcaseData.badge.nominated_on ? showcaseData.badge.nominated_on : 0 ),
        approved_badge_id: ( showcaseData && showcaseData.badge && showcaseData.badge.approved_badge_id ? showcaseData.badge.approved_badge_id : '' ),
        approved_badge_type: ( showcaseData && showcaseData.badge && showcaseData.badge.approved_badge_type ? showcaseData.badge.approved_badge_type : '' ),
        approved_by: ( showcaseData && showcaseData.badge && showcaseData.badge.approved_by ? showcaseData.badge.approved_by : '' ),
        approved_by_email: ( showcaseData && showcaseData.badge && showcaseData.badge.approved_by_email ? showcaseData.badge.approved_by_email : '' ),
        approved_on: ( showcaseData && showcaseData.badge && showcaseData.badge.approved_on ? showcaseData.badge.approved_on : 0 ),
        issued_badge_id: ( showcaseData && showcaseData.badge && showcaseData.badge.issued_badge_id ? showcaseData.badge.issued_badge_id : '' ),
        issued_badge_on: ( showcaseData && showcaseData.badge && showcaseData.badge.issued_badge_on ? showcaseData.badge.issued_badge_on : 0 ),
        feedback_form_answers: showcaseData && showcaseData.feedback_form_answers ? showcaseData.feedback_form_answers : [],
    };

    return data;
}

// get trainee's team data
export const getTraineeTeamData = ({ teams, trainee_id }) => {
    return ( teams && trainee_id && isArrayExists( teams ) ? _find( teams, t => {
        return ( t.trainees && isArrayExists( t.trainees ) && _find( t.trainees, { id: trainee_id }) ? true : false );
    }) : false ); 
}

// get team data
export const getTeamData = ({ teams, id }) => {
    return ( teams && id && isArrayExists( teams ) ? _find( teams, { id } ) : false ); 
}

// get team project data
export const getTeamProjectSubmission = ({ teams, id, schedule }) => {
    const team = getTeamData({ teams, id });
    let projects = [];
    if ( schedule && schedule.project_submissions && !_isEmpty( schedule.project_submissions ) ) {
        const total = parseInt( schedule.project_submissions );
        for (let i = 1; i <= total; i++) {
            const project = ( team && team.projects && isArrayExists( team.projects ) ? _find( team.projects, p => compareString( p.id, i ) ) : false );
            if ( project ) {
                projects.push( project );
            } else {
                projects.push({
                    id: i.toString(),
                    title: '',
                    desc: '',
                    project_feedback: ''
                });
            }
        }
    } else {
        projects = ( team && team.projects && isArrayExists( team.projects ) ? [ ...team.projects ] : [] );
    }
    return projects;
}

// is competition module enabled
export const isCompetitionModule = (schedule) => {
    return ( schedule && schedule.team_module && schedule.team_module === 'yes' ? true : false );
}

// check if student in team already have issued badge
export const isTeamBadgeIssued = ({ trainees, team }) => {
    let issued = false;
    if ( team && team.trainees && isArrayExists( team.trainees ) ) {
        _forEach( team.trainees, t => {
            const trainee = _find( trainees, { id: t.id });
            const showcase = ( trainee && trainee.showcase && isArrayExists( trainee.showcase ) ? _find( trainee.showcase, s => compareString( s.id, team.lms_course_id ) ) : false );
            if ( showcase && showcase.badge_id && !_isEmpty( showcase.badge_id ) ) {
                issued = true;
            }
        });
    } // end - trainees
    return issued;
}