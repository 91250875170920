/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import blue from "@material-ui/core/colors/blue";
import { withStyles } from "@material-ui/core/styles";
import _isEmpty from "lodash/isEmpty";

import infoCoursesPage from "./info_CoursesPage";
import infoProgramsPage from "./info_ProgramsPage";
import infoLocationsPage from "./info_LocationsPage";
import infoPartnersPage from "./info_PartnersPage";
import infoPersonnelsPage from "./info_PersonnelsPage";
import infoDatabaseMentorsPage from "./info_DatabaseMentorsPage";
import infoClassRegistrationPage from "./info_ClassRegistrationPage";
import infoDatabaseMarketingPage from "./info_DatabaseMarketingPage";
import infoClassConfirmationPage from "./info_ClassConfirmationPage";
import infoClassCourseAccessPage from "./info_ClassCourseAccessPage";
import infoClassPaymentTrackingPage from "./info_ClassPaymentTrackingPage";
import infoClassAttendanceReportingPage from "./info_ClassAttendanceReportingPage";
import infoClassShowcasePage from "./info_ClassShowcasePage";
import infoClassQuarterlyReportPage from "./info_ClassQuarterlyReportPage";
import infoBusinessDashboard from "./info_BusinessDashboard";

import InformationBox from "../../components/InformationBox";

import { isArrayExists, isObjectExists } from "../../helpers/validation";

import { WrapWord } from "../../styles/misc";
import { AInfoLink, WarningButton, AInverseLink } from "../../styles/button";
import { InfoBox } from "../../styles/message";

import theme from "../../theme";
import { PLATFORM_URL } from "../../constants";

const useStyles = (theme) => ({
    popper: {
        zIndex: "3000",
        pointerEvents: "auto",
    },
    tooltip: {
        fontSize: "14px",
        color: "#fff",
        background: blue[400],
        maxWidth: 400,
        padding: "0px",
        "& > .MuiTooltip-arrow": {
            color: blue[400],
        },
    },
});

const Button = ({ tooltip = false, tooltipContent = "", type = "", link = "", label = "", classes = {} }) => {
    const imgSrc = useMemo(() => {
        switch (type) {
            case "notion":
                return "https://firebasestorage.googleapis.com/v0/b/chumbaka-website.appspot.com/o/website%2Fnotion.png?alt=media&token=e4d90009-68aa-438a-8c37-3e8e71d43486";
            case "drive":
                return "https://firebasestorage.googleapis.com/v0/b/chumbaka-dev-tms.appspot.com/o/file_storage%2FGoogle_Drive_logo.png?alt=media&token=dbb71881-bd34-4756-81e4-1276bed82281";
        }
        return "";
    }, [type]);

    if (tooltip && !_isEmpty(tooltipContent)) {
        return (
            <Tooltip
                arrow
                placement="top"
                title={tooltipContent}
                classes={{
                    popper: classes.popper,
                    tooltip: classes.tooltip,
                }}
            >
                <AInverseLink href={link} style={{ padding: "10px 12px", color: "#fff", textDecoration: "none" }} target="_blank">
                    {imgSrc && !_isEmpty(imgSrc) && <img src={imgSrc} style={{ maxWidth: "20px", verticalAlign: "middle", marginRight: "10px" }} />}
                    {label}
                </AInverseLink>
            </Tooltip>
        );
    }

    return (
        <AInverseLink href={link} style={{ padding: "10px 12px", color: "#fff", textDecoration: "none" }} target="_blank">
            {imgSrc && !_isEmpty(imgSrc) && <img src={imgSrc} style={{ maxWidth: "20px", verticalAlign: "middle", marginRight: "10px" }} />}
            {label}
        </AInverseLink>
    );
};

const AppInfoBox = ({ page = "", classes = {} }) => {
    const { info = {}, buttons = [] } = useMemo(() => {
        switch (page) {
            case "CoursesPage":
                return infoCoursesPage;
            case "ProgramsPage":
                return infoProgramsPage;
            case "LocationsPage":
                return infoLocationsPage;
            case "PartnersPage":
                return infoPartnersPage;
            case "PersonnelsPage":
                return infoPersonnelsPage;
            case "DatabaseMentorsPage":
                return infoDatabaseMentorsPage;
            case "ClassRegistrationPage":
                return infoClassRegistrationPage;
            case "DatabaseMarketingPage":
                return infoDatabaseMarketingPage;
            case "ClassConfirmationPage":
                return infoClassConfirmationPage;
            case "ClassCourseAccessPage":
                return infoClassCourseAccessPage;
            case "ClassPaymentTrackingPage":
                return infoClassPaymentTrackingPage;
            case "ClassAttendanceReportingPage":
                return infoClassAttendanceReportingPage;
            case "ClassShowcasePage":
                return infoClassShowcasePage;
            case "ClassQuarterlyReportPage":
                return infoClassQuarterlyReportPage;
            case "BusinessDashboard":
                return infoBusinessDashboard;
        }
        return false;
    }, [page]);

    const { heading = {}, contents = [] } = info;

    return (
        <InfoBox style={{ marginBottom: "20px" }}>
            {info && isObjectExists(info) ? <InformationBox headling={heading} contents={contents} /> : null}
            {buttons && isArrayExists(buttons) ? (
                <Grid
                    container
                    style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                    }}
                >
                    {buttons.map((buttonProps) => (
                        <Button key={buttonProps.id} {...buttonProps} classes={classes} />
                    ))}
                </Grid>
            ) : null}
        </InfoBox>
    );
};

export default compose(withRouter, withStyles(useStyles))(AppInfoBox);
