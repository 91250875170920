import React from "react";

const info = {
    heading: <></>,
    contents: [
        {
            id: "1",
            title: "What is 3. Delivery-Attendance & Reporting stage? ",
            content: (
                <>
                    <div>
                        <p>Delivery is about fulfilling your obligation to students and their parents.</p>
                        <p>
                            Use this tracker to help you with tracking students’ attendance & activities. Mentors are able to access this via LMS (
                            <a href="https://lms.chumbaka.asia/courses/105/modules/items/10393" target="_blank">
                                example link
                            </a>
                            ).
                        </p>
                    </div>
                </>
            ),
        },
        {
            id: "2",
            title: "How is this page useful? ",
            content: (
                <>
                    <div>
                        <ul style={{ listStyle: "disc", paddingLeft: "10px", marginLeft: "10px" }}>
                            <li>Craft Mentor’s General Comment </li>
                            <li>Edit student’s attendance </li>
                            <li>Mass edit students’ attendance</li>
                            <li>
                                The dates displayed here are connected to the{" "}
                                <a href="https://sms.chumbaka.asia/schedules/JUMPSTAR_MON0900_876" target="_blank">
                                    Class Calendar scheduled
                                </a>
                            </li>
                        </ul>
                    </div>
                </>
            ),
        },
    ],
};

const buttons = [
    {
        id: "1",
        tooltip: true,
        tooltipContent: (
            <div style={{ padding: "10px" }}>
                <p style={{ fontWeight: "700" }}>📂 Chumbaka Partner Portal - 6. Operation - 4. Closing </p>
                <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                    <li>Management Review</li>
                </ul>
            </div>
        ),
        type: "notion",
        link: "https://chumbaka.notion.site/4-Closing-Open-Day-Digital-Badges-6d0c7f4c6d3a4a968d9bc32cb7476e51?pvs=4",
        label: "Notion",
    },
    {
        id: "2",
        tooltip: true,
        tooltipContent: (
            <div style={{ padding: "10px" }}>
                <p style={{ fontWeight: "700" }}>📂 Chumbaka Mentor Portal - 4. Closing</p>
                <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                    <li>Digital Badge Criteria</li>
                    <li>Guide to create/update portfolio</li>
                    <li>Open Day Feedback form</li>
                </ul>
            </div>
        ),
        type: "drive",
        link: "https://drive.google.com/drive/u/0/folders/1p-exsrgkILH0-54Y1wKSqHCxtZTke9C5",
        label: "Google Drive",
    },
];

export default {
    info,
    buttons,
};
