export const scheduleSchema = [
    { 
        id: 'id', 
        label: 'ID',
        required: ['update','delete'], 
        default: '', 
        type: 'string', 
        validation: 'string_id', 
        skip: ['add'],
        disabled: ['update'],
    },
    {   
        id: 'id_section',
        label: 'Section ID',
        required: false,
        default: '',
        type: 'system_data',
        skip: ['add','update'],
        sync: true
    },
    {   
        id: 'is_program_schedule',
        label: 'Is Program Schedule',
        required: false,
        default: 'yes',
        type: 'string',
        skip: false,
    },
    {   
        id: 'region',
        label: 'Partner',
        required: ['add'],
        default: '',
        type: 'string',
        field: 'select',
        skip: ['update'],
        disabled: ['update','admin'],
        sync: true
    },
    {   
        id: 'partner_id',
        label: 'Partner ID (OLD)',
        required: false,
        default: '',
        type: 'system_data',
        skip: ['update'],
    },
    {   
        id: 'partner_email',
        label: 'Partner Email',
        required: false,
        default: '',
        type: 'system_data',
        skip: ['update'],
        dependency: { schema: 'partner', id: 'email_forwarder' }
    },
    {   
        id: 'partner_contact',
        label: 'Partner Contact',
        required: false,
        default: '',
        type: 'system_data',
        skip: ['update'],
        dependency: { schema: 'partner', id: 'work_phone' }
    },
    
    {   
        id: 'class_name',
        label: 'Class Name',
        required: ['update'],
        default: '',
        type: 'string',
        field: 'text',
        sync: true
    },
    {   
        id: 'program_id',
        label: 'Program',
        required: ['add'],
        default: '',
        type: 'string',
        field: 'select',
        skip: ['update'],
        disabled: ['update'],
        sync: true
    },
    {   
        id: 'program_name',
        label: 'Program Name',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        skip: ['update'],
        disabled: ['add','update'],
        dependency: { schema: 'program', id: 'name' },
        sync: true
    },
    {   
        id: 'course_duration',
        label: 'Duration',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        disabled: ['update'],
        skip: ['update'],
        dependency: { schema: 'program', id: 'course_duration' },
        sync: true
    },
    {   
        id: 'course_duration_label',
        label: 'Duration Label',
        required: false,
        default: '',
        type: 'system_data',
        skip: ['update'],
        dependency: { schema: 'program', id: 'course_duration_label' }
    },
    {   
        id: 'course_type',
        label: 'Delivery Type',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        disabled: ['update'],
        skip: ['update'],
        dependency: { schema: 'program', id: 'course_type' },
        sync: true
    },
    {   
        id: 'course_type_label',
        label: 'Delivery Type Label',
        required: false,
        default: '',
        type: 'system_data',
        skip: ['update'],
        dependency: { schema: 'program', id: 'course_type_label' }
    },
    {   
        id: 'price_per_pax_id',
        label: 'Price Per Pax',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        disabled: ['add','update'],
        skip: ['update'],
        dependency: { schema: 'program', id: 'price_per_pax_id' },
        sync: true
    },
    {   
        id: 'price_per_pax',
        label: 'Price Per Pax (Public-facing)',
        required: false,
        default: '',
        type: 'string',
        field: 'tinymce_editor',
        disabled: ['add','update'],
        skip: ['update'],
        dependency: { schema: 'program', id: 'price_per_pax' }
    },

    {   
        id: 'course_id',
        label: 'Course',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: ['update'],
        disabled: ['update'],
        sync: true
    },
    {   
        id: 'course_name',
        label: 'Course Name',
        required: false,
        default: '',
        type: 'system_data',
        skip: ['update'],
        dependency: { schema: 'course', id: 'name' },
        sync: true
    },
    {   
        id: 'feedback_form_id',
        label: 'Feedback Form',
        required: false,
        default: '',
        type: 'string',
        skip: ['update'],
        dependency: { schema: 'course', id: 'feedback_form_id' }
    },
    {   
        id: 'training_location_id',
        label: 'Venue',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        // disabled: ['update'],
        sync: true
    },
    {   
        id: 'training_location_label',
        label: 'Venue Name',
        required: false,
        default: '',
        type: 'system_data',
        skip: false,
        dependency: { schema: 'training_location', id: 'name' },
        sync: true
    },
    
    {   
        id: 'principle',
        label: 'Principal',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'coordinator',
        label: 'Coordinator',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'date_of_course',
        label: 'Date of Course',
        required: false,
        default: '',
        type: 'string',
    }, 
    {   
        id: 'start_date',
        label: 'Start Date',
        required: ['add','update'],
        default: 0,
        type: 'timestamp',
        field: 'date_time',
        sync: true
    }, 
    {   
        id: 'end_date',
        label: 'End Date',
        required: ['add','update'],
        default: 0,
        type: 'timestamp',
        field: 'date_time',
        sync: true
    }, 
    {   
        id: 'registration_deadline',
        label: 'Registration opens until',
        required: ['add','update'],
        default: 0,
        type: 'timestamp',
        field: 'datepicker',
        sync: true
    }, 
    {   
        id: 'rolling_class',
        label: 'On Rolling Basis?',
        required: false,
        default: 'no',
        type: 'string',
        field: 'select',
        sync: true
    },
    {   
        id: 'repeat_weekly',
        label: 'Repeat Weekly?',
        required: false,
        default: 'no',
        type: 'string',
        field: 'select',
        skip: false,
    },
    {   
        id: 'repeat_weekly_on',
        label: 'Repeat Weekly On',
        required: false,
        default: 'sat',
        type: 'string',
        field: 'select',
        skip: false,
    },
    {   
        id: 'course_access',
        label: 'Course Access',
        required: false,
        default: [],
        type: 'array_ids',
        skip: false,
        dependency: { schema: 'program', id: 'products' },
        sync: true
    },
    {   
        id: 'sessions',
        label: 'Sessions',
        required: false,
        default: [],
        type: 'array_obj',
        skip: ['update']
    },
    {   
        id: 'class_calendar',
        label: 'Class Calendar',
        required: false,
        default: [],
        type: 'array_obj',
        skip: ['add','update']
    },
    {   
        id: 'publish',
        label: 'Publish to Website',
        required: ['add','update'],
        default: 'no',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'show_price_per_pax',
        label: 'Show Price Per Pax',
        required: ['add','update'],
        default: 'yes',
        type: 'string',
        field: 'select',
        skip: false,
    },
    {   
        id: 'mentors',
        label: 'Mentors',
        required: false,
        default: [],
        type: 'array_ids',
        skip: false,
        sync: true
    },
    // {   
    //     id: 'max_class_size',
    //     label: 'Max Class Size',
    //     required: ['add','update'],
    //     default: 30,
    //     type: 'number',
    //     field: 'text_number',
    // },
    {   
        id: 'total_registered',
        label: 'Total Registered',
        required: false,
        default: 0,
        type: 'number',
        skip: ['update'],
    },
    {   
        id: 'registration_form',
        label: 'Registration Form Template',
        required: false,
        default: 'default',
        type: 'string',
        field: 'select',
        disabled: ['add','update'],
        skip: ['update'],
        dependency: { schema: 'program', id: 'registration_form' },
        sync: true
    },
    {   
        id: 'team_module',
        label: 'Enable Competition Module',
        required: false,
        default: 'no',
        type: 'string',
        field: 'radio',
        disabled: ['add','update'],
        skip: ['update'],
        dependency: { schema: 'program', id: 'team_module' },
        sync: true
    },
    {   
        id: 'team_size',
        label: 'Total Team Size',
        required: false,
        default: '4',
        type: 'string',
        field: 'select',
        disabled: ['add','update'],
        skip: ['update'],
        dependency: { schema: 'program', id: 'team_size' },
        sync: true
    },
    {   
        id: 'project_submissions',
        label: 'Total Project Submissions',
        required: false,
        default: '3',
        type: 'string',
        field: 'select',
        disabled: ['add','update'],
        skip: ['update'],
        dependency: { schema: 'program', id: 'project_submissions' },
        sync: true
    },
    {   
        id: 'api_bearer_token',
        label: 'API Token',
        required: false,
        default: '',
        type: 'string',
        field: 'text'
    },

    {   
        id: 'created_on',
        label: 'Created On',
        required: false,
        default: 0,
        type: 'system_date',
        skip: ['update']
    },
    {   
        id: 'modified_on',
        label: 'Modified On',
        required: false,
        default: 0,
        type: 'system_date'
    },
    {   
        id: 'last_modified_by',
        label: 'Modified By',
        required: false,
        default: '',
        type: 'system_modified_by'
    }
];