/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import styled from "styled-components";
import Alert from "react-s-alert";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import NativeSelect from "@material-ui/core/NativeSelect";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _sortBy from "lodash/sortBy";
import _filter from "lodash/filter";
import _toString from "lodash/toString";

import ScheduleProgram from "./program";
import ClassSessions from "./sessions";
import ClassCalendar from "./calendar";

// import CSVUploadTeachersMentors from '../../modules/CSVUploadTeachersMentors';
import TeamProjectSubmissionDeadline from "../../modules/TeamProjectSubmissionDeadline";

import ModalView from "../../components/ModalView";
import FormInput from "../../components/FormInput";
import FormInputCopy from "../../components/FormInputCopy";
import FormSelect from "../../components/FormSelect";
import FormMultiSelect from "../../components/FormMultiSelect";
import FormDatePicker from "../../components/FormDatePicker";
import FormTimePicker from "../../components/FormTimePicker";
import FormCheckboxes from "../../components/FormCheckboxes";
import FormEditor from "../../components/FormEditor";
import ToolTipInfo from "../../components/ToolTipInfo";

import { AInfoLink, InfoButton } from "../../styles/button";
import { FormBox } from "../../styles/form";
import { InfoBox } from "../../styles/message";

import { isAdmin, hasAccessRights } from "../../helpers/auth";
import { isArrayExists } from "../../helpers/validation";
import { cloneCollections, getSelectOptions, getSelectValues, getSelectedValue } from "../../helpers/data";
import { getMomentTime } from "../../helpers/date";
import { isSchemaRequired } from "../../helpers/schemas";
import { uploadMediaToStorage } from "../../helpers/firebase";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";

import { scheduleSchema } from "../../schemas/schedule";

import { toggleLoader } from "../../actions/global";

import { YES_NO_OPTIONS, DAY_OPTIONS, PLATFORM_COURSE_REGISTRATION_LINK, PLATFORM_DATA_API_URL, DEV_MODE } from "../../constants";

const useStyles = (theme) => ({
    boxheading: {
        fontSize: "20px",
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd",
    },
    nativeselect: {
        marginLeft: "10px",
    },
});

class ScheduleForm extends React.Component {
    state = {
        randNum: false,
    };

    handleTokenGeneration = () => {
        // generate random 48 character aphanumeric token
        const newToken = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        this.handleFormUpdate(newToken, "api_bearer_token");
    };

    handleFormUpdate = (newValue, key) => {
        const { onFormUpdate, schedule, users, regions, locations, courses } = this.props;
        let newData = schedule && !_isEmpty(schedule) ? cloneCollections(schedule) : {};

        switch (key) {
            case "training_location_id":
                newData[key] = newValue;
                newData["training_location_label"] = getSelectedValue(locations, newValue, "id", "name", "");
                break;
            case "course_id":
                newData[key] = newValue;
                newData["course_name"] = getSelectedValue(courses, newValue, "id", "name", "");
                break;
            case "region":
                newData[key] = newValue;
                // reset coordinator & principle
                newData["principle"] = "";
                newData["coordinator"] = "";
                break;
            case "start_date":
                newData[key] = moment(newValue)
                    .utcOffset(8)
                    .set({
                        hour: moment(schedule[key]).utcOffset(8).get("hour"),
                        minute: moment(schedule[key]).utcOffset(8).get("minute"),
                        second: 0,
                        millisecond: 0,
                    })
                    .valueOf();
                newData["registration_deadline"] = moment(newValue).utcOffset(8).add(2, "weeks").endOf("date").valueOf();
                break;
            case "end_date":
                newData[key] = moment(newValue)
                    .utcOffset(8)
                    .set({
                        hour: moment(schedule[key]).utcOffset(8).get("hour"),
                        minute: moment(schedule[key]).utcOffset(8).get("minute"),
                        second: 0,
                        millisecond: 0,
                    })
                    .valueOf();
                break;
            case "start_time":
                newData["start_date"] = moment(newValue)
                    .utcOffset(8)
                    .set({
                        year: moment(schedule["start_date"]).utcOffset(8).get("year"),
                        month: moment(schedule["start_date"]).utcOffset(8).get("month"),
                        date: moment(schedule["start_date"]).utcOffset(8).get("date"),
                        second: 0,
                        millisecond: 0,
                    })
                    .valueOf();
                // update all the class calendar's start time
                if (schedule.class_calendar && isArrayExists(schedule.class_calendar)) {
                    newData["class_calendar"] = this.updateClassCalendar(schedule, newValue, key);
                }
                break;
            case "end_time":
                newData["end_date"] = moment(newValue)
                    .utcOffset(8)
                    .set({
                        year: moment(schedule["end_date"]).utcOffset(8).get("year"),
                        month: moment(schedule["end_date"]).utcOffset(8).get("month"),
                        date: moment(schedule["end_date"]).utcOffset(8).get("date"),
                        second: 0,
                        millisecond: 0,
                    })
                    .valueOf();
                // update all the class calendar's end time
                if (schedule.class_calendar && isArrayExists(schedule.class_calendar)) {
                    newData["class_calendar"] = this.updateClassCalendar(schedule, newValue, key);
                }
                break;
            case "registration_deadline":
                newData[key] = moment(newValue).utcOffset(8).endOf("date").valueOf();
                break;
            case "rolling_class":
                newData[key] = newValue;
                // set end date & registration deadline to infinity
                if (newValue && newValue === "yes") {
                    newData["end_date"] = moment(schedule["end_date"])
                        .utcOffset(8)
                        .set({
                            year: 2099,
                        })
                        .valueOf();
                    // newData['registration_deadline'] = moment(schedule['end_date']).utcOffset(8).set({
                    //     year: 2099
                    // }).endOf('date').valueOf();
                } // end - newValue
                break;
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if (onFormUpdate) onFormUpdate(newData);
    };

    updateClassCalendar = (schedule, newValue, key) => {
        let classCalendar = cloneCollections(schedule.class_calendar);
        _forEach(schedule.class_calendar, (event, index) => {
            if (event.type && event.type === "class") {
                switch (key) {
                    case "start_time":
                        classCalendar[index].start_date = moment(event.start_date)
                            .utcOffset(8)
                            .set({
                                hour: moment(newValue).utcOffset(8).get("hour"),
                                minute: moment(newValue).utcOffset(8).get("minute"),
                                second: 0,
                                millisecond: 0,
                            })
                            .valueOf();
                        break;
                    case "end_time":
                        classCalendar[index].end_date = moment(event.end_date)
                            .utcOffset(8)
                            .set({
                                hour: moment(newValue).utcOffset(8).get("hour"),
                                minute: moment(newValue).utcOffset(8).get("minute"),
                                second: 0,
                                millisecond: 0,
                            })
                            .valueOf();
                        break;
                }
            } // end - event.type
        });
        return classCalendar;
    };

    getCourseAccessOptions = () => {
        const { lms_courses, courses, schedule, currentSchedule } = this.props;
        let options = [];
        if (courses && isArrayExists(courses)) {
            _forEach(courses, (course) => {
                let lms_course =
                    lms_courses && isArrayExists(lms_courses) && course.lms_course_id && !_isEmpty(course.lms_course_id)
                        ? _find(lms_courses, (c) => _toString(c.id) === _toString(course.lms_course_id))
                        : false;
                if (lms_course) {
                    let option = {
                        value: course.id,
                        label:
                            (course && course.course_code && !_isEmpty(course.course_code) ? "[" + course.course_code + "] " : "") +
                            (lms_course.name || "") +
                            (lms_course.id && DEV_MODE ? " (" + course.id + "/" + lms_course.id + ")" : ""),
                        disabled:
                            schedule &&
                            schedule.usage_enrollments &&
                            isArrayExists(schedule.usage_enrollments) &&
                            _find(
                                schedule.usage_enrollments,
                                (e) => e.course_id && _toString(e.course_id) === _toString(lms_course.id) && e.status && e.status === "active"
                            ) &&
                            currentSchedule.course_access &&
                            isArrayExists(currentSchedule.course_access) &&
                            _find(currentSchedule.course_access, (i) => i.lms_course_id && _toString(i.lms_course_id) === _toString(lms_course.id))
                                ? true
                                : false, // disable option if contains active enrollment
                    };
                    // add option keys
                    option.o_lms_course_id = lms_course.id ? _toString(lms_course.id) : "";
                    options.push(option);
                } // end - lms_course
            });
        } // end - courses
        return isArrayExists(options) ? _sortBy(options, [(o) => o.label.toLowerCase()]) : [];
    };

    isProgramSchedule = () => {
        const { schedule } = this.props;
        return schedule && schedule.is_program_schedule && schedule.is_program_schedule === "yes" ? true : false;
    };

    getFieldOptions = (field) => {
        const { schedule, users, regions, locations, courses } = this.props;

        switch (field.name) {
            case "principle":
            case "coordinator":
                return getSelectOptions({
                    list: users && schedule.region ? _filter(users, (u) => u.belongs_to && u.belongs_to.includes(schedule.region)) : false,
                    options: [],
                    keys: { value: "email", label: "name", disabled: "status" },
                    sortList: "name",
                });
            case "region":
                return getSelectOptions({ list: regions || false, options: [], keys: { value: "id", label: "label" }, sortList: "label" });
            case "training_location_id":
                return getSelectOptions({
                    list: locations || false,
                    options: [],
                    keys: { value: "id", label: "name", disabled: "status" },
                    sortList: "name",
                });
            case "course_id":
                return getSelectOptions({ list: courses || false, options: [], keys: { value: "id", label: "name" }, sortList: "name" });
            case "publish":
            case "show_price_per_pax":
                return YES_NO_OPTIONS;
            case "repeat_weekly":
                return DAY_OPTIONS;
            case "team_size":
                return [
                    { value: "1", label: "1 Student" },
                    { value: "2", label: "2 Students" },
                    { value: "3", label: "3 Students" },
                    { value: "4", label: "4 Students" },
                    { value: "5", label: "5 Students" },
                    { value: "6", label: "6 Students" },
                    { value: "7", label: "7 Students" },
                    { value: "8", label: "8 Students" },
                    { value: "9", label: "9 Students" },
                    { value: "10", label: "10 Students" },
                ];
            case "project_submissions":
                return [
                    { value: "1", label: "1 Project" },
                    { value: "2", label: "2 Projects" },
                    { value: "3", label: "3 Projects" },
                    { value: "4", label: "4 Projects" },
                    { value: "5", label: "5 Projects" },
                    { value: "6", label: "6 Projects" },
                    { value: "7", label: "7 Projects" },
                    { value: "8", label: "8 Projects" },
                    { value: "9", label: "9 Projects" },
                    { value: "10", label: "10 Projects" },
                ];
            default:
                return [];
        }
    };

    getFieldValue = (field) => {
        const { schedule } = this.props;
        switch (field.name) {
            default:
                return schedule && schedule[field.name] ? schedule[field.name] : field.default || "";
        }
    };

    isFieldDisabled = (schema) => {
        const { authData, formType } = this.props;
        var disabled = false;

        if (schema && schema.disabled && isArrayExists(schema.disabled)) {
            schema.disabled.forEach((condition) => {
                switch (condition) {
                    case "admin":
                        if (!isAdmin(authData)) disabled = true;
                        break;
                    case "add":
                        if (formType && formType === "add") disabled = true;
                        break;
                    case "update":
                        if (formType && formType === "update") disabled = true;
                        break;
                }
            });
        } // end - schema

        return disabled;
    };

    getTooltip = (schema) => {
        switch (schema.id) {
            // case 'lms_course_link':
            //     return <a href="https://community.canvaslms.com/t5/Instructor-Guide/How-do-I-enable-schedule-self-enrollment-with-a-join-code-or/ta-p/830" target="_blank">Refer to this method</a>;
            case "course_id":
                return (
                    <div>
                        This course selected, along with its details & pricing, will be published on Registration Page. Once set, it cannot be
                        modified.
                    </div>
                );
            case "class_name":
                return <div>This will be Section in LMS, please ensure unique name</div>;
            case "publish":
                return (
                    <div>
                        Select "Yes" for Registration link to appear on chumbaka.asia website. Disable this to keep registration for closed community.{" "}
                    </div>
                );
            case "show_price_per_pax":
                return 'Select "No" to hide Price per Pax on Registration page. Use this if you do not want students to be aware of the pricing.';
            case "coordinator":
                return (
                    <>
                        Coordinator and his/her contact will appear on the Registration page.
                        <br />
                        {
                            "Add more coordinators in your organisation. You can do that in Partner Profile -> Edit -> Personnel Database -> Add New Personnel"
                        }
                    </>
                );
            case "registration_deadline":
                return "When past registration deadline, the registration link will become unavailable.";
            default:
                return;
        }
    };

    getField = (id) => {
        const { massEditMode, formType } = this.props;
        let schema = _find(scheduleSchema, { id });
        return schema
            ? {
                  name: schema.id || "",
                  label: (schema.label || "") + (isSchemaRequired(schema, formType) && !massEditMode ? " (Required)" : ""),
                  field_type: schema.field || "",
                  default: massEditMode ? null : schema.default || null,
                  disabled: schema.disabled ? this.isFieldDisabled(schema) : false,
                  tooltip: this.getTooltip(schema),
              }
            : null;
    };

    renderField = (id) => {
        const { authData, schedule } = this.props;
        let field = this.getField(id);
        if (field && field.field_type && !_isEmpty(field.field_type)) {
            switch (field.field_type) {
                case "text":
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case "textarea":
                    return (
                        <FormInput
                            {...field}
                            rows={"notes" === id ? 6 : 3}
                            multiline={true}
                            value={this.getFieldValue(field)}
                            onChange={this.handleFormUpdate}
                        />
                    );
                case "text_number":
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case "email":
                    return <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case "select":
                    return (
                        <FormSelect
                            {...field}
                            value={this.getFieldValue(field)}
                            options={this.getFieldOptions(field)}
                            disableNative={true}
                            onChange={this.handleFormUpdate}
                        />
                    );
                case "multiselect":
                    return (
                        <FormMultiSelect
                            {...field}
                            value={this.getFieldValue(field)}
                            options={this.getFieldOptions(field)}
                            onChange={this.handleFormUpdate}
                        />
                    );
                case "datepicker":
                    return <FormDatePicker {...field} noDefaultVal={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case "tinymce_editor":
                    return (
                        <FormEditor
                            id={id + "-" + (schedule && schedule.id && !_isEmpty(schedule.id) ? schedule.id : "")}
                            {...field}
                            value={this.getFieldValue(field)}
                            height={250}
                        />
                    );
            }
        } // end - field.field_type
    };

    renderDisabledInput = (label, value) => {
        return <FormInputCopy label={label} value={value} />;
    };

    renderDateTimeField = (id) => {
        const { authData, schedule } = this.props;
        let field = this.getField(id),
            value = this.getFieldValue(field);
        // disabled end date if is on rolling basis
        if ("end_date" === id && schedule && schedule.rolling_class && schedule.rolling_class === "yes") {
            field.disabled = true;
        } // end - id
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormDatePicker {...field} noDefaultVal={true} value={value} onChange={this.handleFormUpdate} />
                    {"end_date" === id ? this.renderRollingBasis() : null}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormTimePicker
                        noDefaultVal={true}
                        label={id === "start_date" ? "Start Time (Required)" : "End Time (Required)"}
                        name={id === "start_date" ? "start_time" : "end_time"}
                        value={value}
                        onChange={this.handleFormUpdate}
                    />
                </Grid>
            </Grid>
        );
    };

    renderRollingBasis = () => {
        const { schedule } = this.props;
        return (
            <div style={{ padding: "0px 10px" }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={schedule && schedule.rolling_class && schedule.rolling_class == "yes" ? true : false}
                            onChange={(event) => {
                                if (event.target.checked) {
                                    this.handleFormUpdate("yes", "rolling_class");
                                } else {
                                    this.handleFormUpdate("no", "rolling_class");
                                }
                            }}
                            value={schedule && schedule.rolling_class && schedule.rolling_class == "yes" ? "yes" : "no"}
                            color="primary"
                        />
                    }
                    label={"This class is on a rolling basis"}
                />
            </div>
        );
    };

    renderRepeatField = () => {
        const { schedule, classes } = this.props;
        return (
            <div style={{ padding: "0 10px" }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={schedule && schedule.repeat_weekly && schedule.repeat_weekly == "yes" ? true : false}
                            onChange={(event) => {
                                if (event.target.checked) {
                                    this.handleFormUpdate("yes", "repeat_weekly");
                                } else {
                                    this.handleFormUpdate("no", "repeat_weekly");
                                }
                            }}
                            value={schedule && schedule.repeat_weekly && schedule.repeat_weekly == "yes" ? "yes" : "no"}
                            color="primary"
                        />
                    }
                    label={
                        <>
                            Repeat Weekly On
                            <NativeSelect
                                disabled={schedule && schedule.repeat_weekly && schedule.repeat_weekly == "yes" ? false : true}
                                value={schedule && schedule.repeat_weekly_on ? schedule.repeat_weekly_on : ""}
                                className={classes.nativeselect}
                                onChange={(event) => {
                                    this.handleFormUpdate(event.target.value, "repeat_weekly_on");
                                }}
                            >
                                {DAY_OPTIONS.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label || ""}
                                    </option>
                                ))}
                            </NativeSelect>
                        </>
                    }
                />
            </div>
        );
    };

    renderCourseAccess = () => {
        const { schedule, classes, lms_courses, courses } = this.props;
        return (
            <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff", marginTop: "60px" }}>
                <FormBox>
                    <Typography variant="h4" className={classes.boxheading}>
                        <ToolTipInfo
                            content={
                                <>
                                    Courses enabled here will carry forward to{" "}
                                    <a
                                        href="#"
                                        onClick={(event) => {
                                            const { currentSchedule, history } = this.props;
                                            event.preventDefault();
                                            history.push("/course_access", { schedule_id: (schedule && schedule.id) || "" });
                                        }}
                                    >
                                        Course Access
                                    </a>{" "}
                                    module These are the courses you will run in the class for the students.
                                </>
                            }
                        />
                        Course Access
                    </Typography>
                    <FormCheckboxes
                        label=""
                        value={schedule.course_access || false}
                        options={this.getCourseAccessOptions()}
                        keys={["lms_course_id"]}
                        name="course_access"
                        xs={3}
                        sm={3}
                        showAllCheck={true}
                        onChange={this.handleFormUpdate}
                    />
                </FormBox>
            </Paper>
        );
    };

    renderCreatedModifiedDate = (item) => {
        let created_on = item.created_on && item.created_on._seconds ? item.created_on._seconds * 1000 : item.created_on || null,
            modified_on = item.modified_on && item.modified_on._seconds ? item.modified_on._seconds * 1000 : item.modified_on || null;
        return (
            <div style={{ paddingTop: "15px", textAlign: "right", color: "#999", fontSize: "1.25rem" }}>
                <div>{created_on ? "Created on " + getMomentTime(created_on, "YYYY-MM-DD hh:mm:ssa") : ""}</div>
                <div>{modified_on ? "Last Modified on " + getMomentTime(modified_on, "YYYY-MM-DD hh:mm:ssa") : ""}</div>
            </div>
        );
    };

    render = () => {
        const {
            classes,
            authData,
            schedule,
            disableSystemDate,
            massEditMode,
            formType,
            users,
            course_type,
            course_duration,
            regions,
            locations,
            courses,
            calendar_events,
            lms_courses,
            price_per_pax,
            programs,
            contact_hour,
            product_level,
            product_credit,
            onFormUpdate,
        } = this.props;
        return (
            <>
                {this.isProgramSchedule() ? (
                    <ScheduleProgram
                        authData={authData}
                        formType={formType}
                        schedule={schedule}
                        regions={regions}
                        programs={programs}
                        price_per_pax={price_per_pax}
                        contact_hour={contact_hour}
                        product_level={product_level}
                        product_credit={product_credit}
                        course_duration={course_duration}
                        course_type={course_type}
                        courses={courses}
                        lms_courses={lms_courses}
                        onFormUpdate={onFormUpdate}
                    />
                ) : null}

                {(formType && formType === "update") ||
                (formType && formType === "add" && schedule && schedule.program_id && !_isEmpty(schedule.program_id)) ? (
                    <>
                        <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff", marginTop: "60px" }}>
                            <FormBox>
                                <Typography variant="h4" className={classes.boxheading}>
                                    Schedule Details
                                </Typography>
                                <Grid container spacing={3}>
                                    {!this.isProgramSchedule() ? (
                                        <Grid item xs={12}>
                                            {this.renderField("region")}
                                        </Grid>
                                    ) : null}
                                    {formType && formType === "update" ? (
                                        <Grid item xs={12}>
                                            {this.renderField("class_name")}
                                        </Grid>
                                    ) : null}
                                    {/* { formType && formType === 'update' ? <Grid item xs={12}>{this.renderDisabledInput("Class ID",schedule.id)}</Grid> : null } */}

                                    <Grid item xs={12} sm={6}>
                                        {this.renderField("training_location_id")}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {formType && formType === "update" && !this.isProgramSchedule() ? this.renderField("course_id") : " "}
                                    </Grid>

                                    {!this.isProgramSchedule() ? (
                                        <Grid item xs={12}>
                                            {this.renderField("price_per_pax")}
                                        </Grid>
                                    ) : null}

                                    <Grid item xs={12} sm={6}>
                                        {this.renderField("principle")}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {this.renderField("coordinator")}
                                    </Grid>

                                    {/* <Grid item xs={12}>{this.renderRepeatField('repeat_weekly')}</Grid> */}
                                    <Grid item xs={12}>
                                        {this.renderDateTimeField("start_date")}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {this.renderDateTimeField("end_date")}
                                    </Grid>
                                </Grid>
                            </FormBox>
                        </Paper>

                        <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff", marginTop: "60px" }}>
                            <FormBox>
                                <Typography variant="h4" className={classes.boxheading}>
                                    Registration Form Details
                                </Typography>
                                <Grid container spacing={3}>
                                    {formType && formType === "update" ? (
                                        <>
                                            <Grid item xs={10}>
                                                {this.renderDisabledInput("Registration Link", PLATFORM_COURSE_REGISTRATION_LINK + schedule.id)}
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Tooltip
                                                    title={<span style={{ fontSize: "13px", padding: "4px" }}>View Registration Form</span>}
                                                    arrow
                                                    placement="top"
                                                >
                                                    <AInfoLink
                                                        href={PLATFORM_COURSE_REGISTRATION_LINK + schedule.id}
                                                        target="_blank"
                                                        noIconMargin="yes"
                                                        style={{ padding: "10px 15px", marginRight: "5px" }}
                                                        minWidth="0px"
                                                    >
                                                        <i className="fa fa-globe"></i>
                                                    </AInfoLink>
                                                </Tooltip>
                                                <ToolTipInfo
                                                    content={
                                                        <>
                                                            CHECK HOW IT LOOKS LIKE
                                                            <br />
                                                            Understand user experience from our{" "}
                                                            <a href="https://www.chumbaka.asia/our-program" target="_blank">
                                                                webpage
                                                            </a>{" "}
                                                            See how it is presented to potential parents and students
                                                        </>
                                                    }
                                                />
                                            </Grid>
                                        </>
                                    ) : null}
                                    <Grid item xs={12} sm={4}>
                                        {this.renderField("publish")}
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        {this.renderField("show_price_per_pax")}
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        {this.renderField("registration_deadline")}
                                    </Grid>
                                    {/* <Grid item xs={6}>{this.renderField('max_class_size')}</Grid> */}
                                </Grid>
                            </FormBox>
                        </Paper>
                    </>
                ) : null}

                {formType && formType === "update" && !this.isProgramSchedule() ? this.renderCourseAccess() : null}

                {formType && formType === "update" && schedule.team_module && schedule.team_module === "yes" ? (
                    <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff", marginTop: "60px" }}>
                        <FormBox>
                            <Typography variant="h4" className={classes.boxheading}>
                                Competition Modules
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    {this.renderField("team_size")}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {this.renderField("project_submissions")}
                                </Grid>
                                {/* <Grid item xs={12}>
                            <TeamProjectSubmissionDeadline
                                schedule={schedule}
                                 />
                        </Grid> */}
                                <Grid item xs={12}>
                                    {this.renderDisabledInput(
                                        "Registration API",
                                        PLATFORM_DATA_API_URL + "?type=registration&class_id=" + schedule.id
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    {this.renderDisabledInput("Teams API", PLATFORM_DATA_API_URL + "?type=teams&class_id=" + schedule.id)}
                                </Grid>
                                {/* <Grid item xs={12}>
                            {this.renderDisabledInput('Teams Projects API',PLATFORM_DATA_API_URL+'?type=teams_projects&class_id='+schedule.id)}
                        </Grid> */}
                                <Grid item xs={12} sm={6}>
                                    {this.renderDisabledInput("API Bearer Token", schedule.api_bearer_token || "")}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InfoButton
                                        minWidth="0px"
                                        style={{ padding: "5px 10px", marginTop: "10px" }}
                                        onClick={this.handleTokenGeneration}
                                    >
                                        {schedule.api_bearer_token && !_isEmpty(schedule.api_bearer_token)
                                            ? "Re-generate Token"
                                            : "Generate New Token"}
                                    </InfoButton>
                                </Grid>
                                {/* <Grid item xs={12}>
                            <CSVUploadTeachersMentors
                                schedule={schedule}
                                 />
                        </Grid> */}
                            </Grid>
                        </FormBox>
                    </Paper>
                ) : null}

                {formType && formType === "update" ? (
                    <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff", marginTop: "60px" }}>
                        <FormBox>
                            <Typography variant="h4" className={classes.boxheading}>
                                Class Calendar
                            </Typography>
                            {schedule &&
                            schedule.sessions &&
                            isArrayExists(schedule.sessions) &&
                            !(schedule.class_calendar && isArrayExists(schedule.class_calendar)) ? (
                                <ClassSessions
                                    authData={authData}
                                    schedule={schedule}
                                    users={users || false}
                                    regions={regions || false}
                                    locations={locations || false}
                                    courses={courses || false}
                                    onUpdate={this.handleFormUpdate}
                                />
                            ) : (
                                <ClassCalendar
                                    authData={authData}
                                    schedule={schedule}
                                    users={users || false}
                                    regions={regions || false}
                                    locations={locations || false}
                                    courses={courses || false}
                                    calendar_events={calendar_events || false}
                                    onUpdate={this.handleFormUpdate}
                                />
                            )}
                        </FormBox>
                    </Paper>
                ) : null}

                {disableSystemDate ? null : this.renderCreatedModifiedDate(schedule)}
            </>
        );
    };
}

export default compose(connect(), withStyles(useStyles), withRouter)(ScheduleForm);
