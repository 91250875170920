/* eslint-disable */
import _random from 'lodash/random';

import { 
    GET_TRAINEES,
    ADD_TRAINEE,
    EDIT_TRAINEE,
    DELETE_TRAINEE,
    RESET_TRAINEES,
} from '../actions/types';

const initialState = {
    trainees: null,
    updated: false,
    rand: false
};

export const trainees = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_TRAINEES:
            return {
                ...state,
                trainees: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case ADD_TRAINEE:
        case EDIT_TRAINEE:
        case DELETE_TRAINEE:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_TRAINEES:
            return {
                ...state,
                trainees: null,
                rand: false
            };
        default:
            return state;
    }
}