/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_PAYMENT_FORM,
	CREATE_PAYMENT_FORM,
	PAYMENT_FORM_ERROR,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getPaymentForm, fb_createPaymentForm } from './fb';


export const getPaymentForm = (trainee_id) => {
	return dispatch => {

  		fb_getPaymentForm(trainee_id)
		.then(data => {
			dispatch({
				type: GET_PAYMENT_FORM,
				payload: { data }
			});	
		})
		.catch(error => {
			dispatch({
				type: PAYMENT_FORM_ERROR,
				payload: getAPIErrorMessage(error)
			});
		})

	}
}