/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormSelect2 from '../../components/FormSelect2';

import { InfoButton } from '../../styles/button';
import { SuccessTag, GreyTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { isAdmin, hasAccessRights, getUserRegionFilter } from '../../helpers/auth';

import { appChangesReset } from '../../actions/misc';

// import { PLATFORM_COURSE_REGISTRATION_LINK } from '../../constants';

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
`;

const OuterWrapper = styled.div`
    max-width: 500px;
`;

class FilterMenu extends React.Component {
    
    state = {
        region: 'all',
        month: moment().utcOffset(8).format('YYYY-MM'),
    };

    componentDidMount() {
        const { authData } = this.props;

        if ( authData && authData.region && authData.region && !_isEmpty( authData.region ) && !isAdmin( authData ) ) 
            this.setState({ region: authData.region })

    }

    handleFilterTrigger = () => {
        const { onFilter } = this.props;
        const { region, month } = this.state;
        let error = false;

        if ( !( month && !_isEmpty( month ) ) )
            error = 'Please select a month';

        if ( !( region && !_isEmpty( region ) ) )
            error = 'Please select a partner';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            onFilter({ 
                region, 
                month,
                start_date: moment(month).utcOffset(8).startOf('month').valueOf(),
                end_date: moment(month).utcOffset(8).endOf('month').valueOf(),
            });
        }
    }

    getOptions = (type) => {
        const { regions } = this.props;
        const { region, course_id } = this.state;
        let options = [];
        switch (type) {
            case 'regions':
                options = getSelectOptions({ 
                    list: ( regions && isArrayExists( regions ) ? _sortBy( regions, ['label'] ) : [] ), 
                    options: [{ value: 'all', label: 'All Partners'}], 
                    keys: { value: 'id', label: 'label' } 
                });
                break; 
            case 'month':
                let months = [];
                let start_month = moment('202106').utcOffset(8).startOf('month'),
                    current_month = moment().utcOffset(8).startOf('month'),
                    diff_month = current_month.diff(start_month, 'month');
                for (let i = 0; i <= diff_month; i++) {
                    let current = moment(start_month).utcOffset(8).add(i,'month');
                    months.push({ value: current.format('YYYY-MM'), label: current.format('YYYY MMM') });
                }
                months = _reverse( months );
                options = [{ value: '', label: 'Select an Option'},...months];
                break; 
        }
        return options;
    }

    renderMenu = () => {
        const { authData, hideRegionSelector } = this.props;
        const { region, month } = this.state;
        return (
        <Grid container spacing={2}>
            { hideRegionSelector ? null : (
            <Grid item xs={12} sm={12}>
                <FormSelect 
                    value={( region || '' )} 
                    label="Partner" 
                    name="region" 
                    options={this.getOptions('regions')} 
                    disabled={( isAdmin( authData ) ? false : true )} 
                    onChange={(newValue) => this.setState({ region: newValue })} />
            </Grid>
            ) }
            <Grid item xs={12} sm={12}> 
                <FormSelect 
                    value={( month || '' )} 
                    label="Month" 
                    name="month" 
                    options={this.getOptions('month')} 
                    onChange={(newValue) => this.setState({ month: newValue })} />
            </Grid>
            <Grid item xs={12}>
                <InfoButton minWidth="128px" style={{ padding: '5px 15px' }} onClick={this.handleFilterTrigger}><i className="fa fa-refresh"></i>Load Data</InfoButton>
            </Grid>
        </Grid>
        )
    }

    render() {
        return (
        <OuterWrapper>

            <Paper elevation={2}>
                <Wrapper>
                    {this.renderMenu()}
                </Wrapper>
            </Paper>

        </OuterWrapper>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(FilterMenu);