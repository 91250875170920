/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _remove from 'lodash/remove';
import _size from 'lodash/size';
import _toString from 'lodash/toString';
import _random from 'lodash/random';

import FormSelect from '../../components/FormSelect';
import DotsLoader from '../../components/DotsLoader';

import { InfoButton, AInfoLink } from '../../styles/button';
import { WrapWord } from '../../styles/misc';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { isSchemaRequired } from '../../helpers/schemas';

import { fb_getCourseAssignments, fb_getCourseModules } from '../../actions/canvas_lms/fb';

import { courseSchema } from '../../schemas/course';

import { CANVAS_LMS_URL } from '../../constants';

const useStyles = theme => ({
    boxheading: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background,
        padding: "15px 5px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

class LMSAssignments extends React.Component {

    state = {
        assignments: [],
        modules: [],
        randNum: false
    };

    componentDidMount() {
        const { course } = this.props;
        const { randNum } = this.state;
        if ( !randNum ) {
            this.getAssignmentsModules(course);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { course } = this.props;
        // if lms_course_id changed
        if ( course && course.lms_course_id && prevProps && prevProps.course && prevProps.course.lms_course_id && course.lms_course_id !== prevProps.course.lms_course_id) {
            this.setState({ randNum: false });
            this.getAssignmentsModules(course);
        } // end - currentCourse
    }

    handleFormUpdate = (newValue,key) => {
        const { onUpdate } = this.props;
        // do update
        if ( onUpdate )
            onUpdate( newValue, key );
    }

    getCourseRubricLink = () => {
        const { course, rubrics } = this.props;
        let selected = ( course && course.course_rubric && !_isEmpty( course.course_rubric ) ? _find( rubrics, { id: course.course_rubric } ) : false );
        return ( selected && selected.value && !_isEmpty( selected.value ) ? selected.value : '' );
    }

    getAssignmentsModules = (course) => {
        if ( course && course.lms_course_id && !_isEmpty( course.lms_course_id ) ) {
            let promises = [];
            promises.push( fb_getCourseAssignments(course.lms_course_id) );
            promises.push( fb_getCourseModules(course.lms_course_id) );
            Promise.all( promises )
            .then(results => {
                this.setState({ assignments: ( ( results && results[0] ) || [] ), modules: ( ( results && results[1] ) || [] ), randNum: _random(1,9999) })
            });
        }
    }

    getAssignmentOptions = () => {
        const { assignments } = this.state;
        let options = [{ value: '', label: 'Select an Option' }];
        if ( assignments && isArrayExists( assignments ) ) {
            _forEach( assignments, assignment => {
                if ( assignment && assignment.id )
                    options.push({ value: _toString( assignment.id ), label: ( assignment.name || '' ) })
            });
        } // end - assignments
        return options;
    }

    getModuleOptions = () => {
        const { modules } = this.state;
        let options = [{ value: '', label: 'Select an Option' }];
        if ( modules && isArrayExists( modules ) ) {
            _forEach( modules, module => {
                if ( module && module.id )
                    options.push({ value: _toString( module.id ), label: ( module.title || '' ) })
            });
        } // end - modules
        return options;
    }

    getFieldOptions = (field) => {
        const { course, feedback_forms, rubrics } = this.props;

        switch( field.name ) {
            case 'feedback_form_id':
                return getSelectOptions({ list: ( feedback_forms || false ), options: [], keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' });
            case 'course_rubric':
                return getSelectOptions({ list: ( rubrics || false ), options: [], keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' });
            case 'lms_assignment_showcase_id':
            case 'lms_assignment_feedback_form_id':
            case 'lms_assignment_project_id':
            case 'lms_assignment_team_profile':
            case 'lms_assignment_team_project_1':
            case 'lms_assignment_team_project_2':
            case 'lms_assignment_team_project_3':
            case 'lms_assignment_team_project_4':
            case 'lms_assignment_team_project_5':
            case 'lms_assignment_team_project_6':
            case 'lms_assignment_team_project_7':
            case 'lms_assignment_team_project_8':
            case 'lms_assignment_team_project_9':
            case 'lms_assignment_team_project_10':
                return this.getAssignmentOptions();
            case 'lms_assignment_mentor_page_id':
            case 'lms_assignment_portfolio_id':
                return this.getModuleOptions();
            default:
                return [];
        }
    }

    getFieldValue = (field) => {
        const { course } = this.props;
        switch( field.name ) {
            default:
                return ( course && course[field.name] ? course[field.name] : ( field.default || '' ) );
        }
    }

    isFieldDisabled = (schema) => {
        const { authData } = this.props;
        var disabled = false;

        if ( schema && schema.disabled && isArrayExists( schema.disabled ) ) {
            schema.disabled.forEach( condition => {
                switch( condition ) {
                    case 'admin':
                        if ( !isAdmin( authData ) )
                            disabled = true;
                        break;
                    case 'update':
                        disabled = true;
                        break;
                }
            });
        } // end - schema

        return disabled
    }

    getField = (id) => {
        const { massEditMode } = this.props;
        let schema = _find( courseSchema, { id } );
        return ( schema ? {
            name: ( schema.id || '' ),
            label: ( schema.label || '' ) + ( isSchemaRequired(schema,'update') && !massEditMode ? ' (Required)' : '' ),
            field_type: ( schema.field || '' ),
            default: ( massEditMode ? null : ( schema.default || null ) ),
            disabled: ( schema.disabled ? this.isFieldDisabled( schema ) : false )
        } : null );
    }

    renderField = (id) => {
        let field = this.getField(id);
        return <FormSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} disableNative={( id === 'feedback_form_id' || id === 'course_rubric' ? true : false )}  onChange={this.handleFormUpdate} />;
    }

    renderOptions = () => {
        const { classes, course } = this.props;
        return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                {this.renderField('lms_assignment_showcase_id')}
            </Grid>
            <Grid item xs={12} sm={6}>
                { course && course.lms_assignment_showcase_id && !_isEmpty( course.lms_assignment_showcase_id ) ? <Tooltip 
                    title={<span style={{ fontSize: '13px', padding: '4px' }}>View Assignment in LMS</span>} 
                    arrow placement="top"><AInfoLink href={( CANVAS_LMS_URL + 'courses/'+course.lms_course_id+"/assignments/"+course.lms_assignment_showcase_id )} target="_blank" noIconMargin="yes" style={{ padding: '10px 15px' }} minWidth="0px"><i className="fa fa-globe"></i></AInfoLink></Tooltip> : null }
            </Grid>
            <Grid item xs={12} sm={6}>
                {this.renderField('lms_assignment_project_id')}
            </Grid>
            <Grid item xs={12} sm={6}>
                { course && course.lms_assignment_project_id && !_isEmpty( course.lms_assignment_project_id ) ? <Tooltip 
                    title={<span style={{ fontSize: '13px', padding: '4px' }}>View Assignment in LMS</span>} 
                    arrow placement="top"><AInfoLink href={( CANVAS_LMS_URL + 'courses/'+course.lms_course_id+"/assignments/"+course.lms_assignment_project_id )} target="_blank" noIconMargin="yes" style={{ padding: '10px 15px' }} minWidth="0px"><i className="fa fa-globe"></i></AInfoLink></Tooltip> : null }
            </Grid>
            <Grid item xs={6}>
                {this.renderField('course_rubric')}
            </Grid>
            <Grid item xs={6}>
                { course && course.course_rubric && !_isEmpty( course.course_rubric ) ? <Tooltip 
                    title={<span style={{ fontSize: '13px', padding: '4px' }}>View Course Rubric</span>} 
                    arrow placement="top"><AInfoLink href={this.getCourseRubricLink()} target="_blank" noIconMargin="yes" style={{ padding: '10px 15px' }} minWidth="0px"><i className="fa fa-globe"></i></AInfoLink></Tooltip> : null }
            </Grid>
            <Grid item xs={6}>
                {this.renderField('feedback_form_id')}
            </Grid>
            <Grid item xs={6}>

            </Grid>
            <Grid item xs={12} sm={6}>
                {this.renderField('lms_assignment_feedback_form_id')}
            </Grid>
            <Grid item xs={12} sm={6}>
                { course && course.lms_assignment_feedback_form_id && !_isEmpty( course.lms_assignment_feedback_form_id ) ? <Tooltip 
                    title={<span style={{ fontSize: '13px', padding: '4px' }}>View Assignment in LMS</span>} 
                    arrow placement="top"><AInfoLink href={( CANVAS_LMS_URL + 'courses/'+course.lms_course_id+"/assignments/"+course.lms_assignment_feedback_form_id )} target="_blank" noIconMargin="yes" style={{ padding: '10px 15px' }} minWidth="0px"><i className="fa fa-globe"></i></AInfoLink></Tooltip> : null }
            </Grid>
            <Grid item xs={12} sm={6}>
                {this.renderField('lms_assignment_portfolio_id')}
            </Grid>
            <Grid item xs={12} sm={6}>
                { course && course.lms_assignment_portfolio_id && !_isEmpty( course.lms_assignment_portfolio_id ) ? <Tooltip 
                    title={<span style={{ fontSize: '13px', padding: '4px' }}>View Module in LMS</span>} 
                    arrow placement="top"><AInfoLink href={( CANVAS_LMS_URL + 'courses/'+course.lms_course_id+"/modules/items/"+course.lms_assignment_portfolio_id )} target="_blank" noIconMargin="yes" style={{ padding: '10px 15px' }} minWidth="0px"><i className="fa fa-globe"></i></AInfoLink></Tooltip> : null }
            </Grid>
            <Grid item xs={12} sm={6}>
                {this.renderField('lms_assignment_mentor_page_id')}
            </Grid>
            <Grid item xs={12} sm={6}>
                { course && course.lms_assignment_mentor_page_id && !_isEmpty( course.lms_assignment_mentor_page_id ) ? <Tooltip 
                    title={<span style={{ fontSize: '13px', padding: '4px' }}>View Module in LMS</span>} 
                    arrow placement="top"><AInfoLink href={( CANVAS_LMS_URL + 'courses/'+course.lms_course_id+"/modules/items/"+course.lms_assignment_mentor_page_id )} target="_blank" noIconMargin="yes" style={{ padding: '10px 15px' }} minWidth="0px"><i className="fa fa-globe"></i></AInfoLink></Tooltip> : null }
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h4" className={classes.boxheading}>Competition Module Assignments</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
                {this.renderField('lms_assignment_team_profile')}
            </Grid>
            <Grid item xs={12} sm={6}>
                { course && course.lms_assignment_team_profile && !_isEmpty( course.lms_assignment_team_profile ) ? <Tooltip 
                    title={<span style={{ fontSize: '13px', padding: '4px' }}>View Assignment in LMS</span>} 
                    arrow placement="top"><AInfoLink href={( CANVAS_LMS_URL + 'courses/'+course.lms_course_id+"/assignments/"+course.lms_assignment_team_profile )} target="_blank" noIconMargin="yes" style={{ padding: '10px 15px' }} minWidth="0px"><i className="fa fa-globe"></i></AInfoLink></Tooltip> : null }
            </Grid>

            <Grid item xs={12} sm={6}>
                {this.renderField('lms_assignment_team_project_1')}
            </Grid>
            <Grid item xs={12} sm={6}>
                { course && course.lms_assignment_team_project_1 && !_isEmpty( course.lms_assignment_team_project_1 ) ? <Tooltip 
                    title={<span style={{ fontSize: '13px', padding: '4px' }}>View Assignment in LMS</span>} 
                    arrow placement="top"><AInfoLink href={( CANVAS_LMS_URL + 'courses/'+course.lms_course_id+"/assignments/"+course.lms_assignment_team_project_1 )} target="_blank" noIconMargin="yes" style={{ padding: '10px 15px' }} minWidth="0px"><i className="fa fa-globe"></i></AInfoLink></Tooltip> : null }
            </Grid>

            <Grid item xs={12} sm={6}>
                {this.renderField('lms_assignment_team_project_2')}
            </Grid>
            <Grid item xs={12} sm={6}>
                { course && course.lms_assignment_team_project_2 && !_isEmpty( course.lms_assignment_team_project_2 ) ? <Tooltip 
                    title={<span style={{ fontSize: '13px', padding: '4px' }}>View Assignment in LMS</span>} 
                    arrow placement="top"><AInfoLink href={( CANVAS_LMS_URL + 'courses/'+course.lms_course_id+"/assignments/"+course.lms_assignment_team_project_2 )} target="_blank" noIconMargin="yes" style={{ padding: '10px 15px' }} minWidth="0px"><i className="fa fa-globe"></i></AInfoLink></Tooltip> : null }
            </Grid>

            <Grid item xs={12} sm={6}>
                {this.renderField('lms_assignment_team_project_3')}
            </Grid>
            <Grid item xs={12} sm={6}>
                { course && course.lms_assignment_team_project_3 && !_isEmpty( course.lms_assignment_team_project_3 ) ? <Tooltip 
                    title={<span style={{ fontSize: '13px', padding: '4px' }}>View Assignment in LMS</span>} 
                    arrow placement="top"><AInfoLink href={( CANVAS_LMS_URL + 'courses/'+course.lms_course_id+"/assignments/"+course.lms_assignment_team_project_3 )} target="_blank" noIconMargin="yes" style={{ padding: '10px 15px' }} minWidth="0px"><i className="fa fa-globe"></i></AInfoLink></Tooltip> : null }
            </Grid>

            <Grid item xs={12} sm={6}>
                {this.renderField('lms_assignment_team_project_4')}
            </Grid>
            <Grid item xs={12} sm={6}>
                { course && course.lms_assignment_team_project_4 && !_isEmpty( course.lms_assignment_team_project_4 ) ? <Tooltip 
                    title={<span style={{ fontSize: '13px', padding: '4px' }}>View Assignment in LMS</span>} 
                    arrow placement="top"><AInfoLink href={( CANVAS_LMS_URL + 'courses/'+course.lms_course_id+"/assignments/"+course.lms_assignment_team_project_4 )} target="_blank" noIconMargin="yes" style={{ padding: '10px 15px' }} minWidth="0px"><i className="fa fa-globe"></i></AInfoLink></Tooltip> : null }
            </Grid>

            <Grid item xs={12} sm={6}>
                {this.renderField('lms_assignment_team_project_5')}
            </Grid>
            <Grid item xs={12} sm={6}>
                { course && course.lms_assignment_team_project_5 && !_isEmpty( course.lms_assignment_team_project_5 ) ? <Tooltip 
                    title={<span style={{ fontSize: '13px', padding: '4px' }}>View Assignment in LMS</span>} 
                    arrow placement="top"><AInfoLink href={( CANVAS_LMS_URL + 'courses/'+course.lms_course_id+"/assignments/"+course.lms_assignment_team_project_5 )} target="_blank" noIconMargin="yes" style={{ padding: '10px 15px' }} minWidth="0px"><i className="fa fa-globe"></i></AInfoLink></Tooltip> : null }
            </Grid>

            <Grid item xs={12} sm={6}>
                {this.renderField('lms_assignment_team_project_6')}
            </Grid>
            <Grid item xs={12} sm={6}>
                { course && course.lms_assignment_team_project_6 && !_isEmpty( course.lms_assignment_team_project_6 ) ? <Tooltip 
                    title={<span style={{ fontSize: '13px', padding: '4px' }}>View Assignment in LMS</span>} 
                    arrow placement="top"><AInfoLink href={( CANVAS_LMS_URL + 'courses/'+course.lms_course_id+"/assignments/"+course.lms_assignment_team_project_6 )} target="_blank" noIconMargin="yes" style={{ padding: '10px 15px' }} minWidth="0px"><i className="fa fa-globe"></i></AInfoLink></Tooltip> : null }
            </Grid>

            <Grid item xs={12} sm={6}>
                {this.renderField('lms_assignment_team_project_7')}
            </Grid>
            <Grid item xs={12} sm={6}>
                { course && course.lms_assignment_team_project_7 && !_isEmpty( course.lms_assignment_team_project_7 ) ? <Tooltip 
                    title={<span style={{ fontSize: '13px', padding: '4px' }}>View Assignment in LMS</span>} 
                    arrow placement="top"><AInfoLink href={( CANVAS_LMS_URL + 'courses/'+course.lms_course_id+"/assignments/"+course.lms_assignment_team_project_7 )} target="_blank" noIconMargin="yes" style={{ padding: '10px 15px' }} minWidth="0px"><i className="fa fa-globe"></i></AInfoLink></Tooltip> : null }
            </Grid>

            <Grid item xs={12} sm={6}>
                {this.renderField('lms_assignment_team_project_8')}
            </Grid>
            <Grid item xs={12} sm={6}>
                { course && course.lms_assignment_team_project_8 && !_isEmpty( course.lms_assignment_team_project_8 ) ? <Tooltip 
                    title={<span style={{ fontSize: '13px', padding: '4px' }}>View Assignment in LMS</span>} 
                    arrow placement="top"><AInfoLink href={( CANVAS_LMS_URL + 'courses/'+course.lms_course_id+"/assignments/"+course.lms_assignment_team_project_8 )} target="_blank" noIconMargin="yes" style={{ padding: '10px 15px' }} minWidth="0px"><i className="fa fa-globe"></i></AInfoLink></Tooltip> : null }
            </Grid>

            <Grid item xs={12} sm={6}>
                {this.renderField('lms_assignment_team_project_9')}
            </Grid>
            <Grid item xs={12} sm={6}>
                { course && course.lms_assignment_team_project_9 && !_isEmpty( course.lms_assignment_team_project_9 ) ? <Tooltip 
                    title={<span style={{ fontSize: '13px', padding: '4px' }}>View Assignment in LMS</span>} 
                    arrow placement="top"><AInfoLink href={( CANVAS_LMS_URL + 'courses/'+course.lms_course_id+"/assignments/"+course.lms_assignment_team_project_9 )} target="_blank" noIconMargin="yes" style={{ padding: '10px 15px' }} minWidth="0px"><i className="fa fa-globe"></i></AInfoLink></Tooltip> : null }
            </Grid>

            <Grid item xs={12} sm={6}>
                {this.renderField('lms_assignment_team_project_10')}
            </Grid>
            <Grid item xs={12} sm={6}>
                { course && course.lms_assignment_team_project_10 && !_isEmpty( course.lms_assignment_team_project_10 ) ? <Tooltip 
                    title={<span style={{ fontSize: '13px', padding: '4px' }}>View Assignment in LMS</span>} 
                    arrow placement="top"><AInfoLink href={( CANVAS_LMS_URL + 'courses/'+course.lms_course_id+"/assignments/"+course.lms_assignment_team_project_10 )} target="_blank" noIconMargin="yes" style={{ padding: '10px 15px' }} minWidth="0px"><i className="fa fa-globe"></i></AInfoLink></Tooltip> : null }
            </Grid>

        </Grid>
        );
    }

    render = () => {
        const { course } = this.props;
        const { randNum } = this.state;
        return randNum && course && course.lms_course_id && !_isEmpty( course.lms_course_id ) ? this.renderOptions() : <DotsLoader style={{ height: '200px' }} />
    }

}

export default compose(
    connect(),
    withStyles(useStyles)
)(LMSAssignments);