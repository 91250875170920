/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _split from 'lodash/split';
import _map from 'lodash/map';
import _trim from 'lodash/trim';
import _size from 'lodash/size';

import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';
import FormCheckboxes from '../../components/FormCheckboxes';

import { FormBox } from '../../styles/form';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists, validate } from '../../helpers/validation';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { getMomentTime, getDateNumFromString, isParentFieldRequired } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';

import { userStudentSchema } from '../../schemas/user_student';
import { COUNTRIES_LIST } from '../../data/const_countries';
import { STATE_OPTIONS } from '../../data/const_states';

import { YES_NO_OPTIONS, TEMPLATE_OPTIONS_AGREE, TEMPLATE_OPTIONS_HIGH, TEMPLATE_OPTIONS_GOOD, TEMPLATE_OPTIONS_TYPE } from '../../constants';

const CheckboxesWrapper = styled.div`
    padding-top: 14px;
    .MuiInputLabel-formControl {
        padding-bottom: 12px;
    }
    .MuiCheckbox-root {
        padding: 2px 9px 0px 12px;
    }
`;

const useStyles = theme => ({
    section_title: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingTop: "15px",
        paddingBottom: "15px",
        marginBottom: "5px",
        borderBottom: "1px solid #ddd"
    }
});

class FeedbackForm extends React.Component {

    state = {
        randNum: false
    };

    handleCheckboxesUpdate = (field) => (newValue, key) => {
        let val = '';
        if ( newValue && isArrayExists( newValue ) ) {
            _forEach( newValue, item => {
                if ( item && item.id && !_isEmpty( item.id ) )
                    val += ( !_isEmpty( val ) ? ' , ' : '' ) +item.id;
            });
        }
        if ( field && field.max_answers && validate( field.max_answers, 'numeric' ) ) {
            if ( newValue && isArrayExists( newValue ) && _size( newValue ) <= parseInt( field.max_answers ) ) {
                this.handleFormUpdate(val, key);
            }
        } else {
            this.handleFormUpdate(val, key);
        }
    }

    handleFormUpdate = ( newValue, key, subkey, subVal ) => {
        const { onFormUpdate, feedback_form } = this.props;
        let newAnswers = ( feedback_form && feedback_form.answers && isArrayExists( feedback_form.answers ) ? cloneCollections( feedback_form.answers ) : [] ),
            index = ( newAnswers && isArrayExists( newAnswers ) ? _findIndex( newAnswers, { id: key } ) : -1 );

        switch( key ) {
            default:
                if ( index >= 0 ) {
                    newAnswers[index].value = newValue;
                } else {
                    newAnswers.push({
                        id: key,
                        value: newValue
                    });
                }
                break;
        }

        // do update
        if ( onFormUpdate )
            onFormUpdate( newAnswers );
    }

    getFieldOptions = (field,addBlankOption) => {
        const { feedback_form } = this.props;
        let splitData = false,
            options = [];

        if ( addBlankOption )
            options.push({ value: '', label: 'Select an option' });

        switch( field.options ) {
            case 'template_agree_options':
                splitData = _split( TEMPLATE_OPTIONS_AGREE.value, '_|_' );
                if ( splitData && isArrayExists( splitData ) ) {
                    _forEach( splitData, item => {
                        if ( item && !_isEmpty( item ) ) {
                            options.push({ value: item, label: item });
                        } // end - item
                    });
                } // end - splitData
                return options;
            case 'template_high_options':
                splitData = _split( TEMPLATE_OPTIONS_HIGH.value, '_|_' );
                if ( splitData && isArrayExists( splitData ) ) {
                    _forEach( splitData, item => {
                        if ( item && !_isEmpty( item ) ) {
                            options.push({ value: item, label: item });
                        } // end - item
                    });
                } // end - splitData
                return options;
            case 'template_good_options':
                splitData = _split( TEMPLATE_OPTIONS_GOOD.value, '_|_' );
                if ( splitData && isArrayExists( splitData ) ) {
                    _forEach( splitData, item => {
                        if ( item && !_isEmpty( item ) ) {
                            options.push({ value: item, label: item });
                        } // end - item
                    });
                } // end - splitData
                return options;
            case 'custom_options':
                splitData = ( field.options_custom && !_isEmpty( field.options_custom ) ? _split( field.options_custom, ',' ) : false );
                if ( splitData && isArrayExists( splitData ) ) {
                    _forEach( splitData, item => {
                        if ( item && !_isEmpty( item ) ) {
                            options.push({ value: _trim( item ), label: _trim( item ) });
                        } // end - item
                    });
                } // end - splitData
                return options;
            default:
                return options;
        }
    }

    getFieldValue = (field) => {
        const { feedback_form } = this.props;
        let selected_field = ( feedback_form && feedback_form.answers && isArrayExists( feedback_form.answers ) ? _find( feedback_form.answers, { id: field.name } ) : false );
        switch( field.field_type ) {
            case 'checkboxes':
                let checkboxes = [];
                if ( selected_field && selected_field.value && !_isEmpty( selected_field.value ) ) {
                    const splitValue = _split( selected_field.value, ' , ' );
                    if ( splitValue && isArrayExists( splitValue ) ) {
                        _forEach( splitValue, item => {
                            if ( item && !_isEmpty( item ) ) {
                                checkboxes.push({ id: item });
                            } // end - item
                        });
                    } // end - splitValue
                }
                return checkboxes;
            default:
                return ( selected_field && selected_field.value && !_isEmpty( selected_field.value ) ? selected_field.value : '' );
        }
    }

    getField = (field) => {
        const { feedback_form } = this.props;
        return {
            name: ( field.id || '' ),
            label: ( field.question || '' ) + ( field.required && field.required === 'yes' ? ' (Required)' : '' ),
            desc: ( field && field.desc || '' ),
            field_type: ( field.type || '' ),
            default: '',
            max_answers: ( field.max_answers || false ),
            options: ( field.options || '' ),
            options_custom: ( field.options_custom || '' ),
            // disabled: ( feedback_form && feedback_form.trainee && feedback_form.trainee.showcase && feedback_form.trainee.showcase.badge_id && !_isEmpty( feedback_form.trainee.showcase.badge_id ) ? true : false )
        };
    }

    renderField = (item) => {
        const { classes } = this.props
        let field = this.getField(item);
        if ( field && field.field_type && !_isEmpty( field.field_type ) ) {
            switch( field.field_type ) {
                case 'input':
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'textarea':
                    return <FormInput {...field} rows={5} multiline={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'select':
                    return <div style={{ paddingTop: "14px" }}><FormSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field,true)} onChange={this.handleFormUpdate} /></div>;
                case 'radio':
                    return <div style={{ paddingTop: "14px" }}><FormRadio {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} /></div>;
                case 'checkboxes':
                    return <CheckboxesWrapper><FormCheckboxes 
                        {...field} 
                        xs={12}
                        sm={12}
                        showAllCheck={false}
                        value={this.getFieldValue(field)} 
                        options={this.getFieldOptions(field)} 
                        onChange={this.handleCheckboxesUpdate(field)} /></CheckboxesWrapper>;
                case 'section_title':
                    return (
                    <Typography variant="h4" className={classes.section_title}>
                        {field.label || ''}
                        { field.desc && !_isEmpty( field.desc ) ? <Typography variant="body1" style={{ padding: '5px 0 0 0' }}>{ field.desc }</Typography> : null }
                    </Typography>
                    );
            }
        } // end - field.field_type
    }

    render = () => {
        const { classes, feedback_form } = this.props;
        return (
        <FormBox>
            <Grid container spacing={3}>
                { feedback_form.fields && isArrayExists( feedback_form.fields ) ? _map( _sortBy( feedback_form.fields, [ f => parseInt( f.position, 10 ) ] ), (field) => {
                    return <Grid item xs={12} key={field.id}>{this.renderField(field)}</Grid>;
                }) : null }
            </Grid>
            <Grid item xs={12}><Typography variant="body1"  style={{ color: '#D32F2F', fontWeight: "700", marginTop: '20px' }}>* Compulsory to fill in</Typography></Grid>
        </FormBox>
        );
    }

}

export default compose(
    withStyles(useStyles),
    withRouter
)(FeedbackForm);