/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import { CopyToClipboard } from "react-copy-to-clipboard";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _slice from "lodash/slice";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _toLower from "lodash/toLower";
import _split from "lodash/split";
import _toString from "lodash/toString";

import ReviewShowcase from "./review_showcase";
import StudentNameToDatabase from "../../modules/StudentNameToDatabase";

import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import TableBar from "../../components/TableBar";
import ModelDelete from "../../components/ModalDelete";
import ModalView from "../../components/ModalView";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormSelect2 from "../../components/FormSelect2";
import FormRadio from "../../components/FormRadio";
import FormCheckbox from "../../components/FormCheckbox";
import ButtonActions from "../../components/ButtonActions";
import StudentEnrollmentStatus from "../../components/StudentEnrollmentStatus";

import BulkPrintCerts from "./bulk_print_certificates";
import MassApproveBadge from "./mass_approve_badge";
import MassIssueBadge from "./mass_issue_badge";
import ImportModal from "./import";

import {
    InfoButton,
    GreyButton,
    ButtonGroup,
    AInfoLink,
    InverseButton,
    ErrorButton,
    WarningButton,
    SuccessButton,
    WhiteButton,
    AWhiteLink,
} from "../../styles/button";
import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from "../../styles/tag";
import { ErrorBox } from "../../styles/message";
import { WrapWord } from "../../styles/misc";

import { isArrayExists, isObjectExists } from "../../helpers/validation";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from "../../helpers/data";
import { getMomentTime, getMomentTimestamp, getAge } from "../../helpers/date";
import { isAdmin, hasAccessRights, getUserRegionFilter } from "../../helpers/auth";
import { getStudentEnrollmentStatus } from "../../helpers/trainees";
import { doSchemaErrorCheck } from "../../helpers/schemas";
import { getShowcaseStatus } from "../../helpers/showcase";

import { editTrainee, deleteTrainee } from "../../actions/trainees";
import { appChangesMade } from "../../actions/misc";

import { traineeSchema } from "../../schemas/trainee";

import { PLATFORM_COURSE_REGISTRATION_LINK, GENDER_OPTIONS, ENROLLMENT_STATUS_OPTIONS, YES_NO_OPTIONS, CANVAS_LMS_URL } from "../../constants";

const SuccessIcon = styled.i`
    color: #64dd17;
`;

const GreyIcon = styled.i`
    color: #64dd17;
`;

const WarningIcon = styled.i`
    color: #ff6f00;
`;

const CourseColumn = styled.div`
    padding: 16px;
    font-size: 11px;
    height: 100%;
    width: 100%;
    max-width: 200px;
    ${(props) => {
        if (props.overdue && props.overdue === "yes") {
            return `
            background: #ffebee;
            `;
        }
        if (props.disabled && props.disabled === "yes") {
            return `
            .disabled-wrapper {
                opacity: 0.4;
            }
            `;
        }
    }};
`;

const RadioWrapper = styled.div`
    cursor: default;
    pointer-events: none;
    .MuiFormControl-fullWidth {
        background: none;
    }
    .MuiFormLabel-root,
    .MuiTypography-body1 {
        font-size: 11px;
    }
`;

const TextareaWrapper = styled.div`
    textarea {
        width: 100%;
        max-width: 150px;
        font-size: 11px;
        padding: 2px;
        overflow: scroll-y;
    }
`;

class StudentsTable extends React.Component {
    utcOffSet = 8;

    state = {
        checked: [],
        allCheck: false,
        searchterms: "",
        filterBy: "all",
        filterByShowcaseDate: "all",
        sortBy: "date-desc",
        perPage: 20,
        page: 1,
        openMassApprove: false,
        openBulkPrintCerts: false,
        openMassIssueBadge: false,
        openImportModal: false,
        randNum: false,
    };

    componentDidMount() {}

    componentDidUpdate(prevProps, prevState, snapshot) {}

    doMassProjectReenable = () => {
        const { checked } = this.state;
        const { trainees, onUpdate } = this.props;
        let newTrainees = trainees && isArrayExists(trainees) ? cloneCollections(trainees) : [],
            error = false;

        _forEach(checked, (item) => {
            const index = newTrainees && isArrayExists(newTrainees) ? _findIndex(newTrainees, { id: item.id }) : -1;
            if (index >= 0) {
                console.log(newTrainees[index]);
            }
        });

        if (error) {
            triggerErrorAlert(error);
        } else {
            // if ( onUpdate ) {
            //     onUpdate(newTrainees);
            //     this.props.dispatch(appChangesMade());
            // }
        }
    };

    handleTraineeUpdate = (newTraineeData, trainee_id) => {
        const { trainees, onUpdate } = this.props;
        let newTrainees = trainees && isArrayExists(trainees) ? cloneCollections(trainees) : [],
            index = newTrainees && isArrayExists(newTrainees) ? _findIndex(newTrainees, { id: trainee_id }) : -1;
        if (index >= 0) {
            newTrainees[index] = newTraineeData;
        }
        if (onUpdate) {
            onUpdate(newTrainees);
            this.props.dispatch(appChangesMade());
        }
    };

    handleFilterByUpdate = (newFilterBy) => {
        const { trainees, courses, lms_courses, courseColumns, onColumnUpdate } = this.props;
        const { filterBy } = this.state;
        let list = trainees && isArrayExists(trainees) ? _filter(trainees, (i) => this.checkTraineeShowcaseStatus(i, newFilterBy)) : [],
            newCourseColumns = [];
        if (list && isArrayExists(list) && newFilterBy && !_isEmpty(newFilterBy) && newFilterBy !== "all") {
            _forEach(list, (trainee) => {
                if (trainee.course_access && isArrayExists(trainee.course_access)) {
                    _forEach(trainee.course_access, (course) => {
                        if (course && course.id) {
                            const showcaseStatus = this.getShowcaseStatus(trainee, course.id);
                            let selected_course = _find(courses, (c) => c.lms_course_id && _toString(c.lms_course_id) === _toString(course.id)),
                                selected_lms_course = _find(lms_courses, (c) => _toString(c.id) === _toString(course.id));
                            if (
                                selected_lms_course &&
                                selected_course &&
                                !_find(newCourseColumns, { id: selected_lms_course.id }) &&
                                ((!showcaseStatus && newFilterBy === "ineligible") ||
                                    showcaseStatus === newFilterBy ||
                                    (newFilterBy === "edit" && showcaseStatus === "action_needed"))
                            ) {
                                newCourseColumns.push({
                                    id: selected_lms_course.id,
                                    name: selected_course.name || "",
                                    course_code: selected_course.course_code || "",
                                });
                            } // end - selected_lms_course
                        } // end - course
                    });
                } // end - trainee.course_access
            });
            onColumnUpdate(newCourseColumns);
        } else if (
            (filterBy && filterBy !== "all" && newFilterBy && !_isEmpty(newFilterBy) && newFilterBy === "all") ||
            (filterBy && filterBy !== "all" && !(newFilterBy && !_isEmpty(newFilterBy)))
        ) {
            // reset back to default columns
            onColumnUpdate([], true);
        } // end - list

        this.setState({ filterBy: newFilterBy, page: 1, checked: [], allCheck: false });
    };

    getActions = () => {
        const { authData, changesMade, history, schedule } = this.props;
        let actions = [];

        actions.push({
            id: "upload",
            label: "Upload badge nomination & feedback",
            icon: "fa-file-excel-o",
            onClick: () => {
                if (!changesMade) {
                    this.setState({ openImportModal: true });
                } else {
                    triggerErrorAlert("Please save changes first");
                } // end - checked
            },
        });

        actions.push({
            id: "massedit",
            label: "Mass Approve Nominated Badges",
            icon: "fa-edit",
            onClick: () => {
                const { checked } = this.state;
                if (checked && isArrayExists(checked)) {
                    this.setState({ openMassApprove: true });
                } else {
                    triggerErrorAlert("Please select at least one student");
                } // end - checked
            },
        });

        actions.push({
            id: "massbadgeissue",
            label: "Force Issue Approved Badges",
            icon: "fa-edit",
            onClick: () => {
                const { checked } = this.state;
                if (checked && isArrayExists(checked)) {
                    if (changesMade) {
                        triggerErrorAlert("Please save changes first");
                    } else {
                        this.setState({ openMassIssueBadge: true });
                    }
                } else {
                    triggerErrorAlert("Please select at least one student");
                } // end - checked
            },
        });

        actions.push({
            id: "bulkprintcerts",
            label: "Bulk Print Certificates",
            icon: "fa-certificate",
            onClick: () => {
                const { checked, filterBy } = this.state;
                if (filterBy !== "issued") {
                    triggerErrorAlert('Please filter the list to "Badge Claimed" status first');
                } else {
                    if (checked && isArrayExists(checked)) {
                        // make sure there wasn't more than 100 students selected
                        if (_size(checked) > 100) {
                            triggerErrorAlert("Please select less than 100 students at a time");
                        } else {
                            this.setState({ openBulkPrintCerts: true });
                        }
                    } else {
                        triggerErrorAlert("Please select at least one student");
                    } // end - checked
                }
            },
        });

        // actions.push({
        //     id: 'massedit', label: 'Mass Re-enable Project Submission', icon: 'fa-edit', onClick: () => {
        //         const { checked } = this.state;
        //         if ( checked && isArrayExists( checked ) ) {
        //             this.doMassProjectReenable();
        //         } else {
        //             triggerErrorAlert('Please select at least one student');
        //         } // end - checked
        //     }
        // });

        actions.push({
            id: "export",
            label: "Export Badge Database",
            icon: "fa-cloud-download",
            onClick: () => {
                if (!changesMade) {
                    history.push(`/student_database`, { schedule_id: schedule.id });
                } else {
                    triggerErrorAlert("Please save changes first");
                } // end - checked
            },
        });

        return actions;
    };

    getCells = () => {
        const { courseColumns } = this.props;
        let cells = [
            // { id: 'region', label: 'Licensee', render: this.renderCell('region') },
            // { id: 'class_name', label: 'Class Name', render: this.renderCell('class_name') },
            // { id: 'created_on', label: 'Registration Date', render: this.renderCell('created_on') },
            { id: "confirmation_email_date", label: "Confirmation Date", render: this.renderCell("confirmation_email_date") },
            // { id: 'booking_date', label: 'Booking Date', render: this.renderCell('booking_date') },
            // { id: 'email', label: 'Email', render: this.renderCell('email') },
            { id: "name", label: "Name", render: (item) => <StudentNameToDatabase {...item} /> },
            // { id: 'showcase_date_booked', label: 'Showcase Date Booked', render: this.renderCell('showcase_date_booked') },
        ];

        if (courseColumns && isArrayExists(courseColumns)) {
            _forEach(
                _sortBy(courseColumns, [(c) => _toLower((c.course_code && !_isEmpty(c.course_code) ? c.course_code + " - " : "") + (c.name || ""))]),
                (course) => {
                    cells.push({
                        id: "course_column_" + course.id,
                        label: (
                            <>
                                <a href={CANVAS_LMS_URL + "courses/" + course.id} target="_blank">
                                    {course.course_code || ""}
                                </a>
                            </>
                        ),
                        // headstyle: ( course.id === today ? { background: '#ffb74d' } : null ),
                        style: { padding: "0px" },
                        render: this.renderCourseColumn(course),
                    });
                }
            );
        } // end - courseColumns

        return cells;
    };

    getCurrentTrainee = (trainee) => {
        const { currentTrainees } = this.props;
        return currentTrainees && isArrayExists(currentTrainees) && trainee && trainee.id ? _find(currentTrainees, { id: trainee.id }) : false;
    };

    getSelectedShowcase = (trainee, course_id) => {
        return trainee && trainee.showcase && isArrayExists(trainee.showcase)
            ? _find(trainee.showcase, (b) => _toString(b.id) === _toString(course_id))
            : false;
    };

    getSelectedTraineeFromShowcase = (trainee) => {
        const { showcase } = this.props;
        return trainee && trainee.id && !_isEmpty(trainee.id) && showcase && showcase.trainees && isArrayExists(showcase.trainees)
            ? _find(showcase.trainees, { id: trainee.id })
            : false;
    };

    getSelectedTraineeCourseFromShowcase = (trainee, course_id) => {
        const { showcase } = this.props;
        let traineeData = this.getSelectedTraineeFromShowcase(trainee);
        return traineeData && traineeData.courses && isArrayExists(traineeData.courses)
            ? _find(traineeData.courses, (c) => _toString(c.id) === _toString(course_id))
            : false;
    };

    getFeedbackformFromTrainee = (trainee, course_id) => {
        let course = this.getSelectedTraineeCourseFromShowcase(trainee, course_id);
        return course && course.feedback_form_answers && isArrayExists(course.feedback_form_answers) ? course.feedback_form_answers : false;
    };

    getShowcaseStatus = (trainee, course_id) => {
        const { currentTrainees, showcase } = this.props;
        const currentTrainee = this.getCurrentTrainee(trainee);
        // const showcase = this.getSelectedShowcase(currentTrainee,course_id);
        const feedbackForm = this.getFeedbackformFromTrainee(trainee, course_id);
        // let status = false;
        // if ( showcase && showcase.badge_id && !_isEmpty( showcase.badge_id ) ) {
        //     status = 'issued';
        // } else if ( feedbackForm ) {
        //     status = 'action_needed';
        // }
        // return status;
        return getShowcaseStatus({ trainee, lms_course_id: course_id, currentTrainees, showcase });
    };

    getShowcaseDateOptions = () => {
        const { schedule } = this.props;
        let options = [{ value: "all", label: "All Open Days Dates" }];
        if (schedule && schedule.class_calendar && isArrayExists(schedule.class_calendar)) {
            _forEach(_sortBy(schedule.class_calendar, ["start_date"]), (event) => {
                if (event.showcase && event.showcase === "yes")
                    options.push({ value: event.id, label: event.start_date ? getMomentTime(event.start_date, "DD MMM YYYY") : "" });
            });
        } // end - schedule.class_calendar
        return options;
    };

    checkTraineeShowcaseStatus = (trainee, status) => {
        let valid = false;
        if (trainee && trainee.course_access && isArrayExists(trainee.course_access)) {
            _forEach(trainee.course_access, (course) => {
                if (course && course.id) {
                    const showcaseStatus = this.getShowcaseStatus(trainee, course.id);
                    const showcaseData = this.getSelectedShowcase(trainee, course.id);
                    switch (status) {
                        case "issued":
                            if (showcaseStatus === status) valid = true;
                            break;
                        case "action_needed":
                            if (
                                showcaseStatus === status &&
                                !(
                                    showcaseData &&
                                    showcaseData.badge_id &&
                                    !_isEmpty(showcaseData.badge_id) &&
                                    showcaseData.badge_type &&
                                    !_isEmpty(showcaseData.badge_type)
                                )
                            )
                                valid = true;
                            break;
                        case "edit":
                            if (
                                showcaseStatus !== "issued" &&
                                showcaseData &&
                                showcaseData.id &&
                                !_isEmpty(showcaseData.id) &&
                                showcaseData.badge_id &&
                                !_isEmpty(showcaseData.badge_id) &&
                                showcaseData.badge_type &&
                                !_isEmpty(showcaseData.badge_type)
                            )
                                valid = true;
                            break;
                        case "ineligible":
                            if (!showcaseStatus || showcaseStatus === status) valid = true;
                            break;
                    }
                    // if ( status === 'ineligible' ) {
                    //     if ( !showcaseStatus )
                    //         valid = true;
                    // } else {
                    //     if ( showcaseStatus === status )
                    //         valid = true;
                    // } // end - status
                } // end - course
            });
        } // end - trainee.course_access
        return valid;
    };

    reorganizeData() {
        const { searchterms, sortBy, perPage, page, filterBy, filterByShowcaseDate } = this.state;
        const { trainees, showcase } = this.props;
        let items = trainees ? cloneCollections(trainees) : [],
            total = _size(items);

        // do search
        if (searchterms && !_isEmpty(searchterms)) {
            items = doArraySearch(items, searchterms, ["name", "email"]);
            total = _size(items);
        } // end - searchterms

        // do filter
        if (filterBy && !_isEmpty(filterBy) && filterBy !== "all") {
            items = _filter(items, (i) => this.checkTraineeShowcaseStatus(i, filterBy));
            total = _size(items);
        } // end - filterBy

        // do showcase dates filter
        if (filterByShowcaseDate && !_isEmpty(filterByShowcaseDate) && filterByShowcaseDate !== "all") {
            items = _filter(items, (i) => {
                let valid = false,
                    selectedTrainee =
                        showcase && showcase.trainees && isArrayExists(showcase.trainees) ? _find(showcase.trainees, { id: i.id }) : false;
                if (selectedTrainee && selectedTrainee.courses && isArrayExists(selectedTrainee.courses)) {
                    _forEach(selectedTrainee.courses, (course) => {
                        if (course.showcase_date_calendar_id && course.showcase_date_calendar_id === filterByShowcaseDate) valid = true;
                    });
                } // end - selectedTrainee.courses
                return valid;
            });
            total = _size(items);
        } // end - filterByShowcaseDate

        // do sort
        if (sortBy && !_isEmpty(sortBy) && !_isEmpty(items)) {
            switch (sortBy) {
                case "date-desc":
                    items = _sortBy(items, ["confirmation_email_date"]);
                    items = _reverse(items);
                    break;
                case "date-asc":
                    items = _sortBy(items, ["confirmation_email_date"]);
                    break;
                case "name-desc":
                    items = _sortBy(items, ["name"]);
                    items = _reverse(items);
                    break;
                case "name-asc":
                    items = _sortBy(items, ["name"]);
                    break;
                // case 'email-desc':
                //     items = _sortBy( items, ['email'] );
                //     items = _reverse( items );
                //     break;
                // case 'email-asc':
                //     items = _sortBy( items, ['email'] );
                //     break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination(items, perPage, page);

        return { items, total };
    }

    renderCourseColumn = (course) => (trainee) => {
        const { badges, showcase, schedule, currentTrainees, courses, rubrics, feedback_forms_fields, teams } = this.props;
        return (
            <ReviewShowcase
                course={course}
                trainee={trainee}
                badges={badges}
                teams={teams}
                showcase={showcase}
                schedule={schedule}
                currentTrainees={currentTrainees}
                courses={courses}
                rubrics={rubrics}
                feedback_forms_fields={feedback_forms_fields}
                onTraineeUpdate={this.handleTraineeUpdate}
            />
        );
    };

    renderCell = (type) => (item) => {
        const { regions, schedules, currentSchedule } = this.props;
        switch (type) {
            case "created_on":
            case "confirmation_email_date":
                return <WrapWord>{item[type] ? getMomentTime(item[type], "DD MMM YYYY") : "---"}</WrapWord>;
            case "email":
                return <WrapWord>{item[type] || "-"}</WrapWord>;
        }
    };

    renderTableActions = () => {
        const { authData } = this.props;
        const { sortBy, perPage, searchterms, checked, filterBy, filterByShowcaseDate } = this.state;
        return (
            <TableBar
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                filterBy={filterBy}
                sortByOptions={[
                    { value: "date-desc", label: "Confirmation Date (Recent First)" },
                    { value: "date-asc", label: "Confirmation Date (Oldest First)" },
                    { value: "name-asc", label: "Name ( A - Z)" },
                    { value: "name-desc", label: "Name ( Z - A )" },
                    // { value: 'email-asc', label: 'Email ( A - Z)' },
                    // { value: 'email-desc', label: 'Email ( Z - A )' },
                    // { value: 'org-asc', label: 'School ( A - Z)' },
                    // { value: 'org-desc', label: 'School ( Z - A )' },
                ]}
                leftButtons={
                    checked && isArrayExists(checked)
                        ? [
                              <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                                  <GreyButton
                                      style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }}
                                      onClick={() => this.setState({ checked: [], allCheck: false })}
                                  >
                                      <i className="fa fa-remove" style={{ marginRight: "10px" }}></i>
                                      {_size(checked) + " selected"}
                                  </GreyButton>
                              </div>,
                          ]
                        : null
                }
                rightButtons={[
                    <ButtonActions
                        key="actions"
                        label="Actions"
                        menuContainerStyle={{ width: "300px" }}
                        style={{ marginRight: "5px" }}
                        actions={this.getActions()}
                    />,
                    <div key="filterbydates" style={{ maxWidth: "300px", width: "100%" }}>
                        <FormSelect2
                            label="Filter By"
                            placeholder={""}
                            options={this.getShowcaseDateOptions()}
                            value={filterByShowcaseDate || false}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(newFilterBy) => this.setState({ filterByShowcaseDate: newFilterBy, page: 1, checked: [], allCheck: false })}
                        />
                    </div>,
                ]}
                filterByOptions={[
                    { value: "all", label: "All Status" },
                    { value: "ineligible", label: "Review" },
                    { value: "action_needed", label: "Review Now" },
                    { value: "edit", label: "Badge Approved" },
                    { value: "issued", label: "Badge Claimed" },
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1, checked: [], allCheck: false })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1, checked: [], allCheck: false })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1, checked: [], allCheck: false })}
                onFilterByChange={this.handleFilterByUpdate}
                style={{ marginBottom: "20px" }}
            />
        );
    };

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return (
            <Pagination
                total={totalCount}
                perPage={perPage}
                page={page}
                style={{ marginTop: "20px" }}
                doneLoaded={true}
                // entriesLabel="(Total Registration For This Class)"
                onPageChange={(newPage) => this.setState({ page: newPage })}
            />
        );
    };

    render() {
        const {
            authData,
            users,
            showcase,
            feedback_forms_fields,
            trainees,
            badges,
            schedule,
            courses,
            lms_courses,
            currentTrainees,
            courseColumns,
            teams,
            onForceIssueBadge,
        } = this.props;
        const { allCheck, checked, openMassApprove, openImportModal, openMassIssueBadge, openBulkPrintCerts } = this.state;
        const { items, total } = this.reorganizeData();
        return (
            <div>
                <h4 style={{ padding: "20px 0 10px 0", fontWeight: "700" }}>INDIVIDUAL(S) PROJECT SUBMISSION:</h4>

                {this.renderTableActions()}
                <div style={{ paddingBottom: "10px" }}>{this.renderPagination(total)}</div>
                <Table
                    items={items}
                    showCheckbox={true}
                    emptyCell="No Student(s) Found."
                    checked={checked || []}
                    allCheck={allCheck}
                    onChecked={(newValue) => this.setState({ checked: newValue })}
                    onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                    cells={this.getCells()}
                    // actionStyles={{ width: "10%" }}
                    // actions={(item) => (
                    //     <ButtonGroup>
                    //         { item.enrollment_status && item.enrollment_status === 'delete' ? null : <InfoButton key="edit" size="small" onClick={() => this.setState({ openShowcaseModal: true, modalData: item })}><i className="fa fa-edit"></i>Edit</InfoButton> }
                    //         {/* { authData && hasAccessRights( authData, ['scd'] ) ? <ErrorButton key="delete" size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton> : null } */}
                    //     </ButtonGroup>
                    // )}
                />
                {this.renderPagination(total)}

                <MassApproveBadge
                    open={openMassApprove}
                    selected={checked}
                    showcase={showcase}
                    badges={badges}
                    teams={teams}
                    schedule={schedule}
                    trainees={trainees}
                    lms_courses={lms_courses}
                    courses={courses}
                    currentTrainees={currentTrainees || false}
                    onClose={() => this.setState({ openMassApprove: false })}
                    onUpdate={(newTrainees) => {
                        const { onUpdate } = this.props;
                        if (onUpdate) {
                            onUpdate(newTrainees);
                            this.props.dispatch(appChangesMade());
                        }
                    }}
                />

                <BulkPrintCerts
                    open={openBulkPrintCerts}
                    selected={checked}
                    showcase={showcase}
                    badges={badges}
                    teams={teams}
                    schedule={schedule}
                    trainees={trainees}
                    lms_courses={lms_courses}
                    courses={courses}
                    courseColumns={courseColumns}
                    currentTrainees={currentTrainees || false}
                    onClose={() => this.setState({ openBulkPrintCerts: false })}
                />

                <MassIssueBadge
                    open={openMassIssueBadge}
                    selected={checked}
                    showcase={showcase}
                    badges={badges}
                    teams={teams}
                    schedule={schedule}
                    trainees={trainees}
                    lms_courses={lms_courses}
                    courses={courses}
                    currentTrainees={currentTrainees || false}
                    onClose={() => this.setState({ openMassIssueBadge: false })}
                    onUpdate={(newTrainees) => {
                        const { onUpdate } = this.props;
                        if (onUpdate) {
                            onUpdate(newTrainees);
                            this.props.dispatch(appChangesMade());
                        }
                    }}
                    onForceIssueBadge={onForceIssueBadge}
                />

                <ImportModal
                    open={openImportModal}
                    showcase={showcase}
                    badges={badges}
                    teams={teams}
                    feedback_forms_fields={feedback_forms_fields}
                    schedule={schedule}
                    trainees={trainees}
                    lms_courses={lms_courses}
                    courses={courses}
                    currentTrainees={currentTrainees || false}
                    onClose={() => this.setState({ openImportModal: false })}
                />
            </div>
        );
    }
}

export default compose(connect(), withRouter)(StudentsTable);
