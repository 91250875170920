/* eslint-disable */
import Alert from 'react-s-alert';

import {
	MISC_CHANGES_NOT_SAVED,
	MISC_CHANGES_RESET,
	RESET_PARTNER,
	RESET_PARTNERS,
    RESET_PERSONNEL,
    RESET_PERSONNELS,
	RESET_COURSE,
	RESET_COURSES,
	RESET_SCHEDULE,
	RESET_SCHEDULES,
	RESET_SCHEDULES_OPTIONS,
	RESET_BADGES,
	RESET_BADGES_ONE_OFF,
	RESET_TRAINING_LOCATIONS,
	RESET_EMAIL_TEMPLATES,
	RESET_EMAIL_TEMPLATE,
	RESET_COURSE_REGISTRATION,
	RESET_PAYMENT_FORM,
	RESET_FEEDBACK_FORM,
	RESET_REGISTRATION_FORM,
	RESET_CERTIFICATE,
	RESET_CLASS,
	RESET_TRAINEES,
	RESET_MENTOR,
	RESET_STUDENT,
	RESET_STUDENT_SHOWCASE,
	RESET_DATABASE_STUDENTS,
	RESET_DATABASE_MARKETING,
	RESET_PROGRAM,
	RESET_PROGRAMS,
	RESET_PROGRAMS_BY_PRODUCT,
	RESET_HQ_SUMMARY
} from './types';

export const appChangesMade = () => {
	return dispatch => {
  		dispatch({ type: MISC_CHANGES_NOT_SAVED });
	}
}

export const appChangesReset = () => {
	return dispatch => {
  		dispatch({ type: MISC_CHANGES_RESET });
	}
}

export const resetRedux = (type) => {
	return dispatch => {
		switch( type ) {
			case 'partner':
				setTimeout(() => {
					dispatch({ type: RESET_PARTNER });
				}, 150);
				break;
			case 'partners':
				setTimeout(() => {
					dispatch({ type: RESET_PARTNERS });
				}, 150);
				break;
			case 'personnel':
				setTimeout(() => {
					dispatch({ type: RESET_PERSONNEL });
				}, 150);
				break;
			case 'personnels':
				setTimeout(() => {
					dispatch({ type: RESET_PERSONNELS });
				}, 150);
				break;
			case 'training_locations':
				setTimeout(() => {
					dispatch({ type: RESET_TRAINING_LOCATIONS });
				}, 150);
				break;
			case 'course':
				setTimeout(() => {
					dispatch({ type: RESET_COURSE });
				}, 150);
				break;
			case 'courses':
				setTimeout(() => {
					dispatch({ type: RESET_COURSES });
				}, 150);
				break;
			case 'badges':
				setTimeout(() => {
					dispatch({ type: RESET_BADGES });
				}, 150);
				break;
			case 'oneoff_badges':
				setTimeout(() => {
					dispatch({ type: RESET_BADGES_ONE_OFF });
				}, 150);
				break;
			case 'program':
				setTimeout(() => {
					dispatch({ type: RESET_PROGRAM });
				},150);
				break;
			case 'programs':
				setTimeout(() => {
					dispatch({ type: RESET_PROGRAMS });
				},150);
				break;
			case 'programs_by_product':
				setTimeout(() => {
					dispatch({ type: RESET_PROGRAMS_BY_PRODUCT });
				},150);
				break;
			case 'schedule':
				setTimeout(() => {
					dispatch({ type: RESET_SCHEDULE });
				}, 150);
				break;
			case 'schedules':
				setTimeout(() => {
					dispatch({ type: RESET_SCHEDULES });
				}, 150);
				break;
			case 'schedules_options': 
				setTimeout(() => {
					dispatch({ type: RESET_SCHEDULES_OPTIONS });
				}, 150);
				break;
			case 'email_template':
				setTimeout(() => {
					dispatch({ type: RESET_EMAIL_TEMPLATE });
				}, 150);
				break;
			case 'email_templates':
				setTimeout(() => {
					dispatch({ type: RESET_EMAIL_TEMPLATES });
				}, 150);
				break;
			case 'feedback_form':
				setTimeout(() => {
					dispatch({ type: RESET_FEEDBACK_FORM });
				}, 150);
				break;
			case 'registration_form':
				setTimeout(() => {
					dispatch({ type: RESET_REGISTRATION_FORM });
				}, 150);
				break;
			case 'certificate':
				setTimeout(() => {
					dispatch({ type: RESET_CERTIFICATE });
				}, 150);
				break;
			case 'course_registration':
				setTimeout(() => {
					dispatch({ type: RESET_COURSE_REGISTRATION });
				},150);
				break;
			case 'payment_form':
				setTimeout(() => {
					dispatch({ type: RESET_PAYMENT_FORM });
				},150);
				break;
			case 'trainees':
				setTimeout(() => {
					dispatch({ type: RESET_TRAINEES });
				},150);
				break;
			case 'classes':
				setTimeout(() => {
					dispatch({ type: RESET_CLASS });
				},150);
				break;
			case 'mentor':
				setTimeout(() => {
					dispatch({ type: RESET_MENTOR });
				},150);
				break;
			case 'student':
				setTimeout(() => {
					dispatch({ type: RESET_STUDENT });
				},150);
				break;
			case 'student_showcase':
				setTimeout(() => {
					dispatch({ type: RESET_STUDENT_SHOWCASE });
				},150);
				break;
			case 'database_students':
				setTimeout(() => {
					dispatch({ type: RESET_DATABASE_STUDENTS });
				},150);
				break;
			case 'database_marketing':
				setTimeout(() => {
					dispatch({ type: RESET_DATABASE_MARKETING });
				},150);
				break;
			case 'hq_summary':
				setTimeout(() => {
					dispatch({ type: RESET_HQ_SUMMARY });
				},150);
				break;
		}
	}
}