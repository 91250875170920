/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _isEqual from 'lodash/isEqual';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader'
import SnackBarSave from '../../components/SnackBarSave';

import EditForm from './form';
import ItemNotFound from '../../components/ItemNotFound';

import { InfoButton, GreyButton } from '../../styles/button';

import { hasAccessRights } from '../../helpers/auth'; 
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { getPartners } from '../../actions/partners';
import { getProductsOptions } from '../../actions/courses';
import { getProgram, editProgram } from '../../actions/programs';
import { getMaintenance } from '../../actions/maintenance';
import { appChangesMade, resetRedux } from '../../actions/misc';

import { programSchema } from '../../schemas/program';

class ProgramPage extends React.Component {

    state = {
        visibility_lock: true,
        currentTab: 'details',
        program: false,
        randNum: false
    };

    componentDidMount() {
        const { authData, contactHourList, productLevelList, productCreditList, courseTypeList, partnersList, courseDurationList, pricePerPaxList, currencyList, productsOptionsList, registrationFormsList } = this.props;
        const { program_id } = this.props.match.params;
        
        // retrieve program details
        this.props.dispatch(getProgram(program_id));

        // get partners
        if ( !partnersList )
            this.props.dispatch( getPartners({ authData }) );

        // get contact hour
        if ( !contactHourList )
            this.props.dispatch(getMaintenance('contact_hour'));

        // get product level
        if ( !productLevelList )
            this.props.dispatch(getMaintenance('product_level'));

        // get product credit
        if ( !productCreditList )
            this.props.dispatch(getMaintenance('product_credit'));

        // get course_type
        if ( !courseTypeList )
            this.props.dispatch(getMaintenance('course_type'));

        // get program duration
        if ( !courseDurationList )
            this.props.dispatch(getMaintenance('course_duration'));

        // get feedback_forms
        if ( !pricePerPaxList )
            this.props.dispatch(getMaintenance('price_per_pax'));

        // get currency
        if ( !currencyList )
            this.props.dispatch(getMaintenance('currency'));

        // get registration forms
        if ( !registrationFormsList )
            this.props.dispatch(getMaintenance('registration_forms'));

        // get products
        if ( !productsOptionsList )
            this.props.dispatch(getProductsOptions());

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentProgram, randNum, 
            partnersList, contactHourList, productLevelList, productCreditList, courseTypeList, courseDurationList, pricePerPaxList, currencyList, productsOptionsList, registrationFormsList
            } = this.props;

        // for program
        if ( currentProgram && contactHourList && productLevelList && productCreditList && partnersList && courseTypeList && courseDurationList && pricePerPaxList && currencyList && productsOptionsList && registrationFormsList && randNum && randNum !== this.state.randNum ) {
            this.setState({ program: cloneCollections( currentProgram ), randNum });
        } // end - currentProgram
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('program'));
    }

    handleRefresh = (type) => {
        // const { program_id } = this.props.match.params;
        // switch( type ) {
        //     case 'emails':
        //         this.setState({ emails: false });
        //         this.props.dispatch(getEmailsBy('courses',program_id));
        //         break;
        // }
    }

    handleSaveChanges = (event) => {
        const { program } = this.state;
        event.preventDefault();
        var error = false,
            formData = {};

        // do error check
        programSchema.forEach(schema => {
            formData[schema.id] = ( program && program[schema.id] ? cloneCollections( program[schema.id] ) : schema.default );
            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editProgram(formData));
            this.setState({ visibility_lock: true });
        } // end - error

    }

    handleFormUpdate = (newValue) => {
        this.setState({ program: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    renderTabContents = () => {
        const { program_id } = this.props.match.params;
        const { authData, currentProgram, productsOptionsList, contactHourList, productLevelList, productCreditList, partnersList, courseTypeList, courseDurationList, pricePerPaxList, currencyList, registrationFormsList, changesMade } = this.props;
        const { currentTab, program, visibility_lock } = this.state;
        const tabProps = {
            authData: ( authData || false ),
            program: ( program || false ),
            changesMade: ( changesMade || false ),
            onFormUpdate: this.handleFormUpdate
        };
        switch( currentTab ) {
            case 'details':
                return <EditForm 
                    formType="update"
                    currentProgram={( currentProgram || false )}
                    products_options={productsOptionsList}
                    contact_hour={contactHourList}
                    product_level={productLevelList}
                    product_credit={productCreditList}
                    partners={( partnersList || false )}
                    course_type={( courseTypeList || false )}
                    course_duration={( courseDurationList || false )}
                    price_per_pax={( pricePerPaxList || false )}
                    currency={( currencyList || false )}
                    registration_forms={( registrationFormsList || false )}
                    visibility_lock={( visibility_lock || false )}
                    onVisibilityUnlock={() => this.setState({ visibility_lock: false })}
                    {...tabProps} />;
        }
    }

    renderContents() {
        return (
        <div>

            {this.renderTabContents()}

            <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
            </div>

            <SnackBarSave onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { program_id } = this.props.match.params;
        const { randNum } = this.state;
        const { currentProgram } = this.props;
        return <AppWrapper 
                title="Edit Program Profile"
                subtitle="Setup"
                back="/programs"
                breadcrumbs={[
                    { url: '/programs', label: 'Program' },
                    { label: 'Edit' }
                ]}
                maxWidth="1110px"
                onLoad={( !( randNum ) ? true : false )}
                contents={ currentProgram && currentProgram.id && !_isEmpty( currentProgram.id ) ? this.renderContents() : <ItemNotFound item="Program" />} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        currentProgram: state.programs && state.programs.program ? state.programs.program : null,
        randNum: state.programs && state.programs.rand ? state.programs.rand : null,
        productsOptionsList: state.courses && state.courses.products_options ? state.courses.products_options : false,
        partnersList: state.partners && state.partners.partners ? state.partners.partners : null,
        courseTypeList: state.maintenance && state.maintenance.course_type ? state.maintenance.course_type : null,
        courseDurationList: state.maintenance && state.maintenance.course_duration ? state.maintenance.course_duration : null,
        currencyList: state.maintenance && state.maintenance.currency ? state.maintenance.currency : null,
        pricePerPaxList: state.maintenance && state.maintenance.price_per_pax ? state.maintenance.price_per_pax : null,
        contactHourList: state.maintenance && state.maintenance.contact_hour ? state.maintenance.contact_hour : null,
        productLevelList: state.maintenance && state.maintenance.product_level ? state.maintenance.product_level : null,
        productCreditList: state.maintenance && state.maintenance.product_credit ? state.maintenance.product_credit : null,
        registrationFormsList: state.maintenance && state.maintenance.registration_forms ? state.maintenance.registration_forms : null,
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ProgramPage);