/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import Dropzone from 'react-dropzone';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';
import _replace from 'lodash/replace';

import { isArrayExists } from '../../helpers/validation';
import { InfoButton, InverseButton } from '../../styles/button';

const useStyles = theme => ({
    formcontrol: {
        background: '#fff',
        
    },
    label: {
        textTransform: 'uppercase',
        fontWeight: '700',
        fontSize: '14px',
        paddingBottom: '5px'
    },
    radio: {
        padding: '5px 9px'
    },
    imageholder: {
        '& > img': {
            display: 'block',
            width: '100%', 
            height: 'auto', 
            maxWidth: '150px'
        }
    },
    fileholder: {
        padding: '10px',
        textAlign: 'center',
        background: '#f1f1f1',
        maxWidth: '150px',
        '& > a': {
            display: 'inline-block',
            fontSize: '90px'
        }
    }
});

class FormUpload extends React.Component {

    handleFileUpload = (files) => {
        const { name, onUpload } = this.props;
        if ( files && isArrayExists( files ) && onUpload )
            onUpload(files,name);
    }

    handleFileReset = (event) => {
        const { name, onReset } = this.props;
        if ( onReset )
            onReset('',name);
    }

    renderFile = () => {
        const { classes, value } = this.props;
        return <div className={classes.fileholder}><a href={value} target="_blank"><i className="fa fa-file"></i></a></div>;
    }

    renderImage = () => {
        const { classes, value } = this.props;
        return <div className={classes.imageholder}><img src={value} /></div>;
    }

    renderLabel = () => {
        const { label } = this.props;
        var newLabel = false;
        if ( label && !_isEmpty( label ) && ( 
            ( _toLower( label ).indexOf("(required)") >= 0 ) || 
            ( _toLower( label ).indexOf("*") >= 0 )
         ) ) {
            newLabel = _replace( label, '(Required)', '' );
            newLabel = _replace( newLabel, '(required)', '' );
            newLabel = _replace( newLabel, '*', '' );
        } // end - label
        return ( newLabel ? <span>{ newLabel || '' }<span className="required">*</span></span> : ( label || '' ) );
    }

    render() {
        const { id, value, options, name, disabled, classes, inline, accept, linkToFile } = this.props;
        var newID = 'upload-field-' + _random(1,9999);
        return (
        <FormControl id={( id || newID )} variant="outlined" fullWidth={true} className={classes.formcontrol} >
            <FormLabel component="legend" className={classes.label}>{this.renderLabel()}</FormLabel>
            { value && !_isEmpty( value ) ? ( linkToFile ? this.renderFile() : this.renderImage() ) : null }
            { value && !_isEmpty( value ) ? (
                <InverseButton disabled={disabled} style={{ padding: "5px 25px", borderRadius: "25px", marginTop: '5px', maxWidth: '175px' }} onClick={this.handleFileReset}><i className="fa fa-trash"></i>Remove</InverseButton>
            ) : (
            <Dropzone style={{ display: 'inline-block', width: 'auto' }} onDrop={this.handleFileUpload} accept={ accept ? accept : "image/*"}>
            {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ style: {
                    display: "inline-block"
                } })}>
                    <input {...getInputProps()} />
                    <InfoButton style={{ padding: "5px 25px", borderRadius: "25px", marginTop: '5px' }} disabled={disabled}><i className="fa fa-cloud-upload"></i>Upload File</InfoButton>
                </div>
            )}
            </Dropzone>
            ) }
        </FormControl>
        )
    }

}

export default compose(
    withStyles(useStyles)
)(FormUpload);