/* eslint-disable */
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _isInteger from 'lodash/isInteger';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';
import _size from 'lodash/size';
import _split from 'lodash/split';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _trim from 'lodash/trim';
import _sortBy from 'lodash/sortBy';
import _snakeCase from 'lodash/snakeCase';
import _toLower from 'lodash/toLower';

import { isArrayExists, isObjectExists } from './validation';
import { replaceAll, getSelectOptions } from './data';

import { STATE_OPTIONS } from '../data/const_states';
import { REGISTRATION_FORM_TYPES, YES_NO_OPTIONS } from '../constants';

// get field type
export const getFieldType = (field) => {
    const fieldType = _find( REGISTRATION_FORM_TYPES, { id: field.type } );
    return ( fieldType || false );
}

// get all custom fields
export const getCustomFields = ( schedule = false, registration_forms = [], returnFormat = 'all' ) => {
    const form = ( schedule && schedule.registration_form && !_isEmpty( schedule.registration_form ) ? _find( registration_forms, { id: schedule.registration_form } ) : false );
    let fields = [];
    if ( form && form.fields && isArrayExists( form.fields ) ) {
        _forEach( form.fields, (field) => {
            const fieldType = getFieldType( field );
            if ( fieldType ) {
                switch( returnFormat ) {
                    case 'non_default':
                        if ( !fieldType.default ) {
                            fields.push(field);
                        }
                        break;
                    case 'all':
                    default:
                        fields.push(field);
                        break;
                }
            } // end - fieldType
        });
    } else {
        if ( returnFormat === 'all' ) {
            // return default field
            let position = 1;
            REGISTRATION_FORM_TYPES.forEach( type => {
                if ( type.default ) {
                    fields.push({
                        id: type.id,
                        type: type.id, 
                        label: type.label || '', 
                        required: type.required || 'no', 
                        options: type.options || '', 
                        options_custom: type.options_custom || '',
                        position
                    });
                    position++;
                } // end - type.default
            });
        } // end - returnFormat
    } // end -form
    return _sortBy( fields, [ f => parseInt( f.position, 10 ) ] );
}

// get custom field
export const getCustomField = (fields,type) => {
    const field = ( fields && isArrayExists( fields ) ? _find( fields, { type: type } ) : false );
    return ( field || false );
}

// get custom field value
export const getCustomFieldValue = (field,trainee) => {
    const selectedField = ( trainee  && trainee.custom_fields && isArrayExists( trainee.custom_fields ) ? _find( trainee.custom_fields, { id: field.id } ) : false );
    return ( selectedField && selectedField.value || '' );
}

// get state value
export const getStateValue = (trainee) => {
    const selected = _find( STATE_OPTIONS, { country: 'Malaysia' } );
    const selectedField = ( trainee  && trainee.custom_fields && isArrayExists( trainee.custom_fields ) ? _find( trainee.custom_fields, f => ( f.type && f.type === 'state' ) || _find( selected.states, s => s === f.value ) ) : false );
    return ( selectedField && selectedField.value || '' );
}

// get custom fields options
export const getCustomFieldOptions = (field = {}, { races, districts, selectedState = null }, addBlankOption = true ) => {
    let options = [];
    if ( addBlankOption ) {
        options.push({ value: '', label: 'Select an Option' });
    }
    if ( field.options && !_isEmpty( field.options ) ) {
        switch( field.options ) {
            case 'template_yes_no_options':
                options = [ ...YES_NO_OPTIONS ];
                break;
            case 'custom_options':
                let splitData = _split( field.options_custom, ',' );
                if ( splitData && isArrayExists( splitData ) ) {
                    if ( splitData.length > 1 ) {
                        _forEach( splitData, (string) => {
                            let splitString = _split( string, '\n' );
                            if ( splitString && isArrayExists( splitString ) && splitString.length > 1 ) {
                                _forEach( splitString, lineString => {
                                    options.push({
                                        value: _trim( lineString ),
                                        label: _trim( lineString )
                                    });
                                });
                            } else {
                                options.push({
                                    value: _trim( splitString[0] ),
                                    label: _trim( splitString[0] )
                                });
                            } // end - splitString
                        });
                    } else {
                        let splitString = _split( splitData[0], '\n' );
                        if ( splitString && isArrayExists( splitString ) ) {
                            if ( splitString.length > 1 ) {
                                _forEach( splitString, lineString => {
                                    options.push({
                                        value: _trim( lineString ),
                                        label: _trim( lineString )
                                    });
                                });
                            } else {
                                options.push({
                                    value: _trim( splitString[0] ),
                                    label: _trim( splitString[0] )
                                });
                            } // end - splitString
                        }
                    }
                }
                break;
        }
    } else {
        switch( field.type ) {
            case 'state':
                let sState = _find( STATE_OPTIONS, { country: 'Malaysia' } );
                if ( sState && sState.states && isArrayExists( sState.states ) ) {
                    sState.states.forEach(state => {
                        options.push({
                            value: state,
                            label: state
                        });
                    });
                }
                break;
            case 'district':
                options = getSelectOptions({ list: ( districts && isArrayExists( districts ) && selectedState && !_isEmpty( selectedState ) ? _filter( districts, { value: selectedState } ) : [] ), options: [], keys: { value: 'label', label: 'label', sortBy: 'label' } });
                break;
            case 'race':
                options = getSelectOptions({ list: races && isArrayExists( races ) ? _sortBy( races, r => r.label.toLowerCase() ) : [], options: [], keys: { value: 'label', label: 'label', sortBy: 'label' } });
                break;
            case 'iam':
                options = [
                    { value: 'Student', label: 'Student' },
                    { value: 'Teacher', label: 'Teacher' },
                    { value: 'University Volunteer', label: 'University Volunteer' },
                ];
                break;
        }
    } // end - field.options
    return options;
}

// check if field is default field
export const isDefaultField = (field) => {
    const fieldType = getFieldType( field );
    return ( fieldType && fieldType.default ? true : false );
}

// check if field is unique field
export const isUniqueField = (field) => {
    const fieldType = getFieldType( field );
    return ( fieldType && fieldType.unique ? true : false );
}

// check if field is fixed field
export const isFixedField = (field) => {
    const fieldType = getFieldType( field );
    return ( fieldType && fieldType.fixed ? true : false );
}

// get field snake case id 
export const getFieldSnakeCaseId = (field, addID = false) => {
    const field_label = ( field && field.label && !_isEmpty( field.label ) ) ? _snakeCase( _toLower( field.label ) ) : '';
    return ( field_label ) + ( addID ? '_|_' + field.id : '' );
}

// get field by snake case id
export const getFieldBySnakeCaseId = (fields, id, addID = false) => {
    const snakeCaseData = _split( getFieldSnakeCaseId( id, addID ), '_|_', 2 );
    const field_id = ( snakeCaseData && snakeCaseData[1] && !_isEmpty( snakeCaseData[1] ) ? snakeCaseData[1] : ( snakeCaseData[0] || '' ) );
    return _find( fields, { id: field_id } );
}