/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';

import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';


// import { PLATFORM_COURSE_REGISTRATION_LINK } from '../../constants';

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
    border: 2px solid #212121;
`;

class ColumnsMenu extends React.Component {

    utcOffSet = 8;
    
    handleCheckboxChanges = option => event => {
        const { onUpdate, columns } = this.props;
        let newColumns = ( columns && isArrayExists( columns ) ? cloneCollections( columns ) : [] );
        if ( event.target.checked ) {
           if ( !_find( newColumns, { id: option.id } ) )
                newColumns.push(option); 
        } else {
            if ( _find( newColumns, { id: option.id } ) ) {
                var removed = _remove( newColumns, { id: option.id } );
            }
        } // end - checked
        
        if ( onUpdate )
            onUpdate( newColumns );
    }

    getOptions = () => {
        let options = [];

        // add 12 months forward
        for (let i = 12; i >= 0; i--) {
            let date = moment().utcOffset(this.utcOffSet).add(i,'months').startOf('month');
            options.push({
                id: date.format('YYYY-MM'),
                label: date.format('MMM YY'),
                start_at: date.valueOf(),
                end_at: date.endOf('month').valueOf()
            });
        }

        // add 12 months backward
        for (let i = 1; i < 13; i++) {
            let date = moment().utcOffset(this.utcOffSet).subtract(i,'months').startOf('month');
            options.push({
                id: date.format('YYYY-MM'),
                label: date.format('MMM YY'),
                start_at: date.valueOf(),
                end_at: date.endOf('month').valueOf()
            });
        }
        
        return _sortBy( options, ['id'] );
    }

    checkIfDisabled = (option) => {
        const { disableColumns } = this.props;
        return ( disableColumns && isArrayExists( disableColumns ) && _find( disableColumns, { id: option.id } ) ? true : false );
    }

    render() {
        const { columns } = this.props;
        const options = this.getOptions();
        return (
        <Wrapper>
            <Typography variant="h5">Show Column(s)</Typography>
            <Grid container spacing={0}>
                { options && isArrayExists( options ) ? options.map(option => {
                    return (
                    <Grid key={option.id} item xs={6} sm={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={( columns && isArrayExists( columns ) && _find( columns, { id: option.id } ) ? true : false )}
                                    onChange={this.handleCheckboxChanges(option)}
                                    disabled={this.checkIfDisabled(option)}
                                    value={option.id}
                                    color="primary" />
                            }
                            label={( option.label || '' )} />
                    </Grid>
                    );
                }) : null }
            </Grid>
        </Wrapper>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ColumnsMenu);