/* eslint-disable */
import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

// import styles
import theme from "./theme";
import { OpacityComponent } from "./styles/division";

// import components
import ScreenLoader from "./components/ScreenLoader";
import OpacityLoader from "./components/OpacityLoader";
import RouterModifier from "./components/RouterModifier";

// import pages
import Dashboard from "./pages/Dashboard";
// import MaintenanceModePage from './pages/MaintenanceModePage';

import UsersPage from "./pages/UsersPage";
import LoginPage from "./pages/Login";
import AuthVerifier from "./pages/AuthVerifier";
import SSOVerifier from "./pages/SSOVerifier";
import AuthProfilePage from "./pages/AuthProfilePage";
import RedirectPage from "./pages/RedirectPage";
import RedirectNotificationPage from "./pages/RedirectNotificationPage";
import BadgrRedirect from "./pages/BadgrRedirect";
import SSOBadgr from "./pages/SSOBadgr";

import PartnerPage from "./pages/PartnerPage";
import PartnersPage from "./pages/PartnersPage";
import PersonnelPage from "./pages/PersonnelPage";
import PersonnelsPage from "./pages/PersonnelsPage";
import LocationsPage from "./pages/LocationsPage";

import CoursePage from "./pages/CoursePage";
import CourseNewPage from "./pages/CourseNewPage";
import CoursesPage from "./pages/CoursesPage";
import ProgramPage from "./pages/ProgramPage";
import ProgramsPage from "./pages/ProgramsPage";
import SchedulePage from "./pages/SchedulePage";
import ScheduleNewPage from "./pages/ScheduleNewPage";
import SchedulesPage from "./pages/SchedulesPage";
import ClassRegistrationPage from "./pages/ClassRegistrationPage";
import ClassConfirmationPage from "./pages/ClassConfirmationPage";
import ClassCourseAccessPage from "./pages/ClassCourseAccessPage";
import ClassPaymentTrackingPage from "./pages/ClassPaymentTrackingPage";
import ClassAttendanceReportingPage from "./pages/ClassAttendanceReportingPage";
import ClassShowcasePage from "./pages/ClassShowcasePage";
import ClassQuarterlyReportPage from "./pages/ClassQuarterlyReportPage";
import OpenDay from "./pages/OpenDay";
import PaymentHistory from "./pages/PaymentHistory";
import BadgesOneOffIssue from "./pages/BadgesOneOffIssue";
import BusinessDashboard from "./pages/BusinessDashboard";
import BusinessDashboard2 from "./pages/BusinessDashboard2";
import BusinessDashboardRND from "./pages/BusinessDashboardRND";
import ReportLTU from "./pages/ReportLTU";
import NotionPage from "./pages/NotionPage";

import DatabaseStudentPage from "./pages/DatabaseStudentPage";
import DatabaseStudentsPage from "./pages/DatabaseStudentsPage";
import DatabaseStudentsSearch from "./pages/DatabaseStudentsSearch";
import DatabaseMentorPage from "./pages/DatabaseMentorPage";
import DatabaseMentorsPage from "./pages/DatabaseMentorsPage";
import DatabaseMarketingPage from "./pages/DatabaseMarketingPage";
import TraineeToStudentRedirect from "./pages/TraineeToStudentRedirect";

// import MaintenanceRegions from './pages/MaintenanceRegions';
import MaintenanceDesignation from "./pages/MaintenanceDesignation";
import MaintenanceCourseType from "./pages/MaintenanceCourseType";
import MaintenanceCourseDuration from "./pages/MaintenanceCourseDuration";
import MaintenancePaymentMethod from "./pages/MaintenancePaymentMethod";
import MaintenanceCurrency from "./pages/MaintenanceCurrency";
import MaintenanceBadgeType from "./pages/MaintenanceBadgeType";
import MaintenanceFeedbackForms from "./pages/MaintenanceFeedbackForms";
import MaintenanceFeedbackForm from "./pages/MaintenanceFeedbackForm";
import MaintenanceRegistrationForms from "./pages/MaintenanceRegistrationForms";
import MaintenanceRegistrationForm from "./pages/MaintenanceRegistrationForm";
import MaintenanceRubrics from "./pages/MaintenanceRubrics";
import MaintenanceCertificates from "./pages/MaintenanceCertificates";
import MaintenanceCertificate from "./pages/MaintenanceCertificate";
import MaintenanceContactHour from "./pages/MaintenanceContactHour";
import MaintenanceProductLevel from "./pages/MaintenanceProductLevel";
import MaintenanceProductCredit from "./pages/MaintenanceProductCredit";
import MaintenancePricePerPax from "./pages/MaintenancePricePerPax";
import MaintenanceRaces from "./pages/MaintenanceRaces";
import MaintenanceDistricts from "./pages/MaintenanceDistricts";
import MaintenanceSchools from "./pages/MaintenanceSchools";
import MaintenanceOneOffBadges from "./pages/MaintenanceOneOffBadges";
import MaintenanceEmailTemplates from "./pages/MaintenanceEmailTemplates";
import MaintenanceRoles from "./pages/MaintenanceRoles";
import MaintenanceModePage from "./pages/MaintenanceModePage";

// student pages
import StudentDashboard from "./pages/StudentDashboard";
import StudentProfilePage from "./pages/StudentProfilePage";
import StudentDigitalBadges from "./pages/StudentDigitalBadges";
import StudentShowcaseFeedback from "./pages/StudentShowcaseFeedback";
import StudentShowcaseDate from "./pages/StudentShowcaseDate";
import StudentShowcaseProject from "./pages/StudentShowcaseProject";
import StudentTeamsProfile from "./pages/StudentTeamsProfile";
import StudentTeamProject from "./pages/StudentTeamProject";

// mentor pages
import MentorClassAttendances from "./pages/MentorClassAttendances";

// public pages
import CourseRegistrationPage from "./pages/CourseRegistrationPage";
import BadgeDetailsPage from "./pages/BadgeDetailsPage";
import BackpackPage from "./pages/BackpackPage";
import CertificatePage from "./pages/CertificatePage";
import CertificatesPage from "./pages/CertificatesPage";

// payment related
import PaymentFormPage from "./pages/PaymentFormPage";
import PaymentStatusPage from "./pages/PaymentStatusPage";

import HQSummary from "./pages/HQSummary";

// helpers
import { hasAccessRights, isAdminType, isUserType, isMentorRole } from "./helpers/auth";

// import actions
import { isUserLoggedIn } from "./actions/auth";

// import { DEV_MODE } from './constants';

const isActive = (authData) => {
    // for admin & super admin
    if (authData && isAdminType(authData)) {
        return authData && authData.status && authData.status === "active" ? true : false;
    } else if (authData && isUserType(authData)) {
        // for student or mentor ignore status
        return true;
    } // end - authData
};

const isSuperAdmin = (authData) => {
    return authData && authData.type && authData.type === "superadmin" ? true : false;
};

/* Authentication based routes */
const PublicRoute = ({ component, authData, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (isAdminType(authData) && isActive(authData)) {
                    // if is admin, redirect to admin dashboard
                    return <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />;
                } else if (isUserType(authData) && isActive(authData)) {
                    // if is user, redirect to student dashboard
                    return (
                        <Redirect
                            to={{
                                pathname: "/student/dashboard",
                                state: { from: props.location },
                            }}
                        />
                    );
                } else {
                    // else, return the current element
                    return React.createElement(component, props);
                } // end - authData
            }}
        />
    );
};

const MentorRoute = ({ component, authData, accessRights, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                authData ? (
                    (isAdminType(authData) || isMentorRole(authData)) && isActive(authData) ? (
                        React.createElement(component, props)
                    ) : (
                        <Redirect to={{ pathname: "/noaccess", state: { from: props.location } }} />
                    )
                ) : (
                    <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                )
            }
        />
    );
};

const UserRoute = ({ component, authData, accessRights, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                authData ? (
                    (isAdminType(authData) || isUserType(authData)) && isActive(authData) ? (
                        React.createElement(component, props)
                    ) : (
                        <Redirect to={{ pathname: "/noaccess", state: { from: props.location } }} />
                    )
                ) : (
                    <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                )
            }
        />
    );
};

const AdminRoute = ({ component, authData, accessRights, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                authData ? (
                    isAdminType(authData) && isActive(authData) ? (
                        hasAccessRights(authData, accessRights) ? (
                            React.createElement(component, props)
                        ) : (
                            <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />
                        )
                    ) : (
                        <Redirect to={{ pathname: "/noaccess", state: { from: props.location } }} />
                    )
                ) : (
                    <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                )
            }
        />
    );
};

const SuperAdminRoute = ({ component, authData, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                isSuperAdmin(authData) && isActive(authData) ? (
                    React.createElement(component, props)
                ) : (
                    <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />
                )
            }
        />
    );
};

// open route (ignore authData)
const OpenRoute = ({ component, authData, ...rest }) => {
    return <Route {...rest} render={(props) => React.createElement(component, props)} />;
};

const TestRoute = ({ component, authData, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                authData && authData.email && authData.email == "webadmin@dreamcatcher.asia" ? (
                    React.createElement(component, props)
                ) : (
                    <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />
                )
            }
        />
    );
};

/* App Structure */
class Structure extends React.Component {
    state = {
        authRand: false,
        dataLoaded: false,
    };

    componentDidMount() {
        // call for auth listener
        this.props.dispatch(isUserLoggedIn());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { authRand } = this.props;
        if (authRand && !this.state.authRand) {
            this.setState({ dataLoaded: true, authRand });
        }
    }

    renderStructure() {
        const { loader, authData, loader_label } = this.props;
        return (
            <React.Fragment>
                {loader ? <OpacityLoader open={loader} message={loader_label || ""} /> : null}
                <BrowserRouter>
                    <RouterModifier>
                        <Switch>
                            <AdminRoute path="/dashboard" component={Dashboard} authData={authData} />

                            <AdminRoute
                                path="/partner/:partner_id/personnel/:personnel_id"
                                component={PersonnelPage}
                                authData={authData}
                                accessRights={["ptr"]}
                            />
                            <AdminRoute path="/partner/:partner_id/personnel" component={PersonnelsPage} authData={authData} accessRights={["ptr"]} />
                            <AdminRoute path="/partner/:partner_id" component={PartnerPage} authData={authData} accessRights={["ptr"]} />
                            <AdminRoute path="/partner" component={PartnersPage} authData={authData} accessRights={["ptr"]} />

                            <AdminRoute path="/training_venues" component={LocationsPage} authData={authData} accessRights={["tlr", "tlw"]} />

                            <AdminRoute path="/products/new" component={CourseNewPage} authData={authData} accessRights={["cpr"]} />
                            <AdminRoute path="/products/:course_id" component={CoursePage} authData={authData} accessRights={["cpr"]} />
                            <AdminRoute path="/products" component={CoursesPage} authData={authData} accessRights={["cpr"]} />
                            <AdminRoute path="/courses/new" component={CourseNewPage} authData={authData} accessRights={["cpr"]} />
                            <AdminRoute path="/courses/:course_id" component={CoursePage} authData={authData} accessRights={["cpr"]} />
                            <AdminRoute path="/courses" component={CoursesPage} authData={authData} accessRights={["cpr"]} />

                            <AdminRoute path="/programs/:program_id" component={ProgramPage} authData={authData} accessRights={["ppr"]} />
                            <AdminRoute path="/programs" component={ProgramsPage} authData={authData} accessRights={["ppr"]} />

                            <AdminRoute path="/schedules/new" component={ScheduleNewPage} authData={authData} accessRights={["scw"]} />
                            <AdminRoute path="/schedules/:schedule_id" component={SchedulePage} authData={authData} accessRights={["scr"]} />
                            <AdminRoute path="/schedules" component={SchedulesPage} authData={authData} accessRights={["scr", "scw"]} />

                            <AdminRoute path="/class_registration" component={ClassRegistrationPage} authData={authData} accessRights={["crr"]} />
                            <AdminRoute path="/class_confirmation" component={ClassConfirmationPage} authData={authData} accessRights={["cfr"]} />
                            <AdminRoute path="/course_access" component={ClassCourseAccessPage} authData={authData} accessRights={["car"]} />
                            <AdminRoute
                                path="/class_payment_tracking"
                                component={ClassPaymentTrackingPage}
                                authData={authData}
                                accessRights={["pyr"]}
                            />
                            <AdminRoute
                                path="/class_attendance_reporting"
                                component={ClassAttendanceReportingPage}
                                authData={authData}
                                accessRights={["arr"]}
                            />
                            <AdminRoute path="/class_showcase" component={ClassShowcasePage} authData={authData} accessRights={["shr"]} />
                            <AdminRoute
                                path="/class_quarterly_report"
                                component={ClassQuarterlyReportPage}
                                authData={authData}
                                accessRights={["qrr"]}
                            />
                            <AdminRoute path="/open_days" component={OpenDay} authData={authData} accessRights={["scr"]} />
                            <AdminRoute path="/payment_history" component={PaymentHistory} authData={authData} accessRights={["pyr"]} />
                            <AdminRoute path="/issue_oneoff_badges" component={BadgesOneOffIssue} authData={authData} accessRights={["obr"]} />

                            <AdminRoute path="/t/:trainee_id" component={TraineeToStudentRedirect} authData={authData} accessRights={["sdr"]} />
                            <AdminRoute path="/student_database/:uid" component={DatabaseStudentPage} authData={authData} accessRights={["sdr"]} />
                            <AdminRoute path="/student_database" component={DatabaseStudentsPage} authData={authData} accessRights={["sdr"]} />
                            <AdminRoute
                                path="/student_database_search"
                                component={DatabaseStudentsSearch}
                                authData={authData}
                                accessRights={["sdr"]}
                            />
                            <AdminRoute path="/mentors/:uid" component={DatabaseMentorPage} authData={authData} accessRights={["mer"]} />
                            <AdminRoute path="/mentors" component={DatabaseMentorsPage} authData={authData} accessRights={["mer"]} />
                            <AdminRoute path="/course_access_report" component={HQSummary} authData={authData} />

                            <AdminRoute path="/marketing_database" component={DatabaseMarketingPage} authData={authData} accessRights={["mdr"]} />
                            <AdminRoute path="/business_dashboard" component={BusinessDashboard} authData={authData} />
                            <AdminRoute path="/business_dashboard_2" component={BusinessDashboard2} authData={authData} />
                            <AdminRoute path="/rnd_data_dashboard" component={BusinessDashboardRND} authData={authData} />
                            <AdminRoute path="/report_ltu" component={ReportLTU} authData={authData} />

                            <AdminRoute
                                path="/maintenance/certificates/:certificate_id"
                                component={MaintenanceCertificate}
                                authData={authData}
                                accessRights={["mtr"]}
                            />
                            <AdminRoute
                                path="/maintenance/certificates"
                                component={MaintenanceCertificates}
                                authData={authData}
                                accessRights={["mtr"]}
                            />
                            <AdminRoute
                                path="/maintenance/feedback_forms/:feedback_form_id"
                                component={MaintenanceFeedbackForm}
                                authData={authData}
                                accessRights={["mtr"]}
                            />
                            <AdminRoute
                                path="/maintenance/feedback_forms"
                                component={MaintenanceFeedbackForms}
                                authData={authData}
                                accessRights={["mtr"]}
                            />
                            <AdminRoute
                                path="/maintenance/registration_forms/:registration_form_id"
                                component={MaintenanceRegistrationForm}
                                authData={authData}
                                accessRights={["mtr"]}
                            />
                            <AdminRoute
                                path="/maintenance/registration_forms"
                                component={MaintenanceRegistrationForms}
                                authData={authData}
                                accessRights={["mtr"]}
                            />
                            <AdminRoute
                                path="/maintenance/email_templates"
                                component={MaintenanceEmailTemplates}
                                authData={authData}
                                accessRights={["mtr"]}
                            />
                            <AdminRoute
                                path="/maintenance/designation"
                                component={MaintenanceDesignation}
                                authData={authData}
                                accessRights={["mtr"]}
                            />
                            <AdminRoute
                                path="/maintenance/course_type"
                                component={MaintenanceCourseType}
                                authData={authData}
                                accessRights={["mtr"]}
                            />
                            <AdminRoute
                                path="/maintenance/course_duration"
                                component={MaintenanceCourseDuration}
                                authData={authData}
                                accessRights={["mtr"]}
                            />
                            <AdminRoute
                                path="/maintenance/payment_method"
                                component={MaintenancePaymentMethod}
                                authData={authData}
                                accessRights={["mtr"]}
                            />
                            <AdminRoute path="/maintenance/currency" component={MaintenanceCurrency} authData={authData} accessRights={["mtr"]} />
                            <AdminRoute path="/maintenance/badge_type" component={MaintenanceBadgeType} authData={authData} accessRights={["mtr"]} />
                            <AdminRoute path="/maintenance/rubrics" component={MaintenanceRubrics} authData={authData} accessRights={["mtr"]} />
                            <AdminRoute
                                path="/maintenance/price_per_pax"
                                component={MaintenancePricePerPax}
                                authData={authData}
                                accessRights={["mtr"]}
                            />
                            <AdminRoute
                                path="/maintenance/contact_hour"
                                component={MaintenanceContactHour}
                                authData={authData}
                                accessRights={["mtr"]}
                            />
                            <AdminRoute
                                path="/maintenance/product_level"
                                component={MaintenanceProductLevel}
                                authData={authData}
                                accessRights={["mtr"]}
                            />
                            <AdminRoute
                                path="/maintenance/product_credit"
                                component={MaintenanceProductCredit}
                                authData={authData}
                                accessRights={["mtr"]}
                            />
                            <AdminRoute path="/maintenance/districts" component={MaintenanceDistricts} authData={authData} accessRights={["mtr"]} />
                            <AdminRoute path="/maintenance/ethnicity" component={MaintenanceRaces} authData={authData} accessRights={["mtr"]} />
                            <AdminRoute path="/maintenance/schools" component={MaintenanceSchools} authData={authData} accessRights={["mtr"]} />
                            <AdminRoute
                                path="/maintenance/one_off_badges"
                                component={MaintenanceOneOffBadges}
                                authData={authData}
                                accessRights={["mtr"]}
                            />
                            <AdminRoute path="/maintenance" component={Dashboard} authData={authData} accessRights={["mtr"]} />

                            <AdminRoute path="/notion" component={NotionPage} authData={authData} />

                            <AdminRoute path="/auth/profile" component={AuthProfilePage} authData={authData} />
                            <AdminRoute path="/auth" component={AuthProfilePage} authData={authData} />

                            <SuperAdminRoute path="/users" component={UsersPage} authData={authData} />
                            <SuperAdminRoute path="/roles" component={MaintenanceRoles} authData={authData} />

                            <OpenRoute path="/course_registration/:schedule_id" component={CourseRegistrationPage} authData={authData} />
                            <OpenRoute path="/course_registration" component={CourseRegistrationPage} authData={authData} />

                            {/* <OpenRoute path="/payment/:trainee_id" component={PaymentFormPage} authData={authData} /> */}
                            <OpenRoute path="/payment" component={PaymentFormPage} authData={authData} />
                            <OpenRoute path="/payment_status_page" component={PaymentStatusPage} />

                            <OpenRoute path="/backpack/:uid" component={BackpackPage} authData={authData} />
                            <OpenRoute path="/b/:badge_id" component={BadgeDetailsPage} authData={authData} />
                            <OpenRoute path="/b" component={BadgeDetailsPage} authData={authData} />

                            <OpenRoute path="/certificate/:cert_id" component={CertificatePage} authData={authData} />
                            <OpenRoute path="/certificate" component={CertificatePage} authData={authData} />
                            <OpenRoute path="/certificates" component={CertificatesPage} authData={authData} />

                            <MentorRoute path="/student/class/:lms_course_id/attendances" component={MentorClassAttendances} authData={authData} />
                            <MentorRoute path="/mentor/class/:sis_section_id/project" component={StudentShowcaseProject} authData={authData} />
                            <MentorRoute path="/mentor/class/:sis_section_id/showcase_date" component={StudentShowcaseDate} authData={authData} />
                            <MentorRoute path="/mentor/class/:sis_section_id/feedback_form" component={StudentShowcaseFeedback} authData={authData} />
                            <MentorRoute
                                path="/mentor/class/:sis_section_id/team_project/:project_id"
                                component={StudentTeamProject}
                                authData={authData}
                            />
                            <UserRoute path="/student/class/:sis_section_id/project" component={StudentShowcaseProject} authData={authData} />
                            <UserRoute
                                path="/student/class/:sis_section_id/team_project/:project_id"
                                component={StudentTeamProject}
                                authData={authData}
                            />
                            <UserRoute path="/student/class/:sis_section_id/showcase_date" component={StudentShowcaseDate} authData={authData} />
                            <UserRoute path="/student/class/:sis_section_id/feedback_form" component={StudentShowcaseFeedback} authData={authData} />
                            <UserRoute path="/student/class/:sis_section_id" component={StudentDashboard} authData={authData} />
                            <UserRoute path="/student/class" component={StudentDashboard} authData={authData} />
                            <UserRoute path="/student/badges" component={StudentDigitalBadges} authData={authData} />
                            <UserRoute path="/student/profile" component={StudentProfilePage} authData={authData} />
                            <UserRoute path="/student/teams" component={StudentTeamsProfile} authData={authData} />
                            <UserRoute path="/student/dashboard" component={StudentDashboard} authData={authData} />
                            <UserRoute path="/student" component={StudentDashboard} authData={authData} />

                            <OpenRoute path="/badgr_oauth_redirect" component={BadgrRedirect} />
                            <OpenRoute path="/sso_badgr" component={SSOBadgr} />
                            <OpenRoute path="/sso" component={SSOVerifier} />
                            <AdminRoute path="/nr/:schedule_id" component={RedirectNotificationPage} authData={authData} />
                            <AdminRoute path="/nr" component={RedirectNotificationPage} authData={authData} />
                            <OpenRoute path="/r/:schedule_id" component={RedirectPage} />
                            <OpenRoute path="/r" component={RedirectPage} />

                            <PublicRoute path="/bp/:trainee_id" component={TraineeToStudentRedirect} />
                            <PublicRoute path="/fbauth" component={AuthVerifier} authData={authData} />
                            <PublicRoute path="/login" component={LoginPage} authData={authData} />
                            <PublicRoute path="/noaccess" component={LoginPage} authData={authData} />

                            <Route exact path="/" render={() => <Redirect to="/dashboard" />} />

                            {/* <PublicRoute path="/*" component={MaintenanceModePage} authData={authData} /> */}
                        </Switch>
                    </RouterModifier>
                </BrowserRouter>
            </React.Fragment>
        );
    }

    render() {
        const { dataLoaded } = this.state;
        return <ThemeProvider theme={theme}>{dataLoaded ? this.renderStructure() : <ScreenLoader />}</ThemeProvider>;
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth.user,
        authRand: state.auth.rand,
        loader: state.global.loader,
        loader_label: state.global.loader_label,
    };
};

export default connect(mapStateToProps)(Structure);
