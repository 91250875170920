/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import _isEmpty from 'lodash/isEmpty';

import { getStudentEnrollmentStatus, getStudentEnrollmentStatusLabel } from '../../helpers/trainees';

import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import theme from '../../theme';

class StudentEnrollmentStatus extends React.Component {

    render() {
        const { trainee } = this.props;
        if ( trainee && !_isEmpty( trainee ) ) {
            let status = getStudentEnrollmentStatus(trainee),
                status_label = getStudentEnrollmentStatusLabel( status );
            switch( status ) {
                case 'registered':
                case 'trial':
                    return <WrapWord style={{ fontSize: '12px', fontWeight: '700', textTransform: 'uppercase', padding: '4px 8px' }}>{status_label}</WrapWord>;
                case 'confirmed':
                    return <SuccessTag>{status_label}</SuccessTag>;
                case 'active':
                    return <InfoTag>{status_label}</InfoTag>;
                case 'inactivate':
                    return <GreyTag>{status_label}</GreyTag>;
                case 'conclude':
                    return <SuccessTag>{status_label}</SuccessTag>;
                case 'delete':
                    return <ErrorTag>{status_label}</ErrorTag>;
            } // end - item[type]
        } // end - item[type]
    }

}

export default StudentEnrollmentStatus