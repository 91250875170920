import React from "react";

const info = {
    heading: <></>,
    contents: [
        {
            id: "1",
            title: "Add a mentor here.",
            content: (
                <>
                    <div>
                        <p style={{ marginBottom: "10px" }}>
                            1. Each mentor should have 2 logins to LMS:
                            <ul style={{ listStyle: "lower-alpha", paddingLeft: "10px", marginLeft: "10px" }}>
                                <li>
                                    One for the student role (e.g.{" "}
                                    <a href="mailto:haslina+student@gmail.com" target="_blank">
                                        haslina+student@gmail.com
                                    </a>
                                </li>
                                <li>
                                    One as a mentor role (e.g.{" "}
                                    <a href="mailto:haslina+mentor@gmail.com" target="_blank">
                                        haslina+mentor@gmail.com
                                    </a>
                                </li>
                            </ul>
                        </p>
                        <p style={{ marginBottom: "10px" }}>
                            2. Mentor as a Student role in LMS
                            <ul style={{ listStyle: "disc", paddingLeft: "10px", marginLeft: "10px" }}>
                                <li>Mainly used for training purposes (e.g. mentor going through Mentor Development Program as a student).</li>
                                <li>
                                    Mentor will be registered (as a student) into SMS-Class Schedule-Mentor Development Program (MDP) for onboarding.
                                </li>
                                <li>Mentor needs to earn the Mentor Startup’s Digital Badge to become an eligible mentor.</li>
                                <li>After the MDP, Mentor can use this account for testing as a student</li>
                            </ul>
                        </p>
                        <p style={{ marginBottom: "10px" }}>
                            3. Mentor as a Mentor role in LMS
                            <ul style={{ listStyle: "disc", paddingLeft: "10px", marginLeft: "10px" }}>
                                <li>Add mentor here with their mentor-role email</li>
                                <li>Add mentor into the designated class on Course Access</li>
                                <li>To experience as a student in LMS, each mentor can use the “Student View” function</li>
                            </ul>
                        </p>
                    </div>
                </>
            ),
        },
        {
            id: "2",
            title: "Use a different email for LMS & SMS.",
            content: (
                <>
                    <div>
                        <p style={{ marginBottom: "10px" }}>
                            If you are a principal and/or a school coordinator, you need to use both SMS & LMS. Due to security reasons, we have
                            disabled the linkages between SMS and LMS for the same email address & account.
                        </p>
                        <p style={{ marginBottom: "10px" }}>
                            To minimise this confusion, we suggest to follow the following format: For SMS
                            <br />-{" "}
                            <a href="mailto:haslina+sms@gmail.com" target="_blank">
                                haslina+sms@gmail.com
                            </a>{" "}
                            For LMS (as mentor)
                            <br />-{" "}
                            <a href="mailto:haslina+mentor@gmail.com" target="_blank">
                                haslina+mentor@gmail.com
                            </a>{" "}
                            For LMS (as student)
                            <br />-{" "}
                            <a href="mailto:haslina+student@gmail.com" target="_blank">
                                haslina+student@gmail.com
                            </a>
                        </p>
                        <p style={{ marginBottom: "5px" }}>Note:</p>
                        <ol style={{ listStyle: "numeric", paddingLeft: "10px", marginLeft: "10px" }}>
                            <li>
                                The plus (“+”) sign method is recognised as a different email in LMS,{" "}
                                <a
                                    href="https://support.google.com/a/users/answer/9308648?hl=en#:~:text=If%20you%20don't%20want,of%20emails%20for%20different%20purposes."
                                    target="_blank"
                                >
                                    you will still get incoming emails in the same inbox
                                </a>
                                .
                            </li>
                            <li>
                                Only available for those with
                                <br />
                                - gmail or emails under GSuite
                                <br />-{" "}
                                <a href="https://www.ghacks.net/2013/09/17/can-now-use-email-aliases-outlook-com/" target="_blank">
                                    Outlook
                                </a>{" "}
                                or emails under Outlook <br />-{" "}
                                <a
                                    href="https://proton.me/support/creating-aliases#:~:text=And%20all%20Proton%20Mail%20users,hide%2Dmy%2Demail%20aliases.&text=Here%20we%20explain%20how%20to,%2C%20%2Baliases%20and%20SimpleLogin%20aliases."
                                    target="_blank"
                                >
                                    Proton mail
                                </a>
                            </li>
                        </ol>
                    </div>
                </>
            ),
        },
    ],
};

const buttons = [
    {
        id: "0",
        type: "drive",
        link: "hhttps://drive.google.com/drive/u/1/folders/1IfWxrjYP-ZCM9RsfEVrJmqC0ZKt0L-J",
        label: "Mentor Portal",
    },
    {
        id: "1",
        tooltip: true,
        tooltipContent: (
            <div style={{ padding: "10px" }}>
                <p style={{ fontWeight: "700" }}>📂 Chumbaka Partner Portal - 3. Mentor Recruitment & Compliance Audit</p>
                <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                    <li>Resources</li>
                </ul>
            </div>
        ),
        type: "notion",
        link: "https://www.notion.so/chumbaka/3-Mentor-Recruitment-Compliance-Audit-84a0d89195e34eabac14b75f90de4e94?pvs=4",
        label: "Partner Portal",
    },
    {
        id: "2",
        tooltip: true,
        tooltipContent: (
            <div style={{ padding: "10px" }}>
                <p style={{ fontWeight: "700" }}>📂 Chumbaka Partner Portal - 3. Mentor Recruitment & Compliance Audit</p>
                <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                    <li>Mentor Contract</li>
                    <li>Post-audit Report</li>
                </ul>
            </div>
        ),
        type: "drive",
        link: "https://drive.google.com/drive/folders/1G2SEjGUkei2aK4ZPm6xhQoC9MoApfbqm",
        label: "Partner Portal",
    },
];

export default {
    info,
    buttons,
};
