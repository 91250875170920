/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _sortBy from "lodash/sortBy";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _remove from "lodash/remove";
import _toLower from "lodash/toLower";

import ModalView from "../../components/ModalView";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormRadio from "../../components/FormRadio";
import FormDatePicker from "../../components/FormDatePicker";
import FormCheckbox from "../../components/FormCheckbox";
import ToolTipInfo from "../../components/ToolTipInfo";

import { isArrayExists, isObjectExists, validateEmail } from "../../helpers/validation";
import { cloneCollections, getSelectOptions } from "../../helpers/data";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import { createNewPassword } from "../../helpers/password";
// import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';

import { toggleLoader } from "../../actions/global";

import { InfoButton, InverseButton } from "../../styles/button";

import { ENROLLMENT_STATUS_OPTIONS } from "../../constants";

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
    border: 2px solid #212121;
`;

class StudentLMSPassword extends React.Component {
    utcOffSet = 8;

    state = {
        open: false,
        modalData: false,
        confirmation: false,
    };

    handleConfirmation = () => {
        const { student, onTrigger } = this.props;
        const { modalData } = this.state;

        const formData = {
            uid: student.uid,
            email: student.email,
            password: modalData.password,
        };

        this.setState({ open: false, modalData: false, confirmation: false });
        onTrigger(formData);
    };

    handleUpdate = () => {
        const { modalData } = this.state;
        let error = false;

        if (!(modalData && modalData.password_repeat && !_isEmpty(modalData.password_repeat))) error = "Please confirm the new password";

        if (!(modalData && modalData.password && !_isEmpty(modalData.password))) error = "Please insert a new password";

        if (!(modalData && modalData.password && !_isEmpty(modalData.password) && _size(modalData.password) > 7))
            error = "The new password entered must be at least 8 characters long";

        if (
            modalData &&
            modalData.password_repeat &&
            !_isEmpty(modalData.password_repeat) &&
            modalData.password &&
            !_isEmpty(modalData.password) &&
            modalData.password !== modalData.password_repeat
        )
            error = "The new password entered do not match";

        // if ( !( modalData && modalData.email && validateEmail( modalData.email ) ) )
        //     error = "Please insert a valid email.";

        if (error) {
            triggerErrorAlert(error);
        } else {
            this.setState({ confirmation: true });
        }
    };

    handleFormUpdate = (newValue, key, subkey, subVal) => {
        const { modalData } = this.state;
        let newData = modalData ? cloneCollections(modalData) : {};
        switch (key) {
            default:
                if (subkey && (subkey === "checked" || subkey === "unchecked") && subVal && !_isEmpty(subVal)) {
                    // use subVal as the new value
                    newData[key] = subVal;
                } else {
                    // otherwise - just update normally
                    newData[key] = newValue;
                }
                break;
        } // end - key

        this.setState({ modalData: newData });
    };

    handleOpen = (event) => {
        const { changesMade, student } = this.props;
        event.preventDefault();
        let error = false;

        if (changesMade) {
            error = "Please save your changes before proceeding.";
        }

        if (error) {
            triggerErrorAlert(error, 4000);
        } else {
            this.setState({
                open: true,
                modalData: {
                    password: "",
                    password_repeat: "",
                },
            });
        }
    };

    handlePasswordGenerate = () => {
        const { modalData } = this.state;
        let newPassword = createNewPassword();
        let newData = modalData ? cloneCollections(modalData) : {};
        newData.password = newPassword;
        newData.password_repeat = newPassword;
        this.setState({ modalData: newData });
    };

    renderForm = () => {
        const { modalData } = this.state;
        return (
            <div>
                {/* <FormInput
                label="New Email *"
                value={ modalData.email || '' }
                name="email"
                type="email"
                onChange={ this.handleFormUpdate } /> */}
                <FormInput
                    label="New Password *"
                    value={modalData.password || ""}
                    name="password"
                    // type="password"
                    onChange={this.handleFormUpdate}
                />
                <FormInput
                    label="Confirm New Password *"
                    value={modalData.password_repeat || ""}
                    name="password_repeat"
                    // type="password"
                    onChange={this.handleFormUpdate}
                />
                <div>
                    <InverseButton
                        style={{
                            padding: "10px 20px",
                        }}
                        onClick={this.handlePasswordGenerate}
                    >
                        Generate a Password
                    </InverseButton>
                </div>
            </div>
        );
    };

    render() {
        const { student } = this.props;
        const { open, confirmation, modalData } = this.state;
        return (
            <>
                <div style={{ padding: "10px 0", textAlign: "left" }}>
                    <InfoButton
                        disabled={student && student.role && (student.role === "student" || student.role === "mentor") ? null : "yes"}
                        size="small"
                        style={{ padding: "4px 6px" }}
                        onClick={this.handleOpen}
                    >
                        <i className="fa fa-random"></i>Change LMS Password
                    </InfoButton>
                </div>

                <ModalView
                    open={open}
                    title={
                        <>
                            {/* <ToolTipInfo
                        content={(
                        <>
                            ONE PERSON HAS ONE EMAIL ACCOUNT<br /><br />
                            One email address can only be associated with one Chumbaka account at a time.<br /><br />
                            You may change your email here for only a maximum of 2 times.   
                        </>
                        )} /> */}
                            {`Change LMS Password for ${(student && student.name) || ""}`}
                        </>
                    }
                    onClose={() => this.setState({ open: false, modalData: false })}
                    disableBackdrop={true}
                    actionLabel="Change"
                    maxWidth="sm"
                    doAction={this.handleUpdate}
                    contents={this.renderForm()}
                />

                <ModalView
                    open={confirmation}
                    title={`Please confirm your action`}
                    onClose={() => this.setState({ open: false, modalData: false, confirmation: false })}
                    disableBackdrop={true}
                    actionLabel="Confirm"
                    maxWidth="sm"
                    doAction={this.handleConfirmation}
                    contents={
                        <>
                            <Typography variant="h5" style={{ marginBottom: "15px" }}>{`You're about to change the LMS password of "${
                                (student && student.name) || ""
                            }"`}</Typography>
                            <Typography variant="body1">{`This action cannot be reversed.`}</Typography>
                        </>
                    }
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(StudentLMSPassword);
