import React from "react";

const info = {
    heading: <></>,
    contents: [
        {
            id: "1",
            title: "What is Enrolment-Confirmation stage? ",
            content: (
                <>
                    <div>
                        <p>
                            Enrolment targets to turn registered students into confirmed students. Trial class is one of the strategies for the
                            Enrolment stage to be successful. The goal of the Trial is to convince students with a taste of our exciting learning
                            journey.{" "}
                        </p>
                    </div>
                </>
            ),
        },
        {
            id: "2",
            title: "Background of Trial Class",
            content: (
                <>
                    <div>
                        <p>Trial Classes give confidence to students by allowing them to experience and enjoy Chumbaka's learning journey.</p>
                        <p>To meet the objective, we suggest the following strategies:</p>
                        <ul style={{ listStyle: "numeric", paddingLeft: "10px", marginLeft: "10px" }}>
                            <li>Come & see Policy</li>
                            <li>Trial Class</li>
                        </ul>
                    </div>
                </>
            ),
        },
        {
            id: "3",
            title: "How is this page useful?",
            content: (
                <>
                    <div>
                        <ul style={{ listStyle: "disc", paddingLeft: "10px", marginLeft: "10px" }}>
                            <li>Invite students to a trial class</li>
                            <li>Send confirmation email to the students to confirm their seats</li>
                            <li>Confirmation email includes payment details, calendar, and LMS login details </li>
                            <li>Before the class starts, you may send them a reminder email</li>
                        </ul>
                    </div>
                </>
            ),
        },
    ],
};

const buttons = [
    {
        id: "1",
        tooltip: true,
        tooltipContent: (
            <div style={{ padding: "10px" }}>
                <p style={{ fontWeight: "700" }}>📂 Chumbaka Partner Portal - 6. Operation - 2. Enrolment</p>
                <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                    <li>Background of Trial Class </li>
                    <li>Guide to Run a Trial Class</li>
                    <li>Trial Class FAQs</li>
                    <li>Our Product Lines </li>
                    <li>After Trial Class</li>
                </ul>
            </div>
        ),
        type: "notion",
        link: "https://www.notion.so/chumbaka/2-Enrolment-Trial-Class-1e4815fa5a084bb6a1624bd65cab0158?pvs=4",
        label: "Notion",
    },
    {
        id: "2",
        tooltip: true,
        tooltipContent: (
            <div style={{ padding: "10px" }}>
                <p style={{ fontWeight: "700" }}>📂 Chumbaka Mentor Portal - 2. Enrolment</p>
                <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                    <li>Partner Order Form</li>
                </ul>
            </div>
        ),
        type: "drive",
        link: "https://drive.google.com/drive/u/0/folders/1mPdno1zyBdI8OzKxbkBXBDBE6iIobjqc",
        label: "Google Drive",
    },
];

export default {
    info,
    buttons,
};
