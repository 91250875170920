import React from "react";

const info = {
    heading: <></>,
    contents: [
        {
            id: "1",
            title: "What is 4. Closing-Quarterly Report stage? ",
            content: (
                <>
                    <div>
                        <p>Delivery is about fulfilling your obligation to students and their parents.</p>
                        <p>
                            Use this Page to help you with sending report to students & parents. <br />
                            It is recommended to report on a quarterly basis.
                        </p>
                    </div>
                </>
            ),
        },
        {
            id: "2",
            title: "How is this page useful? ",
            content: (
                <>
                    <div>
                        <ul style={{ listStyle: "disc", paddingLeft: "10px", marginLeft: "10px" }}>
                            <li>Edit Mentor’s General Comment</li>
                            <li>Mass send email to parents & students</li>
                        </ul>
                    </div>
                </>
            ),
        },
    ],
};

const buttons = [
    {
        id: "1",
        tooltip: true,
        tooltipContent: (
            <div style={{ padding: "10px" }}>
                <p style={{ fontWeight: "700" }}>📂 Chumbaka Partner Portal - 6. Operation - 4. Closing </p>
                <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                    <li>Management Review</li>
                </ul>
            </div>
        ),
        type: "notion",
        link: "https://chumbaka.notion.site/4-Closing-Open-Day-Digital-Badges-6d0c7f4c6d3a4a968d9bc32cb7476e51?pvs=4",
        label: "Notion",
    },
    {
        id: "2",
        tooltip: true,
        tooltipContent: (
            <div style={{ padding: "10px" }}>
                <p style={{ fontWeight: "700" }}>📂 Chumbaka Mentor Portal - 4. Closing</p>
                <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                    <li>Digital Badge Criteria</li>
                    <li>Guide to create/update portfolio</li>
                    <li>Open Day Feedback form</li>
                </ul>
            </div>
        ),
        type: "drive",
        link: "https://drive.google.com/drive/u/0/folders/1p-exsrgkILH0-54Y1wKSqHCxtZTke9C5",
        label: "Google Drive",
    },
];

export default {
    info,
    buttons,
};
