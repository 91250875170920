import React from "react";

const info = {
    heading: <></>,
    contents: [
        {
            id: "1",
            title: "What is 3. Delivery-Course Access stage?",
            content: (
                <>
                    <div>
                        <p>Delivery is about fulfilling your obligation to students and their parents.</p>
                        <p>Here in Course Access, you control which LMS course becomes available to the students. </p>
                    </div>
                </>
            ),
        },
        {
            id: "2",
            title: "How is this page useful? ",
            content: (
                <>
                    <div>
                        <ul style={{ listStyle: "disc", paddingLeft: "10px", marginLeft: "10px" }}>
                            <li>Activate the intended course(s) here to enable your student(s) the access to course content on LMS. </li>
                            <li>
                                When activated, the student and his/her parent will receive an email from Chumbaka LMS{" "}
                                <a href="mailto:lms@mail.chumbaka.asia" target="_blank">
                                    {"<lms@mail.chumbaka.asia>"}
                                </a>{" "}
                                to direct to the LMS Course link.
                            </li>
                            <li>You may assign mentor(s) to all the students in this class.</li>
                            <li>You may also assign individual mentor to individual student.</li>
                            <li>When a student decided to withdraw from the class, you may use the “unenrol” the student.</li>
                        </ul>
                    </div>
                </>
            ),
        },
        {
            id: "3",
            title: "Activity Report of Course Access ",
            content: (
                <>
                    <div>
                        <ul style={{ listStyle: "disc", paddingLeft: "10px", marginLeft: "10px" }}>
                            <li>
                                Every time you activate Course Access for a student, we will charge Partner based on the activation and License To Use
                                (LTU).{" "}
                            </li>
                            <li>Please ensure that information here is true & updated to the latest.</li>
                            <li>We will base our billing to you on this.</li>
                            <li>
                                Before the 30th of every month, you need to:
                                <ul style={{ listStyle: "disc", paddingLeft: "10px", marginLeft: "10px" }}>
                                    <li>
                                        Check & verify{" "}
                                        <a href="https://sms.chumbaka.asia/report_ltu" target="_blank">
                                            SMS - Report of LTU
                                        </a>
                                    </li>
                                    <li>
                                        Remark activation for our adjustment on{" "}
                                        <a href="https://sms.chumbaka.asia/course_access_report" target="_blank">
                                            SMS-Report of Course Access
                                        </a>
                                    </li>
                                    <li>Before 5th of the following month, we will send an invoice to collect payment</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </>
            ),
        },
    ],
};

const buttons = [
    {
        id: "2",
        tooltip: true,
        tooltipContent: (
            <div style={{ padding: "10px" }}>
                <p style={{ fontWeight: "700" }}>📂 Chumbaka Mentor Portal - 3. Delivery</p>
                <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                    <li>Class Attendance and Reporting Manual Tracker (Template)</li>
                    <li>Class Norms for Day 1</li>
                    <li>Reporting Bug</li>
                    <li>Walkthrough LMS</li>
                    <li>Course Access Report Examples</li>
                </ul>
            </div>
        ),
        type: "drive",
        link: "https://drive.google.com/drive/u/0/folders/152jmBqjA8L79qHhxw2bkSPZzBwY_RZy3",
        label: "Google Drive",
    },
];

export default {
    info,
    buttons,
};
