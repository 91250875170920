/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';
import _toString from 'lodash/toString';

import ModalView from '../../components/ModalView';
import Table from '../../components/Table';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';
import FormDatePicker from '../../components/FormDatePicker';
import FormCheckbox from '../../components/FormCheckbox';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue, compareString } from '../../helpers/data';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getAPIErrorMessage } from '../../helpers/action';
import { getCourseBadges, getFeedbackformFromTrainee } from '../../helpers/showcase';

import { InverseButton, GreyButton } from '../../styles/button';
import { WarningBox } from '../../styles/message';
import { WrapWord } from '../../styles/misc';

import { ENROLLMENT_STATUS_OPTIONS } from '../../constants';

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
    border: 2px solid #212121;
`;

class MassApproveBadge extends React.Component {

    utcOffSet = 8;
    
    state = {
        modalData: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { open } = this.props;
        if ( open && !prevProps.open ) {
            this.setState({ modalData: this.getInitialModalData() });
        } // end - open
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose )
            onClose();
    }

    handleUpdate = () => {
        const { schedule, authData, onUpdate } = this.props;
        const { modalData } = this.state;
        let error = false,
            eligibleStudents = this.getEligibleStudents();

        if ( !( eligibleStudents && isArrayExists( eligibleStudents ) ) )
            error = 'No eligible student(s) found for the selected course.';

        if ( !( modalData && modalData.lms_course_id && !_isEmpty( modalData.lms_course_id ) ) )
            error = 'Please select a course';

        if ( error ) {
            triggerErrorAlert(error);
        } else if ( onUpdate ) {
            onUpdate(this.updateTrainees(eligibleStudents));
            this.handleClose();
        } 
    }
    
    handleFormUpdate = (newValue,key,subkey,subVal) => {
        const { modalData } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} );
        switch( key ) {
            case 'action':
                newData[key] = newValue
                break;
            default:
                if ( subkey && ( subkey === 'checked' || subkey === 'unchecked' ) && subVal && !_isEmpty( subVal ) ) {
                    // use subVal as the new value
                    newData[key] = subVal;
                } else {
                    // otherwise - just update normally
                    newData[key] = newValue
                }
                break;
        } // end - key
        
        this.setState({ modalData: newData })
    }

    updateTrainees = (students) => {
        const { trainees, courses, schedule, badges } = this.props;
        const { modalData } = this.state;
        const courseBadges = getCourseBadges({ lms_course_id: ( modalData && modalData.lms_course_id || '' ), schedule, badges });
        let newTrainees = ( trainees && isArrayExists( trainees ) ? cloneCollections( trainees ) : [] );
        _forEach( students, student => {
            let index = ( trainees && isArrayExists( trainees ) ? _findIndex( trainees, { id: student.id } ) : -1 );
            if ( index >= 0 ) {
                let showcaseIndex = ( newTrainees[index].showcase && isArrayExists( newTrainees[index].showcase ) ? _findIndex( newTrainees[index].showcase, (c) => compareString( c.id, modalData.lms_course_id ) ) : -1 );
                if ( showcaseIndex >= 0 ) {
                    newTrainees[index].showcase[showcaseIndex].badge_id = getSelectedValue(courseBadges,newTrainees[index].showcase[showcaseIndex].badge_type,'type','id','');
                }
            } // end - index
        });
        return newTrainees;
    }

    // getSelectedTraineeFromShowcase = (trainee) => {
    //     const { showcase } = this.props;
    //     return ( trainee && trainee.id && !_isEmpty(trainee.id) && showcase && showcase.trainees && isArrayExists( showcase.trainees ) ? _find( showcase.trainees, { id: trainee.id }) : false );
    // }

    // getSelectedTraineeCourseFromShowcase = (trainee,course_id) => {
    //     let traineeData = this.getSelectedTraineeFromShowcase(trainee);
    //     return ( traineeData && traineeData.courses && isArrayExists( traineeData.courses ) ? _find( traineeData.courses, c => _toString( c.id ) === _toString( course_id ) ) : false );
    // }

    // getFeedbackformFromTrainee = (trainee,course_id) => {
    //     let course = this.getSelectedTraineeCourseFromShowcase(trainee,course_id);
    //     return ( course && course.feedback_form_answers && isArrayExists( course.feedback_form_answers ) ? course.feedback_form_answers : false );
    // }

    // getCourseBadges = (lms_course_id) => {
    //     const { badges, schedule } = this.props;
    //     let course = ( schedule && schedule.course_access && isArrayExists( schedule.course_access ) && lms_course_id ? _find( schedule.course_access, (c) => compareString( c.lms_course_id, lms_course_id ) ) : false );
    //     return ( course && course.id && badges && isArrayExists( badges ) ? _filter( badges, b => b.references && compareString( b.references.course_id, course.id ) ? true : false ) : [] );
    // }

    getEligibleStudents = () => {
        const { badges, schedule, showcase } = this.props;
        const { modalData } = this.state;
        const coursebadges = getCourseBadges({ lms_course_id: ( modalData && modalData.lms_course_id || '' ), schedule, badges });
        const students = this.getSelectedTrainees();
        let list = [];
        if ( students && isArrayExists( students ) ) {
            _forEach( students, student => {
                let selected = ( student.showcase && isArrayExists( student.showcase ) ? _find( student.showcase, (c) => compareString( c.id, modalData.lms_course_id ) ) : false );
                const feedbackForm = getFeedbackformFromTrainee({ trainee: student, lms_course_id: modalData.lms_course_id, showcase });
                if ( 
                    selected && selected.badge_type && !_isEmpty( selected.badge_type ) && 
                    !( selected.badge_id && !_isEmpty( selected.badge_id ) ) 
                    // && feedbackForm // make sure feedback form is there
                ) {
                    list.push({
                        id: student.id,
                        name: ( student.name || '' ),
                        email: ( student.email || '' ),
                        nominated_badge: getSelectedValue( coursebadges, selected.badge_type,'type','type_label',''),
                    });
                } // end - selected
            });
        } // end - students
        return list;
    }

    getSelectedTrainees = () => {
        const { trainees, selected } = this.props;
        return ( trainees && isArrayExists( trainees ) ? _filter( trainees, t => {
            return ( t && t.id && _find( selected, { id: t.id } ) ? true : false );
        }) : [] );
    }

    getCourseOptions = () => {
        const { schedule, courses, lms_courses } = this.props;
        let options = [];
        if ( schedule && schedule.course_access && isArrayExists( schedule.course_access ) ) {
            _forEach( schedule.course_access, course => {
                let selected = ( courses && isArrayExists( courses ) ? _find( courses, { id: course.id } ) : false ),
                    name = ( course.name &&  !_isEmpty( course.name ) ? course.name : ( selected && selected.name || '' ) ),
                    code = ( course.course_code &&  !_isEmpty( course.course_code ) ? course.course_code : ( selected && selected.course_code || '' ) );
                if ( course && course.lms_course_id ) {
                    options.push({
                        value: _toString( course.lms_course_id ),
                        label: ( code && !_isEmpty( code ) ? '['+code+'] ' : '' ) + ( name || '' )
                    });
                } // end - selected
            });
        } // end - schedule.course_access
        return [{ value: '', label: 'Select a Course' }, ..._sortBy( options, [ o => o.label.toLowerCase()] ) ];
    }

    getInitialModalData = () => {
        return {
            lms_course_id: ''
        };
    }

    renderModalForm = () => {
        const { courses, lms_courses, trainees } = this.props;
        const { modalData } = this.state;
        const eligibleStudents = this.getEligibleStudents();
        return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormSelect
                        label="Course"
                        name="lms_course_id"
                        value={( modalData && modalData.lms_course_id || '' )}
                        options={this.getCourseOptions()}
                        onChange={this.handleFormUpdate} />
                </Grid>
                { modalData && modalData.lms_course_id && !_isEmpty( modalData.lms_course_id ) ? (
                <>
                    <Grid item xs={12}>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">Eligible Student(s)</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Table
                            items={eligibleStudents}
                            showCheckbox={false}
                            emptyCell="No eligible student(s) found for this course."
                            cells={[
                                { id: 'email', label: 'Email', render: (item) => <WrapWord>{( item.email || '-' )}</WrapWord> },
                                { id: 'name', label: 'Name', render: (item) => <WrapWord>{( item.name || '-' )}</WrapWord> },
                                { id: 'nominated_badge', label: 'Nominated Badge', render: (item) => <WrapWord>{( item.nominated_badge || '-' )}</WrapWord>}
                            ]} />
                    </Grid>
                </>
                ) : null }
            </Grid>
        </>
        );
    }

    render() {
        const { open, schedule } = this.props;
        const { modalData } = this.state;
        return (
        <>
            <ModalView 
                open={open}
                title={"Mass Approve Nominated Badges"}
                onClose={this.handleClose}
                disableBackdrop={true}
                noAction={( modalData && modalData.lms_course_id && !_isEmpty( modalData.lms_course_id ) ? false : true )}
                actionLabel="Update"
                maxWidth="md"
                doAction={this.handleUpdate}
                contents={this.renderModalForm()} />

        </>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(MassApproveBadge);