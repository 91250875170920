/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_TRAINEES,
	GET_CLASS_SHOWCASE,
	UPDATE_CLASS_SHOWCASE,
	UPDATE_SCHEDULE_RAND,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getClassShowcase, fb_updateClassShowcase, fb_importClassShowcase, fb_forceIssueBadge } from './fb';
import { fb_getTrainees } from '../trainees/fb';

export const getClassShowcase = (id) => {
	return dispatch => {

		fb_getClassShowcase(id)
		.then(showcase => {
			dispatch({
				type: GET_CLASS_SHOWCASE,
				payload: { showcase }
			});	
		})
		.catch(error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_CLASS_SHOWCASE,
				payload: { showcase: {} }
			});
		});

	}
}


export const updateClassShowcase = (formData,props) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateClassShowcase(formData)
		.then( results => {
			let promises = [];
			promises.push( fb_getTrainees(props) );
			promises.push( fb_getClassShowcase(props.schedule_id) );
			
			Promise.all( promises )
			.then(results => {
				// push new data
				dispatch({ type: GET_CLASS_SHOWCASE, payload: { showcase: results && results[1] || {} } });	
				dispatch({ type: GET_TRAINEES, payload: { list: results && results[0] || [] } });
				// updated
				dispatch({ type: UPDATE_CLASS_SHOWCASE });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Data Updated");
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const importClassShowcase = (formData,props) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));
		dispatch(toggleLoader(true,"Importing process is on-going! Please do not close this window or click the Back button on your browser"));

		fb_importClassShowcase(formData)
		.then( results => {
			let promises = [];
			promises.push( fb_getClassShowcase(props.schedule_id) );
			promises.push( fb_getTrainees(props) );
			return Promise.all( promises );
		})
		.then(results => {
			// push new data
			dispatch({ type: GET_CLASS_SHOWCASE, payload: { showcase: ( results && results[0] || {} ) } });
			dispatch({ type: GET_TRAINEES, payload: { list: ( results && results[1] || [] ) } });
			dispatch({ type: UPDATE_SCHEDULE_RAND });	
			dispatch(toggleModalProcessing(false));
			dispatch(toggleLoader(false));
			triggerSuccessAlert("Data Updated");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}


export const forceIssueBadge = (formData,props) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_forceIssueBadge(formData)
		.then( results => {
			let promises = [];
			promises.push( fb_getTrainees(props) );
			promises.push( fb_getClassShowcase(props.schedule_id) );
			
			Promise.all( promises )
			.then(results => {
				// push new data
				dispatch({ type: GET_CLASS_SHOWCASE, payload: { showcase: results && results[1] || {} } });	
				dispatch({ type: GET_TRAINEES, payload: { list: results && results[0] || [] } });
				// updated
				dispatch({ type: UPDATE_CLASS_SHOWCASE });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Badge issuing process started! It will take a few minutes to complete before you can see the changes.",4000);
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}