/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import Papa from "papaparse";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _sortBy from "lodash/sortBy";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _remove from "lodash/remove";
import _toLower from "lodash/toLower";

import ModalView from "../../components/ModalView";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormRadio from "../../components/FormRadio";
import FormDatePicker from "../../components/FormDatePicker";
import FormCheckbox from "../../components/FormCheckbox";
import ToolTipInfo from "../../components/ToolTipInfo";

import { isArrayExists, isObjectExists } from "../../helpers/validation";
import { cloneCollections, getSelectOptions, getSelectedValue } from "../../helpers/data";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import { getMomentTime } from "../../helpers/date";
// import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';

import { toggleLoader } from "../../actions/global";

import { InfoButton } from "../../styles/button";
import { WarningBox } from "../../styles/message";

import { ENROLLMENT_STATUS_OPTIONS } from "../../constants";

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
    border: 2px solid #212121;
`;

class StudentClassTransfer extends React.Component {
    utcOffSet = 8;

    state = {
        open: false,
        modalData: false,
        confirmation: false,
    };

    doCSVDownload = (results) => {
        let csv = Papa.unparse(results, { delimiter: "," });
        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" }); //new way
        let uri = window.URL.createObjectURL(blob);
        let link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", "_blank");
        link.setAttribute("download", "student_attendance_data.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    handleDataDownload = () => {
        const { trainee, schedule, regions } = this.props;
        let csvData = {
            fields: ["partner", "Class Name", "email", "name", "school"],
            data: [],
        };

        csvData.data.push(getSelectedValue(regions, trainee.region, "id", "label", "-"));
        csvData.data.push(schedule.class_name);
        csvData.data.push(trainee.email || "");
        csvData.data.push(trainee.name || "");
        csvData.data.push(trainee.org || "");

        if (trainee.attendances && isArrayExists(trainee.attendances)) {
            trainee.attendances.forEach((item) => {
                const session =
                    schedule.class_calendar && isArrayExists(schedule.class_calendar) ? _find(schedule.class_calendar, { id: item.id }) : false;
                if (session) {
                    csvData.fields.push(getMomentTime(session.start_date, "YYYY-MM-DD") + " - Attendance");
                    csvData.data.push(item.attend && item.attend === "yes" ? "yes" : "no");
                    csvData.fields.push(getMomentTime(session.start_date, "YYYY-MM-DD") + " - Notes");
                    csvData.data.push(item.notes || "");
                }
            });
        }

        this.doCSVDownload(csvData);
    };

    handleConfirmation = () => {
        const { trainee, schedule, onTrigger } = this.props;
        const { modalData } = this.state;

        const formData = {
            trainee_id: trainee.id,
            schedule_id: modalData.schedule_id,
            remark: modalData.remark || "",
        };

        this.setState({ open: false, modalData: false, confirmation: false });
        onTrigger(formData);
    };

    handleUpdate = () => {
        const { modalData } = this.state;
        let error = false;
        // make sure a class is selected
        if (!(modalData && modalData.schedule_id && !_isEmpty(modalData.schedule_id))) error = "Please select a class.";

        if (error) {
            triggerErrorAlert(error);
        } else {
            this.setState({ confirmation: true });
        }
    };

    handleFormUpdate = (newValue, key, subkey, subVal) => {
        const { modalData } = this.state;
        let newData = modalData ? cloneCollections(modalData) : {};
        switch (key) {
            default:
                if (subkey && (subkey === "checked" || subkey === "unchecked") && subVal && !_isEmpty(subVal)) {
                    // use subVal as the new value
                    newData[key] = subVal;
                } else {
                    // otherwise - just update normally
                    newData[key] = newValue;
                }
                break;
        } // end - key

        this.setState({ modalData: newData });
    };

    handleOpen = (event) => {
        const { changesMade } = this.props;
        event.preventDefault();
        if (changesMade) {
            triggerErrorAlert("Please save your changes before proceeding.");
        } else {
            this.setState({
                open: true,
                modalData: {
                    schedule_id: "",
                },
            });
        }
    };

    getClassName = () => {
        const { modalData } = this.state;
        const options = this.getScheduleOptions();
        const selected = modalData && modalData.schedule_id && options ? _find(options, { value: modalData.schedule_id }) : false;
        return selected ? selected.label : "";
    };

    getScheduleOptions = () => {
        const { schedule, regionsList, schedulesList } = this.props;
        let options = [];
        if (schedule && regionsList && schedulesList) {
            const region = _find(regionsList, { id: schedule.region });
            const schedules = region
                ? _filter(schedulesList, (s) => {
                      let valid = false;
                      // make sure the schedule is in the same region
                      if (s.region && s.region === region.id) {
                          // make sure the scheduled is from the same program
                          if (
                              s.is_program_schedule &&
                              s.is_program_schedule === "yes" &&
                              schedule.is_program_schedule &&
                              schedule.is_program_schedule === "yes"
                          ) {
                              if (s.program_id === schedule.program_id) {
                                  valid = true;
                              }
                          } else {
                              if (
                                  s.course_id &&
                                  !_isEmpty(s.course_id) &&
                                  schedule.course_id &&
                                  !_isEmpty(schedule.course_id) &&
                                  s.course_id === schedule.course_id
                              ) {
                                  valid = true;
                              }
                          }
                      } // end region
                      return valid;
                  })
                : [];
            // remove the current schedule from the options
            if (schedules && isArrayExists(schedules)) {
                _forEach(schedules, (item) => {
                    if (item && item.id !== schedule.id) {
                        options.push({ label: item.class_name, value: item.id });
                    }
                });
            } // end - schedules
        }
        return options;
    };

    renderForm = () => {
        const { history } = this.props;
        const { modalData } = this.state;
        return (
            <div>
                <WarningBox style={{ margin: "10px 0" }}>
                    <i className="fa fa-exclamation-triangle"></i>WARNING:
                    <br />
                    <ol style={{ paddingLeft: "10px", listStyle: "numeric" }}>
                        <li>
                            Data of class attendance & reporting will NOT be transferred over. Please download the data and archive it by yourself.{" "}
                        </li>
                        <li>An email will be sent out to the parents & students.</li>
                        <li>This action cannot be reversed.</li>
                    </ol>
                </WarningBox>
                <div style={{ padding: "10px 8px" }}>
                    <Typography variant="h6" style={{ marginBottom: "10px", fontWeight: "700" }}>
                        Step #1:
                    </Typography>
                    <InfoButton size="small" style={{ padding: "4px 6px" }} onClick={this.handleDataDownload}>
                        <i className="fa fa-cloud-down"></i>Download Attendance Data
                    </InfoButton>
                </div>
                <div style={{ padding: "10px 8px" }}>
                    <Typography variant="h6" style={{ marginBottom: "15px", fontWeight: "700" }}>
                        Step #2: Select New Class
                        <ToolTipInfo
                            content={
                                <>
                                    If you cannot find a class in the list, that is because the class has a different program (with a different blend
                                    of courses) compared to this class’ Program. Find out more about the Program courses{" "}
                                    <a href="https://sms.chumbaka.asia/programs" target="_blank">
                                        here
                                    </a>
                                    <br />
                                    <br />
                                    To rectify: <br />
                                    <ol
                                        style={{
                                            listStyle: "decimal",
                                            marginLeft: "15px",
                                        }}
                                    >
                                        <li>Download this student’s data in CSV format</li>
                                        <li>Bulk upload this student’s data in Registeration of the new Class. </li>
                                        <li>
                                            [Optional] Unenrol the student from this class (i.e. this student’s activated courses in the class will
                                            all be deactivated).{" "}
                                        </li>
                                    </ol>
                                </>
                            }
                        />
                    </Typography>
                    <FormSelect
                        label="Select New Class"
                        name="schedule_id"
                        value={modalData && modalData.schedule_id ? modalData.schedule_id : ""}
                        options={[{ value: "", label: "Select an Option" }, ...this.getScheduleOptions()]}
                        onChange={this.handleFormUpdate}
                    />
                </div>
                <div style={{ padding: "10px 8px" }}>
                    <Typography variant="h6" style={{ marginBottom: "10px", fontWeight: "700" }}>
                        Step #3: Remark for reference
                    </Typography>
                    <FormInput
                        label="Remarks (Max 500 characters)"
                        name="remark"
                        value={modalData && modalData.remark ? modalData.remark : ""}
                        rows={5}
                        multiline={true}
                        onChange={this.handleFormUpdate}
                    />
                </div>
            </div>
        );
    };

    render() {
        const { trainee } = this.props;
        const { open, confirmation } = this.state;
        return (
            <>
                <div style={{ padding: "10px 0 0 0" }}>
                    <InfoButton size="small" style={{ padding: "4px 6px" }} onClick={this.handleOpen}>
                        <i className="fa fa-random"></i>Change Class
                    </InfoButton>
                </div>

                <ModalView
                    open={open}
                    title={`Transfer ${(trainee && trainee.name) || ""} to a Different Class`}
                    onClose={() => this.setState({ open: false, modalData: false })}
                    disableBackdrop={true}
                    actionLabel="Transfer"
                    maxWidth="xs"
                    doAction={this.handleUpdate}
                    contents={this.renderForm()}
                />

                <ModalView
                    open={confirmation}
                    title={`Please confirm your action`}
                    onClose={() => this.setState({ open: false, modalData: false, confirmation: false })}
                    disableBackdrop={true}
                    actionLabel="Confirm"
                    maxWidth="md"
                    doAction={this.handleConfirmation}
                    contents={
                        <>
                            <Typography variant="h5" style={{ marginBottom: "15px" }}>{`You're about to transfer "${
                                (trainee && trainee.name) || ""
                            }" to "${this.getClassName()}"`}</Typography>
                            <WarningBox style={{ marginBottom: "10px" }}>
                                <i className="fa fa-exclamation-triangle"></i>WARNING:
                                <br />
                                <ol style={{ paddingLeft: "10px", listStyle: "numeric" }}>
                                    <li>
                                        Data of class attendance & reporting will NOT be transferred over. Please download the data and archive it by
                                        yourself.{" "}
                                    </li>
                                    <li>An email will be sent out to the parents & students.</li>
                                    <li>This action cannot be reversed.</li>
                                </ol>
                            </WarningBox>
                            {/* <Typography variant="body1">{`This action cannot be reversed.`}</Typography> */}
                        </>
                    }
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        schedulesList: state.schedules && state.schedules.schedules_options ? state.schedules.schedules_options : false,
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(StudentClassTransfer);
