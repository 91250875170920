/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Papa from 'papaparse';
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import moment from 'moment';
import shortid from 'shortid';
import blue from '@material-ui/core/colors/blue';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dropzone from 'react-dropzone';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';
import _toString from 'lodash/toString';
import _reverse from 'lodash/reverse';
import _trim from 'lodash/trim';

import ModalView from '../../components/ModalView';
import TableBar from '../../components/TableBar';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import DotsLoader from '../../components/DotsLoader';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton, WarningButton } from '../../styles/button';
import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from '../../styles/tag';
import { InfoBox, SuccessBox } from '../../styles/message';
import { WrapWord } from '../../styles/misc';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists, isObjectExists, validate } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, convertArrayToObject, convertObjectToArray, replaceAll, doPagination, doArraySearch, getSelectedValue, trimEmail, compareString } from '../../helpers/data';
import { isSkip, doSchemaErrorCheck } from '../../helpers/schemas';
import { getMomentTime, getAge } from '../../helpers/date';
import { getAPIErrorMessage } from '../../helpers/action';

import { importClassShowcase } from '../../actions/class_showcase'
import { toggleLoader } from '../../actions/global';
import { appChangesMade } from '../../actions/misc';

import { TEMPLATE_OPTIONS_AGREE, TEMPLATE_OPTIONS_HIGH, TEMPLATE_OPTIONS_GOOD, ENROLLMENT_STATUS_OPTIONS } from '../../constants';

const ReconWrapper = styled.div`
    h5 { display: none; }
`;

const useStyles = theme => ({
    tooltip: {
        fontSize: '14px',
    },
    emailModal: {
        '& .MuiFilledInput-input.Mui-disabled, & .MuiInputBase-root.Mui-disabled, & .MuiFormLabel-root.Mui-disabled': {
            background: '#f1f1f1',
            color: '#212121'
        }
    }
});

class ImportModal extends React.Component {

    utcOffSet = 8;
    
    state = {
        lms_course_id: '',
        list: false,
        searchterms: '',
        filterBy: 'all',
        sortBy: 'email-asc',
        perPage: 20,
        page: 1,
        dataLoaded: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { open } = this.props;
        if ( open && !prevProps.open ) {
            this.setState({ lms_course_id: '', list: false });
        } // end - open
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose )
            onClose();
    }

    doCSVDownload = (results) => {
        let csv = Papa.unparse( results, { delimiter: ',' } );
        let blob = new Blob( [csv] , { type: 'text/csv;charset=utf-8;' }); //new way
        let uri = window.URL.createObjectURL(blob);
        let link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", '_blank');
        link.setAttribute("download", "class_showcase_sample.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    handleImport = () => {
        const { schedule } = this.props;
        const { list, lms_course_id } = this.state;
        let error = false,
            formData = {
                schedule_id: ( schedule && schedule.id || '' ),
                trainees: []
            };

        if ( !( schedule && schedule.id && !_isEmpty( schedule.id ) ) )
            error = 'Unknown Error - Please refresh your browser and try again.';

        if ( !( list && isArrayExists( list ) ) )
            error = 'Please import at least one student';

        // make sure all the trainees have all the required data
        if ( list && isArrayExists( list ) ) {
            _forEach( list, item => {
                formData.trainees.push({ 
                    id: item.id,
                    lms_course_id: _toString( lms_course_id ),
                    showcase: ( item.showcase && isArrayExists( item.showcase ) ? cloneCollections( item.showcase ) : [] ),
                    feedback_form_answers: ( item.feedback_form_answers && isArrayExists( item.feedback_form_answers ) ? cloneCollections( item.feedback_form_answers ) : [] )
                });
            });
        } // end - list

        if ( error ) {
            triggerErrorAlert( error );
        } else {
            this.props.dispatch( importClassShowcase(formData,{ 
                schedule_id: schedule.id,
                enrollment_status: this.getEnrollmentStatusFilter() 
            }) );
        } // end - error

    }

    handleFileUpload = (files) => {
        if ( files && files[0] && !_isEmpty( files[0] ) ) {
            Papa.parse(files[0], {
                header: true,
                complete: (results) => {
                    const list = this.compileTraineesData(( results && results.data || false ));
                    if ( _size( list ) > 100 ) {
                        triggerErrorAlert("Please upload only 100 students at a time");
                    } else {
                        this.setState({ list });
                    } // end - list
                }
            });
        } else {
            triggerErrorAlert('Please upload a valid CSV file');
        }
    }

    handleCSVDownload = (event) => {
        event.preventDefault();
        const badges = this.getCourseBadges();
        const students = this.getEligibleStudents();
        const fields = this.getSelectedFeedbackFields();
        let csvData = {
                fields: [
                    "name",
                    "email",
                    "project_feedback",
                    "nominated_badge",
                ],
                data: []
            };

        if ( fields && isArrayExists( fields ) ) {
            _forEach( fields, field => {
                if ( field && field.type && field.type !== 'section_title' ) {
                    csvData.fields.push( ( field.question && !_isEmpty( field.question ) ? field.question : '' ) );
                }
            })
        } // end - fields

        if ( students && isArrayExists( students ) ) {
            _forEach( students, student => {
                let row = [];
                
                row.push( ( student.name || '' ) );
                row.push( ( student.email || '' ) );

                let selected_showcase = this.getSelectedTraineeShowcase( student );
                row.push( ( selected_showcase && selected_showcase.project_feedback || '' ) );
                row.push( _toLower( getSelectedValue( badges, ( selected_showcase && selected_showcase.badge_type || '' ), 'type', 'type_label', '' ) ) );

                if ( fields && isArrayExists( fields ) ) {
                    let answers = this.getStudentFeedbackAnswers( student );
                    _forEach( fields, field => {
                        if ( field && field.type && field.type !== 'section_title' ) {
                            let answer = getSelectedValue( answers, field.id, 'id', 'value', '' );
                            row.push( ( answer || '' ) );
                        } // end - field
                    })
                } // end - fields

                csvData.data.push( row );
            });
        } // end - students
        this.doCSVDownload(csvData);
    }

    getEnrollmentStatusFilter = () => {
        let statuses = [];
        ENROLLMENT_STATUS_OPTIONS.forEach(option => {
            if ( option.value && !_isEmpty( option.value ) && ( option.value !== 'delete' && option.value !== 'registered' && option.value !== 'trial' ) )
                statuses.push( option.value );
        });
        return statuses
    }

    getStudentFeedbackAnswers = (trainee) => {
        const { showcase } = this.props;
        const { lms_course_id } = this.state;
        let selectedTrainee = ( showcase && showcase.trainees && isArrayExists( showcase.trainees ) && trainee && trainee.id && !_isEmpty( trainee.id ) ? _find( showcase.trainees, { id: trainee.id } ) : false ),
            selectedCourse = ( selectedTrainee && selectedTrainee.courses && isArrayExists( selectedTrainee.courses ) && lms_course_id && !_isEmpty( lms_course_id ) ? _find( selectedTrainee.courses, c => compareString(c.id,lms_course_id) ) : false );
        return ( selectedCourse && selectedCourse.feedback_form_answers && isArrayExists( selectedCourse.feedback_form_answers ) ? selectedCourse.feedback_form_answers : [] );
    }

    getSelectedTraineeShowcase = (trainee) => {
        const { lms_course_id } = this.state;
        return ( trainee && trainee.showcase && isArrayExists( trainee.showcase ) ? _find( trainee.showcase, (c) => compareString( c.id, lms_course_id ) ) : false );
    }

    getEligibleStudents = () => {
        const { trainees } = this.props;
        const { lms_course_id } = this.state;
        const badges = this.getCourseBadges();
        let list = [];
        if ( trainees && isArrayExists( trainees ) ) {
            _forEach( trainees, trainee => {
                let found = ( trainee.course_access && isArrayExists( trainee.course_access ) ? _find( trainee.course_access, (c) => compareString( c.id, lms_course_id ) ) : false );
                if ( found ) {
                    list.push( trainee );
                } // end - selected
            });
        } // end - trainees
        return list;
    }

    getSelectedFeedbackFields = () => {
        const { schedule, courses, feedback_forms_fields } = this.props;
        const { lms_course_id } = this.state;
        let fields = [],
            course = ( courses && isArrayExists( courses ) ? _find( courses, (c) => compareString( c.lms_course_id, lms_course_id ) ) : false );
        if ( course && course.feedback_form_id && !_isEmpty( course.feedback_form_id ) ) {
            let form = ( feedback_forms_fields && isArrayExists( feedback_forms_fields ) ? _find( feedback_forms_fields, { id: course.feedback_form_id } ) : false );
            if ( form && form.fields && isArrayExists( form.fields ) ) {
                fields = _sortBy( cloneCollections( form.fields ), ['position'] );
            }
        } // end - course
        return fields;
    }

    compileTraineesData = (data) => {
        const { schedule, trainees, showcase, authData } = this.props;
        const { lms_course_id } = this.state;
        const badges = this.getCourseBadges();
        const fields = this.getSelectedFeedbackFields();
        let list = [];
        if ( data && isArrayExists( data ) ) {
            _forEach( data, item => {
                let trainee = ( trainees && isArrayExists( trainees ) && item.email && validate( item.email, 'email' ) ? _find( trainees, { email: trimEmail( item.email ) } ) : false );
                // trainee exists
                if ( trainee ) {
                    const answers = this.getStudentFeedbackAnswers(trainee);
                    let newTraineeData = cloneCollections( trainee ),
                        selected_badge = ( item.nominated_badge && !_isEmpty( item.nominated_badge ) ? _find( badges, b => _toLower( _trim( b.type_label ) ) === _toLower( _trim( item.nominated_badge ) ) ) : false );
                    let showcaseIndex = ( newTraineeData.showcase && isArrayExists( newTraineeData.showcase ) ? _findIndex( newTraineeData.showcase, c => compareString( c.id, lms_course_id ) ) : -1 );

                    // update project feedback
                    if ( showcaseIndex > -1 ) {
                        newTraineeData.showcase[showcaseIndex].project_feedback = ( item.project_feedback && !_isEmpty( item.project_feedback ) ? item.project_feedback : '' );
                    }

                    // update showcase
                    if ( selected_badge && selected_badge.type && !_isEmpty( selected_badge.type ) ) {
                        if ( showcaseIndex >= 0 ) {
                            if ( selected_badge && selected_badge.type && !_isEmpty( selected_badge.type ) ) {
                                newTraineeData.showcase[showcaseIndex].score = 'pass';
                                newTraineeData.showcase[showcaseIndex].badge_type = selected_badge.type;
                            } // end - selected_badge
                        } else {
                            if ( !( newTraineeData.showcase && isArrayExists( newTraineeData.showcase ) ) )
                                newTraineeData.showcase = [];
    
                            newTraineeData.showcase.push({
                                id: lms_course_id,
                                project_feedback: ( item.project_feedback && !_isEmpty( item.project_feedback ) ? item.project_feedback : '' ),
                                score: ( selected_badge && selected_badge.type && !_isEmpty( selected_badge.type ) ? 'pass' : '' ),
                                badge_id: '',
                                badge_type: ( selected_badge && selected_badge.type && !_isEmpty( selected_badge.type ) ? selected_badge.type : '' ),
                            });
                        }
                    } // end - selected_badge
                    
                    // update feedback form fields
                    newTraineeData.feedback_form_answers = [];
                    if ( fields && isArrayExists( fields ) ) {
                        let field_count = 3;
                        _forEach( fields, field => {
                            if ( field && field.type && field.type !== 'section_title' ) {
                                let value = ( field.question && !_isEmpty( field.question ) && item[field.question] && !_isEmpty( item[field.question] ) ? item[field.question] : '' );
                                newTraineeData.feedback_form_answers.push({
                                    id: field.id,
                                    value
                                });
                                field_count++;
                            } // end - field
                        })
                    } // end - fields
                    list.push(newTraineeData);
                } // end - trainee
            });
        } // end -data
        return list;
    }

    getCourseBadges = () => {
        const { badges, schedule } = this.props;
        const { lms_course_id } = this.state;
        let course = ( schedule && schedule.course_access && isArrayExists( schedule.course_access ) && lms_course_id ? _find( schedule.course_access, (c) => compareString( c.lms_course_id, lms_course_id ) ) : false );
        return ( course && course.id && badges && isArrayExists( badges ) ? _filter( badges, b => b.references && compareString( b.references.course_id, course.id ) ? true : false ) : [] );
    }

    getCourseOptions = () => {
        const { schedule, courses, lms_courses } = this.props;
        let options = [];
        if ( schedule && schedule.course_access && isArrayExists( schedule.course_access ) ) {
            _forEach( schedule.course_access, course => {
                let selected = ( courses && isArrayExists( courses ) ? _find( courses, { id: course.id } ) : false ),
                    name = ( course.name &&  !_isEmpty( course.name ) ? course.name : ( selected && selected.name || '' ) ),
                    code = ( course.course_code &&  !_isEmpty( course.course_code ) ? course.course_code : ( selected && selected.course_code || '' ) );
                if ( course && course.lms_course_id ) {
                    options.push({
                        value: _toString( course.lms_course_id ),
                        label: ( code && !_isEmpty( code ) ? '['+code+'] ' : '' ) + ( name || '' )
                    });
                } // end - selected
            });
        } // end - schedule.course_access
        return [{ value: '', label: 'Select a Course' }, ..._sortBy( options, [ o => o.label.toLowerCase()] ) ];
    }

    getCells = () => {
        const badges = this.getCourseBadges();
        const fields = this.getSelectedFeedbackFields();
        let cells = [
            { id: 'name', label: '* Name', headstyle: { minWidth: '120px' }, render: (item) => <WrapWord>{( item.name || '' )}</WrapWord> },
            { id: 'email', label: '* Email', headstyle: { minWidth: '120px' }, render: (item) => <WrapWord>{( item.email || '' )}</WrapWord> },
            { id: 'project_feedback', label: 'Project Feedback', headstyle: { minWidth: '120px' }, render: (item) => {
                let showcase = this.getSelectedTraineeShowcase(item);
                return ( showcase && showcase.project_feedback && !_isEmpty( showcase.project_feedback ) ? showcase.project_feedback : '' );
            }},
            { id: 'nominated_badge', label: 'Nominated Badge', headstyle: { minWidth: '120px' }, render: (item) => {
                let showcase = this.getSelectedTraineeShowcase(item);
                return ( showcase && showcase.badge_type && !_isEmpty( showcase.badge_type ) ? getSelectedValue( badges, showcase.badge_type, 'type', 'type_label', '' ) : '' );
            }},
        ];

        if ( fields && isArrayExists( fields ) ) {
            let field_count = 3;
            _forEach( fields, field => {
                if ( field && field.type && field.type !== 'section_title' ) {
                    cells.push({ 
                        id: field.id, 
                        label: ( field.question || '' ),
                        headstyle: { minWidth: '120px' },
                        render: (item) => {
                            let answer = ( item.feedback_form_answers && isArrayExists( item.feedback_form_answers ) ? _find( item.feedback_form_answers, { id: field.id } ) : false );
                            return ( answer && answer.value || '' );
                        }
                    });
                } // end - field
            })
        } // end - fields

        return cells;
    }

    reorganizeItems = () => {
        const { list, searchterms, sortBy, perPage, page, filterBy } = this.state;
        let items = ( list && isArrayExists( list ) ? cloneCollections( list ) : [] ),
            total = _size( items );

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
                case 'email-desc':
                    items = _sortBy( items, ['email'] );
                    items = _reverse( items );
                    break;
                case 'email-asc':
                    items = _sortBy( items, ['email'] );
                    break;
            }
        } // end - sortBy

        return { items, total };
    }


    renderTable = (items) => {
        const { classes } = this.props;
        return (
        <>
            <Table 
                items={items} 
                emptyCell="No data found"
                cells={this.getCells()}
                noAction={true}
                elevation={0}
                 />
            <div style={{ paddingTop: '20px', color: '#9e0000', fontStyle: 'italic' }}>* Required field(s).</div>
        </>
        )
    }

    renderCSVtable = () => {
        const { classes } = this.props;
        const { items, total } = this.reorganizeItems();
        return (
        <Grid container spacing={2}>
            <Grid item xs={12} align="left"><InverseButton style={{ padding: '10px 25px' }} onClick={() => this.setState({ list: false })}><i className="fa fa-refresh"></i>Reset</InverseButton></Grid>
            <Grid item xs={12}>{this.renderTable(items)}</Grid>
        </Grid>
        )
    }

    renderContent = () => {
        const { lms_course_id } = this.state;
        const students = this.getEligibleStudents();
        const badges = this.getCourseBadges();
        return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormSelect
                    label="Course"
                    name="lms_course_id"
                    value={( lms_course_id || '' )}
                    options={this.getCourseOptions()}
                    onChange={(newValue) => this.setState({ lms_course_id: newValue })} />
            </Grid>
            { lms_course_id && !_isEmpty( lms_course_id ) && students && isArrayExists( students ) ? (
            <>
                <Grid item xs={12} align="left">
                    <InfoButton style={{ padding: '10px 25px' }} onClick={this.handleCSVDownload}>Download Sample CSV File</InfoButton>
                </Grid>
                <Grid item xs={12}>
                    { badges && isArrayExists( badges ) && (
                    <SuccessBox>
                        <strong style={{ fontWeight: '700' }}>Available Badge Type For Nomination:</strong>
                        {badges.map(badge => (
                        <div key={badge.id}>
                            {badge.type_label ? _toLower( badge.type_label ) : ''}
                        </div>
                        ))}
                    </SuccessBox>
                    )}
                    <InfoBox>
                        <i className='fa fa-lightbulb-o' style={{ marginRight: '5px' }}></i>Max upload 100 students at a time only. Split your CSV file using <a href="https://chumbaka.notion.site/SMS-Using-CSV-Splitter-for-Bulk-Upload-39d184801e854dd393df9f04e9ee4c90" target="_blank">this Notion guide</a>. 
                    </InfoBox>
                </Grid>
                <Grid item xs={12}>
                    <Dropzone onDrop={this.handleFileUpload} accept={".csv"}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ style: {
                            display: "flex",
                            height: '200px', 
                            width: '100%', 
                            border: '2px dashed ' + blue['700'],
                            color: blue['700'],
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        } })}>
                            <input {...getInputProps()} />
                            <div>Drop a CSV file here, or click to select a CSV file to upload</div>
                        </div>
                    )}
                    </Dropzone>
                </Grid>
            </>
            ) : ( lms_course_id && !_isEmpty( lms_course_id ) ? <Typography variant="body1" style={{ padding: '20px 10px 10px 10px' }}>No eligible student(s) found for this course.</Typography> : null ) }
        </Grid>
        )
    }

    render() {
        const { open } = this.props;
        const { dataLoaded, list } = this.state;
        return (
        <>
            <ModalView 
                open={open}
                title={"Upload badge nomination & feedback"}
                maxWidth={( list && isArrayExists( list ) ? 'lg' : 'md' )}
                onClose={this.handleClose}
                cancelLabel="Close"
                disableBackdrop={true}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                noAction={( list && isArrayExists( list ) ? false : true )}
                actionLabel="Import"
                doAction={this.handleImport}
                contents={ list ? this.renderCSVtable() : this.renderContent() } />
        </>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles),
    withRouter
)(ImportModal);