import React from "react";

const info = {
    heading: <></>,
    contents: [
        {
            id: "1",
            title: "What is Marketing Database? ",
            content: (
                <>
                    <div>
                        <ul style={{ listStyle: "disc", paddingLeft: "10px", marginLeft: "10px" }}>
                            <li>Marketing Database is the full database for your school (as a partner) </li>
                            <li>
                                When a student fills up the registration form, and tick ✔ the “I would like to subscribe to Chumbaka's quarterly
                                newsletter.”, their data is automatically populated here.{" "}
                            </li>
                            <li>Meaning, individuals listed here have consented Chumbaka to send them information. </li>
                            <li>You may bulk upload and download new entries here too!</li>
                        </ul>
                    </div>
                </>
            ),
        },
        {
            id: "2",
            title: "How is this page useful?",
            content: (
                <>
                    <div>
                        <ul style={{ listStyle: "disc", paddingLeft: "10px", marginLeft: "10px" }}>
                            <li>When you send out newsletter, you may mass blast newsletter to people on this database. </li>
                            <li>
                                When you explore new area to run classes, you may filter this marketing database to the nearest region/suburb area to
                                share the good news with them
                            </li>
                        </ul>
                    </div>
                </>
            ),
        },
    ],
};

const buttons = [
    {
        id: "1",
        tooltip: true,
        tooltipContent: (
            <div style={{ padding: "10px" }}>
                <p style={{ fontWeight: "700" }}>📂 Chumbaka Partner Portal - 5. Sales & Marketing</p>
                <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                    <li>Our Publicity Paragraph</li>
                    <li>Our Product Lines </li>
                    <li>Tools and Resources</li>
                </ul>
            </div>
        ),
        type: "notion",
        link: "https://www.notion.so/chumbaka/5-Sales-Marketing-35ab881159f3405daa1d7d8fdb4a9a4c?pvs=4",
        label: "Notion",
    },
    {
        id: "2",
        tooltip: true,
        tooltipContent: (
            <div style={{ padding: "10px" }}>
                <p style={{ fontWeight: "700" }}>📂 Chumbaka Partner Portal - 5. Sales & Marketing</p>
                <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                    <li>Communication Slides</li>
                    <li>Marketing Materials</li>
                    <li>P&L Simulator</li>
                    <li>Comms Slides Template</li>
                </ul>
            </div>
        ),
        type: "drive",
        link: "https://drive.google.com/drive/folders/1G2SEjGUkei2aK4ZPm6xhQoC9MoApfbqm",
        label: "Google Drive",
    },
];

export default {
    info,
    buttons,
};
