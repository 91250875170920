/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_TRAINING_LOCATIONS,
	GET_TRAINING_LOCATIONS_OPTIONS,
	ADD_TRAINING_LOCATION,
	EDIT_TRAINING_LOCATION,
	DELETE_TRAINING_LOCATION,
	GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getAPIErrorMessage } from '../../helpers/action';
import { appChangesReset } from '../misc';

import { fb_getTrainingLocations, fb_getTrainingLocationsOptions, fb_addTrainingLocation, fb_updateTrainingLocation, fb_deleteTrainingLocation } from './fb';

export const getTrainingLocations = (props) => {
	return dispatch => {

		fb_getTrainingLocations(props)
		.then(list => {
			dispatch({
				type: GET_TRAINING_LOCATIONS,
				payload: { list }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_TRAINING_LOCATIONS,
				payload: { list: [] }
			});
		})

	}
}

export const getTrainingLocationsOptions = (props) => {
	return dispatch => {

		fb_getTrainingLocationsOptions(list => {
			dispatch({ 
                type: GET_TRAINING_LOCATIONS_OPTIONS, 
                payload: { list } 
            });
		},props);

	}
}

export const addTrainingLocation = (formData,props) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addTrainingLocation(formData)
		.then( results => {
			fb_getTrainingLocations(props)
			.then(list => {
				dispatch({ type: GET_TRAINING_LOCATIONS, payload: { list } });	
				dispatch({ type: ADD_TRAINING_LOCATION });
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("Training Venue Added");
			})
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const editTrainingLocation = (formData,props) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_updateTrainingLocation(formData)
		.then( results => {
			fb_getTrainingLocations(props)
			.then(list => {
				dispatch({ type: GET_TRAINING_LOCATIONS, payload: { list } });	
				dispatch({ type: EDIT_TRAINING_LOCATION });
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("Training Venue Updated");
			})
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const deleteTrainingLocation = (id,props) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteTrainingLocation(id)
		.then( results => {
			fb_getTrainingLocations(props)
			.then(list => {
				dispatch({ type: GET_TRAINING_LOCATIONS, payload: { list } });	
				dispatch({ type: DELETE_TRAINING_LOCATION });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("Training Venue Unpublished & Unarchived!");
			})
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}