import _random from 'lodash/random';

import { 
	GET_PERSONNELS,
    GET_PERSONNEL,
	ADD_PERSONNEL,
	EDIT_PERSONNEL,
	DELETE_PERSONNEL,
    RESET_PERSONNEL,
    RESET_PERSONNELS
} from '../actions/types';

const initialState = {
    personnels: null,
    personnel: null,
    updated: false,
    rand_options: false,
    rand: false
};

export const personnels = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_PERSONNELS:
            return {
                ...state,
                personnels: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case GET_PERSONNEL:
            return {
                ...state,
                personnel: ( payload.personnel || [] ),
                rand: _random(1,9999)
            }; 
        case ADD_PERSONNEL:
        case EDIT_PERSONNEL:
        case DELETE_PERSONNEL:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_PERSONNEL:
            return {
                ...state,
                personnel: null,
                updated: false,
                rand: false
            };
        case RESET_PERSONNELS:
            return {
                ...state,
                personnels: null,
                rand: false
            };
        default:
            return state;
    }
}