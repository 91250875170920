/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';

import TeamsTable from '../../modules/TeamsTable';

import AppWrapper from '../../components/AppWrapper';
import ErrorMessage from '../../components/ErrorMessage';
import DotsLoader from '../../components/DotsLoader';


// import { isAdminType } from '../../helpers/auth';
import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';

import { toggleLoader } from '../../actions/global';



// import { PLATFORM_NAME } from '../../constants';

// import theme from '../../theme';

const getScheduleTeams = (schedule_id) => {
    return new Promise((resolve,reject) => {
        callFunctionsAPI({ url: 'student', action: 'get_teams_by_schedule', formData: { schedule_id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
    });
}

const Teams = ({
    authData,
    schedule,
    registration_forms = [],
    randNum,
    trainees,
    dispatch
}) => {
    const [ loaded, setLoaded ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState(null);
    const [ teams, setTeams ] = useState([]);
    const [ teachers, setTeachers ] = useState([]);
    const [ mentors, setMentors ] = useState([]);

    useEffect(() => {
        let mounted = true;
        if ( schedule && schedule.id && !_isEmpty( schedule.id ) ) {
            getScheduleTeams(schedule.id)
            .then(data => {
                if (mounted) {
                    setLoaded(true);
                    setTeams(( data && data.teams || [] ));
                    setTeachers(( data && data.teachers || [] ));
                    setMentors(( data && data.mentors || [] ));
                }
            })
            .catch(error => {
                if ( mounted ) {
                    setLoaded(true);
                    setErrorMsg(getAPIErrorMessage(error));
                }
            });
        }
        return () => { mounted = false; }
    }, [ authData, schedule, randNum ]);

    const handleAction = (type,formData) => {
        let promises = [],
            successMessage = '';
        switch( type ) {
            case 'create':
                promises.push( callFunctionsAPI({ url: 'student', action: 'admin_create_team', formData }) );
                successMessage = 'Team created successfully';
                break;
            case 'delete':
                promises.push( callFunctionsAPI({ url: 'student', action: 'delete_team', formData }) );
                successMessage = 'Team deleted';
                break;
            case 'edit':
            default:
                promises.push( callFunctionsAPI({ url: 'student', action: 'update_team', formData }) );
                successMessage = 'Team updated successfully';
                break;
        }

        dispatch( toggleLoader(true) );
        Promise.all(promises)
        .then( results => {
            return getScheduleTeams(schedule.id);
        })
        .then( data => {
            setTeams(( data && data.teams || [] ));
            setTeachers(( data && data.teachers || [] ));
            setMentors(( data && data.mentors || [] ));
            dispatch( toggleLoader(false) );
            triggerSuccessAlert(successMessage);
        })
        .catch(error => {
            dispatch( toggleLoader(false) );
            triggerErrorAlert( getAPIErrorMessage(error), 4000 );
        });
        
    }

    return loaded ? (
            <>
                { errorMsg ? <ErrorMessage errorMessage={errorMsg} /> : (
                <>
                    <TeamsTable
                        view={'admin'}
                        teams={teams}
                        schedule={schedule}
                        registration_forms={registration_forms}
                        trainees={trainees}
                        teachers={teachers}
                        mentors={mentors}
                        onAction={handleAction}
                        />
                </>
                )}
            </>
            ) : <DotsLoader />;

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        changesNotSaved: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(Teams);