/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Papa from "papaparse";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _slice from "lodash/slice";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _find from "lodash/find";
import _forEach from "lodash/forEach";
import _upperFirst from "lodash/upperFirst";
import _snakeCase from "lodash/snakeCase";

import MultiRegionSelector from "../../modules/MultiRegionSelector";

import Pagination from "../../components/Pagination";
import TableBar from "../../components/TableBar";
import ModelDelete from "../../components/ModalDelete";
import ModalView from "../../components/ModalView";
import FormSelect from "../../components/FormSelect";
import FormInput from "../../components/FormInput";

import { InfoButton, ErrorButton, ButtonGroup, SuccessButton, GreyButton, InverseButton } from "../../styles/button";
import { WrapWord } from "../../styles/misc";
import { SuccessTag, AmberTag, GreyTag } from "../../styles/tag";

import { isArrayExists, validateEmail } from "../../helpers/validation";
import { triggerErrorAlert } from "../../helpers/alert";
import { cloneCollections, doPagination, doArraySearch, getSelectOptions } from "../../helpers/data";

import { addUser, editUser, deleteUser } from "../../actions/users";

const useStyles = (theme) => ({
    headcell: {
        fontSize: "16px",
        fontWeight: "700",
        color: theme.palette.background,
    },
    bodycell: {
        fontSize: "16px",
        verticalAlign: "top",
    },
});

class Users extends React.Component {
    state = {
        checked: [],
        allCheck: false,
        searchterms: "",
        filterBy: "all",
        sortBy: "date-desc",
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalType: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false,
    };

    doCSVDownload = (results) => {
        console.log(results);
        let csv = Papa.unparse(results, { delimiter: "," });
        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" }); //new way
        let uri = window.URL.createObjectURL(blob);
        let link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", "_blank");
        link.setAttribute("download", "export_users.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    handleDataExport = () => {
        const { users, regions, roles } = this.props;
        const { checked } = this.state;
        let error = false;

        if (!(checked && isArrayExists(checked))) {
            error = "Please select at least one user.";
        }

        if (error) {
            triggerErrorAlert(error);
        } else {
            // get selected users
            let selectedUsers = _filter(users, (u) => checked.includes(u.uid));

            // prepare data
            let csvData = {
                fields: ["Name", "Email", "Status", "Role", "Partner(s)"],
                data: [],
            };
            _forEach(selectedUsers, (u) => {
                let region = regions && u.region && !_isEmpty(u.region) ? _find(regions, { id: u.region }) : false,
                    role = u.role && !_isEmpty(u.role) ? _find(roles, { id: u.role }) : false,
                    row = [];

                row.push(u.name || "");
                row.push(u.email || "");
                row.push(u.status || "");
                row.push(u.type && u.type === "superadmin" ? "Super Admin" : role && role.label && !_isEmpty(role.label) ? role.label : "");
                row.push(
                    u.belongs_to && isArrayExists(u.belongs_to)
                        ? u.belongs_to
                              .map((b) => {
                                  const selectedRegion = _find(regions, { id: b });
                                  return selectedRegion ? selectedRegion.label : "";
                              })
                              .join("\n")
                        : region && region.label
                        ? region.label
                        : ""
                );

                csvData.data.push(row);
            });

            this.doCSVDownload(csvData);
        } // end - error
    };

    handleFormUpdate = (newValue, key) => {
        const { modalData } = this.state;
        var newData = modalData ? cloneCollections(modalData) : {};
        newData[key] = newValue;

        // update region when belongs_to is updated
        if (key === "belongs_to") {
            if (newValue && isArrayExists(newValue)) {
                newData["region"] = newValue[0];
            } else {
                newData["region"] = "";
            }
        }

        // change user type based on role
        if (key === "role" && newValue && !_isEmpty(newValue)) {
            newData["type"] = newValue === "admin" ? "superadmin" : "admin";
        } // end - key

        this.setState({ modalData: newData });
    };

    handleAddNew = () => {
        const { modalData } = this.state;
        var error = false;

        if (!(modalData && modalData.region && !_isEmpty(modalData.region))) {
            error = "Please select a partner";
        } // end - modalData.role

        if (!(modalData && modalData.role && !_isEmpty(modalData.role))) {
            error = "Please select a role";
        } // end - modalData.role

        if (!(modalData && modalData.email && validateEmail(modalData.email))) {
            error = "Please insert a valid email address";
        } // end - modalData.email

        if (!(modalData && modalData.name && !_isEmpty(modalData.name))) {
            error = "Please insert a valid name";
        } // end - modalData.name

        if (error) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addUser(modalData));
        } // end - error
    };

    handleEdit = () => {
        const { modalData } = this.state;
        var error = false;

        if (!(modalData && modalData.region && !_isEmpty(modalData.region))) {
            error = "Please select a partner";
        } // end - modalData.role

        if (!(modalData && modalData.role && !_isEmpty(modalData.role))) {
            error = "Please select a role";
        } // end - modalData.role

        if (!(modalData && modalData.status && !_isEmpty(modalData.status))) {
            error = "Please select a status";
        } // end - modalData.role

        if (!(modalData && modalData.name && !_isEmpty(modalData.name))) {
            error = "Please insert a valid name";
        } // end - modalData.name

        if (error) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editUser(modalData));
        } // end - error
    };

    handleAction = () => {
        const { modalType } = this.state;
        if (modalType && modalType == "new") {
            this.handleAddNew();
        } else if (modalType && modalType == "edit") {
            this.handleEdit();
        } // end - modalType
    };

    handleDelete = () => {
        const { deleteModal } = this.state;
        //perform delete
        this.props.dispatch(deleteUser(deleteModal.uid));
    };

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { users } = this.props;
        var items = users ? cloneCollections(users) : [],
            total = _size(items);

        // do search
        if (searchterms && !_isEmpty(searchterms)) {
            items = doArraySearch(items, searchterms, ["name", "email", "role"]);
            total = _size(items);
        } // end - searchterms

        // do filter
        if (filterBy && !_isEmpty(filterBy) && filterBy != "all") {
            items = _filter(items, (i) => i.belongs_to && i.belongs_to.includes(filterBy));
            total = _size(items);
        }

        // do sort
        if (sortBy && !_isEmpty(sortBy) && !_isEmpty(items)) {
            switch (sortBy) {
                case "date-desc":
                    items = _sortBy(items, [(i) => (i.created_on && i.created_on.seconds ? i.created_on.seconds : 999999999)]);
                    items = _reverse(items);
                    break;
                case "date-asc":
                    items = _sortBy(items, [(i) => (i.created_on && i.created_on.seconds ? i.created_on.seconds : 999999999)]);
                    break;
                case "name-desc":
                    items = _sortBy(items, ["name"]);
                    items = _reverse(items);
                    break;
                case "name-asc":
                    items = _sortBy(items, ["name"]);
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination(items, perPage, page);

        return { items, total };
    }

    renderViewForm = () => {
        const { regions, roles } = this.props;
        const { modalData, modalType } = this.state;
        return (
            <div>
                {modalType && modalType == "edit" ? (
                    <div style={{ marginTop: "8px" }}>
                        <FormSelect
                            label="Status (Required)"
                            name="status"
                            value={modalData.status || ""}
                            disabled={modalData.status && modalData.status === "pending" ? true : false}
                            options={
                                modalData.status && modalData.status === "pending"
                                    ? [{ value: "pending", label: "Pending" }]
                                    : [
                                          { value: "active", label: "Active" },
                                          { value: "disabled", label: "Disabled" },
                                      ]
                            }
                            onChange={this.handleFormUpdate}
                        />
                    </div>
                ) : null}
                <FormInput label="Name (Required)" name="name" value={modalData.name || ""} onChange={this.handleFormUpdate} />
                {modalType && modalType == "edit" ? null : (
                    <FormInput label="Email (Required)" type="email" name="email" value={modalData.email || ""} onChange={this.handleFormUpdate} />
                )}
                <div style={{ paddingTop: "10px" }}>
                    <FormSelect
                        label="Role (Required)"
                        name="role"
                        value={modalData.role || ""}
                        options={getSelectOptions({
                            list: roles,
                            options: [
                                { value: "", label: "Select an Option" },
                                { value: "admin", label: "Super Admin" },
                            ],
                            keys: { value: "id", label: "label" },
                            sortBy: "label",
                        })}
                        onChange={this.handleFormUpdate}
                    />
                </div>
                <div style={{ paddingTop: "15px" }}>
                    <MultiRegionSelector user={modalData} regions={regions} onChange={this.handleFormUpdate} />
                </div>
            </div>
        );
    };

    renderRightButtons = () => {
        const { authData } = this.props;
        const { checked } = this.state;
        let buttons = [];

        buttons.push(
            <InfoButton
                minWidth="128px"
                key="addnew"
                onClick={() =>
                    this.setState({
                        openViewModal: true,
                        modalData: {
                            name: "",
                            email: "",
                            region: "",
                            role: "",
                        },
                        modalType: "new",
                    })
                }
            >
                <i className="fa fa-plus-circle"></i>Add New
            </InfoButton>
        );

        return buttons;
    };

    renderTableActions = () => {
        const { regions, roles } = this.props;
        const { filterBy, sortBy, perPage, searchterms, checked } = this.state;
        return (
            <TableBar
                leftWidth="50%"
                rightWidth="50%"
                filterBy={filterBy}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: "date-desc", label: "Recent Entries first" },
                    { value: "date-asc", label: "Oldest Entries first" },
                    { value: "name-asc", label: "Name ( A - Z)" },
                    { value: "name-desc", label: "Name ( Z - A )" },
                ]}
                filterByOptions={getSelectOptions({
                    list: regions,
                    options: [{ value: "all", label: "All" }],
                    keys: { value: "id", label: "label" },
                    sortBy: "label",
                })}
                leftButtons={
                    checked && isArrayExists(checked)
                        ? [
                              <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                                  <GreyButton
                                      style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }}
                                      onClick={() => this.setState({ checked: [], allCheck: false })}
                                  >
                                      <i className="fa fa-remove" style={{ marginRight: "10px" }}></i>
                                      {_size(checked) + " selected"}
                                  </GreyButton>
                                  <SuccessButton
                                      style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }}
                                      minWidth="128px"
                                      key="export"
                                      onClick={() => this.handleDataExport()}
                                  >
                                      <i className="fa fa-cloud-download"></i>Download Selected
                                  </SuccessButton>
                              </div>,
                          ]
                        : null
                }
                rightButtons={this.renderRightButtons()}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }}
            />
        );
    };

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return (
            <Pagination
                total={totalCount}
                perPage={perPage}
                page={page}
                doneLoaded={true}
                style={{ marginTop: "20px" }}
                onPageChange={(newPage) => this.setState({ page: newPage })}
            />
        );
    };

    renderBody = (items) => {
        const { classes, regions, roles, authData } = this.props;
        const { checked } = this.state;
        return (
            <TableBody>
                {items && isArrayExists(items) ? (
                    items.map((item) => {
                        var region = regions && item.region && !_isEmpty(item.region) ? _find(regions, { id: item.region }) : false,
                            role = roles && item.role && !_isEmpty(item.role) ? _find(roles, { id: item.role }) : false;
                        return (
                            <TableRow key={item.uid} id={item.uid}>
                                <TableCell padding="checkbox" className={classes.bodycell}>
                                    <Checkbox
                                        color="primary"
                                        checked={checked.includes(item.uid)}
                                        onClick={(event) => {
                                            if (event.target.checked) {
                                                this.setState({ checked: [...checked, item.uid] });
                                            } else {
                                                this.setState({ checked: checked.filter((c) => c !== item.uid) });
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell className={classes.bodycell}>
                                    {item.status && item.status == "active" ? (
                                        <SuccessTag>{item.status}</SuccessTag>
                                    ) : item.status && item.status == "pending" ? (
                                        <AmberTag>{item.status}</AmberTag>
                                    ) : (
                                        <GreyTag>disabled</GreyTag>
                                    )}
                                </TableCell>
                                <TableCell className={classes.bodycell}>
                                    <WrapWord>{item.name || ""}</WrapWord>
                                </TableCell>
                                <TableCell className={classes.bodycell}>
                                    <WrapWord>{item.email || ""}</WrapWord>
                                </TableCell>
                                <TableCell className={classes.bodycell}>
                                    {item.type && item.type === "superadmin"
                                        ? "Super Admin"
                                        : role && role.label && !_isEmpty(role.label)
                                        ? role.label
                                        : ""}
                                </TableCell>
                                <TableCell className={classes.bodycell}>
                                    {item.belongs_to && isArrayExists(item.belongs_to)
                                        ? item.belongs_to.map((b) => {
                                              const selectedRegion = _find(regions, { id: b });
                                              if (selectedRegion) return <div key={b}>{selectedRegion ? selectedRegion.label : ""}</div>;
                                          })
                                        : region && region.label
                                        ? region.label
                                        : ""}
                                </TableCell>
                                <TableCell className={classes.bodycell}>
                                    <ButtonGroup>
                                        <InfoButton
                                            size="small"
                                            onClick={() => this.setState({ openViewModal: true, modalData: item, modalType: "edit" })}
                                        >
                                            <i className="fa fa-edit"></i>Edit
                                        </InfoButton>
                                        <ErrorButton
                                            size="small"
                                            disabled={item.uid && item.uid == authData.uid ? "yes" : false}
                                            onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}
                                        >
                                            <i className="fa fa-trash"></i>Delete
                                        </ErrorButton>
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        );
                    })
                ) : (
                    <TableRow>
                        <TableCell className={classes.bodycell}>No List(s) found.</TableCell>
                    </TableRow>
                )}
            </TableBody>
        );
    };

    renderHeader = () => {
        const { classes } = this.props;
        const { allCheck } = this.state;
        const { items } = this.reorganizeData();
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox" className={classes.headcell}>
                        <Checkbox
                            color="primary"
                            checked={allCheck}
                            onClick={(event) => {
                                if (event.target.checked) {
                                    this.setState({ checked: items && isArrayExists(items) ? items.map((u) => u.uid) : [], allCheck: true });
                                } else {
                                    this.setState({ checked: [], allCheck: false });
                                }
                            }}
                        />
                    </TableCell>
                    <TableCell className={classes.headcell}>Status</TableCell>
                    <TableCell className={classes.headcell} style={{ width: "20%" }}>
                        Name
                    </TableCell>
                    <TableCell className={classes.headcell} style={{ width: "20%" }}>
                        Email
                    </TableCell>
                    <TableCell className={classes.headcell}>Role</TableCell>
                    <TableCell className={classes.headcell}>Partner(s)</TableCell>
                    <TableCell className={classes.headcell} style={{ width: "20%" }}>
                        Actions
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    };

    render() {
        const { openDeleteModal, deleteModal, openViewModal, modalType } = this.state;
        const { items, total } = this.reorganizeData();
        return (
            <div>
                <ModalView
                    open={openViewModal}
                    maxWidth="md"
                    disableBackdrop={true}
                    title={modalType && modalType == "edit" ? "Edit User" : "Add New User"}
                    actionLabel={modalType && modalType == "edit" ? "Update" : "Add New"}
                    onClose={() => this.setState({ openViewModal: false, modalType: false, modalData: false })}
                    doAction={this.handleAction}
                    contents={this.renderViewForm()}
                />

                <ModelDelete
                    open={openDeleteModal}
                    title={deleteModal && deleteModal.name ? `Are you sure you want to delete this user ( ${deleteModal.name} )?` : false}
                    onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                    onDelete={this.handleDelete}
                />

                {this.renderTableActions()}
                <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                    <Table>
                        {this.renderHeader()}
                        {this.renderBody(items)}
                    </Table>
                </Paper>
                {this.renderPagination(total)}
            </div>
        );
    }
}

export default compose(connect(), withStyles(useStyles), withRouter)(Users);
