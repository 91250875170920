/* eslint-disable */
import firebase from "firebase/app";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _snakeCase from "lodash/snakeCase";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";

import { isAdmin } from "../../helpers/auth";
import { callFunctionsAPI, doPromise } from "../../helpers/action";
import { isArrayExists, validateEmail, isObjectExists } from "../../helpers/validation";
import { getSnapshotDataBySchema, getDocDataBySchema } from "../../helpers/schemas";

import { userStudentSchema } from "../../schemas/user_student";

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve, reject) => {});
};

export const doAsyncAPICallAddmentor = async ({ users }) => {
    const list = [];
    const limits = 1;
    let count = 1,
        batch = [];

    if (users && isArrayExists(users)) {
        _forEach(users, (user) => {
            batch.push(user);
            if (count === limits) {
                list.push(batch); // add to list
                // then reset
                count = 1;
                batch = [];
            } else {
                count++;
            } // end - count
        });

        // if batch is not empty - add to list
        if (batch && isArrayExists(batch)) list.push(batch);
    } // end - trainees

    if (list && isArrayExists(list)) {
        let cIndex = 0;
        while (cIndex < list.length) {
            await fb_addMentor(list[cIndex][0])
                .then(() => {
                    cIndex++;
                })
                .catch((error) => {
                    let errorMessage =
                        error && error.response && error.response.data && error.response.data.message
                            ? error.response.data.message
                            : error.message || "Unable to import user data. Please try again.";
                    throw new Error(errorMessage);
                });
        }
    } // end - contacts

    return "done";
};

export const getMentorEnrollments = (uid) => {
    return new Promise((resolve, reject) => {
        let enrollments = [];

        firebase
            .database()
            .ref("usage/mentors/" + uid + "/enrollments")
            .once("value")
            .then((snapshot) => {
                if (snapshot && snapshot.exists() && snapshot.hasChildren()) {
                    snapshot.forEach((childSnapshot) => {
                        enrollments.push(childSnapshot.val());
                    });
                } // end - snapshot
                resolve({ uid, enrollments });
            })
            .catch((error) => {
                // just return empty array
                resolve({ uid, enrollments });
            });
    });
};

/* helper end */

export const fb_getMentors = (callback, props) => {
    return new Promise((resolve, reject) => {
        const { region } = props;

        let Ref = firebase.database().ref("database_mentors");

        if (region && !_isEmpty(region)) {
            Ref = Ref.orderByChild("region").equalTo(region);
        } // end - region

        Ref.on("value", (snapshot) => {
            let promises = [],
                list = [];
            if (snapshot && snapshot.exists() && snapshot.hasChildren()) {
                snapshot.forEach((childSnapshot) => {
                    let item = childSnapshot.val();
                    item.id = childSnapshot.key;
                    item.enrollments = [];
                    list.push(item);
                    // use promises to get enrollments
                    if (item.uid && !_isEmpty(item.uid)) {
                        promises.push(getMentorEnrollments(item.uid));
                    } // end - item.uid
                });
            } // end - snapshot

            Promise.all(promises)
                .then((results) => {
                    if (results && isArrayExists(results)) {
                        _forEach(results, (result) => {
                            let index = _findIndex(list, { uid: result.uid });
                            if (index >= 0) {
                                list[index].enrollments = result && result.enrollments && isArrayExists(result.enrollments) ? result.enrollments : [];
                            } // end - index
                        });
                    } // end - results
                    callback(list);
                })
                .catch((error) => {
                    callback(list);
                });
        });
    });
};

export const fb_getMentor = (uid) => {
    return new Promise((resolve, reject) => {
        let promises = [];
        promises.push(callFunctionsAPI({ url: "mentor", action: "get_mentor", formData: { uid } }));
        promises.push(getMentorEnrollments(uid));

        Promise.all(promises)
            .then((results) => {
                const mentor = results && results[0] && isObjectExists(results[0]) ? results[0] : {};
                if (mentor && results[1] && results[1].enrollments && isArrayExists(results[1].enrollments)) {
                    mentor.mentor_enrollments = results[1].enrollments;
                } // end - results
                resolve(mentor);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_addMentor = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "mentor", action: "add_mentor", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_updateMentor = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "mentor", action: "update_mentor", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_resendMentorEmail = (uid) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "mentor", action: "resend_email", formData: { uid } })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_removeMentor = (uid) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "mentor", action: "remove_mentor", formData: { uid } })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_triggerGrantMentorAccess = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "mentor", action: "grant_access", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_triggerRevokeMentorAccess = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "mentor", action: "revoke_access", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
