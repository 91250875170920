

export const isClassTeacher = (schedules = [],schedule_id = "", email = "") => {
    const selected = schedules.find(schedule => schedule.id === schedule_id);
    return selected && selected.teachers && selected.teachers.length > 0 && selected.teachers.find(teacher => teacher.email === email);
}

export const isClassMentor = (schedules = [],schedule_id = "", email = "") => {
    const selected = schedules.find(schedule => schedule.id === schedule_id);
    return selected && selected.mentors && selected.mentors.length > 0 && selected.mentors.find(mentor => mentor.email === email);
}