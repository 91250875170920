/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Papa from "papaparse";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import moment from "moment";
import shortid from "shortid";
import blue from "@material-ui/core/colors/blue";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dropzone from "react-dropzone";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _sortBy from "lodash/sortBy";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _remove from "lodash/remove";
import _toLower from "lodash/toLower";
import _toString from "lodash/toString";
import _reverse from "lodash/reverse";

import ModalView from "../../components/ModalView";
import TableBar from "../../components/TableBar";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import DotsLoader from "../../components/DotsLoader";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton, WarningButton } from "../../styles/button";
import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from "../../styles/tag";
import { WrapWord } from "../../styles/misc";

import { isAdmin, hasAccessRights } from "../../helpers/auth";
import { isArrayExists, isObjectExists, validate } from "../../helpers/validation";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import {
    cloneCollections,
    getSelectOptions,
    convertArrayToObject,
    convertObjectToArray,
    replaceAll,
    doPagination,
    doArraySearch,
    getSelectedValue,
    trimEmail,
} from "../../helpers/data";
import { isSkip, doSchemaErrorCheck } from "../../helpers/schemas";
import { getMomentTime, getAge } from "../../helpers/date";
import { getAPIErrorMessage } from "../../helpers/action";
import { getCustomFields, getFieldSnakeCaseId, getFieldBySnakeCaseId, getCustomFieldValue } from "../../helpers/registration_form";

import { importCourseRegistration } from "../../actions/course_registration";
import { toggleLoader } from "../../actions/global";
import { appChangesMade } from "../../actions/misc";

import { traineeSchema } from "../../schemas/trainee";

// import { PLATFORM_COURSE_REGISTRATION_LINK } from '../../constants';

const ReconWrapper = styled.div`
    h5 {
        display: none;
    }
`;

const useStyles = (theme) => ({
    tooltip: {
        fontSize: "14px",
    },
    emailModal: {
        "& .MuiFilledInput-input.Mui-disabled, & .MuiInputBase-root.Mui-disabled, & .MuiFormLabel-root.Mui-disabled": {
            background: "#f1f1f1",
            color: "#212121",
        },
    },
});

class ImportModal extends React.Component {
    utcOffSet = 8;

    state = {
        list: false,
        searchterms: "",
        filterBy: "all",
        sortBy: "email-asc",
        perPage: 20,
        page: 1,
        dataLoaded: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { open } = this.props;
        if (open && !prevProps.open) {
            this.setState({ list: false });
        } // end - open
    }

    doCSVDownload = (results) => {
        let csv = Papa.unparse(results, { delimiter: "," });
        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" }); //new way
        let uri = window.URL.createObjectURL(blob);
        let link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", "_blank");
        link.setAttribute("download", "import_registration_sample_custom_fields.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    handleClose = () => {
        const { onClose } = this.props;
        if (onClose) onClose();
    };

    handleImport = () => {
        const { schedule } = this.props;
        const { list } = this.state;
        let error = false;

        if (!(schedule && schedule.id && !_isEmpty(schedule.id))) error = "Unknown Error - Please refresh your browser and try again.";

        if (!(list && isArrayExists(list))) error = "Please import at least one student";

        // make sure all the trainees have all the required data
        if (list && isArrayExists(list)) {
            _forEach(list, (item) => {
                traineeSchema.forEach((schema) => {
                    if (!(item && item.name && !_isEmpty(item.name) && item.email && validate(item.email, "email"))) {
                        error = "Please make sure the columns marked with * are filled. Email must be a valid email address. Name must not be empty.";
                    }
                });
            });
        } // end - list

        if (error) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(
                importCourseRegistration(
                    {
                        schedule_id: schedule.id,
                        trainees: list,
                    },
                    { schedule_id: schedule.id }
                )
            );
        } // end - error
    };

    handleFileUpload = (files) => {
        if (files && files[0] && !_isEmpty(files[0])) {
            Papa.parse(files[0], {
                header: true,
                complete: (results) => {
                    const list = this.compileTraineesData((results && results.data) || false);
                    if (_size(list) > 100) {
                        triggerErrorAlert("Please upload only 100 students at a time");
                    } else {
                        this.setState({ list });
                    } // end - list
                },
            });
        } else {
            triggerErrorAlert("Please upload a valid CSV file");
        }
    };

    handleCustomFieldsCSVDownload = () => {
        const { schedule, registration_forms } = this.props;
        const nonDefaultfields = getCustomFields(schedule, registration_forms, "non_default");
        let csvData = {
            fields: [
                "email",
                "name",
                "gender",
                "date_of_birth",
                "school",
                "medical_condition",
                "parent_name",
                "parent_email",
                "parent_mobile",
                "parent_city",
            ],
            data: [],
        };

        _forEach(nonDefaultfields, (field) => {
            csvData.fields.push(getFieldSnakeCaseId(field, true));
        });

        this.doCSVDownload(csvData);
    };

    isCustomRegistrationForm = () => {
        const { schedule } = this.props;
        return schedule && schedule.registration_form && !_isEmpty(schedule.registration_form) && schedule.registration_form !== "default"
            ? true
            : false;
    };

    compileTraineesData = (data) => {
        const { schedule, trainees, registration_forms } = this.props;
        let list = [];
        if (data && isArrayExists(data)) {
            _forEach(data, (item) => {
                let trainee = { id: shortid.generate() },
                    dob =
                        item && item.date_of_birth && !_isEmpty(item.date_of_birth)
                            ? moment(item.date_of_birth).utcOffset(8).startOf("date").valueOf()
                            : 0,
                    dob_string = dob ? moment(dob).utcOffset(8).format("YYYY-MMM-DD") : "";
                traineeSchema.forEach((schema) => {
                    if (!isSkip(schema, "add")) {
                        switch (schema.id) {
                            case "course_id":
                                trainee[schema.id] = (schedule && schedule.course_id) || "";
                                break;
                            case "schedule_id":
                                trainee[schema.id] = (schedule && schedule.id) || "";
                                break;
                            case "region":
                                trainee[schema.id] = (schedule && schedule.region) || "";
                                break;
                            case "email":
                            case "parent_email":
                            case "gender":
                                trainee[schema.id] = item && item[schema.id] ? trimEmail(item[schema.id]) : schema.default || "";
                                break;
                            case "age":
                                trainee[schema.id] = getAge(dob_string);
                                break;
                            case "date_of_birth":
                                trainee[schema.id] = dob;
                                break;
                            case "date_of_birth_string":
                                trainee[schema.id] = dob_string;
                                break;
                            case "org":
                                trainee[schema.id] = item && item.school && !_isEmpty(item.school) ? item.school : "";
                                break;
                            case "parent_phone":
                                trainee[schema.id] = item && item.parent_mobile && !_isEmpty(item.parent_mobile) ? item.parent_mobile : "";
                                break;
                            case "custom_fields":
                                trainee[schema.id] = [];
                                const nonDefaultfields = getCustomFields(schedule, registration_forms, "non_default");
                                if (nonDefaultfields && isArrayExists(nonDefaultfields)) {
                                    _forEach(nonDefaultfields, (field) => {
                                        const snakeCaseID = getFieldSnakeCaseId(field, true);
                                        trainee[schema.id].push({
                                            id: field.id,
                                            value: item && item[snakeCaseID] && !_isEmpty(item[snakeCaseID]) ? item[snakeCaseID] : "",
                                        });
                                    });
                                }
                                break;
                            default:
                                trainee[schema.id] =
                                    item && item[schema.id]
                                        ? item[schema.id]
                                        : schema.default || 0 === schema.default || "" === schema.default
                                        ? schema.default
                                        : null;
                                break;
                        }
                    } // end - schema
                });
                // make sure email wasn't repeat
                if (
                    trainee.email &&
                    !_isEmpty(trainee.email) &&
                    !_find(trainees, { email: trainee.email }) &&
                    !_find(list, { email: trainee.email })
                ) {
                    list.push(trainee);
                } // end - trainee
            });
        } // end -data
        return list;
    };

    getCells = () => {
        const { schedule, registration_forms } = this.props;
        let cells = [
            { id: "email", label: "* Email", render: (item) => <WrapWord>{item.email || ""}</WrapWord> },
            { id: "name", label: "* Name", render: (item) => <WrapWord>{item.name || ""}</WrapWord> },
            { id: "gender", label: "Gender", render: (item) => <WrapWord>{item.gender || ""}</WrapWord> },
            { id: "date_of_birth_string", label: "Date of Birth", render: (item) => <WrapWord>{item.date_of_birth_string || ""}</WrapWord> },
            { id: "org", label: "School", render: (item) => <WrapWord>{item.org || ""}</WrapWord> },
            { id: "medical_condition", label: "Medical Condition", render: (item) => <WrapWord>{item.medical_condition || ""}</WrapWord> },
            { id: "parent_name", label: "Parent Email", render: (item) => <WrapWord>{item.parent_name || ""}</WrapWord> },
            { id: "parent_email", label: "Parent Email", render: (item) => <WrapWord>{item.parent_email || ""}</WrapWord> },
            { id: "parent_phone", label: "Parent Phone", render: (item) => <WrapWord>{item.parent_phone || ""}</WrapWord> },
            { id: "parent_city", label: "Parent City", render: (item) => <WrapWord>{item.parent_city || ""}</WrapWord> },
        ];

        const nonDefaultfields = getCustomFields(schedule, registration_forms, "non_default");
        if (nonDefaultfields && isArrayExists(nonDefaultfields)) {
            _forEach(nonDefaultfields, (field) => {
                cells.push({
                    id: field.id,
                    label: field.label || "",
                    render: (item) => <WrapWord>{getCustomFieldValue(field, item)}</WrapWord>,
                });
            });
        }

        return cells;
    };

    reorganizeItems = () => {
        const { list, searchterms, sortBy, perPage, page, filterBy } = this.state;
        let items = list && isArrayExists(list) ? cloneCollections(list) : [],
            total = _size(items);

        // do sort
        if (sortBy && !_isEmpty(sortBy) && !_isEmpty(items)) {
            switch (sortBy) {
                case "name-desc":
                    items = _sortBy(items, ["name"]);
                    items = _reverse(items);
                    break;
                case "name-asc":
                    items = _sortBy(items, ["name"]);
                    break;
                case "email-desc":
                    items = _sortBy(items, ["email"]);
                    items = _reverse(items);
                    break;
                case "email-asc":
                    items = _sortBy(items, ["email"]);
                    break;
            }
        } // end - sortBy

        return { items, total };
    };

    renderTable = (items) => {
        const { classes } = this.props;
        return (
            <>
                <Table
                    items={items}
                    emptyCell="No data found"
                    cells={this.getCells()}
                    elevation={this.isCustomRegistrationForm() ? 0 : 2}
                    noAction={true}
                />
                <div style={{ paddingTop: "20px", color: "#9e0000", fontStyle: "italic" }}>* Required field(s).</div>
            </>
        );
    };

    renderCSVtable = () => {
        const { classes } = this.props;
        const { items, total } = this.reorganizeItems();
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} align="right">
                    <InverseButton style={{ padding: "10px 25px" }} onClick={() => this.setState({ list: false })}>
                        <i className="fa fa-refresh"></i>Reset
                    </InverseButton>
                </Grid>
                <Grid item xs={12}>
                    {this.renderTable(items)}
                </Grid>
            </Grid>
        );
    };

    renderContent = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} align="left">
                    {this.isCustomRegistrationForm() ? (
                        <InfoButton style={{ padding: "10px 25px" }} onClick={this.handleCustomFieldsCSVDownload}>
                            Download Example CSV File
                        </InfoButton>
                    ) : (
                        <AInfoLink
                            href="https://firebasestorage.googleapis.com/v0/b/chumbaka-dev-tms.appspot.com/o/file_storage%2Fimport_registration_sample.csv?alt=media&token=01236aec-a723-4e04-82f8-e047b0331f48"
                            target="_blank"
                            style={{ padding: "10px 25px" }}
                        >
                            Download Example CSV File
                        </AInfoLink>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Dropzone onDrop={this.handleFileUpload} accept={".csv"}>
                        {({ getRootProps, getInputProps }) => (
                            <div
                                {...getRootProps({
                                    style: {
                                        display: "flex",
                                        height: "200px",
                                        width: "100%",
                                        border: "2px dashed " + blue["700"],
                                        color: blue["700"],
                                        justifyContent: "center",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    },
                                })}
                            >
                                <input {...getInputProps()} />
                                <div>Drop a CSV file here, or click to select a CSV file to upload</div>
                            </div>
                        )}
                    </Dropzone>
                </Grid>
            </Grid>
        );
    };

    render() {
        const { open } = this.props;
        const { dataLoaded, list } = this.state;
        return (
            <>
                <ModalView
                    open={open}
                    title={"Import Students via CSV file"}
                    maxWidth="lg"
                    onClose={this.handleClose}
                    cancelLabel="Close"
                    disableBackdrop={true}
                    disableAutoFocus={true}
                    disableEnforceFocus={true}
                    noAction={list && isArrayExists(list) ? false : true}
                    actionLabel="Import"
                    doAction={this.handleImport}
                    contents={list ? this.renderCSVtable() : this.renderContent()}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
    };
};

export default compose(connect(mapStateToProps), withStyles(useStyles), withRouter)(ImportModal);
