/* eslint-disable */
import firebase from 'firebase/app';

import { getIDToken } from '../auth/fb';
import { doPromise, callFunctionsAPI } from '../../helpers/action';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

/* helper end */

export const fb_getRegions = (callback) => {

    firebase.firestore().collection("maintenance").where("type","==", "regions")
    .onSnapshot(snapshot => {

        var list = [];

        if ( snapshot ) {
            snapshot.forEach( doc => {
                list.push(doc.data());
            });
        } // end - snapshpt\
        
        callback(list);

    });
    
}

export const fb_addRegion = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'mRegions', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateRegion = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'mRegions', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteRegion = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'mRegions', action: 'delete', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}