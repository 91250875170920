/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';

import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';


// import { PLATFORM_COURSE_REGISTRATION_LINK } from '../../constants';

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
    border: 2px solid #212121;
`;

class ColumnsMenu extends React.Component {

    utcOffSet = 8;
    
    handleCheckboxChanges = option => event => {
        const { onUpdate, columns, mentorMode } = this.props;
        let proceed = true;
        // ignore if columsn already more than 3
        // if ( mentorMode && columns && isArrayExists( columns ) && _size( columns ) >= 3 && event.target.checked ) {
        //     proceed = false;
        // }

        if ( proceed ) {
            let newColumns = ( columns && isArrayExists( columns ) ? cloneCollections( columns ) : [] );
            if ( event.target.checked ) {
               if ( !_find( newColumns, { id: option.id } ) )
                    newColumns.push(option); 
            } else {
                if ( _find( newColumns, { id: option.id } ) ) {
                    var removed = _remove( newColumns, { id: option.id } );
                }
            } // end - checked
            
            if ( onUpdate )
                onUpdate( newColumns );
        }
    }

    getOptions = () => {
        const { schedule } = this.props;
        let options = [];
        if ( schedule && schedule.class_calendar && isArrayExists( schedule.class_calendar ) ) {
            _forEach( schedule.class_calendar, event => {
                let option = cloneCollections( event );
                option.label = ( option.start_date ? getMomentTime( option.start_date, 'DD-MMM-YY' ) : option.id );
                options.push(option);
            });
        } // end - schedule.class_calendar
        return _reverse( _sortBy( options, ['start_date'] ) );
    }

    render() {
        const { columns } = this.props;
        const options = this.getOptions();
        return (
        <Wrapper>
            <Typography variant="h5">Show Column(s)</Typography>
            <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                <Grid container spacing={0}>
                    { options && isArrayExists( options ) ? options.map(option => {
                        return (
                        <Grid key={option.id} item xs={6} sm={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={( columns && isArrayExists( columns ) && _find( columns, { id: option.id } ) ? true : false )}
                                        onChange={this.handleCheckboxChanges(option)}
                                        value={option.id}
                                        color="primary" />
                                }
                                label={( option.label || option.id )} />
                        </Grid>
                        );
                    }) : null }
                </Grid>
            </div>
        </Wrapper>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ColumnsMenu);