/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import blue from "@material-ui/core/colors/blue";
import { withStyles } from "@material-ui/core/styles";
// import _isEmpty from 'lodash/isEmpty';
// import _merge from 'lodash/merge';
// import _size from 'lodash/size';
// import _isEqual from 'lodash/isEqual';

import AppWrapper from "../../components/AppWrapper";
import InformationBox from "../../components/InformationBox";
import Table from "./table";

import { isAdmin, getUserRegionFilter } from "../../helpers/auth";
// import { cloneCollections } from '../../helpers/data';

import { AInverseLink } from "../../styles/button";

import { getSchedules } from "../../actions/schedules";
import { getCoursesOptions } from "../../actions/courses";
import { getUsersOptions } from "../../actions/users";
import { getTrainingLocationsOptions } from "../../actions/training_locations";
import { getRegions } from "../../actions/m_regions";
// import { getMaintenance } from '../../actions/maintenance';
import { resetRedux } from "../../actions/misc";

const useStyles = (theme) => ({
    popper: {
        zIndex: "3000",
        pointerEvents: "auto",
    },
    tooltip: {
        fontSize: "14px",
        color: "#fff",
        background: blue[400],
        maxWidth: 400,
        padding: "0px",
        "& > .MuiTooltip-arrow": {
            color: blue[400],
        },
    },
});

class SchedulesPage extends React.Component {
    state = {
        // start_date: moment().utcOffset(8).subtract(6, 'months').startOf('date').valueOf(),
        start_date: moment().utcOffset(8).set({ year: 2021, month: 0, date: 1 }).startOf("date").valueOf(),
        end_date: moment().utcOffset(8).add(6, "months").endOf("date").valueOf(),
        filter_region: "all",
        filter_coordinator: "all",
        filter_venue: "all",
        randNum: false,
    };

    componentDidMount() {
        const { authData, regionsList, usersOptionsList, venueList, coursesList } = this.props;
        // get schedules
        this.props.dispatch(getSchedules(this.getQueryProps()));

        // get regions
        if (!regionsList) this.props.dispatch(getRegions());

        // get users options
        if (!usersOptionsList) this.props.dispatch(getUsersOptions({ region: getUserRegionFilter(authData) }));

        // get venue options
        if (!venueList) this.props.dispatch(getTrainingLocationsOptions({ region: getUserRegionFilter(authData) }));

        // get course options
        if (!coursesList) this.props.dispatch(getCoursesOptions({ region: getUserRegionFilter(authData) }));

        // set filter_region if not admin
        if (!isAdmin(authData)) {
            this.setState({ filter_region: getUserRegionFilter(authData) });
        } // end - authData
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { schedulesList, regionsList, usersOptionsList, venueList, coursesList, randNum } = this.props;
        if (schedulesList && regionsList && usersOptionsList && venueList && coursesList && randNum && randNum !== this.state.randNum) {
            this.setState({ randNum });
        } // end - randNum
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux("schedules"));
    }

    handleFilter = (props) => {
        const { authData } = this.props;
        const { start_date, end_date, filter_region, filter_coordinator, filter_venue } = props;
        // only trigger if start_date or end_date changed
        if (start_date !== this.state.start_date || end_date !== this.state.end_date) {
            this.props.dispatch(resetRedux("schedules"));
            setTimeout(() => {
                this.setState({ randNum: false, start_date, end_date, filter_region, filter_coordinator, filter_venue });
                this.props.dispatch(getSchedules({ start_date, end_date, region: getUserRegionFilter(authData) }));
            }, 300);
        } else {
            // otherwise just update state
            this.setState({ start_date, end_date, filter_region, filter_coordinator, filter_venue });
        } // end - start_date
    };

    getQueryProps = () => {
        const { authData } = this.props;
        const { start_date, end_date } = this.state;
        return { start_date, end_date, region: getUserRegionFilter(authData) };
    };

    renderContents() {
        const { start_date, end_date, filter_region, filter_coordinator, filter_venue, randNum } = this.state;
        const { authData, schedulesList, usersOptionsList, regionsList, venueList, coursesList, classes } = this.props;
        return (
            <>
                <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={8}>
                        <InformationBox
                            headling=""
                            contents={[
                                {
                                    id: "1",
                                    title: "Create a class here",
                                    content: (
                                        <>
                                            <div>
                                                <ul style={{ listStyle: "lower-alpha", paddingLeft: "10px", marginLeft: "10px" }}>
                                                    <li>
                                                        <span style={{ fontWeight: "700" }}>Recruitment</span> stage focuses on funneling interest
                                                        into registration. Pre-sales happen here mainly targeted at convincing our commercial
                                                        customers (parents). The recruitment stage is considered successful when many registrations
                                                        are generated.
                                                    </li>
                                                    <li>Create a class here to start funnelling interests into registration.</li>
                                                    <li>
                                                        If you cannot find a suitable program for your class, check{" "}
                                                        <a href="https://sms.chumbaka.asia/programs" target="_blank">
                                                            all program profiles
                                                        </a>{" "}
                                                        OR contact{" "}
                                                        <a href="mailto:zhix.chong@chumbaka.asia" target="_blank">
                                                            zhix.chong@chumbaka.asia
                                                        </a>{" "}
                                                        to request
                                                    </li>
                                                </ul>
                                            </div>
                                        </>
                                    ),
                                },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {" "}
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            style={{
                                display: "flex",
                                gap: "10px",
                            }}
                        >
                            <Tooltip
                                arrow
                                placement="top"
                                title={
                                    <div style={{ padding: "10px" }}>
                                        <p style={{ fontWeight: "700" }}>📂 Chumbaka Partner Portal - 5. Sales & Marketing</p>
                                        <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                                            <li>Our Publicity Paragraph</li>
                                            <li>Our Product Lines</li>
                                            <li>Tools and Resources</li>
                                        </ul>
                                    </div>
                                }
                                classes={{
                                    popper: classes.popper,
                                    tooltip: classes.tooltip,
                                }}
                            >
                                <AInverseLink
                                    href="https://www.notion.so/chumbaka/5-Sales-Marketing-35ab881159f3405daa1d7d8fdb4a9a4c?pvs=4"
                                    style={{ padding: "10px 12px" }}
                                    target="_blank"
                                >
                                    <img
                                        src="https://firebasestorage.googleapis.com/v0/b/chumbaka-website.appspot.com/o/website%2Fnotion.png?alt=media&token=e4d90009-68aa-438a-8c37-3e8e71d43486"
                                        style={{ maxWidth: "20px", verticalAlign: "middle", marginRight: "10px" }}
                                    />
                                    Notion
                                </AInverseLink>
                            </Tooltip>
                            <Tooltip
                                arrow
                                placement="top"
                                title={
                                    <div style={{ padding: "10px" }}>
                                        <p style={{ fontWeight: "700" }}>📂 Chumbaka Partner Portal - 5. Sales & Marketing</p>
                                        <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                                            <li>Communication Slides</li>
                                            <li>Marketing Materials</li>
                                            <li>P&L Simulator</li>
                                            <li>Comms Slides Template</li>
                                        </ul>
                                    </div>
                                }
                                classes={{
                                    popper: classes.popper,
                                    tooltip: classes.tooltip,
                                }}
                            >
                                <AInverseLink
                                    href="https://drive.google.com/drive/folders/1G2SEjGUkei2aK4ZPm6xhQoC9MoApfbqm"
                                    style={{ padding: "10px 12px" }}
                                    target="_blank"
                                >
                                    <img
                                        src="https://firebasestorage.googleapis.com/v0/b/chumbaka-dev-tms.appspot.com/o/file_storage%2FGoogle_Drive_logo.png?alt=media&token=dbb71881-bd34-4756-81e4-1276bed82281"
                                        style={{ maxWidth: "20px", verticalAlign: "middle", marginRight: "10px" }}
                                    />
                                    Google Drive
                                </AInverseLink>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
                <Table
                    start_date={start_date || 0}
                    end_date={end_date || 0}
                    filter_region={filter_region || "all"}
                    filter_coordinator={filter_coordinator || "all"}
                    filter_venue={filter_venue || "all"}
                    schedules={schedulesList}
                    users={usersOptionsList}
                    regions={regionsList}
                    locations={venueList}
                    courses={coursesList}
                    authData={authData}
                    randNum={randNum}
                    onFilter={this.handleFilter}
                />
            </>
        );
    }

    render() {
        const { randNum } = this.state;
        const { authData } = this.props;
        return (
            <AppWrapper
                subtitle="1. Recruitment"
                title="Class Schedule"
                onLoad={!randNum ? true : false}
                contents={authData ? this.renderContents() : null}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        schedulesList: state.schedules && state.schedules.schedules ? state.schedules.schedules : null,
        randNum: state.schedules && state.schedules.rand ? state.schedules.rand : null,
        usersOptionsList: state.users && state.users.users_options ? state.users.users_options : false,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        venueList: state.training_locations && state.training_locations.locations_options ? state.training_locations.locations_options : false,
        coursesList: state.courses && state.courses.courses_options ? state.courses.courses_options : false,
    };
};

export default compose(connect(mapStateToProps), withStyles(useStyles), withRouter)(SchedulesPage);
