/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import firebase from "firebase/app";
import moment from "moment";
import _random from "lodash/random";

import { GET_NOTIFICATIONS, UPDATE_NOTIFICATION_DATE_RANGE } from "../../actions/types";

import { DEV_MODE } from "../../constants";

export const useNotifications = (authData) => {
    const dispatch = useDispatch();
    const startDate = useSelector((state) => state.notifications.startDate);
    const endDate = useSelector((state) => state.notifications.endDate);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        // get notifications from firestore using realtime listener
        const unsubscribe = firebase
            .firestore()
            .collection("notifications")
            .where("region", "==", authData.region)
            .where("added_on", ">=", startDate)
            .where("added_on", "<=", endDate)
            .orderBy("added_on", "desc")
            .limit(DEV_MODE ? 10 : 4000)
            .onSnapshot((snapshot) => {
                const list = [];
                if (snapshot) {
                    snapshot.forEach((doc) => {
                        let item = doc.data();
                        list.push(item);
                    });
                } // end - snapshot
                setNotifications(list);

                // dispatch to update state
                dispatch({
                    type: GET_NOTIFICATIONS,
                    payload: {
                        notifications: list,
                        rand: _random(1, 9999),
                    },
                });
            });

        return () => {
            unsubscribe();
        };
    }, [startDate, endDate]);

    const setStartDate = (date) => {
        dispatch({
            type: UPDATE_NOTIFICATION_DATE_RANGE,
            payload: {
                startDate: moment(date).utcOffset(8).startOf("day").valueOf(),
                endDate,
            },
        });
    };

    const setEndDate = (date) => {
        dispatch({
            type: UPDATE_NOTIFICATION_DATE_RANGE,
            payload: {
                startDate,
                endDate: moment(date).utcOffset(8).endOf("day").valueOf(),
            },
        });
    };

    return [notifications];
};

export const updateNotificationsDate = () => {
    const dispatch = useDispatch();
    const startDate = useSelector((state) => state.notifications.startDate);
    const endDate = useSelector((state) => state.notifications.endDate);
    const notifications = useSelector((state) => state.notifications.notifications);

    const setStartDate = (date) => {
        dispatch({
            type: UPDATE_NOTIFICATION_DATE_RANGE,
            payload: {
                startDate: moment(date).utcOffset(8).startOf("day").valueOf(),
                endDate,
            },
        });
    };

    const setEndDate = (date) => {
        dispatch({
            type: UPDATE_NOTIFICATION_DATE_RANGE,
            payload: {
                startDate,
                endDate: moment(date).utcOffset(8).endOf("day").valueOf(),
            },
        });
    };

    return { setStartDate, setEndDate, notifications };
};
