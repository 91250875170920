/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';
import _toString from 'lodash/toString';
import _reverse from 'lodash/reverse';

import ColumnsMenu from './columns_menu';

import ModalView from '../../components/ModalView';
import TableBar from '../../components/TableBar';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import DotsLoader from '../../components/DotsLoader';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton, WarningButton } from '../../styles/button';
import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists, isObjectExists, validate } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, convertArrayToObject, convertObjectToArray, replaceAll, doPagination, doArraySearch, getSelectedValue } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { uploadMediaToStorage } from '../../helpers/firebase';
import { getAPIErrorMessage } from '../../helpers/action';
import { formatMoney } from '../../helpers/number';

import { fb_getPaymentHistory } from '../../actions/class_payment_tracking/fb'
import { toggleLoader } from '../../actions/global';
import { appChangesMade } from '../../actions/misc';

// import { PLATFORM_COURSE_REGISTRATION_LINK } from '../../constants';

const ReconWrapper = styled.div`
    h5 { display: none; }
`;

const useStyles = theme => ({
    tooltip: {
        fontSize: '14px',
    },
    emailModal: {
        '& .MuiFilledInput-input.Mui-disabled, & .MuiInputBase-root.Mui-disabled, & .MuiFormLabel-root.Mui-disabled': {
            background: '#f1f1f1',
            color: '#212121'
        }
    }
});

class PaymentHistory extends React.Component {

    utcOffSet = 8;
    
    state = {
        openReconModal: false,
        modalData: false,
        traineeData: false,
        list: false,
        searchterms: '',
        filterBy: 'all',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        dataLoaded: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { trainee, open } = this.props;
        if ( open && !prevProps.open ) {
            this.getPaymentHistory(trainee);
        } // end - open
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose )
            onClose();
    }

    handleTraineesUpdate = () => {
        const { onTraineesUpdate, trainees } = this.props;
        const { modalData, traineeData } = this.state;
        let error = false,
            newTrainees = ( trainees && isArrayExists( trainees ) ? cloneCollections( trainees ) : [] ),
            index = ( trainees && isArrayExists( trainees ) ? _findIndex( trainees, { id: traineeData.id } ) : -1 );

        // error


        // update trainees
        if ( index >= 0 ) {
            let newPaymentTracking = ( newTrainees && newTrainees[index] && newTrainees[index].payment_tracking && isArrayExists( newTrainees[index].payment_tracking ) ? cloneCollections( newTrainees[index].payment_tracking ) : [] );
            // link payment
            if ( newTrainees && newTrainees[index] && newTrainees[index].payment_tracking && isArrayExists( newTrainees[index].payment_tracking ) ) {
                _forEach( newTrainees[index].payment_tracking, (payment,pindex) => {
                    let month = ( modalData && modalData.months && isArrayExists( modalData.months ) ? _find( modalData.months, { id: payment.id } ) : false );
                    if ( month ) {
                        newPaymentTracking[pindex].payment_made = 'yes';
                        newPaymentTracking[pindex].payment_id = modalData.id;
                    } else {
                        // unlink it if can't found in months options
                        if ( payment && payment.payment_id && !_isEmpty( payment.payment_id ) && payment.payment_id === modalData.id ) {
                            newPaymentTracking[pindex].payment_made = 'no';
                            newPaymentTracking[pindex].payment_id = '';
                        }
                    }// end - found
                });
            } // end - newTrainees[index].payment_tracking
            // link new payment
            if ( modalData && modalData.months && isArrayExists( modalData.months ) ) {
                _forEach( modalData.months, month => {
                    let found = ( newTrainees && newTrainees[index] && newTrainees[index].payment_tracking && isArrayExists( newTrainees[index].payment_tracking ) ? _find( newTrainees[index].payment_tracking, { id: month.id } ) : false );
                    if ( !found ) {
                        newPaymentTracking.push({
                            id: month.id,
                            payment_made: 'yes',
                            invoice_sent: 'no',
                            notes: '',
                            payment_id: modalData.id
                        });
                    }
                });
            } // end - modalData
            // update payment_tracking
            newTrainees[index].payment_tracking = newPaymentTracking;
        } // end - index

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            if ( onTraineesUpdate ) {
                onTraineesUpdate(newTrainees);
                this.props.dispatch(appChangesMade(true));
            }
            this.setState({ modalData: false, traineeData: false, openReconModal: false });
        } // end - error
    }

    handleFormUpdate = (newValue,key,status) => {
        const { modalData } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} );
        switch (key) {
            default:
                newData[key] = newValue;
                break;
        }
        this.setState({ modalData: newData });
    }

    getDisableColumns = () => {
        const { modalData, traineeData } = this.state;
        let disabled = [];
        if ( modalData && modalData.id && traineeData && traineeData.payment_tracking && !_isEmpty( traineeData.payment_tracking ) ) {
            _forEach( traineeData.payment_tracking, payment => {
                if ( payment && payment.payment_id && !_isEmpty( payment.payment_id ) && payment.payment_id !== modalData.id ) {
                    disabled.push(payment);
                } // end - payment.payment_id
            });
        } // end - traineeData.payment_tracking
        return disabled;
    }

    getModalData = (item) => {
        let trainee = this.getTraineeData(item),
            data = {
                id: item.id,
                months: []
            };

        if ( trainee && trainee.payment_tracking && isArrayExists( trainee.payment_tracking ) ) {
            _forEach( trainee.payment_tracking, payment => {
                if ( payment && payment.payment_id && payment.payment_id === item.id ) {
                    data.months.push(payment);
                } // end - payment.payment_id
            });
        } // end - trainee.payment_tracking

        return data;
    }

    getTraineeData = (item) => {
        const { trainees } = this.props;
        return ( trainees && isArrayExists( trainees ) && item && item.trainee_id && !_isEmpty( item.trainee_id ) ? _find( trainees, { id: item.trainee_id } ) : false );
    }

    getPaymentHistory = (trainee) => {
        const { onClose } = this.props;
        if ( trainee && trainee.id && !_isEmpty( trainee.id ) ) {
            this.setState({ dataLoaded: false, list: [] });
            fb_getPaymentHistory({ trainee_id: trainee.id })
            .then(list => {
                this.setState({ list, dataLoaded: true });
            })
            .catch(error => {
                triggerErrorAlert(getAPIErrorMessage(error));
                if ( onClose )
                    onClose();
            });
        } else {
            triggerErrorAlert("Invalid Trainee ID");
            if ( onClose )
                onClose();
        }
    }

    checkIfNeedToDoReconciliation = (payment) => {
        const trainee = this.getTraineeData(payment);
        return ( trainee && trainee.payment_tracking && isArrayExists( trainee.payment_tracking ) && _find( trainee.payment_tracking, { payment_id: payment.id }) ? false : true );
    }

    getPaymentReconciliationDate = (payment) => {
        const trainee = this.getTraineeData(payment);
        const selected = trainee && trainee.payment_tracking && isArrayExists( trainee.payment_tracking ) ? _find( trainee.payment_tracking, { payment_id: payment.id }) : false;
        return ( selected && selected.id && !_isEmpty( selected.id ) ? `Reconciled for "${selected.id}"` : false );
    }

    reorganizeItems = () => {
        const { list, searchterms, sortBy, perPage, page, filterBy } = this.state;
        let items = ( list && isArrayExists( list ) ? cloneCollections( list ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','email','phone','ip_transid','ip_amount','ip_errdesc'] );
			total = _size( items );
        } // end - searchterms

        // do filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy !== 'all' ) {
            items = _filter( items, { status: filterBy });
            total = _size( items );
        } // end - filterBy

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['updated_on'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['updated_on'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
                case 'email-desc':
                    items = _sortBy( items, ['email'] );
                    items = _reverse( items );
                    break;
                case 'email-asc':
                    items = _sortBy( items, ['email'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderReconContent = () => {
        const { modalData } = this.state;
        return (
        <ReconWrapper>
            <ColumnsMenu
                columns={( modalData && modalData.months && isArrayExists( modalData.months ) ? modalData.months : false )}
                disableColumns={this.getDisableColumns()}
                onUpdate={(newColumns) => this.handleFormUpdate(newColumns,'months')} />
        </ReconWrapper>
        )
    }

    renderPaymentHistory = (items) => {
        const { classes } = this.props;
        return (
        <>
            <Table 
                items={items}
                emptyCell="No payment history found for this student."
                cells={[
                    { id: 'id', label: 'ID', render: (item) => <div>{( item.id || '' )}</div> },
                    { id: 'status', label: 'Status', render: (item) => {
                        if ( item.status ) {
                            switch (item.status) {
                                case 'success':
                                    return <SuccessTag>SUCCESS</SuccessTag>;
                                case 'fail':
                                    return <ErrorTag>FAIL</ErrorTag>;
                                case 'pending':
                                    return <AmberTag>PENDING</AmberTag>;
                            }
                        } // end - items.status
                    }},
                    { id: 'ip_transid', label: 'iPay88 Transaction ID', render: (item) => <WrapWord>{( item.ip_transid || '' )}</WrapWord> },
                    { id: 'ip_amount', label: 'Amount Paid', render: (item) => <><span></span> <WrapWord>{( item.ip_ecurrency && !_isEmpty( item.ip_ecurrency ) ? item.ip_ecurrency+' ' : '' )+( item.ip_amount ? item.ip_amount : '' )}</WrapWord></> },
                    { id: 'updated_on', label: 'Date', render: (item) => <WrapWord>{getMomentTime(item.updated_on,'DD-MMM-YYYY h:mma')}</WrapWord> },
                    { id: 'name', label: 'Name', render: (item) => <WrapWord>{( item.name || '' )}</WrapWord> },
                    { id: 'email', label: 'Email', render: (item) => <WrapWord>{( item.email || '' )}</WrapWord> },
                    { id: 'phone', label: 'Contact No.', render: (item) => <WrapWord>{( item.phone || '' )}</WrapWord> },
                    { id: 'remarks', label: 'Remarks', render: (item) => <div>{( item.remarks || '' )}</div> },
                    // { id: 'payment_reconciliation', label: 'Payment Reconciliation', render: (item) => {
                    //     if ( item.payment_reconciliation && item.status && item.status === 'success' ) {
                    //         switch (item.payment_reconciliation) {
                    //             case 'yes':
                    //                 return <SuccessTag>DONE</SuccessTag>;
                    //             case 'no':
                    //                 return <ErrorTag>Action Needed</ErrorTag>;
                    //         }
                    //     } // end - items.payment_reconciliation
                    // }},
                ]}
                actionStyles={{ width: "20%" }}
                actions={(item) => (
                    <ButtonGroup>
                        { item && item.status && item.status === 'success' ? ( this.checkIfNeedToDoReconciliation(item) ? (
                            <WarningButton key="edit" size="small" onClick={() => this.setState({ openReconModal: true, modalData: this.getModalData(item), traineeData: this.getTraineeData(item) })}>Do Payment Reconciliation</WarningButton>
                        ) : (
                        <>
                            <InfoButton key="update" size="small" onClick={() => this.setState({ openReconModal: true, modalData: this.getModalData(item), traineeData: this.getTraineeData(item) })}>Update Payment Reconciliation</InfoButton>
                            <div style={{ paddingTop: '5px', textAlign: 'center' }}>{this.getPaymentReconciliationDate(item)}</div>
                        </>
                        ) ) : ( item && item.status && item.status === 'fail' ? (
                        <><strong style={{ fontWeight: '700' }}>Error Description</strong>:<br />{( item.ip_errdesc && !_isEmpty( item.ip_errdesc ) ? validate( item.ip_errdesc, 'safe_escape' ) : '' )}</>
                        ) : null ) }
                    </ButtonGroup>
                )}
                 />
        </>
        )
    }

renderTableActions = () => {
        const { authData } = this.props;
        const { sortBy, perPage, searchterms, filterBy } = this.state;
        return <TableBar
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                filterBy={filterBy}
                sortByOptions={[
                    { value: 'date-desc', label: 'Date (Recent First)' },
                    { value: 'date-asc', label: 'Date (Oldest First)' },
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' },
                    { value: 'email-asc', label: 'Email ( A - Z)' },
                    { value: 'email-desc', label: 'Email ( Z - A )' },
                    // { value: 'org-asc', label: 'School ( A - Z)' },
                    // { value: 'org-desc', label: 'School ( Z - A )' },
                ]}
                leftButtons={null}
                rightButtons={null}
                filterByOptions={getSelectOptions({ list: [], options: [
                    { value: 'all', label: 'All Status' },
                    { value: 'success', label: 'Success' },
                    { value: 'fail', label: 'Fail' },
                ], keys: { value: 'value', label: 'label' } })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    doneLoaded={true}
                    // entriesLabel="(Total Registration For This Class)"
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderContent = () => {
        const { classes } = this.props;
        const { items, total } = this.reorganizeItems();
        return (
        <>
            {this.renderTableActions()}
            {this.renderPaymentHistory(items)}
            {this.renderPagination(total)}
        </>
        )
    }

    render() {
        const { open, trainee } = this.props;
        const { dataLoaded, openReconModal, modalData } = this.state;
        return (
        <>

            <ModalView 
                open={openReconModal}
                title={"Payment Reconciliation"+( modalData && modalData.id && !_isEmpty( modalData.id ) ? ' For Payment #'+modalData.id : '' )}
                maxWidth="md"
                onClose={() => this.setState({ openReconModal: false, modalData: false, traineeData: false })}
                disableBackdrop={true}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                actionLabel="Update"
                doAction={this.handleTraineesUpdate}
                contents={this.renderReconContent()} />

            <ModalView 
                open={open}
                title={"Payment History"+( trainee && trainee.name && !_isEmpty( trainee.name ) ? ' For '+trainee.name : '' )}
                maxWidth="lg"
                onClose={this.handleClose}
                cancelLabel="Close"
                disableBackdrop={true}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                noAction={true}
                actionLabel="Update"
                doAction={this.handleUpdate}
                contents={ dataLoaded ? this.renderContent() : <DotsLoader /> } />
        </>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles),
    withRouter
)(PaymentHistory);