/* eslint-disable */
import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import _isEmpty from "lodash/isEmpty";
import _sortBy from "lodash/sortBy";
import _forEach from "lodash/forEach";
import _reverse from "lodash/reverse";

import ModalView from "../../components/ModalView";
import Table from "../../components/Table";

import { triggerErrorAlert } from "../../helpers/alert";
import { isArrayExists } from "../../helpers/validation";
import { getMomentTime } from "../../helpers/date";

import { WrapWord } from "../../styles/misc";
import { AInfoLink, WarningButton, ButtonGroup } from "../../styles/button";

import theme from "../../theme";
import { PLATFORM_URL } from "../../constants";

const Wrapper = styled.div`
    margin-bottom: 60px;
`;

const Header = styled.h3`
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    color: ${theme.palette.background};
`;

const StudentBackpackProjects = ({ projects = [], badges = [], user = {}, authData, changesNotSaved }) => {
    const [sortBy, setSortBy] = useState("submission_grade-desc");
    const list = useMemo(() => {
        let submissions = [];
        _forEach(projects, (project) => {
            if (project.assignment_submissions && isArrayExists(project.assignment_submissions)) {
                project.assignment_submissions.forEach((submission) => {
                    if (submission.submission_url && !_isEmpty(submission.submission_url)) {
                        submissions.push({
                            ...submission,
                            id: project.lms_course_id + "-" + submission.id,
                            project_id: project.id,
                            badge_name: project.badge_name || "",
                            class_name: project.class_name || "",
                            course_name: project.course_name || "",
                            submission_date: submission.submission_date || "",
                            created_on: project.created_on + project.position,
                            lms_course_id: project.lms_course_id || "",
                            schdedule_id: project.schedule_id || "",
                        });
                    }
                });
            }
        });
        // get the top 5 based on grade
        submissions = _sortBy(submissions, ["submission_grade"]);
        submissions = _reverse(submissions);
        submissions = submissions.slice(0, 5);

        switch (sortBy) {
            case "submission_grade-desc":
                submissions = _reverse(_sortBy(submissions, ["submission_grade"]));
                break;
            case "submission_grade-asc":
                submissions = _sortBy(submissions, ["submission_grade"]);
                break;
            case "date-desc":
                submissions = _reverse(_sortBy(submissions, ["created_on"]));
                break;
            case "date-asc":
                submissions = _sortBy(submissions, ["created_on"]);
                break;
            default:
                break;
        }
        return submissions;
    }, [projects, sortBy]);

    return (
        <Wrapper>
            <Header>Top 5 Personal Best Projects:</Header>
            <Grid
                container
                spacing={2}
                alignItems="right"
                style={{
                    margin: "10px 0",
                }}
            >
                <Grid item xs={12} align="right">
                    <FormControl variant="outlined" style={{ background: "#fff", marginRight: "5px" }}>
                        <InputLabel style={{ background: "#fff", padding: "5px", color: theme.palette.background }}>Sort By</InputLabel>
                        <Select native value={sortBy} onChange={(event) => setSortBy(event.target.value)} input={<OutlinedInput name="sortby" />}>
                            <option value="submission_grade-desc">Grade High to Low</option>
                            <option value="submission_grade-asc">Grade Low to High</option>
                            <option value="date-desc">Recent First</option>
                            <option value="date-asc">Oldest First</option>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Table
                items={list}
                showCheckbox={false}
                emptyCell={"No project(s) found."}
                cells={[
                    { id: "class_name", label: "Class", render: (item) => <WrapWord>{item.class_name || "-"}</WrapWord> },
                    { id: "course_name", label: "Course", render: (item) => <WrapWord>{item.course_name || "-"}</WrapWord> },
                    { id: "name", label: "Assignment", render: (item) => <WrapWord>{item.name || "-"}</WrapWord> },
                    { id: "submission_grade", label: "Grade", render: (item) => <WrapWord>{item.submission_grade || "-"}</WrapWord> },
                    {
                        id: "submission_date",
                        label: "Date Submitted",
                        render: (item) => (
                            <>
                                <div>
                                    <WrapWord>
                                        {item.submission_date && !_isEmpty(item.submission_date)
                                            ? getMomentTime(item.submission_date, "YYYY-MM-DD")
                                            : "-"}
                                    </WrapWord>
                                </div>
                                <div>
                                    <WrapWord>
                                        {item.submission_date && !_isEmpty(item.submission_date)
                                            ? getMomentTime(item.submission_date, "hh:mm:ss a")
                                            : "-"}
                                    </WrapWord>
                                </div>
                            </>
                        ),
                    },
                ]}
                actionStyles={{ width: "15%" }}
                actions={(item) =>
                    item && item.submission_url && !_isEmpty(item.submission_url) ? (
                        <ButtonGroup>
                            <AInfoLink size="small" href={item.submission_url} target="_blank">
                                View
                            </AInfoLink>
                        </ButtonGroup>
                    ) : null
                }
            />
        </Wrapper>
    );
};

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        changesNotSaved: (state.misc && state.misc.changes_made) || null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(StudentBackpackProjects);
