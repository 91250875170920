/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _isEqual from 'lodash/isEqual';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader'
import SnackBarSave from '../../components/SnackBarSave';

import EditForm from './form';
import ItemNotFound from '../../components/ItemNotFound';

import { InfoButton, GreyButton } from '../../styles/button';

import { hasAccessRights } from '../../helpers/auth'; 
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { getPartners } from '../../actions/partners';
import { getCourse, editCourse } from '../../actions/courses';
import { getProgramsByProduct } from '../../actions/programs';
import { getMaintenance } from '../../actions/maintenance';
import { getLMSCourses } from '../../actions/canvas_lms';
import { appChangesMade, resetRedux } from '../../actions/misc';

import { courseSchema } from '../../schemas/course';

class CoursePage extends React.Component {

    state = {
        visibility_lock: true,
        currentTab: 'details',
        course: false,
        programs: false,
        randByProducts: false,
        randNum: false
    };

    componentDidMount() {
        const { authData, contactHourList, productLevelList, productCreditList, courseTypeList, partnersList, courseDurationList, feedbackFormsList, currencyList, rubricsList, lmsCoursesList, pricePerPaxList } = this.props;
        const { course_id } = this.props.match.params;
        
        // retrieve course details
        this.props.dispatch(getCourse(course_id));

        // retrieve programs by course id
        this.props.dispatch(getProgramsByProduct(course_id));

        // get contact hour
        if ( !contactHourList )
            this.props.dispatch(getMaintenance('contact_hour'));

        // get product level
        if ( !productLevelList )
            this.props.dispatch(getMaintenance('product_level'));

        // get product credit
        if ( !productCreditList )
            this.props.dispatch(getMaintenance('product_credit'));

        // get partners
        if ( !partnersList )
            this.props.dispatch( getPartners({ authData }) );

        // get course_type
        if ( !courseTypeList )
            this.props.dispatch(getMaintenance('course_type'));

        // get price per pax list
        if ( !pricePerPaxList )
            this.props.dispatch(getMaintenance('price_per_pax'));

        // get course duration
        if ( !courseDurationList )
            this.props.dispatch(getMaintenance('course_duration'));

        // get feedback_forms
        if ( !feedbackFormsList )
            this.props.dispatch(getMaintenance('feedback_forms'));

        // get currency
        if ( !currencyList )
            this.props.dispatch(getMaintenance('currency'));

        // get rubrics
        if ( !rubricsList )
            this.props.dispatch(getMaintenance('rubrics'));

        // get lms courses
        if ( !lmsCoursesList )
            this.props.dispatch(getLMSCourses());

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentCourse, randNum, 
            programsByProduct, randByProducts,
            contactHourList, productLevelList, productCreditList, partnersList, courseTypeList, courseDurationList, feedbackFormsList, currencyList, rubricsList, lmsCoursesList, pricePerPaxList 
            } = this.props;

        // for course
        if ( currentCourse && contactHourList && productLevelList && productCreditList && partnersList && courseTypeList && courseDurationList && feedbackFormsList && currencyList && rubricsList && lmsCoursesList && pricePerPaxList && randNum && randNum !== this.state.randNum ) {
            this.setState({ 
                course: cloneCollections( currentCourse ), 
                randNum, 
                programs: cloneCollections( programsByProduct ), 
                randByProducts 
            });
        } // end - currentCourse
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('course'));
        this.props.dispatch(resetRedux('programs_by_product'));
    }

    handleRefresh = (type) => {
        // const { course_id } = this.props.match.params;
        // switch( type ) {
        //     case 'emails':
        //         this.setState({ emails: false });
        //         this.props.dispatch(getEmailsBy('courses',course_id));
        //         break;
        // }
    }

    handleSaveChanges = (event) => {
        const { course } = this.state;
        event.preventDefault();
        var error = false,
            formData = {};

        // do error check
        courseSchema.forEach(schema => {
            formData[schema.id] = ( course && course[schema.id] ? cloneCollections( course[schema.id] ) : schema.default );
            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editCourse(formData));
            this.setState({ visibility_lock: true });
        } // end - error

    }

    handleFormUpdate = (newValue) => {
        this.setState({ course: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    // handleTabChange = (newTab) => {
    //     const { course_id } = this.props.match.params;
    //     const { history } = this.props;
    //     this.setState({ currentTab: newTab });
    // }

    // getLinks = () => {
    //     const { authData } = this.props;
    //     let links = [
    //         { id: 'details', label: 'Course Info', icon: 'fa fa-cog' },
    //         { id: 'personnel', label: 'Personnel Database', icon: 'fa fa-users' }
    //     ];

    //     return links;
    // }

    renderTabContents = () => {
        const { course_id } = this.props.match.params;
        const { authData, currentCourse, contactHourList, productLevelList, productCreditList, partnersList, courseTypeList, courseDurationList, feedbackFormsList, currencyList, rubricsList, lmsCoursesList, pricePerPaxList, changesMade } = this.props;
        const { currentTab, course, visibility_lock, programs } = this.state;
        const tabProps = {
            authData: ( authData || false ),
            course: ( course || false ),
            changesMade: ( changesMade || false ),
            onFormUpdate: this.handleFormUpdate
        };
        switch( currentTab ) {
            case 'details':
                return <EditForm 
                    formType="update"
                    currentCourse={( currentCourse || false )}
                    programs={programs}
                    price_per_pax={pricePerPaxList}
                    contact_hour={contactHourList}
                    product_level={productLevelList}
                    product_credit={productCreditList}
                    partners={( partnersList || false )}
                    course_type={( courseTypeList || false )}
                    course_duration={( courseDurationList || false )}
                    feedback_forms={( feedbackFormsList || false )}
                    currency={( currencyList || false )}
                    rubrics={( rubricsList || false )}
                    lms_courses={( lmsCoursesList || false )}
                    visibility_lock={( visibility_lock || false )}
                    onVisibilityUnlock={() => this.setState({ visibility_lock: false })}
                    // updateActions={[getCourse(course_id)]} 
                    {...tabProps} />;
            // case 'emails': 
            //     return ( emails ? <EmailsList
            //         type="courses"
            //         typeID={( course_id || false )}
            //         changesMade={changesMade}
            //         onRefresh={this.handleRefresh}
            //         {...tabProps} /> : <DotsLoader /> ); 
        }
    }

    // renderTabs = () => {
    //     const { currentTab } = this.state;
    //     const btnStyle = {
    //         textAlign: 'center',
    //         minWidth: '225px',
    //         padding: "10px 5px",
    //         marginRight: "5px"
    //     };
    //     return (
    //     <Box display="flex" justifyContent="center" alignItems="center">
    //         {this.getLinks().map((button,index) => {
    //             if ( button.id === currentTab) {
    //                 return <InfoButton key={index} style={btnStyle} onClick={event => event.preventDefault()}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</InfoButton>
    //             } else {
    //                 return <GreyButton key={index} style={btnStyle} onClick={this.handleTabChange.bind(this,button.id)}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</GreyButton>
    //             }
    //         })}
    //     </Box>
    //     )
    // }

    renderContents() {
        return (
        <div>

            {/* {this.renderTabs()} */}
            {this.renderTabContents()}

            <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
            </div>

            <SnackBarSave onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { course_id } = this.props.match.params;
        const { randNum } = this.state;
        const { currentCourse } = this.props;
        return <AppWrapper 
                title="Edit Product Profile"
                subtitle="Setup"
                back="/products"
                breadcrumbs={[
                    { url: '/products', label: 'Product' },
                    { label: 'Edit' }
                ]}
                maxWidth="1110px"
                onLoad={( !( randNum ) ? true : false )}
                contents={ currentCourse && currentCourse.id && !_isEmpty( currentCourse.id ) ? this.renderContents() : <ItemNotFound item="Course" />} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        currentCourse: state.courses && state.courses.course ? state.courses.course : null,
        randNum: state.courses && state.courses.rand ? state.courses.rand : null,
        programsByProduct: state.programs && state.programs.programs_by_product ? state.programs.programs_by_product : null,
        randByProducts: state.programs && state.programs.rand_by_products ? state.programs.rand_by_products : null,
        partnersList: state.partners && state.partners.partners ? state.partners.partners : null,
        courseTypeList: state.maintenance && state.maintenance.course_type ? state.maintenance.course_type : null,
        courseDurationList: state.maintenance && state.maintenance.course_duration ? state.maintenance.course_duration : null,
        feedbackFormsList: state.maintenance && state.maintenance.feedback_forms ? state.maintenance.feedback_forms : null,
        currencyList: state.maintenance && state.maintenance.currency ? state.maintenance.currency : null,
        rubricsList: state.maintenance && state.maintenance.rubrics ? state.maintenance.rubrics : null,
        lmsCoursesList: state.canvas_lms && state.canvas_lms.courses ? state.canvas_lms.courses : null,
        contactHourList: state.maintenance && state.maintenance.contact_hour ? state.maintenance.contact_hour : null,
        productLevelList: state.maintenance && state.maintenance.product_level ? state.maintenance.product_level : null,
        productCreditList: state.maintenance && state.maintenance.product_credit ? state.maintenance.product_credit : null,
        pricePerPaxList: state.maintenance && state.maintenance.price_per_pax ? state.maintenance.price_per_pax : null,
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(CoursePage);