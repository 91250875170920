/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import shortid from 'shortid';
import styled from "styled-components";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _size from 'lodash/size';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import _forEach from 'lodash/forEach';
import _trim from 'lodash/trim';

import Table from '../../components/Table';
import ModalView from '../../components/ModalView';
import ModalDelete from '../../components/ModalDelete';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';
import FormSelect2 from '../../components/FormSelect2';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormDatePicker from '../../components/FormDatePicker';
import FormCheckbox from '../../components/FormCheckbox';

import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, movePosition, calculatePosition, getSelectOptions, getSelectedValue } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton, SuccessButton } from '../../styles/button';

import { REGISTRATION_FORM_TYPES, YES_NO_OPTIONS, GENDER_OPTIONS } from '../../constants';

const TableWrapper = styled.div`
    display: block;
    padding: 30px 0px 0px 0px;

    .MuiPaper-elevation2 { box-shadow: none; border-radius: 0px; }
    .MuiTable-root { border: 1px solid #999; }
    .MuiTableCell-root { 
        border-color: #999; 
        padding: 8px; 
        &.MuiTableCell-head { 
            font-size: 14px; 
            background: #f1f1f1;
        }
    }
`;

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '14px',
        verticalAlign: 'top'
    }
});

class StudentFields extends React.Component {

    state = {
        openModal: false,
        modalType: false,
        selectItem: false
    };

    handleAction = () => {
        const { modalType, selectItem } = this.state;
        const { onChange, name, value, moveable } = this.props;
        const cells = this.getCells();

        switch( modalType ) {
            case 'new':
            case 'edit':
                // do error check
                var error = false;

                if ( cells && isArrayExists( cells ) ) {
                    _forEach( cells, cell => {
                        if ( this.isRequired(cell) ) {
                            if ( !( selectItem[cell.id] && !_isEmpty( selectItem[cell.id] ) ) )
                                error = 'Please fill out all the required field(s).'
                        } // end - required
                    });
                } // end - cells

                if ( error ) {
                    triggerErrorAlert(error);
                } else {
                    // add or update
                    var newValue = ( value ? cloneCollections( value ) : [] );
                    if ( modalType === 'new' ) {
                        newValue.push( selectItem );
                    } else {
                        let index = _findIndex( newValue, { id: selectItem.id } );
                        newValue[index] = selectItem;
                    } // end - modalType

                    // push updates
                    if ( onChange )
                        onChange( newValue, name );

                    // reset modal
                    this.setState({ openModal: true, modalType: false, selectItem: false });
                } // end - error
                break;
            case 'delete':
                if ( selectItem && selectItem.id && value && isArrayExists( value ) && _find( value, { id: selectItem.id }) ) {
                    var newValue = ( value ? cloneCollections( value ) : [] ),
                        pulled = _remove( newValue, { id: selectItem.id });

                    // if movable - recalculate position
                    if ( moveable ) {
                        newValue = calculatePosition( newValue );
                    } // end - movable

                    // push updates
                    if ( onChange )
                        onChange( newValue, name );

                    // reset modal
                    this.setState({ openModal: true, modalType: false, selectItem: false });
                } // end - selectItem
                break;
        } // end - modalType

    }

    handleFilterUpdated = ({ formData, newValue, key, item, cell, subkey, subVal}) => {
        switch ( key ) {
            case 'type':
                formData[ key ] = ( newValue || '' );
                let uniqueType = _find( REGISTRATION_FORM_TYPES, { id: newValue, unique: true, fixed: false, category: 'student' } );
                if ( uniqueType ) {
                    formData[ 'label' ] = ( uniqueType.label || '' );
                    if ( uniqueType.required ) {
                        formData[ 'required' ] = ( uniqueType.required || '' );
                    }  
                } else {
                    formData[ 'required' ] = 'no';
                }
                break;
            case 'required':
                formData[ key ] = ( newValue && newValue === 'yes' ? 'yes' : 'no' );
                if ( newValue === 'yes' ) {
                    formData[ 'hidden' ] = 'no';
                }
                break;
            default:
                formData[ key ] = ( newValue || '' );
                break;
        }
        return formData;
    }

    handleFormUpdate = (newValue,key,subkey,subVal) => {
        const { selectItem } = this.state;
        const cells = this.getCells();
        let newData = ( selectItem ? cloneCollections( selectItem ) : {}),
            cell = _find( cells, { id: key });
        // if need to update value externally
        if ( cell && cell.onValueUpdated ) {
            newData = cell.onValueUpdated({ formData: newData, newValue, key, item: selectItem, cell, subkey, subVal });
        } else {  
            if ( subkey && ( subkey === 'checked' || subkey === 'unchecked' ) && subVal && !_isEmpty( subVal ) ) {
                // use subVal as the new value
                newData[key] = subVal;
            } else {
                // otherwise - just update normally
                newData[key] = newValue;
            }
        } // end - newData[key] = newValue;
        this.setState({ selectItem: newData });
    }

    handlePositionMove = ( item, direction ) => event => {
        const { value, name, onChange } = this.props;
        let newValue = ( value ? cloneCollections( value ) : [] ),
            currentPosition = ( item.position ? item.position : 1 ),
            newPosition = ( direction && direction === 'up' ? currentPosition - 1 : currentPosition + 1 );

        // make sure new position don't exceed more that array size, and not below 1
        if ( newPosition > _size( value ) )
            newPosition = _size( value );

        if ( !newPosition )
            newPosition = 1;

        // move position
        newValue = movePosition( newValue, currentPosition, newPosition );

        // push updates
        if ( onChange )
            onChange( newValue, name );
    }

    isFixedField = (item) => {
        const selected = _find( REGISTRATION_FORM_TYPES, { id: item.type } );
        return ( selected && selected.fixed ? true : false );
    }

    isUniqueField = (item) => {
        const selected = _find( REGISTRATION_FORM_TYPES, { id: item.type } );
        return ( selected && selected.unique ? true : false );
    }

    getAvailableTypes = () => {
        const { value } = this.props;
        return REGISTRATION_FORM_TYPES.filter(t => {
            let valid = true;
            // make sure unique fields are not shown if already exist
            if ( t.unique ) {
                if ( _find( value, { type: t.id }) )
                    valid = false;
            }
            return valid;
        });
    }

    getCells = () => {
        const { modalType, selectItem } = this.state;
        const requiredTypes = REGISTRATION_FORM_TYPES.map(t => {
            if ( ( t.unique && !t.fixed ) || ( t.category && t.category === 'general' ) )
                return t.id;
        });
        return [
            { 
                id: 'label', 
                label: 'Label', 
                render: this.renderFieldValue('label'), 
                field: 'text', 
                required: true, 
                column: 12,
                onValueUpdated: this.handleFilterUpdated 
            },
            { 
                id: 'desc', 
                label: 'Description', 
                render: this.renderFieldValue('desc'), 
                field: 'textarea', 
                required: false, 
                column: 12,
                onValueUpdated: this.handleFilterUpdated 
            },
            { 
                id: 'type', 
                label: 'Type', 
                render: this.renderFieldValue('type'), 
                field: 'select', 
                required: true,
                // disabled: ( modalType && modalType === 'edit' ? ( this.isUniqueField( selectItem ) ? true : false ) : false ), 
                disabled: ( modalType && modalType === 'edit' ? true : false ), 
                column: 6,
                options: getSelectOptions({ list: ( modalType && modalType === 'new' ? this.getAvailableTypes() : REGISTRATION_FORM_TYPES ), options: [
                    { value: '', label: 'Select an Option' },
                    { value: 'section_title', label: 'Section Title' }
                ], keys: { value: 'id', label: 'label', sortBy: 'label' }}),
                onValueUpdated: this.handleFilterUpdated 
            },
            { 
                id: 'required', 
                label: 'Required', 
                render: this.renderFieldValue('required'), 
                field: 'select', 
                isRequired: ({ item,cell }) => {
                    return ( item && item.type && _find( requiredTypes, (t) => t === item.type ) ? true : false )
                }, 
                showOnly: [
                    { type: 'type', values: requiredTypes }
                ],
                column: 6,
                options: getSelectOptions({ list: YES_NO_OPTIONS, options: [
                    { value: '', label: 'Select an Option' }, 
                ], keys: { value: 'value', label: 'label', sortBy: 'label' }}),
                onValueUpdated: this.handleFilterUpdated 
            },
            { 
                id: 'hidden', 
                label: 'Hide Field', 
                render: this.renderFieldValue('hidden'), 
                field: 'select', 
                showOnly: [
                    { type: 'required', values: [ "no" ] }
                ],
                column: 6,
                options: getSelectOptions({ list: YES_NO_OPTIONS, options: [
                    { value: '', label: 'Select an Option' }, 
                ], keys: { value: 'value', label: 'label', sortBy: 'label' }}),
                onValueUpdated: this.handleFilterUpdated 
            },
            { 
                id: 'options', 
                label: 'Options', 
                render: this.renderFieldValue('options'), 
                field: 'select', 
                isRequired: ({ item,cell }) => ( item && item.type && ( item.type === 'radio' || item.type === 'select' ) ? true : false ), 
                showOnly: [
                    { type: 'type', values: ['select','radio'] }
                ],
                column: 12,
                options: [
                    { value: '', label: 'Select an Option' },
                    { value: 'template_yes_no_options', label: 'Yes, No' },
                    { value: 'custom_options', label: 'Custom Options' },
                ],
                onValueUpdated: this.handleFilterUpdated 
            },
            { 
                id: 'options_custom', 
                label: 'Fill in the custom options for this fields separated by comma or by line: (Ex: option 1,option 2,option 3)', 
                render: false, 
                field: 'textarea', 
                hideCell: true,
                isRequired: ({ item,cell }) => ( item && item.options && ( item.options === 'custom_options' ) ? true : false ), 
                showOnly: [
                    { type: 'options', values: ['custom_options'] }
                ],
                column: 12,
                onValueUpdated: this.handleFilterUpdated 
            },
        ];
    }

    getDefaultValue = () => {
        const { moveable, value } = this.props;
        const cells = this.getCells();
        var val = { id: shortid.generate() };

        // calculate position
        if ( moveable ) {
            val.position = ( value && isArrayExists( value ) ? _size( value ) : 0 ) + 1;
        } // end - moveable

        // populate cell value
        if ( cells && isArrayExists( cells ) ) {
            _forEach( cells, cell => {
                val[cell.id] = ( cell.defaultVal || '' );
            });
        } // end - cells

        return val;
    }

    getFieldprops = (cell) => {
        const { selectItem } = this.state;
        let props = {
            label: ( cell.label || '' ) + ( this.isRequired(cell) ? ' (required)' : '' ),
            name: ( cell.id || '' ),
            disabled: ( cell.disabled || false ),
            value: ( selectItem && cell.id && selectItem[cell.id] ? selectItem[cell.id] : '' ),
            options: ( cell.getOptions ? cell.getOptions(selectItem) : ( cell.options && isArrayExists( cell.options ) ? cell.options : null ) )
        };
        return props;
    }

    isFieldHidden = (cell) => {
        const { selectItem } = this.state;
        let hide = false;
        if ( cell.showOnly && isArrayExists( cell.showOnly ) ) {
            hide = true; // if showOnly found, set hide to true
            _forEach( cell.showOnly, item => {
                if ( item.type && !_isEmpty( item.type ) && item.values && isArrayExists( item.values ) && selectItem && selectItem[item.type] && !_isEmpty( selectItem[item.type] ) && _find( item.values, v => v === selectItem[item.type] ) ) {
                    hide = false;
                } // end - hidItem.type
            });
        } // end - cell.showOnly
        return hide;
    }

    isRequired = (cell) => {
        const { selectItem } = this.state;
        return ( cell && ( cell.required || ( cell.isRequired && cell.isRequired({ item: selectItem, cell }) ) ) ? true : false )
    }

    renderFieldValue = key => item => {
        switch (key) {
            case 'options':
                if ( item.options && item.options === 'template_yes_no_options' ) {
                    return 'Yes, No';
                // } else if ( item.options && item.options === 'template_high_options' ) {
                //     return 'Very High, High, Medium, Low, Very Low';
                // } else if ( item.options && item.options === 'template_good_options' ) {
                //     return 'Excellent, Good, Average, Poor, Very poor';
                } else if ( item.options && item.options === 'custom_options' ) {
                    let lines = ( item.options_custom && !_isEmpty( item.options_custom ) ? item.options_custom.split('\n') : [] ),
                        more = false;
                    if ( lines.length > 5 ) {
                        lines = lines.slice(0,5);
                        more = true;
                    }
                    return ( lines && isArrayExists( lines ) ? (
                    <>
                        {lines.map((string,index) => <div key={index} style={{ fontSize: '11px' }}>{string}</div>)}
                        { more ? <div style={{ fontSize: '11px' }}>...</div> : null }
                    </>
                    ) : '' );
                } else {
                    return '';
                }
            case 'required':
                return ( item.type && item.type === 'section_title' ? '---' : getSelectedValue( YES_NO_OPTIONS, item[key], 'value', 'label' ) );
            case 'type':
                return getSelectedValue( [ ...REGISTRATION_FORM_TYPES.filter(t => t.category && ( t.category === 'student' || t.category === 'general' )), { id: 'section_title', label: 'Section Title' } ] , item[key], 'id', 'label' );
            case 'hidden':
                return ( item[key] && item[key] === 'yes' ? 'Yes' : 'No' );
            default:
                return ( item && item[key] ? item[key] : '' );
        }
    }

    renderField = (cell) => {
        const { selectItem } = this.state;
        if ( cell && cell.field && !_isEmpty( cell.field ) ) {
            let field = this.getFieldprops( cell );
            switch( cell.field ) {
                case 'text':
                    return <FormInput {...field} onChange={this.handleFormUpdate} />;
                case 'textarea':
                    return <FormInput {...field} rows={4} multiline={true} onChange={this.handleFormUpdate} />;
                case 'text_number':
                    return <FormInput {...field} type="number" onChange={this.handleFormUpdate} />;
                case 'email':
                    return <FormInput {...field} type="email" onChange={this.handleFormUpdate} />;
                case 'select2':
                    return <div style={{ paddingTop: "10px" }}><FormSelect2 placeholder="Select an Option" {...field} onChange={this.handleFormUpdate} /></div>;
                case 'select':
                    return <div style={{ paddingTop: "10px" }}><FormSelect {...field} onChange={this.handleFormUpdate} /></div>;
                case 'radio':
                    return <div style={{ paddingTop: "10px" }}><FormRadio {...field} inline={true} onChange={this.handleFormUpdate} /></div>;
                case 'multiselect':
                    return <div style={{ paddingTop: "10px" }}><FormMultiSelect {...field} onChange={this.handleFormUpdate} /></div>;
                case 'datepicker':
                    return <FormDatePicker {...field} noDefaultVal={true} onChange={this.handleFormUpdate} />;
                case 'checkbox':
                    return <FormCheckbox {...field} checked={( cell.checked ? cell.checked : 'yes' )} unchecked={( cell.unchecked ? cell.unchecked : 'no' )} onChange={this.handleFormUpdate} />;
                case 'custom':
                    return ( cell.renderCustomField ? cell.renderCustomField({ item: selectItem, field, cell, onChange: this.handleFormUpdate }) : null );
            }
        } // end - cell.field_type
    }

    renderModal = () => {
        const cells = this.getCells();
        return (
        <div>
            <Grid container spacing={1}>
                { cells && isArrayExists( cells ) ? _map( cells, (cell) => ( this.isFieldHidden(cell) ? null : <Grid key={cell.id} item xs={( cell.column || 12 )}>{this.renderField(cell)}</Grid> ) ) : null }
            </Grid>
        </div>
        );
    }

    renderAddNewButton = () => {
        const { onAction, isDisabled } = this.props;
        return (
        <div style={{ padding: '10px 0 0 5px' }}>
            <InfoButton key="select" disabled={( isDisabled ? 'yes' : false )} size="small" onClick={() => this.setState({ openModal: true, modalType: 'new', selectItem: this.getDefaultValue() })} style={{ borderRadius: '25px', padding: '8px 25px' }}><i className="fa fa-plus"></i>Add New</InfoButton>
        </div>
        )
    }

    renderTable = () => {
        const { value, onAction, isDisabled } = this.props;
        const { moveable } = this.props;
        const cells = this.getCells();
        return <Table 
            items={( value && isArrayExists( value ) ? ( moveable ? _sortBy( value, ['position'] ) : value ) : [] )}
            showCheckbox={false}
            cells={ cells && isArrayExists( cells ) ? cells : [] }
            //emptyCell="abc"
            actionStyles={{ width: "15%" }}
            actions={(item) => (
                <ButtonGroup>
                    <InfoButton key="add" disabled={( isDisabled || this.isFixedField(item) ? 'yes' : false )} size="small" minWidth="none" noIconMargin="yes" onClick={() => this.setState({ openModal: true, modalType: 'edit', selectItem: item })}><i className="fa fa-edit"></i></InfoButton>
                    { moveable ? <SuccessButton key="moveup" size="small" minWidth="none" noIconMargin="yes" disabled={( isDisabled || ( item.position && item.position < 2 ) ? 'yes' : false )} onClick={this.handlePositionMove(item,'up')}><i className="fa fa-angle-up"></i></SuccessButton> : null }
                    { moveable ? <SuccessButton key="movedown" size="small" minWidth="none" noIconMargin="yes" disabled={( isDisabled || ( item.position && item.position >= _size( value ) ) ? 'yes' : false )} onClick={this.handlePositionMove(item,'down')}><i className="fa fa-angle-down"></i></SuccessButton> : null }
                    <ErrorButton key="delete" disabled={( isDisabled || this.isFixedField(item) ? 'yes' : false )} size="small" minWidth="none" noIconMargin="yes" onClick={() => this.setState({ openModal: true, modalType: 'delete', selectItem: item })}><i className="fa fa-trash"></i></ErrorButton>
                </ButtonGroup>
            )} />
    }

    render() {
        const { id, label } = this.props;
        const { openModal, modalType } = this.state;
        var newID = 'multi-options-' + _random(1,9999);
        return (
        <div>

            <FormControl fullWidth={true}>
                <InputLabel shrink={true} htmlFor={( id || newID )} style={{ fontSize: "16px", fontWeight: "700", textTransform: "uppercase" }}>{label || '' }</InputLabel>
                <TableWrapper>
                    {this.renderTable()}
                    {this.renderAddNewButton()}
                </TableWrapper>
            </FormControl>

            <ModalView 
                open={( openModal && modalType && ( modalType === 'new' || modalType === 'edit' ) ? true : false )}
                title={( openModal && modalType && modalType === 'edit' ? 'Edit Field' : 'Add New Field' )}
                actionLabel={( openModal && modalType && modalType === 'edit' ? 'Update' : 'Add New' )}
                cancelLabel="Close"
                doAction={this.handleAction}
                onClose={() => this.setState({ openModal: true, modalType: false, selectItem: false })}
                contents={this.renderModal()} />

            <ModalDelete
                open={( openModal && modalType && modalType === 'delete' ? true : false )}
                title="Are you sure you want to delete this item?"
                onClose={() => this.setState({ openModal: true, modalType: false, selectItem: false })}
                onDelete={this.handleAction} />

        </div>
        )
    }

}

export default compose(
    withStyles(useStyles)
)(StudentFields);