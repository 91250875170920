/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_TRAINEES,
	GET_CLASS_CONFIRMATION,
	UPDATE_CLASS_CONFIRMATION,
	TRIGGER_CLASS_EMAIL,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getClassConfirmation, fb_updateClassConfirmationEmail, fb_triggerPreviewEmail, fb_triggerConfirmationEmail, fb_triggerReminderEmail, fb_triggerTrialEmail } from './fb';
import { fb_getTrainees } from '../trainees/fb';

export const getClassConfirmation = (id) => {
		return dispatch => {

		fb_getClassConfirmation(id)
		.then(confirmation => {
			dispatch({
				type: GET_CLASS_CONFIRMATION,
				payload: { confirmation }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_CLASS_CONFIRMATION,
				payload: { confirmation: {} }
			});
		})

	}
}


export const updateClassConfirmationEmail = (formData,props) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_updateClassConfirmationEmail(formData)
		.then( results => {
			fb_getClassConfirmation(formData.id)
			.then(confirmation => {
				// push new data
				dispatch({ type: GET_CLASS_CONFIRMATION, payload: { confirmation } });	
				// updated
				dispatch({ type: UPDATE_CLASS_CONFIRMATION });
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("Confirmation Email Updated");
			})
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const triggerPreviewEmail = (formData,props) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_triggerPreviewEmail(formData)
		.then( results => {
			// updated
			dispatch({ type: TRIGGER_CLASS_EMAIL });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Confirmation Email Sent To Your Inbox - It might takes up to 5 minutes to reach your inbox",4000);
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const triggerConfirmationEmail = (formData,props) => {
	return dispatch => {
		dispatch(toggleLoader(true,"The sending process is on-going! Please do not close this window or click the Back button on your browser"));

		fb_triggerConfirmationEmail(formData)
		.then( results => {
			fb_getTrainees(props)
			.then(list => {
				// push new data
				dispatch({ type: GET_TRAINEES, payload: { list } });	
				// updated
				dispatch({ type: TRIGGER_CLASS_EMAIL });
				dispatch(toggleLoader(false));
				triggerSuccessAlert("Confirmation Email Sent! It might takes up to 5 minutes to reach students inbox.",4000);
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const triggerReminderEmail = (formData,props) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));
		dispatch(toggleLoader(true));

		fb_triggerReminderEmail(formData)
		.then( results => {
			fb_getTrainees(props)
			.then(list => {
				// push new data
				dispatch({ type: GET_TRAINEES, payload: { list } });	
				// updated
				dispatch({ type: TRIGGER_CLASS_EMAIL });
				dispatch(toggleLoader(false));
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("Reminder Email Sent! It might takes up to 5 minutes to reach students inbox.",4000);
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const triggerTrialEmail = (formData,props) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));
		dispatch(toggleLoader(true));

		fb_triggerTrialEmail(formData)
		.then( results => {
			fb_getTrainees(props)
			.then(list => {
				// push new data
				dispatch({ type: GET_TRAINEES, payload: { list } });	
				// updated
				dispatch({ type: TRIGGER_CLASS_EMAIL });
				dispatch(toggleLoader(false));
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("Trial Email Sent! It might takes up to 5 minutes to reach students inbox.",4000);
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}