/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import _size from 'lodash/size';
// import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
// import _slice from 'lodash/slice';
// import _split from 'lodash/split';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _trim from 'lodash/trim';
// import _replace from 'lodash/replace';
// import _remove from 'lodash/remove';
// import _map from 'lodash/map';

import { doCSVDownload } from '../../modules/TeamsTable/export';

import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
// import ModelDelete from '../../components/ModalDelete';
// import ModalView from '../../components/ModalView';
// import FormRadio from '../../components/FormRadio';
// import FormInput from '../../components/FormInput';
// import FormSelect from '../../components/FormSelect';

import { InfoButton, ErrorButton, ButtonGroup, InverseButton } from '../../styles/button';
// import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, getSelectOptions } from '../../helpers/data';
// import { getMomentTime } from '../../helpers/date';
import { isAdmin, hasAccessRights } from '../../helpers/auth';

import { addNewMaintenance, updateMaintenance, deleteMaintenance } from '../../actions/maintenance';

import { STATE_OPTIONS } from '../../data/const_states';
import { getMomentTime } from '../../helpers/date';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});

const ActiveTag = styled.div`
    display: inline-block;
    padding: 5px 8px;
    margin: 0px 5px 5px 0px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 6px;
    color: #fff;
    background: ${props => (props.active && props.active == 'yes' ? green['500'] : grey['500'])};
`;

class List extends React.Component {
    
    state = {
        sortBy: 'start_date-asc',
        perPage: 20,
        page: 1,
    }

    reorganizeData() {
        const { sortBy, perPage, page } = this.state;
        const { list } = this.props;
        var items = ( list ? cloneCollections( list ) : [] ),
            total = _size( items );

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'class_name-desc':
                    items = _sortBy( items, [ i => i.class_name && i.class_name.toLowerCase() || '' ] );
                    items = _reverse( items );
                    break;
                case 'class_name-asc':
                    items = _sortBy( items, [ i => i.class_name && i.class_name.toLowerCase() || '' ] );
                    break;
                case 'partner_name-desc':
                    items = _sortBy( items, [ i => i.partner_name && i.partner_name.toLowerCase() || '' ] );
                    items = _reverse( items );
                    break;
                case 'partner_name-asc':
                    items = _sortBy( items, [ i => i.partner_name && i.partner_name.toLowerCase() || '' ] );
                    break;
                case 'start_date-desc':
                    items = _sortBy( items, ['start_date'] );
                    items = _reverse( items );
                    break;
                case 'start_date-asc':
                    items = _sortBy( items, ['start_date'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderTableActions = () => {
        const { sortBy, perPage, searchterms } = this.state;
        return <TableBar
                show={['sort','entries']}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'start_date-asc', label: 'Open Day Date ( most recent first )' },
                    { value: 'start_date-desc', label: 'Open Day Date ( most recent last )' },
                    { value: 'partner_name-asc', label: 'Partner ( A - Z)' },
                    { value: 'partner_name-desc', label: 'Partner ( Z - A )' },
                    { value: 'class_name-asc', label: 'Class Name ( A - Z)' },
                    { value: 'class_name-desc', label: 'Class Name ( Z - A )' },
                    // { value: 'position-asc', label: 'Position (smallest number first)' },
                    // { value: 'position-desc', label: 'Position (biggest number first)' }
                ]}
                rightButtons={null}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    doneLoaded={true}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderBody = (items) => {
        const { classes, authData, history } = this.props;
        return (
        <TableBody>
            { items && isArrayExists( items ) ? items.map(item => {
                return (
                <TableRow key={item.id}>
                    <TableCell className={classes.bodycell} style={{ width: '30%' }}>{getMomentTime( item.start_date, 'YYYY-MMM-DD hh:mma' )}</TableCell>
                    <TableCell className={classes.bodycell} style={{ width: '40%' }}>{item.partner_name || ''}</TableCell>
                    <TableCell className={classes.bodycell} style={{ width: '30%' }}><a href={"/schedules/"+item.schedule_id} onClick={(event) => {
                        event.preventDefault();
                        history.push( "/schedules/"+item.schedule_id );
                    }}>{item.class_name || ''}</a></TableCell>
                </TableRow>
                )
            }) : (
                <TableRow>
                    <TableCell className={classes.bodycell}>No item(s) found.</TableCell>
                </TableRow>
            ) }
        </TableBody>
        )
    }

    renderHeader = () => {
        const { classes } = this.props;
        return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headcell}>Open Day</TableCell>
                <TableCell className={classes.headcell}>Partner</TableCell>
                <TableCell className={classes.headcell}>Class Name</TableCell>
                {/* <TableCell className={classes.headcell}>Actions</TableCell> */}
            </TableRow>
        </TableHead>
        )
    }

    render() {
        const { items, total } = this.reorganizeData();
        return (
        <div>

            {this.renderTableActions()}
            <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                <Table>
                    {this.renderHeader()}
                    {this.renderBody(items)}
                </Table>
            </Paper>
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(List);