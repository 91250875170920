/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
// import moment from "moment";
// import Grid from "@material-ui/core/Grid";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import Tooltip from "@material-ui/core/Tooltip";
import _isEmpty from "lodash/isEmpty";
import _sortBy from "lodash/sortBy";

import { useNotifications } from "./hooks";

import { isArrayExists } from "../../helpers/validation";
import { triggerErrorAlert } from "../../helpers/alert";
import { cloneCollections, getSelectOptions, getSelectValues } from "../../helpers/data";
import { callFunctionsAPI } from "../../helpers/action";

import { WrapWord } from "../../styles/misc";
import { AInfoLink, WarningButton, InverseButton, IndigoButton } from "../../styles/button";
import { InfoTag } from "../../styles/tag";

import { PLATFORM_URL } from "../../constants";

const Wrapper = styled.div`
    display: inline-flex;
    height: 64px;
    align-items: center;
    justify-content: center;
    padding: 0 0 0 16px;
`;

const IconWrapper = styled.div`
    position: relative;
    i {
        cursor: pointer;
        font-size: 24px;
    }
`;

const IconRedDot = styled.div`
    position: absolute;
    top: -10px;
    right: -10px;
    width: 16px;
    height: 16px;
    border-radius: 20%;
    background-color: red;
    color: white;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
`;

const NotificationsIcon = ({ authData, history }) => {
    const changesMade = useSelector((state) => state.misc.changes_made);
    const loaded = useSelector((state) => state.notifications.rand);
    const [notifications] = useNotifications(authData);

    const pendingNotificationsNum = useMemo(() => {
        let count = 0;
        if (isArrayExists(notifications)) {
            notifications.forEach((notification) => {
                if (!(notification.marked_as_completed === "yes" || notification.marked_as_read === "yes")) {
                    count++;
                }
            });
        }
        return count;
    }, [notifications]);

    return (
        <Wrapper
            style={
                !loaded
                    ? {
                          opacity: 0.25,
                          pointerEvents: "none",
                      }
                    : {}
            }
        >
            <IconWrapper>
                {loaded && pendingNotificationsNum && pendingNotificationsNum > 0 ? <IconRedDot>{pendingNotificationsNum}</IconRedDot> : null}
                <i
                    className="fa fa-bell"
                    onClick={() => {
                        if (changesMade) {
                            triggerErrorAlert("Please save your changes before viewing notifications.");
                        } else {
                            history.push("/dashboard");
                        }
                    }}
                />
            </IconWrapper>
        </Wrapper>
    );
};

export default compose(withRouter)(NotificationsIcon);
