/* eslint-disable */
import React, { useState } from 'react';
import styled from "styled-components";
import red from '@material-ui/core/colors/red';
import Typography from '@material-ui/core/Typography';

import theme from '../../theme';

import { getAPIErrorMessage } from '../../helpers/action';

const ErrorMessage = ({
    error = false,
    errorMessage = ''
}) => {
    return (
        <Typography variant="h4" style={{ color: red['700'], textAlign: "center", background: red['50'], padding: '20px', border: '2px solid '+ red['700'] }}>{( error ? getAPIErrorMessage(error) : errorMessage )}</Typography>
    )
}

export default ErrorMessage