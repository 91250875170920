/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import _isEmpty from "lodash/isEmpty";
// import _merge from 'lodash/merge';
// import _size from 'lodash/size';
// import _isEqual from 'lodash/isEqual';

import AppWrapper from "../../components/AppWrapper";
import AppInfoBox from "../../modules/AppInfoBox";
import Table from "./table";

import { isAdmin, getUserRegionFilter } from "../../helpers/auth";
// import { cloneCollections } from '../../helpers/data';

import { getMarketingsList } from "../../actions/database_marketing";
import { getRegions } from "../../actions/m_regions";
// import { getMaintenance } from '../../actions/maintenance';
import { resetRedux } from "../../actions/misc";

import { ButtonGroup, AInfoLink } from "../../styles/button";
import { InfoBox } from "../../styles/message";

const TableWrapper = styled.div`
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
`;

class DatabaseMarketingPage extends React.Component {
    state = {
        dataLoaded: false,
    };

    componentDidMount() {
        const { authData, marketingDBList, regionsList } = this.props;

        // get marketing database
        if (!marketingDBList) this.props.dispatch(getMarketingsList({ region: getUserRegionFilter(authData) }));

        // get regions
        if (!regionsList) this.props.dispatch(getRegions());

        if (regionsList && marketingDBList) {
            this.setState({ dataLoaded: true });
        } // end - randNum
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { marketingDBList, regionsList } = this.props;
        if (marketingDBList && regionsList && !this.state.dataLoaded) {
            this.setState({ dataLoaded: true });
        } // end - randNum
    }

    renderTable() {
        const { authData, marketingDBList, regionsList } = this.props;
        return <Table list={marketingDBList} regions={regionsList} authData={authData} />;
    }

    renderContents() {
        const { marketingDBList } = this.props;
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                        <AppInfoBox page="DatabaseMarketingPage" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {" "}
                    </Grid>
                </Grid>
                <TableWrapper>{this.renderTable()}</TableWrapper>
            </>
        );
    }

    render() {
        const { dataLoaded } = this.state;
        const { authData } = this.props;
        return (
            <AppWrapper
                subtitle="1. Recruitment"
                title="Marketing Database"
                onLoad={!dataLoaded ? true : false}
                contents={authData ? this.renderContents() : null}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        marketingDBList: state.database_marketing && state.database_marketing.list ? state.database_marketing.list : null,
        randNum: state.database_marketing && state.database_marketing.rand ? state.database_marketing.rand : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
    };
};

export default compose(connect(mapStateToProps), withRouter)(DatabaseMarketingPage);
