/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _split from 'lodash/split';
import _endsWith from 'lodash/endsWith';

import ParentObserver  from '../DatabaseStudentPage/parent_observer';
import LinkedAccounts  from '../DatabaseStudentPage/linked_accounts';

import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormInputCopy from '../../components/FormInputCopy';
import FormSelect from '../../components/FormSelect';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormDatePicker from '../../components/FormDatePicker';
import FormPhoneField from '../../components/FormPhoneField';
import FormDateOfBirth from '../../components/FormDateOfBirth';

import { FormBox } from '../../styles/form';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { getMomentTime, getDateNumFromString, isParentFieldRequired } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';

import { addParentObverser, removeParentObverser } from '../../actions/students';

import { userStudentSchema } from '../../schemas/user_student';
import { COUNTRIES_LIST } from '../../data/const_countries';
import { STATE_OPTIONS } from '../../data/const_states';

import { YES_NO_OPTIONS, GENDER_OPTIONS, PLATFORM_BACKPACK_URL } from '../../constants';

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

class EditProfileForm extends React.Component {

    state = {
        randNum: false
    };

    handleFormUpdate = ( newValue, key, subkey, subVal ) => {
        const { onFormUpdate, student } = this.props;
        let newData = ( student && !_isEmpty( student ) ? cloneCollections( student ) : {} );

        switch( key ) {
            case 'date_of_birth_string':
                newData[key] = newValue;
                newData['date_of_birth'] = subVal;
                break;
            default:
                newData[key] = newValue;
                break;
        }

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    getFieldOptions = (field) => {
        const { student, roles } = this.props;

        switch( field.name ) {
            case 'status':
                return student[field.name] && student[field.name] === 'pending' ? [{value:'pending',label: 'Pending' }] : [
                    {value:'active',label: 'Active'},
                    {value:'disabled',label: 'Disabled'},
                ];
            case 'gender':
                return GENDER_OPTIONS;
            default:
                return [];
        }
    }

    getFieldValue = (field) => {
        const { student } = this.props;
        switch( field.name ) {
            default:
                return ( student && student[field.name] ? student[field.name] : ( field.default || '' ) );
        }
    }

    requiredParentDetails = (key) => {
        const { student } = this.props;
        let required = false;
        switch (key) {
            case 'parent_name':
            case 'parent_email':
            case 'parent_phone':
            case 'parent_city':
                required = ( student && student.date_of_birth_string && !_isEmpty( student.date_of_birth_string ) && isParentFieldRequired( student.date_of_birth_string ) ? true : false );
                break;
        }
        return required;
    }

    getTooltip = (schema) => {
        switch( schema.id ) {
            case 'parent_email':
                return (
                <>
                    ALLOW PARENT TO ACCESS LMS<br />
                    We allow parents to observe their child’s learning progress on LMS. Upon confirmation of Program, parent should receive an email with password to access LMS with the Observer role.
                </>
                );
            case 'about_me':
                return (
                <>
                 This will reflect in your Backpack which public can view. 
                </>
                );
            case 'eportfolio':
                return (
                <>
                    A e-portfolio is a digital resume of past projects, showcasing student’s accomplishments, skills, and capabilities.<br /><br />
                    This will reflect in your Backpack which public can view.
                </>
                );
            default:
                return;
        }
    }

    isFieldDisabled = (schema) => {
        const { authData, student } = this.props;
        var disabled = false;

        if ( schema && schema.disabled && isArrayExists( schema.disabled ) ) {
            schema.disabled.forEach( condition => {
                switch( condition ) {
                    case 'admin':
                        if ( !isAdmin( authData ) )
                            disabled = true;
                        break;
                    case 'update':
                        disabled = true;
                        break;
                    case 'self':
                        // special condition for status - disable it if it's the current user
                        if ( authData && student && authData.uid === student.uid ) {
                            disabled = true;
                        } // end - authData
                        break;
                }
            });
        } // end - schema

        // special condition for parent_email
        if ( 'parent_email' === schema.id ) {
            if ( student && student.observer_email && !_isEmpty( student.observer_email ) && student.parent_email && student.parent_email ===  student.observer_email ) {
                disabled = true;
            }
        }

        return disabled
    }

    getField = (id) => {
        const { massEditMode } = this.props;
        let schema = _find( userStudentSchema, { id } );
        return ( schema ? {
            name: ( schema.id || '' ),
            label: ( schema.label || '' ) + ( ( this.requiredParentDetails(schema.id) || isSchemaRequired(schema,'update') ) && !massEditMode ? ' (Required)' : '' ),
            field_type: ( schema.field || '' ),
            default: ( massEditMode ? null : ( schema.default || null ) ),
            disabled: ( schema.disabled || 'parent_email' === schema.id ? this.isFieldDisabled( schema ) : false ),
            tooltip: this.getTooltip(schema)
        } : null );
    }

    renderField = (id) => {
        let field = this.getField(id);
        if ( field && field.field_type && !_isEmpty( field.field_type ) ) {
            switch( field.field_type ) {
                case 'text':
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'textarea':
                    return <FormInput {...field} rows={( 'notes' === id ? 6 : 3 )} multiline={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'text_number':
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'email':
                    return <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'select':
                    return <div style={{ paddingTop: "14px" }}><FormSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} /></div>;
                case 'multiselect':
                    return <div style={{ paddingTop: "14px" }}><FormMultiSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} /></div>;
                case 'datepicker':
                    return <FormDatePicker {...field} noDefaultVal={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'phone':
                    return <FormPhoneField {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />
                case 'select_date':
                    return <FormDateOfBirth {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />  
            }
        } // end - field.field_type
    }

    render = () => {
        const { classes, student, authData, changesMade } = this.props;
        return (
        <FormBox>
            <Grid container spacing={3}>

                {/* <Grid item xs={12} sm={6}>{this.renderField('name')}</Grid> */}
                <Grid item xs={12}>{this.renderField('email')}</Grid>
                <Grid item xs={12}>{this.renderField('name')}</Grid>
                <Grid item xs={12}>{this.renderField('date_of_birth_string')}</Grid>
                <Grid item xs={12} sm={6}>{this.renderField('gender')}</Grid>
                <Grid item xs={12} sm={6}>{this.renderField('phone')}</Grid>
                <Grid item xs={12}>{this.renderField('org')}</Grid>
                <Grid item xs={12}>{this.renderField('about_me')}</Grid>
                <Grid item xs={12}>{this.renderField('eportfolio')}</Grid>
                <Grid item xs={12}>
                    <FormInputCopy label="Badges Backpack" value={PLATFORM_BACKPACK_URL+student.uid} />
                </Grid>

                <Grid item xs={12}><Divider style={{ marginTop: '10px', marginBottom: '10px' }} /></Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="h4" className={classes.boxheading}>Parent / Guardian Details</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>{this.renderField('parent_name')}</Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={10}>
                                    {this.renderField('parent_email')}
                                </Grid>
                                <Grid item xs={2} align="center">
                                    <ParentObserver 
                                        student={student}
                                        authData={authData}
                                        onAddObserver={(student) => {
                                            this.props.dispatch( addParentObverser({ uid: student.uid }) );
                                        }}
                                        onRemoveObserver={(student) => {
                                            this.props.dispatch( removeParentObverser({ uid: student.uid }) );
                                        }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>{this.renderField('parent_phone')}</Grid>
                        <Grid item xs={12}>{this.renderField('parent_city')}</Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="h4" className={classes.boxheading}>Teacher Details</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>{this.renderField('teacher_name')}</Grid>
                        <Grid item xs={12}>{this.renderField('teacher_email')}</Grid>
                        <Grid item xs={12}>{this.renderField('teacher_phone')}</Grid>
                        <Grid item xs={12}>{this.renderField('teacher_org')}</Grid>
                    </Grid>
                </Grid>

                <LinkedAccounts
                    student={student}
                    authData={authData}
                    changesMade={changesMade} />

            </Grid>

        </FormBox>
        );
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(EditProfileForm);