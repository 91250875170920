/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _filter from "lodash/filter";
import _find from "lodash/find";
import _size from "lodash/size";
import _isEqual from "lodash/isEqual";
import _findIndex from "lodash/findIndex";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";

import AppWrapper from "../../components/AppWrapper";
import AppInfoBox from "../../modules/AppInfoBox";
import ScheduleSwitcher from "../../components/ScheduleSwitcher";
import SnackBarSave from "../../components/SnackBarSave";
import Table from "./table";
import ClassFilterMenu from "../ClassRegistrationPage/filter_menu";
import ColumnsMenu from "./columns_menu";

import { isAdmin, getUserRegionFilter } from "../../helpers/auth";
import { cloneCollections } from "../../helpers/data";
import { getMomentTime } from "../../helpers/date";
import { isArrayExists } from "../../helpers/validation";

import { updateClassAttendanceReporting } from "../../actions/class_attendance_reporting";
import { getTrainees } from "../../actions/trainees";
// import { getCoursesOptions } from '../../actions/courses';
import { getSchedulesOptions, getSchedule } from "../../actions/schedules";
import { getRegions } from "../../actions/m_regions";
import { getMentors } from "../../actions/mentors";
// import { getMaintenance } from '../../actions/maintenance';
import { resetRedux } from "../../actions/misc";

import { ButtonGroup, AInfoLink, InfoButton } from "../../styles/button";
import { SuccessTag } from "../../styles/tag";
import { WarningBox, InfoBox } from "../../styles/message";

import { ENROLLMENT_STATUS_OPTIONS } from "../../constants";

const TableWrapper = styled.div`
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
`;

class ClassAttendanceReportingPage extends React.Component {
    utcOffSet = 8;

    state = {
        trainees: false,
        scheduleRand: false,
        currentSchedule: false,
        columns: [],
        dataLoaded: false,
    };

    componentDidMount() {
        const { authData, regionsList, schedulesList, mentorsList, location } = this.props;

        // preload trainees
        if (location && location.state && location.state.schedule_id && !_isEmpty(location.state.schedule_id))
            this.handleFilter(location.state.schedule_id);

        // get regions
        if (!regionsList) this.props.dispatch(getRegions());

        // get course options
        // if ( !coursesList )
        //     this.props.dispatch(getCoursesOptions({ region: getUserRegionFilter(authData) }));

        // get schedule options
        if (!schedulesList) this.props.dispatch(getSchedulesOptions({ region: getUserRegionFilter(authData) }));

        // get mentor list
        if (!mentorsList) this.props.dispatch(getMentors({ region: getUserRegionFilter(authData) }));

        if (regionsList && schedulesList && mentorsList) {
            this.setState({ dataLoaded: true });
        } // end - randNum
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { traineesList, regionsList, schedulesList, mentorsList, currentScheduleData, scheduleRand } = this.props;
        if (regionsList && schedulesList && mentorsList && !this.state.dataLoaded) {
            this.setState({ dataLoaded: true });
        } // end - randNum

        if (currentScheduleData && traineesList && scheduleRand && scheduleRand !== this.state.scheduleRand) {
            this.setState({
                scheduleRand,
                trainees: traineesList && isArrayExists(traineesList) ? cloneCollections(traineesList) : [],
                columns: this.getDefaultColumns(currentScheduleData),
            });
        } // end - scheduleRand
    }

    componentWillUnmount() {
        this.handleReset();
    }

    handleReset = () => {
        this.props.dispatch(resetRedux("trainees"));
        this.props.dispatch(resetRedux("schedule"));
        this.props.dispatch(resetRedux("classes"));
    };

    handleFilter = (schedule_id) => {
        const { authData } = this.props;
        this.setState({ currentSchedule: schedule_id, columns: [] });
        this.props.dispatch(getTrainees({ schedule_id, enrollment_status: this.getEnrollmentStatusFilter() }, true));
        this.props.dispatch(getSchedule(schedule_id, { authData }));
    };

    handleSaveChanges = (event) => {
        const { traineesList } = this.props;
        const { trainees, currentSchedule } = this.state;
        event.preventDefault();
        let formData = { schedule_id: currentSchedule, trainees: [] };
        // run through all the trainees to look for changes
        if (trainees && isArrayExists(trainees) && traineesList && isArrayExists(traineesList)) {
            _forEach(trainees, (trainee) => {
                let oriTrainee = _find(traineesList, { id: trainee.id });
                if (!_isEqual(trainee, oriTrainee)) {
                    formData.trainees.push({
                        id: trainee.id,
                        mentor_comments: trainee.mentor_comments || "",
                        mentor_comments_updated: trainee.mentor_comments_updated || 0,
                        mentor_comments_updated_by: trainee.mentor_comments_updated_by || "",
                        attendances: trainee.attendances || [],
                    });
                }
            });
        } // end - trainees

        this.props.dispatch(
            updateClassAttendanceReporting(formData, {
                schedule_id: currentSchedule,
                enrollment_status: this.getEnrollmentStatusFilter(),
            })
        );
    };

    getEnrollmentStatusFilter = () => {
        let statuses = [];
        ENROLLMENT_STATUS_OPTIONS.forEach((option) => {
            if (option.value && !_isEmpty(option.value) && option.value !== "delete" && option.value !== "registered" && option.value !== "trial")
                statuses.push(option.value);
        });
        return statuses;
    };

    getDefaultColumns = (schedule) => {
        let columns = [],
            pastEvents = [],
            futureEvents = [],
            today = moment().utcOffset(this.utcOffSet).startOf("date").valueOf();

        if (schedule && schedule.class_calendar && isArrayExists(schedule.class_calendar)) {
            pastEvents = _filter(schedule.class_calendar, { type: "past_class" });
            // sort & reverse
            if (pastEvents && isArrayExists(pastEvents)) {
                pastEvents = _sortBy(pastEvents, ["start_date"]);
                pastEvents = _reverse(pastEvents);
            }

            futureEvents = _filter(schedule.class_calendar, { type: "class" });
            // sort
            if (futureEvents && isArrayExists(futureEvents)) {
                futureEvents = _sortBy(futureEvents, ["start_date"]);
            }
        } // end - schedule.class_calendar

        for (let p = 0; p < 4; p++) {
            if (pastEvents && pastEvents[p] && pastEvents[p].id && !_isEmpty(pastEvents[p].id)) {
                let passEvent = cloneCollections(pastEvents[p]);
                passEvent.label = passEvent.start_date ? getMomentTime(passEvent.start_date, "DD-MMM-YY") : passEvent.id;
                columns.push(passEvent);
            }
        }

        for (let p = 0; p < 2; p++) {
            if (futureEvents && futureEvents[p] && futureEvents[p].id && !_isEmpty(futureEvents[p].id)) {
                let futureEvent = cloneCollections(futureEvents[p]);
                futureEvent.label = futureEvent.start_date ? getMomentTime(futureEvent.start_date, "DD-MMM-YY") : futureEvent.id;
                columns.push(futureEvent);
            }
        }

        return columns;
    };

    renderTable() {
        const { authData, traineesList, regionsList, schedulesList, mentorsList, currentScheduleData } = this.props;
        const { currentSchedule, trainees, columns } = this.state;
        return (
            <Table
                editMode="admin"
                columns={columns || false}
                schedule={currentScheduleData || false}
                currentSchedule={currentSchedule || false}
                trainees={trainees}
                currentTrainees={traineesList}
                schedules={schedulesList}
                regions={regionsList}
                mentors={mentorsList}
                // courses={coursesList}
                authData={authData}
                onUpdate={(newTrainees) => this.setState({ trainees: newTrainees })}
            />
        );
    }

    renderTrackingInfo = () => {
        const { currentScheduleData, traineesList } = this.props;
        const { columns } = this.state;
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AppInfoBox page="ClassAttendanceReportingPage" />
                    <Typography variant="h5" style={{ paddingBottom: "15px" }}>
                        Current Class Size: {_size(traineesList) ? <SuccessTag style={{ fontSize: "16px" }}>{_size(traineesList)}</SuccessTag> : 0}
                    </Typography>
                    {/* <WarningBox>
                    <i className="fa fa-lightbulb-o"></i>Licensee will have to use own accounting system to create student’s invoices and reconcile payment.
                </WarningBox> */}
                </Grid>
                <Grid item xs={12}>
                    <ColumnsMenu
                        schedule={currentScheduleData || false}
                        columns={columns || false}
                        onUpdate={(newColumns) => this.setState({ columns: newColumns })}
                    />
                </Grid>
            </Grid>
        );
    };

    renderContents() {
        const { traineesList, currentScheduleData } = this.props;
        const { currentSchedule, scheduleRand, trainees } = this.state;
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                        <ClassFilterMenu currentSchedule={currentSchedule || false} onFilter={this.handleFilter} />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        {currentScheduleData && scheduleRand ? this.renderTrackingInfo() : null}
                    </Grid>
                </Grid>

                {traineesList ? (
                    <TableWrapper>
                        {this.renderTable()}
                        {trainees && isArrayExists(trainees) ? (
                            <div style={{ textAlign: "center", marginTop: "45px" }}>
                                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}>
                                    <i className="fa fa-save"></i>Save Changes
                                </InfoButton>
                            </div>
                        ) : null}
                    </TableWrapper>
                ) : null}

                <SnackBarSave onSave={this.handleSaveChanges} />
            </>
        );
    }

    render() {
        const { dataLoaded, currentSchedule } = this.state;
        const { authData } = this.props;
        return (
            <AppWrapper
                subtitle="3. Delivery"
                title="Attendance & Reporting"
                onLoad={!dataLoaded ? true : false}
                customColumn={
                    currentSchedule && !_isEmpty(currentSchedule) ? (
                        <ScheduleSwitcher page="class_attendance_reporting" schedule_id={currentSchedule} authData={authData} />
                    ) : null
                }
                contents={authData ? this.renderContents() : null}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        traineesList: state.trainees && state.trainees.trainees ? state.trainees.trainees : null,
        traineesRand: state.trainees && state.trainees.rand ? state.trainees.rand : null,
        currentScheduleData: state.schedules && state.schedules.schedule ? state.schedules.schedule : null,
        scheduleRand: state.schedules && state.schedules.rand ? state.schedules.rand : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        // coursesList: state.courses && state.courses.courses_options ? state.courses.courses_options : false,
        schedulesList: state.schedules && state.schedules.schedules_options ? state.schedules.schedules_options : false,
        mentorsList: state.mentors && state.mentors.list ? state.mentors.list : null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(ClassAttendanceReportingPage);
