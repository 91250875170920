/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists, validateEmail, isObjectExists } from '../../helpers/validation';
import { getSnapshotDataBySchema, getDocDataBySchema } from '../../helpers/schemas';

import { marketingDatabaseSchema } from '../../schemas/marketing_database';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getMarketingList = (callback,props = {}) => {
    const { region } = props;

    let query = firebase.database().ref("marketing_list");

    if ( region && !_isEmpty( region ) ) {
        query = query.orderByChild("region").equalTo(region);
    } // end - region

    query.on("value",snapshot => {
        let list = [];
        if ( snapshot && snapshot.exists() && snapshot.hasChildren() ) {
            snapshot.forEach(childSnapshot => {
                let item = getSnapshotDataBySchema( marketingDatabaseSchema, childSnapshot );
                list.push( item );
            });
        } // end - snapshot
        callback(list);
    });
}

export const fb_addMarketingData = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'marketingDB', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateMarketingData = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'marketingDB', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteMarketingData = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'marketingDB', action: 'delete', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_importMarketingData = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'marketingDB', action: 'import', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}