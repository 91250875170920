/* eslint-disable */
import _random from 'lodash/random';

import { 
    CLMS_GET_COURSES 
} from '../actions/types';

const initialState = {
    courses: null,
    rand: false
};

export const canvas_lms = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case CLMS_GET_COURSES:
            return {
                ...state,
                courses: ( payload.courses || [] ),
                rand: _random(1,9999)
            };
        default:
            return state;
    }
}