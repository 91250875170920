/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import _isEmpty from "lodash/isEmpty";
import _merge from "lodash/merge";
import _size from "lodash/size";
import _isEqual from "lodash/isEqual";
import _filter from "lodash/filter";

import EmailSender from "./email";

import AppWrapper from "../../components/AppWrapper";
import AppInfoBox from "../../modules/AppInfoBox";
import ScheduleSwitcher from "../../components/ScheduleSwitcher";
import Table from "./table";
import ConfirmationMenu from "./confirmation_menu";
import ClassFilterMenu from "../ClassRegistrationPage/filter_menu";

import { isAdmin, getUserRegionFilter } from "../../helpers/auth";
import { cloneCollections } from "../../helpers/data";
import { isArrayExists } from "../../helpers/validation";

import { getTrainees } from "../../actions/trainees";
// import { getCoursesOptions } from '../../actions/courses';
import { getSchedulesOptions, getSchedule } from "../../actions/schedules";
import { getClassConfirmation, triggerConfirmationEmail } from "../../actions/class_confirmation";
import { getRegions } from "../../actions/m_regions";
import { getMaintenance } from "../../actions/maintenance";
import { resetRedux } from "../../actions/misc";

import { ButtonGroup, AInfoLink } from "../../styles/button";
import { InfoBox } from "../../styles/message";
import { SuccessTag, InfoTag } from "../../styles/tag";

import { ENROLLMENT_STATUS_OPTIONS } from "../../constants";

const TableWrapper = styled.div`
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
`;

class ClassConfirmationPage extends React.Component {
    state = {
        currentSchedule: false,
        selected: [],
        email_open: false,
        email_list: false,
        email_type: "reminder",
        dataLoaded: false,
    };

    componentDidMount() {
        const { authData, regionsList, schedulesList, emailTemplatesList, location } = this.props;

        // preload trainees
        if (location && location.state && location.state.schedule_id && !_isEmpty(location.state.schedule_id))
            this.handleFilter(location.state.schedule_id);

        // get regions
        if (!regionsList) this.props.dispatch(getRegions());

        // get course options
        // if ( !coursesList )
        //     this.props.dispatch(getCoursesOptions({ region: getUserRegionFilter(authData) }));

        // get schedule options
        if (!schedulesList) this.props.dispatch(getSchedulesOptions({ region: getUserRegionFilter(authData) }));

        // get email templates
        if (!emailTemplatesList) this.props.dispatch(getMaintenance("email_templates"));

        if (regionsList && schedulesList && emailTemplatesList) {
            this.setState({ dataLoaded: true });
        } // end - randNum
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { regionsList, schedulesList, emailTemplatesList } = this.props;
        if (regionsList && schedulesList && emailTemplatesList && !this.state.dataLoaded) {
            this.setState({ dataLoaded: true });
        } // end - randNum
    }

    componentWillUnmount() {
        this.handleReset();
    }

    handleReset = () => {
        this.props.dispatch(resetRedux("trainees"));
        this.props.dispatch(resetRedux("classes"));
        this.props.dispatch(resetRedux("schedule"));
    };

    handleFilter = (schedule_id) => {
        const { authData } = this.props;
        this.setState({ currentSchedule: schedule_id });
        this.props.dispatch(getTrainees({ schedule_id, enrollment_status: this.getEnrollmentStatusFilter() }, true));
        this.props.dispatch(getClassConfirmation(schedule_id));
        this.props.dispatch(getSchedule(schedule_id, { authData, getScheduleUsage: true }));
    };

    handleSendConfirmation = (formData) => {
        const { currentSchedule } = this.state;
        this.props.dispatch(
            triggerConfirmationEmail(formData, { schedule_id: currentSchedule, enrollment_status: this.getEnrollmentStatusFilter() })
        );
    };

    getEnrollmentStatusFilter = () => {
        let statuses = [];
        ENROLLMENT_STATUS_OPTIONS.forEach((option) => {
            if (option.value && !_isEmpty(option.value) && option.value !== "delete") statuses.push(option.value);
        });
        return statuses;
    };

    getCurrentClassSize = (callback) => {
        const { traineesList } = this.props;
        const activeStudents = traineesList && isArrayExists(traineesList) ? _filter(traineesList, (t) => (callback(t) ? true : false)) : [];
        return _size(activeStudents);
    };

    renderTable() {
        const { authData, traineesList, randNum, regionsList, schedulesList } = this.props;
        const { currentSchedule } = this.state;
        return (
            <Table
                currentSchedule={currentSchedule || false}
                trainees={traineesList}
                traineesRand={randNum}
                schedules={schedulesList}
                regions={regionsList}
                // courses={coursesList}
                authData={authData}
                onSelectedChange={(newValue) => this.setState({ selected: newValue })}
                onEmailSend={({ trainees, email_type }) => this.setState({ email_open: true, email_list: trainees, email_type })}
            />
        );
    }

    renderContents() {
        const { traineesList, emailTemplatesList, currentConfirmation, currentScheduleData } = this.props;
        const { currentSchedule, selected, email_open, email_list, email_type } = this.state;
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <ClassFilterMenu currentSchedule={currentSchedule || false} onFilter={this.handleFilter} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={12}>
                                <AppInfoBox page="ClassConfirmationPage" />
                            </Grid>
                        </Grid>
                        {currentSchedule && !_isEmpty(currentSchedule) && currentConfirmation ? (
                            <ConfirmationMenu
                                confirmation={currentConfirmation}
                                selected={selected}
                                trainees={traineesList}
                                currentSchedule={currentSchedule || false}
                                currentScheduleData={currentScheduleData}
                                email_templates={emailTemplatesList}
                                onSendConfirmation={this.handleSendConfirmation}
                                onTrialEmail={({ trainees }) =>
                                    this.setState({ email_open: true, email_list: trainees, email_type: "trial_invitation_email" })
                                }
                            />
                        ) : null}
                    </Grid>
                </Grid>

                {traineesList && currentScheduleData ? (
                    <>
                        <Typography variant="h5">
                            Number of Registered Students:{" "}
                            <SuccessTag style={{ fontSize: "16px" }}>
                                {currentScheduleData && currentScheduleData.usage_trainees_status
                                    ? _size(currentScheduleData.usage_trainees_status)
                                    : 0}
                            </SuccessTag>
                        </Typography>
                        <Typography variant="h5">
                            Number of Confirmed Students:{" "}
                            <SuccessTag style={{ fontSize: "16px" }}>
                                {this.getCurrentClassSize((t) => (t && t.enrollment_status && t.enrollment_status === "confirmed" ? true : false))}
                            </SuccessTag>
                        </Typography>
                    </>
                ) : null}

                {traineesList ? <TableWrapper>{this.renderTable()}</TableWrapper> : null}

                <EmailSender
                    open={email_open}
                    trainees={email_list || false}
                    email_type={email_type || false}
                    schedule_id={currentSchedule || false}
                    enrollment_status={this.getEnrollmentStatusFilter()}
                    traineesList={traineesList}
                    email_templates={emailTemplatesList}
                    onClose={() => this.setState({ email_open: false, email_list: false, email_type: "reminder" })}
                />
            </>
        );
    }

    render() {
        const { dataLoaded, currentSchedule } = this.state;
        const { authData } = this.props;
        return (
            <AppWrapper
                subtitle="2. Enrolment"
                title="Confirmation"
                onLoad={!dataLoaded ? true : false}
                customColumn={
                    currentSchedule && !_isEmpty(currentSchedule) ? (
                        <ScheduleSwitcher page="class_confirmation" schedule_id={currentSchedule} authData={authData} />
                    ) : null
                }
                contents={authData ? this.renderContents() : null}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        currentConfirmation: state.classes && state.classes.confirmation ? state.classes.confirmation : null,
        traineesList: state.trainees && state.trainees.trainees ? state.trainees.trainees : null,
        randNum: state.trainees && state.trainees.rand ? state.trainees.rand : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        emailTemplatesList: state.maintenance && state.maintenance.email_templates ? state.maintenance.email_templates : null,
        currentScheduleData: state.schedules && state.schedules.schedule ? state.schedules.schedule : null,
        // coursesList: state.courses && state.courses.courses_options ? state.courses.courses_options : false,
        schedulesList: state.schedules && state.schedules.schedules_options ? state.schedules.schedules_options : false,
    };
};

export default compose(connect(mapStateToProps), withRouter)(ClassConfirmationPage);
