/* eslint-disable */
import React, { useState } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Grid from "@material-ui/core/Grid";
// import _isEmpty from 'lodash/isEmpty';
// import _merge from 'lodash/merge';
// import _size from 'lodash/size';
// import _isEqual from 'lodash/isEqual';

// import { cloneCollections } from '../../helpers/data';
import { isArrayExists } from "../../helpers/validation";

import { InfoBox } from "../../styles/message";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        boxShadow: "none",
        marginBottom: "10px",
        "&::before": {
            display: "none",
        },
    },
    summaryRoot: {
        minHeight: "32px",
        "&.Mui-expanded": {
            minHeight: "32px",
        },
        "& .MuiAccordionSummary-content": {
            margin: "0",
        },
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    details: {
        paddingTop: "0",
    },
}));

const InformationBox = ({ headling, contents = [] }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    return (
        <>
            <div
                style={{
                    margin: "10px 0 5px 0",
                }}
            >
                {headling}
            </div>
            {contents && isArrayExists(contents)
                ? contents.map((item) => (
                      <Accordion
                          className={classes.root}
                          expanded={expanded === item.id}
                          onChange={() => {
                              setExpanded(expanded === item.id ? false : item.id);
                          }}
                      >
                          <AccordionSummary className={classes.summaryRoot}>
                              <Grid alignItems="center" container spacing={4}>
                                  {expanded === item.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                  <span style={{ paddingLeft: "5px" }}>💡</span>
                                  <span style={{ paddingLeft: "5px", fontWeight: "700" }}>{item.title}</span>
                              </Grid>
                          </AccordionSummary>
                          <AccordionDetails className={classes.details}>
                              <div>{item.content}</div>
                          </AccordionDetails>
                      </Accordion>
                  ))
                : null}
        </>
    );
};

export default InformationBox;
