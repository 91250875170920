/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';
import _toString from 'lodash/toString';

import ModalView from '../../components/ModalView';
import MediaLibrary from '../../components/MediaLibrary';
import FormInput from '../../components/FormInput';
import FormEditor from '../../components/FormEditor';
import EmailShortcodes from '../../components/EmailShortcodes';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, convertArrayToObject, convertObjectToArray } from '../../helpers/data';

import { updateClassQuarterlyReport } from '../../actions/class_quarterly_reports';

// import { PLATFORM_COURSE_REGISTRATION_LINK } from '../../constants';

const FieldWrapper = styled.div`
    margin-bottom: 10px;

    h4 {
        color: #212121;
        font-size: 20px;
        font-weight: 700;
        &:after { display: block; height: 2px; width: 50px; content: " "; background: #212121; }
    }

    h5 {
        color: #212121;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 4px;
        text-transform: none;
    }
`;

class EditReportModal extends React.Component {

    state = {
        modalData: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { quarterly_report, schedule, open } = this.props;
        if ( open && !prevProps.open ) {
            this.setState({ modalData: this.getInitialModalData( quarterly_report, schedule ) });
        } // end - open
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose )
            onClose();
    }

    handleEmailUpdate = () => {
        const { schedule } = this.props;
        const { modalData } = this.state;
        let error = false,
            formData = {
                id: ( schedule && schedule.id ? schedule.id : '' ),
                email_title: ( modalData.email_title && !_isEmpty( modalData.email_title ) ? modalData.email_title : '' ),
                email_content: ( modalData.email_content && !_isEmpty( modalData.email_content ) ? modalData.email_content : '' ),
                email_attachments: ( modalData.email_attachments && isObjectExists( modalData.email_attachments ) ? modalData.email_attachments : {} )
            };

        // check for error
        if ( !( formData && formData.id && !_isEmpty( formData.id ) ) )
            error = 'Unknown Error - Missing Schedule ID';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateClassQuarterlyReport(formData));
        } // end - error
        
    }

    handleFormUpdate = (newValue,key,status) => {
        const { modalData } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} );
        newData[key] = newValue;
        this.setState({ modalData: newData });
    }

    getInitialModalData = (quarterly_report, schedule) => {
        return {
            id: ( schedule.id || '' ),
            email_title: ( quarterly_report.email_title && !_isEmpty( quarterly_report.email_title ) ? quarterly_report.email_title : '' ),
            email_content: ( quarterly_report.email_content && !_isEmpty( quarterly_report.email_content ) ? quarterly_report.email_content : '' ),
            email_attachments: ( quarterly_report.email_attachments && isObjectExists( quarterly_report.email_attachments ) ? quarterly_report.email_attachments : {} )
        };
    }

    renderContent = () => {
        const { modalData } = this.state;
        return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    
                </Grid>
                <Grid item xs={12}>
                    <MediaLibrary 
                        attachable={true}
                        attachments={( modalData && modalData.email_attachments && isObjectExists( modalData.email_attachments ) ? convertObjectToArray(modalData.email_attachments) : [] )}
                        onAttachmentUpdate={(medias) => this.handleFormUpdate(convertArrayToObject(medias),'email_attachments')} />
                </Grid>
                <Grid item xs={9}>
                    <FormInput label="Subject Line" name="email_title" value={( modalData.email_title || '' )} onChange={this.handleFormUpdate} />
                    <FormEditor 
                        id={( modalData && modalData.id && !_isEmpty( modalData.id ) ? modalData.id : false )}
                        name="email_content"
                        value={( modalData && modalData.email_content && !_isEmpty( modalData.email_content ) ? modalData.email_content : '' )} 
                        height={600} 
                        onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={3}>
                    <EmailShortcodes email_type="send_report" />
                </Grid>
            </Grid>
        </>
        )
    }


    render() {
        const { open } = this.props;
        return (
        <>

            <ModalView 
                open={open}
                title="Edit Quaterly Report Email Draft"
                maxWidth="lg"
                onClose={this.handleClose}
                disableBackdrop={true}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                actionLabel="Update"
                doAction={this.handleEmailUpdate}
                contents={this.renderContent()} />

        </>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(EditReportModal);