/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import red from '@material-ui/core/colors/red';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';

import ModalView from '../../components/ModalView';
import MediaLibrary from '../../components/MediaLibrary';
import FormInput from '../../components/FormInput';
import FormEditor from '../../components/FormEditor';
import EmailShortcodes from '../../components/EmailShortcodes';
import ToolTipInfo from '../../components/ToolTipInfo';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections, convertArrayToObject, convertObjectToArray } from '../../helpers/data';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getStudentEnrollmentStatus, checkForDuplication } from '../../helpers/trainees';

import { ButtonGroup, AInfoLink, InfoButton, SuccessButton, InverseButton, IndigoButton } from '../../styles/button';

import { updateClassConfirmationEmail, triggerPreviewEmail } from '../../actions/class_confirmation';
import { getMomentTime } from '../../helpers/date';

// import { PLATFORM_COURSE_REGISTRATION_LINK } from '../../constants';

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
    border: 2px solid #212121;
`;

const useStyles = theme => ({
    tooltip: {
        fontSize: '14px',
    },
    emailModal: {
        '& .MuiFilledInput-input.Mui-disabled, & .MuiInputBase-root.Mui-disabled, & .MuiFormLabel-root.Mui-disabled': {
            background: '#f1f1f1',
            color: '#212121'
        }
    }
});

class ConfirmationMenu extends React.Component {
    
    state = {
        cc_parent: 'yes',
        cc_principle: 'yes',
        cc_coordinator: 'yes',
        openConfirmation: false,
        confirmationData: false,
        openEmail: false,
        emailAttachments: false,
        emailType: false,
        openResync: false
    };

    handleTrialInvitation = () => {
        const { authData, currentSchedule, selected, trainees, onTrialEmail } = this.props;
        // make sure selected students wasn't already confirmed
        let error = false,
            list = [];

        if ( !( selected && isArrayExists( selected ) ) ) {
            error = 'Please select at least one student';
        }

        if ( selected && isArrayExists( selected ) && trainees && isArrayExists( trainees ) ) {
            _forEach( selected, item => {
                let trainee = _find( trainees, { id: item.id } );
                if ( trainee && trainee.enrollment_status && ( trainee.enrollment_status === 'registered' || trainee.enrollment_status === 'trial' ) ) {
                    list.push({ id: trainee.id, email: ( trainee.email || '' ) });
                } else {
                    error = 'Please select only "Registered" or "Trial" student(s).'
                }
            });
        } // end - selected

        if ( error ) {
            triggerErrorAlert( error );
        } else {
            if ( onTrialEmail )
                onTrialEmail({ trainees: list })
        }
    }

    handleEmailTrigger = () => {
        const { authData, currentSchedule, selected, confirmation, onSendConfirmation } = this.props;
        const { cc_parent, cc_principle, cc_coordinator, emailAttachments } = this.state;
        const { emailType } = this.state;
        if ( emailType && emailType === 'confirmation_email' ) {
            const students = this.getSelectedTrainees();
            if ( onSendConfirmation ) {
                onSendConfirmation({
                    schedule_id: currentSchedule,
                    trainees: selected,
                    cc_parent, 
                    cc_principle, 
                    cc_coordinator,
                    email_attachments: ( emailAttachments || false )
                });
            }
            this.setState({ openEmail: false, emailType: false, emailAttachments: false });
        } else if ( emailType && emailType === 'preview_email' ) {
            this.props.dispatch( triggerPreviewEmail({
                schedule_id: currentSchedule,
                email: ( authData && authData.email ? authData.email : '' )
            }) );
        } // end - emailType
    }

    handleConfirmationUpdate = () => {
        const { confirmationData } = this.state;
        let error = false,
            formData = ( confirmationData ? {
                // id: confirmationData.id,
                // email_title: ( confirmationData && confirmationData.email_title || '' ),
                // email_content: ( confirmationData && confirmationData.email_content || '' ),
                // email_attachments: ( confirmationData && confirmationData.email_attachments && isObjectExists( confirmationData.email_attachments ) ? confirmationData.email_attachments : false ),
                ...confirmationData,
                modified_on: false,
                modified_by: false
            } : {} );
        // check for error
        if ( !( confirmationData && confirmationData.id && !_isEmpty( confirmationData.id ) ) )
            error = 'Unknown Error - Missing Schedule ID';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateClassConfirmationEmail(formData));
        } // end - error

    }

    handleEmailResync = () => {
        const { email_templates, currentSchedule } = this.props;
        const { confirmationData } = this.state;
        let error = false,
            email_template = ( email_templates && isArrayExists( email_templates ) ? _find( email_templates, { id: 'confirmation_email' }) : false ),
            formData = {
                id: currentSchedule,
                email_title: ( email_template && email_template.subject && !_isEmpty( email_template.subject ) ? email_template.subject : '' ),
                email_content: ( email_template && email_template.template && !_isEmpty( email_template.template ) ? email_template.template : '' ),
                email_attachments: ( email_template && email_template.attachments && isObjectExists( email_template.attachments ) ? email_template.attachments : false ),
                modified_on: 'NAN'
            };

        // check for error
        if ( !( formData && formData.id && !_isEmpty( formData.id ) ) )
            error = 'Unknown Error - Missing Schedule ID';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateClassConfirmationEmail(formData));
        } // end - error
    }

    handleFormUpdate = (newValue,key,status) => {
        const { confirmationData } = this.state;
        let newData = ( confirmationData ? cloneCollections( confirmationData ) : {} );
        newData[key] = newValue;
        this.setState({ confirmationData: newData });
    }

    emailNotModifiedYet = () => {
        const { confirmation } = this.props;
        return ( confirmation && confirmation.modified_on && confirmation.modified_on === 'NAN' );
    }

    isDuplicatesFound = () => {
        const { trainees } = this.props;
        let duplicates = false
        const students = this.getSelectedTrainees();
        if ( students && isArrayExists( students ) ) {
            _forEach( students, student => {
                if ( checkForDuplication( trainees, student.email ) )
                    duplicates = true;
            })
        } // end - students
        return duplicates;
    }

    getSelectedTrainees = () => {
        const { selected, trainees } = this.props;
        let list = [];
        if ( selected && isArrayExists( selected ) && trainees && isArrayExists( trainees ) ) {
            _forEach( selected, item => {
                let trainee = _find( trainees, { id: item.id } );
                if ( trainee ) {
                    list.push( trainee );
                } // end - trainee
            });
        } // end - selected
        return list;
    }

    getStudentsEmails = () => {
        const students = this.getSelectedTrainees();
        let emails = '';
        if ( students && isArrayExists( students ) ) {
            _forEach( students, student => {
                emails += ( !_isEmpty( emails ) ? '\n' : '' ) + ( student.email || '' );
            });
        } // end - students
        return emails;
    }

    renderResyncWarning = () => {
        const { authData, classes } = this.props;
        return (
        <div className={classes.emailModal}>
            <Typography variant="body1" style={{ fontSize: '18px', marginBottom: '10px' }}>Resync this email template to the latest version.</Typography>
            <Typography variant="body1" style={{ fontSize: '18px', marginBottom: '10px' }}>Your customised edits from earlier will be removed.</Typography>
            <Typography variant="body1" style={{ fontSize: '18px', color: red['500'], fontWeight: 'bold' }}>This action cannot be undone.</Typography>
        </div>
        )
    }

    renderPreviewEmail = () => {
        const { authData, classes } = this.props;
        return (
        <div className={classes.emailModal}>
            <Typography variant="h5">A Preview Email will be sent to the following email address:</Typography>
            <FormInput type="email" disabled={true} value={( authData && authData.email ? authData.email : '' )} />
        </div>
        )
    }

    renderConfirmationEmail = () => {
        const { currentSchedule, confirmation, classes } = this.props;
        const { emailAttachments } = this.state;
        return (
        <div className={classes.emailModal}>
            <Typography variant="h5">Please confirm if everything is correct:</Typography>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <FormInput
                        label="Students' Emails"
                        multiline={true}
                        row={9} 
                        disabled={true} 
                        value={this.getStudentsEmails()} />
                </Grid>
                <Grid item xs={9}>
                    <div style={{ border: '2px solid #212121', marginBottom: '2px', padding: '10px 20px' }}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} sm={1}>
                                CC:
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                {this.renderCheckBox("Parent",'cc_parent')}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                {this.renderCheckBox("Principal",'cc_principle')}
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                {this.renderCheckBox("Coordinator",'cc_coordinator')}
                            </Grid>
                        </Grid>
                    </div>
                    <MediaLibrary 
                        attachable={true}
                        attachments={( emailAttachments && isObjectExists( emailAttachments ) ? convertObjectToArray(emailAttachments) : [] )}
                        onAttachmentUpdate={(medias) => this.setState({ emailAttachments: convertArrayToObject(medias) })} />
                    <FormEditor 
                        id={"confirmation_email"}
                        name="email_content"
                        value={( confirmation && confirmation.email_content && !_isEmpty( confirmation.email_content ) ? confirmation.email_content : '' )} 
                        disabled={true}
                        height={600} />
                </Grid>
            </Grid>
        </div>
        )
    }

    renderConfirmationForm = () => {
        const { confirmationData } = this.state;
        return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MediaLibrary 
                        attachable={true}
                        attachments={( confirmationData && confirmationData.email_attachments && isObjectExists( confirmationData.email_attachments ) ? convertObjectToArray(confirmationData.email_attachments) : [] )}
                        onAttachmentUpdate={(medias) => this.handleFormUpdate(convertArrayToObject(medias),'email_attachments')} />
                </Grid>
                <Grid item xs={9}>
                    <FormInput label="Subject Line" name="email_title" value={( confirmationData.email_title || '' )} onChange={this.handleFormUpdate} />
                    <FormEditor 
                        id={( confirmationData && confirmationData.id && !_isEmpty( confirmationData.id ) ? confirmationData.id : false )}
                        name="email_content"
                        value={( confirmationData && confirmationData.email_content && !_isEmpty( confirmationData.email_content ) ? confirmationData.email_content : '' )} 
                        height={600} 
                        onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={3}>
                    <EmailShortcodes email_type="confirmation_email" />
                </Grid>
            </Grid>
        </>
        )
    }

    renderCheckBox = ( label, key, disabled ) => {
        return <FormControlLabel
                control={
                    <Checkbox
                        checked={( this.state[key] && this.state[key] === 'yes' ? true : false )}
                        onChange={(event) => {
                            if ( !disabled )
                                this.setState({ [key]: ( event.target.checked ? 'yes' : 'no' ) })
                        }}
                        value={this.state[key]}
                        color="primary" />
                }
                label={label} />
    }

    renderTrialBox = () => {
        return (
        <Wrapper style={{ marginBottom: '15px' }}>
            <Typography variant="h5" style={{ marginBottom: '20px' }}>
                <ToolTipInfo content={
                    <>
                        TRIAL CLASS CONCEPT <br /><br />
                        Understand trial class operation on <a href="https://www.notion.so/chumbaka/2-Enrolment-Trial-Class-1e4815fa5a084bb6a1624bd65cab0158" target="_blank">Notion</a>.  
                    </>
                    } />
                Trial Class
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={7}> </Grid>
                <Grid item xs={12} sm={5}>
                    <IndigoButton style={{ padding: '5px 12px' }} onClick={this.handleTrialInvitation}><i className="fa fa-envelope-o"></i>Send Trial Invitation Email</IndigoButton>
                </Grid>
            </Grid>
        </Wrapper>
        )
    }

    renderContent = () => {
        const { confirmation, selected, classes, currentScheduleData } = this.props;
        return (
        <Wrapper>
            <Typography variant="h5" style={{ marginBottom: '20px' }}>Confirmation Email</Typography>
            {/* <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={1}>
                    CC:
                </Grid>
                <Grid item xs={12} sm={3}>
                    {this.renderCheckBox("Parent",'cc_parent')}
                </Grid>
                <Grid item xs={12} sm={3}>
                    {this.renderCheckBox("Principal",'cc_principle')}
                </Grid>
                <Grid item xs={12} sm={5}>
                    {this.renderCheckBox("Coordinator",'cc_coordinator')}
                </Grid>
            </Grid> */}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                    <div style={{ marginBottom: '10px' }}>
                        <ButtonGroup>
                            <InfoButton style={{ padding: '5px 12px' }} onClick={() => this.setState({ openConfirmation: true, confirmationData: cloneCollections( confirmation ) })}><i className="fa fa-edit"></i>Edit Confirmation Email Draft</InfoButton>
                            <Tooltip 
                                title="Resync Email Template" 
                                classes={{ 
                                    tooltip: classes.tooltip
                                }}
                                arrow placement="top">
                                <InfoButton noIconMargin="yes" minWidth="0px" style={{ padding: '5px 8px' }} onClick={() => this.setState({ openResync: true })}><i className="fa fa-refresh"></i></InfoButton>
                            </Tooltip>
                        </ButtonGroup>
                    </div>
                    <div>
                        <em>Last Updated: </em>
                        { confirmation && confirmation.modified_on === 'NAN' ? 'N/A' : ( confirmation.modified_on ? getMomentTime( confirmation.modified_on, 'YYYY-MMM-DD hh:mma' ) : ( currentScheduleData && currentScheduleData.modified_on ? getMomentTime( currentScheduleData.modified_on, 'YYYY-MMM-DD hh:mma' ) : '---' ) ) }
                    </div>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <div style={{ paddingBottom: '20px' }}>
                        <InverseButton style={{ padding: '5px 12px' }} onClick={() => this.setState({ openEmail: true, emailType: 'preview_email' })}><i className="fa fa-search"></i>Send me Preview Email</InverseButton>
                    </div>
                    <Tooltip 
                        title={( selected && isArrayExists( selected ) ? "Send Confirmation Email" : "Please select at least one student" )} 
                        classes={{ 
                            tooltip: classes.tooltip
                        }}
                        arrow placement="top">
                        <div style={{ display: 'inline-block' }}>
                            <IndigoButton style={{ padding: '5px 12px' }} disabled={( selected && isArrayExists( selected ) ? false : 'yes' )} onClick={() => {
                                // check for duplicates
                                if ( this.isDuplicatesFound() ) {
                                    triggerErrorAlert("Cannot send Confirmation Email because email is duplicated. Please remove the duplicated email under Registration, and keep the desired one.",5000);
                                } else if ( this.emailNotModifiedYet() ) {
                                    triggerErrorAlert("Warning: Please edit the Confirmation Email Draft before sending.",5000);
                                } else {
                                    this.setState({
                                        openEmail: true, 
                                        emailType: 'confirmation_email', 
                                        emailAttachments: ( confirmation && confirmation.email_attachments && isObjectExists( confirmation.email_attachments ) ? cloneCollections( confirmation.email_attachments ) : false )
                                    })
                                }
                            }}><i className="fa fa-envelope-o"></i>Send Confirmation Email </IndigoButton>
                        </div>
                    </Tooltip>
                </Grid>
            </Grid>
        </Wrapper>
        )
    }

    render() {
        const { openConfirmation, confirmationData, openEmail, emailType, openResync } = this.state;
        return (
        <>
            {this.renderTrialBox()}
            {this.renderContent()}

            <ModalView 
                open={openConfirmation}
                title="Edit Confirmation Email Draft"
                maxWidth="lg"
                onClose={() => this.setState({ openConfirmation: false, confirmationData: false })}
                disableBackdrop={true}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                actionLabel="Update"
                doAction={this.handleConfirmationUpdate}
                contents={this.renderConfirmationForm()} />

            <ModalView 
                open={openResync}
                title={<span style={{ color: red['500'] }}><i className="fa fa-exclamation-triangle" style={{ marginRight: '8px' }}></i>{"RESYNC EMAIL TEMPLATE"}</span>}
                maxWidth="sm"
                onClose={() => this.setState({ openResync: false })}
                disableBackdrop={true}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                actionLabel="Resync Email Template"
                doAction={this.handleEmailResync}
                contents={this.renderResyncWarning()} />

            <ModalView 
                open={openEmail}
                title={ emailType && emailType === 'confirmation_email' ? "Send Confirmation Email" : "Send Preview Email" }
                maxWidth={ emailType && emailType === 'confirmation_email' ? "lg" : "sm" }
                onClose={() => this.setState({ openEmail: false, emailType: false, emailAttachments: false })}
                disableBackdrop={true}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                actionLabel="Confirm & Send Email"
                doAction={this.handleEmailTrigger}
                contents={ emailType && emailType === 'confirmation_email' ? this.renderConfirmationEmail() : this.renderPreviewEmail() } />

        </>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        traineesList: state.trainees && state.trainees.trainees ? state.trainees.trainees : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles),
    withRouter
)(ConfirmationMenu);