/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import shortid from 'shortid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _isEqual from 'lodash/isEqual';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';
import _split from 'lodash/split';
import _toUpper from 'lodash/toUpper';

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader'
import SnackBarSave from '../../components/SnackBarSave';

import BadgesBackpack from './backpack';
import ItemNotFound from '../../components/ItemNotFound';

import { InfoButton, GreyButton } from '../../styles/button';

import { hasAccessRights } from '../../helpers/auth'; 
import { isArrayExists, isObjectExists, isNumeric } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { getDateNumFromString, isParentFieldRequired } from '../../helpers/date';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { getStudentBadges, getStudentProfile } from '../../actions/students';
import { appChangesMade, resetRedux } from '../../actions/misc';


class StudentDigitalBadges extends React.Component {

    state = {
        currentTab: 'details',
        user: false,
        badges: false,
        randNum: false
    };

    componentDidMount() {
        const { authData } = this.props;
        
        // retrieve feedback details
        this.props.dispatch(getStudentBadges());

        // retrieve profile details
        this.props.dispatch(getStudentProfile(authData.uid));

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentStudent, badgesList, randNum
            } = this.props;

        // for feedback form
        if ( badgesList && currentStudent && randNum && randNum !== this.state.randNum ) {
            this.setState({ badges: this.reorganizeData( badgesList ), user: cloneCollections( currentStudent ), randNum });
        } // end - badgesList
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('student'));
    }

    reorganizeData = (list) => {
        let badges = cloneCollections( list );
        //*incomplete - remove this
        // fake badges
        // for (let index = 0; index < 8; index++) {
        //     let badge = cloneCollections( list[0] );
        //     badge.id = shortid.generate();
        //     if ( index === 3 || index === 4 ) {
        //         badge.course_type = '-y-8dqYaQ';
        //         badge.course_type_label = 'Core';
        //     }
        //     badges.push(badge);
        // }
        return badges;
    }

    renderContents() {
        const { badges, user } = this.state;
        const { authData } = this.props;
        return (
        <div>
            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>

                <BadgesBackpack
                    user={user}
                    authData={( authData || false )}
                    badges={( badges || false )} />
                
            </Paper>
        </div>
        );
    }

    render() {
        const { randNum } = this.state;
        const { badgesList } = this.props;
        return <AppWrapper 
                title="Badges Backpack"
                maxWidth="1110px"
                hideTopBar={true}
                topBarHeight="0px"
                onLoad={( !( randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        badgesList: state.students && state.students.badges || null,
        randNum: state.students && state.students.rand || null,
        currentStudent: state.students && state.students.profile || null,
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(StudentDigitalBadges);