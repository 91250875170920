/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _sortBy from "lodash/sortBy";
import _find from "lodash/find";
import _remove from "lodash/remove";
import _toLower from "lodash/toLower";
import _toString from "lodash/toString";

import ToolTipInfo from "../../components/ToolTipInfo";

import { isArrayExists } from "../../helpers/validation";
import { cloneCollections } from "../../helpers/data";

// import { PLATFORM_COURSE_REGISTRATION_LINK } from '../../constants';

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
    border: 2px solid #212121;
`;

class CourseColumnsMenu extends React.Component {
    handleCheckboxChanges = (course) => (event) => {
        const { onUpdate, courseColumns } = this.props;
        let newColumns = courseColumns && isArrayExists(courseColumns) ? cloneCollections(courseColumns) : [];
        if (event.target.checked) {
            if (!_find(newColumns, { id: course.id })) newColumns.push(course);
        } else {
            if (_find(newColumns, { id: course.id })) {
                var removed = _remove(newColumns, { id: course.id });
            }
        } // end - checked

        if (onUpdate) onUpdate(newColumns);
    };

    getOptions = () => {
        const { courses, lms_courses, schedule } = this.props;
        let options = [];
        if (schedule.course_access && isArrayExists(schedule.course_access) && lms_courses && isArrayExists(lms_courses)) {
            _forEach(schedule.course_access, (course) => {
                let selected_course =
                        course && course.id && !_isEmpty(course.id) && courses && isArrayExists(courses) ? _find(courses, { id: course.id }) : false,
                    lms_course =
                        course && course.lms_course_id ? _find(lms_courses, (c) => _toString(c.id) === _toString(course.lms_course_id)) : false,
                    course_code =
                        course && course.course_code && !_isEmpty(course.course_code)
                            ? course.course_code
                            : selected_course && selected_course.course_code && !_isEmpty(selected_course.course_code)
                            ? selected_course.course_code
                            : "";
                if (lms_course && !_find(options, { id: lms_course.id })) {
                    options.push({
                        id: lms_course.id,
                        label: (course_code && !_isEmpty(course_code) ? course_code + " - " : "") + (lms_course.name || ""),
                        name: lms_course.name || "",
                        course_code,
                    });
                } // end - lms_course
            });
        } // end - schedule.course_access
        return _sortBy(options, (o) => o.label.toLowerCase());
    };

    render() {
        const { courseColumns } = this.props;
        const options = this.getOptions();
        return (
            <Wrapper>
                <Typography variant="h5">
                    Show Course Column(s)
                    <ToolTipInfo
                        content={
                            <>
                                💡 Choose the latest course.
                                <br />
                                <br />
                                Latest course is indicated by the last alphabet of the course code.
                                <br />
                                E.g. L220B is a newer version compared to L220A.
                            </>
                        }
                    />
                </Typography>
                <Grid container spacing={1}>
                    {options && isArrayExists(options)
                        ? options.map((course) => {
                              return (
                                  <Grid key={course.id} item xs={12} sm={6}>
                                      <FormControlLabel
                                          control={
                                              <Checkbox
                                                  checked={
                                                      courseColumns && isArrayExists(courseColumns) && _find(courseColumns, { id: course.id })
                                                          ? true
                                                          : false
                                                  }
                                                  onChange={this.handleCheckboxChanges(course)}
                                                  value={course.id}
                                                  color="primary"
                                              />
                                          }
                                          label={course.label || ""}
                                      />
                                  </Grid>
                              );
                          })
                        : null}
                </Grid>
            </Wrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        coursesList: state.courses && state.courses.courses_options ? state.courses.courses_options : false,
        lmsCoursesList: state.canvas_lms && state.canvas_lms.courses ? state.canvas_lms.courses : null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(CourseColumnsMenu);
