/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import _isEmpty from 'lodash/isEmpty';
// import _merge from 'lodash/merge';
// import _size from 'lodash/size';
// import _isEqual from 'lodash/isEqual';

import AppWrapper from "../../components/AppWrapper";
import AppInfoBox from "../../modules/AppInfoBox";
import Partners from "./table";

// import { cloneCollections } from '../../helpers/data';

import { AInfoLink, AInverseLink } from "../../styles/button";

import { getPartners } from "../../actions/partners";
import { getMaintenance } from "../../actions/maintenance";
import { resetRedux } from "../../actions/misc";

class PartnersPage extends React.Component {
    state = {
        // query: {
        //     searchterms: '',
        //     filterBy: 'all',
        //     sortBy: 'modified_on-desc',
        //     perPage: 20,
        //     page: 1
        // },
        randNum: false,
    };

    componentDidMount() {
        const { authData, partnersList, designationList, currencyList, randNum } = this.props;
        // get partners
        if (!partnersList) this.props.dispatch(getPartners({ authData }));

        // get designation
        if (!designationList) this.props.dispatch(getMaintenance("designation"));

        // get currency
        if (!currencyList) this.props.dispatch(getMaintenance("currency"));

        if (partnersList && designationList && currencyList && randNum) {
            this.setState({ randNum });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { partnersList, designationList, currencyList, randNum } = this.props;
        if (partnersList && designationList && currencyList && randNum && randNum !== this.state.randNum) {
            this.setState({ randNum });
        } // end - randNum
    }

    componentWillUnmount() {
        // this.props.dispatch(resetRedux('partners'));
    }

    handleRefresh = (props) => {
        // get partners
        this.setState({ randNum: false });
        this.props.dispatch(resetRedux("partners"));
        this.props.dispatch(getPartners(props));
    };

    renderContents() {
        const { authData, partnersList, designationList, currencyList, classes } = this.props;
        // const { query } = this.state;
        return (
            <>
                <AppInfoBox page="PartnersPage" />
                <Partners
                    partners={partnersList}
                    designation={designationList}
                    currency={currencyList}
                    authData={authData}
                    onRefresh={this.handleRefresh}
                />
            </>
        );
    }

    render() {
        const { randNum } = this.state;
        const { partnersList, authData } = this.props;
        return (
            <AppWrapper
                subtitle="Setup"
                title="Partner Profile"
                onLoad={!randNum ? true : false}
                contents={authData ? this.renderContents() : null}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        partnersList: state.partners && state.partners.partners ? state.partners.partners : null,
        randNum: state.partners && state.partners.rand ? state.partners.rand : null,
        designationList: (state.maintenance && state.maintenance.designations) || null,
        currencyList: (state.maintenance && state.maintenance.currency) || null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(PartnersPage);
