/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _remove from "lodash/remove";
import _isEqual from "lodash/isEqual";
import _startsWith from "lodash/startsWith";
import _replace from "lodash/replace";
import _split from "lodash/split";
import _toUpper from "lodash/toUpper";

import AppWrapper from "../../components/AppWrapper";
import DotsLoader from "../../components/DotsLoader";
import SnackBarSave from "../../components/SnackBarSave";

import EditForm from "./form";
import ClassFilterMenu from "../ClassRegistrationPage/filter_menu";
import ItemNotFound from "../../components/ItemNotFound";

import { InfoButton, GreyButton } from "../../styles/button";

import { hasAccessRights } from "../../helpers/auth";
import { isArrayExists, isObjectExists, isNumeric } from "../../helpers/validation";
import { cloneCollections } from "../../helpers/data";
import { getDateNumFromString, isParentFieldRequired } from "../../helpers/date";
import { triggerErrorAlert } from "../../helpers/alert";
import { doSchemaErrorCheck } from "../../helpers/schemas";

import {
    getMentorClasses,
    getMentorClassAttendances,
    updateMentorClassAttendances,
    nominateBadgeMentor,
    addNewClassSession,
} from "../../actions/mentor_class_attendances";
import { appChangesMade, resetRedux } from "../../actions/misc";

class MentorClassAttendances extends React.Component {
    state = {
        currentSchedule: false,
        schedules: false,
        currentTab: "details",
        attendances: false,
        attendancesRand: false,
        randNum: false,
    };

    componentDidMount() {
        const { lms_course_id } = this.props.match.params;
        const { authData } = this.props;

        // get schedules
        this.props.dispatch(getMentorClasses(lms_course_id));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentClasses, randNum, currentAttendances, attendancesRand } = this.props;
        // for classes
        if (currentClasses && randNum && randNum !== this.state.randNum) {
            this.setState({ schedules: cloneCollections(currentClasses), randNum });
        } // end - currentClasses

        // for student data
        if (currentAttendances && attendancesRand && attendancesRand !== this.state.attendancesRand) {
            this.setState({ attendances: cloneCollections(currentAttendances), attendancesRand });
        } // end - currentAttendances
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux("student_showcase"));
    }

    handleFilter = (schedule_id) => {
        const { lms_course_id } = this.props.match.params;
        const { authData } = this.props;
        this.setState({ currentSchedule: schedule_id });
        this.props.dispatch(getMentorClassAttendances(schedule_id, lms_course_id));
    };

    handleTraineesUpdate = (newTrainees) => {
        const { lms_course_id } = this.props.match.params;
        const { currentAttendances } = this.props;
        const { attendances, currentSchedule } = this.state;
        event.preventDefault();
        var error = false,
            formData = {
                schedule_id: currentSchedule,
                lms_course_id,
                trainees: [],
            };

        // run through all the trainees to look for changes
        if (
            newTrainees &&
            isArrayExists(newTrainees) &&
            currentAttendances &&
            currentAttendances.trainees &&
            isArrayExists(currentAttendances.trainees)
        ) {
            _forEach(newTrainees, (trainee) => {
                let oriTrainee = _find(currentAttendances.trainees, { id: trainee.id });
                if (!_isEqual(trainee, oriTrainee)) {
                    formData.trainees.push({
                        id: trainee.id,
                        mentor_comments: trainee.mentor_comments || "",
                        mentor_comments_updated: trainee.mentor_comments_updated || 0,
                        mentor_comments_updated_by: trainee.mentor_comments_updated_by || "",
                        attendances: trainee.attendances || [],
                    });
                }
            });
        } // end - trainees

        if (error) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateMentorClassAttendances(formData));
        } // end - error
    };

    handleShowcaseUpdate = (formData) => {
        this.props.dispatch(nominateBadgeMentor(formData));
    };

    handleAddNewClass = (formData) => {
        this.props.dispatch(addNewClassSession(formData));
    };

    // handleSaveChanges = (event) => {
    //     const { lms_course_id } = this.props.match.params;
    //     const { currentAttendances } = this.props;
    //     const { attendances, currentSchedule } = this.state;
    //     event.preventDefault();
    //     var error = false,
    //         formData = {
    //             schedule_id: currentSchedule,
    //             lms_course_id,
    //             trainees: []
    //         };

    //     // run through all the trainees to look for changes
    //     if ( attendances && attendances.trainees && isArrayExists( attendances.trainees ) && currentAttendances && currentAttendances.trainees && isArrayExists( currentAttendances.trainees ) ) {
    //         _forEach( attendances.trainees, trainee => {
    //             let oriTrainee = _find( currentAttendances.trainees, { id: trainee.id } );
    //             if ( !_isEqual( trainee, oriTrainee ) ) {
    //                 formData.trainees.push({
    //                     id: trainee.id,
    //                     mentor_comments: ( trainee.mentor_comments || '' ),
    //                     attendances: ( trainee.attendances || [] )
    //                 });
    //             }
    //         });
    //     } // end - trainees

    //     if ( error ) {
    //         triggerErrorAlert(error);
    //     } else {
    //         this.props.dispatch(updateMentorClassAttendances(formData));
    //     } // end - error

    // }

    // handleFormUpdate = (newTrainees) => {
    //     const { attendances } = this.state;
    //     let newData = ( attendances && !_isEmpty( attendances ) ? cloneCollections( attendances ) : { trainees: [] } );
    //     newData.trainees = cloneCollections( newTrainees );
    //     this.setState({ attendances: newData });

    //     // trigger changes made
    //     this.props.dispatch(appChangesMade());
    // }

    renderTabContents = () => {
        const { lms_course_id } = this.props.match.params;
        const { authData, currentAttendances, changesMade, attendancesRand } = this.props;
        const { currentTab, attendances, currentSchedule } = this.state;
        const tabProps = {
            authData: authData || false,
            attendances: attendances || false,
            changesMade: changesMade || false,
            onFormUpdate: this.handleTraineesUpdate,
        };
        switch (currentTab) {
            case "details":
                return (
                    <EditForm
                        schedule_id={currentSchedule}
                        lms_course_id={lms_course_id}
                        currentAttendances={currentAttendances || false}
                        attendancesRand={attendancesRand}
                        onShowcaseUpdate={this.handleShowcaseUpdate}
                        onAddNewClass={this.handleAddNewClass}
                        {...tabProps}
                    />
                );
        }
    };

    renderAttendances() {
        return (
            <div>
                <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                    {this.renderTabContents()}
                </Paper>
                {/* <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Update</InfoButton>
            </div>
            <SnackBarSave saveNowLabel="Update" onSave={this.handleSaveChanges} /> */}
            </div>
        );
    }

    renderContents() {
        const { currentAttendances } = this.props;
        const { currentSchedule, schedules } = this.state;
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <ClassFilterMenu
                            hideRegionSelector={true}
                            customSchedules={schedules}
                            currentSchedule={currentSchedule || false}
                            onFilter={this.handleFilter}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}></Grid>
                </Grid>

                {currentAttendances ? this.renderAttendances() : null}
            </>
        );
    }

    render() {
        const { randNum } = this.state;
        const { currentAttendances } = this.props;
        return (
            <AppWrapper
                title="Class Attendance & Reporting"
                maxWidth="100%"
                hideTopBar={true}
                topBarHeight="0px"
                onLoad={!randNum ? true : false}
                contents={this.renderContents()}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: (state.auth && state.auth.user) || null,
        currentClasses: (state.mentors && state.mentors.classes) || null,
        currentAttendances: (state.mentors && state.mentors.attendances) || null,
        attendancesRand: (state.mentors && state.mentors.attendances_rand) || null,
        randNum: (state.mentors && state.mentors.rand) || null,
        changesMade: (state.misc && state.misc.changes_made) || null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(MentorClassAttendances);
