/* eslint-disable */
import Papa from 'papaparse';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { compareString } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { getCustomField, getCustomFieldValue, getCustomFields } from '../../helpers/registration_form';

import { REGISTRATION_FORM_TYPES } from '../../constants';

export const doCSVDownload = (csvData,filename = 'csv') => {
    let csv = Papa.unparse( csvData, { delimiter: ',' } );
    let blob = new Blob( [csv] , { type: 'text/csv;charset=utf-8;' }); //new way
    let uri = window.URL.createObjectURL(blob);
    let link = document.createElement("a");

    link.setAttribute("href", uri);
    link.setAttribute("target", '_blank');
    link.setAttribute("download", "export_"+filename+".csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}


export const exportTeamsProjectsToCSV = ({ 
    schedule = {},
    teams = [],
    trainees = [], 
    showcase = {} 
}) => {
    let csvData = {
        fields: [
            'Team',
            'School',
        ],
        data: []
    };

    // team members
    const total_members = ( schedule && schedule.team_size && !_isEmpty( schedule.team_size ) ? parseInt( schedule.team_size, 10 ) : 0 );
    if ( total_members ) {
        for (let index = 1; index <= total_members; index++) {
            csvData.fields.push( 'Team Member #' + index + ' - Name' );
            csvData.fields.push( 'Team Member #' + index + ' - Email' );
        }
    } // end -total_members 

    // projects
    const total_projects = ( schedule && schedule.project_submissions && !_isEmpty( schedule.project_submissions ) ? parseInt( schedule.project_submissions, 10 ) : 0 );
    if ( total_projects ) {
        for (let index = 1; index <= total_projects; index++) {
            csvData.fields.push( 'Project #' + index + ' - Title' );
            csvData.fields.push( 'Project #' + index + ' - URL' );
            csvData.fields.push( 'Project #' + index + ' - time created' );
            csvData.fields.push( 'Project #' + index + ' - time edited' );
        }
    } // end -total_projects
    
    // add badge related
    csvData.fields.push( 'Score' );
    csvData.fields.push( 'Nominated Badge Type' );

    // teams
    if ( isArrayExists( teams ) ) {
        _forEach( teams, ( team, index ) => {
            let team_members = [];
            let team_projects = [];
            let team_badge = [];

            // team members
            if ( total_members ) {
                for (let index = 0; index < total_members; index++) {
                    const student = ( team.trainees && team.trainees[index] && team.trainees[index].id ? _find( trainees, { id: team.trainees[index].id } ) : false );
                    team_members.push( student && student.name || '' );
                    team_members.push( student && student.email || '' );
                }
            }

            // projects
            if ( total_projects ) {
                for (let index = 1; index <= total_projects; index++) {
                    let project = _find( team.projects, p => compareString( p.id, index ) );
                    team_projects.push( project && project.title || '' );
                    team_projects.push( project && project.desc || '' );
                    team_projects.push( ( project && project.created_on ? getMomentTime( project.created_on, 'YYYY-MMM-DD hh:mma' )  : ( project && project.updated_on ? getMomentTime( project.updated_on, 'YYYY-MMM-DD hh:mma' ) : '' ) ) );
                    team_projects.push( project && project.updated_on ? getMomentTime( project.updated_on, 'YYYY-MMM-DD hh:mma' ) : '' );
                }
            }
            // badge
            team_badge.push( team.score || '' );
            const nominated_badge = ( team.badge && team.badge.nominated_badge_type ? _find( showcase.badges, { type: team.badge.nominated_badge_type } ) : false );
            team_badge.push( ( nominated_badge && nominated_badge.type_label || '' ) );

            let team_data = [
                team.name,
                team.school,
            ];
            team_data = team_data.concat( team_members );
            team_data = team_data.concat( team_projects );
            team_data = team_data.concat( team_badge );

            csvData.data.push( team_data );
        });
    }

    doCSVDownload( csvData, 'teams_projects' );

}

export const exportTeamsDataToCSV = ({
    schedule = {},
    registration_forms = [],
    teams = [],
    trainees = [],
    mentors = [],
    teachers = []
}) => {
    const fields = getCustomFields( schedule, registration_forms, 'all' );
    const nonDefaultfields = getCustomFields( schedule, registration_forms, 'non_default' );
    const schoolField = getCustomField( fields, 'org' );
    const mediField = getCustomField( fields, 'medical_condition' );
    let csvData = {
        fields: [
            'Team',
            'School',
            'Mentor',
            'Teacher',
        ],
        data: []
    };

    // team members
    const total_members = ( schedule && schedule.team_size && !_isEmpty( schedule.team_size ) ? parseInt( schedule.team_size, 10 ) : 0 );
    if ( total_members ) {
        for (let index = 1; index <= total_members; index++) {
            csvData.fields.push( 'Team Member #' + index + ' - Name' );
            csvData.fields.push( 'Team Member #' + index + ' - Email' );
            csvData.fields.push( 'Team Member #' + index + ' - Gender' );
            csvData.fields.push( 'Team Member #' + index + ' - Date of Birth' );
            csvData.fields.push( 'Team Member #' + index + ' - Age' );

            if ( schoolField )
                csvData.fields.push( 'Team Member #' + index + ' - School' );

            if ( mediField )
                csvData.fields.push( 'Team Member #' + index + ' - Medical Condition' );

            _forEach( nonDefaultfields, ( field ) => {
                csvData.fields.push( 'Team Member #' + index + ' - ' + ( field.label || '' ) );
            });
        }
    } // end -total_members 

    // teams
    if ( isArrayExists( teams ) ) {
        _forEach( teams, ( team ) => {
            let team_data = [
                team.name,
                team.school,
            ];
            let team_members = [];

            // mentor & teacher
            const mentor = ( team.mentor && team.mentor ? _find( mentors, { email: team.mentor } ) : false );
            team_data.push( ( mentor && mentor.name || '' ) );

            const teacher = ( team.teacher && team.teacher ? _find( teachers, { email: team.teacher } ) : false );
            team_data.push( ( teacher && teacher.name || '' ) );

            // team members
            if ( total_members ) {
                for (let index = 0; index < total_members; index++) {
                    const student = ( team.trainees && team.trainees[index] && team.trainees[index].id ? _find( trainees, { id: team.trainees[index].id } ) : false );
                    team_members.push( student && student.name || '' );
                    team_members.push( student && student.email || '' );
                    team_members.push( student && student.gender || '' );
                    team_members.push( student && student.date_of_birth_string || '' );
                    team_members.push( student && student.age || '' );

                    if ( schoolField )
                        team_members.push( student && student.org || '' );
        
                    if ( mediField )
                        team_members.push( student && student.medical_condition || '' );
        
                    _forEach( nonDefaultfields, ( field ) => {
                        team_members.push( getCustomFieldValue( field, student ) );
                    });
                }
            }

            team_data = team_data.concat( team_members );

            csvData.data.push( team_data );
        });
    }

    doCSVDownload( csvData, 'teams' );

}