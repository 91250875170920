/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _slice from "lodash/slice";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _find from "lodash/find";
import _toLower from "lodash/toLower";

import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import TableBar from "../../components/TableBar";
import ModelDelete from "../../components/ModalDelete";
import ModalView from "../../components/ModalView";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import ButtonActions from "../../components/ButtonActions";

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton, ASuccessLink } from "../../styles/button";
import { SuccessTag, GreyTag } from "../../styles/tag";
import { WrapWord } from "../../styles/misc";

import { isArrayExists } from "../../helpers/validation";
import { triggerErrorAlert } from "../../helpers/alert";
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from "../../helpers/data";
import { getMomentTime, getMomentTimestamp } from "../../helpers/date";
import { isAdmin, hasAccessRights } from "../../helpers/auth";

import { addCourse, deleteCourse } from "../../actions/courses";

import { courseSchema } from "../../schemas/course";

import { CANVAS_LMS_URL } from "../../constants";

class Courses extends React.Component {
    state = {
        checked: [],
        allCheck: false,
        searchterms: "",
        filterBy: "all",
        sortBy: "date-desc",
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false,
    };

    handlePageRefresh = (event) => {
        event.preventDefault();
        if (this.props.onRefresh) this.props.onRefresh();
    };

    handleFormUpdate = (newValue, key, subkey, subVal) => {
        const { course_type } = this.props;
        const { modalData } = this.state;
        let newData = modalData ? cloneCollections(modalData) : {};
        switch (key) {
            case "course_type":
                let selectedType = course_type && isArrayExists(course_type) ? _find(course_type, { id: newValue }) : false;
                newData[key] = newValue;
                newData[key + "_label"] = selectedType && selectedType.label ? selectedType.label : "";
                break;
            default:
                if (subkey && (subkey === "checked" || subkey === "unchecked") && subVal && !_isEmpty(subVal)) {
                    // use subVal as the new value
                    newData[key] = subVal;
                } else {
                    // otherwise - just update normally
                    newData[key] = newValue;
                }
                break;
        } // end - key
        this.setState({ modalData: newData });
    };

    handleAddNew = () => {
        const { history } = this.props;
        const { modalData } = this.state;
        var error = false;

        if (!(modalData && modalData.name && !_isEmpty(modalData.name))) {
            error = "Please enter a valid course name";
        } // end - modalData.email

        if (error) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addCourse(modalData, history));
        } // end - error
    };

    handleEdit = (id) => (event) => {
        if (event && event.preventDefault) event.preventDefault();

        this.props.history.push("/products/" + id);
    };

    handleDelete = () => {
        const { deleteModal } = this.state;
        // perform delete
        this.props.dispatch(deleteCourse(deleteModal.id));
    };

    handleAction = (type) => {
        const { checked } = this.state;
        // make sure there is an item selected
        if (isArrayExists(checked)) {
        } else {
            triggerErrorAlert("Please select at least one item");
        } // end - checked
    };

    getQueryProps = () => {
        const { sortBy, perPage, page } = this.state;
        return false;
    };

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { courses } = this.props;
        let items = courses ? cloneCollections(courses) : [],
            total = _size(items);

        // do search
        if (searchterms && !_isEmpty(searchterms)) {
            items = doArraySearch(items, searchterms, ["name", "course_type_label", "course_code"]);
            total = _size(items);
        } // end - searchterms

        // do custom filter
        if (filterBy && !_isEmpty(filterBy) && filterBy !== "all") {
            items = _filter(items, { course_type: filterBy });
            total = _size(items);
        }

        // do sort
        if (sortBy && !_isEmpty(sortBy) && !_isEmpty(items)) {
            switch (sortBy) {
                case "date-desc":
                    items = _sortBy(items, (i) => (i.modified_on && i.modified_on._seconds ? i.modified_on._seconds : i.modified_on));
                    items = _reverse(items);
                    break;
                case "date-asc":
                    items = _sortBy(items, (i) => (i.modified_on && i.modified_on._seconds ? i.modified_on._seconds : i.modified_on));
                    break;
                case "course_code-desc":
                    items = _sortBy(items, ["course_code"]);
                    items = _reverse(items);
                    break;
                case "course_code-asc":
                    items = _sortBy(items, ["course_code"]);
                    break;
                case "course_type_label-desc":
                    items = _sortBy(items, ["course_type_label"]);
                    items = _reverse(items);
                    break;
                case "course_type_label-asc":
                    items = _sortBy(items, ["course_type_label"]);
                    break;
                case "name-desc":
                    items = _sortBy(items, ["name"]);
                    items = _reverse(items);
                    break;
                case "name-asc":
                    items = _sortBy(items, ["name"]);
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination(items, perPage, page);

        return { items, total };
    }

    renderCell = (type) => (item) => {
        switch (type) {
            case "publish":
                return item.publish && item.publish == "yes" ? <SuccessTag>Yes</SuccessTag> : <GreyTag>No</GreyTag>;
            case "email":
                return <WrapWord>{item[type] || ""}</WrapWord>;
        }
    };

    renderAddNewForm = () => {
        const { course_type } = this.props;
        const { modalData } = this.state;
        return (
            <div>
                <FormInput label="Course Name *" name="name" value={modalData.name || ""} onChange={this.handleFormUpdate} />
                <FormInput label="Course Code" name="course_code" value={modalData.course_code || ""} onChange={this.handleFormUpdate} />
                <div style={{ paddingTop: "7px" }}>
                    <FormSelect
                        label="Course Type"
                        name="course_type"
                        value={modalData.course_type || ""}
                        disableNative={true}
                        options={getSelectOptions({
                            list: course_type || false,
                            options: [],
                            keys: { value: "id", label: "label", disabled: "status" },
                            sortBy: "label",
                        })}
                        onChange={this.handleFormUpdate}
                    />
                </div>
            </div>
        );
    };

    renderTableActions = () => {
        const { authData, course_type } = this.props;
        const { filterBy, sortBy, perPage, searchterms, checked } = this.state;
        return (
            <TableBar
                filterBy={filterBy}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: "date-desc", label: "Recent Modified first" },
                    { value: "date-asc", label: "Oldest Modified first" },
                    { value: "name-asc", label: "Course Name ( A - Z)" },
                    { value: "name-desc", label: "Course Name ( Z - A )" },
                    { value: "course_code-asc", label: "Product Code ( A - Z)" },
                    { value: "course_code-desc", label: "Product Code ( Z - A )" },
                    { value: "course_type_label-asc", label: "(legacy) Course Type ( A - Z)" },
                    { value: "course_type_label-desc", label: "(legacy) Course Type ( Z - A )" },
                ]}
                // leftButtons={ checked && isArrayExists( checked ) ? [
                //     <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                //         <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                //     </div>
                //     ] : null }
                rightButtons={[
                    <InfoButton minWidth="168px" key="addnew" style={{ marginRight: "5px" }} onClick={this.handleEdit("new")}>
                        <i className="fa fa-plus-circle"></i>Add New
                    </InfoButton>,
                    <InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}>
                        <i className="fa fa-refresh"></i>Refresh
                    </InverseButton>,
                ]}
                filterByOptions={getSelectOptions({
                    list: course_type || false,
                    options: [{ value: "all", label: "All" }],
                    keys: { value: "id", label: "label" },
                    sortBy: "label",
                })}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }}
            />
        );
    };

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return (
            <Pagination
                total={totalCount}
                perPage={perPage}
                page={page}
                style={{ marginTop: "20px" }}
                doneLoaded={true}
                onPageChange={(newPage) => this.setState({ page: newPage })}
            />
        );
    };

    render() {
        const { authData, lms_courses } = this.props;
        const { openDeleteModal, deleteModal, openViewModal, allCheck, checked } = this.state;
        const { items, total } = this.reorganizeData();
        return (
            <div>
                {authData && hasAccessRights(authData, ["cpw"]) ? (
                    <ModalView
                        open={openViewModal}
                        title="Add New Course"
                        onClose={() => this.setState({ openViewModal: false, modalData: false })}
                        doAction={this.handleAddNew}
                        contents={this.renderAddNewForm()}
                    />
                ) : null}

                {authData && hasAccessRights(authData, ["cpd"]) ? (
                    <ModelDelete
                        open={openDeleteModal}
                        title={deleteModal && deleteModal.label ? `Are you sure you want to delete this course ( ${deleteModal.label} )?` : false}
                        onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                        onDelete={this.handleDelete}
                    />
                ) : null}

                {this.renderTableActions()}
                <Table
                    items={items}
                    showCheckbox={false}
                    checked={checked || []}
                    allCheck={allCheck}
                    onChecked={(newValue) => this.setState({ checked: newValue })}
                    onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                    cells={[
                        { id: "course_type_label", label: "Course Type (legacy)", render: (item) => item.course_type_label || "-" },
                        { id: "course_code", label: "Product Code", render: (item) => item.course_code || "-" },
                        { id: "name", label: "Product Name", render: (item) => item.name || "" },
                        {
                            id: "lms_course_id",
                            label: "LMS Course",
                            render: (item) => <WrapWord>{getSelectedValue(lms_courses, item.lms_course_id, "id", "name", "-")}</WrapWord>,
                        },
                        { id: "contact_hour", label: "Contact Hour", render: (item) => item.contact_hour_label || "" },
                        { id: "product_level", label: "Level", render: (item) => item.product_level_label || "" },
                        { id: "product_credit", label: "Credit", render: (item) => item.product_credit_label || "" },
                        // { id: 'publish', label: 'Publish to Web', render: this.renderCell('publish') },
                    ]}
                    actionStyles={{ width: "10%" }}
                    actions={(item) => (
                        <ButtonGroup>
                            <AInfoLink key="edit" href={"/products/" + item.id} size="small" onClick={this.handleEdit(item.id)}>
                                <i className="fa fa-edit"></i>Edit
                            </AInfoLink>
                            {item.lms_course_id && !_isEmpty(item.lms_course_id) ? (
                                <AInfoLink
                                    key="lms_course"
                                    href={CANVAS_LMS_URL + "/courses/" + item.lms_course_id}
                                    target="_blank"
                                    style={{ padding: "5px", fontSize: "12px" }}
                                >
                                    <i className="fa fa-external-link"></i>View LMS
                                </AInfoLink>
                            ) : null}
                            {authData && hasAccessRights(authData, ["cpd"]) ? (
                                <ErrorButton key="delete" size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}>
                                    <i className="fa fa-trash"></i>Delete
                                </ErrorButton>
                            ) : null}
                        </ButtonGroup>
                    )}
                />
                {this.renderPagination(total)}
            </div>
        );
    }
}

export default compose(connect(), withRouter)(Courses);
