/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import _isEmpty from 'lodash/isEmpty';
import _toLower from 'lodash/toLower';
import _replace from 'lodash/replace';

import ToolTipInfo from '../ToolTipInfo';
import { validate } from '../../helpers/validation';

import theme from '../../theme';

class FormInput extends React.Component {

    handleClick = (e) => {
        const { onClick } = this.props;
        if ( onClick )
            onClick();
    }

    handleChange = (event) => {
        const { name, type, value, minNum, maxNum } = this.props;
        if ( this.props.onChange ) {
            // validate value based on type
            switch ( type ) {
                case 'number':
                    let newNumber = event.target.value,
                        numValid = true;
                    if ( validate( newNumber, 'integer' ) ) {
                        // only update if within minNum & maxNum
                        if ( minNum || 0 === minNum ) {
                            if ( newNumber < minNum )
                                numValid = false;
                        } // end - minNum
                        if ( maxNum || 0 === maxNum ) {
                            if ( newNumber > maxNum )
                                numValid = false;
                        } // end - maxNum
                    } else {
                        numValid = false;
                    } // end - newNumber
                    // only update if is valid
                    if ( numValid ) {
                        this.props.onChange(newNumber,name);
                    }
                    break;
                default:
                    this.props.onChange(event.target.value,name);
                    break;
            }
        }
    }

    getLabelStyle = () => {
       const { tooltip } = this.props;
       let style = { 
           fontSize: "16px", 
           fontWeight: "700", 
           textTransform: "uppercase", 
           backgroundColor: "#fff", 
           paddingLeft: '3px', 
           paddingRight: '8px' 
        };
        // if tooltip found
        if ( !_isEmpty( tooltip ) ) {
            style['pointerEvents'] = 'auto';
        }
        return style;
    }

    renderTooltipInfo = () => {
        const { tooltip } = this.props;
        return <ToolTipInfo content={( tooltip || false )} />
    }

    renderLabel = () => {
        const { label, tooltip } = this.props;
        var newLabel = false;
        if ( label && !_isEmpty( label ) && ( 
            ( _toLower( label ).indexOf("(required)") >= 0 ) || 
            ( _toLower( label ).indexOf("*") >= 0 )
         ) ) {
            newLabel = _replace( label, '(Required)', '' );
            newLabel = _replace( newLabel, '(required)', '' );
            newLabel = _replace( newLabel, '*', '' );
        } // end - label
        return (
        <>
            { tooltip && !_isEmpty( tooltip ) ? this.renderTooltipInfo() : null }
            { newLabel ? <span className="field-label">{ newLabel || '' }<span className="field-required required">*</span></span> : ( label || '' ) }
        </>
        );
    }

    render() {
        const { type, label, labelProps, value, disabled, multiline, rows, rowsMax, placeholder, InputProps, desc } = this.props;
        return (
        <FormControl fullWidth={true}>
            <TextField 
                label={this.renderLabel()} 
                type={( type || 'text' )} 
                disabled={( disabled || false )}
                multiline={( multiline || null )}
                rows={( rows || null )}
                rowsMax={( rowsMax || null )}
                fullWidth={true} 
                InputLabelProps={( labelProps ? labelProps : {
                    shrink: true,
                    style: this.getLabelStyle()
                })}
                InputProps={( InputProps ? InputProps : null )}
                margin="normal" 
                placeholder={( placeholder || null )}
                variant={( disabled ? "filled" : "outlined" )} 
                value={( value || '' )} 
                onClick={this.handleClick}
                onChange={this.handleChange} />
            { desc && !_isEmpty( desc ) ? <Typography variant="body1" style={{ padding: '5px' }}>{ desc }</Typography> : null }
        </FormControl>
        )
    }

}

export default FormInput