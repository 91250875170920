/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormSelect2 from '../../components/FormSelect2';
import FormDatePicker from '../../components/FormDatePicker';

import { InfoButton } from '../../styles/button';
import { SuccessTag, GreyTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { isAdmin, hasAccessRights, getUserRegionFilter } from '../../helpers/auth';

import { appChangesReset } from '../../actions/misc';

// import { PLATFORM_COURSE_REGISTRATION_LINK } from '../../constants';

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
`;

const OuterWrapper = styled.div`
    max-width: 800px;
`;

class ClassFilterMenu extends React.Component {
    
    state = {
        region: '',
        course_id: '',
        schedule_id: '',
        start_date: moment().utcOffset(8).subtract(12,'months').startOf('date').valueOf(),
        end_date: moment().utcOffset(8).endOf('date').valueOf(),
        scheduleLoaded: true
    };

    componentDidMount() {
        const { authData, currentSchedule } = this.props;
        // set region if not admin
        if ( !isAdmin( authData ) )
            this.setState({ region: getUserRegionFilter(authData) });

        if ( currentSchedule && !_isEmpty( currentSchedule ) ) {
            this.setState({ schedule_id: currentSchedule });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    handleFilterTrigger = () => {
        const { onFilter, changesMade } = this.props;
        const { region, schedule_id, start_date, end_date } = this.state;
        let error = false;

        if ( !( schedule_id && !_isEmpty( schedule_id ) ) )
            error = 'Please select a Class';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            if ( onFilter ) {
                onFilter({ 
                    schedule_id: ( schedule_id && !_isEmpty( schedule_id ) ? schedule_id : false ), 
                    region: ( region && !_isEmpty( region ) ? region : false ), 
                    start_date, 
                    end_date 
                });
            } // end - onFilter
        }
    }

    handleCourseChange = (newValue,key) => {
        this.setState({ course_id: newValue });
    }

    getOptions = (type) => {
        const { regionsList, coursesList, schedulesList, authData } = this.props;
        const { region, course_id } = this.state;
        let options = [];
        switch (type) {
            case 'regions':
                options = getSelectOptions({ 
                    list: ( regionsList && isArrayExists( regionsList ) ? _sortBy( regionsList, r => r.label.toLowerCase() ) : [] ), 
                    options: [{ value: '', label: 'All Partners'}], 
                    keys: { value: 'id', label: 'label' } 
                });
                break; 
            case 'schedules':
                let schedules = ( schedulesList && isArrayExists( schedulesList ) ? _sortBy( schedulesList, ['id'] ) : [] );
                options = getSelectOptions({ 
                    list: _sortBy( ( region && !_isEmpty( region ) ? _filter( schedules, { region }) : schedules ),  s => s.class_name.toLowerCase() ), 
                    options: isAdmin( authData ) ? [{ value: '', label: 'Select an Option'},{ value: 'all', label: 'All Classes'}] : [{ value: '', label: 'Select an Option'}], 
                    keys: { value: 'id', label: 'class_name' } 
                });
                break; 
        }
        return options;
    }

    renderMenu = () => {
        const { authData } = this.props;
        const { scheduleLoaded, region, course_id, schedule_id, start_date, end_date } = this.state;
        return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <FormSelect 
                    value={( region || '' )} 
                    label="Partner" 
                    name="region" 
                    options={this.getOptions('regions')} 
                    disabled={( isAdmin( authData ) ? false : true )} 
                    onChange={(newValue) => this.setState({ region: newValue })} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormSelect 
                    value={( schedule_id || '' )} 
                    label="Class Name" 
                    name="schedule_id" 
                    options={this.getOptions('schedules')}
                    // disabled={( course_id && !_isEmpty( course_id ) && scheduleLoaded ? false : true )}
                    onChange={(newValue) => this.setState({ schedule_id: newValue })} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormDatePicker 
                    label="Registration From" 
                    name="start_date" 
                    value={( start_date || 0 )} 
                    onChange={(newValue) => this.setState({ start_date: newValue })} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormDatePicker 
                    label="Registration To" 
                    name="end_date" 
                    value={( end_date || 0 )} 
                    onChange={(newValue) => this.setState({ end_date: newValue })} />
            </Grid>

            <Grid item xs={12}>
                <InfoButton disabled={( scheduleLoaded ? false : 'yes' )} minWidth="128px" style={{ padding: '5px 15px' }} onClick={this.handleFilterTrigger}><i className="fa fa-refresh"></i>Load Student Data</InfoButton>
            </Grid>
        </Grid>
        )
    }

    render() {
        return (
        <OuterWrapper>

            <Paper elevation={2}>
                <Wrapper>
                    {this.renderMenu()}
                </Wrapper>
            </Paper>

        </OuterWrapper>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        coursesList: state.courses && state.courses.courses_options ? state.courses.courses_options : false,
        schedulesList: state.schedules && state.schedules.schedules_options ? state.schedules.schedules_options : false,
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ClassFilterMenu);