/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormSelect2 from '../../components/FormSelect2';
import FormDatePicker from '../../components/FormDatePicker';

import { InfoButton } from '../../styles/button';
import { SuccessTag, GreyTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { isAdmin, hasAccessRights, getUserRegionFilter } from '../../helpers/auth';

import { appChangesReset } from '../../actions/misc';

// import { PLATFORM_COURSE_REGISTRATION_LINK } from '../../constants';

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
`;

const OuterWrapper = styled.div`
    max-width: 800px;
`;

class SearchBox extends React.Component {
    
    state = {
        email: '',
        scheduleLoaded: true
    };

    componentDidMount() {
        const { authData } = this.props;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    handleFilterTrigger = () => {
        const { onFilter } = this.props;
        const { email } = this.state;
        let error = false;

        if ( !( email && validateEmail( email ) ) )
            error = 'Please insert a valid email';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            if ( onFilter ) {
                onFilter({ 
                    email
                });
            } // end - onFilter
        }
    }

    filterEmailInput = (value) => {
        // filter input to only allow email
        return value.replace(/[^a-zA-Z0-9+-_@.]/g, '');
    }

    renderMenu = () => {
        const { authData } = this.props;
        const { email } = this.state;
        return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <form onSubmit={(event) => {
                    event.preventDefault();
                    this.handleFilterTrigger();
                }}>
                    <FormInput 
                        value={( email || '' )} 
                        label="Search By Email" 
                        name="email" 
                        onChange={(newValue) => this.setState({ email: this.filterEmailInput(newValue) })} />
                </form>
                <div style={{ fontStyle: 'italic', fontSize: '12px' }}>* Please enter exact email address.</div>
            </Grid>
            <Grid item xs={12}>
                <InfoButton minWidth="128px" style={{ padding: '5px 15px' }} onClick={this.handleFilterTrigger}><i className="fa fa-search"></i>Search</InfoButton>
            </Grid>
        </Grid>
        )
    }

    render() {
        return (
        <OuterWrapper>

            <Paper elevation={2}>
                <Wrapper>
                    {this.renderMenu()}
                </Wrapper>
            </Paper>

        </OuterWrapper>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        coursesList: state.courses && state.courses.courses_options ? state.courses.courses_options : false,
        schedulesList: state.schedules && state.schedules.schedules_options ? state.schedules.schedules_options : false,
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(SearchBox);