/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { getMomentNow } from '../../helpers/date';

import theme from '../../theme';

import { PLATFORM_COMPANY_NAME } from '../../constants';

const Wrapper = styled.div`
    border-top: 1px solid #ddd;
    padding: 20px 40px;
    background: #fff;
    margin-top: 90px;
`;

const FooterLinks = styled.ul`
    display: block;
    text-align: right;

    li,
    a {
        font-family: 'Open Sans';
        font-size: 20px;
        font-weight: 400;
        color: #686868;

        @media (max-width:1024px) {
            font-size: 13px;
        }
    }

    li {
        display: inline-block;
        padding: 0px 15px;
        &:last-child {
            &:after { display: none; }
        }
    }

    a {
        color: #686868;
        &:hover {
            color: #ee422e;
        }
        &.active {
            color: #212121;
        }
    }

`;

const FooterLeft = styled.div`
    display: block;
    text-align: left;
    color: #999;
    font-size: 14px;

    a {
        color: #999;
        &:hover {
            color: #ee422e;
        }
    }

    & > span {
        padding: 0px 5px;
    }
`;

class Footer extends React.Component {

    handleLogout = event => {
        const { onLogout } = this.props;
        event.preventDefault();
        if ( onLogout )
            onLogout();
    }

    render() {
        const { authData, adminView } = this.props;
        return (
        <Wrapper>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={5}>
                    <FooterLeft>
                        Copyright © 2015 - {getMomentNow('YYYY')} {PLATFORM_COMPANY_NAME}
                        <span>|</span>
                        <a href="https://www.chumbaka.asia/privacy-policy" target="_blank">Privacy Policy</a>
                        <span>|</span>
                        <a href="https://www.chumbaka.asia/tos" target="_blank">Terms of Use</a>
                        { authData && !adminView ? (
                        <>
                            <span>|</span>
                            <a href="#" onClick={this.handleLogout}>Logout</a>
                        </>
                        ) : null }
                    </FooterLeft>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <div style={{ textAlign: "center" }}>
                        <img src="/assets/images/basquarewithtagandtransparentbackground.png" style={{
                            width: '40px',
                            height: 'auto',
                        }} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <FooterLinks>
                        <li>
                            <a href="https://www.instagram.com/chumbaka.cyberjaya/" target="_blank">
                                <i className="fa fa-instagram"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/channel/UCNhXOdykAfFPqSVAMo1_hKA" target="_blank">
                                <i className="fa fa-youtube-play"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/chumbaka.asia" target="_blank">
                                <i className="fa fa-facebook"></i>
                            </a>
                        </li>
                    </FooterLinks>
                </Grid>
            </Grid>
        </Wrapper>
        )
    }

}

export default Footer