/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";

import AppWrapper from "../../components/AppWrapper";
import NotificationsTable from "../../modules/NotificationsTable";
import ToolTipInfo from "../../components/ToolTipInfo";

import { isAdminType } from "../../helpers/auth";

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InfoLink, InverseButton, ErrorButton } from "../../styles/button";
import { InfoBox } from "../../styles/message";

import theme from "../../theme";

import { PLATFORM_NAME } from "../../constants";

const QuickLinksWrapper = styled.div`
    margin-top: 45px;
    padding: 30px;
    border: 1px solid #ddd;
    background: #fff;
    h3 {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #ddd;
    }
`;

const QuickLinksCard = styled.div`
    padding: 30px;
    text-align: center;

    img {
        display: block;
        max-width: 150px;
        width: 100%;
        height: auto;
        margin: 0 auto 20px auto;
    }

    h5 {
    }

    a {
        margin-top: 15px;
        padding: 10px 25px;
    }

    p {
        margin-top: 10px;
    }
`;

const DashboardIcon = styled.a`
    display: block;
    margin: 10px;
    text-align: center;
    padding: 90px 30px;
    color: #212121;
    font-weight: 700;
    border: 4px dashed ${theme.palette.background};

    i {
        display: block;
        font-size: 60px;
        margin-bottom: 10px;
    }

    &:hover {
        background: ${theme.palette.primary.main};
        border: 4px solid ${theme.palette.primary.main};
        color: #fff;
    }
`;

class DashboardPage extends React.Component {
    componentDidMount() {}

    componentDidUpdate(prevProps, prevState, snapshot) {}

    handleLinkClick = (url) => (event) => {
        const { history, changesNotSaved } = this.props;
        event.preventDefault();

        // if changes not saved - trigger warning
        if (changesNotSaved) {
            var answer = window.confirm(
                "You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?"
            );
            // reset status if answer is yes
            if (answer) {
                this.props.dispatch(appChangesReset());
                history.push(url);
            }
        } else {
            history.push(url);
        }
    };

    renderContents() {
        const { authData } = this.props;
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="h4">How Can I Help You Today?</Typography>
                    </Grid>
                </Grid>

                {authData && <NotificationsTable authData={authData} />}

                <QuickLinksWrapper>
                    <Typography variant="h3">Quick external links</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="h4" style={{ paddingBottom: "20px", textAlign: "center", fontWeight: "700" }}>
                                Public
                            </Typography>
                            <QuickLinksCard>
                                <img src="https://firebasestorage.googleapis.com/v0/b/chumbaka-website.appspot.com/o/website%2FCrazy_Eye_Logo_with_Stay_Curious.png?alt=media&token=47ed4cfa-d2f4-4ca6-b6ac-5313a5bcf835" />
                                <Typography variant="h5">Chumbaka Website</Typography>
                                <AInfoLink href="https://www.chumbaka.asia/" target="_blank">
                                    Visit
                                </AInfoLink>
                            </QuickLinksCard>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <QuickLinksCard>
                                        <img
                                            src="https://firebasestorage.googleapis.com/v0/b/chumbaka-website.appspot.com/o/website%2Fnotion.png?alt=media&token=e4d90009-68aa-438a-8c37-3e8e71d43486"
                                            style={{ maxWidth: "50px" }}
                                        />
                                        <Typography variant="h5">Chumbaka Wiki</Typography>
                                        <AInfoLink
                                            href="https://www.notion.so/chumbaka/Chumbaka-Partners-Portal-a64106d165f14d049d8364cc07bef4e7"
                                            target="_blank"
                                        >
                                            Partners Portal
                                        </AInfoLink>
                                        <AInfoLink
                                            href="https://www.notion.so/chumbaka/Chumbaka-Mentor-Portal-515d4eac7fd041f3a1878613c49c577c"
                                            target="_blank"
                                        >
                                            Mentors Portal
                                        </AInfoLink>
                                        <AInfoLink href="https://bit.ly/chumbakawiki" target="_blank">
                                            Public Portal
                                        </AInfoLink>
                                    </QuickLinksCard>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <QuickLinksCard>
                                        <img
                                            src="https://firebasestorage.googleapis.com/v0/b/chumbaka-dev-tms.appspot.com/o/file_storage%2Fdiscord.png?alt=media&token=34fa5e8d-f758-4fed-930c-2db7dd11b1d6"
                                            style={{ height: "50px", width: "auto" }}
                                        />
                                        <Typography variant="h5">Chumbaka Discord</Typography>
                                        <ToolTipInfo
                                            content={
                                                <>
                                                    🔑 Invite code:
                                                    <br />
                                                    <strong>pBdUBMr2A2</strong>
                                                </>
                                            }
                                        />
                                        <AInfoLink href="https://discord.com/invite/pBdUBMr2A2" target="_blank">
                                            Join
                                        </AInfoLink>
                                    </QuickLinksCard>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="h4" style={{ paddingBottom: "20px", textAlign: "center", fontWeight: "700" }}>
                                Student, Mentor
                            </Typography>
                            <QuickLinksCard>
                                <img
                                    src="https://firebasestorage.googleapis.com/v0/b/chumbaka-website.appspot.com/o/website%2Fcanvas.png?alt=media&token=f8f47a54-e99e-424a-905e-04ab0db27d59"
                                    style={{ maxWidth: "50px" }}
                                />
                                <Typography variant="h5">Learning Management System (LMS)</Typography>
                                <AInfoLink href="https://lms.chumbaka.asia/" target="_blank">
                                    Login
                                </AInfoLink>
                            </QuickLinksCard>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="h4" style={{ paddingBottom: "20px", textAlign: "center", fontWeight: "700" }}>
                                SOTF, HQ
                            </Typography>
                            <QuickLinksCard>
                                <img src="/assets/images/mattermost-logo.png" style={{ maxWidth: "50px" }} />
                                <Typography variant="h5">MatterMost</Typography>
                                <InfoBox style={{ textAlign: "left" }}>
                                    💡 Details you may need:
                                    <br />
                                    - Server Display Name: Chumbaka
                                    <br />- Server URL:{" "}
                                    <a href="https://team.chumbaka.asia/" target="_blank">
                                        https://team.chumbaka.asia/
                                    </a>
                                </InfoBox>
                                <AInfoLink href="https://team.chumbaka.asia/login" target="_blank">
                                    Login
                                </AInfoLink>
                                <AInfoLink href="https://team.chumbaka.asia/signup_user_complete/?id=14t3ima78jg6mfy4a6f4racuoh" target="_blank">
                                    SETUP ACCOUNT (BRAVEHEART)
                                </AInfoLink>
                                <AInfoLink href="https://team.chumbaka.asia/signup_user_complete/?id=du36f9ns9iywd84n8kosfqgs1y" target="_blank">
                                    SETUP ACCOUNT (Mentor)
                                </AInfoLink>
                                <AInfoLink href="https://mattermost.com/download/#" target="_blank">
                                    Download
                                </AInfoLink>
                            </QuickLinksCard>
                        </Grid>
                        {/* <Grid item xs={12} sm={4}>
                        <QuickLinksCard>
                            <img src="https://firebasestorage.googleapis.com/v0/b/chumbaka-website.appspot.com/o/website%2FCrazy_Eye_Logo_with_Stay_Curious.png?alt=media&token=47ed4cfa-d2f4-4ca6-b6ac-5313a5bcf835" />
                            <Typography variant="h5">Chumbaka World</Typography>
                            <AInfoLink href="https://account.chumbaka.asia/" target="_blank">Go to Chumbaka World</AInfoLink>
                            <Typography variant="body1">for student access (will be obsolete in June 2022 where data will be migrated over)</Typography>
                        </QuickLinksCard>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <QuickLinksCard>
                            <img src="https://firebasestorage.googleapis.com/v0/b/chumbaka-website.appspot.com/o/website%2FCrazy_Eye_Logo_with_Stay_Curious.png?alt=media&token=47ed4cfa-d2f4-4ca6-b6ac-5313a5bcf835" />
                            <Typography variant="h5">Chumbaka Old TMS</Typography>
                            <AInfoLink href="https://tms.chumbaka.asia/" target="_blank">Go to Old TMS</AInfoLink>
                            <Typography variant="body1">only for existing students who needs to enrol into old course. Registration link does not link to website (will be obsolete in June 2022)</Typography>
                        </QuickLinksCard>
                    </Grid> */}
                    </Grid>
                </QuickLinksWrapper>

                <QuickLinksWrapper>
                    <Typography variant="h3">My Profile </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <DashboardIcon href={"/student/badges"} onClick={this.handleLinkClick("/student/badges")}>
                                <i className="fa fa-shield"></i>
                                My Backpack
                            </DashboardIcon>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <DashboardIcon
                                href={isAdminType(authData) ? "/auth/profile" : "/student/profile"}
                                onClick={this.handleLinkClick(isAdminType(authData) ? "/auth/profile" : "/student/profile")}
                            >
                                <i className="fa fa-address-book-o"></i>
                                Edit Profile
                            </DashboardIcon>
                        </Grid>
                    </Grid>
                </QuickLinksWrapper>
            </div>
        );
    }

    render() {
        return <AppWrapper title={"Welcome to " + PLATFORM_NAME} maxWidth="1110px" contents={this.renderContents()} />;
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        sample: state.sample || null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(DashboardPage);
