/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_PROGRAMS,
	GET_PROGRAMS_OPTIONS,
	GET_PROGRAM,
	ADD_PROGRAM,
	EDIT_PROGRAM,
	DELETE_PROGRAM,
	GET_PROGRAMS_BY_PRODUCT,
	GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getPrograms, fb_getProgramsByProduct, fb_getProgramsOptions, fb_getProgram, fb_addProgram, fb_updateProgram, fb_deleteProgram } from './fb';


export const getPrograms = (props) => {
	return dispatch => {

		fb_getPrograms(props)
		.then(list => {
			dispatch({
				type: GET_PROGRAMS,
				payload: { list }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_PROGRAMS,
				payload: { list: [] }
			});
		})

	}
}

export const getProgramsByProduct = (product_id) => {
	return dispatch => {

		fb_getProgramsByProduct(product_id)
		.then(list => {
			dispatch({
				type: GET_PROGRAMS_BY_PRODUCT,
				payload: { list }
			});
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_PROGRAMS_BY_PRODUCT,
				payload: { list: [] }
			});
		})

	}

}

export const getProgramsOptions = (props) => {
	return dispatch => {
		fb_getProgramsOptions(list => {
			dispatch({
				type: GET_PROGRAMS_OPTIONS,
				payload: { list }
			});	
		},props);
	}
}

export const getProgram = (id) => {
	return dispatch => {

		fb_getProgram(id)
		.then(program => {
			dispatch({
				type: GET_PROGRAM,
				payload: { program }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_PROGRAM,
				payload: { program: {} }
			});
		})

	}
}

export const addProgram = (formData,history) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_addProgram(formData)
		.then( results => {
			if ( results && results.new_program_id && !_isEmpty( results.new_program_id ) ) {
				// updated
				dispatch({ type: ADD_PROGRAM });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Program Added");
				// redirect to program page
				history.push("/programs/" + results.new_program_id);
			} else {
				dispatch(toggleLoader(false));
				triggerErrorAlert('Missing new program ID');
			}
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const editProgram = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateProgram(formData)
		.then( results => {
			fb_getProgram(formData.id)
			.then(program => {
				// push new data
				dispatch({ type: GET_PROGRAM, payload: { program } });	
				// updated
				dispatch({ type: EDIT_PROGRAM });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Program Updated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const deleteProgram = (id) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteProgram(id)
		.then( results => {
			fb_getPrograms()
			.then(list => {
				dispatch({ type: GET_PROGRAMS, payload: { list } });	
				dispatch({ type: DELETE_PROGRAM });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("Program Deleted!");
			})
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}