/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import _isEmpty from "lodash/isEmpty";
// import _merge from 'lodash/merge';
// import _size from 'lodash/size';
// import _isEqual from 'lodash/isEqual';

import AppWrapper from "../../components/AppWrapper";
import AppInfoBox from "../../modules/AppInfoBox";
import Table from "./table";

import { isAdmin, getUserRegionFilter } from "../../helpers/auth";
// import { cloneCollections } from '../../helpers/data';

import { getMentors } from "../../actions/mentors";
import { getRegions } from "../../actions/m_regions";
import { getSchedulesOptions } from "../../actions/schedules";
// import { getMaintenance } from '../../actions/maintenance';
import { resetRedux } from "../../actions/misc";

import { ButtonGroup, AInfoLink, AInverseLink } from "../../styles/button";
import { InfoBox } from "../../styles/message";

const TableWrapper = styled.div`
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
`;

class DatabaseMentorsPage extends React.Component {
    state = {
        dataLoaded: false,
    };

    componentDidMount() {
        const { authData, mentorsList, schedulesList, regionsList } = this.props;

        // get mentors
        this.props.dispatch(getMentors({ region: getUserRegionFilter(authData) }));

        // get schedule options
        if (!schedulesList) this.props.dispatch(getSchedulesOptions({ region: getUserRegionFilter(authData) }));

        // get regions
        if (!regionsList) this.props.dispatch(getRegions());

        if (regionsList && mentorsList) {
            this.setState({ dataLoaded: true });
        } // end - randNum
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { mentorsList, regionsList, schedulesList } = this.props;
        if (mentorsList && regionsList && schedulesList && !this.state.dataLoaded) {
            this.setState({ dataLoaded: true });
        } // end - randNum
    }

    renderTable() {
        const { authData, mentorsList, regionsList, schedulesList } = this.props;
        return <Table mentors={mentorsList} regions={regionsList} schedules={schedulesList} authData={authData} />;
    }

    renderContents() {
        const { mentorsLis, classes } = this.props;
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                        <AppInfoBox page="DatabaseMentorsPage" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {" "}
                    </Grid>
                </Grid>
                <TableWrapper>{this.renderTable()}</TableWrapper>
            </>
        );
    }

    render() {
        const { dataLoaded } = this.state;
        const { authData } = this.props;
        return (
            <AppWrapper subtitle="Setup" title="LMS Users" onLoad={!dataLoaded ? true : false} contents={authData ? this.renderContents() : null} />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        mentorsList: state.mentors && state.mentors.list ? state.mentors.list : null,
        randNum: state.mentors && state.mentors.rand ? state.mentors.rand : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        schedulesList: state.schedules && state.schedules.schedules_options ? state.schedules.schedules_options : false,
    };
};

export default compose(connect(mapStateToProps), withRouter)(DatabaseMentorsPage);
