/* eslint-disable */
import React, { useState, useMemo } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import styled from "styled-components";
import shortid from "shortid";
import Typography from "@material-ui/core/Typography";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import _forEach from "lodash/forEach";
import _map from "lodash/map";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _slice from "lodash/slice";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _snakeCase from "lodash/snakeCase";

import Modal from "./modal";
import ShowcaseModal from "./modal_showcase";
import UploadBadgeNomination from "./upload_badge_nomination";
import { exportTeamsProjectsToCSV, exportTeamsDataToCSV } from "./export";
import { isClassTeacher, isClassMentor } from "./helpers";

import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import TableBar from "../../components/TableBar";
import ModalDelete from "../../components/ModalDelete";
import ButtonActions from "../../components/ButtonActions";

import {
    InfoButton,
    SuccessButton,
    GreyButton,
    ButtonGroup,
    InfoLink,
    SuccessLink,
    InverseButton,
    ErrorButton,
    AInfoLink,
    WarningButton,
} from "../../styles/button";
import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from "../../styles/tag";
import { WrapWord } from "../../styles/misc";

import { isArrayExists, isObjectExists, validURL } from "../../helpers/validation";
// import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from "../../helpers/data";
import { isAdmin, hasAccessRights, isMentorRole } from "../../helpers/auth";
import { getTeamProjectSubmission, isTeamBadgeIssued } from "../../helpers/showcase";
import { getStateValue } from "../../helpers/registration_form";

import theme from "../../theme";
import { triggerErrorAlert } from "../../helpers/alert";

const Wrapper = styled.div``;

const ScoreWrapper = styled.div`
    div {
        display: flex;
    }
    svg {
        margin-right: 8px;
    }
`;

const getSchoolList = (team, trainees) => {
    let list = [];
    if (team.trainees && isArrayExists(team.trainees)) {
        _forEach(team.trainees, (trainee) => {
            if (trainee.org && !_isEmpty(trainee.org)) {
                if (!_find(list, { name: trainee.org })) {
                    list.push({
                        id: _snakeCase(trainee.org),
                        name: trainee.org,
                    });
                }
            } else {
                let selected = _find(trainees, { id: trainee.id });
                if (selected && selected.org && !_isEmpty(selected.org) && !_find(list, { name: selected.org })) {
                    list.push({
                        id: _snakeCase(selected.org),
                        name: selected.org,
                    });
                }
            }
        });
    }
    return _sortBy(list, ["name"]);
};

const TeamsTable = ({
    authData,
    changesMade,
    view = "student",
    adminView = "registration",
    showTeamManagement = false,
    student = {},
    student_trainees = [],
    schedule = {},
    registration_forms = [],
    showcase = {},
    trainees = [],
    teams = [],
    schedules = [],
    teachers = [],
    mentors = [],
    onShowcaseAction = () => {},
    onAction = () => {},
}) => {
    const [modal, setModal] = useState({});
    const [showcaseModal, setShowcaseModal] = useState({});
    const [openUploadBadgeNomination, setOpenUploadBadgeNomination] = useState(false);
    const [deleteTeam, setDeleteTeam] = useState(false);
    const [deleteTeamTeacher, setDeleteTeamTeacher] = useState(false);
    const [searchterms, setSearchterms] = useState("");
    const [sortBy, setSortBy] = useState("name-asc");
    const [filterBy, setFilterBy] = useState("all");
    const [perPage, setPerPage] = useState(20);
    const [page, setPage] = useState(1);
    const [checked, setChecked] = useState([]);
    const [allCheck, setAllCheck] = useState(false);

    const isStudentView = view === "student";
    const isAdminView = view === "admin";

    const teamsData = useMemo(() => {
        let items = teams ? cloneCollections(teams) : [],
            total = _size(items),
            emptyRow = [];

        // for student view only
        if (isStudentView) {
            // add empty team if available
            if (schedules && isArrayExists(schedules)) {
                _forEach(schedules, (schedule) => {
                    if (schedule && schedule.team_module && schedule.team_module === "yes") {
                        const studentTraineeData =
                            student_trainees && isArrayExists(student_trainees) ? _find(student_trainees, { schedule_id: schedule.id }) : false;
                        const selectedTeams = _filter(items, { schedule_id: schedule.id });
                        if (selectedTeams && isArrayExists(selectedTeams)) {
                            _forEach(selectedTeams, (selectedTeam, index) => {
                                items[index].class_name = schedule.class_name || "";
                                items[index].program_name = schedule.program_name || "";
                                items[index].project_submissions = schedule.project_submissions || "3";
                            });
                        } else {
                            emptyRow.push(schedule.id);
                            items.push({
                                id: schedule.id,
                                region: schedule.region,
                                schedule_id: schedule.id,
                                class_name: schedule.class_name || "",
                                program_name: schedule.program_name || "",
                                project_submissions: schedule.project_submissions || "3",
                                name: "",
                                school: "",
                                state: getStateValue(studentTraineeData),
                                trainees: [],
                            });
                        }
                    }
                });
            } // end - schedules

            /// if is teacher role, create an empty row for each schedule if no team is available
            if (schedules && isArrayExists(schedules)) {
                _forEach(schedules, (schedule) => {
                    const studentTraineeData =
                        student_trainees && isArrayExists(student_trainees) ? _find(student_trainees, { schedule_id: schedule.id }) : false;
                    if (
                        schedule &&
                        schedule.team_module &&
                        schedule.team_module === "yes" &&
                        isClassTeacher(schedules, schedule.id, authData.email) &&
                        !(emptyRow && isArrayExists(emptyRow) && _find(emptyRow, (r) => r === schedule.id))
                    ) {
                        items.push({
                            id: schedule.id,
                            region: schedule.region,
                            schedule_id: schedule.id,
                            class_name: schedule.class_name || "",
                            program_name: schedule.program_name || "",
                            project_submissions: schedule.project_submissions || "3",
                            name: "",
                            school: "",
                            state: getStateValue(studentTraineeData),
                            trainees: [],
                        });
                    }
                });
            } // end - schedules

            if (isMentorRole(authData)) {
                if (filterBy && !_isEmpty(filterBy) && filterBy !== "all") {
                    items = _filter(items, { state: filterBy });
                    total = _size(items);
                } // end - filterBy
            }
            return { items, total };
        }

        // do search
        if (searchterms && !_isEmpty(searchterms)) {
            // items = doArraySearch( items, searchterms, ['name','school'] );
            // find teachers, mentors, student
            items = _filter(items, (item) => {
                let found = false;
                // find team name
                if (item.name && item.name.toLowerCase().indexOf(searchterms.toLowerCase()) >= 0) {
                    found = true;
                }

                // find school name
                if (item.school && item.school.toLowerCase().indexOf(searchterms.toLowerCase()) >= 0) {
                    found = true;
                }

                // find teacher name
                let teacher = _find(teachers, { email: item.teacher });
                if (teacher && teacher.name && teacher.name.toLowerCase().indexOf(searchterms.toLowerCase()) >= 0) {
                    found = true;
                }
                // find mentor name
                let mentor = _find(mentors, { email: item.mentor });
                if (mentor && mentor.name && mentor.name.toLowerCase().indexOf(searchterms.toLowerCase()) >= 0) {
                    found = true;
                }
                // find student name
                if (item && item.trainees && isArrayExists(item.trainees)) {
                    _forEach(item.trainees, (trainee) => {
                        let selected = _find(trainees, { id: trainee.id });
                        if (selected && selected.name && selected.name.toLowerCase().indexOf(searchterms.toLowerCase()) >= 0) {
                            found = true;
                        }
                    });
                }
                return found;
            });
            total = _size(items);
        } // end - searchterms

        // do filter
        if (isMentorRole(authData) || isAdmin(authData)) {
            if (filterBy && !_isEmpty(filterBy) && filterBy !== "all") {
                items = _filter(items, { state: filterBy });
                total = _size(items);
            } // end - filterBy
        }

        // do sort
        if (sortBy && !_isEmpty(sortBy) && !_isEmpty(items)) {
            switch (sortBy) {
                case "name-desc":
                    items = _sortBy(items, ["name"]);
                    items = _reverse(items);
                    break;
                case "name-asc":
                    items = _sortBy(items, ["name"]);
                    break;
                case "org-desc":
                    items = _sortBy(items, ["org"]);
                    items = _reverse(items);
                    break;
                case "org-asc":
                    items = _sortBy(items, ["org"]);
                    break;
                case "state-desc":
                    items = _sortBy(items, ["state"]);
                    items = _reverse(items);
                    break;
                case "state-asc":
                    items = _sortBy(items, ["state"]);
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination(items, perPage, page);

        return { items, total };
    }, [teams, sortBy, filterBy, perPage, page, view, schedules, searchterms, student_trainees, authData]);

    const buttonActions = useMemo(() => {
        let actions = [];

        if (adminView === "showcase") {
            actions.push({
                id: "upload_badge_nomination",
                label: "Upload badge nomination for teams",
                icon: "fa-file-excel-o",
                onClick: () => {
                    if (!changesMade) {
                        setOpenUploadBadgeNomination(true);
                    } else {
                        triggerErrorAlert("Please save changes first");
                    } // end - checked
                },
            });
        } // end - isAdminView

        if (adminView === "registration") {
            actions.push({
                id: "create_team",
                label: "Create New Team",
                icon: "fa-plus",
                onClick: () => {
                    if (!changesMade) {
                        setModal({
                            item: {
                                id: shortid.generate(),
                                schedule_id: schedule.id,
                            },
                            type: "create",
                        });
                    } else {
                        triggerErrorAlert("Please save changes first");
                    } // end - checked
                },
            });
        }

        actions.push({
            id: "export",
            label: "Export Data to CSV",
            icon: "fa-file-excel-o",
            disabled: checked && isArrayExists(checked) ? false : true,
            onClick: () => {
                if (handleCSVExport) {
                    handleCSVExport();
                }
            },
        });

        return actions;
    }, [adminView, changesMade, checked]);

    const cells = useMemo(() => {
        let cells = [];

        if (isStudentView) {
            // cells.push({
            //     id: 'program_name', label: 'Program Name', headstyle: { width: '15%' }, render: (item) => <WrapWord>{( item.program_name || '-' )}</WrapWord>,
            // });
            cells.push({
                id: "class_name",
                label: "Class Name",
                headstyle: { width: "15%" },
                render: (item) => {
                    const schedule = _find(schedules, { id: item.schedule_id });
                    if (schedule) {
                        return <WrapWord>{schedule.class_name || "-"}</WrapWord>;
                    } else {
                        return <WrapWord>{item.class_name || "-"}</WrapWord>;
                    }
                },
            });
            cells.push({
                id: "state",
                label: "State",
                render: (item) => {
                    const studentTraineeData =
                        student_trainees && isArrayExists(student_trainees) ? _find(student_trainees, { schedule_id: item.schedule_id }) : false;
                    return (
                        <WrapWord>
                            {item.state && !_isEmpty(item.state)
                                ? item.state
                                : studentTraineeData && isObjectExists(studentTraineeData)
                                ? getStateValue(studentTraineeData)
                                : ""}
                        </WrapWord>
                    );
                },
            });

            if (showTeamManagement) {
                // cells.push({
                //     id: 'role', label: 'Role', render: (item) => {
                //         return <WrapWord>{ isClassTeacher(schedules,item.schedule_id,authData.email) ? 'Teacher' : isClassMentor(schedules,item.id,authData.email) ? 'Mentor' : 'Student' }</WrapWord>;
                //     }
                // });
            }
        } else {
            cells.push({
                id: "state",
                label: "State",
                render: (item) => {
                    return <WrapWord>{item.state || "-"}</WrapWord>;
                },
            });
        }

        cells.push({
            id: "school",
            label: "School",
            render: (item) => {
                const schoolsList = getSchoolList(item, trainees);
                return schoolsList ? (
                    <div>
                        {_map(schoolsList, (school) => {
                            return (
                                <div key={school.id} style={{ fontSize: "11px" }}>
                                    <WrapWord>{school.name || ""}</WrapWord>
                                </div>
                            );
                        })}
                    </div>
                ) : null;
            },
        });

        cells.push({
            id: "name",
            label: "Team Name",
            headstyle: { width: "15%" },
            render: (item) => {
                if (item.name && !_isEmpty(item.name)) {
                    return adminView && adminView === "showcase" ? (
                        <InfoLink
                            onClick={(e) => {
                                e.preventDefault();
                                if (changesMade) {
                                    triggerErrorAlert("Please save changes first");
                                } else {
                                    setModal({
                                        item,
                                        type: "edit",
                                    });
                                }
                            }}
                        >
                            <WrapWord>{item.name}</WrapWord>
                        </InfoLink>
                    ) : (
                        <WrapWord>{item.name}</WrapWord>
                    );
                }

                return !isArrayExists(item.trainees) && !isClassTeacher(schedules, item.id, authData.email) ? (
                    <em style={{ fontStyle: "italic" }}>{"* You haven't join any team yet"}</em>
                ) : (
                    "---"
                );
            },
        });

        if (isStudentView) {
            if (showTeamManagement) {
                cells.push({
                    id: "teacher",
                    label: "Teacher",
                    render: (item) => {
                        let teacher = _find(teachers, { email: item.teacher });
                        return <WrapWord>{(teacher && teacher.name) || "-"}</WrapWord>;
                    },
                });
                cells.push({
                    id: "mentor",
                    label: "Mentor",
                    render: (item) => {
                        let mentor = _find(mentors, { email: item.mentor });
                        return <WrapWord>{(mentor && mentor.name) || "-"}</WrapWord>;
                    },
                });

                cells.push({
                    id: "students",
                    label: "Students",
                    render: (item) => (
                        <WrapWord>
                            {item.trainees && isArrayExists(item.trainees)
                                ? _map(_sortBy(item.trainees, [(t) => (t.name && t.name.toLowerCase()) || ""]), (trainee) => {
                                      return (
                                          <div key={trainee.id} style={{ fontSize: "11px" }}>
                                              {(trainee && trainee.name) || ""}
                                          </div>
                                      );
                                  })
                                : "--"}
                        </WrapWord>
                    ),
                });
            } else {
                cells.push({
                    id: "total_members",
                    label: "Total Members",
                    headstyle: { width: "10%" },
                    render: (item) => <WrapWord>{item.trainees && isArrayExists(item.trainees) ? _size(item.trainees) : ""}</WrapWord>,
                });
            }
        }

        if (isAdminView) {
            if (adminView === "registration") {
                cells.push({
                    id: "teacher",
                    label: "Teacher",
                    render: (item) => {
                        let teacher = _find(teachers, { email: item.teacher });
                        return <WrapWord>{(teacher && teacher.name) || "-"}</WrapWord>;
                    },
                });
                cells.push({
                    id: "mentor",
                    label: "Mentor",
                    render: (item) => {
                        let mentor = _find(mentors, { email: item.mentor });
                        return <WrapWord>{(mentor && mentor.name) || "-"}</WrapWord>;
                    },
                });
            }

            cells.push({
                id: "students",
                label: "Students",
                render: (item) => (
                    <WrapWord>
                        {item.trainees && isArrayExists(item.trainees)
                            ? _map(_sortBy(item.trainees, [(t) => (t.name && t.name.toLowerCase()) || ""]), (trainee) => {
                                  let selected = trainees && isArrayExists(trainees) ? _find(trainees, { id: trainee.id }) : false;
                                  return (
                                      <div key={trainee.id} style={{ fontSize: "11px" }}>
                                          {(selected && selected.name) || ""}
                                      </div>
                                  );
                              })
                            : "--"}
                    </WrapWord>
                ),
            });

            if (adminView === "showcase") {
                cells.push({
                    id: "projects_submission",
                    label: "Project(s) Submission",
                    headstyle: { width: "30%" },
                    render: (item) => {
                        const projects = getTeamProjectSubmission({ teams, id: item.id, schedule });
                        return projects.map((project) => {
                            return (
                                <div key={project.id} style={{ fontSize: "11px", marginBottom: "5px" }}>
                                    <strong style={{ fontWeight: "700" }}>Project #{project.id}:</strong>
                                    {project.title && !_isEmpty(project.title) && project.desc && validURL(project.desc) ? (
                                        <a target="_blank" href={project.desc} style={{ padding: "0px", marginLeft: "5px" }}>
                                            {project.title}
                                        </a>
                                    ) : project.title && !_isEmpty(project.title) ? (
                                        project.title
                                    ) : (
                                        <i className="fa fa-times" style={{ color: "#9e0000", marginLeft: "10px" }}></i>
                                    )}
                                </div>
                            );
                        });
                    },
                });
                cells.push({
                    id: "score",
                    label: "Score",
                    // headstyle: { width: '10%' },
                    render: (item) =>
                        (item.score && item.score === "pass") || item.score === "fail" ? (
                            <ScoreWrapper>
                                {item.score === "pass" ? (
                                    <SuccessTag>
                                        <ThumbUpIcon />
                                        PASS
                                    </SuccessTag>
                                ) : (
                                    <ErrorTag>
                                        <ThumbDownIcon />
                                        FAIL
                                    </ErrorTag>
                                )}
                            </ScoreWrapper>
                        ) : (
                            "--"
                        ),
                });
                cells.push({
                    id: "nominated_badge_type",
                    label: "Nominated Badge Type",
                    // headstyle: { width: '10%' },
                    render: (item) => (
                        <WrapWord>
                            {getSelectedValue(
                                (showcase && showcase.badges) || [],
                                (item && item.badge && item.badge.nominated_badge_type) || false,
                                "type",
                                "type_label",
                                "---"
                            )}
                        </WrapWord>
                    ),
                });
            }
        } // end - isAdminView

        return cells;
    }, [view, teachers, mentors, trainees, teams, showTeamManagement, schedules, authData]);

    const handleCSVExport = () => {
        // event.preventDefault();
        if (isArrayExists(checked)) {
            switch (adminView) {
                case "showcase":
                    exportTeamsProjectsToCSV({
                        schedule,
                        teams: _filter(teams, (t) => _find(checked, { id: t.id })),
                        trainees,
                        showcase,
                    });
                    break;
                case "registration":
                    exportTeamsDataToCSV({
                        schedule,
                        registration_forms,
                        teams: _filter(teams, (t) => _find(checked, { id: t.id })),
                        trainees,
                        mentors,
                        teachers,
                    });
                    break;
            }
        } else {
            triggerErrorAlert("Please select at least one team to export.");
        }
    };

    // render action buttons based on view
    const renderActions = (item) => {
        const btnStyle = { textTransform: "initial" };
        if (isStudentView) {
            const noTeamYet = !isArrayExists(item.trainees);
            const classTeacher = isClassTeacher(schedules, item.schedule_id, authData.email);
            return noTeamYet ? (
                <ButtonGroup>
                    {classTeacher ? (
                        <>
                            <InfoButton
                                style={btnStyle}
                                onClick={() =>
                                    setModal({
                                        item,
                                        type: "create",
                                    })
                                }
                            >
                                Create new team for this class
                            </InfoButton>
                        </>
                    ) : (
                        <>
                            <InfoButton
                                style={btnStyle}
                                onClick={() =>
                                    setModal({
                                        item,
                                        type: "create",
                                    })
                                }
                            >
                                Create new team
                            </InfoButton>
                            <InfoButton
                                style={btnStyle}
                                onClick={() =>
                                    setModal({
                                        item,
                                        type: "join",
                                    })
                                }
                            >
                                Join a team
                            </InfoButton>
                        </>
                    )}
                </ButtonGroup>
            ) : (
                <ButtonGroup>
                    <InfoButton
                        style={btnStyle}
                        onClick={() =>
                            setModal({
                                item,
                                type: "edit",
                            })
                        }
                    >
                        Edit Team
                    </InfoButton>
                    {classTeacher ? (
                        <ErrorButton
                            style={btnStyle}
                            onClick={() => {
                                setDeleteTeamTeacher(item);
                            }}
                        >
                            Delete Team
                        </ErrorButton>
                    ) : (
                        <span></span>
                    )}
                </ButtonGroup>
            );
        }

        if (isAdminView && adminView === "showcase") {
            return (
                <ButtonGroup>
                    {isTeamBadgeIssued({ trainees, team: item }) ? (
                        <InfoButton
                            key="edit"
                            size="small"
                            onClick={() =>
                                setShowcaseModal({
                                    item,
                                })
                            }
                        >
                            Edit
                        </InfoButton>
                    ) : item.score && (item.score === "pass" || item.score === "fail") ? (
                        <InfoButton
                            key="edit"
                            size="small"
                            onClick={() =>
                                setShowcaseModal({
                                    item,
                                })
                            }
                        >
                            Edit
                        </InfoButton>
                    ) : (
                        <WarningButton
                            key="review"
                            size="small"
                            onClick={() =>
                                setShowcaseModal({
                                    item,
                                })
                            }
                        >
                            Review Now
                        </WarningButton>
                    )}
                </ButtonGroup>
            );
        }

        return (
            <ButtonGroup>
                <InfoButton
                    key="delete"
                    size="small"
                    onClick={() =>
                        setModal({
                            item,
                            type: "edit",
                        })
                    }
                >
                    <i className="fa fa-edit"></i>Edit
                </InfoButton>
                {authData && hasAccessRights(authData, ["scd"]) ? (
                    <ErrorButton
                        key="delete"
                        size="small"
                        onClick={() => {
                            setDeleteTeam(item);
                        }}
                    >
                        <i className="fa fa-trash"></i>Delete
                    </ErrorButton>
                ) : null}
            </ButtonGroup>
        );
    };

    return (
        <Wrapper>
            {isStudentView && !isMentorRole(authData) ? null : (
                <TableBar
                    sortBy={sortBy}
                    show={["entries", "sort", "search", "filter"]}
                    perPage={perPage}
                    filterBy={filterBy}
                    searchterms={searchterms}
                    sortByOptions={[
                        { value: "name-asc", label: "Name ( A - Z)" },
                        { value: "name-desc", label: "Name ( Z - A )" },
                        { value: "org-asc", label: "School ( A - Z)" },
                        { value: "org-desc", label: "School ( Z - A )" },
                        { value: "state-asc", label: "State ( A - Z)" },
                        { value: "state-desc", label: "State ( Z - A )" },
                    ]}
                    leftButtons={
                        checked && isArrayExists(checked)
                            ? [
                                  <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                                      <GreyButton
                                          style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }}
                                          onClick={() => {
                                              setChecked([]);
                                              setAllCheck(false);
                                          }}
                                      >
                                          <i className="fa fa-remove" style={{ marginRight: "10px" }}></i>
                                          {_size(checked) + " selected"}
                                      </GreyButton>
                                  </div>,
                              ]
                            : null
                    }
                    // rightButtons={ isAdminView ? [
                    //     <InfoButton key="export" disabled={( checked && isArrayExists( checked ) ? false : true )} style={{ marginRight: '5px', paddingLeft: '20px', paddingRight: '20px' }} onClick={handleCSVExport}><i className='fa fa-file-excel-o'></i>Export Data to CSV</InfoButton>
                    // ] : null }
                    rightButtons={
                        isAdminView
                            ? [
                                  <ButtonActions
                                      key="actions"
                                      label="Actions"
                                      menuContainerStyle={{ width: "300px" }}
                                      style={{ marginRight: "5px" }}
                                      actions={buttonActions}
                                  />,
                              ]
                            : null
                    }
                    filterByOptions={
                        isMentorRole(authData) || isAdmin(authData)
                            ? getSelectOptions({
                                  list: [
                                      { value: "Johor" },
                                      { value: "Kedah" },
                                      { value: "Kelantan" },
                                      { value: "Kuala Lumpur" },
                                      { value: "Labuan" },
                                      { value: "Malacca" },
                                      { value: "Negeri Sembilan" },
                                      { value: "Pahang" },
                                      { value: "Perak" },
                                      { value: "Perlis" },
                                      { value: "Penang" },
                                      { value: "Putrajaya" },
                                      { value: "Sabah" },
                                      { value: "Sarawak" },
                                      { value: "Selangor" },
                                      { value: "Terengganu" },
                                  ],
                                  options: [{ value: "all", label: "All States" }],
                                  keys: { value: "value", label: "value" },
                              })
                            : null
                    }
                    onEntriesChange={(newPerPage) => {
                        setPerPage(newPerPage);
                        setPage(1);
                    }}
                    onSearchChange={(terms) => {
                        setSearchterms(terms);
                        setPage(1);
                    }}
                    onSortByChange={(newSortBy) => {
                        setSortBy(newSortBy);
                        setPage(1);
                    }}
                    onFilterByChange={(newFilterBy) => {
                        setFilterBy(newFilterBy);
                        setPage(1);
                    }}
                    style={{ marginBottom: "20px" }}
                />
            )}

            {!isStudentView && adminView && adminView === "showcase" && (
                <Pagination
                    total={teamsData.total}
                    perPage={perPage}
                    page={page}
                    style={{ paddingBottom: "10px" }}
                    doneLoaded={true}
                    // entriesLabel="(Total Registration For This Class)"
                    onPageChange={(newPage) => setPage(newPage)}
                />
            )}

            <Table
                items={teamsData.items}
                showCheckbox={isAdminView ? true : false}
                emptyCell={view === "student" ? "Team profile not available for your account." : "No team(s) found."}
                checked={checked || []}
                allCheck={allCheck}
                onChecked={(newValue) => setChecked(newValue)}
                onAllChecked={(newValue) => setAllCheck(newValue)}
                cells={teamsData.items && isArrayExists(teamsData.items) ? cells : []}
                actionStyles={{ width: isStudentView ? "20%" : adminView && adminView === "showcase" ? "15%" : "20%" }}
                actions={renderActions}
            />

            {isStudentView ? null : (
                <Pagination
                    total={teamsData.total}
                    perPage={perPage}
                    page={page}
                    style={{ marginTop: "20px" }}
                    doneLoaded={true}
                    // entriesLabel="(Total Registration For This Class)"
                    onPageChange={(newPage) => setPage(newPage)}
                />
            )}

            {modal.type && (
                <Modal
                    view={view}
                    authData={authData}
                    student={student}
                    student_trainees={student_trainees}
                    schedule={schedule}
                    schedules={schedules}
                    trainees={trainees}
                    item={modal.item}
                    modalType={modal.type}
                    teamsList={isStudentView ? [] : teamsData.items}
                    onAction={onAction}
                    onClose={() => setModal({})}
                />
            )}

            {showcaseModal && isObjectExists(showcaseModal) && (
                <ShowcaseModal
                    authData={authData}
                    schedule={schedule}
                    trainees={trainees}
                    team={showcaseModal.item}
                    teams={teams}
                    onAction={onShowcaseAction}
                    onClose={() => setShowcaseModal({})}
                />
            )}

            {adminView && adminView === "showcase" && (
                <UploadBadgeNomination
                    open={openUploadBadgeNomination}
                    authData={authData}
                    schedule={schedule}
                    showcase={showcase}
                    trainees={trainees}
                    teams={teams}
                    onImport={onShowcaseAction}
                    onClose={() => setOpenUploadBadgeNomination(false)}
                />
            )}

            <ModalDelete
                open={deleteTeam && isObjectExists(deleteTeam) ? true : false}
                title={deleteTeam && deleteTeam.name ? `Are you sure you want to remove this team ( ${deleteTeam.name} )?` : false}
                onClose={() => setDeleteTeam(false)}
                onDelete={() => {
                    onAction("delete", { id: deleteTeam.id });
                    setDeleteTeam(false);
                }}
            />

            <ModalDelete
                open={deleteTeamTeacher && isObjectExists(deleteTeamTeacher) ? true : false}
                title={
                    deleteTeamTeacher && deleteTeamTeacher.name ? `Are you sure you want to remove this team ( ${deleteTeamTeacher.name} )?` : false
                }
                onClose={() => setDeleteTeamTeacher(false)}
                onDelete={() => {
                    onAction("delete_teacher", { id: deleteTeamTeacher.id, schedule_id: deleteTeamTeacher.schedule_id });
                    setDeleteTeamTeacher(false);
                }}
            />
        </Wrapper>
    );
};

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null,
    };
};

export default compose(connect(mapStateToProps))(TeamsTable);
