/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _snakeCase from 'lodash/snakeCase';
import _find from 'lodash/find';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists, validateEmail, isObjectExists } from '../../helpers/validation';
import { getSnapshotDataBySchema, getDocDataBySchema } from '../../helpers/schemas';

import { programSchema } from '../../schemas/program';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_checkLMSCourseUsage = (lms_course_id,program_id) => {
    return new Promise((resolve,reject) => {

        firebase.database().ref("usage/lms_courses_enrollments/"+lms_course_id+"/enrollments").orderByChild("program_id").equalTo(program_id).once("value")
        .then(snapshot => {
            let enrollments = [];
            if ( snapshot && snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach( childSnapshot => {
                    let enrollment = childSnapshot.val();
                    if ( enrollment && enrollment.status && enrollment.status === 'active' ) {
                        enrollments.push( enrollment );
                    }
                });
            } // end - snapshot
            resolve(enrollments);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getPrograms = (props) => {
    return new Promise((resolve,reject) => {

        firebase.firestore().collection("programs").get()
        .then(snapshot => {
            let list = [];
            if ( snapshot ) {
                snapshot.forEach(doc => {
                    let item = getDocDataBySchema( programSchema, doc );
                    list.push( item );
                });
            } // end - snapshot
            resolve(list);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getProgramsByProduct = (product_id) => {
    return new Promise((resolve,reject) => {

        firebase.firestore().collection("programs").where("products_ids","array-contains-any",[product_id]).get()
        .then(snapshot => {
            let list = [];
            if ( snapshot ) {
                snapshot.forEach(doc => {
                    let item = getDocDataBySchema( programSchema, doc );
                    list.push( item );
                });
            } // end - snapshot
            resolve(list);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getProgramsOptions = (callback,props = {}) => {
    return new Promise((resolve,reject) => {
        const { region } = props;
        let Ref = firebase.firestore().collection("programs");

        if ( region && !_isEmpty( region ) )
            Ref = Ref.where("program_visibility_ids","array-contains-any",[region]);

        Ref.onSnapshot(snapshot => {
            const list = [];
            if ( snapshot ) {
                snapshot.forEach( doc => {
                    let item = getDocDataBySchema( programSchema, doc );
                    list.push( item );
                });
            } // end - snapshot
            callback(list);
        });

    })
}


export const fb_getProgram = (id) => {
    return new Promise((resolve,reject) => {

        let promises = [];
        promises.push( firebase.firestore().collection("programs").doc(id).get() );
        
        Promise.all( promises )
        .then(results => {
            let item = {};
            if ( results && results[0] ) {
                item = getDocDataBySchema( programSchema, results[0] );
            } // end - results[0]
            resolve(item);
        })
        .catch(error => {
            reject(error);
        });
    })
}

export const fb_addProgram = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'program', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateProgram = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'program', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteProgram = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'program', action: 'delete', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}