import _random from 'lodash/random';

import { 
	GET_BADGES,
    GET_BADGES_ALL,
    GET_BADGES_ONE_OFF,
	ADD_BADGE,
	EDIT_BADGE,
	DELETE_BADGE,
    RESET_BADGES_ONE_OFF,
    RESET_BADGES
} from '../actions/types';

const initialState = {
    badges: null,
    updated: false,
    rand: false
};

export const badges = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_BADGES:
            return {
                ...state,
                badges: ( payload.list || [] ),
                rand: _random(1,9999)
            }; 
        case GET_BADGES_ALL:
            return {
                ...state,
                badges_all: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case GET_BADGES_ONE_OFF:
            return {
                ...state,
                badges_one_off: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case ADD_BADGE:
        case EDIT_BADGE:
        case DELETE_BADGE:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_BADGES_ONE_OFF:
            return {
                ...state,
                badges_one_off: null,
                rand: false
            };
        case RESET_BADGES:
            return {
                ...state,
                badges: null,
                rand: false
            };
        default:
            return state;
    }
}