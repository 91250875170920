/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _toUpper from 'lodash/toUpper';

import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormDatePicker from '../../components/FormDatePicker';

import { FormBox } from '../../styles/form';
import { InfoBox } from '../../styles/message';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';

import { trainingLocationSchema } from '../../schemas/training_location';
import { COUNTRIES_LIST } from '../../data/const_countries';
import { STATE_OPTIONS } from '../../data/const_states';

import { YES_NO_OPTIONS, GENDER_OPTIONS } from '../../constants';


const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

class LocationForm extends React.Component {

    state = {
        randNum: false
    };

    handleFormUpdate = ( newValue, key ) => {
        const { onFormUpdate, venue } = this.props;
        let newData = ( venue && !_isEmpty( venue ) ? cloneCollections( venue ) : {} );

        switch ( key ) {
            case 'id':
                newData[key] = _toUpper( newValue );
                break;
            default:
                newData[key] = newValue;
                break;
        }

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    getFieldOptions = (field) => {
        const { venue, regions } = this.props;

        switch( field.name ) {
            case 'publish':
                return YES_NO_OPTIONS;
            case 'region':
                return getSelectOptions({ list: ( regions ? _sortBy( regions, [ r => ( r.label ? r.label.toLowerCase() : '' ) ] ) : false ), options: [{ value: '', label: 'Select an Option' }], keys: { value: 'id', label: 'label' } });
            case 'state':
                let selected = ( venue && venue.country && !_isEmpty( venue.country ) ? _find( COUNTRIES_LIST, { value: venue.country }) : false ),
                    country = ( selected && selected.label && !_isEmpty( selected.label ) ? _find( STATE_OPTIONS, { country: selected.label }) : false ),
                    options = [{ value: '', label: 'Select an Option' },{ value: 'online', label: 'Online' }];
                if ( country && country.states && isArrayExists( country.states ) ) {
                    country.states.forEach(state => {
                        options.push({ value: state, label: state });
                    });
                } // end - country.states
                return options;
            case 'country':
                return getSelectOptions({ list: COUNTRIES_LIST, options: [{ value: '', label: 'Select an Option' }], keys: { value: 'value', label: 'label' } });
            default:
                return [];
        }
    }

    getFieldValue = (field) => {
        const { venue } = this.props;
        switch( field.name ) {
            default:
                return ( venue && venue[field.name] ? venue[field.name] : ( field.default || '' ) );
        }
    }

    isFieldDisabled = (schema) => {
        const { authData, venue, type } = this.props;
        var disabled = false;

        if ( schema && schema.disabled && isArrayExists( schema.disabled ) ) {
            schema.disabled.forEach( condition => {
                switch( condition ) {
                    case 'admin':
                        if ( !isAdmin( authData ) )
                            disabled = true;
                        break;
                    case 'update':
                        if ( type && type === 'edit' ) {
                            disabled = true;
                        }
                        break;
                }
            });
        } // end - schema

        return disabled
    }

    getTooltip = (schema) => {
        switch( schema.id ) {
            case 'guide_to_venue':
                return (
                <>
                    <strong>PUBLISHED ON EMAIL</strong><br />
                    <p>Make a copy of the template <a href="https://docs.google.com/document/d/1AenOYLQcixsZo4W9VyYX-AZPCtp7OSwKRztRKySskhc/copy" target="_blank">Here</a></p>
                    <p>Don’t forget to to enable public sharing to allow anyone on the internet to view the link.</p>
                    <p>This information will be embedded in the email to parents and students. Help them to navigate to your venue easily!</p>
                </>
                );
            case 'map_link':
                return (
                <>
                    <strong>PUBLISHED ON EMAIL</strong><br />
                    This information will be embedded in the email to parents and students. Help them to navigate to your venue easily!
                </>
                );
            case 'name': 
                return (
                <>
                    <strong>PUBLISHED ON WEBSITE</strong><br />
                    This information will be published on website. Check how it looks like <a href="https://www.chumbaka.asia/our-program/#ourprogram" target="_blank">here</a>.<br /><br />
                    If this is an online class, follow this format “Virtual on Zoom (YOUR STATE)”. 
                </>
                );
            case 'state':
                return (
                <>
                    <strong>PUBLISHED ON WEBSITE</strong><br />
                    This information will be published on website. Check how it looks like <a href="https://www.chumbaka.asia/our-program/#ourprogram" target="_blank">here</a><br /><br />
                    If this is an online class, choose “Online”. 
                </>
                );
            case 'publish':
                return "This links to location & map on chumbaka.asia website.";
            default:
                return;
        }
    }

    getField = (id) => {
        const { massEditMode } = this.props;
        let schema = _find( trainingLocationSchema, { id } );
        return ( schema ? {
            name: ( schema.id || '' ),
            label: ( schema.label || '' ) + ( isSchemaRequired(schema,'update') && !massEditMode ? ' (Required)' : '' ),
            field_type: ( schema.field || '' ),
            default: ( massEditMode ? null : ( schema.default || null ) ),
            disabled: ( schema.id === 'publish' ? true : ( schema.disabled ? this.isFieldDisabled( schema ) : false ) ),
            tooltip: this.getTooltip(schema)
        } : null );
    }

    renderField = (id) => {
        let field = this.getField(id);
        if ( field && field.field_type && !_isEmpty( field.field_type ) ) {
            switch( field.field_type ) {
                case 'text':
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'textarea':
                    return <FormInput {...field} rows={( 'notes' === id ? 6 : 3 )} multiline={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'text_number':
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'email':
                    return <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'select':
                    return <div style={{ paddingTop: "7px" }}><FormSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} /></div>;
                case 'multiselect':
                    return <div style={{ paddingTop: "7px" }}><FormMultiSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} /></div>;
                case 'datepicker':
                    return <FormDatePicker {...field} noDefaultVal={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
            }
        } // end - field.field_type
    }

    renderCreatedModifiedDate = (item) => {
        let created_on = ( item.created_on && item.created_on._seconds ? item.created_on._seconds*1000 : ( item.created_on || null ) ),
            modified_on = ( item.modified_on && item.modified_on._seconds ? item.modified_on._seconds*1000 : ( item.modified_on || null ) );
        return (
        <div style={{ paddingTop: "10px", textAlign: 'right', color: '#999', fontSize: '1.25rem' }}>
            <div>{ created_on ? 'Created on ' + getMomentTime( created_on , 'YYYY-MM-DD hh:mm:ssa' ) : ''}</div>
            <div>{ modified_on ? 'Last Modified on ' + getMomentTime( modified_on , 'YYYY-MM-DD hh:mm:ssa' ) : ''}</div>
        </div>
        )
    }

    render = () => {
        const { classes, venue, disableSystemDate, massEditMode, type } = this.props;
        return (
        <FormBox>
            <Grid container spacing={3}>
                
                <Grid item xs={12}>{this.renderField('region')}</Grid>
                <Grid item xs={8}><div style={{ paddingTop: '8px' }}>
                    {this.renderField('id')}
                    { type && type === 'new' ? <InfoBox inline={true}><i className="fa fa-lightbulb-o"></i>Max 8 alphanumeric, no spacing</InfoBox> : null }
                </div></Grid>
                <Grid item xs={4}>{this.renderField('publish')}</Grid>

                <Grid item xs={12}>{this.renderField('name')}</Grid>
                <Grid item xs={12}>{this.renderField('address')}</Grid>
                <Grid item xs={12}>{this.renderField('address2')}</Grid>
                <Grid item xs={6}>{this.renderField('city')}</Grid>
                <Grid item xs={6}>{this.renderField('postcode')}</Grid>
                <Grid item xs={6}>{this.renderField('state')}</Grid>
                <Grid item xs={6}>{this.renderField('country')}</Grid>

                <Grid item xs={12}>
                    {this.renderField('map_link')}
                </Grid>
                <Grid item xs={12}>
                    {this.renderField('guide_to_venue')}
                    {/* <InfoBox inline={true}><i className="fa fa-cloud-download"></i>Download The Template <a href="https://docs.google.com/document/d/1OwoEjXPkqh02gsiWq1eVwqbrY2Rd2lEsnnQFdaqTW2M/edit#" target="_blank">Here</a></InfoBox>
                    <InfoBox inline={true} style={{ marginLeft: '8px' }}><i className="fa fa-lightbulb-o"></i>Don't forget to enable public sharing of the document</InfoBox> */}
                </Grid>

            </Grid>

            { disableSystemDate ? null : this.renderCreatedModifiedDate(venue)}

        </FormBox>
        );
    }

}

export default compose(
    withStyles(useStyles),
    withRouter
)(LocationForm);