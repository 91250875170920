/* eslint-disable */
import React from "react";
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import shortid from "shortid";
import styled from "styled-components";
import RGL, { WidthProvider } from "react-grid-layout";
import WebFont from "webfontloader";
import QRCode from "qrcode";
import Typography from "@material-ui/core/Typography";
import _map from "lodash/map";
import _size from "lodash/size";
import _isEmpty from "lodash/isEmpty";
import _sortBy from "lodash/sortBy";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _remove from "lodash/remove";
import _forEach from "lodash/forEach";
import _split from "lodash/split";
import _replace from "lodash/replace";

// import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists, isObjectExists } from "../../helpers/validation";
import { cloneCollections, replaceAll } from "../../helpers/data";
import { getMomentTime } from "../../helpers/date";

import { badgeSchema } from "../../schemas/badge";

import { PLATFORM_LEGACY_BADGE_TYPES, PLATFORM_BADGE_URL, PLATFORM_CERT_URL } from "../../constants";

const ReactGridLayout = WidthProvider(RGL);

const useStyles = (theme) => ({
    boxheading: {
        fontSize: "20px",
        fontWeight: "700",
        borderBottom: "2px solid #212121",
        paddingBottom: "20px",
        marginBottom: "20px",
    },
    itemheading: {
        fontWeight: "700",
        paddingBottom: "5px",
    },
});

const Wrapper = styled.div`
    display: block;
    padding: 30px;

    @media print {
        min-height: 0;
        padding: 5px;
    }
`;

const CertificateWrapper = styled.div`
    display: block;
    position: relative;
    width: 842px;
    margin: 0px auto;
    background: #fff;

    .certificate-grid {
        min-height: 595px;
        z-index: 2;

        .react-grid-item img {
            width: 100%;
            height: auto;
        }
    }

    .certificate-content-bgimage {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
`;

const InstructionsWrapper = styled.div`
    margin: 45px auto 0;
    max-width: 650px;

    h3 {
        font-size: 1.75em;
        font-weight: 300;
        border-bottom: 1px solid #212121;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    ol {
        display: block;
        list-style-type: decimal;
        margin: 0;
        padding: 0;

        li {
            display: block;
            margin: 0 0 7px 3px;
            list-style-type: decimal;

            &:before {
                font-family: FontAwesome;
                font-weight: 400;
                font-style: normal;
                text-decoration: inherit;
                -webkit-font-smoothing: antialiased;
                content: "\f105";
                margin-right: 15px;
            }

            strong {
                font-weight: 700;
            }
        }
    }

    @media print {
        display: none;
    }
`;

class CertificateDetails extends React.Component {
    state = {
        defaultQRCode: "",
    };

    componentDidMount() {
        this.getDefaultQRCode();
    }

    getInlineStyle(item, defaultFontSize) {
        const { fonts } = this.props;
        let style = { fontSize: ((item.value && item.value.size) || defaultFontSize) + "px", textAlign: (item.value && item.value.align) || "left" };

        if (fonts && isArrayExists(fonts) && item.value && item.value.font && !_isEmpty(item.value.font)) {
            let selected = _find(fonts, { family: item.value.font });
            if (selected) {
                style["fontFamily"] = selected.family;
            }
        } // end - fonts

        return style;
    }

    getDefaultQRCode() {
        const { cert_id } = this.props;
        QRCode.toString(
            PLATFORM_BADGE_URL + (cert_id || ""),
            { width: 16 },
            function (err, string) {
                if (!err) this.setState({ defaultQRCode: string });
            }.bind(this)
        );
    }

    getCertificate = () => {
        const { certificate } = this.props;
        let data = certificate && isObjectExists(certificate) ? cloneCollections(certificate) : {};

        // retrieve template items
        if (certificate && certificate["template"] && isArrayExists(certificate["template"])) {
            data.template = [];
            data.template_layout = [];

            _forEach(certificate["template"], (item) => {
                data.template.push({
                    id: item.id,
                    type: item.type || "",
                    path: item.path || "",
                    value: item.value || "",
                });

                if (item.type && item.type != "background_image") {
                    data.template_layout.push({
                        i: item.id,
                        x: item.x ? parseInt(item.x) : 0,
                        y: item.y ? parseInt(item.y) : 0,
                        w: item.w ? parseInt(item.w) : 0,
                        h: item.h ? parseInt(item.h) : 0,
                        minW: item.minW ? parseInt(item.minW) : 0,
                        minH: item.minH ? parseInt(item.minH) : 0,
                    });
                } // end - item.type
            });
        } // end - certificate

        return data;
    };

    filterContent(content) {
        const { badge } = this.props;

        if (content && !_isEmpty(content) && badge) {
            // find course title
            if (content.indexOf("__course_title__") >= 0) {
                content = replaceAll(content, "__course_title__", badge.name || "");
            }

            // find course date
            if (content.indexOf("__course_date__") >= 0) {
                if (badge.date_of_course && badge.date_of_course !== "") content = replaceAll(content, "__course_date__", badge.date_of_course || "");
                else if (badge.created_on && badge.created_on._seconds)
                    content = replaceAll(content, "__course_date__", getMomentTime(badge.created_on._seconds * 1000, "DD MMM YYYY"));
                else content = replaceAll(content, "__course_date__", "");
            }

            // find trainee's name
            if (content.indexOf("__cert_url__") >= 0) {
                content = replaceAll(content, "__cert_url__", PLATFORM_CERT_URL + badge.id);
            }

            if (content.indexOf("__badge_url__") >= 0) {
                content = replaceAll(content, "__badge_url__", PLATFORM_BADGE_URL + badge.id);
            }

            if (content.indexOf("__program_title__") >= 0) {
                content = replaceAll(content, "__program_title__", badge.program_title || "");
            }

            // find badge type
            if (content.indexOf("__badge_type__") >= 0) {
                content = replaceAll(content, "__badge_type__", badge.type_label || "");
            }

            // find course title
            if (content.indexOf("__trainee_name__") >= 0) {
                content = replaceAll(content, "__trainee_name__", badge.user_name || "");
            }

            // find vanue name
            if (content.indexOf("__venue_name__") >= 0) {
                content = replaceAll(content, "__venue_name__", badge.venue_name || "");
            }
        } // end - content

        return content;
    }

    getBackgroundImage() {
        const { certificate } = this.props;
        let image = false;
        if (certificate && certificate.template && isArrayExists(certificate.template)) {
            let block = _find(certificate.template, { type: "background_image" });
            if (block && block.value && !_isEmpty(block.value)) {
                image = block.value;
            }
        } // end - certificate.template
        return image;
    }

    loadAllRequiredFonts() {
        const { certificate, fonts } = this.props;
        var loaded = [];
        if (fonts && isArrayExists(fonts) && certificate && certificate.template && isArrayExists(certificate.template)) {
            _forEach(certificate.template, (item) => {
                if (item.type && (item.type == "h1" || item.type == "h2" || item.type == "h3" || item.type == "h4" || item.type == "paragraph")) {
                    if (item.value && item.value.font && !_isEmpty(item.value.font)) {
                        var selected = _find(fonts, { family: item.value.font });
                        if (selected) {
                            var already_loaded = isArrayExists(loaded) ? _find(loaded, { id: selected.family }) : false;
                            if (!already_loaded) {
                                var families = [],
                                    font_label = selected.family;

                                // get variants
                                if (selected.variants && isArrayExists(selected.variants)) {
                                    var count = 1;
                                    _forEach(selected.variants, (variant) => {
                                        font_label += (count > 1 ? "," : ":") + variant;
                                        count++;
                                    });
                                } // end - selected.variants

                                families.push(font_label);
                                families.push(selected.category);
                                WebFont.load({
                                    google: { families },
                                });
                                loaded.push({ id: selected.family });
                            }
                        } // end - selected
                    } // end - item.value.font
                } // end - item.type
            });
        } // end - fonts
    }

    renderTemplateLayout = (item) => {
        switch (item.type) {
            case "image":
                return <img src={item.value} />;
            case "h1":
                return (
                    <h1 style={this.getInlineStyle(item, 36)}>{item.value && item.value.content ? this.filterContent(item.value.content) : ""}</h1>
                );
            case "h2":
                return (
                    <h2 style={this.getInlineStyle(item, 28)}>{item.value && item.value.content ? this.filterContent(item.value.content) : ""}</h2>
                );
            case "h3":
                return (
                    <h3 style={this.getInlineStyle(item, 22)}>{item.value && item.value.content ? this.filterContent(item.value.content) : ""}</h3>
                );
            case "h4":
                return (
                    <h4 style={this.getInlineStyle(item, 18)}>{item.value && item.value.content ? this.filterContent(item.value.content) : ""}</h4>
                );
            case "paragraph":
                var splitData =
                        item.value && item.value.content && !_isEmpty(item.value.content) ? _split(item.value.content, /(?:\r\n|\r|\n)/g) : false,
                    count = 0;
                return (
                    <div className="certificate-template-paragraph">
                        {splitData && isArrayExists(splitData)
                            ? _map(splitData, (line) => {
                                  count++;
                                  return (
                                      <p key={count} style={this.getInlineStyle(item, 16)}>
                                          {line ? this.filterContent(line) : ""}
                                      </p>
                                  );
                              })
                            : ""}
                    </div>
                );
            case "qrcode":
                return (
                    <div className="certificate-template-qrcode">
                        <div dangerouslySetInnerHTML={{ __html: this.state.defaultQRCode }} />
                    </div>
                );
        }
    };

    renderTemplateItem = (item) => {
        if (item.type && item.type == "background_image") {
            return <div key={item.id} style={{ display: "none" }}></div>;
        } else {
            return <div key={item.id}>{item.type && !_isEmpty(item.type) ? this.renderTemplateLayout(item) : ""}</div>;
        } // end - item
    };

    renderCertificate = () => {
        const certificate = this.getCertificate();
        let bg_image = this.getBackgroundImage();
        this.loadAllRequiredFonts();
        return (
            <CertificateWrapper>
                <ReactGridLayout
                    className={"certificate-grid"}
                    layout={certificate && certificate.template_layout ? certificate.template_layout : []}
                    width={842}
                    rowHeight={22}
                    cols={60}
                    items={20}
                    margin={[0, 0]}
                    isDraggable={false}
                    isResizable={false}
                    compactType={null}
                    preventCollision={true}
                >
                    {certificate && certificate.template && isArrayExists(certificate.template)
                        ? _map(certificate.template, this.renderTemplateItem)
                        : null}
                </ReactGridLayout>
                {bg_image ? <img src={bg_image} className="certificate-content-bgimage" /> : ""}
            </CertificateWrapper>
        );
    };

    renderInstructions() {
        return (
            <InstructionsWrapper>
                <h3>How to Print Certificate:</h3>
                <ol>
                    <li>
                        To print this certificate, use the <strong>"Print"</strong> function in your browser.
                    </li>
                    <li>
                        Set the <strong>Layout</strong> to <strong>Landscape</strong>.
                    </li>
                    <li>
                        Remove all the <strong>Headers and Footers</strong>.
                    </li>
                    <li>Set the Paper size and Margins accordingly.</li>
                    <li>
                        <strong>Adjust the Scale</strong> to fit the certificate nicely to the paper.
                    </li>
                    <li>
                        Example:
                        <br />
                        <div style={{ paddingLeft: "30px" }}>
                            Paper size: A4
                            <br />
                            Margins: None
                            <br />
                            Scale: 130
                        </div>
                    </li>
                </ol>
            </InstructionsWrapper>
        );
    }

    render = () => {
        const { previewMode, noInstruction = false } = this.props;
        return (
            <Wrapper>
                {this.renderCertificate()}
                {previewMode || noInstruction ? null : this.renderInstructions()}
            </Wrapper>
        );
    };
}

export default compose(withStyles(useStyles), withRouter)(CertificateDetails);
