/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _isEqual from 'lodash/isEqual';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';
import _split from 'lodash/split';
import _toUpper from 'lodash/toUpper';

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader'
import SnackBarSave from '../../components/SnackBarSave';

import EditForm from './form';
import ItemNotFound from '../../components/ItemNotFound';

import { InfoButton, GreyButton } from '../../styles/button';

import { hasAccessRights } from '../../helpers/auth'; 
import { isArrayExists, isObjectExists, isNumeric } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { getDateNumFromString, isParentFieldRequired } from '../../helpers/date';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { getStudentShowcaseDates, updateStudentShowcaseDate } from '../../actions/student_showcase_dates';
import { appChangesMade, resetRedux } from '../../actions/misc';


class StudentShowcaseDate extends React.Component {

    state = {
        currentTab: 'details',
        showcase: false,
        randNum: false
    };

    componentDidMount() {
        const { sis_section_id } = this.props.match.params;
        const { authData } = this.props;
        
        // retrieve feedback details
        this.props.dispatch(getStudentShowcaseDates( this.getScheduleID(sis_section_id), this.getLMSCourseID(sis_section_id) ));

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentShowcase, randNum
            } = this.props;

        // for feedback form
        if ( currentShowcase && randNum && randNum !== this.state.randNum ) {
            this.setState({ showcase: cloneCollections( currentShowcase ), randNum });
        } // end - currentShowcase
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('student_showcase'));
    }

    handleSaveChanges = (event) => {
        const { sis_section_id } = this.props.match.params;
        const { showcase } = this.state;
        event.preventDefault();
        var error = false,
            formData = {
                schedule_id: this.getScheduleID(sis_section_id),
                lms_course_id: this.getLMSCourseID(sis_section_id),
                booking_date: ( showcase && showcase.trainee && showcase.trainee.booking_date ? showcase.trainee.booking_date : 0 ),
                showcase_date: ( showcase && showcase.trainee && showcase.trainee.showcase_date ? showcase.trainee.showcase_date : 0 ),
                calendar_id: ( showcase && showcase.trainee && showcase.trainee.calendar_id ? showcase.trainee.calendar_id : '' )
            };

        // do error check
        if ( !( formData.showcase_date ) )
            error = 'Please select a showcase date';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateStudentShowcaseDate(formData));
        } // end - error

    }

    handleFormUpdate = (newTrainee) => {
        const { showcase } = this.state;
        let newData = ( showcase && !_isEmpty( showcase ) ? cloneCollections( showcase ) : { trainee: {} } );
        newData.trainee.booking_date = ( newTrainee && newTrainee.booking_date ? newTrainee.booking_date : 0 );
        newData.trainee.showcase_date = ( newTrainee && newTrainee.showcase_date ? newTrainee.showcase_date : 0 );
        newData.trainee.calendar_id = ( newTrainee && newTrainee.calendar_id ? newTrainee.calendar_id : '' );
        this.setState({ showcase: newData });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    isMentorView = () => {
        const { location = {} } = this.props;
        return ( location && location.pathname && location.pathname.indexOf("/mentor/class/") >= 0 ? true : false );
    }

    splitSISSectionID = (sis_section_id) => {
        return ( sis_section_id && !_isEmpty( sis_section_id ) ? _split( sis_section_id, '-c', 2 ) : false );
    }

    getScheduleID = (sis_section_id) => {
        let data = this.splitSISSectionID(sis_section_id);
        return ( data && data[0] && !_isEmpty( data[0] ) ? _toUpper( data[0] ) : false );
    }

    getLMSCourseID = (sis_section_id) => {
        let data = this.splitSISSectionID(sis_section_id);
        return ( data && data[1] && !_isEmpty( data[1] ) && isNumeric( data[1] ) ? data[1] : false );
    }

    renderTabContents = () => {
        const { sis_section_id } = this.props.match.params;
        const { authData, currentShowcase, changesMade } = this.props;
        const { currentTab, showcase } = this.state;
        const tabProps = {
            authData: ( authData || false ),
            showcase: ( showcase || false ),
            changesMade: ( changesMade || false ),
            onFormUpdate: this.handleFormUpdate
        };
        switch( currentTab ) {
            case 'details':
                return <EditForm 
                    sis_section_id={( sis_section_id || false )}
                    schedule_id={this.getScheduleID(sis_section_id)}
                    lms_course_id={this.getLMSCourseID(sis_section_id)}
                    currentShowcase={( currentShowcase || false )}
                    {...tabProps} />;
        }
    }

    renderContents() {
        const { sis_section_id, mentor_view } = this.props.match.params;
        const { showcase } = this.state;
        return (
        <div>

            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                {this.renderTabContents()}
            </Paper>

            { ( showcase && showcase.trainee && showcase.trainee.showcase && showcase.trainee.showcase.badge_id && !_isEmpty( showcase.trainee.showcase.badge_id ) ) || this.isMentorView()
             ? ( this.isMentorView() ? (
                <>
                    <div style={{ textAlign: "center", marginTop: "45px" }}>
                        <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" disabled="yes" onClick={() => { /* do nothing */ }}><i className="fa fa-save"></i>Submit Form</InfoButton>
                    </div>
                </>
                ) : null ) : (
            <>
                <div style={{ textAlign: "center", marginTop: "45px" }}>
                    <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Submit</InfoButton>
                </div>
                <SnackBarSave saveNowLabel="Submit" onSave={this.handleSaveChanges} />
            </>
            )}

        </div>
        );
    }

    render() {
        const { randNum } = this.state;
        const { currentShowcase } = this.props;
        return <AppWrapper 
                title="Book Your Open Day"
                maxWidth="1110px"
                hideTopBar={true}
                topBarHeight="0px"
                onLoad={( !( randNum ) ? true : false )}
                contents={ currentShowcase && currentShowcase.class_calendar && isArrayExists( currentShowcase.class_calendar ) ? this.renderContents() : <ItemNotFound item="Class" />} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        currentShowcase: state.student_showcase && state.student_showcase.showcase || null,
        randNum: state.student_showcase && state.student_showcase.rand || null,
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(StudentShowcaseDate);