/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_PARTNERS,
	// GET_PARTNERS_OPTIONS,
	GET_PARTNER,
	ADD_PARTNER,
	EDIT_PARTNER,
	DELETE_PARTNER,
	GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getPartners, fb_getPartner, fb_addPartner, fb_updatePartner, fb_deletePartner } from './fb';


export const getPartners = (props) => {
	return dispatch => {

		fb_getPartners(list => {
			dispatch({
				type: GET_PARTNERS,
				payload: { list }
			});	
		},props);

	}
}

export const getPartner = (id,props) => {
	return dispatch => {

		fb_getPartner(id,props)
		.then(partner => {
			dispatch({
				type: GET_PARTNER,
				payload: { partner }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_PARTNER,
				payload: { partner: {} }
			});
		})

	}
}

export const addPartner = (formData,history,props) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addPartner(formData)
		.then( results => {
			if ( results && results.new_partner_id && !_isEmpty( results.new_partner_id ) ) {
				fb_getPartner( results.new_partner_id, props)
				.then( partner => {
					// updated
					dispatch({ type: ADD_PARTNER });
					dispatch(toggleModalProcessing(false));
					if ( formData && formData.invitation && formData.invitation === 'yes' ) {
						triggerSuccessAlert("<strong>Partner Added</strong><br/>An email has been sent to instruct new user of the next steps to create an account. Notify the user to check his/her inbox.",5000);
					} else {
						triggerSuccessAlert("Partner Added");
					}
					// redirect to partner page
					history.push("/partner/" + results.new_partner_id);
				});
			} else {
				dispatch(toggleModalProcessing(false,apiNum));
				triggerErrorAlert('Missing new partner ID');
			}
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const editPartner = (formData,props) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updatePartner(formData)
		.then( results => {
			fb_getPartner(formData.id,props)
			.then(partner => {
				// push new data
				dispatch({ type: GET_PARTNER, payload: { partner } });	
				// updated
				dispatch({ type: EDIT_PARTNER });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Partner Updated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const deletePartner = (id,region) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deletePartner(id,region)
		.then( results => {
			dispatch({ type: DELETE_PARTNER });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Partner Deleted!");
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}