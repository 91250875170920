/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _remove from "lodash/remove";
import _isEqual from "lodash/isEqual";
import _startsWith from "lodash/startsWith";
import _replace from "lodash/replace";

import AppWrapper from "../../components/AppWrapper";
import DotsLoader from "../../components/DotsLoader";
import SnackBarSave from "../../components/SnackBarSave";

import EditForm from "./form";
import ItemNotFound from "../../components/ItemNotFound";

import { InfoButton, GreyButton } from "../../styles/button";

import { hasAccessRights, getUserRegionFilter } from "../../helpers/auth";
import { isArrayExists, isObjectExists } from "../../helpers/validation";
import { cloneCollections } from "../../helpers/data";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import { doSchemaErrorCheck } from "../../helpers/schemas";

import {
    getStudentData,
    updateStudentData,
    triggerStudentRegionTransfer,
    triggerStudentAccountTransfer,
    triggerStudentPasswordChange,
    triggerStudentBackpackTransfer,
} from "../../actions/database_students";
import { getMaintenance } from "../../actions/maintenance";
import { getLMSCourses } from "../../actions/canvas_lms";
import { getSchedules } from "../../actions/schedules";
import { getRegions } from "../../actions/m_regions";
import { getUsersOptions } from "../../actions/users";
import { getRoles } from "../../actions/m_roles";
import { appChangesMade, resetRedux } from "../../actions/misc";

import { userStudentSchema } from "../../schemas/user_student";

class DatabaseStudentPage extends React.Component {
    state = {
        currentTab: "details",
        student: false,
        randNum: false,
    };

    componentDidMount() {
        const { authData, designationList, regionsList, lmsCoursesList, schedulesList, usersOptionsList } = this.props;
        const { uid } = this.props.match.params;

        // retrieve student details
        this.props.dispatch(getStudentData(uid));

        // get regions
        if (!regionsList) {
            this.props.dispatch(getRegions());
        }

        // get schedule options
        if (!schedulesList) this.props.dispatch(getSchedules({ region: getUserRegionFilter(authData) }));

        // get lms courses
        if (!lmsCoursesList) this.props.dispatch(getLMSCourses());

        // get users options
        if (!usersOptionsList) this.props.dispatch(getUsersOptions({ region: getUserRegionFilter(authData) }));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentStudent, randNum, regionsList, lmsCoursesList, schedulesList, usersOptionsList } = this.props;

        // for student
        if (currentStudent && regionsList && lmsCoursesList && schedulesList && usersOptionsList && randNum && randNum !== this.state.randNum) {
            this.setState({
                student: cloneCollections(currentStudent),
                randNum,
            });
        } // end - currentStudent
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux("database_students"));
    }

    handleRefresh = (type) => {
        // const { uid } = this.props.match.params;
        // switch( type ) {
        //     case 'emails':
        //         this.setState({ emails: false });
        //         this.props.dispatch(getEmailsBy('student_database',uid));
        //         break;
        // }
    };

    handleBackpackTransfer = (formData) => {
        this.props.dispatch(triggerStudentBackpackTransfer(formData));
    };

    handleRegionTransfer = (formData) => {
        this.props.dispatch(triggerStudentRegionTransfer(formData));
    };

    handleAccountTransfer = (formData) => {
        this.props.dispatch(triggerStudentAccountTransfer(formData));
    };

    handleLMSPasswordChange = (formData) => {
        this.props.dispatch(triggerStudentPasswordChange(formData));
    };

    handleSaveChanges = (event) => {
        const { student } = this.state;
        event.preventDefault();
        var error = false,
            formData = {};

        // do error check
        userStudentSchema.forEach((schema) => {
            formData[schema.id] = student && student[schema.id] ? cloneCollections(student[schema.id]) : schema.default;
            if (!doSchemaErrorCheck(formData[schema.id], schema, "update")) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if (error) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateStudentData(formData));
        } // end - error
    };

    handleFormUpdate = (newValue) => {
        this.setState({ student: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    };

    handleTabChange = (newTab) => {
        this.setState({ currentTab: newTab });
    };

    // getLinks = () => {
    //     const { authData } = this.props;
    //     let links = [
    //         { id: 'details', label: 'Partner Info', icon: 'fa fa-cog' },
    //         { id: 'personal', label: 'Personal Database', icon: 'fa fa-users' }
    //     ];

    //     return links;
    // }

    getPassingState = () => {
        const { location } = this.props;
        return location && location.state && isObjectExists(location.state) ? location.state : null;
    };

    renderTabContents = () => {
        const { uid } = this.props.match.params;
        const { authData, currentStudent, designationList, regionsList, lmsCoursesList, schedulesList, usersOptionsList, changesMade } = this.props;
        const { currentTab, student } = this.state;
        const tabProps = {
            authData: authData || false,
            student: student || false,
            changesMade: changesMade || false,
            onFormUpdate: this.handleFormUpdate,
        };
        switch (currentTab) {
            case "details":
                return (
                    <EditForm
                        formType="student"
                        currentStudent={currentStudent || false}
                        designations={designationList || false}
                        regions={regionsList || false}
                        lms_courses={lmsCoursesList || false}
                        schedules={schedulesList}
                        usersOptions={usersOptionsList || false}
                        onRegionTransfer={this.handleRegionTransfer}
                        onAccountTransfer={this.handleAccountTransfer}
                        onLMSPasswordChange={this.handleLMSPasswordChange}
                        onBackpackTransfer={this.handleBackpackTransfer}
                        {...tabProps}
                    />
                );
            // case 'emails':
            //     return ( emails ? <EmailsList
            //         type="student_database"
            //         typeID={( uid || false )}
            //         changesMade={changesMade}
            //         onRefresh={this.handleRefresh}
            //         {...tabProps} /> : <DotsLoader /> );
        }
    };

    // renderTabs = () => {
    //     const { currentTab } = this.state;
    //     const btnStyle = {
    //         textAlign: 'center',
    //         minWidth: '225px',
    //         padding: "10px 5px",
    //         marginRight: "5px"
    //     };
    //     return (
    //     <Box display="flex" justifyContent="center" alignItems="center">
    //         {this.getLinks().map((button,index) => {
    //             if ( button.id === currentTab) {
    //                 return <InfoButton key={index} style={btnStyle} onClick={event => event.preventDefault()}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</InfoButton>
    //             } else {
    //                 return <GreyButton key={index} style={btnStyle} onClick={this.handleTabChange.bind(this,button.id)}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</GreyButton>
    //             }
    //         })}
    //     </Box>
    //     )
    // }

    renderContents() {
        return (
            <div>
                {/* {this.renderTabs()} */}

                <Paper
                    elevation={3}
                    style={{
                        padding: "45px 30px",
                        background: "#fff",
                        marginTop: "15px",
                    }}
                >
                    {this.renderTabContents()}
                </Paper>

                <div style={{ textAlign: "center", marginTop: "45px" }}>
                    <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}>
                        <i className="fa fa-save"></i>Save Changes
                    </InfoButton>
                </div>

                <SnackBarSave onSave={this.handleSaveChanges} />
            </div>
        );
    }

    renderDifferentRegion() {
        const { regionsList, authData } = this.props;
        const { currentStudent } = this.props;
        const region = regionsList && isArrayExists(regionsList) && regionsList.find((region) => region.id === currentStudent.region);
        const currentRegion = regionsList && isArrayExists(regionsList) ? regionsList.find((region) => region.id === authData.region) : null;
        return (
            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                <Typography variant="h4" align="center">
                    You don't have access to this student's information.
                </Typography>
                <div style={{ maxWidth: "500px", margin: "0 auto", marginTop: "45px" }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ fontSize: "16px", fontWeight: "700" }}>Partner</TableCell>
                                <TableCell style={{ fontSize: "16px" }}>{region && region.label}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontSize: "16px", fontWeight: "700" }}>Student Name</TableCell>
                                <TableCell style={{ fontSize: "16px" }}>{currentStudent.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontSize: "16px", fontWeight: "700" }}>Student Email</TableCell>
                                <TableCell style={{ fontSize: "16px" }}>{currentStudent.email}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <div style={{ textAlign: "center" }}>
                        <InfoButton
                            style={{ padding: "10px 25px", marginTop: "45px" }}
                            onClick={() => {
                                axios({
                                    method: "post",
                                    url: `https://discord.com/api/webhooks/1124274880624459877/EqEdavk6agVuuq7zSmaiq9H66Ee8Hy8Rl3OrF10vIA4sEnFI6Z7gJ3r0JCoS3kV33LtP`,
                                    data: {
                                        content: `Request to change partner for ${currentStudent.email || ""}\n\n
                                    Requested by ${(currentRegion && currentRegion.label) || ""}.\n
                                    Current partner is ${region.label || ""}.\n
                                    Student Name: ${currentStudent.name || ""}\n
                                    Student UID: ${currentStudent._uid || ""}`,
                                    },
                                })
                                    .then((response) => {
                                        triggerSuccessAlert("Request Sent!");
                                    })
                                    .catch((error) => {
                                        triggerErrorAlert(error);
                                    });
                            }}
                        >
                            Request to Change Partner
                        </InfoButton>
                    </div>
                </div>
            </Paper>
        );
    }

    render() {
        const { uid } = this.props.match.params;
        const { randNum } = this.state;
        const { currentStudent } = this.props;
        return (
            <AppWrapper
                title="Edit Student"
                subtitle="3. Delivery"
                back={"/student_database"}
                breadcrumbs={[{ url: "/student_database", label: "Student Database" }, { label: "Edit" }]}
                passingState={this.getPassingState()}
                maxWidth="1110px"
                onLoad={!randNum ? true : false}
                contents={
                    currentStudent && currentStudent.uid && !_isEmpty(currentStudent.uid) ? (
                        this.renderContents()
                    ) : currentStudent && currentStudent.region && !_isEmpty(currentStudent.region) ? (
                        this.renderDifferentRegion()
                    ) : (
                        <ItemNotFound item="Student" />
                    )
                }
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: (state.auth && state.auth.user) || null,
        currentStudent: (state.database_students && state.database_students.student) || null,
        randNum: (state.database_students && state.database_students.rand) || null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        lmsCoursesList: state.canvas_lms && state.canvas_lms.courses ? state.canvas_lms.courses : null,
        schedulesList: state.schedules && state.schedules.schedules ? state.schedules.schedules : false,
        // designationList: state.maintenance && state.maintenance.designations || null,
        // rolesList: state.maintenance && state.maintenance.roles ? state.maintenance.roles : false,
        usersOptionsList: state.users && state.users.users_options ? state.users.users_options : false,
        changesMade: (state.misc && state.misc.changes_made) || null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(DatabaseStudentPage);
