export const paymentSchema = [
    { 
        id: 'id', 
        label: 'ID',
        required: ['update','delete'], 
        default: '', 
        type: 'string', 
        validation: 'string_id', 
        skip: ['add']
    },
    { 
        id: 'trainee_id', 
        label: 'Trainee ID',
        required: false, 
        default: '', 
        type: 'system_data', 
        disabled: ['update']
    },
    { 
        id: 'schedule_id', 
        label: 'Schedule ID',
        required: false, 
        default: '', 
        type: 'system_data', 
        disabled: ['update']
    },
    {   
        id: 'name',
        label: 'Name',
        required: ['add'],
        default: '',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'email',
        label: 'Email',
        required: ['add'],
        default: '',
        type: 'email',
        field: 'email'
    },
    {   
        id: 'currency',
        label: 'Currency',
        required: ['add'],
        default: 'MYR',
        type: 'string',
        field: 'select'
    },
    {   
        id: 'amount',
        label: 'Amount',
        required: ['add'],
        default: 0,
        type: 'currency',
        field: 'text_number'
    },
    {   
        id: 'phone',
        label: 'Mobile No',
        required: ['add'],
        default: '',
        type: 'string',
        field: 'phone'
    },
    {   
        id: 'remarks',
        label: 'Remarks',
        required: false,
        default: '',
        type: 'string',
        field: 'textarea'
    },
    {   
        id: 'created_on',
        label: 'Created On',
        required: false,
        default: 0,
        type: 'system_date',
        skip: ['update']
    },
    {   
        id: 'modified_on',
        label: 'Modified On',
        required: false,
        default: 0,
        type: 'system_date'
    }
];