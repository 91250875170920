/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _isEqual from 'lodash/isEqual';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader'
import SnackBarSave from '../../components/SnackBarSave';

import EditForm from './form';
import ItemNotFound from '../../components/ItemNotFound';

import { InfoButton, GreyButton } from '../../styles/button';

import { hasAccessRights } from '../../helpers/auth'; 
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { getRegistrationForm, editRegistrationForm } from '../../actions/m_registration_forms';
import { getMaintenance } from '../../actions/maintenance';
import { appChangesMade, resetRedux } from '../../actions/misc';

class MaintenanceRegistrationForm extends React.Component {

    state = {
        currentTab: 'details',
        registration_form: false,
        randNum: false
    };

    componentDidMount() {
        const { authData, racesList, districtsList } = this.props;
        const { registration_form_id } = this.props.match.params;
        
        // retrieve form details
        this.props.dispatch(getRegistrationForm(registration_form_id));

        if ( !racesList )
            this.props.dispatch(getMaintenance('races'));

        if ( !districtsList )
            this.props.dispatch(getMaintenance('districts'));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentRegistrationForm, racesList, districtsList, randNum } = this.props;

        // for registration_form
        if ( currentRegistrationForm && racesList && districtsList && randNum && randNum !== this.state.randNum ) {
            this.setState({ registration_form: cloneCollections( currentRegistrationForm ), randNum });
        } // end - currentRegistrationForm
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('registration_form'));
    }

    handleRefresh = (type) => {
        // const { registration_form_id } = this.props.match.params;
        // switch( type ) {
        //     case 'emails':
        //         this.setState({ emails: false });
        //         this.props.dispatch(getEmailsBy('partners',registration_form_id));
        //         break;
        // }
    }

    handleSaveChanges = (event) => {
        const { registration_form } = this.state;
        event.preventDefault();
        var error = false,
            formData = ( registration_form && isObjectExists( registration_form ) ? cloneCollections( registration_form ) : {} );

        // do error check
        if ( !( formData && formData.status && !_isEmpty( formData.status ) ) )
            error = 'Please select a valid status';

        if ( !( formData && formData.label && !_isEmpty( formData.label ) ) )
            error = 'Please insert a valid label';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editRegistrationForm(formData));
        } // end - error

    }

    handleFormUpdate = (newValue) => {
        this.setState({ registration_form: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    renderTabContents = () => {
        const { registration_form_id } = this.props.match.params;
        const { authData, currentRegistrationForm, districtsList, racesList, changesMade } = this.props;
        const { currentTab, registration_form } = this.state;
        const tabProps = {
            authData: ( authData || false ),
            registration_form: ( registration_form || false ),
            changesMade: ( changesMade || false ),
            onFormUpdate: this.handleFormUpdate
        };
        switch( currentTab ) {
            case 'details':
                return <EditForm 
                    currentRegistrationForm={( currentRegistrationForm || false )}
                    districts={districtsList}
                    races={racesList}
                    {...tabProps} />;
        }
    }

    renderContents() {
        return (
        <div>

            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                {this.renderTabContents()}
            </Paper>

            <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
            </div>

            <SnackBarSave onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { registration_form_id } = this.props.match.params;
        const { randNum } = this.state;
        const { currentRegistrationForm } = this.props;
        return <AppWrapper 
                title="Edit Registration Form"
                subtitle="Registration Forms"
                back="/maintenance/registration_forms"
                breadcrumbs={[
                    { url: '/maintenance/registration_forms', label: 'Registration Forms' },
                    { label: 'Edit' }
                ]}
                maxWidth="1110px"
                onLoad={( !( randNum ) ? true : false )}
                contents={ currentRegistrationForm && currentRegistrationForm.id && !_isEmpty( currentRegistrationForm.id ) ? this.renderContents() : <ItemNotFound item="Registration Form" />} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        currentRegistrationForm: state.maintenance && state.maintenance.registration_form || null,
        randNum: state.maintenance && state.maintenance.rand_registration_form || null,
        racesList: state.maintenance && state.maintenance.races ? state.maintenance.races : null,
        districtsList: state.maintenance && state.maintenance.districts ? state.maintenance.districts : null,
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(MaintenanceRegistrationForm);