/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import axios from 'axios';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';

import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';
import FormDatePicker from '../../components/FormDatePicker';
import FormCheckbox from '../../components/FormCheckbox';
import ToolTipInfo from '../../components/ToolTipInfo';

import { isArrayExists, isObjectExists, validateEmail } from '../../helpers/validation';
import { cloneCollections, getSelectOptions } from '../../helpers/data';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
// import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';

import { toggleLoader } from '../../actions/global';

import { InfoButton } from '../../styles/button';

import { ENROLLMENT_STATUS_OPTIONS } from '../../constants';

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
    border: 2px solid #212121;
`;

class StudentAccountTransferPartner extends React.Component {

    utcOffSet = 8;
    
    state = {
        open: false,
        modalData: false,
        confirmation: false
    };

    handleUpdate = () => {
        const { student, authData, regions } = this.props;
        const { modalData } = this.state;
        let error = false;

        if ( !( modalData && modalData.email && validateEmail( modalData.email ) ) )
            error = "Please insert a valid email.";

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            // testingonly@pleaseignore.com
            const region = regions && isArrayExists( regions ) ? _find( regions, { id: authData.region } ) : false;
            axios({
                method: 'post',
                url: `https://discord.com/api/webhooks/1124274880624459877/EqEdavk6agVuuq7zSmaiq9H66Ee8Hy8Rl3OrF10vIA4sEnFI6Z7gJ3r0JCoS3kV33LtP`,
                data: {
                    content: `Request to change email from ${student.email} to ${modalData.email}, requested by ${authData.name} (${region.label || ''}).
Notes: ${modalData.notes || '--'}
                    `
                }
            })
            .then((response) => {
                triggerSuccessAlert("Request Sent!");
                this.setState({ open: false, modalData: false })
            })
            .catch((error) => {
                triggerErrorAlert(error);
            });
        }
            
    }

    handleFormUpdate = (newValue,key,subkey,subVal) => {
        const { modalData } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} );
        switch( key ) {
            default:
                if ( subkey && ( subkey === 'checked' || subkey === 'unchecked' ) && subVal && !_isEmpty( subVal ) ) {
                    // use subVal as the new value
                    newData[key] = subVal;
                } else {
                    // otherwise - just update normally
                    newData[key] = newValue
                }
                break;
        } // end - key
        
        this.setState({ modalData: newData })
    }

    handleOpen = (event) => {
        const { changesMade, student } = this.props;
        event.preventDefault();
        let error = false;

        if ( changesMade ) {
            error = "Please save your changes before proceeding.";
        }

        if ( error ) {
            triggerErrorAlert(error,4000);
        } else {
            this.setState({ 
                open: true, 
                modalData: {
                    email: '',
                    notes: ''
                } 
            });
        }

    }

    renderForm = () => {
        const { modalData } = this.state;
        return (
        <div>
            <FormInput
                label="New Email *"
                value={ modalData.email || '' }
                name="email"
                type="email"
                onChange={ this.handleFormUpdate } />
            <FormInput
                label="Notes"
                value={ modalData.notes || '' }
                name="notes"
                multiline={true}
                minRows={4}
                maxRows={4}
                onChange={ this.handleFormUpdate } />
            {/* <FormInput
                label="New Password *"
                value={ modalData.password || '' }
                name="password"
                type="password"
                onChange={ this.handleFormUpdate } />
            <FormInput
                label="Confirm New Password *"
                value={ modalData.password_repeat || '' }
                name="password_repeat"
                type="password"
                onChange={ this.handleFormUpdate } />  */}
        </div>
        );
    }

    render() {
        const { student } = this.props;
        const { open, confirmation, modalData } = this.state;
        return (
        <>

            <div style={{ padding: '10px 0', textAlign: 'right' }}>
                <InfoButton disabled={( student && student.role && ( student.role === 'student' || student.role === 'mentor' ) ? null : 'yes' )} size="small" style={{ padding: '4px 6px' }} onClick={this.handleOpen}><i className="fa fa-random"></i>Change Email</InfoButton>
            </div>

            <ModalView 
                open={open}
                title={(
                <>
                    <ToolTipInfo
                        content={(
                        <>
                            ONE PERSON HAS ONE EMAIL ACCOUNT<br /><br />
                            One email address can only be associated with one Chumbaka account at a time.<br /><br />
                            You may change your email here for only a maximum of 2 times.   
                        </>
                        )} />
                    {`Change Email for ${student && student.name || ''}`}
                </>
                )}
                onClose={() => this.setState({ open: false, modalData: false })}
                disableBackdrop={true}
                actionLabel="Request"
                maxWidth="sm"
                doAction={this.handleUpdate}
                contents={this.renderForm()} />

        </>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(StudentAccountTransferPartner);