/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import Papa from 'papaparse';
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';

import ModalView from '../../components/ModalView';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';
import FormDatePicker from '../../components/FormDatePicker';
import FormCheckbox from '../../components/FormCheckbox';
import FormCheckboxes from '../../components/FormCheckboxes';
import ToolTipInfo from '../../components/ToolTipInfo';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions, getSelectedValue } from '../../helpers/data';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getMomentTime } from '../../helpers/date';
import { stringToInteger } from '../../helpers/number';
// import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';

import { toggleLoader } from '../../actions/global';

import { InfoButton } from '../../styles/button';
import { WarningBox } from '../../styles/message';

import { ENROLLMENT_STATUS_OPTIONS, DEV_MODE, PLATFORM_BACKPACK_URL } from '../../constants';

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
    border: 2px solid #212121;
`;

class StudentRegionTransfer extends React.Component {

    utcOffSet = 8;
    
    state = {
        open: false,
        modalData: false,
        confirmation: false
    };

    doCSVDownload = (results) => {
        let csv = Papa.unparse( results, { delimiter: ',' } );
        let blob = new Blob( [csv] , { type: 'text/csv;charset=utf-8;' }); //new way
        let uri = window.URL.createObjectURL(blob);
        let link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", '_blank');
        link.setAttribute("download", "student_data.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    
    handleDataDownload = () => {
        const { student, regionsList, enrollments } = this.props;
        let csvData = {
            fields: [
                "email", 
                "name",
                "gender", 
                "date_of_birth",
                "school",
                "medical_condition",
                "parent_name",
                "parent_email", 
                "parent_mobile",
                "parent_City",
                "partner",
                "phone",
                "city", 
                "description_about_me",
                "teacher_name",
                "teacher_number",
                "teacher_email", 
                "teacher_School",
                "badges_backpack_url", 
                "personal_portfolio_url" ,
            ],
            data: []
        };

        csvData.data.push( student.email || '' );
        csvData.data.push( student.name || '' );
        csvData.data.push( student.gender || '' );
        csvData.data.push( student.date_of_birth ? moment(student.date_of_birth).utcOffset(8).startOf('date').format("YYYY-MM-DD") : '' );
        csvData.data.push( student.org || '' );
        csvData.data.push( student.medical_condition || '' );
        csvData.data.push( student.parent_name || '' );
        csvData.data.push( student.parent_email || '' );
        csvData.data.push( student.parent_phone || '' );
        csvData.data.push( student.parent_city || '' );
        csvData.data.push( getSelectedValue( regionsList, student.region, 'value', 'label', '-' ) );
        csvData.data.push( student.phone || '' );
        csvData.data.push( student.city || '' );
        csvData.data.push( student.about_me || '' );
        csvData.data.push( student.teacher_name || '' );
        csvData.data.push( student.teacher_phone || '' );
        csvData.data.push( student.teacher_email || '' );
        csvData.data.push( student.teacher_org || '' );
        csvData.data.push( PLATFORM_BACKPACK_URL + ( student.uid || '' ) );
        csvData.data.push( student.eportfolio || '' );

        if ( enrollments && isArrayExists( enrollments ) ) {
            enrollments.forEach( (enrollment) => {
                csvData.fields.push( "class_name" );
                csvData.data.push( enrollment.class_name );

                csvData.fields.push( "course_name" );
                csvData.data.push( enrollment.name );

                csvData.fields.push( "status" );
                csvData.data.push( getSelectedValue( ENROLLMENT_STATUS_OPTIONS, enrollment.status, 'value', 'label', ( enrollment.status && !_isEmpty( enrollment.status ) ? enrollment.status : '-' ) ) );

                csvData.fields.push( "activated_on" );
                csvData.data.push( ( enrollment.activated ? getMomentTime( stringToInteger( enrollment.activated ), 'DD-MMM-YYYY' ) : '-' ) );

                csvData.fields.push( "deactivated_on" );
                csvData.data.push( ( enrollment.deactivated ? getMomentTime( stringToInteger( enrollment.deactivated ), 'DD-MMM-YYYY' ) : '-' ) );

                csvData.fields.push( "concluded_on" );
                csvData.data.push( ( enrollment.concluded ? getMomentTime( stringToInteger( enrollment.concluded ), 'DD-MMM-YYYY' ) : '-' ) );
            });
        }

        this.doCSVDownload(csvData);
    }

    handleConfirmation = () => {
        const { student, onTrigger } = this.props;
        const { modalData } = this.state;

        const formData = {
            uid: student.uid,
            region: modalData.region
        };

        this.setState({ open: false, modalData: false, confirmation: false })
        onTrigger(formData);
    }

    handleUpdate = () => {
        const { modalData } = this.state;
        let error = false;
        // make sure a class is selected
        if ( !( modalData && modalData.region && !_isEmpty( modalData.region ) ) )
            error = "Please select a partner.";

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.setState({ confirmation: true });
        }
            
    }

    handleFormUpdate = (newValue,key,subkey,subVal) => {
        const { modalData } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} );
        switch( key ) {
            default:
                if ( subkey && ( subkey === 'checked' || subkey === 'unchecked' ) && subVal && !_isEmpty( subVal ) ) {
                    // use subVal as the new value
                    newData[key] = subVal;
                } else {
                    // otherwise - just update normally
                    newData[key] = newValue
                }
                break;
        } // end - key
        
        this.setState({ modalData: newData })
    }

    handleOpen = (event) => {
        const { changesMade } = this.props;
        event.preventDefault();
        if ( changesMade ) {
            triggerErrorAlert("Please save your changes before proceeding.");
        } else {
            this.setState({ 
                open: true, 
                modalData: {
                    region: '' 
                } 
            });
        }

    }

    getPartnerName = () => {
        const { modalData } = this.state;
        const options = this.getPartnerOptions();
        const selected = ( modalData && modalData.region && options ? _find( options, { value: modalData.region } ) : false );
        return ( selected ? selected.label : '' );
    }

    getPartnerOptions = () => {
        const { student, regionsList } = this.props;
        let options = [];
        if ( regionsList ) {
            // remove the current student's region from the options
            if ( regionsList && isArrayExists( regionsList ) ) {
                _forEach( regionsList, (item) => {
                    if ( item && item.id !== student.region ) {
                        options.push({ label: item.label, value: item.id });
                    }
                });
            } // end - schedules
        }
        return _sortBy( options, o => o.label.toLowerCase() );
    }

    getStudentClassesPrograms = () => {
        const { student } = this.props;
        let programs = [];
        if ( student && student._data && student._data.trainees ) {
            _forEach( student._data.trainees, (trainee) => {
                if ( trainee && trainee.program_id && !_isEmpty( trainee.program_id ) && !_find( programs, { id: trainee.program_id } ) ) {
                    programs.push({ id: trainee.program_id});
                }
            });
        }
        console.log(programs);
        return programs;
    }

    getNewPartnerClasses = () => {
        const { schedules } = this.props;
        const { modalData } = this.state;
        const studentPrograms = this.getStudentClassesPrograms();
        let options = [];
        let newPartnerSchedules = _filter( schedules, (schedule) => {
            return ( schedule && schedule.region === modalData.region && schedule.program_id && !_isEmpty( schedule.program_id ) && _find( studentPrograms, { id: schedule.program_id } ) );
            });
        if ( newPartnerSchedules && isArrayExists( newPartnerSchedules ) ) {
            _forEach( newPartnerSchedules, (schedule) => {
                options.push({ label: schedule.class_name, value: schedule.id });
            });
        }
        return _sortBy( options, o => o.label.toLowerCase() );
    }

    renderForm = () => {
        const { student, schedules } = this.props;
        const { modalData } = this.state;
        return (
        <div>
            <WarningBox style={{ margin: '10px 0' }}>
                <i className="fa fa-exclamation-triangle"></i>WARNING:<br />
                <div style={{ fontWeight: "700", paddingBottom: "5px" }}>Implications to both Partners:</div>
                <ol style={{ paddingLeft: '10px', listStyle: 'numeric' }}>
                    <li>After the transfer, the selected Partner will need to register, confirm, and open access for the student as a new student </li>
                    <li><a href="https://sms.chumbaka.asia/class_attendance_reporting" target="_blank">Class Attendance & Reporting data</a> will NOT be transferred over to the new Class. Please download the data manually. </li>
                    <li>After the transfer, the current Partner can no longer see the student’s profile and database</li>
                    <li>Student’s login credentials at LMS will not be changed</li>
                    <li>Learning progress in LMS will not be lost doing this method</li>
                </ol>
                <div style={{ fontWeight: "700", padding: "5px 0" }}>Conditions for this transfer:</div>
                <ol style={{ paddingLeft: '10px', listStyle: 'numeric' }}>
                    <li>This student to be transferred must have a “Confirmed” status in the current Partner’s Class(es). </li>
                </ol>
                <div style={{ fontWeight: "700", padding: "5px 0" }}>By clicking “Transfer”, the system will:</div>
                <ol style={{ paddingLeft: '10px', listStyle: 'numeric' }}>
                    <li>Deactivate all active Course(s) of this student in the current Partner’s Class(es)</li>
                    <li>Transfer this student’s database to the selected Partner</li>
                </ol>
            </WarningBox>
            <div style={{ padding: '0px 8px' }}>
                <Typography variant="h6" style={{ marginBottom: '10px', fontWeight: '700' }}>Step #1:</Typography>
                <InfoButton size="small" style={{ padding: '4px 6px' }} onClick={this.handleDataDownload}><i className="fa fa-cloud-down"></i>Download Student Data</InfoButton>
            </div> 
            <div style={{ padding: '25px 8px 15px 8px' }}>
                <Typography variant="h6" style={{ marginBottom: '10px', fontWeight: '700' }}>Step #2: Select the New Partner to transfer to</Typography>
                <FormSelect 
                    label="Select New Partner" 
                    name="region"
                    // tooltip={(
                    // <>
                    //     TRANSFER TO A DIFFERENT CLASS CONSTITUTES:<br /><br />
                    //     * Adding the student in the class with “Confirmed” status<br />
                    //     * Learning progress in LMS will not be lost doing this method <br />
                    //     * The student’s active course(s) here will be deactivated first, and then activated again at the new class. <br />
                    //     * Make sure the new class is the same “program” as the current class. <br />
                    //     * Class Attendance & Reporting data will NOT be transferred over to the new class. Please download the data manually. <br />
                    //     * An email will be sent out to notify the parents & students. 
                    // </>
                    // )} 
                    value={( modalData && modalData.region ? modalData.region : '' )} 
                    options={[ { value: '', label: 'Select an Option' }, ...this.getPartnerOptions() ]} 
                    onChange={this.handleFormUpdate} />
            </div>
            { DEV_MODE && modalData && modalData.region && !_isEmpty( modalData.region ) && (
            <div style={{ padding: '25px 8px 15px 8px' }}>
                <Typography variant="h6" style={{ marginBottom: '10px', fontWeight: '700' }}>Step 3: Select the classes from the New Partner to transfer to</Typography>
                <FormCheckboxes
                    label=""
                    value={( modalData.new_classes || [] )}
                    options={this.getNewPartnerClasses()}
                    name="new_classes"
                    xs={6}
                    sm={6}
                    // showAllCheck={true}
                    onChange={(newValue,key) => {
                        this.handleFormUpdate(newValue,key);
                    }} />
            </div>
            ) }
        </div>
        );
    }

    render() {
        const { student } = this.props;
        const { open, confirmation } = this.state;
        return (
        <>

            <div style={{ padding: '10px 0' }}>
                <InfoButton disabled={( student && student.role && ( student.role === 'student' || student.role === 'mentor' ) ? null : 'yes' )} size="small" style={{ padding: '4px 6px' }} onClick={this.handleOpen}><i className="fa fa-random"></i>Change Partner</InfoButton>
            </div>

            <ModalView 
                open={open}
                title={(
                <>  
                    {`Transfer ${student && student.name || ''} to a Different Partner`}
                </>
                )}
                onClose={() => this.setState({ open: false, modalData: false })}
                disableBackdrop={true}
                actionLabel="Transfer"
                maxWidth="md"
                doAction={this.handleUpdate}
                contents={this.renderForm()} />

            <ModalView 
                open={confirmation}
                title={`Please confirm your action`}
                onClose={() => this.setState({ open: false, modalData: false, confirmation: false })}
                disableBackdrop={true}
                actionLabel="Confirm"
                maxWidth="sm"
                doAction={this.handleConfirmation}
                contents={(
                <>
                    <Typography variant="h5" style={{ marginBottom: '15px' }}>{`You're about to transfer "${student && student.name || ''}" to "${this.getPartnerName()}"`}</Typography>
                    <Typography variant="body1">{`This action cannot be reversed.`}</Typography>
                </>
                )} />

        </>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(StudentRegionTransfer);