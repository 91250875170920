/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormSelect2 from '../../components/FormSelect2';

import { InfoButton } from '../../styles/button';
import { SuccessTag, GreyTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { isAdmin, hasAccessRights, getUserRegionFilter } from '../../helpers/auth';

import { appChangesReset } from '../../actions/misc';

// import { PLATFORM_COURSE_REGISTRATION_LINK } from '../../constants';

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
`;

const OuterWrapper = styled.div`
    max-width: 500px;
`;

class ClassFilterMenu extends React.Component {
    
    state = {
        region: '',
        course_id: '',
        schedule_id: '',
        scheduleLoaded: true
    };

    componentDidMount() {
        const { authData, currentSchedule } = this.props;
        // set region if not admin
        if ( !isAdmin( authData ) )
            this.setState({ region: getUserRegionFilter(authData) });

        if ( currentSchedule && !_isEmpty( currentSchedule ) ) {
            this.setState({ schedule_id: currentSchedule });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    handleFilterTrigger = () => {
        const { onFilter, changesMade } = this.props;
        const { region, schedule_id } = this.state;
        let error = false;

        if ( !( schedule_id && !_isEmpty( schedule_id ) ) )
            error = 'Please select a Class';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            if ( changesMade ) {
                let answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to load new set of data?");
                if ( answer ) {
                    if ( onFilter ) {
                        this.props.dispatch(appChangesReset());
                        onFilter( schedule_id );
                    }
                } // end - answer
            } else {
                if ( onFilter ) {
                    this.props.dispatch(appChangesReset());
                    onFilter( schedule_id );
                }
            } // end - changesMade
        }
    }

    handleCourseChange = (newValue,key) => {
        this.setState({ course_id: newValue });
    }

    getClassName = () => {
        const { schedule_id } = this.state;
        const options = this.getOptions('schedules');
        const selected = _find( options, { value: schedule_id } );
        return ( selected && !_isEmpty( selected ) ? selected.label : '' );
    }


    getOptions = (type) => {
        const { regionsList, coursesList, schedulesList, customSchedules } = this.props;
        const { region, course_id } = this.state;
        let options = [];
        switch (type) {
            case 'regions':
                options = getSelectOptions({ 
                    list: ( regionsList && isArrayExists( regionsList ) ? _sortBy( regionsList, ['label'] ) : [] ), 
                    options: [{ value: '', label: 'All Partners'}], 
                    keys: { value: 'id', label: 'label' } 
                });
                break; 
            case 'courses':
                let courses = ( coursesList && isArrayExists( coursesList ) ? _sortBy( coursesList, ['name'] ) : [] );
                options = getSelectOptions({ 
                    list: ( region && !_isEmpty( region ) ? _filter( courses, c => c.course_visibility && _find( c.course_visibility, { id: region } ) ? true : false ) : courses ), 
                    options: [{ value: '', label: 'Select an Option'}], 
                    keys: { value: 'id', label: 'name' } 
                });
                break; 
            case 'schedules':
                let schedules = ( customSchedules && isArrayExists( customSchedules ) ? _sortBy( customSchedules, ['class_name'] ) : ( schedulesList && isArrayExists( schedulesList ) ? _sortBy( schedulesList, ['class_name'] ) : [] ) );
                options = getSelectOptions({ 
                    list: ( region && !_isEmpty( region ) ? _filter( schedules, { region }) : schedules ), 
                    // options: [{ value: '', label: 'Select an Option'}], 
                    options: [],
                    keys: { value: 'id', label: 'class_name' } 
                });
                break; 
        }
        return options;
    }

    renderMenu = () => {
        const { authData, hideRegionSelector } = this.props;
        const { scheduleLoaded, region, course_id, schedule_id } = this.state;
        return (
        <Grid container spacing={2}>
            { hideRegionSelector ? null : (
            <Grid item xs={12} sm={12}>
                <FormSelect2 
                    value={( region || '' )} 
                    label="Partner" 
                    name="region" 
                    options={this.getOptions('regions')} 
                    disabled={( isAdmin( authData ) ? false : true )} 
                    isSearchable={true}
                    onChange={(newValue) => this.setState({ region: newValue })} />
            </Grid>
            ) }
            {/* <Grid item xs={12} sm={6}> </Grid> */}
            {/* <Grid item xs={12} sm={4}>
                <FormSelect 
                    value={( course_id || '' )} 
                    label="Course" 
                    name="course_id" 
                    options={this.getOptions('courses')}
                    onChange={this.handleCourseChange} />
            </Grid> */}
            <Grid item xs={10} sm={10}>
                <FormSelect2 
                    value={( schedule_id || '' )} 
                    label="Class Name" 
                    name="schedule_id" 
                    options={this.getOptions('schedules')}
                    placeholder="Select a Class"
                    isSearchable={true}
                    // disabled={( course_id && !_isEmpty( course_id ) && scheduleLoaded ? false : true )}
                    onChange={(newValue) => this.setState({ schedule_id: newValue })} />
            </Grid>
            <Grid item xs={2} sm={2}>
                <div style={{ textAlign: "center" }}>
                    {/* copy class name button */}
                    { schedule_id && !_isEmpty( schedule_id ) ? (
                    <CopyToClipboard 
                        text={this.getClassName()}
                        onCopy={() => {
                            triggerSuccessAlert("Value copied to clipboard.");
                        }}>
                        <IconButton><FileCopyIcon /></IconButton>
                    </CopyToClipboard>
                    ) : <IconButton disabled={true}><FileCopyIcon /></IconButton> }
                </div>
            </Grid>
            {/* <Grid item xs={12} sm={6}> </Grid> */}
            <Grid item xs={12}>
                <InfoButton disabled={( scheduleLoaded ? false : 'yes' )} minWidth="128px" style={{ padding: '5px 15px' }} onClick={this.handleFilterTrigger}><i className="fa fa-refresh"></i>Load Student Data</InfoButton>
            </Grid>
        </Grid>
        )
    }

    render() {
        return (
        <OuterWrapper>

            <Paper elevation={2}>
                <Wrapper>
                    {this.renderMenu()}
                </Wrapper>
            </Paper>

        </OuterWrapper>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        coursesList: state.courses && state.courses.courses_options ? state.courses.courses_options : false,
        schedulesList: state.schedules && state.schedules.schedules_options ? state.schedules.schedules_options : false,
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ClassFilterMenu);