/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
//import Select2 from 'react-select';
import _forEach from "lodash/forEach";
import _find from "lodash/find";

import { FlexColumn } from "../../styles/division";

import FormSelect2 from "../FormSelect2";

import { isArrayExists } from "../../helpers/validation";

import theme from "../../theme";

const useStyles = (theme) => ({
    selector: {},
});

class TableBar extends React.Component {
    handleEntriesChange = (event) => {
        if (this.props.onEntriesChange) this.props.onEntriesChange(event.target.value);
    };

    handleSortByChange = (event) => {
        if (this.props.onSortByChange) this.props.onSortByChange(event.target.value);
    };

    handlefilterByChange = (newValue) => {
        if (this.props.onFilterByChange) this.props.onFilterByChange(newValue);
    };

    handleInputChange = (event) => {
        if (this.props.onSearchChange) this.props.onSearchChange(event.target.value);
    };

    showItem = (id) => {
        const { show } = this.props;
        return !show || (show && isArrayExists(show) && _find(show, (o) => o == id)) ? true : false;
    };

    renderSearchBox = () => {
        const { searchterms, secondLineOptions = [] } = this.props;
        return (
            <TextField
                label="Search"
                value={searchterms || ""}
                margin="normal"
                variant="outlined"
                onChange={this.handleInputChange}
                style={{ background: "#fff", marginTop: secondLineOptions && isArrayExists(secondLineOptions) ? "0px" : null }}
            />
        );
    };

    renderFilterBy = () => {
        const { filterBy, filterByLabel = "Filter By", filterByOptions, filterByWidth, filterByPlaceholder } = this.props;
        return (
            <div style={{ maxWidth: filterByWidth || "300px", width: "100%" }}>
                <FormSelect2
                    label={filterByLabel}
                    placeholder={filterByPlaceholder || ""}
                    options={filterByOptions || []}
                    value={filterBy || false}
                    isClearable={true}
                    isSearchable={true}
                    onChange={this.handlefilterByChange}
                />
            </div>
            // <FormControl variant="outlined" style={{ background: "#fff", marginRight: "5px" }}>
            //     {/* <InputLabel style={{ background: "#fff", padding: "5px", color: theme.palette.background }}>Filter By</InputLabel> */}
            //     <FormSelect2
            //         label="Filter By"
            //         options={( filterByOptions || [] )}
            //         value={( filterBy || false )}
            //         isClearable={true}
            //         isSearchable={true}
            //         onChange={this.handlefilterByChange} />
            // </FormControl>
        );
    };

    renderSortBy = () => {
        const { sortBy, sortByOptions } = this.props;
        return (
            <FormControl variant="outlined" style={{ background: "#fff", marginRight: "5px" }}>
                <InputLabel style={{ background: "#fff", padding: "5px", color: theme.palette.background }}>Sort By</InputLabel>
                <Select native value={sortBy} onChange={this.handleSortByChange} input={<OutlinedInput name="sortby" />}>
                    {sortByOptions.map((option) => {
                        return (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        );
                    })}
                </Select>
            </FormControl>
        );
    };

    renderEntries = () => {
        const { perPage, limitedPerPage } = this.props;
        return (
            <FormControl variant="outlined" style={{ background: "#fff", marginRight: "5px" }}>
                <InputLabel style={{ background: "#fff", padding: "5px", color: theme.palette.background }}>Per Page</InputLabel>
                <Select native value={perPage} onChange={this.handleEntriesChange} input={<OutlinedInput name="perpage" />}>
                    <option value={5}>5 per page</option>
                    <option value={10}>10 per page</option>
                    <option value={20}>20 per page</option>
                    {limitedPerPage ? null : <option value={40}>40 per page</option>}
                    {limitedPerPage ? null : <option value={60}>60 per page</option>}
                    {limitedPerPage ? null : <option value={80}>80 per page</option>}
                    {limitedPerPage ? null : <option value={100}>100 per page</option>}
                    {limitedPerPage ? null : <option value={200}>200 per page</option>}
                    {limitedPerPage ? null : <option value={500}>500 per page</option>}
                </Select>
            </FormControl>
        );
    };

    render() {
        const { style, sortByOptions, filterByOptions, leftWidth, rightWidth, leftButtons, rightButtons, secondLineOptions = [] } = this.props;
        return (
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems={isArrayExists(secondLineOptions) ? "flex-start" : "center"}
                style={style || null}
            >
                <FlexColumn width={leftWidth || "30%"}>
                    <Box display="flex">
                        {this.showItem("search") ? this.renderSearchBox() : null}
                        {leftButtons && isArrayExists(leftButtons)
                            ? leftButtons.map((btn, index) => {
                                  return btn;
                              })
                            : null}
                    </Box>
                </FlexColumn>
                <FlexColumn width={rightWidth || "70%"}>
                    <Box display="flex" justifyContent="flex-end">
                        {rightButtons && isArrayExists(rightButtons)
                            ? rightButtons.map((btn, index) => {
                                  return btn;
                              })
                            : null}
                        {this.showItem("filter") && filterByOptions && isArrayExists(filterByOptions) ? this.renderFilterBy() : null}
                        {this.showItem("sort") && sortByOptions && isArrayExists(sortByOptions) ? this.renderSortBy() : null}
                        {this.showItem("entries") ? this.renderEntries() : null}
                    </Box>
                    {secondLineOptions && isArrayExists(secondLineOptions) ? (
                        <Box display="flex" justifyContent="flex-end" style={{ paddingTop: "20px" }}>
                            {secondLineOptions.map((item, index) => {
                                return item;
                            })}
                        </Box>
                    ) : null}
                </FlexColumn>
            </Box>
        );
    }
}

export default compose(withStyles(useStyles))(TableBar);
