/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import red from "@material-ui/core/colors/red";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _filter from "lodash/filter";
import _find from "lodash/find";
import _size from "lodash/size";
import _isEqual from "lodash/isEqual";
import _findIndex from "lodash/findIndex";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _toString from "lodash/toString";

import Teams from "./teams";
import Table from "./table";
import ClassFilterMenu from "../ClassRegistrationPage/filter_menu";
import CourseColumnsMenu from "./columns_menu";

import AppWrapper from "../../components/AppWrapper";
import AppInfoBox from "../../modules/AppInfoBox";
import ScheduleSwitcher from "../../components/ScheduleSwitcher";
import SnackBarSave from "../../components/SnackBarSave";

import { isAdmin, getUserRegionFilter } from "../../helpers/auth";
import { cloneCollections } from "../../helpers/data";
import { getMomentTime } from "../../helpers/date";
import { isArrayExists, isObjectExists } from "../../helpers/validation";

import { getClassShowcase, updateClassShowcase, forceIssueBadge } from "../../actions/class_showcase";
import { getTrainees } from "../../actions/trainees";
import { getCoursesOptions } from "../../actions/courses";
import { getSchedulesOptions, getSchedule } from "../../actions/schedules";
import { getLMSCourses } from "../../actions/canvas_lms";
import { getRegions } from "../../actions/m_regions";
import { getFeedbackFormsFields } from "../../actions/m_feedback_forms";
import { getMaintenance } from "../../actions/maintenance";
import { toggleLoader } from "../../actions/global";
import { appChangesMade, resetRedux } from "../../actions/misc";

import { ButtonGroup, AInfoLink, InfoButton } from "../../styles/button";
import { SuccessTag } from "../../styles/tag";
import { WarningBox } from "../../styles/message";

import { ENROLLMENT_STATUS_OPTIONS } from "../../constants";

const TableWrapper = styled.div``;

class ClassShowcasePage extends React.Component {
    utcOffSet = 8;

    state = {
        trainees: false,
        traineesRand: false,
        scheduleRand: false,
        showcaseRand: false,
        showcase: false,
        currentSchedule: false,
        courseColumns: [],
        dataLoaded: false,
    };

    componentDidMount() {
        const { authData, coursesList, regionsList, schedulesList, lmsCoursesList, rubricsList, badgeTypeList, feedbackFormsFieldsList, location } =
            this.props;

        // preload trainees
        if (location && location.state && location.state.schedule_id && !_isEmpty(location.state.schedule_id))
            this.handleFilter(location.state.schedule_id);

        // get regions
        if (!regionsList) this.props.dispatch(getRegions());

        // get course options
        // if ( !coursesList )
        this.props.dispatch(getCoursesOptions());

        // get schedule options
        if (!schedulesList) this.props.dispatch(getSchedulesOptions({ region: getUserRegionFilter(authData) }));

        // get lms courses
        if (!lmsCoursesList) this.props.dispatch(getLMSCourses());

        // get rubric
        if (!rubricsList) this.props.dispatch(getMaintenance("rubrics"));

        // get feedback forms fields
        if (!feedbackFormsFieldsList) this.props.dispatch(getFeedbackFormsFields());

        // get badge types
        // if ( !badgeTypeList )
        //     this.props.dispatch(getMaintenance('badge_type'));

        if (regionsList && schedulesList && lmsCoursesList && rubricsList && feedbackFormsFieldsList) {
            this.setState({ dataLoaded: true });
        } // end - randNum
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            traineesList,
            regionsList,
            schedulesList,
            coursesList,
            lmsCoursesList,
            rubricsList,
            feedbackFormsFieldsList,
            currentShowcase,
            currentScheduleData,
            traineesRand,
            scheduleRand,
            showcaseRand,
        } = this.props;
        if (regionsList && schedulesList && lmsCoursesList && rubricsList && feedbackFormsFieldsList && !this.state.dataLoaded) {
            this.setState({ dataLoaded: true });
        } // end - randNum

        if (
            currentShowcase &&
            currentScheduleData &&
            lmsCoursesList &&
            coursesList &&
            traineesList &&
            traineesRand &&
            traineesRand !== this.state.traineesRand &&
            scheduleRand &&
            scheduleRand !== this.state.scheduleRand &&
            showcaseRand &&
            showcaseRand !== this.state.showcaseRand
        ) {
            this.props.dispatch(toggleLoader(false));
            this.setState({
                traineesRand,
                scheduleRand,
                showcaseRand,
                courseColumns: this.getDefaultCourseColumns(currentScheduleData, coursesList, lmsCoursesList),
                trainees: traineesList && isArrayExists(traineesList) ? cloneCollections(traineesList) : [],
                showcase: currentShowcase && isObjectExists(currentShowcase) ? cloneCollections(currentShowcase) : {},
            });
        } // end - traineesRand
    }

    componentWillUnmount() {
        this.handleReset();
    }

    handleReset = () => {
        this.props.dispatch(resetRedux("trainees"));
        this.props.dispatch(resetRedux("schedule"));
        this.props.dispatch(resetRedux("classes"));
    };

    handleFilter = (schedule_id) => {
        const { authData } = this.props;
        this.setState({ currentSchedule: schedule_id });
        this.props.dispatch(getTrainees({ schedule_id, enrollment_status: this.getEnrollmentStatusFilter() }));
        this.props.dispatch(getSchedule(schedule_id, { authData, getScheduleUsage: true }));
        this.props.dispatch(getClassShowcase(schedule_id));
        this.props.dispatch(toggleLoader(true));
    };

    handleSaveChanges = (event) => {
        const { traineesList } = this.props;
        const { trainees, currentSchedule } = this.state;
        event.preventDefault();
        let formData = {
            schedule_id: currentSchedule,
            trainees: [],
        };
        // run through all the trainees to look for changes
        if (trainees && isArrayExists(trainees) && traineesList && isArrayExists(traineesList)) {
            _forEach(trainees, (trainee) => {
                let oriTrainee = _find(traineesList, { id: trainee.id });
                if (!_isEqual(trainee, oriTrainee)) {
                    formData.trainees.push({
                        id: trainee.id,
                        showcase: trainee.showcase || [],
                    });
                }
            });
        } // end - trainees

        // console.log( formData );

        this.props.dispatch(
            updateClassShowcase(formData, {
                schedule_id: currentSchedule,
                enrollment_status: this.getEnrollmentStatusFilter(),
            })
        );
    };

    handleForceIssueBadge = (eligibleStudents, lms_course_id) => {
        const { currentSchedule } = this.state;
        // console.log( eligibleStudents );
        this.props.dispatch(
            forceIssueBadge(
                {
                    schedule_id: currentSchedule,
                    lms_course_id,
                    trainees: eligibleStudents,
                },
                {
                    schedule_id: currentSchedule,
                    enrollment_status: this.getEnrollmentStatusFilter(),
                }
            )
        );
    };

    getEnrollmentStatusFilter = () => {
        let statuses = [];
        ENROLLMENT_STATUS_OPTIONS.forEach((option) => {
            if (option.value && !_isEmpty(option.value) && option.value !== "delete" && option.value !== "registered" && option.value !== "trial")
                statuses.push(option.value);
        });
        return statuses;
    };

    getDefaultCourseColumns = (schedule, courses, lms_courses) => {
        let columns = [],
            enrollments_by_course = [];

        // calculate most enrollments by courses
        if (schedule && schedule.usage_enrollments && isArrayExists(schedule.usage_enrollments)) {
            _forEach(schedule.usage_enrollments, (enrollment) => {
                if (enrollment.course_id) {
                    let index = _findIndex(enrollments_by_course, (c) => _toString(c.id) === _toString(enrollment.course_id));
                    if (index >= 0) {
                        enrollments_by_course[index].count++;
                    } else {
                        enrollments_by_course.push({
                            id: enrollment.course_id,
                            count: 1,
                        });
                    } // end - index
                } // end - enrollment.course_id
            });
            enrollments_by_course = _sortBy(enrollments_by_course, ["count"]);
            enrollments_by_course = _reverse(enrollments_by_course);
        } // end - schedule.usage_enrollments

        // show top 4 courses
        if (
            schedule.course_access &&
            isArrayExists(schedule.course_access) &&
            lms_courses &&
            isArrayExists(lms_courses) &&
            courses &&
            isArrayExists(courses) &&
            isArrayExists(enrollments_by_course)
        ) {
            for (let index = 0; index < 6; index++) {
                if (enrollments_by_course[index] && enrollments_by_course[index].id) {
                    let selected_course = _find(
                            courses,
                            (c) => c.lms_course_id && _toString(c.lms_course_id) === _toString(enrollments_by_course[index].id)
                        ),
                        selected_lms_course = _find(lms_courses, (c) => _toString(c.id) === _toString(enrollments_by_course[index].id));
                    if (selected_lms_course && selected_course && _find(schedule.course_access, { id: selected_course.id })) {
                        columns.push({
                            id: selected_lms_course.id,
                            name: selected_course.name || "",
                            course_code: selected_course.course_code || "",
                        });
                    } // end - selected_lms_course
                }
            } // end - i
        } // end - schedule.course_access

        return columns;
    };

    getCurrentClassSize = () => {
        const { traineesList } = this.props;
        const activeStudents =
            traineesList && isArrayExists(traineesList)
                ? _filter(traineesList, (t) =>
                      t.enrollment_status && t.enrollment_status !== "deleted" && t.enrollment_status !== "registered" ? true : false
                  )
                : [];
        return _size(activeStudents);
    };

    getShowcaseDates = () => {
        const { currentScheduleData } = this.props;
        return currentScheduleData && currentScheduleData.class_calendar && isArrayExists(currentScheduleData.class_calendar)
            ? _filter(currentScheduleData.class_calendar, { showcase: "yes" })
            : false;
    };

    renderShowcaseDates = (events) => {
        const { currentScheduleData } = this.props;
        let today = moment().utcOffset(this.utcOffSet).startOf("date");
        const oneyearago = moment().utcOffset(this.utcOffSet).subtract(1, "year").startOf("date");
        // only show events within 1 year
        events = _filter(events, (event) => {
            let start_date = event.start_date ? moment(event.start_date).utcOffset(this.utcOffSet).startOf("date") : false;
            return start_date && start_date.isAfter(oneyearago) ? true : false;
        });
        return (
            <div style={{ padding: "20px", border: "2px solid #212121", background: "#fff" }}>
                <Typography variant="h5" style={{ marginBottom: "8px" }}>
                    <i className="fa fa-lightbulb-o" style={{ marginRight: "8px" }}></i>Planned Open Days
                </Typography>
                {_sortBy(events, ["start_date"]).map((event) => {
                    let start_date = event.start_date ? moment(event.start_date).utcOffset(this.utcOffSet).startOf("date") : false,
                        differences = start_date.diff(today, "days");
                    return start_date ? (
                        <Grid key={event.id} container spacing={1}>
                            <Grid item xs={4} style={{ fontSize: "12px" }}>
                                {getMomentTime(event.start_date, "DD-MMM-YYYY")}
                            </Grid>
                            <Grid item xs={8} key={event.id} style={{ fontSize: "12px" }}>
                                {differences >= 0 ? (
                                    " " + differences + " day(s) left"
                                ) : (
                                    <>
                                        <i className="fa fa-exclamation-triangle" style={{ marginRight: "3px", color: red[500] }}></i>Overdue
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    ) : null;
                })}
            </div>
        );
    };

    renderTable() {
        const {
            authData,
            traineesList,
            regionsList,
            lmsCoursesList,
            coursesList,
            schedulesList,
            rubricsList,
            feedbackFormsFieldsList,
            badgeTypeList,
            currentScheduleData,
            changesMade,
        } = this.props;
        const { currentSchedule, courseColumns, trainees, showcase } = this.state;
        return (
            <Table
                courseColumns={courseColumns || false}
                showcase={showcase || false}
                badges={showcase && showcase.badges ? showcase.badges : false}
                teams={showcase && showcase.teams ? showcase.teams : false}
                schedule={currentScheduleData || false}
                currentSchedule={currentSchedule || false}
                trainees={trainees}
                currentTrainees={traineesList}
                schedules={schedulesList}
                regions={regionsList}
                courses={coursesList}
                lms_courses={lmsCoursesList}
                rubrics={rubricsList}
                feedback_forms_fields={feedbackFormsFieldsList}
                // badge_types={badgeTypeList}
                authData={authData}
                changesMade={changesMade}
                onUpdate={(newTrainees) => this.setState({ trainees: newTrainees })}
                onColumnUpdate={(newColumns, defaultCol) =>
                    this.setState({
                        courseColumns: defaultCol ? this.getDefaultCourseColumns(currentScheduleData, coursesList, lmsCoursesList) : newColumns,
                    })
                }
                onForceIssueBadge={this.handleForceIssueBadge}
            />
        );
    }

    renderInfoSection = () => {
        const { currentScheduleData, traineesList, coursesList, lmsCoursesList } = this.props;
        const { courseColumns } = this.state;
        let showcaseDates = this.getShowcaseDates();
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            {showcaseDates ? this.renderShowcaseDates(showcaseDates) : null}
                        </Grid>
                        <Grid item xs={6}>
                            <ButtonGroup align="right">
                                <AInfoLink
                                    href="https://www.notion.so/chumbaka/4-Closing-Open-Day-Digital-Badges-6d0c7f4c6d3a4a968d9bc32cb7476e51#dd5886985f8b4b8cbd90ba1ac82c5735"
                                    target="_blank"
                                    style={{ padding: "10px 25px", borderRadius: "25px" }}
                                >
                                    Open Day Preparation
                                </AInfoLink>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <CourseColumnsMenu
                        courseColumns={courseColumns || false}
                        schedule={currentScheduleData || false}
                        courses={coursesList}
                        lms_courses={lmsCoursesList}
                        onUpdate={(newColumns) => this.setState({ courseColumns: newColumns })}
                    />
                </Grid>
            </Grid>
        );
    };

    renderContents() {
        const { traineesList, currentScheduleData } = this.props;
        const { currentSchedule, traineesRand, trainees, showcase } = this.state;
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                        <ClassFilterMenu currentSchedule={currentSchedule || false} onFilter={this.handleFilter} />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <AppInfoBox page="ClassShowcasePage" />
                        {currentScheduleData && traineesRand ? this.renderInfoSection() : null}
                    </Grid>
                </Grid>

                <div style={{ borderTop: "1px solid #ddd", marginTop: "30px" }}>
                    <>
                        {currentScheduleData && currentScheduleData.team_module && currentScheduleData.team_module === "yes" ? (
                            <div style={{ padding: "30px 0", marginBottom: "30px", borderBottom: "1px solid #ddd" }}>
                                <Typography variant="h5" style={{ paddingBottom: "15px" }}>
                                    Team(s) Project Submission:
                                </Typography>
                                <Teams
                                    teams={showcase && showcase.teams ? showcase.teams : false}
                                    trainees={trainees}
                                    showcase={showcase}
                                    schedule={currentScheduleData}
                                    onUpdate={(newTrainees, newShowcase) => {
                                        this.setState({ trainees: newTrainees, showcase: newShowcase });
                                        this.props.dispatch(appChangesMade());
                                    }}
                                    onReset={() => {
                                        this.handleFilter(currentScheduleData.id);
                                    }}
                                />
                            </div>
                        ) : null}
                    </>

                    {traineesList && traineesRand ? (
                        <TableWrapper>
                            {this.renderTable()}
                            {trainees && isArrayExists(trainees) ? (
                                <div style={{ textAlign: "center", marginTop: "45px" }}>
                                    <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}>
                                        <i className="fa fa-save"></i>Save Changes
                                    </InfoButton>
                                </div>
                            ) : null}
                        </TableWrapper>
                    ) : null}
                </div>

                <SnackBarSave onSave={this.handleSaveChanges} />
            </>
        );
    }

    render() {
        const { dataLoaded, currentSchedule } = this.state;
        const { authData } = this.props;
        return (
            <AppWrapper
                subtitle="4. Closing"
                title="Showcase"
                onLoad={!dataLoaded ? true : false}
                customColumn={
                    currentSchedule && !_isEmpty(currentSchedule) ? (
                        <ScheduleSwitcher page="class_showcase" schedule_id={currentSchedule} authData={authData} />
                    ) : null
                }
                contents={authData ? this.renderContents() : null}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        currentShowcase: state.classes && state.classes.showcase ? state.classes.showcase : null,
        showcaseRand: state.classes && state.classes.rand ? state.classes.rand : null,
        traineesList: state.trainees && state.trainees.trainees ? state.trainees.trainees : null,
        traineesRand: state.trainees && state.trainees.rand ? state.trainees.rand : null,
        authData: state.auth && state.auth.user ? state.auth.user : null,
        currentScheduleData: state.schedules && state.schedules.schedule ? state.schedules.schedule : null,
        scheduleRand: state.schedules && state.schedules.rand ? state.schedules.rand : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        rubricsList: state.maintenance && state.maintenance.rubrics ? state.maintenance.rubrics : null,
        badgeTypeList: state.maintenance && state.maintenance.badge_type ? state.maintenance.badge_type : null,
        feedbackFormsFieldsList: state.maintenance && state.maintenance.feedback_forms_fields ? state.maintenance.feedback_forms_fields : null,
        coursesList: state.courses && state.courses.courses_options ? state.courses.courses_options : false,
        lmsCoursesList: state.canvas_lms && state.canvas_lms.courses ? state.canvas_lms.courses : null,
        schedulesList: state.schedules && state.schedules.schedules_options ? state.schedules.schedules_options : false,
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(ClassShowcasePage);
