/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _isEqual from 'lodash/isEqual';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader'
import SnackBarSave from '../../components/SnackBarSave';

import EditForm from '../CoursePage/form';
import ItemNotFound from '../../components/ItemNotFound';

import { InfoButton, GreyButton } from '../../styles/button';

import { hasAccessRights } from '../../helpers/auth'; 
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck, isSkip } from '../../helpers/schemas';

import { getPartners } from '../../actions/partners';
import { getCourse, addCourse } from '../../actions/courses';
import { getMaintenance } from '../../actions/maintenance';
import { getLMSCourses } from '../../actions/canvas_lms';
import { appChangesMade, resetRedux } from '../../actions/misc';

import { courseSchema } from '../../schemas/course';

class CourseNewPage extends React.Component {

    state = {
        visibility_lock: true,
        currentTab: 'details',
        course: false,
        randNum: false
    };

    componentDidMount() {
        const { authData, contactHourList, productLevelList, productCreditList, courseTypeList, courseDurationList, feedbackFormsList, currencyList, rubricsList, lmsCoursesList } = this.props;


        if ( courseTypeList && courseDurationList && feedbackFormsList && currencyList && rubricsList && lmsCoursesList && !this.state.randNum ) {
            this.setState({ course: this.getDefaultCourseData(), randNum: true });
        } // end - currentCourse

        // get contact hour
        if ( !contactHourList )
            this.props.dispatch(getMaintenance('contact_hour'));

        // get product level
        if ( !productLevelList )
            this.props.dispatch(getMaintenance('product_level'));

        // get product credit
        if ( !productCreditList )
            this.props.dispatch(getMaintenance('product_credit'));

        // get partners
        // if ( !partnersList )
        //     this.props.dispatch( getPartners({ authData }) );

        // get course_type
        if ( !courseTypeList )
            this.props.dispatch(getMaintenance('course_type'));

        // get course duration
        if ( !courseDurationList )
            this.props.dispatch(getMaintenance('course_duration'));

        // get feedback_forms
        if ( !feedbackFormsList )
            this.props.dispatch(getMaintenance('feedback_forms'));

        // get currency
        if ( !currencyList )
            this.props.dispatch(getMaintenance('currency'));

        // get rubrics
        if ( !rubricsList )
            this.props.dispatch(getMaintenance('rubrics'));

        // get lms courses
        if ( !lmsCoursesList )
            this.props.dispatch(getLMSCourses());

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { contactHourList, productLevelList, productCreditList, courseTypeList, courseDurationList, feedbackFormsList, currencyList, rubricsList, lmsCoursesList 
            } = this.props;

        // for course
        if ( contactHourList && productLevelList && productCreditList && courseTypeList && courseDurationList && feedbackFormsList && currencyList && rubricsList && lmsCoursesList && !this.state.randNum ) {
            this.setState({ course: this.getDefaultCourseData(), randNum: true });
        } // end - currentCourse
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('course'));
    }

    handleRefresh = (type) => {
        // const { course_id } = this.props.match.params;
        // switch( type ) {
        //     case 'emails':
        //         this.setState({ emails: false });
        //         this.props.dispatch(getEmailsBy('courses',course_id));
        //         break;
        // }
    }

    handleSaveChanges = (event) => {
        const { history } = this.props;
        const { course } = this.state;
        event.preventDefault();
        var error = false,
            formData = {};

        // do error check
        courseSchema.forEach(schema => {
            formData[schema.id] = ( course && course[schema.id] ? cloneCollections( course[schema.id] ) : schema.default );
            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'add' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addCourse(formData,history));
            this.setState({ visibility_lock: true });
        } // end - error

    }

    handleFormUpdate = (newValue) => {
        this.setState({ course: newValue });
        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    getDefaultCourseData = () => {
        const { authData, partnersList } = this.props;
        let course = {};
        courseSchema.forEach(schema => {
            if ( !isSkip(schema,'add') ) {
                switch (schema.id) {
                    case 'course_visibility':
                        course[schema.id] = [];
                        // don't need this anymore
                        // if ( partnersList && isArrayExists( partnersList ) ) {
                        //     _forEach( partnersList, partner => {
                        //         if ( partner.region && !_isEmpty( partner.region ) ) {
                        //             course[schema.id].push({
                        //                 id: partner.region,
                        //                 name: ( partner.label || '' )
                        //             });
                        //         } // end - partner.region
                        //     });
                        // } // end - partnersList
                        break;
                    default:
                        course[schema.id] = ( schema.default || 0 === schema.default || '' === schema.default ? schema.default : null );
                        break;
                }
            } // end - schema
        });
        return course;
    }

    renderTabContents = () => {
        const { authData, partnersList, contactHourList, productLevelList, productCreditList, courseTypeList, courseDurationList, feedbackFormsList, currencyList, rubricsList, lmsCoursesList, changesMade } = this.props;
        const { currentTab, course, visibility_lock } = this.state;
        const tabProps = {
            authData: ( authData || false ),
            course: ( course || false ),
            changesMade: ( changesMade || false ),
            onFormUpdate: this.handleFormUpdate
        };
        switch( currentTab ) {
            case 'details':
                return <EditForm 
                    formType="add"
                    currentCourse={( course || false )}
                    contact_hour={contactHourList}
                    product_level={productLevelList}
                    product_credit={productCreditList}
                    partners={( partnersList || false )}
                    course_type={( courseTypeList || false )}
                    course_duration={( courseDurationList || false )}
                    feedback_forms={( feedbackFormsList || false )}
                    currency={( currencyList || false )}
                    rubrics={( rubricsList || false )}
                    lms_courses={( lmsCoursesList || false )}
                    // visibility_lock={( visibility_lock || false )}
                    // onVisibilityUnlock={() => this.setState({ visibility_lock: false })}
                    {...tabProps} />;
        }
    }


    renderContents() {
        return (
        <div>

            {/* {this.renderTabs()} */}
            {this.renderTabContents()}

            <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
            </div>

            <SnackBarSave onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { randNum } = this.state;
        return <AppWrapper 
                title="Add New Product Profile"
                subtitle="Setup"
                back="/products"
                breadcrumbs={[
                    { url: '/products', label: 'Product' },
                    { label: 'Add New' }
                ]}
                maxWidth="1110px"
                onLoad={( !( randNum ) ? true : false )}
                contents={this.renderContents()} 
                />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        partnersList: state.partners && state.partners.partners ? state.partners.partners : null,
        courseTypeList: state.maintenance && state.maintenance.course_type ? state.maintenance.course_type : null,
        courseDurationList: state.maintenance && state.maintenance.course_duration ? state.maintenance.course_duration : null,
        feedbackFormsList: state.maintenance && state.maintenance.feedback_forms ? state.maintenance.feedback_forms : null,
        currencyList: state.maintenance && state.maintenance.currency ? state.maintenance.currency : null,
        rubricsList: state.maintenance && state.maintenance.rubrics ? state.maintenance.rubrics : null,
        lmsCoursesList: state.canvas_lms && state.canvas_lms.courses ? state.canvas_lms.courses : null,
        contactHourList: state.maintenance && state.maintenance.contact_hour ? state.maintenance.contact_hour : null,
        productLevelList: state.maintenance && state.maintenance.product_level ? state.maintenance.product_level : null,
        productCreditList: state.maintenance && state.maintenance.product_credit ? state.maintenance.product_credit : null,
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(CourseNewPage);