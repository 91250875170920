/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Papa from 'papaparse';
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import moment from 'moment';
import shortid from 'shortid';
import blue from '@material-ui/core/colors/blue';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dropzone from 'react-dropzone';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';
import _toString from 'lodash/toString';
import _reverse from 'lodash/reverse';

import ModalView from '../../components/ModalView';
import TableBar from '../../components/TableBar';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import DotsLoader from '../../components/DotsLoader';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton, WarningButton } from '../../styles/button';
import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists, isObjectExists, validate } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, convertArrayToObject, convertObjectToArray, replaceAll, doPagination, doArraySearch, getSelectedValue, trimEmail } from '../../helpers/data';
import { isSkip, doSchemaErrorCheck } from '../../helpers/schemas';
import { getMomentTime, getAge } from '../../helpers/date';
import { getAPIErrorMessage } from '../../helpers/action';

import { importMarketingData } from '../../actions/database_marketing'
import { toggleLoader } from '../../actions/global';
import { appChangesMade } from '../../actions/misc';

import { marketingDatabaseSchema } from '../../schemas/marketing_database';

import { PLATFORM_ADMIN_DEFAULT_REGION } from '../../constants';

const ReconWrapper = styled.div`
    h5 { display: none; }
`;

const useStyles = theme => ({
    tooltip: {
        fontSize: '14px',
    },
    emailModal: {
        '& .MuiFilledInput-input.Mui-disabled, & .MuiInputBase-root.Mui-disabled, & .MuiFormLabel-root.Mui-disabled': {
            background: '#f1f1f1',
            color: '#212121'
        }
    }
});

class ImportModal extends React.Component {

    utcOffSet = 8;
    
    state = {
        list: false,
        searchterms: '',
        filterBy: 'all',
        sortBy: 'email-asc',
        perPage: 20,
        page: 1,
        dataLoaded: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { open } = this.props;
        if ( open && !prevProps.open ) {
            this.setState({ list: false });
        } // end - open
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose )
            onClose();
    }

    handleImport = () => {
        const { list } = this.state;
        let error = false;

        if ( !( list && isArrayExists( list ) ) )
            error = 'Please import at least one contact';

        // make sure all the contacts have all the required data
        if ( list && isArrayExists( list ) ) {
            _forEach( list, item => {
                marketingDatabaseSchema.forEach(schema => {
                    if ( !doSchemaErrorCheck( item[schema.id], schema, 'add' ) ) {
                        error = 'Please make sure the columns marked with * are filled';
                    }
                });
            });
        } // end - list

        if ( error ) {
            triggerErrorAlert( error );
        } else {
            this.props.dispatch( importMarketingData({
                contacts: list
            }) );
        } // end - error

    }

    handleFileUpload = (files) => {
        if ( files && files[0] && !_isEmpty( files[0] ) ) {
            Papa.parse(files[0], {
                header: true,
                complete: (results) => {
                    const list = this.compileContactsData(( results && results.data || false ));
                    if ( _size( list ) > 100 ) {
                        triggerErrorAlert("Please upload only 100 contacts at a time");
                    } else {
                        this.setState({ list });
                    } // end - list
                }
            });
        } else {
            triggerErrorAlert('Please upload a valid CSV file');
        }
    }

    compileContactsData = (data) => {
        const { existingList, authData } = this.props;
        let contacts = [];
        if ( data && isArrayExists( data ) ) {
            _forEach( data, item => {
                let contact = { id: shortid.generate() };
                marketingDatabaseSchema.forEach(schema => {
                    if ( !isSkip(schema,'add') ) {
                        switch (schema.id) {
                            case 'region': contact[schema.id] = ( authData && authData.region || PLATFORM_ADMIN_DEFAULT_REGION ); break;
                            case 'email': 
                                contact[schema.id] = ( item && item[schema.id] ? trimEmail( item[schema.id] ) : ( schema.default || '' ) ); 
                                break;
                             case 'newsletter': 
                                contact[schema.id] = ( item && item[schema.id] && trimEmail( item[schema.id] ) === 'yes' ? 'yes' : 'no' ); 
                                break;
                            default:
                                contact[schema.id] = ( item && item[schema.id] ? item[schema.id] : ( schema.default || 0 === schema.default || '' === schema.default ? schema.default : null ) );
                                break;
                        }
                    } // end - schema
                });
                // make sure email wasn't repeat
                if ( contact.email && !_isEmpty( contact.email ) && !_find( existingList, { email: contact.email } ) && !_find( contacts, { email: contact.email } ) ) {
                    contacts.push(contact);
                } // end - contact
            });
        } // end -data
        return contacts;
    }

    reorganizeItems = () => {
        const { list, searchterms, sortBy, perPage, page, filterBy } = this.state;
        let items = ( list && isArrayExists( list ) ? cloneCollections( list ) : [] ),
            total = _size( items );

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
                case 'email-desc':
                    items = _sortBy( items, ['email'] );
                    items = _reverse( items );
                    break;
                case 'email-asc':
                    items = _sortBy( items, ['email'] );
                    break;
            }
        } // end - sortBy

        return { items, total };
    }


    renderTable = (items) => {
        const { classes, regions, authData } = this.props;
        return (
        <>
            <Table 
                items={items}
                emptyCell="No data found"
                cells={[
                    { id: 'region', label: '* Partner', render: (item) => <WrapWord>{getSelectedValue( regions, authData.region, 'id', 'label', '-' )}</WrapWord> },
                    { id: 'email', label: '* Email', render: (item) => <WrapWord>{( item.email || '' )}</WrapWord> },
                    { id: 'name', label: '* Name', render: (item) => <WrapWord>{( item.name || '' )}</WrapWord> },
                    { id: 'phone', label: 'Mobile No', render: (item) => <WrapWord>{( item.phone || '' )}</WrapWord> },
                    { id: 'org', label: 'Organisation', render: (item) => <WrapWord>{( item.org || '' )}</WrapWord> }, 
                    { id: 'city', label: 'City', render: (item) => <WrapWord>{( item.city || '' )}</WrapWord> },
                    { id: 'position', label: 'Position', render: (item) => <WrapWord>{( item.position || '' )}</WrapWord> },
                    { id: 'newsletter', label: 'Subscribe to Newsletter', render: (item) => <WrapWord>{( item.newsletter && _toLower( item.newsletter ) === 'yes' ? 'yes' : 'no' )}</WrapWord> },
                ]}
                noAction={true}
                 />
            <div style={{ paddingTop: '20px', color: '#9e0000', fontStyle: 'italic' }}>* Required field(s).</div>
        </>
        )
    }

    renderCSVtable = () => {
        const { classes } = this.props;
        const { items, total } = this.reorganizeItems();
        return (
        <Grid container spacing={2}>
            <Grid item xs={12} align="right"><InverseButton style={{ padding: '10px 25px' }} onClick={() => this.setState({ list: false })}><i className="fa fa-refresh"></i>Reset</InverseButton></Grid>
            <Grid item xs={12}>{this.renderTable(items)}</Grid>
        </Grid>
        )
    }

    renderContent = () => {
        return (
        <Grid container spacing={2}>
            <Grid item xs={12} align="right"><AInfoLink href="https://firebasestorage.googleapis.com/v0/b/chumbaka-dev-tms.appspot.com/o/file_storage%2Fimport_marketing_database_sample.csv?alt=media&token=0a672c64-1eb2-44fe-b1e3-4ddf7ae06c53" target="_blank" style={{ padding: '10px 25px' }}>Download Example CSV File</AInfoLink></Grid>
            <Grid item xs={12}>
                <Dropzone onDrop={this.handleFileUpload} accept={".csv"}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ style: {
                        display: "flex",
                        height: '200px', 
                        width: '100%', 
                        border: '2px dashed ' + blue['700'],
                        color: blue['700'],
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer'
                    } })}>
                        <input {...getInputProps()} />
                        <div>Drop a CSV file here, or click to select a CSV file to upload</div>
                    </div>
                )}
                </Dropzone>
            </Grid>
        </Grid>
        )
    }

    render() {
        const { open } = this.props;
        const { dataLoaded, list } = this.state;
        return (
        <>
            <ModalView 
                open={open}
                title={"Upload Marketing Database via CSV file"}
                maxWidth="lg"
                onClose={this.handleClose}
                cancelLabel="Close"
                disableBackdrop={true}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                noAction={( list && isArrayExists( list ) ? false : true )}
                actionLabel="Upload"
                doAction={this.handleImport}
                contents={ list ? this.renderCSVtable() : this.renderContent() } />
        </>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles),
    withRouter
)(ImportModal);