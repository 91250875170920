/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _sortBy from "lodash/sortBy";
import _find from "lodash/find";
import _remove from "lodash/remove";
import _toLower from "lodash/toLower";
import _toString from "lodash/toString";

import ReportPreviewStudentData from "./report_preview_student_data";

import ModalView from "../../components/ModalView";
import MediaLibrary from "../../components/MediaLibrary";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormEditor from "../../components/FormEditor";
import EmailShortcodes from "../../components/EmailShortcodes";

import { isArrayExists, isObjectExists } from "../../helpers/validation";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import { cloneCollections, getSelectOptions, convertArrayToObject, convertObjectToArray } from "../../helpers/data";
import { getMomentTime } from "../../helpers/date";

import { triggerClassQuarterlyReport, triggerPreviewEmail } from "../../actions/class_quarterly_reports";

const FieldWrapper = styled.div`
    margin-bottom: 10px;

    h4 {
        color: #212121;
        font-size: 20px;
        font-weight: 700;
        &:after {
            display: block;
            height: 2px;
            width: 50px;
            content: " ";
            background: #212121;
        }
    }

    h5 {
        color: #212121;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 4px;
        text-transform: none;
    }
`;

const useStyles = (theme) => ({
    tooltip: {
        fontSize: "14px",
    },
    emailModal: {
        "& .MuiFilledInput-input.Mui-disabled, & .MuiInputBase-root.Mui-disabled, & .MuiFormLabel-root.Mui-disabled": {
            background: "#f1f1f1",
            color: "#212121",
        },
    },
});

class SendReportModal extends React.Component {
    utcOffSet = 8;

    state = {
        modalData: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { quarterly_report, schedule, class_calendar_id, open } = this.props;
        if (open && !prevProps.open) {
            this.setState({ modalData: this.getInitialModalData(quarterly_report, schedule, class_calendar_id) });
        } // end - open
    }

    handleClose = () => {
        const { onClose } = this.props;
        if (onClose) onClose();
    };

    handleEmailSend = () => {
        const { schedule, onClose, enrollment_status } = this.props;
        const { modalData } = this.state;
        let error = false,
            formData = {
                schedule_id: schedule && schedule.id ? schedule.id : "",
                trainees: this.compileSelectedTrainees(),
                class_calendar_id: modalData && modalData.class_calendar_id ? modalData.class_calendar_id : "",
                email_content: modalData && modalData.email_content ? modalData.email_content : "",
                email_attachments:
                    modalData && modalData.email_attachments && !_isEmpty(modalData.email_attachments) ? modalData.email_attachments : {},
                cc_parent: modalData && modalData.cc_parent && modalData.cc_parent === "yes" ? "yes" : "no",
                cc_principle: modalData && modalData.cc_principle && modalData.cc_principle === "yes" ? "yes" : "no",
                cc_coordinator: modalData && modalData.cc_coordinator && modalData.cc_coordinator === "yes" ? "yes" : "no",
            };

        // check for error
        if (!(formData && formData.schedule_id && !_isEmpty(formData.schedule_id))) error = "Unknown Error - Missing Schedule ID";

        if (!(formData && formData.trainees && isArrayExists(formData.trainees))) error = "No student selected";

        if (!(formData && formData.class_calendar_id && !_isEmpty(formData.class_calendar_id))) error = "Please select a report date";

        if (error) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(triggerClassQuarterlyReport(formData, { schedule_id: schedule.id, enrollment_status }));
        } // end - error
    };

    handleFormUpdate = (newValue, key, status) => {
        const { modalData } = this.state;
        let newData = modalData ? cloneCollections(modalData) : {};
        newData[key] = newValue;
        this.setState({ modalData: newData });
    };

    compileSelectedTrainees = () => {
        const { modalData } = this.state;
        let list = this.getSelectedTrainees(),
            checker = modalData && modalData.class_calendar_id ? modalData.class_calendar_id : "";
        if (list && isArrayExists(list) && !_isEmpty(checker)) {
            _forEach(list, (trainee, index) => {
                // if no quarterly report yet
                if (!(trainee && trainee.quarterly_reports && isArrayExists(trainee.quarterly_reports))) list[index].quarterly_reports = [];

                // add new report timestamp
                list[index].quarterly_reports.push({
                    id: checker,
                    sent_on: moment().utcOffset(this.utcOffSet).valueOf(),
                });
            });
        } // end - list
        return list;
    };

    getInitialModalData = (quarterly_report, schedule, class_calendar_id) => {
        return {
            id: schedule.id || "",
            class_calendar_id,
            email_content: quarterly_report && quarterly_report.email_content ? quarterly_report.email_content : "",
            email_attachments:
                quarterly_report && quarterly_report.email_attachments && !_isEmpty(quarterly_report.email_attachments)
                    ? quarterly_report.email_attachments
                    : {},
            cc_parent: "yes",
            cc_principle: "yes",
            cc_coordinator: "yes",
        };
    };

    getSelectedTrainees = () => {
        const { schedule, class_calendar_id, trainees, studentsSelected, report_type } = this.props;
        const { modalData } = this.state;
        let list = [],
            checker = modalData && modalData.class_calendar_id ? modalData.class_calendar_id : "";

        if (studentsSelected && isArrayExists(studentsSelected) && trainees && isArrayExists(trainees)) {
            _forEach(studentsSelected, (selected) => {
                let trainee = _find(trainees, { id: selected.id });
                // make sure quarterly report wasn't already issued for the selected class_calendar_id
                if (
                    trainee.email &&
                    !_isEmpty(trainee.email) &&
                    !(
                        trainee &&
                        trainee.quarterly_reports &&
                        isArrayExists(trainee.quarterly_reports) &&
                        !_isEmpty(checker) &&
                        _find(trainee.quarterly_reports, { id: checker })
                    )
                )
                    list.push({ id: trainee.id, email: trainee.email });
            });
        } // end - studentsSelected

        return list;
    };

    getStudentsEmails = () => {
        const students = this.getSelectedTrainees();
        let emails = "";
        if (students && isArrayExists(students)) {
            _forEach(students, (student) => {
                emails += (!_isEmpty(emails) ? "\n" : "") + (student.email || "");
            });
        } // end - students
        return emails;
    };

    getCalendarOptions = () => {
        const { schedule } = this.props;
        let options = [{ value: "", label: "Select an Option" }];
        if (schedule && schedule.class_calendar && isArrayExists(schedule.class_calendar)) {
            _forEach(_sortBy(schedule.class_calendar, ["start_date"]), (event) => {
                if (event.quarterly_report && event.quarterly_report === "yes")
                    options.push({ value: event.id, label: event.start_date ? getMomentTime(event.start_date, "DD-MMM-YY") : event.id });
            });
        } // end - schedule.class_calendar
        return options;
    };

    renderCheckBox = (label, key, disabled) => {
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={this.state.modalData[key] && this.state.modalData[key] === "yes" ? true : false}
                        onChange={(event) => {
                            if (!disabled) {
                                this.handleFormUpdate(event.target.checked ? "yes" : "no", key);
                            }
                        }}
                        value={this.state.modalData[key]}
                        color="primary"
                    />
                }
                label={label}
            />
        );
    };

    renderContent = () => {
        const { quarterly_report, schedule, classes, report_type, trainees } = this.props;
        const { modalData } = this.state;
        return (
            <div className={classes.emailModal}>
                <Typography variant="h5" style={{ marginBottom: "20px" }}>
                    Please confirm if everything is correct:
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormSelect
                            label="Select Report Date"
                            tooltip={
                                <>
                                    JUST A PLACEHOLDER
                                    <br />
                                    <br />
                                    The Report Date selected will only be reflected internally here on SMS, not anywhere on student’s report.
                                </>
                            }
                            name="class_calendar_id"
                            disabled={report_type && report_type === "single" ? true : false}
                            value={modalData && modalData.class_calendar_id ? modalData.class_calendar_id : ""}
                            options={this.getCalendarOptions()}
                            onChange={this.handleFormUpdate}
                        />
                        <FormInput label="Students' Emails" multiline={true} row={9} disabled={true} value={this.getStudentsEmails()} />
                        <ReportPreviewStudentData
                            emails={this.getStudentsEmails()}
                            selectedStudents={this.getSelectedTrainees()}
                            schedule={schedule}
                            trainees={trainees}
                            quarterly_report={quarterly_report}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <div style={{ border: "2px solid #212121", marginBottom: "2px", padding: "10px 20px" }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} sm={1}>
                                    CC:
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    {this.renderCheckBox("Parent", "cc_parent")}
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    {this.renderCheckBox("Principal", "cc_principle")}
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    {this.renderCheckBox("Coordinator", "cc_coordinator")}
                                </Grid>
                            </Grid>
                        </div>
                        <MediaLibrary
                            attachable={true}
                            attachments={
                                modalData.email_attachments && isObjectExists(modalData.email_attachments)
                                    ? convertObjectToArray(modalData.email_attachments)
                                    : []
                            }
                            onAttachmentUpdate={(medias) => this.handleFormUpdate(medias, "email_attachments")}
                        />
                        <FormInput
                            label="Subject Line"
                            name="email_title"
                            value={quarterly_report && quarterly_report.email_title ? quarterly_report.email_title : ""}
                            disabled={true}
                        />
                        <FormEditor
                            id={"confirmation_email"}
                            name="email_content"
                            value={modalData && modalData.email_content && !_isEmpty(modalData.email_content) ? modalData.email_content : ""}
                            // disabled={true}
                            height={600}
                            onChange={this.handleFormUpdate}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    };

    render() {
        const { open } = this.props;
        return (
            <>
                <ModalView
                    open={open}
                    title="Send Report"
                    maxWidth="lg"
                    onClose={this.handleClose}
                    disableBackdrop={true}
                    disableAutoFocus={true}
                    disableEnforceFocus={true}
                    actionLabel="Send Now"
                    doAction={this.handleEmailSend}
                    contents={this.renderContent()}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
    };
};

export default compose(connect(mapStateToProps), withStyles(useStyles), withRouter)(SendReportModal);
