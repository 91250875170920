export const shortcodesConfirmationEmail = [
  {
    id: "#program_name#",
    label: "Program Name",
    schema: "schedule",
    key: "program_name",
    category: "general",
  },
  {
    id: "#program_overview#",
    label: "Program Name",
    schema: "program",
    key: "web_overview",
    category: "general",
  },
  {
    id: "#course_name#",
    label: "Course Name",
    schema: "schedule",
    key: "course_name",
    category: "general",
  },
  {
    id: "#course_outline#",
    label: "Course Outline",
    schema: "course",
    key: "web_what_you_learn",
    category: "general",
  },
  {
    id: "#class_name#",
    label: "Class Name",
    schema: "schedule",
    key: "class_name",
    category: "general",
  },
  {
    id: "#registration_link#",
    label: "Registration Link",
    schema: "schedule",
    key: "custom",
    category: "general",
  },
  {
    id: "#class_schedule_link#",
    label: "Class Schedule Link",
    schema: "schedule",
    key: "custom",
    category: "general",
  },
  {
    id: "#class_date_time#",
    label: "Class Date & Time",
    schema: "schedule",
    key: "custom",
    category: "general",
  },
  {
    id: "#class_first_day#",
    label: "First Day of Class",
    schema: "schedule",
    key: "custom",
    category: "general",
  },
  {
    id: "#price_per_pax#",
    label: "Price Per Pax",
    schema: "schedule",
    key: "price_per_pax",
    category: "general",
  },
  {
    id: "#class_venue#",
    label: "Venue",
    schema: "training_location",
    key: "custom",
    category: "general",
  },
  {
    id: "#guide_to_venue#",
    label: "Guide To Venue",
    schema: "training_location",
    key: "guide_to_venue",
    category: "general",
  },
  {
    id: "#coordinator_name#",
    label: "Coordinator Name",
    schema: "coordinator",
    key: "name",
    category: "general",
  },
  {
    id: "#coordinator_email#",
    label: "Coordinator Email",
    schema: "coordinator",
    key: "email",
    category: "general",
  },
  {
    id: "#coordinator_contact#",
    label: "Coordinator Contact No",
    schema: "coordinator",
    key: "mobile",
    category: "general",
  },
  {
    id: "#principal_name#",
    label: "Principal Name",
    schema: "principle",
    key: "name",
    category: "general",
  },
  {
    id: "#principal_email#",
    label: "Principal Email",
    schema: "principle",
    key: "email",
    category: "general",
  },
  {
    id: "#principal_contact#",
    label: "Principal Contact No",
    schema: "principle",
    key: "mobile",
    category: "general",
  },
  {
    id: "#licensee_payer_name#",
    label: "Partner Payer Name",
    schema: "partner",
    key: "finance_payee_name",
    category: "general",
  },
  {
    id: "#licensee_bank_name#",
    label: "Partner bank Name",
    schema: "partner",
    key: "finance_bank_name",
    category: "general",
  },
  {
    id: "#licensee_bank_account#",
    label: "Partner Bank Account No",
    schema: "partner",
    key: "finance_bank_account_no",
    category: "general",
  },
  { id: "#payment_link#", label: "Payment Link", category: "student" },
  { id: "#payment_fee#", label: "Payment Fee", category: "student" },
  {
    id: "#student_name#",
    label: "Student Name",
    schema: "trainee",
    key: "name",
    category: "student",
  },
  {
    id: "#student_email#",
    label: "Student Email",
    schema: "trainee",
    key: "email",
    category: "student",
  },
  {
    id: "#student_password#",
    label: "Student Temporary Password",
    category: "student",
  },
  {
    id: "#parent_name#",
    label: "Parent Name",
    schema: "trainee",
    key: "parent_name",
    category: "student",
  },
  {
    id: "#parent_email#",
    label: "Parent Email",
    schema: "trainee",
    key: "parent_email",
    category: "student",
  },
  {
    id: "#parent_phone#",
    label: "Parent Email",
    schema: "trainee",
    key: "parent_phone",
    category: "student",
  },
  {
    id: "#student_calendar#",
    label: "Student Calendar URL",
    category: "student",
  },
];

export const shortcodesTrialEmail = [
  {
    id: "#program_name#",
    label: "Program Name",
    schema: "schedule",
    key: "program_name",
    category: "general",
  },
  {
    id: "#course_name#",
    label: "Course Name",
    schema: "schedule",
    key: "course_name",
    category: "general",
  },
  {
    id: "#class_name#",
    label: "Class Name",
    schema: "schedule",
    key: "class_name",
    category: "general",
  },
  {
    id: "#registration_link#",
    label: "Registration Link",
    schema: "schedule",
    key: "custom",
    category: "general",
  },
  {
    id: "#class_venue#",
    label: "Venue",
    schema: "training_location",
    key: "custom",
    category: "general",
  },
  {
    id: "#guide_to_venue#",
    label: "Guide To Venue",
    schema: "training_location",
    key: "guide_to_venue",
    category: "general",
  },
  {
    id: "#coordinator_name#",
    label: "Coordinator Name",
    schema: "coordinator",
    key: "name",
    category: "general",
  },
  {
    id: "#coordinator_email#",
    label: "Coordinator Email",
    schema: "coordinator",
    key: "email",
    category: "general",
  },
  {
    id: "#coordinator_contact#",
    label: "Coordinator Contact No",
    schema: "coordinator",
    key: "mobile",
    category: "general",
  },
  {
    id: "#principal_name#",
    label: "Principal Name",
    schema: "principle",
    key: "name",
    category: "general",
  },
  {
    id: "#principal_email#",
    label: "Principal Email",
    schema: "principle",
    key: "email",
    category: "general",
  },
  {
    id: "#principal_contact#",
    label: "Principal Contact No",
    schema: "principle",
    key: "mobile",
    category: "general",
  },
  {
    id: "#student_name#",
    label: "Student Name",
    schema: "trainee",
    key: "name",
    category: "student",
  },
  {
    id: "#student_email#",
    label: "Student Email",
    schema: "trainee",
    key: "email",
    category: "student",
  },
  {
    id: "#parent_name#",
    label: "Parent Name",
    schema: "trainee",
    key: "parent_name",
    category: "student",
  },
  {
    id: "#parent_email#",
    label: "Parent Email",
    schema: "trainee",
    key: "parent_email",
    category: "student",
  },
  {
    id: "#parent_phone#",
    label: "Parent Email",
    schema: "trainee",
    key: "parent_phone",
    category: "student",
  },
];

export const shortcodesReminderEmail = [
  {
    id: "#program_name#",
    label: "Program Name",
    schema: "schedule",
    key: "program_name",
    category: "general",
  },
  {
    id: "#program_overview#",
    label: "Program Name",
    schema: "program",
    key: "web_overview",
    category: "general",
  },
  {
    id: "#course_name#",
    label: "Course Name",
    schema: "schedule",
    key: "course_name",
    category: "general",
  },
  {
    id: "#course_outline#",
    label: "Course Outline",
    schema: "course",
    key: "web_what_you_learn",
    category: "general",
  },
  {
    id: "#class_name#",
    label: "Class Name",
    schema: "schedule",
    key: "class_name",
    category: "general",
  },
  {
    id: "#registration_link#",
    label: "Registration Link",
    schema: "schedule",
    key: "custom",
    category: "general",
  },
  {
    id: "#class_date_time#",
    label: "Class Date & Time",
    schema: "schedule",
    key: "custom",
    category: "general",
  },
  {
    id: "#class_first_day#",
    label: "First Day of Class",
    schema: "schedule",
    key: "custom",
    category: "general",
  },
  {
    id: "#price_per_pax#",
    label: "Price Per Pax",
    schema: "schedule",
    key: "price_per_pax",
    category: "general",
  },
  {
    id: "#class_venue#",
    label: "Venue",
    schema: "training_location",
    key: "custom",
    category: "general",
  },
  {
    id: "#coordinator_name#",
    label: "Coordinator Name",
    schema: "coordinator",
    key: "name",
    category: "general",
  },
  {
    id: "#coordinator_email#",
    label: "Coordinator Email",
    schema: "coordinator",
    key: "email",
    category: "general",
  },
  {
    id: "#coordinator_contact#",
    label: "Coordinator Contact No",
    schema: "coordinator",
    key: "mobile",
    category: "general",
  },
  {
    id: "#principal_name#",
    label: "Principal Name",
    schema: "principle",
    key: "name",
    category: "general",
  },
  {
    id: "#principal_email#",
    label: "Principal Email",
    schema: "principle",
    key: "email",
    category: "general",
  },
  {
    id: "#principal_contact#",
    label: "Principal Contact No",
    schema: "principle",
    key: "mobile",
    category: "general",
  },
  {
    id: "#licensee_payer_name#",
    label: "Partner Payer Name",
    schema: "partner",
    key: "finance_payee_name",
    category: "general",
  },
  {
    id: "#licensee_bank_name#",
    label: "Partner bank Name",
    schema: "partner",
    key: "finance_bank_name",
    category: "general",
  },
  {
    id: "#licensee_bank_account#",
    label: "Partner Bank Account No",
    schema: "partner",
    key: "finance_bank_account_no",
    category: "general",
  },
  { id: "#payment_link#", label: "Payment Link", category: "student" },
  { id: "#payment_fee#", label: "Payment Fee", category: "student" },
  {
    id: "#student_name#",
    label: "Student Name",
    schema: "trainee",
    key: "name",
    category: "student",
  },
  {
    id: "#student_email#",
    label: "Student Email",
    schema: "trainee",
    key: "email",
    category: "student",
  },
  {
    id: "#parent_name#",
    label: "Parent Name",
    schema: "trainee",
    key: "parent_name",
    category: "student",
  },
  {
    id: "#parent_email#",
    label: "Parent Email",
    schema: "trainee",
    key: "parent_email",
    category: "student",
  },
  {
    id: "#parent_phone#",
    label: "Parent Email",
    schema: "trainee",
    key: "parent_phone",
    category: "student",
  },
  {
    id: "#student_calendar#",
    label: "Student Calendar URL",
    category: "student",
  },
];

export const shortcodesInvoiceEmail = [
  {
    id: "#program_name#",
    label: "Program Name",
    schema: "schedule",
    key: "program_name",
    category: "general",
  },
  {
    id: "#course_name#",
    label: "Course Name",
    schema: "schedule",
    key: "course_name",
    category: "general",
  },
  {
    id: "#price_per_pax#",
    label: "Price Per Pax",
    schema: "schedule",
    key: "price_per_pax",
    category: "general",
  },
  {
    id: "#coordinator_name#",
    label: "Coordinator Name",
    schema: "coordinator",
    key: "name",
    category: "general",
  },
  {
    id: "#coordinator_email#",
    label: "Coordinator Email",
    schema: "coordinator",
    key: "email",
    category: "general",
  },
  {
    id: "#coordinator_contact#",
    label: "Coordinator Contact No",
    schema: "coordinator",
    key: "mobile",
    category: "general",
  },
  {
    id: "#principal_name#",
    label: "Principal Name",
    schema: "principle",
    key: "name",
    category: "general",
  },
  {
    id: "#principal_email#",
    label: "Principal Email",
    schema: "principle",
    key: "email",
    category: "general",
  },
  {
    id: "#principal_contact#",
    label: "Principal Contact No",
    schema: "principle",
    key: "mobile",
    category: "general",
  },
  {
    id: "#licensee_payer_name#",
    label: "Partner Payer Name",
    schema: "partner",
    key: "finance_payee_name",
    category: "general",
  },
  {
    id: "#licensee_bank_name#",
    label: "Partner bank Name",
    schema: "partner",
    key: "finance_bank_name",
    category: "general",
  },
  {
    id: "#licensee_bank_account#",
    label: "Partner Bank Account No",
    schema: "partner",
    key: "finance_bank_account_no",
    category: "general",
  },
  { id: "#payment_link#", label: "Payment Link", category: "student" },
  { id: "#payment_fee#", label: "Payment Fee", category: "student" },
  {
    id: "#student_name#",
    label: "Student Name",
    schema: "trainee",
    key: "name",
    category: "student",
  },
  {
    id: "#student_email#",
    label: "Student Email",
    schema: "trainee",
    key: "email",
    category: "student",
  },
  {
    id: "#parent_name#",
    label: "Parent Name",
    schema: "trainee",
    key: "parent_name",
    category: "student",
  },
  {
    id: "#parent_email#",
    label: "Parent Email",
    schema: "trainee",
    key: "parent_email",
    category: "student",
  },
  {
    id: "#parent_phone#",
    label: "Parent Email",
    schema: "trainee",
    key: "parent_phone",
    category: "student",
  },
];

export const shortcodesPaymentReminderEmail = [
  {
    id: "#program_name#",
    label: "Program Name",
    schema: "schedule",
    key: "program_name",
    category: "general",
  },
  {
    id: "#course_name#",
    label: "Course Name",
    schema: "schedule",
    key: "course_name",
    category: "general",
  },
  {
    id: "#price_per_pax#",
    label: "Price Per Pax",
    schema: "schedule",
    key: "price_per_pax",
    category: "general",
  },
  {
    id: "#coordinator_name#",
    label: "Coordinator Name",
    schema: "coordinator",
    key: "name",
    category: "general",
  },
  {
    id: "#coordinator_email#",
    label: "Coordinator Email",
    schema: "coordinator",
    key: "email",
    category: "general",
  },
  {
    id: "#coordinator_contact#",
    label: "Coordinator Contact No",
    schema: "coordinator",
    key: "mobile",
    category: "general",
  },
  {
    id: "#principal_name#",
    label: "Principal Name",
    schema: "principle",
    key: "name",
    category: "general",
  },
  {
    id: "#principal_email#",
    label: "Principal Email",
    schema: "principle",
    key: "email",
    category: "general",
  },
  {
    id: "#principal_contact#",
    label: "Principal Contact No",
    schema: "principle",
    key: "mobile",
    category: "general",
  },
  {
    id: "#licensee_payer_name#",
    label: "Partner Payer Name",
    schema: "partner",
    key: "finance_payee_name",
    category: "general",
  },
  {
    id: "#licensee_bank_name#",
    label: "Partner bank Name",
    schema: "partner",
    key: "finance_bank_name",
    category: "general",
  },
  {
    id: "#licensee_bank_account#",
    label: "Partner Bank Account No",
    schema: "partner",
    key: "finance_bank_account_no",
    category: "general",
  },
  { id: "#payment_link#", label: "Payment Link", category: "student" },
  { id: "#payment_fee#", label: "Payment Fee", category: "student" },
  {
    id: "#student_name#",
    label: "Student Name",
    schema: "trainee",
    key: "name",
    category: "student",
  },
  {
    id: "#student_email#",
    label: "Student Email",
    schema: "trainee",
    key: "email",
    category: "student",
  },
  {
    id: "#parent_name#",
    label: "Parent Name",
    schema: "trainee",
    key: "parent_name",
    category: "student",
  },
  {
    id: "#parent_email#",
    label: "Parent Email",
    schema: "trainee",
    key: "parent_email",
    category: "student",
  },
  {
    id: "#parent_phone#",
    label: "Parent Email",
    schema: "trainee",
    key: "parent_phone",
    category: "student",
  },
];

export const shortcodesRegistrationReceiptEmail = [
  {
    id: "#program_name#",
    label: "Program Name",
    schema: "schedule",
    key: "program_name",
    category: "general",
  },
  {
    id: "#course_name#",
    label: "Course Name",
    schema: "schedule",
    key: "course_name",
  },
  {
    id: "#class_name#",
    label: "Class Name",
    schema: "schedule",
    key: "class_name",
  },
  { id: "#registration_link#", label: "Registration Link" },
  {
    id: "#coordinator_name#",
    label: "Coordinator Name",
    schema: "coordinator",
    key: "name",
  },
  {
    id: "#coordinator_email#",
    label: "Coordinator Email",
    schema: "coordinator",
    key: "email",
  },
  {
    id: "#coordinator_contact#",
    label: "Coordinator Contact No",
    schema: "coordinator",
    key: "mobile",
  },
  {
    id: "#student_name#",
    label: "Student Name",
    schema: "trainee",
    key: "name",
  },
  {
    id: "#student_email#",
    label: "Student Email",
    schema: "trainee",
    key: "email",
  },
  {
    id: "#student_gender#",
    label: "Student Gender",
    schema: "trainee",
    key: "gender",
  },
  { id: "#student_age#", label: "Student Age", schema: "trainee", key: "age" },
  {
    id: "#student_school#",
    label: "Student School",
    schema: "trainee",
    key: "org",
  },
  {
    id: "#student_medical_condition#",
    label: "Student Medical Condition",
    schema: "trainee",
    key: "medical_condition",
  },
  {
    id: "#parent_name#",
    label: "Parent Name",
    schema: "trainee",
    key: "parent_name",
  },
  {
    id: "#parent_email#",
    label: "Parent Email",
    schema: "trainee",
    key: "parent_email",
  },
  {
    id: "#parent_phone#",
    label: "Parent Contact No",
    schema: "trainee",
    key: "parent_phone",
  },
  {
    id: "#parent_city#",
    label: "Parent Residence City",
    schema: "trainee",
    key: "parent_city",
  },
];

export const shortcodesLoginReminderEmail = [
  {
    id: "#program_name#",
    label: "Program Name",
    schema: "schedule",
    key: "program_name",
    category: "general",
  },
  {
    id: "#course_name#",
    label: "Course Name",
    schema: "schedule",
    key: "course_name",
    category: "general",
  },
  {
    id: "#coordinator_name#",
    label: "Coordinator Name",
    schema: "coordinator",
    key: "name",
    category: "general",
  },
  {
    id: "#coordinator_email#",
    label: "Coordinator Email",
    schema: "coordinator",
    key: "email",
    category: "general",
  },
  {
    id: "#coordinator_contact#",
    label: "Coordinator Contact No",
    schema: "coordinator",
    key: "mobile",
    category: "general",
  },
  {
    id: "#principal_name#",
    label: "Principal Name",
    schema: "principle",
    key: "name",
    category: "general",
  },
  {
    id: "#principal_email#",
    label: "Principal Email",
    schema: "principle",
    key: "email",
    category: "general",
  },
  {
    id: "#principal_contact#",
    label: "Principal Contact No",
    schema: "principle",
    key: "mobile",
    category: "general",
  },
  {
    id: "#student_name#",
    label: "Student Name",
    schema: "trainee",
    key: "name",
    category: "student",
  },
  {
    id: "#student_email#",
    label: "Student Email",
    schema: "trainee",
    key: "email",
    category: "student",
  },
  {
    id: "#parent_name#",
    label: "Parent Name",
    schema: "trainee",
    key: "parent_name",
    category: "student",
  },
  {
    id: "#parent_email#",
    label: "Parent Email",
    schema: "trainee",
    key: "parent_email",
    category: "student",
  },
  {
    id: "#parent_phone#",
    label: "Parent Email",
    schema: "trainee",
    key: "parent_phone",
    category: "student",
  },
];

export const shortcodesWithDrawalNoticeEmail = [
  {
    id: "#program_name#",
    label: "Program Name",
    schema: "schedule",
    key: "program_name",
    category: "general",
  },
  {
    id: "#course_name#",
    label: "Course Name",
    schema: "schedule",
    key: "course_name",
    category: "general",
  },
  {
    id: "#coordinator_name#",
    label: "Coordinator Name",
    schema: "coordinator",
    key: "name",
    category: "general",
  },
  {
    id: "#coordinator_email#",
    label: "Coordinator Email",
    schema: "coordinator",
    key: "email",
    category: "general",
  },
  {
    id: "#coordinator_contact#",
    label: "Coordinator Contact No",
    schema: "coordinator",
    key: "mobile",
    category: "general",
  },
  {
    id: "#principal_name#",
    label: "Principal Name",
    schema: "principle",
    key: "name",
    category: "general",
  },
  {
    id: "#principal_email#",
    label: "Principal Email",
    schema: "principle",
    key: "email",
    category: "general",
  },
  {
    id: "#principal_contact#",
    label: "Principal Contact No",
    schema: "principle",
    key: "mobile",
    category: "general",
  },
  {
    id: "#student_name#",
    label: "Student Name",
    schema: "trainee",
    key: "name",
    category: "student",
  },
  {
    id: "#student_email#",
    label: "Student Email",
    schema: "trainee",
    key: "email",
    category: "student",
  },
  {
    id: "#parent_name#",
    label: "Parent Name",
    schema: "trainee",
    key: "parent_name",
    category: "student",
  },
  {
    id: "#parent_email#",
    label: "Parent Email",
    schema: "trainee",
    key: "parent_email",
    category: "student",
  },
  {
    id: "#parent_phone#",
    label: "Parent Email",
    schema: "trainee",
    key: "parent_phone",
    category: "student",
  },
  {
    id: "#backpack_url#",
    label: "Backpack URL",
    schema: "student",
    key: "custom",
    category: "student",
  },
];

export const shortcodesQuarterlyReportEmail = [
  {
    id: "#program_name#",
    label: "Program Name",
    schema: "schedule",
    key: "program_name",
    category: "general",
  },
  {
    id: "#course_name#",
    label: "Course Name",
    schema: "schedule",
    key: "course_name",
  },
  {
    id: "#course_type#",
    label: "Course Type / Program Delivery Type",
    schema: "schedule",
    key: "course_type_label",
  },
  {
    id: "#coordinator_name#",
    label: "Coordinator Name",
    schema: "coordinator",
    key: "name",
  },
  {
    id: "#coordinator_email#",
    label: "Coordinator Email",
    schema: "coordinator",
    key: "email",
  },
  {
    id: "#coordinator_contact#",
    label: "Coordinator Contact No",
    schema: "coordinator",
    key: "mobile",
  },
  {
    id: "#principal_name#",
    label: "Principal Name",
    schema: "principle",
    key: "name",
  },
  {
    id: "#principal_email#",
    label: "Principal Email",
    schema: "principle",
    key: "email",
  },
  {
    id: "#principal_contact#",
    label: "Principal Contact No",
    schema: "principle",
    key: "mobile",
  },
  {
    id: "#student_name#",
    label: "Student Name",
    schema: "trainee",
    key: "name",
  },
  {
    id: "#student_email#",
    label: "Student Email",
    schema: "trainee",
    key: "email",
  },
  {
    id: "#student_gender#",
    label: "Student Gender",
    schema: "trainee",
    key: "gender",
  },
  { id: "#student_age#", label: "Student Age", schema: "trainee", key: "age" },
  {
    id: "#student_school#",
    label: "Student School",
    schema: "trainee",
    key: "school",
  },
  {
    id: "#student_medical_condition#",
    label: "Student Medical Condition",
    schema: "trainee",
    key: "medical_condition",
  },
  {
    id: "#student_attendances#",
    label: "Student Attendances",
    schema: "trainee",
    key: "custom",
  },
  {
    id: "#student_backpack#",
    label: "Student Backpack URL",
    schema: "user_student",
    key: "custom",
  },
  {
    id: "#student_personal_portfolio#",
    label: "Student Personal Portfolio URL",
    schema: "user_student",
    key: "custom",
  },
  {
    id: "#mentor_general_comments#",
    label: "Mentor's General Comments",
    schema: "trainee",
    key: "mentor_comments",
  },
  {
    id: "#parent_name#",
    label: "Parent Name",
    schema: "trainee",
    key: "parent_name",
  },
  {
    id: "#parent_email#",
    label: "Parent Email",
    schema: "trainee",
    key: "parent_email",
  },
  {
    id: "#parent_phone#",
    label: "Parent Contact No",
    schema: "trainee",
    key: "parent_phone",
  },
  {
    id: "#parent_city#",
    label: "Parent Residence City",
    schema: "trainee",
    key: "parent_city",
  },
];

export const shortcodesShowcaseEmail = [
  { id: "#course_name#", label: "Course Name", schema: "course", key: "name" },
  {
    id: "#course_code#",
    label: "Course Code",
    schema: "course",
    key: "course_code",
  },
  { id: "#student_name#", label: "Student Name", schema: "user", key: "name" },
  {
    id: "#student_email#",
    label: "Student Email",
    schema: "user",
    key: "email",
  },
  {
    id: "#student_gender#",
    label: "Student Gender",
    schema: "user",
    key: "gender",
  },
  {
    id: "#showcase_project_name#",
    label: "Showcase Project Name",
    schema: "student_showcase",
    key: "custom",
  },
  {
    id: "#showcase_project_link#",
    label: "Showcase Project Link",
    schema: "student_showcase",
    key: "custom",
  },
  {
    id: "#showcase_project_evaluation#",
    label: "Showcase Project Evaluation",
    schema: "student_showcase",
    key: "custom",
  },
  {
    id: "#showcase_project_feedback#",
    label: "Showcase Project Feedback",
    schema: "student_showcase",
    key: "custom",
  },
  {
    id: "#badge_level#",
    label: "Badge Level",
    schema: "badge",
    key: "type_label",
  },
  {
    id: "#parent_name#",
    label: "Parent Name",
    schema: "user",
    key: "parent_name",
  },
  {
    id: "#parent_email#",
    label: "Parent Email",
    schema: "user",
    key: "parent_email",
  },
  {
    id: "#parent_phone#",
    label: "Parent Contact No",
    schema: "user",
    key: "parent_phone",
  },
  {
    id: "#parent_city#",
    label: "Parent Residence City",
    schema: "user",
    key: "parent_city",
  },
  {
    id: "#badge_image_url#",
    label: "Badge Image URL",
    schema: "user_badge",
    key: "image",
  },
  { id: "#cert_url#", label: "Cert URL", schema: "user_badge", key: "custom" },
  {
    id: "#badge_url#",
    label: "Badge URL",
    schema: "user_badge",
    key: "custom",
  },
  {
    id: "#backpack_url#",
    label: "Backpack URL",
    schema: "user_badge",
    key: "custom",
  },
  {
    id: "#google_map_link#",
    label: "Google Map Link",
    schema: "training_location",
    key: "map_link",
  },
];

export const shortcodesShowcaseProjectEmail = [
  { id: "#course_name#", label: "Course Name", schema: "course", key: "name" },
  {
    id: "#course_code#",
    label: "Course Code",
    schema: "course",
    key: "course_code",
  },
  { id: "#student_name#", label: "Student Name", schema: "user", key: "name" },
  {
    id: "#student_email#",
    label: "Student Email",
    schema: "user",
    key: "email",
  },
  {
    id: "#student_gender#",
    label: "Student Gender",
    schema: "user",
    key: "gender",
  },
  {
    id: "#showcase_project_name#",
    label: "Showcase Project Name",
    schema: "student_showcase",
    key: "custom",
  },
  {
    id: "#showcase_project_link#",
    label: "Showcase Project Link",
    schema: "student_showcase",
    key: "custom",
  },
  {
    id: "#showcase_project_evaluation#",
    label: "Showcase Project Evaluation",
    schema: "student_showcase",
    key: "custom",
  },
  {
    id: "#showcase_project_feedback#",
    label: "Showcase Project Feedback",
    schema: "student_showcase",
    key: "custom",
  },
  {
    id: "#parent_name#",
    label: "Parent Name",
    schema: "user",
    key: "parent_name",
  },
  {
    id: "#parent_email#",
    label: "Parent Email",
    schema: "user",
    key: "parent_email",
  },
  {
    id: "#parent_phone#",
    label: "Parent Contact No",
    schema: "user",
    key: "parent_phone",
  },
  {
    id: "#parent_city#",
    label: "Parent Residence City",
    schema: "user",
    key: "parent_city",
  },
];

export const shortcodesMentorLMSEmail = [
  {
    id: "#mentor_name#",
    label: "Mentor Name",
    schema: "mentor",
    key: "name",
    category: "mentor",
  },
  {
    id: "#mentor_email#",
    label: "Mentor Email",
    schema: "mentor",
    key: "email",
    category: "mentor",
  },
  {
    id: "#mentor_password#",
    label: "Mentor Temporary Password",
    category: "mentor",
  },
];

export const shortcodesStudentLMSEmail = [
  {
    id: "#student_name#",
    label: "Student Name",
    schema: "student",
    key: "name",
    category: "student",
  },
  {
    id: "#student_email#",
    label: "Student Email",
    schema: "student",
    key: "email",
    category: "student",
  },
  {
    id: "#student_password#",
    label: "Student Temporary Password",
    category: "student",
  },
];

export const shortcodesParentLMSEmail = [
  {
    id: "#parent_name#",
    label: "Parent Name",
    schema: "student",
    key: "parent_name",
    category: "student",
  },
  {
    id: "#parent_email#",
    label: "Parent Email",
    schema: "student",
    key: "parent_email",
    category: "student",
  },
  {
    id: "#parent_password#",
    label: "Parent Temporary Password",
    category: "student",
  },
  {
    id: "#teacher_name#",
    label: "Teacher Name",
    schema: "student",
    key: "parent_name",
    category: "student",
  },
  {
    id: "#teacher_email#",
    label: "Teacher Email",
    schema: "student",
    key: "parent_email",
    category: "student",
  },
  {
    id: "#teacher_password#",
    label: "Teacher Temporary Password",
    category: "student",
  },
  {
    id: "#student_name#",
    label: "Student Name",
    schema: "student",
    key: "name",
    category: "student",
  },
  {
    id: "#student_email#",
    label: "Student Email",
    schema: "student",
    key: "email",
    category: "student",
  },
  {
    id: "#student_school#",
    label: "Student School",
    schema: "student",
    key: "org",
    category: "student",
  },
];

export const shortcodesBadgeOneOffIssuedEmail = [
  {
    id: "#student_name#",
    label: "Student Name",
    schema: "user_badge",
    key: "user_name",
  },
  {
    id: "#student_email#",
    label: "Student Email",
    schema: "user_badge",
    key: "user_email",
  },
  {
    id: "#badge_name#",
    label: "Badge Name",
    schema: "user_badge",
    key: "name",
  },
  {
    id: "#badge_level#",
    label: "Badge Level",
    schema: "user_badge",
    key: "type_label",
  },
  {
    id: "#badge_image_url#",
    label: "Badge Image URL",
    schema: "user_badge",
    key: "image",
  },
  {
    id: "#badge_description#",
    label: "Badge Description",
    schema: "user_badge",
    key: "description",
  },
  { id: "#cert_url#", label: "Cert URL", schema: "user_badge", key: "custom" },
  {
    id: "#badge_url#",
    label: "Badge URL",
    schema: "user_badge",
    key: "custom",
  },
  {
    id: "#backpack_url#",
    label: "Backpack URL",
    schema: "user_badge",
    key: "custom",
  },
];

export const shortcodesShowcaseOutstandingTasksStudentEmail = [
  {
    id: "#student_name#",
    label: "Student Name",
    schema: "trainee",
    key: "name",
  },
  {
    id: "#student_email#",
    label: "Student Email",
    schema: "trainee",
    key: "email",
  },
  {
    id: "#student_gender#",
    label: "Student Gender",
    schema: "trainee",
    key: "gender",
  },
  { id: "#student_age#", label: "Student Age", schema: "trainee", key: "age" },
  {
    id: "#student_school#",
    label: "Student School",
    schema: "trainee",
    key: "school",
  },
  { id: "#course_name#", label: "Course Name", schema: "course", key: "name" },
  {
    id: "#course_url#",
    label: "Course URL",
    schema: "course",
    key: "lms_course_id",
  },
  {
    id: "#booked_open_day#",
    label: "Booked Open Day",
    schema: "student_showcase",
    key: "custom",
  },
  {
    id: "#lms_assignment_showcase_project_url#",
    label: "LMS Assignment - Showcase Project URL",
    schema: "course",
    key: "lms_assignment_project_id",
  },
  {
    id: "#lms_assignment_feedback_form_url#",
    label: "LMS Assignment - Feedback Form URL",
    schema: "course",
    key: "lms_assignment_feedback_form_id",
  },
  {
    id: "#lms_module_update_portfolio_url#",
    label: "LMS Module - Update Portfolio URL",
    schema: "course",
    key: "lms_assignment_portfolio_id",
  },
];

export const shortcodesShowcaseOutstandingTasksMentorEmail = [
  {
    id: "#students_table#",
    label: "Students Table List",
    schema: "showcase",
    key: "custom",
  },
  { id: "#mentor_name#", label: "Mentor Name", schema: "mentor", key: "name" },
  {
    id: "#mentor_email#",
    label: "Mentor Email",
    schema: "mentor",
    key: "email",
  },
];

export const shortcodesShowcaseBadgeApprovedEmail = [
  {
    id: "#student_name#",
    label: "Student Name",
    schema: "trainee",
    key: "name",
  },
  {
    id: "#student_email#",
    label: "Student Email",
    schema: "trainee",
    key: "email",
  },
  {
    id: "#student_gender#",
    label: "Student Gender",
    schema: "trainee",
    key: "gender",
  },
  { id: "#student_age#", label: "Student Age", schema: "trainee", key: "age" },
  {
    id: "#student_school#",
    label: "Student School",
    schema: "trainee",
    key: "school",
  },
  { id: "#course_name#", label: "Course Name", schema: "course", key: "name" },
  {
    id: "#course_code#",
    label: "Course Code",
    schema: "course",
    key: "course_code",
  },
  {
    id: "#course_url#",
    label: "Course URL",
    schema: "course",
    key: "lms_course_id",
  },
  {
    id: "#lms_assignment_feedback_form_url#",
    label: "LMS Assignment - Feedback Form URL",
    schema: "course",
    key: "lms_assignment_feedback_form_id",
  },
  {
    id: "#showcase_project_name#",
    label: "Showcase Project Name",
    schema: "student_showcase",
    key: "custom",
  },
  {
    id: "#showcase_project_link#",
    label: "Showcase Project Link",
    schema: "student_showcase",
    key: "custom",
  },
  {
    id: "#showcase_project_evaluation#",
    label: "Showcase Project Evaluation",
    schema: "student_showcase",
    key: "custom",
  },
  {
    id: "#showcase_project_feedback#",
    label: "Showcase Project Feedback",
    schema: "student_showcase",
    key: "custom",
  },
  {
    id: "#badge_image_url#",
    label: "Badge Image URL",
    schema: "user_badge",
    key: "image",
  },
  {
    id: "#badge_level#",
    label: "Badge Level",
    schema: "badge",
    key: "type_label",
  },
  {
    id: "#backpack_url#",
    label: "Backpack URL",
    schema: "user_badge",
    key: "custom",
  },
];
