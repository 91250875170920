/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import theme from '../../theme';

const Container = styled.div`
    display: block;
    width: 500px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    border-radius: 4px;
    background: #fff;
`;

class PopperBox extends React.Component {

    handleClickAway = () => {
        if ( this.props.onClose ) 
            this.props.onClose();
    }

    renderContent = () => {
        const { contents, wrapperStyle, containerStyle } = this.props;
        return (
        <div style={( wrapperStyle || null )}>
            <Container style={( containerStyle || null )}>{ contents || '' }</Container>
        </div>
        )
    }

    renderTransition = (TransitionProps) => {
        const { disableClickAway } = this.props;
        if ( disableClickAway ) {
            return this.renderContent();
        } else {
            return (
            <ClickAwayListener onClickAway={this.handleClickAway} >
                <Fade {...TransitionProps} timeout={350}>
                    {this.renderContent()}
                </Fade>
            </ClickAwayListener>
            )
        } // end - disableClickAway
    }

    render() {
        const { open, anchorEl, placement, contents, wrapperStyle, containerStyle, zIndex } = this.props;
        return (
        <Popper 
        open={open} 
        placement={ placement || "bottom-end" }
        anchorEl={( anchorEl || {} )} 
        style={{ zIndex: ( zIndex || '99999' ) }}
        transition>
            {({ TransitionProps }) => this.renderTransition(TransitionProps)}
        </Popper>
        );
    }

}

export default PopperBox