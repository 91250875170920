/* eslint-disable */
import React from "react";
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import IconButton from "@material-ui/core/IconButton";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    EmailIcon,
} from "react-share";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import Tooltip from "@material-ui/core/Tooltip";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _filter from "lodash/filter";
import _split from "lodash/split";
import _map from "lodash/map";
import _trim from "lodash/trim";
import _size from "lodash/size";

import StudentBackpackProjects from "../../modules/StudentBackpackProjects";

import FormSelect2 from "../../components/FormSelect2";
import ToolTipInfo from "../../components/ToolTipInfo";

import { isAdmin, hasAccessRights } from "../../helpers/auth";
import { isArrayExists, isObjectExists, validate } from "../../helpers/validation";
import { cloneCollections, getSelectOptions, getSelectValues } from "../../helpers/data";
import { triggerSuccessAlert } from "../../helpers/alert";

import { AInfoLink, AIndigoLink, ButtonGroup, AGreyLink, AWarningLink } from "../../styles/button";

import { userStudentSchema } from "../../schemas/user_student";
import { COUNTRIES_LIST } from "../../data/const_countries";
import { STATE_OPTIONS } from "../../data/const_states";

import theme from "../../theme";

import { PLATFORM_BADGE_URL, PLATFORM_CERT_URL, PLATFORM_BACKPACK_URL, CANVAS_LMS_URL, PLATFORM_URL } from "../../constants";

const useStyles = (theme) => ({
    section_title: {
        fontSize: "20px",
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd",
    },
    course_type_title: {
        display: "inline-block",
        fontSize: "18px",
        fontWeight: "700",
        padding: "5px 15px",
        borderRadius: "6px",
        marginBottom: "20px",
        color: "#fff",
        background: theme.palette.primary.main,
    },
});

const Wrapper = styled.div``;

const CourseTypeWrapper = styled.div`
    margin-bottom: 40px;
`;

const BadgeWrapperOLd = styled.div`
    text-align: center;

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    a {
        display: inline-block;
        &:hover {
            opacity: 0.75;
        }
    }
`;

const BadgesWrapper = styled.div`
    display: block;
`;

const BadgeWrapper = styled.div`
    padding: 30px;
    height: 100%;
    text-align: center;

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    h4 {
        margin-top: 15px;
        font-size: 18px;
        font-weight: 300;

        span {
            display: block;
            font-size: 13px;
            margin-top: 5px;
        }
    }

    h5 {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 300;
        strong {
            font-weight: 700;
        }
    }
`;

const UserDetailsWrapper = styled.div`
    margin-bottom: 40px;
    border: 1px solid #212121;

    & > div {
        padding: 20px;
        border-bottom: 1px solid #212121;

        &:last-child {
            border-bottom: none;
        }
    }

    h4 {
        font-size: 2.25rem;
        strong {
            font-weight: 700;
        }
    }
`;

const SocialLinks = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > div {
        display: inline-block;
        margin: 0 15px 5px 0;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    }
`;

const ConditionWrapper = styled.ul`
    font-size: 16px;
    li {
        display: block;
        margin-bottom: 10px;
        span {
            margin-right: 5px;
        }
        i {
            &.fa-check {
                color: ${green[200]};
            }
            &.fa-times {
                color: ${red[200]};
            }
        }
    }
`;

class BadgesBackpack extends React.Component {
    state = {
        sortBy: false,
        randNum: false,
    };

    reorganizeData = () => {
        const { badges } = this.props;
        const { sortBy } = this.state;
        let items = badges && isArrayExists(badges) ? cloneCollections(badges) : [];

        // do sort
        if (sortBy && !_isEmpty(sortBy) && !_isEmpty(items)) {
            switch (sortBy) {
                case "date-desc":
                    items = _sortBy(items, [(i) => i.created_on._seconds]);
                    items = _reverse(items);
                    break;
                case "date-asc":
                    items = _sortBy(items, [(i) => i.created_on._seconds]);
                    break;
                case "name-desc":
                    items = _sortBy(items, ["name"]);
                    items = _reverse(items);
                    break;
                case "name-asc":
                    items = _sortBy(items, ["name"]);
                    break;
            }
        } // end - sortBy

        return items;
    };

    getCourseTypes = () => {
        const { badges } = this.props;
        let list = [];
        if (badges && isArrayExists(badges)) {
            _forEach(badges, (badge) => {
                if (badge.course_type && !_isEmpty(badge.course_type) && !_find(list, { id: badge.course_type })) {
                    list.push({
                        id: badge.course_type,
                        label: badge.course_type_label || "",
                    });
                } // end - badge.course_type
            });
        } // end - badges
        return _sortBy(list, [(item) => item.label.toLowerCase()]);
    };

    renderOldBadge = (badge) => {
        return (
            <Grid key={badge.id} item xs={6} sm={3} md={2}>
                <BadgeWrapperOLd>
                    <Tooltip title={<span style={{ fontSize: "12px", padding: "4px 8px" }}>Click to View Badge</span>} arrow placement="top">
                        <a href={PLATFORM_BADGE_URL + badge.id} target="_blank">
                            <img src={badge.image} />
                        </a>
                    </Tooltip>
                </BadgeWrapperOLd>
            </Grid>
        );
    };

    renderBadgeCondition = (badge) => {
        return (
            <ConditionWrapper>
                {badge && badge.conditions && badge.conditions.showcase_date && badge.conditions.showcase_date === "yes" ? (
                    <li>
                        <span>Open Day Date:</span>
                        <i className="fa fa-check"></i>
                    </li>
                ) : null}
                <li>
                    <span>Feedback Form:</span>
                    {badge && badge.conditions && badge.conditions.feedback_form && badge.conditions.feedback_form === "yes" ? (
                        <i className="fa fa-check"></i>
                    ) : (
                        <i className="fa fa-times"></i>
                    )}
                </li>
                <li>
                    <span>Showcase Project:</span>
                    {badge && badge.conditions && badge.conditions.project && badge.conditions.project === "yes" ? (
                        <i className="fa fa-check"></i>
                    ) : (
                        <i className="fa fa-times"></i>
                    )}
                </li>
                <li>
                    <span>Evaluation:</span>
                    {badge && badge.conditions && badge.conditions.score && !_isEmpty(badge.conditions.score) ? (
                        badge.conditions.score === "pass" ? (
                            <>
                                <i className="fa fa-thumbs-up" style={{ marginLeft: "3px" }}></i>
                                {badge.conditions.badge_approved && badge.conditions.badge_approved === "yes" ? (
                                    <i className="fa fa-check"></i>
                                ) : null}
                            </>
                        ) : (
                            <i className="fa fa-times"></i>
                        )
                    ) : (
                        "---"
                    )}
                </li>
                <li>
                    <span>Badge Nominated:</span>
                    {badge.badge_status && (badge.badge_status === "approved" || badge.badge_status === "nominated")
                        ? badge.type_label || "---"
                        : "---"}
                </li>
            </ConditionWrapper>
        );
    };

    renderBadgeStatus = (badge) => {
        switch (badge.badge_status) {
            case "review":
            case "nominated":
            case "approved":
                return (
                    <ButtonGroup style={{ marginTop: "20px", textAlign: "center" }}>
                        <Tooltip title={this.renderBadgeCondition(badge)} arrow placement="top">
                            <AGreyLink size="small" href={CANVAS_LMS_URL + "courses/" + badge.lms_course_id + "/assignments"} target="_blank">
                                <i className="fa fa-hourglass-3"></i>INCOMPLETE
                            </AGreyLink>
                        </Tooltip>
                    </ButtonGroup>
                );
            case "claimed":
                return (
                    <ButtonGroup style={{ marginTop: "20px", textAlign: "center" }}>
                        <AInfoLink size="small" href={PLATFORM_BADGE_URL + badge.id} target="_blank">
                            <i className="fa fa-shield"></i>View Badge
                        </AInfoLink>
                        {badge && badge.cert_referer && !_isEmpty(badge.cert_referer) ? (
                            <AIndigoLink size="small" href={PLATFORM_CERT_URL + badge.id} target="_blank">
                                <i className="fa fa-certificate"></i>Print PDF Cert
                            </AIndigoLink>
                        ) : null}
                    </ButtonGroup>
                );
        }
    };

    renderBadge = (badge) => {
        return (
            <Grid key={badge.id} item xs={12} sm={4}>
                <BadgeWrapper>
                    <a
                        href={
                            badge.badge_status && badge.badge_status === "claimed" ? PLATFORM_BADGE_URL + badge.id : PLATFORM_URL + "sudent/badges/"
                        }
                        target={badge.badge_status && badge.badge_status === "claimed" ? "_blank" : null}
                        style={{ display: "block" }}
                    >
                        <img
                            src={badge.image}
                            style={
                                badge.badge_status &&
                                (badge.badge_status === "review" || badge.badge_status === "nominated" || badge.badge_status === "approved")
                                    ? {
                                          opacity: 0.5,
                                          filter:
                                              badge.badge_status === "review" || badge.badge_status === "nominated"
                                                  ? "grayscale(100%)"
                                                  : "sepia(100%)",
                                      }
                                    : null
                            }
                        />
                    </a>
                    <Typography variant="h4">
                        {badge.name || ""}
                        {badge.badge_status && badge.badge_status === "claimed" ? (
                            <span>{"( " + (badge.type_label || "") + " )"}</span>
                        ) : (
                            <span style={{ height: "18px" }}>
                                {badge.badge_status && badge.badge_status === "nominated" ? "( " + (badge.type_label || "") + " )" : " "}
                            </span>
                        )}
                    </Typography>
                    {/* <Typography variant="h5"><strong>Issuer: </strong>{( badge.issuer_name || '' )}</Typography> */}
                    {this.renderBadgeStatus(badge)}
                </BadgeWrapper>
            </Grid>
        );
    };

    renderCourseTypeBadges = (courseType) => {
        const { classes, badges } = this.props;
        const list = badges && isArrayExists(badges) ? _filter(badges, { course_type: courseType.id }) : false;
        return (
            <CourseTypeWrapper key={courseType.id}>
                <Typography variant="h4">
                    <span className={classes.course_type_title}>{courseType.label || ""}</span>
                </Typography>
                {list && isArrayExists(list) ? (
                    <Grid container spacing={2}>
                        {_map(list, this.renderBadge)}
                    </Grid>
                ) : null}
            </CourseTypeWrapper>
        );
    };

    renderBadges = () => {
        const { sortBy } = this.state;
        const list = this.reorganizeData();
        return (
            <BadgesWrapper>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h5">
                            Total: <span style={{ fontWeight: "700" }}>{_size(list)}</span> Badge(s)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} align="right">
                        <FormControl variant="outlined" style={{ background: "#fff", marginRight: "5px" }}>
                            <InputLabel style={{ background: "#fff", padding: "5px", color: theme.palette.background }}>Sort By</InputLabel>
                            <Select
                                native
                                value={sortBy}
                                onChange={(event) => this.setState({ sortBy: event.target.value })}
                                input={<OutlinedInput name="sortby" />}
                            >
                                <option value="date-desc">Recent First</option>
                                <option value="date-asc">Oldest First</option>
                                <option value="name-asc">Badge Name ( A - Z)</option>
                                <option value="name-desc">Badge Name ( Z - A)</option>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                {list && isArrayExists(list) ? (
                    <Grid container spacing={2}>
                        {_map(list, this.renderBadge)}
                    </Grid>
                ) : null}
            </BadgesWrapper>
        );
    };

    renderUserPart = () => {
        const { classes, user } = this.props;
        let title = user.name || "",
            twitter_title = "Check out the digital badges in my backpack:",
            desc = user.about_me || "",
            share_url = PLATFORM_BACKPACK_URL + (user && user.uid && !_isEmpty(user.uid) ? user.uid : "");
        return (
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "0px 20px" }}>
                        <ToolTipInfo
                            content={
                                <>
                                    <strong style={{ fontWeight: "700", textTransform: "uppercase" }}>PROFILE PICTURE</strong>
                                    <br />
                                    <br />
                                    Update your profile pic on{" "}
                                    <a href={"https://lms.chumbaka.asia/profile/settings"} target={"_blank"}>
                                        Settings
                                    </a>
                                    .
                                </>
                            }
                        />
                        {user.avatar_url && !_isEmpty(user.avatar_url) ? (
                            <img src={user.avatar_url || ""} width="150px" height={"150px"} style={{ borderRadius: "100%" }} />
                        ) : (
                            <i className="fa fa-user" style={{ width: "256px", height: "256px", borderRadius: "100%" }}></i>
                        )}
                    </div>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <UserDetailsWrapper>
                        <div>
                            <Typography variant="h4" style={{ textTransform: "uppercase" }}>
                                <strong>{user && user.name && !_isEmpty(user.name) ? user.name : "---"}</strong>
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="p">
                                <ToolTipInfo
                                    content={
                                        <>
                                            <strong style={{ fontWeight: "700", textTransform: "uppercase" }}>Description About Me</strong>
                                            <br />
                                            <br />
                                            Update the description about you on{" "}
                                            <a href={"https://lms.chumbaka.asia/users/1595/external_tools/13"} target={"_blank"}>
                                                My Profile
                                            </a>
                                            .
                                        </>
                                    }
                                />
                                {user && user.about_me && !_isEmpty(user.about_me) ? (
                                    user.about_me
                                ) : (
                                    <>
                                        <span>---</span>
                                    </>
                                )}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="h5">
                                <ToolTipInfo
                                    content={
                                        <>
                                            <strong style={{ fontWeight: "700" }}>Portfolio</strong>
                                            <br />
                                            <br />
                                            A portfolio is a digital resume of past projects, showcasing student’s accomplishments, skills, and
                                            capabilities.
                                            <br />
                                            <br />
                                            You can update your portfolio on{" "}
                                            <a href={"https://lms.chumbaka.asia/users/1595/external_tools/13"} target={"_blank"}>
                                                My Profile
                                            </a>
                                            .
                                        </>
                                    }
                                />
                                <strong>PORTFOLIO</strong>
                                <br />
                                {user && user.eportfolio && !_isEmpty(user.eportfolio) ? (
                                    <a
                                        href={validate(user.eportfolio, "safe_escape")}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        style={{ textTransform: "lowercase" }}
                                    >
                                        {validate(user.eportfolio, "safe_escape")}
                                    </a>
                                ) : (
                                    "---"
                                )}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="h5">
                                <strong>RECENT COMMENT FROM MENTOR</strong>
                                <br />
                                {user.mentor_comment && !_isEmpty(user.mentor_comment) ? (
                                    <>
                                        <Typography variant="h5" style={{ textDecoration: "underline" }}>
                                            <strong>{user.mentor_name}</strong>
                                        </Typography>
                                        <Typography variant="h5" style={{ fontStyle: "italic", paddingTop: "5px" }}>
                                            {user.mentor_date}
                                        </Typography>
                                        <Typography variant="body1" style={{ paddingTop: "5px" }}>
                                            "{user.mentor_comment}"
                                        </Typography>
                                    </>
                                ) : (
                                    "---"
                                )}
                            </Typography>
                        </div>

                        {/* <Typography variant="h4">
                        <strong>BACKPACK:</strong> <a href={PLATFORM_BACKPACK_URL+( user && user.uid && !_isEmpty( user.uid ) ? user.uid : '' )} target="_blank">{PLATFORM_BACKPACK_URL+( user && user.uid && !_isEmpty( user.uid ) ? user.uid : '' )}</a>
                        <CopyToClipboard text={PLATFORM_BACKPACK_URL+( user && user.uid && !_isEmpty( user.uid ) ? user.uid : '' )}
                            onCopy={() => {
                                triggerSuccessAlert("URL copied to clipboard.");
                            }}>
                            <IconButton style={{ marginLeft: '5px' }}><FileCopyIcon /></IconButton>
                        </CopyToClipboard>
                    </Typography> */}
                    </UserDetailsWrapper>
                </Grid>
                <Grid item xs={12} sm={12} style={{ paddingTop: "20px" }}>
                    <div style={{ marginTop: "auto", display: "flex", justifyContent: "flex-end" }}>
                        <SocialLinks>
                            <div>Share This:</div>
                            <div>
                                <Tooltip
                                    title={<span style={{ fontSize: "12px", padding: "2px" }}>{"Share this to Facebook"}</span>}
                                    arrow
                                    placement="top"
                                >
                                    <FacebookShareButton className="showcase-sharing-item" quote={desc} url={share_url}>
                                        <FacebookIcon size={36} round={true} />
                                    </FacebookShareButton>
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip
                                    title={<span style={{ fontSize: "12px", padding: "2px" }}>{"Share this to Linkedin"}</span>}
                                    arrow
                                    placement="top"
                                >
                                    <LinkedinShareButton title={title} description={desc} className="showcase-sharing-item" url={share_url}>
                                        <LinkedinIcon size={36} round={true} />
                                    </LinkedinShareButton>
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip
                                    title={<span style={{ fontSize: "12px", padding: "2px" }}>{"Share this to Twitter"}</span>}
                                    arrow
                                    placement="top"
                                >
                                    <TwitterShareButton title={twitter_title} className="showcase-sharing-item" url={share_url}>
                                        <TwitterIcon size={36} round={true} />
                                    </TwitterShareButton>
                                </Tooltip>
                            </div>
                            <div style={{ minHeight: "42px" }}>
                                <Tooltip
                                    title={<span style={{ fontSize: "12px", padding: "2px" }}>{"Get Backpack Link"}</span>}
                                    arrow
                                    placement="top"
                                >
                                    <a
                                        href={share_url}
                                        className="showcase-sharing-item"
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        <CopyToClipboard
                                            text={share_url}
                                            onCopy={() => {
                                                triggerSuccessAlert("URL copied to clipboard.");
                                            }}
                                        >
                                            {/* <span class="fa-stack fa-lg">
                                            <i class="fa fa-circle fa-stack-2x"></i>
                                            <i class="fa fa-link fa-stack-1x fa-inverse"></i>
                                        </span> */}
                                            <div style={{ width: "36px", height: "36px" }}>
                                                <img src="https://www.dropbox.com/s/9idxabmkll1n2u7/link.png?dl=1" width={"36px"} height="36px" />
                                            </div>
                                        </CopyToClipboard>
                                        {/* <i className="fa fa-link" style={{ width: '32px', height: '32px' }}></i> */}
                                    </a>
                                </Tooltip>
                            </div>
                        </SocialLinks>
                    </div>
                </Grid>
            </Grid>
        );
    };

    render = () => {
        const { classes, badges, user, projects, authData } = this.props;
        const courseTypes = this.getCourseTypes();
        return (
            <Wrapper>
                <Typography
                    variant="h1"
                    style={{
                        fontSize: "30px",
                        fontWeight: "700",
                        paddingBottom: "15px",
                        marginBottom: "30px",
                        borderBottom: "1px solid #ddd",
                    }}
                >
                    Student Achievement Backpack
                </Typography>
                {user && !_isEmpty(user) ? this.renderUserPart() : null}
                <StudentBackpackProjects projects={projects} authData={authData} user={user} badges={badges} />
                <Typography variant="h2" className={classes.section_title}>
                    <ToolTipInfo
                        content={
                            <>
                                All your completed courses will be featured here with badges and digital certs. To claim your certs, refer to{" "}
                                <a href="https://www.notion.so/chumbaka/Claim-My-Digital-Badge-9038a5c4c7914375a163a814591367f7">this Notion guide</a>
                                .
                            </>
                        }
                    />
                    COMPLETED COURSES:
                </Typography>
                {/* { courseTypes && isArrayExists( courseTypes ) ? _map( courseTypes, this.renderCourseTypeBadges ) : <Typography variant="body1">No badge(s) added yet.</Typography> } */}
                {badges && isArrayExists(badges) ? this.renderBadges() : <Typography variant="body1">No badge(s) added yet.</Typography>}
            </Wrapper>
        );
    };
}

export default compose(withStyles(useStyles), withRouter)(BadgesBackpack);
