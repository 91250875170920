/* eslint-disable */
import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import _isEmpty from 'lodash/isEmpty';


class ItemNotFound extends React.Component {

    render() {
        const { item, customMsg } = this.props;
        return (
        <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
            <Typography variant="h4" align="center">{ customMsg && !_isEmpty( customMsg ) ? customMsg : ( ( item ? item : 'Item' ) + " Not Found / You don't have access to this " + ( item ? item : 'Item' ) ) }</Typography>
        </Paper>
        )
    }

}

export default ItemNotFound