/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Papa from "papaparse";
import axios from "axios";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _slice from "lodash/slice";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _find from "lodash/find";
import _toLower from "lodash/toLower";
import _startCase from "lodash/startCase";

import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import TableBar from "../../components/TableBar";
import ModelDelete from "../../components/ModalDelete";
import ModalView from "../../components/ModalView";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormSelect2 from "../../components/FormSelect2";
import FormCheckbox from "../../components/FormCheckbox";
import ButtonActions from "../../components/ButtonActions";

import {
  InfoButton,
  GreyButton,
  ButtonGroup,
  AInfoLink,
  InverseButton,
  ErrorButton,
  IndigoButton,
} from "../../styles/button";
import { SuccessTag, AmberTag, GreyTag } from "../../styles/tag";
import { WrapWord } from "../../styles/misc";

import { isArrayExists, validateEmail } from "../../helpers/validation";
import { triggerErrorAlert } from "../../helpers/alert";
import {
  cloneCollections,
  doPagination,
  doArraySearch,
  getSelectOptions,
  getSelectValues,
  getSelectedValue,
} from "../../helpers/data";
import { getMomentTime, getMomentTimestamp } from "../../helpers/date";
import { isAdmin, hasAccessRights } from "../../helpers/auth";
import { callFunctionsAPI, getAPIErrorMessage } from "../../helpers/action";

import {
  addPersonnel,
  deletePersonnel,
  resendAccountCreationEmail,
} from "../../actions/personnels";
import { toggleLoader } from "../../actions/global";

import { userPartnerSchema } from "../../schemas/user_partner";

import {
  PLATFORM_PARTNER_DEFAULT_ROLE,
  PARTNER_ROLES_OPTIONS,
} from "../../constants";

class Personnels extends React.Component {
  state = {
    checked: [],
    allCheck: false,
    searchterms: "",
    filterBy: "all",
    sortBy: "name-asc",
    perPage: 20,
    page: 1,
    openViewModal: false,
    modalData: false,
    openDeleteModal: false,
    deleteModal: false,
  };

  doCSVDownload = (results) => {
    let csv = Papa.unparse(results, { delimiter: "," });
    let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" }); //new way
    let uri = window.URL.createObjectURL(blob);
    let link = document.createElement("a");

    link.setAttribute("href", uri);
    link.setAttribute("target", "_blank");
    link.setAttribute("download", "export_personnel_database.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  handleExportAll = () => {
    const { regions, roles, dispatch } = this.props;
    dispatch(toggleLoader(true));
    callFunctionsAPI({
      method: "GET",
      url: "data",
      action: "get_admins",
    })
      .then((users) => {
        dispatch(toggleLoader(false));
        let csvData = {
          fields: [
            "Partner",
            "status",
            "Name",
            "Email",
            "SMS Role",
            "Mobile No.",
            "Organisation",
          ],
          data: [],
        };
        if (users && isArrayExists(users)) {
          _forEach(users, (user) => {
            if (user && !_isEmpty(user)) {
              let row = [];
              row.push(
                getSelectedValue(regions, user.region, "id", "label", "-")
              );
              row.push((user && user.status) || "");
              row.push((user && user.name) || "");
              row.push((user && user.email) || "");
              row.push(getSelectedValue(roles, user.role, "id", "label", "-"));
              row.push((user && user.mobile) || "");
              row.push((user && user.org) || "");
              csvData.data.push(row);
            } // end - trainee
          });
          this.doCSVDownload(csvData);
        } else {
          triggerErrorAlert("No data to export.");
        }
      })
      .catch((error) => {
        dispatch(toggleLoader(false));
        triggerErrorAlert(getAPIErrorMessage(error));
      });
  };

  handleExport = () => {
    const { personnels, regions, roles } = this.props;
    const { checked } = this.state;
    if (checked && isArrayExists(checked)) {
      let csvData = {
        fields: [
          "Partner",
          "status",
          "Name",
          "Email",
          "SMS Role",
          "Mobile No.",
          "Organisation",
        ],
        data: [],
      };
      _forEach(checked, (selected) => {
        let item = _find(personnels, { uid: selected.uid });
        if (item && !_isEmpty(item)) {
          let row = [];
          row.push(getSelectedValue(regions, item.region, "id", "label", "-"));
          row.push((item && item.status) || "");
          row.push((item && item.name) || "");
          row.push((item && item.email) || "");
          row.push(getSelectedValue(roles, item.role, "id", "label", "-"));
          row.push((item && item.mobile) || "");
          row.push((item && item.org) || "");
          csvData.data.push(row);
        } // end - trainee
      });
      this.doCSVDownload(csvData);
    } else {
      triggerErrorAlert("Please select at least one personnel");
    } // end - checked
  };

  handlePageRefresh = (event) => {
    const { partner_id } = this.props.match.params;
    event.preventDefault();
    if (this.props.onRefresh) this.props.onRefresh({ partner_id });
  };

  handleResendEmail = (item) => (event) => {
    event.preventDefault();
    this.props.dispatch(resendAccountCreationEmail(item.uid));
  };

  handleFormUpdate = (newValue, key, subkey, subVal) => {
    const { modalData } = this.state;
    let newData = modalData ? cloneCollections(modalData) : {};
    if (
      subkey &&
      (subkey === "checked" || subkey === "unchecked") &&
      subVal &&
      !_isEmpty(subVal)
    ) {
      // use subVal as the new value
      newData[key] = subVal;
    } else {
      // otherwise - just update normally
      newData[key] = newValue;
    }
    this.setState({ modalData: newData });
  };

  handleAddNew = () => {
    const { history, regions } = this.props;
    const { modalData } = this.state;
    var error = false;

    if (!(modalData && modalData.region && !_isEmpty(modalData.region))) {
      error = "Please select a valid Partner";
    } // end - modalData.email

    if (!(modalData && modalData.role && !_isEmpty(modalData.role))) {
      error = "Please enter a valid role";
    } // end - modalData.email

    if (!(modalData && modalData.email && validateEmail(modalData.email))) {
      error = "Please enter a valid email";
    } // end - modalData.email

    if (error) {
      triggerErrorAlert(error);
    } else {
      const partner_id = this.getSelectedPartnerID(modalData);
      this.props.dispatch(addPersonnel(modalData, history, partner_id));
    } // end - error
  };

  handleEdit = (item, event) => {
    event.preventDefault();
    this.props.history.push(
      "/partner/" + this.getSelectedPartnerID(item) + "/personnel/" + item.uid
    );
  };

  handleDelete = () => {
    const { deleteModal } = this.state;
    // perform delete
    this.props.dispatch(deletePersonnel(deleteModal.uid));
  };

  handleAction = (type) => {
    const { checked } = this.state;
    // make sure there is an item selected
    if (isArrayExists(checked)) {
    } else {
      triggerErrorAlert("Please select at least one item");
    } // end - checked
  };

  getActions = () => {
    const { regions, roles, partner_id, authData } = this.props;
    // get default region
    let selectedRegion =
        regions && isArrayExists(regions) && partner_id && !_isEmpty(partner_id)
          ? _find(regions, { value: partner_id })
          : false,
      actions = [];

    if (authData && hasAccessRights(authData, ["ptm"])) {
      actions.push({
        id: "addnew",
        label: "Add New Personnel",
        icon: "fa-plus-circle",
        onClick: () =>
          this.setState({
            openViewModal: true,
            modalData: {
              region:
                selectedRegion && selectedRegion.id ? selectedRegion.id : "",
              name: "",
              email: "",
              role: PLATFORM_PARTNER_DEFAULT_ROLE,
            },
          }),
      });
    }

    actions.push({
      id: "downloadcsv",
      label: "Export Database",
      icon: "fa-cloud-download",
      onClick: this.handleExport,
    });

    if (authData && isAdmin(authData)) {
      actions.push({
        id: "downloadcsvall",
        label: "Export Database (All Partners)",
        icon: "fa-cloud-download",
        onClick: this.handleExportAll,
      });
    }

    return actions;
  };

  getQueryProps = () => {
    const { sortBy, perPage, page } = this.state;
    return false;
  };

  getSelectedPartnerID = (item) => {
    const { regions, partner_id } = this.props;
    // let selected = ( regions && isArrayExists( regions ) && item.region && !_isEmpty( item.region ) ? _find( regions, { id: item.region } ) : false );
    // return ( selected && selected.value && !_isEmpty( selected.value ) ? selected.value : '' );
    return partner_id;
  };

  reorganizeData() {
    const { searchterms, sortBy, filterBy, perPage, page } = this.state;
    const { personnels } = this.props;
    let items = personnels ? cloneCollections(personnels) : [],
      total = _size(items);

    // do search
    if (searchterms && !_isEmpty(searchterms)) {
      items = doArraySearch(items, searchterms, ["name", "email", "org"]);
      total = _size(items);
    } // end - searchterms

    // do custom filter
    if (filterBy && !_isEmpty(filterBy) && filterBy !== "all") {
      items = _filter(items, { role: filterBy });
      total = _size(items);
    }

    // do sort
    if (sortBy && !_isEmpty(sortBy) && !_isEmpty(items)) {
      switch (sortBy) {
        case "name-desc":
          items = _sortBy(items, ["name"]);
          items = _reverse(items);
          break;
        case "name-asc":
          items = _sortBy(items, ["name"]);
          break;
        case "email-desc":
          items = _sortBy(items, ["email"]);
          items = _reverse(items);
          break;
        case "email-asc":
          items = _sortBy(items, ["email"]);
          break;
        case "org-desc":
          items = _sortBy(items, ["org"]);
          items = _reverse(items);
          break;
        case "org-asc":
          items = _sortBy(items, ["org"]);
          break;
      }
    } // end - sortBy

    // do pagination
    items = doPagination(items, perPage, page);

    return { items, total };
  }

  renderCell = (type) => (item) => {
    const { regions, roles } = this.props;
    switch (type) {
      case "role":
        let role =
          roles && isArrayExists(roles) && item[type] && !_isEmpty(item[type])
            ? _find(roles, { id: item[type] })
            : false;
        return role && role.label && !_isEmpty(role.label) ? role.label : "";
      case "region":
        let region =
          regions &&
          isArrayExists(regions) &&
          item[type] &&
          !_isEmpty(item[type])
            ? _find(regions, { id: item[type] })
            : false;
        return region && region.label && !_isEmpty(region.label)
          ? region.label
          : "";
      case "status":
        return (
          <>
            {item[type] && item[type] == "active" ? (
              <SuccessTag>{item[type]}</SuccessTag>
            ) : item[type] && item[type] == "pending" ? (
              <AmberTag>{item[type]}</AmberTag>
            ) : (
              <GreyTag>disabled</GreyTag>
            )}
          </>
        );
      case "email":
      case "mobile":
        return <WrapWord>{item[type] || ""}</WrapWord>;
    }
  };

  renderAddNewForm = () => {
    const { authData, roles, regions } = this.props;
    const { modalData } = this.state;
    return (
      <div>
        <div style={{ paddingTop: "15px" }}>
          <FormSelect
            label="Partner (Required)"
            name="region"
            value={modalData.region || ""}
            disabled={isAdmin(authData) ? false : true}
            options={getSelectOptions({
              list: regions,
              options: [{ value: "", label: "Select an Option" }],
              keys: { value: "id", label: "label" },
              sortBy: "label",
            })}
            onChange={this.handleFormUpdate}
          />
        </div>
        <FormInput
          label="Name"
          name="name"
          value={modalData.name || ""}
          onChange={this.handleFormUpdate}
        />
        <FormInput
          label="Email Address *"
          name="email"
          type="email"
          value={modalData.email || ""}
          onChange={this.handleFormUpdate}
        />
        <div style={{ paddingTop: "10px" }}>
          <FormSelect
            label="Role (Required)"
            name="role"
            value={modalData.role || ""}
            options={getSelectOptions({
              list: isAdmin(authData) ? roles : PARTNER_ROLES_OPTIONS,
              options: [{ value: "", label: "Select an Option" }],
              keys: { value: "id", label: "label" },
              sortBy: "label",
            })}
            onChange={this.handleFormUpdate}
          />
        </div>
      </div>
    );
  };

  renderTableActions = () => {
    const { authData, roles, regions, partner_id, onPageChange } = this.props;
    const { filterBy, sortBy, perPage, searchterms, checked } = this.state;
    return (
      <TableBar
        filterBy={filterBy}
        sortBy={sortBy}
        perPage={perPage}
        searchterms={searchterms}
        sortByOptions={[
          { value: "name-asc", label: "Name ( A - Z)" },
          { value: "name-desc", label: "Name ( Z - A )" },
          { value: "email-asc", label: "License ( A - Z)" },
          { value: "email-desc", label: "License ( Z - A )" },
          { value: "org-asc", label: "Organisation ( A - Z)" },
          { value: "org-desc", label: "Organisation ( Z - A )" },
        ]}
        leftButtons={
          checked && isArrayExists(checked)
            ? [
                <div
                  key="check_option"
                  style={{ marginLeft: "15px", paddingTop: "20px" }}
                >
                  <GreyButton
                    style={{
                      padding: "10px 25px",
                      borderRadius: "25px",
                      marginRight: "10px",
                    }}
                    onClick={() =>
                      this.setState({ checked: [], allCheck: false })
                    }
                  >
                    <i
                      className="fa fa-remove"
                      style={{ marginRight: "10px" }}
                    ></i>
                    {_size(checked) + " selected"}
                  </GreyButton>
                </div>,
              ]
            : null
        }
        rightButtons={[
          <ButtonActions
            key="actions"
            label="Actions"
            menuContainerStyle={{ width: "250px" }}
            style={{ marginRight: "5px" }}
            actions={this.getActions()}
          />,
          <div
            key="filterbyregions"
            style={{ maxWidth: "300px", width: "100%" }}
          >
            <FormSelect2
              label="Partner"
              disabled={isAdmin(authData) ? false : true}
              options={getSelectOptions({
                list: regions || false,
                keys: { value: "id", label: "label" },
                sortBy: "label",
              })}
              value={partner_id || false}
              isClearable={true}
              isSearchable={true}
              onChange={(newFilterBy) => {
                if (onPageChange) onPageChange(newFilterBy);
              }}
            />
          </div>,
        ]}
        filterByOptions={getSelectOptions({
          list: isAdmin(authData) ? roles : PARTNER_ROLES_OPTIONS,
          options: [{ value: "all", label: "All Roles" }],
          keys: { value: isAdmin(authData) ? "id" : "value", label: "label" },
          sortBy: "label",
        })}
        onFilterByChange={(newFilterBy) =>
          this.setState({ filterBy: newFilterBy, page: 1 })
        }
        onEntriesChange={(newPerPage) =>
          this.setState({ perPage: newPerPage, page: 1 })
        }
        onSearchChange={(terms) =>
          this.setState({ searchterms: terms, page: 1 })
        }
        onSortByChange={(newSortBy) =>
          this.setState({ sortBy: newSortBy, page: 1 })
        }
        style={{ marginBottom: "20px" }}
      />
    );
  };

  renderPagination = (totalCount) => {
    const { perPage, page } = this.state;
    return (
      <Pagination
        total={totalCount}
        perPage={perPage}
        page={page}
        style={{ marginTop: "20px" }}
        doneLoaded={true}
        onPageChange={(newPage) => this.setState({ page: newPage })}
      />
    );
  };

  render() {
    const { authData, regions } = this.props;
    const { openDeleteModal, deleteModal, openViewModal, allCheck, checked } =
      this.state;
    const { items, total } = this.reorganizeData();
    const region =
      regions && isArrayExists(regions)
        ? _find(regions, { id: authData.region })
        : false;
    return (
      <div>
        {authData && hasAccessRights(authData, ["ptm"]) ? (
          <ModalView
            open={openViewModal}
            title="Add New Personnel"
            onClose={() =>
              this.setState({ openViewModal: false, modalData: false })
            }
            doAction={this.handleAddNew}
            contents={this.renderAddNewForm()}
          />
        ) : null}

        {authData && isAdmin(authData) ? (
          <ModelDelete
            open={openDeleteModal}
            title={
              deleteModal && deleteModal.name
                ? `Are you sure you want to delete this personnel ( ${deleteModal.name} )?`
                : false
            }
            onClose={() =>
              this.setState({ openDeleteModal: false, deleteModal: false })
            }
            onDelete={this.handleDelete}
          />
        ) : null}

        {this.renderTableActions()}
        <Table
          uniqueKey="uid"
          items={items}
          showCheckbox={true}
          checked={checked || []}
          allCheck={allCheck}
          onChecked={(newValue) => this.setState({ checked: newValue })}
          onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
          cells={[
            {
              id: "status",
              label: "Status",
              render: this.renderCell("status"),
            },
            // { id: 'region', label: 'License', render: this.renderCell('region') },
            { id: "name", label: "Name", render: (item) => item.name || "" },
            {
              id: "role",
              label: "System Role",
              render: this.renderCell("role"),
            },
            { id: "email", label: "Email", render: this.renderCell("email") },
            {
              id: "mobile",
              label: "Mobile",
              render: this.renderCell("mobile"),
            },
            {
              id: "org",
              label: "Organisation",
              render: (item) => item.org || "",
            },
          ]}
          actionStyles={{ width: "20%" }}
          actions={(item) => (
            <ButtonGroup>
              <AInfoLink
                key="edit"
                href={
                  "/partner/" +
                  this.getSelectedPartnerID(item) +
                  "/personnel/" +
                  item.uid
                }
                size="small"
                onClick={this.handleEdit.bind(this, item)}
              >
                <i className="fa fa-edit"></i>Edit
              </AInfoLink>
              {authData && isAdmin(authData) ? (
                <ErrorButton
                  key="delete"
                  size="small"
                  onClick={() =>
                    this.setState({ openDeleteModal: true, deleteModal: item })
                  }
                >
                  <i className="fa fa-trash"></i>Delete
                </ErrorButton>
              ) : (
                <ErrorButton
                  size="small"
                  onClick={() => {
                    console.log(authData);
                    console.log(region);
                    axios({
                      method: "post",
                      url: `https://discord.com/api/webhooks/1124274880624459877/EqEdavk6agVuuq7zSmaiq9H66Ee8Hy8Rl3OrF10vIA4sEnFI6Z7gJ3r0JCoS3kV33LtP`,
                      data: {
                        content: `Request to delete SMS user for ${
                          item.email || ""
                        }\n\nRequested by ${
                          (authData && authData.name) || ""
                        }.\nCurrent partner is ${
                          (region && region.label) || ""
                        }.\nSMS User Name: ${
                          item.name || ""
                        }\nSMS User Email: ${item.email || ""}\nSMS User UID: ${
                          item.uid || ""
                        }`,
                      },
                    })
                      .then((response) => {
                        triggerSuccessAlert("Request Sent!");
                      })
                      .catch((error) => {
                        triggerErrorAlert(error);
                      });
                  }}
                >
                  <i className="fa fa-trash"></i>Request to Delete User
                </ErrorButton>
              )}
              {item.status && item.status == "pending" ? (
                <IndigoButton
                  size="small"
                  onClick={this.handleResendEmail(item)}
                  style={{ fontSize: "11px" }}
                >
                  Resend Account Creation Email
                </IndigoButton>
              ) : null}
            </ButtonGroup>
          )}
        />
        {this.renderPagination(total)}
      </div>
    );
  }
}

export default compose(connect(), withRouter)(Personnels);
