/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _snakeCase from 'lodash/snakeCase';
import _find from 'lodash/find';

import { isAdmin } from '../../helpers/auth';
import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists, validateEmail, isObjectExists } from '../../helpers/validation';
import { getSnapshotDataBySchema, getDocDataBySchema } from '../../helpers/schemas';

import { trainingLocationSchema } from '../../schemas/training_location';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getTrainingLocations = (props = {}) => {
    return new Promise((resolve,reject) => {
        const { region } = props;

        let Ref = firebase.firestore().collection("training_locations").where("status",'in', ['publish', 'pending']);

        // for non-admin, filter by region
        if ( region && !_isEmpty( region ) ) {
            Ref = Ref.where('region','==',region);
        } 
        
        Ref.get()
        .then(snapshot => {
            const list  = [];
            if ( snapshot && !snapshot.empty ) {
                snapshot.forEach( doc => {
                    let item = getDocDataBySchema( trainingLocationSchema, doc );
                    list.push(item)
                });
            } // end - snapshot
            resolve(list);
        })
        .catch(error => {
            reject(error);
        });
        

    })
}

export const fb_getTrainingLocationsOptions = (callback,props = {}) => {
    return new Promise((resolve,reject) => {
        const { region } = props;

        let Ref = firebase.firestore().collection("training_locations").where("status","==","publish");

        // for non-admin, filter by region
        if ( region && !_isEmpty( region ) ) {
            Ref = Ref.where('region','==',region);
        } 

        Ref.onSnapshot(snapshot => {
            const list = [];
            if ( snapshot ) {
                snapshot.forEach( doc => {
                    let item = getDocDataBySchema( trainingLocationSchema, doc );
                    list.push( item );
                });
            } // end - snapshot
            callback(list);
        });

    })
}

export const fb_addTrainingLocation = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'location', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateTrainingLocation = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'location', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteTrainingLocation = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'location', action: 'delete', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}