/* eslint-disable */
import React from 'react';
import styled from "styled-components";

export const fa = `
font-family: FontAwesome;
font-weight: normal;
font-style: normal;
text-decoration: inherit;
-webkit-font-smoothing: antialiased;
`;