import _random from 'lodash/random';

import { 
    M_GET_REGIONS,
    M_REGIONS_UPDATED,
    M_GET_ROLES,
    M_ROLES_UPDATED,
    M_GET_DESIGNATIONS,
    M_GET_COURSE_TYPE,
    M_GET_COURSE_DURATION,
    M_GET_PAYMENT_METHOD,
	M_GET_CURRENCY,
    M_GET_FEEDBACK_FORMS_FIELDS,
    M_GET_FEEDBACK_FORMS,
    M_GET_FEEDBACK_FORM,
    M_FEEDBACK_FORM_UPDATED,
    M_GET_REGISTRATION_FORMS_FIELDS,
    M_GET_REGISTRATION_FORMS,
    M_GET_REGISTRATION_FORM,
    M_REGISTRATION_FORM_UPDATED,
    M_GET_DISTRICTS,
    M_GET_RACES,
    M_GET_SCHOOLS,
    M_GET_BADGE_TYPE,
    M_GET_EMAIL_TEMPLATES,
    M_GET_RUBRICS,
    M_GET_CONTACT_HOUR,
	M_GET_PRODUCT_LEVEL,
	M_GET_PRODUCT_CREDIT,
    M_GET_PRICE_PER_PAX,
	M_GET_CERTIFICATES,
	M_GET_CERTIFICATE,
    M_CERTIFICATE_UPDATED,
    GET_GOOGLE_FONTS,
	MAINTENANCE_UPDATED,
    RESET_FEEDBACK_FORM,
    RESET_REGISTRATION_FORM,
    RESET_CERTIFICATE,
} from '../actions/types';

const initialState = {
    regions: null,
    roles: null,
    designations: null,
    currency: null,
    payment_method: null,
    course_type: null,
    course_duration: null,
    contact_hour: null,
    product_level: null,
    product_credit: null,
    price_per_pax: null,
    feedback_forms: null,
    feedback_forms_fields: null,
    feedback_form: null,
    registration_forms: null,
    registration_forms_fields: null,
    registration_form: null,
    email_templates: null,
    badge_type: null,
    rubrics: null,
    certificates: null,
    certificate: null,
    rand_feedback_form: false,
    rand_registration_form: false,
    rand_certificate: false,
    rand: false
};

export const maintenance = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case M_GET_REGIONS: return { ...state, regions: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_ROLES: return { ...state, roles: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_DESIGNATIONS: return { ...state, designations: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_COURSE_TYPE: return { ...state, course_type: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_DISTRICTS: return { ...state, districts: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_RACES: return { ...state, races: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_SCHOOLS: return { ...state, schools: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_COURSE_DURATION: return { ...state, course_duration: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_PAYMENT_METHOD: return { ...state, payment_method: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_CURRENCY: return { ...state, currency: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_FEEDBACK_FORMS: return { ...state, feedback_forms: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_FEEDBACK_FORMS_FIELDS: return { ...state, feedback_forms_fields: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_REGISTRATION_FORMS: return { ...state, registration_forms: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_REGISTRATION_FORMS_FIELDS: return { ...state, registration_forms_fields: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_REGISTRATION_FORM: return { ...state, registration_form: ( payload.registration_form || {} ), rand_registration_form: _random(1,9999) };
        case M_GET_EMAIL_TEMPLATES: return { ...state, email_templates: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_BADGE_TYPE: return { ...state, badge_type: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_FEEDBACK_FORM: return { ...state, feedback_form: ( payload.feedback_form || {} ), rand_feedback_form: _random(1,9999) };
        case M_GET_RUBRICS: return { ...state, rubrics: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_CONTACT_HOUR: return { ...state, contact_hour: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_PRODUCT_LEVEL: return { ...state, product_level: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_PRODUCT_CREDIT: return { ...state, product_credit: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_PRICE_PER_PAX: return { ...state, price_per_pax: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_CERTIFICATES: return { ...state, certificates: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_CERTIFICATE: return { ...state, certificate: ( payload.certificate || {} ), rand_certificate: _random(1,9999) };
        case GET_GOOGLE_FONTS: return { ...state, fonts: ( payload.list || [] ), rand_fonts: _random(1,9999) };

        case M_REGIONS_UPDATED:
        case M_ROLES_UPDATED:
        case MAINTENANCE_UPDATED:
            return { ...state, rand: _random(1,9999) };
        case M_FEEDBACK_FORM_UPDATED:
            return { ...state, rand_feedback_form: _random(1,9999) };
        case M_CERTIFICATE_UPDATED:
            return { ...state, rand_certificate: _random(1,9999) };
        case RESET_CERTIFICATE:
            return { 
                ...state,
                certificate: null,
                rand_certificate: false
            };
        case RESET_FEEDBACK_FORM:
            return { 
                ...state,
                feedback_form: null,
                rand_feedback_form: false
            };
        case RESET_REGISTRATION_FORM:
            return { 
                ...state,
                registration_form: null,
                rand_registration_form: false
            };
        default:
            return state;
    }
}