/* eslint-disable */
import axios from 'axios';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import _forEach from 'lodash/forEach';
import _random from 'lodash/random';
import _snakeCase from 'lodash/snakeCase';
import qs from 'qs';

import { FIREBASE_CLOUD_API } from '../constants';
import { isArrayExists, isObjectExists } from './validation';
import { getIDToken, getCurrentUser } from '../actions/auth/fb';

// trigger Functions API
export const callFunctionsAPI = ( props ) => {
    const { url, action, formData, method = 'post', params = [] } = props;
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method,
                url,
                params: ( params && isArrayExists( params ) ? [{ key: 'action', value: action }, ...params] : [{ key: 'action', value: action }] ),
                formData
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    });
}

/* perform axios via promise */
export const doPromise = function( props ) {
    const { idToken, method, url, formData, params, api_url } = props;
    return new Promise((resolve,reject) => {

        var paramString = '';
        if ( isArrayExists( params ) ) {
            var paramCount = 1;
            _forEach( params, function(param) {
                paramString += ( paramCount > 1 ? '&' : '' ) + param.key + "=" + param.value;
                paramCount++;
            });
        }

        var query = { 
            method: method, 
            baseURL: api_url || FIREBASE_CLOUD_API, 
            url: '/' + url + ( idToken ? '?token=' + idToken + ( !_isEmpty( paramString ) ? '&' + paramString : '' ) : ( !_isEmpty( paramString ) ? '?' + paramString : '' ) ),
        };

        if ( formData && !_isEmpty( formData ) ) {
            query['headers'] = { 'Content-Type': 'application/x-www-form-urlencoded' };
            query['data'] = qs.stringify(formData)
        }

        axios(query)
        .then(function(response) {
            resolve(response);
        })
        .catch(function(error) {
            reject(error);
        });

    });
};

// trigger single / multiple promises 
export const triggerPromises = (promises) => {
    return ( promises && isArrayExists( promises ) ? Promise.all( promises ) : false );
}

// trigger single / multiple dispatch using promises 
export const triggerDispatches = (actions,dispatch) => {
    var promises = [];

    if ( actions && isArrayExists( actions ) ) {
        _forEach( actions, action => {
            promises.push( dispatch(action) );
        });
    } // end - actions

    return ( isArrayExists( promises ) ? Promise.all( promises ) : false );
}

// get error message
export const getAPIErrorMessage = (error) => {
    return ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unknown API Error' ) );
}