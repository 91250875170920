/* eslint-disable */
import React from "react";
import styled from "styled-components";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Typography from "@material-ui/core/Typography";
import _random from "lodash/random";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";
import _toLower from "lodash/toLower";
import _replace from "lodash/replace";

import { isArrayExists } from "../../helpers/validation";

import theme from "../../theme";

const Select2Wrapper = styled.div`
    padding: 0 5px;

    .react-select__control {
        border-top: none;
        border-left: none;
        border-right: none;
    }

    .react-select-container > div:not(.react-select__control),
    .react-select__menu {
        z-index: 9999;

        .react-select__option {
            font-size: 13px;
        }
    }
`;

class FormSelect2 extends React.Component {
    handleChange = (selectedOption) => {
        const { name } = this.props;
        if (this.props.onChange) {
            if (selectedOption && selectedOption.value) {
                this.props.onChange(selectedOption.value, name);
            } else {
                this.props.onChange("", name);
            } // end - selectedOption
        } // end - onChange
    };

    handleAsyncLoad = (inputValue, callback) => {
        const { asyncLoadOptions } = this.props;
        if (asyncLoadOptions) {
            asyncLoadOptions(inputValue).then((options) => {
                callback(options);
            });
        } // end - asyncLoadOptions
    };

    handleInputChange = (newValue) => {
        const { onInputChange, name } = this.props;
        if (onInputChange) onInputChange(newValue, name);
    };

    getValue = () => {
        const { value, options } = this.props;
        let selected = [],
            option = options && isArrayExists(options) ? _find(options, { value }) : false;

        if (option) selected.push(option);

        return selected;
    };

    getSelectProps = () => {
        const {
            id,
            async,
            label,
            options,
            disabled,
            placeholder,
            className,
            classNamePrefix,
            menuPosition,
            menuPortalTarget,
            cacheOptions,
            isClearable,
            isLoading,
            isSearchable,
            isOptionDisabled = () => false,
            styles = {},
        } = this.props;

        var newID = "multi-select-" + _random(1, 9999),
            props = {
                inputId: id || newID,
                className: className || "react-select-container",
                classNamePrefix: classNamePrefix || "react-select",
                TextFieldProps: {
                    label,
                    InputLabelProps: {
                        htmlFor: "react-select-single",
                        shrink: true,
                    },
                },
                menuPosition: menuPosition || "absolute",
                menuPortalTarget: menuPortalTarget || null,
                placeholder: placeholder || null,
                options: options || [],
                value: this.getValue(),
                isDisabled: disabled || false,
                isClearable: isClearable | false,
                isLoading: isLoading | false,
                isSearchable: isSearchable | false,
                isOptionDisabled,
                // menuIsOpen: true,
                styles,
                onChange: this.handleChange,
            };

        if (async) {
            props["defaultOptions"] = options || [];
            props["cacheOptions"] = cacheOptions || [];
            props["loadOptions"] = this.handleAsyncLoad;
            props["onInputChange"] = this.handleInputChange;
        } // end - async

        return props;
    };

    renderLabel = () => {
        const { label } = this.props;
        var newLabel = false;
        if (label && !_isEmpty(label) && (_toLower(label).indexOf("(required)") >= 0 || _toLower(label).indexOf("*") >= 0)) {
            newLabel = _replace(label, "(Required)", "");
            newLabel = _replace(newLabel, "(required)", "");
            newLabel = _replace(newLabel, "*", "");
        } // end - label
        return newLabel ? (
            <span>
                {newLabel || ""}
                <span className="required">*</span>
            </span>
        ) : (
            label || ""
        );
    };

    render = () => {
        const { async, desc } = this.props;
        return (
            <Select2Wrapper>
                <InputLabel
                    style={{ fontSize: "12px", fontWeight: "700", textTransform: "uppercase", background: "#fff", padding: "0px 5px 5px 5px" }}
                >
                    {this.renderLabel()}
                </InputLabel>
                {async ? <AsyncSelect {...this.getSelectProps()} /> : <Select {...this.getSelectProps()} />}
                {desc && !_isEmpty(desc) ? (
                    <Typography variant="body1" style={{ padding: "5px" }}>
                        {desc}
                    </Typography>
                ) : null}
            </Select2Wrapper>
        );
    };
}

export default FormSelect2;
