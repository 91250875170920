/* eslint-disable */
import _random from 'lodash/random';

import { 
	STUDENT_SHOWCASE_GET_FEEDBACK_FORM,
	STUDENT_SHOWCASE_UPDATE_FEEDBACK_FORM,
    STUDENT_SHOWCASE_GET_DATES,
    STUDENT_SHOWCASE_UPDATE_DATE,
    STUDENT_SHOWCASE_GET_PROJECT,
    STUDENT_SHOWCASE_UPDATE_PROJECT,
    STUDENT_SHOWCASE_GET_TEAM_PROJECT,
    STUDENT_SHOWCASE_UPDATE_TEAM_PROJECT,
    RESET_STUDENT_SHOWCASE,
} from '../actions/types';

const initialState = {
    feedback_form: null,
    rand: false
};

export const student_showcase = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case STUDENT_SHOWCASE_GET_FEEDBACK_FORM:
            return {
                ...state,
                feedback_form: payload.feedback_form || {},
                rand: _random(1,9999)
            };
        case STUDENT_SHOWCASE_GET_DATES:
            return {
                ...state,
                showcase: payload.showcase || {},
                rand: _random(1,9999)
            };
        case STUDENT_SHOWCASE_GET_PROJECT:
            return {
                ...state,
                project: payload.project || {},
                rand: _random(1,9999)
            };
        case STUDENT_SHOWCASE_GET_TEAM_PROJECT:
            return {
                ...state,
                team_project: payload.project || {},
                rand: _random(1,9999)
            };
        case STUDENT_SHOWCASE_UPDATE_FEEDBACK_FORM:
        case STUDENT_SHOWCASE_UPDATE_DATE:
        case STUDENT_SHOWCASE_UPDATE_PROJECT:
        case STUDENT_SHOWCASE_UPDATE_TEAM_PROJECT:
            return {
                ...state,
                rand: _random(1,9999)
            };
        case RESET_STUDENT_SHOWCASE: 
            return {
                ...state,
                feedback_form: null,
                showcase: null,
                project: null,
                team_project: null,
                rand: false
            };
        default:
            return state;
    }
}