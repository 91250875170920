/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
// import moment from 'moment';
// import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _filter from "lodash/filter";
import _find from "lodash/find";
import _size from "lodash/size";
import _isEqual from "lodash/isEqual";
import _findIndex from "lodash/findIndex";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
// import { NotionRenderer } from "react-notion-x";

import AppWrapper from "../../components/AppWrapper";
import AppInfoBox from "../../modules/AppInfoBox";
import FormSelect2 from "../../components/FormSelect2";
// import Table from './table';
// import FilterMenu from './filter_menu';
// import LatestStats from './latest_stats';

import { isAdmin } from "../../helpers/auth";
import { getSelectOptions } from "../../helpers/data";
// import { getMomentTime } from '../../helpers/date';
import { isArrayExists } from "../../helpers/validation";
// import { triggerErrorAlert } from '../../helpers/alert';
// import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';

import { getRegions } from "../../actions/m_regions";
// import { toggleLoader } from '../../actions/global';

// import { ButtonGroup, AInfoLink, InfoButton } from '../../styles/button';
// import { SuccessTag } from '../../styles/tag';
// import { WarningBox, InfoBox } from '../../styles/message';

// import { DEV_MODE } from '../../constants';

const ResultWrapper = styled.div`
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px solid #ddd;
`;

const BusinessDashboard2 = ({ authData, dispatch, regionsList }) => {
  const [selectedRegion, setSelectedRegion] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (regionsList) {
      setSelectedRegion(authData.region);
      setLoading(false);
    } else {
      dispatch(getRegions());
    }
  }, [regionsList, authData]);

  const selectedRegionLabel = useMemo(() => {
    const selected = _find(regionsList, (item) => item.id === selectedRegion);
    return selected ? selected.label : "";
  }, [selectedRegion, regionsList]);

  return (
    <AppWrapper
      subtitle="Summary"
      title="Business Dashboard"
      onLoad={false}
      contents={
        authData ? (
          <>
            <AppInfoBox page="BusinessDashboard" />
            <Grid container spacing={2} style={{ marginBottom: "30px" }}>
              <Grid item xs={12} sm={5}>
                <div style={{ padding: "10px" }}>
                  <FormSelect2
                    value={selectedRegion || ""}
                    label="Partner"
                    name="region"
                    options={getSelectOptions({
                      list:
                        regionsList && isArrayExists(regionsList)
                          ? _sortBy(regionsList, ["label"])
                          : [],
                      options: [{ value: "", label: "All Partners" }],
                      keys: { value: "id", label: "label" },
                    })}
                    disabled={isAdmin(authData) ? false : true}
                    isSearchable={true}
                    onChange={(newValue) => setSelectedRegion(newValue)}
                  />
                </div>
              </Grid>
            </Grid>
            {!loading && (
              <iframe
                src={`https://lookerstudio.google.com/embed/reporting/9f1c8dae-55c9-4b1c-b5ca-49962188d705/page/p_7t9cfqf17c?params=%7B%22df259%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${selectedRegionLabel}%22%7D`}
                frameborder="0"
                style={{ width: "100%", height: "100vh" }}
                allowfullscreen
              ></iframe>
            )}
          </>
        ) : null
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    authData: state.auth && state.auth.user ? state.auth.user : null,
    regionsList:
      state.maintenance && state.maintenance.regions
        ? state.maintenance.regions
        : false,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter
)(BusinessDashboard2);
