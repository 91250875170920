/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _split from 'lodash/split';
import _map from 'lodash/map';
import _trim from 'lodash/trim';

import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';

import { FormBox } from '../../styles/form';
import { AInfoLink } from '../../styles/button';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';

import { userStudentSchema } from '../../schemas/user_student';
import { COUNTRIES_LIST } from '../../data/const_countries';
import { STATE_OPTIONS } from '../../data/const_states';

import { YES_NO_OPTIONSE } from '../../constants';

const useStyles = theme => ({
    section_title: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingTop: "15px",
        paddingBottom: "15px",
        marginBottom: "5px",
        borderBottom: "1px solid #ddd"
    }
});

class ShowcaseDates extends React.Component {

    utcOffSet = 8;

    state = {
        randNum: false
    };

    handleFormUpdate = ( newValue, key, subkey, subVal ) => {
        const { onFormUpdate, showcase } = this.props;
        let newTrainee = ( showcase && showcase.trainee && isObjectExists( showcase.trainee ) ? cloneCollections( showcase.trainee ) : {} );

        switch( key ) {
            case 'calendar_id':
                let selected_event = ( showcase && showcase.class_calendar && isArrayExists( showcase.class_calendar ) ? _find( showcase.class_calendar, { id: newValue } ) : false );
                if ( selected_event && selected_event.start_date ) {
                    newTrainee[key] = newValue;
                    newTrainee.showcase_date = ( selected_event.start_date || 0 );
                    newTrainee.booking_date = moment().utcOffset(this.utcOffSet).valueOf();
                } // end - selected_event
                break;
        }

        // do update
        if ( onFormUpdate )
            onFormUpdate( newTrainee );
    }

    getFieldOptions = (field) => {
        const { showcase } = this.props;
        let options = [];
        switch( field.name ) {
            case 'calendar_id':
                options = [{ value: '', label: 'Select a Date' }];
                if ( showcase && showcase.class_calendar && isArrayExists( showcase.class_calendar ) ) {
                    _forEach( showcase.class_calendar, event => {
                        if ( event.showcase && event.showcase === 'yes' )
                            options.push({ value: event.id, label: getMomentTime( event.start_date, 'DD MMM YYYY' ) });
                    });
                } // end - showcase.class_calendar
                return options;
            default:
                return options;
        }
    }

    getFieldValue = (field) => {
        const { showcase } = this.props;
        switch( field.name ) {
            default:
                return ( showcase && showcase.trainee && showcase.trainee[field.name] ? showcase.trainee[field.name] : '' );
        }
    }

    renderShowcaseDate = () => {
        const { showcase } = this.props;
        let field = {
            name: 'calendar_id',
            label: 'Select a Date (Required)',
            default: '',
            disabled: ( showcase && showcase.trainee && showcase.trainee.showcase && showcase.trainee.showcase.badge_id && !_isEmpty( showcase.trainee.showcase.badge_id ) ? true : false )
        };
        return (
        <div style={{ paddingTop: "14px" }}>
            <FormSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} />
        </div>
        )
    }

    render = () => {
        const { classes, showcase } = this.props;
        return (
        <FormBox>
            { showcase && showcase.course && showcase.course.course_rubric_url && !_isEmpty( showcase.course.course_rubric_url ) ? <Grid item xs={12}><AInfoLink href={showcase.course.course_rubric_url} target="_blank" rightIcon="yes" style={{ padding: '5px 10px', marginBottom: '10px' }}>Read Rubric<i className="fa fa-long-arrow-right"></i></AInfoLink></Grid> : null }
            <Grid container spacing={3}>
                <Grid item xs={12}>{this.renderShowcaseDate()}</Grid>
            </Grid>
            <Grid item xs={12}><Typography variant="body1"  style={{ color: '#D32F2F', fontWeight: "700", marginTop: '20px' }}>* Compulsory to fill in</Typography></Grid>
        </FormBox>
        );
    }

}

export default compose(
    withStyles(useStyles),
    withRouter
)(ShowcaseDates);