/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AppWrapper from '../../components/AppWrapper';
import List from './list';

import { getMaintenance } from '../../actions/maintenance';
import { getOneOffBadges } from '../../actions/badges';
import { resetRedux } from '../../actions/misc';

class MaintenanceOneOffBadges extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {
        const { courseTypeList, badgeTypeList, certificatesList } = this.props;
        // get list
        this.props.dispatch(getOneOffBadges('oneoff'));

        // get course_type
        if ( !courseTypeList )
            this.props.dispatch(getMaintenance('course_type'));

        // get course_type
        if ( !badgeTypeList )
            this.props.dispatch(getMaintenance('badge_type'));

        // get certificate templates
        if ( !certificatesList )
            this.props.dispatch(getMaintenance('certificates'));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { badgesList, courseTypeList, badgeTypeList, certificatesList, randNum } = this.props;

        if ( badgesList && courseTypeList && badgeTypeList && certificatesList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('oneoff_badges'));
    }

    renderContents() {
        const { badgesList, authData } = this.props;
        return <List 
                type="badge"
                typeLabel="One Off Badges"
                authData={authData} 
                list={badgesList} />;
    }

    render() {
        const { badgesList, randNum } = this.props;
        return <AppWrapper 
                title="One Off Badges"
                subtitle="Maintenance"
                onLoad={( !( badgesList && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        badgesList: state.badges && state.badges.badges_one_off ? state.badges.badges_one_off : null,
        randNum: state.badges && state.badges.rand ? state.badges.rand : null,
        courseTypeList: state.maintenance && state.maintenance.course_type ? state.maintenance.course_type : null,
        badgeTypeList: state.maintenance && state.maintenance.badge_type ? state.maintenance.badge_type : null,
        certificatesList: state.maintenance && state.maintenance.certificates ? state.maintenance.certificates : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(MaintenanceOneOffBadges);