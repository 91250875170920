/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Papa from 'papaparse';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

import Form from './form';
import ImportModal from './import';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormSelect2 from '../../components/FormSelect2';
import FormDatePicker from '../../components/FormDatePicker';
import FormDateOfBirth from '../../components/FormDateOfBirth';
import FormPhoneField from '../../components/FormPhoneField';
import ButtonActions from '../../components/ButtonActions';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton, SuccessButton } from '../../styles/button';
import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { getAPIErrorMessage } from '../../helpers/action';
import { isArrayExists, isObjectExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue,  } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp, getAge } from '../../helpers/date';
import { isAdmin, hasAccessRights, getUserRegionFilter } from '../../helpers/auth';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { addMarketingData, updateMarketingData, deleteMarketingData } from '../../actions/database_marketing';
import { toggleLoader } from '../../actions/global';

import { marketingDatabaseSchema } from '../../schemas/marketing_database';

import { GENDER_OPTIONS, PLATFORM_ADMIN_DEFAULT_REGION } from '../../constants';

class MentorsTable extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        sortBy: 'date-desc',
        filterBy: 'all',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false,
        openViewModal: false,
        modalData: false,
        modalType: false,
        openImportModal: false,
        randNum: false,
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        
    }

    doCSVDownload = (results) => {
        let csv = Papa.unparse( results, { delimiter: ',' } );
        let blob = new Blob( [csv] , { type: 'text/csv;charset=utf-8;' }); //new way
        let uri = window.URL.createObjectURL(blob);
        let link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", '_blank');
        link.setAttribute("download", "export_marketing_data.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    handleCSVDownload = () => {
        const { list, regions } = this.props;
        const { checked } = this.state;
        if ( checked && isArrayExists( checked ) ) {
            let csvData = {
                    fields: [
                        "Partner",
                        "Name",
                        "Email",
                        "Organisation",
                        "City",
                        "Position",
                        "Mobile No.",
                        "Subscribe to Newsletter",
                        "Added On",
                        "Notes"
                    ],
                    data: []
                };
            _forEach( checked, item => {
                let contact = _find( list, { id: item.id } );
                if ( contact && !_isEmpty( contact ) ) {
                    let row = [];
                    row.push( getSelectedValue( regions, contact.region, 'id', 'label', '-' ) );
                    row.push( ( contact && contact.name || '' ) );
                    row.push( ( contact && contact.email || '' ) );
                    row.push( ( contact && contact.org || '' ) );
                    row.push( ( contact && contact.city || '' ) );
                    row.push( ( contact && contact.position || '' ) );
                    row.push( ( contact && contact.phone || '' ) );
                    row.push( ( contact && contact.newsletter && contact.newsletter === 'no' ? 'no' : 'yes' ) );
                    row.push( ( contact && contact.added_on ? getMomentTime( contact.added_on, 'DD MMM YYYY' ) : '' ) );
                    row.push( ( contact && contact.notes || '' ) );
                    csvData.data.push(row);
                } // end - trainee
            });
            this.doCSVDownload(csvData);
        } else {
            triggerErrorAlert('Please select at least one contact');
        } // end - checked
    }

    handleDatabaseDelete = () => {
        const { authData } = this.props;
        const { deleteModal } = this.state;
        // perform delete
        this.props.dispatch(deleteMarketingData(deleteModal.id));
    }

    handleDatabaseUpdate = () => {
        const { authData } = this.props;
        const { modalData } = this.state;
        let error = false,
            formData = {};

        // do error check
        marketingDatabaseSchema.forEach(schema => {
            formData[schema.id] = ( modalData && modalData[schema.id] ? cloneCollections( modalData[schema.id] ) : schema.default );
            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if ( error ) {
            triggerErrorAlert( error );
        } else {
            this.props.dispatch(updateMarketingData(formData));
        }
    }

    handleAddNew = () => {
        const { modalData } = this.state;
        let error = false,
            formData = ( modalData ? cloneCollections( modalData ) : {} );

        // do error check
        marketingDatabaseSchema.forEach(schema => {
            formData[schema.id] = ( modalData && modalData[schema.id] ? cloneCollections( modalData[schema.id] ) : schema.default );
            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'add' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if ( error ) {
            triggerErrorAlert( error );
        } else {
            this.props.dispatch(addMarketingData(formData));
        }
    }

    handleAction = () => {
        const { modalType } = this.state;
        if ( modalType && modalType == 'new' ) {
            this.handleAddNew();
        } else if ( modalType && modalType == 'update' ) {
            this.handleDatabaseUpdate();
        } // end - modalType
    }

    handleFormUpdate = (newValue,key,subkey,subVal) => {
        const { modalData } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} );
        switch( key ) {
            // case 'course_type':
            //     let selectedType = ( course_type && isArrayExists( course_type ) ? _find( course_type, { id: newValue } ) : false );
            //     newData[key] = newValue;
            //     newData[key + '_label'] = ( selectedType && selectedType.label ? selectedType.label : '' );
            //     break;
            default:
                if ( subkey && ( subkey === 'checked' || subkey === 'unchecked' ) && subVal && !_isEmpty( subVal ) ) {
                    // use subVal as the new value
                    newData[key] = subVal;
                } else {
                    // otherwise - just update normally
                    newData[key] = newValue
                }
                break;
        } // end - key
        this.setState({ modalData: newData });
    }

    getActions = () => {
        const { authData } = this.props;
        let actions = [];

        actions.push({ 
            id: 'addnew', label: 'Add New Entry', icon: 'fa-plus', onClick: () => this.setState({
                openViewModal: true,
                modalType: 'new',
                modalData: this.getDefaultValue()
            })
        });

        actions.push({ 
            id: 'downloadcsv', label: 'Download Selected as CSV', icon: 'fa-cloud-download', onClick: this.handleCSVDownload
        });

        actions.push({ 
            id: 'bulkupload', label: 'Bulk Upload', icon: 'fa-cloud-upload', onClick: () => this.setState({ openImportModal: true })
        });

        return actions;
    }

    getDefaultValue = () => {
        const { authData } = this.props;
        let formData = {};
        marketingDatabaseSchema.forEach(schema => {
            switch (schema.id) {
                case 'region':
                    formData[schema.id] = ( isAdmin( authData ) ? '' : ( authData && authData.region && !_isEmpty( authData.region ) ? authData.region :  PLATFORM_ADMIN_DEFAULT_REGION ) );
                    break;
                default:
                    formData[schema.id] = ( schema.default || '' );
                    break;
            }
        });
        return formData;
    }

    reorganizeData() {
        const { searchterms, sortBy, perPage, page , filterBy } = this.state;
        const { list } = this.props;
        let items = ( list ? cloneCollections( list ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','email','org','city'] );
			total = _size( items );
        } // end - searchterms

        // do filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy !== 'all' ) {
            items = _filter( items, { region: filterBy });
            total = _size( items );
        } // end - filterBy

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['added_on'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['added_on'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
                case 'email-desc':
                    items = _sortBy( items, ['email'] );
                    items = _reverse( items );
                    break;
                case 'email-asc':
                    items = _sortBy( items, ['email'] );
                    break;
                case 'org-desc':
                    items = _sortBy( items, [i => i.org.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'org-asc':
                    items = _sortBy( items, [i => i.org.toLowerCase()] );
                    break; 
                case 'city-desc':
                    items = _sortBy( items, [i => i.city.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'city-asc':
                    items = _sortBy( items, [i => i.city.toLowerCase()] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderModalForm = () => {
        const { authData, regions } = this.props;
        const { modalData, modalType } = this.state;
        return (
        <div>
            <Form
                type={( modalType || false )}
                venue={( modalData || false )}
                authData={authData}
                regions={regions}
                disableSystemDate={true} // hide this for now - since the old date doesn't have timestamp
                onFormUpdate={(newValue) => this.setState({ modalData: newValue })} />
        </div>
        );
    }

    renderCell = type => item => {
        const { regions, schedules } = this.props;
        switch( type ) {
            case 'region':
                return getSelectedValue( regions, item[type], 'id', 'label', '-' );
            case 'added_on':
                return <WrapWord>{( item[type] ? getMomentTime( item[type], 'DD MMM YYYY' ) : '---' )}</WrapWord>;
            case 'email':
                return <WrapWord>{( item[type] || '-' )}</WrapWord>;
        }
    }

    renderTableActions = () => {
        const { authData, regions } = this.props;
        const { sortBy, perPage, searchterms, filterBy, checked } = this.state;
        return <TableBar
                sortBy={sortBy}
                filterBy={filterBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Modified first' },
                    { value: 'date-asc', label: 'Oldest Modified first' },
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' },
                    { value: 'email-asc', label: 'Email ( A - Z)' },
                    { value: 'email-desc', label: 'Email ( Z - A )' },
                    { value: 'org-asc', label: 'Organisation ( A - Z)' },
                    { value: 'org-desc', label: 'Organisation ( Z - A )' },
                    { value: 'city-asc', label: 'City ( A - Z)' },
                    { value: 'city-desc', label: 'City ( Z - A )' },
                ]}
                leftButtons={ checked && isArrayExists( checked ) ? [
                    <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                        <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                    </div>
                    ] : null }
                rightButtons={[
                    <ButtonActions 
                        key="actions" 
                        label="Actions"
                        menuContainerStyle={{ width: "250px" }}
                        style={{ marginRight: "5px" }}
                        actions={this.getActions()} />
                ]}
                filterByOptions={ isAdmin( authData ) ? getSelectOptions({ list: regions, options: [{ value: 'all', label: 'All' }], keys: { value: 'id', label: 'label' }, sortBy: 'label' }) : null }
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    doneLoaded={true}
                    // entriesLabel="(Total Registration For This Class)"
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { authData, regions, list } = this.props;
        const { openDeleteModal, deleteModal, openViewModal, modalData, modalType, allCheck, checked, openImportModal } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={openViewModal}
                title={ modalType && modalType === 'update' ? "Update Entry" : "Add New Entry" }
                maxWidth="md"
                onClose={() => this.setState({ openViewModal: false, modalData: false, modalType: false })}
                disableBackdrop={true}
                actionLabel={ modalType && modalType === 'update' ? "Update" : "Add New" }
                doAction={this.handleAction}
                contents={this.renderModalForm()} />

            <ImportModal
                open={openImportModal}
                authData={authData}
                existingList={list}
                regions={regions}
                onClose={() => this.setState({ openImportModal: false })} />

            { authData && hasAccessRights(authData,['mdd']) ? <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to remove this entry ( ${deleteModal.name} ) from the list?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDatabaseDelete} /> : null }

            {this.renderTableActions()}
            <div style={{ padding: '0 0 10px 0' }}>
                {this.renderPagination(total)}
            </div>
            <Table 
                uniqueKey="id"
                items={items}
                showCheckbox={true}
                emptyCell="No item(s) Found."
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={(newValue) => this.setState({ checked: newValue })}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                cells={[
                    { id: 'region', label: 'Partner', render: this.renderCell('region') },
                    { id: 'name', label: 'Name', render: (item) => <WrapWord>{( item.name || '-' )}</WrapWord> },
                    { id: 'org', label: 'Organisation', render: (item) => <WrapWord>{( item.org || '-' )}</WrapWord> },
                    { id: 'city', label: 'City', render: (item) => <WrapWord>{( item.city || '-' )}</WrapWord> },
                    { id: 'position', label: 'Position', render: (item) => <WrapWord>{( item.position || '-' )}</WrapWord> },
                    { id: 'phone', label: 'Mobile No.', render: (item) => <WrapWord>{( item.phone || '-' )}</WrapWord> },
                    { id: 'email', label: 'Email', render: this.renderCell('email') },
                    { id: 'added_on', label: 'Added On', render: this.renderCell('added_on') },
                ]}
                actionStyles={{ width: "15%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <InfoButton key="edit" size="small" onClick={() => this.setState({ 
                            openViewModal: true,
                            modalData: ( item && isObjectExists(item) ? cloneCollections( item ) : {} ),
                            modalType: 'update'
                        })}><i className="fa fa-edit"></i>Edit</InfoButton>
                        { authData && hasAccessRights( authData, ['mdd'] ) ? <ErrorButton key="delete" size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton> : null }
                    </ButtonGroup>
                )} />
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(MentorsTable);