import _random from 'lodash/random';

import { 
	GET_TRAINING_LOCATIONS,
	GET_TRAINING_LOCATIONS_OPTIONS,
	ADD_TRAINING_LOCATION,
	EDIT_TRAINING_LOCATION,
	DELETE_TRAINING_LOCATION,
    RESET_TRAINING_LOCATIONS
} from '../actions/types';

const initialState = {
    locations: null,
    locations_options: null,
    updated: false,
    rand_options: false,
    rand: false
};

export const training_locations = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_TRAINING_LOCATIONS:
            return {
                ...state,
                locations: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case GET_TRAINING_LOCATIONS_OPTIONS:
            return {
                ...state,
                locations_options: ( payload.list || [] ),
                rand_options: _random(1,9999)
            };
        case ADD_TRAINING_LOCATION:
        case EDIT_TRAINING_LOCATION:
        case DELETE_TRAINING_LOCATION:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_TRAINING_LOCATIONS:
            return {
                ...state,
                locations: null,
                rand: false
            };
        default:
            return state;
    }
}