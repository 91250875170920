/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from 'moment';
import Papa from 'papaparse';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _toLower from 'lodash/toLower';
import _split from 'lodash/split';

import ReviewShowcase from '../ClassShowcasePage/review_showcase';
import StudentNameToDatabase from '../../modules/StudentNameToDatabase';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';
import FormCheckbox from '../../components/FormCheckbox';
import ButtonActions from '../../components/ButtonActions';
import StudentEnrollmentStatus from '../../components/StudentEnrollmentStatus';
import ToolTipInfo from '../../components/ToolTipInfo';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from '../../styles/tag';
import { ErrorBox } from '../../styles/message';
import { WrapWord } from '../../styles/misc';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp, getAge } from '../../helpers/date';
import { isAdmin, hasAccessRights, getUserRegionFilter } from '../../helpers/auth';
import { getStudentEnrollmentStatus, getStudentEnrollmentStatusLabel } from '../../helpers/trainees';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { editTrainee, deleteTrainee } from '../../actions/trainees';
import { appChangesMade } from '../../actions/misc';

import { traineeSchema } from '../../schemas/trainee';

import { PLATFORM_COURSE_REGISTRATION_LINK, GENDER_OPTIONS, ENROLLMENT_STATUS_OPTIONS, YES_NO_OPTIONS } from '../../constants';

const SuccessIcon = styled.i`
    color: #64dd17;
`;

const GreyIcon = styled.i`
    color: #64dd17;
`;

const WarningIcon = styled.i`
    color: #ff6f00;
`;

const AttendanceColumn = styled.div`
    padding: 16px;
    font-size: 11px;
    height: 100%;
    width: 100%;
    max-width: 200px;
    ${props => {
        if ( props.overdue && props.overdue === 'yes' ) {
            return `
            background: #ffebee;
            `;
        }
        if ( props.disabled && props.disabled === 'yes' ) {
            return `
            .disabled-wrapper {
                opacity: 0.4;
            }
            `;
        }
    }};
`;

const RadioWrapper = styled.div`
    .MuiFormControl-fullWidth {
        background: none;
    }
    .MuiFormLabel-root,
    .MuiTypography-body1 {
        font-size: 11px;
    }

    ${props => {
        if ( props.disablePointer && props.disablePointer === 'yes' ) {
            return `
            .MuiFormControlLabel-root,
            .MuiIconButton-label > input {
                cursor: default;
            }
            `;
        }
    }};
`;

const TextareaWrapper = styled.div`
    textarea {
        width: 100%;
        max-width: 150px;
        font-size: 11px;
        padding: 2px;
        overflow: scroll-y;
    }
`;

// course access schema
const PAYMENT_TRACKING_SCHEMA = {
    id: '', // id based on date format YYYY-MM,
    payment_made: 'no', // course profile ID
    invoice_sent: 'no', // from course profile
    notes: ''
};

class StudentsTable extends React.Component {

    utcOffSet = 8;
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        filterBy: 'active',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openAttendanceModal: false,
        modalData: false,
        traineeData: false,
        modalType: false,
        openMentorCommentsModal: false,
        randNum: false,
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        
    }

    doCSVDownload = (results) => {
        let csv = Papa.unparse( results, { delimiter: ',' } );
        let blob = new Blob( [csv] , { type: 'text/csv;charset=utf-8;' }); //new way
        let uri = window.URL.createObjectURL(blob);
        let link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", '_blank');
        link.setAttribute("download", "export_attendance_reporting.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    handleCSVDownload = (event) => {
        const { trainees, columns, regions, mentors } = this.props;
        const { checked } = this.state;
        if ( checked && isArrayExists( checked ) ) {
            let csvData = {
                    fields: [
                        "Partner",
                        "Name",
                        "Email",
                        "Enrolment Status",
                        "Mentor",
                        "Mentor's General Comment",
                    ],
                    data: []
                };

            if ( columns && isArrayExists( columns ) ) {
                _forEach( _sortBy(columns,['start_date']), column => {
                    csvData.fields.push(( column.label || column.id ) + " - Attendance");
                    csvData.fields.push(( column.label || column.id ) + " - Notes");
                });
            } // end - columns

            _forEach( checked, selected => {
                let item = _find( trainees, { id: selected.id } );
                if ( item && !_isEmpty( item ) ) {
                    let row = [];
                    row.push( getSelectedValue( regions, item.region, 'id', 'label', '-' ) );
                    row.push( ( item && item.name || '' ) );
                    row.push( ( item && item.email || '' ) );
                    row.push( getStudentEnrollmentStatusLabel( getStudentEnrollmentStatus( item ) ) );
                    row.push( getSelectedValue( mentors, item.mentor, 'email', 'name', '-' ) );
                    row.push( ( item && item.mentor_comments || '' ) );

                    if ( columns && isArrayExists( columns ) ) {
                        _forEach( _sortBy(columns,['start_date']), column => {
                            let attendance = ( item && item.attendances && isArrayExists( item.attendances ) ? _find( item.attendances, { id: column.id } ) : false );
                            row.push( ( attendance && attendance.attend && ( attendance.attend === 'yes' || attendance.attend === 'no' ) ? attendance.attend : 'none' ) );
                            row.push( ( attendance && attendance.notes || '' ) );
                        });
                    } // end - columns

                    csvData.data.push(row);
                } // end - trainee
            });
            this.doCSVDownload(csvData);
        } else {
            triggerErrorAlert('Please select at least one student');
        } // end - checked
    }

    handleSendEmail = (email_type) => event => {
        event.preventDefault();
    }

    handleTraineeUpdate = (newData,trainee_id) => {
        const { trainees, onUpdate } = this.props;
        let newTrainees = ( trainees && isArrayExists( trainees ) ? cloneCollections( trainees ) : [] ),
            index = ( newTrainees && isArrayExists( newTrainees ) ? _findIndex( newTrainees, { id: trainee_id }) : -1 );
        if ( index >= 0 ) {
            newTrainees[index].attendances = newData;
        }
        if ( onUpdate ) {
            onUpdate(newTrainees);
            this.props.dispatch(appChangesMade());
        }
    }

    handleMentorCommentsUpdate = () => {
        const { trainees, onUpdate, authData } = this.props;
        const { modalData } = this.state;
        let error = false,
            newTrainees = ( trainees && isArrayExists( trainees ) ? cloneCollections( trainees ) : [] ),
            index = ( newTrainees && isArrayExists( newTrainees ) && modalData && modalData.id ? _findIndex( newTrainees, { id: modalData.id }) : -1 );

        // make sure comments doesn't exceed 1000 chars
        if ( modalData && modalData.mentor_comments && !_isEmpty( modalData.mentor_comments ) && _size( modalData.mentor_comments ) > 1000 )
            error = "Comments cannot be exceed more than 1000 characters";

        if ( error ) {
            triggerErrorAlert( error );
        } else {
            if ( index >= 0 ) {
                newTrainees[index].mentor_comments = ( modalData && modalData.mentor_comments && !_isEmpty( modalData.mentor_comments ) ? modalData.mentor_comments : '' );
                newTrainees[index].mentor_comments_updated = moment().utcOffset(8).valueOf();
                newTrainees[index].mentor_comments_updated_by = ( authData && authData.email || '' );
            }

            if ( onUpdate ) {
                onUpdate(newTrainees);
                this.props.dispatch(appChangesMade());
                this.setState({ openMentorCommentsModal: false, modalData: false, traineeData: false });
            }
        } // end - error

    }

    handleAttendanceUpdate = () => {
        const { trainees, onUpdate } = this.props;
        const { modalData, modalType, traineeData, checked } = this.state;
        let error = false;

        // make sure notes doesn't exceed 500 chars
        if ( modalData && modalData.notes && !_isEmpty( modalData.notes ) && _size( modalData.notes ) > 500 )
            error = "Notes cannot be exceed more than 500 characters";

        if ( modalType && modalType === 'attendance_form' ) {
            let index = ( modalData && modalData.id && traineeData && traineeData.attendances && isArrayExists( traineeData.attendances ) ? _findIndex( traineeData.attendances, { id: modalData.id } ) : -1 ),
                newData = ( traineeData && traineeData.attendances && isArrayExists( traineeData.attendances ) ? cloneCollections( traineeData.attendances ) : [] );

            if ( error ) {
                triggerErrorAlert(error);
            } else {
                if ( index >= 0 ) {
                    newData[index] = cloneCollections(modalData);
                } else {
                    newData.push(modalData);
                }
                // do update
                this.handleTraineeUpdate(newData,traineeData.id);
                this.setState({ openAttendanceModal: false, modalData: false, traineeData: false, modalType: false });
            } // end - error

        } else if ( modalType && modalType === 'mass_edit_attendance' ) {
            // check for month select
            if ( !(modalData && modalData.id && !_isEmpty( modalData.id )) )
                error = "Please Select a Month";

            if ( error ) {
                triggerErrorAlert(error);
            } else {
                let newTrainees = ( trainees && isArrayExists( trainees ) ? cloneCollections( trainees ) : [] );
                if ( checked && isArrayExists( checked ) ) {
                    _forEach( checked, item => {
                        let tindex = ( newTrainees && isArrayExists( newTrainees ) ? _findIndex( newTrainees, { id: item.id }) : -1 );
                        if ( tindex >= 0 ) {
                            let pindex = ( trainees[tindex].attendances && isArrayExists( trainees[tindex].attendances ) ? _findIndex( trainees[tindex].attendances, { id: modalData.id } ) : -1 );
                            if ( pindex >= 0 ) {
                                newTrainees[tindex].attendances[pindex].attend = ( modalData.attend && ( modalData.attend === 'yes' || modalData.attend === 'no' ) ? modalData.attend : '' );
                                if ( modalData.notes && !_isEmpty( modalData.notes ) ) {
                                    newTrainees[tindex].attendances[pindex].notes = modalData.notes;
                                } 
                            } else {
                                // add array if empty
                                if ( !( newTrainees[tindex].attendances && isArrayExists( newTrainees[tindex].attendances ) ) )
                                    newTrainees[tindex].attendances = [];

                                newTrainees[tindex].attendances.push({
                                    id: modalData.id,
                                    attend: ( modalData.attend && ( modalData.attend === 'yes' || modalData.attend === 'no' ) ? modalData.attend : '' ),
                                    notes: ( modalData.notes && !_isEmpty( modalData.notes ) ? modalData.notes : '' )
                                });
                            } // end - pindex
                        } // end - tindex
                    });
                } // end - checked
                if ( onUpdate ) {
                    onUpdate(newTrainees);
                    this.props.dispatch(appChangesMade());
                }
                this.setState({ openAttendanceModal: false, modalData: false, traineeData: false, modalType: false, checked: [], allCheck: false });
            } // end - modalData
        }
    }

    handleFormUpdate = (newValue,key,subkey,subVal) => {
        const { modalData } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} );
        switch( key ) {
            // case 'course_type':
            //     let selectedType = ( course_type && isArrayExists( course_type ) ? _find( course_type, { id: newValue } ) : false );
            //     newData[key] = newValue;
            //     newData[key + '_label'] = ( selectedType && selectedType.label ? selectedType.label : '' );
            //     break;
            default:
                if ( subkey && ( subkey === 'checked' || subkey === 'unchecked' ) && subVal && !_isEmpty( subVal ) ) {
                    // use subVal as the new value
                    newData[key] = subVal;
                } else {
                    // otherwise - just update normally
                    newData[key] = newValue
                }
                break;
        } // end - key
        this.setState({ modalData: newData });
    }

    getActions = () => {
        const { authData, editMode } = this.props;
        let actions = [];

        actions.push({ 
            id: 'massedit', label: 'Mass Edit Attendance', icon: 'fa-edit', disabled: false, onClick: () => {
                const { checked } = this.state;
                if ( checked && isArrayExists( checked ) ) {
                    this.setState({ 
                        openAttendanceModal: true,
                        modalData: {
                            id: '',
                            attend: '',
                            notes: ''
                        },
                        modalType: 'mass_edit_attendance'
                    });
                } else {
                    triggerErrorAlert("Please select at least one student!")
                } // end - checked
            }
        });

        if ( editMode && editMode === 'admin' ) {
            actions.push({
                id: 'downloadcsv', label: 'Download Selected Data as CSV', icon: 'fa-cloud-download', disabled: false, onClick: this.handleCSVDownload
            });
        } // end - editMode

        return actions;
    }

    getCells = () => {
        const { columns, editMode, currentSchedule } = this.props;
        let today = moment().utcOffset(this.utcOffSet).startOf('month').format('YYYY-MM'),
            cells = [
                // { id: 'region', label: 'Licensee', render: this.renderCell('region') },
                // { id: 'class_name', label: 'Class Name', render: this.renderCell('class_name') },
                // { id: 'created_on', label: 'Registration Date', render: this.renderCell('created_on') },
                // { id: 'confirmation_email_date', label: 'Confirmation Date', render: this.renderCell('confirmation_email_date') },
                // { id: 'email', label: 'Email', render: this.renderCell('email') },
                { id: 'name', label: 'Name', render: (item) => <StudentNameToDatabase {...item} showBackpackLink={true} /> },
                // { id: 'enrollment_status', label: 'Enrolment Status', render: this.renderEnrollmentStatus('enrollment_status') },
                // { id: 'lms_progress', label: 'Current LMS Progress', render: this.renderCell('lms_progress') },
                { id: 'mentor', label: (<><ToolTipInfo content={(
                <>
                    Add Mentor for each student in <a href="#" onClick={event => {
                        const { currentSchedule, history } = this.props;
                        event.preventDefault();
                        history.push('/course_access',{ schedule_id: currentSchedule });
                    }}>Course Access</a>
                </>
                )} />{"Mentor"}</>), render: this.renderCell('mentor') },
                { id: 'mentor_comments', label: (<><ToolTipInfo content={(
                <>
                    This comment is an overall comment about a student over a quarter. <br /><br />
                    This comment will be automatically attached together in the <a href={"/r/"+currentSchedule+"?location=quarterly_report"} onClick={event => {
                        const { currentSchedule, history } = this.props;
                        event.preventDefault();
                        history.push('/class_quarterly_report',{ schedule_id: currentSchedule });
                    }}>Quarterly Report</a> to parents.<br /><br />
                    You may use use the <a href="https://docs.google.com/document/d/15K16BMbXewAlus8RLGOa-gca-iVCDQN5lkn2MBWg7SY/edit?usp=sharing" target="_blank">Rubric on Student Impact</a> to help you 
                </>
                )} />{"Mentor's General Comment"}</>), render: this.renderCell('mentor_comments'), style: { padding: '0px' } },
            ];

        if ( columns && isArrayExists( columns ) ) {
            _forEach( _sortBy(columns,['start_date']), column => {
                cells.push({
                    id: column.id,
                    label: ( column.label || column.id ),
                    headstyle: ( column.id === today ? { background: '#ffb74d' } : null ),
                    style: { padding: '0px' },
                    render: this.renderAttendanceColumn(column)
                });
            });
        } // end - columns

        if ( editMode && editMode === 'mentor' ) {
            cells.push({
                id: 'nominate_badge',
                label: 'Nominate Badge',
                style: { padding: '0px' },
                render: this.renderNominateBadge
            });
        }

        return cells;
    }

    getCourseCount = (column,trainee) => {
        const data = this.getSelectedPaymentData(column,trainee);
        let count = 0;
        if ( trainee && trainee.course_access && isArrayExists( trainee.course_access ) ) {
            _forEach( trainee.course_access, course => {
                if ( course.activated <= column.end_at ) {
                    if ( course.deactivated ) {
                        if ( course.deactivated >= column.end_at ) {
                            count++
                        } else {
                            if ( course.deactivated >= column.start_at ) {
                               count++ 
                            }
                        }
                    } else if ( course.concluded ) {
                        if ( course.concluded >= column.end_at ) {
                            count++
                        } else {
                            if ( course.concluded >= column.start_at ) {
                               count++ 
                            }
                        }
                    } else {
                        count++;
                    } // end - course.deactivated
                } // end - course.activated
            });
        } // end - trainee.course_access
        return count;
    }

    getSelectedPaymentData = (column,trainee) => {
        let selected = ( trainee && trainee.attendances && isArrayExists( trainee.attendances ) ? _find( trainee.attendances, { id: column.id } ) : false );
        return ( selected && isObjectExists( selected ) ? cloneCollections( selected ) : false );
    }

    checkIfisValidAttendance = (column,trainee) => {
        let valid = false;
        if ( trainee && trainee.confirmation_email_date && column.start_date && moment(trainee.confirmation_email_date).utcOffset(this.utcOffSet).startOf('date').valueOf() <= moment(column.start_date).utcOffset(this.utcOffSet).endOf('date').valueOf() ) {
            valid = true
        } // end - trainee.course_access
        // disable it if is inactive
        if ( trainee && getStudentEnrollmentStatus(trainee) === 'inactivate' ) {
            valid = false;
        }
        return valid;
    }

    reorganizeData() {
        const { searchterms, sortBy, perPage, page, filterBy } = this.state;
        const { trainees } = this.props;
        let items = ( trainees ? cloneCollections( trainees ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','email'] );
			total = _size( items );
        } // end - searchterms

        // do filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy !== 'all' ) {
            items = _filter( items, (i) => getStudentEnrollmentStatus(i) === filterBy );
            total = _size( items );
        } // end - filterBy

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['confirmation_email_date'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['confirmation_email_date'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
                // case 'email-desc':
                //     items = _sortBy( items, ['email'] );
                //     items = _reverse( items );
                //     break;
                // case 'email-asc':
                //     items = _sortBy( items, ['email'] );
                //     break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderNominateBadge = (trainee) => {
        const { course, badges, showcase, schedule, currentTrainees, courses, rubrics, feedback_forms_fields, onShowcaseUpdate } = this.props;
        return <ReviewShowcase
                course={course}
                trainee={trainee}
                badges={badges}
                showcase={showcase} 
                schedule={schedule}
                currentTrainees={currentTrainees}
                courses={courses}
                rubrics={rubrics}
                feedback_forms_fields={feedback_forms_fields}
                onTraineeUpdate={(newData,trainee_id) => {
                    let formData = {
                        schedule_id: schedule.id,
                        lms_course_id: ( course.id || '' ),
                        trainees: []
                    };
                    formData.trainees.push({
                        id: newData.id,
                        showcase: ( newData.showcase && isArrayExists( newData.showcase ) ? newData.showcase : [] )
                    });
                    // console.log(formData);
                    if ( onShowcaseUpdate )
                        onShowcaseUpdate(formData);
                }} />
    }

    renderMentorCommentsForm = () => {
        const { columns, currentSchedule, history } = this.props;
        const { modalData } = this.state;
        return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormInput 
                    tooltip={(
                        <>
                            This comment is an overall comment about a student over a quarter. <br /><br />
                            This comment will be automatically attached together in the <a href={"/r/"+currentSchedule+"?location=quarterly_report"} onClick={event => {
                                const { currentSchedule, history } = this.props;
                                event.preventDefault();
                                history.push('/class_quarterly_report',{ schedule_id: currentSchedule });
                            }}>Quarterly Report</a> to parents.<br /><br />
                            You may use use the <a href="https://docs.google.com/document/d/15K16BMbXewAlus8RLGOa-gca-iVCDQN5lkn2MBWg7SY/edit?usp=sharing" target="_blank">Rubric on Student Impact</a> to help you 
                        </>
                        )} 
                    label="Mentor's General Comments (Max 1000 characters)" name="mentor_comments" value={( modalData && modalData.mentor_comments ? modalData.mentor_comments : '' )} rows={5} multiline={true} onChange={this.handleFormUpdate} />
                    { modalData && modalData.mentor_comments && !_isEmpty( modalData.mentor_comments ) && _size( modalData.mentor_comments ) > 1000 ? <ErrorBox>Please reduce your notes to less than 1000 characters. Current size: {_size( modalData.mentor_comments )} characters.</ErrorBox> : null }
                </Grid>
            </Grid>
        </div>
        );
    }

    renderAttendanceForm = () => {
        const { regions, columns, editMode } = this.props;
        const { modalData, modalType } = this.state;
        return (
        <div>
            <Grid container spacing={2}>
                { modalType && modalType === 'mass_edit_attendance' ? <Grid item xs={12}><div style={{ padding: '5px 0 10px 0' }}><FormRadio label="Select a Date *" name="id" inline={true} value={( modalData && modalData.id && !_isEmpty( modalData.id ) ? modalData.id : '' )} options={getSelectOptions({ list: _sortBy( columns, ['start_date'] ), keys: { value: 'id', label: 'label' } })} onChange={this.handleFormUpdate} /></div></Grid> : null }
                <Grid item xs={12}>
                    {/* { modalType && modalType === 'mass_edit_attendance' ? <FormSelect label="Attendance" name="attend" value={( modalData && modalData.attend && !_isEmpty( modalData.attend ) ? modalData.attend : '' )} options={[
                        { value: '', label: 'None' },
                        { value: 'yes', label: 'Yes' },
                        { value: 'no', label: 'No' }
                    ]} onChange={this.handleFormUpdate} /> : <FormRadio inline={true} label="Attendance" name="attend" value={( modalData && modalData.attend && ( modalData.attend === 'yes' || modalData.attend === 'no' ) ? modalData.attend : '' )} options={YES_NO_OPTIONS} onChange={this.handleFormUpdate} /> } */}
                    <FormRadio inline={true} label="Attendance" name="attend" value={( modalData && modalData.attend && ( modalData.attend === 'yes' || modalData.attend === 'no' ) ? modalData.attend : '' )}                 
                        options={[
                            { value: 'yes', label: 'Yes' },
                            { value: 'no', label: 'No' },
                            { value: '', label: 'None' },
                        ]} onChange={this.handleFormUpdate} />
                </Grid>
                { editMode && editMode === 'mentor' && modalType && modalType === 'mass_edit_attendance' ? null : (
                <Grid item xs={12}>
                    <FormInput label="Notes (Max 500 characters)" name="notes" value={( modalData && modalData.notes ? modalData.notes : '' )} rows={4} multiline={true} onChange={this.handleFormUpdate} />
                    { modalData && modalData.notes && !_isEmpty( modalData.notes ) && _size( modalData.notes ) > 500 ? <ErrorBox>Please reduce your notes to less than 500 characters. Current size: {_size( modalData.notes )} characters.</ErrorBox> : null }
                </Grid>
                )}
            </Grid>
        </div>
        );
    }

    renderAttendanceFormTitle = () => {
        const { columns } = this.props;
        const { modalData, traineeData, modalType } = this.state;
        const selectedColumn = ( modalData && modalData.id && !_isEmpty( modalData.id ) && columns ? _find( columns, { id: modalData.id } ) : false );
        return ( modalType && modalType === 'mass_edit_attendance' ? 'Mass Edit Attendance' : "Edit Attendance" + ( traineeData && traineeData.name && !_isEmpty( traineeData.name ) ? ' for '+traineeData.name + " on " +( selectedColumn && selectedColumn.label ? selectedColumn.label : '' ) : '' ) );
    }

    renderRadio = (key,label,trainee,column,disabled) => {
        const { editMode } = this.props;
        const data = this.getSelectedPaymentData(column,trainee);
        // const showAttendance = this.checkIfisValidAttendance(column,trainee);
        return (
        <RadioWrapper disablePointer={( editMode && editMode === 'mentor' ? 'yes' : null )}>
            <FormRadio 
                inline={true}
                label={( label || '' )}
                name="attend"
                disabled={disabled}
                value={( data && data[key] && ( data[key] === 'yes' || data[key] === 'no' ) ? data[key] : '' )}
                options={YES_NO_OPTIONS}
                onChange={(newValue) => {
                    let index = ( data && data.id && trainee && trainee.attendances && isArrayExists( trainee.attendances ) ? _findIndex( trainee.attendances, { id: data.id } ) : -1 ),
                        newData = ( trainee && trainee.attendances && isArrayExists( trainee.attendances ) ? cloneCollections( trainee.attendances ) : [] );
                    if ( index >= 0 ) {
                        newData[index][key] = newValue;
                    } else {
                        newData.push({
                            id: column.id,
                            attend: ( key === 'attend' && ( newValue === 'yes' || newValue === 'no' ) ? newValue : '' ),
                            notes: ''
                        });
                    }
                    // only trigger updates if is not from mentor
                    if ( !( editMode && editMode === 'mentor' ) ) {
                        if ( trainee && trainee.id )
                            this.handleTraineeUpdate(newData,trainee.id);
                    } // end - editMode
                }} />
        </RadioWrapper>
        )
    }

    renderAttendanceColumn = (column) => (trainee) => {
        const data = this.getSelectedPaymentData(column,trainee);
        const showAttendance = this.checkIfisValidAttendance(column,trainee);
        const course_count = this.getCourseCount(column,trainee);
        return (
        <AttendanceColumn disabled={( showAttendance ? null : 'yes' )}>
            <div className="disabled-wrapper">
                {this.renderRadio('attend','Attendance',trainee,column,!showAttendance)}
                <strong style={{ fontWeight: '700' }}>Note:</strong> 
                <TextareaWrapper>
                    <textarea rows={2} disabled="disabled" value={ data.notes && !_isEmpty( data.notes ) ? data.notes : '-' } />
                </TextareaWrapper>
            </div>
            <InfoButton size="small" minWidth="0px" style={{ padding: '4px 8px', marginTop: '4px' }} onClick={() => this.setState({
                    openAttendanceModal: true,
                    modalType: 'attendance_form',
                    traineeData: ( trainee || false ),
                    modalData: ( data ? data : {
                        id: column.id,
                        attend: '',
                        notes: ''
                    })
                })}>Edit</InfoButton>
        </AttendanceColumn>
        );
    }

    renderEnrollmentStatus = type => item => {
        return <StudentEnrollmentStatus trainee={item} />
    }

    renderCell = type => item => {
        const { regions, schedules, currentSchedule, mentors, editMode } = this.props;
        switch( type ) {
            case 'created_on':
            case 'confirmation_email_date':
                return <WrapWord>{( item[type] ? getMomentTime( item[type], 'DD MMM YYYY' ) : '---' )}</WrapWord>;
            case 'mentor':
                return <WrapWord>{getSelectedValue( mentors, item[type], 'email', 'name', ( item[type] && !_isEmpty( item[type] ) ? item[type] : '-' ) )}</WrapWord>;
            case 'mentor_comments':
                return (
                <AttendanceColumn>
                    <div className="disabled-wrapper">
                        <TextareaWrapper>
                            <textarea rows={6} disabled="disabled" value={ item.mentor_comments && !_isEmpty( item.mentor_comments ) ? item.mentor_comments : '-' } />
                        </TextareaWrapper>
                    </div>
                    <InfoButton size="small" minWidth="0px" style={{ padding: '4px 8px', marginTop: '4px' }} onClick={() => this.setState({
                        openMentorCommentsModal: true,
                        traineeData: ( item || false ),
                        modalData: {
                            id: item.id,
                            mentor_comments: ( item.mentor_comments || '' )
                        }
                    })}>Edit</InfoButton>
                    <div>
                        { item.mentor_comments_updated ? <div style={{ fontSize: '10px' }}>Last Updated on: {getMomentTime( item.mentor_comments_updated, 'DD MMM YYYY' )}</div> : null }
                        { item.mentor_comments_updated_by && !_isEmpty( item.mentor_comments_updated_by ) && <div style={{ fontSize: '10px' }}>Last Updated By: <WrapWord>{item.mentor_comments_updated_by}</WrapWord></div> }
                    </div>
                </AttendanceColumn>
                );
            case 'email':
                return <WrapWord>{( item[type] || '-' )}</WrapWord>;
        }
    }

    renderTableActions = () => {
        const { authData } = this.props;
        const { sortBy, perPage, searchterms, checked, filterBy } = this.state;
        return <TableBar
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                filterBy={filterBy}
                filterByLabel="Filter By Class Status"
                sortByOptions={[
                    { value: 'date-desc', label: 'Confirmation Date (Recent First)' },
                    { value: 'date-asc', label: 'Confirmation Date (Oldest First)' },
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' },
                    // { value: 'email-asc', label: 'Email ( A - Z)' },
                    // { value: 'email-desc', label: 'Email ( Z - A )' },
                    // { value: 'org-asc', label: 'School ( A - Z)' },
                    // { value: 'org-desc', label: 'School ( Z - A )' },
                ]}
                leftButtons={ checked && isArrayExists( checked ) ? [
                    <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                        <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                    </div>
                    ] : null }
                rightButtons={[
                    <ButtonActions 
                        key="actions" 
                        label="Actions"
                        menuContainerStyle={{ width: "250px" }}
                        style={{ marginRight: "5px" }}
                        actions={this.getActions()} />
                ]}
                filterByOptions={getSelectOptions({ list: _filter( ENROLLMENT_STATUS_OPTIONS, { course_access: true } ), options: [{ value: 'all', label: 'All Status' }], keys: { value: 'value', label: 'label' } })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    doneLoaded={true}
                    // entriesLabel="(Total Registration For This Class)"
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { authData, users, editMode } = this.props;
        const { openDeleteModal, deleteModal, openAttendanceModal, modalData, traineeData, allCheck, checked, openMentorCommentsModal } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={openAttendanceModal}
                title={this.renderAttendanceFormTitle()}
                maxWidth="sm"
                onClose={() => this.setState({ openAttendanceModal: false, modalData: false, traineeData: false, modalType: false })}
                disableBackdrop={true}
                actionLabel="Update"
                doAction={this.handleAttendanceUpdate}
                contents={this.renderAttendanceForm()} />

            <ModalView 
                open={openMentorCommentsModal}
                title="Edit Mentor's General Comments"
                maxWidth="sm"
                onClose={() => this.setState({ openMentorCommentsModal: false, modalData: false, traineeData: false })}
                disableBackdrop={true}
                actionLabel="Update"
                doAction={this.handleMentorCommentsUpdate}
                contents={this.renderMentorCommentsForm()} />

            {this.renderTableActions()}
            <Table 
                items={items}
                showCheckbox={true}
                emptyCell="No Student(s) Found."
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={(newValue) => this.setState({ checked: newValue })}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                cells={this.getCells()}
                // actionStyles={{ width: "10%" }}
                // actions={(item) => (
                //     <ButtonGroup>
                //         { item.enrollment_status && item.enrollment_status === 'delete' ? null : <InfoButton key="edit" size="small" onClick={() => this.setState({ openAttendanceModal: true, modalData: item })}><i className="fa fa-edit"></i>Edit</InfoButton> }
                //         {/* { authData && hasAccessRights( authData, ['scd'] ) ? <ErrorButton key="delete" size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton> : null } */}
                //     </ButtonGroup>
                // )}
                 />
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(StudentsTable);