import React from "react";

const info = {
    heading: <></>,
    contents: [
        {
            id: "1",
            title: "Register students here.",
            content: (
                <>
                    <div>
                        <ul style={{ listStyle: "disc", paddingLeft: "10px", marginLeft: "10px" }}>
                            <li>All registrations of interest for this class will funnel here.</li>
                            <li>Use the Registration Link to collect student details. </li>
                            <li>You may bulk upload students here too!</li>
                        </ul>
                    </div>
                </>
            ),
        },
        {
            id: "2",
            title: "Your Pre-Sales Strategy",
            content: (
                <>
                    <div>
                        <p style={{ marginBottom: "10px" }}>
                            Pre-sales gives confidence to parents on 2 factors:
                            <ul style={{ listStyle: "disc", paddingLeft: "10px", marginLeft: "10px" }}>
                                <li>we are the right school/center i.e. “this center has good educators.”</li>
                                <li>we have the right course i.e. “my students/kids will be interested to attend.”</li>
                            </ul>
                        </p>
                        <p>
                            <strong style={{ fontWeight: "700" }}>Strategise your actions to give confidence:</strong>
                            <ul style={{ listStyle: "disc", paddingLeft: "10px", marginLeft: "10px" }}>
                                <li>Come and See — Invite potential schools/parents to our class</li>
                                <li>Parent info session</li>
                                <li>Existing parents as our evangelists</li>
                            </ul>
                            Read more on Partner Portals.
                        </p>
                    </div>
                </>
            ),
        },
    ],
};

const buttons = [
    {
        id: "1",
        tooltip: true,
        tooltipContent: (
            <div style={{ padding: "10px" }}>
                <p style={{ fontWeight: "700" }}>📂 Chumbaka Partner Portal - 6. Operation - 1. Recruitment </p>
                <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                    <li>Pre-Sales Objective</li>
                    <li>FAQs from Parents</li>
                </ul>
            </div>
        ),
        type: "notion",
        link: "https://www.notion.so/chumbaka/1-Recruitment-Pre-Sales-Parent-FAQs-4192ee00cfb64370a5ff377b51e70544?pvs=4",
        label: "Notion",
    },
    {
        id: "2",
        tooltip: true,
        tooltipContent: (
            <div style={{ padding: "10px" }}>
                <p style={{ fontWeight: "700" }}>📂 Chumbaka Mentor Portal - 1. Recruitment </p>
                <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                    <li>Parental Consent Form</li>
                    <li>Media Release Form</li>
                </ul>
            </div>
        ),
        type: "drive",
        link: "https://drive.google.com/drive/u/0/folders/1mPdno1zyBdI8OzKxbkBXBDBE6iIobjqc",
        label: "Google Drive",
    },
];

export default {
    info,
    buttons,
};
