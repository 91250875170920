/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	M_GET_REGISTRATION_FORMS_FIELDS,
	M_GET_REGISTRATION_FORM,
    M_REGISTRATION_FORM_UPDATED,
	GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getAPIErrorMessage } from '../../helpers/action';
import { appChangesReset } from '../misc';
import { fb_getRegistrationForm, fb_getRegistrationFormsFields } from './fb';
import { fb_addMaintenance, fb_updateMaintenance } from '../maintenance/fb';

export const getRegistrationFormsFields = () => {
	return dispatch => {

		fb_getRegistrationFormsFields(list => {
			dispatch({ 
				type: M_GET_REGISTRATION_FORMS_FIELDS, 
				payload: { list } 
			});
		});

	}
}

export const getRegistrationForm = (id) => {
	return dispatch => {

		fb_getRegistrationForm(id)
		.then(registration_form => {
			dispatch({
				type: M_GET_REGISTRATION_FORM,
				payload: { registration_form }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: M_GET_REGISTRATION_FORM,
				payload: { registration_form: {} }
			});
		})

	}
}

export const addRegistrationForm = (formData,history) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addMaintenance(formData)
		.then( results => {
			if ( results && results.item_id && !_isEmpty( results.item_id ) ) {
				// updated
				dispatch({ type: M_REGISTRATION_FORM_UPDATED });
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("Registration Form Added");
				// redirect to course page
				history.push("/maintenance/registration_forms/" + results.item_id);
			} else {
				dispatch(toggleModalProcessing(false,apiNum));
				triggerErrorAlert('Missing new form ID');
			}
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

  }
}

export const editRegistrationForm = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateMaintenance(formData)
		.then( results => {
			fb_getRegistrationForm(formData.id)
			.then(registration_form => {
				// push new data
				dispatch({ type: M_GET_REGISTRATION_FORM, payload: { registration_form } });	
				// updated
				dispatch({ type: M_REGISTRATION_FORM_UPDATED });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Registration Form Updated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

  }
}