/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _isEqual from 'lodash/isEqual';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader'
import SnackBarSave from '../../components/SnackBarSave';

import EditForm from './form';
import ItemNotFound from '../../components/ItemNotFound';

import { InfoButton, GreyButton } from '../../styles/button';

import { hasAccessRights } from '../../helpers/auth'; 
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { getFeedbackForm, editFeedbackForm } from '../../actions/m_feedback_forms';
import { appChangesMade, resetRedux } from '../../actions/misc';

class MaintenanceFeedbackForm extends React.Component {

    state = {
        currentTab: 'details',
        feedback_form: false,
        randNum: false
    };

    componentDidMount() {
        const { authData } = this.props;
        const { feedback_form_id } = this.props.match.params;
        
        // retrieve form details
        this.props.dispatch(getFeedbackForm(feedback_form_id));


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentFeedbackForm, randNum } = this.props;

        // for feedback_form
        if ( currentFeedbackForm && randNum && randNum !== this.state.randNum ) {
            this.setState({ feedback_form: cloneCollections( currentFeedbackForm ), randNum });
        } // end - currentFeedbackForm
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('feedback_form'));
    }

    handleRefresh = (type) => {
        // const { feedback_form_id } = this.props.match.params;
        // switch( type ) {
        //     case 'emails':
        //         this.setState({ emails: false });
        //         this.props.dispatch(getEmailsBy('partners',feedback_form_id));
        //         break;
        // }
    }

    handleSaveChanges = (event) => {
        const { feedback_form } = this.state;
        event.preventDefault();
        var error = false,
            formData = ( feedback_form && isObjectExists( feedback_form ) ? cloneCollections( feedback_form ) : {} );

        // do error check
        if ( !( formData && formData.status && !_isEmpty( formData.status ) ) )
            error = 'Please select a valid status';

        if ( !( formData && formData.label && !_isEmpty( formData.label ) ) )
            error = 'Please insert a valid label';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editFeedbackForm(formData));
        } // end - error

    }

    handleFormUpdate = (newValue) => {
        this.setState({ feedback_form: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    renderTabContents = () => {
        const { feedback_form_id } = this.props.match.params;
        const { authData, currentFeedbackForm, designationList, currencyList, changesMade } = this.props;
        const { currentTab, feedback_form } = this.state;
        const tabProps = {
            authData: ( authData || false ),
            feedback_form: ( feedback_form || false ),
            changesMade: ( changesMade || false ),
            onFormUpdate: this.handleFormUpdate
        };
        switch( currentTab ) {
            case 'details':
                return <EditForm 
                    currentFeedbackForm={( currentFeedbackForm || false )}
                    {...tabProps} />;
        }
    }

    renderContents() {
        return (
        <div>

            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                {this.renderTabContents()}
            </Paper>

            <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
            </div>

            <SnackBarSave onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { feedback_form_id } = this.props.match.params;
        const { randNum } = this.state;
        const { currentFeedbackForm } = this.props;
        return <AppWrapper 
                title="Edit Feedback Form"
                subtitle="Feedback Forms"
                back="/maintenance/feedback_forms"
                breadcrumbs={[
                    { url: '/maintenance/feedback_forms', label: 'Feedback Forms' },
                    { label: 'Edit' }
                ]}
                maxWidth="1110px"
                onLoad={( !( randNum ) ? true : false )}
                contents={ currentFeedbackForm && currentFeedbackForm.id && !_isEmpty( currentFeedbackForm.id ) ? this.renderContents() : <ItemNotFound item="Feedback Form" />} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        currentFeedbackForm: state.maintenance && state.maintenance.feedback_form || null,
        randNum: state.maintenance && state.maintenance.rand_feedback_form || null,
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(MaintenanceFeedbackForm);