/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

const getMaintenanceFromFirestore = (type,callback) => {

    firebase.firestore().collection("maintenance").where("type","==", type)
    .onSnapshot(snapshot => {
        const list = [];
        if ( snapshot ) {
            snapshot.forEach( doc => {
                let item = doc.data();
                // add status if not set
                if ( !( item.status && !_isEmpty( item.status ) ) )
                    item.status = 'active';

                list.push(item);
            });
        } // end - snapshpt\
        callback(list);
    });

}

const getMaintenanceFromDatabase = (type,callback) => {

    firebase.database().ref("maintenance/"+type)
    .on("value",snapshot => {
        const list = [];
        if ( snapshot && snapshot.exists() && snapshot.hasChildren() ) {
            snapshot.forEach( childSnapshot => {
                let item = childSnapshot.val();
                item.id = childSnapshot.key;

                // add status if not set
                if ( !( item.status && !_isEmpty( item.status ) ) )
                    item.status = 'active';

                list.push(item);
            });
        } // end - snapshpt\
        callback(list);
    });

}

/* helper end */

export const fb_getMaintenance = (type,callback) => {
    if ( type && !_isEmpty(type) && ( 
        type === 'email_templates' || 
        type === 'certificates' 
        ) ) {
        getMaintenanceFromDatabase(type,callback);
    } else {
        getMaintenanceFromFirestore(type,callback);
    } // end - type
}

export const fb_addMaintenance = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'maintenance', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateMaintenance = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'maintenance', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteMaintenance = (id,type) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'maintenance', action: 'delete', formData: { id, type } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}