/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';

import ScreenLoader from '../../components/ScreenLoader';

import { GreyButton } from '../../styles/button';


import { signInWithCustomToken } from '../../actions/auth';
import { PLATFORM_NAME_LONG, PLATFORM_URL } from '../../constants';

class RedirectPage extends React.Component {

    componentDidMount() {
        const { schedule_id } = this.props.match.params;
        const { history } = this.props;
        const { location } = this.getQueryString();

        // make sure token is available
        if ( location && !_isEmpty( location ) ) {
            switch( location ) {
                case 'registration':
                    this.props.history.push( '/class_registration' , { schedule_id } );
                    break;
                case 'confirmation':
                    this.props.history.push( '/class_confirmation' , { schedule_id } );
                    break;
                case 'course_access':
                    this.props.history.push( '/course_access' , { schedule_id } );
                    break;
                case 'attendance':
                    this.props.history.push( '/class_attendance_reporting' , { schedule_id } );
                    break;
                case 'showcase':
                    this.props.history.push( '/class_showcase' , { schedule_id } );
                    break;
                case 'payment':
                    this.props.history.push( '/class_payment_tracking' , { schedule_id } );
                    break;
                case 'student_database':
                    this.props.history.push( '/student_database' , { schedule_id } );
                    break;
                case 'quarterly_report':
                    this.props.history.push( '/class_quarterly_report' , { schedule_id } );
                    break;
                default: 
                    this.props.history.push( '/schedules/'+schedule_id );
                    break;
            } 
        } else {
            this.props.history.push( '/schedules/'+schedule_id );
        }
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    getQueryString() {
        return ( this.props.location && this.props.location.search ? queryString.parse(this.props.location.search) : {} );
    }

    render() {
        return <ScreenLoader />
    }

}

// const mapStateToProps = state => {
//     return {
//         authData: state.auth && state.auth.user ? state.auth.user : null
//     }
// }

export default compose(
    connect(),
    withRouter
)(RedirectPage);