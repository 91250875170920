import React from "react";

const info = {
    heading: <></>,
    contents: [
        {
            id: "1",
            title: "What is 3. Delivery-Attendance & Reporting stage? ",
            content: (
                <>
                    <div>
                        <p>Delivery is about fulfilling your obligation to students and their parents.</p>
                        <p>
                            Use this tracker to help you with tracking students’ attendance & activities. Mentors are able to access this via LMS (
                            <a href="https://lms.chumbaka.asia/courses/105/modules/items/10393" target="_blank">
                                example link
                            </a>
                            ).
                        </p>
                    </div>
                </>
            ),
        },
        {
            id: "2",
            title: "How is this page useful? ",
            content: (
                <>
                    <div>
                        <ul style={{ listStyle: "disc", paddingLeft: "10px", marginLeft: "10px" }}>
                            <li>Craft Mentor’s General Comment </li>
                            <li>Edit student’s attendance </li>
                            <li>Mass edit students’ attendance</li>
                            <li>
                                The dates displayed here are connected to the{" "}
                                <a href="https://sms.chumbaka.asia/schedules/JUMPSTAR_MON0900_876" target="_blank">
                                    Class Calendar scheduled
                                </a>
                            </li>
                        </ul>
                    </div>
                </>
            ),
        },
    ],
};

const buttons = [
    {
        id: "2",
        tooltip: true,
        tooltipContent: (
            <div style={{ padding: "10px" }}>
                <p style={{ fontWeight: "700" }}>📂 Chumbaka Mentor Portal - 3. Delivery</p>
                <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                    <li>Class Attendance and Reporting Manual Tracker (Template)</li>
                    <li>Class Norms for Day 1</li>
                    <li>Reporting Bug</li>
                    <li>Walkthrough LMS</li>
                    <li>Course Access Report Examples</li>
                </ul>
            </div>
        ),
        type: "drive",
        link: "https://drive.google.com/drive/u/0/folders/152jmBqjA8L79qHhxw2bkSPZzBwY_RZy3",
        label: "Google Drive",
    },
];

export default {
    info,
    buttons,
};
