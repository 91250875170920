/* eslint-disable */
import React, { useState } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";
import _sortBy from "lodash/sortBy";
import _filter from "lodash/filter";
import _split from "lodash/split";
import _endsWith from "lodash/endsWith";

import ModalView from "../../components/ModalView";
import ToolTipInfo from "../../components/ToolTipInfo";

import { FormBox } from "../../styles/form";
import { AInverseLink, InfoButton, InverseButton, AInfoLink } from "../../styles/button";
import { SuccessTag, GreyTag } from "../../styles/tag";

import { isAdmin, hasAccessRights, isAdminType, isMentorRole } from "../../helpers/auth";
import { isArrayExists } from "../../helpers/validation";
import { triggerErrorAlert } from "../../helpers/alert";

// import { DEV_MODE } from '../../constants';

const useStyles = (theme) => ({
    boxheading: {
        fontSize: "20px",
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd",
    },
    headcell: {
        fontSize: "16px",
        fontWeight: "700",
        color: theme.palette.background,
    },
    bodycell: {
        fontSize: "14px",
        verticalAlign: "top",
    },
});

const ExternalAssetsAccess = ({
    authData,
    dispatch,
    user = {},
    classes,
    changesMade,
    onGrantPersonnelAccess = () => {},
    onRevokePersonnelAccess = () => {},
    onGrantMentorAccess = () => {},
    onRevokeMentorAccess = () => {},
}) => {
    const handleAccessGrant = (type) => () => {
        if (changesMade) {
            triggerErrorAlert("Please save changes first");
        } else {
            if (isAdminType(user)) {
                onGrantPersonnelAccess({
                    uid: user.uid,
                    region: user.region,
                    asset_type: type,
                });
            } else {
                onGrantMentorAccess({
                    uid: user.uid,
                    region: user.region,
                    asset_type: type,
                });
            }
        }
    };

    const handleAccessRevoke = (type) => () => {
        if (changesMade) {
            triggerErrorAlert("Please save changes first");
        } else {
            if (isAdminType(user)) {
                onRevokePersonnelAccess({
                    uid: user.uid,
                    region: user.region,
                    asset_type: type,
                });
            } else {
                onRevokeMentorAccess({
                    uid: user.uid,
                    region: user.region,
                    asset_type: type,
                });
            }
        }
    };

    return (
        user &&
        (isAdminType(user) || isMentorRole(user)) && (
            <>
                <Grid item xs={12}>
                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.boxheading}>
                        Access To External Assets
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper elevation={2}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.headcell} style={{ width: "40%" }}>
                                                Asset
                                            </TableCell>
                                            {/* <TableCell className={classes.headcell}>Status</TableCell> */}
                                            <TableCell className={classes.headcell} style={{ textAlign: "center", width: "25%" }}>
                                                Action(s)
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={classes.bodycell}>
                                                <a
                                                    href={
                                                        isAdminType(user)
                                                            ? "https://drive.google.com/drive/folders/17Q3-EPDzs2qtKc2tFygXOp66_tNdKPln"
                                                            : "https://drive.google.com/drive/folders/1IfWxrjYP-ZCM9RsfEVrJmqC0ZKt0L-JY/"
                                                    }
                                                    target="_blank"
                                                >
                                                    Google Drive
                                                </a>
                                            </TableCell>
                                            {/* <TableCell className={classes.bodycell}>
                                        { user && user.access_gdrive && user.access_gdrive === 'yes' ? <SuccessTag>Linked</SuccessTag> : <GreyTag>Un-Linked</GreyTag> }
                                    </TableCell> */}
                                            <TableCell className={classes.bodycell} style={{ textAlign: "center" }}>
                                                {/* {user && user.access_gdrive && user.access_gdrive === "yes" ? (
                                                    <InverseButton
                                                        size="small"
                                                        style={{ padding: "5px 15px" }}
                                                        onClick={handleAccessRevoke("gdrive")}
                                                    >
                                                        Revoke Access
                                                    </InverseButton>
                                                ) : (
                                                    <InfoButton size="small" style={{ padding: "5px 15px" }} onClick={handleAccessGrant("gdrive")}>
                                                        Grant Access
                                                    </InfoButton>
                                                )} */}
                                                <AInfoLink
                                                    href={
                                                        isAdminType(user)
                                                            ? "https://drive.google.com/drive/folders/17Q3-EPDzs2qtKc2tFygXOp66_tNdKPln?userstoinvite=" +
                                                              user.email
                                                            : "https://drive.google.com/drive/folders/1IfWxrjYP-ZCM9RsfEVrJmqC0ZKt0L-JY/?userstoinvite=" +
                                                              user.email
                                                    }
                                                    target="_blank"
                                                    style={{ padding: "5px 15px" }}
                                                >
                                                    Grant Access
                                                </AInfoLink>
                                            </TableCell>
                                        </TableRow>
                                        {/* <TableRow>
                                    <TableCell className={classes.bodycell}>Notion</TableCell>
                                    <TableCell className={classes.bodycell}>
                                        { user && user.access_notion && user.access_notion === 'yes' ? <SuccessTag>Linked</SuccessTag> : <GreyTag>Un-Linked</GreyTag> }
                                    </TableCell>
                                    <TableCell className={classes.bodycell} style={{ textAlign: 'center' }}>
                                        { user && user.access_notion && user.access_notion === 'yes' ? (
                                                <InverseButton size="small" style={{ padding: '5px 15px' }} onClick={handleAccessRevoke('notion')}>Revoke Access</InverseButton>
                                            ) : (
                                                <InfoButton size="small" style={{ padding: '5px 15px' }} onClick={handleAccessGrant('notion')}>Grant Access</InfoButton>
                                            ) }
                                    </TableCell>
                                </TableRow> */}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    );
};

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        changesMade: (state.misc && state.misc.changes_made) || null,
    };
};

export default compose(connect(mapStateToProps), withStyles(useStyles))(ExternalAssetsAccess);
