import _random from 'lodash/random';

import { 
	GET_SCHEDULES,
    GET_SCHEDULES_OPTIONS,
    GET_SCHEDULE,
	ADD_SCHEDULE,
	EDIT_SCHEDULE,
	DELETE_SCHEDULE,
    GET_GOOGLE_CALENDAR,
    UPDATE_SCHEDULE_RAND,
    RESET_SCHEDULE,
    RESET_SCHEDULES,
    RESET_SCHEDULES_OPTIONS
} from '../actions/types';

const initialState = {
    schedules: null,
    schedules_options: null,
    schedule: null,
    updated: false,
    rand_options: false,
    rand: false
};

export const schedules = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_SCHEDULES:
            return {
                ...state,
                schedules: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case GET_SCHEDULES_OPTIONS:
            return {
                ...state,
                schedules_options: ( payload.list || [] ),
                rand_options: _random(1,9999)
            };
        case GET_SCHEDULE:
            return {
                ...state,
                schedule: ( payload.schedule || [] ),
                rand: _random(1,9999)
            }; 
        case GET_GOOGLE_CALENDAR:
            return {
                ...state,
                calendar_events: ( payload.calendar_events || [] )
            }; 
        case ADD_SCHEDULE:
        case EDIT_SCHEDULE:
        case DELETE_SCHEDULE:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case UPDATE_SCHEDULE_RAND:
            return {
                ...state,
                rand: _random(1,9999)
            };
        case RESET_SCHEDULES_OPTIONS:
            return {
                ...state,
                schedules_options: null,
                rand_options: false
            };
        case RESET_SCHEDULE:
            return {
                ...state,
                schedule: null,
                updated: false,
                rand: false
            };
        case RESET_SCHEDULES:
            return {
                ...state,
                schedules: null,
                rand: false
            };
        default:
            return state;
    }
}