/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_BADGES,
	GET_BADGES_ALL,
	GET_BADGES_ONE_OFF,
	ADD_BADGE,
	EDIT_BADGE,
	DELETE_BADGE,
	GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getAPIErrorMessage } from '../../helpers/action';
import { appChangesReset } from '../misc';

import { fb_getBadges, fb_getAllBadges, fb_getOneOffBadges, fb_getBadgesOptions, fb_addBadge, fb_updateBadge, fb_deleteBadge } from './fb';

export const getBadges = (course_id) => {
	return dispatch => {

		fb_getBadges(list => {
			dispatch({
				type: GET_BADGES,
				payload: { list }
			});	
		},course_id);

	}
}

export const getOneOffBadges = () => {
	return dispatch => {

		fb_getOneOffBadges(list => {
			dispatch({
				type: GET_BADGES_ONE_OFF,
				payload: { list }
			});	
		});

	}
}

export const getAllBadges = () => {
	return dispatch => {

		fb_getAllBadges()
		.then(list => {
			dispatch({
				type: GET_BADGES_ALL,
				payload: { list }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_BADGES_ALL,
				payload: { list: [] }
			});
		})

	}
}

export const addBadge = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));
		dispatch(toggleLoader(true));

		fb_addBadge(formData)
		.then( results => {
			dispatch({ type: ADD_BADGE });
			dispatch(toggleModalProcessing(false));
			dispatch(toggleLoader(false));
			triggerSuccessAlert("Badge Added");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const editBadge = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));
		dispatch(toggleLoader(true));

		fb_updateBadge(formData)
		.then( results => {
			dispatch({ type: EDIT_BADGE });
			dispatch(toggleModalProcessing(false));
			dispatch(toggleLoader(false));
			triggerSuccessAlert("Badge Updated");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const deleteBadge = (id) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteBadge(id)
		.then( results => {
			dispatch({ type: DELETE_BADGE });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Badge Delete");
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}