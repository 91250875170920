/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
// import _isEmpty from 'lodash/isEmpty';
// import _merge from 'lodash/merge';
// import _size from 'lodash/size';
// import _isEqual from 'lodash/isEqual';

import AppWrapper from "../../components/AppWrapper";
import AppInfoBox from "../../modules/AppInfoBox";
import Table from "./table";

// import { cloneCollections } from '../../helpers/data';

import { getCourses } from "../../actions/courses";
import { getLMSCourses } from "../../actions/canvas_lms";
import { getMaintenance } from "../../actions/maintenance";
import { resetRedux } from "../../actions/misc";

class CoursesPage extends React.Component {
    state = {
        // query: {
        //     searchterms: '',
        //     filterBy: 'all',
        //     sortBy: 'modified_on-desc',
        //     perPage: 20,
        //     page: 1
        // },
        randNum: false,
    };

    componentDidMount() {
        const { courseTypeList, contactHourList, productLevelList, productCreditList, lmsCoursesList } = this.props;
        // get courses
        this.props.dispatch(getCourses());

        // get course type
        if (!courseTypeList) this.props.dispatch(getMaintenance("course_type"));

        // get contact hour
        if (!contactHourList) this.props.dispatch(getMaintenance("contact_hour"));

        // get product level
        if (!productLevelList) this.props.dispatch(getMaintenance("product_level"));

        // get product credit
        if (!productCreditList) this.props.dispatch(getMaintenance("product_credit"));

        // get lms courses
        if (!lmsCoursesList) this.props.dispatch(getLMSCourses());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { coursesList, courseTypeList, contactHourList, productLevelList, productCreditList, lmsCoursesList, randNum } = this.props;
        if (
            coursesList &&
            lmsCoursesList &&
            courseTypeList &&
            contactHourList &&
            productLevelList &&
            productCreditList &&
            randNum &&
            randNum !== this.state.randNum
        ) {
            this.setState({ randNum });
        } // end - randNum
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux("courses"));
    }

    handleRefresh = (props) => {
        // get courses
        this.setState({ randNum: false });
        this.props.dispatch(resetRedux("courses"));
        this.props.dispatch(getCourses(props));
    };

    renderContents() {
        const { authData, coursesList, courseTypeList, contactHourList, productLevelList, productCreditList, lmsCoursesList } = this.props;
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={7}>
                        <AppInfoBox page="CoursesPage" />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        {" "}
                    </Grid>
                </Grid>
                <Table
                    courses={coursesList}
                    course_type={courseTypeList}
                    contact_hour={contactHourList}
                    product_level={productLevelList}
                    product_credit={productCreditList}
                    lms_courses={lmsCoursesList}
                    authData={authData}
                    onRefresh={this.handleRefresh}
                />
            </>
        );
    }

    render() {
        const { randNum } = this.state;
        const { coursesList, authData } = this.props;
        return (
            <AppWrapper
                subtitle="Setup"
                title="Product Profile"
                onLoad={!randNum ? true : false}
                contents={authData ? this.renderContents() : null}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        coursesList: state.courses && state.courses.courses ? state.courses.courses : null,
        randNum: state.courses && state.courses.rand ? state.courses.rand : null,
        courseTypeList: state.maintenance && state.maintenance.course_type ? state.maintenance.course_type : null,
        contactHourList: state.maintenance && state.maintenance.contact_hour ? state.maintenance.contact_hour : null,
        productLevelList: state.maintenance && state.maintenance.product_level ? state.maintenance.product_level : null,
        productCreditList: state.maintenance && state.maintenance.product_credit ? state.maintenance.product_credit : null,
        lmsCoursesList: state.canvas_lms && state.canvas_lms.courses ? state.canvas_lms.courses : null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(CoursesPage);
