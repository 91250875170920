/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _isEqual from 'lodash/isEqual';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';
import _split from 'lodash/split';

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader'
import SnackBarSave from '../../components/SnackBarSave';

import EditForm from './form';
import ItemNotFound from '../../components/ItemNotFound';

import { InfoButton, GreyButton } from '../../styles/button';

import { hasAccessRights } from '../../helpers/auth'; 
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { getDateNumFromString, isParentFieldRequired } from '../../helpers/date';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { getStudentProfile, updateStudentProfile } from '../../actions/students';
import { appChangesMade } from '../../actions/misc';

import { userStudentSchema } from '../../schemas/user_student';

class StudentProfilePage extends React.Component {

    state = {
        currentTab: 'details',
        student: false,
        randNum: false
    };

    componentDidMount() {
        const { authData } = this.props;
        
        // retrieve profile details
        this.props.dispatch(getStudentProfile(authData.uid));

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentStudent, randNum
            } = this.props;

        // for student
        if ( currentStudent && randNum && randNum !== this.state.randNum ) {
            this.setState({ student: cloneCollections( currentStudent ), randNum });
        } // end - currentStudent
    }

    handleSaveChanges = (event) => {
        const { student } = this.state;
        event.preventDefault();
        var error = false,
            formData = {};

        // do error check
        userStudentSchema.forEach(schema => {
            formData[schema.id] = ( student && student[schema.id] ? cloneCollections( student[schema.id] ) : schema.default );
            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        // make sure date format is correct
        if ( formData.date_of_birth_string && !_isEmpty( formData.date_of_birth_string ) ) {
            let splitData = _split( formData.date_of_birth_string, '-' );
            if ( !( splitData && splitData[0] && !_isEmpty( splitData[0] ) && splitData[1] && !_isEmpty( splitData[1] ) && splitData[2] && !_isEmpty( splitData[2] ) ) )
                error = 'Please fill out "Date of Birth" field';
        }

        // check for parent field
        if ( formData.date_of_birth_string && !_isEmpty( formData.date_of_birth_string ) && isParentFieldRequired( formData.date_of_birth_string ) ) {
            if ( !( formData.parent_city && !_isEmpty( formData.parent_city ) ) )
                error = 'Please fill out "Parent/Guardian City" field';

            if ( !( formData.parent_phone && !_isEmpty( formData.parent_phone ) ) )
                error = 'Please fill out "Parent/Guardian Mobile No." field';

            if ( !( formData.parent_email && !_isEmpty( formData.parent_email ) ) )
                error = 'Please fill out "Parent/Guardian Email" field';

            if ( !( formData.parent_name && !_isEmpty( formData.parent_name ) ) )
                error = 'Please fill out "Parent/Guardian Name" field';
        }

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateStudentProfile(formData));
        } // end - error

    }

    handleFormUpdate = (newValue) => {
        this.setState({ student: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }



    renderTabContents = () => {
        const { authData, currentStudent, changesMade } = this.props;
        const { currentTab, student } = this.state;
        const tabProps = {
            authData: ( authData || false ),
            student: ( student || false ),
            changesMade: ( changesMade || false ),
            onFormUpdate: this.handleFormUpdate
        };
        switch( currentTab ) {
            case 'details':
                return <EditForm 
                    currentStudent={( currentStudent || false )}
                    {...tabProps} />;
        }
    }

    renderContents() {
        return (
        <div>

            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                {this.renderTabContents()}
            </Paper>

            <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
            </div>

            <SnackBarSave onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { randNum } = this.state;
        const { currentStudent } = this.props;
        return <AppWrapper 
                title="My Profile"
                maxWidth="1110px"
                onLoad={( !( randNum ) ? true : false )}
                contents={ currentStudent && currentStudent.uid && !_isEmpty( currentStudent.uid ) ? this.renderContents() : <ItemNotFound item="Profile" />} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        currentStudent: state.students && state.students.profile || null,
        randNum: state.students && state.students.rand || null,
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(StudentProfilePage);