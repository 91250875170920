/* eslint-disable */
import React from "react";
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _filter from "lodash/filter";
import _split from "lodash/split";
import _map from "lodash/map";
import _trim from "lodash/trim";

import MentorAddClassCalendar from "../../modules/MentorAddClassCalendar";

import Table from "../ClassAttendanceReportingPage/table";
import ColumnsMenu from "../ClassAttendanceReportingPage/columns_menu";

import ModalView from "../../components/ModalView";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormRadio from "../../components/FormRadio";

import { FormBox } from "../../styles/form";

import { isAdmin, hasAccessRights } from "../../helpers/auth";
import { isArrayExists, isObjectExists } from "../../helpers/validation";
import { cloneCollections, getSelectOptions, getSelectValues } from "../../helpers/data";
import { getMomentTime } from "../../helpers/date";
import { isSchemaRequired } from "../../helpers/schemas";
import { getStudentEnrollmentStatus } from "../../helpers/trainees";

import { userStudentSchema } from "../../schemas/user_student";
import { COUNTRIES_LIST } from "../../data/const_countries";
import { STATE_OPTIONS } from "../../data/const_states";

const useStyles = (theme) => ({
    section_title: {
        fontSize: "20px",
        fontWeight: "700",
        color: theme.palette.background,
        paddingTop: "15px",
        paddingBottom: "15px",
        marginBottom: "5px",
        borderBottom: "1px solid #ddd",
    },
});

class ClassAttendances extends React.Component {
    utcOffSet = 8;

    state = {
        current_schedule_id: false,
        attendancesRand: false,
        columns: [],
        randNum: false,
    };

    componentDidMount() {
        const { schedule_id, attendancesRand } = this.props;
        this.setState({ columns: this.getDefaultColumns(), current_schedule_id: schedule_id, attendancesRand });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { schedule_id, attendancesRand, currentAttendances } = this.props;
        // console.log("---", schedule_id, attendancesRand, currentAttendances);
        if ((schedule_id && schedule_id !== this.state.current_schedule_id) || (attendancesRand && attendancesRand !== this.state.attendancesRand)) {
            // console.log("update");
            this.setState({ columns: this.getDefaultColumns(), current_schedule_id: schedule_id, attendancesRand });
        }
    }

    handleShowcaseUpdate = (formData) => {
        const { onShowcaseUpdate } = this.props;
        if (onShowcaseUpdate) onShowcaseUpdate(formData);
    };

    handleTraineesUpdate = (newTrainees) => {
        const { onFormUpdate } = this.props;
        // do update
        if (onFormUpdate) onFormUpdate(newTrainees);
    };

    excludeInactiveStudents = (trainees) => {
        return _filter(trainees, (t) => (getStudentEnrollmentStatus(t) !== "inactivate" ? true : false));
    };

    getDefaultColumns = () => {
        const { authData, attendances, currentAttendances } = this.props;
        let columns = [],
            class_calendar =
                currentAttendances && currentAttendances.class_calendar && isArrayExists(currentAttendances.class_calendar)
                    ? cloneCollections(currentAttendances.class_calendar)
                    : [];

        // original
        // if ( class_calendar && isArrayExists( class_calendar ) ) {
        //     let count = 1;
        //     // sort by start_date and reverse it
        //     class_calendar = _sortBy( class_calendar, ['start_date'] );
        //     class_calendar = _reverse( class_calendar );

        //     _forEach( class_calendar, event => {
        //         if ( count <= 3 ) {
        //             let column = cloneCollections(event);
        //             column.label = ( column.start_date ? getMomentTime( column.start_date, 'DD-MMM-YY' ) : column.id );
        //             columns.push(column);
        //             count++;
        //         }
        //     });
        // } // end - class_calendar

        let pastEvents = [],
            futureEvents = [],
            today = moment().utcOffset(this.utcOffSet).startOf("date").valueOf();

        if (currentAttendances && currentAttendances.class_calendar && isArrayExists(currentAttendances.class_calendar)) {
            pastEvents = _filter(currentAttendances.class_calendar, { type: "past_class" });
            // sort & reverse
            if (pastEvents && isArrayExists(pastEvents)) {
                pastEvents = _sortBy(pastEvents, ["start_date"]);
                pastEvents = _reverse(pastEvents);
            }

            futureEvents = _filter(currentAttendances.class_calendar, { type: "class" });
            // sort
            if (futureEvents && isArrayExists(futureEvents)) {
                futureEvents = _sortBy(futureEvents, ["start_date"]);
            }
        } // end - currentAttendances.class_calendar

        for (let p = 0; p < 3; p++) {
            if (pastEvents && pastEvents[p] && pastEvents[p].id && !_isEmpty(pastEvents[p].id)) {
                let passEvent = cloneCollections(pastEvents[p]);
                passEvent.label = passEvent.start_date ? getMomentTime(passEvent.start_date, "DD-MMM-YY") : passEvent.id;
                columns.push(passEvent);
            }
        }

        for (let p = 0; p < 3; p++) {
            if (futureEvents && futureEvents[p] && futureEvents[p].id && !_isEmpty(futureEvents[p].id)) {
                let futureEvent = cloneCollections(futureEvents[p]);
                futureEvent.label = futureEvent.start_date ? getMomentTime(futureEvent.start_date, "DD-MMM-YY") : futureEvent.id;
                columns.push(futureEvent);
            }
        }

        return columns;
    };

    renderTable() {
        const { authData, attendances, currentAttendances } = this.props;
        const { columns } = this.state;
        // console.log(currentAttendances.trainees);
        return (
            <Table
                editMode="mentor"
                columns={columns || false}
                schedule={currentAttendances && currentAttendances.schedule ? currentAttendances.schedule : false}
                currentSchedule={
                    currentAttendances && currentAttendances.schedule && currentAttendances.schedule.id ? currentAttendances.schedule.id : false
                }
                trainees={
                    attendances && attendances.trainees && isArrayExists(attendances.trainees)
                        ? this.excludeInactiveStudents(attendances.trainees)
                        : false
                }
                mentors={
                    currentAttendances && currentAttendances.mentor_list && isArrayExists(currentAttendances.mentor_list)
                        ? currentAttendances.mentor_list
                        : false
                }
                currentTrainees={
                    currentAttendances && currentAttendances.trainees && isArrayExists(currentAttendances.trainees)
                        ? currentAttendances.trainees
                        : false
                }
                course={currentAttendances && currentAttendances.course ? currentAttendances.course : false}
                badges={
                    currentAttendances && currentAttendances.showcase && currentAttendances.showcase.badges
                        ? currentAttendances.showcase.badges
                        : false
                }
                showcase={currentAttendances && currentAttendances.showcase ? currentAttendances.showcase : false}
                courses={currentAttendances && currentAttendances.courses ? currentAttendances.courses : false}
                rubrics={currentAttendances && currentAttendances.rubrics ? currentAttendances.rubrics : false}
                feedback_forms_fields={
                    currentAttendances && currentAttendances.feedback_forms_fields ? currentAttendances.feedback_forms_fields : false
                }
                authData={authData}
                onUpdate={this.handleTraineesUpdate}
                onShowcaseUpdate={this.handleShowcaseUpdate}
            />
        );
    }

    renderColumnsMenu = () => {
        const { authData, attendances, currentAttendances } = this.props;
        const { columns } = this.state;
        return (
            <ColumnsMenu
                schedule={
                    currentAttendances && currentAttendances.class_calendar && isArrayExists(currentAttendances.class_calendar)
                        ? { class_calendar: currentAttendances.class_calendar }
                        : false
                }
                columns={columns || false}
                mentorMode={true}
                onUpdate={(newColumns) => this.setState({ columns: newColumns })}
            />
        );
    };

    render = () => {
        const { classes, attendances, currentAttendances, authData, schedule_id, lms_course_id, onAddNewClass = () => {} } = this.props;

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MentorAddClassCalendar
                        authData={authData}
                        schedule_id={schedule_id}
                        lms_course_id={lms_course_id}
                        currentAttendances={currentAttendances}
                        class_calendar={
                            currentAttendances && currentAttendances.class_calendar && isArrayExists(currentAttendances.class_calendar)
                                ? currentAttendances.class_calendar
                                : []
                        }
                        schedule={
                            currentAttendances && currentAttendances.schedule && isObjectExists(currentAttendances.schedule)
                                ? currentAttendances.schedule
                                : {}
                        }
                        onAddNew={(newClass) => {
                            onAddNewClass(newClass);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    {this.renderColumnsMenu()}
                </Grid>
                <Grid item xs={12}>
                    {this.renderTable()}
                </Grid>
            </Grid>
        );
    };
}

export default compose(withStyles(useStyles), withRouter)(ClassAttendances);
