/* eslint-disable */
import _forEach from 'lodash/forEach';
import _sortBy from 'lodash/sortBy';
import _concat from 'lodash/concat';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _slice from 'lodash/slice';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import _toLower from 'lodash/toLower';
import _split from 'lodash/split';
import _last from 'lodash/last';
import _trim from 'lodash/trim';
import _uniq from 'lodash/uniq';
import _snakeCase from 'lodash/snakeCase';
import _remove from 'lodash/remove';
import _toString from 'lodash/toString';

import { isArrayExists, isObjectExists, safeUnescape } from './validation';
import { stringToInteger, stringToCurrency } from './number';

// get cloned collections
export const cloneCollections = function(collections) {
    return _cloneDeep( collections );
}

// do search in array
export const doArraySearch = function( data, keywords, compares ) {
    var newData = [],
        splitKeywords = keywords.split(' ');

    if ( isArrayExists( data ) ) {
        newData = _filter( data, function(single) {
            var found = false;

            if ( isArrayExists( splitKeywords ) ) {
                var splitCount = _size( splitKeywords ),
                    keywordCount = 0;
                _forEach( splitKeywords, function(keyword) {
                    if ( isArrayExists( compares ) ) {
                        var valid = false;
                        _forEach( compares, function(key) {
                            // check if key is object
                            if ( key.indexOf(".") >= 0 ) {
                                let splitKey = key.split('.');
                                // do object search
                                if ( splitKey[0] && !_isEmpty( splitKey[0] ) && splitKey[1] && !_isEmpty( splitKey[1] ) && doObjSearch( single[splitKey[0]], keyword, [ splitKey[1] ] ) )
                                    valid = true;
                            } else {
                                if ( _toLower( single[key] ).indexOf(_toLower(keyword)) !== -1 )
                                    valid = true;
                            } // end - key
                        });

                        if ( valid )
                            keywordCount++;
                    } // end - compares
                });
                if ( keywordCount == splitCount )
                    found = true;
            }

            return found;
        });
    } // end - data

    return newData;
}

// do search in object
export const doObjSearch = function( data, keywords, compares ) {
    var found = false,
        splitKeywords = keywords.split(' ');

    if ( isObjectExists( data ) ) {
        _forEach( data, function(single) {
            if ( isArrayExists( splitKeywords ) ) {
                var splitCount = _size( splitKeywords ),
                    keywordCount = 0;
                _forEach( splitKeywords, function(keyword) {
                    if ( isArrayExists( compares ) ) {
                        var valid = false;
                        _forEach( compares, function(key) {
                            if ( _toLower( single[key] ).indexOf(_toLower(keyword)) !== -1 )
                                valid = true;
                        });

                        if ( valid )
                            keywordCount++;
                    } // end - compares
                });
                if ( keywordCount == splitCount )
                    found = true;
            }
        });
    } // end - data

    return found;
}

// do pagination
export const doPagination = function( list, per_page, page ) {
    var start = per_page*(page-1),
        end = (per_page*page);
    return ( isArrayExists( list ) ? _slice( list, start, end ) : [] );
}

// replace all keywords on a given string
export const replaceAll = function( string, search, replacement ) {
	return string.replace(new RegExp(search, 'g'), replacement);
};

// get selectable options
export const getSelectOptions = function( props ) {
    const { list, options, keys, sortBy, unescape, sortList } = props;
    var returnData = []; 

    if ( isArrayExists( list ) ) {
        const items = ( sortList ? _sortBy( list, [ i => _toLower(i[sortList]) ]) : list );
        _forEach( items, function(single) {
            var label = ( single[keys.label] || '' ),
                option = { 
                    value: single[keys.value], 
                    label: ( unescape ? safeUnescape(label) : label ) 
                };

            // add additional keys if provided
            if ( keys && isObjectExists( keys ) ) {
                _forEach( keys, (val,key) => {
                    if ( key !== 'label' && key !== 'value' ) {
                        option[key] = ( single[val] || '' );
                    } // end - key
                });
            } // end - keys
            
            returnData.push(option);
        });
    }

    if ( isArrayExists( returnData ) && sortBy ) {
        returnData = _sortBy( returnData, (r) => ( _toLower( r[sortBy] ) ) );
    }

    if ( isArrayExists( options ) ) {
        return _concat( options, returnData );
    } else {
        return returnData;
    }
    
}

// get select values
export const getSelectValues = (options,key) => {
    var values = [];

    if ( options && isArrayExists( options ) ) {
        options.forEach(option => {
            if ( option[key] )
                values.push(option[key]);
        });
    } // end- options

    return values;
}

// get selected value
export const getSelectedValue = (options,selected,key,returnKey,defaultVal) => {
    let selectedVal = ( options && isArrayExists( options ) && selected && !_isEmpty( selected ) && key && !_isEmpty( key ) ? _find( options, o => selected === o[key] ) : false ); 
    return ( selectedVal && returnKey && !_isEmpty( returnKey ) && selectedVal[returnKey] ? selectedVal[returnKey] : ( defaultVal || null ) );
}

// reverse select values
export const reverseSelectValues = (options,key,oriVal) => {
    var values = [];

    if ( options && isArrayExists( options ) ) {
        options.forEach(option => {
            var val = ( oriVal && !_isEmpty( oriVal ) ? cloneCollections( oriVal ) : {} );
            val[key] = option;
            values.push(val);
        });
    } // end- options

    return values;
}

// convert array to object
export const convertArrayToObject = (array) => {
    let obj = {};
    if ( array && isArrayExists( array ) ) {
        _forEach( array, item => {
            if ( item.id && !_isEmpty( item.id ) ) {
                obj[item.id] = item;
            } // end - item.id
        });
    } // end - array
    return obj;
}

// convert object to array
export const convertObjectToArray = (obj) => {
    let list = [];
    if ( obj && isObjectExists( obj ) ) {
        _forEach( obj, item => {
            if ( item.id && !_isEmpty( item.id ) ) {
                list.push(item);
            } // end - item.id
        });
    } // end - obj
    return list;
}

// combine two string together ( useful for getting full name from first & last name)
export const combineStrings = function( first, second, third, fourth, fifth ) {
    var string = '';

    string += ( first && !_isEmpty( first ) ? _trim( first ) : '' );
    
	string += ( first && !_isEmpty( first ) && ( 
		( second && !_isEmpty( second ) ) || 
		( third && !_isEmpty( third ) ) || 
		( fourth && !_isEmpty( fourth ) ) || 
		( fifth && !_isEmpty( fifth ) )
	) ? ' ' : '' );
    string += ( second && !_isEmpty( second ) ? _trim( second ) : '' );
    
    string += ( second && !_isEmpty( second ) && ( 
		( third && !_isEmpty( third ) ) || 
		( fourth && !_isEmpty( fourth ) ) || 
		( fifth && !_isEmpty( fifth ) )
	) ? ' ' : '' );
    string += ( third && !_isEmpty( third ) ? _trim( third ) : '' );

    string += ( third && !_isEmpty( third ) && ( 
		( fourth && !_isEmpty( fourth ) ) || 
		( fifth && !_isEmpty( fifth ) )
	) ? ' ' : '' );
    string += ( fourth && !_isEmpty( fourth ) ? _trim( fourth ) : '' );

    string += ( fourth && !_isEmpty( fourth ) && fifth && !_isEmpty( fifth ) ? ' ' : '' );
    string += ( fifth && !_isEmpty( fifth ) ? _trim( fifth ) : '' );

	return replaceAll( _trim( string ), '  ', ' ' );
};


// combine multiple string together with separator
export const combineStringsBySeparator = function( items, separator ) {
	var string = '';

    if ( items && isArrayExists( items ) ) {
        _forEach( items, item => {
            // add separator
            string += ( !_isEmpty( string ) ? separator : '' );
            // add string
            string += ( item && !_isEmpty( item ) ? item : '' );
        });
    } // end - items

	return string;
};

/* Move array */
export const moveArray = function( obj, old_index, new_index ) {
	while (old_index < 0) {
        old_index += obj.length;
    }
    while (new_index < 0) {
        new_index += obj.length;
    }
    if (new_index >= obj.length) {
        var k = new_index - obj.length;
        while ((k--) + 1) {
            obj.push(undefined);
        }
    }
    obj.splice(new_index, 0, obj.splice(old_index, 1)[0]);
    
    return obj;
};

/* Move position */
export const movePosition = function( obj, current_pos, new_pos, flow ) {
    let newList = ( obj && isArrayExists( obj ) ? cloneCollections( obj ) : [] );
    if ( isArrayExists( newList ) ) {
        const currentindex = _findIndex( obj, (o) => ( parseInt( o.position ) === parseInt( current_pos ) ) ),
            newIndex = _findIndex( obj, (o) => ( parseInt( o.position ) === parseInt( new_pos ) ) );

        if ( currentindex >= 0 ) {
            newList[currentindex].position = parseInt( new_pos );
            if ( newIndex >= 0 ) {
                newList[newIndex].position = parseInt( current_pos );
            }
        } // end - currentIndex
    } // end - obj
    return newList;
};

/* recalculate position */
export const calculatePosition = function(list) {
    if ( list && isArrayExists( list ) ) {
        var tempList = _sortBy( list, ['position'] ),
            newList = cloneCollections( tempList ),
            count = 1;
        
        _forEach( tempList, function(item,index) {
            newList[index].position = count;
            count++;
        });
        
        list = newList;
    } // end - obj

    return list;
}

// break string into keywords array
export const compileKeywordsArray = function( string ) {
	var keywords = [],
		snake = ( string && !_isEmpty( string ) ? _snakeCase( _toLower( string ) ) : false );

	if ( snake ) {
		keywords = _split( snake, '_' );

		if ( isArrayExists( keywords ) )
			keywords = _uniq( keywords );
	}
	
	return keywords;
};

// break email into keywords array
export const compileEmailKeywordsArray = function( email ) {
	var keywords = [],
		splitEmail = ( email && !_isEmpty( email ) ? _split( _trim( _toLower( email ) ), '@', 2 ) : false );

	if ( splitEmail && isArrayExists( splitEmail ) && splitEmail[0] && !_isEmpty( splitEmail[0] ) ) {
		// add email ID
		keywords = compileKeywordsArray( splitEmail[0] );

        if ( splitEmail[1] && !_isEmpty( splitEmail[1] ) ) {

			var domainKeywords = compileKeywordsArray( splitEmail[1] );
			if ( domainKeywords && isArrayExists( domainKeywords ) ) {
				_forEach( domainKeywords , keyword => {
					keywords.push(keyword);
				});
			} // end - domainKeywords
        } // end - splitEmail[1]

	} // end - splitEmail

	// uniqify
	if ( isArrayExists( keywords ) )
		keywords = _uniq( keywords );

    // remove common extension
    var keywordsList = ( isArrayExists( keywords ) ? cloneCollections( keywords ) : [] );
    keywords = []; // reset it
    if ( isArrayExists( keywordsList ) ) {
        _forEach( keywordsList, keyword => {
            switch ( keyword ) {
                // ignore this for search
                case 'com':
                case 'net':
                case 'com.my':
                case 'my':
                    break;
                default:
                    keywords.push( keyword );
                    break;
            }
        });
    } // end - keywordsList
	
	return keywords;
};

// multidimension sort
const multiSortRecursive = ( a,b,columns,order_by,type,index ) => {
	var direction = order_by[index] == 'DESC' ? 1 : 0;

    if ( type[index] && type[index] === 'number' ) {
        var x = ( a[columns[index]] ? ( _toString( a[columns[index]] ).indexOf('.') >= 0 || _toString( a[columns[index]] ).indexOf(',') >= 0 ? +stringToCurrency( a[columns[index]].toString() ) : +stringToInteger( a[columns[index]].toString() ) ) : 0 );
        var y = ( b[columns[index]] ? ( _toString( b[columns[index]] ).indexOf('.') >= 0 || _toString( b[columns[index]] ).indexOf(',') >= 0 ? +stringToCurrency( b[columns[index]].toString() ) : +stringToInteger( b[columns[index]].toString() ) ) : 0 );
    } else if ( type[index] && type[index] === 'currency' ) {
        var x = ( a[columns[index]] ? +stringToCurrency( a[columns[index]].toString() ) : 0 );
        var y = ( b[columns[index]] ? +stringToCurrency( b[columns[index]].toString() ) : 0 );
    } else {
        var is_numeric = !isNaN(+a[columns[index]] - +b[columns[index]]);
        var x = is_numeric ? +a[columns[index]] : _toLower( a[columns[index]] );
        var y = is_numeric ? +b[columns[index]] : _toLower( b[columns[index]] );
    } // end - type[index]

	if(x < y) {
		return direction == 0 ? -1 : 1;
	}

	if(x == y)  {               
		return columns.length-1 > index ? multiSortRecursive(a,b,columns,order_by,index+1) : 0;
	}

	return direction == 0 ? 1 : -1;
}

export const doMultiSort = ( items, columns, order_by, type ) => {
	return ( items && isArrayExists( items ) ? items.sort((a,b) => {
		return multiSortRecursive(a,b,columns,order_by,type,0);
	}) : [] );
}

export const trimString = function( string, lowercase ) {
    return ( string ? ( lowercase ? _trim( _toLower( string ) ) : _trim( string ) ) : '' );
}

export const trimEmail = function(email) {
    return trimString(email,true);
}

export const compareString = function(string1,string2) {
    return ( _toString( string1 ) === _toString( string2 ) ? true : false );
}