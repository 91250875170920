/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import { Editor } from '@tinymce/tinymce-react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import _isEmpty from 'lodash/isEmpty';
import _toLower from 'lodash/toLower';
import _replace from 'lodash/replace';
import _random from 'lodash/random';

import ToolTipInfo from '../ToolTipInfo';

import theme from '../../theme';

class FormEditor extends React.Component {

    handleChange = (content) => {
        const { name } = this.props;
        if ( this.props.onChange ) {
            this.props.onChange(content,name);
        }
    }

    getDefaultPlugins = () => {
        return [
            'advlist autolink lists link image charmap preview hr anchor',
            'searchreplace visualblocks visualchars code',
            'insertdatetime media nonbreaking save table directionality',
            'paste textpattern imagetools toc'
        ];
    }

    getDefaultToolBar1 = () => {
        return 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image';
    }

    getDefaultToolBar2 = () => {
        return 'preview media | forecolor backcolor';
    }

    getLabelStyle = () => {
       const { tooltip } = this.props;
       let style = { 
           fontSize: "16px", 
           fontWeight: "700", 
           textTransform: "uppercase", 
           backgroundColor: "#fff", 
           paddingLeft: '5px', 
           paddingRight: '8px',
           paddingBottom: '10px' 
        };
        // if tooltip found
        if ( !_isEmpty( tooltip ) ) {
            style['pointerEvents'] = 'auto';
        }
        return style;
    }

    renderTooltipInfo = () => {
        const { tooltip } = this.props;
        return <ToolTipInfo content={( tooltip || false )} />
    }

    renderLabel = () => {
        const { label, tooltip } = this.props;
        var newLabel = false;
        if ( label && !_isEmpty( label ) && ( 
            ( _toLower( label ).indexOf("(required)") >= 0 ) || 
            ( _toLower( label ).indexOf("*") >= 0 )
         ) ) {
            newLabel = _replace( label, '(Required)', '' );
            newLabel = _replace( newLabel, '(required)', '' );
            newLabel = _replace( newLabel, '*', '' );
        } // end - label
        return (
        <>
            { tooltip && !_isEmpty( tooltip ) ? this.renderTooltipInfo() : null }
            { newLabel ? <span className="field-label">{ newLabel || '' }<span className="field-required required">*</span></span> : ( label || '' ) }
        </>
        );
    }

    render() {
        const { id, type, label, value, disabled, height, plugins, toolbar1, toolbar2, filePickerTypes, filePickerCallback } = this.props;
        const editorID = ( id ? id : "mce-editor-" + _random(1,9999) );
        return (
        <div style={{ background: "#fff", paddingTop: '10px', paddingBottom: '10px' }}>
            <FormControl variant="outlined" style={{ background: "#fff" }} fullWidth={true}>
                <InputLabel shrink={true} style={this.getLabelStyle()}>{this.renderLabel()}</InputLabel>
                <div style={{ paddingTop: '10px' }}>
                    <Editor
                        id={editorID}
                        value={( value ? value : '' )}
                        disabled={( disabled || false )}
                        init={{
                            height: ( height || 500 ),
                            plugins: plugins || this.getDefaultPlugins(),
                            toolbar1: toolbar1 || this.getDefaultToolBar1(),
                            toolbar2: toolbar2 || this.getDefaultToolBar2(),
                            file_picker_types: ( filePickerTypes || false ),
                            file_picker_callback: ( filePickerCallback ? filePickerCallback : null )
                        }}
                        onEditorChange={this.handleChange} />
                        <input id={'editor-mce-file-upload-field-'+editorID} type="file" name={'editor-mce-file_upload_field'} style={{ display: "none" }} />
                </div>
            </FormControl>
        </div>
        )
    }

}

export default FormEditor