export const traineeSchema = [
    { 
        id: 'id', 
        label: 'ID',
        required: ['update','delete'], 
        default: '', 
        type: 'string', 
        validation: 'string_id', 
        skip: ['add']
    },
    {   
        id: 'course_id',
        label: 'Course ID',
        required: false,
        default: '',
        type: 'system_data',
        skip: ['update'],
        dependency: { schema: 'schedule', id: 'course_id' }
    },
    {   
        id: 'program_id',
        label: 'Program',
        required: false,
        default: '',
        type: 'system_data',
        skip: ['update'],
        dependency: { schema: 'schedule', id: 'program_id' }
    },
    {   
        id: 'schedule_id',
        label: 'Class ID',
        required: ['add'],
        default: '',
        type: 'string',
        skip: ['update'],
    },
    {   
        id: 'region',
        label: 'Partner',
        required: false,
        default: '',
        type: 'system_data',
        skip: ['update'],
        dependency: { schema: 'schedule', id: 'region' }
    },
    {   
        id: 'register_source',
        label: 'Registration Source',
        required: false,
        default: 'registration_form',
        type: 'system_data',
        skip: ['update']
    },
    {   
        id: 'predefined_badge_type',
        label: 'Predefined Badge Type',
        required: false,
        default: '',
        type: 'system_data'
    },

    {   
        id: 'name',
        label: 'Full Name',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'email',
        label: 'Email',
        required: ['add','update'],
        default: '',
        type: 'email',
        field: 'email',
        disabled: ['update'],
    },
    {   
        id: 'gender',
        label: 'Gender',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'select',
    },
    {   
        id: 'age',
        label: 'Age',
        required: false,
        default: 1,
        type: 'number',
        field: 'text_number'
    },
    {   
        id: 'date_of_birth',
        label: 'Date of Birth (timestamp)',
        required: false,
        default: 0,
        type: 'timestamp',
        skip: false
    },
    {   
        id: 'date_of_birth_string',
        label: 'Date of Birth',
        required: ['add','update'],
        default: '',
        dateFormat: 'YYYY-MMM-DD',
        type: 'string',
        field: 'select_date'
    },
    {   
        id: 'ic_number',
        label: 'IC Number / Passport No',
        required: false,
        default: '',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'phone',
        label: 'Mobile No',
        required: false,
        default: '',
        type: 'string',
        field: 'phone'
    },
    {   
        id: 'org',
        label: 'School',
        required: false,
        default: '',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'medical_condition',
        label: 'Medical Condition',
        required: false,
        default: '',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'newsletter',
        label: 'Newsletter',
        required: false,
        default: 'no',
        type: 'string',
        field: 'select',
    },
    {   
        id: 'paid',
        label: 'Payment Received?',
        required: false,
        default: 'no',
        type: 'string',
        field: 'select',
    },
    {   
        id: 'payment_level',
        label: 'Payment Level',
        required: false,
        default: '', // example - l1,l2,l3,l4,l5
        type: 'string',
        field: 'select',
    },
    {   
        id: 'trial',
        label: 'I want to come for trial',
        required: false,
        default: 'no',
        type: 'string',
        field: 'checkbox',
    },
    {   
        id: 'confirmation_email_date',
        label: 'Confirmation Email Sent Date',
        required: false,
        default: 0,
        type: 'timestamp'
    },
    {   
        id: 'trial_email_date',
        label: 'Trial Email Sent Date',
        required: false,
        default: 0,
        type: 'timestamp'
    },
    {   
        id: 'enrollment_status',
        label: 'Student Status',
        required: ['update'],
        default: 'registered',
        type: 'string',
        field: 'select',
    },
    {   
        id: 'course_access',
        label: 'Course Access',
        required: false,
        default: [],
        type: 'array_ids',
        field: 'special',
        skip: false
    },
    {   
        id: 'payment_tracking',
        label: 'Payment Tracking',
        required: false,
        default: [],
        type: 'array_ids',
        field: 'special',
        skip: false
    },
    {   
        id: 'payment_linked_ids',
        label: 'Payment Linked IDs',
        required: false,
        default: [],
        type: 'array_obj',
        field: 'special',
        skip: false
    },
    {   
        id: 'attendances',
        label: 'Attendances & Reporting',
        required: false,
        default: [],
        type: 'array_obj',
        field: 'special',
        skip: false
    },
    {   
        id: 'showcase',
        label: 'Showcase',
        required: false,
        default: [],
        type: 'array_obj',
        field: 'special',
    },
    {   
        id: 'quarterly_reports',
        label: 'Quarterly Reports',
        required: false,
        default: [],
        type: 'array_ids',
        field: 'special',
    },
    {   
        id: 'mentor',
        label: 'Mentor',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
    },
    {   
        id: 'mentor_comments',
        label: "Mentor's General Comments",
        required: false,
        default: '',
        type: 'string',
        field: 'text',
    },
    {   
        id: 'mentor_comments_updated',
        label: "Mentor's General Comments (Last Updated)",
        required: false,
        default: 0,
        type: 'timestamp',
        field: 'date_time',
    },
    {   
        id: 'mentor_comments_updated_by',
        label: "Mentor's General Comments (Updated By)",
        required: false,
        default: '',
        type: 'string',
        field: 'text',
    },
    {   
        id: 'registration_form',
        label: 'Registration Form Template',
        required: false,
        default: '',
        type: 'system_data',
        skip: ['update'],
        dependency: { schema: 'schedule', id: 'registration_form' }
    },
    {   
        id: 'teams',
        label: 'Teams',
        required: false,
        default: [],
        type: 'array_ids',
        field: 'special',
    },
    {   
        id: 'custom_fields',
        label: 'Custom Fields',
        required: false,
        default: [],
        type: 'array_ids',
        field: 'special',
    },
    {
        id: 'custom_fields_form_id',
        label: 'Custom Fields Form ID',
        required: false,
        default: '',
        type: 'system_data',
    },

    {   
        id: 'parent_id',
        label: 'Guardian ID',
        required: false,
        default: '',
        type: 'system_data',
    },
    {   
        id: 'parent_name',
        label: 'Guardian Name',
        required: false,
        default: '',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'parent_email',
        label: 'Guardian Email',
        required: false,
        default: '',
        type: 'email',
        field: 'email',
    },
    {   
        id: 'parent_phone',
        label: 'Guardian Mobile No',
        required: false,
        default: '',
        type: 'string',
        field: 'phone'
    },
    {   
        id: 'parent_city',
        label: 'Guardian City',
        required: false,
        default: '',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'parent_role',
        label: 'Guardian Role',
        required: false,
        default: 'Parent',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'parent_hear_source',
        label: 'How did you hear about us?',
        required: false,
        default: [],
        type: 'array',
        field: 'tag_input',
    },
    {   
        id: 'remarks',
        label: 'Registration Remarks',
        required: false,
        default: '',
        type: 'string',
        field: 'textarea'
    },
    

    {   
        id: 'created_on',
        label: 'Created On',
        required: false,
        default: 0,
        type: 'system_date',
        skip: ['update']
    },
    {   
        id: 'modified_on',
        label: 'Modified On',
        required: false,
        default: 0,
        type: 'system_date'
    },
    {   
        id: 'last_modified_by',
        label: 'Modified By',
        required: false,
        default: '',
        type: 'system_modified_by'
    }
];