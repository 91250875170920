/* eslint-disable */
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _size from 'lodash/size';
import _find from 'lodash/find';
import _endsWith from 'lodash/endsWith';
import _split from 'lodash/split';

import { isArrayExists, validate } from './validation';
import { cloneCollections } from './data';

import { partnerSchema } from '../schemas/partner';
import { trainingLocationSchema } from '../schemas/training_location';
import { courseSchema } from '../schemas/course';
import { badgeSchema } from '../schemas/badge';

// is skip?
export const isSkip = (schema,action) => {
    return ( schema.skip && isArrayExists( schema.skip ) && _find( schema.skip, (r) => ( r == action ) ) ? true : false );
}

// is schema required
export const isSchemaRequired = (schema,action) => {
    return ( schema.required && isArrayExists( schema.required ) && _find( schema.required, (r) => ( r == action ) ) ? true : false );
}

// do schema error check
export const doSchemaErrorCheck = (val,schema,action) => {
    var valid = true; // by default is valid

    // if required - check for invalid condition
    if ( isSchemaRequired( schema, action ) ) {

        // if validation is needed
        if ( schema.validation && !_isEmpty( schema.validation ) ) {

            if ( !( val && validate( val, schema.validation ) ) )
                valid = false;

        } else {

            // else - just check if there is a value there
            switch( schema.type ) {
                case 'array_ids':
                    if ( val && isArrayExists( val ) ) {
                        _forEach( val, (obj) => {
                            if ( !( obj.id && !_isEmpty( obj.id ) ) )
                                valid = false;
                        });
                    } else {
                        valid = false;
                    } // end - val   
                    break;
                case 'array':
                    if ( !( val && isArrayExists( val ) ) )
                        valid = false;
                    break;
                case 'number':
                case 'timestamp':
                    if ( !( ( val && validate( val, 'integer' ) ) || 0 === val ) )
                        valid = false;
                    break;
                case 'email':
                    if ( !( val && validate( val, 'email' ) ) )
                        valid = false;
                    break;
                default:
                    if ( !( val && !_isEmpty( val ) ) )
                        valid = false;
                    break;
            } // end - schema.type

        } // end - schema.validation

    } // end - schema.required

    return valid;
}

// retrieve snapshot data from snapshot based on provided schema
export const getSnapshotDataBySchema = (schema,snapshot) => {
    var data = { id: snapshot.key },
        val = snapshot.val();

    if ( schema && isArrayExists( schema ) ) {
        _forEach( schema, key => {
            // get ID
            if ( key.id === 'id' ) {
                data[key.id] = snapshot.key;
            } else {
                // run through all the type
                switch( key.type ) {
                    case 'array':
                    case 'array_ids':
                        data[key.id] = [];
                        if ( snapshot.child(key.id).exists() && snapshot.child(key.id).hasChildren() ) {
                            snapshot.child(key.id).forEach( childSnapshot => {
                                // set a default value
                                var item = { id: childSnapshot.key };
                                // if have children - spread it out
                                if ( childSnapshot.hasChildren() ) {
                                    childSnapshot.forEach( itemSnapshot => {
                                        item[itemSnapshot.key] = itemSnapshot.val();
                                    });
                                } // end - childSnapshot
                                // push it to array
                                data[key.id].push(item);
                            });
                        } // end - snapshot.child(key.id)
                        break;
                    case 'number':
                    case 'timestamp':
                        data[key.id] = ( val && val[key.id] ? val[key.id] : key.default );
                        break;
                    case 'system_date':
                        data[key.id] = key.default;
                        if ( val && val[key.id] && val[key.id]._seconds && validate( val[key.id]._seconds, 'integer' ) ) {
                            data[key.id] = val[key.id]._seconds*1000;
                        } else if ( val && val[key.id] && validate( val[key.id], 'integer' ) ) {
                            data[key.id] = val[key.id];
                        }
                        break;    
                    default:
                        data[key.id] = ( val && val[key.id] && !_isEmpty( val[key.id] ) ? val[key.id] : key.default );
                        break;
                } // end - key
            } // end - key
        });
    } // end - schema

    return data;
}

// retrieve doc data from doc based on provided schema
export const getDocDataBySchema = (schema,doc) => {
    var data = {},
        val = doc.data();

    if ( schema && isArrayExists( schema ) ) {
        _forEach( schema, key => {
            // run through all the type
            switch( key.type ) {
                case 'array':
                case 'array_ids':
                    data[key.id] = ( val && val[key.id] && isArrayExists( val[key.id] ) ? val[key.id] : key.default );
                    break;
                case 'number':
                case 'timestamp':
                case 'currency':
                    data[key.id] = ( val && val[key.id] ? val[key.id] : key.default );
                    break;
                case 'system_date':
                    data[key.id] = key.default;
                    if ( val && val[key.id] && val[key.id].seconds && validate( val[key.id].seconds, 'integer' ) ) {
                        data[key.id] = val[key.id].seconds*1000;
                    } else if ( val && val[key.id] && val[key.id]._seconds && validate( val[key.id]._seconds, 'integer' ) ) {
                        data[key.id] = val[key.id]._seconds*1000;
                    } else if ( val && val[key.id] && validate( val[key.id], 'integer' ) ) {
                        data[key.id] = val[key.id];
                    }
                    break;   
                default:
                    data[key.id] = ( val && val[key.id] && !_isEmpty( val[key.id] ) ? val[key.id] : key.default );
                    break;
            } // end - key
        });
    } // end - schema

    return data;
}

// check if provided schema is sync data
export const isSync = (schema) => {
    return ( schema && schema.sync ? true : false );
}

// check if provided schema is report data
export const isReportData = (schema) => {
    return ( schema && schema.report ? true : false );
}

// check if provided schema is report visible
export const isReportVisible = (schema) => {
    return ( schema && schema.report_hidden ? false : true );
}

// get schema mod
export const getSchemaMod = (stringID) => {
    let splitData = ( stringID && !_isEmpty( stringID ) ? _split( stringID, '|' ) : false );
    return ( splitData && splitData[0] && !_isEmpty( splitData[0] ) ? splitData[0] : '' );
}

// get schema key
export const getSchemaKey = (stringID) => {
    let splitData = ( stringID && !_isEmpty( stringID ) ? _split( stringID, '|' ) : false );
    return ( splitData && splitData[1] && !_isEmpty( splitData[1] ) ? splitData[1] : '' );
}

// get selcted schemas based on mod provided
export const getSelectedSchemas = (mod) => {
    var schemas = [];
    switch (mod) {
        case 'partner': 
        case 'partners': 
            schemas = cloneCollections( partnerSchema ); 
            break;
        case 'training_location': 
        case 'training_locations': 
            schemas = cloneCollections( trainingLocationSchema ); 
            break;
        case 'course': 
        case 'courses': 
            schemas = cloneCollections( courseSchema ); 
            break;
        case 'badge': 
        case 'badges':  
            schemas = cloneCollections( badgeSchema ); 
            break;
    }
    return schemas;
}

// get selcted schemas based on mod provided
export const getSelectedSchema = (stringID) => {
    var mod = getSchemaMod( stringID ),
        key = getSchemaKey( stringID ),
        schemas = getSelectedSchemas(mod);
    return ( schemas && isArrayExists( schemas ) ? _find( schemas, { id: key }) : false );
}

// check if value provided is valid schema
export const isValidSchema = (string) => {
    var valid = false;
    if ( string && !_isEmpty( string ) ) {
        switch (string) {
            case 'partner': 
            case 'partners': 
            case 'training_location': 
            case 'training_locations': 
            case 'course': 
            case 'courses': 
            case 'badge': 
            case 'badges': 
                valid = true; break;
        }
    } // end - string
    return valid;
}

// get schema default value
export const getSchemaDefaultValue = (schemas,action) => {
    let data = {};
    if ( schemas && isArrayExists( schemas ) ) {
        _forEach( schemas, schema => {
            if ( !isSkip(schema,action) ) {
                data[schema.id] = ( schema.default || '' );
            }
        });
    } // end - schemas
    return data;
}