/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_TRAINEES,
	UPDATE_CLASS_ATTENDANCE_REPORTING,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_updateClassAttendanceReporting } from './fb';
import { fb_getTrainees } from '../trainees/fb';


export const updateClassAttendanceReporting = (formData,props) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateClassAttendanceReporting(formData)
		.then( results => {
			fb_getTrainees(props)
			.then(list => {
				// push new data
				dispatch({ type: GET_TRAINEES, payload: { list } });	
				// updated
				dispatch({ type: UPDATE_CLASS_ATTENDANCE_REPORTING });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Data Updated");
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}