/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Papa from 'papaparse';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

import StudentNameToDatabase from '../../modules/StudentNameToDatabase';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormSelect2 from '../../components/FormSelect2';
import FormDatePicker from '../../components/FormDatePicker';
import FormDateOfBirth from '../../components/FormDateOfBirth';
import FormPhoneField from '../../components/FormPhoneField';
import ButtonActions from '../../components/ButtonActions';
import ToolTipInfo from '../../components/ToolTipInfo';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp, getAge } from '../../helpers/date';
import { isAdmin, hasAccessRights, getUserRegionFilter } from '../../helpers/auth';
import { doSchemaErrorCheck } from '../../helpers/schemas';
import { getStudentEnrollmentStatus, checkForDuplication } from '../../helpers/trainees';

import { editTrainee, deleteTrainee } from '../../actions/trainees';

import { traineeSchema } from '../../schemas/trainee';

import { PLATFORM_COURSE_REGISTRATION_LINK, GENDER_OPTIONS, ENROLLMENT_STATUS_OPTIONS } from '../../constants';

class StudentsTable extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        sortBy: 'date-desc',
        filterBy: 'all',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false,
        openImportModal: false,
        randNum: false,
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { traineesRand, onSelectedChange } = this.props;
        // reset checkbox
        if ( prevProps.traineesRand && traineesRand && prevProps.traineesRand !== traineesRand ) {
            this.setState({ checked: [], allCheck: false });
            onSelectedChange([]);
        }
    }

    doCSVDownload = (results) => {
        let csv = Papa.unparse( results, { delimiter: ',' } );
        let blob = new Blob( [csv] , { type: 'text/csv;charset=utf-8;' }); //new way
        let uri = window.URL.createObjectURL(blob);
        let link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", '_blank');
        link.setAttribute("download", "export_course_confirmation.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    handleCSVDownload = () => {
        const { trainees, schedules, currentSchedule, regions } = this.props;
        const { checked } = this.state;
        if ( checked && isArrayExists( checked ) ) {
            let schedule = ( schedules ? _find( schedules, { id: currentSchedule } ) : false );
            let csvData = {
                    fields: [
                        "Partner",
                        "Class Name",
                        "Registration Date",
                        "Email",
                        "Name",
                        "Want Trial?",
                        "Class Status",
                        "Confirmation Email Sent Date"
                    ],
                    data: []
                };

            _forEach( checked, selected => {
                let item = _find( trainees, { id: selected.id } );
                if ( item && !_isEmpty( item ) ) {
                    let row = [];
                    row.push( getSelectedValue( regions, item.region, 'id', 'label', '-' ) );
                    row.push( schedule && schedule.class_name ? schedule.class_name : '' );
                    row.push( ( item.created_on ? getMomentTime( item.created_on, 'DD MMM YYYY' ) : '' ) );
                    row.push( ( item && item.email || '' ) );
                    row.push( ( item && item.name || '' ) );
                    row.push( ( item && item.trial && item.trial === 'yes' ? 'yes' : 'no' ) );
                    row.push( ( item && item.enrollment_status ? item.enrollment_status : '' ) );
                    row.push( ( item.confirmation_email_date ? getMomentTime( item.confirmation_email_date, 'DD MMM YYYY' ) : '' ) );

                    csvData.data.push(row);
                } // end - trainee
            });
            this.doCSVDownload(csvData);
        } else {
            triggerErrorAlert('Please select at least one student');
        } // end - checked
    }

    handleSendMassEmail = (email_type) => () => {
        const { trainees, onEmailSend } = this.props;
        const { checked } = this.state; 
        let error = false,
            list = [];
        // compile trainees list
        if ( checked && isArrayExists( checked ) ) {
            _forEach( checked, item => {
                let trainee = _find( trainees, { id: item.id } );
                if ( trainee )
                    list.push({ id: trainee.id, email: ( trainee.email || '' ) });
            });
        } // end - checked

        if ( !( list && isArrayExists( list ) ) )
            error = 'Please select at least one student';

        // make sure only trainees that is confirmed
        if ( list && isArrayExists( list ) ) {
            _forEach( list, item => {
                let trainee = _find( trainees, { id: item.id } );
                if ( trainee && trainee.enrollment_status && trainee.enrollment_status === 'registered' )
                    error = "Please select only student(s) that have 'confirmed' enrolment status.";
            });
        } // end - list

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            if ( onEmailSend ) {
                onEmailSend({ 
                    trainees: list,
                    email_type     
                });
            } // end = onEmailSend
        }

    }

    handleCheckboxChange = (newValue) => {
        const { onSelectedChange } = this.props;
        this.setState({ checked: newValue });
        if ( onSelectedChange )
            onSelectedChange(newValue);
    }

    getActions = () => {
        const { authData } = this.props;
        let actions = [];

        // actions.push({ 
        //     id: 'resend_confirmation_email', label: 'Re-Send Confirmation Email', icon: 'fa-envelope-o', disabled: true
        // });

        actions.push({ 
            id: 'send_reminder_email', label: 'Send Reminder Email', icon: 'fa-envelope-o', onClick: this.handleSendMassEmail('reminder')
        });

        actions.push({ 
            id: 'downloadcsv', label: 'Download Selected Data as CSV', icon: 'fa-cloud-download', onClick: this.handleCSVDownload
        });

        return actions;
    }

    reorganizeData() {
        const { searchterms, sortBy, perPage, page, filterBy } = this.state;
        const { trainees } = this.props;
        let items = ( trainees ? cloneCollections( trainees ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','email','org'] );
			total = _size( items );
        } // end - searchterms

        // do filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy !== 'all' ) {
            if ( filterBy === 'duplicates' ) {
                items = _filter( items, i => checkForDuplication( trainees,i.email) );
                total = _size( items );
            } else if ( filterBy === 'bounced' ) {
                items = _filter( items, i => ( i && i.bounced_emails && isArrayExists( i.bounced_emails ) && _find( i.bounced_emails, e => e === i.email ) ? true : false ) );
                total = _size( items );
            } else {
                items = _filter( items, { enrollment_status: filterBy });
                total = _size( items );
            }
        } // end - filterBy

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['created_on'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['created_on'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
                case 'email-desc':
                    items = _sortBy( items, ['email'] );
                    items = _reverse( items );
                    break;
                case 'email-asc':
                    items = _sortBy( items, ['email'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderEnrollmentStatus = type => item => {
        if ( item[type] && !_isEmpty( item[type] ) ) {
            switch( item[type] ) {
                case 'registered':
                    return <WrapWord style={{ fontSize: '12px', fontWeight: '700', textTransform: 'uppercase', padding: '4px 8px' }}>{item[type]}</WrapWord>;
                case 'trial':
                    return <AmberTag style={{ fontSize: '12px', fontWeight: '700', textTransform: 'uppercase', padding: '4px 8px' }}>{item[type]}</AmberTag>;
                case 'confirmed':
                    return <SuccessTag>{item[type]}</SuccessTag>;
                case 'active':
                    return <InfoTag>{item[type]}</InfoTag>;
                case 'inactivate':
                    return <GreyTag>Inactive</GreyTag>;
                case 'conclude':
                    return <SuccessTag>Concluded</SuccessTag>;
                case 'delete':
                    return <ErrorTag>Deleted</ErrorTag>;
            } // end - item[type]
        } // end - item[type]
    }

    renderCell = type => item => {
        const { regions, schedules, currentSchedule, trainees } = this.props;
        switch( type ) {
            case 'region':
                return getSelectedValue( regions, item[type], 'id', 'label', '-' );
            case 'age':
                return ( item.date_of_birth_string && !_isEmpty( item.date_of_birth_string ) ? getAge( item.date_of_birth_string ) : ( item[type] || '-' ) );
            case 'created_on':
            case 'confirmation_email_date':
                return <WrapWord>{( item[type] ? getMomentTime( item[type], 'DD MMM YYYY' ) : '---' )}</WrapWord>;
            case 'email':
                return (
                <>
                    <WrapWord>{( item[type] || '-' )}</WrapWord>
                    { checkForDuplication(trainees,item[type]) ? <div><AmberTag showBorder="yes" style={{ marginTop: '10px' }}><ToolTipInfo icon_color="#ffa000" content={
                    <>
                        DUPLICATED<br /><br />
                        There are more than 1 registrations with the same email address. Please delete the duplicate(s) to proceed.  
                    </>
                    } />DUPLICATED</AmberTag></div> : null }
                    { item[type] && item.bounced_emails && isArrayExists( item.bounced_emails ) && _find( item.bounced_emails, e => e === item[type] ) ? <div><AmberTag showBorder="yes" style={{ marginTop: '10px' }}><ToolTipInfo icon_color="#ffa000" content={
                    <>
                        BOUNCED<br /><br />
                        Email got bounced when sending registration receipt. This email may be incorrect. Please verify with the student.  
                    </>
                    } />BOUNCED</AmberTag></div> : null }
                </>
                );
            case 'class_name':
                let schedule = ( schedules ? _find( schedules, { id: currentSchedule } ) : false );
                return <WrapWord>{( schedule && schedule.class_name ? schedule.class_name : '' )}</WrapWord>;
        }
    }

    renderTableActions = () => {
        const { authData } = this.props;
        const { sortBy, perPage, searchterms, checked, filterBy } = this.state;
        return <TableBar
                sortBy={sortBy}
                // show={['entries','sortby']}
                perPage={perPage}
                searchterms={searchterms}
                filterBy={filterBy}
                sortByOptions={[
                    { value: 'date-desc', label: 'Registration Date (Recent First)' },
                    { value: 'date-asc', label: 'Registration Date (Oldest First)' },
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' },
                    { value: 'email-asc', label: 'Email ( A - Z)' },
                    { value: 'email-desc', label: 'Email ( Z - A )' },
                ]}
                leftButtons={ checked && isArrayExists( checked ) ? [
                    <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                        <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                    </div>
                    ] : null }
                rightButtons={[
                    <ButtonActions 
                        key="actions" 
                        label="Actions"
                        menuContainerStyle={{ width: "250px" }}
                        style={{ marginRight: "5px" }}
                        actions={this.getActions()} />
                ]}
                filterByOptions={getSelectOptions({ list: _filter( ENROLLMENT_STATUS_OPTIONS, { confirmation: true } ), options: [{ value: 'all', label: 'All Status' },{ value: 'duplicates', label: 'Email Duplicated' },{ value: 'bounced', label: 'Email Bounced' }], keys: { value: 'value', label: 'label' } })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    doneLoaded={true}
                    // entriesLabel="(Total Registration For This Class)"
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { authData, users } = this.props;
        const { openDeleteModal, deleteModal, openViewModal, allCheck, checked } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            {/* <ModalView 
                open={openViewModal}
                title="Edit Student Details"
                maxWidth="lg"
                onClose={() => this.setState({ openViewModal: false, modalData: false })}
                disableBackdrop={true}
                actionLabel="Update"
                doAction={this.handleTraineeUpdate}
                contents={this.renderStudentForm()} /> */}

            {this.renderTableActions()}
            <Table 
                items={items}
                showCheckbox={true}
                emptyCell="No Student(s) Found."
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={this.handleCheckboxChange}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                cells={[
                    { id: 'region', label: 'Partner', render: this.renderCell('region') },
                    { id: 'class_name', label: 'Class Name', render: this.renderCell('class_name') },
                    { id: 'created_on', label: 'Registration Date', render: this.renderCell('created_on') },
                    { id: 'email', label: 'Email', render: this.renderCell('email') },
                    { id: 'name', label: 'Name', render: (item) => <StudentNameToDatabase {...item} /> },
                    { id: 'trial', label: 'Want Trial?', render: (item) => ( item.trial && item.trial === 'yes' ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> ) },
                    { id: 'enrollment_status', label: 'Class Status', render: this.renderEnrollmentStatus('enrollment_status') },
                    { id: 'confirmation_email_date', label: 'Confirmation Email Sent Date', render: this.renderCell('confirmation_email_date') },
                ]}
                // actionStyles={{ width: "10%" }}
                // actions={(item) => (
                //     <ButtonGroup>
                //         { item.enrollment_status && item.enrollment_status === 'delete' ? null : <InfoButton key="edit" size="small" onClick={() => this.setState({ openViewModal: true, modalData: item })}><i className="fa fa-edit"></i>Edit</InfoButton> }
                //     </ButtonGroup>
                // )} 
                />
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(StudentsTable);