/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Alert from "react-s-alert";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _remove from 'lodash/remove';
import _filter from 'lodash/filter';

import DotsLoader from '../../components/DotsLoader';
import Table from '../../components/Table';
import TableBar from '../../components/TableBar';
import ModalView from '../../components/ModalView';
import ModalDelete from '../../components/ModalDelete';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
// import FormRadio from '../../components/FormRadio';
import FormSelect2 from '../../components/FormSelect2';
import FormEditor from '../../components/FormEditor';
import FormUpload from '../../components/FormUpload';
import FormTagInput from '../../components/FormTagInput';

import BadgeDetails from '../BadgeDetailsPage/details';

import { InfoButton, GreyButton, ButtonGroup, InverseButton, ErrorButton } from '../../styles/button';
import { WrapWord } from '../../styles/misc';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions } from '../../helpers/data';
import { uploadMediaToStorage } from '../../helpers/firebase';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { isSkip, isSchemaRequired, doSchemaErrorCheck } from '../../helpers/schemas';

import { getBadges, addBadge, editBadge, deleteBadge } from '../../actions/badges';
import { getMaintenance } from '../../actions/maintenance';
import { toggleLoader } from '../../actions/global';
import { resetRedux } from '../../actions/misc';

import { badgeSchema } from '../../schemas/badge';

import { YES_NO_OPTIONS, PLATFORM_LEGACY_BADGE_TYPES } from '../../constants';


const TableWrapper = styled.div`
    display: block;
    padding: 10px 15px 0px 15px;
    width: 100%;

    .MuiPaper-elevation2 { box-shadow: none; border-radius: 0px; width: 100%; }
    .MuiTable-root { border: 1px solid #999; }
    .MuiTableCell-root { 
        border-color: #999; 
        padding: 8px; 
        &.MuiTableCell-head { 
            font-size: 14px; 
            background: #f1f1f1;
        }
    }
`;

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '14px',
        verticalAlign: 'top'
    }
});

const filePickerCallBack = (authData,course_id,field,dispatch) => ( callback, value, meta ) => {
    const input = document.getElementById('editor-mce-file-upload-field-' + field.name + '-' + ( course_id && !_isEmpty( course_id ) ? course_id : '' ) );

    input.click();
    input.onchange = function () {
        const file = input.files[0];
        const uploadPath = 'courses/' + ( course_id && !_isEmpty( course_id ) ? course_id : 'misc' ) + '/content';
        Alert.success('<span class="app-alert text-center content-block"><i class="fa fa-circle-o-notch fa-spin"></i>Uploading image - Please do not click on anything, browser back button, refresh or close this page!</span>', { position: 'top', effect: 'flip', beep: false, timeout: 'none', offset: 70 });
        dispatch(toggleLoader(true));
        uploadMediaToStorage(file, uploadPath, authData)
        .then(function(url) {
            Alert.closeAll();
            dispatch(toggleLoader(false));
            triggerSuccessAlert('Upload Done!');
            callback(url);
        })
        .catch(function(error) {
            Alert.closeAll();
            dispatch(toggleLoader(false));
            triggerErrorAlert(error.data.error);
        });

    };
}

class Badges extends React.Component {

    state = {
        badges: [],
        sortBy: 'date-desc',
        openModal: false,
        modalType: false,
        selectItem: false,
        openBadgePreview: false, 
        selectedBadge: false,
        randNum: false
    };

    componentDidMount() {
        const { course_id, courseTypeList, badgeTypeList, certificatesList } = this.props;
        
        // retrieve badges based on course_id
        this.props.dispatch(getBadges(course_id));

        // get course_type
        if ( !courseTypeList )
            this.props.dispatch(getMaintenance('course_type'));

        // get course_type
        if ( !badgeTypeList )
            this.props.dispatch(getMaintenance('badge_type'));

        // get certificate templates
        if ( !certificatesList )
            this.props.dispatch(getMaintenance('certificates'));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { badgesList, randNum, 
            courseTypeList, badgeTypeList, certificatesList 
            } = this.props;
        
        // for badges
        if ( badgesList && courseTypeList && badgeTypeList && certificatesList && randNum && randNum !== this.state.randNum ) {
            this.setState({ badges: cloneCollections( badgesList ), randNum });
        } // end - badgesList
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('badges'));
    }

    handleUpdate = (action) => {
        const { selectItem } = this.state;
        let error = false,
            formData = {};

        // do error check
        badgeSchema.forEach(schema => {
            formData[schema.id] = ( selectItem && selectItem[schema.id] ? cloneCollections( selectItem[schema.id] ) : schema.default );
            if ( !doSchemaErrorCheck( formData[schema.id], schema, action ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            if ( action === 'add' ) {
                this.props.dispatch(addBadge(selectItem));
            } else {
                this.props.dispatch(editBadge(selectItem));
            }
        } // end - error
    }

    handleDelete = () => {
        const { selectItem } = this.state;
        //perform delete
        this.props.dispatch(deleteBadge(selectItem.id));
    }

    handleAction = () => {
        const { modalType } = this.state;
        if ( modalType && modalType === 'new' ) {
            this.handleUpdate('add');
        } else if ( modalType && modalType === 'edit' ) {
            this.handleUpdate('update');
        } else if ( modalType && modalType === 'delete' ) {
            this.handleDelete();
        }
    }

    handleFormUpdate = (newValue,key) => {
        const { courseTypeList, badgeTypeList } = this.props;
        const { selectItem } = this.state;
        let newData = ( selectItem && !_isEmpty( selectItem ) ? cloneCollections( selectItem ) : {} );
        switch( key ) {
            case 'course_type':
                let selected_course_type = ( courseTypeList && isArrayExists( courseTypeList ) ? _find( courseTypeList, { id: newValue } ) : false );
                newData[key] = newValue;
                newData[key+"_label"] = ( selected_course_type && selected_course_type.label ? selected_course_type.label : '' );
                break;
            case 'type':
                let selected_badge_type = ( badgeTypeList && isArrayExists( badgeTypeList ) ? _find( badgeTypeList, { id: newValue } ) : false );
                newData[key] = newValue;
                newData[key+"_label"] = ( selected_badge_type && selected_badge_type.label ? selected_badge_type.label : '' );
                break;  
            default:
                newData[key] = newValue;
                break;
        } // end - key
        this.setState({ selectItem: newData });
    }

    handleFileUpload = (files,key) => {
        const { authData, dispatch, course_id } = this.props;
        if ( files && files[0] && !_isEmpty( files[0] ) ) {
            dispatch(toggleLoader(true));
            const uploadPath = 'courses/' + ( course_id && !_isEmpty( course_id ) ? course_id : 'misc' ) + ( key && key === 'web_course_image' ? '/image' : '/content' );
            uploadMediaToStorage( files[0], uploadPath, authData )
            .then( url => {
                this.handleFormUpdate(url,key);
                dispatch(toggleLoader(false));
            })
            .catch(error => {
                dispatch(toggleLoader(false));
                triggerErrorAlert(( error && error.message || 'Upload Error. Please try again' ));
            });
        } else {
            triggerErrorAlert('Please upload a valid image file');
        }
    }

    handleModaltrigger = (type,item) => event => {
        event.preventDefault();
        const { changesMade } = this.props;
        if ( changesMade ) {
            triggerErrorAlert("Please save changes first before continue");
        } else {
            this.setState({ openModal: true, modalType: type, selectItem: item });
        }
    }

    getNewBadgeProps = () => {
        const { course } = this.props;
        let badge = {};
        badgeSchema.forEach(schema => {
            if ( !isSkip( schema, 'add' ) ) {
                switch ( schema.id ) {
                    case 'name':
                    case 'course_type':
                    case 'course_type_label':
                        badge[schema.id] = ( course && course[schema.id] && !_isEmpty( course[schema.id] ) ? course[schema.id] : '' );
                        break;
                    case 'references':
                        badge[schema.id] = { course_id: ( course && course.id ? course.id : '' ) };
                        break;
                    case 'criteria_text':
                        badge[schema.id] = ( course && course['web_what_you_learn'] && !_isEmpty( course['web_what_you_learn'] ) ? course['web_what_you_learn'] : '' );
                        break;
                    case 'description':
                        badge[schema.id] = ( course && course['web_synopsis'] && !_isEmpty( course['web_synopsis'] ) ? course['web_synopsis'] : '' );
                        break;
                    default:
                        badge[schema.id] = ( schema.default || null );
                        break;
                } // end - schema.id
            }
        });
        return badge;
    }

    reorganizeData = () => {
        const { badges, sortBy } = this.state;
        let items = ( badges && isArrayExists( badges ) ? cloneCollections( badges ) : [] )
        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, (i) => ( i.modified_on && i.modified_on._seconds ? i.modified_on._seconds : i.modified_on ) );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, (i) => ( i.modified_on && i.modified_on._seconds ? i.modified_on._seconds : i.modified_on ) );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
            }
        } // end - sortBy

        return items;
    }

    getTooltip = (schema) => {
        const { history } = this.props;
        switch( schema.id ) {
            case 'description':
                return (
                <>
                    This description is pulled from Product Synopsis, describing the context and achievements. To refine this further, convert this passage from “you” to “badge earner”, and from future tense to past tense.<br /><br />
                    Read examples <a href="https://www.umt.edu/umonline/services-and-support/microcredentials_faculty/credly-best-practices-081220.pdf" target="_blank">here</a>.
                </>
                );
            case 'criteria_text':
                return (
                <>
                    Badge Criteria is pulled from Product Structure, telling about the tasks completed by students to qualify for this badge. <br /><br />
                    Read examples <a href="https://www.umt.edu/umonline/services-and-support/microcredentials_faculty/credly-best-practices-081220.pdf" target="_blank">here</a>. 
                </>
                );
            case 'cert_referer':
                return (
                <>
                    Create Certificate <a href="/maintenance/certificates" target="_blank">Here</a>
                </>
                )
            default:
                return;
        }
    }

    getFieldOptions = (field) => {
        const { courseTypeList, badgeTypeList, certificatesList } = this.props;
        const { badges, modalType } = this.state;
        switch( field.name ) {
            case 'course_type':
                return getSelectOptions({ list: ( courseTypeList || false ), options: [{ value: '', label: 'Select an Option' }], keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' });
            case 'type':
                let defaultTypeOptions = [], 
                    badgeTypeOptions = false;
                if ( modalType && modalType === 'new' ) {
                    badgeTypeOptions = ( badgeTypeList && isArrayExists( badgeTypeList ) ? _filter( badgeTypeList, b => ( !( badges && isArrayExists( badges ) && _find( badges, { type: b.id }) ) ? true : false )) : false );
                } else {
                    badgeTypeOptions = ( badgeTypeList || false );
                    defaultTypeOptions = [ ...defaultTypeOptions, ...PLATFORM_LEGACY_BADGE_TYPES ];
                }
                return getSelectOptions({ list: badgeTypeOptions, options: defaultTypeOptions, keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' });
            case 'cert_referer':
                return getSelectOptions({ list: certificatesList, options: [{ value: '', label: 'None' }], keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' });
            case 'publish':
                return YES_NO_OPTIONS;
            default:
                return [];
        }
    }

    getFieldValue = (field) => {
        const { selectItem } = this.state;
        switch( field.name ) {
            default:
                return ( selectItem && selectItem[field.name] ? selectItem[field.name] : ( field.default || '' ) );
        }
    }

    isFieldDisabled = (schema) => {
        const { authData } = this.props;
        let disabled = false;

        if ( schema && schema.disabled && isArrayExists( schema.disabled ) ) {
            schema.disabled.forEach( condition => {
                switch( condition ) {
                    case 'admin':
                        if ( !isAdmin( authData ) )
                            disabled = true;
                        break;
                    case 'update':
                        disabled = true;
                        break;
                }
            });
        } // end - schema

        return disabled
    }

    getField = (id) => {
        let schema = _find( badgeSchema, { id } );
        return ( schema ? {
            name: ( schema.id || '' ),
            label: ( schema.label || '' ) + ( isSchemaRequired(schema,'update') ? ' (Required)' : '' ),
            field_type: ( schema.field || '' ),
            default: ( schema.default || null ),
            disabled: ( schema.disabled ? this.isFieldDisabled( schema ) : false ),
            tooltip: this.getTooltip(schema)
        } : null );
    }

    renderField = (id) => {
        const { authData, dispatch, course_id } = this.props;
        let field = this.getField(id);
        if ( field && field.field_type && !_isEmpty( field.field_type ) ) {
            switch( field.field_type ) {
                case 'text':
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'textarea':
                    return <FormInput {...field} rows={( 'notes' === id ? 6 : 3 )} multiline={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'text_number':
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'select':
                    return <FormSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} disableNative={( id === 'type' || id === 'cert_referer' ? true : false )} onChange={this.handleFormUpdate} />;
                // case 'multiselect':
                //     return <FormMultiSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} />;
                case 'tinymce_editor':
                    return <FormEditor id={id + '-' + ( course_id && !_isEmpty( course_id ) ? course_id : '' )} {...field} value={this.getFieldValue(field)} height={300} onChange={this.handleFormUpdate} filePickerTypes= 'image' filePickerCallback={filePickerCallBack(authData,course_id,field,dispatch)} />;
                case 'upload_image':
                    return <FormUpload {...field} value={this.getFieldValue(field)} onReset={this.handleFormUpdate} accept="image/png" onUpload={this.handleFileUpload} />
                case 'tag_input':
                    return <FormTagInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
            }
        } // end - field.field_type
    }

    renderModal = () => {
        const { modalType, selectItem } = this.state;
        return (
        <div>
            <Grid container spacing={2}>
                {/* <Grid item xs={6}>{this.renderField('course_type')}</Grid>
                <Grid item xs={6}> </Grid> */}
                <Grid item xs={12}>{this.renderField('name')}</Grid>
                <Grid item xs={6}>{this.renderField('image')}</Grid>
                <Grid item xs={6}>{this.renderField('type')}</Grid>
                <Grid item xs={12}>{this.renderField('description')}</Grid>
                <Grid item xs={12}>{this.renderField('criteria_text')}</Grid>
                <Grid item xs={12}>{this.renderField('standard')}</Grid>
                <Grid item xs={6}>{this.renderField('issuer_name')}</Grid>
                <Grid item xs={6}>{this.renderField('issuer_url')}</Grid>
                <Grid item xs={12}>{this.renderField('cert_referer')}</Grid>

                <Grid item xs={12}>{this.renderField('tags')}</Grid>
            
            </Grid>
        </div>
        )
    }

    renderBadgePreview = () => {
        const { selectedBadge } = this.state;
        return (
        <div>
            <BadgeDetails 
                preview={true}
                badge={( selectedBadge || false )} />
        </div>
        );
    }

    renderCell = type => item => {
        const { badgeTypeList } = this.props;
        switch( type ) {
            case 'type':
                let selected_badge_type = ( badgeTypeList && isArrayExists( badgeTypeList ) && item[type] && !_isEmpty( item[type] ) ? _find( badgeTypeList, { id: item[type] } ) : false );
                return ( selected_badge_type && selected_badge_type.label ? selected_badge_type.label : '' );
            case 'preview':
                return <InfoButton size="small" style={{ padding: '5px 8px' }} onClick={() => this.setState({ openBadgePreview: true, selectedBadge: item })}><i className="fa fa-search"></i>Preview Badge</InfoButton>;
        }
    }

    renderTableActions = () => {
        const { authData } = this.props;
        const { sortBy } = this.state;
        return <TableBar
                show={['sort']}
                sortBy={sortBy}
                perPage={9999}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Modified first' },
                    { value: 'date-asc', label: 'Oldest Modified first' },
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' }
                ]}
                leftButtons={[
                    <InfoButton minWidth="128px" key="addnew" style={{ padding: '8px 15px' }} onClick={this.handleModaltrigger('new',this.getNewBadgeProps())}><i className="fa fa-plus-circle"></i>Add New Badge</InfoButton>
                ]}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy })}
                style={{ marginBottom: "20px" }} />
    }

    renderTable = () => {
        const { authData } = this.props;
        return <Table 
            items={this.reorganizeData()}
            showCheckbox={false}
            cells={[
                { id: 'type', label: 'Badge Level', render: this.renderCell('type') },
                { id: 'preview', label: 'Badge Display Preview', render: this.renderCell('preview') },
            ]}
            actionStyles={{ width: "25%" }}
            actions={(item) => (
                <ButtonGroup>
                    <InfoButton key="edit" href={"/badges/"+item.id} size="small" onClick={this.handleModaltrigger('edit',item)}><i className="fa fa-edit"></i>Edit</InfoButton>
                    { authData && hasAccessRights( authData, ['cpd'] ) ? <ErrorButton key="delete" size="small" onClick={this.handleModaltrigger('delete',item)}><i className="fa fa-trash"></i>Delete</ErrorButton> : null }
                </ButtonGroup>
            )} />
    }

    renderContents = () => {
        const { openModal, modalType, selectItem, openBadgePreview, selectedBadge } = this.state;
        return (
        <>
            <TableWrapper>
                {this.renderTableActions()}
                {this.renderTable()}
            </TableWrapper>

            <ModalView 
                open={( openModal && modalType && ( modalType === 'new' || modalType === 'edit' ) ? true : false )}
                title={( openModal && modalType && modalType === 'edit' ? 'Edit Badge' : 'Add New Badge' )}
                maxWidth="md"
                actionLabel={( openModal && modalType && modalType === 'edit' ? 'Update' : 'Add New' )}
                cancelLabel="Close"
                doAction={this.handleAction}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                disableBackdrop={true}
                onClose={() => this.setState({ openModal: false, modalType: false, selectItem: false })}
                contents={this.renderModal()} />

            <ModalView 
                open={ openBadgePreview ? true : false }
                title="Badge Preview"
                maxWidth="lg"
                noAction={true}
                cancelLabel="Close"
                onClose={() => this.setState({ openBadgePreview: false, selectedBadge: false })}
                contents={this.renderBadgePreview()} />

            <ModalDelete
                open={( openModal && modalType && modalType === 'delete' ? true : false )}
                title={`Are you sure you want to delete this badge ( ${selectItem.type_label} )?`}
                onClose={() => this.setState({ openModal: false, modalType: false, selectItem: false })}
                onDelete={this.handleAction} />

        </>
        );
    }

    render = () => {
        const { randNum } = this.state;
        return randNum ? this.renderContents() : <DotsLoader style={{ width: '100%', padding: '20px 30px' }} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        badgesList: state.badges && state.badges.badges ? state.badges.badges : null,
        randNum: state.badges && state.badges.rand ? state.badges.rand : null,
        courseTypeList: state.maintenance && state.maintenance.course_type ? state.maintenance.course_type : null,
        badgeTypeList: state.maintenance && state.maintenance.badge_type ? state.maintenance.badge_type : null,
        certificatesList: state.maintenance && state.maintenance.certificates ? state.maintenance.certificates : null,
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles),
    withRouter
)(Badges);