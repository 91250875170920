/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _isEqual from 'lodash/isEqual';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader'
import SnackBarSave from '../../components/SnackBarSave';

import EditForm from '../DatabaseStudentPage/form';
import ItemNotFound from '../../components/ItemNotFound';

import { InfoButton, GreyButton } from '../../styles/button';

import { hasAccessRights, getUserRegionFilter } from "../../helpers/auth";
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';


import { getMentorProfile, updateMentor, triggerMentorRegionTransfer, triggerMentorAccountTransfer, triggerGrantMentorAccess, triggerRevokeMentorAccess } from '../../actions/mentors';
import { triggerStudentPasswordChange } from '../../actions/database_students';
import { getMaintenance } from '../../actions/maintenance';
import { getSchedules } from "../../actions/schedules";
import { getUsersOptions } from '../../actions/users';
import { getRegions } from '../../actions/m_regions';
import { getRoles } from '../../actions/m_roles';
import { appChangesMade, resetRedux } from '../../actions/misc';

import { userStudentSchema } from '../../schemas/user_student';

class DatabaseMentorPage extends React.Component {

    state = {
        currentTab: 'details',
        student: false,
        randNum: false
    };

    componentDidMount() {
        const { designationList, regionsList, rolesList, schedulesList, usersOptionsList, authData } = this.props;
        const { uid } = this.props.match.params;
        
        // retrieve student details
        this.props.dispatch(getMentorProfile(uid));

        // get regions
        if ( !regionsList ) {
            this.props.dispatch(getRegions());
        }

        // get users options
        if ( !usersOptionsList )
        this.props.dispatch(getUsersOptions({ region: getUserRegionFilter(authData) }));

        // get schedule options
        if (!schedulesList) {
            this.props.dispatch(
                getSchedules({ region: getUserRegionFilter(authData) })
            );
        }


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentMentor, randNum, 
            regionsList,
            schedulesList,
            usersOptionsList
            } = this.props;

        // for student
        if ( currentMentor && regionsList && usersOptionsList && schedulesList && randNum && randNum !== this.state.randNum ) {
            this.setState({ student: cloneCollections( currentMentor ), randNum });
        } // end - currentMentor
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('mentor'));
    }

    handleRefresh = (type) => {
        // const { uid } = this.props.match.params;
        // switch( type ) {
        //     case 'emails':
        //         this.setState({ emails: false });
        //         this.props.dispatch(getEmailsBy('student_database',uid));
        //         break;
        // }
    }

    handleGrantMentorAccess = (formData) => {
        this.props.dispatch( triggerGrantMentorAccess(formData) );
    }

    handleRevokeMentorAccess = (formData) => {
        this.props.dispatch( triggerRevokeMentorAccess(formData) );
    }

    handleRegionTransfer = (formData) => {
        this.props.dispatch( triggerMentorRegionTransfer(formData) );
    }

    handleAccountTransfer = (formData) => {
        this.props.dispatch( triggerMentorAccountTransfer(formData) );
    }

    handleLMSPasswordChange = (formData) => {
        this.props.dispatch( triggerStudentPasswordChange(formData) );
    }

    handleSaveChanges = (event) => {
        const { student } = this.state;
        event.preventDefault();
        var error = false,
            formData = {};

        // do error check
        userStudentSchema.forEach(schema => {
            formData[schema.id] = ( student && student[schema.id] ? cloneCollections( student[schema.id] ) : schema.default );
            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateMentor(formData));
        } // end - error

    }

    handleFormUpdate = (newValue) => {
        this.setState({ student: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    handleTabChange = (newTab) => {
        this.setState({ currentTab: newTab });
    }

    // getLinks = () => {
    //     const { authData } = this.props;
    //     let links = [
    //         { id: 'details', label: 'Licensee Info', icon: 'fa fa-cog' },
    //         { id: 'personal', label: 'Personal Database', icon: 'fa fa-users' }
    //     ];

    //     return links;
    // }

    getPassingState = () => {
        const { location } = this.props;
        return ( location && location.state && isObjectExists( location.state ) ? location.state : null );
    }

    renderTabContents = () => {
        const { uid } = this.props.match.params;
        const { authData, currentMentor, designationList, regionsList, rolesList, schedulesList, usersOptionsList, changesMade } = this.props;
        const { currentTab, student } = this.state;
        const tabProps = {
            authData: ( authData || false ),
            student: ( student || false ),
            changesMade: ( changesMade || false ),
            onFormUpdate: this.handleFormUpdate
        };
        switch( currentTab ) {
            case 'details':
                return <EditForm 
                    formType="mentor"
                    currentStudent={( currentMentor || false )}
                    designations={( designationList || false )}
                    schedules={( schedulesList || false )}
                    regions={( regionsList || false )}
                    roles={( rolesList || false )}
                    usersOptions={( usersOptionsList || false )}
                    onRegionTransfer={this.handleRegionTransfer}
                    onAccountTransfer={this.handleAccountTransfer}
                    onGrantMentorAccess={this.handleGrantMentorAccess}
                    onRevokeMentorAccess={this.handleRevokeMentorAccess}
                    onLMSPasswordChange={this.handleLMSPasswordChange}
                    {...tabProps} />;
            // case 'emails': 
            //     return ( emails ? <EmailsList
            //         type="student_database"
            //         typeID={( uid || false )}
            //         changesMade={changesMade}
            //         onRefresh={this.handleRefresh}
            //         {...tabProps} /> : <DotsLoader /> ); 
        }
    }

    // renderTabs = () => {
    //     const { currentTab } = this.state;
    //     const btnStyle = {
    //         textAlign: 'center',
    //         minWidth: '225px',
    //         padding: "10px 5px",
    //         marginRight: "5px"
    //     };
    //     return (
    //     <Box display="flex" justifyContent="center" alignItems="center">
    //         {this.getLinks().map((button,index) => {
    //             if ( button.id === currentTab) {
    //                 return <InfoButton key={index} style={btnStyle} onClick={event => event.preventDefault()}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</InfoButton>
    //             } else {
    //                 return <GreyButton key={index} style={btnStyle} onClick={this.handleTabChange.bind(this,button.id)}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</GreyButton>
    //             }
    //         })}
    //     </Box>
    //     )
    // }

    renderContents() {
        return (
        <div>

            {/* {this.renderTabs()} */}

            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                {this.renderTabContents()}
            </Paper>

            <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
            </div>

            <SnackBarSave onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { uid } = this.props.match.params;
        const { randNum } = this.state;
        const { currentMentor } = this.props;
        return <AppWrapper 
                title="Edit LMS User"
                subtitle="LMS Users"
                back={'/mentors'}
                breadcrumbs={[
                    { url: '/mentors', label: 'LMS Users' },
                    { label: 'Edit' }
                ]}
                passingState={this.getPassingState()}
                maxWidth="1110px"
                onLoad={( !( randNum ) ? true : false )}
                contents={ currentMentor && currentMentor.uid && !_isEmpty( currentMentor.uid ) ? this.renderContents() : <ItemNotFound item="Mentor" />} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        currentMentor: state.mentors && state.mentors.mentor || null,
        randNum: state.mentors && state.mentors.rand || null, 
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        // designationList: state.maintenance && state.maintenance.designations || null,
        // rolesList: state.maintenance && state.maintenance.roles ? state.maintenance.roles : false,
        schedulesList:
        state.schedules && state.schedules.schedules
            ? state.schedules.schedules
            : false,
            usersOptionsList: state.users && state.users.users_options ? state.users.users_options : false,
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(DatabaseMentorPage);