/* eslint-disable */
import firebase from "firebase/app";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _snakeCase from "lodash/snakeCase";
import _find from "lodash/find";

import { isAdmin } from "../../helpers/auth";
import { callFunctionsAPI } from "../../helpers/action";
import { isArrayExists, validateEmail, isObjectExists } from "../../helpers/validation";
import { cloneCollections } from "../../helpers/data";
import { getSnapshotDataBySchema, getDocDataBySchema } from "../../helpers/schemas";

import { traineeSchema } from "../../schemas/trainee";

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve, reject) => {});
};

const getTraineeData = (doc) => {
    return new Promise((resolve, reject) => {
        let val = doc.data(),
            item = getDocDataBySchema(traineeSchema, doc);
        // add bounced_emails if found
        if (val.bounced_emails && isArrayExists(val.bounced_emails)) {
            item.bounced_emails = cloneCollections(val.bounced_emails);
        }

        // get transfer trainee_id
        if (val.transfer_class_oid && !_isEmpty(val.transfer_class_oid)) {
            let transferTrainee = false,
                transferTraineeShowcaseData = [];
            firebase
                .firestore()
                .collection("trainees")
                .doc(val.transfer_class_oid)
                .get()
                .then((transferDoc) => {
                    if (transferDoc && transferDoc.exists) transferTrainee = getDocDataBySchema(traineeSchema, transferDoc);

                    return transferTrainee && transferTrainee.id && transferTrainee.schedule_id
                        ? firebase
                              .database()
                              .ref(`class_showcase/${transferTrainee.schedule_id}/trainees/${transferTrainee.id}/courses`)
                              .once("value")
                        : false;
                })
                .then((snapshot) => {
                    if (snapshot && snapshot.exists()) {
                        let courses = snapshot.val();
                        snapshot.forEach((childSnapshot) => {
                            let course = childSnapshot.val();
                            course.course_id = childSnapshot.key;
                            course.feedback_form_answers = [];

                            if (childSnapshot.child("feedback_form_answers").exists() && childSnapshot.child("feedback_form_answers").hasChildren()) {
                                childSnapshot.child("feedback_form_answers").forEach((feedbackSnapshot) => {
                                    let feedback = feedbackSnapshot.val();
                                    feedback.id = feedbackSnapshot.key;
                                    course.feedback_form_answers.push(feedback);
                                });
                            }

                            transferTraineeShowcaseData.push(course);
                        });
                    }
                    resolve({
                        ...item,
                        transfer_trainee: transferTrainee,
                        transfer_trainee_showcase_data: transferTraineeShowcaseData,
                    });
                })
                .catch((error) => {
                    reject(error);
                });
        } else {
            resolve(item);
        }
    });
};

/* helper end */

export const fb_getTrainees = (props = {}) => {
    return new Promise((resolve, reject) => {
        const { region, course_id, schedule_id, enrollment_status } = props;

        let Ref = firebase.firestore().collection("trainees");

        // if filter by schedule_id, will ignore everything else
        if (schedule_id && !_isEmpty(schedule_id)) {
            Ref = Ref.where("schedule_id", "==", schedule_id);
        } else {
            // filter by region
            if (region && !_isEmpty(region)) {
                Ref = Ref.where("region", "==", region);
            }

            // filter by course_id
            if (course_id && !_isEmpty(course_id)) {
                Ref = Ref.where("course_id", "==", course_id);
            }
        } // end - schedule_id

        if (enrollment_status && !_isEmpty(enrollment_status)) {
            Ref = Ref.where("enrollment_status", "in", enrollment_status);
        } // end - enrollment_status

        // Ref.limit(100).get()
        Ref.get()
            .then((snapshot) => {
                let promises = [];
                if (snapshot && !snapshot.empty) {
                    snapshot.forEach((doc) => {
                        promises.push(getTraineeData(doc));
                    });
                } // end - snapshot
                return isArrayExists(promises) ? Promise.all(promises) : [];
            })
            .then((list) => {
                resolve(list);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_addTrainee = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "trainee", action: "add", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_updateTrainee = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "trainee", action: "update", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_deleteTrainee = (id) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "trainee", action: "delete", formData: { id } })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_undeleteTrainee = (id) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "trainee", action: "undelete", formData: { id } })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
