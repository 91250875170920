/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from "@material-ui/core/styles";
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _endsWith from 'lodash/endsWith';
import _replace from 'lodash/replace';
import _remove from 'lodash/remove';
import _findIndex from 'lodash/findIndex';

import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';

import { InfoButton, ErrorButton, ButtonGroup, AInfoLink, SuccessButton } from '../../styles/button';
import { WrapWord } from '../../styles/misc';

import { isArrayExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions } from '../../helpers/data';

import { addRole, editRole, deleteRole } from '../../actions/m_roles';

import { ACCESS_RIGHTS } from '../../constants';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});

const AccessTag = styled.div`
    display: inline-block;
    padding: 5px 8px;
    margin: 0px 5px 5px 0px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 6px;
    color: #fff;
    background: ${props => (props.access && props.access == 'yes' ? green['500'] : grey['500'])};
`;

class Roles extends React.Component {
    
    state = {
        sortBy: 'name-asc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalType: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false
    }

    handleFormUpdate = (newValue,key) => {
        const { modalData } = this.state;
        var newData = ( modalData ? cloneCollections( modalData ) : {} );
        newData[key] = newValue;
        this.setState({ modalData: newData });
    }

    handleAccessUpdate = key => event => {
        const { modalData } = this.state;
        var newData = ( modalData ? cloneCollections( modalData ) : {} ),
            newAccesses = ( modalData && modalData.accesses && isArrayExists( modalData.accesses ) ? cloneCollections( modalData.accesses ) : [] );

        if ( event.target.checked ) {
            // if aren't already in the state
            if ( !_find( newAccesses, (o) => o == key ) ) {
                // push to state
                newAccesses.push(key);

                // if is write access
                if ( _endsWith( key, 'w' ) ) {
                    var read = key[0]+key[1]+'r';
                    // make sure the read access is a valid access
                    if ( _find( ACCESS_RIGHTS, { id: read } ) ) {
                        // check if read access already in the state - if not push to state
                        if ( !_find( newAccesses, (o) => o == read ) )
                            newAccesses.push(read);
                    } // end - ACCESS_RIGHTS

                    var create = key[0]+key[1]+'m';
                    // make sure the create access is a valid access
                    if ( _find( ACCESS_RIGHTS, { id: create } ) ) {
                        // check if create access already in the state - if not push to state
                        if ( !_find( newAccesses, (o) => o == create ) )
                            newAccesses.push(create);
                    } // end - ACCESS_RIGHTS

                } else if ( _endsWith( key, 'm' ) ) {
                    // if is create access
                    var read = key[0]+key[1]+'r';
                    // make sure the read access is a valid access
                    if ( _find( ACCESS_RIGHTS, { id: read } ) ) {
                        // check if read access already in the state - if not push to state
                        if ( !_find( newAccesses, (o) => o == read ) )
                            newAccesses.push(read);
                    } // end - ACCESS_RIGHTS
                } else if ( _endsWith( key, 'd' ) ) {
                    // if is delete access
                    var read = key[0]+key[1]+'r';
                    // make sure the read access is a valid access
                    if ( _find( ACCESS_RIGHTS, { id: read } ) ) {
                        // check if read access already in the state - if not push to state
                        if ( !_find( newAccesses, (o) => o == read ) )
                            newAccesses.push(read);
                    } // end - ACCESS_RIGHTS

                    var create = key[0]+key[1]+'m';
                    // make sure the create access is a valid access
                    if ( _find( ACCESS_RIGHTS, { id: create } ) ) {
                        // check if create access already in the state - if not push to state
                        if ( !_find( newAccesses, (o) => o == create ) )
                            newAccesses.push(create);
                    } // end - ACCESS_RIGHTS

                    var write = key[0]+key[1]+'w';
                    // make sure the write access is a valid access
                    if ( _find( ACCESS_RIGHTS, { id: write } ) ) {
                        // check if write access already in the state - if not push to state
                        if ( !_find( newAccesses, (o) => o == write ) )
                            newAccesses.push(write);
                    } // end - ACCESS_RIGHTS
                } // end - key

            } // end - newAccesses
        } else {
            // remove the selected item
            var pulled = _remove( newAccesses, (o) => o == key );

            // if is read access
            if ( _endsWith( key, 'r' ) ) {

                var write = key[0]+key[1]+'w';;
                // make sure the write access is a valid access
                if ( _find( ACCESS_RIGHTS, { id: write } ) ) {
                    // check if write access already in the state - if yes remove it
                    if ( _find( newAccesses, (o) => o == write ) )
                        var pulledWrite = _remove( newAccesses, (o) => o == write );
                } // end - ACCESS_RIGHTS

                var create = key[0]+key[1]+'m';;
                // make sure the create access is a valid access
                if ( _find( ACCESS_RIGHTS, { id: create } ) ) {
                    // check if delete access already in the state - if yes remove it
                    if ( _find( newAccesses, (o) => o == create ) )
                        var pulledModify = _remove( newAccesses, (o) => o == create );
                } // end - ACCESS_RIGHTS

                var deleteRight = key[0]+key[1]+'d';;
                // make sure the delete access is a valid access
                if ( _find( ACCESS_RIGHTS, { id: deleteRight } ) ) {
                    // check if delete access already in the state - if yes remove it
                    if ( _find( newAccesses, (o) => o == deleteRight ) )
                        var pulledDelete = _remove( newAccesses, (o) => o == deleteRight );
                } // end - ACCESS_RIGHTS
            } // end - key

            // if is modify access
            if ( _endsWith( key, 'm' ) ) {

                var write = key[0]+key[1]+'w';;
                // make sure the write access is a valid access
                if ( _find( ACCESS_RIGHTS, { id: write } ) ) {
                    // check if write access already in the state - if yes remove it
                    if ( _find( newAccesses, (o) => o == write ) )
                        var pulledWrite = _remove( newAccesses, (o) => o == write );
                } // end - ACCESS_RIGHTS

                var deleteRight = key[0]+key[1]+'d';;
                // make sure the delete access is a valid access
                if ( _find( ACCESS_RIGHTS, { id: deleteRight } ) ) {
                    // check if delete access already in the state - if yes remove it
                    if ( _find( newAccesses, (o) => o == deleteRight ) )
                        var pulledDelete = _remove( newAccesses, (o) => o == deleteRight );
                } // end - ACCESS_RIGHTS
            } // end - key

            // if is create access
            if ( _endsWith( key, 'w' ) ) {

                var deleteRight = key[0]+key[1]+'d';;
                // make sure the delete access is a valid access
                if ( _find( ACCESS_RIGHTS, { id: deleteRight } ) ) {
                    // check if delete access already in the state - if yes remove it
                    if ( _find( newAccesses, (o) => o == deleteRight ) )
                        var pulledDelete = _remove( newAccesses, (o) => o == deleteRight );
                } // end - ACCESS_RIGHTS
            } // end - key

        }

        newData['accesses'] = newAccesses;
        this.setState({ modalData: newData });
    }

    handleAddNew = () => {
        const { modalData } = this.state;
        var error = false;

        if ( !( modalData && modalData.accesses && isArrayExists( modalData.accesses ) ) ) {
            error = 'Please select at least one access right(s)';
        } // end - modalData.role

        if ( !( modalData && modalData.label && !_isEmpty( modalData.label ) ) ) {
            error = 'Please insert a valid label';
        } // end - modalData.label

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addRole(modalData));
        } // end - error
    }

    handleEdit = () => {
        const { modalData } = this.state;
        var error = false;

        if ( !( modalData && modalData.label && !_isEmpty( modalData.label ) ) ) {
            error = 'Please insert a valid label';
        } // end - modalData.label

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editRole(modalData));
        } // end - error
    }

    handleAction = () => {
        const { modalType } = this.state;
        if ( modalType && modalType == 'new' ) {
            this.handleAddNew();
        } else if ( modalType && modalType == 'edit' ) {
            this.handleEdit();
        } // end - modalType
    }

    handleDelete = () => {
        const { deleteModal } = this.state;
        //perform delete
        this.props.dispatch(deleteRole(deleteModal.id));
    }

    reorganizeData() {
        const { searchterms, sortBy, perPage, page } = this.state;
        const { roles } = this.props;
        var items = ( roles ? cloneCollections( roles ) : [] ),
            total = _size( items );

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['created_on'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['created_on'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['label'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['label'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    getAccess = (rights,key) => {
        let read = _find( rights, r => _endsWith( r.id, 'r' ) ),
            create = _find( rights, r => _endsWith( r.id, 'w' ) ),
            update = _find( rights, r => _endsWith( r.id, 'm' ) ),
            del = _find( rights, r => _endsWith( r.id, 'd' ) );
        switch( key ) {
            case 'r': return read;
            case 'd': return del;
            case 'w': return create;
            case 'm': return update;
        }
    }

    getAccessModules = () => {
        let accesses = [];
        ACCESS_RIGHTS.forEach(access => {
            let index = _findIndex( accesses, { id: access.module_id } );
            if ( index >= 0 ) {
                accesses[index].rights.push( access );
            } else {
                accesses.push({
                    id: access.module_id,
                    label: access.module,
                    section: access.section,
                    rights: [ access ]
                });
            } // end - index
        });
        return accesses;
    }

    renderViewFormOld = () => {
        const { modalData, modalType } = this.state;
        return (
        <div>
            <FormInput label="Name" name="label" value={( modalData.label || '' )} disabled={( modalType && modalType === 'new' ? false : true )} onChange={this.handleFormUpdate} />
            <Grid container spacing={0}>
                {ACCESS_RIGHTS.map( access => {
                    return (
                    <Grid key={access.id} item xs={( access.width || 6 )}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={( modalData && modalData.accesses && _find( modalData.accesses, (o) => o == access.id ) ? true : false )}
                                    onChange={this.handleAccessUpdate(access.id)}
                                    value={access.id}
                                    color="primary" />
                            }
                            label={access.label} />
                    </Grid>   
                    )
                })}
            </Grid>
        </div>
        );
    }

    renderAccessCheckbox = (rights,key, readOnly) => {
        const { modalData } = this.state;
        let access = this.getAccess(rights,key);
        return access && access.id ? <FormControlLabel
            control={
                <Checkbox
                    disabled={( readOnly ? true : false )}
                    checked={( modalData && modalData.accesses && _find( modalData.accesses, (o) => o == access.id ) ? true : false )}
                    onChange={this.handleAccessUpdate(access.id)}
                    value={access.id}
                    color="primary" />
            }
            label="" /> : null ;
    }

    renderViewForm = () => {
        const { classes } = this.props;
        const { modalData, modalType } = this.state;
        const modules = this.getAccessModules();
        return (
        <div>
            <FormInput label="Name" name="label" value={( modalData.label || '' )} disabled={( modalType && modalType === 'new' ? false : true )} onChange={this.handleFormUpdate} />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.headcell} style={{ width: "20%" }}>Section</TableCell>
                        <TableCell className={classes.headcell} style={{ width: "20%" }}>Module</TableCell>
                        <TableCell className={classes.headcell} style={{ width: "15%" }}>Read</TableCell>
                        <TableCell className={classes.headcell} style={{ width: "15%" }}>Write<div>(Update)</div></TableCell>
                        <TableCell className={classes.headcell} style={{ width: "15%" }}>Write<div>(Create)</div></TableCell>
                        <TableCell className={classes.headcell} style={{ width: "15%" }}>Delete</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {modules.map(accessModule => (
                    <TableRow key={accessModule.id}>
                        <TableCell className={classes.bodycell}>{( accessModule.section || '' )}</TableCell>
                        <TableCell className={classes.bodycell}>{( accessModule.label || '' )}</TableCell>
                        <TableCell className={classes.bodycell}>{this.renderAccessCheckbox( accessModule.rights, 'r' )}</TableCell>
                        <TableCell className={classes.bodycell}>{this.renderAccessCheckbox( accessModule.rights, 'm' )}</TableCell>
                        <TableCell className={classes.bodycell}>{this.renderAccessCheckbox( accessModule.rights, 'w' )}</TableCell>
                        <TableCell className={classes.bodycell}>{this.renderAccessCheckbox( accessModule.rights, 'd' )}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
        );
    }

    renderTableActions = () => {
        const { sortBy, perPage, searchterms } = this.state;
        return <TableBar
                show={['sort','entries']}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Entries first' },
                    { value: 'date-asc', label: 'Oldest Entries first' },
                    { value: 'name-asc', label: 'Label ( A - Z)' },
                    { value: 'name-desc', label: 'Label ( Z - A )' }
                ]}
                rightButtons={[
                    <InfoButton minWidth="128px" key="addnew" style={{ marginRight: '5px' }} onClick={() => this.setState({ openViewModal: true, modalData: {
                        label: '',
                        accesses: []
                    }, modalType: 'new' })}><i className="fa fa-plus-circle"></i>Add New</InfoButton>
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    doneLoaded={true}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderBody = (items) => {
        const { classes } = this.props;
        return (
        <TableBody>
            { items && isArrayExists( items ) ? items.map(item => {
                return (
                <TableRow key={item.id}>
                    <TableCell className={classes.bodycell}>{item.label || ''}</TableCell>
                    {/* <TableCell className={classes.bodycell}>
                        {ACCESS_RIGHTS.map(access => {
                            var haveAccess = ( item.accesses && isArrayExists( item.accesses ) && _find( item.accesses, (o) => o == access.id ) ? 'yes' : 'no' );
                            return (
                            <AccessTag key={access.id} access={haveAccess}>{access.label}</AccessTag>
                            );
                        })}
                    </TableCell> */}
                    <TableCell className={classes.bodycell}>
                        <ButtonGroup>
                            <InfoButton size="small" onClick={() => this.setState({ openViewModal: true, modalData: item, modalType: 'edit' })}><i className="fa fa-edit"></i>Edit</InfoButton>
                            <ErrorButton size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton>
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
                )
            }) : (
                <TableRow>
                    <TableCell className={classes.bodycell}>No Role(s) found.</TableCell>
                </TableRow>
            ) }
        </TableBody>
        )
    }

    renderHeader = () => {
        const { classes } = this.props;
        return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headcell} style={{ width: "80%" }}>Name</TableCell>
                {/* <TableCell className={classes.headcell}>Access</TableCell> */}
                <TableCell className={classes.headcell} style={{ width: "20%" }}>Actions</TableCell>
            </TableRow>
        </TableHead>
        )
    }

    render() {
        const { openDeleteModal, deleteModal, openViewModal, modalType } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={openViewModal}
                maxWidth="md"
                title={ modalType && modalType == 'edit' ? "Edit Role" : "Add New Role" }
                actionLabel={ modalType && modalType == 'edit' ? "Update" : "Add New" }
                onClose={() => this.setState({ openViewModal: false, modalType: false, modalData: false })}
                doAction={this.handleAction}
                contents={this.renderViewForm()} />

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to delete this role ( ${deleteModal.name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            {this.renderTableActions()}
            <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                <Table>
                    {this.renderHeader()}
                    {this.renderBody(items)}
                </Table>
            </Paper>
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(Roles);