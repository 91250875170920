/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';

import AppWrapper from '../../components/AppWrapper';
import List from './list';

import { InfoBox } from '../../styles/message';

import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';
import { triggerErrorAlert } from '../../helpers/alert';

const getList = () => {
    return new Promise((resolve,reject) => {
        callFunctionsAPI({ method: 'GET', url: 'data', action: 'open_days' })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
    });
}

const OpenDay = ({
    authData,
}) => {
    const [ list, setList ] = useState([]);
    const [ loaded, setLoaded ] = useState(false);

    useEffect(() => {
        let mounted = true;
        getList()
        .then(newList => {
            if ( mounted ) {
                setList(newList);
                setLoaded(true);
            }
        })
        .catch(error => {
            triggerErrorAlert( getAPIErrorMessage(error) );
        });
        return () => {
            mounted = false;
        };
    }, []);

    return (
    <AppWrapper 
        title="List of Open Days"
        subtitle="Summary"
        onLoad={( loaded ? false : true )}
        contents={(
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                    <InfoBox>
                        <i className="fa fa-lightbulb-o"></i>
                        List of all Open Days of all Partners. This is to allow partner to coincide certain Open Days together. 
                    </InfoBox>
                </Grid>
                <Grid item xs={12} sm={4}> </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '45px' }}>
                <List 
                    authData={authData} 
                    list={list} />
            </Grid>
        </>
        )} />
    )

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(OpenDay);