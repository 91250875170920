/* eslint-disable */
import React, { useState, useEffect } from "react";
import { compose } from "recompose";
// import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
// import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _filter from "lodash/filter";
import _split from "lodash/split";
import _map from "lodash/map";
import _trim from "lodash/trim";

import ModalView from "../../components/ModalView";
import FormInput from "../../components/FormInput";
import FormDatePicker from "../../components/FormDatePicker";
import FormTimePicker from "../../components/FormTimePicker";

import { InfoButton } from "../../styles/button";

import { isArrayExists, isObjectExists } from "../../helpers/validation";
import { cloneCollections, getSelectOptions, getSelectValues } from "../../helpers/data";
import { getMomentTime } from "../../helpers/date";
import { triggerErrorAlert } from "../../helpers/alert";

const getInitialFormData = (schedule = {}, lms_course_id = "") => {
    const today = moment().utcOffset(8).valueOf();
    let eventData = {
        name: "Class",
        start_date: moment(today)
            .utcOffset(8)
            .set({
                hour: moment(schedule.start_date).utcOffset(8).get("hour"),
                minute: moment(schedule.start_date).utcOffset(8).get("minute"),
                second: 0,
                millisecond: 0,
            })
            .valueOf(),
        end_date: moment(today)
            .utcOffset(8)
            .set({
                hour: moment(schedule.end_date).utcOffset(8).get("hour"),
                minute: moment(schedule.end_date).utcOffset(8).get("minute"),
                second: 0,
                millisecond: 0,
            })
            .valueOf(),
        remarks: "",
        description: "",
        schedule_id: schedule.id,
        lms_course_id,
    };

    return eventData;
};

const MentorAddClassCalendar = ({
    authData = {},
    currentAttendances = {},
    class_calendar = [],
    schedule = {},
    lms_course_id = "",
    onAddNew = () => {},
}) => {
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({});

    const handleAction = () => {
        let error = false;
        if (!(formData.name && !_isEmpty(formData.name))) {
            error = "Please enter a valid class name.";
        }
        if (!formData.start_date) {
            error = "Please enter a valid class date.";
        }

        if (error) {
            triggerErrorAlert(error);
        } else {
            onAddNew(formData);
            setOpen(false);
        }
    };

    return (
        <>
            <div style={{ textAlign: "right" }}>
                <InfoButton
                    style={{ padding: "10px 20px" }}
                    onClick={() => {
                        setOpen(true);
                        setFormData(getInitialFormData(schedule, lms_course_id));
                    }}
                >
                    Add New Class
                </InfoButton>
            </div>

            <ModalView
                open={open}
                onClose={() => setOpen(false)}
                title="Add New Class"
                doAction={handleAction}
                contents={
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormInput
                                    label="Title"
                                    name="name"
                                    value={formData.name}
                                    onChange={(newValue) => setFormData({ ...formData, name: newValue })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormDatePicker
                                    label="Class Date"
                                    name="class_date"
                                    noDefaultVal={true}
                                    disablePast={true}
                                    value={formData.start_date || 0}
                                    onChange={(newValue) => {
                                        setFormData({
                                            ...formData,
                                            start_date: moment(newValue)
                                                .utcOffset(8)
                                                .set({
                                                    hour: moment(schedule.start_date).utcOffset(8).get("hour"),
                                                    minute: moment(schedule.start_date).utcOffset(8).get("minute"),
                                                    second: 0,
                                                    millisecond: 0,
                                                })
                                                .valueOf(),
                                            end_date: moment(newValue)
                                                .utcOffset(8)
                                                .set({
                                                    hour: moment(schedule.end_date).utcOffset(8).get("hour"),
                                                    minute: moment(schedule.end_date).utcOffset(8).get("minute"),
                                                    second: 0,
                                                    millisecond: 0,
                                                })
                                                .valueOf(),
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormTimePicker
                                    noDefaultVal={true}
                                    label="Start Time"
                                    disabled={true}
                                    value={(schedule && schedule.start_date) || 0}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormTimePicker noDefaultVal={true} label="End Time" disabled={true} value={(schedule && schedule.end_date) || 0} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    tooltip={<>For internal reference on SMS only.</>}
                                    label="Remarks"
                                    name="remarks"
                                    value={formData.remarks}
                                    multiline={true}
                                    rows={3}
                                    onChange={(newValue) => setFormData({ ...formData, remarks: newValue })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    tooltip={
                                        <>
                                            Description will be reflected in LMS Calendar. Learn how to sync LMS calendar{" "}
                                            <a
                                                href="https://community.canvaslms.com/t5/Student-Guide/How-do-I-subscribe-to-the-Calendar-feed-using-Google-Calendar-as/ta-p/535"
                                                target="_blank"
                                            >
                                                here
                                            </a>
                                            .
                                        </>
                                    }
                                    label="Description"
                                    name="description"
                                    value={formData.description || ""}
                                    multiline={true}
                                    rows={3}
                                    onChange={(newValue) => setFormData({ ...formData, description: newValue })}
                                />
                            </Grid>
                        </Grid>
                    </>
                }
            />
        </>
    );
};

export default MentorAddClassCalendar;
