/* eslint-disable */
import firebase from "firebase/app";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _snakeCase from "lodash/snakeCase";
import _find from "lodash/find";

import { callFunctionsAPI } from "../../helpers/action";
import { isArrayExists, validateEmail, isObjectExists } from "../../helpers/validation";
import { getSnapshotDataBySchema, getDocDataBySchema } from "../../helpers/schemas";

import { partnerSchema } from "../../schemas/partner";
import { userPartnerSchema } from "../../schemas/user_partner";

/* helpers start */
// get specific partner personnel
const getSpecificPartnerPersonnel = (partner_id) => {
    return new Promise((resolve, reject) => {
        firebase
            .firestore()
            .collection("partners")
            .doc(partner_id)
            .get()
            .then((doc) => {
                let partner = {};

                if (doc && doc.exists) {
                    partner = getDocDataBySchema(partnerSchema, doc);
                } // end - doc

                return partner && partner.region && !_isEmpty(partner.region)
                    ? firebase
                          .firestore()
                          .collection("users")
                          .where("belongs_to", "array-contains", partner.region)
                          .where("type", "==", "admin")
                          .get()
                    : false;
            })
            .then((snapshot) => {
                resolve(snapshot);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

// get specific region personnel
const getSpecificRegionPersonnel = (region) => {
    return new Promise((resolve, reject) => {
        firebase
            .firestore()
            .collection("users")
            .where("belongs_to", "array-contains", region)
            .where("type", "==", "admin")
            .get()
            .then((snapshot) => {
                resolve(snapshot);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/* helper end */

export const fb_getPersonnels = (props = {}) => {
    return new Promise((resolve, reject) => {
        const { partner_id, region } = props;
        const promises = [];

        // get specific partner personnels
        if (partner_id && !_isEmpty(partner_id)) {
            promises.push(getSpecificPartnerPersonnel(partner_id));
        } else if (region && !_isEmpty(region)) {
            // get specific region personnels
            promises.push(getSpecificRegionPersonnel(region));
        } else {
            // otherwise, get all personnels
            promises.push(firebase.firestore().collection("users").where("type", "==", "admin").get());
        } // end - partner_id

        Promise.all(promises)
            .then((results) => {
                let list = [];
                if (results && results[0]) {
                    results[0].forEach((doc) => {
                        let item = getDocDataBySchema(userPartnerSchema, doc);
                        list.push(item);
                    });
                } // end - results[0]
                resolve(list);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_getPersonnel = (id, partner_id) => {
    return new Promise((resolve, reject) => {
        let promises = [];
        promises.push(firebase.firestore().collection("users").doc(id).get());

        Promise.all(promises)
            .then((results) => {
                let item = {};

                if (results && results[0]) {
                    const data = results[0].exists ? results[0].data() : false;
                    item = getDocDataBySchema(userPartnerSchema, results[0]);
                    item.type = (data && data.type) || false;
                } // end - results[0]

                resolve(item);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_addPersonnel = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "partner", action: "add_personnel", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_updatePersonnel = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "partner", action: "update_personnel", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_resendAccountCreationEmail = (uid) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "partner", action: "resend_account_email", formData: { uid } })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_deletePersonnel = (uid) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "admin", action: "delete", formData: { uid } })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_triggerGrantPersonnelAccess = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "partner", action: "grant_access", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_triggerRevokePersonnelAccess = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "partner", action: "revoke_access", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
