/* eslint-disable */
import firebase from 'firebase/app';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getHQSummary = (props) => {
    return new Promise((resolve,reject) => {
        const { start_date, end_date, region } = props;

        if ( start_date && end_date ) {
            firebase.database().ref("course_access_log")
            .orderByChild("logged_on")
            .startAt(start_date).endAt(end_date)
            .once("value")
            .then(snapshot => {
                let list = [];
                if ( snapshot && snapshot.exists() && snapshot.hasChildren() ) {
                    snapshot.forEach( childSnapshot => {
                        let item = childSnapshot.val();
                        if ( region && region !== 'all' ) {
                            if ( region === item.region ) {
                                list.push( item );
                            }
                        } else {
                            list.push( item );
                        } // end - region
                    });
                } // end - snapshot
                resolve(list);
            })
            .catch(error => {
                reject(error);
            });
        } else {
            resolve([]);
        } // end - end_date

    })
}

export const fb_getCourseAccessLog = (callback,props) => {
    const { start_date, end_date, region } = props;

    if ( start_date && end_date ) {
        firebase.database().ref("course_access_log")
        .orderByChild("logged_on")
        .startAt(start_date).endAt(end_date)
        .on("value",snapshot => {
            let list = [];
            if ( snapshot && snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach( childSnapshot => {
                    let item = childSnapshot.val();
                    if ( region && region !== 'all' ) {
                        if ( region === item.region ) {
                            list.push( item );
                        }
                    } else {
                        list.push( item );
                    } // end - region
                });
            } // end - snapshot
            callback(list);
        });
    } else {
        callback([]);
    } // end - end_date

}

export const fb_updateCourseAccessLog = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'class', action: 'update_course_access_report', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}