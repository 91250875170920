/* eslint-disable */
import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import _isEmpty from "lodash/isEmpty";

import ModalView from "../../components/ModalView";
import FormCheckbox from "../../components/FormCheckbox";

import { triggerErrorAlert } from "../../helpers/alert";

import { WrapWord } from "../../styles/misc";
import { AInfoLink, WarningButton, IndigoButton } from "../../styles/button";

import theme from "../../theme";
import { PLATFORM_URL } from "../../constants";

const UndeleteStudent = ({ student = {}, schedule_id = false, changesNotSaved = false, onStudentUndelete = () => {}, onClose = () => {} }) => {
    const [openUndeleteModal, setOpenUndeleteModal] = useState(false);

    const showButton = useMemo(() => {
        return student && student.enrollment_status === "delete";
    }, [student]);

    const triggerUndelete = () => {
        setOpenUndeleteModal(false);
        if (onClose) onClose();

        onStudentUndelete(student.id);
    };

    return showButton ? (
        <>
            <div style={{ paddingTop: "5px" }}>
                <IndigoButton
                    style={{ padding: "4px 8px", fontSize: "12px" }}
                    onClick={() => {
                        if (changesNotSaved) {
                            triggerErrorAlert("Please save your changes first.");
                        } else {
                            setOpenUndeleteModal(true);
                        }
                    }}
                >
                    <i className="fa fa-refresh"></i>
                    Un-delete
                </IndigoButton>
            </div>

            <ModalView
                open={openUndeleteModal ? true : false}
                title={"Un-delete student"}
                maxWidth="sm"
                actionLabel={"Un-delete"}
                cancelLabel="Cancel"
                doAction={triggerUndelete}
                onClose={() => {
                    setOpenUndeleteModal(false);
                }}
                contents={
                    <>
                        <DialogTitle>
                            You are about to un-delete this student{student.name && !_isEmpty(student.name) ? " (" + student.name + ")" : ""} for this
                            class.
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                This will restore the student to the class but all the courses they were enrolled in previously will remain inactive.
                                You will need to re-activate them manually.
                            </DialogContentText>
                        </DialogContent>
                    </>
                }
            />
        </>
    ) : null;
};

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        changesNotSaved: (state.misc && state.misc.changes_made) || null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(UndeleteStudent);
