/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import blue from "@material-ui/core/colors/blue";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";

import { InfoButton, ButtonGroup, AInfoLink, ASuccessLink, ErrorButton } from "../../styles/button";

import { isArrayExists, validate } from "../../helpers/validation";
import { cloneCollections, getSelectOptions, getSelectedValue } from "../../helpers/data";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";

const useStyles = (theme) => ({
    popper: {
        zIndex: "3000",
        pointerEvents: "auto",
    },
    tooltip: {
        fontSize: "14px",
        color: "#fff",
        padding: "5px 8px",
    },
});

const Wrapper = styled.div`
    display: block;
    vertical-align: middle;
`;

const ParentObserver = ({ type = "parent", student, authData, classes, changesMade, onAddObserver, onRemoveObserver }) => {
    const hasObserver = useMemo(() => {
        if (type === "teacher") {
            return student &&
                student.teacher_observer_email &&
                !_isEmpty(student.teacher_observer_email) &&
                student.teacher_email &&
                student.teacher_email === student.teacher_observer_email
                ? true
                : false;
        } else {
            return student &&
                student.observer_email &&
                !_isEmpty(student.observer_email) &&
                student.parent_email &&
                student.parent_email === student.observer_email
                ? true
                : false;
        }
    }, [type, student]);

    const handleRemoveObserver = (event) => {
        event.preventDefault();
        if (changesMade) {
            triggerErrorAlert("Please save changes first");
        } else {
            onRemoveObserver(student);
        }
    };

    const handleAddObserver = (event) => {
        event.preventDefault();
        if (
            student &&
            student.uid &&
            !_isEmpty(student.uid) &&
            ((type === "parent" && student.parent_email && validate(student.parent_email, "email")) ||
                (type === "teacher" && student.teacher_email && validate(student.teacher_email, "email")))
        ) {
            if (changesMade) {
                triggerErrorAlert("Please save changes first");
            } else {
                onAddObserver(student);
            }
        } else {
            if (type === "teacher") {
                triggerErrorAlert("Please insert a valid teacher's email.");
            } else {
                triggerErrorAlert("Please insert a valid parent's email.");
            }
        }
    };

    return (
        <Wrapper>
            {hasObserver ? (
                <Tooltip
                    title={"Remove Parent Observer from this student"}
                    arrow
                    placement="top"
                    classes={{
                        popper: classes.popper,
                        tooltip: classes.tooltip,
                    }}
                >
                    <ErrorButton minWidth={"0px"} noIconMargin="yes" style={{ padding: "5px 10px" }} onClick={handleRemoveObserver}>
                        <i className="fa fa-remove"></i>
                    </ErrorButton>
                </Tooltip>
            ) : (
                <Tooltip
                    title={"Add Parent Observer to this student"}
                    arrow
                    placement="top"
                    classes={{
                        popper: classes.popper,
                        tooltip: classes.tooltip,
                    }}
                >
                    <InfoButton minWidth={"0px"} noIconMargin="yes" style={{ padding: "5px 10px" }} onClick={handleAddObserver}>
                        <i className="fa fa-plus"></i>
                    </InfoButton>
                </Tooltip>
            )}
            <span style={{ paddingLeft: "10px", display: "inline-block" }}>
                <i className="fa fa-eye"></i>
            </span>
        </Wrapper>
    );
};

const mapStateToProps = (state) => {
    return {
        changesMade: (state.misc && state.misc.changes_made) || null,
    };
};

export default compose(connect(mapStateToProps), withStyles(useStyles), withRouter)(ParentObserver);
