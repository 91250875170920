// create new password for use
const createNewPassword = () => {
    const words = [
        "apple",
        "banana",
        "cookie",
        "donut",
        "elephant",
        "fluffy",
        "garden",
        "happy",
        "igloo",
        "jacket",
        "kitten",
        "lizard",
        "monkey",
        "noodle",
        "orange",
        "penguin",
        "queen",
        "rainbow",
        "sunshine",
        "tiger",
        "umbrella",
        "violet",
        "water",
        "red",
        "yellow",
        "zebra",
        "baby",
        "candy",
        "daisy",
        "egg",
        "flower",
        "grape",
        "honey",
        "icecream",
        "jelly",
        "kite",
        "lollipop",
        "melon",
        "nugget",
        "ocean",
        "pizza",
        "quilt",
        "robot",
        "sailor",
        "turtle",
        "unicorn",
        "volley",
        "wiggle",
        "blue",
        "yoyo",
        "zesty",
        "ant",
        "bug",
        "cat",
        "dog",
        "ear",
        "fish",
        "goat",
        "hen",
        "ink",
        "jazz",
        "key",
        "lion",
        "moon",
        "nest",
        "owl",
        "paw",
        "quack",
        "rat",
        "snake",
        "tongue",
        "urchin",
        "vine",
        "whale",
        "xray",
        "yawn",
        "zipper",
    ];
    const randomNumber1 = Math.floor(Math.random() * (words.length - 1));
    const randomNumber2 = Math.floor(Math.random() * (words.length - 1));
    // add 2 random number at the end
    return words[randomNumber1] + "-" + words[randomNumber2] + "-" + Math.floor(Math.random() * 99);
    // const nanoid = customAlphabet('0123456789abcdefghjkmnopqrstuvwxyz-', 12 );
    // return nanoid();
};

export { createNewPassword };
