/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _snakeCase from 'lodash/snakeCase';
import _find from 'lodash/find';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists, validateEmail, isObjectExists } from '../../helpers/validation';
import { getSnapshotDataBySchema, getDocDataBySchema } from '../../helpers/schemas';

import { badgeSchema } from '../../schemas/badge';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getBadges = (callback,course_id) => {
    return new Promise((resolve,reject) => {
        
        firebase.firestore().collection("badges").where("references.course_id","==",course_id)
        .onSnapshot(snapshot => {
            const list = [];
            if ( snapshot ) {
                snapshot.forEach( doc => {
                    let item = getDocDataBySchema( badgeSchema, doc );
                    list.push( item );
                });
            } // end - snapshot
            callback(list);
        });

    })
}

export const fb_getAllBadges = () => {
    return new Promise((resolve,reject) => {
        
        firebase.firestore().collection("badges").get()
        .then(snapshot => {
            const list = [];
            if ( snapshot ) {
                snapshot.forEach( doc => {
                    let item = getDocDataBySchema( badgeSchema, doc );
                    list.push( item );
                });
            } // end - snapshot
            resolve(list);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getOneOffBadges = (callback) => {
        
        firebase.firestore().collection("badges").where("references.course_id","==","oneoff")
        .onSnapshot(snapshot => {
            const list = [];
            if ( snapshot ) {
                snapshot.forEach( doc => {
                    let item = getDocDataBySchema( badgeSchema, doc );
                    list.push( item );
                });
            } // end - snapshot
            callback(list);
        });
}

export const fb_addBadge = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'badge', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateBadge = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'badge', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteBadge = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'badge', action: 'delete', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}