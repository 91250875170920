/* eslint-disable */
import React from 'react';
// import { compose } from "recompose";
import styled from "styled-components";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import PhoneInput from 'react-phone-number-input';
// import { withStyles } from "@material-ui/core/styles";
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';
import _replace from 'lodash/replace';

import ToolTipInfo from '../ToolTipInfo';

import theme from '../../theme';

const Wrapper = styled.div`
    .MuiFormControl-root {
        background: #fff;
        border: 1px solid #bbb;
        margin-top: 16px;
        margin-bottom: 8x;
        border-radius: 5px;
    }

    .PhoneInput { padding: 12.5px 14px; }
    .PhoneInputInput { border: none; }

    &:hover { 
        .MuiFormControl-root { border-color: ${theme.palette.background}; }
    }

    ${props => {
    if ( props.focused && props.focused == 'yes' ) {
        return `
            .MuiFormControl-root { border-color: ${theme.palette.primary.main}; border-width: 2px; }
            .MuiFormLabel-root { color: ${theme.palette.primary.main}; }
            &:hover { 
                .MuiFormControl-root { border-color: ${theme.palette.primary.main}; }
            }
        `;
    }
    }}
`;

class FormPhoneField extends React.Component {

    state = {
        focused: false
    }

    handleChange = (newValue) => {
        const { name } = this.props;
        if ( this.props.onChange ) {
            this.props.onChange(newValue,name);
        }
    }

    getLabelStyle = () => {
       const { tooltip } = this.props;
       let style = { 
           fontSize: "16px", 
           fontWeight: "700", 
           textTransform: "uppercase", 
           backgroundColor: "#fff", 
           paddingLeft: '5px', 
           paddingRight: '5px' 
        };
        // if tooltip found
        if ( !_isEmpty( tooltip ) ) {
            style['pointerEvents'] = 'auto';
        }
        return style;
    }

    renderTooltipInfo = () => {
        const { tooltip } = this.props;
        return <ToolTipInfo content={( tooltip || false )} />
    }

    renderLabel = () => {
        const { label, tooltip } = this.props;
        var newLabel = false;
        if ( label && !_isEmpty( label ) && ( 
            ( _toLower( label ).indexOf("(required)") >= 0 ) || 
            ( _toLower( label ).indexOf("*") >= 0 )
         ) ) {
            newLabel = _replace( label, '(Required)', '' );
            newLabel = _replace( newLabel, '(required)', '' );
            newLabel = _replace( newLabel, '*', '' );
        } // end - label
        return (
        <>  
            { tooltip && !_isEmpty( tooltip ) ? this.renderTooltipInfo() : null }
            { newLabel ? <span>{ newLabel || '' }<span className="required">*</span></span> : ( label || '' ) }
        </>
        );
    }

    render() {
        const { value, name, disabled, defaultCountry, placeholder } = this.props;
        const { focused } = this.state;
        return (
        <Wrapper focused={( focused ? 'yes' : 'no' )}>
            <FormControl variant="outlined" fullWidth={true}>
                <InputLabel shrink={true} style={this.getLabelStyle()}>{this.renderLabel()}</InputLabel>
                <PhoneInput
                    international
                    // addInternationalOption={false}
                    defaultCountry={( defaultCountry || 'MY' )}
                    placeholder={( placeholder || "" )}
                    value={value}
                    onChange={this.handleChange}
                    onFocus={() => this.setState({ focused: true })}
                    onBlur={() => this.setState({ focused: false })} />
                
            </FormControl>
        </Wrapper>
        )
    }

}

export default FormPhoneField;