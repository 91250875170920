/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
// import moment from 'moment';
// import Typography from '@material-ui/core/Typography';
// import Grid from '@material-ui/core/Grid';
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _filter from "lodash/filter";
import _find from "lodash/find";
import _size from "lodash/size";
import _isEqual from "lodash/isEqual";
import _findIndex from "lodash/findIndex";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
// import { NotionRenderer } from "react-notion-x";

import AppWrapper from "../../components/AppWrapper";
import AppInfoBox from "../../modules/AppInfoBox";
// import Table from './table';
// import FilterMenu from './filter_menu';
// import LatestStats from './latest_stats';

// import { isAdmin } from '../../helpers/auth';
// import { cloneCollections } from '../../helpers/data';
// import { getMomentTime } from '../../helpers/date';
// import { isArrayExists } from '../../helpers/validation';
// import { triggerErrorAlert } from '../../helpers/alert';
// import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';

// import { getRegions } from '../../actions/m_regions';
// import { toggleLoader } from '../../actions/global';

// import { ButtonGroup, AInfoLink, InfoButton } from '../../styles/button';
// import { SuccessTag } from '../../styles/tag';
// import { WarningBox, InfoBox } from '../../styles/message';

// import { DEV_MODE } from '../../constants';

const ResultWrapper = styled.div`
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px solid #ddd;
`;

const BusinessDashboardRND = ({ authData, dispatch }) => {
  return (
    <AppWrapper
      subtitle="Summary"
      title="R&D Data Dashboard"
      onLoad={false}
      contents={
        authData ? (
          <>
            <AppInfoBox page="BusinessDashboard" />
            <iframe
              src="https://lookerstudio.google.com/embed/reporting/08a7d4a6-f159-4b03-9e41-e9950ccc780c/page/p_7t9cfqf17c"
              frameborder="0"
              style={{ width: "100%", height: "100vh" }}
              allowfullscreen
              sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
            ></iframe>
          </>
        ) : null
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    authData: state.auth && state.auth.user ? state.auth.user : null,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter
)(BusinessDashboardRND);
