/* eslint-disable */
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import grey from "@material-ui/core/colors/grey";
import Grid from "@material-ui/core/Grid";
import _startCase from "lodash/startCase";
import _find from "lodash/find";
import _isEmpty from "lodash/isEmpty";

import PopperBox from "../Popper";

import PartnerSwitcher from "../../modules/PartnerSwitcher";
import NotificationsIcon from "../../modules/NotificationsIcon";

import { H3 } from "../../styles/heading";
import { FlexColumn } from "../../styles/division";
import { ErrorLink, GreyLink, InverseLink, AWhiteLink, AGreyLink } from "../../styles/button";
import { SuccessTag } from "../../styles/tag";

import { isArrayExists } from "../../helpers/validation";
import { hasAccessRights, isAdmin, isAdminType } from "../../helpers/auth";

import { logout } from "../../actions/auth";
import { appChangesReset } from "../../actions/misc";

import theme from "../../theme";

import { PLATFORM_ADMIN_DEFAULT_REGION } from "../../constants";

const HeaderWrapper = styled.header`
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 0px 0px 0px 30px;
    border-bottom: 1px solid #ddd;
    z-index: 999;
`;

const Nav = styled.nav`
    display: block;

    ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        list-style: none;

        li {
            display: block;
            padding: 0px 1.25vw 0px 0px;
            margin: 0px;
            height: 64px;

            &:last-child {
                padding-right: 0px;
            }
        }
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0px 5px;
        min-width: 64px;
        text-align: center;
        color: ${theme.palette.background};
        font-weight: 700;
        box-shadow: inset 0 0 0 0 #fff;
        text-transform: uppercase;

        &:hover,
        &:active,
        &.active {
            color: ${theme.palette.primary.main};
            box-shadow: inset 0 -5px 0 0 ${theme.palette.primary.main};
        }
    }
`;

const ToggleButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.35ms ease-in-out;
    cursor: pointer;
    padding: 0px 45px;
    height: 100%;
    vertical-align: middle;
    border-radius: 45px 0px 0px 45px;

    i {
        font-size: 30px;
        margin-left: 30px;

        &:first-child {
            margin-left: 0px;
        }
    }

    &.opened {
        background: ${theme.palette.primary.main};
        color: #fff;
    }
`;

const UserDetails = styled.div`
    padding: 20px;
    border-bottom: 1px solid #ddd;
`;

const UserSubMenuWrapper = styled.div`
    padding: 20px;

    a {
        background: none;
        color: ${grey["500"]};

        &:hover {
            background: none;
            color: ${grey["700"]};
        }
    }
`;

class Header extends React.Component {
    state = {
        openSubMenu: false,
        subMenuAnchor: {},
        subMenuID: false,
        userAnchorEl: {},
        openUserMenu: false,
    };

    handleLogOut = (event) => {
        const { changesNotSaved } = this.props;
        event.preventDefault();

        // if changes not saved - trigger warning
        if (changesNotSaved) {
            var answer = window.confirm(
                "You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?"
            );
            // reset status if answer is yes
            if (answer) {
                this.props.dispatch(appChangesReset());
                this.props.dispatch(logout());
            }
        } else {
            this.props.dispatch(logout());
        }
    };

    handleLinkClick(url, event) {
        const { history, changesNotSaved } = this.props;
        event.preventDefault();

        // if changes not saved - trigger warning
        if (changesNotSaved) {
            var answer = window.confirm(
                "You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?"
            );
            // reset status if answer is yes
            if (answer) {
                this.props.dispatch(appChangesReset());
                history.push(url);
            }
        } else {
            history.push(url);
        }
    }

    handleSubMenu = (link_id, event) => {
        const { openSubMenu } = this.state;
        event.preventDefault();
        this.setState({
            openSubMenu: !openSubMenu,
            subMenuAnchor: event.currentTarget,
            subMenuID: link_id,
        });
    };

    handleSubMenuOnHover = (link_id) => (event) => {
        const { openSubMenu } = this.state;
        event.preventDefault();
        if (link_id) {
            this.setState({
                openSubMenu: true,
                subMenuAnchor: event.currentTarget,
                subMenuID: link_id,
            });
        } else {
            this.setState({
                openSubMenu: false,
                subMenuAnchor: {},
                subMenuID: false,
            });
        } // end - link_id
    };

    handleUserMenu = (event) => {
        const { openUserMenu } = this.state;
        this.setState({
            openUserMenu: !openUserMenu,
            userAnchorEl: event.currentTarget,
        });
    };

    getLinks = () => {
        const { authData } = this.props;

        let links = [{ id: "dashboard", label: "Dashboard", url: "/dashboard" }];

        if (hasAccessRights(authData, ["ptr"]) || hasAccessRights(authData, ["cpr"]) || hasAccessRights(authData, ["tlr"])) {
            let setupSubMenu = [];

            if (hasAccessRights(authData, ["cpr"]))
                setupSubMenu.push({
                    id: "products",
                    label: "Product Profile",
                    url: "/products",
                });

            if (hasAccessRights(authData, ["ppr"]))
                setupSubMenu.push({
                    id: "programs",
                    label: "Program Profile",
                    url: "/programs",
                });

            if (hasAccessRights(authData, ["ptr"])) {
                setupSubMenu.push({
                    id: "partner",
                    label: "Partner Profile",
                    url: "/partner",
                });
            }

            if (hasAccessRights(authData, ["tlr"]))
                setupSubMenu.push({
                    id: "training_venues",
                    label: "Training Venues",
                    url: "/training_venues",
                });

            if (hasAccessRights(authData, ["ptr"])) {
                setupSubMenu.push({
                    id: "personnel_database",
                    label: "SMS Users",
                    url: "/partner/" + authData.region + "/personnel",
                });
            }

            if (hasAccessRights(authData, ["mer"]))
                setupSubMenu.push({
                    id: "mentors",
                    label: "LMS Users",
                    url: "/mentors",
                });

            links.push({
                id: "setup",
                label: "Setup",
                url: false,
                submenu: setupSubMenu,
            });
        } // end - ptr

        if (hasAccessRights(authData, ["scr"]) || hasAccessRights(authData, ["crr"]) || hasAccessRights(authData, ["mdr"])) {
            let recruitmentSubMenu = [];

            if (hasAccessRights(authData, ["scr"]))
                recruitmentSubMenu.push({
                    id: "schedules",
                    label: "Class Schedule",
                    url: "/schedules",
                });

            if (hasAccessRights(authData, ["crr"]))
                recruitmentSubMenu.push({
                    id: "class_registration",
                    label: "Registration",
                    url: "/class_registration",
                });

            if (hasAccessRights(authData, ["mdr"]))
                recruitmentSubMenu.push({
                    id: "marketing_database",
                    label: "Marketing Database",
                    url: "/marketing_database",
                });

            links.push({
                id: "recruitment",
                label: "1. Recruitment",
                url: false,
                submenu: recruitmentSubMenu,
            });
        } // end - scr

        if (hasAccessRights(authData, ["cfr"])) {
            let enrolmentSubMenu = [];

            if (hasAccessRights(authData, ["cfr"]))
                enrolmentSubMenu.push({
                    id: "class_confirmation",
                    label: "Confirmation",
                    url: "/class_confirmation",
                });

            links.push({
                id: "enrolment",
                label: "2. Enrolment",
                url: false,
                submenu: enrolmentSubMenu,
            });
        } // end - scr

        if (
            hasAccessRights(authData, ["scr"]) ||
            hasAccessRights(authData, ["pyr"]) ||
            hasAccessRights(authData, ["arr"]) ||
            hasAccessRights(authData, ["sdr"])
        ) {
            let deliverySubMenu = [];

            if (hasAccessRights(authData, ["car"]))
                deliverySubMenu.push({
                    id: "course_access",
                    label: "Course Access",
                    url: "/course_access",
                });

            if (hasAccessRights(authData, ["pyr"]))
                deliverySubMenu.push({
                    id: "class_payment_tracking",
                    label: "Payment",
                    url: "/class_payment_tracking",
                });

            if (hasAccessRights(authData, ["arr"]))
                deliverySubMenu.push({
                    id: "class_attendance_reporting",
                    label: "Attendance & Reporting",
                    url: "/class_attendance_reporting",
                });

            if (hasAccessRights(authData, ["sdr"]))
                deliverySubMenu.push({
                    id: "student_database",
                    label: "Student Database",
                    url: "/student_database",
                });

            if (hasAccessRights(authData, ["sdr"]))
                deliverySubMenu.push({
                    id: "student_database_search",
                    label: "Search Student",
                    url: "/student_database_search",
                });

            links.push({
                id: "delivery",
                label: "3. Delivery",
                url: false,
                submenu: deliverySubMenu,
            });
        } // end - scr

        if (hasAccessRights(authData, ["shr"]) || hasAccessRights(authData, ["qrr"]) || hasAccessRights(authData, ["obr"])) {
            let closingSubMenu = [];

            if (hasAccessRights(authData, ["shr"]))
                closingSubMenu.push({
                    id: "class_showcase",
                    label: "Showcase",
                    url: "/class_showcase",
                });

            if (hasAccessRights(authData, ["qrr"]))
                closingSubMenu.push({
                    id: "class_quarterly_report",
                    label: "Quarterly Report",
                    url: "/class_quarterly_report",
                });

            if (
                // isAdminType(authData) &&
                // authData &&
                // authData.region &&
                // authData.region === PLATFORM_ADMIN_DEFAULT_REGION
                hasAccessRights(authData, ["obr"])
            )
                closingSubMenu.push({
                    id: "issue_oneoff_badges",
                    label: "Issue One-Off Badges",
                    url: "/issue_oneoff_badges",
                });

            links.push({
                id: "closing",
                label: "4. Closing",
                url: false,
                submenu: closingSubMenu,
            });
        } // end - scr

        if (hasAccessRights(authData, ["rcr"]) || hasAccessRights(authData, ["scr"]) || hasAccessRights(authData, ["pyr"])) {
            let summarySubMenu = [];

            if (hasAccessRights(authData, ["bdr"])) {
                // summarySubMenu.push({ id: 'business_dashboard', label: 'Business Dashboard', url: '/business_dashboard' });
                summarySubMenu.push({
                    id: "business_dashboard_2",
                    label: "Business Dashboard",
                    url: "/business_dashboard_2",
                });
            }

            if (hasAccessRights(authData, ["rcr"]))
                summarySubMenu.push({
                    id: "course_access_report",
                    label: "Report of Course Access",
                    url: "/course_access_report",
                });

            if (hasAccessRights(authData, ["rcr"]))
                summarySubMenu.push({
                    id: "report_ltu",
                    label: "Report of LTU",
                    url: "/report_ltu",
                });

            if (hasAccessRights(authData, ["scr"]))
                summarySubMenu.push({
                    id: "open_days",
                    label: "List of Open Days",
                    url: "/open_days",
                });

            if (hasAccessRights(authData, ["pyr"]))
                summarySubMenu.push({
                    id: "payment_history",
                    label: "Payment History",
                    url: "/payment_history",
                });

            if (hasAccessRights(authData, ["bdr"])) {
                summarySubMenu.push({
                    id: "rnd_data_dashboard",
                    label: "R&D Data Dashboard",
                    url: "/rnd_data_dashboard",
                });
            }

            links.push({
                id: "summary",
                label: "Summary",
                url: false,
                submenu: summarySubMenu,
            });
        }

        // if ( hasAccessRights( authData, ['etr'] ) )
        //     links.push({ id: 'email_templates', label: 'Email Templates', url: '/email_templates' });

        return links;
    };

    renderSubMenu() {
        const { openSubMenu, subMenuAnchor, subMenuID } = this.state;
        const links = this.getLinks();
        var parent = _find(links, { id: subMenuID });
        return (
            <PopperBox
                open={openSubMenu}
                placement="bottom"
                anchorEl={subMenuAnchor}
                onClose={() =>
                    this.setState({
                        openSubMenu: false,
                        subMenuAnchor: {},
                        subMenuID: false,
                    })
                }
                wrapperStyle={{ paddingTop: "10px" }}
                containerStyle={{ width: "250px" }}
                contents={
                    <div style={{ padding: "10px" }}>
                        {parent && parent.submenu && isArrayExists(parent.submenu)
                            ? parent.submenu.map((link) => {
                                  return (
                                      <AWhiteLink
                                          href={link.url}
                                          key={link.id}
                                          style={{ display: "block", padding: "15px" }}
                                          onClick={this.handleLinkClick.bind(this, link.url)}
                                      >
                                          {link.icon ? <i className={link.icon}></i> : ""}
                                          {link.label}
                                      </AWhiteLink>
                                  );
                              })
                            : ""}
                    </div>
                }
            />
        );
    }

    renderUserSubMenu() {
        const { authData } = this.props;
        const linkStyle = { marginBottom: "10px", textAlign: "left" };
        const hasMTR = hasAccessRights(authData, ["mtr"]);
        return (
            <UserSubMenuWrapper>
                <Grid container spacing={3}>
                    {isAdmin(authData) ? (
                        <Grid item xs={12} sm={6}>
                            <div style={{ padding: "0 10px" }}>
                                <Typography variant="h5" style={{ marginBottom: "15px", padding: "0px 5px" }}>
                                    Users Management
                                </Typography>
                                <AGreyLink href="/users" onClick={this.handleLinkClick.bind(this, "/users")} style={linkStyle}>
                                    <i className="fa fa-users"></i>Users
                                </AGreyLink>
                                <AGreyLink href="/roles" onClick={this.handleLinkClick.bind(this, "/roles")} style={linkStyle}>
                                    <i className="fa fa-address-card-o"></i>Roles & Privileges
                                </AGreyLink>
                            </div>
                        </Grid>
                    ) : null}
                    <Grid item xs={12} sm={6}>
                        <div style={isAdmin(authData) ? { padding: "0 10px", borderLeft: "1px solid #ddd" } : { padding: "0 10px" }}>
                            <Typography variant="h5" style={{ marginBottom: "15px", padding: "0px 5px" }}>
                                Account Settings
                            </Typography>
                            <AGreyLink href="/auth/profile" onClick={this.handleLinkClick.bind(this, "/auth/profile")} style={linkStyle}>
                                <i className="fa fa-user"></i>Edit Profile
                            </AGreyLink>
                            <AGreyLink href="/student/dashboard" onClick={this.handleLinkClick.bind(this, "/student/dashboard")} style={linkStyle}>
                                <i className="fa fa-graduation-cap"></i>Student Dashboard
                            </AGreyLink>
                        </div>
                    </Grid>
                    {hasMTR ? (
                        <Grid item xs={12}>
                            <div style={{ padding: "0 10px" }}>
                                <Typography variant="h5" style={{ marginBottom: "15px", padding: "0px 5px" }}>
                                    Maintenance
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <div>
                                            <AGreyLink
                                                href="/maintenance/badge_type"
                                                onClick={this.handleLinkClick.bind(this, "/maintenance/badge_type")}
                                                style={linkStyle}
                                            >
                                                <i className="fa fa-shield"></i>Badge Level
                                            </AGreyLink>
                                        </div>
                                        <AGreyLink
                                            href="/maintenance/one_off_badges"
                                            onClick={this.handleLinkClick.bind(this, "/maintenance/one_off_badges")}
                                            style={linkStyle}
                                        >
                                            <i className="fa fa-shield"></i>Badges (One Off)
                                        </AGreyLink>
                                        <div>
                                            <AGreyLink
                                                href="/maintenance/certificates"
                                                onClick={this.handleLinkClick.bind(this, "/maintenance/certificates")}
                                                style={linkStyle}
                                            >
                                                <i className="fa fa-certificate"></i>Certificate Templates
                                            </AGreyLink>
                                        </div>
                                        <div>
                                            <AGreyLink
                                                href="/maintenance/contact_hour"
                                                onClick={this.handleLinkClick.bind(this, "/maintenance/contact_hour")}
                                                style={linkStyle}
                                            >
                                                <i className="fa fa-hourglass-end"></i>Contact Hour
                                            </AGreyLink>
                                        </div>
                                        <div>
                                            <AGreyLink
                                                href="/maintenance/currency"
                                                onClick={this.handleLinkClick.bind(this, "/maintenance/currency")}
                                                style={linkStyle}
                                            >
                                                <i className="fa fa-dollar"></i>Currency
                                            </AGreyLink>
                                        </div>
                                        <div>
                                            <AGreyLink
                                                href="/maintenance/course_type"
                                                onClick={this.handleLinkClick.bind(this, "/maintenance/course_type")}
                                                style={linkStyle}
                                            >
                                                <i className="fa fa-tags"></i>Delivery Type
                                            </AGreyLink>
                                        </div>
                                        <div>
                                            <AGreyLink
                                                href="/maintenance/designation"
                                                onClick={this.handleLinkClick.bind(this, "/maintenance/designation")}
                                                style={linkStyle}
                                            >
                                                <i className="fa fa-address-book-o"></i>Designation
                                            </AGreyLink>
                                        </div>
                                        <div>
                                            <AGreyLink
                                                href="/maintenance/districts"
                                                onClick={this.handleLinkClick.bind(this, "/maintenance/districts")}
                                                style={linkStyle}
                                            >
                                                <i className="fa fa-map-o"></i>Districts
                                            </AGreyLink>
                                        </div>
                                        <div>
                                            <AGreyLink
                                                href="/maintenance/course_duration"
                                                onClick={this.handleLinkClick.bind(this, "/maintenance/course_duration")}
                                                style={linkStyle}
                                            >
                                                <i className="fa fa-calendar-o"></i>Duration
                                            </AGreyLink>
                                        </div>
                                        <div>
                                            <AGreyLink
                                                href="/maintenance/ethnicity"
                                                onClick={this.handleLinkClick.bind(this, "/maintenance/ethnicity")}
                                                style={linkStyle}
                                            >
                                                <i className="fa fa-user"></i>Ethnicity
                                            </AGreyLink>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div>
                                            <AGreyLink
                                                href="/maintenance/email_templates"
                                                onClick={this.handleLinkClick.bind(this, "/maintenance/email_templates")}
                                                style={linkStyle}
                                            >
                                                <i className="fa fa-envelope-o"></i>Email Templates
                                            </AGreyLink>
                                            <AGreyLink
                                                href="/maintenance/feedback_forms"
                                                onClick={this.handleLinkClick.bind(this, "/maintenance/feedback_forms")}
                                                style={linkStyle}
                                            >
                                                <i className="fa fa-check-square-o"></i>Feedback Forms
                                            </AGreyLink>
                                            <AGreyLink
                                                href="/maintenance/registration_forms"
                                                onClick={this.handleLinkClick.bind(this, "/maintenance/registration_forms")}
                                                style={linkStyle}
                                            >
                                                <i className="fa fa-check-square"></i>Registration Forms
                                            </AGreyLink>
                                            <AGreyLink
                                                href="/maintenance/rubrics"
                                                onClick={this.handleLinkClick.bind(this, "/maintenance/rubrics")}
                                                style={linkStyle}
                                            >
                                                <i className="fa fa-book"></i>Rubric
                                            </AGreyLink>
                                            {/* <AGreyLink onClick={this.handleLinkClick.bind(this,'/maintenance/payment_method')} style={linkStyle}><i className="fa fa-credit-card"></i>Payment Method</AGreyLink> */}
                                            <div>
                                                <AGreyLink
                                                    href="/maintenance/price_per_pax"
                                                    onClick={this.handleLinkClick.bind(this, "/maintenance/price_per_pax")}
                                                    style={linkStyle}
                                                >
                                                    <i className="fa fa-usd"></i>Price Per Pax
                                                </AGreyLink>
                                            </div>
                                            <div>
                                                <AGreyLink
                                                    href="/maintenance/product_credit"
                                                    onClick={this.handleLinkClick.bind(this, "/maintenance/product_credit")}
                                                    style={linkStyle}
                                                >
                                                    <i className="fa fa-database"></i>Product Credit
                                                </AGreyLink>
                                            </div>
                                            <div>
                                                <AGreyLink
                                                    href="/maintenance/product_level"
                                                    onClick={this.handleLinkClick.bind(this, "/maintenance/product_level")}
                                                    style={linkStyle}
                                                >
                                                    <i className="fa fa-signal"></i>Product Level
                                                </AGreyLink>
                                            </div>
                                            <div>
                                                <AGreyLink
                                                    href="/maintenance/schools"
                                                    onClick={this.handleLinkClick.bind(this, "/maintenance/schools")}
                                                    style={linkStyle}
                                                >
                                                    <i className="fa fa-university"></i>Schools (Custom)
                                                </AGreyLink>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    ) : null}
                </Grid>
            </UserSubMenuWrapper>
        );
    }

    renderUserDetails() {
        const { authData, regionsList } = this.props;
        return (
            <UserDetails>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <FlexColumn width="70%">
                        <Box display="flex">
                            {authData.photoURL && !_isEmpty(authData.photoURL) ? (
                                <img
                                    src={authData.photoURL}
                                    style={{
                                        width: "45px",
                                        height: "45px",
                                        borderRadius: "100%",
                                    }}
                                />
                            ) : (
                                <i className="fa fa-user-circle" style={{ fontSize: "45px" }}></i>
                            )}
                            <div style={{ paddingLeft: "20px" }}>
                                <Typography variant="h5" style={{ fontWeight: "700", marginBottom: "4px" }}>
                                    {authData.name || ""}
                                </Typography>
                                <Typography variant="h6" color="textSecondary" style={{ marginBottom: "4px" }}>
                                    {authData.email || ""}
                                </Typography>
                            </div>
                        </Box>
                    </FlexColumn>
                    <FlexColumn width="30%">
                        <div style={{ textAlign: "center" }}>
                            <ErrorLink onClick={this.handleLogOut}>
                                <i className="fa fa-sign-out"></i>Logout
                            </ErrorLink>
                        </div>
                    </FlexColumn>
                </Box>
                <div style={{ paddingLeft: "65px", display: "flex" }}>
                    {/* <Typography variant="h6" color="primary" style={{ display: "inline-block", marginRight: "10px" }}>
                        {authData.role ? _startCase(authData.role) : ""}
                    </Typography> */}
                    <SuccessTag
                        style={{
                            display: "inline-block",
                            marginRight: "10px",
                            border: "2px solid #388e3c",
                        }}
                    >
                        {authData.role ? _startCase(authData.role) : ""}
                    </SuccessTag>
                    <PartnerSwitcher regions={regionsList} />
                </div>
            </UserDetails>
        );
    }

    renderUserMenu = () => {
        const { authData } = this.props;
        const { openUserMenu, userAnchorEl } = this.state;
        const hasMTR = hasAccessRights(authData, ["mtr"]);
        return (
            <PopperBox
                open={openUserMenu}
                anchorEl={userAnchorEl}
                wrapperStyle={{ padding: "15px" }}
                onClose={() => this.setState({ openUserMenu: false, userAnchorEl: {} })}
                contents={
                    <div>
                        {this.renderUserDetails()}
                        {this.renderUserSubMenu()}
                    </div>
                }
            />
        );
    };

    render() {
        const { authData } = this.props;
        const { openUserMenu, subMenuID } = this.state;
        const links = this.getLinks();
        return (
            <HeaderWrapper>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <FlexColumn width="20%" style={{ paddingRight: "15px" }}>
                        <img src="/logo.gif" style={{ maxWidth: "150px" }} />
                    </FlexColumn>
                    <FlexColumn width="80%">
                        <Nav>
                            <ul>
                                {links.map((link) => {
                                    if (link.submenu && isArrayExists(link.submenu))
                                        return (
                                            <li key={link.id}>
                                                <a
                                                    href="#"
                                                    className={subMenuID && subMenuID === link.id ? "active" : null}
                                                    // onMouseEnter={this.handleSubMenuOnHover(link.id)}
                                                    // onMouseLeave={this.handleSubMenuOnHover(false)}
                                                    onClick={this.handleSubMenu.bind(this, link.id)}
                                                >
                                                    <span>{link.label}</span>
                                                    <i className="fa fa-angle-down" style={{ marginLeft: "8px" }}></i>
                                                </a>
                                            </li>
                                        );
                                    else
                                        return (
                                            <li key={link.id}>
                                                <a href={link.url} onClick={this.handleLinkClick.bind(this, link.url)}>
                                                    <span>{link.label}</span>
                                                </a>
                                            </li>
                                        );
                                })}
                                {authData && (
                                    <li>
                                        <NotificationsIcon authData={authData} />
                                    </li>
                                )}
                                <li>
                                    <ToggleButton onClick={this.handleUserMenu} className={openUserMenu ? "opened" : "closed"}>
                                        <i className="fa fa-user-circle"></i>
                                        {openUserMenu ? <i className="fa fa-remove"></i> : <i className="fa fa-ellipsis-v"></i>}
                                    </ToggleButton>
                                </li>
                            </ul>
                        </Nav>
                        {this.renderSubMenu()}
                        {this.renderUserMenu()}
                    </FlexColumn>
                </Box>
            </HeaderWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth.user || null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        changesNotSaved: state.misc && state.misc.changes_made ? state.misc.changes_made : null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(Header);
