import React from "react";

const info = {
    heading: (
        <>
            <i className="fa fa-exclamation-circle"></i>Strictly for Ops & System’s edit only
        </>
    ),
    contents: [
        {
            id: "1",
            title: "Add your training venues here.",
            content: (
                <>
                    <div>
                        <p>All training venues are linked to:</p>
                        <ol style={{ listStyle: "disc", paddingLeft: "10px", marginBottom: "10px" }}>
                            <li>
                                <a href="https://sms.chumbaka.asia/class_registration" target="_blank">
                                    Class Schedule
                                </a>
                            </li>
                            <li>
                                <a href="https://sms.chumbaka.asia/class_registration" target="_blank">
                                    Registration Form
                                </a>
                            </li>
                            <li>
                                <a href="https://sms.chumbaka.asia/class_showcase" target="_blank">
                                    Badge Issued Email
                                </a>
                            </li>
                        </ol>
                        <p>
                            When a Class is published, all parents and students will see the venue on our{" "}
                            <a href="https://www.chumbaka.asia/our-program/" target="_blank">
                                website
                            </a>
                            .
                        </p>
                    </div>
                </>
            ),
        },
    ],
};

const buttons = [];

export default {
    info,
    buttons,
};
