/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _replace from 'lodash/replace';
import _remove from 'lodash/remove';
import _size from 'lodash/size';

import ModalDelete from '../../components/ModalDelete';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormPhoneField from '../../components/FormPhoneField';

import { isArrayExists, isObjectExists, validate } from '../../helpers/validation';
import { getSelectedValue, cloneCollections } from '../../helpers/data';
import { getMomentTime, printSemiPrettyDate } from '../../helpers/date';
import { isSkip, isSchemaRequired } from '../../helpers/schemas';

import { Button, InverseButton, InverseLink } from '../../styles/button';
import { ErrorBox } from '../../styles/message';

import { paymentSchema } from '../../schemas/payment';

import { DAY_OPTIONS, GENDER_OPTIONS } from '../../constants';

const FormWrapper = styled.div`
    padding: 0px;
`;

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});



class PaymentForm extends React.Component {

    handleFormUpdate = (newValue,key) => {
        const { onUpdate, form } = this.props;
        let newData = ( form && isObjectExists( form ) ? cloneCollections( form ) : {} );
        newData[key] = newValue;

        if ( onUpdate )
            onUpdate(newData);
    }

    getFieldOptions = (field) => {
        switch( field.name ) {
            case 'gender':
                return GENDER_OPTIONS;
            default:
                return [];
        }
    }

    getFieldValue = (field) => {
        const { form } = this.props;
        switch( field.name ) {
            default:
                return ( form && form[field.name] ? form[field.name] : ( field.default || '' ) );
        }
    }

    getTooltip = (schema) => {
        switch( schema.id ) {
            // case 'lms_course_link':
            //     return <a href="https://community.canvaslms.com/t5/Instructor-Guide/How-do-I-enable-course-self-enrollment-with-a-join-code-or/ta-p/830" target="_blank">Refer to this method</a>;
            default:
                return;
        }
    }

    getField = (id) => {
        let schema = _find( paymentSchema, { id } );
        return ( schema ? {
            name: ( schema.id || '' ),
            label: ( schema.label ? schema.label + ( isSchemaRequired(schema,'add') ? ' *' : '' ) : '' ),
            required: ( schema.required || false ),
            field_type: ( schema.field || '' ),
            default: ( schema.default || null ),
            disabled: false,
            tooltip: this.getTooltip(schema)
        } : null );
    }

    renderField = (id) => {
        let field = this.getField(id);
        if ( field && field.field_type && !_isEmpty( field.field_type ) ) {
            switch( field.field_type ) {
                case 'text':
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'textarea':
                    return <FormInput {...field} rows={( 'remarks' === id ? 2 : 3 )} placeholder={( 'remarks' === id ? `HINT: StudentName for MONTH-YEAR
e.g. ChongZhiXiong for June 2021` : null )} multiline={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'text_number':
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'email':
                    return <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'select':
                    return <div style={{ paddingTop: "15px" }}><FormSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} disableNative={false} onChange={this.handleFormUpdate} /></div>;
                case 'phone':
                    return <FormPhoneField {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />
            }
        } // end - field.field_type
    }

    renderAmountField = () => {
        const { form } = this.props;
        let field = this.getField('amount');
        return (
        <FormControl fullWidth={true}>
            <TextField 
                label={<span className="field-label">Amount<span className="field-required required">*</span></span>} 
                // type="number" 
                fullWidth={true} 
                placeholder="0.00"
                InputLabelProps={{
                    shrink: true,
                    style: { 
                        fontSize: "16px", 
                        fontWeight: "700", 
                        textTransform: "uppercase", 
                        backgroundColor: "#fff", 
                        paddingLeft: '3px', 
                        paddingRight: '8px' 
                    }
                }}
                InputProps={{
                    startAdornment: <InputAdornment placement="start" style={{ marginRight: '8px' }}>{ form && form.currency && !_isEmpty( form.currency ) ? form.currency : null }</InputAdornment>
                }}
                margin="normal" 
                variant="outlined" 
                // disabled={true} //*incomplete - remove this
                value={this.getFieldValue(field)} 
                onChange={event => {
                    if ( event.target.value ) {
                        if ( validate(event.target.value,'currency') )
                            this.handleFormUpdate( event.target.value, field.name );
                    } else {
                        this.handleFormUpdate( 0, field.name );
                    }
                }} />
        </FormControl>
        )
    }

    render = () => {
        const { form } = this.props;
        return (
        <FormWrapper>
            <Grid container spacing={2}>
                <Grid item xs={12}>{this.renderField('name')}</Grid>
                <Grid item sm={6} xs={12}>{this.renderField('email')}</Grid>
                <Grid item sm={6} xs={12}>{this.renderField('phone')}</Grid>
                <Grid item xs={12}>{this.renderAmountField()}</Grid>
                <Grid item xs={12}>
                    {this.renderField('remarks')}
                    { form && form.remarks && !_isEmpty( form.remarks ) && _size( form.remarks ) > 100 ? <ErrorBox>Please reduce your remarks to less than 100 characters. Current size: {_size( form.remarks )} characters.</ErrorBox> : null }
                </Grid>
                <Grid item xs={12}><Typography variant="body1"  style={{ color: '#D32F2F', fontWeight: "700" }}>* Compulsory to fill in</Typography></Grid>
            </Grid>
        </FormWrapper>
        )
    }
}


export default compose(
    withStyles(useStyles),
    withRouter
)(PaymentForm);