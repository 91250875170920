/* eslint-disable */
import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import _isEmpty from "lodash/isEmpty";

import ModalView from "../../components/ModalView";
import FormCheckbox from "../../components/FormCheckbox";

import { callFunctionsAPI, getAPIErrorMessage } from "../../helpers/action";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";

import { WrapWord } from "../../styles/misc";
import { AInfoLink, WarningButton, IndigoButton, InfoButton } from "../../styles/button";

import theme from "../../theme";
import { PLATFORM_URL } from "../../constants";

const StudentSyncData = ({ student = {}, changesNotSaved = false }) => {
    const [openModal, setOpenModal] = useState(false);
    const [processing, setProcessing] = useState(false);

    const triggerSyncData = () => {
        setProcessing(true);
        callFunctionsAPI({
            url: "student",
            action: "student_sync_data",
            formData: {
                uid: student.uid,
            },
        })
            .then((data) => {
                setProcessing(false);
                setOpenModal(false);
                triggerSuccessAlert("Student's profile data has been synced successfully.");
            })
            .catch((error) => {
                setProcessing(false);
                triggerErrorAlert(getAPIErrorMessage(error));
            });
    };

    return (
        <>
            <div style={{ paddingTop: "5px" }}>
                <InfoButton
                    style={{ padding: "4px 8px", fontSize: "12px" }}
                    onClick={() => {
                        if (changesNotSaved) {
                            triggerErrorAlert("Please save your changes first.");
                        } else {
                            setOpenModal(true);
                        }
                    }}
                >
                    <i className="fa fa-refresh"></i>
                    Sync Profile Data with Student's class data
                </InfoButton>
            </div>

            <ModalView
                open={openModal ? true : false}
                title={"Please Confirm your action"}
                maxWidth="sm"
                actionLabel={"Sync Data"}
                cancelLabel="Cancel"
                doAction={triggerSyncData}
                onClose={() => {
                    setOpenModal(false);
                }}
                processing={processing}
                contents={
                    <>
                        <DialogContent>
                            <DialogContentText>
                                This will sync the student's profile data with the class data. Are you sure you want to proceed?
                            </DialogContentText>
                        </DialogContent>
                    </>
                }
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        changesNotSaved: (state.misc && state.misc.changes_made) || null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(StudentSyncData);
