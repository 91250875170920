/* eslint-disable */
import React from "react";
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";
import _sortBy from "lodash/sortBy";
import _filter from "lodash/filter";

import ExternalAssetsAccess from "../../modules/ExternalAssetsAccess";

import ModalView from "../../components/ModalView";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormMultiSelect from "../../components/FormMultiSelect";
import FormDatePicker from "../../components/FormDatePicker";
import FormPhoneField from "../../components/FormPhoneField";

import { FormBox } from "../../styles/form";

import { isAdmin, hasAccessRights } from "../../helpers/auth";
import { isArrayExists } from "../../helpers/validation";
import { cloneCollections, getSelectOptions, getSelectValues } from "../../helpers/data";
import { getMomentTime } from "../../helpers/date";
import { isSchemaRequired } from "../../helpers/schemas";

import { userPartnerSchema } from "../../schemas/user_partner";
import { COUNTRIES_LIST } from "../../data/const_countries";
import { STATE_OPTIONS } from "../../data/const_states";

import { resendAccountCreationEmail } from "../../actions/personnels";

import { YES_NO_OPTIONS, GENDER_OPTIONS, PARTNER_ROLES_OPTIONS } from "../../constants";

const useStyles = (theme) => ({
    boxheading: {
        fontSize: "20px",
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd",
    },
});

class PersonnelEditForm extends React.Component {
    state = {
        randNum: false,
    };

    handleFormUpdate = (newValue, key) => {
        const { onFormUpdate, personnel, currentPersonnel } = this.props;
        let newData = personnel && !_isEmpty(personnel) ? cloneCollections(personnel) : {};
        newData[key] = newValue;

        // do update
        if (onFormUpdate) onFormUpdate(newData);
    };

    getRegionIDByPartnerID = (partner_id) => {
        const { regions } = this.props;
        const selected = _find(regions, { value: partner_id });
        return selected ? selected.id : "";
    };

    getFieldOptions = (field) => {
        const { personnel, designations, currency, regions, roles, authData } = this.props;

        switch (field.name) {
            case "status":
                return personnel[field.name] && personnel[field.name] === "pending"
                    ? [{ value: "pending", label: "Pending" }]
                    : [
                          { value: "active", label: "Active" },
                          { value: "disabled", label: "Disabled" },
                      ];
            case "region":
                return getSelectOptions({
                    list: regions || false,
                    options: [{ value: "", label: "Select an Option" }],
                    keys: { value: "id", label: "label" },
                });
            case "role":
                return getSelectOptions({
                    list: isAdmin(authData) ? roles || false : PARTNER_ROLES_OPTIONS,
                    options: [{ value: "", label: "Select an Option" }],
                    keys: isAdmin(authData) ? { value: "id", label: "label" } : { value: "value", label: "label" },
                });
            case "designation":
                return getSelectOptions({
                    list: designations || false,
                    options: [{ value: "", label: "Select an Option" }],
                    keys: { value: "id", label: "label" },
                });
            case "finance_currency":
                return getSelectOptions({
                    list: currency || false,
                    options: [{ value: "", label: "Select an Option" }],
                    keys: { value: "id", label: "label" },
                });
            case "gender":
                return GENDER_OPTIONS;
            default:
                return [];
        }
    };

    getFieldValue = (field) => {
        const { personnel } = this.props;
        switch (field.name) {
            default:
                return personnel && personnel[field.name] ? personnel[field.name] : field.default || "";
        }
    };

    isFieldDisabled = (schema) => {
        const { authData, personnel } = this.props;
        var disabled = false;

        if (schema && schema.disabled && isArrayExists(schema.disabled)) {
            schema.disabled.forEach((condition) => {
                switch (condition) {
                    case "admin":
                        if (!isAdmin(authData)) disabled = true;
                        break;
                    case "update":
                        disabled = true;
                        break;
                    case "self":
                        // special condition for status - disable it if it's the current user
                        if (authData && personnel && authData.uid === personnel.uid) {
                            disabled = true;
                        } // end - authData
                        break;
                }
            });
        } // end - schema

        return disabled;
    };

    getField = (id) => {
        const { massEditMode } = this.props;
        let schema = _find(userPartnerSchema, { id });
        return schema
            ? {
                  name: schema.id || "",
                  label: (schema.label || "") + (isSchemaRequired(schema, "update") && !massEditMode ? " (Required)" : ""),
                  field_type: schema.field || "",
                  default: massEditMode ? null : schema.default || null,
                  disabled: schema.disabled ? this.isFieldDisabled(schema) : false,
              }
            : null;
    };

    renderField = (id) => {
        let field = this.getField(id);
        if (field && field.field_type && !_isEmpty(field.field_type)) {
            switch (field.field_type) {
                case "text":
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case "textarea":
                    return (
                        <FormInput
                            {...field}
                            rows={"notes" === id ? 6 : 3}
                            multiline={true}
                            value={this.getFieldValue(field)}
                            onChange={this.handleFormUpdate}
                        />
                    );
                case "text_number":
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case "email":
                    return <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case "select":
                    return (
                        <div style={{ paddingTop: "7px" }}>
                            <FormSelect
                                {...field}
                                value={this.getFieldValue(field)}
                                options={this.getFieldOptions(field)}
                                onChange={this.handleFormUpdate}
                            />
                        </div>
                    );
                case "multiselect":
                    return (
                        <div style={{ paddingTop: "7px" }}>
                            <FormMultiSelect
                                {...field}
                                value={this.getFieldValue(field)}
                                options={this.getFieldOptions(field)}
                                onChange={this.handleFormUpdate}
                            />
                        </div>
                    );
                case "datepicker":
                    return <FormDatePicker {...field} noDefaultVal={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case "phone":
                    return <FormPhoneField {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
            }
        } // end - field.field_type
    };

    renderCreatedModifiedDate = (item) => {
        let created_on = item.created_on && item.created_on._seconds ? item.created_on._seconds * 1000 : item.created_on || null,
            modified_on = item.modified_on && item.modified_on._seconds ? item.modified_on._seconds * 1000 : item.modified_on || null;
        return (
            <div style={{ paddingTop: "10px", textAlign: "right", color: "#999", fontSize: "1.25rem" }}>
                <div>{created_on ? "Created on " + getMomentTime(created_on, "YYYY-MM-DD hh:mm:ssa") : ""}</div>
                <div>{modified_on ? "Last Modified on " + getMomentTime(modified_on, "YYYY-MM-DD hh:mm:ssa") : ""}</div>
            </div>
        );
    };

    render = () => {
        const {
            authData,
            partner_id,
            classes,
            personnel,
            disableSystemDate,
            massEditMode,
            onGrantPersonnelAccess = () => {},
            onRevokePersonnelAccess = () => {},
        } = this.props;
        return (
            <FormBox>
                <Typography variant="h4" className={classes.boxheading}>
                    Contact Details
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {this.renderField("status")}
                    </Grid>
                    <Grid item xs={6}>
                        {" "}
                    </Grid>
                    <Grid item xs={12}>
                        <FormSelect
                            name="region"
                            label="Partner"
                            value={this.getRegionIDByPartnerID(partner_id)}
                            options={this.getFieldOptions({ name: "region" })}
                            disabled={true}
                            onChange={() => {}}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        {this.renderField("designation")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("gender")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("name")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("id_num")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("email")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("mobile")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("org")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("role")}
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.boxheading}>
                            Finance
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        {this.renderField("finance_currency")}
                    </Grid>
                    <Grid item xs={6}>
                        {" "}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("finance_payee_name")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("finance_bank_account_no")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("finance_bank_name")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("finance_bank_swift_code")}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderField("finance_bank_address")}
                    </Grid>

                    <ExternalAssetsAccess
                        authData={authData}
                        user={personnel}
                        onGrantPersonnelAccess={(formData) => onGrantPersonnelAccess(formData)}
                        onRevokePersonnelAccess={(formData) => onRevokePersonnelAccess(formData)}
                    />
                </Grid>

                {disableSystemDate ? null : this.renderCreatedModifiedDate(personnel)}
            </FormBox>
        );
    };
}

export default compose(withStyles(useStyles), withRouter)(PersonnelEditForm);
