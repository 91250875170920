/* eslint-disable */
import _random from "lodash/random";
import _isEmpty from "lodash/isEmpty";

import {
    DATABASE_STUDENTS_LIST,
    DATABASE_STUDENTS_GET_DATA,
    DATABASE_STUDENTS_UPDATE,
    DATABASE_STUDENTS_DELETE,
    // GLOBAL_ERROR_TRIGGER
} from "../types";

import { getAPIErrorMessage } from "../../helpers/action";
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from "../global";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import { appChangesReset } from "../misc";

import {
    fb_getStudentsList,
    fb_getStudentData,
    fb_updateStudentData,
    fb_deleteStudentBadge,
    fb_deleteStudentFromDatabase,
    fb_addParentObverser,
    fb_removeParentObverser,
    fb_triggerStudentRegionTransfer,
    fb_triggerStudentAccountTransfer,
    fb_triggerStudentPasswordChange,
    fb_triggerStudentBackpackTransfer,
} from "./fb";

export const getStudentsList = (props) => {
    return (dispatch) => {
        dispatch(toggleLoader(true));

        fb_getStudentsList(props)
            .then((list) => {
                dispatch(toggleLoader(false));
                dispatch({
                    type: DATABASE_STUDENTS_LIST,
                    payload: { list },
                });
            })
            .catch((error) => {
                dispatch(toggleLoader(false));
                triggerErrorAlert(getAPIErrorMessage(error));
                dispatch({
                    type: DATABASE_STUDENTS_LIST,
                    payload: { list: [] },
                });
            });
    };
};

export const getStudentData = (uid, props) => {
    return (dispatch) => {
        fb_getStudentData(uid, props)
            .then((student) => {
                dispatch({
                    type: DATABASE_STUDENTS_GET_DATA,
                    payload: { student },
                });
            })
            .catch((error) => {
                triggerErrorAlert(getAPIErrorMessage(error));
                dispatch({
                    type: DATABASE_STUDENTS_GET_DATA,
                    payload: { student: {} },
                });
            });
    };
};

export const updateStudentData = (formData) => {
    return (dispatch) => {
        dispatch(toggleLoader(true));

        fb_updateStudentData(formData)
            .then((results) => {
                fb_getStudentData(formData.uid).then((student) => {
                    // push new data
                    dispatch({
                        type: DATABASE_STUDENTS_GET_DATA,
                        payload: { student },
                    });
                    // updated
                    dispatch({ type: DATABASE_STUDENTS_UPDATE });
                    dispatch(toggleLoader(false));
                    dispatch(appChangesReset());
                    triggerSuccessAlert("Student Data Updated");
                });
            })
            .catch((error) => {
                dispatch(toggleLoader(false));
                triggerErrorAlert(getAPIErrorMessage(error));
            });
    };
};

export const deleteStudentFromDatabase = (uid, props) => {
    return (dispatch) => {
        dispatch(toggleModalDeleting(true));

        fb_deleteStudentFromDatabase(uid)
            .then((results) => {
                fb_getStudentsList(props).then((list) => {
                    dispatch({
                        type: DATABASE_STUDENTS_LIST,
                        payload: { list },
                    });
                    dispatch({ type: DATABASE_STUDENTS_DELETE });
                    dispatch(toggleModalDeleting(false));
                    triggerSuccessAlert("Student Data Deleted!");
                });
            })
            .catch((error) => {
                dispatch(toggleModalDeleting(false));
                triggerErrorAlert(getAPIErrorMessage(error));
            });
    };
};

export const deleteStudentBadge = (badge_id, uid) => {
    return (dispatch) => {
        dispatch(toggleModalDeleting(true));

        fb_deleteStudentBadge(badge_id)
            .then((results) => {
                fb_getStudentData(uid).then((student) => {
                    // push new data
                    dispatch({
                        type: DATABASE_STUDENTS_GET_DATA,
                        payload: { student },
                    });
                    dispatch(toggleModalDeleting(false));
                    triggerSuccessAlert("Badge Deleted");
                });
            })
            .catch((error) => {
                dispatch(toggleModalDeleting(false));
                triggerErrorAlert(getAPIErrorMessage(error));
            });
    };
};

export const addParentObverser = (formData) => {
    return (dispatch) => {
        dispatch(toggleLoader(true));

        fb_addParentObverser(formData)
            .then((results) => {
                fb_getStudentData(formData.uid).then((student) => {
                    // push new data
                    dispatch({
                        type: DATABASE_STUDENTS_GET_DATA,
                        payload: { student },
                    });
                    // updated
                    dispatch({ type: DATABASE_STUDENTS_UPDATE });
                    dispatch(toggleLoader(false));
                    dispatch(appChangesReset());
                    triggerSuccessAlert("Observer Added! It might takes up to 5 minutes to reach observer's inbox.");
                });
            })
            .catch((error) => {
                dispatch(toggleLoader(false));
                triggerErrorAlert(getAPIErrorMessage(error));
            });
    };
};

export const removeParentObverser = (formData) => {
    return (dispatch) => {
        dispatch(toggleLoader(true));

        fb_removeParentObverser(formData)
            .then((results) => {
                fb_getStudentData(formData.uid).then((student) => {
                    // push new data
                    dispatch({
                        type: DATABASE_STUDENTS_GET_DATA,
                        payload: { student },
                    });
                    // updated
                    dispatch({ type: DATABASE_STUDENTS_UPDATE });
                    dispatch(toggleLoader(false));
                    dispatch(appChangesReset());
                    triggerSuccessAlert("Observer Removed");
                });
            })
            .catch((error) => {
                dispatch(toggleLoader(false));
                triggerErrorAlert(getAPIErrorMessage(error));
            });
    };
};

export const triggerStudentRegionTransfer = (formData) => {
    return (dispatch) => {
        dispatch(toggleLoader(true, "Transfering process is on-going! Please do not close this window or click the Back button on your browser"));

        fb_triggerStudentRegionTransfer(formData)
            .then((results) => {
                fb_getStudentData(formData.uid).then((student) => {
                    // push new data
                    dispatch({
                        type: DATABASE_STUDENTS_GET_DATA,
                        payload: { student },
                    });
                    // updated
                    dispatch({ type: DATABASE_STUDENTS_UPDATE });
                    dispatch(toggleLoader(false));
                    dispatch(appChangesReset());
                    triggerSuccessAlert("Partner Transfer Completed");
                });
            })
            .catch((error) => {
                dispatch(toggleLoader(false));
                triggerErrorAlert(getAPIErrorMessage(error), 5000);
            });
    };
};

export const triggerStudentAccountTransfer = (formData) => {
    return (dispatch) => {
        dispatch(toggleLoader(true, "Transfering process is on-going! Please do not close this window or click the Back button on your browser"));

        fb_triggerStudentAccountTransfer(formData)
            .then((results) => {
                fb_getStudentData(formData.uid).then((student) => {
                    // push new data
                    dispatch({
                        type: DATABASE_STUDENTS_GET_DATA,
                        payload: { student },
                    });
                    // updated
                    dispatch({ type: DATABASE_STUDENTS_UPDATE });
                    dispatch(toggleLoader(false));
                    dispatch(appChangesReset());
                    triggerSuccessAlert(
                        "Email Change Completed! Please ask the student to logout of their old account, and re-login with their new email as well as the new password",
                        10000
                    );
                });
            })
            .catch((error) => {
                dispatch(toggleLoader(false));
                triggerErrorAlert(getAPIErrorMessage(error), 5000);
            });
    };
};

export const triggerStudentPasswordChange = (formData) => {
    return (dispatch) => {
        dispatch(toggleLoader(true, "Updating process is on-going! Please do not close this window or click the Back button on your browser"));

        fb_triggerStudentPasswordChange(formData)
            .then((results) => {
                fb_getStudentData(formData.uid).then((student) => {
                    // push new data
                    dispatch({
                        type: DATABASE_STUDENTS_GET_DATA,
                        payload: { student },
                    });
                    // updated
                    dispatch({ type: DATABASE_STUDENTS_UPDATE });
                    dispatch(toggleLoader(false));
                    dispatch(appChangesReset());
                    triggerSuccessAlert("Password Change Completed!", 10000);
                });
            })
            .catch((error) => {
                dispatch(toggleLoader(false));
                triggerErrorAlert(getAPIErrorMessage(error), 5000);
            });
    };
};

export const triggerStudentBackpackTransfer = (formData) => {
    return (dispatch) => {
        dispatch(toggleLoader(true, "Transfering process is on-going! Please do not close this window or click the Back button on your browser"));

        fb_triggerStudentBackpackTransfer(formData)
            .then((results) => {
                fb_getStudentData(formData.uid).then((student) => {
                    // push new data
                    dispatch({
                        type: DATABASE_STUDENTS_GET_DATA,
                        payload: { student },
                    });
                    // updated
                    dispatch({ type: DATABASE_STUDENTS_UPDATE });
                    dispatch(toggleLoader(false));
                    dispatch(appChangesReset());
                    triggerSuccessAlert("Backpack transfer completed!", 5000);
                });
            })
            .catch((error) => {
                dispatch(toggleLoader(false));
                triggerErrorAlert(getAPIErrorMessage(error), 5000);
            });
    };
};
