/* eslint-disable */
import _random from 'lodash/random';
import _forEach from 'lodash/forEach';
import axios from 'axios';

import {
	GET_GOOGLE_FONTS,
	API_ERROR
} from './types';

import { FIREBASE_CONFIG_DATA } from '../firebase';

export function getGoogleFonts() {
	return dispatch => {

        axios({
            method: 'get',
            url: 'https://www.googleapis.com/webfonts/v1/webfonts?key='+FIREBASE_CONFIG_DATA.apiKey
        })
        .then( response => {
            dispatch({
                type: GET_GOOGLE_FONTS,
                payload: { list: ( response && response.data && response.data.items ? response.data.items : [] ) , rand: _random(1,9999) }
            });
        })
        .catch(error => {
            dispatch({
                type: GET_GOOGLE_FONTS,
                payload: { list: [] , rand: _random(1,9999) }
            });
        });

	}
}