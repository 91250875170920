/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Grid from "@material-ui/core/Grid";
import _isEmpty from "lodash/isEmpty";
// import _merge from 'lodash/merge';
// import _size from 'lodash/size';
// import _isEqual from 'lodash/isEqual';

import CSVUploadTeachersMentors from "../../modules/CSVUploadTeachersMentors";
import Teams from "./teams";

import AppWrapper from "../../components/AppWrapper";
import ScheduleSwitcher from "../../components/ScheduleSwitcher";
import AppInfoBox from "../../modules/AppInfoBox";
import Table from "./table";
import ClassFilterMenu from "./filter_menu";

import { isAdmin, getUserRegionFilter } from "../../helpers/auth";
import { triggerSuccessAlert } from "../../helpers/alert";
// import { cloneCollections } from '../../helpers/data';

import { getTrainees } from "../../actions/trainees";
// import { getCoursesOptions } from '../../actions/courses';
import { getSchedule, getSchedulesOptions } from "../../actions/schedules";
import { getRegions } from "../../actions/m_regions";
import { getRegistrationFormsFields } from "../../actions/m_registration_forms";
import { getMaintenance } from "../../actions/maintenance";
import { resetRedux } from "../../actions/misc";

import { ButtonGroup, AInfoLink, InfoButton } from "../../styles/button";
import { InfoBox } from "../../styles/message";

import { PLATFORM_COURSE_REGISTRATION_LINK } from "../../constants";

const TableWrapper = styled.div`
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
`;

class ClassRegistrationPage extends React.Component {
    state = {
        currentSchedule: false,
        dataLoaded: false,
    };

    componentDidMount() {
        const { authData, regionsList, schedulesList, registrationFormsFieldsList, racesList, districtsList, location } = this.props;

        // preload trainees
        if (location && location.state && location.state.schedule_id && !_isEmpty(location.state.schedule_id))
            this.handleFilter(location.state.schedule_id);

        // get regions
        if (!regionsList) this.props.dispatch(getRegions());

        // get course options
        // if ( !coursesList )
        //     this.props.dispatch(getCoursesOptions({ region: getUserRegionFilter(authData) }));

        // get schedule options
        if (!schedulesList) this.props.dispatch(getSchedulesOptions({ region: getUserRegionFilter(authData) }));

        // get registration form
        if (!registrationFormsFieldsList) this.props.dispatch(getRegistrationFormsFields("registration_forms"));

        if (!racesList) this.props.dispatch(getMaintenance("races"));

        if (!districtsList) this.props.dispatch(getMaintenance("districts"));

        if (regionsList && schedulesList) {
            this.setState({ dataLoaded: true });
        } // end - randNum
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { regionsList, schedulesList, registrationFormsFieldsList, racesList, districtsList } = this.props;
        if (regionsList && schedulesList && registrationFormsFieldsList && racesList && districtsList && !this.state.dataLoaded) {
            this.setState({ dataLoaded: true });
        } // end - randNum
    }

    componentWillUnmount() {
        this.handleReset();
    }

    handleReset = () => {
        this.props.dispatch(resetRedux("trainees"));
        this.props.dispatch(resetRedux("schedule"));
    };

    handleFilter = (schedule_id) => {
        const { authData } = this.props;
        this.setState({ currentSchedule: schedule_id });
        this.props.dispatch(getTrainees({ schedule_id }, true));
        this.props.dispatch(getSchedule(schedule_id, { authData }));
    };

    renderTable() {
        const { authData, traineesList, regionsList, schedulesList, registrationFormsFieldsList, racesList, districtsList, currentScheduleData } =
            this.props;
        const { currentSchedule } = this.state;
        return (
            <Table
                currentSchedule={currentSchedule || false}
                schedule={currentScheduleData || false}
                trainees={traineesList}
                schedules={schedulesList}
                registration_forms={registrationFormsFieldsList}
                races={racesList}
                districts={districtsList}
                regions={regionsList}
                // courses={coursesList}
                authData={authData}
            />
        );
    }

    renderContents() {
        const { traineesList, currentScheduleData, registrationFormsFieldsList, randNum } = this.props;
        const { currentSchedule } = this.state;
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <ClassFilterMenu currentSchedule={currentSchedule || false} onFilter={this.handleFilter} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppInfoBox page="ClassRegistrationPage" />
                        {currentSchedule && !_isEmpty(currentSchedule) && (
                            <ButtonGroup align="right">
                                <CopyToClipboard
                                    text={PLATFORM_COURSE_REGISTRATION_LINK + currentSchedule}
                                    onCopy={() => {
                                        triggerSuccessAlert("Link copied to clipboard.");
                                    }}
                                >
                                    <InfoButton style={{ padding: "10px 25px", borderRadius: "25px" }}>
                                        <i className="fa fa-link"></i>Get Registration Link
                                    </InfoButton>
                                </CopyToClipboard>
                            </ButtonGroup>
                        )}
                    </Grid>
                </Grid>

                {traineesList ? <TableWrapper>{this.renderTable()}</TableWrapper> : null}

                <>
                    {currentScheduleData && currentScheduleData.team_module && currentScheduleData.team_module === "yes" ? (
                        <>
                            <div style={{ borderTop: "1px solid #ddd", padding: "30px 0", marginTop: "30px" }}>
                                <Typography variant="h5" style={{ paddingBottom: "15px" }}>
                                    Team(s) Registered:
                                </Typography>
                                <Teams
                                    trainees={traineesList}
                                    schedule={currentScheduleData}
                                    registration_forms={registrationFormsFieldsList}
                                    randNum={randNum}
                                />
                            </div>
                            <div style={{ borderTop: "1px solid #ddd", padding: "30px 0", marginTop: "30px" }}>
                                <CSVUploadTeachersMentors schedule={currentScheduleData} />
                            </div>
                        </>
                    ) : null}
                </>
            </>
        );
    }

    render() {
        const { dataLoaded, currentSchedule } = this.state;
        const { authData } = this.props;
        return (
            <AppWrapper
                subtitle="1. Recruitment"
                title="Registration"
                onLoad={!dataLoaded ? true : false}
                customColumn={
                    currentSchedule && !_isEmpty(currentSchedule) ? (
                        <ScheduleSwitcher page="class_registration" schedule_id={currentSchedule} authData={authData} />
                    ) : null
                }
                contents={authData ? this.renderContents() : null}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        traineesList: state.trainees && state.trainees.trainees ? state.trainees.trainees : null,
        randNum: state.trainees && state.trainees.rand ? state.trainees.rand : null,
        currentScheduleData: state.schedules && state.schedules.schedule ? state.schedules.schedule : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        // coursesList: state.courses && state.courses.courses_options ? state.courses.courses_options : false,
        schedulesList: state.schedules && state.schedules.schedules_options ? state.schedules.schedules_options : false,
        registrationFormsFieldsList:
            state.maintenance && state.maintenance.registration_forms_fields ? state.maintenance.registration_forms_fields : null,
        racesList: state.maintenance && state.maintenance.races ? state.maintenance.races : null,
        districtsList: state.maintenance && state.maintenance.districts ? state.maintenance.districts : null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(ClassRegistrationPage);
