/* eslint-disable */
import React, { useState, useMemo, useEffect } from 'react';
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';

import Form from './form';
import { isClassTeacher, isClassMentor } from './helpers';

import ErrorMessage from '../../components/ErrorMessage';
import DotsLoader from '../../components/DotsLoader';
// import ModelDelete from '../ModalDelete';
import ModalView from '../../components/ModalView';
// import FormInput from '../FormInput';
// import FormSelect from '../FormSelect';
// import ButtonActions from '../ButtonActions';
// import ToolTipInfo from '../ToolTipInfo';

// import { InfoButton, SuccessButton, GreyButton, ButtonGroup, InfoLink, SuccessLink, InverseButton, ErrorButton } from '../../styles/button';
import { GreyTag } from '../../styles/tag';
// import { WrapWord } from '../../styles/misc';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
// import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
// import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';
import { getStateValue } from '../../helpers/registration_form';

import theme from '../../theme';


const Wrapper = styled.div`

`;

const getDataFromAPI = (item,student,type,schedules,authData) => {
    return new Promise((resolve,reject) => {
        let trainee = ( student && student.trainees && isArrayExists( student.trainees ) ? _find( student.trainees, { schedule_id: item.schedule_id } ) : false ),
            formData = {
                id: item.id,
                trainee_id: ( trainee && trainee.id || '' ),
                schedule_id: item.schedule_id || ''
            },
            action = '';

        switch( type ) {
            case 'create':
            case 'join':
                if ( isClassTeacher(schedules,item.schedule_id,authData.email) ) {
                    action = 'get_class_info_by_schedule';
                } else {
                    action = 'get_teams_by_schedule';
                }
                break;
            case 'edit':
                if ( isClassTeacher(schedules,item.schedule_id,authData.email) ) {
                    action = 'get_team_by_id_teacher';
                } else {
                    action = 'get_team_by_id';
                }
                break;
            default:
                action = 'get_team_by_id';
                break;
        }

        callFunctionsAPI({ url: 'student', action, formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
    });
}

const Modal = ({
    authData,
    view = 'student',
    student = {},
    student_trainees = [],
    schedule = {},
    trainees = [],
    item = {},
    modalType = '',
    teamsList = [],
    schedules = [],
    onAction,
    onClose
}) => {
    const [ errorMsg, setErrorMsg ] = useState(null);
    const [ loaded, setLoaded ] = useState(false);
    const [ type, setType ] = useState('');
    const [ trainee, setTrainee ] = useState({});
    const [ students, setStudents ] = useState([]);
    const [ you, setYou ] = useState({});
    const [ team, setTeam ] = useState({});
    const [ teams, setTeams ] = useState([]);
    const [ teachers, setTeachers ] = useState([]);
    const [ mentors, setMentors ] = useState([]);
    const [ submitData, setSubmitData ] = useState({});

    useEffect(() => {
        let mounted = true;
        getDataFromAPI(item,student,modalType,schedules,authData)
        .then(data => {
            if (mounted) {
                setLoaded(true);
                switch( modalType ) {
                    case 'create':
                        setTeams(( data && data.teams || [] ));
                        setTrainee(( data && data.trainee || {} ));
                        setTeachers(( data && data.teachers || [] ));
                        setMentors(( data && data.mentors || [] ));
                        if ( data && data.students && isArrayExists( data.students ) ) {
                            setStudents( data.students );
                        }
                        if ( data && data.you && isObjectExists( data.you ) ) {
                            setYou( data.you );
                        }
                        break;
                    case 'join':
                        setTeams(( data && data.teams || [] ));
                        setTrainee(( data && data.trainee || {} ));
                        setTeachers(( data && data.teachers || [] ));
                        setMentors(( data && data.mentors || [] ));
                        break;
                    case 'edit':
                    default:
                        setTeachers(( data && data.teachers || [] ));
                        setMentors(( data && data.mentors || [] ));
                        setTeam(( data && data.team || {} ));
                        if ( data && data.students && isArrayExists( data.students ) ) {
                            setStudents( data.students );
                        }
                        if ( data && data.you && isObjectExists( data.you ) ) {
                            setYou( data.you );
                        }
                        break;
                }
            }
        })
        .catch(error => {
            if ( mounted ) {
                setLoaded(true);
                setErrorMsg(getAPIErrorMessage(error));
            }
        });
        return () => { mounted = false };
    },[]);

    useEffect(() => {
        setType( modalType );
        if ( view === 'admin' ) {
            setTeams( teamsList );
        }
    },[ modalType, teamsList ]);

    const open = useMemo(() => {
        return ( item && isObjectExists( item ) ? true : false );
    },[ item ]);

    const modalTitle = useMemo(() => {
        switch( type ) {
            case 'create':
                return 'Create New Team';
            case 'join':
                return (
                <Grid container spacing={2} alignContent="center">
                    <Grid item xs={6}>
                        {'Join a Team'}
                    </Grid>
                    <Grid item xs={6} align="right">
                        { trainee ? <GreyTag>{getStateValue( trainee )}</GreyTag> : null }
                    </Grid>
                </Grid>
                );
            case 'edit':
            default:
                return 'Edit Team';
        }
    },[ type, trainee ]);

    const actionLabel = useMemo(() => {
        switch( type ) {
            case 'create':
                return 'Create';
            case 'join':
                return 'Join';
            case 'edit':
            default:
                return 'Update';
        }
    },[ type ]);

    const noAction = useMemo(() => {
        if ( loaded && !errorMsg ) {
            switch( type ) {
                case 'join':
                    return ( teams && _size( teams ) > 0 ? false : true );
                case 'edit':
                case 'create':
                default:
                    return false;
            }
        } else {
            return true;
        }
    },[ type, loaded, errorMsg, teams ]);

    const traineesList = useMemo(() => {
        if ( you && you.role === 'teacher' ) {
            return ( students && isArrayExists( students ) ? students.filter( s => s.teams && isArrayExists( s.teams ) ? false : true ) : [] );
        }
        return trainees || [];
    },[ trainees, students, you ]);

    const handleAction = () => {
        const trainee = ( student && student.trainees && isArrayExists( student.trainees ) ? _find( student.trainees, { schedule_id: item.schedule_id } ) : false );
        let error = false;
        // do error check
        switch( type ) {
            case 'create':
            case 'edit':

                // do member limits check
                if ( schedule && isObjectExists( schedule ) ) {
                    if ( submitData.trainees.length > parseInt( schedule.team_size ) ) {
                        error = 'You can only add '+schedule.team_size+' members per team.';
                    } 
                }

                // make sure name wasn't already in-used
                if ( type === 'create' ) {
                    if ( submitData.name && !_isEmpty( submitData.name ) && _find( teams, { name: submitData.name } ) ) {
                        error = "This team name already exists. Please use another name for your team.";
                    }

                    // make sure there is at least one student in the team
                    if ( you && you.role && you.role === 'teacher' ) {
                        if ( submitData.trainees && submitData.trainees.length < 1 ) {
                            error = "Please add at least one student to the team.";
                        }
                    }
                }

                if ( !submitData.state )
                    error = 'Please select your state.';

                // if ( !submitData.school )
                //     error = 'Please select your school.';

                // if ( !submitData.teacher )
                //     error = 'Please select your teacher.';

                // if ( !submitData.mentor )
                //     error = 'Please select your mentor.';

                if ( !( submitData.name && !_isEmpty( submitData.name ) ) )
                    error = 'Please enter a name for the team.';

                // add required data to submiData
                submitData.trainee_id = ( trainee && trainee.id || '' );
                submitData.schedule_id = item.schedule_id || '';
                break;
            case 'join':
                if ( !( submitData.passcode && !_isEmpty( submitData.passcode ) ) )
                    error = 'Please enter a valid passcode.';

                if ( !submitData.id )
                    error = 'Please select a team to join'; 

                // trim pass code
                if ( submitData.passcode && !_isEmpty( submitData.passcode ) )
                    submitData.passcode = submitData.passcode.trim();

                // add trainee_id & schedule_id
                submitData.trainee_id = ( trainee && trainee.id || '' );
                submitData.schedule_id = item.schedule_id || '';
                break;
        }

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            if ( you && you.role && you.role === 'teacher' ) {
                if ( type === 'create' ) {
                    onAction('create_teacher',submitData);
                } else {
                    onAction('edit_teacher',submitData);
                }
            } else {
                onAction(type,submitData);
            }
            onClose();
        }
                
    }

    return (
    <Wrapper>

        <ModalView 
            open={open}
            title={modalTitle}
            maxWidth="md"
            noAction={noAction}
            actionLabel={actionLabel}
            cancelLabel="Cancel"
            doAction={handleAction}
            onClose={onClose}
            contents={ loaded ? ( errorMsg ? <ErrorMessage errorMessage={errorMsg} /> : (
            <>
                <Form
                    view={view}
                    authData={authData}
                    type={type}
                    you={ 
                        isClassTeacher(schedules,item.schedule_id,authData.email) ||
                        isClassMentor(schedules,item.schedule_id,authData.email) 
                        ? you : false }
                    item={item}
                    trainee={trainee}
                    trainees={traineesList}
                    team={team}
                    teams={teams}
                    teachers={teachers}
                    mentors={mentors}
                    onTypeChange={(newType) => setType(newType)}
                    onFormUpdate={(newValue) => setSubmitData( newValue )} />
            </>
            ) ) : <DotsLoader /> } />


    </Wrapper>
    )
}

export default Modal