/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _sortBy from "lodash/sortBy";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _remove from "lodash/remove";
import _toLower from "lodash/toLower";
import _toString from "lodash/toString";

import ModalView from "../../components/ModalView";
import Table from "../../components/Table";
import FormSelect from "../../components/FormSelect";
import FormRadio from "../../components/FormRadio";
import FormDatePicker from "../../components/FormDatePicker";
import FormCheckbox from "../../components/FormCheckbox";

import { isArrayExists, isObjectExists } from "../../helpers/validation";
import {
    cloneCollections,
    doPagination,
    doArraySearch,
    getSelectOptions,
    getSelectValues,
    getSelectedValue,
    compareString,
} from "../../helpers/data";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import { getAPIErrorMessage } from "../../helpers/action";
import { getCourseBadges, getFeedbackformFromTrainee } from "../../helpers/showcase";

import { InverseButton, GreyButton } from "../../styles/button";
import { WarningBox } from "../../styles/message";
import { WrapWord } from "../../styles/misc";

import { ENROLLMENT_STATUS_OPTIONS, DEV_MODE, PLATFORM_URL } from "../../constants";

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
    border: 2px solid #212121;
`;

class BulkPrintCerts extends React.Component {
    utcOffSet = 8;

    state = {
        modalData: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { open } = this.props;
        if (open && !prevProps.open) {
            this.setState({ modalData: this.getInitialModalData() });
        } // end - open
    }

    handleClose = () => {
        const { onClose } = this.props;
        if (onClose) onClose();
    };

    handlePrint = () => {
        const { schedule, authData, showcase, onClose } = this.props;
        const { modalData } = this.state;
        let error = false;
        const students = this.getSelectedTrainees();

        if (!(modalData && modalData.lms_course_id && !_isEmpty(modalData.lms_course_id))) {
            error = "Please select a course to print certificates.";
        }

        if (!students || !isArrayExists(students)) {
            error = "Please select at least one trainee to print certificates.";
        }

        if (error) {
            triggerErrorAlert(error);
            return;
        }

        // do the print
        // get all the badges ids
        const ids = [];
        students.forEach((student) => {
            const studentShowcase =
                showcase && showcase.trainees && isArrayExists(showcase.trainees) ? _find(showcase.trainees, { id: student.id }) : false;
            if (studentShowcase && studentShowcase.courses && isArrayExists(studentShowcase.courses)) {
                studentShowcase.courses.forEach((course) => {
                    if (course && course.id && compareString(course.id, modalData.lms_course_id)) {
                        if (course.issued_badge_id && !_isEmpty(course.issued_badge_id)) {
                            ids.push(course.issued_badge_id);
                        }
                    }
                });
            }
        });

        if (!isArrayExists(ids)) {
            triggerErrorAlert("No certificates to print.");
            return;
        }

        // combine all the ids into strings
        const idsStr = ids.join("_|_");
        const url = (DEV_MODE ? "http://localhost:3000/" : PLATFORM_URL) + "certificates?ids=" + idsStr;
        window.open(url, "_blank");

        // close the modal
        if (onClose) onClose();
    };

    handleFormUpdate = (newValue, key, subkey, subVal) => {
        const { modalData } = this.state;
        let newData = modalData ? cloneCollections(modalData) : {};
        switch (key) {
            case "action":
                newData[key] = newValue;
                break;
            default:
                if (subkey && (subkey === "checked" || subkey === "unchecked") && subVal && !_isEmpty(subVal)) {
                    // use subVal as the new value
                    newData[key] = subVal;
                } else {
                    // otherwise - just update normally
                    newData[key] = newValue;
                }
                break;
        } // end - key

        this.setState({ modalData: newData });
    };

    getSelectedTrainees = () => {
        const { trainees, selected } = this.props;
        return trainees && isArrayExists(trainees)
            ? _filter(trainees, (t) => {
                  return t && t.id && _find(selected, { id: t.id }) ? true : false;
              })
            : [];
    };

    getCourseOptions = () => {
        const { courseColumns } = this.props;
        let options = [];
        if (courseColumns && isArrayExists(courseColumns)) {
            _forEach(courseColumns, (course) => {
                options.push({
                    value: _toString(course.id),
                    label: (course.course_code && !_isEmpty(course.course_code) ? "[" + course.course_code + "] " : "") + (course.name || ""),
                });
            });
        } // end - schedule.course_access
        return [..._sortBy(options, [(o) => o.label.toLowerCase()])];
    };

    getInitialModalData = () => {
        const options = this.getCourseOptions();
        return {
            lms_course_id: options && options.length === 1 ? options[0].value : "",
        };
    };

    renderModalForm = () => {
        const { modalData } = this.state;
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormRadio
                            label=""
                            name="lms_course_id"
                            value={(modalData && modalData.lms_course_id) || ""}
                            options={this.getCourseOptions()}
                            onChange={this.handleFormUpdate}
                        />
                    </Grid>
                </Grid>
            </>
        );
    };

    render() {
        const { open } = this.props;
        const { modalData } = this.state;
        return (
            <>
                <ModalView
                    open={open}
                    title={"Select a Course to Bulk Print Certificates"}
                    onClose={this.handleClose}
                    disableBackdrop={true}
                    noAction={modalData && modalData.lms_course_id && !_isEmpty(modalData.lms_course_id) ? false : true}
                    actionLabel="Print"
                    maxWidth="sm"
                    doAction={this.handlePrint}
                    contents={this.renderModalForm()}
                />
            </>
        );
    }
}

export default compose(connect(), withRouter)(BulkPrintCerts);
