/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';

import AppWrapper from '../../components/AppWrapper';

import { isAdminType } from '../../helpers/auth';

import { PLATFORM_NAME } from '../../constants';

import theme from '../../theme';

import { CANVAS_LMS_URL } from '../../constants';

const DashboardIcon = styled.a`
    display: block;
    margin: 10px;
    text-align: center;
    padding: 90px 30px;
    color: #212121;
    font-weight: 700;
    border: 4px dashed ${theme.palette.background};

    i {
        display: block;
        font-size: 60px;
        margin-bottom: 10px;
    }

    &:hover {
        background: ${theme.palette.primary.main};
        border: 4px solid ${theme.palette.primary.main};
        color: #fff;
    }
`;

class StudentDashboardPage extends React.Component {

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    handleLinkClick = (url) => (event) => {
        const { history, changesNotSaved } = this.props;
        event.preventDefault();

        // if changes not saved - trigger warning        
        if ( changesNotSaved ) {
            var answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?");
            // reset status if answer is yes
            if ( answer ) {
                this.props.dispatch(appChangesReset());
                history.push(url);
            }
        } else {
            history.push(url);
        }
    }

    renderContents() {
        const { authData } = this.props;
        return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">How Can I Help You Today?</Typography>
                </Grid>
                <Grid item xs={12} xs={4}>
                    <DashboardIcon href={CANVAS_LMS_URL} target="_blank">
                        <ImportContactsIcon style={{ fontSize: 65 }} />
                        <div>Login to LMS</div>
                    </DashboardIcon>
                </Grid>
                <Grid item xs={12} xs={4}>
                    <DashboardIcon href={'/student/badges'} onClick={this.handleLinkClick('/student/badges')}>
                        <i className="fa fa-shield"></i>
                        My Backpack
                    </DashboardIcon>
                </Grid>
                <Grid item xs={12} xs={4}>
                    <DashboardIcon href={'/student/teams'} onClick={this.handleLinkClick('/student/teams')}>
                        <i className="fa fa-users"></i>
                        My Team Profile
                    </DashboardIcon>
                </Grid>
                <Grid item xs={12} xs={4}>
                    <DashboardIcon href={( isAdminType(authData) ? '/auth/profile' : '/student/profile' )} onClick={this.handleLinkClick(( isAdminType(authData) ? '/auth/profile' : '/student/profile' ))}>
                        <i className="fa fa-address-book-o"></i>
                        Edit Profile
                    </DashboardIcon>
                </Grid>
            </Grid>

        </div>
        );
    }

    render() {
        return <AppWrapper 
                title={"Welcome to Chumbaka World"}
                maxWidth="1110px"
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        changesNotSaved: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(StudentDashboardPage);