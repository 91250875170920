/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _isEqual from 'lodash/isEqual';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader'
import SnackBarSave from '../../components/SnackBarSave';

import EditForm from './form';
import ItemNotFound from '../../components/ItemNotFound';

import { InfoButton, GreyButton } from '../../styles/button';

import { hasAccessRights, getUserRegionFilter } from '../../helpers/auth'; 
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { getPartner, editPartner } from '../../actions/partners';
import { getProgramsOptions } from '../../actions/programs';
import { getMaintenance } from '../../actions/maintenance';
import { appChangesMade, resetRedux } from '../../actions/misc';

import { partnerSchema } from '../../schemas/partner';

class PartnerPage extends React.Component {

    state = {
        currentTab: 'details',
        partner: false,
        randNum: false
    };

    componentDidMount() {
        const { authData, designationList, currencyList, courseTypeList, courseDurationList, programsList } = this.props;
        const { partner_id } = this.props.match.params;
        
        // retrieve partner details
        this.props.dispatch(getPartner(partner_id,{ authData }));

        // get programs
        if ( !programsList )
            this.props.dispatch(getProgramsOptions({ region: getUserRegionFilter(authData) }));

        // get designation
        if ( !designationList )
            this.props.dispatch(getMaintenance('designation'));

        // get currency
        if ( !currencyList )
            this.props.dispatch(getMaintenance('currency'));

        // get course type
        if ( !courseTypeList )
            this.props.dispatch(getMaintenance('course_type'));

        // get course duration
        if ( !courseDurationList )
            this.props.dispatch(getMaintenance('course_duration'));

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentPartner, randNum, 
            designationList, currencyList, courseTypeList, courseDurationList, programsList 
            } = this.props;

        // for partner
        if ( currentPartner && courseTypeList && courseDurationList && programsList && designationList && currencyList && randNum && randNum !== this.state.randNum ) {
            this.setState({ partner: cloneCollections( currentPartner ), randNum });
        } // end - currentPartner
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('partner'));
    }

    handleRefresh = (type) => {
        // const { partner_id } = this.props.match.params;
        // switch( type ) {
        //     case 'emails':
        //         this.setState({ emails: false });
        //         this.props.dispatch(getEmailsBy('partners',partner_id));
        //         break;
        // }
    }

    handleSaveChanges = (event) => {
        const { partner } = this.state;
        event.preventDefault();
        var error = false,
            formData = {};

        // do error check
        partnerSchema.forEach(schema => {
            // populate finance fields
            if ( _startsWith( schema.id, 'finance_' ) ) {
                const secondaryID = _replace( schema.id, 'finance_', '' );
                formData[schema.id] = ( partner.finance && partner.finance[secondaryID] && !_isEmpty( partner.finance[secondaryID] ) ? cloneCollections( partner.finance[secondaryID] ) : schema.default );
            } else {
                formData[schema.id] = ( partner && partner[schema.id] ? cloneCollections( partner[schema.id] ) : schema.default );
            } // end - _startsWith

            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editPartner(formData));
        } // end - error

    }

    handleFormUpdate = (newValue) => {
        this.setState({ partner: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    handleTabChange = (newTab) => {
        const { partner_id } = this.props.match.params;
        const { history } = this.props;
        if ( 'personnel' === newTab ) {
            history.push("/partner/"+partner_id+"/personnel");
        } else {
            this.setState({ currentTab: newTab });
        }
    }

    // getLinks = () => {
    //     const { authData } = this.props;
    //     let links = [
    //         { id: 'details', label: 'Licensee Info', icon: 'fa fa-cog' },
    //         { id: 'personnel', label: 'Personnel Database', icon: 'fa fa-users' }
    //     ];

    //     return links;
    // }

    renderTabContents = () => {
        const { partner_id } = this.props.match.params;
        const { authData, currentPartner, designationList, currencyList, courseTypeList, courseDurationList, programsList, changesMade } = this.props;
        const { currentTab, partner } = this.state;
        const tabProps = {
            authData: ( authData || false ),
            partner: ( partner || false ),
            changesMade: ( changesMade || false ),
            onFormUpdate: this.handleFormUpdate
        };
        switch( currentTab ) {
            case 'details':
                return <EditForm 
                    currentPartner={( currentPartner || false )}
                    designations={( designationList || false )}
                    currency={( currencyList || false )}
                    programs={( programsList || false )}
                    course_type={( courseTypeList || false )}
                    course_duration={( courseDurationList || false )}
                    updateActions={[getPartner(partner_id,{ authData })]} 
                    {...tabProps} />;
            // case 'emails': 
            //     return ( emails ? <EmailsList
            //         type="partners"
            //         typeID={( partner_id || false )}
            //         changesMade={changesMade}
            //         onRefresh={this.handleRefresh}
            //         {...tabProps} /> : <DotsLoader /> ); 
        }
    }

    // renderTabs = () => {
    //     const { currentTab } = this.state;
    //     const btnStyle = {
    //         textAlign: 'center',
    //         minWidth: '225px',
    //         padding: "10px 5px",
    //         marginRight: "5px"
    //     };
    //     return (
    //     <Box display="flex" justifyContent="center" alignItems="center">
    //         {this.getLinks().map((button,index) => {
    //             if ( button.id === currentTab) {
    //                 return <InfoButton key={index} style={btnStyle} onClick={event => event.preventDefault()}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</InfoButton>
    //             } else {
    //                 return <GreyButton key={index} style={btnStyle} onClick={this.handleTabChange.bind(this,button.id)}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</GreyButton>
    //             }
    //         })}
    //     </Box>
    //     )
    // }

    renderContents() {
        return (
        <div>

            {/* {this.renderTabs()} */}

            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                {this.renderTabContents()}
            </Paper>

            <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
            </div>

            <SnackBarSave onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { partner_id } = this.props.match.params;
        const { randNum } = this.state;
        const { currentPartner } = this.props;
        return <AppWrapper 
                title="Edit Partner"
                subtitle="Partner"
                back="/partner"
                breadcrumbs={[
                    { url: '/partner', label: 'Partner' },
                    { label: 'Edit' }
                ]}
                buttons={[
                    { url: '/partner/'+partner_id, label: 'Partner Info', icon: 'fa fa-cog', active: true },
                    { url: '/partner/'+partner_id+'/personnel', label: 'Personnel Database', icon: 'fa fa-users' }
                ]}
                maxWidth="1110px"
                onLoad={( !( randNum ) ? true : false )}
                contents={ currentPartner && currentPartner.id && !_isEmpty( currentPartner.id ) ? this.renderContents() : <ItemNotFound item="Partner" />} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        currentPartner: state.partners && state.partners.partner || null,
        randNum: state.partners && state.partners.rand || null,
        programsList: state.programs && state.programs.programs_options ? state.programs.programs_options : null,
        designationList: state.maintenance && state.maintenance.designations || null,
        currencyList: state.maintenance && state.maintenance.currency || null,
        courseTypeList: state.maintenance && state.maintenance.course_type ? state.maintenance.course_type : null,
        courseDurationList: state.maintenance && state.maintenance.course_duration ? state.maintenance.course_duration : null,
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(PartnerPage);