/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	STUDENT_SHOWCASE_GET_DATES,
	STUDENT_SHOWCASE_UPDATE_DATE,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getStudentShowcaseDates, fb_updateStudentShowcaseDate } from './fb';

export const getStudentShowcaseDates = (schedule_id,lms_course_id) => {
	return dispatch => {

		fb_getStudentShowcaseDates(schedule_id,lms_course_id)
		.then(showcase => {
			dispatch({
				type: STUDENT_SHOWCASE_GET_DATES,
				payload: { showcase }
			});	
		})
		.catch(error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: STUDENT_SHOWCASE_GET_DATES,
				payload: { showcase: {} }
			});
		});

	}
}


export const updateStudentShowcaseDate = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateStudentShowcaseDate(formData)
		.then( results => {
			fb_getStudentShowcaseDates(formData.schedule_id,formData.lms_course_id)
			.then(showcase => {
				// push new data
				dispatch({ type: STUDENT_SHOWCASE_GET_DATES, payload: { showcase } });	
				// updated
				dispatch({ type: STUDENT_SHOWCASE_UPDATE_DATE });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Showcase Date Updated");
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}