/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';

import PreviewForm from '../StudentShowcaseFeedback/form';

import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormRadio from '../../components/FormRadio';
import FormMultiOptions from '../../components/FormMultiOptions';

import { FormBox } from '../../styles/form';
import { InfoButton } from '../../styles/button';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';

import { partnerSchema } from '../../schemas/partner';

import { TEMPLATE_OPTIONS_TYPE, YES_NO_OPTIONS } from '../../constants';

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

class EditFeedbackForm extends React.Component {

    state = {
        openPreview: false,
        randNum: false
    };

    handleFormUpdate = ( newValue, key ) => {
        const { onFormUpdate, feedback_form } = this.props;
        let newData = ( feedback_form && !_isEmpty( feedback_form ) ? cloneCollections( feedback_form ) : {} );

        switch( key ) {
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    handleFilterUpdated = ({ formData, newValue, key, item, cell, subkey, subVal}) => {
        switch ( key ) {
            case 'type':
                formData[ key ] = ( newValue || '' );
                if ( !( formData[ 'required' ] && !_isEmpty( formData[ 'required' ] ) ) ) {
                    formData[ 'required' ] = 'no';
                }
                if ( 'checkboxes' === newValue ) {
                    formData[ 'options' ] = 'custom_options';
                }
                break;
            default:
                formData[ key ] = ( newValue || '' );
                break;
        }
        return formData;
    }

    renderFieldValue = key => item => {
        switch (key) {
            case 'options':
                let opt = '';
                if ( item.options && item.options === 'template_agree_options' ) {
                    opt = 'Strongly Agree, Agree, Neutral, Disagree, Strongly Disagree';
                } else if ( item.options && item.options === 'template_high_options' ) {
                    opt = 'Very High, High, Medium, Low, Very Low';
                } else if ( item.options && item.options === 'template_good_options' ) {
                    opt = 'Excellent, Good, Average, Poor, Very poor';
                } else if ( item.options && item.options === '' || item.options && item.options === 'custom_options' ) {
                    opt = ( item.options_custom && !_isEmpty( item.options_custom ) ? item.options_custom : '' );
                }
                return opt;
            case 'required':
                return ( item.type && item.type === 'section_title' ? '---' : getSelectedValue( YES_NO_OPTIONS, item[key], 'value', 'label' ) );
            case 'type':
                return getSelectedValue( TEMPLATE_OPTIONS_TYPE, item[key], 'value', 'label' );
            default:
                return ( item && item[key] ? item[key] : '' );
        }
    }

    render = () => {
        const { classes, feedback_form } = this.props;
        const { openPreview } = this.state;
        return (
        <>
            <FormBox>
                <Grid container spacing={3}>

                    <Grid item xs={12} align="right">
                        <InfoButton style={{ padding: '5px 15px' }} onClick={() => this.setState({ openPreview: true })}>Preview</InfoButton>
                    </Grid>

                    <Grid item xs={12}>
                        <FormRadio label="Status *" name="status" inline={true} value={( feedback_form.status || '' )} options={[
                            { value: 'active', label: 'Active' },
                            { value: 'disabled', label: 'Disabled' }
                        ]} onChange={this.handleFormUpdate} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormInput label="Label *" name="label" value={( feedback_form.label || '' )} onChange={this.handleFormUpdate} />
                    </Grid>
                    <Grid item xs={12}> 
                        <FormMultiOptions
                            name="fields"
                            label="Field(s)"
                            value={( feedback_form.fields && isArrayExists( feedback_form.fields ) ? _sortBy( feedback_form.fields, ['position'] ) : [] )}
                            moveable={true}
                            actionCellWidth="15%"
                            cells={[
                                { 
                                    id: 'question', 
                                    label: 'Question', 
                                    render: this.renderFieldValue('question'), 
                                    field: 'text', 
                                    required: true, 
                                    column: 12,
                                    onValueUpdated: this.handleFilterUpdated 
                                },
                                { 
                                    id: 'desc', 
                                    label: 'Description', 
                                    render: this.renderFieldValue('desc'), 
                                    field: 'textarea', 
                                    required: false, 
                                    column: 12,
                                    onValueUpdated: this.handleFilterUpdated 
                                },
                                { 
                                    id: 'type', 
                                    label: 'Type', 
                                    render: this.renderFieldValue('type'), 
                                    field: 'select', 
                                    required: true, 
                                    column: 6,
                                    options: getSelectOptions({ list: TEMPLATE_OPTIONS_TYPE, options: [{ value: '', label: 'Select an Option' }], keys: { value: 'value', label: 'label', sortBy: 'label' }}),
                                    onValueUpdated: this.handleFilterUpdated 
                                },
                                { 
                                    id: 'required', 
                                    label: 'Required', 
                                    render: this.renderFieldValue('required'), 
                                    field: 'select', 
                                    isRequired: ({ item,cell }) => {
                                        return ( item && item.type && ( item.type === 'input' || item.type === 'textarea' || item.type === 'radio' || item.type === 'select' || item.type === 'checkboxes' ) ? true : false )
                                    }, 
                                    showOnly: [
                                        { type: 'type', values: ['input','textarea','select','radio','checkboxes'] }
                                    ],
                                    column: 6,
                                    options: getSelectOptions({ list: YES_NO_OPTIONS, options: [{ value: '', label: 'Select an Option' }], keys: { value: 'value', label: 'label', sortBy: 'label' }}),
                                    onValueUpdated: this.handleFilterUpdated 
                                },
                                { 
                                    id: 'options', 
                                    label: 'Options', 
                                    render: this.renderFieldValue('options'), 
                                    field: 'select', 
                                    isRequired: ({ item,cell }) => ( item && item.type && ( item.type === 'radio' || item.type === 'select' || item.type === 'checkboxes' ) ? true : false ), 
                                    showOnly: [
                                        { type: 'type', values: ['select','radio'] }
                                    ],
                                    column: 12,
                                    options: [
                                        { value: '', label: 'Select an Option' },
                                        { value: 'template_agree_options', label: 'Strongly Agree, Agree, Neutral, Disagree, Strongly Disagree' },
                                        { value: 'template_high_options', label: 'Very High, High, Medium, Low, Very Low' },
                                        { value: 'template_good_options', label: 'Excellent, Good, Average, Poor, Very poor' },
                                        { value: 'custom_options', label: 'Custom Options' },
                                    ],
                                    onValueUpdated: this.handleFilterUpdated 
                                },
                                { 
                                    id: 'options_custom', 
                                    label: 'Fill in the custom options for this fields separated by comma: (Ex: option 1,option 2,option 3)', 
                                    render: false, 
                                    field: 'text', 
                                    hideCell: true,
                                    isRequired: ({ item,cell }) => ( item && item.options && ( item.options === 'custom_options' ) ? true : false ), 
                                    showOnly: [
                                        { type: 'options', values: ['custom_options'] }
                                    ],
                                    column: 12,
                                    onValueUpdated: this.handleFilterUpdated 
                                },
                                { 
                                    id: 'max_answers', 
                                    label: 'Max Choices (leave it empty for unlimited)', 
                                    render: false, 
                                    field: 'text', 
                                    hideCell: true,
                                    isRequired: ({ item,cell }) => false, 
                                    showOnly: [
                                        { type: 'type', values: ['checkboxes'] },
                                        { type: 'options', values: ['custom_options'] }
                                    ],
                                    column: 12,
                                    onValueUpdated: this.handleFilterUpdated 
                                },
                            ]}
                            onChange={this.handleFormUpdate} />
                    </Grid>
                    
                </Grid>
            </FormBox>

            <ModalView
                open={openPreview}
                title={"Preview Mode"}
                onClose={() => this.setState({ openPreview: false })}
                disableBackdrop={true}
                noAction={true}
                cancelLabel="Close"
                maxWidth="md"
                contents={(
                <>
                    <PreviewForm
                        feedback_form={feedback_form}
                        />
                </>
                )} />

        </>
        );
    }

}

export default compose(
    withStyles(useStyles),
    withRouter
)(EditFeedbackForm);