/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
// import _merge from 'lodash/merge';
// import _size from 'lodash/size';
// import _isEqual from 'lodash/isEqual';

import AppWrapper from '../../components/AppWrapper';
import ScheduleSwitcher from '../../components/ScheduleSwitcher';
import Results from './results';
import Search from './search';

import { isAdmin, getUserRegionFilter } from '../../helpers/auth';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections } from '../../helpers/data';

import { fb_searchStudentData } from '../../actions/database_students/fb';
// import { getCoursesOptions } from '../../actions/courses';
import { getLMSCourses } from '../../actions/canvas_lms';
import { getSchedulesOptions } from '../../actions/schedules';
import { getRegions } from '../../actions/m_regions';
// import { getMaintenance } from '../../actions/maintenance';
import { toggleLoader } from '../../actions/global';
import { getAPIErrorMessage } from '../../helpers/action';
import { resetRedux } from '../../actions/misc';

import { ButtonGroup, AInfoLink } from '../../styles/button';

const TableWrapper = styled.div`
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
`;

class DatabaseStudentsSearch extends React.Component {

    state = {
        student: false,
        dataLoaded: false
    };

    componentDidMount() {
        const { authData, regionsList, schedulesList, lmsCoursesList } = this.props;

        // get regions
        if ( !regionsList )
            this.props.dispatch(getRegions());

        // get schedule options
        if ( !schedulesList )
            this.props.dispatch(getSchedulesOptions({ region: getUserRegionFilter(authData) }));

        // get lms courses
        if ( !lmsCoursesList )
            this.props.dispatch(getLMSCourses());

        if ( regionsList && schedulesList && lmsCoursesList ) {
            this.setState({ dataLoaded: true });
        } // end - randNum
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { regionsList, schedulesList, lmsCoursesList } = this.props;
        if ( regionsList && schedulesList && lmsCoursesList && !this.state.dataLoaded ) {
            this.setState({ dataLoaded: true });
        } // end - randNum
    }

    componentWillUnmount() {
        
    }

    handleFilter = ({ email }) => {
        this.setState({ student: false });
        this.props.dispatch(toggleLoader(true));
        fb_searchStudentData({ email })
        .then( student => {
            this.props.dispatch(toggleLoader(false));
            this.setState({ student });
        })
        .catch(error => {
            this.props.dispatch(toggleLoader(false));
            triggerErrorAlert( getAPIErrorMessage(error), 4000 );
        });
    }

    renderResults() {
        const { authData, regionsList, schedulesList, lmsCoursesList } = this.props;
        const { student } = this.state;
        return <Results
                student={student}
                schedules={schedulesList}
                regions={regionsList}
                lms_courses={lmsCoursesList}
                authData={authData} />;
    }

    renderContents() {
        const { student } = this.state;
        return (
        <>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Search 
                        onFilter={this.handleFilter} />
                </Grid>
            </Grid>

            { student ? this.renderResults() : null }

            {/* { student ? <TableWrapper>{this.renderTable()}</TableWrapper> : null } */}


        </>
        );
    }

    render() {
        const { dataLoaded } = this.state;
        const { authData } = this.props;
        return <AppWrapper 
                title="Search Student"
                subtitle="3. Delivery"
                onLoad={( !( dataLoaded ) ? true : false )}
                contents={ authData ? this.renderContents() : null } />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        schedulesList: state.schedules && state.schedules.schedules_options ? state.schedules.schedules_options : false,
        lmsCoursesList: state.canvas_lms && state.canvas_lms.courses ? state.canvas_lms.courses : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(DatabaseStudentsSearch);