/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import Papa from 'papaparse';
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';

import DotsLoader from '../../components/DotsLoader';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
// import FormSelect from '../../components/FormSelect';
// import FormRadio from '../../components/FormRadio';
// import FormDatePicker from '../../components/FormDatePicker';
// import FormCheckbox from '../../components/FormCheckbox';
// import FormCheckboxes from '../../components/FormCheckboxes';
import ToolTipInfo from '../../components/ToolTipInfo';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions, getSelectedValue } from '../../helpers/data';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getMomentTime } from '../../helpers/date';
import { stringToInteger } from '../../helpers/number';
// import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';

import { fb_searchStudentData } from '../../actions/database_students/fb';
import { toggleLoader } from '../../actions/global';

import { InfoButton } from '../../styles/button';
import { WarningBox } from '../../styles/message';

import { ENROLLMENT_STATUS_OPTIONS, DEV_MODE, PLATFORM_BACKPACK_URL } from '../../constants';

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
    border: 2px solid #212121;
`;

class StudentBackpackTransfer extends React.Component {

    utcOffSet = 8;
    
    state = {
        open: false,
        modalData: false,
        loading: false,
        confirmation: false
    };

    handleConfirmation = () => {
        const { student, onTrigger } = this.props;
        const { modalData } = this.state;

        const formData = {
            target_email: modalData.target_account.email,
            region: student.region,
            email: student.email,
            uid: student.uid
        };

        this.setState({ open: false, modalData: false, confirmation: false })
        onTrigger(formData);
    }

    handleUpdate = () => {
        const { modalData } = this.state;
        let error = false;
        // make sure a class is selected
        if ( !( modalData && modalData.email && !_isEmpty( modalData.email ) ) )
            error = "Please enter the email address of the account you want to transfer the backpack to.";

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.setState({ loading: true });
            fb_searchStudentData({ email: modalData.email })
            .then((data) => {
                if ( data && data.name && data.email && data.uid && data.role === 'student' ) {
                    this.setState({
                        loading: false,
                        open: false,
                        modalData: {
                            ...modalData,
                            target_account: data
                        },
                        confirmation: true
                    });
                } else {
                    triggerErrorAlert("No student account found with that email address.");
                    this.setState({ loading: false });
                }
            })
            .catch((error) => {
                triggerErrorAlert("No student account found with that email address.");
                this.setState({ loading: false });
            });
        }
            
    }

    handleFormUpdate = (newValue,key,subkey,subVal) => {
        const { modalData } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} );
        switch( key ) {
            default:
                if ( subkey && ( subkey === 'checked' || subkey === 'unchecked' ) && subVal && !_isEmpty( subVal ) ) {
                    // use subVal as the new value
                    newData[key] = subVal;
                } else {
                    // otherwise - just update normally
                    newData[key] = newValue
                }
                break;
        } // end - key
        
        this.setState({ modalData: newData })
    }

    handleOpen = (event) => {
        const { changesMade } = this.props;
        event.preventDefault();
        if ( changesMade ) {
            triggerErrorAlert("Please save your changes before proceeding.");
        } else {
            this.setState({ 
                open: true, 
                modalData: {
                    email: '',
                    target_account: false 
                } 
            });
        }

    }

    getNewAccount = () => {
        const { modalData } = this.state;
        return ( modalData && modalData.target_account ? `${modalData.target_account && modalData.target_account.name || '' } (${modalData.target_account && modalData.target_account.email || ''})` : "No name" );
    }



    renderForm = () => {
        const { student, schedules } = this.props;
        const { modalData, loading } = this.state;
        return (
        <div>
            <div style={{ padding: '10px 8px 15px 8px' }}>
                <Typography variant="h6" style={{ marginBottom: '0px', fontWeight: '700' }}>Enter the email address of the account you want to transfer the backpack to</Typography>
                <FormInput
                    label="Email address"
                    value={modalData.email || ''}
                    onChange={(newValue) => this.handleFormUpdate(newValue,'email')}
                    // placeholder="Email address"
                    />
            </div>
        </div>
        );
    }

    render() {
        const { student } = this.props;
        const { open, confirmation } = this.state;
        return (
        <>

            <div style={{ padding: '5px 10px' }}>
                <InfoButton disabled={( student && student.role && ( student.role === 'student' || student.role === 'mentor' ) ? null : 'yes' )} size="small" style={{ padding: '4px 6px' }} onClick={this.handleOpen}><i className="fa fa-random"></i>Transfer Backpack</InfoButton>
            </div>

            <ModalView 
                open={open}
                title={(
                <>  
                    {`Transfer "${student && student.name || 'Student'}" Backpack to another account`}
                </>
                )}
                onClose={() => this.setState({ open: false, modalData: false })}
                disableBackdrop={true}
                processing={this.state.loading}
                actionLabel="Transfer"
                maxWidth="sm"
                doAction={this.handleUpdate}
                contents={this.renderForm()} />

            <ModalView 
                open={confirmation}
                title={`Please confirm your action`}
                onClose={() => this.setState({ open: false, modalData: false, confirmation: false })}
                disableBackdrop={true}
                actionLabel="Confirm"
                maxWidth="sm"
                doAction={this.handleConfirmation}
                contents={(
                <>
                    <Typography variant="h5" style={{ marginBottom: '15px' }}>{`You're about to transfer the backpack of "${student && student.name || ''} (${student && student.email || ''})" to "${this.getNewAccount()}"`}</Typography>
                    <Typography variant="body1">{`This action cannot be reversed.`}</Typography>
                </>
                )} />

        </>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(StudentBackpackTransfer);