import React from 'react';
import ReactDOM from 'react-dom';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";

import './reset.css';
import './styles.css';
import App from './app';
import * as serviceWorker from './serviceWorker';

import { FIREBASE_CONFIG_DATA } from './firebase';

// init fb
firebase.initializeApp(FIREBASE_CONFIG_DATA);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
