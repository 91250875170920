/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';
import _toString from 'lodash/toString';

import ModalView from '../../components/ModalView';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { isBadgeIssuedFromTransferedClass, getTransferTraineeShowcaseData, convertTranferedTraineeShowcaseData } from '../../helpers/showcase';


// import { PLATFORM_COURSE_REGISTRATION_LINK } from '../../constants';

const FieldWrapper = styled.div`
    margin-bottom: 10px;

    h4 {
        color: #212121;
        font-size: 20px;
        font-weight: 700;
        &:after { display: block; height: 2px; width: 50px; content: " "; background: #212121; }
    }

    h5 {
        color: #212121;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 4px;
        text-transform: none;
    }
`;

class FeedbackFormAnswers extends React.Component {

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose )
            onClose();
    }

    getAnswer = (field,answers) => {
        let selectedAnswer = ( answers && field && field.id && !_isEmpty( field.id ) ? _find( answers, { id: field.id } ) : false );
        return ( selectedAnswer && selectedAnswer.value ? selectedAnswer.value : '---' );
    }

    getFeedbackFormAnswers = () => {
        const { trainee, course_id, showcase } = this.props;
        const isTransferredBadge = isBadgeIssuedFromTransferedClass({ trainee, lms_course_id: course_id });
        if ( isTransferredBadge ) {
            const transferShowcaseData = getTransferTraineeShowcaseData({ trainee, lms_course_id: course_id });
            if ( transferShowcaseData ) {
                const showcaseData = convertTranferedTraineeShowcaseData( transferShowcaseData );
                return ( showcaseData && showcaseData.feedback_form_answers && isArrayExists( showcaseData.feedback_form_answers ) ? showcaseData.feedback_form_answers : [] );
            }
        }
        let selectedTrainee = ( showcase && showcase.trainees && isArrayExists( showcase.trainees ) && trainee && trainee.id && !_isEmpty( trainee.id ) ? _find( showcase.trainees, { id: trainee.id } ) : false ),
            selectedCourse = ( selectedTrainee && selectedTrainee.courses && isArrayExists( selectedTrainee.courses ) && course_id && !_isEmpty( course_id ) ? _find( selectedTrainee.courses, c => _toString(c.id) === _toString(course_id) ) : false );
        return ( selectedCourse && selectedCourse.feedback_form_answers && isArrayExists( selectedCourse.feedback_form_answers ) ? selectedCourse.feedback_form_answers : [] );
    }

    getFeedbackFormFields = () => {
        const { trainee, course_id, showcase, feedback_forms_fields, courses } = this.props;
        let selectedCourseProfile = ( courses && isArrayExists( courses ) ? _find( courses, c => _toString( c.lms_course_id ) === _toString( course_id ) ) : false ),
            feedback_form = ( selectedCourseProfile && selectedCourseProfile.feedback_form_id && !_isEmpty( selectedCourseProfile.feedback_form_id ) && feedback_forms_fields && isArrayExists( feedback_forms_fields ) ? _find( feedback_forms_fields, { id: selectedCourseProfile.feedback_form_id }) : false );
        return ( feedback_form && feedback_form.fields && isArrayExists( feedback_form.fields ) ? feedback_form.fields : {} );
    }   

    renderField = (answers) => (field) => {
        return (
        <FieldWrapper key={field.id}>
            { field.type && field.type === 'section_title' ? <Typography variant="h4">{( field.question || '' )}</Typography> : (
            <>
                <Typography variant="h5">{( field.question || '' )}</Typography>
                <Typography variant="body1">{this.getAnswer(field,answers)}</Typography>
            </>
            )}
        </FieldWrapper>
        )
    }

    renderContent = () => {
        const { trainee, showcase } = this.props;
        const answers = this.getFeedbackFormAnswers();
        const fields = this.getFeedbackFormFields();
        return (
        <div>
            { fields && isArrayExists( fields ) ? _sortBy( fields, [f => parseInt( f.position, 10 )] ).map(this.renderField(answers)) : (
            <div style={{ padding: '20px' }}>
                <Typography variant="h5" align="center">No valid form found.</Typography>
            </div>
            ) }
        </div>
        )
    }


    render() {
        const { open } = this.props;
        return (
        <>

            <ModalView 
                open={open}
                title="Feedback Form"
                maxWidth="md"
                onClose={this.handleClose}
                noAction={true}
                cancelLabel="Close"
                contents={this.renderContent()} />

        </>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(FeedbackFormAnswers);