/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _snakeCase from 'lodash/snakeCase';
import _find from 'lodash/find';

import { doPromise, callFunctionsAPI } from '../../helpers/action';
import { isArrayExists, validateEmail, isObjectExists } from '../../helpers/validation';
import { getSnapshotDataBySchema, getDocDataBySchema } from '../../helpers/schemas';

import { userPartnerSchema } from '../../schemas/user_partner';

// get user data via onAuth
export const onAuth = (userToken) => {
    return new Promise((resolve, reject) => {
        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'auth',
                params: [{ key: 'action', value: 'get' }]
            })
            .then(res => {
                var user = res.data;
                // user['photoURL'] = ( userToken && userToken.photoURL ? userToken.photoURL : '' );
                resolve(user);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

// register user upon sign-up
export const onAccountCreated = (result) => {
    return new Promise((resolve, reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'auth',
                params: [{ key: 'action', value: 'post' }],
                formData: {
                    uid: ( result && result.user && result.user.uid || '' ),
                    email: ( result && result.user && result.user.email || '' )
                }
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

// get current user
export const getCurrentUser = () => {
    return firebase.auth().currentUser;
}

// get current user ID token
export const getIDToken = function(authHeader) {
    return new Promise((resolve,reject) => {

        firebase.auth().currentUser.getIdToken(true)
        .then(function(idToken) {
            resolve(( authHeader ? 'Bearer ' + idToken : idToken ));
        })
        .catch(function(error) {
            reject(error);
        });
          
    });
}

// perform login
export const logoutUser = (ignoreError) => {
    return new Promise((resolve,reject) => {

        firebase.auth().signOut().then(function() {
            resolve('logged_out');
        }).catch(function(error) {
            if ( ignoreError ) {
                resolve(true);
            } else {
                reject(error);
            }
        });
          
    });
}

// perform google sign-in
export const googleSignIn = () => {
    return new Promise((resolve,reject) => {

        const provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');

        firebase.auth().signInWithPopup(provider).then(function(result) { 
            onAccountCreated(result)
            .then( user => {
                user['photoURL'] = ( result && result.user && result.user.photoURL || '' );
                resolve(user);
            })
            .catch(function(error) {
                reject(error);
            });
        }).catch(function(error) {
            reject(error);
        });
          
    });
}

// perform passwordless signin
export const passwordlessSignIn = () => {
    return new Promise((resolve,reject) => {

        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
            // User opened the link on a different device. To prevent session fixation
            // attacks, ask the user to provide the associated email again. For example:
            email = window.prompt('Please provide your email for confirmation');
        }
        // The client SDK will parse the code from the link for you.
        firebase.auth().signInWithEmailLink(email, window.location.href)
        .then(function(result) {
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn');

            // do account creation
            onAccountCreated(result)
            .then( user => {
                user['photoURL'] = ( result && result.user && result.user.photoURL || '' );
                resolve(user);
            })
            .catch(function(error) {
                reject(error);
            });
        })
        .catch(function(error) {
            reject(error);
        });
          
    });
}

// sign in with custom token
export const customTokenSignIn = (custom_token) => {
    return new Promise((resolve,reject) => {
        firebase.auth().signInWithCustomToken(custom_token)
        .then(function(result) { 
            onAuth(( result && result.user ? result.user : false ))
            .then( user => {
                resolve(user);
            })
            .catch(function(error) {
                reject(error);
            });
        }).catch(function(error) {
            reject(error);
        });
          
    });
}

// get user profile
export const fb_getUserProfile = (uid) => {
    return new Promise((resolve,reject) => {

        let promises = [];
        promises.push( firebase.firestore().collection("users").doc(uid).get() );
        
        Promise.all( promises )
        .then(results => {
            let item = {};
            if ( results && results[0] ) {
                item = getDocDataBySchema( userPartnerSchema, results[0] );
            } // end - results[0]
            resolve(item);
        })
        .catch(error => {
            reject(error);
        });
    })
}

export const fb_updateUserProfile = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'auth', action: 'update_profile', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}