/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import _isEmpty from 'lodash/isEmpty';
import _toLower from 'lodash/toLower';
import _replace from 'lodash/replace';

import ToolTipInfo from '../ToolTipInfo';

import { triggerSuccessAlert } from '../../helpers/alert';

import theme from '../../theme';

class FormInputCopy extends React.Component {

    handleChange = (event) => {
        const { name } = this.props;
        if ( this.props.onChange ) {
            this.props.onChange(event.target.value,name);
        }
    }

    getLabelStyle = () => {
       const { tooltip } = this.props;
       let style = { 
           fontSize: "16px", 
           fontWeight: "700", 
           textTransform: "uppercase", 
           backgroundColor: "#fff", 
           paddingLeft: '8px', 
           paddingRight: '8px' 
        };
        // if tooltip found
        if ( !_isEmpty( tooltip ) ) {
            style['pointerEvents'] = 'auto';
        }
        return style;
    }

    renderTooltipInfo = () => {
        const { tooltip } = this.props;
        return <ToolTipInfo content={( tooltip || false )} />
    }

    renderLabel = () => {
        const { label, tooltip } = this.props;
        var newLabel = false;
        if ( label && !_isEmpty( label ) && ( 
            ( _toLower( label ).indexOf("(required)") >= 0 ) || 
            ( _toLower( label ).indexOf("*") >= 0 )
         ) ) {
            newLabel = _replace( label, '(Required)', '' );
            newLabel = _replace( newLabel, '(required)', '' );
            newLabel = _replace( newLabel, '*', '' );
        } // end - label
        return (
        <>
            { newLabel ? <span className="field-label">{ newLabel || '' }<span className="field-required required">*</span></span> : ( label || '' ) }
            { tooltip && !_isEmpty( tooltip ) ? this.renderTooltipInfo() : null }
        </>
        );
    }

    render() {
        const { type, label, labelProps, value, multiline, rows, rowsMax } = this.props;
        return (
        <FormControl fullWidth={true}>
            <TextField 
                label={this.renderLabel()} 
                type={( type || 'text' )} 
                disabled={true}
                multiline={( multiline || null )}
                rows={( rows || null )}
                rowsMax={( rowsMax || null )}
                fullWidth={true} 
                InputLabelProps={( labelProps ? labelProps : {
                    shrink: true,
                    style: this.getLabelStyle()
                })}
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="end">
                        <CopyToClipboard text={value}
                            onCopy={() => {
                                triggerSuccessAlert("Value copied to clipboard.");
                            }}>
                            <IconButton><FileCopyIcon /></IconButton>
                        </CopyToClipboard>
                    </InputAdornment>
                    ),
                }}
                margin="normal" 
                variant={"filled"} 
                value={( value || '' )} 
                onChange={this.handleChange} />
        </FormControl>
        )
    }

}

export default FormInputCopy