/* eslint-disable */
import React, { useState } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

import ProgramsTable, { ProductsList } from './programs_table';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import ButtonActions from '../../components/ButtonActions';
import FormSelect2 from '../../components/FormSelect2';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { SuccessTag, GreyTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { isAdmin, hasAccessRights } from '../../helpers/auth';

import { addProgram, deleteProgram } from '../../actions/programs';

import { programSchema } from '../../schemas/program';

const PList = styled.ul`
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 200px;
    li {
        display: inline-block;
        padding: 0;
        margin: 0;
        font-size: 12px;
        &:after { display: inline-block; content: ","; padding-right: 3px; }
        &:last-child:after { display: none; }

        &.show-more-link {
            display: block;
            padding: 4px 0 0 0;
            margin: 0;
            font-size: 12px;
            &:after { display: none; }
        }
    }
`;

class Programs extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        filterBy: 'all',
        filterByType: 'all',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false
    }

    handlePageRefresh = (event) => {
        event.preventDefault();
        if ( this.props.onRefresh )
            this.props.onRefresh();
    }

    handleFormUpdate = (newValue,key,subkey,subVal) => {
        const { course_type, course_duration, price_per_pax } = this.props;
        const { modalData } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} );
        switch( key ) {
            case 'course_type':
                let selectedType = ( course_type && isArrayExists( course_type ) ? _find( course_type, { id: newValue } ) : false );
                newData[key] = newValue;
                newData[key + '_label'] = ( selectedType && selectedType.label ? selectedType.label : '' );
                break;
            case 'course_duration':
                let selectedDurationType = ( course_duration && isArrayExists( course_duration ) ? _find( course_duration, { id: newValue } ) : false );
                newData[key] = newValue;
                newData[key + '_label'] = ( selectedDurationType && selectedDurationType.label ? selectedDurationType.label : '' );
                break;
            default:
                if ( subkey && ( subkey === 'checked' || subkey === 'unchecked' ) && subVal && !_isEmpty( subVal ) ) {
                    // use subVal as the new value
                    newData[key] = subVal;
                } else {
                    // otherwise - just update normally
                    newData[key] = newValue
                }
                break;
        } // end - key
        this.setState({ modalData: newData });
    }

    handleAddNew = () => {
        const { history } = this.props;
        const { modalData } = this.state;
        var error = false;

        if ( !( modalData && modalData.name && !_isEmpty( modalData.name ) ) ) {
            error = 'Please enter a valid program name';
        } // end - modalData.email

        if ( !( modalData && modalData.course_duration && !_isEmpty( modalData.course_duration ) ) ) {
            error = 'Please enter a valid duration';
        } // end - modalData.email

        if ( !( modalData && modalData.course_type && !_isEmpty( modalData.course_type ) ) ) {
            error = 'Please enter a valid delivery type';
        } // end - modalData.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addProgram(modalData,history));
        } // end - error
    }

    handleEdit = (id) => (event) => {
        if ( event && event.preventDefault )
            event.preventDefault();

        this.props.history.push("/programs/"+id);
    }

    handleDelete = () => {
        const { deleteModal } = this.state;
        // perform delete
        this.props.dispatch(deleteProgram(deleteModal.id));
    }

    handleAction = type => {
        const { checked } = this.state;
        // make sure there is an item selected
        if ( isArrayExists( checked ) ) {

        } else {
            triggerErrorAlert("Please select at least one item");
        } // end - checked
    }

    getActions = () => {
        const { authData } = this.props;
        let actions = [];

        if ( isAdmin( authData ) ) {
            actions.push({ 
                id: 'addnew', label: 'Add New Program', icon: 'fa-plus-circle', 
                onClick: () => this.setState({ openViewModal: true, modalData: { 
                    name: '',
                    course_duration: '',
                    course_duration_label: '',
                    course_type: '',
                    course_type_label: ''
                } }) 
            });
        } // end - isAdmin

        return actions;
    }

    getQueryProps = () => {
        const { sortBy, perPage, page } = this.state;
        return false;
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, filterByType, perPage, page } = this.state;
        const { programs } = this.props;
        let items = ( programs ? cloneCollections( programs ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','course_type_label','course_duration_label'] );
			total = _size( items );
        } // end - searchterms

        // do tfilter
        if ( ( filterByType && !_isEmpty( filterByType ) && filterByType !== 'all' ) || ( filterBy && !_isEmpty( filterBy ) && filterBy !== 'all' ) ) {
            items = _filter( items, ( item ) => {
                let valid = false;
                if ( filterByType && !_isEmpty( filterByType ) && filterByType !== 'all' ) {
                    if ( item.course_type === filterByType )
                        valid = true;
                } // end - filterByType
                if ( filterBy && !_isEmpty( filterBy ) && filterBy !== 'all' ) {
                    if ( item.course_duration === filterBy )
                        valid = true;
                } // end - filterBy
                if ( ( filterByType && !_isEmpty( filterByType ) && filterByType !== 'all' ) && ( filterBy && !_isEmpty( filterBy ) && filterBy !== 'all' ) ) {
                    valid = ( item.course_type === filterByType && item.course_duration === filterBy );
                }
                return valid;
            });
            total = _size( items );
        } // end - filterByType

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, (i) => ( i.modified_on && i.modified_on._seconds ? i.modified_on._seconds : i.modified_on ) );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, (i) => ( i.modified_on && i.modified_on._seconds ? i.modified_on._seconds : i.modified_on ) );
                    break;
                case 'course_duration_label-desc':
                    items = _sortBy( items, ['course_duration_label'] );
                    items = _reverse( items );
                    break;
                case 'course_duration_label-asc':
                    items = _sortBy( items, ['course_duration_label'] );
                    break;
                case 'course_type_label-desc':
                    items = _sortBy( items, ['course_type_label'] );
                    items = _reverse( items );
                    break;
                case 'course_type_label-asc':
                    items = _sortBy( items, ['course_type_label'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderCell = type => item => {
        const { products_options } = this.props;
        switch( type ) {
            case 'publish':
                return item.publish && item.publish == 'yes' ? <SuccessTag>Yes</SuccessTag> : <GreyTag>No</GreyTag>;
            case 'email':
                return <WrapWord>{( item[type] || '' )}</WrapWord>;
            case 'products':
                return ( item.products && isArrayExists( item.products ) ? <ProductsList products={item.products} products_options={products_options} /> : [] )
        }
    }

    renderExistingPrograms = () => {
        const { modalData } = this.state;
        const { price_per_pax, programs, products_options } = this.props;
        return <ProgramsTable
            title="Existing Programs"
            filterBy={{
                course_duration: ( modalData && modalData.course_duration || false ),
                course_type: ( modalData && modalData.course_type || false )
            }}
            programs={programs}
            price_per_pax={price_per_pax}
             />;
    }

    renderAddNewForm = () => {
        const { course_type, course_duration } = this.props;
        const { modalData } = this.state;
        return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormSelect label="Delivery Type *" name="course_type" value={( modalData.course_type || '' )} disableNative={true} options={getSelectOptions({ list: ( course_type || false ), options: [], keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' })} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormSelect label="Duration *" name="course_duration" value={( modalData.course_duration || '' )} disableNative={true} options={getSelectOptions({ list: ( course_duration || false ), options: [], keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' })} onChange={this.handleFormUpdate} />
                </Grid>
            </Grid>
            { ( modalData.course_type && !_isEmpty( modalData.course_type ) ) || ( modalData.course_duration && !_isEmpty( modalData.course_duration ) ) ? this.renderExistingPrograms() : null }
            <div style={{ paddingTop: '7px' }}>
                <FormInput label="Program Name *" name="name" value={( modalData.name || '' )} onChange={this.handleFormUpdate} />
            </div>
        </div>
        );
    }

    renderTableActions = () => {
        const { authData, course_type, course_duration } = this.props;
        const { filterByType, filterBy, sortBy, perPage, searchterms, checked } = this.state;
        return <TableBar
                filterBy={filterBy}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Modified first' },
                    { value: 'date-asc', label: 'Oldest Modified first' },
                    { value: 'name-asc', label: 'Program Name ( A - Z)' },
                    { value: 'name-desc', label: 'Program Name ( Z - A )' },
                    { value: 'course_type_label-asc', label: 'Delivery Type ( A - Z)' },
                    { value: 'course_type_label-desc', label: 'Delivery Type ( Z - A )' },
                    { value: 'course_duration_label-asc', label: 'Duration ( A - Z)' },
                    { value: 'course_duration_label-desc', label: 'Duration ( Z - A )' },
                ]}

                // leftButtons={ checked && isArrayExists( checked ) ? [
                //     <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                //         <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                //     </div>
                //     ] : null }
                rightButtons={[
                    // <ButtonActions 
                    //     key="actions" 
                    //     label="Actions"
                    //     menuContainerStyle={{ width: "200px" }}
                    //     style={{ marginRight: "5px" }}
                    //     actions={this.getActions()} />
                    <InfoButton minWidth="168px" key="addnew" style={{ marginRight: "5px" }} onClick={() => this.setState({ openViewModal: true, modalData: { 
                        name: '',
                        course_duration: '',
                        course_duration_label: '',
                        course_type: '',
                        course_type_label: ''
                    } })}><i className="fa fa-plus-circle"></i>Add New</InfoButton>
                    ,
                    // <InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton>
                    <div key="filterbydates" style={{ maxWidth: '300px', width: '100%' }}>
                        <FormSelect2 
                            label="Filter By"
                            placeholder={''}
                            options={getSelectOptions({ list: ( course_type || false ), options: [{ value: 'all', label: 'All Delivery Type' }], keys: { value: 'id', label: 'label' }, sortBy: 'label' })}
                            value={( filterByType || false )}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(newFilterBy) => this.setState({ filterByType: newFilterBy, page: 1 })} />
                    </div>
                ]}
                filterByOptions={getSelectOptions({ list: ( course_duration || false ), options: [{ value: 'all', label: 'All Duration' }], keys: { value: 'id', label: 'label' }, sortBy: 'label' })}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    doneLoaded={true}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { authData, price_per_pax, productMode } = this.props;
        const { openDeleteModal, deleteModal, openViewModal, allCheck, checked } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            { authData && hasAccessRights(authData,['ppw']) ? <ModalView 
                open={openViewModal}
                title="Add New Program"
                maxWidth="md"
                onClose={() => this.setState({ openViewModal: false, modalData: false })}
                doAction={this.handleAddNew}
                contents={this.renderAddNewForm()} /> : null }

            { authData && hasAccessRights(authData,['ppd']) ? <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to delete this program ( ${deleteModal.name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} /> : null }

            { productMode ? null : this.renderTableActions()}
            <Table 
                items={items}
                showCheckbox={false}
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={(newValue) => this.setState({ checked: newValue })}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                cells={ productMode ? [
                    { id: 'course_type_label', label: 'Delivery Type', render: (item) => ( item.course_type_label || '-' ) },
                    { id: 'course_duration_label', label: 'Duration', render: (item) => ( item.course_duration_label || '-' ) },
                    { id: 'name', label: 'Program Name', render: (item) => ( item.name || '' ) },
                    { id: 'price_per_pax', label: 'Price Per Pax', render: (item) => getSelectedValue( price_per_pax, item.price_per_pax_id, 'id', 'label', '-' ) },
                ] : [
                    { id: 'course_type_label', label: 'Delivery Type', render: (item) => ( item.course_type_label || '-' ) },
                    { id: 'course_duration_label', label: 'Duration', render: (item) => ( item.course_duration_label || '-' ) },
                    { id: 'name', label: 'Program Name', render: (item) => ( item.name || '' ) },
                    { id: 'price_per_pax', label: 'Price Per Pax', render: (item) => getSelectedValue( price_per_pax, item.price_per_pax_id, 'id', 'label', '-' ) },
                    { id: 'products', label: 'Courses', render: this.renderCell('products') },
                ]}
                actionStyles={{ width: productMode ? "20%" : "10%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <AInfoLink key="edit" href={"/programs/"+item.id} size="small" onClick={this.handleEdit(item.id)}><i className="fa fa-edit"></i>Edit</AInfoLink>
                        { authData && hasAccessRights( authData, ['ppd'] ) ? <ErrorButton key="delete" size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton> : null }
                    </ButtonGroup>
                )} />
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(Programs);