/* eslint-disable */
import React, { useState } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, Link } from "react-router-dom";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import ButtonActions from '../../components/ButtonActions';
import FormSelect2 from '../../components/FormSelect2';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { SuccessTag, GreyTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { isAdmin, hasAccessRights } from '../../helpers/auth';

import { addProgram, deleteProgram } from '../../actions/programs';

import { programSchema } from '../../schemas/program';

const PList = styled.ul`
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 200px;
    li {
        display: inline-block;
        padding: 0;
        margin: 0;
        font-size: 12px;
        &:after { display: inline-block; content: ","; padding-right: 3px; }
        &:last-child:after { display: none; }

        &.show-more-link {
            display: block;
            padding: 4px 0 0 0;
            margin: 0;
            font-size: 12px;
            &:after { display: none; }
        }
    }
`;

export const ProductsList = ({
    products,
    products_options
}) => {
    const [ show, setShow ] = useState(false);
    let count = 1,
        first5 = [],
        theRest = [];

    const handleClick = (event) => {
        event.preventDefault();
        setShow(!show);
    }

    // for testing only
    // const products = [
    //     { id: '1', course_code: 'CODE1', name: '1' },
    //     { id: '2', course_code: 'CODE2', name: '2' },
    //     { id: '3', course_code: 'CODE3', name: '3' },
    //     { id: '4', course_code: 'CODE4', name: '4' },
    //     { id: '5', course_code: 'CODE5', name: '5' },
    //     { id: '6', course_code: 'CODE6', name: '6' },
    //     { id: '7', course_code: 'CODE7', name: '7' },
    //     { id: '8', course_code: 'CODE8', name: '8' },
    //     { id: '9', course_code: 'CODE9', name: '9' },
    // ];

    if ( products && isArrayExists( products ) ) {
        products.forEach( product => {
            if ( count < 6 ) {
                first5.push( product );
            } else {
                theRest.push( product );
            }
            count++;
        })
    } // end - products

    return (
    <PList>
        { first5 && isArrayExists( first5 ) ? first5.map( product => <li key={product.id}><Link to={"/products/"+product.id}>{product.course_code || ''}</Link></li> ) : null }
        { show ? ( theRest && isArrayExists( theRest ) ? theRest.map( product => <li key={product.id}><Link to={"/products/"+product.id}>{product.course_code || ''}</Link></li> ) : null ) : null }
        { theRest && isArrayExists( theRest ) ? <li className="show-more-link"><a href="#" onClick={handleClick}>{ show ? 'Hide' : 'Show more' }</a></li> : null }
    </PList>
    )
}

class ProgramsTable extends React.Component {
    
    state = {

    }

    handleSelect = (id) => (event) => {
        const { onSelected } = this.props;
        event.preventDefault();
        if ( onSelected )
            onSelected(id);
    }

    getExistingPrograms = () => {
        const { programs, filterBy } = this.props;
        let items = ( programs ? cloneCollections( programs ) : [] );
        // do duration filter
        if ( filterBy && filterBy.course_duration && !_isEmpty( filterBy.course_duration ) ) {
            items = _filter( items, { course_duration: filterBy.course_duration });
        }
        // do type filter
        if ( filterBy && filterBy.course_type && !_isEmpty( filterBy.course_type ) ) {
            items = _filter( items, { course_type: filterBy.course_type });
        }
        return items;
    }

    renderCell = type => item => {
        const { products_options } = this.props;
        switch( type ) {
            case 'publish':
                return item.publish && item.publish == 'yes' ? <SuccessTag>Yes</SuccessTag> : <GreyTag>No</GreyTag>;
            case 'email':
                return <WrapWord>{( item[type] || '' )}</WrapWord>;
            case 'products':
                return ( item.products && isArrayExists( item.products ) ? <ProductsList products={item.products} products_options={products_options} /> : [] )
        }
    }

    render() {
        const { price_per_pax, selectable, title = '' } = this.props;
        const items = this.getExistingPrograms();
        return (
        <div style={{ padding: '10px 5px' }}>
            { title && !_isEmpty( title ) ? <Typography variant="h6">{title}</Typography> : null }
            <Table 
                items={items}
                emptyCell="No program(s) found."
                showCheckbox={false}
                cells={[
                    { id: 'name', label: 'Program Name', render: (item) => <a href={"/programs/"+item.id} target="_blank">{( item.name || '' )}</a> },
                    { id: 'products', label: 'Courses', render: this.renderCell('products') },
                    { id: 'price_per_pax', label: 'Price Per Pax', render: (item) => getSelectedValue( price_per_pax, item.price_per_pax_id, 'id', 'label', '-' ) },
                ]}
                actionTooltips={ selectable ? (
                <>
                    This program selected for your class, along with its details & pricing, will be published on Registration Page. Once set, it cannot be modified.
                </>
                ) : null }
                noActions={ selectable ? false : true }
                actions={selectable ? (item) => (
                    <ButtonGroup>
                        <InverseButton key="select" size="small" onClick={this.handleSelect(item.id)}>Select</InverseButton>
                    </ButtonGroup>
                ) : null }
                />
        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(ProgramsTable);