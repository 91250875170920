/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import styled from "styled-components";
import blue from '@material-ui/core/colors/blue';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from "@material-ui/core/styles";
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';
import _replace from 'lodash/replace';

import ToolTipInfo from '../ToolTipInfo';

import { isArrayExists } from '../../helpers/validation';

const useStyles = theme => ({
    menu: {
        "& .MuiMenu-paper": {
            background: '#fff',
            boxShadow: '0 0 20px 0 rgba(0,0,0,0.45)'
        }
    },
    menuitem: {
        background: '#fff',
        color: '#212121',
        "&:hover": {
            background: '#e5e5e5'
        },
        "&.Mui-selected, &.Mui-selected:hover": {
            background: theme.palette.primary.main,
            color: '#fff'
        }
    }
});

class FormSelect extends React.Component {

    handleChange = (event) => {
        const { name } = this.props;
        if ( this.props.onChange ) {
            this.props.onChange(event.target.value,name);
        }
    }

    getLabelStyle = () => {
       const { tooltip } = this.props;
       let style = { 
           fontSize: "16px", 
           fontWeight: "700", 
           textTransform: "uppercase", 
           background: '#fff', 
           paddingLeft: '5px', 
           paddingRight: '5px' 
        };
        // if tooltip found
        if ( !_isEmpty( tooltip ) ) {
            style['pointerEvents'] = 'auto';
        }
        return style;
    }

    renderValue = selected => {
        const { options } = this.props;
        var string = '';
        if ( selected && isArrayExists( selected ) ) {
            selected.forEach(value => {
                var item = _find( options, { value } );
                string += ( !_isEmpty( string ) ? ', ' : '' ) + ( item && item.label || '' );
            });
        }
        return string;
    }

    renderNativeOptions = () => {
        const { options, enableGreyOption } = this.props;
        return ( options && isArrayExists( options ) ? options.map(option => {
            return <option key={option.value} value={option.value} style={ enableGreyOption ? { color: '#212121' } : null }>{option.label}</option>
        }) : null );
    }

    renderMenuOptions = () => {
        const { options, classes } = this.props;
        return ( options && isArrayExists( options ) ? options.map(option => {
            return <MenuItem key={option.value} value={option.value} disabled={( option.disabled && option.disabled === 'disabled' ? true : false )} className={classes.menuitem}>{option.label}</MenuItem>;
        }) : null );
    }

    renderTooltipInfo = () => {
        const { tooltip } = this.props;
        return <ToolTipInfo content={( tooltip || false )} />
    }

    renderLabel = () => {
        const { label, tooltip } = this.props;
        var newLabel = false;
        if ( label && !_isEmpty( label ) && ( 
            ( _toLower( label ).indexOf("(required)") >= 0 ) || 
            ( _toLower( label ).indexOf("*") >= 0 )
         ) ) {
            newLabel = _replace( label, '(Required)', '' );
            newLabel = _replace( newLabel, '(required)', '' );
            newLabel = _replace( newLabel, '*', '' );
        } // end - label
        return (
        <>
            { tooltip && !_isEmpty( tooltip ) ? this.renderTooltipInfo() : null }
            { newLabel ? <span>{ newLabel || '' }<span className="required">*</span></span> : ( label || '' ) }
        </>
        );
    }

    render() {
        const { id, value, options, name, disabled, disableNative, classes, desc, enableGreyOption } = this.props;
        var newID = 'multi-select-' + _random(1,9999);
        return (
        <FormControl variant="outlined" style={{ background: "#fff" }} fullWidth={true}>
            <InputLabel shrink={true} style={this.getLabelStyle()}>{this.renderLabel()}</InputLabel>
            <Select 
                native={( disableNative ? false : true )}
                value={value} 
                onChange={this.handleChange}
                disabled={( disabled || false )}
                MenuProps={( disableNative ? {
                    className: classes.menu
                } : null )}
                input={
                    disabled ? <FilledInput name={name} fullWidth={true} /> : <OutlinedInput name={name} fullWidth={true} style={ enableGreyOption && !( value && !_isEmpty( value ) ) ? {
                        color: '#bbb'
                    } : null } />
                } >
                { disableNative ? this.renderMenuOptions() : this.renderNativeOptions()}
            </Select>
            { desc && !_isEmpty( desc ) ? <Typography variant="body1" style={{ padding: '5px' }}>{ desc }</Typography> : null }
        </FormControl>
        )
    }

}

export default compose(
    withStyles(useStyles)
)(FormSelect);