/* eslint-disable */
import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import queryString from 'query-string';
import styled from "styled-components";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import _startCase from 'lodash/startCase';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';

import PopperBox from '../Popper';

import { H3 } from '../../styles/heading';
import { FlexColumn } from '../../styles/division';
import { ErrorLink, GreyLink, InverseLink, AWhiteLink } from '../../styles/button';

import { isArrayExists } from '../../helpers/validation';
import { hasAccessRights, isAdmin, isAdminType } from '../../helpers/auth';

import { logout } from '../../actions/auth';
import { appChangesReset } from '../../actions/misc';

import theme from '../../theme';

// import { DEV_MODE } from '../../constants';

const HeaderWrapperFixed = styled.header`
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 0px 0px 0px 30px;
    border-bottom: 1px solid #ddd;
    z-index: 999;
`;

const HeaderWrapper = styled.header`
    display: block;
    width: 100%;
    background: #fff;
    padding: 0px 0px 0px 30px;
    border-bottom: 1px solid #ddd;
`;

const Nav = styled.nav`
    display: block;

    ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        list-style: none;

        li {
            display: block;
            padding: 0px 1.25vw 0px 0px;
            margin: 0px;
            height: 64px;

            &:last-child {
                padding-right: 0px;
            }
        }

    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0px 5px;
        min-width: 64px;
        text-align: center;
        color: ${theme.palette.background};
        font-weight: 700;
        box-shadow:inset 0 0 0 0 #fff;
        text-transform: uppercase;

        
        &:hover,
        &:active,
        &.active {
            color: ${theme.palette.primary.main};
            box-shadow:inset 0 -5px 0 0 ${theme.palette.primary.main};
        }
    }
`;

const ToggleButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.35ms ease-in-out;
    cursor: pointer;
    padding: 0px 45px;
    height: 100%;
    vertical-align: middle;
    border-radius: 45px 0px 0px 45px;

    i { 
        font-size: 30px;
        margin-left: 30px;
        
        &:first-child {
            margin-left: 0px;
        }
    }

    &.opened {
        background: ${theme.palette.primary.main};
        color: #fff;
    }
`;

const UserDetails = styled.div`
    padding: 20px;
    border-bottom: 1px solid #ddd;
`;

const UserSubMenuWrapper = styled.div`
    padding: 20px;
`;

class HeaderStudent extends React.Component {

    state = {
        openSubMenu: false,
        subMenuAnchor: {},
        subMenuID: false,
        userAnchorEl: {},
        openUserMenu: false
    };

    handleLogOut = (event) => {
        const { changesNotSaved } = this.props;
        event.preventDefault();

        // if changes not saved - trigger warning        
        if ( changesNotSaved ) {
            var answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?");
            // reset status if answer is yes
            if ( answer ) {
                this.props.dispatch(appChangesReset());
                this.props.dispatch(logout());
            }
        } else {
            this.props.dispatch(logout());
        }
    }

    handleLinkClick(url,event) {
        const { history, changesNotSaved } = this.props;
        event.preventDefault();

        // if changes not saved - trigger warning        
        if ( changesNotSaved ) {
            var answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?");
            // reset status if answer is yes
            if ( answer ) {
                this.props.dispatch(appChangesReset());
                history.push(url);
            }
        } else {
            history.push(url);
        }
    }

    handleUserMenu = (event) => {
        const { openUserMenu } = this.state;
        this.setState({ openUserMenu: !openUserMenu, userAnchorEl: event.currentTarget });
    }

    showUserMenu = () => {
        const { location = {}, authData } = this.props;
        const { lms_view } = queryString.parse(location.search);
        return ( isAdminType(authData) ? true : false );
    }

    renderUserSubMenu() {
        const { authData } = this.props;
        const linkStyle = { marginBottom: "10px" };
        const hasMTR = hasAccessRights( authData, ['mtr'] );
        return (
        <UserSubMenuWrapper>
            <div style={{ padding: "0 10px" }}>
                <Typography variant="h5" style={{ marginBottom: "15px", padding: "0px 5px" }}>Quick Links</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <GreyLink onClick={this.handleLinkClick.bind(this,( isAdminType( authData ) ? '/auth/profile' : '/student/profile' ))} style={linkStyle}><i className="fa fa-user"></i>Edit Profile</GreyLink>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <GreyLink onClick={this.handleLinkClick.bind(this,'/student/badges')} style={linkStyle}><i className="fa fa-shield"></i>My Backpack</GreyLink>
                    </Grid>
                    { isAdminType(authData) ? (
                    <Grid item xs={12} sm={12}>
                        <GreyLink onClick={this.handleLinkClick.bind(this,'/dashboard')} style={linkStyle}><i className="fa fa-long-arrow-left"></i>Return to SMS</GreyLink>
                    </Grid>
                    ) : null }
                </Grid>
            </div>
        </UserSubMenuWrapper>
        )
    }

    renderUserDetails() {
        const { authData } = this.props;
        return (
        <UserDetails>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <FlexColumn width="70%">
                    <Box display="flex">
                        { authData.photoURL && !_isEmpty( authData.photoURL ) ? <img src={authData.photoURL} style={{ width: "45px", height: "45px", borderRadius: "100%" }} /> : <i className="fa fa-user-circle" style={{ fontSize: '45px' }}></i> }
                        <div style={{ paddingLeft: "20px" }}>
                            <Typography variant="h5" style={{ fontWeight: "700", marginBottom: "4px" }}>{authData.name || ''}</Typography>
                            <Typography variant="h6" color="textSecondary" style={{ marginBottom: "4px" }}>{authData.email || ''}</Typography>
                            <Typography variant="h6" color="primary">{( authData.role ? _startCase( authData.role ) : '' )}</Typography>
                        </div>
                    </Box>
                </FlexColumn>
                <FlexColumn width="30%">
                    <ErrorLink onClick={this.handleLogOut}><i className="fa fa-sign-out"></i>Logout</ErrorLink>
                </FlexColumn>
            </Box>
        </UserDetails>
        )
    }

    renderUserMenu = () => {
        const { authData } = this.props;
        const { openUserMenu, userAnchorEl } = this.state;
        const hasMTR = hasAccessRights( authData, ['mtr'] );
        return <PopperBox 
                open={openUserMenu}
                anchorEl={userAnchorEl}
                wrapperStyle={{ padding: "15px" }}
                onClose={() => this.setState({ openUserMenu: false, userAnchorEl: {} })}
                contents={(
                    <div>
                        {this.renderUserDetails()}
                        {this.renderUserSubMenu()}
                    </div>
                )} />
    }

    render() {
        const { openUserMenu, subMenuID } = this.state;
        let showUserMenu = this.showUserMenu();
        return (
        <HeaderWrapper>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <FlexColumn width={ showUserMenu ? "20%" : "100%" } style={( showUserMenu ? { paddingRight: "15px" } : { padding: "10px 15px", textAlign: "center" } )}>
                    <img src="/logo.gif" style={{ maxWidth: '150px', display: 'inline-block' }} />
                </FlexColumn>
                { showUserMenu ? (
                <FlexColumn width="80%">
                    <Nav>
                        <ul>
                            <li>
                                <ToggleButton onClick={this.handleUserMenu} className={( openUserMenu ? 'opened' : 'closed' )}>
                                    <i className="fa fa-user-circle"></i>
                                    { openUserMenu ? <i className="fa fa-remove"></i> : <i className="fa fa-ellipsis-v"></i> }
                                </ToggleButton>
                            </li>
                        </ul>
                    </Nav>
                    {this.renderUserMenu()}
                </FlexColumn>
                ) : null }
            </Box>
        </HeaderWrapper>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth.user || null,
        changesNotSaved: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(HeaderStudent);