/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import styled from "styled-components";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { WithContext as ReactTags } from 'react-tag-input';
import { withStyles } from "@material-ui/core/styles";
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _toLower from 'lodash/toLower';
import _replace from 'lodash/replace';
import _find from 'lodash/find';
import _trim from 'lodash/trim';

import { isArrayExists } from '../../helpers/validation';

import theme from '../../theme';

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const useStyles = theme => ({
    tagWrapper: {
        padding: '10px', 
        border: '1px solid #ddd',
        borderRadius: '4px',
        '& .tag-wrapper': {
            padding: '3px 5px',
            marginRight: '4px', 
            background: '#ddd'
        },
        '& .tag-wrapper .ReactTags__remove': {
            padding: '2px',
            marginLeft: '3px',
            lineHeight: 1, 
            background: '#212121',
            color: '#fff',
            '&:hover': {
                background: '#676767',
                color: '#fff',
            }
        },
        '& .ReactTags__tagInputField': {
            border: "none",
            marginTop: '5px'
        }
    },
});

class FormTagInput extends React.Component {

    handleDelete = (i) => {
        const tags = this.getTags();
        // trigger update
        this.onUpdate(tags.filter((tag, index) => index !== i));
    }

    handleAddition = (tag) => {
        const tags = this.getTags();
        // trigger update
        this.onUpdate([...tags, tag]);
    }

    handleDrag = (tag, currPos, newPos) => {
        const tags = this.getTags();
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // trigger update
        this.onUpdate(newTags);
    }

    onUpdate = (tags) => {
        const { name, onChange } = this.props;
        let value = [];

        if ( tags && isArrayExists( tags ) ) {    
            _forEach( tags, tag => {
                // make sure it wasn't already exists'
                if ( tag && tag.text && !_isEmpty( tag.text ) && !_find( value, t => _trim(_toLower(t)) === _trim(_toLower(tag.text)) ) )
                    value.push(tag.text);
            });
        } // end - tags

        if ( onChange ) {
            onChange(value,name);
        }
    }

    getTags = () => {
        const { value } = this.props;
        let tags = [];

        if ( value && isArrayExists( value ) ) {    
            _forEach( value, tag => {
                tags.push({ id: tag, text: tag });
            });
        } // end - value

        return tags;
    }

    renderLabel = () => {
        const { label } = this.props;
        var newLabel = false;
        if ( label && !_isEmpty( label ) && ( 
            ( _toLower( label ).indexOf("(required)") >= 0 ) || 
            ( _toLower( label ).indexOf("*") >= 0 )
         ) ) {
            newLabel = _replace( label, '(Required)', '' );
            newLabel = _replace( newLabel, '(required)', '' );
            newLabel = _replace( newLabel, '*', '' );
        } // end - label
        return ( newLabel ? <span>{ newLabel || '' }<span className="required">*</span></span> : ( label || '' ) );
    }

    render() {
        const { name, disabled, classes } = this.props;
        return (
        <FormControl variant="outlined" style={{ background: "#fff" }} fullWidth={true} className={classes.tagWrapper}>
            <InputLabel shrink={true} style={{ fontSize: "16px", fontWeight: "700", textTransform: "uppercase", background: '#fff', paddingLeft: '5px', paddingRight: '5px' }}>{this.renderLabel()}</InputLabel>
            <ReactTags 
                // inputFieldPosition="inline"
                allowDeleteFromEmptyInput={false}
                tags={this.getTags()}
                handleDelete={this.handleDelete}
                handleAddition={this.handleAddition}
                handleDrag={this.handleDrag}
                delimiters={delimiters} />
        </FormControl>
        )
    }

}

export default compose(
    withStyles(useStyles)
)(FormTagInput);