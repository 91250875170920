/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import grey from "@material-ui/core/colors/grey";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import _size from "lodash/size";
// import _filter from 'lodash/filter';
import _isEmpty from "lodash/isEmpty";
// import _slice from 'lodash/slice';
// import _split from 'lodash/split';
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
// import _find from 'lodash/find';
// import _replace from 'lodash/replace';
// import _remove from 'lodash/remove';
// import _map from 'lodash/map';

import BadgeForm from "./badge";
import BadgeDetails from "../BadgeDetailsPage/details";

import Pagination from "../../components/Pagination";
import TableBar from "../../components/TableBar";
import ModelDelete from "../../components/ModalDelete";
import ModalView from "../../components/ModalView";
import FormRadio from "../../components/FormRadio";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";

import { InfoButton, ErrorButton, ButtonGroup, IndigoButton, SuccessButton } from "../../styles/button";
// import { WrapWord } from '../../styles/misc';

import { isArrayExists } from "../../helpers/validation";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import { cloneCollections, doPagination, getSelectOptions, doArraySearch } from "../../helpers/data";
// import { getMomentTime } from '../../helpers/date';
import { isAdmin, hasAccessRights } from "../../helpers/auth";
import { isSkip, isSchemaRequired, doSchemaErrorCheck } from "../../helpers/schemas";

// import { addNewMaintenance, updateMaintenance, deleteMaintenance } from '../../actions/maintenance';
// import { addRegistrationForm } from '../../actions/m_registration_forms';

import { addBadge, editBadge, deleteBadge } from "../../actions/badges";

import { badgeSchema } from "../../schemas/badge";

const useStyles = (theme) => ({
    headcell: {
        fontSize: "16px",
        fontWeight: "700",
        color: theme.palette.background,
    },
    bodycell: {
        fontSize: "16px",
        verticalAlign: "top",
    },
});

const ActiveTag = styled.div`
    display: inline-block;
    padding: 5px 8px;
    margin: 0px 5px 5px 0px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 6px;
    color: #fff;
    background: ${(props) => (props.active && props.active == "yes" ? green["500"] : grey["500"])};
`;

class List extends React.Component {
    state = {
        sortBy: "name-asc",
        perPage: 20,
        page: 1,
        searchterms: "",
        openModal: false,
        modalType: false,
        modalData: false,
        openBadgePreview: false,
        selectedBadge: false,
        openDeleteModal: false,
        deleteModal: false,
    };

    handleFormUpdate = (newValue, key, status) => {
        const { modalData } = this.state;
        var newData = modalData ? cloneCollections(modalData) : {};
        newData[key] = newValue;
        this.setState({ modalData: newData });
    };

    handleAction = () => {
        const { modalData, modalType } = this.state;
        let error = false,
            formData = {};

        // do error check
        badgeSchema.forEach((schema) => {
            formData[schema.id] = modalData && modalData[schema.id] ? cloneCollections(modalData[schema.id]) : schema.default;
            if (!doSchemaErrorCheck(formData[schema.id], schema, modalType === "new" ? "add" : "update")) {
                // error = 'Please fill out "' + schema.label + '" field';
                error = "Please fill out all the required field(s)";
            }
        });

        if (error) {
            triggerErrorAlert(error);
        } else {
            // add oneoff as course_id
            formData.references = { course_id: "oneoff" };
            if (modalType === "new") {
                this.props.dispatch(addBadge(formData));
            } else {
                this.props.dispatch(editBadge(formData));
            }
        } // end - error
    };

    handleDelete = () => {
        const { deleteModal } = this.state;
        //perform delete
        this.props.dispatch(deleteBadge(deleteModal.id));
    };

    reorganizeData() {
        const { sortBy, perPage, page, searchterms } = this.state;
        const { list } = this.props;
        var items = list ? cloneCollections(list) : [],
            total = _size(items);

        // do search
        if (searchterms && !_isEmpty(searchterms) && !_isEmpty(items)) {
            items = doArraySearch(items, searchterms, ["name", "type_label"]);
            total = _size(items);
        } // end - searchterms

        // do sort
        if (sortBy && !_isEmpty(sortBy) && !_isEmpty(items)) {
            switch (sortBy) {
                // case 'position-desc':
                //     items = _sortBy( items, [(i) => ( i.position ? parseInt( i.position, 10 ) : 99 )] );
                //     items = _reverse( items );
                //     break;
                // case 'position-asc':
                //     items = _sortBy( items, [(i) => ( i.position ? parseInt( i.position, 10 ) : 99 )] );
                //     break;
                case "name-desc":
                    items = _sortBy(items, ["name"]);
                    items = _reverse(items);
                    break;
                case "name-asc":
                    items = _sortBy(items, ["name"]);
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination(items, perPage, page);

        return { items, total };
    }

    renderBadgePreview = () => {
        const { selectedBadge } = this.state;
        return (
            <div>
                <BadgeDetails preview={true} badge={selectedBadge || false} />
            </div>
        );
    };

    renderModal = () => {
        const { authData, list } = this.props;
        const { modalData, modalType } = this.state;
        return (
            <>
                <BadgeForm
                    isOneOff={true}
                    authData={authData}
                    course_id="oneoff"
                    selectItem={modalData}
                    modalType={modalType}
                    badges={list}
                    onUpdate={(newValue) => this.setState({ modalData: newValue })}
                />
            </>
        );
    };

    renderTableActions = () => {
        const { type } = this.props;
        const { sortBy, perPage, searchterms } = this.state;
        return (
            <TableBar
                show={["sort", "entries", "search"]}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: "name-asc", label: "Badge Name ( A - Z)" },
                    { value: "name-desc", label: "Badge Name ( Z - A )" },
                    // { value: 'position-asc', label: 'Position (smallest number first)' },
                    // { value: 'position-desc', label: 'Position (biggest number first)' }
                ]}
                rightButtons={[
                    <InfoButton
                        minWidth="128px"
                        key="addnew"
                        style={{ marginRight: "5px" }}
                        onClick={() =>
                            this.setState({
                                openModal: true,
                                modalData: {
                                    type,
                                    label: "",
                                    value: "",
                                    status: "active",
                                    duplicate_form_id: "",
                                },
                                modalType: "new",
                            })
                        }
                    >
                        <i className="fa fa-plus-circle"></i>Add New
                    </InfoButton>,
                ]}
                onSearchChange={(newSearchTerms) => this.setState({ searchterms: newSearchTerms, page: 1 })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }}
            />
        );
    };

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return (
            <Pagination
                total={totalCount}
                perPage={perPage}
                page={page}
                doneLoaded={true}
                style={{ marginTop: "20px" }}
                onPageChange={(newPage) => this.setState({ page: newPage })}
            />
        );
    };

    renderBody = (items) => {
        const { classes, authData } = this.props;
        return (
            <TableBody>
                {items && isArrayExists(items) ? (
                    items.map((item) => {
                        return (
                            <TableRow key={item.id}>
                                <TableCell className={classes.bodycell} style={{ width: "50%" }}>
                                    {item.image && !_isEmpty(item.image) && (
                                        <div
                                            style={{
                                                display: "inline-block",
                                                width: "50px",
                                                height: "50px",
                                                marginRight: "15px",
                                                verticalAlign: "middle",
                                            }}
                                        >
                                            <img src={item.image} style={{ width: "100%", height: "100%" }} />
                                        </div>
                                    )}
                                    {item.name || ""}
                                </TableCell>
                                <TableCell className={classes.bodycell}>
                                    <ActiveTag active={item.status && item.status === "active" ? "yes" : "no"}>{item.status || ""}</ActiveTag>
                                </TableCell>
                                <TableCell className={classes.bodycell}>{item.type_label || ""}</TableCell>
                                <TableCell className={classes.bodycell}>
                                    <ButtonGroup>
                                        <InfoButton
                                            size="small"
                                            onClick={() =>
                                                this.setState({
                                                    openModal: true,
                                                    modalType: "edit",
                                                    modalData: item,
                                                })
                                            }
                                        >
                                            <i className="fa fa-edit"></i>Edit
                                        </InfoButton>
                                        <SuccessButton
                                            size="small"
                                            onClick={() =>
                                                this.setState({
                                                    openModal: true,
                                                    modalType: "new",
                                                    modalData: {
                                                        ...item,
                                                        id: "",
                                                    },
                                                })
                                            }
                                        >
                                            <i className="fa fa-copy"></i>Duplicate
                                        </SuccessButton>
                                        <IndigoButton
                                            size="small"
                                            onClick={() =>
                                                this.setState({
                                                    openBadgePreview: true,
                                                    selectedBadge: item,
                                                })
                                            }
                                        >
                                            <i className="fa fa-search"></i>Preview
                                        </IndigoButton>
                                        {authData && hasAccessRights(authData, ["mtd"]) ? (
                                            <ErrorButton size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}>
                                                <i className="fa fa-trash"></i>Delete
                                            </ErrorButton>
                                        ) : null}
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        );
                    })
                ) : (
                    <TableRow>
                        <TableCell className={classes.bodycell}>No badge(s) found.</TableCell>
                    </TableRow>
                )}
            </TableBody>
        );
    };

    renderHeader = () => {
        const { classes } = this.props;
        return (
            <TableHead>
                <TableRow>
                    <TableCell className={classes.headcell}>Badge</TableCell>
                    <TableCell className={classes.headcell}>Status</TableCell>
                    <TableCell className={classes.headcell}>Level</TableCell>
                    <TableCell className={classes.headcell}>Actions</TableCell>
                </TableRow>
            </TableHead>
        );
    };

    render() {
        const { typeLabel } = this.props;
        const { openDeleteModal, deleteModal, openModal, modalType, openBadgePreview } = this.state;
        const { items, total } = this.reorganizeData();
        return (
            <div>
                <ModalView
                    open={openModal}
                    title={openModal && modalType && modalType === "edit" ? "Edit Badge" : "Add New Badge"}
                    maxWidth="md"
                    actionLabel={openModal && modalType && modalType === "edit" ? "Update" : "Add New"}
                    cancelLabel="Close"
                    doAction={this.handleAction}
                    disableAutoFocus={true}
                    disableEnforceFocus={true}
                    disableBackdrop={true}
                    onClose={() => this.setState({ openModal: false, modalType: false, selectItem: false })}
                    contents={this.renderModal()}
                />

                <ModalView
                    open={openBadgePreview ? true : false}
                    title="Badge Preview"
                    maxWidth="lg"
                    noAction={true}
                    cancelLabel="Close"
                    onClose={() => this.setState({ openBadgePreview: false, selectedBadge: false })}
                    contents={this.renderBadgePreview()}
                />

                <ModelDelete
                    open={openDeleteModal}
                    title={
                        deleteModal && deleteModal.name
                            ? `Are you sure you want to delete this ${typeLabel || "Item"} ( ${deleteModal.name} )?`
                            : false
                    }
                    onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                    onDelete={this.handleDelete}
                />

                {this.renderTableActions()}
                <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                    <Table>
                        {this.renderHeader()}
                        {this.renderBody(items)}
                    </Table>
                </Paper>
                {this.renderPagination(total)}
            </div>
        );
    }
}

export default compose(connect(), withStyles(useStyles), withRouter)(List);
