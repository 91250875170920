/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';

import ProgramsTable from './programs_table';

import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormDatePicker from '../../components/FormDatePicker';
import FormPhoneField from '../../components/FormPhoneField';

import { FormBox } from '../../styles/form';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';

import { partnerSchema } from '../../schemas/partner';
import { COUNTRIES_LIST } from '../../data/const_countries';
import { STATE_OPTIONS } from '../../data/const_states';

import { YES_NO_OPTIONS, GENDER_OPTIONS } from '../../constants';

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

class PartnerEditForm extends React.Component {

    state = {
        randNum: false
    };

    handleFormUpdate = ( newValue, key ) => {
        const { onFormUpdate, partner, currentPartner } = this.props;
        let newData = ( partner && !_isEmpty( partner ) ? cloneCollections( partner ) : {} );

        // if finance object is empty
        if ( !( newData.finance && !_isEmpty( newData.finance ) ) )
            newData.finance = {};

        switch( key ) {
            case 'finance_currency':
                newData[key] = newValue;
                newData.finance.currency = newValue;
                break;
            case 'finance_payee_name':
                newData[key] = newValue;
                newData.finance.payee_name = newValue;
                break;
            case 'finance_bank_name':
                newData[key] = newValue;
                newData.finance.bank_name = newValue;
                break;
            case 'finance_bank_account_no':
                newData[key] = newValue;
                newData.finance.bank_account_no = newValue;
                break;
            case 'finance_payment_method':
                newData[key] = newValue;
                newData.finance.payment_method = newValue;
                break;
            case 'finance_bank_swift_code':
                newData[key] = newValue;
                newData.finance.bank_swift_code = newValue;
                break;
            case 'finance_bank_address':
                newData[key] = newValue;
                newData.finance.bank_address = newValue;
                break;
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    getFieldOptions = (field) => {
        const { partner, designations, currency } = this.props;

        switch( field.name ) {
            case 'designation':
                return getSelectOptions({ list: ( designations || false ), keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' });
            case 'finance_currency':
                return getSelectOptions({ list: ( currency || false ), keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' });
            case 'gender':
                return GENDER_OPTIONS;
            case 'state':
                let selected = ( partner && partner.country && !_isEmpty( partner.country ) ? _find( COUNTRIES_LIST, { value: partner.country }) : false ),
                    country = ( selected && selected.label && !_isEmpty( selected.label ) ? _find( STATE_OPTIONS, { country: selected.label }) : false ),
                    options = [{ value: '', label: 'Select an Option' }];
                if ( country && country.states && isArrayExists( country.states ) ) {
                    country.states.forEach(state => {
                        options.push({ value: state, label: state });
                    });
                } // end - country.states
                return options;
            case 'country':
                return getSelectOptions({ list: COUNTRIES_LIST, options: [{ value: '', label: 'Select an Option' }], keys: { value: 'value', label: 'label' } });
                
            default:
                return [];
        }
    }

    getFieldValue = (field) => {
        const { partner } = this.props;
        switch( field.name ) {
            case 'finance_currency':
                return ( partner && partner.finance && partner.finance.currency ? partner.finance.currency : ( field.default || '' ) );
            case 'finance_payee_name':
                return ( partner && partner.finance && partner.finance.payee_name ? partner.finance.payee_name : ( field.default || '' ) );
            case 'finance_bank_name':
                return ( partner && partner.finance && partner.finance.bank_name ? partner.finance.bank_name : ( field.default || '' ) );
            case 'finance_bank_account_no':
                return ( partner && partner.finance && partner.finance.bank_account_no ? partner.finance.bank_account_no : ( field.default || '' ) );
            case 'finance_payment_method':
                return ( partner && partner.finance && partner.finance.payment_method ? partner.finance.payment_method : ( field.default || '' ) );
            case 'finance_bank_swift_code':
                return ( partner && partner.finance && partner.finance.bank_swift_code ? partner.finance.bank_swift_code : ( field.default || '' ) );
            case 'finance_bank_address':
                return ( partner && partner.finance && partner.finance.bank_address ? partner.finance.bank_address : ( field.default || '' ) );
            default:
                return ( partner && partner[field.name] ? partner[field.name] : ( field.default || '' ) );
        }
    }

    isFieldDisabled = (schema) => {
        const { authData } = this.props;
        var disabled = false;

        if ( schema && schema.disabled && isArrayExists( schema.disabled ) ) {
            schema.disabled.forEach( condition => {
                switch( condition ) {
                    case 'admin':
                        if ( !isAdmin( authData ) )
                            disabled = true;
                        break;
                    case 'update':
                        disabled = true;
                        break;
                }
            });
        } // end - schema

        return disabled
    }

    getTooltip = (schema) => {
        switch( schema.id ) {
            case 'label':
                return <div>e.g. Chumbaka@Kota Kinabalu or Sekolah Sri Tenby KL </div>;
            case 'work_phone':
                return <div>Public will be able to contact this number. Number will be accessible via website.</div>;
            case 'email_forwarder':
                return <div>This will be published on website. This is set up by HQ (Superadmin), linking to your Company Email. Find out more on the usage from <a href="https://www.notion.so/chumbaka/WIP-Setting-up-Chumbaka-Forwarder-Email-24ffd17a05244521aa2be2c83ad6eeaa" target="_blank">Notion</a>.</div>;
            default:
                return;
        }
    }

    getField = (id) => {
        const { massEditMode } = this.props;
        let schema = _find( partnerSchema, { id } );
        return ( schema ? {
            name: ( schema.id || '' ),
            label: ( schema.label || '' ) + ( isSchemaRequired(schema,'update') && !massEditMode ? ' (Required)' : '' ),
            field_type: ( schema.field || '' ),
            default: ( massEditMode ? null : ( schema.default || null ) ),
            disabled: ( schema.disabled ? this.isFieldDisabled( schema ) : false ),
            tooltip: this.getTooltip(schema)
        } : null );
    }

    renderField = (id) => {
        let field = this.getField(id);
        if ( field && field.field_type && !_isEmpty( field.field_type ) ) {
            switch( field.field_type ) {
                case 'text':
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'textarea':
                    return <FormInput {...field} rows={( 'notes' === id ? 6 : 3 )} multiline={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'text_number':
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'email':
                    return <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'select':
                    return <FormSelect {...field} disableNative={( 'designation' === id || 'finance_currency' === id ? true : false )} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} />;
                case 'multiselect':
                    return <FormMultiSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} />;
                case 'datepicker':
                    return <FormDatePicker {...field} noDefaultVal={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'phone':
                    return <FormPhoneField {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />
            }
        } // end - field.field_type
    }

    renderCreatedModifiedDate = (item) => {
        let created_on = ( item.created_on && item.created_on._seconds ? item.created_on._seconds*1000 : ( item.created_on || null ) ),
            modified_on = ( item.modified_on && item.modified_on._seconds ? item.modified_on._seconds*1000 : ( item.modified_on || null ) );
        return (
        <div style={{ paddingTop: "10px", textAlign: 'right', color: '#999', fontSize: '1.25rem' }}>
            <div>{ created_on ? 'Created on ' + getMomentTime( created_on , 'YYYY-MM-DD hh:mm:ssa' ) : ''}</div>
            <div>{ modified_on ? 'Last Modified on ' + getMomentTime( modified_on , 'YYYY-MM-DD hh:mm:ssa' ) : ''}</div>
        </div>
        )
    }

    render = () => {
        const { classes, partner, disableSystemDate, massEditMode, programs, course_type, course_duration } = this.props;
        return (
        <FormBox>
            <Typography variant="h4" className={classes.boxheading}>Partner Profile</Typography>
            <Grid container spacing={3}>

                <Grid item xs={12}>{this.renderField('label')}</Grid>
                <Grid item xs={6}>{this.renderField('email_company')}</Grid>
                <Grid item xs={6}>{this.renderField('email_forwarder')}</Grid>
                <Grid item xs={6}>{this.renderField('work_phone')}</Grid>
                <Grid item xs={6}> </Grid>

                <Grid item xs={12}><Typography variant="h4" className={classes.boxheading}>Person In-charge's  Details</Typography></Grid>

                <Grid item xs={6}>{this.renderField('designation')}</Grid>
                <Grid item xs={6}> </Grid>
                <Grid item xs={6}>{this.renderField('name')}</Grid>
                <Grid item xs={6}>{this.renderField('gender')}</Grid>
                <Grid item xs={6}>{this.renderField('email')}</Grid>
                <Grid item xs={6}>{this.renderField('id_num')}</Grid>
                <Grid item xs={6}>{this.renderField('mobile')}</Grid>
                
                <Grid item xs={12}><Typography variant="h4" className={classes.boxheading}>Company Details</Typography></Grid>

                <Grid item xs={6}>{this.renderField('company_name')}</Grid>
                <Grid item xs={6}>{this.renderField('company_reg_no')}</Grid>
                <Grid item xs={12}>{this.renderField('address')}</Grid>
                <Grid item xs={12}>{this.renderField('address2')}</Grid>
                <Grid item xs={6}>{this.renderField('city')}</Grid>
                <Grid item xs={6}>{this.renderField('postcode')}</Grid>
                <Grid item xs={6}>{this.renderField('state')}</Grid>
                <Grid item xs={6}>{this.renderField('country')}</Grid>

                <Grid item xs={12}><Typography variant="h4" className={classes.boxheading}>Finance</Typography></Grid>

                <Grid item xs={6}>{this.renderField('finance_currency')}</Grid>
                <Grid item xs={6}> </Grid>
                <Grid item xs={6}>{this.renderField('finance_payee_name')}</Grid>
                <Grid item xs={6}>{this.renderField('finance_bank_account_no')}</Grid>
                <Grid item xs={6}>{this.renderField('finance_bank_name')}</Grid>
                <Grid item xs={6}>{this.renderField('finance_bank_swift_code')}</Grid>
                <Grid item xs={12}>{this.renderField('finance_bank_address')}</Grid>

                <Grid item xs={12}><Typography variant="h4" className={classes.boxheading}>iPay88 Details</Typography></Grid>
                <Grid item xs={6}>{this.renderField('merchant_code')}</Grid>
                <Grid item xs={6}>{this.renderField('merchant_key')}</Grid>

                <Grid item xs={12}><Typography variant="h4" className={classes.boxheading}>Payment Webhook Details</Typography></Grid>
                <Grid item xs={12}>{this.renderField('payment_webhook_url')}</Grid>
                <Grid item xs={6}>{this.renderField('payment_webhook_key')}</Grid>

                <Grid item xs={12}><Typography variant="h4" className={classes.boxheading}>Program Visible</Typography></Grid>
                <Grid item xs={12}><ProgramsTable
                    partner={partner}
                    programs={programs}
                    course_type={course_type}
                    course_duration={course_duration} /></Grid>

            </Grid>

            { disableSystemDate ? null : this.renderCreatedModifiedDate(partner)}

        </FormBox>
        );
    }

}

export default compose(
    withStyles(useStyles),
    withRouter
)(PartnerEditForm);