/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';

import { getIDToken } from '../auth/fb';
import { doPromise } from '../../helpers/action';

export const fb_getRegistrationForm = (id) => {
    return new Promise((resolve, reject) => {

        let promises = [];
        promises.push( firebase.firestore().collection("maintenance").doc(id).get() );
        promises.push( firebase.database().ref("registration_forms/"+id+"/fields").once("value") );
        
        Promise.all( promises )
        .then(results => {
            let item = {};
            // add maintenance data
            if ( results && results[0] && results[0].exists ) {
                item = results[0].data();
            } // end - results[0]

            // add status if not set
            if ( !( item.status && !_isEmpty( item.status ) ) )
                item.status = 'active';

            // add fields
            item.fields = [];
            if ( results && results[1] && results[1].exists() && results[1].hasChildren() ) {
                results[1].forEach(snapshot => {
                    let field = snapshot.val();
                    field.id = snapshot.key;
                    item.fields.push( field );
                });
            } // end - results[0]
            resolve(item);
        })
        .catch(error => {
            reject(error);
        });

    });  
}

export const fb_getRegistrationFormsFields = (callback) => {
    return new Promise((resolve, reject) => {

        firebase.database().ref("registration_forms")
        .on("value",snapshot => {
            const list = [];
            if ( snapshot && snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach( childSnapshot => {
                    let form = { id: childSnapshot.key, fields: [] };
                    if ( childSnapshot.child("fields").exists() && childSnapshot.child("fields").hasChildren() ) {
                        childSnapshot.child("fields").forEach( fieldSnapshot => {
                            let field = fieldSnapshot.val();
                            field.id = fieldSnapshot.key;
                            form.fields.push( field );
                        });
                    } // end - childSnapshot.child("fields")
                    list.push(form);
                });
            } // end - snapshpt\
            callback(list);
        });

    });  
}