/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import blue from "@material-ui/core/colors/blue";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import _isEmpty from "lodash/isEmpty";
import _sortBy from "lodash/sortBy";

import ModalView from "../../components/ModalView";
import DotsLoader from "../../components/DotsLoader";
import Pagination from "../../components/Pagination";

import { isArrayExists } from "../../helpers/validation";
import { triggerErrorAlert } from "../../helpers/alert";
import { getMomentTime } from "../../helpers/date";
import { cloneCollections, getSelectOptions, getSelectValues } from "../../helpers/data";
import { callFunctionsAPI } from "../../helpers/action";

import { WrapWord } from "../../styles/misc";
import { AInfoLink, WarningButton, InverseButton, IndigoButton, SuccessButton } from "../../styles/button";
import { InfoTag, GreyTag, SuccessTag } from "../../styles/tag";

import { PLATFORM_URL, DEV_MODE, ENTRIES_OPTIONS } from "../../constants";

const Wrapper = styled.div`
    margin-top: 45px;
    padding: 30px;
    border: 1px solid #ddd;
    background: #fff;
    h3 {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #ddd;
    }
`;

const IconRedDot = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 20%;
    background-color: red;
    color: white;
    font-size: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    padding: 0 4px;
`;

const CircleRedDot = styled.span`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: red;
    color: white;
    font-size: 12px;
    display: inline-block;
`;

const WordWrap = styled.div`
    word-wrap: break-word;
    max-width: 200px;
`;

const useStyles = (theme) => ({
    headcell: {
        fontSize: "16px",
        fontWeight: "700",
        color: theme.palette.background,
    },
    bodycell: {
        fontSize: "14px",
        verticalAlign: "top",
    },
    popper: {
        zIndex: "3000",
        pointerEvents: "auto",
    },
    tooltip: {
        fontSize: "14px",
        color: "#fff",
        background: blue[400],
        maxWidth: 400,
        padding: "10px",
        "& > .MuiTooltip-arrow": {
            color: blue[400],
        },
    },
});

const getTimeAgo = (timestamp) => {
    let result = moment(timestamp).utcOffset(8).fromNow();
    const now = moment().utcOffset(8);
    const hours = now.diff(timestamp, "hours");
    const days = now.diff(timestamp, "days");
    const weeks = now.diff(timestamp, "weeks");
    if (hours < 1) {
        result = "less than an hour ago";
    } else if (hours >= 1 && hours < 2) {
        result = "an hour ago";
    } else if (hours >= 2 && hours < 24) {
        result = `${hours} hours ago`;
    } else if (days >= 1 && days < 7) {
        result = `${days} days ago`;
    } else if (days >= 7) {
        if (days <= 13) {
            result = `a week ago`;
        } else if (days > 13 && days <= 25) {
            result = `${weeks} weeks ago`;
        }
    }
    return result;
};

const getStageData = (notification) => {
    switch (notification.type) {
        case "notification_new_mentor":
            return {
                stage: "setup",
                stage_no: 0,
                type: "lms_users",
                label: "Setup - LMS Users",
            };
        case "notification_new_registration_group":
            return {
                stage: "recruitment",
                stage_no: 1,
                type: "registration",
                label: "1. Recruitment - Registration",
            };
        case "notification_new_registration":
            return {
                stage: "recruitment",
                stage_no: 1,
                type: "registration",
                label: "1. Recruitment - Registration",
            };
        case "notification_new_payment":
            return {
                stage: "delivery",
                stage_no: 3,
                type: "payment",
                label: "3. Delivery - Payment",
            };
        case "notification_new_badge_status_review":
        case "notification_new_badge_status_nominated":
            return {
                stage: "closing",
                stage_no: 4,
                type: "showcase",
                label: "4. Closing - Showcase",
            };
        case "notification_new_mentor_general_comments":
            return {
                stage: "delivery",
                stage_no: 3,
                type: "attendance_reporting",
                label: "3. Delivery - Attendance & Reporting",
            };
        case "notification_new_quarterly_report_sent":
            return {
                stage: "closing",
                stage_no: 4,
                type: "quarterly_report",
                label: "4. Closing - Quarterly Report",
            };
    }

    return {
        stage: "",
        stage_no: 0,
        type: "",
        label: "",
    };
};

const getNoficationData = (notification = {}) => {
    const { type = "", data = {}, region = "noregion", added_on = 0, marked_as_completed = "no", marked_as_completed_by = "" } = notification;
    switch (notification.type) {
        case "notification_new_mentor":
            return {
                link: `/nr/${data.uid || "nouid"}?location=mentor&nid=${notification.id}`,
                linkText: "View Mentor",
                message: `A new mentor registered: ${data.name || ""} (${data.email || ""})`,
            };
        case "notification_new_registration":
            return {
                link: `/nr/${data.schedule_id || "noscheduleid"}?location=registration&nid=${notification.id}`,
                linkText: "View Registration",
                message: `A new registration from ${data.name || ""} (${data.email || ""})`,
            };
        case "notification_new_registration_group":
            let registrationCount = data.trainees && data.trainees.length ? data.trainees.length : 0;
            return {
                link: `/nr/${data.id || "noscheduleid"}?location=registration&nid=${notification.id}`,
                linkText: "View Registration",
                message: `${registrationCount} new registration(s)${data.class_name && data.class_name !== "" ? ` for ${data.class_name}` : ""}`,
            };
        case "notification_new_payment":
            return {
                link: `/nr/${data.schedule_id || "noscheduleid"}?location=payment&nid=${notification.id}`,
                linkText: "View Payment",
                message: `A new payment from ${data.name || ""} (${data.email || ""}) for ${
                    data.remarks && data.remarks !== "" ? data.remarks : data.student_email || ""
                }`,
            };
        case "notification_new_badge_status_review":
            let badgeReviewCount = data.trainees && data.trainees.length ? data.trainees.length : 0;
            return {
                link: `/nr/${data.id || "noscheduleid"}?location=showcase&nid=${notification.id}`,
                linkText: "View Showcase",
                message: `${badgeReviewCount} students' badge status need to be reviewed for ${
                    data.class_name && data.class_name !== "" ? ` for ${data.class_name}` : ""
                }`,
            };
        case "notification_new_badge_status_nominated":
            let badgeNominatedCount = data.trainees && data.trainees.length ? data.trainees.length : 0;
            return {
                link: `/nr/${data.id || "noscheduleid"}?location=showcase&nid=${notification.id}`,
                linkText: "View Showcase",
                message: `${badgeNominatedCount} students' badge has been nominated for ${
                    data.class_name && data.class_name !== "" ? ` for ${data.class_name}` : ""
                }`,
            };
        case "notification_new_mentor_general_comments":
            let mentorCommentCount = data.trainees && data.trainees.length ? data.trainees.length : 0;
            return {
                link: `/nr/${data.id || "noscheduleid"}?location=attendance&nid=${notification.id}`,
                linkText: "View Mentor General Comments",
                message: `${mentorCommentCount} new mentor's general comments has been added for ${
                    data.class_name && data.class_name !== "" ? ` for ${data.class_name}` : ""
                }`,
            };
        case "notification_new_quarterly_report_sent":
            return {
                link: `/nr/${data.schedule_id || "noscheduleid"}?location=quarterly_report&nid=${notification.id}`,
                linkText: "View Quarterly Report",
                message: `A new quarterly report sent for ${data.name || ""} (${data.email || ""})`,
            };
    }

    return {
        link: "",
        linkText: "",
        message: "",
    };
};

const NotificationItem = ({ notification = {}, classes = {}, loading = [], onMarkAsCompleted = () => {}, history }) => {
    const stageData = getStageData(notification);
    const notifData = getNoficationData(notification);
    const markedAsCompleted = useMemo(() => {
        return notification.marked_as_completed === "yes" ? true : false;
    }, [notification]);
    const isLoading = useMemo(() => {
        return loading.includes(notification.id);
    }, [loading, notification]);
    return (
        <TableRow
            id={notification.id}
            hover={markedAsCompleted ? false : true}
            key={notification.id}
            style={{
                backgroundColor: markedAsCompleted ? "#e8f5e9" : null,
            }}
        >
            <TableCell className={classes.bodycell} style={{ width: "20px", textAlign: "center", paddingLeft: "0px", paddingRight: "0px" }}>
                {!(notification.marked_as_completed === "yes" || notification.marked_as_read === "yes") && <CircleRedDot />}
            </TableCell>
            <TableCell className={classes.bodycell}>
                {getMomentTime(notification.added_on, "YYYY-MM-DD HH:mma")}
                <div>
                    <GreyTag>({getTimeAgo(notification.added_on)})</GreyTag>
                </div>
            </TableCell>
            <TableCell className={classes.bodycell}>{stageData.label}</TableCell>
            <TableCell className={classes.bodycell}>
                <WordWrap>{notifData.message}</WordWrap>
            </TableCell>
            <TableCell className={classes.bodycell}>
                <AInfoLink
                    href={`${DEV_MODE ? "http://localhost:3000/" : PLATFORM_URL}${notifData.link.replace(/^\//, "")}`}
                    onClick={(e) => {
                        e.preventDefault();
                        history.push(notifData.link);
                    }}
                    style={{
                        padding: "5px 3px",
                        fontSize: "12px",
                        width: "100%",
                        maxWidth: "170px",
                    }}
                >
                    {notifData.linkText}
                </AInfoLink>
            </TableCell>
            <TableCell className={classes.bodycell}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                    }}
                >
                    <Checkbox
                        checked={markedAsCompleted}
                        disabled={isLoading}
                        onChange={(event) => {
                            onMarkAsCompleted(notification.id, event.target.checked);
                        }}
                    />
                    {isLoading ? <i className="fa fa-spinner fa-spin" /> : null}
                    {markedAsCompleted ? (
                        <GreyTag
                            style={{
                                fontSize: "12px",
                                wordWrap: "break-word",
                                maxWidth: "200px",
                            }}
                        >
                            Marked as Completed by{" "}
                            {notification.marked_as_completed_by_name && notification.marked_as_completed_by_name !== ""
                                ? notification.marked_as_completed_by_name
                                : notification.marked_as_completed_by || "Unknown"}{" "}
                            on {getMomentTime(notification.marked_as_completed_on, "YYYY-MM-DD HH:mma")}
                        </GreyTag>
                    ) : null}
                </div>
            </TableCell>
        </TableRow>
    );
};

const NotificationsTable = ({ authData, classes, history }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [filterByStage, setFilterByStage] = useState("all");
    const [filterByStatus, setFilterByStatus] = useState("all");
    const notifications = useSelector((state) => state.notifications.notifications);
    const loaded = useSelector((state) => state.notifications.rand);

    const notificationsList = useMemo(() => {
        let list = [];
        if (isArrayExists(notifications)) {
            notifications.forEach((notification) => {
                const stageData = getStageData(notification);
                if (filterByStage !== "all" && filterByStage !== stageData.stage + "_" + stageData.type) {
                    return;
                }
                if (filterByStatus !== "all" && filterByStatus !== notification.marked_as_completed) {
                    return;
                }
                list.push(notification);
            });
        }

        return list;
    }, [notifications, filterByStage, filterByStatus]);

    const paginatedNotificationsList = useMemo(() => {
        return notificationsList.slice((page - 1) * perPage, page * perPage);
    }, [notificationsList, page, perPage]);

    const pendingNotificationsNum = useMemo(() => {
        let count = 0;
        if (isArrayExists(notifications)) {
            notifications.forEach((notification) => {
                if (!(notification.marked_as_completed === "yes" || notification.marked_as_read === "yes")) {
                    count++;
                }
            });
        }
        return count;
    }, [notifications]);

    const handleMarkAsCompleted = (nid, checked) => {
        setTimeout(() => {
            setLoading((currentLoading) => [...currentLoading, nid]);
            callFunctionsAPI({
                url: "notifications",
                action: "marked_as_completed",
                formData: {
                    notification_id: nid,
                    marked_as_completed: checked ? "yes" : "no",
                },
            })
                .then((result) => {
                    setTimeout(() => {
                        setLoading((currentLoading) => currentLoading.filter((n) => n !== nid));
                    }, 150);
                })
                .catch((error) => {
                    setTimeout(() => {
                        setLoading((currentLoading) => currentLoading.filter((n) => n !== nid));
                        triggerErrorAlert("Failed to mark notification as completed.");
                    }, 150);
                });
        }, 150);
    };

    const handleMarkAsUncompleted = (nid) => {
        setTimeout(() => {
            setLoading((currentLoading) => [...currentLoading, nid]);
            callFunctionsAPI({
                url: "notifications",
                action: "marked_as_uncompleted",
                formData: {
                    notification_id: nid,
                },
            })
                .then((result) => {
                    setTimeout(() => {
                        setLoading((currentLoading) => currentLoading.filter((n) => n !== nid));
                    }, 150);
                })
                .catch((error) => {
                    setTimeout(() => {
                        setLoading((currentLoading) => currentLoading.filter((n) => n !== nid));
                        triggerErrorAlert("Failed to mark notification as uncompleted.");
                    }, 150);
                });
        }, 150);
    };

    return (
        <Wrapper>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h3">
                        Notifications
                        {pendingNotificationsNum > 0 && <IconRedDot>{pendingNotificationsNum}</IconRedDot>}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
                        <Select
                            native
                            value={filterByStage}
                            onChange={(e) => {
                                setFilterByStage(e.target.value);
                                setPage(1);
                            }}
                            input={<OutlinedInput name="filterbystage" />}
                        >
                            <option value="all">All Stages</option>
                            <option value="setup_lm_users">0. Setup - LMS Users</option>
                            <option value="recruitment_registration">1. Recruitment - Registration</option>
                            <option value="delivery_payment">3. Delivery - Payment</option>
                            <option value="delivery_attendance_reporting">3. Delivery - Attendance & Reporting</option>
                            <option value="closing_showcase">4. Closing - Showcase</option>
                            <option value="closing_quarterly_report">4. Closing - Quarterly Report</option>
                        </Select>
                        <Select
                            native
                            value={filterByStatus}
                            onChange={(e) => {
                                setFilterByStatus(e.target.value);
                                setPage(1);
                            }}
                            input={<OutlinedInput name="filterbystatus" />}
                        >
                            <option value="all">All Status</option>
                            <option value="yes">Marked as Completed</option>
                            <option value="no">Pending</option>
                        </Select>
                        <Select
                            native
                            value={perPage}
                            onChange={(e) => {
                                setPerPage(e.target.value);
                                setPage(1);
                            }}
                            input={<OutlinedInput name="perpage" />}
                        >
                            {ENTRIES_OPTIONS.map((option) => {
                                if (option.value > 100) return null;

                                return (
                                    <option key={option.value} value={option.value}>
                                        {option.label} per page
                                    </option>
                                );
                            })}
                        </Select>
                    </div>
                </Grid>
            </Grid>
            {loaded ? (
                <>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.headcell} style={{ width: "20px", paddingLeft: "0px", paddingRight: "0px" }}>
                                    {" "}
                                </TableCell>
                                <TableCell className={classes.headcell}>Logged On</TableCell>
                                <TableCell className={classes.headcell}>Stage</TableCell>
                                <TableCell className={classes.headcell}>Notification</TableCell>
                                <TableCell className={classes.headcell}>Action</TableCell>
                                <TableCell className={classes.headcell}>
                                    <div style={{ display: "flex", gap: "10px" }}>
                                        <Tooltip
                                            title="Mark all the current page notifications as completed"
                                            placement="top"
                                            arrow
                                            classes={{
                                                popper: classes.popper,
                                                tooltip: classes.tooltip,
                                            }}
                                        >
                                            <SuccessButton
                                                minWidth="none"
                                                style={{ padding: " 5px 12px" }}
                                                onClick={() => {
                                                    paginatedNotificationsList.forEach((notification) => {
                                                        if (!(notification.marked_as_completed === "yes")) {
                                                            setTimeout(() => {
                                                                handleMarkAsCompleted(notification.id, true);
                                                            }, 150);
                                                        }
                                                    });
                                                }}
                                            >
                                                <i
                                                    className="fa fa-check-square"
                                                    style={{
                                                        marginRight: "0px",
                                                    }}
                                                ></i>
                                            </SuccessButton>
                                        </Tooltip>
                                        <span>Mark As Completed</span>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isArrayExists(paginatedNotificationsList) ? (
                                paginatedNotificationsList.map((notification) => {
                                    return (
                                        <NotificationItem
                                            key={notification.id}
                                            notification={notification}
                                            classes={classes}
                                            loading={loading}
                                            history={history}
                                            onMarkAsCompleted={(nid, checked) => {
                                                if (checked) {
                                                    handleMarkAsCompleted(nid, checked);
                                                } else {
                                                    handleMarkAsUncompleted(nid);
                                                }
                                            }}
                                        />
                                    );
                                })
                            ) : (
                                <TableRow hover>
                                    <TableCell colSpan={6} className={classes.bodycell}>
                                        <WrapWord>No notifications found.</WrapWord>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <Pagination
                        total={notificationsList.length}
                        doneLoaded={true}
                        perPage={perPage}
                        page={page}
                        onPageChange={(p) => {
                            setPage(p);
                        }}
                    />
                </>
            ) : (
                <DotsLoader />
            )}
        </Wrapper>
    );
};

export default compose(withStyles(useStyles), withRouter)(NotificationsTable);
