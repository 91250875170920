/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';

import { getIDToken } from '../auth/fb';
import { doPromise } from '../../helpers/action';

export const fb_getCertificate = (id) => {
    return new Promise((resolve, reject) => {

        let promises = [];
        promises.push( firebase.firestore().collection("certificates").doc(id).get() );

        
        Promise.all( promises )
        .then(results => {
            let item = {};
            // add maintenance data
            if ( results && results[0] && results[0].exists ) {
                item = results[0].data();
            } // end - results[0]

            // add status if not set
            if ( !( item.status && !_isEmpty( item.status ) ) )
                item.status = 'active';

            // add type if not set
            if ( !( item.type && !_isEmpty( item.type ) ) )
                item.type = 'certificates';

            resolve(item);
        })
        .catch(error => {
            reject(error);
        });

    });  
}