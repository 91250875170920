/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists, validateEmail, isObjectExists } from '../../helpers/validation';
import { getSnapshotDataBySchema, getDocDataBySchema } from '../../helpers/schemas';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getClassQuarterlyReport = (schedule_id) => {
    return new Promise((resolve,reject) => {

        let promises = [];
        promises.push( callFunctionsAPI({ url: 'class', action: 'get_class_quarterly_report', formData: { schedule_id } }) );
        
        Promise.all( promises )
        .then(results => {
            let item = {};
            if ( results && results[0] && isObjectExists( results[0] ) ) {
                item = results[0];
            } // end - results[0]
            resolve(item);
        })
        .catch(error => {
            reject(error);
        });
    })
}

export const fb_updateClassQuarterlyReport = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'class', action: 'update_class_quarterly_report', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_triggerClassQuarterlyReport = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'class', action: 'trigger_class_quarterly_report', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_triggerPreviewReport = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'class', action: 'preview_class_quarterly_report', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}