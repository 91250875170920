/* eslint-disable */
import _random from 'lodash/random';

import { 
	GET_MENTORS,
    GET_MENTOR,
	ADD_MENTOR,
	UPDATE_MENTOR,
	DELETE_MENTOR,
    MENTOR_GET_CLASSES,
    MENTOR_GET_CLASS_ATTENDANCES,
    MENTOR_UPDATE_CLASS_ATTENDANCES,
    RESET_MENTOR,
    RESET_STUDENT 
} from '../actions/types';

const initialState = {
    list: null,
    attendances: null,
    badges: null,
    updated: false,
    attendances_rand: false,
    rand: false
};

export const mentors = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_MENTORS:
            return {
                ...state,
                list: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case GET_MENTOR:
            return {
                ...state,
                mentor: ( payload.mentor || {} ),
                rand: _random(1,9999)
            };
        case MENTOR_GET_CLASSES:
            return {
                ...state,
                classes: ( payload.classes || {} ),
                rand: _random(1,9999)
            };
        case MENTOR_GET_CLASS_ATTENDANCES:
            return {
                ...state,
                attendances: ( payload.attendances || {} ),
                attendances_rand: _random(1,9999)
            };
        case MENTOR_UPDATE_CLASS_ATTENDANCES:
        case ADD_MENTOR:
        case UPDATE_MENTOR:
        case DELETE_MENTOR:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_MENTOR:
            return {
                ...state,
                mentor: null,
                updated: false,
                rand: false
            };
        case RESET_STUDENT:
            return {
                ...state,
                classes: null,
                attendances: null, 
                updated: false,
                attendances_rand: null,
                rand: false
            };
        default:
            return state;
    }
}