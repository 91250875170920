/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import styled from "styled-components";
import Alert from "react-s-alert";
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import NativeSelect from '@material-ui/core/NativeSelect';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _toString from 'lodash/toString';

import ClassSessions from './sessions';
import ClassCalendar from './calendar';
import ProgramsTable from '../ProgramsPage/programs_table';
import ProductsTable  from '../ProgramPage/products_table';

import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormInputCopy from '../../components/FormInputCopy';
import FormSelect from '../../components/FormSelect';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormDatePicker from '../../components/FormDatePicker';
import FormTimePicker from '../../components/FormTimePicker';
import FormCheckboxes from '../../components/FormCheckboxes';
import FormEditor from '../../components/FormEditor';
import ToolTipInfo from '../../components/ToolTipInfo';

import { AInfoLink } from '../../styles/button';
import { FormBox } from '../../styles/form';
import { InfoBox } from '../../styles/message';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';
import { uploadMediaToStorage } from '../../helpers/firebase';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';

import { scheduleSchema } from '../../schemas/schedule';

import { toggleLoader } from '../../actions/global';

import { YES_NO_OPTIONS, DAY_OPTIONS, PLATFORM_COURSE_REGISTRATION_LINK, DEV_MODE } from '../../constants';

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    },
    nativeselect: {
        marginLeft: "10px"
    }
});

class ScheduleProgram extends React.Component {

    state = {
        randNum: false
    };

    handleFormUpdate = ( newValue, key ) => {
        const { onFormUpdate, schedule, programs, course_type, course_duration } = this.props;
        let newData = ( schedule && !_isEmpty( schedule ) ? cloneCollections( schedule ) : {} );

        switch( key ) {
            case 'program_id':
                newData[key] = newValue;
                newData['program_name'] = getSelectedValue( programs, newValue, 'id', 'name', '' );
                // see if need to re-disable this or not
                newData['course_duration'] = getSelectedValue( programs, newValue, 'id', 'course_duration', '' );
                newData['course_duration_label'] = getSelectedValue( programs, newValue, 'id', 'course_duration_label', '' );
                newData['course_type'] = getSelectedValue( programs, newValue, 'id', 'course_type', '' );
                newData['course_type_label'] = getSelectedValue( programs, newValue, 'id', 'course_type_label', '' );

                newData['price_per_pax_id'] = getSelectedValue( programs, newValue, 'id', 'price_per_pax_id', '' );
                newData['price_per_pax'] = getSelectedValue( programs, newValue, 'id', 'price_per_pax', '' );
                // setup course access for schedules
                let selected_program_products = getSelectedValue( programs, newValue, 'id', 'products', [] );
                newData['course_access'] = [];
                if ( selected_program_products && isArrayExists( selected_program_products ) ) {
                    _forEach( selected_program_products, product => {
                        newData['course_access'].push(product);
                    });
                } // end - selected_program_products
                break;
            case 'course_type':
                newData[key] = newValue;
                newData[key+'_label'] = getSelectedValue( course_type, newValue, 'id', 'label', '' );
                break;
            case 'course_duration':
                newData[key] = newValue;
                newData[key+'_label'] = getSelectedValue( course_duration, newValue, 'id', 'label', '' );
                break;
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    getAvailableProductOptions = () => {
        const { lms_courses, schedule } = this.props;
        let options = [];
        if ( schedule && schedule.course_access && isArrayExists( schedule.course_access ) ) {
            _forEach( schedule.course_access, item => {
                let lms_course = ( lms_courses && isArrayExists( lms_courses ) && item.lms_course_id && !_isEmpty( item.lms_course_id ) ? _find( lms_courses, c => _toString(c.id) === _toString( item.lms_course_id ) ) : false );
                    let option = {
                        id: item.id,
                        course_code: ( item && item.course_code && !_isEmpty( item.course_code ) ? item.course_code : '' ),
                        name: ( lms_course && lms_course.name || '' ) + ( lms_course && lms_course.id && DEV_MODE ? ' ('+item.id+'/'+lms_course.id+')' : '' ),
                        contact_hour: ( item && item.contact_hour && !_isEmpty( item.contact_hour ) ? item.contact_hour : '' ),
                        product_level: ( item && item.product_level && !_isEmpty( item.product_level ) ? item.product_level : '' ),
                        product_credit: ( item && item.product_credit && !_isEmpty( item.product_credit ) ? item.product_credit : '' ),
                        // disabled: true
                    };
                    options.push(option);
            });
        } // end - schedule.course_access
        return options;
    }

    showAvailableCourseAccess = () => {
        const { lms_courses, schedule } = this.props;
        let options = [],
            program = this.getSelectedProgram();
        if ( schedule && schedule.course_access && isArrayExists( schedule.course_access ) ) {
            _forEach( schedule.course_access, item => {
                let product = ( program && program.products && isArrayExists( program.products ) ? _find( program.products, { id: item.id } ) : false ),
                    lms_course = ( lms_courses && isArrayExists( lms_courses ) && item.lms_course_id && !_isEmpty( item.lms_course_id ) ? _find( lms_courses, c => _toString(c.id) === _toString( item.lms_course_id ) ) : false );
                    let option = {
                        value: item.id,
                        label: ( product && product.course_code && !_isEmpty( product.course_code ) ? '['+product.course_code+'] ' : '' ) + ( lms_course.name || '' ) + ( lms_course.id && DEV_MODE ? ' ('+item.id+'/'+lms_course.id+')' : '' ),
                        // disabled: true
                    };
                    // add option keys
                    option.o_lms_course_id = ( lms_course.id ? _toString( lms_course.id ) : '' );
                    options.push(option);
            });
        } // end - schedule.course_access
        return ( isArrayExists( options ) ? _sortBy( options, [o => o.label.toLowerCase()] ) : [] );
    }

    getSelectedProgram = () => {
        const { schedule, programs } = this.props;
        return ( schedule && schedule.program_id && !_isEmpty( schedule.program_id ) && programs && isArrayExists( programs ) ? _find( programs, { id: schedule.program_id } ) : false );
    }

    isProgramSelected = () => {
        const { schedule } = this.props;
        return ( schedule && schedule.program_id && !_isEmpty( schedule.program_id ) ? true : false );
    }

    getFieldOptions = (field) => {
        const { schedule, users, regions, locations, courses, course_type, course_duration } = this.props;

        switch( field.name ) {
            case 'course_type':
                return getSelectOptions({ list: ( course_type || false ), options: [], keys: { value: 'id', label: 'label', disabled: 'status' }, sortList: 'label' });
            case 'course_duration':
                return getSelectOptions({ list: ( course_duration || false ), options: [], keys: { value: 'id', label: 'label', disabled: 'status' }, sortList: 'label' });
            case 'principle':
            case 'coordinator':
                return getSelectOptions({ list: ( users && schedule.region ? _filter( users, { region: schedule.region } ) : false ), options: [], keys: { value: 'email', label: 'name', disabled: 'status' }, sortList: 'name' });
            case 'region':
                return getSelectOptions({ list: ( regions || false ), options: [], keys: { value: 'id', label: 'label' }, sortList: 'label' });
            case 'training_location_id':
                return getSelectOptions({ list: ( locations || false ), options: [], keys: { value: 'id', label: 'name', disabled: 'status' }, sortList: 'name' });
            case 'publish':
            case 'show_price_per_pax':
                return YES_NO_OPTIONS;
            case 'repeat_weekly':
                return DAY_OPTIONS;
            default:
                return [];
        }
    }

    getFieldValue = (field) => {
        const { schedule } = this.props;
        switch( field.name ) {
            default:
                return ( schedule && schedule[field.name] ? schedule[field.name] : ( field.default || '' ) );
        }
    }

    isFieldDisabled = (schema) => {
        const { authData, schedule, formType } = this.props;
        var disabled = false;

        if ( schema && schema.disabled && isArrayExists( schema.disabled ) ) {
            schema.disabled.forEach( condition => {
                switch( condition ) {
                    case 'admin':
                        if ( !isAdmin( authData ) )
                            disabled = true;
                        break;
                    case 'add':
                        if ( formType && formType === 'add' )
                            disabled = true;
                        break;
                    case 'update':
                        if ( formType && formType === 'update' )
                            disabled = true;
                        break;
                }
            });
        } // end - schema

        if ( schema.id === 'course_type' || schema.id === 'course_duration' ) {
            if ( this.isProgramSelected() )
                disabled = true;
        } // end - schema.id

        return disabled
    }

    getTooltip = (schema) => {
        switch( schema.id ) {
            // case 'lms_course_link':
            //     return <a href="https://community.canvaslms.com/t5/Instructor-Guide/How-do-I-enable-schedule-self-enrollment-with-a-join-code-or/ta-p/830" target="_blank">Refer to this method</a>;
            case 'program_name':
                return 'This program selected for your class, along with its details & pricing, will be published on Registration Page. Once set, it cannot be modified.';
            default:
                return;
        }
    }

    getField = (id) => {
        const { massEditMode, formType } = this.props;
        let schema = _find( scheduleSchema, { id } );
        return ( schema ? {
            name: ( schema.id || '' ),
            label: ( schema.label || '' ) + ( isSchemaRequired(schema,formType) && !massEditMode ? ' (Required)' : '' ),
            field_type: ( schema.field || '' ),
            default: ( massEditMode ? null : ( schema.default || null ) ),
            disabled: ( schema.disabled ? this.isFieldDisabled( schema ) : false ),
            tooltip: this.getTooltip(schema)
        } : null );
    }

    renderField = (id) => {
        const { authData, schedule } = this.props;
        let field = this.getField(id);
        if ( field && field.field_type && !_isEmpty( field.field_type ) ) {
            switch( field.field_type ) {
                case 'text':
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'textarea':
                    return <FormInput {...field} rows={( 'notes' === id ? 6 : 3 )} multiline={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'text_number':
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'email':
                    return <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'select':
                    return <FormSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} disableNative={true} onChange={this.handleFormUpdate} />;
                case 'multiselect':
                    return <FormMultiSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} />;
                case 'datepicker':
                    return <FormDatePicker {...field} noDefaultVal={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'tinymce_editor':
                    return <FormEditor id={id + '-' + ( schedule && schedule.id && !_isEmpty( schedule.id ) ? schedule.id : '' )} {...field} value={this.getFieldValue(field)} height={250} />;
            }
        } // end - field.field_type
    }

    render = () => {
        const { classes, authData, schedule, programs, price_per_pax, formType, contact_hour, product_level, product_credit } = this.props;
        return (
        <>

            <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff" }}>
                <FormBox>
                    <Typography variant="h4" className={classes.boxheading}>{ !this.isProgramSelected() ? 'Select Program' : 'Program Details' }</Typography>
                    <Grid container spacing={3}>

                        <Grid item xs={12}>{this.renderField('region')}</Grid>
                        <Grid item xs={12} sm={6}>{this.renderField('course_duration')}</Grid>
                        <Grid item xs={12} sm={6}>{this.renderField('course_type')}</Grid>

                        { formType && formType === 'add' && schedule && ( 
                            ( schedule.course_duration && !_isEmpty( schedule.course_duration ) ) || 
                            ( schedule.course_type && !_isEmpty( schedule.course_type ) ) 
                        ) ? ( !this.isProgramSelected() ? (
                        <Grid item xs={12}><ProgramsTable
                            selectable={true}
                            filterBy={{
                                course_duration: ( schedule && schedule.course_duration || false ),
                                course_type: ( schedule && schedule.course_type || false )
                            }}
                            programs={programs}
                            price_per_pax={price_per_pax}
                            onSelected={(newValue) => this.handleFormUpdate(newValue,'program_id')}
                            /></Grid>
                        ) : null ) : null }

                        { this.isProgramSelected() ? (
                        <>
                            <Grid item xs={12}>{this.renderField('program_name')}</Grid>
                            <Grid item xs={12}>{this.renderField('price_per_pax')}</Grid>
                            <Grid item xs={12}>
                                <Typography variant="h4" className={classes.boxheading}>
                                    <ToolTipInfo 
                                        content={(
                                        <>
                                            Courses enabled here will carry forward to <a href="#" onClick={event => {
                                        const { currentSchedule, history } = this.props;
                                        event.preventDefault();
                                        history.push('/course_access',{ schedule_id: ( schedule && schedule.id || '' ) });
                                    }}>Course Access</a> module These are the courses you will run in the class for the students. 
                                        </>
                                        )} />
                                    Course Access
                                </Typography>
                                {/* <FormCheckboxes
                                    label=""
                                    value={( schedule.course_access || false )}
                                    options={this.showAvailableCourseAccess()}
                                    keys={['lms_course_id']}
                                    name="course_access"
                                    xs={4}
                                    sm={4}
                                    showAllCheck={false}
                                    onChange={() => { }} /> */}
                                <ProductsTable 
                                    viewMode={true}
                                    value={( schedule.course_access || false )}
                                    products_options={this.getAvailableProductOptions()}
                                    contact_hour={contact_hour}
                                    product_level={product_level}
                                    product_credit={product_credit}
                                    onUpdate={() => { /* do nothing */ }} />
                            </Grid>
                        </>
                        ) : null }

                    </Grid>
                </FormBox>
            </Paper>

        
        </>
        );
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(ScheduleProgram);