/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';

import ScreenLoader from '../../components/ScreenLoader';

import { GreyButton } from '../../styles/button';


import { signInWithCustomToken } from '../../actions/auth';
import { PLATFORM_NAME_LONG, PLATFORM_URL } from '../../constants';

class SSOVerifier extends React.Component {

    componentDidMount() {
        const { history, location = {} } = this.props;
        const { ltoken, redirect } = queryString.parse(location.search);

        // make sure token is available
        if ( ltoken && !_isEmpty( ltoken ) ) {
            this.props.dispatch( signInWithCustomToken( ltoken, history, redirect ) );
        } else {
            history.push('/login');
        }
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return <ScreenLoader />
    }

}

// const mapStateToProps = state => {
//     return {
//         authData: state.auth && state.auth.user ? state.auth.user : null
//     }
// }

export default compose(
    connect(),
    withRouter
)(SSOVerifier);