/* eslint-disable */
import _random from 'lodash/random';

import {
	GET_USERS,
	GET_USERS_OPTIONS,
    ADD_USER,
    EDIT_USER,
    DELETE_USER,
	GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getAPIErrorMessage } from '../../helpers/action';
import { appChangesReset } from '../misc';

import { fb_getUsers, fb_getUsersOptions, fb_addUser, fb_editUser, fb_deleteUser } from './fb';

export const getUsers = () => {
	return dispatch => {

		fb_getUsers()
		.then(list => {
			dispatch({
				type: GET_USERS,
				payload: { list }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_USERS,
				payload: { list: [] }
			});
		})

	}
}

export const getUsersOptions = (props) => {
	return dispatch => {
		fb_getUsersOptions(list => {
			dispatch({
				type: GET_USERS_OPTIONS,
				payload: { list }
			});	
		},props);
	}
}

export const addUser = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addUser(formData)
		.then( results => {
			fb_getUsers()
			.then(list => {
				dispatch({ type: GET_USERS, payload: { list } });	
				dispatch({ type: ADD_USER });
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("<strong>Account Creation Email sent!</strong><br/>An email has been sent to instruct new user of the next steps to create an account. Notify the user to check his/her inbox.",5000);
			})
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const editUser = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_editUser(formData)
		.then( results => {
			fb_getUsers()
			.then(list => {
				dispatch({ type: GET_USERS, payload: { list } });	
				dispatch({ type: EDIT_USER });
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("User Updated");
			})
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const deleteUser = (uid) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteUser(uid)
		.then( results => {
			fb_getUsers()
			.then(list => {
				dispatch({ type: GET_USERS, payload: { list } });	
				dispatch({ type: DELETE_USER });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("User Deleted!");
			})
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}