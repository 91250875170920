/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

import AppWrapper from '../../components/AppWrapper';
import ModalView from '../../components/ModalView';
import FormSelect from '../../components/FormSelect';
import FormDatePicker from '../../components/FormDatePicker';

import List from './list';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { SuccessTag, GreyTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';
import { InfoBox } from '../../styles/message';

import { isAdmin } from '../../helpers/auth'; 
import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';
import { triggerErrorAlert } from '../../helpers/alert';
import { getMomentTime } from '../../helpers/date';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';

const FilterBox = ({
    authData,
    start_date = 0,
    end_date = 0,
    region = 'all',
    regions = [],
    onFilter = () => {}
}) => {
    const [ open, setOpen ] = useState(false);
    const [ formData, setFormData ] = useState({});

    useEffect(() => {
        setFormData({
            start_date,
            end_date,
            region
        });
    }, [ open ]);

    const handleFilter = () => {
        onFilter(formData);
        setOpen(false);
    }

    const handleUpdate = (newValue,key) => {
        setFormData({
            ...formData,
            [key]: newValue
        });
    }

    const tagStyle = { marginRight: '5px', backgroundColor: "#ddd" };

    return (
    <>

        <div>
            <GreyTag key="date" style={tagStyle}>{ "From " + getMomentTime(start_date,'YYYY-MM-DD') + ' Until ' + getMomentTime(end_date,'YYYY-MM-DD') }</GreyTag>
            <GreyTag key="region" style={tagStyle}>Partner: { region && region === 'all' ? 'All' : getSelectedValue(regions,region, 'id', 'label', '-' ) }</GreyTag>
            <InfoButton size="small" key="filter" style={{ padding: "5px 8px" }} onClick={() => setOpen(true)}><i className="fa fa-filter"></i>Customize Filter</InfoButton>
        </div>
    
        <ModalView 
            open={open}
            title="Filter Payment History"
            onClose={() => setOpen(false)}
            actionLabel="Filter"
            doAction={handleFilter}
            contents={(
            <div>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={6}><FormDatePicker InputAdornmentProps={{ disabled: true }} value={formData.start_date || 0} label="From" name="start_date" onChange={handleUpdate} /></Grid>
                    <Grid item xs={6}><FormDatePicker InputAdornmentProps={{ disabled: true }} value={formData.end_date || 0} label="Until" name="end_date" onChange={handleUpdate} /></Grid>
                    <Grid item xs={12}>
                        <div style={{ paddingTop: '7px' }}>
                            <FormSelect 
                                value={formData.region || ''} 
                                label="Partner" 
                                name="region" 
                                options={getSelectOptions({ 
                                    list: _sortBy( regions, ['label'] ), 
                                    options: [{ value: 'all', label: 'All Partners'}], 
                                    keys: { value: 'id', label: 'label' } 
                                })} disabled={( isAdmin( authData ) ? false : true )} 
                                onChange={handleUpdate} />
                        </div>
                    </Grid>
                </Grid>
            </div>
            )} />

    </>
    )
}

const getList = ({
    start_date = 0,
    end_date = 0,
    region = 'all',
}) => {
    return new Promise((resolve,reject) => {
        callFunctionsAPI({ method: 'GET', url: 'data', action: 'payment_history', params: [
            { key: 'start_date', value: start_date },
            { key: 'end_date', value: end_date },
            { key: 'region', value: region },
        ] })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
    });
}

const PaymentHistory = ({
    authData,
}) => {
    const [ list, setList ] = useState([]);
    const [ loaded, setLoaded ] = useState(false);
    const [ startDate, setStartDate ] = useState( moment().utcOffset(8).subtract( 12, 'months' ).startOf('date').valueOf() );
    const [ endDate, setEndDate ] = useState( moment().utcOffset(8).endOf('date').valueOf() );
    const [ region, setRegion ] = useState(( isAdmin( authData ) ? 'all' : authData.region ));
    const [ regions, setRegions ] = useState([]);

    useEffect(() => {
        let mounted = true;
        getList({
            start_date: startDate,
            end_date: endDate,
            region
        })
        .then(data => {
            if ( mounted ) {
                setList(data.payments);
                setRegions(data.regions);
                setLoaded(true);
            }
        })
        .catch(error => {
            triggerErrorAlert( getAPIErrorMessage(error) );
        });
        return () => {
            mounted = false;
        };
    }, []);

    const handleFilter = (data) => {
        setStartDate(data.start_date);
        setEndDate(data.end_date);
        setRegion(data.region);
        setLoaded(false);
        getList(data)
        .then(data => {
            setList(data.payments);
            setRegions(data.regions);
            setLoaded(true);
        })
        .catch(error => {
            setLoaded(true);
            triggerErrorAlert( getAPIErrorMessage(error) );
        });
    }

    return (
    <AppWrapper 
        title="Payment History"
        subtitle="Summary"
        onLoad={( loaded ? false : true )}
        contents={(
        <>
        
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FilterBox
                        authData={authData}
                        start_date={startDate}
                        end_date={endDate}
                        region={region}
                        regions={regions}
                        onFilter={handleFilter} />
                </Grid>
                <Grid item xs={12}>
                    <List 
                        authData={authData} 
                        list={list} />
                </Grid>
            </Grid>


        
        </>
        )} />
    )

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(PaymentHistory);