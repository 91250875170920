import _random from 'lodash/random';

import { 
    IS_LOGGED_IN,
    AUTH_LOGIN,
    AUTH_LOGOUT,
	AUTH_GET_PROFILE,
	AUTH_EDIT_PROFILE
} from '../actions/types';

const initialState = {
    user: null,
    profile: null,
    updated: false,
    rand: false
};

export const auth = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case IS_LOGGED_IN:
            return {
                ...state,
                user: payload,
                rand: _random(1,9999)
            };
        case AUTH_LOGIN:
                return {
                    ...state,
                    user: payload,
                    rand: _random(1,9999)
                };
        case AUTH_LOGOUT:
            return {
                ...state,
                user: null,
                rand: _random(1,9999)
            };
        case AUTH_GET_PROFILE:
            return {
                ...state,
                profile: ( payload.profile || false ),
                rand: _random(1,9999)
            };
        case AUTH_EDIT_PROFILE:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        default:
            return state;
    }
}