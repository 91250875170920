/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from 'moment';
import Papa from 'papaparse';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _toLower from 'lodash/toLower';
import _split from 'lodash/split';
import _map from 'lodash/map';

import StudentNameToDatabase from '../../modules/StudentNameToDatabase';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';
import FormCheckbox from '../../components/FormCheckbox';
import FormCheckboxes from '../../components/FormCheckboxes';
import ButtonActions from '../../components/ButtonActions';
import StudentEnrollmentStatus from '../../components/StudentEnrollmentStatus';
import ToolTipInfo from '../../components/ToolTipInfo';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from '../../styles/tag';
import { ErrorBox } from '../../styles/message';
import { WrapWord } from '../../styles/misc';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp, getAge } from '../../helpers/date';
import { isAdmin, hasAccessRights, getUserRegionFilter } from '../../helpers/auth';
import { getStudentEnrollmentStatus } from '../../helpers/trainees';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { editTrainee, deleteTrainee } from '../../actions/trainees';
import { appChangesMade } from '../../actions/misc';

import { traineeSchema } from '../../schemas/trainee';

import { PLATFORM_COURSE_REGISTRATION_LINK, GENDER_OPTIONS, ENROLLMENT_STATUS_OPTIONS, PLATFORM_PAYMENT_URL } from '../../constants';

const SuccessIcon = styled.i`
    color: #64dd17;
`;

const GreyIcon = styled.i`
    color: #64dd17;
`;

const WarningIcon = styled.i`
    color: #ff6f00;
`;

const PaymentColumn = styled.div`
    padding: 16px;
    font-size: 11px;
    height: 100%;
    width: 100%;
    max-width: 200px;
    ${props => {
        if ( props.overdue && props.overdue === 'yes' ) {
            return `
            background: #ffebee;
            `;
        }
        if ( props.disabled && props.disabled === 'yes' ) {
            return `
            .disabled-wrapper {
                opacity: 0.4;
            }
            `;
        }
    }};
`;

const CheckboxWrapper = styled.div`
    .MuiTypography-body1 {
        font-size: 11px;
    }
    .MuiCheckbox-root { padding: 4px; }
    .MuiFormControlLabel-root {
        margin-left: -8px;
        margin-right: 0px;
    }
`;

const TextareaWrapper = styled.div`
    textarea {
        width: 100%;
        max-width: 150px;
        font-size: 11px;
        padding: 2px;
        overflow: scroll-y;
    }
`;

const EmailButtonWrapper = styled.span`
    display: inline-block;
    & > div { 
        display: inline-block; 
        & > button {
            padding: 4px 8px;
            margin-top: 4px;
            font-size: 1.15rem;
            min-width: 0px;
            i { display: none; }
        }
    }
`;

const useStyles = theme => ({
    popper: {
        zIndex: '3000',
        pointerEvents: 'auto'
    },
    tooltip: {
        fontSize: '14px',
        color: '#fff',
        padding: '5px 8px',
    }
});

// course access schema
const PAYMENT_TRACKING_SCHEMA = {
    id: '', // id based on date format YYYY-MM,
    payment_made: 'no', // course profile ID
    invoice_sent: 'no', // from course profile
    notes: '',
    payment_id: ''
};

class StudentsTable extends React.Component {

    utcOffSet = 8;
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        filterBy: 'active',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openPaymentModal: false,
        modalData: false,
        traineeData: false,
        modalType: false,
        randNum: false,
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        
    }

    doCSVDownload = (results) => {
        let csv = Papa.unparse( results, { delimiter: ',' } );
        let blob = new Blob( [csv] , { type: 'text/csv;charset=utf-8;' }); //new way
        let uri = window.URL.createObjectURL(blob);
        let link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", '_blank');
        link.setAttribute("download", "export_payment_tracking.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    handleCSVDownload = () => {
        const { trainees, columns, regions, mentors } = this.props;
        const { checked } = this.state;
        if ( checked && isArrayExists( checked ) ) {
            let csvData = {
                    fields: [
                        "Confirmation Date",
                        "Name",
                        "Enrolment Status",
                        "Payment Level",
                        "Payment Link"
                    ],
                    data: []
                };

            if ( columns && isArrayExists( columns ) ) {
                _forEach( columns, column => {
                    csvData.fields.push(( column.label || column.id ) + " - Course Count");
                    csvData.fields.push(( column.label || column.id ) + " - Invoice Sent");
                    csvData.fields.push(( column.label || column.id ) + " - Reminded");
                    csvData.fields.push(( column.label || column.id ) + " - Paid");
                    csvData.fields.push(( column.label || column.id ) + " - Notes");
                });
            } // end - columns

            _forEach( checked, selected => {
                let item = _find( trainees, { id: selected.id } );
                if ( item && !_isEmpty( item ) ) {
                    let row = [];
                    const payment_amount = this.getStudentPaymentAmount(item);
                    row.push( ( item.confirmation_email_date ? getMomentTime( item.confirmation_email_date, 'DD MMM YYYY' ) : '' ) );
                    row.push( ( item && item.name || '' ) );
                    row.push( ( item && item.enrollment_status ? item.enrollment_status : '' ) );
                    row.push( ( item && item.payment_level ? item.payment_level : 'l0' ) );
                    row.push( PLATFORM_PAYMENT_URL + ( item && item.id ? '?id='+item.id + ( payment_amount && !_isEmpty( payment_amount ) ? '&amt='+payment_amount : '') : '' ) );

                    if ( columns && isArrayExists( columns ) ) {
                        _forEach( columns, column => {
                            let payment = ( item && item.payment_tracking && isArrayExists( item.payment_tracking ) ? _find( item.payment_tracking, { id: column.id } ) : false );
                            row.push( this.getCourseCount(column,item) );
                            row.push( ( payment && payment.invoice_sent ? payment.invoice_sent : '' ) );
                            row.push( ( payment && payment.payment_reminded ? payment.payment_reminded : '' ) );
                            row.push( ( payment && payment.payment_made ? payment.payment_made : '' ) );
                            row.push( ( payment && payment.notes ? payment.notes : '' ) );
                        });
                    } // end - columns

                    csvData.data.push(row);
                } // end - trainee
            });
            this.doCSVDownload(csvData);
        } else {
            triggerErrorAlert('Please select at least one student');
        } // end - checked
    }

    handleSendSingleEmail = (email_type,column,trainee) => () => {
        const { onEmailSend, changesMade } = this.props;    
        if ( changesMade ) {
            triggerErrorAlert('Please save changes first');
        } else {
            let list = [{ id: trainee.id, email: ( trainee.email || '' ) }];
            if ( onEmailSend ) {
                onEmailSend({ 
                    trainees: list,
                    column: ( column || false ),
                    email_type     
                });
            } // end = onEmailSend
        }
    }

    handleSendMassEmail = (email_type) => () => {
        const { trainees, onEmailSend, changesMade } = this.props;
        const { checked } = this.state; 
        let error = false,
            list = [];
        // compile trainees list
        if ( checked && isArrayExists( checked ) ) {
            _forEach( checked, item => {
                let trainee = _find( trainees, { id: item.id } );
                if ( trainee )
                    list.push({ id: trainee.id, email: ( trainee.email || '' ) });
            });
        } // end - checked

        if ( !( list && isArrayExists( list ) ) )
            error = 'Please select at least one student';

        if ( changesMade )
            error = 'Please save changes first';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            if ( onEmailSend ) {
                onEmailSend({ 
                    trainees: list,
                    column: false,
                    email_type     
                });
            } // end = onEmailSend
        }
    }

    handleTraineeUpdate = (newData,trainee_id) => {
        const { trainees, onUpdate } = this.props;
        let newTrainees = ( trainees && isArrayExists( trainees ) ? cloneCollections( trainees ) : [] ),
            index = ( newTrainees && isArrayExists( newTrainees ) ? _findIndex( newTrainees, { id: trainee_id }) : -1 );
        if ( index >= 0 ) {
            newTrainees[index].payment_tracking = newData;
        }
        if ( onUpdate ) {
            onUpdate(newTrainees);
            this.props.dispatch(appChangesMade());
        }
    }

    handleTraineePaymentLevelUpdate = (newData,trainee_id) => {
        const { trainees, onUpdate } = this.props;
        let newTrainees = ( trainees && isArrayExists( trainees ) ? cloneCollections( trainees ) : [] ),
            index = ( newTrainees && isArrayExists( newTrainees ) ? _findIndex( newTrainees, { id: trainee_id }) : -1 );
        if ( index >= 0 ) {
            newTrainees[index].payment_level = newData;
        }
        if ( onUpdate ) {
            onUpdate(newTrainees);
            this.props.dispatch(appChangesMade());
        }
    }

    handlePaymentUpdate = () => {
        const { trainees, onUpdate } = this.props;
        const { modalData, modalType, traineeData, checked } = this.state;
        let error = false;

        // make sure notes doesn't exceed 500 chars
        if ( modalData && modalData.notes && !_isEmpty( modalData.notes ) && _size( modalData.notes ) > 500 )
            error = "Notes cannot be exceed more than 500 characters";

        if ( modalType && modalType === 'payment_form' ) {
            let index = ( modalData && modalData.id && traineeData && traineeData.payment_tracking && isArrayExists( traineeData.payment_tracking ) ? _findIndex( traineeData.payment_tracking, { id: modalData.id } ) : -1 ),
                newData = ( traineeData && traineeData.payment_tracking && isArrayExists( traineeData.payment_tracking ) ? cloneCollections( traineeData.payment_tracking ) : [] );

            if ( error ) {
                triggerErrorAlert(error);
            } else {
                if ( index >= 0 ) {
                    newData[index] = cloneCollections(modalData);
                } else {
                    newData.push(modalData);
                }
                // do update
                this.handleTraineeUpdate(newData,traineeData.id);
                this.setState({ openPaymentModal: false, modalData: false, traineeData: false, modalType: false });
            } // end - error

        } else if ( modalType && modalType === 'mass_edit_payment' ) {
            // check for month select
            if ( !(modalData && modalData.ids && isArrayExists( modalData.ids )) )
                error = "Please Select a Month";

            if ( error ) {
                triggerErrorAlert(error);
            } else {
                let newTrainees = ( trainees && isArrayExists( trainees ) ? cloneCollections( trainees ) : [] );
                if ( checked && isArrayExists( checked ) ) {
                    _forEach( checked, item => {
                        let tindex = ( newTrainees && isArrayExists( newTrainees ) ? _findIndex( newTrainees, { id: item.id }) : -1 );
                        if ( tindex >= 0 ) {
                            _forEach( modalData.ids, month => {
                                let pindex = ( trainees[tindex].payment_tracking && isArrayExists( trainees[tindex].payment_tracking ) ? _findIndex( trainees[tindex].payment_tracking, { id: month.id } ) : -1 );
                                if ( pindex >= 0 ) {
                                    if ( modalData.payment_made && ( modalData.payment_made === 'yes' || modalData.payment_made === 'no' ) ) {
                                        newTrainees[tindex].payment_tracking[pindex].payment_made = modalData.payment_made;
                                    }
                                    if ( modalData.invoice_sent && ( modalData.invoice_sent === 'yes' || modalData.invoice_sent === 'no' ) ) {
                                        newTrainees[tindex].payment_tracking[pindex].invoice_sent = modalData.invoice_sent;
                                    }
                                    if ( modalData.notes && !_isEmpty( modalData.notes ) ) {
                                        newTrainees[tindex].payment_tracking[pindex].notes = modalData.notes;
                                    } 
                                    if ( modalData && modalData.payment_level && !_isEmpty( modalData.payment_level ) ) {
                                        newTrainees[tindex].payment_level = modalData.payment_level;
                                    } 
                                } else {
                                    // add array if empty
                                    if ( !( newTrainees[tindex].payment_tracking && isArrayExists( newTrainees[tindex].payment_tracking ) ) )
                                        newTrainees[tindex].payment_tracking = [];

                                    newTrainees[tindex].payment_tracking.push({
                                        id: month.id,
                                        payment_made: ( modalData.payment_made && modalData.payment_made === 'yes' ? 'yes' : 'no' ),
                                        invoice_sent: ( modalData.invoice_sent && modalData.invoice_sent === 'yes' ? 'yes' : 'no' ),
                                        notes: ( modalData.notes && !_isEmpty( modalData.notes ) ? modalData.notes : '' ),
                                        payment_id: ''
                                    });

                                    if ( modalData && modalData.payment_level && !_isEmpty( modalData.payment_level ) ) {
                                        newTrainees[tindex].payment_level = modalData.payment_level;
                                    } 
                                } // end - pindex
                            }); // end - modalData.ids
                        } // end - tindex
                    });
                } // end - checked
                if ( onUpdate ) {
                    onUpdate(newTrainees);
                    this.props.dispatch(appChangesMade());
                }
                this.setState({ openPaymentModal: false, modalData: false, traineeData: false, modalType: false, checked: [], allCheck: false });
            } // end - modalData
        }
    }

    handleFormUpdate = (newValue,key,subkey,subVal) => {
        const { modalData } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} );
        switch( key ) {
            // case 'course_type':
            //     let selectedType = ( course_type && isArrayExists( course_type ) ? _find( course_type, { id: newValue } ) : false );
            //     newData[key] = newValue;
            //     newData[key + '_label'] = ( selectedType && selectedType.label ? selectedType.label : '' );
            //     break;
            default:
                if ( subkey && ( subkey === 'checked' || subkey === 'unchecked' ) && subVal && !_isEmpty( subVal ) ) {
                    // use subVal as the new value
                    newData[key] = subVal;
                } else {
                    // otherwise - just update normally
                    newData[key] = newValue
                }
                break;
        } // end - key
        this.setState({ modalData: newData });
    }

    getActions = () => {
        const { authData } = this.props;
        let actions = [];

        actions.push({ 
            id: 'massedit', label: 'Mass Edit Payment Tracking', icon: 'fa-edit', disabled: false, onClick: () => {
                const { checked } = this.state;
                if ( checked && isArrayExists( checked ) ) {
                    this.setState({ 
                        openPaymentModal: true,
                        modalData: {
                            ids: [],
                            payment_level: '',
                            payment_made: '',
                            invoice_sent: '',
                            notes: ''
                        },
                        modalType: 'mass_edit_payment'
                    });
                } else {
                    triggerErrorAlert("Please select at least one student!")
                } // end - checked
            }
        });

        actions.push({ 
            id: 'invoice', label: 'Send Invoice', icon: 'fa-envelope-o', onClick: this.handleSendMassEmail('invoice')
        });

        actions.push({ 
            id: 'payment_reminder', label: 'Send Payment Reminder', icon: 'fa-envelope-o', onClick: this.handleSendMassEmail('payment_reminder')
        });

        actions.push({ 
            id: 'downloadcsv', label: 'Download Selected Data as CSV', icon: 'fa-cloud-download', onClick: this.handleCSVDownload
        });

        return actions;
    }

    getCells = () => {
        const { columns, schedule, price_per_pax } = this.props;
        let today = moment().utcOffset(this.utcOffSet).startOf('month').format('YYYY-MM'),
            cells = [
                // { id: 'region', label: 'Licensee', render: this.renderCell('region') },
                // { id: 'class_name', label: 'Class Name', render: this.renderCell('class_name') },
                // { id: 'created_on', label: 'Registration Date', render: this.renderCell('created_on') },
                { id: 'confirmation_email_date', label: 'Confirmation Date', render: this.renderCell('confirmation_email_date') },
                // { id: 'email', label: 'Email', render: this.renderCell('email') },
                { id: 'name', label: 'Name', render: (item) => <StudentNameToDatabase {...item} /> },
                { id: 'enrollment_status', label: 'Class Status', render: this.renderEnrollmentStatus('enrollment_status') },
                { id: 'payment_history', label: 'Payment History', render: this.renderCell('payment_history') },
            ];


        let selected_price = this.getSelectedPrice();
        if ( selected_price ) {
            cells.push({ id: 'payment_level', label: (
            <>
                <ToolTipInfo content={
                    <>
                        SELECT PAYMENT LEVEL<br /><br />
                        Selected payment level will be reflected in the payment link
                    </>
                    } />
                Payment Level
            </>
            ), render: this.renderCell('payment_level') });
        } // end - selected_price

        if ( columns && isArrayExists( columns ) ) {
            _forEach( _sortBy(columns,['id']), column => {
                cells.push({
                    id: column.id,
                    label: column.label,
                    headstyle: ( column.id === today ? { background: '#ffb74d' } : null ),
                    style: { padding: '0px' },
                    render: this.renderPaymentColumn(column)
                });
            });
        } // end - columns

        return cells;
    }

    getCourseCount = (column,trainee) => {
        const data = this.getSelectedPaymentData(column,trainee);
        let count = 0;
        if ( trainee && trainee.course_access && isArrayExists( trainee.course_access ) ) {
            _forEach( trainee.course_access, course => {
                if ( course.activated <= column.end_at ) {
                    if ( course.deactivated ) {
                        if ( course.deactivated >= column.end_at ) {
                            count++
                        } else {
                            if ( course.deactivated >= column.start_at ) {
                               count++ 
                            }
                        }
                    } else if ( course.concluded ) {
                        if ( course.concluded >= column.end_at ) {
                            count++
                        } else {
                            if ( course.concluded >= column.start_at ) {
                               count++ 
                            }
                        }
                    } else {
                        count++;
                    } // end - course.deactivated
                } // end - course.activated
            });
        } // end - trainee.course_access
        return count;
    }

    getSelectedPaymentData = (column,trainee) => {
        let selected = ( trainee && trainee.payment_tracking && isArrayExists( trainee.payment_tracking ) ? _find( trainee.payment_tracking, { id: column.id } ) : false );
        return ( selected && isObjectExists( selected ) ? cloneCollections( selected ) : false );
    }

    getSelectedPrice = () => {
        const { schedule, price_per_pax } = this.props;
        return ( schedule && schedule.price_per_pax_id && !_isEmpty( schedule.price_per_pax_id ) && price_per_pax && isArrayExists( price_per_pax ) ? _find( price_per_pax, { id: schedule.price_per_pax_id } ) : false );
    }

    getPaymentLevels = (addBlank) => {
        const { schedule, price_per_pax } = this.props;
        let selected_price = this.getSelectedPrice(),
            options = [];
        if ( addBlank ) {
            options.push({ key: 'none', value: '', label: 'None' });
        }   
        if ( selected_price ) {
            for ( let i = 0; i < 6; i++ ) {
                if ( selected_price['amount_l'+i] && !_isEmpty( selected_price['amount_l'+i] ) ) {
                    options.push({
                        key: i,
                        value: 'l'+i,
                        label: 'L'+i+' - RM'+selected_price['amount_l'+i],
                        amount: selected_price['amount_l'+i]
                    });
                }
            }
        } // end - selected_price
        return options;
    }

    getStudentPaymentAmount(trainee) {
        let payment_levels = this.getPaymentLevels();
        let payment_amount = ( payment_levels && payment_levels[0] && payment_levels[0].amount && !_isEmpty( payment_levels[0].amount ) ? payment_levels[0].amount : '' );
        if ( trainee && trainee.payment_level && !_isEmpty( trainee.payment_level ) ) {
            let selected_price = _find( payment_levels, { value: trainee.payment_level } );
            if ( selected_price && selected_price.amount && !_isEmpty( selected_price.amount ) ) {
                payment_amount = selected_price.amount;
            }
        }
        return payment_amount;
    }

    checkIfisOverDue = (column,trainee) => {
        const thisMonth = moment().utcOffset(this.utcOffSet).endOf('month').valueOf();
        const data = this.getSelectedPaymentData(column,trainee);
        let overdue = false;
        if ( trainee && trainee.course_access && isArrayExists( trainee.course_access ) && !( data && data.payment_made && data.payment_made === 'yes' ) ) {
            _forEach( trainee.course_access, course => {
                if ( course.activated <= column.end_at && column.end_at <= thisMonth ) {
                    if ( course.deactivated ) {
                        if ( course.deactivated >= column.start_at ) {
                            overdue = true;
                        }
                    } else {
                        overdue = true;
                    } // end - course.deactivated
                } // end - course.activated
            });
        } // end - trainee.course_access
        return overdue;
    }

    reorganizeData() {
        const { searchterms, sortBy, perPage, page, filterBy } = this.state;
        const { trainees } = this.props;
        let items = ( trainees ? cloneCollections( trainees ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','email'] );
			total = _size( items );
        } // end - searchterms

        // do filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy !== 'all' ) {
            items = _filter( items, (i) => getStudentEnrollmentStatus(i) === filterBy );
            total = _size( items );
        } // end - filterBy

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['confirmation_email_date'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['confirmation_email_date'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
                // case 'email-desc':
                //     items = _sortBy( items, ['email'] );
                //     items = _reverse( items );
                //     break;
                // case 'email-asc':
                //     items = _sortBy( items, ['email'] );
                //     break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderPaymentOptions = () => {
        return _map( this.getPaymentLevels() , option => {
            return <option {...option}>{option.label}</option>;
        });
    }

    renderPaymentForm = () => {
        const { regions, columns } = this.props;
        const { modalData, modalType } = this.state;
        return (
        <div>
            <Grid container spacing={2}>
                { modalType && modalType === 'mass_edit_payment' ? <Grid item xs={12}>
                    <div style={{ padding: '5px 0 10px 0' }}>
                        <FormCheckboxes
                            label="Select a Month *"
                            value={( modalData.ids || false )}
                            options={getSelectOptions({ list: _sortBy( columns, ['id'] ), keys: { value: 'id', label: 'label' } })}
                            name="ids"
                            xs={3}
                            sm={3}
                            showAllCheck={true}
                            onChange={this.handleFormUpdate} />
                        {/* <FormRadio label="Select a Month *" name="id" inline={true} value={( modalData && modalData.ids && !_isEmpty( modalData.ids ) ? modalData.ids : '' )} options={getSelectOptions({ list: _sortBy( columns, ['id'] ), keys: { value: 'id', label: 'label' } })} onChange={this.handleFormUpdate} /> */}
                    </div>
                    <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                </Grid> : null }
                { modalType && modalType === 'mass_edit_payment' && this.getSelectedPrice() ? (
                <>
                    <Grid item xs={12} sm={6}>
                        <FormSelect label="Payment Level" name="payment_level" value={( modalData && modalData.payment_level && !_isEmpty( modalData.payment_level ) ? modalData.payment_level : '' )} options={this.getPaymentLevels(true)} onChange={this.handleFormUpdate} />
                    </Grid>
                    <Grid item xs={12} sm={6}> </Grid>
                </>
                ) : null }
                <Grid item xs={12} sm={6}>
                    { modalType && modalType === 'mass_edit_payment' ? <FormSelect label="Payment Made" name="payment_made" value={( modalData && modalData.payment_made && !_isEmpty( modalData.payment_made ) ? modalData.payment_made : '' )} options={[
                        { value: '', label: 'None' },
                        { value: 'yes', label: 'Yes' },
                        { value: 'no', label: 'No' }
                    ]} onChange={this.handleFormUpdate} /> : <FormCheckbox label="Payment Made" name="payment_made" value={( modalData && modalData.payment_made ? modalData.payment_made : 'no' )} checked="yes" unchecked="no" onChange={this.handleFormUpdate} /> }
                </Grid>
                <Grid item xs={12} sm={6}>
                    { modalType && modalType === 'mass_edit_payment' ? <FormSelect label="Invoice Sent" name="invoice_sent" value={( modalData && modalData.invoice_sent && !_isEmpty( modalData.invoice_sent ) ? modalData.invoice_sent : '' )} options={[
                        { value: '', label: 'None' },
                        { value: 'yes', label: 'Yes' },
                        { value: 'no', label: 'No' }
                    ]} onChange={this.handleFormUpdate} /> : <FormCheckbox label="Invoice Sent" name="invoice_sent" value={( modalData && modalData.invoice_sent ? modalData.invoice_sent : 'no' )} checked="yes" unchecked="no" onChange={this.handleFormUpdate} /> }
                </Grid>
                <Grid item xs={12}>
                    <FormInput label="Notes (Max 500 characters)" name="notes" value={( modalData && modalData.notes ? modalData.notes : '' )} rows={4} multiline={true} onChange={this.handleFormUpdate} />
                    { modalData && modalData.notes && !_isEmpty( modalData.notes ) && _size( modalData.notes ) > 500 ? <ErrorBox>Please reduce your notes to less than 500 characters. Current size: {_size( modalData.notes )} characters.</ErrorBox> : null }
                </Grid>
            </Grid>
        </div>
        );
    }

    renderPaymentFormTitle = () => {
        const { columns } = this.props;
        const { modalData, traineeData, modalType } = this.state;
        const selectedColumn = ( modalData && modalData.id && !_isEmpty( modalData.id ) && columns ? _find( columns, { id: modalData.id } ) : false );
        return ( modalType && modalType === 'mass_edit_payment' ? 'Mass Edit Payment Tracking' : "Edit Payment Tracking" + ( traineeData && traineeData.name && !_isEmpty( traineeData.name ) ? ' for '+traineeData.name + " on " +( selectedColumn && selectedColumn.label ? selectedColumn.label : '' ) : '' ) );
    }

    renderCheckBox = (key,label,trainee,column,disabled) => {
        const data = this.getSelectedPaymentData(column,trainee);
        const isOverDue = this.checkIfisOverDue(column,trainee);
        return (
        <CheckboxWrapper>
            <FormControlLabel
                // labelPlacement="start"
                control={
                    <Checkbox
                        disabled={disabled}
                        checked={( data && data[key] && data[key] === 'yes' ? true : false )}
                        onChange={(event) => {
                            let index = ( data && data.id && trainee && trainee.payment_tracking && isArrayExists( trainee.payment_tracking ) ? _findIndex( trainee.payment_tracking, { id: data.id } ) : -1 ),
                                newData = ( trainee && trainee.payment_tracking && isArrayExists( trainee.payment_tracking ) ? cloneCollections( trainee.payment_tracking ) : [] );
                            if ( index >= 0 ) {
                                newData[index][key] = ( event.target.checked ? 'yes' : 'no' );
                            } else {
                                newData.push({
                                    id: column.id,
                                    payment_made: ( key === 'payment_made' && event.target.checked ? 'yes' : 'no' ),
                                    payment_reminded: ( key === 'payment_reminded' && event.target.checked ? 'yes' : 'no' ),
                                    invoice_sent: ( key === 'invoice_sent' && event.target.checked ? 'yes' : 'no' ),
                                    notes: '',
                                    payment_id: ''
                                });
                            }
                            if ( trainee && trainee.id )
                                this.handleTraineeUpdate(newData,trainee.id);
                        }}
                        value={( data[key] || false )}
                        color="primary" />
                }
                label={(
                <>
                    { isOverDue && key === 'payment_made' ? <WarningIcon className="fa fa-exclamation-triangle" style={{ marginRight: '2px' }}></WarningIcon> : null }
                    { label || '' }
                </>
                )} />
            
        </CheckboxWrapper>
        )
    }

    renderPaymentColumn = (column) => (trainee) => {
        const data = this.getSelectedPaymentData(column,trainee);
        const isOverDue = this.checkIfisOverDue(column,trainee);
        const course_count = this.getCourseCount(column,trainee);
        return (
        <PaymentColumn overdue={( isOverDue ? 'yes' : 'no' )} disabled={( course_count ? null : 'yes' )}>
            <div className="disabled-wrapper">
                <strong style={{ fontWeight: '700' }}>Course Count: {course_count || 0}</strong><br />
                {this.renderCheckBox('invoice_sent','Invoice Sent',trainee,column,( course_count ? false : true ))}
                {this.renderCheckBox('payment_reminded','Reminded',trainee,column,( course_count ? false : true ))}
                {this.renderCheckBox('payment_made','Paid',trainee,column,( course_count ? false : true ))}
                <strong style={{ fontWeight: '700' }}>Note:</strong> 
                <TextareaWrapper>
                    <textarea rows={2} disabled="disabled" value={ data.notes && !_isEmpty( data.notes ) ? data.notes : '-' } />
                </TextareaWrapper>
            </div>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <InfoButton size="small" minWidth="0px" style={{ padding: '4px 8px', marginTop: '4px' }} onClick={() => this.setState({
                            openPaymentModal: true,
                            modalType: 'payment_form',
                            traineeData: ( trainee || false ),
                            modalData: ( data ? data : {
                                id: column.id,
                                payment_made: 'no',
                                invoice_sent: 'no',
                                notes: '',
                                payment_id: ''
                            })
                        })}>Edit</InfoButton>
                </Grid>
                <Grid item xs={12} sm={6} align="right">
                    <EmailButtonWrapper>
                        <ButtonActions 
                            key="actions" 
                            color="indigo"
                            label="Email"
                            menuContainerStyle={{ width: "250px" }}
                            menuPlacement="top-end"
                            style={{ marginLeft: "5px" }}
                            actions={[
                                { id: 'invoice', label: 'Send Invoice', icon: 'fa-envelope-o', onClick: this.handleSendSingleEmail('invoice',column,trainee) },
                                { id: 'payment_reminder', label: 'Send Payment Reminder', icon: 'fa-envelope-o', onClick: this.handleSendSingleEmail('payment_reminder',column,trainee) }
                            ]} />
                    </EmailButtonWrapper>
                </Grid>
            </Grid>
        </PaymentColumn>
        );
    }

    renderEnrollmentStatus = type => item => {
        return <StudentEnrollmentStatus trainee={item} />
    }

    renderCell = type => item => {
        const { regions, schedules, currentSchedule, unreconciliation_payments, classes } = this.props; 
        switch( type ) {
            case 'payment_history':
                return (
                <>
                    <InfoButton size="small" onClick={event => {
                        event.preventDefault();
                        const { onPaymentHistoryTrigger } = this.props;
                        if ( onPaymentHistoryTrigger )
                            onPaymentHistoryTrigger(item);
                    }}>View</InfoButton>
                    { unreconciliation_payments && isArrayExists( unreconciliation_payments ) && _find( unreconciliation_payments, { trainee_id: item.id } ) ? <AmberTag showBorder="yes" style={{ marginTop: '10px' }}>Action Needed</AmberTag> : null }
                </>
                );
            case 'payment_level':
                let payment_amount = this.getStudentPaymentAmount(item);
                return (
                <div style={{ minWidth: '95px' }}>
                    <Select 
                        native={true} 
                        value={( item && item.payment_level && !_isEmpty( item.payment_level ) ? item.payment_level : '' )} 
                        onChange={(event) => {
                            this.handleTraineePaymentLevelUpdate(event.target.value,item.id);
                        }}>{this.renderPaymentOptions()}</Select>
                    <div style={{ paddingTop: '5px' }}>
                        <CopyToClipboard text={PLATFORM_PAYMENT_URL+'?id='+item.id+( payment_amount && !_isEmpty( payment_amount ) ? '&amt='+payment_amount : '')}
                        onCopy={() => {
                            triggerSuccessAlert("Payment Link copied to clipboard.");
                        }}>
                            <Tooltip 
                                title={"Copy iPay88 link"} 
                                arrow 
                                placement="top"
                                classes={{ 
                                    popper: classes.popper,
                                    tooltip: classes.tooltip 
                                }}>
                                <InverseButton size="small" style={{ padding: '4px 8px' }} minWidth="0px" noIconMargin="yes"><i className="fa fa-copy"></i></InverseButton>
                            </Tooltip>
                        </CopyToClipboard>
                    </div>
                </div>);
            case 'created_on':
            case 'confirmation_email_date':
                return <WrapWord>{( item[type] ? getMomentTime( item[type], 'DD MMM YYYY' ) : '---' )}</WrapWord>;
            case 'email':
                return <WrapWord>{( item[type] || '-' )}</WrapWord>;
        }
    }

    renderTableActions = () => {
        const { authData } = this.props;
        const { sortBy, perPage, searchterms, checked, filterBy } = this.state;
        return <TableBar
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                filterBy={filterBy}
                filterByLabel="Filter By Class Status"
                sortByOptions={[
                    { value: 'date-desc', label: 'Confirmation Date (Recent First)' },
                    { value: 'date-asc', label: 'Confirmation Date (Oldest First)' },
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' },
                    // { value: 'email-asc', label: 'Email ( A - Z)' },
                    // { value: 'email-desc', label: 'Email ( Z - A )' },
                    // { value: 'org-asc', label: 'School ( A - Z)' },
                    // { value: 'org-desc', label: 'School ( Z - A )' },
                ]}
                leftButtons={ checked && isArrayExists( checked ) ? [
                    <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                        <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                    </div>
                    ] : null }
                rightButtons={[
                    <ButtonActions 
                        key="actions" 
                        label="Actions"
                        menuContainerStyle={{ width: "250px" }}
                        style={{ marginRight: "5px" }}
                        actions={this.getActions()} />
                ]}
                filterByOptions={getSelectOptions({ list: _filter( ENROLLMENT_STATUS_OPTIONS, { course_access: true } ), options: [{ value: 'all', label: 'All Status' }], keys: { value: 'value', label: 'label' } })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    doneLoaded={true}
                    // entriesLabel="(Total Registration For This Class)"
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { authData, users } = this.props;
        const { openDeleteModal, deleteModal, openPaymentModal, modalData, traineeData, allCheck, checked } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={openPaymentModal}
                title={this.renderPaymentFormTitle()}
                maxWidth="sm"
                onClose={() => this.setState({ openPaymentModal: false, modalData: false, modalType: false })}
                disableBackdrop={true}
                actionLabel="Update"
                doAction={this.handlePaymentUpdate}
                contents={this.renderPaymentForm()} />

            {this.renderTableActions()}
            <Table 
                items={items}
                showCheckbox={true}
                emptyCell="No Student(s) Found."
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={(newValue) => this.setState({ checked: newValue })}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                cells={this.getCells()}
                // actionStyles={{ width: "10%" }}
                // actions={(item) => (
                //     <ButtonGroup>
                //         { item.enrollment_status && item.enrollment_status === 'delete' ? null : <InfoButton key="edit" size="small" onClick={() => this.setState({ openPaymentModal: true, modalData: item })}><i className="fa fa-edit"></i>Edit</InfoButton> }
                //         {/* { authData && hasAccessRights( authData, ['scd'] ) ? <ErrorButton key="delete" size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton> : null } */}
                //     </ButtonGroup>
                // )}
                 />
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(StudentsTable);