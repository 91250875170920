/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _replace from 'lodash/replace';
import _remove from 'lodash/remove';
import _random from 'lodash/random';

import BadgeDetails from './details';

import ScreenLoader from '../../components/ScreenLoader';
import Footer from '../../components/Footer';

import { Button, InverseButton, GreyButton, InverseLink, InfoButton, InfoLink, AInverseLink } from '../../styles/button';

import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { getAPIErrorMessage } from '../../helpers/action';

import { fb_getBadgeDetails } from '../../actions/students/fb';
import { toggleLoader } from '../../actions/global';

const MainWrapper = styled.div`
    display: block;
    background: #f1f1f1;
    width: 100%;
    min-height: 100vh;
`;

const HeaderWrapper = styled.div`
    background: #fff;
    border-bottom: 1px solid #ddd;
    padding: 15px 30px;
    text-align: center;
`;

const ContentWrapper = styled.div`
    display: block;
    padding: 60px 15px;

    & > .MuiPaper-root {
        background: #fff;
        padding: 45px 30px;
        max-width: 1080px;
        margin: 0 auto;
    }
`;

class BadgeDetailsPage extends React.Component {

    state = {
        badge: false,
        error: false,
        randNum: false
    };

    componentDidMount() {
        const { badge_id } = this.props.match.params;
        const { dispatch } = this.props;

        this.getBadgeData(badge_id);
    }

    getBadgeData = (badge_id) => {
        fb_getBadgeDetails(badge_id)
        .then(badge => {
            this.setState({ badge, randNum: _random(1,9999) });
        })
        .catch(error => {
            this.setState({ error, randNum: _random(1,9999) });
        });
    }

    renderContents() {
        const { badge } = this.state;
        return (
        <div>
        
            <BadgeDetails 
                badge={badge} />

        </div>
        );
    }

    renderErrorMessage = () => {
        const { error } = this.state;
        return (
        <div>{getAPIErrorMessage(error)}</div>
        );
    }

    render() {
        const { randNum, error } = this.state;
        return !randNum ? <ScreenLoader /> : (
        <MainWrapper>
            <HeaderWrapper><img src="/logo.gif" style={{ display: "inline-block", height: 'auto', width: '100%', maxWidth: '175px' }} /></HeaderWrapper>
            <ContentWrapper>
                <Paper elevation={2}> 
                    { error ? this.renderErrorMessage() : this.renderContents() }
                </Paper>
            </ContentWrapper>
            <Footer />
        </MainWrapper>
        );
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(BadgeDetailsPage);