/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import _isEmpty from 'lodash/isEmpty';

import ScreenLoader from '../../components/ScreenLoader';

import { GreyButton } from '../../styles/button';

import { getAPIErrorMessage } from '../../helpers/action';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';

import { connectWithBadgr } from '../../actions/students/fb';
import { PLATFORM_NAME_LONG, PLATFORM_URL } from '../../constants';

const getQueryString = (location) => {
    return ( location && location.search ? queryString.parse(location.search) : {} );
}

const Results = ({
    status,
    errorMessage = '',
    authData
}) => {

    return (
    <div style={{ textAlign: 'center', padding: "60px 90px", background: "#f1f1f1", borderRadius: "12px", boxShadow: "0 0 15px 0 rgba(0,0,0,0.25)", height: '100vh' }}>
        <div style={{ margin: "0 auto 30px auto", maxWidth: '175px' }}><img src="/logo.gif" style={{ display: "block", height: 'auto', width: '100%' }} /></div>
        { status && status === 'success' ? (
            <Typography variant="h4" style={{ color: green['700'], textAlign: "center", background: green['50'], padding: '20px', border: '2px solid '+ green['700'] }}>
                Your account has been successfully connected to Badgr.<br />
                You can close this tab or window now.
            </Typography>
        ) : (
            <Typography variant="h4" style={{ color: red['700'], textAlign: "center", background: red['50'], padding: '20px', border: '2px solid '+ red['700'] }}>
                {errorMessage}
            </Typography>
        ) }
    </div>
    )
}

const SSObadgr = ({
    authData,
    location
}) => {
    const [ status, setStatus ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState('');
    const { code } = getQueryString(location);

    useEffect(() => {
        if ( code && !_isEmpty( code ) ) {
            connectWithBadgr({
                uid: ( authData && authData.uid || false ),
                code
            })
            .then(status => {
                setStatus(status);
            })
            .catch(error => {
                setErrorMessage( getAPIErrorMessage(error) );
                setStatus('failed');
            });
        } // end - code
    },[]);
    
    return status ? <Results
        status={status}
        errorMessage={errorMessage}
        authData={authData} /> : <ScreenLoader />
}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(SSObadgr);