/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import red from "@material-ui/core/colors/red";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _filter from "lodash/filter";
import _find from "lodash/find";
import _size from "lodash/size";
import _isEqual from "lodash/isEqual";
import _findIndex from "lodash/findIndex";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _toString from "lodash/toString";

import AppWrapper from "../../components/AppWrapper";
import AppInfoBox from "../../modules/AppInfoBox";
import ScheduleSwitcher from "../../components/ScheduleSwitcher";
import SnackBarSave from "../../components/SnackBarSave";
import ToolTipInfo from "../../components/ToolTipInfo";

import Table from "./table";
import ClassFilterMenu from "../ClassRegistrationPage/filter_menu";
import ColumnsMenu from "./columns_menu";
import EditReportModal from "./report_edit";

import { isAdmin, getUserRegionFilter } from "../../helpers/auth";
import { cloneCollections } from "../../helpers/data";
import { getMomentTime } from "../../helpers/date";
import { isArrayExists } from "../../helpers/validation";

import { updateClassAttendanceReporting } from "../../actions/class_attendance_reporting";
import { getClassQuarterlyReport, triggerClassQuarterlyReport, triggerPreviewEmail } from "../../actions/class_quarterly_reports";
import { getTrainees } from "../../actions/trainees";
import { getCoursesOptions } from "../../actions/courses";
import { getSchedulesOptions, getSchedule } from "../../actions/schedules";
import { getLMSCourses } from "../../actions/canvas_lms";
import { getRegions } from "../../actions/m_regions";
import { getMaintenance } from "../../actions/maintenance";
import { toggleLoader } from "../../actions/global";
import { resetRedux } from "../../actions/misc";

import { ButtonGroup, AInfoLink, InfoButton, IndigoButton } from "../../styles/button";
import { SuccessTag } from "../../styles/tag";
import { WarningBox, InfoBox } from "../../styles/message";

import { ENROLLMENT_STATUS_OPTIONS, PLATFORM_URL } from "../../constants";

const TableWrapper = styled.div`
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
`;

class ClassQuarterlyReportPage extends React.Component {
    utcOffSet = 8;

    state = {
        trainees: false,
        traineesRand: false,
        currentSchedule: false,
        columns: [],
        openEditReport: false,
        openSendReport: false,
        openMassReport: false,
        dataLoaded: false,
    };

    componentDidMount() {
        const { authData, coursesList, regionsList, schedulesList, location } = this.props;

        // preload trainees
        if (location && location.state && location.state.schedule_id && !_isEmpty(location.state.schedule_id))
            this.handleFilter(location.state.schedule_id);

        // get regions
        if (!regionsList) this.props.dispatch(getRegions());

        // get schedule options
        if (!schedulesList) this.props.dispatch(getSchedulesOptions({ region: getUserRegionFilter(authData) }));

        if (regionsList && schedulesList) {
            this.setState({ dataLoaded: true });
        } // end - randNum
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { traineesList, regionsList, schedulesList, currentQuarterlyReport, currentScheduleData, traineesRand } = this.props;
        if (regionsList && schedulesList && !this.state.dataLoaded) {
            this.setState({ dataLoaded: true });
        } // end - randNum

        if (currentQuarterlyReport && currentScheduleData && traineesList && traineesRand && traineesRand !== this.state.traineesRand) {
            this.props.dispatch(toggleLoader(false));
            this.setState({
                traineesRand,
                columns: this.getDefaultColumns(currentScheduleData),
                trainees: traineesList && isArrayExists(traineesList) ? cloneCollections(traineesList) : [],
            });
        } // end - traineesRand
    }

    componentWillUnmount() {
        this.handleReset();
    }

    handleReset = () => {
        this.props.dispatch(resetRedux("trainees"));
        this.props.dispatch(resetRedux("schedule"));
        this.props.dispatch(resetRedux("classes"));
    };

    handleFilter = (schedule_id) => {
        const { authData } = this.props;
        this.setState({ currentSchedule: schedule_id });
        this.props.dispatch(getTrainees({ schedule_id, enrollment_status: this.getEnrollmentStatusFilter() }));
        this.props.dispatch(getClassQuarterlyReport(schedule_id));
        this.props.dispatch(getSchedule(schedule_id, { authData }));
        this.props.dispatch(toggleLoader(true));
    };

    handleSaveChanges = (event) => {
        const { traineesList } = this.props;
        const { trainees, currentSchedule } = this.state;
        event.preventDefault();
        let formData = { schedule_id: currentSchedule, trainees: [] };
        // run through all the trainees to look for changes
        if (trainees && isArrayExists(trainees) && traineesList && isArrayExists(traineesList)) {
            _forEach(trainees, (trainee) => {
                let oriTrainee = _find(traineesList, { id: trainee.id });
                if (!_isEqual(trainee, oriTrainee)) {
                    formData.trainees.push({
                        id: trainee.id,
                        mentor_comments: trainee.mentor_comments || "",
                        attendances: trainee.attendances || [],
                    });
                }
            });
        } // end - trainees

        this.props.dispatch(
            updateClassAttendanceReporting(formData, {
                schedule_id: currentSchedule,
                enrollment_status: this.getEnrollmentStatusFilter(),
            })
        );
    };

    getEnrollmentStatusFilter = () => {
        let statuses = [];
        ENROLLMENT_STATUS_OPTIONS.forEach((option) => {
            if (option.value && !_isEmpty(option.value) && option.value !== "delete" && option.value !== "registered" && option.value !== "trial")
                statuses.push(option.value);
        });
        return statuses;
    };

    getDefaultColumns = (schedule) => {
        let columns = [],
            pastEvents = [],
            futureEvents = [],
            today = moment().utcOffset(this.utcOffSet).startOf("date").valueOf();

        if (schedule && schedule.class_calendar && isArrayExists(schedule.class_calendar)) {
            pastEvents = _filter(schedule.class_calendar, { type: "past_class", quarterly_report: "yes" });
            // sort & reverse
            if (pastEvents && isArrayExists(pastEvents)) {
                pastEvents = _sortBy(pastEvents, ["start_date"]);
                pastEvents = _reverse(pastEvents);
            }

            futureEvents = _filter(schedule.class_calendar, { type: "class", quarterly_report: "yes" });
            // sort
            if (futureEvents && isArrayExists(futureEvents)) {
                futureEvents = _sortBy(futureEvents, ["start_date"]);
            }
        } // end - schedule.class_calendar

        for (let p = 0; p < 3; p++) {
            if (pastEvents && pastEvents[p] && pastEvents[p].id && !_isEmpty(pastEvents[p].id)) {
                let passEvent = cloneCollections(pastEvents[p]);
                passEvent.label = passEvent.start_date ? getMomentTime(passEvent.start_date, "DD-MMM-YY") : passEvent.id;
                columns.push(passEvent);
            }
        }

        for (let p = 0; p < 3; p++) {
            if (futureEvents && futureEvents[p] && futureEvents[p].id && !_isEmpty(futureEvents[p].id)) {
                let futureEvent = cloneCollections(futureEvents[p]);
                futureEvent.label = futureEvent.start_date ? getMomentTime(futureEvent.start_date, "DD-MMM-YY") : futureEvent.id;
                columns.push(futureEvent);
            }
        }

        return columns;
    };

    getCurrentClassSize = () => {
        const { traineesList } = this.props;
        const activeStudents =
            traineesList && isArrayExists(traineesList)
                ? _filter(traineesList, (t) =>
                      t.enrollment_status && t.enrollment_status !== "deleted" && t.enrollment_status !== "registered" ? true : false
                  )
                : [];
        return _size(activeStudents);
    };

    getReportDates = () => {
        const { currentScheduleData } = this.props;
        return currentScheduleData && currentScheduleData.class_calendar && isArrayExists(currentScheduleData.class_calendar)
            ? _filter(currentScheduleData.class_calendar, { quarterly_report: "yes" })
            : false;
    };

    renderReportDates = (events) => {
        const { currentScheduleData } = this.props;
        const { currentSchedule } = this.state;
        let today = moment().utcOffset(this.utcOffSet).startOf("date");
        return (
            <div style={{ padding: "20px", border: "2px solid #212121", background: "#fff" }}>
                <Typography variant="h5" style={{ marginBottom: "8px" }}>
                    <span>
                        <ToolTipInfo
                            content={
                                <>
                                    SCHEDULE AHEAD FOR THE YEAR
                                    <br />
                                    <br />
                                    Add dates to send your quarterly report in{" "}
                                    <a
                                        href={
                                            PLATFORM_URL + "schedules" + (currentSchedule && !_isEmpty(currentSchedule) ? "/" + currentSchedule : "")
                                        }
                                        onClick={(event) => {
                                            event.preventDefault();
                                            this.props.history.push(
                                                "/schedules" + (currentSchedule && !_isEmpty(currentSchedule) ? "/" + currentSchedule : "")
                                            );
                                        }}
                                    >
                                        Class Schedule's Class Calendar
                                    </a>
                                    . These dates are only serving as a reminder to you.
                                </>
                            }
                        />
                    </span>
                    Dates to send report:
                </Typography>
                {_sortBy(events, ["start_date"]).map((event) => {
                    let start_date = event.start_date ? moment(event.start_date).utcOffset(this.utcOffSet).startOf("date") : false,
                        differences = start_date.diff(today, "days");
                    return start_date ? (
                        <Grid key={event.id} container spacing={1}>
                            <Grid item xs={6} style={{ fontSize: "12px" }}>
                                {getMomentTime(event.start_date, "DD-MMM-YYYY")}
                            </Grid>
                            <Grid item xs={6} key={event.id} style={{ fontSize: "12px" }}>
                                {differences >= 0 ? (
                                    " " + differences + " day(s) left"
                                ) : (
                                    <>
                                        <i className="fa fa-exclamation-triangle" style={{ marginRight: "3px", color: red[500] }}></i>Overdue
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    ) : null;
                })}
                <div style={{ paddingTop: "20px" }}>
                    <InfoButton style={{ padding: "5px 12px" }} onClick={() => this.setState({ openEditReport: true })}>
                        <i className="fa fa-edit"></i>Edit Email Draft
                    </InfoButton>
                </div>
                <div style={{ paddingTop: "10px" }}>
                    <IndigoButton style={{ padding: "5px 12px" }} onClick={() => this.setState({ openMassReport: true })}>
                        <i className="fa fa-envelope-o"></i>Send Report
                    </IndigoButton>
                </div>
            </div>
        );
    };

    renderTable() {
        const {
            authData,
            traineesList,
            regionsList,
            lmsCoursesList,
            coursesList,
            schedulesList,
            rubricsList,
            badgeTypeList,
            currentQuarterlyReport,
            currentScheduleData,
        } = this.props;
        const { currentSchedule, columns, trainees, openMassReport } = this.state;
        return (
            <Table
                openMassReport={openMassReport}
                onMassReportClose={() => this.setState({ openMassReport: false })}
                columns={columns || false}
                enrollment_status={this.getEnrollmentStatusFilter()}
                quarterly_report={currentQuarterlyReport || false}
                schedule={currentScheduleData || false}
                currentSchedule={currentSchedule || false}
                trainees={trainees}
                currentTrainees={traineesList}
                schedules={schedulesList}
                regions={regionsList}
                authData={authData}
                onUpdate={(newTrainees) => this.setState({ trainees: newTrainees })}
            />
        );
    }

    renderInfoSection = () => {
        const { currentScheduleData, traineesList, coursesList, lmsCoursesList } = this.props;
        const { columns } = this.state;
        let reportDates = this.getReportDates();
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AppInfoBox page="ClassQuarterlyReportPage" />
                    <Typography variant="h5">
                        Current Active Class Size: <SuccessTag style={{ fontSize: "16px" }}>{this.getCurrentClassSize()}</SuccessTag>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={5}>
                            {reportDates ? this.renderReportDates(reportDates) : null}
                        </Grid>
                        <Grid item xs={7}>
                            <ColumnsMenu
                                columns={columns || false}
                                schedule={currentScheduleData || false}
                                onUpdate={(newColumns) => this.setState({ columns: newColumns })}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    renderContents() {
        const { traineesList, currentScheduleData, currentQuarterlyReport } = this.props;
        const { currentSchedule, traineesRand, trainees, openEditReport } = this.state;
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                        <ClassFilterMenu currentSchedule={currentSchedule || false} onFilter={this.handleFilter} />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        {currentScheduleData && traineesRand ? this.renderInfoSection() : null}
                    </Grid>
                </Grid>

                {traineesList && traineesRand ? (
                    <TableWrapper>
                        {this.renderTable()}
                        {trainees && isArrayExists(trainees) ? (
                            <div style={{ textAlign: "center", marginTop: "45px" }}>
                                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}>
                                    <i className="fa fa-save"></i>Save Changes
                                </InfoButton>
                            </div>
                        ) : null}
                    </TableWrapper>
                ) : null}

                <SnackBarSave onSave={this.handleSaveChanges} />

                <EditReportModal
                    open={openEditReport}
                    quarterly_report={currentQuarterlyReport || false}
                    schedule={currentScheduleData || false}
                    onClose={() => this.setState({ openEditReport: false })}
                />
            </>
        );
    }

    render() {
        const { dataLoaded, currentSchedule } = this.state;
        const { authData } = this.props;
        return (
            <AppWrapper
                subtitle="4. Closing"
                title="Quarterly Report"
                onLoad={!dataLoaded ? true : false}
                customColumn={
                    currentSchedule && !_isEmpty(currentSchedule) ? <ScheduleSwitcher schedule_id={currentSchedule} authData={authData} /> : null
                }
                contents={authData ? this.renderContents() : null}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        currentQuarterlyReport: state.classes && state.classes.quarterly_report ? state.classes.quarterly_report : null,
        traineesList: state.trainees && state.trainees.trainees ? state.trainees.trainees : null,
        traineesRand: state.trainees && state.trainees.rand ? state.trainees.rand : null,
        authData: state.auth && state.auth.user ? state.auth.user : null,
        currentScheduleData: state.schedules && state.schedules.schedule ? state.schedules.schedule : null,
        scheduleRand: state.schedules && state.schedules.rand ? state.schedules.rand : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        schedulesList: state.schedules && state.schedules.schedules_options ? state.schedules.schedules_options : false,
    };
};

export default compose(connect(mapStateToProps), withRouter)(ClassQuarterlyReportPage);
