/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import { callFunctionsAPI, doPromise } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

const triggerClassShowcaseImportAPI = (formData) => {
    return new Promise((resolve,reject) => {
        callFunctionsAPI({ url: 'class', action: 'import_class_showcase', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

const doAsyncAPICall = async ({ schedule_id, trainees }) => {

    const list = [];
    const limits = 5;
    let count = 1,
        batch = [];

    if ( trainees && isArrayExists( trainees ) ) {
        _forEach( trainees, trainee => {
            batch.push(trainee);
            if ( count === limits ) {
                list.push(batch); // add to list
                // then reset
                count = 1; 
                batch = [];
            } else {
                count++;
            } // end - count
        });

        // if batch is not empty - add to list
        if ( batch && isArrayExists( batch ) )
            list.push( batch );

    } // end - trainees

    if ( list && isArrayExists( list ) ) {
        let cIndex = 0;
        while ( cIndex < list.length ) {
            await triggerClassShowcaseImportAPI({ schedule_id, trainees: cloneCollections( list[cIndex]) })
                .then(() => {
                    cIndex++;
                })
                .catch(error => {
                    let errorMessage = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to import data. Please try again.' ) );
                    throw new Error( errorMessage );
                });
        }
    } // end - contacts

    return 'done';
}

const doForceIssueBadgeAsyncAPICall = async ({ schedule_id, trainees, lms_course_id }) => {

    const list = [];
    const limits = 25;
    let count = 1,
        batch = [];

    if ( trainees && isArrayExists( trainees ) ) {
        _forEach( trainees, trainee => {
            batch.push(trainee);
            if ( count === limits ) {
                list.push(batch); // add to list
                // then reset
                count = 1; 
                batch = [];
            } else {
                count++;
            } // end - count
        });

        // if batch is not empty - add to list
        if ( batch && isArrayExists( batch ) )
            list.push( batch );

    } // end - trainees

    if ( list && isArrayExists( list ) ) {
        let cIndex = 0;
        while ( cIndex < list.length ) {
            await callFunctionsAPI({ 
                    url: 'class', 
                    action: 'force_issue_badge', 
                    formData: {
                        schedule_id,
                        trainees: cloneCollections( list[cIndex] ),
                        lms_course_id
                    } 
                })
                .then(() => {
                    cIndex++;
                })
                .catch(error => {
                    let errorMessage = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to import data. Please try again.' ) );
                    throw new Error( errorMessage );
                });
        }
    } // end - contacts

    return 'done';
}

/* helper end */

export const fb_getClassShowcase = (schedule_id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'class', action: 'get_class_showcase', formData: { schedule_id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateClassShowcase = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'class', action: 'update_class_showcase', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_forceIssueBadge = (formData) => {
    return new Promise((resolve,reject) => {

        doForceIssueBadgeAsyncAPICall(formData)
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_importClassShowcase = (formData) => {
    return new Promise((resolve,reject) => {

        doAsyncAPICall(formData)
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}