/* eslint-disable */
import _random from "lodash/random";
import moment from "moment";

import { GET_NOTIFICATIONS, UPDATE_NOTIFICATION_DATE_RANGE } from "../actions/types";

const initialState = {
    notifications: null,
    startDate: moment().utcOffset(8).subtract(1, "months").startOf("day").valueOf(),
    endDate: moment().utcOffset(8).endOf("day").valueOf(),
    rand: false,
};

export const notifications = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_NOTIFICATIONS:
            return {
                ...state,
                notifications: payload.notifications || [],
                rand: _random(1, 9999),
            };
        case UPDATE_NOTIFICATION_DATE_RANGE:
            return {
                ...state,
                startDate: payload.startDate,
                endDate: payload.endDate,
            };
        default:
            return state;
    }
};
