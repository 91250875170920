/* eslint-disable */
import _random from "lodash/random";
import _isEmpty from "lodash/isEmpty";

import {
    MENTOR_GET_CLASSES,
    MENTOR_GET_CLASS_ATTENDANCES,
    MENTOR_UPDATE_CLASS_ATTENDANCES,
    // GLOBAL_ERROR_TRIGGER
} from "../types";

import { getAPIErrorMessage } from "../../helpers/action";
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from "../global";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import { appChangesReset } from "../misc";

import {
    fb_getMentorClasses,
    fb_getMentorClassAttendances,
    fb_updateMentorClassAttendances,
    fb_nominateBadgeMentor,
    fb_addNewClassCalendar,
} from "./fb";

export const getMentorClasses = (lms_course_id) => {
    return (dispatch) => {
        fb_getMentorClasses(lms_course_id)
            .then((classes) => {
                dispatch({
                    type: MENTOR_GET_CLASSES,
                    payload: { classes },
                });
            })
            .catch((error) => {
                dispatch(toggleLoader(false));
                triggerErrorAlert(getAPIErrorMessage(error));
                dispatch({
                    type: MENTOR_GET_CLASSES,
                    payload: { classes: [] },
                });
            });
    };
};

export const getMentorClassAttendances = (schedule_id, lms_course_id) => {
    return (dispatch) => {
        dispatch(toggleLoader(true));

        fb_getMentorClassAttendances(schedule_id, lms_course_id)
            .then((attendances) => {
                dispatch(toggleLoader(false));
                dispatch({
                    type: MENTOR_GET_CLASS_ATTENDANCES,
                    payload: { attendances },
                });
            })
            .catch((error) => {
                dispatch(toggleLoader(false));
                triggerErrorAlert(getAPIErrorMessage(error));
                dispatch({
                    type: MENTOR_GET_CLASS_ATTENDANCES,
                    payload: { attendances: {} },
                });
            });
    };
};

export const updateMentorClassAttendances = (formData) => {
    return (dispatch) => {
        dispatch(toggleLoader(true));

        fb_updateMentorClassAttendances(formData)
            .then((results) => {
                fb_getMentorClassAttendances(formData.schedule_id, formData.lms_course_id).then((attendances) => {
                    // push new data
                    dispatch({ type: MENTOR_GET_CLASS_ATTENDANCES, payload: { attendances } });
                    // updated
                    dispatch({ type: MENTOR_UPDATE_CLASS_ATTENDANCES });
                    dispatch(toggleLoader(false));
                    dispatch(appChangesReset());
                    triggerSuccessAlert("Class Attendance Updated");
                });
            })
            .catch((error) => {
                dispatch(toggleLoader(false));
                triggerErrorAlert(getAPIErrorMessage(error));
            });
    };
};

export const nominateBadgeMentor = (formData) => {
    return (dispatch) => {
        dispatch(toggleLoader(true));

        fb_nominateBadgeMentor(formData)
            .then((results) => {
                fb_getMentorClassAttendances(formData.schedule_id, formData.lms_course_id).then((attendances) => {
                    // push new data
                    dispatch({ type: MENTOR_GET_CLASS_ATTENDANCES, payload: { attendances } });
                    // updated
                    dispatch({ type: MENTOR_UPDATE_CLASS_ATTENDANCES });
                    dispatch(toggleLoader(false));
                    dispatch(appChangesReset());
                    triggerSuccessAlert("Badge Data Updated");
                });
            })
            .catch((error) => {
                dispatch(toggleLoader(false));
                triggerErrorAlert(getAPIErrorMessage(error));
            });
    };
};

export const addNewClassSession = (formData) => {
    return (dispatch) => {
        dispatch(toggleLoader(true));

        fb_addNewClassCalendar(formData)
            .then((results) => {
                fb_getMentorClassAttendances(formData.schedule_id, formData.lms_course_id).then((attendances) => {
                    // push new data
                    dispatch({ type: MENTOR_GET_CLASS_ATTENDANCES, payload: { attendances } });
                    // updated
                    // dispatch({ type: MENTOR_UPDATE_CLASS_ATTENDANCES });
                    dispatch(toggleLoader(false));
                    dispatch(appChangesReset());
                    triggerSuccessAlert("New Class Added");
                });
            })
            .catch((error) => {
                dispatch(toggleLoader(false));
                triggerErrorAlert(getAPIErrorMessage(error));
            });
    };
};
