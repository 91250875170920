import React from "react";

const info = {
    heading: <></>,
    contents: [
        {
            id: "1",
            title: "What is Summary-Business Dashboard stage? ",
            content: (
                <>
                    <div>
                        <p>Business Dashboard informs you how your school is doing. </p>
                        <p>
                            Contact{" "}
                            <a href="mailto:zhix.chong@chumbaka.asia" target="_blank">
                                zhix.chong@chumbaka.asia
                            </a>{" "}
                            if you would like a tour of the Dashboard.
                        </p>
                    </div>
                </>
            ),
        },
    ],
};

const buttons = [];

export default {
    info,
    buttons,
};
