import _random from 'lodash/random';

import { 
    GET_PAYMENT_FORM,
    PAYMENT_FORM_ERROR,
    CREATE_PAYMENT_FORM,
    RESET_PAYMENT_FORM
} from '../actions/types';

const initialState = {
    data: null,
    error_message: null,
    updated: false,
    rand: false
};

export const payment_form = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_PAYMENT_FORM:
            return {
                ...state,
                data: ( payload.data || {} ),
                rand: _random(1,9999)
            };
        case PAYMENT_FORM_ERROR:
            return {
                ...state,
                data: {},
                error_message: ( payload || '' ),
                rand: _random(1,9999)
            };
        case CREATE_PAYMENT_FORM:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_PAYMENT_FORM:
            return {
                ...state,
                data: null,
                error_message: null,
                updated: false,
                rand: false
            };
        default:
            return state;
    }
}