/* eslint-disable */
import _random from 'lodash/random';

import { 
    GET_STUDENT_PROFILE,
    UPDATE_STUDENT_PROFILE,
    GET_STUDENT_BADGES,
    REMOVE_STUDENT_BADGE,
    RESET_STUDENT 
} from '../actions/types';

const initialState = {
    profile: null,
    badges: null,
    updated: false,
    rand: false
};

export const students = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_STUDENT_PROFILE:
            return {
                ...state,
                profile: ( payload.profile || {} ),
                rand: _random(1,9999)
            };
        case UPDATE_STUDENT_PROFILE:
        case REMOVE_STUDENT_BADGE:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case GET_STUDENT_BADGES:
            return {
                ...state,
                badges: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case RESET_STUDENT:
            return {
                ...state,
                badges: null,
                profile: null,
                rand: false
            };
        default:
            return state;
    }
}