/* eslint-disable */
import firebase from 'firebase/app';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _snakeCase from 'lodash/snakeCase';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';

import { isAdmin } from '../../helpers/auth';
import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists, validateEmail, isObjectExists } from '../../helpers/validation';
import { getSnapshotDataBySchema, getDocDataBySchema } from '../../helpers/schemas';

import { scheduleSchema } from '../../schemas/schedule';
import { classCalendarSchema } from '../../schemas/class_calendar';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

const getActiveClassSize = (list) => {
    return new Promise((resolve,reject) => {

        let promises = [];
        _forEach( list, item => {
            if ( item && item.id && !_isEmpty( item.id ) )
                promises.push( firebase.database().ref("usage/course_schedules/"+item.id).once("value") );
        });

        if ( isArrayExists( promises ) ) {
            Promise.all( promises )
            .then( results => {
                if ( results && isArrayExists( results ) ) {
                    _forEach( results, result => {
                        if ( result && result.exists() && result.hasChildren() ) {
                            let index = _findIndex( list, { id: result.key } ),
                                count = 0;
                            result.forEach( childSnapshot => {
                                if ( 'active' === childSnapshot.val() )
                                    count++;
                            });
                            if ( index >= 0 ) {
                                list[index].total_registered = count;
                            } // end - index
                        } // end - result
                    });
                } // end - results
                resolve(list);
            })
            .catch(error => {
                reject(error);
            });
        } else {
            resolve(list);
        } // end - promises

    })
}

/* helper end */

export const fb_getSchedules = (props = {}) => {
    return new Promise((resolve,reject) => {
        const { start_date, end_date, region } = props;

        let query = firebase.firestore().collection("schedules");

        if ( region && !_isEmpty( region ) ) {
            query = query.where("region","==",region);
        } // end - region

        if ( start_date && end_date ) {
            query = query.where("start_date",">=",start_date).where("start_date","<=",end_date).orderBy("start_date", "desc");
        } // end - start_date

        query.get()
        .then(snapshot => {
            let list = [];
            if ( snapshot ) {
                snapshot.forEach(doc => {
                    let item = getDocDataBySchema( scheduleSchema, doc );
                    list.push( item );
                });
            } // end - snapshot
            return ( isArrayExists( list ) ? getActiveClassSize( list ) : [] );
        })
        .then(list => {
            resolve(list);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getSchedulesOptions = (callback,props = {}) => {
    const { course_id, region } = props;

    let query = firebase.database().ref("schedules");

    if ( region && !_isEmpty( region ) ) {
        query = query.orderByChild("region").equalTo(region);
    } // end - region

    if ( course_id && !_isEmpty( course_id ) ) {
        query = query.orderByChild("course_id").equalTo(course_id);
    } // end - region

    query.on("value",snapshot => {
        let list = [];
        if ( snapshot && snapshot.exists() && snapshot.hasChildren() ) {
            snapshot.forEach(doc => {
                let item = getSnapshotDataBySchema( scheduleSchema, doc );
                list.push( item );
            });
        } // end - snapshot
        callback(list);
    });
}


export const fb_getSchedule = (id,props = {}) => {
    return new Promise((resolve,reject) => {
        const { authData, getScheduleUsage } = props;

        let today = moment().utcOffset(8).startOf('date').valueOf(),
            promises = [];
        promises.push( firebase.firestore().collection("schedules").doc(id).get() );
        // get class calendar from database
        promises.push( firebase.database().ref("class_calendar").orderByChild("schedule_id").equalTo(id).once("value") );
        // get schedule usage
        if ( getScheduleUsage ) {
            promises.push( firebase.database().ref("usage/class/"+id).once("value") );
            promises.push( firebase.database().ref("usage/course_schedules/"+id).once("value") );
        } // end - getScheduleUsage
        
        Promise.all( promises )
        .then(results => {
            let item = {};
            if ( results && results[0] ) {
                item = getDocDataBySchema( scheduleSchema, results[0] );
            } // end - results[0]

            if ( results && results[1] && results[1].exists() && results[1].hasChildren() ) {
                // add class calendar if not exists
                if ( item && isObjectExists( item ) && !( item.class_calendar && isArrayExists( item.class_calendar ) ) )
                    item.class_calendar = [];

                results[1].forEach(childSnapshot => {
                    let event = getSnapshotDataBySchema( classCalendarSchema, childSnapshot );
                    event.type = ( event.start_date >= today ? 'class' : 'past_class' ); // label pass event
                    item.class_calendar.push(event);
                });
            } // end - results[1]

            // get usage
            if ( getScheduleUsage && results && results[2] && results[2].exists() ) {
                item.usage_sections = [];
                item.usage_enrollments = [];
                if ( results[2].child("sections").exists() && results[2].child("sections").hasChildren() ) {
                    results[2].child("sections").forEach(childSnapshot => {
                        item.usage_sections.push(childSnapshot.val());
                    });
                } // end - results[2].child("sections")
                if ( results[2].child("enrollments").exists() && results[2].child("enrollments").hasChildren() ) {
                    results[2].child("enrollments").forEach(childSnapshot => {
                        item.usage_enrollments.push(childSnapshot.val());
                    });
                } // end - results[2].child("enrollments")
            } // end - getScheduleUsage

            if ( getScheduleUsage && results && results[3] ) {
                item.usage_trainees_status = [];
                if ( results[3].exists() && results[3].hasChildren() ) {
                    results[3].forEach(childSnapshot => {
                        item.usage_trainees_status.push({
                            id: childSnapshot.key,
                            status: childSnapshot.val()
                        });
                    });
                } // end - results[3]
            } // end - getScheduleUsage

            // check if the current user can access the item
            if ( authData && !isAdmin( authData ) ) {
                if ( !( authData.region && !_isEmpty( authData.region ) && authData.region === item.region ) )
                    item = {}; // reset it
            } // end - authData

            resolve(item);
        })
        .catch(error => {
            reject(error);
        });
    })
}

export const fb_addSchedule = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'schedule', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateSchedule = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'schedule', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteSchedule = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'schedule', action: 'delete', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getGoogleCalendar = () => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'schedule', action: 'get_calendar' })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}