/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import firebase from 'firebase/app';
import moment from 'moment';
import styled from "styled-components";
import Papa from 'papaparse';
import shortid from 'shortid';
import blue from '@material-ui/core/colors/blue';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Dropzone from 'react-dropzone';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

import { doCSVDownload } from '../../modules/TeamsTable/export';

import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import Table from '../../components/Table';
import DotsLoader from '../../components/DotsLoader';


import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton, IndigoButton, ASuccessLink } from '../../styles/button';
import { SuccessTag, GreyTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';
import { InfoBox } from '../../styles/message';

import { isAdmin } from '../../helpers/auth'; 
import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';
import { triggerErrorAlert } from '../../helpers/alert';
import { getMomentTime } from '../../helpers/date';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue, trimEmail } from '../../helpers/data';
import { isArrayExists, validateEmail } from '../../helpers/validation';

import { PLATFORM_BADGE_URL, PLATFORM_CERT_URL } from '../../constants';

const ListRecipients = ({
    authData,
    dispatch,
    open = false,
    report = {},
    badges = [],
    certificates = [],
    onClose = () => {}
}) => {
    const [ loaded, setLoaded ] = useState(false);
    const [ recipients, setRecipients ] = useState([]);

    useEffect(() => {
        if ( open && report && report.id && !_isEmpty( report.id ) ) {
            setLoaded(false);
            firebase.database().ref("badges_oneoff_reports_list/"+report.id).once("value")
            .then( snapshot => {
                let list = [];
                if ( snapshot && snapshot.exists() && snapshot.hasChildren() ) {
                    snapshot.forEach( childSnapshot => {
                        let item = childSnapshot.val();
                        item.id = childSnapshot.key;
                        list.push(item);
                    });
                }
                setRecipients( _sortBy( list, [ i => i.name.toLowerCase() ] ) );
                setLoaded(true);
            })
            .catch(error => {
                setLoaded(false);
                triggerErrorAlert( getAPIErrorMessage(error) );
            });
        }
    },[open]);

    const selectedBadge = useMemo(() => {
        return _find( badges, { id: report.badge_id } );
    },[report,badges]);

    const hasCert = useMemo(() => {
        return ( selectedBadge && selectedBadge.cert_referer && !_isEmpty( selectedBadge.cert_referer ) ? true : false );
    },[selectedBadge]);

    const handleCSVExport = (e) => {
        e.preventDefault();
        let csvData = {
            fields: [
                "Name",
                "Email",
                "Badge",
                "Certificate",
            ],
            data: []
        };
        if ( recipients && isArrayExists( recipients ) ) {
            _forEach( recipients, (item) => {
                csvData.data.push([
                    item.name || "",
                    item.email || "",
                    PLATFORM_BADGE_URL+( item.user_badge_id || '' ),
                    PLATFORM_CERT_URL+( item.user_badge_id || '' )
                ]);
            });
        }

        doCSVDownload( csvData, 'one_off_badges' + ( report && report.id && !_isEmpty( report.id ) ? '_'+report.id: '' ) );
    }

    return (
    <>
    
        <ModalView
            open={open}
            title={'View Recipient(s)'}
            maxWidth="lg"
            noAction={true}
            cancelLabel="Close"
            disableAutoFocus={true}
            disableEnforceFocus={true}
            disableBackdrop={true}
            onClose={onClose}
            contents={ loaded ? (
            <>
                <div style={{ paddingBottom: '10px', textAlign: 'right' }}>
                    <InverseButton 
                        style={{ padding: '10px 25px' }}
                        onClick={handleCSVExport}><i className='fa fa-file-excel-o'></i>Export to CSV</InverseButton>
                </div>
                <Table
                    items={recipients} 
                    emptyCell="No recipient found"
                    cells={[
                        { id: 'name', label: 'Name', render: (item) => <WrapWord>{( item.name || '' )}</WrapWord> },
                        { id: 'email', label: 'Email', render: (item) => <WrapWord>{( item.email || '' )}</WrapWord> },
                        { id: 'badge', label: 'Badge', render: (item) => (
                        <ButtonGroup>
                            { item.user_badge_id && !_isEmpty( item.user_badge_id ) ? <AInfoLink href={PLATFORM_BADGE_URL+item.user_badge_id} target="_blank" size="small">View Badge</AInfoLink> : null }
                            { hasCert ? <ASuccessLink href={PLATFORM_CERT_URL+item.user_badge_id} target="_blank" size="small">View Cert</ASuccessLink> : null }
                        </ButtonGroup>
                        ) },
                    ]}
                    noAction={true}
                    elevation={0}
                        />        
            </>
            ) : <DotsLoader /> } />
    
    </>
    );

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ListRecipients);