/* eslint-disable */
import _random from 'lodash/random';

import { 
    GET_CLASS_CONFIRMATION,
    UPDATE_CLASS_CONFIRMATION,
    GET_UNRECONCILIATION_PAYMENTS,
    UPDATE_CLASS_PAYMENT_TRACKING,
    UPDATE_CLASS_COURSE_ACCESS,
    UPDATE_CLASS_ATTENDANCE_REPORTING,
    TRIGGER_CLASS_EMAIL,
	GET_CLASS_SHOWCASE,
	UPDATE_CLASS_SHOWCASE,
    GET_CLASS_QUARTERLY_REPORT,
    PREVIEW_CLASS_QUARTERLY_REPORT,
    TRIGGER_CLASS_QUARTERLY_REPORT,
    UPDATE_CLASS_QUARTERLY_REPORT,
    RESET_CLASS
} from '../actions/types';

const initialState = {
    confirmation: false,
    unreconciliation_payments: false,
    showcase: false,
    quarterly_report: false,
    updated: null,
    rand: false
};

export const classes = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_CLASS_CONFIRMATION:
            return {
                ...state,
                confirmation: ( payload.confirmation || {} ),
                rand: _random(1,9999)
            };
        case GET_UNRECONCILIATION_PAYMENTS:
            return {
                ...state,
                unreconciliation_payments: ( payload.list || {} ),
                rand: _random(1,9999)
            };
        case GET_CLASS_SHOWCASE:
            return {
                ...state,
                showcase: ( payload.showcase || {} ),
                rand: _random(1,9999)
            };    
        case GET_CLASS_QUARTERLY_REPORT:
            return {
                ...state,
                quarterly_report: ( payload.quarterly_report || {} ),
                rand: _random(1,9999)
            }; 
        case UPDATE_CLASS_CONFIRMATION:
        case UPDATE_CLASS_PAYMENT_TRACKING:
        case UPDATE_CLASS_COURSE_ACCESS:
        case UPDATE_CLASS_ATTENDANCE_REPORTING:
        case UPDATE_CLASS_SHOWCASE:
        case PREVIEW_CLASS_QUARTERLY_REPORT:
        case TRIGGER_CLASS_QUARTERLY_REPORT:
        case UPDATE_CLASS_QUARTERLY_REPORT:
        case TRIGGER_CLASS_EMAIL:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_CLASS:
            return {
                ...state,
                confirmation: false,
                showcase: false,
                quarterly_report: false,
                updated: null,
                rand: false
            };
        default:
            return state;
    }
}