/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import queryString from 'query-string';
import Tooltip from '@material-ui/core/Tooltip';
import shortid from 'shortid';
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _replace from 'lodash/replace';
import _remove from 'lodash/remove';
import _size from 'lodash/size';

import ScreenLoader from '../../components/ScreenLoader';

import { getAPIErrorMessage } from '../../helpers/action';
import { isArrayExists, validate, checkIfAmountIsValid } from '../../helpers/validation';
import { getSelectedValue, cloneCollections, trimEmail } from '../../helpers/data';
import { getMomentTime, printSemiPrettyDate } from '../../helpers/date';
import { isSkip, isSchemaRequired, doSchemaErrorCheck } from '../../helpers/schemas';
import { triggerErrorAlert } from '../../helpers/alert';
import { formatMoney } from '../../helpers/number';

import { FullScreenFlex, FullScreenComponent } from '../../styles/division';
import { Button, InverseButton, GreyButton, InverseLink, InfoButton, InfoLink, AInverseLink } from '../../styles/button';

import { getPaymentForm } from '../../actions/payment_form';
import { fb_createPaymentForm, fb_getPaymentStatus } from '../../actions/payment_form/fb';
import { toggleLoader } from '../../actions/global';
import { appChangesMade, resetRedux } from '../../actions/misc';

import { paymentSchema } from '../../schemas/payment';

import { PLATFORM_URL, PLATFORM_EMAIL, PLATFORM_PHONE, PLATFORM_NAME_LONG, DAY_OPTIONS, GENDER_OPTIONS, PLATFORM_WEBSITE_URL, IPAY88_PAYMENT_REQUEST_URL } from '../../constants';

const MainWrapper = styled.div`
    max-width: 700px;
    margin: 0px auto;
    padding: 60px 20px;
`;

const InfoWrapper = styled.div`
    padding: 0px 20px;
    max-width: 600px;
    color: #999;
    font-size: 12px;

    p {
        margin-bottom: 15px;
        &:last-child { margin-bottom: 0px; }
    }

    a {
        color: ${blue['700']};
        font-weight: 700;
        text-decoration: underline;

        &:hover {
            color: color: ${blue['500']};
        }
    }
`;

const ConfirmWrapper = styled.div`
    padding: 0px 20px;

    p {
        font-size: 18px;
        margin-bottom: 10px;
        strong { font-weight: 700; }
        &:last-child { margin-bottom: 0px }
    }

    h4 {
        margin: 20px 0;
        font-weight: 700;
    }

    button {
        width: 100%;
        max-width: 100%;
        text-align: center;
        padding: 15px 10px;
    }
`;

const PaymentButtons = styled.div`
    @media print {
        & > div { display: none; }
    }
`;

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    },
    paragraph: {
        fontSize: "16px",
        fontWeight: "400",
        marginBottom: "15px",
    },
    tooltip: { maxWidth: "600px" },
    wrapperpaper: {
        padding: '30px 20px',
        marginBottom: '30px',
        background: '#fff',
        color: theme.palette.background
    },
    table: {
        marginBottom: '30px',
        border: '1px solid ' + theme.palette.background,
        '& tr:last-child > td': {
            borderColor: theme.palette.background
        }
    },
    hcell: {
        width: '30%',
        fontWeight: '700',
        fontSize: '16px',
        verticalAlign: 'top',
        color: theme.palette.background,
    },
    tcell: {
        width: '70%',
        fontWeight: '400',
        fontSize: '14px',
        verticalAlign: 'top',
        color: theme.palette.background,
    },
    whatsapp: {
        display: "inline-block",
        marginLeft: '8px',
        color: "#fff",
        borderRadius: "100%",
        verticalAlign: 'middle',
        fontSize: '16px',
        width: '26px',
        height: '26px',
        lineHeight: '26px',
        textAlign: 'center',
        backgroundColor: "#25D366",
        '&:hover': {
            backgroundColor: "#128C7E"
        }
    }
});

class PaymentStatusPage extends React.Component {

    state = {
        data: false,
        dataLoaded: false
    }

    componentDidMount() {
        setTimeout(() => {
            this.getPaymentStatus();
        },15000)
    }

    getPaymentStatus = () => {
        const formData = this.getPaymentFormData();
        fb_getPaymentStatus(formData)
        .then( data => {
            this.setState({ data, dataLoaded: true });
        })
        .catch(error => {
            this.setState({ dataLoaded: true });
        });
    }

    getPaymentFormData = () => {
        const { location = {} } = this.props;
        return queryString.parse(location.search);
    }

    renderGeneralFailedMessage = () => {
        const { classes } = this.props;
        const formData = this.getPaymentFormData();
        return (
        <>
            <h1 style={{ fontWeight: "300", fontSize: '32px', textTransform: "uppercase", marginBottom: "20px", textAlign: "center" }}>PAYMENT RECEIPT</h1>
            <Table className={classes.table}>
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.hcell}>Payment Status:</TableCell>
                        <TableCell className={classes.tcell} style={{ color: red['700'] }}>FAIL</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.hcell}>Reason:</TableCell>
                        <TableCell className={classes.tcell}>{validate( formData['ErrDesc'], 'safe_escape' )}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Typography variant="body1" style={{ marginTop: "20px" }} align="center">If you have any enquires regarding this, please contact us via email at {PLATFORM_EMAIL} or call us directly at {PLATFORM_PHONE}.</Typography>
        </>
        );
    }

    renderGeneralMessage = () => {
        return (
        <>
            <Typography variant="h3" style={{ marginBottom: "30px" }} align="center">Thank You! We're currently processing your payment.</Typography>
            <Typography variant="body1" style={{ marginBottom: "15px" }} align="center">You should receive an email contains all the information about your payment once it's processed.</Typography>
            <Typography variant="body1" style={{ marginBottom: "15px" }} align="center">If you did not receive any email within the next 60 minutes, please contact us via email at {PLATFORM_EMAIL} or call us directly at {PLATFORM_PHONE}.</Typography>
        </>
        )
    }

    renderPaymentDetails = () => {
        const { classes } = this.props;
        const { data } = this.state;
        const success = ( data && data.payment && data.payment.status && data.payment.status === 'success' ?  true :  false );
        return (
        <>
            <h1 style={{ fontWeight: "300", fontSize: '32px', textTransform: "uppercase", marginBottom: "20px", textAlign: "center" }}>PAYMENT RECEIPT</h1>
            <Table className={classes.table}>
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.hcell}>Payment Status:</TableCell>
                        <TableCell className={classes.tcell} style={{ color: ( data && data.payment && data.payment.status && data.payment.status === 'success' ?  green['700'] :  red['700'] ) }}>{( data && data.payment && data.payment.status && data.payment.status === 'success' ?  'SUCCESS' :  'FAIL' )}</TableCell>
                    </TableRow>
                    { success ? (
                    <>
                        <TableRow>
                            <TableCell className={classes.hcell}>Ref no / Payment ID:</TableCell>
                            <TableCell className={classes.tcell}>{( data && data.payment && data.payment.id ?  data.payment.id :  '' )}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.hcell}>Payment date & time:</TableCell>
                            <TableCell className={classes.tcell}>{( data && data.payment && data.payment.updated_on ?  getMomentTime( data.payment.updated_on, 'YYYY-MMM-DD hh:mm:ssa' ) :  '' )}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.hcell}>Amount paid:</TableCell>
                            <TableCell className={classes.tcell}>{( data && data.payment && data.payment.ip_amount ?  data.payment.ip_amount :  '' )}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.hcell}>Paid to:</TableCell>
                            <TableCell className={classes.tcell}>{( data && data.region && data.region.label ?  data.region.label :  '' )}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.hcell}>Course name:</TableCell>
                            <TableCell className={classes.tcell}>{( data && data.schedule && data.schedule.course_name ?  data.schedule.course_name :  '' )}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.hcell}>Class name:</TableCell>
                            <TableCell className={classes.tcell}>{( data && data.schedule && data.schedule.class_name ?  data.schedule.class_name :  '' )}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.hcell}>Student name:</TableCell>
                            <TableCell className={classes.tcell}>{( data && data.user && data.user.name ?  data.user.name :  '' )}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.hcell}>Student email:</TableCell>
                            <TableCell className={classes.tcell}>{( data && data.user && data.user.email ?  data.user.email :  '' )}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.hcell}>Name:</TableCell>
                            <TableCell className={classes.tcell}>{( data && data.payment && data.payment.name ?  data.payment.name :  '' )}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.hcell}>Mobile no.:</TableCell>
                            <TableCell className={classes.tcell}>{( data && data.payment && data.payment.phone ?  data.payment.phone :  '' )}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.hcell}>Email:</TableCell>
                            <TableCell className={classes.tcell}>{( data && data.payment && data.payment.email ?  data.payment.email :  '' )}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.hcell}>Remarks:</TableCell>
                            <TableCell className={classes.tcell}>{( data && data.payment && data.payment.remarks ?  validate( data.payment.remarks, 'safe_escape' ) :  '' )}</TableCell>
                        </TableRow>
                    </>
                    ) : (
                    <TableRow>
                        <TableCell className={classes.hcell}>Reason:</TableCell>
                        <TableCell className={classes.tcell}>{( data && data.payment && data.payment.ip_errdesc ?  data.payment.ip_errdesc :  '' )}</TableCell>
                    </TableRow>
                    )}
                </TableBody>
            </Table>
            { success ? (
            <>
                <Typography variant="body1" style={{ marginTop: "20px" }} align="center">Thank you for your payment.<br />Your payment has been successfully received.<br />This is a computer-generated receipt and does not require a signature.</Typography>

            </>
            ) : (
            <>
                { data && data.trainee && data.trainee.id && !_isEmpty( data.trainee.id ) ? <Typography variant="body1" style={{ marginTop: "20px" }} align="center">We are sorry this happen to you. Please remake your payment again, by <a href={PLATFORM_URL + 'payment?id=' +data.trainee.id}>clicking here</a></Typography> : null }
                <Typography variant="body1" style={{ marginTop: "20px" }} align="center">If you have any enquires regarding this, please contact us via email at {PLATFORM_EMAIL} or call us directly at {PLATFORM_PHONE}.</Typography>
            </>
            ) }
        </>
        );
    }

    renderForm() {
        const { submitted, data } = this.state;
        const formData = this.getPaymentFormData();
        return (
        <>

            { data && data.payment && data.payment.status && ( data.payment.status === 'success' || data.payment.status === 'fail' ) ? (
                <>
                    {this.renderPaymentDetails()}
                </>
            ) : ( formData && formData.Status && formData.Status === '0' && formData.ErrDesc && !_isEmpty( formData.ErrDesc ) ? this.renderGeneralFailedMessage() : this.renderGeneralMessage() )}
            
            {/* <InfoWrapper>
                <Typography variant="body1">some text here</Typography>
            </InfoWrapper> */}

            <PaymentButtons style={{ textAlign: "center", marginTop: "60px" }}>
                <div>
                    { data && data.payment && data.payment.status && data.payment.status === 'success' ? <div style={{ paddingBottom: "15px"}}><InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={(event) => {
                        event.preventDefault();
                        window.print();
                    }}>Print Receipt</InfoButton></div> : null }
                    <AInverseLink href={PLATFORM_WEBSITE_URL} style={{ padding: "15px 5px" }} minWidth="250px"><i className="fa fa-long-arrow-left"></i>Go Back to Website</AInverseLink>
                </div>
            </PaymentButtons>

        </>
        );
    }

    renderContent() {
        const { classes } = this.props;
        return (
        <FullScreenComponent style={{ 
            // background: 'url("/loader_bg.jpg")', backgroundPosition: "center center", backgroundSize: "100%", backgroundRepeat: 'repeat-y', 
            background: '#e5e5e5',
            height: "auto", minHeight: "100vh" }}>
            <MainWrapper>
                <Paper elevation={2} className={classes.wrapperpaper}>
                    <div style={{ margin: "0 auto 30px auto", maxWidth: '175px' }}><img src="/logo.gif" style={{ display: "block", height: 'auto', width: '100%' }} /></div>
                    {this.renderForm()}
                </Paper>
                {this.renderInfo()}
            </MainWrapper>
        </FullScreenComponent>
        );
    }

    renderErrorMessage = () => {
        const { classes } = this.props;
        const { error } = this.state;
        return (
        <FullScreenComponent style={{ 
            background: 'url("/loader_bg.jpg")', backgroundPosition: "center center", backgroundSize: "100%", backgroundRepeat: 'repeat-y', 
            height: "auto", minHeight: "100vh" }}>
            <MainWrapper>
                <Paper elevation={2} className={classes.wrapperpaper}>
                    <div style={{ margin: "0 auto 30px auto", maxWidth: '175px' }}><img src="/logo.gif" style={{ display: "block", height: 'auto', width: '100%' }} /></div>
                    <Typography variant="h4" style={{ color: red['700'], textAlign: "center", background: red['50'], padding: '20px', border: '2px solid '+ red['700'] }}>{error}</Typography>
                    <div style={{ textAlign: "center", marginTop: "30px" }}>
                        <AInverseLink href={PLATFORM_WEBSITE_URL} style={{ padding: "15px 5px" }} minWidth="250px"><i className="fa fa-long-arrow-left"></i>Go Back to Website</AInverseLink>
                    </div>
                </Paper>
                {this.renderInfo()}
            </MainWrapper>
        </FullScreenComponent>
        );
    }

    renderInfo = () => {
        return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} align="center">
                <a href="https://www.chumbaka.asia/privacy-policy" target="_blank" style={{ textDecoration: 'underline' }}>Privacy Policy</a>
            </Grid>
            <Grid item xs={12} sm={6} align="center">
                <a href="https://www.chumbaka.asia/class-policies" target="_blank" style={{ textDecoration: 'underline' }}>Terms & Conditions</a>
            </Grid>
            <Grid item xs={12} sm={6} align="center" style={{ color: '#666' }}>
                <i className="fa fa-phone" style={{ marginRight: '8px' }}></i>+604 373 0401 
            </Grid>
            <Grid item xs={12} sm={6} align="center" style={{ color: '#666' }}>
                <i className="fa fa-envelope" style={{ marginRight: '8px' }}></i>{PLATFORM_EMAIL}
            </Grid>
            <Grid item xs={12} align="center">
                <Typography variant="body1" style={{ color: '#666' }}>
                    303-4-8, Block B, Krystal Point, Jalan Sultan Azlan Shah, 11900 Bayan Lepas, Penang<br /><br />
                    © Copyright Chumbaka Sdn Bhd (1065257-D)<br />
                    All rights reserved.
                </Typography>
            </Grid>
        </Grid>
        )
    }

    render() {
        const { dataLoaded, error } = this.state;
        return !dataLoaded ? <ScreenLoader text={
        <>
            Verifying Payment Status...<br />
            Please do not close this window or click the Back button on your browser
        </>
        } /> : ( error ? this.renderErrorMessage() : this.renderContent()  );
    }

}

const mapStateToProps = state => {
    return {
        paymentData: state.payment_form && state.payment_form.data ? state.payment_form.data : null,
        paymentUpdated: state.payment_form && state.payment_form.updated ? state.payment_form.updated : null,
        paymentError: state.payment_form && state.payment_form.error_message ? state.payment_form.error_message : null,
        randNum: state.payment_form && state.payment_form.data ? state.payment_form.rand : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles),
    withRouter
)(PaymentStatusPage);