/* eslint-disable */
import firebase from "firebase/app";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";

import { callFunctionsAPI } from "../../helpers/action";
import { isArrayExists, validateEmail, isObjectExists } from "../../helpers/validation";
import { getSnapshotDataBySchema, getDocDataBySchema } from "../../helpers/schemas";

import { userStudentSchema } from "../../schemas/user_student";

import { getMentorEnrollments } from "../mentors/fb";

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve, reject) => {});
};

/* helper end */

export const fb_getStudentsList = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "student", action: "get_list", formData })
            .then((list) => {
                resolve(list);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_getStudentData = (uid) => {
    return new Promise((resolve, reject) => {
        let promises = [];
        promises.push(callFunctionsAPI({ url: "student", action: "get", formData: { uid } }));

        if (uid && !_isEmpty(uid)) {
            promises.push(getMentorEnrollments(uid));
        }

        Promise.all(promises)
            .then((results) => {
                let student = results && isArrayExists(results) && results[0] && isObjectExists(results[0]) ? { ...results[0] } : {};
                if (results[1] && results[1].enrollments && isArrayExists(results[1].enrollments)) {
                    student.mentor_enrollments = results[1].enrollments;
                } // end - results
                resolve(student);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_getStudentUIDByEmail = (email) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "student", action: "get_uid", formData: { email } })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_searchStudentData = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "student", action: "search", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_updateStudentData = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "student", action: "update", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_deleteStudentFromDatabase = (uid) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "student", action: "delete", formData: { uid } })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_deleteStudentBadge = (badge_id) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "dbp", action: "remove_user_badge", formData: { badge_id } })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_exportStudentData = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "student", action: "export", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_addParentObverser = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "student", action: "add_observer", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_removeParentObverser = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "student", action: "remove_observer", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_triggerStudentRegionTransfer = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "student", action: "student_transfer_region", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_triggerStudentAccountTransfer = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "student", action: "student_transfer_account", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_triggerStudentPasswordChange = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "student", action: "update_lms_password", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_triggerStudentBackpackTransfer = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "student", action: "student_transfer_backpack", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
