/* eslint-disable */
import _random from "lodash/random";
import _isEmpty from "lodash/isEmpty";

import { GET_COURSE_REGISTRATION, GET_COURSE_REGISTRATION_ERROR, DO_COURSE_REGISTRATION, GET_TRAINEES, GLOBAL_ERROR_TRIGGER } from "../types";

import { getAPIErrorMessage } from "../../helpers/action";
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from "../global";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import { appChangesReset } from "../misc";

import { fb_getCourseRegistrationData, fb_doCourseRegistration, fb_importCourseRegistration } from "./fb";
import { fb_getTrainees } from "../trainees/fb";

export const getCourseRegistrationData = (id) => {
    return (dispatch) => {
        fb_getCourseRegistrationData(id)
            .then((data) => {
                dispatch({
                    type: GET_COURSE_REGISTRATION,
                    payload: { data },
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_COURSE_REGISTRATION_ERROR,
                    payload: getAPIErrorMessage(error),
                });
            });
    };
};

export const doCourseRegistration = (formData) => {
    return (dispatch) => {
        dispatch(toggleLoader(true));

        fb_doCourseRegistration(formData)
            .then((results) => {
                // updated
                dispatch({
                    type: DO_COURSE_REGISTRATION,
                    payload: {
                        past_registrations: results,
                    },
                });
                dispatch(toggleLoader(false));
            })
            .catch((error) => {
                dispatch(toggleLoader(false));
                triggerErrorAlert(getAPIErrorMessage(error));
            });
    };
};

export const importCourseRegistration = (formData, props) => {
    return (dispatch) => {
        var apiNum = _random(1, 9999);
        dispatch(toggleModalProcessing(true, apiNum));
        dispatch(toggleLoader(true, "Importing process is on-going! Please do not close this window or click the Back button on your browser"));

        fb_importCourseRegistration(formData)
            .then((results) => {
                fb_getTrainees(props).then((list) => {
                    // push new data
                    dispatch({ type: GET_TRAINEES, payload: { list } });
                    dispatch(toggleModalProcessing(false));
                    dispatch(toggleLoader(false));
                    triggerSuccessAlert("Registration Data Imported");
                });
            })
            .catch((error) => {
                dispatch(toggleModalProcessing(false, apiNum));
                dispatch(toggleLoader(false));
                triggerErrorAlert(getAPIErrorMessage(error));
            });
    };
};
