/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';

export const Tag = styled.div`
    display: inline-block;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 6px;
`;

export const SuccessTag = styled(Tag)`
    color: ${green['700']};
    background: none;
    ${props => {
        if ( props.showBorder && props.showBorder == 'yes' ) {
            return `
            border: 2px solid ${green['700']};
            `;
        }
    }}
`;

export const InfoTag = styled(Tag)`
    color: ${blue['700']};
    background: none;
    ${props => {
        if ( props.showBorder && props.showBorder == 'yes' ) {
            return `
            border: 2px solid ${blue['700']};
            `;
        }
    }}
`;

export const ErrorTag = styled(Tag)`
    color: ${red['700']};
    background: none;
    ${props => {
        if ( props.showBorder && props.showBorder == 'yes' ) {
            return `
            border: 2px solid ${red['700']};
            `;
        }
    }}
`;

export const AmberTag = styled(Tag)`
    color: ${amber['700']};
    background: none;
    ${props => {
        if ( props.showBorder && props.showBorder == 'yes' ) {
            return `
            border: 2px solid ${amber['700']};
            `;
        }
    }}
`;

export const GreyTag = styled(Tag)`
    color: ${grey['700']};
    background: none;
    ${props => {
        if ( props.showBorder && props.showBorder == 'yes' ) {
            return `
            border: 2px solid ${grey['700']};
            `;
        }
    }}
`;