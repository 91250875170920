/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
// import _snakeCase from 'lodash/snakeCase';
// import _find from 'lodash/find';

import { callFunctionsAPI, doPromise } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

const triggerRegistrationImportAPI = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'courseRegistration', action: 'import', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

const doAsyncAPICall = async ({ schedule_id, trainees }) => {

    const list = [];
    const limits = 10;
    let count = 1,
        batch = [];

    if ( trainees && isArrayExists( trainees ) ) {
        _forEach( trainees, trainee => {
            batch.push(trainee);
            if ( count === limits ) {
                list.push(batch); // add to list
                // then reset
                count = 1; 
                batch = [];
            } else {
                count++;
            } // end - count
        });

        // if batch is not empty - add to list
        if ( batch && isArrayExists( batch ) )
            list.push( batch );

    } // end - trainees

    if ( list && isArrayExists( list ) ) {
        let cIndex = 0;
        while ( cIndex < list.length ) {
            await triggerRegistrationImportAPI({ schedule_id, trainees: cloneCollections( list[cIndex]) })
                .then(() => {
                    cIndex++;
                })
                .catch(error => {
                    let errorMessage = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to import registration. Please try again.' ) );
                    throw new Error( errorMessage );
                });
        }
    } // end - contacts

    return 'done';
}


/* helper end */

export const fb_getCourseRegistrationData = (schedule_id) => {
    return new Promise((resolve,reject) => {

        doPromise({ method: 'POST', url: 'courseRegistration', params: [{ key: 'action', value: 'get' }], formData: { schedule_id } })
        .then(res => {
            resolve(res.data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_doCourseRegistration = (formData) => {
    return new Promise((resolve,reject) => {

        doPromise({ method: 'POST', url: 'courseRegistration', params: [{ key: 'action', value: 'register' }], formData })
        .then(res => {
            resolve(res.data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_importCourseRegistration = (formData) => {
    return new Promise((resolve,reject) => {

        doAsyncAPICall(formData)
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}