/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AppWrapper from '../../components/AppWrapper';
import List from './list';

import { getMaintenance } from '../../actions/maintenance';

class MaintenancePricePerPax extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {
        // get list
        this.props.dispatch(getMaintenance('price_per_pax'));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { pricePerPaxList, randNum } = this.props;

        if ( pricePerPaxList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    renderContents() {
        const { pricePerPaxList, authData } = this.props;
        return <List 
                type="price_per_pax"
                typeLabel="Price Per Pax"
                authData={authData} 
                list={pricePerPaxList} />;
    }

    render() {
        const { pricePerPaxList, randNum } = this.props;
        return <AppWrapper 
                title="Price Per Pax"
                subtitle="Maintenance"
                onLoad={( !( pricePerPaxList && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        pricePerPaxList: state.maintenance && state.maintenance.price_per_pax ? state.maintenance.price_per_pax : null,
        randNum: state.maintenance && state.maintenance.rand ? state.maintenance.rand : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(MaintenancePricePerPax);