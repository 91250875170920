/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import shortid from 'shortid';
import styled from "styled-components";
import Alert from "react-s-alert";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import _reverse from 'lodash/reverse';
import _remove from 'lodash/remove';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModalView from '../../components/ModalView';
import ModelDelete from '../../components/ModalDelete';
import FormInput from '../../components/FormInput';
import FormCheckbox from '../../components/FormCheckbox';
import FormSelect from '../../components/FormSelect';
import FormDatePicker from '../../components/FormDatePicker';

import { ButtonGroup, InverseButton, InfoButton, ErrorButton, GreyButton } from '../../styles/button';
import { WrapWord } from '../../styles/misc';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';
import { uploadMediaToStorage } from '../../helpers/firebase';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';

import { scheduleSchema } from '../../schemas/schedule';

import { toggleLoader } from '../../actions/global';

import { YES_NO_OPTIONS, DAY_OPTIONS } from '../../constants';

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

class ScheduleSessions extends React.Component {

    utcOffSet = 8;

    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        sortBy: 'date-asc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalType: 'edit',
        modalData: false,
        openDeleteModal: false,
        deleteModal: false,
        randNum: false
    };

    handleFormUpdate = ( newValue, key , subkey , subVal ) => {
        const { onUpdate, schedule } = this.props;
        const { modalData } = this.state;
        let newData = ( modalData && !_isEmpty( modalData ) ? cloneCollections( modalData ) : {} );
        switch( key ) {
            case 'class_date':
                newData.start_date = ( schedule && schedule.start_date ? moment( newValue ).utcOffset(this.utcOffSet).set({
                    hour: moment(schedule.start_date).utcOffset(this.utcOffSet).get('hour'),
                    minute: moment(schedule.start_date).utcOffset(this.utcOffSet).get('minute'),
                    second: 0,
                    millisecond: 0
                }).valueOf() : 0 );
                newData.end_date = ( schedule && schedule.end_date ? moment( newValue ).utcOffset(this.utcOffSet).set({
                    hour: moment(schedule.end_date).utcOffset(this.utcOffSet).get('hour'),
                    minute: moment(schedule.end_date).utcOffset(this.utcOffSet).get('minute'),
                    second: 0,
                    millisecond: 0
                }).valueOf() : 0 );
                break;
            case 'showcase':
            case 'quarterly_report':
                if ( subkey && ( subkey === 'checked' || subkey === 'unchecked' ) && subVal && !_isEmpty( subVal ) ) {
                    // use subVal as the new value
                    newData[key] = subVal;
                }
                break;
            default:
                newData[key] = newValue;
                break;
        }

        // do update
        this.setState({ modalData: newData });
    }

    handleSessionAction = () => {
        const { onUpdate, schedule } = this.props;
        const { modalData, modalType } = this.state;
        let newSessions = ( schedule && schedule.sessions && isArrayExists( schedule.sessions ) ? cloneCollections( schedule.sessions ) : [] );
        
        if ( modalType && modalType === 'edit' ) {
            let index = ( modalData && modalData.id ? _findIndex( newSessions, { id: modalData.id } ) : -1 );
            newSessions[index] = cloneCollections( modalData );
        } else {
            newSessions.push( modalData );
        } // end - modalType

        // close modal
        this.setState({ openViewModal: false, modalType: 'add', modalData: false });

        // do update
        if ( onUpdate )
            onUpdate( newSessions, 'sessions' );
    }

    handleSessionDelete = () => {
        const { onUpdate, schedule } = this.props;
        const { deleteModal } = this.state;
        let newSessions = ( schedule && schedule.sessions && isArrayExists( schedule.sessions ) ? cloneCollections( schedule.sessions ) : [] ),
            index = ( deleteModal && deleteModal.id ? _findIndex( newSessions, { id: deleteModal.id } ) : -1 );
        
        if ( index >= 0 ) {
            let pulled = _remove( newSessions, { id: deleteModal.id });
        } // end - index

        // close modal
        this.setState({ openDeleteModal: false, deleteModal: false });

        // do update
        if ( onUpdate )
            onUpdate( newSessions, 'sessions' );
    }

    handleMassDelete = () => {
        const { onUpdate, schedule } = this.props;
        const { checked } = this.state;
        let answer = window.confirm( 'Are you sure you want to delete selected item(s)?\n\nThis action cannot be reversed.');
        if ( answer ) {
            let newSessions = ( schedule && schedule.sessions && isArrayExists( schedule.sessions ) ? cloneCollections( schedule.sessions ) : [] );
            // remove from checked item
            _forEach( checked, item => {
                let index = _findIndex( newSessions, { id: item.id });
                if ( index >= 0 ) {
                    let pulled = _remove( newSessions, { id: item.id });
                } // end - index
            });

            // reset checkbox
            this.setState({ checked: [], allCheck: false });

            // do update
            if ( onUpdate )
                onUpdate( newSessions, 'sessions' );
        } // end - answer
    }

    handleAddNewSession = () => {
        const { schedule } = this.props;
        const todayYear = moment().utcOffset(this.utcOffSet).get('year'),
            todayMonth = moment().utcOffset(this.utcOffSet).get('month'),
            todayDay = moment().utcOffset(this.utcOffSet).get('date'),
            start_date = moment(schedule.start_date).utcOffset(this.utcOffSet).set({ year: todayYear, month: todayMonth, date: todayDay, second: 0, millisecond: 0 }).valueOf(),
            end_date = moment(schedule.end_date).utcOffset(this.utcOffSet).set({ year: todayYear, month: todayMonth, date: todayDay, second: 0, millisecond: 0 }).valueOf();
        this.setState({ openViewModal: true, modalData: this.getNewSessionProps( start_date, end_date ), modalType: 'add' });
    }

    handleGenerateSessions = () => {
        const { onUpdate, schedule, regions, locations, courses } = this.props;
        let sessions = [],
            selectedDay = _find( DAY_OPTIONS, { value: schedule.repeat_weekly_on }); // get selected day option;

        // make sure start date and end date is set
        if ( schedule && schedule.start_date && schedule.end_date && selectedDay ) {
            // check if is repeat
            if ( schedule.repeat_weekly && schedule.repeat_weekly === 'yes' && schedule.repeat_weekly_on && !_isEmpty( schedule.repeat_weekly_on ) ) {
                let endDate = moment( schedule.end_date ).utcOffset(this.utcOffSet).endOf('isoWeek'),
                    startDate = moment( schedule.start_date ).utcOffset(this.utcOffSet).startOf('isoWeek'),
                    totalWeeks = endDate.diff( startDate, 'weeks' );
                // run through total weeks to set sessions
                for (let i = 0; i <= totalWeeks; i++) {
                    // calculate startofweek
                    let startofWeek = moment( startDate ).utcOffset(this.utcOffSet).set({ 
                        isoWeekday: selectedDay.number, 
                        hour: startDate.hour(), 
                        minute: startDate.minute(), 
                        millisecond: 0, 
                        second: 0 }).add((i*7),'day');

                    // make sure it doesn't go backforward & go over end date
                    if ( startofWeek.valueOf() >= schedule.start_date && startofWeek.valueOf() <= schedule.end_date ) {
                        let sessionStart = startofWeek.valueOf(),
                            sessionEnd = startofWeek.set({ hour: endDate.hour(), minute: endDate.minute(), millisecond: 0, second: 0 }).valueOf();
                        sessions.push( this.getNewSessionProps( sessionStart, sessionEnd ) );
                    } // end - startofWeek
                } // end - for

                // if sessions is empty
                if ( !isArrayExists( sessions ) ) {
                    triggerErrorAlert("No Valid Date Found.")
                } // end - sessions             

            } else {
                // else just create a session based on the selected date
                sessions.push( this.getNewSessionProps( schedule.start_date, schedule.end_date ) );
            } // end - schedule
        } // end - schedule

        // do update
        if ( onUpdate )
            onUpdate( sessions, 'sessions' );
    }

    getNewSessionProps = ( start_date = 0, end_date = 0 ) => {
        return {
            id: shortid.generate(),
            start_date, 
            end_date,
            showcase: 'yes',
            quarterly_report: 'yes', 
            remarks: '',
            lms_mentor_id: '',
            lms_event_id: '',
            lms_context_code: '',
        };
    }

    reorganizeData = () => {
        const { sortBy, perPage, page } = this.state;
        const { schedule } = this.props;
        let items = ( schedule.sessions && isArrayExists( schedule.sessions ) ? cloneCollections( schedule.sessions ) : [] ),
            total = _size( items );

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['start_date'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['start_date'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderEditForm = () => {    
        const { modalData, modalType } = this.state;
        return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormDatePicker label="Class Date" name="class_date" noDefaultVal={true} value={( modalData.start_date || 0 )} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={6}>
                    <div style={{ padding: '0 10px' }}>
                        <FormCheckbox label="Enable Showcase" name="showcase" value={( modalData.showcase || 'no' )} checked="yes" unchecked="no" onChange={this.handleFormUpdate} />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ padding: '0 10px' }}>
                        <FormCheckbox label="Enable Quarterly Report" name="quarterly_report" value={( modalData.quarterly_report || 'no' )} checked="yes" unchecked="no" onChange={this.handleFormUpdate} />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <FormInput label="Remark" name="remarks" value={( modalData.remarks || '' )} multiline={true} rows={3} onChange={this.handleFormUpdate} />
                </Grid>
            </Grid>
        </div>
        );
    }

    renderCell = type => item => {
        switch ( type ) {
            case 'start_date':
                return ( item[type] ? getMomentTime( item[type], 'DD MMM YYYY' ) : '-' );
            case 'showcase':
            case 'quarterly_report':
                return <Checkbox
                        checked={( item[type] === 'yes' ? true : false )}
                        onChange={event => {
                            const { schedule, onUpdate } = this.props;
                            let newSessions = ( schedule && schedule.sessions && isArrayExists( schedule.sessions ) ? cloneCollections( schedule.sessions ) : [] ),
                                index = _findIndex( newSessions, { id: item.id } );
                            if ( index >= 0 ) {
                                newSessions[index][type] = ( event.target.checked ? 'yes' : 'no' );
                            } // end - index
                            if ( onUpdate )
                                onUpdate( newSessions, 'sessions' );
                        }}
                        value={( item[type] || 'no' )}
                        color="primary" />;
            default:
                return <WrapWord>{( item[type] || '' )}</WrapWord>;
        }
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    doneLoaded={true}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderTableActions = () => {
        const { sortBy, perPage, searchterms, checked } = this.state;
        return <TableBar
                show={['sort', 'entries']}
                sortBy={sortBy}
                perPage={perPage}
                sortByOptions={[
                    { value: 'date-asc', label: 'Class Date (Ascending)' },
                    { value: 'date-desc', label: 'Class Date (Descending)' },
                ]}
                leftButtons={ checked && isArrayExists( checked ) ? [
                    <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                        <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                        <ErrorButton minWidth="0px" key="delete" style={{ marginRight: "5px", padding: '5px 15px' }} onClick={this.handleMassDelete} noIconMargin="yes"><i className="fa fa-trash"></i></ErrorButton>
                    </div>
                    ] : null }
                rightButtons={[
                    <InfoButton minWidth="128px" key="addnew" style={{ marginRight: "5px" }} onClick={this.handleAddNewSession}><i className="fa fa-plus-circle"></i>Add New</InfoButton>
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderSessionTable = () => {
        const { checked, allCheck } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <>
            {this.renderTableActions()}
            <Table 
                items={items}
                showCheckbox={true}
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={(newValue) => this.setState({ checked: newValue })}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                cells={[
                    { id: 'start_date', label: 'Class Date', render: this.renderCell('start_date') },
                    { id: 'showcase', label: 'Showcase', render: this.renderCell('showcase') },
                    { id: 'quarterly_report', label: 'Quarterly Report', render: this.renderCell('quarterly_report') },
                    { id: 'remarks', label: 'Remarks', render: (item) => ( item.remarks || '')},
                ]}
                actionStyles={{ width: "20%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <InfoButton key="edit" size="small" onClick={() => this.setState({ openViewModal: true, modalData: item, modalType: 'edit' })}><i className="fa fa-edit"></i>Edit</InfoButton>
                        <ErrorButton key="delete" size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton>
                    </ButtonGroup>
                )} />
            {this.renderPagination(total)}
        </>
        );
    }

    renderGenerateButton = () => {
        const { schedule } = this.props;
        const btnStyle = { padding: '10px 25px' };
        return schedule && schedule.start_date && schedule.end_date ? <InverseButton style={btnStyle} onClick={this.handleGenerateSessions}><i className="fa fa-calendar-o"></i>Generate Class Calendar</InverseButton> : <Tooltip title="Please insert a Start Date & End Date first" arrow><InverseButton disabled="yes" style={btnStyle}><i className="fa fa-calendar-o"></i>Generate Class Calendar</InverseButton></Tooltip>;
    }

    render = () => {
        const { classes, schedule, formType } = this.props;
        const { openDeleteModal, deleteModal, openViewModal, modalType } = this.state;
        return (
        <>
            
            { schedule.sessions && isArrayExists( schedule.sessions ) ? this.renderSessionTable() : this.renderGenerateButton() }

            <ModalView 
                open={openViewModal}
                title={ modalType && modalType === 'new' ? "Add New Class Calendar" : "Edit Class Calendar" }
                actionLabel={ modalType && modalType == 'edit' ? "Update" : "Add New" }
                onClose={() => this.setState({ openViewModal: false, modalData: false })}
                doAction={this.handleSessionAction}
                contents={this.renderEditForm()} />

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.start_date ? `Are you sure you want to delete this session ( ${getMomentTime( deleteModal.start_date, 'DD MMM YYYY' )} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleSessionDelete} />

        </>
        );
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles),
    withRouter
)(ScheduleSessions);