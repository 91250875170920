/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AppWrapper from '../../components/AppWrapper';
import List from './list';

import { getMaintenance } from '../../actions/maintenance';

class MaintenanceRaces extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {
        // get list
        this.props.dispatch(getMaintenance('races'));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { racesList, randNum } = this.props;

        if ( racesList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    renderContents() {
        const { racesList, authData } = this.props;
        return <List 
                type="races"
                typeLabel="Ethnicity"
                authData={authData} 
                list={racesList} />;
    }

    render() {
        const { racesList, randNum } = this.props;
        return <AppWrapper 
                title="Ethnicity"
                subtitle="Maintenance"
                onLoad={( !( racesList && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        racesList: state.maintenance && state.maintenance.races ? state.maintenance.races : null,
        randNum: state.maintenance && state.maintenance.rand ? state.maintenance.rand : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(MaintenanceRaces);