/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Papa from 'papaparse';
import styled from "styled-components";
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _toLower from 'lodash/toLower';
import _split from 'lodash/split';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';
import FormCheckbox from '../../components/FormCheckbox';
import ButtonActions from '../../components/ButtonActions';
import ToolTipInfo from '../../components/ToolTipInfo';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from '../../styles/tag';
import { ErrorBox } from '../../styles/message';
import { WrapWord } from '../../styles/misc';

import { isArrayExists, isObjectExists, validate } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp, getAge } from '../../helpers/date';

import { updateCourseAccessLog } from '../../actions/hq_summary';

import { ENROLLMENT_STATUS_OPTIONS } from '../../constants';

const SuccessIcon = styled.i`
    color: #64dd17;
`;

const GreyIcon = styled.i`
    color: #64dd17;
`;

const WarningIcon = styled.i`
    color: #ff6f00;
`;

const RemarkColumn = styled.div`
    padding: 0px;
    font-size: 11px;
    height: 100%;
    width: 100%;
    max-width: 200px;
`;

const RadioWrapper = styled.div`
    .MuiFormControl-fullWidth {
        background: none;
    }
    .MuiFormLabel-root,
    .MuiTypography-body1 {
        font-size: 11px;
    }

    ${props => {
        if ( props.disablePointer && props.disablePointer === 'yes' ) {
            return `
            .MuiFormControlLabel-root,
            .MuiIconButton-label > input {
                cursor: default;
            }
            `;
        }
    }};
`;

const TextareaWrapper = styled.div`
    textarea {
        width: 100%;
        max-width: 150px;
        font-size: 11px;
        padding: 2px;
        overflow: scroll-y;
    }
`;

class TableView extends React.Component {

    doCSVDownload = (results,name) => {
        const { year } = this.props;
        let csv = Papa.unparse( results, { delimiter: ',' } );
        let blob = new Blob( [csv] , { type: 'text/csv;charset=utf-8;' }); //new way
        let uri = window.URL.createObjectURL(blob);
        let link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", '_blank');
        link.setAttribute("download", "export_business_dashboard_"+name+".csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    handleCSVDownload = (event) => {
        const { year } = this.props;
        event.preventDefault();
        const cells = this.getCells();
        const { items } = this.reorganizeData();
        let csvData = {
            fields: [],
            data: []
        };

        // add header
        _forEach( cells, cell => {
            csvData.fields.push(cell.label);
        });

        if ( items && isArrayExists( items ) ) {
            _forEach( items, item => {
                let row = [];
                _forEach( cells, cell => {
                    if ( cell.id === 'total' || cell.id === 'label' ) {
                        row.push( item[cell.id] || '' );
                    } else {
                        row.push( item[year+cell.id] || '' );
                    }  
                });
                csvData.data.push(row);
            });
        } // end - items
        
        this.doCSVDownload(csvData,'table_'+year);
    }

    reorganizeData() {
        const { list, regions, year } = this.props;
        let items = [
            { id: 'mentors', label: '# Mentor', total: '' },
            { id: 'classes_active_students', label: '# Classes with Active Students', total: '' },
            { id: 'total_registrations', label: '# of Registrations', total: '' },
            { id: 'total_confirmed_students', label: '# of Confirmations', total: '' },
            { id: 'total_current_active_class_size', label: '# of Active Students', total: '' },
            { id: 'total_active_products', label: '# of Active Products', total: '' },
            { id: 'total_badges_awarded', label: '# of badges', total: '' },
        ];

        // map out each month
        items = items.map( item => {
            let newItem = { ...item };
            for (let index = 1; index < 13; index++) {
                let key = year+( index > 9 ? index : '0'+index );
                newItem[key] = ( _find( list, { id: key } ) ? 0 : '---' );
            }
            return newItem;
        });

        if ( list && isArrayExists( list ) ) {
            _forEach( list, item => {
                // add mentors
                items[0][item.id] = ( item.mentors && isArrayExists( item.mentors ) ? _size( item.mentors ) : 0 );
                if ( item.classes && isArrayExists( item.classes ) ) {
                    _forEach( item.classes, schedule => {
                        // add classes with active students
                        items[1][item.id] += ( schedule.current_active_class_size ? 1 : 0 );
                        // add total_registrations
                        items[2][item.id] += ( schedule.total_registrations || 0 );
                        // add total_confirmed_students
                        items[3][item.id] += ( schedule.total_confirmed_students || 0 );
                        // add total_current_active_class_size
                        items[4][item.id] += ( schedule.current_active_class_size || 0 );
                        // add total_active_products
                        items[5][item.id] += ( schedule.total_active_products || 0 );
                        // add total_badges_awarded
                        items[6][item.id] += ( schedule.total_badges_awarded || 0 );
                    });
                }
            });
        }

        // compile total
        items = items.map( item => {
            let newItem = { ...item };
            newItem.total = 0;
            for (let index = 1; index < 13; index++) {
                newItem.total += validate( newItem[year+( index > 9 ? index : '0'+index )], 'numeric' ) ? newItem[year+( index > 9 ? index : '0'+index )] : 0;
            }
            return newItem;
        });

        return { items };
    }

    getCells = () => {
        const { year } = this.props;
        let cells = [];
        cells.push({ id: 'label', label: ' ', render: this.renderCell('label') });

        if ( year !== '2022' ) {
            cells.push( { id: '01', label: 'Jan-' +year[2]+year[3], render: this.renderCell(year+'01') } );
            cells.push( { id: '02', label: 'Feb-' +year[2]+year[3], render: this.renderCell(year+'02') } );
            // re-enable below
            // cells.push( { id: '03', label: 'Mar-' +year[2]+year[3], render: this.renderCell('03') } );
        }

        // remove this
        cells.push( { id: '03', label: 'Mar-' +year[2]+year[3], render: this.renderCell(year+'03') } );

        cells.push( { id: '04', label: 'Apr-' +year[2]+year[3], render: this.renderCell(year+'04') }  );
        cells.push( { id: '05', label: 'May-' +year[2]+year[3], render: this.renderCell(year+'05') }  );
        cells.push( { id: '06', label: 'Jun-' +year[2]+year[3], render: this.renderCell(year+'06') }  );
        cells.push( { id: '07', label: 'Jul-' +year[2]+year[3], render: this.renderCell(year+'07') }  );
        cells.push( { id: '08', label: 'Aug-' +year[2]+year[3], render: this.renderCell(year+'08') }  );
        cells.push( { id: '09', label: 'Sep-' +year[2]+year[3], render: this.renderCell(year+'09') }  );
        cells.push( { id: '10', label: 'Oct-' +year[2]+year[3], render: this.renderCell(year+'10') }  );
        cells.push( { id: '11', label: 'Nov-' +year[2]+year[3], render: this.renderCell(year+'11') }  );
        cells.push( { id: '12', label: 'Dec-' +year[2]+year[3], render: this.renderCell(year+'12') }  );
        cells.push( { id: 'total', label: 'Total', render: this.renderCell('total') }  );

        return cells;
    }

    renderCell = (key) => (item) => {
        const { year } = this.props;
        switch( key ) {
            case 'label':
            case 'total':
                return ( item[key] || '--' );
            default:
                return ( item[key] || 0 === item[key] ? item[key] : '--' );
        }
    }

    renderTableActions = () => {
        return <TableBar
                show={[]}
                rightButtons={[
                    <InfoButton minWidth="168px" key="csv" style={{ marginRight: "5px", padding: '10px 25px' }} onClick={this.handleCSVDownload}><i className="fa fa-file-excel-o"></i>Download Table Data as CSV</InfoButton>,
                ]}
                style={{ marginBottom: "20px" }} />
    }

    render() {
        const { items } = this.reorganizeData();
        return (
        <div>

            {this.renderTableActions()}
            <Table 
                items={items}
                showCheckbox={false}
                emptyCell="No item(s) Found."
                cells={this.getCells()}
                 />

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(TableView);