import { 
    // api_env, 
    firebase_dev_account 
    } from './env';

export const FIREBASE_ACCOUNT_ID = ( firebase_dev_account ? 'chumbaka-dev-tms' : 'chumbaka-dev-tms' ); //*incomplete - don't add main account ID yet
export const DBP_ACCOUNT_ID = ( firebase_dev_account ? 'chumbaka-dev-account' : 'chumbaka-dev-account' ); //*incomplete - don't add main account ID yet
export const FIREBASE_REGION = 'us-central1';

// emulator port - make sure it's same as firebase.json
export const USE_EMULATOR = false;
export const EMULATOR_PORT_CLOUD_FUNCTIONS = 6100;
export const EMULATOR_PORT_FIRESTORE = 6200;
export const EMULATOR_PORT_DATABASE = 6300;
export const EMULATOR_PORT_UI = 6900;

export const FIREBASE_CONFIG_DATA = ( firebase_dev_account ? {
    apiKey: "AIzaSyDrRRXuTc1n0Fr2mnYNDjKFpgPHHZLpkr4",
    authDomain: "chumbaka-dev-tms.firebaseapp.com",
    databaseURL: "https://chumbaka-dev-tms-default-rtdb.firebaseio.com",
    projectId: "chumbaka-dev-tms",
    storageBucket: "chumbaka-dev-tms.appspot.com",
    messagingSenderId: "1090794486939",
    appId: "1:1090794486939:web:721f481b118a943e691a3e"
} : {
    apiKey: "AIzaSyDrRRXuTc1n0Fr2mnYNDjKFpgPHHZLpkr4",
    authDomain: "chumbaka-dev-tms.firebaseapp.com",
    databaseURL: "https://chumbaka-dev-tms-default-rtdb.firebaseio.com",
    projectId: "chumbaka-dev-tms",
    storageBucket: "chumbaka-dev-tms.appspot.com",
    messagingSenderId: "1090794486939",
    appId: "1:1090794486939:web:721f481b118a943e691a3e"
}); //*incomplete - don't add main account config data yet