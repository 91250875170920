/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import ButtonActions from '../ButtonActions';

import { GreyLink, InfoButton, InfoLink, AInfoLink } from '../../styles/button';

import { appChangesReset } from '../../actions/misc';

// import { DEV_MODE } from '../../constants';

import theme from '../../theme';

const Wrapper = styled.div`
    text-align: right;
`;

const MenuWrapper = styled.div`
    padding: 20px;
    overflow: hidden auto;
    
    a {
        &:hover {
            color: ${grey['700']} !important;
        }
    }
`;

class ScheduleSwitcher extends React.Component {

    handleClick = (link) => (event) => {
        const { authData, schedule_id, changesMade } = this.props;
        event.preventDefault();
        if ( changesMade ) {
            let answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to switch to another page?");
            if ( answer ) {
                this.props.dispatch(appChangesReset());
                this.props.history.push(link,{ schedule_id });

            } // end - answer 
        } else {
            this.props.history.push(link,{ schedule_id });
        }
    }

    getLinks = () => {
        const { authData, schedule_id } = this.props;
        let links = [];

        links.push({ id: 'class_schedule', label: 'Class Schedule', onClick: this.handleClick('/schedules/'+schedule_id) });
        links.push({ id: 'class_registration', label: 'Registration', onClick: this.handleClick('/class_registration') });
        links.push({ id: 'class_confirmation', label: 'Confirmation', onClick: this.handleClick('/class_confirmation') });
        links.push({ id: 'class_payment_tracking', label: 'Payment', onClick: this.handleClick('/class_payment_tracking') });
        links.push({ id: 'class_attendance_reporting', label: 'Attendance & Reporting', onClick: this.handleClick('/class_attendance_reporting') });
        links.push({ id: 'course_access', label: 'Course Access', onClick: this.handleClick('/course_access') });

        return links;
    }

    renderNextButton = () => {
        const { schedule_id, page } = this.props;
        const btnStyle = {
            padding: '8px 25px',
            marginRight: "5px"
        };
        switch (page) {
            case 'class_schedule':
                return <InfoButton rightIcon="yes" onClick={this.handleClick('/class_registration')} style={btnStyle}>Registration<i className="fa fa-long-arrow-right"></i></InfoButton>;
            case 'class_registration':
                return <InfoButton rightIcon="yes" onClick={this.handleClick('/class_confirmation')} style={btnStyle}>Confirmation<i className="fa fa-long-arrow-right"></i></InfoButton>;
            case 'class_confirmation':
                return <InfoButton rightIcon="yes" onClick={this.handleClick('/course_access')} style={btnStyle}>Course Access<i className="fa fa-long-arrow-right"></i></InfoButton>;
             case 'course_access':
                return <InfoButton rightIcon="yes" onClick={this.handleClick('/class_payment_tracking')} style={btnStyle}>Payment<i className="fa fa-long-arrow-right"></i></InfoButton>;
            case 'class_payment_tracking':
                return <InfoButton rightIcon="yes" onClick={this.handleClick('/class_attendance_reporting')} style={btnStyle}>Attendance & Reporting<i className="fa fa-long-arrow-right"></i></InfoButton>;
            case 'class_attendance_reporting':
                return <InfoButton rightIcon="yes" onClick={this.handleClick('/class_showcase')} style={btnStyle}>Showcase<i className="fa fa-long-arrow-right"></i></InfoButton>;
            case 'class_showcase':
                return <InfoButton rightIcon="yes" onClick={this.handleClick('/class_quarterly_report')} style={btnStyle}>Quarterly Report<i className="fa fa-long-arrow-right"></i></InfoButton>;

        }
    }

    renderMenu = () => {
        const { schedule_id } = this.props;
        let linkStyle = { 
                display: 'block',
                marginBottom: "10px", 
                textAlign: 'left',
                background: 'none',
                color:  blue['700']
            },
            currentLinkStyle = {
                ...linkStyle,
                color: '#212121'
            };
        return (
        <MenuWrapper>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="h5" style={{ marginBottom: "15px", borderBottom: '1px solid #ddd', padding: "0px 5px 15px 5px" }}>1. Recruitment</Typography>
                    <AInfoLink href={"/r/"+schedule_id} onClick={this.handleClick('/schedules/'+schedule_id)} style={linkStyle}>Class Schedule</AInfoLink>
                    <AInfoLink href={"/r/"+schedule_id+"?location=registration"} onClick={this.handleClick('/class_registration')} style={linkStyle}>Registration</AInfoLink>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5" style={{ marginBottom: "15px", borderBottom: '1px solid #ddd', padding: "0px 5px 15px 5px" }}>2. Enrolment</Typography>
                    <AInfoLink href={"/r/"+schedule_id+"?location=confirmation"} onClick={this.handleClick('/class_confirmation')} style={linkStyle}>Confirmation</AInfoLink>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5" style={{ marginBottom: "15px", borderBottom: '1px solid #ddd', padding: "0px 5px 15px 5px" }}>3. Delivery</Typography>
                    <AInfoLink href={"/r/"+schedule_id+"?location=course_access"} onClick={this.handleClick('/course_access')} style={linkStyle}>Course Access</AInfoLink>
                    <AInfoLink href={"/r/"+schedule_id+"?location=payment"} onClick={this.handleClick('/class_payment_tracking')} style={linkStyle}>Payment</AInfoLink>
                    <AInfoLink href={"/r/"+schedule_id+"?location=attendance"} onClick={this.handleClick('/class_attendance_reporting')} style={linkStyle}>Attendance & Reporting</AInfoLink>
                    <AInfoLink href={"/r/"+schedule_id+"?location=student_database"} onClick={this.handleClick('/student_database')} style={linkStyle}>Student Database</AInfoLink>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5" style={{ marginBottom: "15px", borderBottom: '1px solid #ddd', padding: "0px 5px 15px 5px" }}>4. Closing</Typography>
                    <AInfoLink href={"/r/"+schedule_id+"?location=showcase"} onClick={this.handleClick('/class_showcase')} style={linkStyle}>Showcase</AInfoLink>
                    <AInfoLink href={"/r/"+schedule_id+"?location=quarterly_report"} onClick={this.handleClick('/class_quarterly_report')} style={linkStyle}>Quarterly Report</AInfoLink>
                </Grid>
            </Grid>
        </MenuWrapper>
        )
    }

    render() {
        return (
        <Wrapper>
            {this.renderNextButton()}
            <div style={{ display: 'inline-block' }}>
                <ButtonActions 
                    key="actions" 
                    label="Quick Access"
                    menuContainerStyle={{ width: "400px" }}
                    color="inverse"
                    style={{ padding: "8px 25px" }}
                    customMenu={this.renderMenu()}
                    actions={this.getLinks()} />
            </div>
        </Wrapper>
        )
    }

}

const mapStateToProps = state => {
    return {
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ScheduleSwitcher);