/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _filter from "lodash/filter";
import _find from "lodash/find";
import _size from "lodash/size";
import _isEqual from "lodash/isEqual";
import _findIndex from "lodash/findIndex";

import AppWrapper from "../../components/AppWrapper";
import AppInfoBox from "../../modules/AppInfoBox";
import ScheduleSwitcher from "../../components/ScheduleSwitcher";
import SnackBarSave from "../../components/SnackBarSave";
import Table from "./table";
import ClassFilterMenu from "../ClassRegistrationPage/filter_menu";
import ColumnsMenu from "./columns_menu";
import EmailSender from "./email";
import PaymentHistory from "./payment_history";

import { isAdmin, getUserRegionFilter } from "../../helpers/auth";
import { cloneCollections } from "../../helpers/data";
import { isArrayExists } from "../../helpers/validation";

import { updateClassPaymentTracking, getUnreconciliationPayments } from "../../actions/class_payment_tracking";
import { getTrainees } from "../../actions/trainees";
// import { getCoursesOptions } from '../../actions/courses';
import { getSchedulesOptions, getSchedule } from "../../actions/schedules";
import { getRegions } from "../../actions/m_regions";
import { getMaintenance } from "../../actions/maintenance";
import { resetRedux } from "../../actions/misc";

import { ButtonGroup, AInfoLink, InfoButton } from "../../styles/button";
import { WarningBox, InfoBox } from "../../styles/message";

import { ENROLLMENT_STATUS_OPTIONS } from "../../constants";

const TableWrapper = styled.div`
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
`;

const getDefaultColumns = () => {
    let columns = [];
    for (let i = 2; i >= 0; i--) {
        let date = moment().utcOffset(8).add(i, "months").startOf("month");
        columns.push({
            id: date.format("YYYY-MM"),
            label: date.format("MMM YY"),
            start_at: date.valueOf(),
            end_at: date.endOf("month").valueOf(),
        });
    }
    for (let i = 1; i < 4; i++) {
        let date = moment().utcOffset(8).subtract(i, "months").startOf("month");
        columns.push({
            id: date.format("YYYY-MM"),
            label: date.format("MMM YY"),
            start_at: date.valueOf(),
            end_at: date.endOf("month").valueOf(),
        });
    }
    return columns;
};

class ClassPaymentTrackingPage extends React.Component {
    state = {
        trainees: false,
        traineesRand: false,
        currentSchedule: false,
        columns: getDefaultColumns(),
        email_open: false,
        email_list: false,
        email_column: false,
        email_type: false,
        paymentOpen: false,
        paymentTrainee: false,
        dataLoaded: false,
    };

    componentDidMount() {
        const { authData, regionsList, schedulesList, pricePerPaxList, emailTemplatesList, location } = this.props;

        // preload trainees
        if (location && location.state && location.state.schedule_id && !_isEmpty(location.state.schedule_id))
            this.handleFilter(location.state.schedule_id);

        // get regions
        if (!regionsList) this.props.dispatch(getRegions());

        // get course options
        // if ( !coursesList )
        //     this.props.dispatch(getCoursesOptions({ region: getUserRegionFilter(authData) }));

        // get price per pax list
        if (!pricePerPaxList) this.props.dispatch(getMaintenance("price_per_pax"));

        // get schedule options
        if (!schedulesList) this.props.dispatch(getSchedulesOptions({ region: getUserRegionFilter(authData) }));

        // get email templates
        if (!emailTemplatesList) this.props.dispatch(getMaintenance("email_templates"));

        if (regionsList && schedulesList && emailTemplatesList) {
            this.setState({ dataLoaded: true });
        } // end - randNum
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { traineesList, regionsList, schedulesList, pricePerPaxList, emailTemplatesList, traineesRand } = this.props;
        if (regionsList && schedulesList && pricePerPaxList && emailTemplatesList && !this.state.dataLoaded) {
            this.setState({ dataLoaded: true });
        } // end - randNum

        if (traineesList && traineesRand && traineesRand !== this.state.traineesRand) {
            this.setState({
                traineesRand,
                trainees: traineesList && isArrayExists(traineesList) ? cloneCollections(traineesList) : [],
            });
        } // end - scheduleRand
    }

    componentWillUnmount() {
        this.handleReset();
    }

    handleReset = () => {
        this.props.dispatch(resetRedux("trainees"));
        this.props.dispatch(resetRedux("classes"));
        this.props.dispatch(resetRedux("schedule"));
    };

    handleFilter = (schedule_id) => {
        const { authData } = this.props;
        this.setState({ currentSchedule: schedule_id });
        this.props.dispatch(getTrainees({ schedule_id, enrollment_status: this.getEnrollmentStatusFilter() }, true));
        this.props.dispatch(getUnreconciliationPayments({ schedule_id, region: getUserRegionFilter(authData) }));
        this.props.dispatch(getSchedule(schedule_id, { authData }));
    };

    handleEmailSend = ({ trainees, email_type, column = false }) => {
        this.setState({ email_open: true, email_list: trainees, email_type, email_column: column || false });
    };

    handleSaveChanges = (event) => {
        const { traineesList } = this.props;
        const { trainees, currentSchedule } = this.state;
        event.preventDefault();
        let formData = { schedule_id: currentSchedule, trainees: [] };
        // run through all the trainees to look for changes
        if (trainees && isArrayExists(trainees) && traineesList && isArrayExists(traineesList)) {
            _forEach(trainees, (trainee) => {
                let oriTrainee = _find(traineesList, { id: trainee.id });
                if (!_isEqual(trainee, oriTrainee)) {
                    formData.trainees.push({
                        id: trainee.id,
                        payment_tracking: trainee.payment_tracking || [],
                        payment_level:
                            trainee.payment_level &&
                            (trainee.payment_level === "l1" ||
                                trainee.payment_level === "l2" ||
                                trainee.payment_level === "l3" ||
                                trainee.payment_level === "l4" ||
                                trainee.payment_level === "l5")
                                ? trainee.payment_level
                                : "",
                    });
                }
            });
        } // end - trainees

        this.props.dispatch(
            updateClassPaymentTracking(formData, {
                schedule_id: currentSchedule,
                enrollment_status: this.getEnrollmentStatusFilter(),
            })
        );
    };

    getEnrollmentStatusFilter = () => {
        let statuses = [];
        ENROLLMENT_STATUS_OPTIONS.forEach((option) => {
            if (option.value && !_isEmpty(option.value) && option.value !== "delete" && option.value !== "registered" && option.value !== "trial")
                statuses.push(option.value);
        });
        return statuses;
    };

    renderTable() {
        const {
            authData,
            traineesList,
            regionsList,
            schedulesList,
            pricePerPaxList,
            unreconciliationPaymentsList,
            currentScheduleData,
            changesMade,
        } = this.props;
        const { currentSchedule, trainees, columns } = this.state;
        return (
            <Table
                columns={columns || false}
                currentSchedule={currentSchedule || false}
                schedule={currentScheduleData || false}
                trainees={trainees}
                currentTrainees={traineesList}
                schedules={schedulesList}
                price_per_pax={pricePerPaxList}
                unreconciliation_payments={unreconciliationPaymentsList}
                regions={regionsList}
                // courses={coursesList}
                authData={authData}
                changesMade={changesMade}
                onUpdate={(newTrainees) => this.setState({ trainees: newTrainees })}
                onEmailSend={this.handleEmailSend}
                onPaymentHistoryTrigger={(trainee) => this.setState({ paymentOpen: true, paymentTrainee: trainee })}
            />
        );
    }

    renderTrackingInfo = () => {
        const { columns } = this.state;
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AppInfoBox page="ClassPaymentTrackingPage" />
                </Grid>
                <Grid item xs={12}>
                    <ColumnsMenu columns={columns || false} onUpdate={(newColumns) => this.setState({ columns: newColumns })} />
                </Grid>
            </Grid>
        );
    };

    renderContents() {
        const { traineesList, emailTemplatesList } = this.props;
        const { currentSchedule, traineesRand, trainees, email_open, email_list, email_column, email_type, columns, paymentOpen, paymentTrainee } =
            this.state;
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                        <ClassFilterMenu currentSchedule={currentSchedule || false} onFilter={this.handleFilter} />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        {traineesList && traineesRand ? this.renderTrackingInfo() : null}
                    </Grid>
                </Grid>

                {traineesList ? (
                    <TableWrapper>
                        {this.renderTable()}
                        {trainees && isArrayExists(trainees) ? (
                            <div style={{ textAlign: "center", marginTop: "45px" }}>
                                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}>
                                    <i className="fa fa-save"></i>Save Changes
                                </InfoButton>
                            </div>
                        ) : null}
                    </TableWrapper>
                ) : null}

                <SnackBarSave onSave={this.handleSaveChanges} />

                <EmailSender
                    open={email_open}
                    trainees={email_list || false}
                    column={email_column || false}
                    columns={columns || false}
                    email_type={email_type || false}
                    schedule_id={currentSchedule || false}
                    enrollment_status={this.getEnrollmentStatusFilter()}
                    traineesList={traineesList}
                    email_templates={emailTemplatesList}
                    onClose={() => this.setState({ email_open: false, email_list: false, email_type: false, email_column: false })}
                />

                <PaymentHistory
                    open={paymentOpen}
                    trainee={paymentTrainee}
                    columns={columns || false}
                    trainees={trainees || false}
                    onTraineesUpdate={(newTrainees) => this.setState({ trainees: newTrainees })}
                    onClose={() => this.setState({ paymentOpen: false, paymentTrainee: false })}
                />
            </>
        );
    }

    render() {
        const { dataLoaded, currentSchedule } = this.state;
        const { authData } = this.props;
        return (
            <AppWrapper
                subtitle="3. Delivery"
                title="Payment"
                onLoad={!dataLoaded ? true : false}
                customColumn={
                    currentSchedule && !_isEmpty(currentSchedule) ? (
                        <ScheduleSwitcher page="class_payment_tracking" schedule_id={currentSchedule} authData={authData} />
                    ) : null
                }
                contents={authData ? this.renderContents() : null}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        traineesList: state.trainees && state.trainees.trainees ? state.trainees.trainees : null,
        traineesRand: state.trainees && state.trainees.rand ? state.trainees.rand : null,
        currentScheduleData: state.schedules && state.schedules.schedule ? state.schedules.schedule : null,
        unreconciliationPaymentsList: state.classes && state.classes.unreconciliation_payments ? state.classes.unreconciliation_payments : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        pricePerPaxList: state.maintenance && state.maintenance.price_per_pax ? state.maintenance.price_per_pax : null,
        emailTemplatesList: state.maintenance && state.maintenance.email_templates ? state.maintenance.email_templates : null,
        // coursesList: state.courses && state.courses.courses_options ? state.courses.courses_options : false,
        schedulesList: state.schedules && state.schedules.schedules_options ? state.schedules.schedules_options : false,
        changesMade: (state.misc && state.misc.changes_made) || null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(ClassPaymentTrackingPage);
