import _random from 'lodash/random';

import { 
	GET_COURSES,
	GET_COURSES_OPTIONS,
    GET_PRODUCTS_OPTIONS,
    GET_COURSE,
	ADD_COURSE,
	EDIT_COURSE,
	DELETE_COURSE,
    RESET_COURSE,
    RESET_COURSES
} from '../actions/types';

const initialState = {
    courses: null,
    course: null,
    courses_options: null,
    products_options: null,
    updated: false,
    rand_options: false,
    rand_products_options: false,
    rand: false
};

export const courses = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_COURSES:
            return {
                ...state,
                courses: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case GET_COURSES_OPTIONS:
            return {
                ...state,
                courses_options: ( payload.list || [] ),
                rand_options: _random(1,9999)
            };
        case GET_PRODUCTS_OPTIONS:
            return {
                ...state,
                products_options: ( payload.list || [] ),
                rand_products_options: _random(1,9999)
            };
        case GET_COURSE:
            return {
                ...state,
                course: ( payload.course || [] ),
                rand: _random(1,9999)
            }; 
        case ADD_COURSE:
        case EDIT_COURSE:
        case DELETE_COURSE:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_COURSE:
            return {
                ...state,
                course: null,
                updated: false,
                rand: false
            };
        case RESET_COURSES:
            return {
                ...state,
                courses: null,
                rand: false
            };
        default:
            return state;
    }
}