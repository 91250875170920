/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import _forEach from 'lodash/forEach';
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';
import _replace from 'lodash/replace';
import _toString from 'lodash/toString';

import ButtonActions from '../ButtonActions';

import { InverseButton, GreyButton, ButtonGroup } from '../../styles/button';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';

import theme from '../../theme';

class FormCheckboxes extends React.Component {

    handleAllCheck = () => {
        const { name, value, keys, options } = this.props;
        if ( this.props.onChange ) {
            let newValue = ( value && isArrayExists( value ) ? cloneCollections( value ) : [] );
            if ( options && isArrayExists( options ) ) {
                _forEach( options, option => {
                    let newItem = { id: option.value };
                    if ( keys && isArrayExists( keys ) ) {
                        _forEach( keys, key => {
                            if ( key && !_isEmpty( key ) )
                                newItem[key] = ( key && option['o_'+key] ? option['o_'+key] : ( option['o_default'] || '' ) );
                        });
                    } // end - keys
                    newValue.push(newItem);
                });
            } // end - options
            this.props.onChange(newValue,name);
        }
    }

    handleAllUncheck = () => {
        const { name, value, keys, options } = this.props;
        this.props.onChange([],name);
    }

    handleChange = option => event => {
        const { name, value, keys } = this.props;
        if ( this.props.onChange ) {
            let newValue = ( value && isArrayExists( value ) ? cloneCollections( value ) : [] );
            if ( event.target.checked ) {
                if ( !_find( newValue, { id: option.value }) ) {
                    let newItem = { id: option.value };
                    if ( keys && isArrayExists( keys ) ) {
                        _forEach( keys, key => {
                            if ( key && !_isEmpty( key ) )
                                newItem[key] = ( key && option['o_'+key] ? option['o_'+key] : ( option['o_default'] || '' ) );
                        });
                    } // end - keys
                    newValue.push(newItem);
                }
            } else {
                if ( _find( newValue, { id: option.value }) ) {
                    let pulled = _remove( newValue, { id: option.value });
                }
            } // end - checked
            this.props.onChange(newValue,name);
        }
    }

    renderCheckbox = (option) => {
        const { value, label, checked, disabled, xs, sm } = this.props;
        return (
        <Grid key={option.value} item xs={( xs || 6 )} sm={( sm || 6 )}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={( value && isArrayExists( value ) && _find( value, { id: option.value }) ? true : false )}
                        disabled={( disabled || option.disabled ? true : false )}
                        onChange={this.handleChange(option)}
                        value={option.value}
                        color="primary" />
                }
                label={( option.label || '' )} />
        </Grid>
        )
    }

    renderOptionMenu = () => {
        return (
        <div style={{ padding: '5px 0 10px 0' }}>
            <ButtonActions
                label="Select"
                color="inverse"
                minWidth="none"
                style={{ padding: '5px 8px', fontSize: '12px' }}
                menuContainerStyle={{ width: "80px" }}
                zIndex="99999"
                closeOnClick={true}
                actions={[
                    { id: 'selectall', label: 'All', icon: false, onClick: this.handleAllCheck },
                    { id: 'selectnone', label: 'None', icon: false, onClick: this.handleAllUncheck }
                ]} />
        </div>
        )
    }

    renderLabel = () => {
        const { label } = this.props;
        var newLabel = false;
        if ( label && !_isEmpty( label ) && ( 
            ( _toLower( label ).indexOf("(required)") >= 0 ) || 
            ( _toLower( label ).indexOf("*") >= 0 )
         ) ) {
            newLabel = _replace( label, '(Required)', '' );
            newLabel = _replace( newLabel, '(required)', '' );
            newLabel = _replace( newLabel, '*', '' );
        } // end - label
        return ( newLabel ? <span>{ newLabel || '' }<span className="required">*</span></span> : ( label || '' ) );
    }

    render = () => {
        const { label, options, showAllCheck, desc } = this.props;
        return (
        <div style={{ paddingLeft: "5px", paddingRight: "5px" }}>
            <FormControl fullWidth={true}>
                { label && !_isEmpty( label ) ? <InputLabel shrink={true} style={{ fontSize: "16px", fontWeight: "700", textTransform: "uppercase", paddingBottom: '8px' }}>{this.renderLabel()}</InputLabel> : null }
                { label && !_isEmpty( label ) ? <div style={{ height: '18px' }}> </div> : null }
                { showAllCheck ? this.renderOptionMenu() : null }
                <Grid container spacing={1}>{ options && isArrayExists( options ) ? options.map(this.renderCheckbox) : null }</Grid>
            </FormControl>
            { desc && !_isEmpty( desc ) ? <Typography variant="body1" style={{ padding: '5px' }}>{ desc }</Typography> : null }
        </div>
        )
    }

}

export default FormCheckboxes