/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _isEqual from 'lodash/isEqual';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';
import _split from 'lodash/split';
import _toUpper from 'lodash/toUpper';

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader'
import SnackBarSave from '../../components/SnackBarSave';

import EditForm from './form';
import ItemNotFound from '../../components/ItemNotFound';

import { InfoButton, GreyButton } from '../../styles/button';

import { hasAccessRights } from '../../helpers/auth'; 
import { isArrayExists, isObjectExists, isNumeric } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { getDateNumFromString, isParentFieldRequired } from '../../helpers/date';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { getStudentFeedbackForm, updateStudentFeedbackForm } from '../../actions/student_showcase_feedback';
import { appChangesMade, resetRedux } from '../../actions/misc';

class StudentShowcaseFeedback extends React.Component {

    state = {
        currentTab: 'details',
        feedback_form: false,
        randNum: false
    };

    componentDidMount() {
        const { sis_section_id } = this.props.match.params;
        const { authData } = this.props;
        
        // retrieve feedback details
        this.props.dispatch(getStudentFeedbackForm( this.getScheduleID(sis_section_id), this.getLMSCourseID(sis_section_id) ));

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentFeedback, randNum
            } = this.props;

        // for feedback form
        if ( currentFeedback && randNum && randNum !== this.state.randNum ) {
            this.setState({ feedback_form: cloneCollections( currentFeedback ), randNum });
        } // end - currentFeedback
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('student_showcase'));
    }

    handleSaveChanges = (event) => {
        const { sis_section_id } = this.props.match.params;
        const { feedback_form } = this.state;
        event.preventDefault();
        var error = false,
            formData = {
                schedule_id: this.getScheduleID(sis_section_id),
                lms_course_id: this.getLMSCourseID(sis_section_id),
                answers: ( feedback_form && feedback_form.answers && isArrayExists( feedback_form.answers ) ? cloneCollections( feedback_form.answers ) : [] )
            };

        // do error check
        if ( feedback_form && feedback_form.fields && isArrayExists( feedback_form.fields ) ) {
            _forEach( feedback_form.fields, field => {
                if ( field.required && field.required === 'yes' ) {
                    let selected = _find( formData.answers, { id: field.id } );
                    if ( !( selected && selected.value && !_isEmpty( selected.value ) ) )
                        error = "Please fill out all the required fields";
                } // end - field.required
            });
        } // end - feedback_form.fields

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateStudentFeedbackForm(formData));
        } // end - error

    }

    handleFormUpdate = (newAnswers) => {
        const { feedback_form } = this.state;
        let newData = ( feedback_form && !_isEmpty( feedback_form ) ? cloneCollections( feedback_form ) : {} );
        newData.answers = newAnswers;
        this.setState({ feedback_form: newData });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    isMentorView = () => {
        const { location = {} } = this.props;
        return ( location && location.pathname && location.pathname.indexOf("/mentor/class/") >= 0 ? true : false );
    }

    splitSISSectionID = (sis_section_id) => {
        return ( sis_section_id && !_isEmpty( sis_section_id ) ? _split( sis_section_id, '-c', 2 ) : false );
    }

    getScheduleID = (sis_section_id) => {
        let data = this.splitSISSectionID(sis_section_id);
        return ( data && data[0] && !_isEmpty( data[0] ) ? _toUpper( data[0] ) : false );
    }

    getLMSCourseID = (sis_section_id) => {
        let data = this.splitSISSectionID(sis_section_id);
        return ( data && data[1] && !_isEmpty( data[1] ) && isNumeric( data[1] ) ? data[1] : false );
    }

    renderTabContents = () => {
        const { sis_section_id } = this.props.match.params;
        const { authData, currentFeedback, changesMade } = this.props;
        const { currentTab, feedback_form } = this.state;
        const tabProps = {
            authData: ( authData || false ),
            feedback_form: ( feedback_form || false ),
            changesMade: ( changesMade || false ),
            onFormUpdate: this.handleFormUpdate
        };
        switch( currentTab ) {
            case 'details':
                return <EditForm 
                    sis_section_id={( sis_section_id || false )}
                    schedule_id={this.getScheduleID(sis_section_id)}
                    lms_course_id={this.getLMSCourseID(sis_section_id)}
                    currentFeedback={( currentFeedback || false )}
                    {...tabProps} />;
        }
    }

    renderContents() {
        const { feedback_form } = this.state;
        return (
        <div>

            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                {this.renderTabContents()}
            </Paper>

            { 
                // ( feedback_form && feedback_form.trainee && feedback_form.trainee.showcase && feedback_form.trainee.showcase.badge_id && !_isEmpty( feedback_form.trainee.showcase.badge_id ) ) || 
                this.isMentorView() ? ( this.isMentorView() ? (
            <>
                <div style={{ textAlign: "center", marginTop: "45px" }}>
                    <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" disabled="yes" onClick={() => { /* do nothing */ }}><i className="fa fa-save"></i>Submit Form</InfoButton>
                </div>
            </>
            ) : null ) : (
            <>
                <div style={{ textAlign: "center", marginTop: "45px" }}>
                    <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Submit Form</InfoButton>
                </div>
                <SnackBarSave saveNowLabel="Submit Form" onSave={this.handleSaveChanges} />
            </>
            )}

        </div>
        );
    }

    render() {
        const { randNum } = this.state;
        const { currentFeedback } = this.props;
        return <AppWrapper 
                title="Feedback Form"
                maxWidth="1110px"
                hideTopBar={true}
                topBarHeight="0px"
                onLoad={( !( randNum ) ? true : false )}
                contents={ currentFeedback && currentFeedback.fields && !_isEmpty( currentFeedback.fields ) ? this.renderContents() : <ItemNotFound item="Feedback Form" />} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        currentFeedback: state.student_showcase && state.student_showcase.feedback_form || null,
        randNum: state.student_showcase && state.student_showcase.rand || null,
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(StudentShowcaseFeedback);