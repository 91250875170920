/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import { callFunctionsAPI, doPromise } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getPaymentForm = (trainee_id) => {
    return new Promise((resolve,reject) => {

        doPromise({ 
            method: 'POST', 
            url: 'payment', 
            params: [{ key: 'action', value: 'get' }], 
            formData: { trainee_id } 
        })
        .then(res => {
            resolve(res.data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_createPaymentForm = (formData) => {
    return new Promise((resolve,reject) => {

        doPromise({ 
            method: 'POST', 
            url: 'payment', 
            params: [{ key: 'action', value: 'create' }], 
            formData
        })
        .then(res => {
            resolve(res.data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getPaymentStatus = (formData) => {
    return new Promise((resolve,reject) => {

        doPromise({ 
            method: 'POST', 
            url: 'payment', 
            params: [{ key: 'action', value: 'get_payment_status' }], 
            formData
        })
        .then(res => {
            resolve(res.data);
        })
        .catch(error => {
            reject(error);
        });

    })
}