/* eslint-disable */
import React, { useState, useMemo, useEffect } from 'react';
import styled from "styled-components";
import Papa from 'papaparse';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import blue from '@material-ui/core/colors/blue';
import Dropzone from 'react-dropzone';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _toLower from 'lodash/toLower';

import { doCSVDownload } from './export';

import ModalView from '../../components/ModalView';
import Table from '../../components/Table';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { trimString, compareString } from '../../helpers/data';
import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';


import { InfoButton, InverseButton } from '../../styles/button';
// import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from '../../styles/tag';
import { InfoBox, SuccessBox } from '../../styles/message';
import { WrapWord } from '../../styles/misc';

const UploadBadgeNomination = ({
    authData,
    open = false,
    schedule = {},
    showcase = {},
    trainees = [],
    teams = [],
    onImport = () => {},
    onClose = () => {},
}) => {
    const [ formData, setFormData ] = useState({});
    const [ items, setItems ] = useState([]);

    const courseBadges = useMemo(() => {
        return ( showcase && showcase.badges && !_isEmpty( showcase.badges ) ? showcase.badges : [] );
    },[ showcase ]);

    const handleCSVDownload = () => {
        let csvData = {
            fields: [
                'team_id',
                'team_name',
                'state',
            ],
            data: []
        };

        // projects
        const total_projects = ( schedule && schedule.project_submissions && !_isEmpty( schedule.project_submissions ) ? parseInt( schedule.project_submissions, 10 ) : 0 );
        if ( total_projects ) {
            for (let index = 1; index <= total_projects; index++) {
                csvData.fields.push( 'project_' + index + '_title' );
                csvData.fields.push( 'project_' + index + '_url' );
                // csvData.fields.push( 'Project #' + index + ' - time created' );
                // csvData.fields.push( 'Project #' + index + ' - time edited' );
            }
        } // end -total_projects

        // add badge related
        // csvData.fields.push( 'score' );
        csvData.fields.push( 'nominated_badge_type' );

        // teams
        if ( isArrayExists( teams ) ) {
            _forEach( teams, ( team, index ) => {
                let team_projects = [];
                let team_badge = [];

                // projects
                if ( total_projects ) {
                    for (let index = 1; index <= total_projects; index++) {
                        let project = _find( team.projects, p => compareString( p.id, index ) );
                        team_projects.push( project && project.title || '' );
                        team_projects.push( project && project.desc || '' );
                        // team_projects.push( ( project && project.created_on ? getMomentTime( project.created_on, 'YYYY-MMM-DD hh:mma' )  : ( project && project.updated_on ? getMomentTime( project.updated_on, 'YYYY-MMM-DD hh:mma' ) : '' ) ) );
                        // team_projects.push( project && project.updated_on ? getMomentTime( project.updated_on, 'YYYY-MMM-DD hh:mma' ) : '' );
                    }
                }
                // badge
                // team_badge.push( team.score || '' );
                const nominated_badge = ( team.badge && team.badge.nominated_badge_type ? _find( showcase.badges, { type: team.badge.nominated_badge_type } ) : false );
                team_badge.push( ( nominated_badge && nominated_badge.type_label ? _toLower( nominated_badge.type_label ) : '' ) );

                let team_data = [
                    team.id,
                    team.name,
                    team.state,
                ];
                team_data = team_data.concat( team_projects );
                team_data = team_data.concat( team_badge );

                csvData.data.push( team_data );
            });
        }

        doCSVDownload( csvData, 'teams_badge_nomination' );

    }

    const handleFileUpload = (files) => {
        if ( files && files[0] && !_isEmpty( files[0] ) ) {
            Papa.parse(files[0], {
                header: true,
                complete: (results) => {
                    let selectedItems = [];
                    if ( results && results.data && isArrayExists( results.data ) ) {
                        _forEach( results.data, ( team, index ) => {
                            if ( 
                                team.team_id && !_isEmpty( team.team_id ) && _find( teams, { id: team.team_id } ) && team.nominated_badge_type && !_isEmpty( team.nominated_badge_type ) && _find( courseBadges, b => trimString( b.type_label, true ) === trimString( team.nominated_badge_type, true ) )
                                // _find( courseBadges, b => {
                                //     console.log( 'type_label', trimString( b.type_label, true ) );
                                //     return ( b.type_label && !_isEmpty( b.type_label ) && trimString( b.type_label, true ) === trimString( team.nominated_badge_type, true ) );
                                // } ) 
                            ) {
                                selectedItems.push( team );
                            }
                        });
                    }
                    setItems(selectedItems);
                }
            });
        } else {
            triggerErrorAlert('Please upload a valid CSV file');
        }
    }

    const handleImport = () => {
        if ( items && isArrayExists( items ) ) {
            _forEach( items, item => {
                const team = _find( teams, { id: item.team_id } );
                if ( team ) {
                    const newData = { ...team };
                    if ( !( newData.badge && !_isEmpty( newData.badge ) ) ) {
                        newData.badge = {};
                    }
                    newData.score = 'pass';
                    newData.badge.nominated_badge_type = _find( courseBadges, b => trimString( b.type_label, true ) === trimString( item.nominated_badge_type, true ) ).type;
                    onImport( newData );
                }
            });
            setItems([]);
            onClose();
            // triggerSuccessAlert('Team badge nomination uploaded successfully');
        }
    }

    return (
    <>
    <ModalView 
        open={open}
        title={"Upload Badge Nomination for Teams"}
        maxWidth="lg"
        noAction={ items && isArrayExists( items ) ? false : true }
        actionLabel="Import"
        cancelLabel="Cancel"
        doAction={handleImport}
        onClose={onClose}
        contents={ items && isArrayExists( items ) ? (
        <Grid container spacing={2}>
            <Grid item xs={12} align="left"><InverseButton style={{ padding: '10px 25px' }} onClick={() => setItems([])}><i className="fa fa-refresh"></i>Reset</InverseButton></Grid>
            <Grid item xs={12}>
                <Table 
                    items={items} 
                    emptyCell="No data found"
                    cells={[
                        { id: 'id', label: '* Team ID', headstyle: { minWidth: '100px' }, render: (item) => <WrapWord>{( item.team_id || '' )}</WrapWord> },
                        { id: 'name', label: 'Team Name', headstyle: { minWidth: '120px' }, render: (item) => <WrapWord>{( item.team_name || '' )}</WrapWord> },
                        { id: 'state', label: 'State', headstyle: { minWidth: '120px' }, render: (item) => <WrapWord>{( item.state || '' )}</WrapWord> },
                        { id: 'nominated_badge', label: '* Nominated Badge', headstyle: { minWidth: '120px' }, render: (item) => {
                            return <WrapWord>{( item.nominated_badge_type || '' )}</WrapWord>;
                        }},
                    ]}
                    noAction={true}
                    elevation={0}
                    />
                <div style={{ paddingTop: '20px', color: '#9e0000', fontStyle: 'italic' }}>* Required field(s).</div>
            </Grid>
        </Grid>
        ) : (
        <Grid container spacing={2}>
            <Grid item xs={12} align="left">
                <InfoButton style={{ padding: '10px 25px' }} onClick={handleCSVDownload}>Download Sample CSV File</InfoButton>
            </Grid>
            <Grid item xs={12}>
                { courseBadges && isArrayExists( courseBadges ) && (
                <SuccessBox>
                    <strong style={{ fontWeight: '700' }}>Available Badge Type For Nomination:</strong>
                    {courseBadges.map(badge => (
                    <div key={badge.id}>
                        {badge.type_label ? _toLower( badge.type_label ) : ''}
                    </div>
                    ))}
                </SuccessBox>
                )}
                <InfoBox>
                    <i className='fa fa-lightbulb-o' style={{ marginRight: '5px' }}></i>Max upload 100 teams at a time only. Split your CSV file using <a href="https://chumbaka.notion.site/SMS-Using-CSV-Splitter-for-Bulk-Upload-39d184801e854dd393df9f04e9ee4c90" target="_blank">this Notion guide</a>. 
                </InfoBox>
            </Grid>
            <Grid item xs={12}>
                <Dropzone onDrop={handleFileUpload} accept={".csv"}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ style: {
                        display: "flex",
                        height: '200px', 
                        width: '100%', 
                        border: '2px dashed ' + blue['700'],
                        color: blue['700'],
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer'
                    } })}>
                        <input {...getInputProps()} />
                        <div>Drop a CSV file here, or click to select a CSV file to upload</div>
                    </div>
                )}
                </Dropzone>
            </Grid>
        </Grid>
        )} />
    
    </>
    )
}

export default UploadBadgeNomination