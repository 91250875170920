/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Papa from "papaparse";
import blue from "@material-ui/core/colors/blue";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { CopyToClipboard } from "react-copy-to-clipboard";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _slice from "lodash/slice";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _find from "lodash/find";
import _toLower from "lodash/toLower";
import _findIndex from "lodash/findIndex";
import _toString from "lodash/toString";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import CourseAccessModal from "./course_access";
import MentorModal from "./mentor";
import MassEditCourseAccess from "./mass_edit";
import MassEditMentors from "./mass_edit_mentors";
import StudentClassTransfer from "./student_class_transfer";
import StudentNameToDatabase from "../../modules/StudentNameToDatabase";
import UnenrolStudents from "../../modules/UnenrolStudents";

import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import TableBar from "../../components/TableBar";
import ModelDelete from "../../components/ModalDelete";
import ModalView from "../../components/ModalView";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormSelect2 from "../../components/FormSelect2";
import FormDatePicker from "../../components/FormDatePicker";
import FormDateOfBirth from "../../components/FormDateOfBirth";
import FormPhoneField from "../../components/FormPhoneField";
import ButtonActions from "../../components/ButtonActions";
import ToolTipInfo from "../../components/ToolTipInfo";
import StudentEnrollmentStatus from "../../components/StudentEnrollmentStatus";

import {
  InfoButton,
  GreyButton,
  ButtonGroup,
  AInfoLink,
  InverseButton,
  ErrorButton,
} from "../../styles/button";
import {
  SuccessTag,
  GreyTag,
  InfoTag,
  AmberTag,
  ErrorTag,
} from "../../styles/tag";
import { WrapWord } from "../../styles/misc";

import { isArrayExists } from "../../helpers/validation";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import {
  cloneCollections,
  doPagination,
  doArraySearch,
  getSelectOptions,
  getSelectValues,
  getSelectedValue,
  compareString,
} from "../../helpers/data";
import { getMomentTime, getMomentTimestamp, getAge } from "../../helpers/date";
import { stringToInteger } from "../../helpers/number";
import { getStudentEnrollmentStatus } from "../../helpers/trainees";
import {
  isAdmin,
  hasAccessRights,
  getUserRegionFilter,
} from "../../helpers/auth";
import { doSchemaErrorCheck } from "../../helpers/schemas";

// import { deleteSchedule } from '../../actions/schedules';
import { appChangesMade } from "../../actions/misc";

import { traineeSchema } from "../../schemas/trainee";

import {
  PLATFORM_COURSE_REGISTRATION_LINK,
  GENDER_OPTIONS,
  ENROLLMENT_STATUS_OPTIONS,
  CANVAS_LMS_URL,
} from "../../constants";

// course access schema
const COURSE_ACCESS_SCHEMA = {
  id: "", // id from LMS,
  course_id: "", // course profile ID
  course_code: "", // from course profile
  status: "", // course access status
  activated: 0, // timestamp of activation
  set_deactivation: "no",
  deactivated: 0, // timestamp of deactivation
  concluded: 0, // timestamp of concluded
};

const useStyles = (theme) => ({
  popper: {
    zIndex: "3000",
    pointerEvents: "auto",
  },
  tooltip: {
    fontSize: "14px",
    color: "#fff",
    background: blue[400],
    maxWidth: 400,
    padding: "5px 10px",
    "& > .MuiTooltip-arrow": {
      color: blue[400],
    },
  },
});

class StudentsTable extends React.Component {
  state = {
    checked: [],
    allCheck: false,
    searchterms: "",
    filterBy: "all",
    sortBy: "date-desc",
    perPage: 20,
    page: 1,
    openMentorModal: false,
    openCourseModal: false,
    courseData: false,
    courseID: false,
    traineeData: false,
    openMassEdit: false,
    openMassEditMentors: false,
    openDeleteModal: false,
    deleteModal: false,
    randNum: false,
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {}

  doCSVDownload = (results) => {
    let csv = Papa.unparse(results, { delimiter: "," });
    let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" }); //new way
    let uri = window.URL.createObjectURL(blob);
    let link = document.createElement("a");

    link.setAttribute("href", uri);
    link.setAttribute("target", "_blank");
    link.setAttribute("download", "export_course_access.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  handleCSVDownload = () => {
    const { trainees, courseColumns, regions, mentors } = this.props;
    const { checked } = this.state;
    if (checked && isArrayExists(checked)) {
      let csvData = {
        fields: [
          "Partner",
          "Confirmation Date",
          "Name",
          "Email",
          "Mentor",
          "Class Status",
        ],
        data: [],
      };

      if (courseColumns && isArrayExists(courseColumns)) {
        _forEach(courseColumns, (column) => {
          csvData.fields.push((column.course_code || column.id) + " - Status");
          csvData.fields.push(
            (column.course_code || column.id) + " - Activate"
          );
          csvData.fields.push(
            (column.course_code || column.id) + " - Deactivate"
          );
        });
      } // end - courseColumns

      _forEach(checked, (selected) => {
        let item = _find(trainees, { id: selected.id });
        if (item && !_isEmpty(item)) {
          let row = [];
          row.push(getSelectedValue(regions, item.region, "id", "label", "-"));
          row.push(
            item.confirmation_email_date
              ? getMomentTime(item.confirmation_email_date, "DD MMM YYYY")
              : ""
          );
          row.push((item && item.name) || "");
          row.push((item && item.email) || "");
          row.push(
            getSelectedValue(mentors, item.mentor, "email", "name", "-")
          );

          if (getStudentEnrollmentStatus(item) === "inactivate") {
            row.push("inactive");
          } else {
            row.push(getStudentEnrollmentStatus(item));
          }

          if (courseColumns && isArrayExists(courseColumns)) {
            _forEach(courseColumns, (column) => {
              let course =
                item && item.course_access && isArrayExists(item.course_access)
                  ? _find(item.course_access, (c) =>
                      compareString(c.id, column.id)
                    )
                  : false;
              const status =
                course && course.status
                  ? getSelectedValue(
                      ENROLLMENT_STATUS_OPTIONS,
                      course.status,
                      "value",
                      "label",
                      "-"
                    )
                  : "";

              if (status && status === "inactivate") {
                row.push("inactive");
              } else {
                row.push(status);
              }

              row.push(
                course && course.activated
                  ? getMomentTime(
                      stringToInteger(course.activated),
                      "DD MMM YYYY"
                    )
                  : ""
              );
              row.push(
                course && course.deactivated
                  ? getMomentTime(
                      stringToInteger(course.deactivated),
                      "DD MMM YYYY"
                    )
                  : ""
              );
            });
          } // end - courseColumns

          csvData.data.push(row);
        } // end - trainee
      });
      this.doCSVDownload(csvData);
    } else {
      triggerErrorAlert("Please select at least one student");
    } // end - checked
  };

  handleSendMassEmail = (email_type) => () => {
    const { trainees, onEmailSend } = this.props;
    const { checked } = this.state;
    let error = false,
      list = [];
    // compile trainees list
    if (checked && isArrayExists(checked)) {
      _forEach(checked, (item) => {
        let trainee = _find(trainees, { id: item.id });
        if (trainee) list.push({ id: trainee.id, email: trainee.email || "" });
      });
    } // end - checked

    if (!(list && isArrayExists(list)))
      error = "Please select at least one student";

    if (error) {
      triggerErrorAlert(error);
    } else {
      if (onEmailSend) {
        onEmailSend({
          trainees: list,
          email_type,
        });
      } // end = onEmailSend
    }
  };

  handleTraineeUpdate = (newTrainee) => {
    const { onUpdate, trainees } = this.props;
    let newTrainees =
        trainees && isArrayExists(trainees) ? cloneCollections(trainees) : [],
      index =
        newTrainee && newTrainee.id && !_isEmpty(newTrainee.id)
          ? _findIndex(newTrainees, { id: newTrainee.id })
          : -1;

    if (index >= 0) {
      // update data
      newTrainees[index] = cloneCollections(newTrainee);
    } // end - index

    if (onUpdate) {
      onUpdate(newTrainees);
      this.props.dispatch(appChangesMade());
    }
  };

  getSelectedCourseAccess = (course_id, trainee) => {
    return trainee &&
      trainee.course_access &&
      isArrayExists(trainee.course_access) &&
      course_id &&
      !_isEmpty(course_id)
      ? _find(trainee.course_access, { id: course_id })
      : false;
  };

  getCells = () => {
    const {
      courseColumns,
      currentScheduleData,
      onClassTransferTrigger,
      regions,
      onStudentsUnenrol,
      trainees,
    } = this.props;
    let cells = [
      // { id: 'region', label: 'Licensee', render: this.renderCell('region') },
      // { id: 'class_name', label: 'Class Name', render: (item) => <WrapWord>{( currentScheduleData && currentScheduleData.class_name ? currentScheduleData.class_name : '-' )}</WrapWord> },
      {
        id: "confirmation_email_date",
        label: "Confirmation Date",
        render: this.renderCell("confirmation_email_date"),
      },
      {
        id: "name",
        label: "Name",
        render: (item) => <StudentNameToDatabase {...item} />,
      },
      {
        id: "email",
        label: "Email",
        render: (item) => (
          <>
            <WrapWord>{item.email || "-"}</WrapWord>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <StudentClassTransfer
                regions={regions}
                schedule={currentScheduleData}
                trainee={item}
                onTrigger={onClassTransferTrigger}
              />
              <div style={{ paddingTop: "5px" }}>
                <UnenrolStudents
                  id={item.id || ""}
                  name={item.name || ""}
                  schedule_id={item.schedule_id}
                  onStudentUnenrol={(trainee_id, withdrawal) => {
                    let newTrainees =
                        trainees && isArrayExists(trainees)
                          ? cloneCollections(trainees)
                          : [],
                      index =
                        trainee_id && !_isEmpty(trainee_id)
                          ? _findIndex(newTrainees, { id: trainee_id })
                          : -1,
                      email_list = [];
                    // unenrol student
                    if (index >= 0) {
                      email_list.push(newTrainees[index]);
                      // change enrollment status to delete
                      newTrainees[index].enrollment_status = "delete";
                      // get course access
                      if (
                        newTrainees[index].course_access &&
                        isArrayExists(newTrainees[index].course_access)
                      ) {
                        newTrainees[index].course_access = newTrainees[
                          index
                        ].course_access.map((item) => {
                          if (item.status && item.status !== "conclude") {
                            item.status = "inactivate";
                            item.deactivated = getMomentTimestamp();
                          }
                          return item;
                        });
                      } // end - course_access
                    }
                    onStudentsUnenrol(newTrainees, email_list, withdrawal);
                  }}
                />
              </div>
            </div>
          </>
        ),
      },
      { id: "mentor", label: "Mentor", render: this.renderCell("mentor") },
      {
        id: "enrollment_status",
        label: "Class Status",
        render: this.renderEnrollmentStatus("enrollment_status"),
      },
    ];

    if (courseColumns && isArrayExists(courseColumns)) {
      _forEach(courseColumns, (course) => {
        cells.push({
          id: "course_column_" + course.id,
          label: (
            <>
              <a href={CANVAS_LMS_URL + "courses/" + course.id} target="_blank">
                {course.course_code || ""}
              </a>
            </>
          ),
          render: this.renderCourseColumn(course.id),
        });
      });
    } // end - courseColumns

    return cells;
  };

  getActions = () => {
    const { authData } = this.props;
    let actions = [];

    actions.push({
      id: "massedit",
      label: "Mass Edit Course Access",
      icon: "fa-edit",
      onClick: () => {
        const { checked } = this.state;
        if (checked && isArrayExists(checked)) {
          this.setState({ openMassEdit: true });
        } else {
          triggerErrorAlert("Please select at least one student");
        } // end - checked
      },
    });

    actions.push({
      id: "masseditmentor",
      label: "Mass Edit Mentor",
      icon: "fa-edit",
      onClick: () => {
        const { checked } = this.state;
        if (checked && isArrayExists(checked)) {
          this.setState({ openMassEditMentors: true });
        } else {
          triggerErrorAlert("Please select at least one student");
        } // end - checked
      },
    });

    actions.push({
      id: "loginreminder",
      label: "Send Login Reminder",
      icon: "fa-envelope",
      onClick: this.handleSendMassEmail("login_reminder"),
    });

    actions.push({
      id: "withdrawnotice",
      label: "Send Withdrawal Notice",
      icon: "fa-envelope",
      onClick: this.handleSendMassEmail("withdrawal_notice"),
    });

    actions.push({
      id: "downloadcsv",
      label: "Download Selected Data as CSV",
      icon: "fa-cloud-download",
      onClick: this.handleCSVDownload,
    });

    return actions;
  };

  reorganizeData() {
    const { searchterms, sortBy, perPage, page, filterBy } = this.state;
    const { trainees, courseColumns } = this.props;
    let items = trainees ? cloneCollections(trainees) : [],
      total = _size(items);

    // do search
    if (searchterms && !_isEmpty(searchterms)) {
      items = doArraySearch(items, searchterms, ["name", "email"]);
      total = _size(items);
    } // end - searchterms

    // do filter
    // if ( filterBy && !_isEmpty( filterBy ) && filterBy !== 'all' ) {
    // items = _filter( items, (i) => {
    //     let includes = false;
    //     if ( i.course_access && isArrayExists( i.course_access ) ) {
    //         _forEach( i.course_access, course => {
    //             // make sure selected course is in the column selection
    //             if ( course && course.status === filterBy && course.id && _find( courseColumns, c => _toString(c.id) === _toString( course.id ) ) ) {
    //                 includes = true;
    //             } // end - filterBy
    //         });
    //     } // end - i.course_access
    //     if ( filterBy === 'confirmed' ) {
    //         if ( !includes && i.enrollment_status === 'confirmed' ) {
    //             includes = true;
    //         } else {
    //             includes = false;
    //         }
    //     }
    //     return includes;
    // });
    // total = _size( items );
    // } // end - filter_region
    if (filterBy && !_isEmpty(filterBy) && filterBy !== "all") {
      items = _filter(items, (i) => getStudentEnrollmentStatus(i) === filterBy);
      total = _size(items);
    } // end - filterBy

    // do sort
    if (sortBy && !_isEmpty(sortBy) && !_isEmpty(items)) {
      switch (sortBy) {
        case "date-desc":
          items = _sortBy(items, ["confirmation_email_date"]);
          items = _reverse(items);
          break;
        case "date-asc":
          items = _sortBy(items, ["confirmation_email_date"]);
          break;
        case "name-desc":
          items = _sortBy(items, ["name"]);
          items = _reverse(items);
          break;
        case "name-asc":
          items = _sortBy(items, ["name"]);
          break;
        case "email-desc":
          items = _sortBy(items, ["email"]);
          items = _reverse(items);
          break;
        case "email-asc":
          items = _sortBy(items, ["email"]);
          break;
        case "org-desc":
          items = _sortBy(items, ["org"]);
          items = _reverse(items);
          break;
        case "org-asc":
          items = _sortBy(items, ["org"]);
          break;
      }
    } // end - sortBy

    // do pagination
    items = doPagination(items, perPage, page);

    return { items, total };
  }

  renderEnrollmentStatusTag = (val) => {
    switch (val) {
      case "N/A":
      case "registered":
      case "trial":
        return (
          <WrapWord
            style={{
              fontSize: "12px",
              fontWeight: "700",
              textTransform: "uppercase",
              padding: "4px 8px",
            }}
          >
            {val}
          </WrapWord>
        );
      case "confirmed":
        return <SuccessTag>{val}</SuccessTag>;
      case "active":
        return <InfoTag>{val}</InfoTag>;
      case "inactivate":
        return <GreyTag>Inactive</GreyTag>;
      case "conclude":
        return <SuccessTag>Concluded</SuccessTag>;
      case "delete":
        return <ErrorTag>Deleted</ErrorTag>;
    } // end - val
  };

  // renderEnrollmentStatus = type => item => {
  //     if ( item[type] && !_isEmpty( item[type] ) ) {
  //         return this.renderEnrollmentStatusTag(item[type]);
  //     } // end - item[type]
  // }

  renderEnrollmentStatus = (type) => (item) => {
    return <StudentEnrollmentStatus trainee={item} />;
  };

  renderCourseColumn = (course_id) => (item) => {
    const { changesMade, onCourseAccessForceSync, classes } = this.props;
    const course = this.getSelectedCourseAccess(course_id, item);
    return (
      <div style={{ fontSize: "11px" }}>
        Status:{" "}
        {this.renderEnrollmentStatusTag(
          course && course.status && !_isEmpty(course.status)
            ? course.status
            : "N/A"
        )}
        <br />
        Activate:{" "}
        {course && course.activated && stringToInteger(course.activated)
          ? getMomentTime(stringToInteger(course.activated), "DD/MM/YYYY")
          : "N/A"}
        <br />
        Deactivate:{" "}
        {course && course.deactivated && stringToInteger(course.deactivated)
          ? getMomentTime(stringToInteger(course.deactivated), "DD/MM/YYYY")
          : "N/A"}
        <br />
        Concluded:{" "}
        {course && course.concluded && stringToInteger(course.concluded)
          ? getMomentTime(stringToInteger(course.concluded), "DD/MM/YYYY")
          : "N/A"}
        <div style={{ display: "flex", gap: "5px" }}>
          <InfoButton
            size="small"
            minWidth="0px"
            style={{ padding: "4px 8px", marginTop: "4px" }}
            onClick={() =>
              this.setState({
                openCourseModal: true,
                courseData: course || false,
                courseID: course_id,
                traineeData: item,
              })
            }
          >
            Edit
          </InfoButton>
          {course && course.status && !_isEmpty(course.status) && (
            <Tooltip
              title="Force sync activation"
              placement="top"
              arrow
              classes={{
                popper: classes.popper,
                tooltip: classes.tooltip,
              }}
            >
              <InverseButton
                size="small"
                minWidth="0px"
                style={{ padding: "4px 8px", marginTop: "4px" }}
                onClick={() => {
                  if (changesMade) {
                    triggerErrorAlert(
                      "Please save your changes before proceeding."
                    );
                  } else {
                    if (onCourseAccessForceSync) {
                      onCourseAccessForceSync({
                        trainee_id: item.id,
                      });
                    }
                  }
                }}
              >
                <i className="fa fa-refresh" style={{ marginRight: "0px" }}></i>
              </InverseButton>
            </Tooltip>
          )}
        </div>
      </div>
    );
  };

  renderCell = (type) => (item) => {
    const { regions, mentors } = this.props;
    switch (type) {
      case "region":
        return getSelectedValue(regions, item[type], "id", "label", "-");
      case "created_on":
      case "confirmation_email_date":
        return (
          <WrapWord>
            {item[type] ? getMomentTime(item[type], "DD MMM YYYY") : "---"}
          </WrapWord>
        );
      case "email":
        return (
          <>
            <WrapWord>{item[type] || "-"}</WrapWord>
            {item[type] &&
            item.bounced_emails &&
            isArrayExists(item.bounced_emails) &&
            _find(item.bounced_emails, (e) => e === item[type]) ? (
              <div>
                <AmberTag showBorder="yes" style={{ marginTop: "10px" }}>
                  <ToolTipInfo
                    icon_color="#ffa000"
                    content={
                      <>
                        BOUNCED
                        <br />
                        <br />
                        Email got bounced when sending registration receipt.
                        This email may be incorrect. Please verify with the
                        student.
                      </>
                    }
                  />
                  BOUNCED
                </AmberTag>
              </div>
            ) : null}
          </>
        );
      case "mentor":
        return (
          <>
            <div>
              <WrapWord>
                {getSelectedValue(
                  mentors,
                  item[type],
                  "email",
                  "name",
                  item[type] && !_isEmpty(item[type]) ? item[type] : "-"
                )}
              </WrapWord>
            </div>
            <InfoButton
              size="small"
              minWidth="0px"
              style={{ padding: "4px 8px", marginTop: "4px" }}
              onClick={() =>
                this.setState({
                  openMentorModal: true,
                  traineeData: item,
                })
              }
            >
              Edit
            </InfoButton>
          </>
        );
    }
  };

  renderTableActions = () => {
    const { authData } = this.props;
    const { sortBy, filterBy, perPage, searchterms, checked } = this.state;
    return (
      <TableBar
        sortBy={sortBy}
        show={["entries", "sort", "filter", "search"]}
        perPage={perPage}
        searchterms={searchterms}
        filterByLabel="Filter By Class Status"
        filterBy={filterBy}
        sortByOptions={[
          { value: "date-desc", label: "Confirmation Date (Recent First)" },
          { value: "date-asc", label: "Confirmation Date (Oldest First)" },
          { value: "name-asc", label: "Name ( A - Z)" },
          { value: "name-desc", label: "Name ( Z - A )" },
          // { value: 'email-asc', label: 'Email ( A - Z)' },
          // { value: 'email-desc', label: 'Email ( Z - A )' },
          // { value: 'org-asc', label: 'School ( A - Z)' },
          // { value: 'org-desc', label: 'School ( Z - A )' },
        ]}
        leftButtons={
          checked && isArrayExists(checked)
            ? [
                <div key="check_option" style={{ paddingTop: "10px" }}>
                  <GreyButton
                    style={{
                      padding: "10px 25px",
                      borderRadius: "25px",
                      marginRight: "10px",
                    }}
                    onClick={() =>
                      this.setState({ checked: [], allCheck: false })
                    }
                  >
                    <i
                      className="fa fa-remove"
                      style={{ marginRight: "10px" }}
                    ></i>
                    {_size(checked) + " selected"}
                  </GreyButton>
                </div>,
              ]
            : null
        }
        rightButtons={[
          <ButtonActions
            key="actions"
            label="Actions"
            menuContainerStyle={{ width: "250px" }}
            style={{ marginRight: "5px" }}
            actions={this.getActions()}
          />,
        ]}
        filterByOptions={getSelectOptions({
          list: _filter(ENROLLMENT_STATUS_OPTIONS, { course_access_2: true }),
          options: [{ value: "all", label: "All Status" }],
          keys: { value: "value", label: "label" },
        })}
        onEntriesChange={(newPerPage) =>
          this.setState({ perPage: newPerPage, page: 1 })
        }
        onSearchChange={(terms) =>
          this.setState({ searchterms: terms, page: 1 })
        }
        onSortByChange={(newSortBy) =>
          this.setState({ sortBy: newSortBy, page: 1 })
        }
        onFilterByChange={(newFilterBy) =>
          this.setState({ filterBy: newFilterBy, page: 1 })
        }
        style={{ marginBottom: "20px" }}
      />
    );
  };

  renderPagination = (totalCount) => {
    const { perPage, page } = this.state;
    return (
      <Pagination
        total={totalCount}
        perPage={perPage}
        page={page}
        style={{ marginTop: "20px" }}
        doneLoaded={true}
        // entriesLabel="(Total Registration For This Class)"
        onPageChange={(newPage) => this.setState({ page: newPage })}
      />
    );
  };

  render() {
    const {
      authData,
      courses,
      currentTrainees,
      lms_courses,
      mentors,
      currentScheduleData,
      trainees,
    } = this.props;
    const {
      openDeleteModal,
      deleteModal,
      openMentorModal,
      openCourseModal,
      courseData,
      courseID,
      traineeData,
      allCheck,
      checked,
      openMassEdit,
      openMassEditMentors,
    } = this.state;
    const { items, total } = this.reorganizeData();
    return (
      <div>
        <MentorModal
          open={openMentorModal}
          traineeData={traineeData || false}
          schedule={currentScheduleData}
          mentors={mentors || false}
          onClose={() =>
            this.setState({ openMentorModal: false, traineeData: false })
          }
          onUpdate={this.handleTraineeUpdate}
        />

        <CourseAccessModal
          open={openCourseModal}
          schedule={currentScheduleData}
          courseData={courseData || false}
          courseID={courseID || false}
          traineeData={traineeData || false}
          courses={courses || false}
          lms_courses={lms_courses || false}
          currentTrainees={currentTrainees || false}
          onClose={() =>
            this.setState({
              openCourseModal: false,
              courseID: false,
              courseData: false,
              traineeData: false,
            })
          }
          onUpdate={this.handleTraineeUpdate}
        />

        <MassEditCourseAccess
          open={openMassEdit}
          selected={checked}
          schedule={currentScheduleData}
          trainees={trainees}
          lms_courses={lms_courses}
          courses={courses}
          currentTrainees={currentTrainees || false}
          onClose={() => this.setState({ openMassEdit: false })}
          onUpdate={(newTrainees) => {
            const { onUpdate } = this.props;
            if (onUpdate) {
              onUpdate(newTrainees);
              this.props.dispatch(appChangesMade());
            }
          }}
        />

        <MassEditMentors
          open={openMassEditMentors}
          selected={checked}
          schedule={currentScheduleData}
          trainees={trainees}
          mentors={mentors}
          currentTrainees={currentTrainees || false}
          onClose={() => this.setState({ openMassEditMentors: false })}
          onUpdate={(newTrainees) => {
            const { onUpdate } = this.props;
            if (onUpdate) {
              onUpdate(newTrainees);
              this.props.dispatch(appChangesMade());
            }
          }}
        />

        {this.renderTableActions()}
        <Table
          items={items}
          showCheckbox={true}
          emptyCell="No Student(s) Found."
          checked={checked || []}
          allCheck={allCheck}
          onChecked={(newValue) => this.setState({ checked: newValue })}
          onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
          cells={this.getCells()}
        />
        {this.renderPagination(total)}
      </div>
    );
  }
}

export default compose(
  connect(),
  withRouter,
  withStyles(useStyles)
)(StudentsTable);
