/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Alert from "react-s-alert";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';

import VisibilityTable from './visibility_table';
import Badges from './badges';
import LMSAssignments from './lms_assignments';
import ProgramsTable from '../ProgramsPage/table';

import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormDatePicker from '../../components/FormDatePicker';
import FormEditor from '../../components/FormEditor';
import FormUpload from '../../components/FormUpload';
import ToolTipInfo from '../../components/ToolTipInfo';

import { FormBox } from '../../styles/form';
import { InfoBox } from '../../styles/message';
import { AInfoLink, InfoButton } from '../../styles/button';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';
import { uploadMediaToStorage } from '../../helpers/firebase';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';

import { courseSchema } from '../../schemas/course';

import { toggleLoader } from '../../actions/global';

import { YES_NO_OPTIONS, CANVAS_LMS_URL } from '../../constants';

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

const filePickerCallBack = (authData,course,field,dispatch) => ( callback, value, meta ) => {
    const input = document.getElementById('editor-mce-file-upload-field-' + field.name + '-' + ( course && course.id && !_isEmpty( course.id ) ? course.id : '' ) );

    input.click();
    input.onchange = function () {
        const file = input.files[0];
        const uploadPath = 'courses/' + ( course && course.id && !_isEmpty( course.id ) ? course.id : 'misc' ) + '/content';
        Alert.success('<span class="app-alert text-center content-block"><i class="fa fa-circle-o-notch fa-spin"></i>Uploading image - Please do not click on anything, browser back button, refresh or close this page!</span>', { position: 'top', effect: 'flip', beep: false, timeout: 'none', offset: 70 });
        dispatch(toggleLoader(true));
        uploadMediaToStorage(file, uploadPath, authData)
        .then(function(url) {
            Alert.closeAll();
            dispatch(toggleLoader(false));
            triggerSuccessAlert('Upload Done!');
            callback(url);
        })
        .catch(function(error) {
            Alert.closeAll();
            dispatch(toggleLoader(false));
            triggerErrorAlert(error.data.error);
        });

    };
}

class CourseEditForm extends React.Component {

    state = {
        randNum: false
    };

    isProduct = () => {
        const { course } = this.props;
        return ( course && course.is_product && course.is_product === 'yes' ? true : false );
    }

    handleFormUpdate = ( newValue, key ) => {
        const { onFormUpdate, course, currentCourse, course_type, course_duration, currency, contact_hour, product_level, product_credit } = this.props;
        let newData = ( course && !_isEmpty( course ) ? cloneCollections( course ) : {} );

        switch( key ) {
            case 'contact_hour':
                let selected_contact_hour = ( contact_hour && isArrayExists( contact_hour ) ? _find( contact_hour, { id: newValue } ) : false );
                newData[key] = newValue;
                newData[key+"_label"] = ( selected_contact_hour && selected_contact_hour.label ? selected_contact_hour.label : '' );
                newData[key+"_value"] = ( selected_contact_hour && ( selected_contact_hour.value || 0 === selected_contact_hour.value ) ? selected_contact_hour.value : 0 );
                break;
            case 'product_level':
                let selected_product_level = ( product_level && isArrayExists( product_level ) ? _find( product_level, { id: newValue } ) : false );
                newData[key] = newValue;
                newData[key+"_label"] = ( selected_product_level && selected_product_level.label ? selected_product_level.label : '' );
                newData[key+"_value"] = ( selected_product_level && ( selected_product_level.value || 0 === selected_product_level.value ) ? selected_product_level.value : 0 );
                break;
            case 'product_credit':
                let selected_product_credit = ( product_credit && isArrayExists( product_credit ) ? _find( product_credit, { id: newValue } ) : false );
                newData[key] = newValue;
                newData[key+"_label"] = ( selected_product_credit && selected_product_credit.label ? selected_product_credit.label : '' );
                newData[key+"_value"] = ( selected_product_credit && ( selected_product_credit.value || 0 === selected_product_credit.value ) ? selected_product_credit.value : 0 );
                break;
            case 'course_type':
                let selected_course_type = ( course_type && isArrayExists( course_type ) ? _find( course_type, { id: newValue } ) : false );
                newData[key] = newValue;
                newData[key+"_label"] = ( selected_course_type && selected_course_type.label ? selected_course_type.label : '' );
                break;
            case 'course_duration':
                let selected_course_duration = ( course_duration && isArrayExists( course_duration ) ? _find( course_duration, { id: newValue } ) : false );
                newData[key] = newValue;
                newData[key+"_label"] = ( selected_course_duration && selected_course_duration.label ? selected_course_duration.label : '' );
                break;
            case 'currency':
                let selected_currency = ( currency && isArrayExists( currency ) ? _find( currency, { id: newValue } ) : false );
                newData[key] = newValue;
                newData[key+"_label"] = ( selected_currency && selected_currency.value ? selected_currency.value : '' );
                break;
            case 'lms_course_id':
                newData[key] = newValue;
                // reset assignment id
                newData['lms_assignment_showcase_id'] = '';
                newData['lms_assignment_feedback_form_id'] = '';
                newData['lms_assignment_project_id'] = '';
                break;
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    handleFileUpload = (files,key) => {
        const { authData, dispatch, course } = this.props;
        if ( files && files[0] && !_isEmpty( files[0] ) ) {
            dispatch(toggleLoader(true));
            const uploadPath = 'courses/' + ( course && course.id && !_isEmpty( course.id ) ? course.id : 'misc' ) + ( key && key === 'web_course_image' ? '/image' : '/content' );
            uploadMediaToStorage( files[0], uploadPath, authData )
            .then( url => {
                this.handleFormUpdate(url,key);
                dispatch(toggleLoader(false));
            })
            .catch(error => {
                dispatch(toggleLoader(false));
                triggerErrorAlert(( error && error.message || 'Upload Error. Please try again' ));
            });
        } else {
            triggerErrorAlert('Please upload a valid image file');
        }
    }

    getFieldOptions = (field) => {
        const { course, contact_hour, product_level, product_credit, course_type, course_duration, feedback_forms, currency, rubrics, lms_courses } = this.props;

        switch( field.name ) {
            case 'contact_hour':
                return getSelectOptions({ list: ( contact_hour && isArrayExists(contact_hour) ? _sortBy( contact_hour, ['value'] ) : false ), options: [], keys: { value: 'id', label: 'label', disabled: 'status' } });
            case 'product_level':
                return getSelectOptions({ list: ( product_level && isArrayExists(product_level) ? _sortBy( product_level, ['value'] ) : false ), options: [], keys: { value: 'id', label: 'label', disabled: 'status' } });
            case 'product_credit':
                return getSelectOptions({ list: ( product_credit && isArrayExists(product_credit) ? _sortBy( product_credit, ['value'] ) : false ), options: [], keys: { value: 'id', label: 'label', disabled: 'status' } });
            case 'course_type':
                return getSelectOptions({ list: ( course_type || false ), options: [], keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' });
            case 'course_duration':
                return getSelectOptions({ list: ( course_duration || false ), options: [], keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' });
            case 'feedback_form_id':
                return getSelectOptions({ list: ( feedback_forms || false ), options: [], keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' });
            case 'currency':
                return getSelectOptions({ list: ( currency || false ), options: [], keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' });
            case 'course_rubric':
                return getSelectOptions({ list: ( rubrics || false ), options: [], keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' });
            case 'publish':
                return YES_NO_OPTIONS;
            case 'lms_course_id':
                let course_options = [];
                if ( lms_courses && isArrayExists( lms_courses ) ) {
                    _forEach( _sortBy( lms_courses, c => c.name.toLowerCase() ), item => {
                        course_options.push({
                            value: item.id.toString(),
                            label: ( item.name || '' ) + ' [LMS Course ID: '+item.id.toString()+']'
                        })
                    });
                } // end - lms_courses
                return course_options;
            default:
                return [];
        }
    }

    getFieldValue = (field) => {
        const { course } = this.props;
        switch( field.name ) {
            default:
                return ( course && course[field.name] ? course[field.name] : ( field.default || '' ) );
        }
    }

    isFieldDisabled = (schema) => {
        const { authData } = this.props;
        var disabled = false;

        if ( schema && schema.disabled && isArrayExists( schema.disabled ) ) {
            schema.disabled.forEach( condition => {
                switch( condition ) {
                    case 'admin':
                        if ( !isAdmin( authData ) )
                            disabled = true;
                        break;
                    case 'update':
                        disabled = true;
                        break;
                }
            });
        } // end - schema

        return disabled
    }

    getFieldHeight = (field) => {
        switch( field.name ) {
            case 'price_per_pax':
                return 300;
            default:
                return 500;
        }
    }

    getTooltip = (schema) => {
        switch( schema.id ) {
            case 'course_code':
                return "This shall be the same as Course Code in LMS.";
            case 'product_level':
                return (
                <>
                    BASED ON PRODUCT CODE<br />
                    Key in based on Product Code. Refer to Product Code structure on <a href="https://www.notion.so/chumbaka/Product-Development-375571603e6e4f19a93393bead9db258#beb086ce91534a21a30bdf27ac1c42a4" target="_blank">Notion</a>.
                </>
                );
            case 'contact_hour':
                return (
                <>
                    BASED ON PRODUCT CODE<br />
                    Key in based on Product Code. Refer to Product Code structure on <a href="https://www.notion.so/chumbaka/Product-Development-375571603e6e4f19a93393bead9db258#beb086ce91534a21a30bdf27ac1c42a4" target="_blank">Notion</a>.
                </>
                );
            // case 'lms_course_link':
            //     return <a href="https://community.canvaslms.com/t5/Instructor-Guide/How-do-I-enable-course-self-enrollment-with-a-join-code-or/ta-p/830" target="_blank">Refer to this method</a>;
            default:
                return;
        }
    }

    getField = (id) => {
        const { massEditMode } = this.props;
        let schema = _find( courseSchema, { id } );
        return ( schema ? {
            name: ( schema.id || '' ),
            label: ( schema.label || '' ) + ( isSchemaRequired(schema,'update') && !massEditMode ? ' (Required)' : '' ),
            field_type: ( schema.field || '' ),
            default: ( massEditMode ? null : ( schema.default || null ) ),
            disabled: ( schema.id === 'publish' ? true : ( schema.disabled ? this.isFieldDisabled( schema ) : false ) ),
            tooltip: this.getTooltip(schema)
        } : null );
    }

    renderField = (id) => {
        const { authData, course, dispatch } = this.props;
        let field = this.getField(id);
        if ( field && field.field_type && !_isEmpty( field.field_type ) ) {
            switch( field.field_type ) {
                case 'text':
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'textarea':
                    return <FormInput {...field} rows={( 'notes' === id ? 6 : 3 )} multiline={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'text_number':
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'email':
                    return <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'select':
                    return <FormSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} disableNative={true} onChange={this.handleFormUpdate} />;
                case 'multiselect':
                    return <FormMultiSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} />;
                case 'datepicker':
                    return <FormDatePicker {...field} noDefaultVal={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'tinymce_editor':
                    return <FormEditor id={id + '-' + ( course && course.id && !_isEmpty( course.id ) ? course.id : '' )} {...field} value={this.getFieldValue(field)} height={this.getFieldHeight(field)} onChange={this.handleFormUpdate} filePickerTypes= 'image' filePickerCallback={filePickerCallBack(authData,course,field,dispatch)} />;
                case 'upload_image':
                    return <FormUpload {...field} value={this.getFieldValue(field)} onReset={this.handleFormUpdate} onUpload={this.handleFileUpload} />
            }
        } // end - field.field_type
    }

    renderCreatedModifiedDate = (item) => {
        let created_on = ( item.created_on && item.created_on._seconds ? item.created_on._seconds*1000 : ( item.created_on || null ) ),
            modified_on = ( item.modified_on && item.modified_on._seconds ? item.modified_on._seconds*1000 : ( item.modified_on || null ) );
        return (
        <div style={{ paddingTop: "15px", textAlign: 'right', color: '#999', fontSize: '1.25rem' }}>
            <div>{ created_on ? 'Created on ' + getMomentTime( created_on , 'YYYY-MM-DD hh:mm:ssa' ) : ''}</div>
            <div>{ modified_on ? 'Last Modified on ' + getMomentTime( modified_on , 'YYYY-MM-DD hh:mm:ssa' ) : ''}</div>
        </div>
        )
    }

    render = () => {
        const { formType, classes, course, partners, disableSystemDate, massEditMode, visibility_lock, onVisibilityUnlock, rubrics, feedback_forms, authData, price_per_pax, programs, course_type, course_duration } = this.props;
        return (
        <>
            <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff" }}>
                <FormBox>
                    <Typography variant="h4" className={classes.boxheading}>Basic Info</Typography>
                    <Grid container spacing={3}>

                        <Grid item xs={12}>{this.renderField('name')}</Grid>
                        <Grid item xs={6}>{this.renderField('course_code')}</Grid>
                        <Grid item xs={6}>{this.renderField('product_credit')}</Grid>
                        <Grid item xs={6}>{this.renderField('contact_hour')}</Grid>
                        <Grid item xs={6}> </Grid>
                        <Grid item xs={6}>{this.renderField('product_level')}</Grid>
                        <Grid item xs={6}> </Grid>
                        { formType && formType === 'update' && !this.isProduct() ? (
                        <>
                            <Grid item xs={6}>{this.renderField('course_type')}</Grid>
                            <Grid item xs={6}>{this.renderField('currency')}</Grid>
                            <Grid item xs={12}>{this.renderField('price_per_pax')}</Grid>
                        </>
                        ) : null }
                    </Grid>
                </FormBox>
            </Paper>
            <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff", marginTop: "60px" }}>
                <FormBox>
                    <Typography variant="h4" className={classes.boxheading}>Program(s) covering this Product</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ProgramsTable
                                productMode={true}
                                programs={programs}
                                course_type={course_type}
                                course_duration={course_duration}
                                price_per_pax={price_per_pax}
                                products_options={[]}
                                authData={authData}
                                />
                        </Grid>
                    </Grid>
                </FormBox>  
            </Paper>
            <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff", marginTop: "60px" }}>
                <FormBox>
                    <Typography variant="h4" className={classes.boxheading}>Syllabus Details</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>{this.renderField('web_course_image')}</Grid>
                        <Grid item xs={6}>{this.renderField('publish')}</Grid>
                        <Grid item xs={6}> </Grid>
                        <Grid item xs={12}>{this.renderField('web_synopsis')}</Grid>
                        <Grid item xs={12}>{this.renderField('web_what_you_learn')}</Grid>
                        <Grid item xs={12}>{this.renderField('lesson_plan')}</Grid>
                        { formType && formType === 'update' && !this.isProduct() ? (
                        <>
                            <Grid item xs={6}>{this.renderField('course_duration')}</Grid>
                            <Grid item xs={6}> </Grid>
                            <Grid item xs={12}>{this.renderField('web_prerequisite')}</Grid>
                        </>
                        ) : null }
                    </Grid>
                </FormBox>    
            </Paper>
            <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff", marginTop: "60px" }}>
                <FormBox>
                    <Typography variant="h4" className={classes.boxheading}>LMS Content Setup</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            {this.renderField('lms_course_id')}
                        </Grid>
                        <Grid item xs={6}>
                            { course && course.lms_course_id && !_isEmpty( course.lms_course_id ) ? <Tooltip 
                                title={<span style={{ fontSize: '13px', padding: '4px' }}>View Course in LMS</span>} 
                                arrow placement="top"><AInfoLink href={( CANVAS_LMS_URL + 'courses/' + course.lms_course_id )} target="_blank" noIconMargin="yes" style={{ padding: '10px 15px' }} minWidth="0px"><i className="fa fa-globe"></i></AInfoLink></Tooltip> : null }
                        </Grid>
                        { course && course.lms_course_id && !_isEmpty( course.lms_course_id ) ? <Grid item xs={12}><LMSAssignments 
                            course={course}
                            rubrics={rubrics}
                            feedback_forms={feedback_forms} 
                            onUpdate={this.handleFormUpdate} /></Grid> : null }
                    </Grid>
                </FormBox> 
            </Paper>
            { formType && formType === 'update' ? (
            <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff", marginTop: "60px" }}>
                <FormBox>
                    <Typography variant="h4" className={classes.boxheading}>Badge Setup</Typography>
                    <Badges 
                        course={( course || false )}
                        course_id={( course && course.id ? course.id : false )} />
                </FormBox>
            </Paper> 
            ) : null }
            { formType && formType === 'update' && !this.isProduct() ? (
            <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff", marginTop: "60px" }}>
                <FormBox>
                    <Typography variant="h4" className={classes.boxheading}>(Legacy) Course Visibility<span style={{ paddingLeft: '8px' }}><ToolTipInfo content="When ticked, this course will be visible to Partner. This allows Partner to enable students to access the course." /></span></Typography>
                    <VisibilityTable 
                        value={( course && course.course_visibility && isArrayExists( course.course_visibility ) ? course.course_visibility : [] )}
                        formDisabled={( visibility_lock || false )}
                        partners={( partners || [] )}
                        onUpdate={this.handleFormUpdate}
                        onUnlock={(newValue) => { this.props.onVisibilityUnlock(newValue) }} />
                </FormBox>
            </Paper>
            ) : null }
            { disableSystemDate ? null : this.renderCreatedModifiedDate(course)}
        </>
        );
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(CourseEditForm);