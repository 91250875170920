/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _isEqual from 'lodash/isEqual';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';
import _split from 'lodash/split';
import _toUpper from 'lodash/toUpper';

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader'
import SnackBarSave from '../../components/SnackBarSave';

import EditForm from './form';
import ItemNotFound from '../../components/ItemNotFound';

import { InfoButton, GreyButton } from '../../styles/button';

import { hasAccessRights } from '../../helpers/auth'; 
import { isArrayExists, isObjectExists, isNumeric, validURL } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { getDateNumFromString, isParentFieldRequired } from '../../helpers/date';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { getStudentTeamProject, updateStudentTeamProject } from '../../actions/student_team_project';
import { appChangesMade, resetRedux } from '../../actions/misc';


class StudentTeamProject extends React.Component {

    state = {
        currentTab: 'details',
        showcase: false,
        randNum: false
    };

    componentDidMount() {
        const { sis_section_id, project_id } = this.props.match.params;
        const { authData } = this.props;
        
        // retrieve feedback details
        this.props.dispatch(getStudentTeamProject( this.getScheduleID(sis_section_id), this.getLMSCourseID(sis_section_id), project_id ));

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentProject, randNum
            } = this.props;

        // for feedback form
        if ( currentProject && randNum && randNum !== this.state.randNum ) {
            this.setState({ showcase: cloneCollections( currentProject ), randNum });
        } // end - currentProject
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('student_showcase'));
    }

    handleSaveChanges = (event) => {
        const { sis_section_id, project_id } = this.props.match.params;
        const { showcase } = this.state;
        event.preventDefault();
        var error = false,
            formData = {
                schedule_id: this.getScheduleID(sis_section_id),
                lms_course_id: this.getLMSCourseID(sis_section_id),
                project_id: ( project_id && isNumeric( project_id ) ? project_id : null ),
                project_title: ( showcase && showcase.trainee && showcase.trainee.project_title ? showcase.trainee.project_title : '' ),
                project_desc: ( showcase && showcase.trainee && showcase.trainee.project_desc ? showcase.trainee.project_desc : '' )
            };

        // do error check
        if ( formData.project_desc && !_isEmpty( formData.project_desc ) && !validURL( formData.project_desc ) )
            error = 'Project link inserted is not a valid URL';

        if ( !( formData.project_title && !_isEmpty( formData.project_title ) && formData.project_desc && !_isEmpty( formData.project_desc ) ) )
            error = 'Please fill out all the required fields';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateStudentTeamProject(formData));
        } // end - error

    }

    handleFormUpdate = (newTrainee) => {
        const { showcase } = this.state;
        let newData = ( showcase && !_isEmpty( showcase ) ? cloneCollections( showcase ) : { trainee: {} } );
        newData.trainee.project_title = ( newTrainee && newTrainee.project_title ? newTrainee.project_title : '' );
        newData.trainee.project_desc = ( newTrainee && newTrainee.project_desc ? newTrainee.project_desc : '' );
        this.setState({ showcase: newData });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    isMentorView = () => {
        const { location = {}, currentProject } = this.props;
        return ( 
            ( location && location.pathname && location.pathname.indexOf("/mentor/class/") >= 0 ) || 
            ( currentProject && currentProject.id && currentProject.id === 'is_teacher_mentor' ) ? true : false );
    }

    splitSISSectionID = (sis_section_id) => {
        return ( sis_section_id && !_isEmpty( sis_section_id ) ? _split( sis_section_id, '-c', 2 ) : false );
    }

    getScheduleID = (sis_section_id) => {
        let data = this.splitSISSectionID(sis_section_id);
        return ( data && data[0] && !_isEmpty( data[0] ) ? _toUpper( data[0] ) : false );
    }

    getLMSCourseID = (sis_section_id) => {
        let data = this.splitSISSectionID(sis_section_id);
        return ( data && data[1] && !_isEmpty( data[1] ) && isNumeric( data[1] ) ? data[1] : false );
    }

    renderTabContents = () => {
        const { sis_section_id, project_id } = this.props.match.params;
        const { authData, currentProject, changesMade } = this.props;
        const { currentTab, showcase } = this.state;
        const tabProps = {
            authData: ( authData || false ),
            showcase: ( showcase || false ),
            changesMade: ( changesMade || false ),
            onFormUpdate: this.handleFormUpdate
        };
        switch( currentTab ) {
            case 'details':
                return <EditForm 
                    sis_section_id={( sis_section_id || false )}
                    schedule_id={this.getScheduleID(sis_section_id)}
                    lms_course_id={this.getLMSCourseID(sis_section_id)}
                    project_id={project_id}
                    currentProject={( currentProject || false )}
                    {...tabProps} />;
        }
    }

    renderContents() {
        const { showcase } = this.state;
        return (
        <div>

            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                {this.renderTabContents()}
            </Paper>

            { 
            ( showcase && showcase.trainee && showcase.trainee.showcase && showcase.trainee.showcase.issued_badge_id && !_isEmpty( showcase.trainee.showcase.issued_badge_id ) ) || 
            this.isMentorView() ? ( this.isMentorView() ? (
            <>
                <div style={{ textAlign: "center", marginTop: "45px" }}>
                    <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" disabled="yes" onClick={() => { /* do nothing */ }}><i className="fa fa-save"></i>Submit Form</InfoButton>
                </div>
            </>
            ) : null ) : (
            <>
                <div style={{ textAlign: "center", marginTop: "45px" }}>
                    <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Submit</InfoButton>
                </div>
                <SnackBarSave saveNowLabel="Submit" onSave={this.handleSaveChanges} />
            </>
            )}

        </div>
        );
    }

    render() {
        const { project_id } = this.props.match.params;
        const { randNum } = this.state;
        const { currentProject } = this.props;
        return <AppWrapper 
                title={"Submit Team Project"+( isNumeric( project_id ) ? ' #'+parseInt( project_id ) : '' )}
                maxWidth="1110px"
                hideTopBar={true}
                topBarHeight="0px"
                onLoad={( !( randNum ) ? true : false )}
                contents={ ( currentProject && currentProject.trainee && !_isEmpty( currentProject.trainee ) ) || this.isMentorView() ? this.renderContents() : <ItemNotFound item="Class" customMsg="Please create or join your team before you submit a project." />} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        currentProject: state.student_showcase && state.student_showcase.team_project || null,
        randNum: state.student_showcase && state.student_showcase.rand || null,
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(StudentTeamProject);