/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_COURSES,
	GET_COURSES_OPTIONS,
	GET_PRODUCTS_OPTIONS,
	GET_COURSE,
	ADD_COURSE,
	EDIT_COURSE,
	DELETE_COURSE,
	GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getCourses, fb_getCoursesOptions, fb_getCourse, fb_addCourse, fb_updateCourse, fb_deleteCourse, fb_getProductsOptions } from './fb';


export const getCourses = (props) => {
	return dispatch => {

		fb_getCourses(props)
		.then(list => {
			dispatch({
				type: GET_COURSES,
				payload: { list }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_COURSES,
				payload: { list: [] }
			});
		})

	}
}

export const getCoursesOptions = (props) => {
	return dispatch => {
		fb_getCoursesOptions(list => {
			dispatch({
				type: GET_COURSES_OPTIONS,
				payload: { list }
			});	
		},props);
	}
}

export const getProductsOptions = (props) => {
	return dispatch => {
		fb_getProductsOptions(list => {
			dispatch({
				type: GET_PRODUCTS_OPTIONS,
				payload: { list }
			});	
		},props);
	}
}

export const getCourse = (id) => {
	return dispatch => {

		fb_getCourse(id)
		.then(course => {
			dispatch({
				type: GET_COURSE,
				payload: { course }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_COURSE,
				payload: { course: {} }
			});
		})

	}
}

export const addCourse = (formData,history) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_addCourse(formData)
		.then( results => {
			if ( results && results.new_course_id && !_isEmpty( results.new_course_id ) ) {
				// updated
				dispatch({ type: ADD_COURSE });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Product Added");
				// redirect to course page
				history.push("/products/" + results.new_course_id);
			} else {
				dispatch(toggleLoader(false));
				triggerErrorAlert('Missing new product ID');
			}
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const editCourse = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateCourse(formData)
		.then( results => {
			fb_getCourse(formData.id)
			.then(course => {
				// push new data
				dispatch({ type: GET_COURSE, payload: { course } });	
				// updated
				dispatch({ type: EDIT_COURSE });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Product Updated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const deleteCourse = (id,region) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteCourse(id,region)
		.then( results => {
			fb_getCourses()
			.then(list => {
				dispatch({ type: GET_COURSES, payload: { list } });	
				dispatch({ type: DELETE_COURSE });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("Product Deleted!");
			})
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}