export const userStudentSchema = [
    {
        id: "uid",
        label: "ID",
        required: ["update", "delete"],
        default: "",
        type: "string",
        validation: "string_id",
        skip: ["add", "update"],
    },
    {
        id: "status",
        label: "Status",
        required: false,
        default: "pending",
        type: "string",
        field: "select",
        skip: ["add", "self"],
        sync: true,
    },
    {
        id: "region",
        label: "Partner",
        required: ["add", "update"],
        default: "",
        type: "string",
        field: "select",
        skip: ["add", "update"],
        disabled: ["admin"],
        sync: true,
    },
    {
        id: "belongs_to",
        label: "Belongs To",
        required: false,
        default: [],
        type: "array",
        field: "special",
    },
    {
        id: "name",
        label: "Name",
        required: false,
        default: "",
        type: "string",
        field: "text",
        skip: ["add", "update"],
        sync: true,
    },
    {
        id: "email",
        label: "Email",
        required: ["add", "update"],
        default: "",
        type: "email",
        field: "email",
        skip: ["add", "update"],
        disabled: ["update"],
        sync: true,
    },
    {
        id: "role",
        label: "SMS Role",
        required: ["add", "update"],
        default: "student",
        type: "string",
        field: "select",
        skip: ["add", "update"],
        sync: true,
    },

    {
        id: "date_of_birth",
        label: "Date of Birth (timestamp)",
        required: false,
        default: 0,
        type: "timestamp",
        skip: false,
        sync: true,
    },
    {
        id: "date_of_birth_string",
        label: "Date of Birth",
        required: ["update"],
        default: "",
        dateFormat: "YYYY-MMM-DD",
        type: "string",
        field: "select_date",
        sync: true,
    },
    {
        id: "gender",
        label: "Gender",
        required: ["update"],
        default: "",
        type: "string",
        field: "select",
        sync: true,
    },
    {
        id: "phone",
        label: "Mobile No.",
        required: false,
        default: "",
        type: "string",
        field: "phone",
        sync: true,
    },
    {
        id: "org",
        label: "School",
        required: false,
        default: "",
        type: "string",
        field: "text",
        sync: true,
    },
    {
        id: "ic_number",
        label: "IC Number",
        required: false,
        default: "",
        type: "string",
        field: "text",
        sync: true,
    },
    {
        id: "city",
        label: "City",
        required: false,
        default: "",
        type: "string",
        field: "text",
        sync: true,
    },
    {
        id: "eportfolio",
        label: "ePortfolio link",
        required: false,
        default: "",
        type: "string",
        field: "text",
        sync: true,
    },
    {
        id: "about_me",
        label: "Description About Me",
        required: false,
        default: "",
        type: "string",
        field: "textarea",
        sync: true,
    },
    {
        id: "observer_id",
        label: "Parent Observer ID",
        required: false,
        default: "",
        type: "string",
        sync: true,
    },
    {
        id: "observer_email",
        label: "Parent Observer Email",
        required: false,
        default: "",
        type: "string",
        sync: true,
    },

    {
        id: "parent_name",
        label: "Parent Name",
        required: false,
        default: "",
        type: "string",
        field: "text",
        sync: true,
    },
    {
        id: "parent_email",
        label: "Email",
        required: false,
        default: "",
        type: "email",
        field: "email",
        sync: true,
    },
    {
        id: "parent_phone",
        label: "Mobile No.",
        required: false,
        default: "",
        type: "string",
        field: "phone",
        sync: true,
    },
    {
        id: "parent_city",
        label: "City",
        required: false,
        default: "",
        type: "string",
        field: "text",
        sync: true,
    },
    {
        id: "teacher_name",
        label: "Teacher Name",
        required: false,
        default: "",
        type: "string",
        field: "text",
        sync: true,
    },
    {
        id: "teacher_email",
        label: "Email",
        required: false,
        default: "",
        type: "email",
        field: "email",
        sync: true,
    },
    {
        id: "teacher_phone",
        label: "Phone Number",
        required: false,
        default: "",
        type: "string",
        field: "phone",
        sync: true,
    },
    {
        id: "teacher_org",
        label: "School",
        required: false,
        default: "",
        type: "string",
        field: "text",
        sync: true,
    },
    {
        id: "trainees",
        label: "Trainees Linked Data",
        required: false,
        default: [], // { id: trainee_id, enrollment_status, schedule_id }
        type: "array_ids",
        field: "special",
        skip: ["update"],
    },
    {
        id: "classes",
        label: "Classes",
        required: false,
        default: [], // { id: schedule_id, course_id, trainee_id }
        type: "array_ids",
        field: "special",
        skip: ["update"],
    },
    {
        id: "badgr_entity_type",
        label: "Badgr Entity Type",
        required: false,
        default: "",
        type: "string",
        sync: true,
    },
    {
        id: "badgr_entity_id",
        label: "Badgr Entity ID",
        required: false,
        default: "",
        type: "string",
        sync: true,
    },
    {
        id: "badgr_emails",
        label: "Badgr Emails",
        required: false,
        default: [],
        type: "array_obj",
        sync: true,
    },
    {
        id: "access_gdrive",
        label: "Access to Google Drive",
        required: false,
        default: "no",
        type: "string",
        field: "select",
        sync: true,
    },
    {
        id: "access_notion",
        label: "Access to Notion",
        required: false,
        default: "no",
        type: "string",
        field: "select",
        sync: true,
    },
    {
        id: "custom_fields",
        label: "Custom Fields",
        required: false,
        default: [],
        type: "array_ids",
        field: "special",
    },
    {
        id: "custom_fields_form_id",
        label: "Custom Fields Form ID",
        required: false,
        default: "",
        type: "system_data",
    },

    {
        id: "created_on",
        label: "Created On",
        required: false,
        default: 0,
        type: "system_date",
        skip: ["update"],
        sync: true,
    },
    {
        id: "modified_on",
        label: "Modified On",
        required: false,
        default: 0,
        type: "system_date",
        sync: true,
    },
    {
        id: "last_modified_by",
        label: "Modified By",
        required: false,
        default: "",
        type: "system_modified_by",
    },
];
