/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_MENTORS,
	GET_MENTOR,
	ADD_MENTOR,
	UPDATE_MENTOR,
	DELETE_MENTOR
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getMentors, fb_getMentor, fb_addMentor, fb_updateMentor, fb_removeMentor, fb_resendMentorEmail, fb_triggerGrantMentorAccess, fb_triggerRevokeMentorAccess, doAsyncAPICallAddmentor } from './fb';
import { fb_triggerStudentRegionTransfer, fb_triggerStudentAccountTransfer } from '../database_students/fb';

export const getMentors = (props) => {
	return dispatch => {
		fb_getMentors(list => {
			dispatch({
				type: GET_MENTORS,
				payload: { list }
			});	
		},props);
	}
}

export const getMentorProfile = (uid) => {
	return dispatch => {

		fb_getMentor(uid)
		.then(mentor => {
			dispatch({
				type: GET_MENTOR,
				payload: { mentor }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_MENTOR,
				payload: { mentor: {} }
			});
		})

	}
}

export const addNewMentor = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addMentor(formData)
		.then( results => {
			dispatch({ type: ADD_MENTOR });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Mentor Added");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const updateMentor = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateMentor(formData)
		.then( results => {
			fb_getMentor(formData.uid)
			.then(mentor => {
				// push new data
				dispatch({ type: GET_MENTOR, payload: { mentor } });	
				// updated
				dispatch({ type: UPDATE_MENTOR });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Mentor Data Updated");
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const resendMentorEmail = (uid) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_resendMentorEmail(uid)
		.then( results => {
			dispatch({ type: ADD_MENTOR });
			dispatch(toggleLoader(false));
			triggerSuccessAlert("Email Sent! It might takes up to 5 minutes to reach mentors inbox.",4000);
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const removeMentor = (uid) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_removeMentor(uid)
		.then( results => {
			dispatch({ type: DELETE_MENTOR });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Mentor Removed");
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			triggerErrorAlert(getAPIErrorMessage(error),5000);
		});

	}
}

export const triggerMentorRegionTransfer = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true,"Transfering process is on-going! Please do not close this window or click the Back button on your browser"));

		fb_triggerStudentRegionTransfer(formData)
		.then( results => {
			fb_getMentor(formData.uid)
			.then(mentor => {
				// push new data
				dispatch({ type: GET_MENTOR, payload: { mentor } });	
				// updated
				dispatch({ type: UPDATE_MENTOR });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Partner Transfer Completed");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error),5000);
		});

	}
}

export const triggerMentorAccountTransfer = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true,"Transfering process is on-going! Please do not close this window or click the Back button on your browser"));

		fb_triggerStudentAccountTransfer(formData)
		.then( results => {
			fb_getMentor(formData.uid)
			.then(mentor => {
				// push new data
				dispatch({ type: GET_MENTOR, payload: { mentor } });	
				// updated
				dispatch({ type: UPDATE_MENTOR });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Email Change Completed! Please ask the student to logout of their old account, and re-login with their new email as well as the new password",10000);

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error),5000);
		});

	}
}

export const triggerGrantMentorAccess = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_triggerGrantMentorAccess(formData)
		.then( results => {
			fb_getMentor(formData.uid)
			.then(mentor => {
				// push new data
				dispatch({ type: GET_MENTOR, payload: { mentor } });	
				// updated
				dispatch({ type: UPDATE_MENTOR });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Access Granted");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error),5000);
		});

	}
}

export const triggerRevokeMentorAccess = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_triggerRevokeMentorAccess(formData)
		.then( results => {
			fb_getMentor(formData.uid)
			.then(mentor => {
				// push new data
				dispatch({ type: GET_MENTOR, payload: { mentor } });	
				// updated
				dispatch({ type: UPDATE_MENTOR });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Access Revoked");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error),5000);
		});

	}
}

export const bulkUploadMentors = (users = []) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));
		dispatch(toggleLoader(true,"Importing process is on-going! Please do not close this window or click the Back button on your browser"));

		doAsyncAPICallAddmentor({ users })
		.then(results => {
			dispatch({ type: ADD_MENTOR });
			dispatch(toggleModalProcessing(false));
			dispatch(toggleLoader(false));
			triggerSuccessAlert("Import Process Done");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}