/* eslint-disable */
import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import red from '@material-ui/core/colors/red';
import styled from "styled-components";
import _isEmpty from 'lodash/isEmpty';

const MessageWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const MessageBox = styled.div`
    display: block;
    max-width: 600px;
    margin: 0 auto;
    font-size: 1.75rem;
    font-weight: 700;
    text-align: center;
    padding: 20px;
    background: #fff;
    border: 4px solid ${red['500']};
`;

class OpacityLoader extends React.Component {

    renderMessage = () => {
        const { message } = this.props;
        return (
        <MessageWrapper>
            <MessageBox>{( message || '' )}</MessageBox>
        </MessageWrapper>
        )
    }

    render() {
        const { open, message } = this.props;
        return (
        <Dialog fullScreen open={open} style={{ zIndex: '999999' }} BackdropProps={{
            style: {
                background: 'rgba(255,255,255,0.7)'
            }
        }}>
            <LinearProgress thickness={6} color="primary" style={{ zIndex: '9999999' }} />
            { message && !_isEmpty( message ) ? this.renderMessage( message ) : null }
        </Dialog>
        )
    }

}

export default OpacityLoader;