/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';

import {
	IS_LOGGED_IN,
	AUTH_LOGIN,
	AUTH_LOGOUT,
	AUTH_GET_PROFILE,
	AUTH_EDIT_PROFILE,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { isArrayExists, validateEmail, isObjectExists } from '../../helpers/validation';
import { getAPIErrorMessage } from '../../helpers/action';
import { toggleLoader } from '../global';
import { appChangesReset } from '../misc';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { googleSignIn, passwordlessSignIn, logoutUser, onAuth, customTokenSignIn, fb_updateUserProfile, fb_getUserProfile } from './fb';

export const isUserLoggedIn = () => {
	return dispatch => {

        firebase.auth().onAuthStateChanged(function(userToken) {
            if (userToken) {
				onAuth(userToken)
				.then(user => {
					dispatch({
						type: IS_LOGGED_IN,
						payload: user
					});
				})
				.catch(error => {
					dispatch({ type: AUTH_LOGOUT });
					triggerErrorAlert(getAPIErrorMessage(error));
				});
            } else {
                dispatch({ type: AUTH_LOGOUT });
            }
        }); 

	}
}

export const signIn = () => {
	return dispatch => {
		dispatch(toggleLoader(true));

		googleSignIn()
		.then( user => {
			setTimeout(() => {
				dispatch({
					type: AUTH_LOGIN,
					payload: user
				});
				dispatch(toggleLoader(false));
			},2500)
		})
		.catch(error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));;
		});

	}
}

export const signInWithEmail = (history) => {
	return dispatch => {

		passwordlessSignIn()
		.then( user => {
			setTimeout(() => {
				dispatch({
					type: AUTH_LOGIN,
					payload: user
				});
			},2500)
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			history.push('/login'); // push back to login page if there is an error
		});

	}
}

export const signInWithCustomToken = (token, history, redirect) => {
	return dispatch => {

		// logout current user first
		logoutUser(true)
		.then(() => {
			customTokenSignIn(token)
			.then( user => {
				setTimeout(() => {
					dispatch({
						type: AUTH_LOGIN,
						payload: user
					});
					// redirect user to page
					if ( redirect && !_isEmpty( redirect ) ) {
						history.push(redirect);
					} else {
						// otherwise, redirect to dashboard
						history.push("/dashboard");
					} // end - redirect
				},1000)
			})
			.catch(error => {
				triggerErrorAlert(getAPIErrorMessage(error));
				history.push('/login'); // push back to login page if there is an error
			});
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			history.push('/login'); // push back to login page if there is an error
		});

	}
}

export const logout = () => {
	return dispatch => {
		dispatch(toggleLoader(true));

		logoutUser()
		.then(() => {
			dispatch({ type: AUTH_LOGOUT });
			dispatch(toggleLoader(false));
		});

	}
}

export const getUserProfile = (uid) => {
	return dispatch => {

		fb_getUserProfile(uid)
		.then(profile => {
			dispatch({
				type: AUTH_GET_PROFILE,
				payload: { profile }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: AUTH_GET_PROFILE,
				payload: { profile: {} }
			});
		})

	}
}


export const updateUserProfile = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateUserProfile(formData)
		.then( results => {
			setTimeout(() => {
				let promises = [];
				promises.push( fb_getUserProfile(formData.uid) );
				promises.push( onAuth() );
				
				Promise.all( promises )
				.then(results => {
					// push new data
					dispatch({ type: AUTH_GET_PROFILE, payload: { profile: ( results && results[0] && isObjectExists( results[0] ) ? results[0] : {} ) } });	
					dispatch({ type: AUTH_LOGIN, payload: ( results && results[1] && isObjectExists( results[1] ) ? results[1] : {} ) });	

					// updated
					dispatch({ type: AUTH_EDIT_PROFILE });
					dispatch(toggleLoader(false));
					dispatch(appChangesReset());
					triggerSuccessAlert("Profile Updated");

				});
			},4000);
			let promises = [];
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}