/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _replace from "lodash/replace";
import _remove from "lodash/remove";
import _split from "lodash/split";
import _trim from "lodash/trim";
import _filter from "lodash/filter";

import SchoolSelector from "../../modules/SchoolSelector";

import ModalDelete from "../../components/ModalDelete";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormCheckbox from "../../components/FormCheckbox";
import FormRadio from "../../components/FormRadio";
import FormDateOfBirth from "../../components/FormDateOfBirth";

import { isArrayExists } from "../../helpers/validation";
import { getSelectedValue, cloneCollections, getSelectOptions } from "../../helpers/data";
import { getMomentTime, printSemiPrettyDate, getAge } from "../../helpers/date";
import { isSkip, isSchemaRequired } from "../../helpers/schemas";
import { getCustomFieldOptions, isDefaultField } from "../../helpers/registration_form";

import { Button, InverseButton, InverseLink } from "../../styles/button";

import { traineeSchema } from "../../schemas/trainee";

import { STATE_OPTIONS } from "../../data/const_states";
import { DAY_OPTIONS, GENDER_OPTIONS, YES_NO_OPTIONS } from "../../constants";

const FormWrapper = styled.div`
    background: #e5e5e5;
    margin-bottom: 20px;
    padding: 20px;
    position: relative;

    .MuiOutlinedInput-multiline,
    .MuiOutlinedInput-input {
        background: #fff;
    }

    .MuiTypography-body1 {
        background: #e5e5e5;
    }

    .MuiTextField-root {
        margin: 0px;
    }
`;

const DOBWrapper = styled.div`
    span.field-label,
    span.required,
    .MuiInputLabel-root {
        background: none !important;
    }
`;

const useStyles = (theme) => ({
    boxheading: {
        fontSize: "20px",
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd",
    },
    section_title: {
        fontSize: "20px",
        fontWeight: "700",
        color: theme.palette.background,
        paddingTop: "15px",
        paddingBottom: "15px",
        marginBottom: "5px",
        borderBottom: "1px solid #ddd",
    },
});

class StudentForm extends React.Component {
    state = {
        openDeleteModal: false,
        deleteModal: false,
    };

    handleStudentUpdate = (newValue, key, subkey, subVal) => {
        const { onUpdate, trainees, student } = this.props;
        let newTrainees = trainees && isArrayExists(trainees) ? cloneCollections(trainees) : [],
            index = _findIndex(newTrainees, { id: student.id });

        if (index > -1) {
            switch (key) {
                case "date_of_birth_string":
                    newTrainees[index][key] = newValue;
                    newTrainees[index]["date_of_birth"] = subVal;
                    newTrainees[index]["age"] = getAge(newValue); // add age
                    break;
                case "trial":
                    newTrainees[index][key] = subVal;
                    break;
                default:
                    newTrainees[index][key] = newValue;
                    break;
            }
        }

        if (onUpdate) onUpdate(newTrainees);
    };

    handleStudentRemove = (event) => {
        const { onUpdate, trainees } = this.props;
        const { deleteModal } = this.state;
        let newTrainees = trainees && isArrayExists(trainees) ? cloneCollections(trainees) : [];
        if (deleteModal && deleteModal.id && !_isEmpty(deleteModal.id) && _find(newTrainees, { id: deleteModal.id })) {
            let pulled = _remove(newTrainees, { id: deleteModal.id });
        }

        this.setState({ openDeleteModal: false, deleteModal: false });

        if (onUpdate) onUpdate(newTrainees);
    };

    // getCustomFieldOptions = (field) => {
    //     const { schedule } = this.props;
    //     let options = [];
    //     if ( field.options && !_isEmpty( field.options ) ) {
    //         switch( field.options ) {
    //             case 'template_yes_no_options':
    //                 options = [ ...YES_NO_OPTIONS ];
    //                 break;
    //             case 'custom_options':
    //                 let splitData = _split( field.options_custom, ',' );
    //                 if ( splitData && isArrayExists( splitData ) ) {
    //                     if ( splitData.length > 1 ) {
    //                         _forEach( splitData, (string) => {
    //                             let splitString = _split( string, '\n' );
    //                             if ( splitString && isArrayExists( splitString ) && splitString.length > 1 ) {
    //                                 _forEach( splitString, lineString => {
    //                                     options.push({
    //                                         value: _trim( lineString ),
    //                                         label: _trim( lineString )
    //                                     });
    //                                 });
    //                             } else {
    //                                 options.push({
    //                                     value: _trim( splitString[0] ),
    //                                     label: _trim( splitString[0] )
    //                                 });
    //                             } // end - splitString
    //                         });
    //                     } else {
    //                         let splitString = _split( splitData[0], '\n' );
    //                         if ( splitString && isArrayExists( splitString ) ) {
    //                             if ( splitString.length > 1 ) {
    //                                 _forEach( splitString, lineString => {
    //                                     options.push({
    //                                         value: _trim( lineString ),
    //                                         label: _trim( lineString )
    //                                     });
    //                                 });
    //                             } else {
    //                                 options.push({
    //                                     value: _trim( splitString[0] ),
    //                                     label: _trim( splitString[0] )
    //                                 });
    //                             } // end - splitString
    //                         }
    //                     }
    //                 }
    //                 break;
    //         }
    //     } else {
    //         switch( field.type ) {
    //             case 'state':
    //                 let selectedState = _find( STATE_OPTIONS, { country: 'Malaysia' } );
    //                 if ( selectedState && selectedState.states && isArrayExists( selectedState.states ) ) {
    //                     selectedState.states.forEach(state => {
    //                         options.push({
    //                             value: state,
    //                             label: state
    //                         });
    //                     });
    //                 }
    //                 break;
    //             case 'district':
    //                 options = getSelectOptions({ list: ( schedule.districts_options && isArrayExists( schedule.districts_options ) ? _filter( schedule.districts_options, { value: this.getStateVal() } ) : [] ), options: [], keys: { value: 'label', label: 'label', sortBy: 'label' } });
    //                 break;
    //             case 'race':
    //                 options = getSelectOptions({ list: schedule.races_options || [], options: [], keys: { value: 'label', label: 'label', sortBy: 'label' } });
    //                 break;
    //         }
    //     } // end - field.options
    //     return options;
    // }

    getFieldOptions = (field) => {
        switch (field.name) {
            case "gender":
                return GENDER_OPTIONS;
            default:
                let custom_options = [{ value: "", label: "Select an Option" }, ...field.options];
                if (field.field_type && field.field_type === "radio") {
                    custom_options = [...field.options];
                }
                return field.custom_field ? custom_options : [];
        }
    };

    getFieldValue = (field) => {
        const { student } = this.props;
        switch (field.name) {
            case "trial":
                return student && student[field.name] && student[field.name] === "yes" ? "yes" : "no";
            default:
                return student && student[field.name] ? student[field.name] : field.default || "";
        }
    };

    getStateVal = () => {
        const { student, schedule } = this.props;
        let selectedField =
            schedule && schedule.registration_form_fields && isArrayExists(schedule.registration_form_fields)
                ? _find(schedule.registration_form_fields, { type: "state" })
                : null;
        return selectedField && student && student["cf_" + selectedField.id] ? student["cf_" + selectedField.id] : "";
    };

    getTooltip = (schema) => {
        switch (schema.id) {
            // case 'lms_course_link':
            //     return <a href="https://community.canvaslms.com/t5/Instructor-Guide/How-do-I-enable-course-self-enrollment-with-a-join-code-or/ta-p/830" target="_blank">Refer to this method</a>;
            default:
                return;
        }
    };

    getCustomField = (field) => {
        const { schedule } = this.props;
        let field_type = "",
            disabled = false;
        switch (field.type) {
            case "input":
                field_type = "text";
                break;
            case "state":
            case "race":
            case "iam":
                field_type = "select";
                break;
            case "district":
                field_type = "select";
                disabled = this.getStateVal() === "" ? true : false;
                break;
            default:
                field_type = field.type;
                break;
        }
        return {
            name: field.default ? field.type : "cf_" + field.id,
            label: (field.label || "") + (field.required && field.required === "yes" ? " (Required)" : ""),
            field_type,
            default: "",
            desc: (field && field.desc) || "",
            custom_field: true,
            options: getCustomFieldOptions(
                field,
                {
                    races: (schedule && schedule.races_options) || [],
                    districts: (schedule && schedule.districts_options) || [],
                    selectedState: this.getStateVal(),
                },
                field_type === "radio" || field_type === "select" ? false : true
            ),
            disabled,
            tooltip: null,
        };
    };

    getField = (id, field) => {
        const schema = _find(traineeSchema, { id });
        return schema
            ? {
                  name: schema.id || "",
                  label: (schema.label || "") + (isSchemaRequired(schema, "add") ? " (Required)" : ""),
                  field_type: schema.field || "",
                  default: schema.default || null,
                  desc: (field && field.desc) || "",
                  disabled: false,
                  tooltip: this.getTooltip(schema),
              }
            : null;
    };

    renderField = (id, placeholder, custom_field) => {
        const field = !isDefaultField(custom_field) ? this.getCustomField(custom_field) : this.getField(id, custom_field);
        if (field && field.field_type && !_isEmpty(field.field_type)) {
            switch (field.field_type) {
                case "text":
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleStudentUpdate} />;
                case "textarea":
                    return (
                        <FormInput
                            {...field}
                            rows={"notes" === id ? 6 : 3}
                            multiline={true}
                            value={this.getFieldValue(field)}
                            onChange={this.handleStudentUpdate}
                        />
                    );
                case "text_number":
                    return (
                        <FormInput
                            {...field}
                            type="number"
                            minNum={"age" === id ? 1 : false}
                            maxNum={"age" === id ? 99 : false}
                            value={this.getFieldValue(field)}
                            onChange={this.handleStudentUpdate}
                        />
                    );
                case "email":
                    return (
                        <FormInput
                            {...field}
                            placeholder={placeholder || false}
                            type="email"
                            value={this.getFieldValue(field)}
                            onChange={this.handleStudentUpdate}
                        />
                    );
                case "checkbox":
                    return (
                        <FormCheckbox
                            {...field}
                            label={<span style={{ fontSize: "16px" }}>{field.label}</span>}
                            value={this.getFieldValue(field)}
                            checked="yes"
                            unchecked="no"
                            onChange={this.handleStudentUpdate}
                        />
                    );
                case "select":
                    return (
                        <div style={{ paddingTop: "0px" }}>
                            <FormSelect
                                {...field}
                                value={this.getFieldValue(field)}
                                options={this.getFieldOptions(field)}
                                disableNative={false}
                                enableGreyOption={true}
                                onChange={this.handleStudentUpdate}
                            />
                        </div>
                    );
                case "radio":
                    return (
                        <div style={{ paddingTop: "5px" }}>
                            <FormRadio
                                {...field}
                                noBackgroundColor={true}
                                radioIcon={<FiberManualRecordIcon fill="#fff" />}
                                value={this.getFieldValue(field)}
                                options={this.getFieldOptions(field)}
                                onChange={this.handleStudentUpdate}
                            />
                        </div>
                    );
                case "select_date":
                    return (
                        <DOBWrapper>
                            <FormDateOfBirth
                                {...field}
                                value={this.getFieldValue(field)}
                                backgroundColor="#e5e5e5"
                                onChange={this.handleStudentUpdate}
                            />
                        </DOBWrapper>
                    );
            }
        } // end - field.field_type
    };

    renderFieldType = (field) => {
        const { classes } = this.props;

        // hide if field is hidden
        if (field.hidden && field.hidden === "yes") return null;

        switch (field.type) {
            case "email":
                return (
                    <>
                        <Grid item sm={6} xs={12}>
                            {this.renderField("email", "Hint: student’s email, not parent’s email", field)}
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Typography variant="body1" style={{ fontWeight: "700" }}>
                                * Email address must be different (unique) for each student.
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: "700" }}>
                                * Use your MOE-DL email if possible! When using MOE-DL email, you get to earn extra badges on DELIMa platform.
                            </Typography>
                        </Grid>
                    </>
                );
            case "name":
            case "gender":
            case "date_of_birth_string":
            case "medical_condition":
            case "iam":
                return (
                    <Grid item sm={6} xs={12}>
                        {this.renderField(field.type, null, field)}
                    </Grid>
                );
            case "trial":
                return (
                    <Grid item xs={12}>
                        {this.renderField(field.type, null, field)}
                    </Grid>
                );
            case "org":
                return (
                    <Grid item sm={6} xs={12}>
                        {
                            <SchoolSelector
                                predefinedState={this.getStateVal()}
                                value={this.getFieldValue({ ...field, name: "org" })}
                                field={field}
                                onChange={this.handleStudentUpdate}
                            />
                        }
                    </Grid>
                );
            case "state":
            case "district":
            case "race":
            case "input":
            case "select":
            case "radio":
            case "textarea":
                return (
                    <Grid item sm={6} xs={12}>
                        {this.renderField(field.id, false, field)}
                    </Grid>
                );
            case "section_title":
                return (
                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.section_title}>
                            {field.label || ""}
                            {field.desc && !_isEmpty(field.desc) ? (
                                <Typography variant="body1" style={{ padding: "5px 0 0 0" }}>
                                    {field.desc}
                                </Typography>
                            ) : null}
                        </Typography>
                    </Grid>
                );
        }
    };

    render = () => {
        const { student, schedule } = this.props;
        const { openDeleteModal, deleteModal } = this.state;
        return (
            <FormWrapper>
                <InverseLink
                    minWidth="0px"
                    noIconMargin="yes"
                    onClick={() => this.setState({ openDeleteModal: true, deleteModal: student })}
                    style={{ position: "absolute", right: "5px", top: "0", margin: "2px", padding: "4px" }}
                >
                    <i className="fa fa-remove"></i>
                </InverseLink>
                <Grid container spacing={3}>
                    {schedule && schedule.registration_form_fields && isArrayExists(schedule.registration_form_fields)
                        ? schedule.registration_form_fields.map(this.renderFieldType)
                        : null}
                    <Grid item xs={12}>
                        <Typography variant="body1" style={{ color: "#D32F2F", fontWeight: "700" }}>
                            * Compulsory to fill in
                        </Typography>
                    </Grid>
                </Grid>

                <ModalDelete
                    open={openDeleteModal || false}
                    title={`Are you sure you want to delete this student ${
                        deleteModal && deleteModal.name && !_isEmpty(deleteModal.name) ? " (" + deleteModal.name + ")" : ""
                    } ?`}
                    onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                    onDelete={this.handleStudentRemove}
                />
            </FormWrapper>
        );
    };
}

export default compose(withStyles(useStyles), withRouter)(StudentForm);
