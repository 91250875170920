/* eslint-disable */
import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import _isEmpty from 'lodash/isEmpty';

import ModalView from '../../components/ModalView';
import FormCheckbox from '../../components/FormCheckbox';

import { triggerErrorAlert } from '../../helpers/alert';

import { WrapWord } from '../../styles/misc';
import { AInfoLink, WarningButton } from '../../styles/button';

import theme from '../../theme';
import { PLATFORM_URL } from '../../constants';

const UnenrolStudents = ({
    id,
    name = '',
    schedule_id = false,
    showButton = true,
    alwaysOpen = false,
    changesNotSaved = false,
    onStudentUnenrol = () => {},
    onClose = () => {}
}) => {
    const [ openUnenrolModal, setOpenUnenrolModal ] = useState(false);
    const [ sendWithdrawEmail, setSendWithdrawEmail ] = useState("no");

    useEffect(() => {
        if ( alwaysOpen ) {
            setOpenUnenrolModal(true);
        }
    },[ alwaysOpen ]);

    const triggerUnenrol = () => {
        setOpenUnenrolModal(false);
        setSendWithdrawEmail("no");
        if ( onClose )
            onClose();
            
        onStudentUnenrol(
            id,
            sendWithdrawEmail === 'yes' ? true : false
        );
    }

    return (
    <>
        { showButton && (
        <div style={{ paddingTop: '5px' }}>
            <WarningButton
                style={{ padding: '4px 8px', fontSize: '12px' }}
                onClick={() => {
                    if ( changesNotSaved ) {
                        triggerErrorAlert('Please save your changes first.');
                    } else {
                        setOpenUnenrolModal(true);
                    }
                }}>
                <i className='fa fa-refresh'></i>
                Un-enrol
            </WarningButton>
        </div>    
        )}

        <ModalView
            open={ openUnenrolModal ? true : false}
            title={"Un-enrol student"}
            maxWidth="sm"
            actionLabel={ sendWithdrawEmail && sendWithdrawEmail === 'yes' ? "Next" : "Un-enrol"}
            cancelLabel="Cancel"
            doAction={triggerUnenrol}
            onClose={() => {
                setOpenUnenrolModal(false);
            }}
            contents={(
            <>
                <DialogTitle>You are about to un-enrol this student{( name && !_isEmpty( name ) ? " ("+name+")" : '' )} from this class.</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This will deactivate the student’s all active courses in the class and turn the Class Status to “Deleted”
                    </DialogContentText>
                    <FormCheckbox
                        label="Send out a withdrawal notice"
                        value={sendWithdrawEmail}
                        checked="yes"
                        unchecked="no"
                        onChange={(value) => {
                            if ( value === 'no' )
                                setSendWithdrawEmail("yes");
                            else
                                setSendWithdrawEmail("no");
                        }}
                        />
                </DialogContent>
            </>
            )}
            />
        
    </>
    )
}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        changesNotSaved: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(UnenrolStudents);