/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _isEmpty from 'lodash/isEmpty';

import TeamsTable from '../../modules/TeamsTable';

import AppWrapper from '../../components/AppWrapper';
import ErrorMessage from '../../components/ErrorMessage';


// import { isAdminType } from '../../helpers/auth';
import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';

import { toggleLoader } from '../../actions/global';



// import { PLATFORM_NAME } from '../../constants';

// import theme from '../../theme';

const getStudentTeams = (uid) => {
    return new Promise((resolve,reject) => {
        callFunctionsAPI({ url: 'student', action: 'get_teams', formData: { uid } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
    });
}

const StudentTeamsProfile = ({
    authData,
    dispatch
}) => {
    const [ loaded, setLoaded ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState(null);
    const [ student, setStudent ] = useState({});
    const [ studentTrainees, setStudentTrainees ] = useState([]);
    const [ teams, setTeams ] = useState([]);
    const [ schedules, setSchedules ] = useState([]);

    useEffect(() => {
        let mounted = true;
        if ( authData && authData.uid && !_isEmpty( authData.uid ) ) {
            getStudentTeams(authData.uid)
            .then(data => {
                if (mounted) {
                    setLoaded(true);
                    setStudent( data && data.student || {} );
                    setTeams(( data && data.teams || [] ));
                    setSchedules(( data && data.schedules || [] ));
                    setStudentTrainees(( data && data.student_trainees || [] ));
                }
            })
            .catch(error => {
                if ( mounted ) {
                    setLoaded(true);
                    setErrorMsg(getAPIErrorMessage(error));
                }
            });
        }
        return () => { mounted = false; }
    }, [ authData ]);

    const showTeamManagement = useMemo(() => {
        let valid = false;
        if ( schedules && schedules.length > 0 ) {
            schedules.forEach(schedule => {
                if ( schedule.teachers && schedule.teachers.length > 0 && schedule.teachers.find(teacher => teacher.email === authData.email) ) {
                    valid = true;
                }
            });
        }
        return valid;
    }, [ authData, schedules ]);

    const combinedTeachers = useMemo(() => {
        let teachers = [];
        if ( schedules && schedules.length > 0 ) {
            schedules.forEach(schedule => {
                if ( schedule.teachers && schedule.teachers.length > 0 ) {
                    schedule.teachers.forEach(teacher => {
                        if ( !teachers.find(t => t.email === teacher.email) ) {
                            teachers.push(teacher);
                        }
                    });
                }
            });
        }
        return teachers;
    },[ schedules ]);

    const combinedMentors = useMemo(() => {
        let mentors = [];
        if ( schedules && schedules.length > 0 ) {
            schedules.forEach(schedule => {
                if ( schedule.mentors && schedule.mentors.length > 0 ) {
                    schedule.mentors.forEach(mentor => {
                        if ( !mentors.find(m => m.email === mentor.email) ) {
                            mentors.push(mentor);
                        }
                    });
                }
            });
        }
        return mentors;
    },[ schedules ]);

    const handleAction = (type,formData) => {
        let promises = [],
            successMessage = '';
        switch( type ) {
            case 'create':
                promises.push( callFunctionsAPI({ url: 'student', action: 'create_team', formData }) );
                successMessage = 'Team created successfully';
                break;
            case 'create_teacher':
                promises.push( callFunctionsAPI({ url: 'student', action: 'teacher_create_team', formData }) );
                successMessage = 'Team created successfully';
                break;
            case 'join':
                promises.push( callFunctionsAPI({ url: 'student', action: 'join_team', formData }) );
                successMessage = 'Team joined successfully';
                break;
            case 'edit_teacher':
                promises.push( callFunctionsAPI({ url: 'student', action: 'teacher_update_team', formData }) );
                successMessage = 'Team updated successfully';
                break;
            case 'delete_teacher':
                promises.push( callFunctionsAPI({ url: 'student', action: 'teacher_delete_team', formData }) );
                successMessage = 'Team deleted successfully';
                break;
            case 'edit':
            default:
                promises.push( callFunctionsAPI({ url: 'student', action: 'update_team', formData }) );
                successMessage = 'Team updated successfully';
                break;
        }

        dispatch( toggleLoader(true) );
        Promise.all(promises)
        .then( results => {
            return getStudentTeams(authData.uid);
        })
        .then( data => {
            setStudent( data && data.student || {} );
            setTeams(( data && data.teams || [] ));
            setSchedules(( data && data.schedules || [] ));
            setStudentTrainees(( data && data.student_trainees || [] ));
            dispatch( toggleLoader(false) );
            triggerSuccessAlert(successMessage);
        })
        .catch(error => {
            dispatch( toggleLoader(false) );
            triggerErrorAlert( getAPIErrorMessage(error) );
        });
        
    }

    return <AppWrapper 
            title={"My Team Profile"}
            // maxWidth="1110px"
            onLoad={( !loaded ? true : false )}
            contents={ loaded ? (
            <>
                { errorMsg ? <ErrorMessage errorMessage={errorMsg} /> : (
                <>
                    <TeamsTable
                        showTeamManagement={showTeamManagement}
                        view={'student'}
                        student={student}
                        student_trainees={studentTrainees}
                        teams={teams}
                        schedules={schedules}
                        teachers={combinedTeachers}
                        mentors={combinedMentors}
                        onAction={handleAction}
                        />
                    {/* { showTeamManagement && (
                    <div
                        style={{
                            margin: "80px auto 40px auto",
                            maxWidth: "400px",
                            padding: "40px",
                            // border: "2px solid #ddd",
                            background: "#fff",
                            boxShadow: "0px 0px 5px rgba(0,0,0,0.25)",
                            borderRadius: "5px"
                        }}>
                        <Typography
                            variant="h4"
                            style={{
                                fontWeight: '700',
                                marginBottom: '20px'
                            }}
                            >Team Management</Typography>
                    </div>
                    ) } */}
                </>
                )}
            </>
            ) : null } />;

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        changesNotSaved: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(StudentTeamsProfile);