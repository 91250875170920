/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import FormControl from '@material-ui/core/FormControl';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    // KeyboardDatePicker,
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import _isEmpty from 'lodash/isEmpty';
import _toLower from 'lodash/toLower';
import _replace from 'lodash/replace';


import theme from '../../theme';

const TimePickerWrapper = styled.div`
    padding: 0px 5px;

    .MuiInputLabel-shrink {
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
    }
`;

class FormTimePicker extends React.Component {

    utcFormat = 8;

    handleChange = (date,value) => {
        const { name } = this.props;
        if ( this.props.onChange ) {
            let newValue = ( date && moment.isMoment(date) ? date.utcOffset(this.utcFormat).valueOf() : 0 );
            this.props.onChange(newValue,name);
        }
    }

    getValue = () => {
        const { value, noDefaultVal } = this.props; 
        return ( value ? moment(value).utcOffset(this.utcFormat) : ( noDefaultVal ? null : moment().utcOffset(this.utcFormat) ) );
    }

    renderLabel = () => {
        const { label } = this.props;
        var newLabel = false;
        if ( label && !_isEmpty( label ) && ( 
            ( _toLower( label ).indexOf("(required)") >= 0 ) || 
            ( _toLower( label ).indexOf("*") >= 0 )
         ) ) {
            newLabel = _replace( label, '(Required)', '' );
            newLabel = _replace( newLabel, '(required)', '' );
            newLabel = _replace( newLabel, '*', '' );
        } // end - label
        return ( newLabel ? <span>{ newLabel || '' }<span className="required">*</span></span> : ( label || '' ) );
    }

    render() {
        const { disabled, InputAdornmentProps, minutesStep = 1, mask = "__:__ _M", variant = 'dialog', disabledTextField = true } = this.props;
        return (
        <TimePickerWrapper>
            <FormControl fullWidth={true}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                        // disableToolbar
                        variant={variant}
                        inputVariant={( disabled ? 'filled' : 'outlined' )}
                        inputLabelProps={{
                            shrink: true,
                        }}
                        inputAdornmentProps={( InputAdornmentProps || null )}
                        inputProps={{
                            disabled: ( disabledTextField || false ),
                        }}
                        margin="normal"
                        mask={mask}
                        minutesStep={( minutesStep || 1 )}
                        TextFieldComponent={TextField}
                        label={<span style={{ background: '#fff', paddingRight: '4px' }}>{this.renderLabel()}</span>} 
                        value={this.getValue()}
                        onChange={this.handleChange}
                        disabled={( disabled || false )}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                        />
                </MuiPickersUtilsProvider>
            </FormControl>
        </TimePickerWrapper>
        )
    }

}

export default FormTimePicker