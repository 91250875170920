/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_SCHEDULES,
	GET_SCHEDULES_OPTIONS,
	GET_SCHEDULE,
	ADD_SCHEDULE,
	EDIT_SCHEDULE,
	DELETE_SCHEDULE,
	GET_GOOGLE_CALENDAR,
	GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getSchedules, fb_getSchedulesOptions, fb_getSchedule, fb_addSchedule, fb_updateSchedule, fb_deleteSchedule, fb_getGoogleCalendar } from './fb';


export const getSchedules = (props) => {
	return dispatch => {

		fb_getSchedules(props)
		.then(list => {
			dispatch({
				type: GET_SCHEDULES,
				payload: { list }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_SCHEDULES,
				payload: { list: [] }
			});
		})

	}
}

export const getSchedulesOptions = (props) => {
	return dispatch => {

		fb_getSchedulesOptions(list => {
			dispatch({
				type: GET_SCHEDULES_OPTIONS,
				payload: { list }
			});	
		},props);

	}
}

export const getSchedule = (id,props) => {
	return dispatch => {

		fb_getSchedule(id,props)
		.then(schedule => {
			dispatch({
				type: GET_SCHEDULE,
				payload: { schedule }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_SCHEDULE,
				payload: { schedule: {} }
			});
		})

	}
}

export const getGoogleCalendar = () => {
	return dispatch => {

		fb_getGoogleCalendar()
		.then(data => {
			dispatch({
				type: GET_GOOGLE_CALENDAR,
				payload: { calendar_events: ( data && data.events ? data.events : [] ) }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_GOOGLE_CALENDAR,
				payload: { calendar_events: [] }
			});
		})

	}
}

export const addSchedule = (formData,history) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_addSchedule(formData)
		.then( results => {
			if ( results && results.new_schedule_id && !_isEmpty( results.new_schedule_id ) ) {
				// updated
				dispatch({ type: ADD_SCHEDULE });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Schedule Added");
				// redirect to schedule page
				history.push("/schedules/" + results.new_schedule_id);
			} else {
				dispatch(toggleLoader(false));
				triggerErrorAlert('Missing new schedule ID');
			}
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const editSchedule = (formData,props) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateSchedule(formData)
		.then( results => {
			fb_getSchedule(formData.id,props)
			.then(schedule => {
				// push new data
				dispatch({ type: GET_SCHEDULE, payload: { schedule } });	
				// updated
				dispatch({ type: EDIT_SCHEDULE });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Schedule Updated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const deleteSchedule = (formData,props) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteSchedule(formData)
		.then( results => {
			fb_getSchedules(props)
			.then(list => {
				dispatch({ type: GET_SCHEDULES, payload: { list } });	
				dispatch({ type: DELETE_SCHEDULE });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("Schedule Deleted!");
			})
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}