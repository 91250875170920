/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	M_GET_CERTIFICATE,
    M_CERTIFICATE_UPDATED,
	GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getAPIErrorMessage } from '../../helpers/action';
import { appChangesReset } from '../misc';
import { fb_getCertificate } from './fb';
import { fb_addMaintenance, fb_updateMaintenance } from '../maintenance/fb';

export const getCertificate = (id) => {
	return dispatch => {

		fb_getCertificate(id)
		.then(certificate => {
			dispatch({
				type: M_GET_CERTIFICATE,
				payload: { certificate }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: M_GET_CERTIFICATE,
				payload: { certificate: {} }
			});
		})

	}
}

export const addCeritificate = (formData,history) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addMaintenance(formData)
		.then( results => {
			if ( results && results.item_id && !_isEmpty( results.item_id ) ) {
				// updated
				dispatch({ type: M_CERTIFICATE_UPDATED });
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("Certificate Template Added");
				// redirect to course page
				history.push("/maintenance/certificates/" + results.item_id);
			} else {
				dispatch(toggleModalProcessing(false,apiNum));
				triggerErrorAlert('Missing new certificate ID');
			}
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

  }
}

export const editCeritificate = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateMaintenance(formData)
		.then( results => {
			fb_getCertificate(formData.id)
			.then(certificate => {
				// push new data
				dispatch({ type: M_GET_CERTIFICATE, payload: { certificate } });	
				// updated
				dispatch({ type: M_CERTIFICATE_UPDATED });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Certificate Template Updated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

  }
}