import React from "react";

const info = {
    heading: (
        <>
            <i className="fa fa-exclamation-circle"></i>Strictly for Ops & System’s edit only
        </>
    ),
    contents: [
        {
            id: "1",
            title: "Add a program to enable partners to choose.",
            content: (
                <>
                    <div>
                        <ol style={{ listStyle: "numeric", paddingLeft: "10px" }}>
                            <li>A Program consists of one and more products (i.e. courses)</li>
                            <li>A Program is crafted to help certain partner to control and curate products for their students. </li>
                            <li>
                                When scheduling a class, a Partner can pick and choose the correct Program to run. If a Program is not available for a
                                Partner, please contact Ops & System team.
                            </li>
                        </ol>
                    </div>
                </>
            ),
        },
    ],
};

const buttons = [];

export default {
    info,
    buttons,
};
