/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import _isEmpty from "lodash/isEmpty";
import _sortBy from "lodash/sortBy";

import ModalView from "../../components/ModalView";
import FormRadio from "../../components/FormRadio";

import { isArrayExists } from "../../helpers/validation";
import { triggerErrorAlert } from "../../helpers/alert";
import { cloneCollections, getSelectOptions, getSelectValues } from "../../helpers/data";
import { callFunctionsAPI } from "../../helpers/action";

import { WrapWord } from "../../styles/misc";
import { AInfoLink, WarningButton, InverseButton, IndigoButton } from "../../styles/button";
import { InfoTag } from "../../styles/tag";

import theme from "../../theme";
import { DEV_MODE, PLATFORM_URL } from "../../constants";
import zIndex from "@material-ui/core/styles/zIndex";

const Wrapper = styled.div`
    display: flex;
    gap: 5px;
`;

const useStyles = (theme) => ({
    popper: {
        zIndex: 9999999,
        pointerEvents: "auto",
    },
    tooltip: {
        fontSize: "14px",
        color: "#fff",
        padding: "5px 8px",
    },
});

const PartnerSwitcher = ({ authData = {}, regions = [], classes = {} }) => {
    const [open, setOpen] = useState(false);
    const [region, setRegion] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (regions && isArrayExists(regions)) {
            setLoaded(true);
        }
    }, [regions]);

    const currentRegion = useMemo(() => {
        if (authData && authData.region && regions && isArrayExists(regions)) {
            return regions.find((region) => region.id === authData.region);
        }
        return null;
    }, [authData, regions]);

    const regionOptions = useMemo(() => {
        let options = [];
        if (regions && isArrayExists(regions)) {
            regions.forEach((region) => {
                if (
                    authData &&
                    authData.belongs_to &&
                    isArrayExists(authData.belongs_to) &&
                    authData.belongs_to.find((belong) => belong === region.id)
                ) {
                    options.push({
                        value: region.id,
                        label: region.label,
                    });
                }
            });
        }
        return options;
    }, [authData, regions]);

    const handleAction = () => {
        setLoading(true);
        callFunctionsAPI({
            method: "post",
            url: "auth",
            action: "change_region",
            formData: {
                newRegion: region,
            },
        })
            .then((data) => {
                window.location = DEV_MODE ? "http://localhost:3000" : PLATFORM_URL;
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                triggerErrorAlert(error);
            });
    };

    return loaded ? (
        <Wrapper>
            <InfoTag
                style={{
                    border: "2px solid #1976d2",
                }}
            >
                {currentRegion ? currentRegion.label : ""}
            </InfoTag>

            <Tooltip title={"Switch Organisation"} arrow placement="top" classes={classes}>
                <IndigoButton
                    minWidth="0px"
                    style={{
                        color: "#fff",
                    }}
                    onClick={() => {
                        setOpen(true);
                        setRegion(currentRegion ? currentRegion.id : "");
                    }}
                >
                    <i className="fa fa-refresh" style={{ marginRight: "0px" }}></i>
                </IndigoButton>
            </Tooltip>

            <ModalView
                open={open}
                processing={loading}
                onClose={() => {
                    if (!loading) setOpen(false);
                }}
                title="Switch Organisation To"
                maxWidth="sm"
                modalStyle={{
                    zIndex: 999999,
                }}
                disableBackdrop={true}
                actionLabel={"Switch"}
                cancelLabel="Cancel"
                doAction={handleAction}
                contents={
                    <>
                        <FormRadio label="" options={regionOptions} value={region} onChange={(newValue) => setRegion(newValue)} />
                    </>
                }
            />
        </Wrapper>
    ) : null;
};

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        changesNotSaved: (state.misc && state.misc.changes_made) || null,
    };
};

export default compose(connect(mapStateToProps), withStyles(useStyles), withRouter)(PartnerSwitcher);
