/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import _isEmpty from "lodash/isEmpty";
import _sortBy from "lodash/sortBy";
import _filter from "lodash/filter";

import ModalView from "../../components/ModalView";
import FormCheckbox from "../../components/FormCheckbox";
import FormSelect from "../../components/FormSelect";
import FormInput from "../../components/FormInput";
import FormMultiSelect from "../../components/FormMultiSelect";

import { isArrayExists } from "../../helpers/validation";
import { triggerErrorAlert } from "../../helpers/alert";
import { cloneCollections, getSelectOptions, getSelectValues } from "../../helpers/data";

import { WrapWord } from "../../styles/misc";
import { AInfoLink, WarningButton, InverseButton } from "../../styles/button";
import { SuccessTag } from "../../styles/tag";

import theme from "../../theme";
import { PLATFORM_URL } from "../../constants";

const Wrapper = styled.div`
    padding: 10px 15px;
    border: 1px solid rgba(0, 0, 0, 0.87);
`;

const MultiRegionSelector = ({ user = {}, regions = [], changesNotSaved = false, onChange = () => {} }) => {
    const [open, setOpen] = useState(false);
    const [searchterms, setSearchTerms] = useState("");

    const items = useMemo(() => {
        // filter by search terms
        const list =
            regions && isArrayExists(regions)
                ? !_isEmpty(searchterms)
                    ? _filter(regions, (r) => r.label.toLowerCase().includes(searchterms.toLowerCase()))
                    : regions
                : [];
        return list && isArrayExists(list) ? _sortBy(list, [(r) => r.label.toLowerCase()]) : [];
    }, [regions, searchterms]);

    return (
        <>
            <div
                style={{
                    padding: "10px 15px",
                    border: "1px solid #e0e0e0",
                }}
            >
                <Typography variant="h6" style={{ marginBottom: 10 }}>
                    Partner(s)
                </Typography>
                <FormInput label="Search" placeholder="Search for partner(s)" value={searchterms} onChange={(newValue) => setSearchTerms(newValue)} />
                <Grid container spacing={2}>
                    {items && isArrayExists(items) ? (
                        items.map((region, index) => {
                            return (
                                <Grid item xs={12} sm={4} key={region.id}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    user.belongs_to && isArrayExists(user.belongs_to) ? user.belongs_to.includes(region.id) : false
                                                }
                                                onChange={(e) => {
                                                    const { checked } = e.target;
                                                    let belongs_to =
                                                        user.belongs_to && isArrayExists(user.belongs_to) ? cloneCollections(user.belongs_to) : [];
                                                    if (checked) {
                                                        belongs_to.push(region.id);
                                                    } else {
                                                        belongs_to = _filter(belongs_to, (r) => r !== region.id);
                                                    }
                                                    onChange(belongs_to, "belongs_to");
                                                }}
                                                color="primary"
                                            />
                                        }
                                        label={
                                            <>
                                                {region.label}
                                                {/* if is first item in user.belongs_to */}
                                                {user.belongs_to && isArrayExists(user.belongs_to) && user.belongs_to[0] === region.id && (
                                                    <SuccessTag style={{ marginLeft: 10 }}>Primary</SuccessTag>
                                                )}
                                            </>
                                        }
                                    />
                                </Grid>
                            );
                        })
                    ) : (
                        <Grid item xs={12}>
                            No partner found
                        </Grid>
                    )}
                </Grid>
                {/*                 
                <FormMultiSelect
                    label="Partner(s)"
                    placeholder="Select at least one partner"
                    value={user.belongs_to && isArrayExists(user.belongs_to) ? user.belongs_to : []}
                    options={getSelectOptions({
                        list: regions && isArrayExists(regions) ? _sortBy(regions, [(r) => r.label.toLowerCase()]) : [],
                        options: [],
                        keys: { value: "id", label: "label" },
                    })}
                    onChange={(value) => {
                        onChange(value, "belongs_to");
                    }}
                /> */}
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        changesNotSaved: (state.misc && state.misc.changes_made) || null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(MultiRegionSelector);
