/* eslint-disable */
import React from 'react';
import styled from "styled-components";

import theme from '../theme';

export const H1 = styled.h1`
    color: ${props => (props.color ? props.color : theme.palette.background)};
    font-weight: ${props => (props.weight ? props.weight : 700)};
    text-transform: ${props => (props.transform ? props.transform : 'none')};
`;

export const H2 = styled.h2`
    color: ${props => (props.color ? props.color : theme.palette.background)};
    font-weight: ${props => (props.weight ? props.weight : 700)};
    text-transform: ${props => (props.transform ? props.transform : 'none')};
`;

export const H3 = styled.h3`
    color: ${props => (props.color ? props.color : theme.palette.background)};
    font-weight: ${props => (props.weight ? props.weight : 700)};
    text-transform: ${props => (props.transform ? props.transform : 'none')};
`;

export const H4 = styled.h4`
    color: ${props => (props.color ? props.color : theme.palette.background)};
    font-weight: ${props => (props.weight ? props.weight : 700)};
    text-transform: ${props => (props.transform ? props.transform : 'none')};
`;

export const H5 = styled.h5`
    color: ${props => (props.color ? props.color : theme.palette.background)};
    font-weight: ${props => (props.weight ? props.weight : 700)};
    text-transform: ${props => (props.transform ? props.transform : 'none')};
`;

export const H6 = styled.h6`
    color: ${props => (props.color ? props.color : theme.palette.background)};
    font-weight: ${props => (props.weight ? props.weight : 700)};
    text-transform: ${props => (props.transform ? props.transform : 'none')};
`;