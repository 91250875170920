/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_PERSONNELS,
	GET_PERSONNEL,
	ADD_PERSONNEL,
	EDIT_PERSONNEL,
	DELETE_PERSONNEL,
	GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getPersonnels, fb_getPersonnel, fb_addPersonnel, fb_updatePersonnel, fb_resendAccountCreationEmail, fb_deletePersonnel, fb_triggerGrantPersonnelAccess, fb_triggerRevokePersonnelAccess } from './fb';


export const getPersonnels = (props) => {
	return dispatch => {
		
		fb_getPersonnels(props)
		.then(list => {
			dispatch({
				type: GET_PERSONNELS,
				payload: { list }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_PERSONNELS,
				payload: { list: [] }
			});
		})

	}
}

export const getPersonnel = (uid,partner_id) => {
	return dispatch => {

		fb_getPersonnel(uid,partner_id)
		.then(personnel => {
			dispatch({
				type: GET_PERSONNEL,
				payload: { personnel }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_PERSONNEL,
				payload: { personnel: {} }
			});
		})

	}
}

export const addPersonnel = (formData,history,partner_id) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addPersonnel(formData)
		.then( results => {
			if ( results && results.new_personnel_id && !_isEmpty( results.new_personnel_id ) ) {
				fb_getPersonnel( results.new_personnel_id )
				.then( personnel => {
					// updated
					dispatch({ type: ADD_PERSONNEL });
					dispatch(toggleModalProcessing(false));
					triggerSuccessAlert("<strong>Personnel Added</strong><br/>An email has been sent to instruct new user of the next steps to create an account. Notify the user to check his/her inbox.",5000);
					// redirect to personnel page
					history.push("/partner/" + partner_id + "/personnel/" + results.new_personnel_id);
				});
			} else {
				dispatch(toggleModalProcessing(false,apiNum));
				triggerErrorAlert('Missing new personnel ID');
			}
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const editPersonnel = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updatePersonnel(formData)
		.then( results => {
			fb_getPersonnel(formData.uid)
			.then(personnel => {
				// push new data
				dispatch({ type: GET_PERSONNEL, payload: { personnel } });	
				// updated
				dispatch({ type: EDIT_PERSONNEL });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Personnel Updated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const resendAccountCreationEmail = (uid) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_resendAccountCreationEmail(uid)
		.then( results => {
			dispatch({ type: EDIT_PERSONNEL });
			dispatch(toggleLoader(false));
			triggerSuccessAlert("<strong>Account Creation Email sent!</strong><br/>An email has been sent to instruct new user of the next steps to create an account. Notify the user to check his/her inbox.",5000);
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const deletePersonnel = (uid) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deletePersonnel(uid)
		.then( results => {
			fb_getPersonnels()
			.then(list => {
				dispatch({ type: GET_PERSONNELS, payload: { list } });	
				dispatch({ type: DELETE_PERSONNEL });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("Personnel Deleted!");
			})
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}


export const triggerGrantPersonnelAccess = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_triggerGrantPersonnelAccess(formData)
		.then( results => {
			fb_getPersonnel(formData.uid)
			.then(personnel => {
				// push new data
				dispatch({ type: GET_PERSONNEL, payload: { personnel } });	
				// updated
				dispatch({ type: EDIT_PERSONNEL });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Access Granted");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error),5000);
		});

	}
}

export const triggerRevokePersonnelAccess = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_triggerRevokePersonnelAccess(formData)
		.then( results => {
			fb_getPersonnel(formData.uid)
			.then(personnel => {
				// push new data
				dispatch({ type: GET_PERSONNEL, payload: { personnel } });	
				// updated
				dispatch({ type: EDIT_PERSONNEL });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Access Revoked");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error),5000);
		});

	}
}