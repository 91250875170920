/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import styled from "styled-components";
import Tooltip from '@material-ui/core/Tooltip';
import blue from '@material-ui/core/colors/blue';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { withStyles } from "@material-ui/core/styles";

import { InverseButton } from '../../styles/button';

import theme from '../../theme';

const IconWrapper = styled.span`
    display: inline-block;
    color: ${props => ( props.icon_color ? props.icon_color : '#676767' )};
    font-size: 18px;
    padding-left: 3px;
    padding-right: 8px;
    vertical-align: middle;
`;

const ButtonWrapper = styled.div`
    display: block;
    width: 35px;
    padding-left: 5px;
    text-align: right;
`;

const ContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    color: #f1f1f1;

    a {
        color: #fff;
        text-decoration: underline;
        &:hover { color: #f1f1f1; }
    }

    p {
        margin-bottom: 8px;
        &:last-child { margin-bottom: 0px; }
    }
`;

const useStyles = theme => ({
    popper: {
        zIndex: '3000',
        pointerEvents: 'auto'
    },
    tooltip: {
        fontSize: '14px',
        color: '#fff',
        background: blue[400],
        maxWidth: 400,
        padding: '0px',
        '& > .MuiTooltip-arrow': {
            color: blue[400]
        }
    }
});

class ToolTipInfo extends React.Component {

    state = {
        open: false
    }

    handleTooltipClose = () => {
        this.setState({ open: false });
    }

    handleTooltipOpen = () => {
        this.setState({ open: true });
    }

    renderContent = () => {
        const { content } = this.props;
        return (
        <ClickAwayListener 
            onClickAway={this.handleTooltipClose}>
            <ContentWrapper>
                <div style={{ paddingRight: "10px", color: "#fff" }}><i className="fa fa-lightbulb-o"></i></div>
                <div>{( content || null )}</div>
                <ButtonWrapper>
                    <InverseButton size="small" onClick={this.handleTooltipClose} noIconMargin="yes" minWidth="0px"><i className="fa fa-times"></i></InverseButton>
                </ButtonWrapper>
            </ContentWrapper>
        </ClickAwayListener>
        )
    }

    render() {
        const { classes, icon_color } = this.props;
        const { open } = this.state;
        return (
        <Tooltip 
            open={open} 
            title={this.renderContent()} 
            arrow 
            placement="top"
            classes={{ 
                popper: classes.popper,
                tooltip: classes.tooltip 
            }}>
            <IconWrapper 
                icon_color={icon_color}
                onMouseEnter={this.handleTooltipOpen}
            >
                <i className="fa fa-info-circle"></i>
            </IconWrapper>
        </Tooltip>
        )
    }

}

export default compose(
    withStyles(useStyles)
)(ToolTipInfo);