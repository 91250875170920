export const classCalendarSchema = [
    { 
        id: 'id', 
        label: 'ID',
        required: ['add','update','delete'], 
        default: '', 
        type: 'string', 
        validation: 'string_id',
        sync: true
    },
    { 
        id: 'lms_context_code', 
        label: 'LMS Context Code',
        required: false, 
        default: '', 
        type: 'system_data', 
        sync: true
    },
    { 
        id: 'lms_event_id', 
        label: 'LMS Event ID',
        required: false, 
        default: '', 
        type: 'system_data', 
        sync: true
    },
    { 
        id: 'lms_mentor_id', 
        label: 'LMS Mentor ID',
        required: false, 
        default: '', 
        type: 'system_data', 
        sync: true
    },
    { 
        id: 'course_id', 
        label: 'Course ID',
        required: false, 
        default: '', 
        type: 'string', 
        disabled: ['update'],
        dependency: { schema: 'schedule', id: 'course_id' },
        sync: true
    },
    { 
        id: 'schedule_id', 
        label: 'Class Schedule ID',
        required: false, 
        default: '', 
        type: 'string', 
        disabled: ['update'],
        dependency: { schema: 'schedule', id: 'id' },
        sync: true
    },
    {   
        id: 'training_location_id',
        label: 'Venue',
        required: false,
        default: '',
        type: 'string', 
        disabled: ['update'],
        dependency: { schema: 'schedule', id: 'training_location_id' },
        sync: true
    },
    {   
        id: 'training_location_label',
        label: 'Venue Name',
        required: false,
        default: '',
        type: 'string', 
        disabled: ['update'],
        dependency: { schema: 'schedule', id: 'training_location_label' },
        sync: true
    },
    {   
        id: 'name',
        label: 'Name',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        sync: true
    },
    {   
        id: 'description',
        label: 'Description',
        required: false,
        default: '',
        type: 'string',
        field: 'tinymce_editor',
        sync: true
    },
    {   
        id: 'start_date',
        label: 'Start Date',
        required: ['add','update'],
        default: 0,
        type: 'timestamp',
        field: 'date_time',
        sync: true
    }, 
    {   
        id: 'end_date',
        label: 'End Date',
        required: ['add','update'],
        default: 0,
        type: 'timestamp',
        field: 'date_time',
        sync: true
    }, 
    {   
        id: 'quarterly_report',
        label: 'Quarterly Report',
        required: false,
        default: 'no',
        type: 'string',
        field: 'checkbox',
        sync: true
    },
    {   
        id: 'showcase',
        label: 'Showcase',
        required: false,
        default: 'no',
        type: 'string',
        field: 'checkbox',
        sync: true
    },
    {   
        id: 'remarks',
        label: 'Remarks',
        required: false,
        default: '',
        type: 'string',
        field: 'textarea',
        sync: true
    },
];