/* eslint-disable */
import styled from "styled-components";

export const OpacityComponent = styled.div`
    opacity: ${props => (props.opacity ? props.opacity : 1)};
    pointer-events: ${props => (props.disabled ? "none" : "inherit")};
`;

export const FlexComponent = styled.div`
    display: ${props => (props.inline ? 'inline-flex' : 'flex')};
    flex-direction: ${props => (props.direction ? props.direction : 'row')};
    justify-content: ${props => (props.justify ? props.justify : 'flex-start')};
    align-items: ${props => (props.items ? props.items : 'flex-start')};
    align-content: ${props => (props.content ? props.content : 'flex-start')};
`;

export const FlexColumn = styled.div`
    width: ${props => (props.width ? props.width : '100%')};
`;

export const FullScreenFlex = styled(FlexComponent)`
    height: ${props => (props.height ? props.height : '100vh')};
    width: 100%;
`;

export const FullScreenComponent = styled.div`
    height: ${props => (props.height ? props.height : '100vh')};
    width: 100%;
`;

export const Disabled = styled.div`
    opacity: ${props => (props.opacity ? props.opacity : 1)};
    cursor: default;
    pointer-events: none;
`;