export const badgeSchema = [
    {
        id: "id",
        label: "ID",
        required: ["update", "delete"],
        default: "",
        type: "string",
        validation: "string_id",
        skip: ["add"],
    },
    {
        id: "status",
        label: "status",
        required: false,
        default: "active",
        type: "string",
        field: "radio",
        skip: false,
    },
    {
        id: "badge_class_id",
        label: "Badge Class ID",
        required: false,
        default: "",
        type: "system_data",
        skip: ["update"],
    },
    {
        id: "badge_class_url",
        label: "Badge Class URL",
        required: false,
        default: "",
        type: "system_data",
        skip: ["update"],
    },
    {
        id: "issuer_class_id",
        label: "Issuer Class ID",
        required: false,
        default: "",
        type: "system_data",
        skip: ["update"],
    },
    {
        id: "issuer_class_url",
        label: "Issuer Class URL",
        required: false,
        default: "",
        type: "system_data",
        skip: ["update"],
    },
    {
        id: "image",
        label: "Badge Image",
        required: ["add", "update"],
        default: "",
        type: "string",
        field: "upload_image",
        skip: false,
    },
    {
        id: "type",
        label: "Badge Level",
        required: ["add", "update"],
        default: "",
        type: "string",
        field: "select",
        skip: false,
    },
    {
        id: "type_label",
        label: "Badge Level (Label)",
        required: ["add", "update"],
        default: "",
        type: "system_data",
        skip: false,
    },
    {
        id: "name",
        label: "Course Name (Badge Name)",
        required: ["add", "update"],
        default: "",
        type: "string",
        field: "text",
        disabled: ["add", "update"],
        skip: false,
    },
    {
        id: "course_type",
        label: "Course Type",
        required: false,
        default: "",
        type: "string",
        field: "select",
        disabled: ["add", "update"],
        skip: false,
    },
    {
        id: "course_type_label",
        label: "Course Type Label",
        required: false,
        default: "",
        type: "system_data",
        skip: false,
    },
    {
        id: "criteria",
        label: "Criteria (URL)",
        required: false,
        default: "",
        type: "system_data",
        skip: ["update"],
    },
    {
        id: "criteria_text",
        label: "Criteria",
        required: ["add", "update"],
        default: "",
        type: "string",
        field: "tinymce_editor",
        skip: false,
    },
    {
        id: "description",
        label: "Badge Description",
        required: ["add", "update"],
        default: "",
        type: "string",
        field: "tinymce_editor",
        skip: false,
    },
    {
        id: "issuer_name",
        label: "Issuer",
        required: ["add", "update"],
        default: "Chumbaka Sdn. Bhd.",
        type: "string",
        field: "text",
        skip: false,
    },
    {
        id: "issuer_url",
        label: "Issuer Website",
        required: ["add", "update"],
        default: "https://www.chumbaka.asia/",
        type: "string",
        field: "text",
        skip: false,
    },
    {
        id: "standard",
        label: "Standard",
        required: false,
        default: "",
        type: "string",
        field: "text",
        skip: false,
    },
    {
        id: "tags",
        label: "Skills Tag",
        required: false,
        default: [],
        type: "array",
        field: "tag_input",
        skip: false,
    },
    {
        id: "cert_referer",
        label: "Certificate Template",
        required: false,
        default: "",
        type: "string",
        field: "select",
        skip: false,
    },
    {
        id: "references",
        label: "References",
        required: ["add"],
        default: { course_id: "" },
        type: "system_data",
        skip: ["update"],
    },
    {
        id: "created_on",
        label: "Created On",
        required: false,
        default: 0,
        type: "system_date",
        skip: ["update"],
    },
    {
        id: "modified_on",
        label: "Modified On",
        required: false,
        default: 0,
        type: "system_date",
    },
    {
        id: "last_modified_by",
        label: "Modified By",
        required: false,
        default: "",
        type: "system_modified_by",
    },
];
