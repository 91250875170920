/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _filter from 'lodash/filter';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getCourses = () => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'canvasLMS', action: 'get_courses' })
        .then(list => {
            let courses = [];
            if ( list && isArrayExists( list ) ) {
                _forEach( list, item => {
                    let course = cloneCollections(item);
                    course.id = item.id.toString();
                    course.account_id = item.account_id.toString();
                    
                    courses.push( course );
                    //*incomplete - need the test course for testing purpose
                    // filter out test accounts courses
                    // if ( course.account_id !== '4' ) {
                    //     courses.push( course );
                    // } // end - course
                });
            } // end - list
            resolve(courses);
        })
        .catch(error => {
            reject(error);
        });


    })
}

export const fb_getCourseAssignments = (course_id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'canvasLMS', action: 'get_course_assignements', formData: { course_id } })
        .then(list => {
            let assignments = [];
            if ( list && isArrayExists( list ) ) {
                _forEach( list, item => {
                    let assignment = cloneCollections(item);
                    assignment.id = item.id.toString();
                    assignments.push( assignment );
                });
            } // end - list
            resolve(assignments);
        })
        .catch(error => {
            reject(error);
        });


    })
}

export const fb_getCourseModules = (course_id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'canvasLMS', action: 'get_course_modules', formData: { course_id } })
        .then(list => {
            let modules = [];
            if ( list && isArrayExists( list ) ) {
                _forEach( list, item => {
                    let module = cloneCollections(item);
                    module.id = item.id.toString();
                    modules.push( module );
                });
            } // end - list
            resolve(modules);
        })
        .catch(error => {
            reject(error);
        });


    })
}