/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _snakeCase from 'lodash/snakeCase';
import _find from 'lodash/find';

import { isAdmin } from '../../helpers/auth';
import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists, validateEmail, isObjectExists } from '../../helpers/validation';
import { getSnapshotDataBySchema, getDocDataBySchema } from '../../helpers/schemas';

import { partnerSchema } from '../../schemas/partner';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getPartners = (callback,props = {}) => {
    return new Promise((resolve,reject) => {
        const { authData } = props;

        let Ref = firebase.firestore().collection("partners");

        // for non-admin, filter by region
        if ( authData && !isAdmin( authData ) && authData.region && !_isEmpty( authData.region ) ) {
            Ref = Ref.where('region','==',authData.region);
        }
        
        Ref.onSnapshot(snapshot => {
            const list = [];
            if ( snapshot ) {
                snapshot.forEach( doc => {
                    let item = getDocDataBySchema( partnerSchema, doc );
                    list.push( item );
                });
            } // end - snapshot
            callback(list);
        });

    })
}

export const fb_getPartner = (id,props = {}) => {
    return new Promise((resolve,reject) => {
        const { authData } = props;

        let promises = [];
        promises.push( firebase.firestore().collection("partners").doc(id).get() );
        
        Promise.all( promises )
        .then(results => {
            let item = {};

            if ( results && results[0] ) {
                item = getDocDataBySchema( partnerSchema, results[0] );
            } // end - results[0]

            // check if the current user can access the item
            if ( authData && !isAdmin( authData ) ) {
                if ( !( authData.region && !_isEmpty( authData.region ) && authData.region === item.region ) )
                    item = {}; // reset it
            } // end - authData

            resolve(item);
        })
        .catch(error => {
            reject(error);
        });
    })
}

export const fb_addPartner = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'partner', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updatePartner = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'partner', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deletePartner = (id,region) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'partner', action: 'delete', formData: { id, region } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}