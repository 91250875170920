/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';

import ModalView from '../../components/ModalView';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';
import FormDatePicker from '../../components/FormDatePicker';
import FormCheckbox from '../../components/FormCheckbox';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions } from '../../helpers/data';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';


import { ENROLLMENT_STATUS_OPTIONS } from '../../constants';

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
    border: 2px solid #212121;
`;

class MentorModal extends React.Component {

    utcOffSet = 8;
    
    state = {
        modalData: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { traineeData, open } = this.props;
        if ( open && !prevProps.open ) {
            this.setState({ modalData: this.getInitialModalData( traineeData ) });
        } // end - open
    }
    
    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose )
            onClose();
    }

    handleUpdate = () => {
        const { traineeData, onUpdate } = this.props;
        const { modalData } = this.state;
        let error = false;
        // make sure a mentor is selected
        if ( !( modalData && modalData.mentor && !_isEmpty( modalData.mentor ) ) )
            error = "Please select a mentor.";

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            if ( onUpdate ) {
                onUpdate( modalData );
                this.handleClose();
            } // end - onUpdate
        }
            
    }

    handleFormUpdate = (newValue,key,subkey,subVal) => {
        const { modalData } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} );
        switch( key ) {
            default:
                if ( subkey && ( subkey === 'checked' || subkey === 'unchecked' ) && subVal && !_isEmpty( subVal ) ) {
                    // use subVal as the new value
                    newData[key] = subVal;
                } else {
                    // otherwise - just update normally
                    newData[key] = newValue
                }
                break;
        } // end - key
        
        this.setState({ modalData: newData })
    }

    getInitialModalData = (traineeData) => {
        return ( traineeData && isObjectExists( traineeData ) ? cloneCollections( traineeData ) : {});
    }

    getMentorOptions = () => {
        const { schedule, mentors } = this.props;
        let options = [{ value: '', label: 'Select an Option' }];
        if ( schedule && schedule.mentors && isArrayExists( schedule.mentors ) ) {
            _forEach( schedule.mentors, mentor => {
                let selected = _find( mentors, { email: mentor.id } );
                options.push({
                    value: mentor.id,
                    label: ( selected && selected.name && !_isEmpty( selected.name ) ? selected.name : mentor.id )
                })
            });
        } // end - schedule.mentors
        return options;
    }

    renderForm = () => {
        const { schedule, mentors } = this.props;
        const { modalData } = this.state;
        return (
        <div>
            <div style={{ padding: '0 8px' }}>
                <FormSelect 
                    label="" 
                    name="mentor" 
                    value={( modalData && modalData.mentor ? modalData.mentor : '' )} 
                    options={this.getMentorOptions()} 
                    onChange={this.handleFormUpdate} />
            </div>  
        </div>
        );
    }

    render() {
        const { open } = this.props;
        const { modalData } = this.state;
        return <ModalView 
                open={open}
                title={"Change Mentor" + ( modalData && modalData.name && !_isEmpty( modalData.name ) ? ' For '+modalData.name : '' )}
                onClose={this.handleClose}
                disableBackdrop={true}
                actionLabel="Update"
                maxWidth="xs"
                doAction={this.handleUpdate}
                contents={this.renderForm()} />
    }

}

export default MentorModal;