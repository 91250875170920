export const marketingDatabaseSchema = [
    { 
        id: 'id', 
        label: 'ID',
        required: ['update','delete'], 
        default: '', 
        type: 'string', 
        validation: 'string_id', 
        skip: ['add']
    },
    {   
        id: 'region',
        label: 'Partner',
        required: ['add','update'],
        default: '',
        type: 'string', 
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'name',
        label: 'Name',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'text',
        sort: true,
        search_keywords: true,
        skip: false,
        sync: true
    },
    {   
        id: 'email',
        label: 'Email',
        required: ['add','update'],
        default: '',
        type: 'email',
        field: 'email',
        search_keywords: true,
        skip: false,
        sync: true
    },
    {   
        id: 'phone',
        label: 'Mobile No',
        required: false,
        default: '',
        type: 'string',
        field: 'phone',
        skip: false,
        sync: true
    },
    {   
        id: 'org',
        label: 'Organisation',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        sort: true,
        search_keywords: true,
        skip: false,
        sync: true
    },
    {   
        id: 'position',
        label: 'Position',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        skip: false,
        sync: true
    },
    {   
        id: 'city',
        label: 'City',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        sort: true,
        search_keywords: true,
        skip: false,
        sync: true
    },
    {   
        id: 'notes',
        label: 'Notes',
        required: false,
        default: '',
        type: 'string',
        field: 'textarea',
        skip: false,
        sync: true
    },
    {   
        id: 'newsletter',
        label: 'Newsletter',
        required: false,
        default: 'no',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'parent_hear_source',
        label: 'How did you hear about us?',
        required: false,
        default: [],
        type: 'array',
        field: 'tag_input',
        skip: ['update'],
    },

    {   
        id: 'added_on',
        label: 'Added On',
        required: false,
        default: 0,
        type: 'system_date',
        skip: ['update'],
        sync: true
    },
    {   
        id: 'modified_on',
        label: 'Modified On',
        required: false,
        default: 0,
        type: 'system_date',
        skip: false,
        sync: true
    },
    {   
        id: 'last_modified_by',
        label: 'Modified By',
        required: false,
        default: '',
        type: 'system_modified_by',
        skip: false,
        sync: true
    }
];