/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
    GET_STUDENT_PROFILE,
    UPDATE_STUDENT_PROFILE,
    GET_STUDENT_BADGES,
    REMOVE_STUDENT_BADGE,
	GET_BADGE_DETAILS,
	GET_CERT_DETAILS,
	GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getStudentProfile, fb_updateStudentProfile, fb_getStudentBadges, fb_deleteStudentBadge, fb_getCertDetails } from './fb';
import { fb_addParentObverser, fb_removeParentObverser } from '../database_students/fb';


export const getStudentProfile = (uid) => {
	return dispatch => {

		fb_getStudentProfile(uid)
		.then(profile => {
			dispatch({
				type: GET_STUDENT_PROFILE,
				payload: { profile }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_STUDENT_PROFILE,
				payload: { profile: {} }
			});
		})

	}
}

export const updateStudentProfile = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateStudentProfile(formData)
		.then( results => {
			fb_getStudentProfile(formData.uid)
			.then(profile => {
				// push new data
				dispatch({ type: GET_STUDENT_PROFILE, payload: { profile } });	
				// updated
				dispatch({ type: UPDATE_STUDENT_PROFILE });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Profile Updated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const getStudentBadges = (props) => {
	return dispatch => {

		fb_getStudentBadges(props)
		.then(list => {
			dispatch({
				type: GET_STUDENT_BADGES,
				payload: { list }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_STUDENT_BADGES,
				payload: { list: [] }
			});
		})

	}
}

export const removeStudentBadge = (badge_id,uid) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteStudentBadge(badge_id)
		.then( results => {
			fb_getStudentBadges({ uid })
			.then(list => {
				dispatch({ type: GET_STUDENT_BADGES, payload: { list } });	
				dispatch({ type: REMOVE_STUDENT_BADGE });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("Badge Removed!");
			})
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const addParentObverser = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_addParentObverser(formData)
		.then( results => {
			fb_getStudentProfile(formData.uid)
			.then(profile => {
				// push new data
				dispatch({ type: GET_STUDENT_PROFILE, payload: { profile } });	
				// updated
				dispatch({ type: UPDATE_STUDENT_PROFILE });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Parent Observer Added! It might takes up to 5 minutes to reach parent's inbox.");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const removeParentObverser = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_removeParentObverser(formData)
		.then( results => {
			fb_getStudentProfile(formData.uid)
			.then(profile => {
				// push new data
				dispatch({ type: GET_STUDENT_PROFILE, payload: { profile } });	
				// updated
				dispatch({ type: UPDATE_STUDENT_PROFILE });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Parent Observer Removed");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}