/* eslint-disable */
import React, { useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _sortBy from "lodash/sortBy";
import _find from "lodash/find";
import _remove from "lodash/remove";
import _toLower from "lodash/toLower";
import _toString from "lodash/toString";

import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";

import { isArrayExists, isObjectExists } from "../../helpers/validation";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import { cloneCollections, getSelectOptions, convertArrayToObject, convertObjectToArray } from "../../helpers/data";
import { getMomentTime } from "../../helpers/date";

const useStyles = (theme) => ({});

const Wrapper = styled.div`
    padding: 20px 10px;
    border: 2px solid #999;
    background: #f9f9f9;
`;

const ReportPreviewStudentData = ({ selectedStudents = [], trainees = [], schedule = {} }) => {
    const [preview, setPreview] = useState([]);

    useEffect(() => {
        if (isArrayExists(selectedStudents)) {
            setPreview(selectedStudents[0].id);
        }
    }, [selectedStudents]);

    const selectedStudent = useMemo(() => {
        return _find(trainees, { id: preview }) || {};
    }, [preview, trainees]);

    const attendances = useMemo(() => {
        let total = selectedStudent.attendances && isArrayExists(selectedStudent.attendances) ? selectedStudent.attendances.length : 0;
        let attended = 0;
        _forEach(selectedStudent.attendances, (attendance) => {
            if (attendance.attend === "yes") {
                attended++;
            }
        });
        return total > 0 ? `${attended} / ${total}` : "---";
    }, [selectedStudent]);

    return (
        <Wrapper>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">Preview Student Data</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormSelect
                        label="Select Student Email"
                        value={preview}
                        options={getSelectOptions({
                            list: selectedStudents && isArrayExists(selectedStudents) ? selectedStudents : [],
                            options: [],
                            keys: { value: "id", label: "email", sortBy: "label" },
                        })}
                        onChange={(newValue) => {
                            setPreview(newValue);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormInput label="Student Name" value={selectedStudent.name} onChange={() => {}} />
                </Grid>
                <Grid item xs={12}>
                    <FormInput
                        label="Current Program Name"
                        value={schedule.program_name && !_isEmpty(schedule.program_name) ? schedule.program_name : schedule.course_name || ""}
                        onChange={() => {}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormInput label="Attendance" value={attendances || ""} onChange={() => {}} />
                </Grid>
                <Grid item xs={12}>
                    <FormInput multiline={true} rows={4} label="General Feedback" value={selectedStudent.mentor_comments || ""} onChange={() => {}} />
                </Grid>
            </Grid>
        </Wrapper>
    );
};

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
    };
};

export default compose(connect(mapStateToProps), withStyles(useStyles), withRouter)(ReportPreviewStudentData);
