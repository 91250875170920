/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import queryString from 'query-string';
import Tooltip from '@material-ui/core/Tooltip';
import shortid from 'shortid';
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _replace from 'lodash/replace';
import _remove from 'lodash/remove';
import _size from 'lodash/size';
import _toString from 'lodash/toString';

import PaymentForm from './payment_form';

import ScreenLoader from '../../components/ScreenLoader';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormCheckbox from '../../components/FormCheckbox';

import { getAPIErrorMessage } from '../../helpers/action';
import { isArrayExists, validate, checkIfAmountIsValid } from '../../helpers/validation';
import { getSelectedValue, cloneCollections, trimEmail } from '../../helpers/data';
import { getMomentTime, printSemiPrettyDate } from '../../helpers/date';
import { isSkip, isSchemaRequired, doSchemaErrorCheck } from '../../helpers/schemas';
import { triggerErrorAlert } from '../../helpers/alert';
import { formatMoney } from '../../helpers/number';

import { FullScreenFlex, FullScreenComponent } from '../../styles/division';
import { Button, InverseButton, GreyButton, InverseLink, InfoButton, InfoLink, AInverseLink } from '../../styles/button';

import { getPaymentForm } from '../../actions/payment_form';
import { fb_createPaymentForm } from '../../actions/payment_form/fb';
import { toggleLoader } from '../../actions/global';
import { appChangesMade, resetRedux } from '../../actions/misc';

import { paymentSchema } from '../../schemas/payment';

import { PLATFORM_NAME_LONG, DAY_OPTIONS, GENDER_OPTIONS, PLATFORM_WEBSITE_URL, IPAY88_PAYMENT_REQUEST_URL, PLATFORM_PHONE, PLATFORM_EMAIL } from '../../constants';

const MainWrapper = styled.div`
    max-width: 700px;
    margin: 0px auto;
    padding: 60px 20px;
`;

const InfoWrapper = styled.div`
    padding: 0px 20px;
    max-width: 600px;
    color: #999;
    font-size: 12px;

    p {
        margin-bottom: 15px;
        &:last-child { margin-bottom: 0px; }
    }

    a {
        color: ${blue['700']};
        font-weight: 700;
        text-decoration: underline;

        &:hover {
            color: color: ${blue['500']};
        }
    }
`;

const ConfirmWrapper = styled.div`
    padding: 0px 20px;

    p {
        font-size: 18px;
        margin-bottom: 10px;
        strong { font-weight: 700; }
        &:last-child { margin-bottom: 0px }
    }

    h4 {
        margin: 20px 0;
        font-weight: 700;
    }

    button {
        width: 100%;
        max-width: 100%;
        text-align: center;
        padding: 15px 10px;
    }
`;

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    },
    paragraph: {
        fontSize: "16px",
        fontWeight: "400",
        marginBottom: "15px",
    },
    tooltip: { maxWidth: "600px" },
    wrapperpaper: {
        padding: '30px 20px',
        marginBottom: '30px',
        background: '#fff',
        color: theme.palette.background
    },
    table: {
        marginBottom: '30px',
        border: '1px solid ' + theme.palette.background,
        '& tr:last-child > td': {
            borderColor: theme.palette.background
        }
    },
    hcell: {
        width: '30%',
        fontWeight: '700',
        fontSize: '16px',
        verticalAlign: 'top',
        color: theme.palette.background,
    },
    tcell: {
        width: '70%',
        fontWeight: '400',
        fontSize: '14px',
        verticalAlign: 'top',
        color: theme.palette.background,
    },
    whatsapp: {
        display: "inline-block",
        marginLeft: '8px',
        color: "#fff",
        borderRadius: "100%",
        verticalAlign: 'middle',
        fontSize: '16px',
        width: '26px',
        height: '26px',
        lineHeight: '26px',
        textAlign: 'center',
        backgroundColor: "#25D366",
        '&:hover': {
            backgroundColor: "#128C7E"
        }
    }
});

const getDefaultFormValue = (amount) => {
    let form = {};
    _forEach( paymentSchema, schema => {
        if ( !isSkip( schema, 'add' ) ) {
            form[schema.id] = schema.default;
        }
    });
    // set default amount
    form.amount = ( amount && !_isEmpty( amount ) ? amount : '' );
    //*incomplete - remove this
    // form.amount = '1.00';
    return form;
}

const getDefaultState = () => {
    let state = {
        form: getDefaultFormValue(),
        data: false,
        order: false,
        confirm_checkbox: 'no',
        submitted: false,
        error: false,
        randNum: false
    };
    return cloneCollections( state );
}

class PaymentFormPage extends React.Component {

    state = getDefaultState();

    componentDidMount() {
        const { trainee_id, amount } = this.getURLParams();
        // set default amount
        if ( amount && !_isEmpty( amount ) ) {
            this.setState({ form: getDefaultFormValue(amount) });
        }

        this.props.dispatch( getPaymentForm(trainee_id) );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { randNum, paymentError, paymentData, paymentUpdated } = this.props;
        if ( randNum && randNum !== this.state.randNum ) {
            if ( paymentError ) {
                this.setState({ error: paymentError, randNum });
            } else if ( paymentUpdated && paymentUpdated === randNum ) {
                this.setState({ submitted: true, randNum });
            } else if ( paymentData ) {
                this.setState({ 
                    data: paymentData,
                    randNum });
            } 
        } // end - randNum
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('payment_form'));
    }

    handlePaymentRedirect = () => {

    }

    handleFormSubmit = () => {
        const { trainee_id } = this.props.match.params;
        const { data, form } = this.state;
        let error = false,
            formData = {};

        // do error check
        paymentSchema.forEach(schema => {
            switch (schema.id) {
                case 'trainee_id':
                    formData[schema.id] = ( data && data.trainee && data.trainee.id ? data.trainee.id : '' );
                    break;
                case 'schedule_id':
                    formData[schema.id] = ( data && data.schedule && data.schedule.id ? data.schedule.id : '' );
                    break;
                default:
                    formData[schema.id] = ( form && form[schema.id] ? form[schema.id] : schema.default );
                    break;
            }
            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'add' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        // make sure remarks doesn't exceed 100 chars
        if ( formData.remarks && !_isEmpty( formData.remarks ) ) {
            if ( _size( formData.remarks ) > 100 )
                error = "Remarks cannot be exceed more than 100 characters";
        } // end - formData.remarks

        // make sure the amount entered is currency
        if ( !( formData.amount && checkIfAmountIsValid( formData.amount ) ) )
            error = "Please enter a valid amount";

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(toggleLoader(true));
            fb_createPaymentForm( formData )
            .then( order => {
                this.props.dispatch(toggleLoader(false));
                this.setState({ submitted: true, order });
            })
            .catch( error => {
                this.props.dispatch(toggleLoader(false));
                triggerErrorAlert(getAPIErrorMessage(error));
            });
        } // end - error

    }

    getURLParams = () => {
        const { location = {} } = this.props;
        const { id, amt } = queryString.parse(location.search);
        return { 
            trainee_id: ( id && !_isEmpty( id ) && validate(id,'string_id') ? id : '' ),
            amount: ( amt && !_isEmpty( amt ) && validate(amt,'numeric') ? _toString( amt ) : '' )
        };
    }

    renderConfirmForm = () => {
        const { confirm_checkbox, order } = this.state;
        return (
        <ConfirmWrapper>
            <Typography variant="body1"><strong>Name:</strong> {( order && order.payment && order.payment.name && !_isEmpty( order.payment.name ) ? order.payment.name : '-' )}</Typography>
            <Typography variant="body1"><strong>Email:</strong> {( order && order.payment && order.payment.email && !_isEmpty( order.payment.email ) ? order.payment.email : '-' )}</Typography>
            <Typography variant="body1"><strong>Mobile No:</strong> {( order && order.payment && order.payment.phone && !_isEmpty( order.payment.phone ) ? order.payment.phone : '-' )}</Typography>
            <Typography variant="h4" align="center">Total Amount: {( order && order.payment && order.payment.currency && !_isEmpty( order.payment.currency ) ? order.payment.currency : 'MYR' )} { order && order.payment && order.payment.amount ? formatMoney( parseFloat(order.payment.amount,2),2, 3) : '0.00' }</Typography>
            <div style={{ padding: '5px 0 20px 0' }}>
                <FormControlLabel
                control={
                    <Checkbox
                        checked={( confirm_checkbox && confirm_checkbox === 'yes' ? true : false )}
                        onChange={(event) => this.setState({ confirm_checkbox: ( event.target.checked ? 'yes' : 'no' ) })}
                        value={confirm_checkbox}
                        color="primary" />
                }
                label={<>By clicking "Make Payment" button, you hereby agree that you have read and accepted the Consent Agreement & Privacy Policy</>} />
            </div>
            <form method="POST" action={IPAY88_PAYMENT_REQUEST_URL}>
                { order && order.ipay88_form && isArrayExists( order.ipay88_form ) ? order.ipay88_form.map(item => {
                    return <input key={item.name} type="hidden" name={item.name} value={item.value} />
                }) : null }
                <InfoButton type="submit" disabled={( confirm_checkbox && confirm_checkbox === 'yes' ? false : 'yes' )} onClick={() => {
                    this.props.dispatch(toggleLoader(true));
                }}>Make Payment</InfoButton>
            </form>
        </ConfirmWrapper>
        )
    }

    renderPaymentForm = () => {
        const { data, form } = this.state;
        return <PaymentForm 
                { ...data }
                form={( form || false )}
                onUpdate={(newValue) => this.setState({ form: newValue })} />
    }

    renderStudentDetails = () => {
        const { classes } = this.props;
        const { data } = this.state;
        return (
        <Table className={classes.table}>
            <TableBody>
                <TableRow>
                    <TableCell className={classes.hcell}>Pay To</TableCell>
                    <TableCell className={classes.tcell}>{ data && data.region && data.region.label ? data.region.label : '' }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Program Name</TableCell>
                    <TableCell className={classes.tcell}>{ data && data.schedule && data.schedule.is_program_schedule && data.schedule.is_program_schedule === 'yes' ? ( data.schedule.program_name ? data.schedule.program_name : '' ) : ( data.schedule.course_name ? data.schedule.course_name : '' ) }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Student Name</TableCell>
                    <TableCell className={classes.tcell}>{ data && data.user && data.user.name ? data.user.name : ( data && data.trainee && data.trainee.name ? data.trainee.name : '' )}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Student Email</TableCell>
                    <TableCell className={classes.tcell}>{ data && data.user && data.user.email ? data.user.email : ( data && data.trainee && data.trainee.email ? data.trainee.email : '' )}</TableCell>
                </TableRow>
                {/* { data && data.schedule && data.schedule.show_price_per_pax && data.schedule.show_price_per_pax === 'yes' && data.schedule.price_per_pax && !_isEmpty( data.schedule.price_per_pax ) ? (
                    <TableRow>
                        <TableCell className={classes.hcell}>Price Per Pax</TableCell>
                        <TableCell className={classes.tcell}><div dangerouslySetInnerHTML={{ __html: data.schedule.price_per_pax }}/></TableCell>
                    </TableRow>
                ) : null } */}
                {/* <TableRow>
                    <TableCell className={classes.hcell}>Coordinator</TableCell>
                    <TableCell className={classes.tcell}>{ data && data.schedule && data.schedule.coordinator_name && !_isEmpty( data.schedule.coordinator_name ) ? data.schedule.coordinator_name : '-' }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hcell}>Coordinator's Contact</TableCell>
                    <TableCell className={classes.tcell}>{ data && data.schedule && data.schedule.coordinator_phone && !_isEmpty( data.schedule.coordinator_phone ) ? (
                    <>
                        {data.schedule.coordinator_phone}
                        { data.schedule.partner_contact && !_isEmpty( data.schedule.partner_contact ) ? <a href={"https://wa.me/"+data.schedule.partner_contact} target="_blank" className={classes.whatsapp}><i className="fa fa-whatsapp"></i></a> : null }
                    </>
                    ) : '-' }</TableCell>
                </TableRow> */}
            </TableBody>
        </Table>
        );
    }

    renderForm() {
        const { submitted, data } = this.state;
        return (
        <>
            <h1 style={{ fontWeight: "300", fontSize: '32px', textTransform: "uppercase", marginBottom: "20px", textAlign: "center" }}>General Payment</h1>
            <Typography variant="body1" style={{ marginBottom: "30px" }}>This is a payment form for all our customers at Chumbaka. You may use this to pay monthly fee for students.</Typography>

            <div style={{ fontSize: '11px' }}>{ data && data.schedule && data.schedule.class_name ? 'Class Name: '+data.schedule.class_name : '' }</div>
            {this.renderStudentDetails()}
            {this.renderPaymentForm()}
            
            {/* <InfoWrapper>
                <Typography variant="body1">some text here</Typography>
            </InfoWrapper> */}

            <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleFormSubmit}>Pay Now</InfoButton>
            </div>
            
            <ModalView 
                open={submitted}
                title={<div style={{ textAlign: "center", fontSize: "26px" }}>CONFIRM PAYMENT</div>}
                noCancel={true}
                noAction={true}
                disableBackdrop={true}
                contents={this.renderConfirmForm()} />

        </>
        );
    }

    renderContent() {
        const { classes } = this.props;
        return (
        <FullScreenComponent style={{ 
            // background: 'url("/loader_bg.jpg")', backgroundPosition: "center center", backgroundSize: "100%", backgroundRepeat: 'repeat-y', 
            background: '#e5e5e5',
            height: "auto", minHeight: "100vh" }}>
            <MainWrapper>
                <Paper elevation={2} className={classes.wrapperpaper}>
                    <div style={{ margin: "0 auto 30px auto", maxWidth: '175px' }}><img src="/logo.gif" style={{ display: "block", height: 'auto', width: '100%' }} /></div>
                    {this.renderForm()}
                </Paper>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} align="center">
                        <a href="https://www.chumbaka.asia/privacy-policy" target="_blank" style={{ textDecoration: 'underline' }}>Privacy Policy</a>
                    </Grid>
                    <Grid item xs={12} sm={4} align="center">
                        <a href="https://www.chumbaka.asia/class-policies" target="_blank" style={{ textDecoration: 'underline' }}>Terms & Conditions</a>
                    </Grid>
                    <Grid item xs={12} sm={4} align="center">
                        <a href="https://www.chumbaka.asia/contact-us/#LearningCenter" target="_blank" style={{ textDecoration: 'underline' }}>Our Locations</a>
                    </Grid>
                    <Grid item xs={12} sm={6} align="center" style={{ color: '#666' }}>
                        <i className="fa fa-phone" style={{ marginRight: '8px' }}></i>+604 373 0401 
                    </Grid>
                    <Grid item xs={12} sm={6} align="center" style={{ color: '#666' }}>
                        <i className="fa fa-envelope" style={{ marginRight: '8px' }}></i>{PLATFORM_EMAIL}
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography variant="body1" style={{ color: '#666' }}>
                            303-4-8, Block B, Krystal Point, Jalan Sultan Azlan Shah, 11900 Bayan Lepas, Penang<br /><br />
                            © Copyright Chumbaka Sdn Bhd (1065257-D)<br />
                            All rights reserved.
                        </Typography>
                    </Grid>
                </Grid>
            </MainWrapper>
        </FullScreenComponent>
        );
    }

    renderErrorMessage = () => {
        const { classes } = this.props;
        const { error } = this.state;
        return (
        <FullScreenComponent style={{ 
            background: 'url("/loader_bg.jpg")', backgroundPosition: "center center", backgroundSize: "100%", backgroundRepeat: 'repeat-y', 
            height: "auto", minHeight: "100vh" }}>
            <MainWrapper>
                <Paper elevation={2} className={classes.wrapperpaper}>
                    <div style={{ margin: "0 auto 30px auto", maxWidth: '175px' }}><img src="/logo.gif" style={{ display: "block", height: 'auto', width: '100%' }} /></div>
                    <Typography variant="h4" style={{ color: red['700'], textAlign: "center", background: red['50'], padding: '20px', border: '2px solid '+ red['700'] }}>{error}</Typography>
                    <div style={{ textAlign: "center", marginTop: "30px" }}>
                        <AInverseLink href={PLATFORM_WEBSITE_URL} style={{ padding: "15px 5px" }} minWidth="250px"><i className="fa fa-long-arrow-left"></i>Go Back to Website</AInverseLink>
                    </div>
                </Paper>
            </MainWrapper>
        </FullScreenComponent>
        );
    }

    render() {
        const { randNum, error } = this.state;
        return !randNum ? <ScreenLoader /> : ( error ? this.renderErrorMessage() : this.renderContent()  );
    }

}

const mapStateToProps = state => {
    return {
        paymentData: state.payment_form && state.payment_form.data ? state.payment_form.data : null,
        paymentUpdated: state.payment_form && state.payment_form.updated ? state.payment_form.updated : null,
        paymentError: state.payment_form && state.payment_form.error_message ? state.payment_form.error_message : null,
        randNum: state.payment_form && state.payment_form.data ? state.payment_form.rand : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles),
    withRouter
)(PaymentFormPage);