/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _upperFirst from 'lodash/upperFirst';
import _snakeCase from 'lodash/snakeCase';

import Form from './form';

import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormSelect from '../../components/FormSelect';
import FormInput from '../../components/FormInput';

import { InfoButton, ErrorButton, ButtonGroup, SuccessButton } from '../../styles/button';
import { WrapWord } from '../../styles/misc';
import { SuccessTag, AmberTag, GreyTag } from '../../styles/tag';

import { isArrayExists, validateEmail, isAlphanumeric } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectedValue } from '../../helpers/data';
import { doSchemaErrorCheck, getSchemaDefaultValue } from '../../helpers/schemas';
import { isAdmin, hasAccessRights, getUserRegionFilter } from '../../helpers/auth';

import { addTrainingLocation, editTrainingLocation, deleteTrainingLocation } from '../../actions/training_locations';

import { trainingLocationSchema } from '../../schemas/training_location';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});


class LocationsTable extends React.Component {
    
    state = {
        searchterms: '',
        filterBy: 'all',
        sortBy: 'name-asc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalType: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false
    }

    handleAddNew = () => {
        const { authData } = this.props;
        const { modalData } = this.state;
        let error = false,
            formData = {};

        // do error check
        trainingLocationSchema.forEach(schema => {
            formData[schema.id] = ( modalData && modalData[schema.id] ? cloneCollections( modalData[schema.id] ) : schema.default );
            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'add' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        // check for ID error
        if ( !( formData.id && !_isEmpty( formData.id ) && _size( formData.id ) <= 8 ) )
            error = 'Venue ID cannot be more than 8 characters.';

        if ( !( formData.id && !_isEmpty( formData.id ) && isAlphanumeric( formData.id ) ) )
            error = 'Invalid Venue ID - only alphanumeric characters are allowed.';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addTrainingLocation(formData,{ region: getUserRegionFilter(authData) }));
        } // end - error
    }

    handleEdit = () => {
        const { authData } = this.props;
        const { modalData } = this.state;
        let error = false,
            formData = {};

        // do error check
        trainingLocationSchema.forEach(schema => {
            formData[schema.id] = ( modalData && modalData[schema.id] ? cloneCollections( modalData[schema.id] ) : schema.default );
            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editTrainingLocation(modalData,{ region: getUserRegionFilter(authData) }));
        } // end - error
    }

    handleAction = () => {
        const { modalType } = this.state;
        if ( modalType && modalType == 'new' ) {
            this.handleAddNew();
        } else if ( modalType && modalType == 'edit' ) {
            this.handleEdit();
        } // end - modalType
    }

    handleDelete = () => {
        const { authData } = this.props;
        const { deleteModal } = this.state;
        //perform delete
        this.props.dispatch(deleteTrainingLocation(deleteModal.id,{ region: getUserRegionFilter(authData) }));
    }

    getDefaultLocationValue = () => {
        const { authData } = this.props;
        let defaultVal = getSchemaDefaultValue(trainingLocationSchema,'add'),
            val = ( defaultVal && !_isEmpty(defaultVal) ? cloneCollections( defaultVal ) : {} );

        // set region to user's region by default
        if ( authData && authData.region && !_isEmpty( authData.region ) ) {
            val.region = authData.region;
        }

        return val;
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { locations, regions } = this.props;
        var items = ( locations ? cloneCollections( locations ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['id','name','city'] );
			total = _size( items );
        } // end - searchterms

        // do filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy !== 'all' ) {
            items = _filter( items, { region: filterBy } );
            total = _size( items );
        }

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'partners-desc':
                    items = _sortBy( items, [ i => {
                        let region = _find( regions, { id: i.region } );
                        return ( region && region.label ? region.label.toLowerCase() : '' );
                    }] );
                    items = _reverse( items );
                    break;
                case 'partners-asc':
                    items = _sortBy( items, [ i => {
                        let region = _find( regions, { id: i.region } );
                        return ( region &&region.label ? region.label.toLowerCase() : '' );
                    }] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
                case 'id-desc':
                    items = _sortBy( items, ['id'] );
                    items = _reverse( items );
                    break;
                case 'id-asc':
                    items = _sortBy( items, ['id'] );
                    break;
                case 'city-desc':
                    items = _sortBy( items, [ t => t.city.toLowerCase() ] );
                    items = _reverse( items );
                    break;
                case 'city-asc':
                    items = _sortBy( items, [ t => t.city.toLowerCase() ] );
                    break;
                case 'state-desc':
                    items = _sortBy( items, [ t => t.state.toLowerCase() ] );
                    items = _reverse( items );
                    break;
                case 'state-asc':
                    items = _sortBy( items, [ t => t.state.toLowerCase() ] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderViewForm = () => {
        const { authData, regions } = this.props;
        const { modalData, modalType } = this.state;
        return (
        <div>
            <Form
                type={( modalType || false )}
                venue={( modalData || false )}
                authData={authData}
                regions={regions}
                disableSystemDate={true} // hide this for now - since the old date doesn't have timestamp
                onFormUpdate={(newValue) => this.setState({ modalData: newValue })} />
            
            {/* { modalType && modalType == 'edit' ? <div style={{ marginTop: '8px' }}><FormSelect 
                label="Status (Required)" 
                name="status" 
                value={( modalData.status || '' )} 
                disabled={( modalData.status && modalData.status === 'pending' ? true : false )}
                options={ modalData.status && modalData.status === 'pending' ? [{value:'pending',label: 'Pending' }] : [
                    {value:'active',label: 'Active'},
                    {value:'disabled',label: 'Disabled'},
                ]}
                onChange={this.handleFormUpdate} /></div> : null }
            <FormInput label="Name (Required)" name="name" value={( modalData.name || '' )} onChange={this.handleFormUpdate} />
            { modalType && modalType == 'edit' ? null : <FormInput label="Email (Required)" type="email" name="email" value={( modalData.email || '' )} onChange={this.handleFormUpdate} /> }
            <div style={{ paddingTop: '10px' }}><FormSelect 
                label="Role (Required)" 
                name="role" 
                value={( modalData.role || '' )} 
                options={getSelectOptions({ list: roles, options: [{ value: '', label: 'Select an Option' },{ value: 'admin', label: 'Admin' }], keys: { value: 'id', label: 'label' }, sortBy: 'label' })}
                onChange={this.handleFormUpdate} /></div> */}

        </div>
        );
    }

    renderRightButtons = () => {
        const { authData } = this.props;
        let buttons = [];

        if ( authData && hasAccessRights(authData,['tlw']) ) {
            buttons.push( <InfoButton minWidth="128px" style={{ marginRight: '5px' }} key="addnew" onClick={() => this.setState({ openViewModal: true, modalData: this.getDefaultLocationValue(), modalType: 'new' })}><i className="fa fa-plus-circle"></i>Add New</InfoButton> );
        } 
        
        return buttons;
    }

    renderTableActions = () => {
        const { regions, roles, authData } = this.props;
        const { filterBy, sortBy, perPage, searchterms } = this.state;
        return <TableBar
                filterBy={filterBy}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'partners-asc', label: 'Partners ( A - Z)' },
                    { value: 'partners-desc', label: 'Partners ( Z - A )' },
                    { value: 'id-asc', label: 'Venue ID ( A - Z)' },
                    { value: 'id-desc', label: 'Venue ID ( Z - A )' },
                    { value: 'name-asc', label: 'Venue Name ( A - Z)' },
                    { value: 'name-desc', label: 'Venue Name ( Z - A )' },
                    { value: 'city-asc', label: 'City ( A - Z)' },
                    { value: 'city-desc', label: 'City ( Z - A )' },
                    { value: 'state-asc', label: 'State ( A - Z)' },
                    { value: 'state-desc', label: 'State ( Z - A )' },
                ]}
                filterByOptions={ isAdmin( authData ) ? getSelectOptions({ list: regions, options: [{ value: 'all', label: 'All' }], keys: { value: 'id', label: 'label' }, sortBy: 'label' }) : null }
                rightButtons={this.renderRightButtons()}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    doneLoaded={true}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderBody = (items) => {
        const { classes, regions, authData } = this.props;
        return (
        <TableBody>
            { items && isArrayExists( items ) ? items.map(item => {
                var region = ( regions && item.region && !_isEmpty( item.region ) ? _find( regions, { id: item.region } ) : false );
                return (
                <TableRow key={item.id} id={item.id}>
                    <TableCell className={classes.bodycell}>{( region && region.label ? region.label : '' )}</TableCell>
                    <TableCell className={classes.bodycell}><WrapWord>{item.id || ''}</WrapWord></TableCell>
                    <TableCell className={classes.bodycell}><WrapWord>{item.name || ''}</WrapWord></TableCell>
                    <TableCell className={classes.bodycell}><WrapWord>{item.city || ''}</WrapWord></TableCell>
                    <TableCell className={classes.bodycell}><WrapWord>{item.state || ''}</WrapWord></TableCell>

                    <TableCell className={classes.bodycell}>
                        { item.map_link && !_isEmpty( item.map_link ) ? (
                        <CopyToClipboard text={item.map_link}
                            onCopy={() => {
                                triggerSuccessAlert("Link copied to clipboard.");
                            }}>
                            <InfoButton style={{ padding: '5px 10px' }}><i className="fa fa-link"></i>Get Link</InfoButton>
                        </CopyToClipboard>
                        ) : '-' }
                    </TableCell>

                    <TableCell className={classes.bodycell}>
                        { item.guide_to_venue && !_isEmpty( item.guide_to_venue ) ? (
                        <CopyToClipboard text={item.guide_to_venue}
                            onCopy={() => {
                                triggerSuccessAlert("Link copied to clipboard.");
                            }}>
                            <InfoButton style={{ padding: '5px 10px' }}><i className="fa fa-link"></i>Get Link</InfoButton>
                        </CopyToClipboard>
                        ) : '-' }
                    </TableCell>

                    <TableCell className={classes.bodycell}>{ item.publish && item.publish == 'yes' ? <SuccessTag>Yes</SuccessTag> : <GreyTag>No</GreyTag> }</TableCell>
                    
                    <TableCell className={classes.bodycell}>
                        <ButtonGroup>
                            <InfoButton size="small" onClick={() => this.setState({ openViewModal: true, modalData: item, modalType: 'edit' })}><i className="fa fa-edit"></i>Edit</InfoButton>
                            { hasAccessRights(authData,['tld']) ? <ErrorButton size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-archive"></i>Unpublish & Archive</ErrorButton> : null }
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
                )
            }) : (
                <TableRow>
                    <TableCell className={classes.bodycell}>No List(s) found.</TableCell>
                </TableRow>
            ) }
        </TableBody>
        )
    }

    renderHeader = () => {
        const { classes } = this.props;
        return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headcell}>Partner</TableCell>
                <TableCell className={classes.headcell}>Venue ID</TableCell>
                <TableCell className={classes.headcell} style={{ width: "15%" }}>Venue Name</TableCell>
                <TableCell className={classes.headcell}>City</TableCell>
                <TableCell className={classes.headcell}>State</TableCell>
                <TableCell className={classes.headcell}>Google Map Link</TableCell>
                <TableCell className={classes.headcell}>Guide to Venue</TableCell>
                <TableCell className={classes.headcell}>Publish to Website</TableCell>
                <TableCell className={classes.headcell} style={{ width: "20%" }}>Actions</TableCell>
            </TableRow>
        </TableHead>
        )
    }

    render() {
        const { openDeleteModal, deleteModal, openViewModal, modalType } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={openViewModal}
                title={ modalType && modalType == 'edit' ? "Edit Training Venue" : "Add New Training Venue" }
                actionLabel={ modalType && modalType == 'edit' ? "Update" : "Add New" }
                maxWidth="md"
                onClose={() => this.setState({ openViewModal: false, modalType: false, modalData: false })}
                doAction={this.handleAction}
                contents={this.renderViewForm()} />

            <ModelDelete
                open={openDeleteModal}
                deleteLabel="Unpublish & Archive"
                deletingLabel="Processing..."
                title={( deleteModal && deleteModal.name ? `Are you sure you want to unpublish & archive this venue ( ${deleteModal.name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            {this.renderTableActions()}
            <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                <Table>
                    {this.renderHeader()}
                    {this.renderBody(items)}
                </Table>
            </Paper>
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(LocationsTable);