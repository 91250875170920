/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';

import StudentFields from './student_fields';
import PreviewForm from '../CourseRegistrationPage/student_form';

import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormRadio from '../../components/FormRadio';
import FormMultiOptions from '../../components/FormMultiOptions';

import { FormBox } from '../../styles/form';
import { InfoButton } from '../../styles/button';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';

import { TEMPLATE_OPTIONS_TYPE, YES_NO_OPTIONS, REGISTRATION_FORM_TYPES } from '../../constants';

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

class EditRegistrationForm extends React.Component {

    state = {
        openPreview: false,
        randNum: false
    };

    handleFormUpdate = ( newValue, key ) => {
        const { onFormUpdate, registration_form } = this.props;
        let newData = ( registration_form && !_isEmpty( registration_form ) ? cloneCollections( registration_form ) : {} );

        switch( key ) {
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    render = () => {
        const { classes, registration_form, races, districts } = this.props;
        const { openPreview } = this.state;
        return (
        <>
            <FormBox>
                <Grid container spacing={3}>

                    <Grid item xs={12} align="right">
                        <InfoButton style={{ padding: '5px 15px' }} onClick={() => this.setState({ openPreview: true })}>Preview</InfoButton>
                    </Grid>

                    <Grid item xs={12}>
                        <FormRadio label="Status *" name="status" inline={true} value={( registration_form.status || '' )} options={[
                            { value: 'active', label: 'Active' },
                            { value: 'disabled', label: 'Disabled' }
                        ]} onChange={this.handleFormUpdate} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormInput label="Label *" name="label" value={( registration_form.label || '' )} onChange={this.handleFormUpdate} />
                    </Grid>
                    <Grid item xs={12}> 
                        <StudentFields
                            name="fields"
                            label="Student Form Field(s)"
                            value={( registration_form.fields && isArrayExists( registration_form.fields ) ? _sortBy( registration_form.fields, ['position'] ) : [] )}
                            moveable={true}
                            onChange={this.handleFormUpdate} />
                    </Grid>
                    
                </Grid>
            </FormBox>

            <ModalView
                open={openPreview}
                title={"Preview Mode"}
                onClose={() => this.setState({ openPreview: false })}
                disableBackdrop={true}
                noAction={true}
                cancelLabel="Close"
                maxWidth="md"
                contents={(
                <>
                    <PreviewForm
                        student={{}}
                        trainees={[]}
                        schedule={{
                            registration_form_fields: ( registration_form.fields && isArrayExists( registration_form.fields ) ? _sortBy( registration_form.fields, ['position'] ) : [] ),
                            races_options: races,
                            districts_options: districts
                        }}
                        onUpdate={() => { /* do nothing */ }}
                        />
                </>
                )} />
        </>
        );
    }

}

export default compose(
    withStyles(useStyles),
    withRouter
)(EditRegistrationForm);