/* eslint-disable */
export const SAMPLE_ACTION = "sample_action";

/* auth */
export const IS_LOGGED_IN = "is_logged_in";
export const AUTH_LOGIN = "auth_login";
export const AUTH_LOGOUT = "auth_logout";
export const AUTH_GET_PROFILE = "auth_get_profile";
export const AUTH_EDIT_PROFILE = "auth_edit_profile";

/* users actions */
export const GET_USERS = "get_users";
export const GET_USERS_OPTIONS = "get_users_options";
export const ADD_USER = "add_user";
export const EDIT_USER = "edit_user";
export const DELETE_USER = "delete_user";

/* partners actions */
export const GET_PARTNERS = "get_partners";
export const GET_PARTNERS_OPTIONS = "get_partners_options";
export const GET_PARTNER = "get_partner";
export const ADD_PARTNER = "add_partner";
export const EDIT_PARTNER = "edit_partner";
export const DELETE_PARTNER = "delete_partner";
export const RESET_PARTNER = "reset_partner";
export const RESET_PARTNERS = "reset_partners";

/* personnel actions */
export const GET_PERSONNELS = "get_personnels";
export const GET_PERSONNEL = "get_personnel";
export const ADD_PERSONNEL = "add_personnel";
export const EDIT_PERSONNEL = "edit_personnel";
export const DELETE_PERSONNEL = "delete_personnel";
export const RESET_PERSONNEL = "reset_personnel";
export const RESET_PERSONNELS = "reset_personnels";

/* training locations actions */
export const GET_TRAINING_LOCATIONS = "get_training_locations";
export const GET_TRAINING_LOCATIONS_OPTIONS = "get_training_locations_options";
export const ADD_TRAINING_LOCATION = "add_training_location";
export const EDIT_TRAINING_LOCATION = "edit_training_location";
export const DELETE_TRAINING_LOCATION = "delete_training_location";
export const RESET_TRAINING_LOCATIONS = "reset_training_locations";

/* courses actions */
export const GET_COURSES = "get_courses";
export const GET_COURSES_OPTIONS = "get_courses_options";
export const GET_PRODUCTS_OPTIONS = "get_products_options";
export const GET_COURSE = "get_course";
export const ADD_COURSE = "add_course";
export const EDIT_COURSE = "edit_course";
export const DELETE_COURSE = "delete_course";
export const RESET_COURSE = "reset_course";
export const RESET_COURSES = "reset_courses";

export const GET_BADGES = "get_badges";
export const GET_BADGES_ALL = "get_badges_all";
export const GET_BADGES_ONE_OFF = "get_badges_one_off";
export const ADD_BADGE = "add_badge";
export const EDIT_BADGE = "edit_badge";
export const DELETE_BADGE = "delete_badge";
export const RESET_BADGES = "reset_badges";
export const RESET_BADGES_ONE_OFF = "reset_badges_one_off";

/* programs actions */
export const GET_PROGRAMS = "get_programs";
export const GET_PROGRAMS_OPTIONS = "get_programs_options";
export const GET_PROGRAMS_BY_PRODUCT = "get_programs_by_product";
export const GET_PROGRAM = "get_program";
export const ADD_PROGRAM = "add_program";
export const EDIT_PROGRAM = "edit_program";
export const DELETE_PROGRAM = "delete_program";
export const RESET_PROGRAM = "reset_program";
export const RESET_PROGRAMS = "reset_programs";
export const RESET_PROGRAMS_BY_PRODUCT = "reset_programs_by_product";

/* schedules actions */
export const GET_SCHEDULES = "get_schedules";
export const GET_SCHEDULES_OPTIONS = "get_schedules_options";
export const GET_SCHEDULE = "get_schedule";
export const ADD_SCHEDULE = "add_schedule";
export const EDIT_SCHEDULE = "edit_schedule";
export const DELETE_SCHEDULE = "delete_schedule";
export const RESET_SCHEDULE = "reset_schedule";
export const RESET_SCHEDULES = "reset_schedules";
export const RESET_SCHEDULES_OPTIONS = "reset_schedules_options";
export const UPDATE_SCHEDULE_RAND = "update_schedule_rand";
export const GET_GOOGLE_CALENDAR = "get_google_calendar";

/* class actions */
export const GET_CLASS_CONFIRMATION = "get_class_confirmation";
export const UPDATE_CLASS_CONFIRMATION = "update_class_confirmation";
export const GET_UNRECONCILIATION_PAYMENTS = "get_unreconciliation_payments";
export const UPDATE_CLASS_PAYMENT_TRACKING = "update_class_payment_tracking";
export const UPDATE_CLASS_COURSE_ACCESS = "update_class_course_access";
export const UPDATE_CLASS_ATTENDANCE_REPORTING = "update_class_attendance_reporting";
export const GET_CLASS_SHOWCASE = "get_class_showcase";
export const UPDATE_CLASS_SHOWCASE = "update_class_showcase";
export const GET_CLASS_QUARTERLY_REPORT = "get_class_quarterly_report";
export const UPDATE_CLASS_QUARTERLY_REPORT = "update_class_quarterly_report";
export const TRIGGER_CLASS_QUARTERLY_REPORT = "trigger_class_quarterly_report";
export const PREVIEW_CLASS_QUARTERLY_REPORT = "preview_class_quarterly_report";
export const TRIGGER_CLASS_EMAIL = "trigger_class_email";
export const RESET_CLASS = "reset_class";

/* student database actions */
export const DATABASE_STUDENTS_LIST = "database_students_list";
export const DATABASE_STUDENTS_GET_DATA = "database_students_get_data";
export const DATABASE_STUDENTS_UPDATE = "database_students_update";
export const DATABASE_STUDENTS_DELETE = "database_students_delete";
export const RESET_DATABASE_STUDENTS = "reset_database_students";

/* trainees actions */
export const GET_TRAINEES = "get_trainees";
export const ADD_TRAINEE = "add_trainee";
export const EDIT_TRAINEE = "edit_trainee";
export const DELETE_TRAINEE = "delete_trainee";
export const RESET_TRAINEES = "reset_trainees";

/* email templates actions */
export const GET_EMAIL_TEMPLATES = "get_email_templates";
export const GET_EMAIL_TEMPLATE = "get_email_template";
export const ADD_EMAIL_TEMPLATE = "add_email_template";
export const EDIT_EMAIL_TEMPLATE = "edit_email_template";
export const DELETE_EMAIL_TEMPLATE = "delete_email_template";
export const RESET_EMAIL_TEMPLATES = "reset_email_templates";
export const RESET_EMAIL_TEMPLATE = "reset_email_template";

/* media library */
export const GET_MEDIA_LIBRARY = "get_media_library";
export const UPLOAD_NEW_MEDIA = "upload_new_media";
export const DELETE_MEDIA = "delete_media";

/* Canvas LMS actions */
export const CLMS_GET_COURSES = "clms_get_courses";

/* maintenance actions */
export const M_GET_REGIONS = "m_get_regions";
export const M_REGIONS_UPDATED = "m_regions_updated";
export const M_GET_ROLES = "m_get_roles";
export const M_ROLES_UPDATED = "m_roles_updated";

export const M_GET_DISTRICTS = "m_get_districts";
export const M_GET_RACES = "m_get_races";
export const M_GET_SCHOOLS = "m_get_schools";
export const M_GET_DESIGNATIONS = "m_get_designations";
export const M_GET_CONTACT_HOUR = "m_get_contact_hour";
export const M_GET_PRODUCT_LEVEL = "m_get_product_level";
export const M_GET_PRODUCT_CREDIT = "m_get_product_credit";
export const M_GET_PRICE_PER_PAX = "m_get_price_per_pax";
export const M_GET_COURSE_TYPE = "m_get_course_type";
export const M_GET_COURSE_DURATION = "m_get_course_duration";
export const M_GET_PAYMENT_METHOD = "m_get_payment_method";
export const M_GET_CURRENCY = "m_get_currency";
export const M_GET_FEEDBACK_FORMS = "m_get_feedback_forms";
export const M_GET_FEEDBACK_FORMS_FIELDS = "m_get_feedback_forms_fields";
export const M_GET_FEEDBACK_FORM = "m_get_feedback_form";
export const M_FEEDBACK_FORM_UPDATED = "m_feedback_form_updated";
export const M_GET_REGISTRATION_FORMS = "m_get_registration_forms";
export const M_GET_REGISTRATION_FORMS_FIELDS = "m_get_registration_forms_fields";
export const M_GET_REGISTRATION_FORM = "m_get_registration_form";
export const M_REGISTRATION_FORM_UPDATED = "m_registration_form_updated";
export const M_GET_BADGE_TYPE = "m_get_badge_type";
export const M_GET_EMAIL_TEMPLATES = "m_get_email_templates";
export const M_GET_RUBRICS = "m_get_rubrics";
export const M_GET_CERTIFICATES = "m_get_certificates";
export const M_GET_CERTIFICATE = "m_get_certificate";
export const M_CERTIFICATE_UPDATED = "m_certificate_updated";
export const MAINTENANCE_UPDATED = "maintenance_updated";
export const RESET_FEEDBACK_FORM = "reset_feedback_form";
export const RESET_REGISTRATION_FORM = "reset_registration_form";
export const RESET_CERTIFICATE = "reset_certificate";

/* Students actions */
export const GET_STUDENT_PROFILE = "get_student_profile";
export const UPDATE_STUDENT_PROFILE = "update_student_profile";
export const GET_STUDENT_BADGES = "get_student_badges";
export const REMOVE_STUDENT_BADGE = "remove_student_badge";
export const GET_BADGE_DETAILS = "get_badge_details";
export const GET_CERT_DETAILS = "get_cert_details";
export const RESET_STUDENT = "reset_student";

/* Students showcase actions */
export const STUDENT_SHOWCASE_GET_FEEDBACK_FORM = "student_showcase_get_feedback_form";
export const STUDENT_SHOWCASE_UPDATE_FEEDBACK_FORM = "student_showcase_update_feedback_form";
export const STUDENT_SHOWCASE_GET_DATES = "student_showcase_get_dates";
export const STUDENT_SHOWCASE_UPDATE_DATE = "student_showcase_update_date";
export const STUDENT_SHOWCASE_GET_PROJECT = "student_showcase_get_project";
export const STUDENT_SHOWCASE_UPDATE_PROJECT = "student_showcase_update_project";
export const STUDENT_SHOWCASE_GET_TEAM_PROJECT = "student_showcase_get_team_project";
export const STUDENT_SHOWCASE_UPDATE_TEAM_PROJECT = "student_showcase_update_team_project";
export const RESET_STUDENT_SHOWCASE = "reset_student_showcase";

/* Mentors actions */
export const GET_MENTORS = "get_mentors";
export const GET_MENTOR = "get_mentor";
export const ADD_MENTOR = "add_mentor";
export const UPDATE_MENTOR = "update_mentor";
export const DELETE_MENTOR = "delete_mentor";
export const RESET_MENTOR = "reset_mentor";
export const MENTOR_GET_CLASSES = "mentor_get_classes";
export const MENTOR_GET_CLASS_ATTENDANCES = "mentor_get_class_attendances";
export const MENTOR_UPDATE_CLASS_ATTENDANCES = "mentor_update_class_attendances";

/* marketing database actions */
export const DATABASE_MARKETING_LIST = "database_marketing_list";
export const DATABASE_MARKETING_ADD = "database_marketing_add";
export const DATABASE_MARKETING_UPDATE = "database_marketing_update";
export const DATABASE_MARKETING_DELETE = "database_marketing_delete";
export const RESET_DATABASE_MARKETING = "reset_database_marketing";

/* HQ summary actions */
export const GET_HQ_SUMMARY = "get_hq_summary";
export const UPDATE_COURSE_ACCESS_REPORT = "update_course_access_report";
export const RESET_HQ_SUMMARY = "reset_hq_summary";

/* course registration actions */
export const GET_COURSE_REGISTRATION = "get_course_registration";
export const GET_COURSE_REGISTRATION_ERROR = "get_course_registration_error";
export const DO_COURSE_REGISTRATION = "do_course_registration";
export const RESET_COURSE_REGISTRATION = "reset_course_registration";

/* payment form actions */
export const GET_PAYMENT_FORM = "get_payment_form";
export const CREATE_PAYMENT_FORM = "create_payment_form";
export const PAYMENT_FORM_ERROR = "payment_form_error";
export const RESET_PAYMENT_FORM = "reset_payment_form";

/* notifications actions */
export const GET_NOTIFICATIONS = "get_notifications";
export const UPDATE_NOTIFICATION_DATE_RANGE = "update_notification_date_range";

/* google fonts actions */
export const GET_GOOGLE_FONTS = "get_google_fonts";

/* global actions */
export const GLOBAL_MODAL_PROCESSING = "global_modal_processing";
export const GLOBAL_MODAL_DELETING = "global_modal_deleting";
export const GLOBAL_ERROR_TRIGGER = "global_error_trigger";
export const GLOBAL_LOADER_START = "global_loader_start";
export const GLOBAL_LOADER_END = "global_loader_end";
export const GLOBAL_SNACKBAR_TRIGGER = "global_snackbar_trigger";

/* MISC actions */
export const MISC_CHANGES_SAVED = "misc_changes_saved";
export const MISC_CHANGES_RESET = "misc_changes_reset";
export const MISC_CHANGES_NOT_SAVED = "misc_changes_not_saved";
