/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Papa from 'papaparse';
import styled from "styled-components";
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';
import _trim from 'lodash/trim';

import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
// import ModelDelete from '../../components/ModalDelete';
// import ModalView from '../../components/ModalView';
// import FormRadio from '../../components/FormRadio';
// import FormInput from '../../components/FormInput';
// import FormSelect from '../../components/FormSelect';

import { InfoButton, ErrorButton, ButtonGroup, InverseButton } from '../../styles/button';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, getSelectOptions, doArraySearch } from '../../helpers/data';
// import { getMomentTime } from '../../helpers/date';
import { isAdmin, hasAccessRights } from '../../helpers/auth';

import { addNewMaintenance, updateMaintenance, deleteMaintenance } from '../../actions/maintenance';

import { STATE_OPTIONS } from '../../data/const_states';
import { getMomentTime } from '../../helpers/date';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});

const ActiveTag = styled.div`
    display: inline-block;
    padding: 5px 8px;
    margin: 0px 5px 5px 0px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 6px;
    color: #fff;
    background: ${props => (props.active && props.active == 'yes' ? green['500'] : grey['500'])};
`;

class List extends React.Component {
    
    state = {
        sortBy: 'created_on-desc',
        perPage: 20,
        page: 1,
    }

    doCSVDownload = (results) => {
        let csv = Papa.unparse( results, { delimiter: ',' } );
        let blob = new Blob( [csv] , { type: 'text/csv;charset=utf-8;' }); //new way
        let uri = window.URL.createObjectURL(blob);
        let link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", '_blank');
        link.setAttribute("download", "export_payment_history.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    handleCSVDownload = () => {
        const { list } = this.props;
        let csvData = {
                fields: [
                    "Partner",
                    "Student",
                    "Class",
                    "ID",
                    "Status",
                    "iPay88 Transaction ID",
                    "Amount Paid",
                    "Date",
                    "Name",
                    "Email",
                    "Contact No.",
                    "Remarks"
                ],
                data: []
            };

        _forEach( list, item => {
            if ( item && !_isEmpty( item ) ) {
                let row = [];
                row.push( ( item && item.partner_name || '' ) );
                row.push( ( item && item.student_name || '' ) );
                row.push( ( item && item.class_name || '' ) );
                row.push( ( item && item.id || '' ) );
                row.push( ( item && item.status || '' ) );
                row.push( ( item && item.ip_transid || '' ) );
                row.push( ( item && item.ip_ecurrency || '' ) + ( item && item.ip_amount || '' ) );
                row.push( 'success' === item.status ? getMomentTime( item.updated_on, 'YYYY-MMM-DD hh:mma' ) : getMomentTime( item.created_on, 'YYYY-MMM-DD hh:mma' ) );
                row.push( ( item && item.name || '' ) );
                row.push( ( item && item.email || '' ) );
                row.push( ( item && item.phone || '' ) );
                row.push( ( item && item.remarks || '' ) );

                csvData.data.push(row);
            } // end - trainee
        });
        this.doCSVDownload(csvData);
    }

    reorganizeData() {
        const { sortBy, perPage, page, searchterms } = this.state;
        const { list } = this.props;
        var items = ( list ? cloneCollections( list ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','email','phone','student_name','class_name','partner_name'] );
			total = _size( items );
        } // end - searchterms

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'student_name-desc':
                    items = _sortBy( items, [ i => i.student_name && i.student_name.toLowerCase() || '' ] );
                    items = _reverse( items );
                    break;
                case 'student_name-asc':
                    items = _sortBy( items, [ i => i.student_name && i.student_name.toLowerCase() || '' ] );
                    break;
                case 'class_name-desc':
                    items = _sortBy( items, [ i => i.class_name && i.class_name.toLowerCase() || '' ] );
                    items = _reverse( items );
                    break;
                case 'class_name-asc':
                    items = _sortBy( items, [ i => i.class_name && i.class_name.toLowerCase() || '' ] );
                    break;
                case 'partner_name-desc':
                    items = _sortBy( items, [ i => i.partner_name && i.partner_name.toLowerCase() || '' ] );
                    items = _reverse( items );
                    break;
                case 'partner_name-asc':
                    items = _sortBy( items, [ i => i.partner_name && i.partner_name.toLowerCase() || '' ] );
                    break;
                case 'created_on-desc':
                    items = _sortBy( items, ['created_on'] );
                    items = _reverse( items );
                    break;
                case 'created_on-asc':
                    items = _sortBy( items, ['created_on'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderTableActions = () => {
        const { sortBy, perPage, searchterms } = this.state;
        return <TableBar
                // show={['sort','entries']}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'created_on-desc', label: 'Most recent first' },
                    { value: 'created_on-asc', label: 'Oldest entries first' },
                    { value: 'partner_name-asc', label: 'Partner ( A - Z)' },
                    { value: 'partner_name-desc', label: 'Partner ( Z - A )' },
                    { value: 'class_name-asc', label: 'Class Name ( A - Z)' },
                    { value: 'class_name-desc', label: 'Class Name ( Z - A )' },
                    { value: 'student_name-asc', label: 'Student Name ( A - Z)' },
                    { value: 'student_name-desc', label: 'Student Name ( Z - A )' },
                    // { value: 'position-asc', label: 'Position (smallest number first)' },
                    // { value: 'position-desc', label: 'Position (biggest number first)' }
                ]}
                rightButtons={[
                    <InfoButton minWidth="168px" key="csv" style={{ marginRight: "5px", padding: '10px 25px' }} onClick={this.handleCSVDownload}><i className="fa fa-file-excel-o"></i>Download Table Data as CSV</InfoButton>
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    doneLoaded={true}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderBody = (items) => {
        const { classes, authData } = this.props;
        return (
        <TableBody>
            { items && isArrayExists( items ) ? items.map(item => {
                return (
                <TableRow key={item.id}>
                    <TableCell className={classes.bodycell}><WrapWord>{item.partner_name || ''}</WrapWord></TableCell>
                    <TableCell className={classes.bodycell}><a href={"/student_database/"+item.student_id} target="_blank"><WrapWord>{( item.student_name || '' )}</WrapWord></a></TableCell>
                    <TableCell className={classes.bodycell}><WrapWord>{item.class_name || ''}</WrapWord></TableCell>
                    <TableCell className={classes.bodycell}>{item.id || ''}</TableCell>
                    <TableCell className={classes.bodycell}>{item.status || ''}</TableCell>
                    <TableCell className={classes.bodycell}><WrapWord>{item.ip_transid || ''}</WrapWord></TableCell>
                    <TableCell className={classes.bodycell}><WrapWord>{item.ip_ecurrency || '' } {item.ip_amount || ''}</WrapWord></TableCell>
                    <TableCell className={classes.bodycell}><WrapWord>{ 'success' === item.status ? getMomentTime( item.updated_on, 'YYYY-MMM-DD hh:mma' ) : getMomentTime( item.created_on, 'YYYY-MMM-DD hh:mma' )}</WrapWord></TableCell>
                    <TableCell className={classes.bodycell}><WrapWord>{item.name || ''}</WrapWord></TableCell>
                    <TableCell className={classes.bodycell}><WrapWord>{item.email || ''}</WrapWord></TableCell>
                    <TableCell className={classes.bodycell}><WrapWord>{item.phone || ''}</WrapWord></TableCell>
                    <TableCell className={classes.bodycell}><WrapWord>{item.remarks || ''}</WrapWord></TableCell>
                </TableRow>
                )
            }) : (
                <TableRow>
                    <TableCell className={classes.bodycell}>No item(s) found.</TableCell>
                </TableRow>
            ) }
        </TableBody>
        )
    }

    renderHeader = () => {
        const { classes } = this.props;
        return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headcell}>Partner</TableCell>
                <TableCell className={classes.headcell}>Student</TableCell>
                <TableCell className={classes.headcell}>Class</TableCell>
                <TableCell className={classes.headcell}>ID</TableCell>
                <TableCell className={classes.headcell}>Status</TableCell>
                <TableCell className={classes.headcell}>iPay88 Transaction ID</TableCell>
                <TableCell className={classes.headcell}>Amount Paid</TableCell>
                <TableCell className={classes.headcell}>Date</TableCell>
                <TableCell className={classes.headcell}>Name</TableCell>
                <TableCell className={classes.headcell}>Email</TableCell>
                <TableCell className={classes.headcell}>Contact No.</TableCell>
                <TableCell className={classes.headcell}>Remarks</TableCell>
                {/* <TableCell className={classes.headcell}>Actions</TableCell> */}
            </TableRow>
        </TableHead>
        )
    }

    render() {
        const { items, total } = this.reorganizeData();
        return (
        <div>

            {this.renderTableActions()}
            <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                <Table>
                    {this.renderHeader()}
                    {this.renderBody(items)}
                </Table>
            </Paper>
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(List);