/* eslint-disable */
import _random from 'lodash/random';

import {
	M_GET_DISTRICTS,
	M_GET_RACES,
	M_GET_SCHOOLS,
    M_GET_DESIGNATIONS,
    M_GET_COURSE_TYPE,
    M_GET_COURSE_DURATION,
	M_GET_PAYMENT_METHOD,
	M_GET_CURRENCY,
    M_GET_FEEDBACK_FORMS,
	M_GET_FEEDBACK_FORM,
    M_GET_REGISTRATION_FORMS,
	M_GET_REGISTRATION_FORM,
	M_GET_BADGE_TYPE,
	M_GET_EMAIL_TEMPLATES,
	M_GET_RUBRICS,
	M_GET_CERTIFICATES,
	M_GET_CONTACT_HOUR,
	M_GET_PRODUCT_LEVEL,
	M_GET_PRODUCT_CREDIT,
	M_GET_PRICE_PER_PAX,
	MAINTENANCE_UPDATED,
} from '../types';

import { 
	toggleModalDeleting, toggleModalProcessing, 
	toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getAPIErrorMessage } from '../../helpers/action';
// import { appChangesReset } from '../misc';

import { fb_getMaintenance, fb_addMaintenance, fb_updateMaintenance, fb_deleteMaintenance } from './fb';

export const getMaintenance = (type) => {
	return dispatch => {

		fb_getMaintenance(type,list => {
			switch( type ) {
				case 'designation':
					dispatch({ type: M_GET_DESIGNATIONS, payload: { list } });
					break;
				case 'course_type':
					dispatch({ type: M_GET_COURSE_TYPE, payload: { list } });
					break;
				case 'course_duration':
					dispatch({ type: M_GET_COURSE_DURATION, payload: { list } });
					break;
				case 'payment_method':
					dispatch({ type: M_GET_PAYMENT_METHOD, payload: { list } });
					break;
				case 'currency':
					dispatch({ type: M_GET_CURRENCY, payload: { list } });
					break;
				case 'feedback_forms':
					dispatch({ type: M_GET_FEEDBACK_FORMS, payload: { list } });
					break;
				case 'registration_forms':
					dispatch({ type: M_GET_REGISTRATION_FORMS, payload: { list } });
					break;
				case 'badge_type':
					dispatch({ type: M_GET_BADGE_TYPE, payload: { list } });
					break;
				case 'email_templates':
					dispatch({ type: M_GET_EMAIL_TEMPLATES, payload: { list } });
					break;
				case 'rubrics':
					dispatch({ type: M_GET_RUBRICS, payload: { list } });
					break;	
				case 'contact_hour':
					dispatch({ type: M_GET_CONTACT_HOUR, payload: { list } });
					break;
				case 'product_level':
					dispatch({ type: M_GET_PRODUCT_LEVEL, payload: { list } });
					break;
				case 'product_credit':
					dispatch({ type: M_GET_PRODUCT_CREDIT, payload: { list } });
					break;
				case 'price_per_pax':
					dispatch({ type: M_GET_PRICE_PER_PAX, payload: { list } });
					break;
				case 'certificates':
					dispatch({ type: M_GET_CERTIFICATES, payload: { list } });
					break;
				case 'districts':
					dispatch({ type: M_GET_DISTRICTS, payload: { list } });
					break;
				case 'races':
					dispatch({ type: M_GET_RACES, payload: { list } });
					break;
				case 'schools':
					dispatch({ type: M_GET_SCHOOLS, payload: { list } });
					break;
			}
		});

	}
}

export const addNewMaintenance = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addMaintenance(formData)
		.then( results => {
			dispatch({ type: MAINTENANCE_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Item Added");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const updateMaintenance = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_updateMaintenance(formData)
		.then( results => {
			dispatch({ type: MAINTENANCE_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Item Updated");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const deleteMaintenance = (id,type) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteMaintenance(id,type)
		.then( results => {
			dispatch({ type: MAINTENANCE_UPDATED });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Item Deleted");
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}