/* eslint-disable */
import _now from 'lodash/now';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _last from 'lodash/last';
import _size from 'lodash/size';
import _split from 'lodash/split';
import _find from 'lodash/find';
import _toString from 'lodash/toString';
import _endsWith from 'lodash/endsWith';
import moment from 'moment';

import { isTimeStamp, isArrayExists } from './validation';
import { replaceAll, combineStringsBySeparator } from './data';

import { MONTH_OPTIONS } from '../constants';

/* convert value into date using moment */
export const getMomentTime = ( value, format , offset ) => {
    var utc = ( offset ? offset : 8 );
    return ( value ? moment(value).utcOffset(utc).format(format) : '' );
}

/* convert now into date using moment */
export const getMomentNow = ( format , offset ) => {
    var utc = ( offset ? offset : 8 );
    return moment().utcOffset(utc).format(format);
}

/* convert use moment to convert whatever value to timestamp */
export const getMomentTimestamp = ( value , offset ) => {
    var utc = ( offset ? offset : 8 );
    return moment(value).utcOffset(utc).valueOf();
}

/* compare time from x using moment */
export const compareTimeFromMoment = ( value, offset ) => {
    var utc = ( offset ? offset : 8 );
    return ( value ? moment().utcOffset(utc).from(value, true) : '' );
}

export const printSemiPrettyDate = function( dates ) {
    let sessionData = [],
        count = 1,
        startDate = false,
        endDate = false,
        label = '';
    
    if ( isArrayExists( dates ) ) {
        _forEach(dates,function(date) {
            if ( date.start_date && date.end_date ) {
                startDate = {
                    date: getMomentTime( date.start_date, 'YYYY-MM-DD' ),
                    day_num: parseInt( getMomentTime( date.start_date, 'D' ), 10 ), 
                    month_num: parseInt( getMomentTime( date.start_date, 'M' ), 10 ),
                    year_num: parseInt( getMomentTime( date.start_date, 'YYYY' ), 10 ),
                    day: getMomentTime( date.start_date, 'D' ), 
                    month: getMomentTime( date.start_date, 'MMMM' ), 
                    year: getMomentTime( date.start_date, 'YYYY' )
                };
                endDate = {
                    date: getMomentTime( date.end_date, 'YYYY-MM-DD' ),
                    day_num: parseInt( getMomentTime( date.end_date, 'D' ), 10 ), 
                    month_num: parseInt( getMomentTime( date.end_date, 'M' ), 10 ),
                    year_num: parseInt( getMomentTime( date.end_date, 'YYYY' ), 10 ),
                    day: getMomentTime( date.end_date, 'D' ), 
                    month: getMomentTime( date.end_date, 'MMMM' ), 
                    year: getMomentTime( date.end_date, 'YYYY' )
                };
            } // end - date
        });
    }

    if ( startDate && endDate ) {

        let start_label = '',
            end_label = '';

        start_label += startDate.day;

        if ( startDate.date == endDate.date ) {
            // if is the same day
            start_label += ' ' + startDate.month + ' ' + startDate.year;
        } else if ( startDate.month == endDate.month && startDate.year == endDate.year ) {
            // if is the same month & same year
            end_label += ' - ' + endDate.day + ' ' + endDate.month + ' ' + endDate.year;
        } else {
            start_label += ' ' + startDate.month + ( startDate.year != endDate.year ? ' ' + startDate.year : '' );
            end_label += ' - ' + endDate.day + ' ' + endDate.month + ' ' + endDate.year;
        } // end - startDate

        label = start_label + end_label;

    } // end - startDate 
    
    return label;
}

// get date number from string
export const getDateNumFromString = (string,key) => {
    let num = 0;
    if ( string && !_isEmpty( string ) ) {
        let splitData = _split( string, '-' );
        switch (key) {
            case 'year':
                if ( splitData && splitData[0] && !_isEmpty( splitData[0] ) ) {
                    num = parseInt( splitData[0] );
                }
                break;
            case 'month':
                if ( splitData && splitData[1] && !_isEmpty( splitData[1] ) ) {
                    let selectedMonth = _find( MONTH_OPTIONS, { label: splitData[1] } );
                    if ( selectedMonth && selectedMonth.value ) {
                        num = ( '0' === selectedMonth.value ? 0 : parseInt( selectedMonth.value ) );
                    }
                }
                break;
            case 'date':
                if ( splitData && splitData[2] && !_isEmpty( splitData[2] ) ) {
                    num = parseInt( splitData[2] );
                }
                break;
        }
    } // end - string
    return num;
}

// get age based on date_of_birth
export const getAge = (date) => {
    let year = getDateNumFromString(date,'year'),
        currentYear = getMomentNow('YYYY');
    return parseInt( currentYear ) - year;
}

// check if required parent fields
export const isParentFieldRequired = (date) => {
    let age = getAge(date);
    return ( age && age >= 16 ? false : true );
}