import _random from "lodash/random";

import { GET_COURSE_REGISTRATION, GET_COURSE_REGISTRATION_ERROR, DO_COURSE_REGISTRATION, RESET_COURSE_REGISTRATION } from "../actions/types";

const initialState = {
    data: null,
    error_message: null,
    updated: false,
    rand: false,
};

export const course_registration = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_COURSE_REGISTRATION:
            return {
                ...state,
                data: payload.data || {},
                rand: _random(1, 9999),
            };
        case GET_COURSE_REGISTRATION_ERROR:
            return {
                ...state,
                data: {},
                error_message: payload || "",
                rand: _random(1, 9999),
            };
        case DO_COURSE_REGISTRATION:
            var randNum = _random(1, 9999);
            return {
                ...state,
                past_registrations: (payload && payload.past_registrations) || [],
                updated: randNum,
                rand: randNum,
            };
        case RESET_COURSE_REGISTRATION:
            return {
                ...state,
                data: null,
                error_message: null,
                updated: false,
                rand: false,
            };
        default:
            return state;
    }
};
