export const courseSchema = [
    { 
        id: 'id', 
        label: 'ID',
        required: ['update','delete'], 
        default: '', 
        type: 'string', 
        validation: 'string_id', 
        skip: ['add']
    },
    {   
        id: 'is_product',
        label: 'Is Product',
        required: false,
        default: 'yes',
        type: 'string',
        skip: false,
        sync: true
    },
    {   
        id: 'name',
        label: 'Product Name',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'text',
        skip: false,
        sync: true
    },
    {   
        id: 'course_code',
        label: 'Product Code',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'text',
        skip: false,
        sync: true
    },
    {   
        id: 'contact_hour',
        label: 'Contact Hour',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'contact_hour_label',
        label: 'Contact Hour Label',
        required: false,
        default: '',
        type: 'system_data',
        skip: false,
        sync: true
    },
    {   
        id: 'contact_hour_value',
        label: 'Contact Hour value',
        required: false,
        default: '',
        type: 'system_data',
        skip: false,
        sync: true
    },
    {   
        id: 'product_level',
        label: 'Level',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'product_level_label',
        label: 'Level Label',
        required: false,
        default: '',
        type: 'system_data',
        skip: false,
        sync: true
    },
    {   
        id: 'product_level_value',
        label: 'Level value',
        required: false,
        default: '',
        type: 'system_data',
        skip: false,
        sync: true
    },
    {   
        id: 'product_credit',
        label: 'Credit',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'product_credit_label',
        label: 'Credit Label',
        required: false,
        default: '',
        type: 'system_data',
        skip: false,
        sync: true
    },
    {   
        id: 'product_credit_value',
        label: 'Credit value',
        required: false,
        default: '',
        type: 'system_data',
        skip: false,
        sync: true
    },

    {   
        id: 'course_type',
        label: '(legacy) Course Type',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
    },
    {   
        id: 'course_type_label',
        label: 'Course Type Label',
        required: false,
        default: '',
        type: 'system_data',
        skip: false,
    },
    {   
        id: 'course_duration',
        label: '(legacy) Course Duration',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
    },
    {   
        id: 'course_duration_label',
        label: 'Course Duration Label',
        required: false,
        default: '',
        type: 'system_data',
        skip: false,
    },
    {   
        id: 'currency',
        label: '(legacy) Payment Currency',
        required: false,
        default: 'BkY4FcjfQ',
        type: 'string',
        field: 'select',
        skip: false,
    },
    {   
        id: 'currency_label',
        label: 'Payment Currency Label',
        required: false,
        default: 'MYR',
        type: 'system_data',
        skip: false,
    },
    {   
        id: 'price_per_pax',
        label: '(legacy) Price Per Pax',
        required: false,
        default: '',
        type: 'string',
        field: 'tinymce_editor',
        skip: false,
    },
    {   
        id: 'publish',
        label: 'Publish to Website',
        required: ['update'],
        default: 'no',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },

    {   
        id: 'web_course_image',
        label: 'Product Cover',
        required: false,
        default: '',
        type: 'string',
        field: 'upload_image',
        skip: false,
    },
    {   
        id: 'web_synopsis',
        label: 'Product Synopsis',
        required: false,
        default: '',
        type: 'string',
        field: 'tinymce_editor',
        skip: false,
    },
    {   
        id: 'web_what_you_learn',
        label: 'Product Structure',
        required: false,
        default: '',
        type: 'string',
        field: 'tinymce_editor',
        skip: false,
    },
        {   
        id: 'web_prerequisite',
        label: '(legacy) Prerequisite for Students',
        required: false,
        default: '',
        type: 'string',
        field: 'tinymce_editor',
        skip: false,
    },
    {   
        id: 'lesson_plan',
        label: 'Lesson Plan (internal use only)',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        skip: false,
    },
    {   
        id: 'lms_course_id',
        label: 'LMS Course',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'lms_assignment_showcase_id',
        label: 'LMS Assignment - Showcase Date',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'lms_assignment_feedback_form_id',
        label: 'LMS Assignment - Feedback Form',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'lms_assignment_project_id',
        label: 'LMS Assignment - Showcase Project',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'lms_assignment_portfolio_id',
        label: 'LMS Module - Update Portfolio',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'lms_assignment_mentor_page_id',
        label: 'LMS Module - Class Attendance and Reporting',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'lms_assignment_team_profile',
        label: 'LMS Assignment - Team Profile (Create / Join)',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'lms_assignment_team_project_1',
        label: 'LMS Assignment - Team Project 1',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'lms_assignment_team_project_2',
        label: 'LMS Assignment - Team Project 2',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'lms_assignment_team_project_3',
        label: 'LMS Assignment - Team Project 3',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'lms_assignment_team_project_4',
        label: 'LMS Assignment - Team Project 4',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'lms_assignment_team_project_5',
        label: 'LMS Assignment - Team Project 5',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'lms_assignment_team_project_6',
        label: 'LMS Assignment - Team Project 6',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'lms_assignment_team_project_7',
        label: 'LMS Assignment - Team Project 7',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'lms_assignment_team_project_8',
        label: 'LMS Assignment - Team Project 8',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'lms_assignment_team_project_9',
        label: 'LMS Assignment - Team Project 9',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'lms_assignment_team_project_10',
        label: 'LMS Assignment - Team Project 10',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'course_rubric',
        label: 'Rubric',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'feedback_form_id',
        label: 'Feedback Form',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'course_visibility',
        label: '(legacy) Course Visibility',
        required: false,
        default: [],
        type: 'array_ids',
        field: 'checkboxes',
        skip: false
    },

    {   
        id: 'created_on',
        label: 'Created On',
        required: false,
        default: 0,
        type: 'system_date',
        skip: ['update']
    },
    {   
        id: 'modified_on',
        label: 'Modified On',
        required: false,
        default: 0,
        type: 'system_date'
    },
    {   
        id: 'last_modified_by',
        label: 'Modified By',
        required: false,
        default: '',
        type: 'system_modified_by'
    }
];