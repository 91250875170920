/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _toLower from 'lodash/toLower';

import Table from '../../components/Table';

import { InfoButton } from '../../styles/button';
import { SuccessTag, GreyTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { isAdmin, hasAccessRights, getUserRegionFilter } from '../../helpers/auth';

import { appChangesReset } from '../../actions/misc';

// import { PLATFORM_COURSE_REGISTRATION_LINK } from '../../constants';

const Wrapper = styled.div`
    padding: 20px;
`;

class Summary extends React.Component {
    
    state = {

    };

    getItems = () => {
        const { list } = this.props;
        let items = [];
        if (isArrayExists(list)) {
            _forEach( _sortBy( list, [ 'logged_on' ] ), item => {
                const index = _findIndex( items, { id: item.schedule_id } );
                if (index > -1) {
                    // const traineeIndex = _findIndex( items[index].trainees, { id: item.trainee_id } );
                    // if (traineeIndex > -1) {
                    //     items[index].trainees[traineeIndex].status = item.status;
                    // } else {
                    //     items[index].trainees.push({
                    //         id: item.trainee_id,
                    //         status: item.status,
                    //     });
                    // }
                    items[index].trainees.push({
                        id: item.trainee_id+'_'+item.status,
                        status: item.status,
                    });
                } else {
                    items.push({
                        id: item.schedule_id,
                        class_name: item.class_name,
                        total_activation: 0,
                        total_deactivation: 0,
                        total_concluded: 0,
                        trainees: [{
                            id: item.trainee_id+'_'+item.status,
                            status: item.status
                        }]
                    });
                }
            });   
        }
        // do calculation
        if ( isArrayExists( items ) ) {
            _forEach( items, item => {
                item.total_activation = _size( _filter( item.trainees, { status: 'active' } ) );
                item.total_deactivation = _size( _filter( item.trainees, { status: 'inactivate' } ) );
                item.total_concluded = _size( _filter( item.trainees, { status: 'conclude' } ) );
            });
        }
        return _sortBy( items, [ i => i.class_name.toLowerCase() ] );
    }

    render() {
        const { month } = this.props;
        return (
        <Wrapper>
            <Typography variant="h4" gutterBottom>Summary for month {month}</Typography>
            <Table
                items={this.getItems()}
                cells={[
                    { id: 'class_name', label: 'Class Name', render: i => i.class_name || '' },
                    { id: 'total_activation', label: '# Activation', render: i => i.total_activation || 0 },
                    { id: 'total_deactivation', label: '# Deactivation', render: i => i.total_deactivation || 0 },
                    { id: 'total_concluded', label: '# Concluded', render: i => i.total_concluded || 0 },
                ]}
                showCheckbox={false}
                emptyCell="No item(s) Found."
                />
        </Wrapper>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(Summary);