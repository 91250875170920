/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Papa from 'papaparse';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormSelect2 from '../../components/FormSelect2';
import FormDatePicker from '../../components/FormDatePicker';
import FormDateOfBirth from '../../components/FormDateOfBirth';
import FormPhoneField from '../../components/FormPhoneField';
import ButtonActions from '../../components/ButtonActions';
import ToolTipInfo from '../../components/ToolTipInfo';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton, SuccessButton } from '../../styles/button';
import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { getAPIErrorMessage } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue,  } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp, getAge } from '../../helpers/date';
import { isAdmin, hasAccessRights, getUserRegionFilter } from '../../helpers/auth';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { deleteStudentFromDatabase } from '../../actions/database_students';
import { fb_exportStudentData } from '../../actions/database_students/fb';
import { toggleLoader } from '../../actions/global';

import { userStudentSchema } from '../../schemas/user_student';

import { PLATFORM_COURSE_REGISTRATION_LINK, GENDER_OPTIONS } from '../../constants';

class StudentsTable extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false,
        openDownloadModal: false,
        downloadData: false,
        csvData: false,
        randNum: false,
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        
    }

    doCSVDownload = (results) => {
        const { downloadData } = this.state;
        let csv = Papa.unparse( results, { delimiter: ',' } );
        let blob = new Blob( [csv] , { type: 'text/csv;charset=utf-8;' }); //new way
        let uri = window.URL.createObjectURL(blob);
        let link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", '_blank');
        link.setAttribute("download", "export_"+downloadData.data_type+".csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    handleCSVDownload = (event) => {
        const { csvData } = this.state;
        event.preventDefault();
        this.doCSVDownload(csvData);
    }

    handleDataExport = () => {
        const { currentSchedule, dispatch } = this.props;
        const { downloadData, checked } = this.state;
        let error = false;

        if ( !( currentSchedule && !_isEmpty( currentSchedule ) ) ) {
            error = 'Please select a class';
        }

        if ( !( checked && isArrayExists( checked ) ) ) {
            error = 'Please select at least one students';
        }

        if ( !( downloadData && downloadData.data_type && !_isEmpty( downloadData.data_type ) ) )
            error = 'Please select a data type';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            dispatch( toggleLoader(true) );
            fb_exportStudentData({
                data_type: downloadData.data_type,
                schedule_id: currentSchedule,
                students: checked
            })
            .then(results => {
                dispatch( toggleLoader(false) );
                this.setState({ csvData: ( results && results.csv_data ? results.csv_data : false ) });
                this.doCSVDownload(( results && results.csv_data ? results.csv_data : false ));
            })
            .catch(error => {
                dispatch( toggleLoader(false) );
                triggerErrorAlert(getAPIErrorMessage(error));
            });
        } // end - error

    }

    handleStudentDelete = () => {
        const { authData, currentSchedule } = this.props;
        const { deleteModal } = this.state;
        // perform delete
        this.props.dispatch(deleteStudentFromDatabase(deleteModal.uid,{ schedule_id: currentSchedule }));
    }

    handleFormUpdate = (newValue,key,subkey,subVal) => {
        const { downloadData } = this.state;
        let newData = ( downloadData ? cloneCollections( downloadData ) : {} );
        switch( key ) {
            // case 'course_type':
            //     let selectedType = ( course_type && isArrayExists( course_type ) ? _find( course_type, { id: newValue } ) : false );
            //     newData[key] = newValue;
            //     newData[key + '_label'] = ( selectedType && selectedType.label ? selectedType.label : '' );
            //     break;
            default:
                if ( subkey && ( subkey === 'checked' || subkey === 'unchecked' ) && subVal && !_isEmpty( subVal ) ) {
                    // use subVal as the new value
                    newData[key] = subVal;
                } else {
                    // otherwise - just update normally
                    newData[key] = newValue
                }
                break;
        } // end - key
        this.setState({ downloadData: newData });
    }

    getActions = () => {
        const { authData } = this.props;
        const { checked } = this.state;
        let actions = [];

        actions.push({ 
            id: 'downloaddata', label: 'Download Selected as CSV', icon: 'fa-cloud-download', onClick: () => {
                if ( checked && isArrayExists( checked ) ) {
                    this.setState({ openDownloadModal: true });
                } else {
                    triggerErrorAlert("Please select at least one student");
                } // end - checked
            }
        });

        actions.push({ 
            id: 'observer', label: (
            <>
                <ToolTipInfo content={(
                <>
                    MASS INVITATION IS DISABLED<br />
                    <br />
                    Get the students to invite their own parents from their LMS’s My Profile. Many times we notice their parent’s email is inaccurate – that creates bounce-back on our email system. Therefore, getting students to check and invite is the best policy for now.
                </>
                )} />
                Mass invite parents as Observer
            </>
            ),
            onClick: () => {
                /* do nothing */
                triggerErrorAlert("Mass Invitation is disabled");
            }
        });

        return actions;
    }

    reorganizeData() {
        const { searchterms, sortBy, perPage, page } = this.state;
        const { students } = this.props;
        let items = ( students ? cloneCollections( students ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','email','org'] );
			total = _size( items );
        } // end - searchterms

        // do filter
        // if ( filter_region && !_isEmpty( filter_region ) && filter_region !== 'all' ) {
        //     items = _filter( items, { region: filter_region });
        //     total = _size( items );
        // } // end - filter_region

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['created_on'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['created_on'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
                case 'email-desc':
                    items = _sortBy( items, ['email'] );
                    items = _reverse( items );
                    break;
                case 'email-asc':
                    items = _sortBy( items, ['email'] );
                    break;
                case 'org-desc':
                    items = _sortBy( items, ['org'] );
                    items = _reverse( items );
                    break;
                case 'org-asc':
                    items = _sortBy( items, ['org'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderDownloadForm = () => {
        const { downloadData, csvData } = this.state;
        return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormSelect label="Select Data Type *" name="data_type" value={( downloadData.data_type || '' )} options={[
                        { value: '', label: 'Select an Option'},
                        { value: 'student_profile', label: 'Student Profile' },
                        { value: 'reg_course_dropout', label: 'Registration, Course, Dropout' },
                        { value: 'courses_attendance_progress', label: 'Courses, Attendance, & Progress' },
                        { value: 'showcase_badges', label: 'Showcase & Badges' },
                        { value: 'feedback', label: 'Feedback' },
                        { value: 'payment', label: 'Payment' },
                    ]} onChange={this.handleFormUpdate} />
                </Grid>
                { csvData && !_isEmpty( csvData ) ? (
                <Grid item xs={12}>
                    <SuccessButton size="small" onClick={this.handleCSVDownload}><i className="fa fa-file-excel-o"></i>Re-Download CSV</SuccessButton>
                </Grid>
                ) : null }
            </Grid>
        </div>
        );
    }

    renderCell = type => item => {
        const { regions, schedules, currentSchedule, history } = this.props;
        switch( type ) {
            case 'region':
                return getSelectedValue( regions, item[type], 'id', 'label', '-' );
            case 'age':
                return ( item.date_of_birth_string && !_isEmpty( item.date_of_birth_string ) ? getAge( item.date_of_birth_string ) : ( item[type] || '-' ) );
            case 'created_on':
                return <WrapWord>{( item[type] ? getMomentTime( item[type], 'DD MMM YYYY' ) : '---' )}</WrapWord>;
            case 'email':
                return <WrapWord>{( item[type] || '-' )}</WrapWord>;
            case 'classes':
                return item && item.classes && isArrayExists( item.classes ) ? (
                <div>{item.classes.map(schedule => (
                    <div key={schedule.id} style={{ fontSize: '11px' }}><WrapWord><a href={"/schedules/"+schedule.id} onClick={event => {
                        event.preventDefault();
                        history.push("/schedules/"+schedule.id);
                    }}>{getSelectedValue( schedules, schedule.id, 'id', 'class_name', schedule.id )}</a></WrapWord></div>
                ))}</div>
                ) : '---';
        }
    }

    renderTableActions = () => {
        const { authData } = this.props;
        const { sortBy, perPage, searchterms, checked } = this.state;
        return <TableBar
                sortBy={sortBy}
                // show={['entries','sortby']}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Registration Date (Recent First)' },
                    { value: 'date-asc', label: 'Registration Date (Oldest First)' },
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' },
                    { value: 'email-asc', label: 'Email ( A - Z)' },
                    { value: 'email-desc', label: 'Email ( Z - A )' },
                    { value: 'org-asc', label: 'School ( A - Z)' },
                    { value: 'org-desc', label: 'School ( Z - A )' },
                ]}
                leftButtons={ checked && isArrayExists( checked ) ? [
                    <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                        <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                    </div>
                    ] : null }
                rightButtons={[
                    <ButtonActions 
                        key="actions" 
                        label="Actions"
                        menuContainerStyle={{ width: "250px" }}
                        style={{ marginRight: "5px" }}
                        actions={this.getActions()} />
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    doneLoaded={true}
                    // entriesLabel="(Total Registration For This Class)"
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { authData, users, currentSchedule, history } = this.props;
        const { openDeleteModal, deleteModal, openDownloadModal, downloadData, allCheck, checked } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={openDownloadModal}
                title="Download Selected Data as CSV"
                maxWidth="sm"
                onClose={() => this.setState({ openDownloadModal: false, downloadData: false, csvData: false, checked: [], allCheck: false })}
                disableBackdrop={true}
                actionLabel="Download Data"
                doAction={this.handleDataExport}
                contents={this.renderDownloadForm()} />

            {/* { authData && hasAccessRights(authData,['sdd']) ? <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to delete this student account ( ${deleteModal.name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleStudentDelete} /> : null } */}

            {this.renderTableActions()}
            <div style={{ paddingBottom: '10px' }}>
                {this.renderPagination(total)}
            </div>
            <Table 
                uniqueKey="uid"
                items={items}
                showCheckbox={true}
                emptyCell="No Student(s) Found."
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={(newValue) => this.setState({ checked: newValue })}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                cells={[
                    { id: 'region', label: 'Partner', render: this.renderCell('region') },
                    { id: 'name', label: 'Name', render: (item) => <a href={"/student_database/" + item.uid}
                        onClick={(event) => {
                            event.preventDefault();
                            history.push("/student_database/" + item.uid);
                        }}><WrapWord>{( item.name || '-' )}</WrapWord></a> },
                    { id: 'classes', label: 'Classes Enrolled', render: this.renderCell('classes') },
                    { id: 'gender', label: 'Gender', render: (item) => <WrapWord>{( item.gender || '-' )}</WrapWord> },
                    { id: 'age', label: 'Age', render: this.renderCell('age') },
                    { id: 'email', label: 'Email', render: this.renderCell('email') },
                    { id: 'phone', label: 'Mobile', render: (item) => <WrapWord>{( item.phone || '-' )}</WrapWord> },
                    { id: 'org', label: 'School', render: (item) => <WrapWord>{( item.org || '-' )}</WrapWord> },
                    { id: 'city', label: 'City', render: (item) => <WrapWord>{( item.city || '-' )}</WrapWord> },
                ]}
                actionStyles={{ width: "10%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <InfoButton key="edit" size="small" onClick={() => {
                            this.props.history.push("/student_database/"+item.uid,{ schedule_id: currentSchedule });
                        }}><i className="fa fa-edit"></i>Edit</InfoButton>
                        {/* { authData && hasAccessRights( authData, ['sdd'] ) ? <ErrorButton key="delete" size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton> : null } */}
                    </ButtonGroup>
                )} />
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(StudentsTable);