/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';
import _toString from 'lodash/toString';

import ModalView from '../../components/ModalView';
import MediaLibrary from '../../components/MediaLibrary';
import FormInput from '../../components/FormInput';
import FormEditor from '../../components/FormEditor';
import EmailShortcodes from '../../components/EmailShortcodes';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, convertArrayToObject, convertObjectToArray } from '../../helpers/data';

import { triggerClassQuarterlyReport, triggerPreviewEmail } from '../../actions/class_quarterly_reports';


// import { PLATFORM_COURSE_REGISTRATION_LINK } from '../../constants';

const FieldWrapper = styled.div`
    margin-bottom: 10px;

    h4 {
        color: #212121;
        font-size: 20px;
        font-weight: 700;
        &:after { display: block; height: 2px; width: 50px; content: " "; background: #212121; }
    }

    h5 {
        color: #212121;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 4px;
        text-transform: none;
    }
`;

const useStyles = theme => ({
    tooltip: {
        fontSize: '14px',
    },
    emailModal: {
        '& .MuiFilledInput-input.Mui-disabled, & .MuiInputBase-root.Mui-disabled, & .MuiFormLabel-root.Mui-disabled': {
            background: '#f1f1f1',
            color: '#212121'
        }
    }
});

class PreviewReportModal extends React.Component {

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose )
            onClose();
    }

    handlePreviewSend = () => {
        const { schedule, class_calendar_id, onClose } = this.props;
        let error = false,
            formData = {
                schedule_id: ( schedule && schedule.id ? schedule.id : '' ),
                class_calendar_id: ( class_calendar_id || '' ),
                trainees: this.getSelectedTrainees()
            };

        // check for error
        if ( !( formData && formData.schedule_id && !_isEmpty( formData.schedule_id ) ) )
            error = 'Unknown Error - Missing Schedule ID';
        
        if ( !( formData && formData.trainees && isArrayExists( formData.trainees ) ) )
            error = 'No student selected';

        if ( !( formData && formData.class_calendar_id && !_isEmpty( formData.class_calendar_id ) ) )
            error = 'Please select the quarterly report date';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch( triggerPreviewEmail(formData) );
        } // end - error
        
    }

    getSelectedTrainees = () => {
        const { schedule, class_calendar_id, trainees, studentsSelected } = this.props;
        let list = [];

        if ( studentsSelected && isArrayExists( studentsSelected ) && trainees && isArrayExists( trainees ) ) {
            _forEach( studentsSelected, selected => {
                let trainee = _find( trainees, { id: selected.id } );
                // make sure quarterly report wasn't already issued for the selected class_calendar_id
                if ( trainee.email && !_isEmpty( trainee.email ) && !( trainee && trainee.quarterly_reports && isArrayExists( trainee.quarterly_reports ) && _find( trainee.quarterly_reports, { id: class_calendar_id } ) ) )
                    list.push({ id: trainee.id, email: trainee.email });
            });
        } // end - studentsSelected

        return list;
    }

    renderContent = () => {
        const { authData, classes } = this.props;
        return (
        <div className={classes.emailModal}>
            <Typography variant="h5" style={{ textTransform: 'none' }}>A preview report will be sent to the following email address:</Typography>
            <FormInput type="email" disabled={true} value={( authData && authData.email ? authData.email : '' )} />
        </div>
        )
    }

    render() {
        const { open } = this.props;
        return (
        <>

            <ModalView 
                open={( open ? true : false )}
                title="SEND PREVIEW REPORT"
                maxWidth="sm"
                onClose={this.handleClose}
                disableBackdrop={true}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                actionLabel="Send"
                doAction={this.handlePreviewSend}
                contents={this.renderContent()} />

        </>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles),
    withRouter
)(PreviewReportModal);