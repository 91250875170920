/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import AppWrapper from "../../components/AppWrapper";
import List from "./list";

import { getCourses } from "../../actions/courses";
import { getAllBadges } from "../../actions/badges";
import { getMaintenance } from "../../actions/maintenance";

import { InfoBox } from "../../styles/message";

class MaintenanceCertificates extends React.Component {
    state = {
        randNum: false,
    };

    componentDidMount() {
        // get courses
        this.props.dispatch(getCourses());
        //get badges
        this.props.dispatch(getAllBadges());
        // get list
        this.props.dispatch(getMaintenance("certificates"));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { coursesList, allBadgesList, certificatesList, randNum } = this.props;

        if (coursesList && allBadgesList && certificatesList && randNum && randNum != this.state.randNum) this.setState({ randNum });
    }

    renderContents() {
        const { coursesList, certificatesList, allBadgesList, authData } = this.props;
        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={7}>
                        <InfoBox>
                            Find templates of Digital Badges on{" "}
                            <a href="https://drive.google.com/drive/folders/1TiA-MXJd0GFRjlan4KCXyv27xANfQ_b1" target="_blank">
                                Google Drive
                            </a>
                            .
                        </InfoBox>
                    </Grid>
                </Grid>
                <List
                    type="certificates"
                    typeLabel="Certificate Template"
                    courses={coursesList}
                    badges={allBadgesList}
                    authData={authData}
                    list={certificatesList}
                />
            </>
        );
    }

    render() {
        const { certificatesList, randNum } = this.props;
        return (
            <AppWrapper
                title="Certificate Templates"
                subtitle="Maintenance"
                onLoad={!(certificatesList && randNum) ? true : false}
                contents={this.renderContents()}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        certificatesList: state.maintenance && state.maintenance.certificates ? state.maintenance.certificates : null,
        coursesList: state.courses && state.courses.courses ? state.courses.courses : null,
        allBadgesList: state.badges && state.badges.badges_all ? state.badges.badges_all : null,
        randNum: state.maintenance && state.maintenance.rand ? state.maintenance.rand : null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(MaintenanceCertificates);
