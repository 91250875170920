import React from "react";

const info = {};

const buttons = [
    {
        id: "1",
        tooltip: true,
        tooltipContent: (
            <div style={{ padding: "10px" }}>
                <p style={{ fontWeight: "700" }}>📂 Chumbaka Partner Portal - 1. Business Setup & Strategy</p>
                <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                    <li>Biz Setup</li>
                    <li>Information Services</li>
                </ul>
            </div>
        ),
        type: "notion",
        link: "https://www.notion.so/chumbaka/1-Business-Setup-Strategy-1749a8ecdb774a9bbdae2a3d4bb80fcf?pvs=4",
        label: "Notion",
    },
    {
        id: "2",
        tooltip: true,
        tooltipContent: (
            <div style={{ padding: "10px" }}>
                <p style={{ fontWeight: "700" }}>📂 Chumbaka Partner Portal - 1. Business Setup & Strategy</p>
                <ul style={{ listStyle: "disc", marginLeft: "20px" }}>
                    <li>Comms Slides for Chumbaka Partnership</li>
                    <li>Finance 101</li>
                    <li>Biz Documents templates</li>
                </ul>
            </div>
        ),
        type: "drive",
        link: "https://drive.google.com/drive/folders/1M-aTh0t5hpnhLi7BOSWCCQCbqUiJPfaF",
        label: "Google Drive",
    },
];

export default {
    info,
    buttons,
};
