/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _remove from 'lodash/remove';
import _size from 'lodash/size';
import _reverse from 'lodash/reverse';

import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';

import { InfoButton } from '../../styles/button';
import { WrapWord } from '../../styles/misc';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions, doArraySearch, doPagination } from '../../helpers/data';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';

import { programSchema } from '../../schemas/program';


const useStyles = theme => ({
    headcell: {
        fontSize: '14px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '14px',
        verticalAlign: 'top'
    }
});

class VisibilityTable extends React.Component {

    state = {
        searchterms: '',
        sortBy: 'label-asc',
        perPage: 20,
        page: 1,
        checkAll: false,
        randNum: false
    };

    handleFormUnlock = () => {
        const { onUnlock, formDisabled } = this.props;
        if ( onUnlock )
            onUnlock(!formDisabled);
    }

    handleCheckAll = event => {
        const { onUpdate, value, partners } = this.props;
        let newValue = ( value && isArrayExists( value ) ? cloneCollections( value ) : [] );

        if ( event.target.checked ) {
            if ( partners && isArrayExists( partners ) ) {
                _forEach( partners, partner => {
                    if ( partner && partner.region && !_isEmpty( partner.region ) && !_find( newValue, { id: partner.region } ) )
                        newValue.push({ id: partner.region, name: ( partner.label || '' ) })
                });
            } // end - partners
        } else {
            newValue = [];
        }

        this.setState({ checkAll: ( event.target.checked ? true : false ) });

        // do update
        if ( onUpdate )
            onUpdate( newValue, 'program_visibility' );
    }

    handleFormUpdate = (region_id) => event => {
        const { onUpdate, value } = this.props;
        let newValue = ( value && isArrayExists( value ) ? cloneCollections( value ) : [] ),
            selected = this.getSelectedPartner(region_id);

        if ( event.target.checked ) {
            if ( !_find( newValue, { id: region_id } ) && selected && selected.region && !_isEmpty( selected.region ) )
                newValue.push({ id: selected.region, name: ( selected.label || '' ) })
        } else {
            if ( _find( newValue, { id: region_id }) ) {
                let pulled = _remove( newValue, { id: region_id });
            }
        }

        // do update
        if ( onUpdate )
            onUpdate( newValue, 'program_visibility' );
    }

    getSelectedPartner = (region_id) => {
        const { partners } = this.props;
        return ( partners && isArrayExists( partners ) ? _find( partners, { region: region_id } ) : false );
    }

    isPartnerSelected = (partner) => {
        const { value } = this.props;
        let selectedRegion = ( value && isArrayExists( value ) ? _find( value, { id: partner.region }) : false );
        return ( selectedRegion && selectedRegion.id && !_isEmpty( selectedRegion.id ) ? selectedRegion.id : false );
    }

    reorganizeData = () => {
        const { searchterms, sortBy, perPage, page } = this.state;
        const { partners } = this.props;
        let list = ( partners && isArrayExists( partners ) ? cloneCollections( partners ) : [] ),
            totalCount = _size( list );

         // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            list = doArraySearch( list, searchterms, ['name','city','label','state'] );
			totalCount = _size( list );
        } // end - searchterms

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( list ) ) {
            switch( sortBy ) {
                case 'label-desc':
                    list = _sortBy( list, ['label'] );
                    list = _reverse( list );
                    break;
                case 'label-asc':
                    list = _sortBy( list, ['label'] );
                    break;
                case 'name-desc':
                    list = _sortBy( list, ['name'] );
                    list = _reverse( list );
                    break;
                case 'name-asc':
                    list = _sortBy( list, ['name'] );
                    break;
                case 'city-desc':
                    list = _sortBy( list, ['city'] );
                    list = _reverse( list );
                    break;
                case 'city-asc':
                    list = _sortBy( list, ['city'] );
                    break;
                case 'state-desc':
                    list = _sortBy( list, ['state'] );
                    list = _reverse( list );
                    break;
                case 'state-asc':
                    list = _sortBy( list, ['state'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        list = doPagination( list, perPage, page );
        

        return { totalCount, list };
    }

    renderTableActions = () => {
        const { authData, course_type, formDisabled } = this.props;
        const { sortBy, perPage, searchterms } = this.state;
        return <TableBar
                sortBy={sortBy}
                limitedPerPage={true}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'label-asc', label: 'Partner ( A - Z)' },
                    { value: 'label-desc', label: 'Partner ( Z - A )' },
                    { value: 'name-asc', label: 'Person-in-charge ( A - Z)' },
                    { value: 'name-desc', label: 'Person-in-charge ( Z - A )' },
                    { value: 'city-asc', label: 'City ( A - Z)' },
                    { value: 'city-desc', label: 'City ( Z - A )' },
                    { value: 'state-asc', label: 'State ( A - Z)' },
                    { value: 'state-desc', label: 'State ( Z - A )' },
                ]}
                rightButtons={ formDisabled ? [
                    <InfoButton minWidth="128px" key="lock" style={{ marginRight: "5px" }} onClick={this.handleFormUnlock}><i className="fa fa-lock-alt"></i>Unlock to Edit</InfoButton>
                ] : null }
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    doneLoaded={true}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderBody = (items) => {
        const { classes, authData, formDisabled } = this.props;
        return (
        <TableBody>
            { items && isArrayExists( items ) ? items.map(item => {
                return (
                <TableRow key={item.region} id={item.region}>
                    <TableCell className={classes.bodycell}><WrapWord>{item.label || ''}</WrapWord></TableCell>
                    <TableCell className={classes.bodycell}><WrapWord>{item.name || ''}</WrapWord></TableCell>
                    <TableCell className={classes.bodycell}>
                        <WrapWord>{item.city || ''}</WrapWord>
                    </TableCell>
                    <TableCell className={classes.bodycell}>
                        <WrapWord>{item.state || ''}</WrapWord>
                    </TableCell>
                    <TableCell className={classes.bodycell}>
                        <Checkbox
                            checked={( this.isPartnerSelected(item) ? true : false )}
                            disabled={formDisabled}
                            onChange={this.handleFormUpdate(item.region)}
                            value={this.isPartnerSelected(item)}
                            color="primary"
                            style={{ padding: '0px' }} />
                    </TableCell>
                </TableRow>
                )
            }) : (
                <TableRow>
                    <TableCell className={classes.bodycell}>No Partner(s) found.</TableCell>
                </TableRow>
            ) }
        </TableBody>
        )
    }

    renderHeader = () => {
        const { classes, onUpdate, formDisabled } = this.props;
        const { checkAll } = this.state;
        return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headcell} style={{ width: "30%" }}>Partner</TableCell>
                <TableCell className={classes.headcell} style={{ width: "20%" }}>Person-in-charge</TableCell>
                <TableCell className={classes.headcell}>City</TableCell>
                <TableCell className={classes.headcell}>State</TableCell>
                <TableCell className={classes.headcell} style={{ width: "20%" }}>
                    <Checkbox
                        checked={checkAll}
                        disabled={formDisabled}
                        onChange={this.handleCheckAll}
                        value={checkAll}
                        color="primary"
                        style={{ padding: '0px 5px 0 0' }} />
                    <span style={{ marginLeft: '5px' }}>Program Visibility</span>
                </TableCell>
            </TableRow>
        </TableHead>
        )
    }

    render = () => {
        const { classes } = this.props;
        const { list, totalCount } = this.reorganizeData();
        return (
        <>
            {this.renderTableActions()}
            <Table>
                {this.renderHeader()}
                {this.renderBody(list)}
            </Table>
            {this.renderPagination(totalCount)}
        </>
        );
    }

}

export default compose(
    withStyles(useStyles)
)(VisibilityTable);