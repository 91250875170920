export const trainingLocationSchema = [
    { 
        id: 'id', 
        label: 'Venue ID',
        required: ['add','update','delete'], 
        default: '', 
        type: 'string', 
        field: 'text',
        validation: 'string_id', 
        skip: ['update'],
        disabled: ['update']
    },
    { 
        id: 'region', 
        label: 'Partner',
        required: ['add','update'], 
        default: '', 
        type: 'string', 
        field: 'select',
        disabled: ['admin']
    },
    { 
        id: 'status', 
        label: 'Status',
        required: ['add','update'], 
        default: 'publish', 
        type: 'string', 
        field: 'select'
    },
    { 
        id: 'publish', 
        label: 'Publish to Website',
        required: ['add','update'], 
        default: 'no', 
        type: 'string', 
        field: 'select'
    },
    {   
        id: 'name',
        label: 'Venue Name',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'text',
        skip: false,
    },
    {   
        id: 'address',
        label: 'Address 1',
        required: false,
        default: '',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'address2',
        label: 'Address 2',
        required: false,
        default: '',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'city',
        label: 'City',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        skip: false,
    },
    {   
        id: 'postcode',
        label: 'Postcode',
        required: false,
        default: '',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'state',
        label: 'State',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'select'
    },
    {   
        id: 'country',
        label: 'Country',
        required: false,
        default: 'MY',
        type: 'string',
        field: 'select'
    },
    {   
        id: 'map_link',
        label: 'Google Map Link',
        required: false,
        default: '',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'guide_to_venue',
        label: 'Guide to Venue',
        required: false,
        default: '',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'created_on',
        label: 'Created On',
        required: false,
        default: 0,
        type: 'system_date',
        skip: ['update'],
    },
    {   
        id: 'modified_on',
        label: 'Modified On',
        required: false,
        default: 0,
        type: 'system_date',
    },
    {   
        id: 'last_modified_by',
        label: 'Modified By',
        required: false,
        default: '',
        type: 'system_modified_by'
    }
];