/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getUnreconciliationPayments = (callback,props) => {
    const { region, schedule_id } = props;

    let Ref = firebase.firestore().collection("payments").where('status','in',['success']).where("payment_reconciliation",'==','no');

    
    if ( schedule_id && !_isEmpty( schedule_id ) ) {
        Ref = Ref.where('schedule_id','==',schedule_id);
    } else if ( region && !_isEmpty( region ) ) {
        Ref = Ref.where('region','==',region);
    }

    Ref.onSnapshot(snapshot => {
        const list = [];
        if ( snapshot ) {
            snapshot.forEach( doc => {
                list.push( doc.data() );
            });
        } // end - snapshot
        callback(list);
    });
}

export const fb_updateClassPaymentTracking = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'class', action: 'update_payment_tracking', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_sendPaymentEmail = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'class', action: 'send_payment_email', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getPaymentHistory = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'class', action: 'get_payment_details', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}