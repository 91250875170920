/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	CLMS_GET_COURSES,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
// import { appChangesReset } from '../misc';

import { fb_getCourses } from './fb';


export const getLMSCourses = () => {
	return dispatch => {

		fb_getCourses()
		.then(courses => {
			dispatch({
				type: CLMS_GET_COURSES,
				payload: { courses }
			});	
		})
		.catch(error => {
			// triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: CLMS_GET_COURSES,
				payload: { courses: [] }
			});
		})

	}
}