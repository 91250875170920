/* eslint-disable */
import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _trim from 'lodash/trim';

import DotsLoader from '../../components/DotsLoader';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormSelect2 from '../../components/FormSelect2';

import { isArrayExists } from '../../helpers/validation';
import { getSelectedValue, cloneCollections, getSelectOptions } from '../../helpers/data';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { callFunctionsAPI, getAPIErrorMessage, doPromise } from '../../helpers/action';

import { STATE_OPTIONS } from '../../data/const_states';

import theme from '../../theme';

const Wrapper = styled.div`
    cursor: pointer;
`;

const getSchoolsList = ( state ) => {
    return new Promise((resolve,reject) => {

        doPromise({ method: 'POST', url: 'courseRegistration', params: [{ key: 'action', value: 'get_school_options' }], formData: { state } })
        .then(res => {
            resolve(res.data);
        })
        .catch(error => {
            reject(error);
        });

    });
}

const SchoolSelector = ({
    predefinedState = '',
    value = '',
    field = {},
    showInputField = true,
    forceOpenModal = false,
    onChange,
    onClose = () => {}
}) => {
    const [ modal, setModal ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ state, setState ] = useState('');
    const [ school, setSchool ] = useState('');
    const [ schools, setSchools ] = useState([]);
    const [ tab, setTab ] = useState('option');

    useEffect(() => {
        setState(predefinedState);
    },[ predefinedState ]);

    useEffect(() => {
        setModal(forceOpenModal);
    }, [ forceOpenModal ]);

    useEffect(() => {
        setSchool(_trim( value ).toUpperCase());
    },[ value ]);

    useEffect(() => {
        if ( state && !_isEmpty( state ) ) {
            setLoading(true);
            getSchoolsList( state )
            .then( list => {
                setLoading(false);
                setSchools( list );
            })
            .catch(error => {
                triggerErrorAlert( getAPIErrorMessage( error ) );
            });
        } else {
            setLoading(false);
            setSchools([]);
        }
    }, [ state ]);

    const getStateOptions = () => {
        let options = [{ value: '', label: 'Select an Option' }];
        const selectedState = _find( STATE_OPTIONS, { country: 'Malaysia' } );
        if ( selectedState && selectedState.states && isArrayExists( selectedState.states ) ) {
            selectedState.states.forEach(state => {
                options.push({
                    value: state,
                    label: state
                });
            });
        }
        return options;
    }

    const getSchoolOptions = () => {
        let options = [
            // { value: '', label: 'Select an Option' }
        ];
        if ( state && !_isEmpty( state ) && schools && isArrayExists( schools ) ) {
            _forEach( _sortBy( schools, s => s.school.toLowerCase() ), ( item, index ) => {
                options.push({
                    value: item.school,
                    label: item.school
                });
            });
        }
        return options;
    }

    const handleClose = () => {
        setModal(false);
        if ( onClose )
            onClose();
    }

    const handleTabChange = (newTab) => (event) => {
        event.preventDefault();
        setTab( newTab );
        // if ( newTab === 'option' )
        //     setSchool('');
    }

    const handleSchoolSelect = () => {
        let error = false;

        if ( !( school && !_isEmpty( school ) ) ) {
            if ( tab === 'option' ) {
                error = 'Please select a school';
            } else {
                error = 'Please insert a school name';
            }
        }

        if ( !( state && !_isEmpty( state ) ) )
            error = 'Please select a state';

        // make sure school name not over 100
        if ( school && !_isEmpty( school ) && _size( school ) > 100 )
            error = 'Your school name is too long. Please keep it under 100 characters.';

        // make sure school name contains only alphanumeric, space, round brackets and dots
        if ( tab === 'new' && school && !_isEmpty( school ) && !/^[a-zA-Z0-9\s\(\)\.]+$/.test( school ) ) {
            error = 'Your school name contains invalid characters. Please use only alphanumeric, round brackets and dots.';
        }

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            onChange( _trim( school ).toUpperCase(), 'org' );
            handleClose();
        }
    }

    return (
    <Wrapper>

        { showInputField && (
            <FormInput
                label={( field.label || '' ) + ( field.required && field.required === 'yes' ? ' (Required)' : '' )}
                desc={ field && field.desc || ''}
                // name={field.name}
                value={( value || '' )}
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="end">
                        <Tooltip title={<span style={{ fontSize: '12px' }}>Select School</span>} arrow placement='top'>
                            <IconButton
                                onClick={() => setModal(true)}>
                                <ControlPointIcon />
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                    )
                }}
                onClick={() => setModal(true)}
                onChange={() => { /* do nothing */  }}
            />
        )}

        <ModalView
            open={modal}
            title={"Select School / University"}
            maxWidth="sm"
            actionLabel={ tab === 'option' ? "Select" : "Add New" }
            cancelLabel="Cancel"
            doAction={handleSchoolSelect}
            onClose={handleClose}
            contents={(
            <>
                <FormSelect
                    label="State *"
                    name="state"
                    value={state}
                    options={getStateOptions()}
                    onChange={(newValue) => setState(newValue)} />
                { loading ? (
                    <DotsLoader height="150px" />
                ) : (
                <div style={{ marginTop: '20px', padding: '10px', border: '1px solid #ddd' }}>  
                    <ButtonGroup disableElevation variant="contained">
                        <Button color={( tab === 'option' ? 'primary' : null )} onClick={handleTabChange('option')}>Choose</Button>
                        <Button color={( tab === 'new' ? 'primary' : null )} onClick={handleTabChange('new')}>Add New</Button>
                    </ButtonGroup>
                    <div style={{ padding: '20px 0 0 0' }}>
                        { tab === 'option' ? (
                            <FormSelect2
                                label="School / University *"
                                name="school"
                                disabled={ !_isEmpty( state ) ? false : true }
                                value={school || ''}
                                isSearchable={true}
                                isClearable={true}
                                menuPortalTarget={document.body}
                                placeholder="Type keyword here to search..."
                                options={getSchoolOptions()}
                                styles={{
                                    menu: (currentStyle) => ({ ...currentStyle, marginTop: '24px' }),
                                    menuList: (currentStyle) => ({ ...currentStyle, height: '250px' }),
                                    menuPortal: (currentStyle) => ({ ...currentStyle , zIndex: "9999" })
                                }}
                                onChange={(newValue) => setSchool(newValue)} /> 
                        ) : (
                            <FormInput
                                label="School / University Name *"
                                name="school"
                                disabled={ !_isEmpty( state ) ? false : true }
                                value={school || ''}
                                onChange={(newValue) => setSchool(newValue)} />
                        )}
                    </div>
                </div>
                ) }
            </>
            )} />

    </Wrapper>
    )
}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
    }
}

export default compose(
    connect(mapStateToProps)
)(SchoolSelector);