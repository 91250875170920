/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import _isEmpty from 'lodash/isEmpty';

import ScreenLoader from '../../components/ScreenLoader';

import { GreyButton } from '../../styles/button';


import { signInWithEmail, logout } from '../../actions/auth';
import { PLATFORM_NAME_LONG, PLATFORM_URL } from '../../constants';

const getQueryString = (location) => {
    return ( location && location.search ? queryString.parse(location.search) : {} );
}

const BadgrRedirect = ({
    authData,
    location
}) => {
    const { code } = getQueryString(location);

    useEffect(() => {
        if ( code && !_isEmpty( code ) )
            window.location = 'https://chumbaka-dev-tms.web.app/sso_badgr?code='+code;
    },[]);
    
    return <ScreenLoader />
}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(BadgrRedirect);