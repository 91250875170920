/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _replace from 'lodash/replace';
import _remove from 'lodash/remove';

import ModalDelete from '../../components/ModalDelete';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormPhoneField from '../../components/FormPhoneField';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { getSelectedValue, cloneCollections } from '../../helpers/data';
import { getMomentTime, printSemiPrettyDate } from '../../helpers/date';
import { isSkip, isSchemaRequired } from '../../helpers/schemas';

import { Button, InverseButton, InverseLink } from '../../styles/button';

import { traineeSchema } from '../../schemas/trainee';

import { DAY_OPTIONS, GENDER_OPTIONS } from '../../constants';

const FormWrapper = styled.div`
    padding: 0px;
`;

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});



class ParentForm extends React.Component {

    state = {
        openDeleteModal: false,
        deleteModal: false,
    };

    handleFormUpdate = (newValue,key) => {
        const { onUpdate, parent } = this.props;
        let newData = ( parent && isObjectExists( parent ) ? cloneCollections( parent ) : {} );
        newData[key] = newValue;

        if ( onUpdate )
            onUpdate(newData);
    }

    getFieldOptions = (field) => {
        switch( field.name ) {
            case 'gender':
                return GENDER_OPTIONS;
            default:
                return [];
        }
    }

    getFieldValue = (field) => {
        const { parent } = this.props;
        switch( field.name ) {
            default:
                return ( parent && parent[field.name] ? parent[field.name] : ( field.default || '' ) );
        }
    }

    getTooltip = (schema) => {
        switch( schema.id ) {
            // case 'lms_course_link':
            //     return <a href="https://community.canvaslms.com/t5/Instructor-Guide/How-do-I-enable-course-self-enrollment-with-a-join-code-or/ta-p/830" target="_blank">Refer to this method</a>;
            default:
                return;
        }
    }

    getField = (id) => {
        let schema = _find( traineeSchema, { id } );
        return ( schema ? {
            name: ( schema.id || '' ),
            label: ( schema.label ? _replace( schema.label, 'Guardian ', '')+ '*' : '' ),
            field_type: ( schema.field || '' ),
            default: ( schema.default || null ),
            disabled: false,
            tooltip: this.getTooltip(schema)
        } : null );
    }

    renderField = (id) => {
        let field = this.getField(id);
        if ( field && field.field_type && !_isEmpty( field.field_type ) ) {
            switch( field.field_type ) {
                case 'text':
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'textarea':
                    return <FormInput {...field} rows={( 'notes' === id ? 6 : 3 )} multiline={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'text_number':
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'email':
                    return <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'select':
                    return <div style={{ paddingTop: "15px" }}><FormSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} disableNative={false} onChange={this.handleFormUpdate} /></div>;
                case 'phone':
                    return <FormPhoneField {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />
            }
        } // end - field.field_type
    }

    render = () => {
        const { parent } = this.props;
        return (
        <FormWrapper>
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>{this.renderField('parent_name')}</Grid>
                <Grid item sm={6} xs={12}>{this.renderField('parent_email')}</Grid>
                <Grid item sm={6} xs={12}>{this.renderField('parent_phone')}</Grid>
                <Grid item sm={6} xs={12}>{this.renderField('parent_city')}</Grid>
                <Grid item xs={12}><Typography variant="body1"  style={{ color: '#D32F2F', fontWeight: "700" }}>* Compulsory to fill in</Typography></Grid>
            </Grid>
        </FormWrapper>
        )
    }
}


export default compose(
    withStyles(useStyles),
    withRouter
)(ParentForm);