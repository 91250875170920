/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';

import ModalView from '../../components/ModalView';
import ToolTipInfo from '../../components/ToolTipInfo';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';
import FormDatePicker from '../../components/FormDatePicker';
import FormCheckbox from '../../components/FormCheckbox';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getAPIErrorMessage } from '../../helpers/action';

import { updateClassMentors, reupdateMentorsCourseAccess } from '../../actions/class_course_access';

import { InverseButton, GreyButton, InfoButton } from '../../styles/button';

import { ENROLLMENT_STATUS_OPTIONS } from '../../constants';

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
    border: 2px solid #212121;
`;

class MentorsList extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        filterBy: 'all',
        sortBy: 'name-asc',
        perPage: 5,
        page: 1,
        openModal: false,
        modalData: false,
    };


    handleUpdate = () => {
        const { schedule, authData } = this.props;
        const { modalData } = this.state;
        let error = false;

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateClassMentors({
                schedule_id: ( schedule && schedule.id ? schedule.id : false ),
                mentors: ( modalData && modalData.mentors && isArrayExists( modalData.mentors ) ? cloneCollections( modalData.mentors ) : [] )
            },{ authData, getScheduleUsage: true }));
        } 
    }

    handleChanges = mentor => event => {
        const { modalData } = this.state;
        let newData = ( modalData && isObjectExists( modalData ) ? cloneCollections( modalData ) : { mentors: [] } );
        if ( event.target.checked ) {
           if ( !_find( modalData.mentors, { id: mentor.email } ) )
                newData.mentors.push({
                    id: mentor.email,
                    name: ( mentor.name || '' )
                }); 
        } else {
            if ( _find( modalData.mentors, { id: mentor.email } ) ) {
                var removed = _remove( newData.mentors, { id: mentor.email } );
            }
        } // end - checked
        
        this.setState({ modalData: newData });
    }

    renderModalForm = () => {
        const { mentors, schedule } = this.props;
        const { modalData } = this.state;
        const list = ( mentors && isArrayExists( mentors ) && schedule && schedule.region && !_isEmpty( schedule.region ) ? _filter( mentors, { region: schedule.region }) : [] );
        return (
        <Grid container spacing={1}>
            { list && isArrayExists( list ) ? _sortBy( list, [m => m.name.toLowerCase()] ).map(mentor => {
                return (
                <Grid key={mentor.id} item xs={12} sm={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={( modalData && modalData.mentors && isArrayExists( modalData.mentors ) && _find( modalData.mentors, { id: mentor.email } ) ? true : false )}
                                onChange={this.handleChanges(mentor)}
                                value={mentor.email}
                                color="primary" />
                        }
                        label={( mentor.name || '' )} />
                </Grid>
                )
            }) : <Grid key="empty" item xs={12}>No mentor options found.</Grid> }
        </Grid>
        );
    }

    render() {
        const { schedule, mentors, changesMade, authData } = this.props;
        const { openModal, modalData } = this.state;
        return (
        <>

            <Wrapper>
                <Typography variant="h5" style={{ paddingBottom: '15px' }}>
                    <ToolTipInfo 
                        content={(
                        <>
                            ADD MENTORS TO THIS POOL<br /><br />
                            Add mentors to this Class to activate the Courses in this Program. Add mentors into your database <a href="/mentors" target="_blank">here</a>
                        </>)} />
                    Mentor(s)
                </Typography>
                <Grid container spacing={1}>
                    { schedule && schedule.mentors && isArrayExists( schedule.mentors ) ? _sortBy( schedule.mentors, ['name','id'] ).map(mentor => {
                        return (
                        <Grid key={mentor.id} item xs={12} sm={4}>{getSelectedValue( mentors, mentor.id, 'email', 'name', mentor.id )}</Grid>
                        );
                    }) : <Grid key="empty" item xs={12}>No mentor(s) added yet.</Grid> }
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <InfoButton size="small" onClick={event => {
                            event.preventDefault();
                            if ( changesMade ) {
                                alert("Please save changes first before proceed.");
                            } else {
                                // make sure there is courses in schedule course access before allow user to update mentors
                                if ( !( schedule && schedule.course_access && isArrayExists( schedule.course_access ) ) ) {
                                    alert("You must have at least one item in the Class Schedule's course access first before you add mentor(s) into this class.");
                                } else {
                                    this.setState({ openModal: true, modalData: { 
                                        mentors: ( schedule && schedule.mentors && isArrayExists( schedule.mentors ) ? cloneCollections( schedule.mentors ) : [] ) 
                                    } });
                                } // end 0 schedule.course_access
                            }
                        }}><i className="fa fa-refresh"></i>Add / Remove</InfoButton>
                    </Grid>
                    <Grid item xs={6} align="right">
                        { mentors && isArrayExists( mentors ) ? (
                        <>
                            <span style={{ paddingRight: '5px' }}><ToolTipInfo 
                                content={(
                                <>
                                    FORCE SYNC WITH LMS<br /><br />
                                    Trigger this manually to update the Mentor in LMS. LMS will always be updated automatically when a new mentor is added to the pool. Use this button when the automation is failed or a new course is added to the class (on Class Schedule).
                                </>)} /></span>
                            <InverseButton size="small" onClick={event => {
                                event.preventDefault();
                                if ( changesMade ) {
                                    alert("Please save changes first before proceed.");
                                } else {
                                    this.props.dispatch(reupdateMentorsCourseAccess({
                                        schedule_id: ( schedule && schedule.id ? schedule.id : false )
                                    },{ authData, getScheduleUsage: true }));
                                }
                            }}><i className="fa fa-refresh"></i>Force sync mentors with LMS</InverseButton>
                        </>
                        ) : null }
                    </Grid>
                </Grid>
            </Wrapper>

            <ModalView 
                open={openModal}
                title={"Update Mentor(s) For This Class"}
                onClose={() => this.setState({ openModal: false, modalData: false })}
                disableBackdrop={true}
                actionLabel="Update"
                maxWidth="md"
                doAction={this.handleUpdate}
                contents={this.renderModalForm()} />

        </>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(MentorsList);