import React from 'react';
import { Provider as ReduxProvider } from "react-redux";
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
// import 'react-data-grid/dist/react-data-grid.css';
import "@pathofdev/react-tag-input/build/index.css";
import 'react-phone-number-input/style.css';
import 'rc-calendar/assets/index.css';
import 'react-grid-layout/css/styles.css';
import '@9gustin/react-notion-render/dist/index.css';
// import 'react-notion-x/src/styles.css';

import Structure from './structure';
import store from './store';

class App extends React.Component {
    render() {
        return (
        <div className="main-app-wrapper">
            <ReduxProvider store={store}>
                <Structure />
                <Alert stack={false} html={true} effect="slide" />
            </ReduxProvider>
        </div>
        );
    }
}

export default App;
