/* eslint-disable */
import firebase from "firebase/app";
import _isEmpty from "lodash/isEmpty";

import { getIDToken } from "../auth/fb";
import { doPromise, callFunctionsAPI } from "../../helpers/action";

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve, reject) => {});
};

/* helper end */

export const fb_getUsers = () => {
    return new Promise((resolve, reject) => {
        firebase
            .firestore()
            .collection("users")
            .where("type", "in", ["admin", "superadmin"])
            .get()
            .then((snapshot) => {
                var users = [];

                if (snapshot && !snapshot.empty) {
                    snapshot.forEach((doc) => {
                        var val = doc.data();
                        users.push({
                            uid: doc.id,
                            name: val.name || "",
                            email: val.email || "",
                            status: val.status || "disabled",
                            type: val.type || "noaccess",
                            role: val.role || "",
                            region: val.region || "",
                            belongs_to: val.belongs_to || [],
                            notify_training_location: val.notify_training_location || "no",
                            created_on: val.created_on || 0,
                        });
                    });
                } // end - snapshot

                resolve(users);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_getUsersOptions = (callback, props = {}) => {
    return new Promise((resolve, reject) => {
        const { region } = props;

        let Ref = firebase.database().ref("maintenance/users");

        // no longer need this
        // if (region && !_isEmpty(region)) {
        //     Ref = Ref.orderByChild("region").equalTo(region);
        // } // end - region

        Ref.on("value", (snapshot) => {
            let list = [];
            if (snapshot.exists() && snapshot.hasChildren()) {
                snapshot.forEach((childSnapshot) => {
                    let item = childSnapshot.val();
                    item.uid = childSnapshot.key;
                    item.belongs_to = [];
                    if (childSnapshot.child("belongs_to").exists() && childSnapshot.child("belongs_to").hasChildren()) {
                        childSnapshot.child("belongs_to").forEach((belongSnapshot) => {
                            item.belongs_to.push(belongSnapshot.key);
                        });
                    }
                    if (region && !_isEmpty(region)) {
                        if (item.belongs_to.includes(region)) {
                            list.push(item);
                        }
                    } else {
                        list.push(item);
                    }
                });
            } // end - snapshpt\
            callback(list);
        });
    });
};

export const fb_addUser = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "admin", action: "add", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_editUser = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "admin", action: "update", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_deleteUser = (uid) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "admin", action: "delete", formData: { uid } })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
