export const userPartnerSchema = [
    {
        id: "uid",
        label: "ID",
        required: ["update", "delete"],
        default: "",
        type: "string",
        validation: "string_id",
        skip: ["add", "update"],
    },
    {
        id: "status",
        label: "Status",
        required: false,
        default: "pending",
        type: "string",
        field: "select",
        skip: ["add"],
        disabled: ["self"],
    },
    {
        id: "region",
        label: "Partner",
        required: ["add", "update"],
        default: "",
        type: "string",
        field: "select",
        skip: ["add", "update"],
        disabled: ["admin"],
    },
    {
        id: "belongs_to",
        label: "Belongs To",
        required: false,
        default: [],
        type: "array",
        field: "special",
    },
    {
        id: "name",
        label: "Name",
        required: ["update"],
        default: "",
        type: "string",
        field: "text",
        skip: ["add", "update"],
    },
    {
        id: "email",
        label: "Email",
        required: ["add", "update"],
        default: "",
        type: "email",
        field: "email",
        skip: ["add", "update"],
        disabled: ["update"],
    },
    {
        id: "role",
        label: "SMS Role",
        required: ["add", "update"],
        default: "",
        type: "string",
        field: "select",
        skip: ["add", "update"],
    },
    {
        id: "designation",
        label: "Title",
        required: false,
        default: "",
        type: "string",
        field: "select",
    },
    {
        id: "gender",
        label: "Gender",
        required: false,
        default: "",
        type: "string",
        field: "select",
    },
    {
        id: "id_num",
        label: "IC/Passport Number",
        required: false,
        default: "",
        type: "string",
        field: "text",
    },
    {
        id: "mobile",
        label: "Mobile",
        required: false,
        default: "",
        type: "string",
        field: "phone",
    },
    {
        id: "org",
        label: "Organisation",
        required: false,
        default: "",
        type: "string",
        field: "text",
    },
    {
        id: "finance_currency",
        label: "Currency",
        required: false,
        default: "BkY4FcjfQ",
        type: "string",
        field: "select",
    },
    {
        id: "finance_payee_name",
        label: "Payee Name",
        required: false,
        default: "",
        type: "string",
        field: "text",
    },
    {
        id: "finance_bank_account_no",
        label: "Bank Account No",
        required: false,
        default: "",
        type: "string",
        field: "text",
    },
    {
        id: "finance_bank_name",
        label: "Bank Name",
        required: false,
        default: "",
        type: "string",
        field: "text",
    },
    {
        id: "finance_bank_swift_code",
        label: "SWIFT Code",
        required: false,
        default: "",
        type: "string",
        field: "text",
    },
    {
        id: "finance_bank_address",
        label: "Bank Address",
        required: false,
        default: "",
        type: "string",
        field: "textarea",
    },
    {
        id: "access_gdrive",
        label: "Access to Google Drive",
        required: false,
        default: "no",
        type: "string",
        field: "select",
        sync: true,
    },
    {
        id: "access_notion",
        label: "Access to Notion",
        required: false,
        default: "no",
        type: "string",
        field: "select",
        sync: true,
    },
];
