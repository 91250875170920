/* eslint-disable */
import _random from 'lodash/random';

import { 
    DATABASE_MARKETING_LIST,
    DATABASE_MARKETING_ADD,
    DATABASE_MARKETING_UPDATE,
    DATABASE_MARKETING_DELETE,
    RESET_DATABASE_MARKETING 
} from '../actions/types';

const initialState = {
    list: null,
    updated: false,
    rand: false
};

export const database_marketing = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case DATABASE_MARKETING_LIST:
            return {
                ...state,
                list: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case DATABASE_MARKETING_ADD:
        case DATABASE_MARKETING_UPDATE:
        case DATABASE_MARKETING_DELETE:
            let randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_DATABASE_MARKETING:
            return {
                ...state,
                list: null,
                updated: false,
                rand: false
            };
        default:
            return state;
    }
}