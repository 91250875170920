/* eslint-disable */
import React, { useMemo, useState, useEffect } from 'react';
import styled from "styled-components";
import moment from 'moment';
import Papa from 'papaparse';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _toLower from 'lodash/toLower';
import _split from 'lodash/split';

import ModalView from '../../components/ModalView';

import { ButtonGroup, InfoButton, IndigoButton } from '../../styles/button';
import { GreyTag, SuccessTag } from '../../styles/tag';

import { isArrayExists } from '../../helpers/validation';
import { getMomentTime } from '../../helpers/date';


const Wrapper = styled.div`
    text-align: right;
`;

const checkStatus = (region_id,list,month) => {
    return _find(list, i => i.id === month && _find( i.regions, r => r === region_id ) ? true : false ) ? true : false;
}

const MassGeneration = ({
    list,
    month,
    regions,
    onMassGeneration = () => {}
}) => {
    const [ opened, setOpened ] = useState(false);

    const showGenerateAllBtn = useMemo(() => {
        let valid = false;
        _forEach( regions, region => {
            if ( !checkStatus(region.id,list,month) ) {
                valid = true;
            }
        }); 
        return valid;
    },[list,month,regions]);

    const handleGenerateData = () => {
        let selected = [];
        _forEach( regions, region => {
            if ( !checkStatus(region.id,list,month) ) {
                selected.push(region.id);
            }
        });
        onMassGeneration(selected, month);
    }

    const handleSingleGeneration = (region_id) => () => {
        onMassGeneration([ region_id ], month);
    }

    return (
    <Wrapper>
        <IndigoButton style={{ padding: '5px 15px' }} onClick={() => setOpened(true)}><i className="fa fa-gear"></i>Generate Data For All Partners</IndigoButton>
        <ModalView
            open={opened}
            onClose={() => setOpened(false)}
            maxWidth="lg"
            title={`Generate "${moment(month).utcOffset(8).format("MMM-YY")}" Data For All Partners`}
            noAction={true}
            cancelLabel="Close"
            contents={(
            <>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Partner</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>
                                { showGenerateAllBtn && <IndigoButton size="small" style={{ padding: '5px 15px' }} onClick={handleGenerateData}>Generate All</IndigoButton> }
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_sortBy( regions, [ r => r.label.toLowerCase() ] ).map((region, index) => (
                            <TableRow key={region.id}>
                                <TableCell>{region.label}</TableCell>
                                <TableCell>
                                    { checkStatus(region.id,list,month) ? <SuccessTag><i className='fa fa-check-circle' style={{ fontSize: '30px' }}></i></SuccessTag> : <GreyTag><i className='fa fa-times-circle' style={{ fontSize: '30px' }}></i></GreyTag> }
                                </TableCell>
                                <TableCell>
                                    { !checkStatus(region.id,list,month) ? <InfoButton size="small" style={{ padding: '5px 15px' }} onClick={handleSingleGeneration(region.id)}>Generate Now</InfoButton> : null }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </>
            )} />
    </Wrapper>
    )
}

export default MassGeneration;