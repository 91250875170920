/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_updateClassAttendanceReporting = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'class', action: 'update_attendance_reporting', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}