/* eslint-disable */
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _isInteger from 'lodash/isInteger';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';
import _size from 'lodash/size';
import _split from 'lodash/split';
import _find from 'lodash/find';
import _filter from 'lodash/filter';

import { isArrayExists } from './validation';
import { replaceAll } from './data';

import { ENROLLMENT_STATUS_OPTIONS } from '../constants';

// get student enrollment status
export const getStudentEnrollmentStatus = function(trainee) {
	let status = ( trainee && trainee.enrollment_status && !_isEmpty( trainee.enrollment_status ) ? trainee.enrollment_status : 'registered' );
    // run through course access to get status, and make sure student wasn't deleted
    if ( trainee && trainee.course_access && isArrayExists( trainee.course_access ) && status !== 'delete' ) {
        let active = 0,
            inactivate = 0,
            conclude = 0;
        _forEach( trainee.course_access, course => {
            if ( course && course.id && course.id !== '3' && course.status && !_isEmpty( course.status ) ) {
                switch (course.status) {
                    case 'active': active++; break;
                    case 'inactivate': inactivate++; break;
                    case 'conclude': conclude++; break;
                }
            } // end - course.status
        });

        if ( active > 0 ) {
            status = 'active';
        } else if ( conclude > 0 ) {
            status = 'conclude';
        } else if ( inactivate > 0 ) {
            status = 'inactivate';
        } // end - active

    } // end - trainee
    return status;
}


// get student enrollment status label
export const getStudentEnrollmentStatusLabel = function(status) {
	let selected = ( status && !_isEmpty( status ) ? _find( ENROLLMENT_STATUS_OPTIONS, { value: status } ) : false );
    return ( selected && selected.label ? selected.label : '' );
}

// check for duplication
export const checkForDuplication = (trainees,email) => {
    let duplicates = false,
        filtered = _filter( trainees, (t) => t.email === email && getStudentEnrollmentStatus(t) !== 'delete' ? true : false );
    if ( _size( filtered ) > 1 ) {
        duplicates = true;
    }
    return duplicates;
}