/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import firebase from 'firebase/app';
import Typography from '@material-ui/core/Typography';
import _isEmpty from 'lodash/isEmpty';

import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';

import { FullScreenFlex, FullScreenComponent } from '../../styles/division';
import { Button, GreyButton } from '../../styles/button';

import { validateEmail } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';

import { logout } from '../../actions/auth';

import { PLATFORM_NAME_LONG, PLATFORM_URL } from '../../constants';

class MaintenanceModePage extends React.Component {

    state = {
        processing: false
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    
    handleLogout = event => {
        event.preventDefault();
        this.props.dispatch(logout());
    }


    render() {
        const { authData } = this.props;
        return (
        <FullScreenComponent style={{ background: 'url("/loader_bg.jpg")', backgroundPosition: "center center", backgroundSize: "cover" }}>
            <FullScreenFlex justify="center" items="center" style={{ background: "rgba(#f1f1f1,0.75)" }}>
                <div style={{ padding: "60px 90px", background: "#f1f1f1", borderRadius: "12px", boxShadow: "0 0 15px 0 rgba(0,0,0,0.25)", maxWidth: '700px' }}>
                    <div style={{ margin: "0 auto 30px auto", maxWidth: '175px' }}><img src="/logo.gif" style={{ display: "block", height: 'auto', width: '100%' }} /></div>
                    <h1 style={{ fontWeight: "700", textTransform: "uppercase", marginBottom: "30px" }}>Chumbaka SMS Scheduled Maintenance, 1 May 01:00am to 2 May 11:59pm</h1>
                    Dear all,<br /><br />
                    We want you to be aware that on 1 May, there will be scheduled downtime for approximately 2 days. We will be using this time to update our Chumbaka LMS to provide better service to all. During the maintenance downtime, you will not be able to access LMS.<br /><br />
                    Maintenance start: 01:00am, 1 May 2021<br /><br />
                    Maintenance end: 11:59pm, 2 May 2021<br /><br />
                    In the meantime, stay curious, keep learning! <br /><br />
                    Kind regards,<br /><br />
                    Chong Zhi Xiong<br /> 
                    Chief Learning Officer<br />
                    Chumbaka<br />
                    {/* <div style={{ textAlign: 'center', marginTop: "60px" }}>
                        <GreyButton style={{ textTransform: 'uppercase', padding: "10px 45px" }} onClick={this.handleLogout}><i className="fa fa-power-off"></i>Logout</GreyButton>
                    </div> */}
                </div>


            </FullScreenFlex>
        </FullScreenComponent>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(MaintenanceModePage);