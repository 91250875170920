/* eslint-disable */
import React, { useState, useMemo } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import shortid from "shortid";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _replace from "lodash/replace";
import _remove from "lodash/remove";
import _reverse from "lodash/reverse";
import _sortBy from "lodash/sortBy";

import ScreenLoader from "../../components/ScreenLoader";
import ModalDelete from "../../components/ModalDelete";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import Footer from "../../components/Footer";

import { isArrayExists } from "../../helpers/validation";
import { getSelectedValue, cloneCollections, trimEmail } from "../../helpers/data";
import { getMomentTime, printSemiPrettyDate, getMomentTimestamp } from "../../helpers/date";
import { isSkip, isSchemaRequired, doSchemaErrorCheck } from "../../helpers/schemas";
import { triggerErrorAlert } from "../../helpers/alert";

import { Button, InverseButton, GreyButton, InverseLink, InfoButton, InfoLink, AInverseLink } from "../../styles/button";

import { traineeSchema } from "../../schemas/trainee";

import { PLATFORM_NAME_LONG, DAY_OPTIONS, GENDER_OPTIONS, PLATFORM_WEBSITE_URL, ENROLLMENT_STATUS_OPTIONS } from "../../constants";

const Wrapper = styled.div``;

const RecordWrapper = styled.div`
    margin-top: 80px;
`;

const RegistrationCompleted = ({
    data = {},
    trainees = [],
    parent = {},
    classes,
    studentEmail = "",
    parentEmail = "",
    pastRegistrations = [],
    onRefresh = () => {},
}) => {
    const registrations = useMemo(() => {
        let list = [];
        _forEach(pastRegistrations, (reg) => {
            let count = 0;
            if (reg.records && isArrayExists(reg.records)) {
                const records = _reverse(_sortBy(reg.records, ["created_on"]));
                records.forEach((record) => {
                    count++;
                    let class_status = record.enrollment_status || "";
                    // check all the previous records to find if there is any duplicated record
                    if (list && isArrayExists(list)) {
                        _forEach(list, (item, index) => {
                            if (item.schedule_id === record.schedule_id && item.source_id === reg.id) {
                                list[index].class_status = "duplicated";
                            }
                        });
                    }
                    list.push({
                        id: reg.id + "-" + count,
                        name: record.name,
                        created_on: count === 1 ? "now" : record.created_on || 0,
                        source_id: reg.id,
                        schedule_id: record.schedule_id,
                        class_name: record.class_name,
                        class_status,
                    });
                });
            }
        });

        return list;
    }, [pastRegistrations]);

    return (
        <Wrapper>
            <h1 style={{ fontWeight: "300", fontSize: "32px", textTransform: "uppercase", marginBottom: "20px", textAlign: "center" }}>
                THANK YOU FOR YOUR REGISTRATION!
            </h1>

            <Typography variant="body1" className={classes.paragraph} style={{ fontWeight: "700" }}>
                A registration receipt has been sent to {studentEmail + parentEmail}.
            </Typography>

            <Typography variant="body1" className={classes.paragraph}>
                If you don’t receive an email in 30 minutes, <br />
                1) you may have entered your email wrongly. Please{" "}
                <InfoLink
                    style={{ padding: "0px", textTransform: "none", fontSize: "16px" }}
                    onClick={(event) => {
                        event.preventDefault();
                        onRefresh();
                    }}
                >
                    register again
                </InfoLink>
                . <br />
                2) it may have gone into your SPAM box.
                <Tooltip
                    title={
                        <img
                            style={{ width: "100%", height: "auto" }}
                            src="https://firebasestorage.googleapis.com/v0/b/chumbaka-dev-tms.appspot.com/o/file_storage%2Fspam-folder-simple.jpg?alt=media&token=cc46d330-68a9-4fb0-8d91-3031e6f089f1"
                        />
                    }
                    classes={{
                        tooltip: classes.tooltip,
                    }}
                    arrow
                    position="top"
                >
                    <i className="fa fa-lightbulb-o" style={{ marginLeft: "5px" }}></i>
                </Tooltip>
                <br />
            </Typography>

            <Typography variant="body1" className={classes.paragraph}>
                For any other enquiry, please contact person in charge{" "}
                {data && data.schedule && data.schedule.coordinator_name && !_isEmpty(data.schedule.coordinator_name)
                    ? data.schedule.coordinator_name
                    : ""}{" "}
                at{" "}
                {data && data.schedule && data.schedule.coordinator_phone && !_isEmpty(data.schedule.coordinator_phone) ? (
                    <a href={"https://api.whatsapp.com/send/?phone=" + data.schedule.coordinator_phone} target="_blank">
                        {data.schedule.coordinator_phone}
                    </a>
                ) : (
                    ""
                )}{" "}
                or email us at{" "}
                {data && data.schedule && data.schedule.coordinator_email && !_isEmpty(data.schedule.coordinator_email) ? (
                    <a href={"mailto:" + data.schedule.coordinator_email} target="_blank">
                        {data.schedule.coordinator_email}
                    </a>
                ) : (
                    ""
                )}
                .
            </Typography>

            <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton
                    style={{ padding: "15px 5px" }}
                    minWidth="250px"
                    onClick={() => {
                        onRefresh();
                    }}
                >
                    <i className="fa fa-refresh"></i>Register Another Student
                </InfoButton>
            </div>

            <RecordWrapper>
                <Typography variant="h4" style={{ marginBottom: "5px" }}>
                    Past Registration(s)
                </Typography>
                <Typography variant="body1" className={classes.paragraph} style={{ marginBottom: "10px", fontSize: "13px" }}>
                    Here are your past registrations in our programs. We are only able to detect your past registrations with the same email.{" "}
                </Typography>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Registration Date</TableCell>
                            <TableCell>Class Name</TableCell>
                            <TableCell>Student Name</TableCell>
                            <TableCell>Class Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {registrations.map((record, index) => (
                            <TableRow key={record.id}>
                                <TableCell>{record.created_on === "now" ? "Now" : getMomentTime(record.created_on, "YYYY-MM-DD hh:mma")}</TableCell>
                                <TableCell>{record.class_name}</TableCell>
                                <TableCell>{record.name}</TableCell>
                                <TableCell>
                                    {record.class_status && record.class_status === "duplicated"
                                        ? "Duplicated"
                                        : getSelectedValue(ENROLLMENT_STATUS_OPTIONS, record.class_status, "value", "label", "-")}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </RecordWrapper>
        </Wrapper>
    );
};

export default RegistrationCompleted;
