/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_HQ_SUMMARY,
	UPDATE_COURSE_ACCESS_REPORT
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getHQSummary, fb_getCourseAccessLog, fb_updateCourseAccessLog } from './fb';


export const getHQSummary = (props) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		// fb_getHQSummary(props)
		// .then( list => {
		// 	dispatch({ type: GET_HQ_SUMMARY, payload: { list } });
		// 	dispatch(toggleLoader(false));
		// })
		// .catch( error => {
		// 	dispatch(toggleLoader(false));
		// 	triggerErrorAlert(getAPIErrorMessage(error));
		// });

		fb_getCourseAccessLog(list => {
			dispatch(toggleLoader(false));
			dispatch({ type: GET_HQ_SUMMARY, payload: { list } });
		},props);

	}
}

export const updateCourseAccessLog = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_updateCourseAccessLog(formData)
		.then( results => {
			dispatch({ type: UPDATE_COURSE_ACCESS_REPORT });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Log Updated");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}