/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _split from 'lodash/split';
import _endsWith from 'lodash/endsWith';

import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormInputCopy from '../../components/FormInputCopy';
import FormSelect from '../../components/FormSelect';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormDatePicker from '../../components/FormDatePicker';
import FormPhoneField from '../../components/FormPhoneField';
import FormDateOfBirth from '../../components/FormDateOfBirth';

import { FormBox } from '../../styles/form';
import { AInverseLink, InverseButton } from '../../styles/button';
import { SuccessTag, ErrorTag } from '../../styles/tag';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { getMomentTime, getDateNumFromString, isParentFieldRequired } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';

// import { userStudentSchema } from '../../schemas/user_student';

// import { YES_NO_OPTIONS, GENDER_OPTIONS, PLATFORM_BACKPACK_URL } from '../../constants';

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    },
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '14px',
        verticalAlign: 'top'
    }
});

class LinkedAccounts extends React.Component {

    state = {
        connecting: false
    };

    handleBadgrUnlink = (event) => {
        event.preventDefault();
        const { unlinkBadgr } = this.props
        let answer = confirm('Are you sure you want to unlink this student with the connected badgr account?\n\nThis action cannot be undone.');
        if ( answer ) {
            if ( unlinkBadgr )
                unlinkBadgr();
        }
    }

    triggerBadgrLink = (event) => {
        event.preventDefault();
        const { changesMade } = this.props;
        if ( changesMade ) {
            triggerErrorAlert("Please save changes first");
        } else {
            let link = "https://badgr.io/auth/oauth2/authorize?client_id=IP3LzpO4Rjc5XvKvgJJiyol3HiApJODIEIHMU9lr&redirect_uri=https://sms.chumbaka.asia/badgr_oauth_redirect&scope=r:profile";
            window.open(link);
            this.setState({ connecting: true });
        }
    }

    render = () => {
        const { classes, student, authData } = this.props;
        const { connecting } = this.state;
        return (
        <Grid item xs={12}>
            <Typography variant="h4" className={classes.boxheading}>Linked Account(s)</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={2}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.headcell} style={{ width: '25%' }}>Service</TableCell>
                                    <TableCell className={classes.headcell}>Account</TableCell>
                                    <TableCell className={classes.headcell} style={{ textAlign: 'center', width: '25%' }}>Action(s)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.bodycell}>Badgr</TableCell>
                                    <TableCell className={classes.bodycell}>{ student.badgr_emails && isArrayExists( student.badgr_emails ) && student.badgr_emails[0] && student.badgr_emails[0].email && !_isEmpty( student.badgr_emails[0].email ) ? student.badgr_emails[0].email : <ErrorTag>{'<Not Connected yet>'}</ErrorTag> }</TableCell>
                                    <TableCell className={classes.bodycell} style={{ textAlign: 'center' }}>{ 
                                        student.badgr_entity_id && !_isEmpty( student.badgr_entity_id ) ? 
                                        (
                                            isAdmin( authData ) ? <InverseButton style={{ padding: '10px 25px' }} onClick={this.handleBadgrUnlink}>Unlink</InverseButton> : 
                                            <SuccessTag>Connected</SuccessTag>
                                        ) : 
                                        (
                                            connecting ? (
                                            <span>Once you've connected to Badgr, please refresh this page to see the status.</span>
                                            ) : ( 
                                                student.uid && authData.uid && student.uid === authData.uid ? <InverseButton style={{ padding: '10px 25px' }} onClick={this.triggerBadgrLink}>Connect Now</InverseButton> : null  
                                            )  
                                        )
                                    }</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
        );
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(LinkedAccounts);