/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import axios from 'axios';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import _random from 'lodash/random';

import CertificateDetails from './details';

import ScreenLoader from '../../components/ScreenLoader';

import { Button, InverseButton, GreyButton, InverseLink, InfoButton, InfoLink, AInverseLink } from '../../styles/button';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { getAPIErrorMessage } from '../../helpers/action';

import { fb_getCertDetails } from '../../actions/students/fb';

import { FIREBASE_CONFIG_DATA } from '../../firebase';
import { PLATFORM_CERT_URL } from '../../constants';

const MainWrapper = styled.div`
    display: block;
    background: #f1f1f1;
    width: 100%;
    min-height: 100vh;
`;

const HeaderWrapper = styled.div`
    background: #fff;
    border-bottom: 1px solid #ddd;
    padding: 15px 30px;
    text-align: center;
`;

const ContentWrapper = styled.div`
    display: block;
    padding: 60px 15px;

    & > .MuiPaper-root {
        background: #fff;
        padding: 45px 30px;
        max-width: 1080px;
        margin: 0 auto;
    }
`;

class CertificatePage extends React.Component {

    state = {
        badge: false,
        certificate: false,
        fonts: false,
        error: false,
        randNum: false
    };

    componentDidMount() {
        const { cert_id } = this.props.match.params;
        this.getCertData(cert_id);
    }

    getCertData = (cert_id) => {
        const { dispatch } = this.props;
        let promises = [];
        promises.push( fb_getCertDetails(cert_id) );
        promises.push( axios({
            method: 'get',
            url: 'https://www.googleapis.com/webfonts/v1/webfonts?key='+FIREBASE_CONFIG_DATA.apiKey
        }) );
        
        Promise.all( promises )
        .then(results => {
            let badge = ( results && results[0] && results[0].badge && isObjectExists( results[0].badge ) ? results[0].badge : false ),
                certificate = ( results && results[0] && results[0].certificate && isObjectExists( results[0].certificate ) ? results[0].certificate : false ),
                fonts = ( results && results[1] && results[1].data && results[1].data.items && isArrayExists( results[1].data.items ) ? results[1].data.items : false );
            this.setState({ badge, certificate, fonts, randNum: _random(1,9999) });
        })
        .catch(error => {
            this.setState({ error, randNum: _random(1,9999) });
        });
    }

    renderContents() {
        const { cert_id } = this.props.match.params;
        const { badge, certificate, fonts } = this.state;
        return <CertificateDetails
            cert_id={cert_id}
            badge={badge}
            certificate={certificate}
            fonts={fonts} />
    }

    renderErrorMessage = () => {
        const { error } = this.state;
        return (
        <>
            <HeaderWrapper><img src="/logo.gif" style={{ display: "inline-block", height: 'auto', width: '100%', maxWidth: '175px' }} /></HeaderWrapper>
            <ContentWrapper>
                <Paper elevation={2}> 
                    <div>{getAPIErrorMessage(error)}</div>
                </Paper>
            </ContentWrapper>
        </>
        );
    }

    render() {
        const { randNum, error } = this.state;
        return !randNum ? <ScreenLoader /> : (
        <MainWrapper>
            { error ? this.renderErrorMessage() : this.renderContents() }
        </MainWrapper>
        );
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(CertificatePage);