/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import styled from "styled-components";
import moment from 'moment';
import blue from '@material-ui/core/colors/blue';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from "@material-ui/core/styles";
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';
import _replace from 'lodash/replace';
import _reverse from 'lodash/reverse';
import _split from 'lodash/split';

import { isArrayExists } from '../../helpers/validation';
import { getDateNumFromString, getMomentNow } from '../../helpers/date';

import { MONTH_OPTIONS } from '../../constants'

const useStyles = theme => ({
    menu: {
        "& .MuiMenu-paper": {
            background: '#fff',
            boxShadow: '0 0 20px 0 rgba(0,0,0,0.45)'
        }
    },
    menuitem: {
        background: '#fff',
        "&:hover": {
            background: '#e5e5e5'
        },
        "&.Mui-selected, &.Mui-selected:hover": {
            background: theme.palette.primary.main,
            color: '#fff'
        }
    }
});

class FormDateOfBirth extends React.Component {

    handleChange = (key) => (event) => {
        const { name, value } = this.props;
        if ( this.props.onChange ) {
            let selected = event.target.value,
                currentYear = this.getSelectedValue('year'),
                currentMonth = this.getSelectedValue('month'),
                currentDate = this.getSelectedValue('date'),
                newValue = '',
                newTimestamp = 0,
                utc = 8;

            switch (key) {
                case 'year':
                    newValue = selected+'-'+currentMonth+'-'+currentDate;
                    newTimestamp = moment().utcOffset(utc).set({ 
                        year: getDateNumFromString(newValue,'year'), 
                        month: ( currentMonth && !_isEmpty( currentMonth ) ? getDateNumFromString(newValue,'month') : 1 ),
                        date: ( currentDate && !_isEmpty( currentDate ) ? getDateNumFromString(newValue,'date') : 1 ),
                    }).startOf('date').valueOf();
                    break;
                case 'month':
                    newValue = currentYear+'-'+selected+'-'+currentDate;
                    newTimestamp = moment().utcOffset(utc).set({ 
                        year: ( currentYear && !_isEmpty( currentYear ) ? getDateNumFromString(newValue,'year') : 1990 ), 
                        month: ( selected && !_isEmpty( selected ) ? getDateNumFromString(newValue,'month') : 1 ),
                        date: ( currentDate && !_isEmpty( currentDate ) ? getDateNumFromString(newValue,'date') : 1 ),
                    }).startOf('date').valueOf();
                    break;
                case 'date':
                    newValue = currentYear+'-'+currentMonth+'-'+selected;
                    newTimestamp = moment().utcOffset(utc).set({ 
                        year: ( currentYear && !_isEmpty( currentYear ) ? getDateNumFromString(newValue,'year') : 1990 ), 
                        month: ( currentMonth && !_isEmpty( currentMonth ) ? getDateNumFromString(newValue,'month') : 1 ),
                        date: ( selected && !_isEmpty( selected ) ? getDateNumFromString(newValue,'date') : 1 ),
                    }).startOf('date').valueOf();
                    break;
            }

            this.props.onChange(newValue,name,key,newTimestamp);
        }
    }

    getSelectedValue = (key) => {
        const { value } = this.props;
        let selected = '';
        if ( value && !_isEmpty( value ) ) {
            let splitData = _split( value, '-' );
            switch (key) {
                case 'year':
                    if ( splitData && splitData[0] && !_isEmpty( splitData[0] ) ) {
                        selected = splitData[0];
                    }
                    break;
                case 'month':
                    if ( splitData && splitData[1] && !_isEmpty( splitData[1] ) ) {
                        selected = splitData[1];
                    }
                    break;
                case 'date':
                    if ( splitData && splitData[2] && !_isEmpty( splitData[2] ) ) {
                        selected = splitData[2];
                    }
                    break;
            }
        }
        return selected;
    }

    isSelectedEmpty = (key) => {
        let selected = this.getSelectedValue(key);
        return ( selected && !_isEmpty( selected ) ? false : true );
    }

    renderValue = selected => {
        const { options } = this.props;
        var string = '';
        if ( selected && isArrayExists( selected ) ) {
            selected.forEach(value => {
                var item = _find( options, { value } );
                string += ( !_isEmpty( string ) ? ', ' : '' ) + ( item && item.label || '' );
            });
        }
        return string;
    }

    renderYearOptions = () => {
        let options = [],
            currentYear = parseInt( getMomentNow('YYYY') );
        for (let index = currentYear; index > ( currentYear-110 ); index--) {
            options.push({ value: index.toString(), label: index.toString() });
        }
        // options = _reverse( options );
        return options.map(option => {
            return <option key={option.value} value={option.value}>{option.label}</option>
        });
    }

    renderMonthOptions = () => {
        return ( MONTH_OPTIONS && isArrayExists( MONTH_OPTIONS ) ? MONTH_OPTIONS.map(option => {
            return <option key={option.label} value={option.label}>{option.label}</option>
        }) : null );
    }

    renderDateOptions = () => {
        const { value } = this.props;
        let options = [],
            selectedMonthNum = getDateNumFromString( value, 'month' ),
            selectedMonth = _find( MONTH_OPTIONS, { value: selectedMonthNum.toString() } ),
            selectedYear = getDateNumFromString( value, 'year' );

        if ( selectedMonth && selectedMonth.maxDate ) {
            let maxDate = selectedMonth.maxDate,
                isLeapYear = ( ((selectedYear % 4 == 0) && (selectedYear % 100 != 0)) || (selectedYear % 400 == 0 ) ? true : false );
            if ( isLeapYear && selectedMonth && selectedMonth.value === '1' ) {
                maxDate = 29;
            }

            for (let index = 1; index <= maxDate; index++) {
                options.push({ value: ( index < 10 ? '0' : '' )+index.toString(), label: ( index < 10 ? '0' : '' )+index.toString() });
            }
        } // end - selectedMonth
        
        return options.map(option => {
            return <option key={option.value} value={option.value}>{option.label}</option>
        });
    }

    renderLabel = () => {
        const { label } = this.props;
        var newLabel = false;
        if ( label && !_isEmpty( label ) && ( 
            ( _toLower( label ).indexOf("(required)") >= 0 ) || 
            ( _toLower( label ).indexOf("*") >= 0 )
         ) ) {
            newLabel = _replace( label, '(Required)', '' );
            newLabel = _replace( newLabel, '(required)', '' );
            newLabel = _replace( newLabel, '*', '' );
        } // end - label
        return ( newLabel ? <span>{ newLabel || '' }<span className="required">*</span></span> : ( label || '' ) );
    }

    render() {
        const { id, value, options, name, disabled, disableNative, classes, backgroundColor } = this.props;
        var newID = 'multi-select-' + _random(1,9999);
        return (
        <div style={{ background: ( backgroundColor || "#fff" ) }}>
            <InputLabel shrink={true} style={{ fontSize: "16px", fontWeight: "700", textTransform: "uppercase", background: '#fff', paddingLeft: '5px', paddingRight: '5px' }}>{this.renderLabel()}</InputLabel>
            <FormControl variant="outlined" style={{ background: "#fff", marginRight: '5px' }}>
                <Select 
                    native={true}
                    value={this.getSelectedValue('year')} 
                    onChange={this.handleChange('year')}
                    disabled={( disabled ? true : false )}
                    MenuProps={{
                        className: classes.menu
                    }}
                    >
                    <option value="">Year</option>
                    {this.renderYearOptions()}
                </Select>
            </FormControl>
            <FormControl variant="outlined" style={{ background: "#fff", marginRight: '5px' }}>
                <Select 
                    native={true}
                    value={this.getSelectedValue('month')} 
                    onChange={this.handleChange('month')}
                    disabled={( disabled || this.isSelectedEmpty('year') ? true : false )}
                    MenuProps={{
                        className: classes.menu
                    }}
                    >
                    <option value="">Month</option>
                    {this.renderMonthOptions()}
                </Select>
            </FormControl>
            <FormControl variant="outlined" style={{ background: "#fff" }}>
                <Select 
                    native={true}
                    value={this.getSelectedValue('date')} 
                    onChange={this.handleChange('date')}
                    disabled={( disabled || this.isSelectedEmpty('month') ? true : false )}
                    MenuProps={{
                        className: classes.menu
                    }}
                    >
                    <option value="">Date</option>
                    {this.renderDateOptions()}
                </Select>
            </FormControl>
        </div>
        )
    }

}

export default compose(
    withStyles(useStyles)
)(FormDateOfBirth);