/* eslint-disable */
import React, { useState } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, Link } from "react-router-dom";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import ButtonActions from '../../components/ButtonActions';
import FormSelect2 from '../../components/FormSelect2';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { SuccessTag, GreyTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { isAdmin, hasAccessRights } from '../../helpers/auth';

import { addProgram, deleteProgram } from '../../actions/programs';

import { programSchema } from '../../schemas/program';

class ProgramsTable extends React.Component {
    
    state = {
        sortBy: 'program_name-asc'
    }

    getExistingPrograms = () => {
        const { programs, filterBy, partner } = this.props;
        const { sortBy } = this.state;
        let items = ( programs ? _filter( programs, p => p.program_visibility && isArrayExists( p.program_visibility ) && _find( p.program_visibility, { id: partner.region || '' } ) ) : [] );
        // do duration filter
        if ( filterBy && filterBy.course_duration && !_isEmpty( filterBy.course_duration ) ) {
            items = _filter( items, { course_duration: filterBy.course_duration });
        }
        // do type filter
        if ( filterBy && filterBy.course_type && !_isEmpty( filterBy.course_type ) ) {
            items = _filter( items, { course_type: filterBy.course_type });
        }

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) ) {
            switch( sortBy ) {
                case 'program_name-desc':
                    items = _sortBy( items, [ i => i.name.toLowerCase() ] );
                    items = _reverse( items );
                    break;
                case 'program_name-asc':
                    items = _sortBy( items, [ i => i.name.toLowerCase() ] );
                    break;
                case 'course_type_label-desc':
                    items = _sortBy( items, [ i => i.course_type_label.toLowerCase() ] );
                    items = _reverse( items );
                    break;
                case 'course_type_label-asc':
                    items = _sortBy( items, [ i => i.course_type_label.toLowerCase() ] );
                    break
                case 'course_duration_label-desc':
                    items = _sortBy( items, [ i => i.course_duration_label.toLowerCase() ] );
                    items = _reverse( items );
                    break;
                case 'course_duration_label-asc':
                    items = _sortBy( items, [ i => i.course_duration_label.toLowerCase() ] );
                    break;
            }
        } // end - sortBy

        return items;
    }

    render() {
        const { course_type, course_duration } = this.props;
        const { sortBy } = this.state;
        const items = this.getExistingPrograms();
        return (
        <div style={{ padding: '10px 5px' }}>
            <TableBar
                show={[ 'sort' ]}
                sortBy={sortBy}
                sortByOptions={[
                    { value: 'program_name-asc', label: 'Program Name ( A - Z)' },
                    { value: 'program_name-desc', label: 'Program Name ( Z - A )' },
                    { value: 'course_type_label-asc', label: 'Delivery Type ( A - Z)' },
                    { value: 'course_type_label-desc', label: 'Delivery Type ( Z - A )' },
                    { value: 'course_duration_label-asc', label: 'Duration ( A - Z)' },
                    { value: 'course_duration_label-desc', label: 'Duration ( Z - A )' },
                ]}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy })}
                style={{ marginBottom: "20px" }} />
            <Table 
                items={items}
                emptyCell="No program(s) found."
                showCheckbox={false}
                cells={[
                    { id: 'course_type', label: 'Delivery Type', render: (item) => <WrapWord>{getSelectedValue( course_type, item.course_type, 'id', 'label', '-' )}</WrapWord> },
                    { id: 'course_duration', label: 'Duration', render: (item) => <WrapWord>{getSelectedValue( course_duration, item.course_duration, 'id', 'label', '-' )}</WrapWord> },
                    { id: 'name', label: 'Program Name', render: (item) => <a href={"/programs/"+item.id} target="_blank">{( item.name || '' )}</a> },
                ]}
                noActions={true}
                />
        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(ProgramsTable);