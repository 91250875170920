/* eslint-disable */
import _random from 'lodash/random';

import { 
	DATABASE_STUDENTS_LIST,
	DATABASE_STUDENTS_GET_DATA,
	DATABASE_STUDENTS_UPDATE,
	DATABASE_STUDENTS_DELETE,
    RESET_DATABASE_STUDENTS,
} from '../actions/types';

const initialState = {
    list: null,
    student: null,
    updated: false,
    rand: false
};

export const database_students = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case DATABASE_STUDENTS_LIST:
            return {
                ...state,
                list: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case DATABASE_STUDENTS_GET_DATA:
            return {
                ...state,
                student: ( payload.student || {} ),
                rand: _random(1,9999)
            };
        case DATABASE_STUDENTS_UPDATE:
        case DATABASE_STUDENTS_DELETE:
            let randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_DATABASE_STUDENTS:
            return {
                ...state,
                list: null,
                student: null,
                updated: false,
                rand: false
            };
        default:
            return state;
    }
}