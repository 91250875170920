/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Alert from "react-s-alert";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';

import VisibilityTable from './visibility_table';
import ProductsTable  from './products_table';

import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormDatePicker from '../../components/FormDatePicker';
import FormEditor from '../../components/FormEditor';
import FormUpload from '../../components/FormUpload';
import FormRadio from '../../components/FormRadio';
import ToolTipInfo from '../../components/ToolTipInfo';

import { FormBox } from '../../styles/form';
import { InfoBox } from '../../styles/message';
import { AInfoLink, InfoButton } from '../../styles/button';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';
import { uploadMediaToStorage } from '../../helpers/firebase';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';

import { programSchema } from '../../schemas/program';

import { toggleLoader } from '../../actions/global';

import { YES_NO_OPTIONS } from '../../constants';

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

const filePickerCallBack = (authData,program,field,dispatch) => ( callback, value, meta ) => {
    const input = document.getElementById('editor-mce-file-upload-field-' + field.name + '-' + ( program && program.id && !_isEmpty( program.id ) ? program.id : '' ) );

    input.click();
    input.onchange = function () {
        const file = input.files[0];
        const uploadPath = 'programs/' + ( program && program.id && !_isEmpty( program.id ) ? program.id : 'misc' ) + '/content';
        Alert.success('<span class="app-alert text-center content-block"><i class="fa fa-circle-o-notch fa-spin"></i>Uploading image - Please do not click on anything, browser back button, refresh or close this page!</span>', { position: 'top', effect: 'flip', beep: false, timeout: 'none', offset: 70 });
        dispatch(toggleLoader(true));
        uploadMediaToStorage(file, uploadPath, authData)
        .then(function(url) {
            Alert.closeAll();
            dispatch(toggleLoader(false));
            triggerSuccessAlert('Upload Done!');
            callback(url);
        })
        .catch(function(error) {
            Alert.closeAll();
            dispatch(toggleLoader(false));
            triggerErrorAlert(error.data.error);
        });

    };
}

class ProgramEditForm extends React.Component {

    state = {
        randNum: false
    };

    handleFormUpdate = ( newValue, key ) => {
        const { onFormUpdate, program, currentCourse, course_type, course_duration, currency, price_per_pax } = this.props;
        let newData = ( program && !_isEmpty( program ) ? cloneCollections( program ) : {} );

        switch( key ) {
            case 'course_type':
                let selected_course_type = ( course_type && isArrayExists( course_type ) ? _find( course_type, { id: newValue } ) : false );
                newData[key] = newValue;
                newData[key+"_label"] = ( selected_course_type && selected_course_type.label ? selected_course_type.label : '' );
                break;
            case 'course_duration':
                let selected_course_duration = ( course_duration && isArrayExists( course_duration ) ? _find( course_duration, { id: newValue } ) : false );
                newData[key] = newValue;
                newData[key+"_label"] = ( selected_course_duration && selected_course_duration.label ? selected_course_duration.label : '' );
                break;
            case 'currency':
                let selected_currency = ( currency && isArrayExists( currency ) ? _find( currency, { id: newValue } ) : false );
                newData[key] = newValue;
                newData[key+"_label"] = ( selected_currency && selected_currency.value ? selected_currency.value : '' );
                break;   
            case 'price_per_pax_id':
                newData[key] = newValue;
                newData['price_per_pax'] = getSelectedValue( price_per_pax, newValue, 'id', 'value', '' );
                break;
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    handleFileUpload = (files,key) => {
        const { authData, dispatch, program } = this.props;
        if ( files && files[0] && !_isEmpty( files[0] ) ) {
            dispatch(toggleLoader(true));
            const uploadPath = 'programs/' + ( program && program.id && !_isEmpty( program.id ) ? program.id : 'misc' ) + ( key && key === 'web_course_image' ? '/image' : '/content' );
            uploadMediaToStorage( files[0], uploadPath, authData )
            .then( url => {
                this.handleFormUpdate(url,key);
                dispatch(toggleLoader(false));
            })
            .catch(error => {
                dispatch(toggleLoader(false));
                triggerErrorAlert(( error && error.message || 'Upload Error. Please try again' ));
            });
        } else {
            triggerErrorAlert('Please upload a valid image file');
        }
    }

    getFieldOptions = (field) => {
        const { program, course_type, course_duration, price_per_pax, currency, registration_forms = [] } = this.props;

        switch( field.name ) {
            case 'course_type':
                return getSelectOptions({ list: ( course_type || false ), options: [], keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' });
            case 'course_duration':
                return getSelectOptions({ list: ( course_duration || false ), options: [], keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' });
            case 'price_per_pax_id':
                return getSelectOptions({ list: ( price_per_pax || false ), options: [], keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' });
            case 'currency':
                return getSelectOptions({ list: ( currency || false ), options: [], keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' });
            case 'registration_form':
                return getSelectOptions({ list: ( registration_forms || false ), options: [{ value: 'default', label: 'Default Template' }], keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' });
            case 'team_size':
                return [
                    { value: '1', label: '1 Student' },
                    { value: '2', label: '2 Students' },
                    { value: '3', label: '3 Students' },
                    { value: '4', label: '4 Students' },
                    { value: '5', label: '5 Students' },
                    { value: '6', label: '6 Students' },
                    { value: '7', label: '7 Students' },
                    { value: '8', label: '8 Students' },
                    { value: '9', label: '9 Students' },
                    { value: '10', label: '10 Students' },
                ];
            case 'project_submissions':
                return [
                    { value: '1', label: '1 Project' },
                    { value: '2', label: '2 Projects' },
                    { value: '3', label: '3 Projects' },
                    { value: '4', label: '4 Projects' },
                    { value: '5', label: '5 Projects' },
                    { value: '6', label: '6 Projects' },
                    { value: '7', label: '7 Projects' },
                    { value: '8', label: '8 Projects' },
                    { value: '9', label: '9 Projects' },
                    { value: '10', label: '10 Projects' },
                ];
            case 'publish':
            case 'team_module':
                return YES_NO_OPTIONS;
            default:
                return [];
        }
    }

    getFieldValue = (field) => {
        const { program } = this.props;
        switch( field.name ) {
            case 'lms_external_url':
                const totalProjects = ( program && program.project_submissions && !_isEmpty( program.project_submissions ) ? parseInt( program.project_submissions ) : 0 );
                console.log(totalProjects);
                let url = '';
                for (let index = 1; index <= totalProjects; index++) {
                    url += ( !_isEmpty( url ) ? '\n' : '' ) + 'https://us-central1-chumbaka-dev-tms.cloudfunctions.net/sso?action=sso_lms_oauth&page=team_project_' + index;
                }
                console.log(url);
                return url;
            case 'registration_form':
                return ( program && program.registration_form && !_isEmpty( program.registration_form ) ? program.registration_form : '' );
            default:
                return ( program && program[field.name] ? program[field.name] : ( field.default || '' ) );
        }
    }

    isFieldDisabled = (schema) => {
        const { authData } = this.props;
        var disabled = false;

        if ( schema && schema.disabled && isArrayExists( schema.disabled ) ) {
            schema.disabled.forEach( condition => {
                switch( condition ) {
                    case 'admin':
                        if ( !isAdmin( authData ) )
                            disabled = true;
                        break;
                    case 'update':
                        disabled = true;
                        break;
                }
            });
        } // end - schema

        return disabled
    }

    getFieldHeight = (field) => {
        switch( field.name ) {
            case 'price_per_pax':
                return 300;
            default:
                return 500;
        }
    }

    getTooltip = (schema) => {
        switch( schema.id ) {
            case 'course_code':
                return "This shall be the same as Course Code in LMS.";
            case 'web_overview':
                return (
                <>
                    PUBLISHED ON WEBSITE<br />
                    Description for Program Overview will be published on Registration page from website.
                </>
                );
            // case 'lms_course_link':
            //     return <a href="https://community.canvaslms.com/t5/Instructor-Guide/How-do-I-enable-program-self-enrollment-with-a-join-code-or/ta-p/830" target="_blank">Refer to this method</a>;
            default:
                return;
        }
    }

    getField = (id) => {
        const { massEditMode } = this.props;
        let schema = _find( programSchema, { id } );
        return ( schema ? {
            name: ( schema.id || '' ),
            label: ( schema.label || '' ) + ( isSchemaRequired(schema,'update') && !massEditMode ? ' (Required)' : '' ),
            field_type: ( schema.field || '' ),
            default: ( massEditMode ? null : ( schema.default || null ) ),
            disabled: ( schema.id === 'publish' ? true : ( schema.disabled ? this.isFieldDisabled( schema ) : false ) ),
            tooltip: this.getTooltip(schema)
        } : null );
    }

    renderField = (id) => {
        const { authData, program, dispatch } = this.props;
        let field = this.getField(id);
        if ( field && field.field_type && !_isEmpty( field.field_type ) ) {
            switch( field.field_type ) {
                case 'text':
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'textarea':
                    return <FormInput {...field} rows={( 'notes' === id ? 6 : 3 )} multiline={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'text_number':
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'email':
                    return <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'radio':
                    return <FormRadio {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} 
                        inline={true}
                        onChange={this.handleFormUpdate} />;
                case 'select':
                    return <FormSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} 
                        disableNative={true}
                        onChange={this.handleFormUpdate} />;
                case 'multiselect':
                    return <FormMultiSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} />;
                case 'datepicker':
                    return <FormDatePicker {...field} noDefaultVal={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'tinymce_editor':
                    return <FormEditor id={id + '-' + ( program && program.id && !_isEmpty( program.id ) ? program.id : '' )} {...field} value={this.getFieldValue(field)} height={this.getFieldHeight(field)} onChange={this.handleFormUpdate} filePickerTypes= 'image' filePickerCallback={filePickerCallBack(authData,program,field,dispatch)} />;
                case 'upload_image':
                    return <FormUpload {...field} value={this.getFieldValue(field)} onReset={this.handleFormUpdate} onUpload={this.handleFileUpload} />
            }
        } // end - field.field_type
    }

    renderCreatedModifiedDate = (item) => {
        let created_on = ( item && item.created_on && item.created_on._seconds ? item.created_on._seconds*1000 : ( item.created_on || null ) ),
            modified_on = ( item && item.modified_on && item.modified_on._seconds ? item.modified_on._seconds*1000 : ( item.modified_on || null ) );
        return (
        <div style={{ paddingTop: "15px", textAlign: 'right', color: '#999', fontSize: '1.25rem' }}>
            <div>{ created_on ? 'Created on ' + getMomentTime( created_on , 'YYYY-MM-DD hh:mm:ssa' ) : ''}</div>
            <div>{ modified_on ? 'Last Modified on ' + getMomentTime( modified_on , 'YYYY-MM-DD hh:mm:ssa' ) : ''}</div>
        </div>
        )
    }

    render = () => {
        const { formType, classes, program, currentProgram, partners, disableSystemDate, massEditMode, visibility_lock, onVisibilityUnlock, products_options, contact_hour, product_level, product_credit } = this.props;
        return (
        <>
            <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff" }}>
                <FormBox>
                    <Typography variant="h4" className={classes.boxheading}>Basic Info</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>{this.renderField('course_type')}</Grid>
                        <Grid item xs={6}>{this.renderField('course_duration')}</Grid>
                        <Grid item xs={12}>{this.renderField('name')}</Grid>
                        <Grid item xs={6}>{this.renderField('currency')}</Grid>
                        <Grid item xs={6}> </Grid>
                        <Grid item xs={6}>{this.renderField('price_per_pax_id')}</Grid>
                        <Grid item xs={6}> </Grid>
                        <Grid item xs={12}>{this.renderField('price_per_pax')}</Grid>
                        <Grid item xs={6}>{this.renderField('registration_form')}</Grid>
                    </Grid>
                </FormBox>
            </Paper>

            <>
                <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff", marginTop: '60px' }}>
                    <FormBox>
                        <Typography variant="h4" className={classes.boxheading}>Competition Module</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={6} style={{ padding: '20px 20px' }}>{this.renderField('team_module')}</Grid>
                            <Grid item xs={6}> </Grid>
                            { program && program.team_module && program.team_module === 'yes' ? (
                            <>
                                <Grid item xs={6}>{this.renderField('team_size')}</Grid>
                                <Grid item xs={6}>{this.renderField('project_submissions')}</Grid>
                                {/* <Grid item xs={12}>
                                    <FormInput
                                        label="LMS External URL for project submissions"
                                        name="lms_external_url"
                                        multiline={true}
                                        rows={5}
                                        value={this.getFieldValue({ name: 'lms_external_url' })}
                                        onChange={() => {}} />
                                </Grid> */}
                            </>) : null }
                        </Grid>
                    </FormBox>
                </Paper>
            </>
            
            <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff", marginTop: "60px" }}>
                <FormBox>
                    <Typography variant="h4" className={classes.boxheading}>Program Details</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>{this.renderField('web_overview')}</Grid>
                        {/* <Grid item xs={12}>{this.renderField('web_what_you_learn')}</Grid> */}
                    </Grid>
                </FormBox>    
            </Paper>

            <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff", marginTop: "60px" }}>
                <FormBox>
                    <Typography variant="h4" className={classes.boxheading}>
                        Product(s) to cover in this Program
                        {/* <span style={{ paddingLeft: '8px' }}><ToolTipInfo content="When ticked, this program will be visible to Partner. This allows Partner to enable students to access the program." /></span> */}
                    </Typography>
                    <ProductsTable 
                        currentProgram={currentProgram}
                        value={( program && program.products && isArrayExists( program.products ) ? program.products : [] )}
                        products_options={( products_options || [] )}
                        contact_hour={contact_hour}
                        product_level={product_level}
                        product_credit={product_credit}
                        onUpdate={this.handleFormUpdate} />
                </FormBox>
            </Paper>

            <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff", marginTop: "60px" }}>
                <FormBox>
                    <Typography variant="h4" className={classes.boxheading}>Program Visibility<span style={{ paddingLeft: '8px' }}><ToolTipInfo content="When ticked, this program will be visible to Partner. This allows Partner to enable students to access the program." /></span></Typography>
                    <VisibilityTable 
                        value={( program && program.program_visibility && isArrayExists( program.program_visibility ) ? program.program_visibility : [] )}
                        formDisabled={( visibility_lock || false )}
                        partners={( partners || [] )}
                        onUpdate={this.handleFormUpdate}
                        onUnlock={(newValue) => { this.props.onVisibilityUnlock(newValue) }} />
                </FormBox>
            </Paper>
            { disableSystemDate ? null : this.renderCreatedModifiedDate(program)}
        </>
        );
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(ProgramEditForm);